import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import './index.scss';
import PropTypes from 'prop-types';

VIAppTabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.array,
  activeTab: PropTypes.string,
  handleClick: PropTypes.func,
};

VIAppTabs.defaultProps = {
  className: '',
  tabs: [],
  activeTab: null,
  handleClick: null,
};

/**
 * @props className (string): không bắt buộc
 * @props tabs (array string): Mảng tên các tab theo thứ tự đảo ngược
 * @props activeTab (string): Tên active tab. K bắt buộc, mặc định là tab đầu
 * @props handleClick(tabName) : Hàm callback tới node cha khi click vào tab
 */
/**
 *
 * @param {{className: string, tabs: string[], activeTab: string, handleClick: function}} props
 * @returns
 */
function VIAppTabs(props) {
  const { tabs, handleClick, className, setTab } = props;
  let activeTab = props.activeTab;
  const { t } = useTranslation();
  const id = uuidv4();

  if (_.isEmpty(activeTab)) {
    activeTab = tabs[tabs.length - 1];
  }

  useEffect(() => {
    var allTabs = document.getElementById(id).getElementsByClassName('tab');
    for (var i = 0, len = allTabs.length; i < len; i++) {
      allTabs[i].addEventListener('click', function () {
        if (this.classList.contains('active')) return;
        var parent = this.parentNode,
          innerTabs = parent.querySelectorAll('.tab');
        for (var index = 0, iLen = innerTabs.length; index < iLen; index++) {
          innerTabs[index].classList.remove('active');
        }
        this.classList.add('active');
      });
    }
  }, []);
  return (
    <div className={'Tabs_Container ' + className} id={id}>
      <div className="tabbed round d-flex flex-row-reverse justify-content-end">
        {tabs.map((text, index) => {
          return (
            <div
              id={`tab`}
              key={index}
              className={
                text == activeTab
                  ? 'tab active font-size-lg position-relative'
                  : 'tab font-size-lg position-relative'
              }
              onClick={(e) => {
                e.preventDefault();
                handleClick(text);
                if (setTab) {
                  setTab(text);
                }
              }}
              style={index == tabs.length - 1 ? { padding: '1rem 1.8rem 1rem 1.2rem' } : {}}
            >
              <div className="radian-border position-absolute">
                <div className="position-relative">
                  <div className="item-radian position-absolute"></div>
                </div>
              </div>
              {t(text)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default VIAppTabs;
