import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TreeCompany from './TreeCompany';
import EmptyView from 'features/Others/components/EmptyView';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import { useTranslation } from 'react-i18next';
import Global from 'general/utils/Global';
import OrganizationHelper from 'general/helpers/OrganizationHelper';
import VIFolderTree from 'general/components/VietInvoice/VIFolderTree';

function InvoiceTransfer(props) {
  const { setOrganizationId } = props;
  const { t } = useTranslation();
  const { organizations } = useSelector((state) => state?.organization);
  const [currentCompany, setCurrentCompany] = useState();
  const [filters, setFilters] = useState({
    q: '',
    page: 0,
    limit: Global.gDefaultPagination,
  });
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  useEffect(() => {
    const { q } = filters;
    const filterItems = OrganizationHelper.filter(organizations, q, '');
    setFilteredOrganizations(filterItems);
  }, [filters, organizations]);

  return (
    <div>
      <VISearchField
        placeholder={t('EnterYourNameTaxCodeToSearch')}
        onSubmit={(e) => {
          setFilters({
            ...filters,
            q: e,
          });
        }}
        // value={searchText}
        iconPosition="right"
        typingTimeout={500}
        className="w-100 mb-3"
      />
      <div
        className="DropdownHeaderChooseCompany min-w-375px"
        style={{ maxHeight: '50vh', overflow: 'auto' }}
      >
        {filteredOrganizations?.length > 0 ? (
          <VIFolderTree
            currentItem={currentCompany}
            data={filteredOrganizations}
            setItem={(item) => {
              setOrganizationId(item);
              setCurrentCompany(item);
            }}
          />
        ) : (
          // <TreeCompany
          //   currentItem={currentCompany}
          //   data={filteredOrganizations}
          //   setItem={(item) => {
          //     setCurrentCompany(item);
          //   }}
          // />
          <div className="pt-12">
            <EmptyView message={t('NoDataToDisplay')} />
          </div>
        )}
      </div>
    </div>
  );
}

export default InvoiceTransfer;
