import faqApi from 'api/faqApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// lay tat ca faqs
export const thunkGetAllFaqs = createAsyncThunk('faq/getAllFaqs', async (params, thunkAPI) => {
  const res = await faqApi.getAll();
  return res;
});

const faqSlice = createSlice({
  name: 'faq',
  initialState: {
    faqList: [],
    isGettingFaqList: false,
  },
  reducers: {},
  extraReducers: {
    // lay tat ca faqs
    [thunkGetAllFaqs.fulfilled]: (state, action) => {
      const { result, faqs } = action.payload;
      if (result === 'success' && faqs) {
        state.faqList = faqs;
      }
    },
  },
});

const { reducer, actions } = faqSlice;
export const {} = actions;
export default reducer;
