import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FastField } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormTextArea from 'general/components/OtherKeenComponents/Forms/KTFormTextArea';

NoteInvoice.propTypes = {
  handleTextArea: PropTypes.func,
  dataInvoice: PropTypes.array,
  showInvoice: PropTypes.bool,
  setDataCheck: PropTypes.any,
  dataNote: PropTypes.string,
};

NoteInvoice.defaultProps = {
  handleTextArea: null,
  dataInvoice: [],
  showInvoice: false,
  setDataCheck: null,
  dataNote: '',
};
function NoteInvoice(props) {
  const { handleTextArea, dataInvoice, showInvoice, setDataCheck, dataNote } = props;
  const { t } = useTranslation();

  return (
    <div>
      {showInvoice && (
        <div className="d-flex text-break flex-wrap">
          {dataInvoice?.map((item, index) => (
            <div
              key={index}
              className="p-2 bg-light d-flex align-items-center rounded mb-4 me-3"
              style={{ width: 'max-content' }}
            >
              <span className="me-2">{item?.invoiceNumber}</span>

              {/* <i
                className="fa-solid fa-xmark cursor-pointer"
                style={{ color: '#e54135' }}
                onClick={() =>
                  setDataCheck(dataInvoice.filter((e) => e?.invoiceId !== item.invoiceId))
                }
              ></i> */}
            </div>
          ))}
        </div>
      )}

      <KTFormGroup
        inputName="textAreaInvoiceContent"
        label={t('Content')}
        inputElement={
          <KTFormTextArea
            value={dataNote}
            // defaultValue={dataInvoice?.map((i) => i.noteInvoice).join(', ')}
            maxLength={0}
            name="textAreaInvoiceContent"
            onChange={(e) => handleTextArea(e)}
          />
        }
      />
    </div>
  );
}

export default NoteInvoice;
