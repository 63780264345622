import dashboardApi from 'api/dashboardApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// thong ke hoa don
export const thunkGetInvoiceStatistic = createAsyncThunk(
  'dashboard/getInvoiceStatistic',
  async (params, thunkAPI) => {
    const copiedParams = { ...(params ?? {}) };
    delete copiedParams['dateRangeLabel'];
    const res = await dashboardApi.getInvoiceStatistic(copiedParams);
    return res;
  }
);
// thong ke hoa don theo thoi gian
export const thunkGetInvoiceByTimeStatistic = createAsyncThunk(
  'dashboard/getInvoiceByTimeStatistic',
  async (params, thunkAPI) => {
    const copiedParams = { ...(params ?? {}) };
    delete copiedParams['dateRangeLabel'];
    const res = await dashboardApi.getInvoiceStatisticByTime(copiedParams);
    return res;
  }
);
// thong ke gia tri hoa don
export const thunkGetInvoiceValueStatistic = createAsyncThunk(
  'dashboard/getInvoiceValueStatistic',
  async (params, thunkAPI) => {
    const copiedParams = { ...(params ?? {}) };
    delete copiedParams['dateRangeLabel'];
    const res = await dashboardApi.getInvoiceValue(copiedParams);
    return res;
  }
);
// bao cao hoa don
export const thunkGetInvoiceReport = createAsyncThunk(
  'dashboard/getInvoiceReport',
  async (params, thunkApi) => {
    const copiedParams = { ...(params ?? {}) };
    delete copiedParams['dateRangeLabel'];
    const res = await dashboardApi.getInvoiceReport(copiedParams);
    return res;
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    // thong ke hoa don
    isGettingInvoiceStatistic: false,
    succeededInvoices: {},
    validInvoices: {},
    invalidInvoices: {},

    // thong ke hoa don theo thoi gian
    isGettingInvoiceByTimeStatistic: false,
    invoiceStatisticByTimeList: [],

    // thong ke gia tri hoa don
    isGettingInvoiceValueStatistic: false,
    invoiceStatisticValueList: [],

    // bao cao hoa don
    invoices: {},
    inputInvoices: {},
    outputInvoices: {},
    isGettingInvoiceReport: false,
  },
  reducers: {},
  extraReducers: {
    // thong ke hoa don
    [thunkGetInvoiceStatistic.pending]: (state, action) => {
      state.isGettingInvoiceStatistic = true;
    },
    [thunkGetInvoiceStatistic.rejected]: (state, action) => {
      state.isGettingInvoiceStatistic = false;
    },
    [thunkGetInvoiceStatistic.fulfilled]: (state, action) => {
      state.isGettingInvoiceStatistic = false;
      const { result, succeededInvoices, validInvoices, invalidInvoices } = action.payload;
      if (result === 'success') {
        state.succeededInvoices = succeededInvoices;
        state.validInvoices = validInvoices;
        state.invalidInvoices = invalidInvoices;
      }
    },

    // thong ke hoa don theo thoi gian
    [thunkGetInvoiceByTimeStatistic.pending]: (state, action) => {
      state.isGettingInvoiceByTimeStatistic = true;
    },
    [thunkGetInvoiceByTimeStatistic.rejected]: (state, action) => {
      state.isGettingInvoiceByTimeStatistic = false;
    },
    [thunkGetInvoiceByTimeStatistic.fulfilled]: (state, action) => {
      state.isGettingInvoiceByTimeStatistic = false;
      const { result, invoiceAnalystByDate } = action.payload;
      if (result === 'success') {
        state.invoiceStatisticByTimeList = invoiceAnalystByDate;
      }
    },

    // thong ke gia tri hoa don
    [thunkGetInvoiceValueStatistic.pending]: (state, action) => {
      state.isGettingInvoiceValueStatistic = true;
    },
    [thunkGetInvoiceValueStatistic.rejected]: (state, action) => {
      state.isGettingInvoiceValueStatistic = false;
    },
    [thunkGetInvoiceValueStatistic.fulfilled]: (state, action) => {
      state.isGettingInvoiceValueStatistic = false;
      const { result, invoiceAnalystTotalValue, total } = action.payload;
      if (result === 'success') {
        state.invoiceStatisticValueList = invoiceAnalystTotalValue;
      }
    },

    // bao cao hoa don
    [thunkGetInvoiceReport.pending]: (state, action) => {
      state.isGettingInvoiceReport = true;
    },
    [thunkGetInvoiceReport.rejected]: (state, action) => {
      state.isGettingInvoiceReport = false;
    },
    [thunkGetInvoiceReport.fulfilled]: (state, action) => {
      state.isGettingInvoiceReport = false;
      const { result, invoices, inputInvoices, outputInvoices } = action.payload;
      if (result === 'success') {
        state.invoices = invoices;
        state.inputInvoices = inputInvoices;
        state.outputInvoices = outputInvoices;
      }
    },
  },
});

const { reducer, actions } = dashboardSlice;
export const {} = actions;
export default reducer;
