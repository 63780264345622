import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppConfigs from 'general/constants/AppConfigs';

CallCenterView.propTypes = {};

function CallCenterView(props) {
  const { t } = useTranslation();

  return (
    <div
      className="CallCenterView d-flex flex-column"
      // style={{ textAlignLast: 'center' }}
    >
      <div className="rounded" style={{ background: '#f8f8f8' }}>
        <p className="m-0 p-1 p-sm-3 px-5 px-sm-15 px-lg-25 text-center font-weight-normal mx-auto">
          Khuyến nghị: Để tiết kiệm chi phí, quý khách nên sử dụng các kênh tư vấn miễn phí trước
          khi sử dụng tổng đài tư vấn hỗ trợ ICORP Invoice. Chi tiết các kênh{' '}
          <a href="#" className="text-decoration-underline">
            {t('Here').toLowerCase()}
          </a>
        </p>
      </div>

      <p
        className="text-center text-uppercase font-size-h1 font-weight-bolder mt-8"
        style={{ color: '#5180fb' }}
      >
        {' '}
        {t('CallCenter')}{' '}
        <a href={`tel: ${AppConfigs.callCenterNumber}`} className="text-decoration-underline">
          {AppConfigs.callCenterNumber}
        </a>
      </p>
      <img
        className="w-100 w-sm-50 mx-auto my-3"
        style={{
          objectFit: 'contain',
        }}
        src="https://www.misacdn.net/sites/misa/images/pages/switchboard/ic-switchboard.svg"
      />
      <div className="w-100 w-sm-50 text-left mx-auto">
        <p className="font-size-h5 font-weight-bolder">{`${t('WorkingTime')}:`}</p>
        <div className="d-flex flex-row flex-sm-column flex-lg-row">
          {/* <p className="font-size-lg font-weight-bolder">Khối Kế toán:</p> */}
          <div className="">
            {/* ms-3 ms-sm-4 ms-md-5 */}
            <p>Ngày thường: 7h30 - 18h30 </p>
            <p>Thứ 7, CN: 8h - 17h30</p>
          </div>
        </div>
        <p className="text-center font-size-h5 font-weight-bolder mt-4">(Cước phí: 1000đ/phút)</p>
      </div>
    </div>
  );
}

export default CallCenterView;
