import LandingPageFooter from 'features/LandingPage/components/LandingPageFooter';
import CartConfirmPaymentInfo from 'features/Payment/components/CartConfirmPaymentInfo';
import CartConfirmPaymentOrder from 'features/Payment/components/CartConfirmPaymentOrder';
import HeaderStore from 'features/Payment/components/HeaderStore';
import ViewOrder from 'features/Payment/components/ViewOrder';
import {
  copyFromCartToOrder,
  removeOrderData,
  updateReadyToPurchaseOrder,
} from 'features/Payment/paymentSlice';
import VIModal from 'general/components/VietInvoice/VIModal';
import AppData from 'general/constants/AppData';
import PaymentHelper from 'general/helpers/PaymentHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Utils from 'general/utils/Utils';
import usePayment from 'hooks/usePayment';
import useRouter from 'hooks/useRouter';
import useScrollToTop from 'hooks/useScrollToTop';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

ConfirmPaymentScreen.propTypes = {};

function ConfirmPaymentScreen(props) {
  // ---- params ----
  const { t } = useTranslation();
  useScrollToTop();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [stepHeaderWidth, setStepHeaderWidth] = useState(0);
  const [stepHeaderHeight, setStepHeaderHeight] = useState(0);
  const [modalOrderShowing, setModalOrderShowing] = useState(false);
  const { createOrderParams } = usePayment();
  const router = useRouter();
  const [creatingOrder, setCreatingOrder] = useState(false);

  useEffect(() => {
    const el = document.getElementById('stepHeaderId');
    if (el) {
      setStepHeaderHeight(el.offsetHeight);
      setStepHeaderWidth(el.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (currentStep < 3) {
      dispatch(updateReadyToPurchaseOrder(false));
    } else {
      dispatch(updateReadyToPurchaseOrder(true));
    }
  }, [currentStep]);

  // ---- methods ----
  async function handlePayment() {
    // console.log('handlePayment');
    // console.log({ createOrderParams });
    // goi api tao don hang
    setCreatingOrder(true);
    const res = await PaymentHelper.callApiCreateOrder(createOrderParams);
    if (res) {
      ToastHelper.showSuccess(t('CreateOrderSuccess'));
      if (res?.typePayment === AppData.paymentType.vnpay) {
        const vnpayUrl = res?.vnpayUrl;
        Utils.openInCurrentTab(vnpayUrl);
        router.navigate('/store/vnpay-return');
      } else {
        router.navigate('/store/shopping-cart/order-success');
      }
    }
    setCreatingOrder(false);
  }

  return (
    <div className="min-vh-100 d-flex flex-column">
      {/* header */}
      <HeaderStore additionalClassName="bg-white" />

      {/* body */}
      <div
        className="pt-10 position-relative"
        style={{
          background: 'linear-gradient(133deg, #D7E4F7 0%, #EDF0FA 46.35%, #BDC7EC 100%)',
          paddingBottom: `${stepHeaderHeight * 0.6}px`,
        }}
      >
        <div className="container-xl flex-grow-1 my-5" style={{}}>
          <h1 className="card-label font-weight-boldest text-center">Xác nhận thanh toán</h1>
          <h6 className="text-center font-weight-boldest text-dark-50">
            Vui lòng điền chính xác những thông tin dưới đây để hoàn thành mua hàng
          </h6>
        </div>
        {/* step header */}
        <div
          className="bg-white shadow p-4 position-absolute w-auto d-flex align-items-center gap-3"
          id="stepHeaderId"
          style={{
            borderRadius: '30px',
            bottom: `-${stepHeaderHeight * 0.5}px`,
            left: `calc(50vw - ${stepHeaderWidth * 0.5}px)`,
          }}
        >
          {/* thong tin doi tuong su dung */}
          <div
            className="d-flex align-items-center gap-2 p-2"
            style={{
              backgroundColor: currentStep >= 1 ? '#5180FB' : '#F8F8F8',
              borderRadius: '16px',
            }}
          >
            <div
              className="w-40px h-40px d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: currentStep >= 1 ? 'white' : '#E8E9EB',
                borderRadius: '12px',
              }}
            >
              <i
                className={`fa-regular fa-user-circle ${
                  currentStep >= 1 ? 'text-primary' : 'text-grey'
                }`}
                style={{
                  fontSize: '24px',
                }}
              />
            </div>
            <span
              className={`pr-2 font-weight-bold ${currentStep >= 1 ? 'text-white' : 'text-grey'}`}
            >
              1. Thông tin đối tượng sử dụng
            </span>
          </div>

          <i
            className={`fa-regular fa-arrow-right-long ${
              currentStep >= 2 ? 'text-primary' : 'text-grey'
            }`}
          />

          {/* thong tin cap hoa don tai chinh */}
          <div
            className="d-flex align-items-center gap-2 p-2"
            style={{
              backgroundColor: currentStep >= 2 ? '#5180FB' : '#F8F8F8',
              borderRadius: '16px',
            }}
          >
            <div
              className="w-40px h-40px d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: currentStep >= 2 ? 'white' : '#E8E9EB',
                borderRadius: '12px',
              }}
            >
              <i
                className={`fa-regular fa-file-invoice-dollar ${
                  currentStep >= 2 ? 'text-primary' : 'text-grey'
                }`}
                style={{
                  fontSize: '24px',
                }}
              />
            </div>
            <span
              className={`pr-2 font-weight-bold ${currentStep >= 2 ? 'text-white' : 'text-grey'}`}
            >
              2. Thông tin cấp hoá đơn tài chính
            </span>
          </div>

          <i
            className={`fa-regular fa-arrow-right-long ${
              currentStep >= 3 ? 'text-primary' : 'text-grey'
            }`}
          />

          {/* thong tin thanh toan */}
          <div
            className="d-flex align-items-center gap-2 p-2"
            style={{
              backgroundColor: currentStep >= 3 ? '#5180FB' : '#F8F8F8',
              borderRadius: '16px',
            }}
          >
            <div
              className="w-40px h-40px d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: currentStep >= 3 ? 'white' : '#E8E9EB',
                borderRadius: '12px',
              }}
            >
              <i
                className={`fa-regular fa-money-check-dollar-pen ${
                  currentStep >= 3 ? 'text-primary' : 'text-grey'
                }`}
                style={{
                  fontSize: '24px',
                }}
              />
            </div>
            <span
              className={`pr-2 font-weight-bold ${currentStep >= 3 ? 'text-white' : 'text-grey'}`}
            >
              3. Thông tin thanh toán
            </span>
          </div>
        </div>
      </div>

      <div
        className="container-xl flex-grow-1 pb-10"
        style={{
          paddingTop: `${stepHeaderHeight * 0.6}px`,
        }}
      >
        <div className="row mt-6">
          <div className="col-8">
            <CartConfirmPaymentInfo
              step={currentStep}
              onStepDone={(step) => {
                setCurrentStep(currentStep + 1);
              }}
              onEditStep1={() => {
                setCurrentStep(1);
              }}
              onEditStep2={() => {
                setCurrentStep(2);
              }}
              onPayment={handlePayment}
              creatingOrder={creatingOrder}
            />
          </div>

          {/* don hang */}
          <div className="col-4">
            <CartConfirmPaymentOrder
              onPressEditOrder={() => {
                dispatch(copyFromCartToOrder());
                setModalOrderShowing(true);
              }}
              onPayment={handlePayment}
              creatingOrder={creatingOrder}
            />
          </div>
        </div>
      </div>

      {/* footer */}
      <LandingPageFooter />

      {/* modal order */}
      <VIModal
        titleModal={t('Order')}
        modalSize="lg"
        show={modalOrderShowing}
        contentModal={
          <ViewOrder
            onPressCancel={() => {
              setModalOrderShowing(false);
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalOrderShowing(false);
          dispatch(removeOrderData());
        }}
      />
    </div>
  );
}

export default ConfirmPaymentScreen;
