import 'assets/styles/keen/theme01/layout/header/base/light.css';
import 'assets/styles/keen/theme01/layout/header/menu/light.css';
import NotificationDetailView from 'features/Notification/components/NotificationDetailView';
import DropdownHeaderAccountInfo from 'features/Others/components/DropdownHeaderAccountInfo';
import DropdownHeaderChooseCompany from 'features/Others/components/DropdownHeaderChooseCompany';
import DropdownHeaderHelpMenuInfo from 'features/Others/components/DropdownHeaderHelpMenuInfo';
import DropdownHeaderMenuInfo from 'features/Others/components/DropdownHeaderMenuInfo';
import DropdownHeaderNotification from 'features/Others/components/DropdownHeaderNotification';
import ModalHeaderProcedureInfo from 'features/Others/components/ModalHeaderProcedureInfo';
import { AnimatePresence, motion } from 'framer-motion';
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTTooltip, { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip';
import VIModal from 'general/components/VietInvoice/VIModal';
import AppData from 'general/constants/AppData';
import AccountHelper from 'general/helpers/AccountHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useConfig from 'hooks/useConfig';
import useNotification from 'hooks/useNotification';
import usePayment from 'hooks/usePayment';
import useRouter from 'hooks/useRouter';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import ModalSignContract from 'general/components/ModalSignContract';
import { thunkGetProfile } from 'features/Auth/authSlice';
const menuItems = [];

function KT01Header(props) {
  const { t } = useTranslation();
  const [modalProcedureShowing, setModalProcedureShowing] = useState(false);
  const [showDropdown, setShowDropdown] = useState();
  const [currentCompany, setCurrentCompany] = useState();
  const router = useRouter();
  const account = useAccount();
  const { organizations } = useSelector((state) => state?.organization);
  const [modalNotificationDetailShowing, setModalNotificationDetailShowing] = useState(false);
  const [currentSelectedNotification, setCurrentSelectedNotification] = useState(null);
  const notification = useNotification();
  const { shoppingCartCount } = usePayment();
  const dispatch = useDispatch();
  const bannerHeight = useConfig().systemNotification.bannerHeight;
  const accounts = useSelector((state) => state?.auth?.account);

  // MARK: --- Hooks ---
  useEffect(() => {
    new KTOffcanvas('kt_header_menu_wrapper', {
      baseClass: 'header-menu-wrapper',
      overlay: true,
      // closeBy: '',
      toggleBy: {
        target: 'kt_header_mobile_toggle',
      },
    });

    if (KTMenu !== undefined) {
      new KTMenu('kt_header_menu', {
        submenu: {
          desktop: 'dropdown',
          tablet: 'accordion',
          mobile: 'accordion',
        },
      });
    }
  }, []);

  useEffect(() => {
    const { organizationId, organizationDepartmentId } = account;
    if (organizationDepartmentId) {
      setCurrentCompany(
        Utils.getAllNodes(organizations).find(
          (item) => item.organizationDepartmentId === organizationDepartmentId
        )
      );
    } else {
      setCurrentCompany(
        Utils.getAllNodes(organizations).find(
          (item) => item.organizationId === organizationId && !organizationDepartmentId
        )
      );
    }
  }, [account, organizations]);

  // ----- Methods -----
  function handleShowProcedure() {
    setModalProcedureShowing(true);
  }

  function handleShowNotificationDetail(notification) {
    if (notification?.type === AppData.notificationType.system) {
      setCurrentSelectedNotification(notification);
      setModalNotificationDetailShowing(true);
    }
  }
  const [showModalSign, setShowModalSign] = useState(false);
  console.log('axxx', accounts);
  return (
    <div
      id="kt_header"
      className="header header-fixed d-none d-lg-flex"
      style={{
        marginTop: `${bannerHeight}px`,
      }}
    >
      <div className="w-100 d-flex align-items-center">
        {/* <NotificationMarqueeView /> */}
        <div className="w-100">
          <div className="container-fluid d-flex align-items-stretch justify-content-between">
            {/* Header menu wrapper */}
            <div
              id="kt_header_menu_wrapper"
              className="header-menu-wrapper header-menu-wrapper-left d-none"
            >
              {/* header menu */}
              <div
                id="kt_header_menu"
                className="header-menu header-menu-mobile header-menu-layout-default"
              >
                {/* menu nav */}
                <ul className="menu-nav">
                  {menuItems.map((item, index) => {
                    const hasSubMenuLV1Items = item?.subMenuItems !== undefined;

                    return (
                      <li
                        key={index}
                        className="menu-item menu-item-submenu menu-item-rel menu-item-open-dropdown"
                        data-menu-toggle="hover"
                      >
                        <a href="#" className="menu-link menu-toggle">
                          <span className="menu-text">{item.text}</span>
                          <i className="menu-arrow" />
                        </a>
                        {/* Sub menu items level 1 */}
                        {hasSubMenuLV1Items && (
                          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                            <ul className="menu-subnav">
                              {item?.subMenuItems?.map((subItemLV1, subIndexLV1) => {
                                const hasSubMenuLV2Items = subItemLV1?.subMenuItems !== undefined;

                                return (
                                  <li
                                    key={subIndexLV1}
                                    className={`menu-item ${
                                      hasSubMenuLV2Items && 'menu-item-submenu'
                                    }`}
                                    data-menu-toggle="hover"
                                  >
                                    <a href="#" className="menu-link menu-toggle">
                                      {subItemLV1.icon ? (
                                        <span className="svg-icon menu-icon">
                                          <img
                                            alt=""
                                            src={subItemLV1.icon}
                                            className="w-20px h-20px"
                                          />
                                        </span>
                                      ) : (
                                        <i className="menu-bullet menu-bullet-dot">
                                          <span></span>
                                        </i>
                                      )}
                                      <span className="menu-text">{subItemLV1.text}</span>
                                      {subItemLV1?.label && (
                                        <span className="menu-label">
                                          <span className="label label-success label-rounded">
                                            {subItemLV1.label}
                                          </span>
                                        </span>
                                      )}
                                      {hasSubMenuLV2Items && <i className="menu-arrow" />}
                                    </a>
                                    {/* Sub menu items level 2 */}
                                    {hasSubMenuLV2Items && (
                                      <div className="menu-submenu menu-submenu-classic menu-submenu-right">
                                        <ul className="menu-subnav">
                                          {subItemLV1?.subMenuItems?.map(
                                            (subItemLV2, subIndexLV2) => {
                                              const hasSubMenuLV3Items =
                                                subItemLV2?.subMenuItems !== undefined;

                                              return (
                                                <li
                                                  key={subIndexLV2}
                                                  className={`menu-item ${
                                                    hasSubMenuLV3Items && 'menu-item-submenu'
                                                  }`}
                                                  data-menu-toggle="hover"
                                                >
                                                  <a href="#" className="menu-link menu-toggle">
                                                    {subItemLV2.icon ? (
                                                      <span className="svg-icon menu-icon">
                                                        <img
                                                          alt=""
                                                          src={subItemLV2.icon}
                                                          className="w-20px h-20px"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <i className="menu-bullet menu-bullet-line">
                                                        <span></span>
                                                      </i>
                                                    )}
                                                    <span className="menu-text">
                                                      {subItemLV2.text}
                                                    </span>
                                                    {subItemLV2?.label && (
                                                      <span className="menu-label">
                                                        <span className="label label-success label-rounded">
                                                          {subItemLV2.label}
                                                        </span>
                                                      </span>
                                                    )}
                                                    {hasSubMenuLV3Items && (
                                                      <i className="menu-arrow" />
                                                    )}
                                                  </a>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            {/* dropdown choose company */}
            <div>
              <AnimatePresence>
                {/* {account.organizationName && ( */}
                <motion.div
                  className="d-flex align-items-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <KTBSDropdown
                    contentEl={
                      <DropdownHeaderChooseCompany
                        currentItem={currentCompany}
                        tree={organizations}
                        onChange={(item) => {
                          setCurrentCompany(item);
                          AccountHelper.callApiChangeOrganization(
                            item?.organizationDepartmentId ? null : item?.organizationId,
                            item?.organizationDepartmentId ? item?.organizationDepartmentId : null
                          );
                        }}
                      />
                    }
                    toggleElement={
                      <div className="border bg-white rounded-4 px-4 py-2 d-flex align-items-center cursor-pointer hover-opacity-60">
                        <div className="mr-4">
                          {account.organization ? (
                            <>
                              <p style={{ color: '#16161E' }} className="font-weight-bolder m-0">
                                {account.organizationName}&nbsp;
                              </p>
                              <div className="m-0 d-flex">
                                {t('EmailReceiveInvoice')}:{' '}
                                <KTTooltip
                                  text={'Nhấn để sao chép'}
                                  position={KTTooltipPositions.auto}
                                >
                                  <a
                                    className="text-primary"
                                    onClick={() => {
                                      Utils.copyTextToClipboard(
                                        account.organizationEmailReceiveInvoice
                                      );
                                      ToastHelper.showSuccess('Sao chép email thành công');
                                    }}
                                  >
                                    {account.organizationEmailReceiveInvoice}&nbsp;
                                  </a>
                                </KTTooltip>
                              </div>
                            </>
                          ) : (
                            <p className="mb-0 font-weight-bolder text-grey font-italic">
                              Bạn chưa chọn cơ cấu tổ chức
                            </p>
                          )}
                        </div>
                        <div className="px-3 py-1 rounded" style={{ backgroundColor: '#F8F8F8' }}>
                          <i
                            style={{ color: '#7177A8' }}
                            className="fa-solid fa-caret-down fa-2x"
                          ></i>
                        </div>
                      </div>
                    }
                    alignment={KTBSDropdownAlignments.start}
                    // autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                  />
                </motion.div>
                {/* )} */}
              </AnimatePresence>
            </div>

            {/* Top bar */}
            <div className="topbar">
              {/* <div className="topbar-item">
                <KTTooltip text={t('Procedure')} position={KTTooltipPositions.auto}>
                  <div className="btn btn-icon btn-clean btn-lg mr-1" onClick={handleShowProcedure}>
                    <i className="fa-regular fa-lightbulb-on" />
                  </div>
                </KTTooltip>
              </div> */}
              {/* gio hang */}
              {!accounts?.Company?.daKyHopDongNCC && (
                <div className="topbar-item">
                  <KTTooltip
                    text={t('Vui lòng kí hợp đồng để sử dụng dịch vụ của ICORP')}
                    position={KTTooltipPositions.auto}
                  >
                    <div
                      className="btn btn-icon btn-clean btn-lg mr-1"
                      onClick={() => {
                        setShowModalSign(true);
                      }}
                    >
                      <i className="far fa-exclamation-triangle" style={{ color: 'red' }} />
                    </div>
                  </KTTooltip>
                </div>
              )}
              {shoppingCartCount > 0 && (
                <div className="topbar-item">
                  <div
                    className="btn btn-icon btn-clean btn-lg mr-1 position-relative"
                    onClick={() => {
                      router.navigate('/store/shopping-cart');
                    }}
                  >
                    <i className="fa-regular fa-shopping-cart" />
                    <span
                      className="position-absolute bg-danger text-white rounded"
                      style={{
                        top: '6px',
                        right: '8px',
                        fontSize: '0.8rem',
                        padding: '0 4px',
                      }}
                    >
                      {shoppingCartCount}
                    </span>
                  </div>
                </div>
              )}
              {/* thong bao */}

              <KTBSDropdown
                contentEl={
                  <div>
                    <DropdownHeaderNotification
                      showDropdown={showDropdown}
                      onPressNotification={handleShowNotificationDetail}
                    />
                  </div>
                }
                dropdownMenuClassName="DropdownHeaderNotificationMenu"
                toggleElement={
                  <div className="topbar-item">
                    <KTTooltip text={t('Notification')} position={KTTooltipPositions.auto}>
                      <div
                        className="btn btn-icon btn-clean btn-lg mr-1 position-relative"
                        onClick={() => setShowDropdown(Math.random())}
                      >
                        <i className="fa-regular fa-bell" />
                        {notification.unreadCount > 0 && (
                          <span
                            className="position-absolute bg-danger text-white rounded"
                            style={{
                              top: '6px',
                              right: '8px',
                              fontSize: '0.8rem',
                              padding: '0 4px',
                            }}
                          >
                            {notification.unreadCount}
                          </span>
                        )}
                      </div>
                    </KTTooltip>
                  </div>
                }
                alignment={KTBSDropdownAlignments.end}
                // autoCloseBehavior={KTBSDropdowAutoCloseBehavior.false}
                autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
              />
              <div className="topbar-item">
                <KTTooltip text={t('FAQ')} position={KTTooltipPositions.auto}>
                  <div
                    className="btn btn-icon btn-clean btn-lg mr-1"
                    onClick={() => {
                      router.navigate('/inbot/faq');
                    }}
                  >
                    <i className="fa-regular fa-circle-question" />
                  </div>
                </KTTooltip>
              </div>
              <KTBSDropdown
                contentEl={<DropdownHeaderHelpMenuInfo />}
                toggleElement={
                  <div className="topbar-item">
                    <KTTooltip text={t('UserManual')} position={KTTooltipPositions.auto}>
                      <div className="btn btn-icon btn-clean btn-lg mr-1">
                        <i className="fa-regular fa-comment-dots" />
                      </div>
                    </KTTooltip>
                  </div>
                }
                alignment={KTBSDropdownAlignments.end}
                // autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
              />
              <KTBSDropdown
                contentEl={<DropdownHeaderMenuInfo />}
                toggleElement={
                  <div className="topbar-item">
                    <KTTooltip text={t('System')} position={KTTooltipPositions.auto}>
                      <div className="btn btn-icon btn-clean btn-lg mr-1">
                        <i className="fa-regular fa-grid-2" />
                      </div>
                    </KTTooltip>
                  </div>
                }
                alignment={KTBSDropdownAlignments.end}
                // autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
              />
              <KTBSDropdown
                contentEl={<DropdownHeaderAccountInfo />}
                toggleElement={
                  <div className="topbar-item ml-2">
                    <KTTooltip text={account.displayName} position={KTTooltipPositions.auto}>
                      <div className="btn btn-icon btn-clean d-flex align-items-center btn-lg">
                        <div className="symbol symbol-circle symbol-35 bg-white">
                          <div className="symbol-label bg-white border rounded overflow-hidden">
                            <img
                              alt="avatar"
                              src={account.avatar}
                              className="w-35px h-35px"
                              style={{
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                          {account?.isOnline && (
                            <i
                              className="symbol-badge"
                              style={{
                                backgroundColor: 'rgb(58, 206, 90)',
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </KTTooltip>
                  </div>
                }
                alignment={KTBSDropdownAlignments.end}
                // autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Modal procedure */}
      <ModalHeaderProcedureInfo
        show={modalProcedureShowing}
        onClose={() => {
          setModalProcedureShowing(false);
        }}
      />

      {/* modal notification detail */}
      <VIModal
        closeBtn
        modalSize="lg"
        contentModal={<NotificationDetailView item={currentSelectedNotification} />}
        onHide={() => {
          setModalNotificationDetailShowing(false);
          setCurrentSelectedNotification(null);
        }}
        show={modalNotificationDetailShowing}
        titleModal={currentSelectedNotification?.title ?? t('NotificationDetail')}
        buttonNegative={t('Close')}
      />
      <ModalSignContract
        show={showModalSign}
        onClose={() => {
          setShowModalSign(false);
          dispatch(thunkGetProfile());
        }}
        contractLink={accounts?.Company?.linkKyHopDong}
      />
    </div>
  );
}

export default KT01Header;
