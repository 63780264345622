import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import KTBSButton from 'general/components/OtherKeenComponents/KTBSButton';
import AppResource from 'general/constants/AppResource';

ModalHeaderProcedureInfo.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onExistDone: PropTypes.func,
};

ModalHeaderProcedureInfo.defaultProps = {
  show: false,
  onClose: null,
  onExistDone: null,
};

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * onExistDone: function,
 * }} props
 * @returns
 */
function ModalHeaderProcedureInfo(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { show, onClose, onExistDone } = props;

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  function handleExistDone() {
    if (onExistDone) {
      onExistDone();
    }
  }

  return (
    <div>
      <Modal
        className=""
        show={show}
        backdrop="static"
        onHide={handleClose}
        centered
        size="xl"
        onExit={() => {}}
        onExited={() => {
          handleExistDone();
        }}
      >
        <Modal.Header className="px-5 py-5">
          <Modal.Title>Hướng dẫn xử lý hoá đơn chỉ với 3 bước</Modal.Title>
          <div
            className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            <i className="far fa-times"></i>
          </div>
        </Modal.Header>
        <Modal.Body
          className="overflow-auto p-0"
          style={{
            maxHeight: '70vh',
          }}
        >
          <div className="w-100">
            <img
              src={AppResource.images.imgMisaProcedure}
              className="w-100"
              style={{
                objectFit: 'contain',
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex w-100 align-items-center justify-content-center">
            <KTBSButton title={t('StartUsing')} onPress={handleClose} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalHeaderProcedureInfo;
