import usePayment from 'hooks/usePayment';
import { Navigate } from 'react-router-dom';

function StoreRoute(props) {
  // MARK: --- Params ---
  const { canShoppingCart } = usePayment();

  return canShoppingCart ? props.children : <Navigate to="/store" />;
}

export default StoreRoute;
