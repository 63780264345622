import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import './style.scss';

VIFormCheck.propTypes = {
  id: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  checkedItem: PropTypes.bool,
  name: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

VIFormCheck.defaultProps = {
  id: null,
  name: null,
  label: null,
  type: 'checkbox',
  handleChange: null,
  disabled: false,
  className: '',
  checkedItem: false,
};

/**
 * @param {string} id formCheck id
 * @param {string} name formCheck name
 * @param {string} type formCheck cell type
 * @param {function} handleChange Handle when click button
 * @param {boolean} disabled
 *
 */

function VIFormCheck(props) {
  const { name, id, disabled, handleChange, type, label, className, checkedItem } = props;
  const [itemChecked, setItemChecked] = useState(checkedItem);

  useEffect(() => {
    setItemChecked(checkedItem);
  }, [checkedItem]);

  return (
    <div className={`Vi-form-check ${className}`}>
      <Form.Check
        disabled={disabled}
        type={type}
        // defaultChecked={itemChecked}
        checked={itemChecked}
        className="check cursor-pointer"
        label={
          <div className="cursor-pointer label-checkbox">{type === 'checkbox' ? name : label}</div>
        }
        id={id}
        name={name}
        onChange={(e) => {
          handleChange(e, name, id), setItemChecked(e?.target.checked);
        }}
      />
    </div>
  );
}

export default VIFormCheck;
