import VIButton from 'general/components/VietInvoice/VIButton';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';
const parse = require('html-react-parser');

ServicePackCard.propTypes = {
  packageCode: PropTypes.string,
  packageCost: PropTypes.number,
  onClick: () => {},
  description: PropTypes.string,
  recommend: PropTypes.bool,
  unit: PropTypes.string,
};

ServicePackCard.defaultProps = {
  packageCode: '',
  packageCost: 0,
  onClick: () => {},
  description: '',
  recommend: false,
  unit: '',
};

/**
 * @param {string} packageCode package name
 * @param {number} packageCost package price
 * @param {string} description package description
 * @param {function} onClick Handle when click button "Buy now"
 * @param {boolean} recommend
 */

function ServicePackCard(props) {
  const { packageCode, packageCost, description, onClick, recommend, unit } = props;
  const { t } = useTranslation();

  return (
    <div
      className="ServicePackCard d-flex justify-content-between flex-column h-100 bg-white px-5 pb-12 pt-18 cursor-pointer position-relative"
      style={{
        borderRadius: '16px',
        // boxShadow: '0px 8px 24px 0px rgba(37, 42, 91, 0.05)',
        border: '1px solid #DDE5EE',
      }}
    >
      <div>
        <h2 className="text-center font-weight-boldest">{packageCode}</h2>
        <p className="medium-title display2-xxl  display5-sm display3 text-primary text-center mb-0">
          {Utils.formatNumber(packageCost)}
        </p>
        <p className="text-center font-weight-boldest" style={{ color: '#1C2256' }}>
          {`(${unit}/${t('Package').toLowerCase()})`}
        </p>
        <div className="mb-3 font-size-lg">{parse(description)}</div>
      </div>

      <VIButton
        className={`btn-white w-100 font-size-h5 text-primary`}
        text={t('BuyNow')}
        onClick={onClick}
      />

      {recommend && (
        <div className="recommend-label mx-auto d-flex gap-2 justify-content-center align-items-center w-75 w-md-50 px-2 py-3">
          <i className="fa-solid fa-star text-white"></i>
          <p className="font-weight-boldest text-white mb-0">{t('PackageRecommend')}</p>
        </div>
      )}
    </div>
  );
}

export default ServicePackCard;
