import AppDimension from 'general/constants/AppDimension';
import Utils from 'general/utils/Utils';
import useConfig from 'hooks/useConfig';
import Marquee from 'react-fast-marquee';

NotificationMarqueeView.propTypes = {};

function NotificationMarqueeView(props) {
  const appConfig = useConfig();
  const notifications = [
    {
      title: appConfig.systemNotification.text,
      url: appConfig.systemNotification.url,
    },
  ];
  const bannerHeight = useConfig().systemNotification.bannerHeight;

  return (
    <div
      className="d-flex align-items-center px-4"
      style={{
        height: `${bannerHeight}px`,
        backgroundColor: '#ff0',
      }}
    >
      <Marquee pauseOnHover speed={50}>
        {notifications?.map((item, index) => (
          <div
            key={index}
            className="mx-4 font-size-normal font-weight-bold text-danger cursor-pointer"
            onClick={() => {
              Utils.openInNewTab(item?.url);
            }}
          >
            {item?.title}
          </div>
        ))}
      </Marquee>
    </div>
  );
}

export default NotificationMarqueeView;
