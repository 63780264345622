import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FastField, Formik } from 'formik';
import * as Yup from 'yup';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import AppConfigs from 'general/constants/AppConfigs';
import VIButton from 'general/components/VietInvoice/VIButton';
import OrganizationHelper from 'general/helpers/OrganizationHelper';
import useConfig from 'hooks/useConfig';
import Global from 'general/utils/Global';

ModalAddDepartmentContent.propTypes = {
  onPressCancel: PropTypes.func,
  parentBranch: PropTypes.object,
  department: PropTypes.object,
};

ModalAddDepartmentContent.defaultProps = {
  onPressCancel: null,
  parentBranch: null,
  department: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * parentBranch: object,
 * department: object,
 * }} props
 * @returns
 */
function ModalAddDepartmentContent(props) {
  // ---- params -----
  const { onPressCancel, parentBranch, department } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEditMode = !_.isNull(department);
  const appConfig = useConfig();

  // ---- methods -----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  return (
    <div
      className=""
      style={{
        maxHeight: '70vh',
      }}
    >
      <Formik
        initialValues={{
          emailReceiveInvoice: isEditMode
            ? _.replace(department?.invoiceMailbox ?? '', appConfig.mailServerDomain, '')
            : '',
          departmentName: isEditMode ? department?.departmentName ?? '' : '',

          representativeName: isEditMode
            ? department?.representativeName
            : Global.gTempOrganizationContactInfo?.name ?? '',
          representativeJobTitle: isEditMode
            ? department?.representativeJobTitle
            : Global.gTempOrganizationContactInfo?.jobTitle ?? '',
          representativeEmail: isEditMode
            ? department?.representativeEmail
            : Global.gTempOrganizationContactInfo?.email ?? '',
          representativePhone: isEditMode
            ? department?.representativePhone
            : Global.gTempOrganizationContactInfo?.phone ?? '',
        }}
        validationSchema={Yup.object({
          emailReceiveInvoice: Yup.string().required(t('Required')),
          departmentName: Yup.string().required(t('Required')),

          representativeEmail: Yup.string().nullable().email(t('EmailInvalid')),
          representativePhone: Yup.string()
            .nullable()
            .test('len', t('PhoneInvalid', { field: t('phone') }), (val) => {
              if (val) {
                return !val.toString().includes(' ') && val.toString().match(AppConfigs.regexPhone);
              }
              return true;
            }),
        })}
        enableReinitialize
        onSubmit={(values) => {
          const params = {
            departmentName: values.departmentName,
            invoiceMailbox: values.emailReceiveInvoice + appConfig.mailServerDomain,

            representativeName: values.representativeName,
            representativeJobTitle: values.representativeJobTitle,
            representativePhone: values.representativePhone,
            representativeEmail: values.representativeEmail,
          };
          let res = false;
          if (!isEditMode) {
            params['organizationId'] = parentBranch?.organizationId;
            res = OrganizationHelper.callApiCreateDepartment(params);
          } else {
            res = OrganizationHelper.callApiUpdateDepartment(
              department?.organizationDepartmentId,
              params
            );
          }
          if (res) {
            handlePressCancel();
          }
        }}
      >
        {(formikProps) => (
          <div className="">
            <div className="row">
              {/* email nhan hoa don */}
              <div className="col-lg-6">
                <KTFormGroup
                  label={
                    <>
                      {t('CreateEmailToReceiveInvoice')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="emailReceiveInvoice"
                  inputElement={
                    <FastField name="emailReceiveInvoice">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableInputGroup
                          disabled={isEditMode}
                          appendElements={[appConfig.mailServerDomain]}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('EmailReceiveInvoice'))}`}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {/* ten phong ban */}
              <div className="col-lg-6">
                <KTFormGroup
                  label={
                    <>
                      {t('DepartmentName')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="departmentName"
                  inputElement={
                    <FastField name="departmentName">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('DepartmentName'))}`}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {/* thong tin lien he */}
              <div className="row">
                {/* ten nguoi lien he */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactName')}</>}
                    inputName="representativeName"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativeName">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.name = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('ContactName'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* chuc vu */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactPosition')}</>}
                    inputName="representativeJobTitle"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativeJobTitle">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.jobTitle = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('ContactPosition'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* email */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactEmail')}</>}
                    inputName="representativeEmail"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativeEmail">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.email = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.email}
                            placeholder={`${_.capitalize(t('ContactEmail'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* so dien thoai */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactPhone')}</>}
                    inputName="representativePhone"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativePhone">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.phone = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('ContactPhone'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>
              </div>
            </div>

            {/* actions */}
            <div className="d-flex align-items-center justify-content-end gap-3">
              <VIButton
                text={t('Cancel')}
                className="btn-grey"
                style={{ padding: '8px' }}
                onClick={handlePressCancel}
              />
              <VIButton
                text={t('Save')}
                className=""
                style={{ padding: '8px' }}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ModalAddDepartmentContent;
