import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTCheckbox, {
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIOverViewBar from 'general/components/VietInvoice/VIOverViewBar';
import SystemHelper from 'general/helpers/SystemHelper';
import usePermissionCode from 'hooks/usePermissionCode';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

AddRoleView.propTypes = {
  onPressCancel: PropTypes.func,
  item: PropTypes.object,
};

AddRoleView.defaultProps = {
  onPressCancel: null,
  item: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * item: object,
 * }} props
 * @returns
 */
function AddRoleView(props) {
  // ---- params -----
  const { t } = useTranslation();
  const { onPressCancel, item } = props;
  const isEditMode = !_.isNull(item);
  const { groupedPermissionCodes, allPermissionCodes } = usePermissionCode();
  const [permissionCodeStates, setPermissionCodeStates] = useState({});
  // console.log({ groupedPermissionCodes, allPermissionCodes });

  useEffect(() => {
    const itemPermissionCodes = item?.permissionCodes ?? [];
    const states = {};
    _.forEach(allPermissionCodes, (item) => {
      const isOn = itemPermissionCodes.includes(item?.permissionCode);
      states[item?.permissionCode] = isOn;
    });
    setPermissionCodeStates(states);
  }, [allPermissionCodes, item]);

  // ---- methods -----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  return (
    <div className="">
      <Formik
        initialValues={{
          name: item?.name ?? '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required(t('Required')),
        })}
        enableReinitialize
        onSubmit={(values) => {
          const onPermissionCodes = _.pickBy(permissionCodeStates, (value, key) => {
            return value === true;
          });
          const params = {
            ...values,
            permissionCodes: Object.keys(onPermissionCodes),
          };
          let res = false;
          if (!isEditMode) {
            res = SystemHelper.callApiCreateRole(params);
          } else {
            res = SystemHelper.callApiUpdateRole(item?.roleId, params);
          }
          if (res) {
            handlePressCancel();
          }
        }}
      >
        {(formikProps) => (
          <div className="">
            <div
              className="mb-4 px-2"
              style={{
                maxHeight: '70vh',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <div className="row">
                <div className="col-12">
                  <KTFormGroup
                    label={
                      <>
                        {t('RoleName')} <span className="text-danger">(*)</span>
                      </>
                    }
                    inputName="name"
                    inputElement={
                      <FastField name="name">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('RoleName'))}`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>
              </div>

              {/* them quyen */}
              <div>
                <div className="separator separator-dashed mb-5"></div>
                <div className="">
                  <VIOverViewBar
                    itemTab={[
                      {
                        title: 'Thêm quyền',
                        status: 'active',
                      },
                    ]}
                    currentSelect={0}
                  />

                  {Object.keys(groupedPermissionCodes).map((key, indexKey) => {
                    const items = groupedPermissionCodes[key];
                    return (
                      <div className="mt-4" key={indexKey}>
                        <p className="mt-4 mb-0 font-size-lg font-weight-bolder text-grey">{key}</p>
                        <div className="row">
                          {items?.map((item, itemKey) => {
                            return (
                              <div className="col-6" key={`${indexKey}_${itemKey}`}>
                                <div className="checkbox-list mt-2">
                                  <KTCheckbox
                                    name={`switch_${item?.permissionCode}`}
                                    label={item?.name}
                                    type={KTCheckboxType.outline}
                                    checked={permissionCodeStates[item?.permissionCode]}
                                    onChange={(value) => {
                                      setPermissionCodeStates({
                                        ...permissionCodeStates,
                                        [item?.permissionCode]: value,
                                      });
                                    }}
                                    additionalClassName="switch-primary mr-4"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* actions */}
            <div className="d-flex align-items-center justify-content-end gap-3">
              <VIButton
                text={t('Cancel')}
                className="btn-grey"
                style={{ padding: '8px' }}
                onClick={handlePressCancel}
              />
              <VIButton
                text={t('Save')}
                className=""
                style={{ padding: '8px' }}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default AddRoleView;
