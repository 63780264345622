import VIFormCheck from 'general/components/VietInvoice/VIFormCheck';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import StringUtils from 'general/utils/StringUtils';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

function ModalLabeled(props) {
  const {
    setShowModalEditLabel,
    setCurrentChangeLabel,
    setTextLabel,
    handleDeleteLabel,
    listCurrentLabels,
    setListCurrentLabels,
    deleteLabel = false,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tagList } = useSelector((state) => state?.tags);
  const [NewTagList, setNewTagList] = useState(tagList);
  const [textSearch, setTextSearch] = useState();

  useEffect(() => {
    if (textSearch === '' || textSearch === undefined) {
      setNewTagList(tagList);
    } else {
      setNewTagList(
        tagList?.filter((item) =>
          StringUtils.removeVietnameseTones(item?.name).includes(
            StringUtils.removeVietnameseTones(textSearch).toLowerCase()
          )
        )
      );
    }
  }, [textSearch, tagList]);
  console.log('listCurrentLabels', listCurrentLabels);
  return (
    <div>
      {!deleteLabel && (
        <VISearchField
          iconPosition="right"
          typingTimeout={500}
          className="w-100 mb-3"
          placeholder={t('Search')}
          onSubmit={(e) => {
            setTextSearch(e);
          }}
          name=""
        />
      )}
      <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
        {deleteLabel ? (
          <>
            {listCurrentLabels?.map((item, index) => (
              <div key={index} className="d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <VIFormCheck
                    type="checkbox"
                    checkedItem={true}
                    // checkedItem={listCurrentLabels.some((x) => x?.tagId === item.tagId)}
                    name={
                      <div className="d-flex">
                        <i
                          className="fas fa-tag d-flex align-items-center ms-2 me-3 font-size-h3"
                          style={{ color: `${item?.color}` }}
                        ></i>
                        <p className="mb-0 d-flex align-items-center font-size-lg font-weight-bold">
                          {item?.name}
                        </p>
                      </div>
                    }
                    id={item?.name}
                    handleChange={(e) => {
                      if (e.target.checked === true) {
                        setListCurrentLabels([...listCurrentLabels, item]);
                      } else {
                        setListCurrentLabels(
                          listCurrentLabels?.filter((x) => x.tagId !== item.tagId)
                        );
                      }
                    }}
                  />
                </div>
                {!deleteLabel && (
                  <div>
                    <i
                      className="far fa-edit me-3 cursor-pointer"
                      onClick={() => {
                        setShowModalEditLabel(true), setCurrentChangeLabel(item);
                        setTextLabel('EditLabel');
                      }}
                      style={{ color: '#5180FB' }}
                    ></i>

                    <i
                      className="fa-regular fa-trash cursor-pointer"
                      onClick={() => {
                        handleDeleteLabel(item), setCurrentChangeLabel(item);
                      }}
                      style={{ color: '#E54135 ' }}
                    ></i>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <>
            {NewTagList?.map((item, index) => (
              <div key={index} className="d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <VIFormCheck
                    type="checkbox"
                    checkedItem={listCurrentLabels.some((x) => x?.tagId === item.tagId)}
                    name={
                      <div className="d-flex">
                        <i
                          className="fas fa-tag d-flex align-items-center ms-2 me-3 font-size-h3"
                          style={{ color: `${item?.color}` }}
                        ></i>
                        <p className="mb-0 d-flex align-items-center font-size-lg font-weight-bold">
                          {item?.name}
                        </p>
                      </div>
                    }
                    id={item?.name}
                    handleChange={(e) => {
                      if (e.target.checked === true) {
                        setListCurrentLabels([...listCurrentLabels, item]);
                      } else {
                        setListCurrentLabels(
                          listCurrentLabels?.filter((x) => x.tagId !== item.tagId)
                        );
                      }
                    }}
                  />
                </div>
                {!deleteLabel && (
                  <div>
                    <i
                      className="far fa-edit me-3 cursor-pointer"
                      onClick={() => {
                        setShowModalEditLabel(true), setCurrentChangeLabel(item);
                        setTextLabel('EditLabel');
                      }}
                      style={{ color: '#5180FB' }}
                    ></i>

                    <i
                      className="fa-regular fa-trash cursor-pointer"
                      onClick={() => {
                        handleDeleteLabel(item), setCurrentChangeLabel(item);
                      }}
                      style={{ color: '#E54135 ' }}
                    ></i>
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default ModalLabeled;
