import { unwrapResult } from '@reduxjs/toolkit';
import store from 'app/store';
import {
  thunkConnectSupplier,
  thunkDisconnectSupplier,
  thunkGetAllSuppliers,
  thunkGetConnectionSupplierHistory,
  thunkGetConnectionSuppliers,
  thunkReload,
  thunkSyncSupplier,
  thunkUpdateConnectionSupplier,
} from 'features/Supplier/supplierSlice';

const SupplierHelper = {
  // goi api lay ds tat ca ncc
  callApiGetAllSuppliers: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetAllSuppliers()));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay danh sach tat ca ket noi voi ncc
  callApiGetAllConnectionSuppliers: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetConnectionSuppliers()));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api ket noi ncc
  callApiConnectSupplier: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkConnectSupplier(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api cap nhat ket noi ncc
  callApiUpdateConnectionSupplier: async (id, params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkUpdateConnectionSupplier({ id, params })));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api dong bo ncc
  callApiSyncSupplier: async (supplierId) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkSyncSupplier(supplierId)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api ngat ket noi ncc
  callApiDisconnectSupplier: async (ids) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkDisconnectSupplier(ids)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay lich su dong bo
  callApiGetConnectionSupplierHistory: async (params) => {
    const copiedParams = { ...params };
    delete copiedParams['dateRangeLabel'];
    try {
      const res = unwrapResult(
        await store.dispatch(thunkGetConnectionSupplierHistory(copiedParams))
      );
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api reload
  callApiReload: async (id) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkReload(id)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
};

export default SupplierHelper;
