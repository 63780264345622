import React from 'react';
import PropTypes from 'prop-types';
import VIButton from 'general/components/VietInvoice/VIButton';
import { useTranslation } from 'react-i18next';

ViewOutOfResource.propTypes = {
  onPressedExtend: PropTypes.func,
};

ViewOutOfResource.defaultProps = {
  onPressedExtend: null,
};

/**
 *
 * @param {{
 * onPressedExtend: function,
 * }} props
 * @returns
 */
function ViewOutOfResource(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { onPressedExtend } = props;

  // ---- methods ----
  function handleExtend() {
    if (onPressedExtend) {
      onPressedExtend();
    }
  }

  return (
    <div
      className="d-flex align-items-center gap-3 rounded p-4"
      style={{
        backgroundColor: '#ffe9e9',
        border: '2px solid red',
      }}
    >
      <div className="d-flex align-items-center gap-3 flex-grow-1">
        <i className="fa-regular fa-triangle-exclamation text-danger fa-2x" />
        <div>
          <h5 className="m-0 text-grey font-weight-bolder">Tài nguyên của bạn đã hết</h5>
          <p className="m-0">Bạn cần Gia hạn/Mua thêm tài nguyên để tiếp tục sử dụng phần mềm</p>
        </div>
      </div>
      <VIButton
        // beforeIcon={<i className="fa-regular fa-arrow-right-from-bracket" />}
        text={t('Extend')}
        className="btn-blue py-2"
        onClick={handleExtend}
      />
    </div>
  );
}

export default ViewOutOfResource;
