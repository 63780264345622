import categoryApi from 'api/categoryApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// lay danh sach ncc/khach hang
export const thunkGetListSupplierCustomers = createAsyncThunk(
  'system/getListSupplierCustomers',
  async (params, thunkAPI) => {
    const res = await categoryApi.getSupplierCustomerList(params);
    return res;
  }
);

// cap nhat thong tin ncc/khach hang
export const thunkUpdateSupplierCustomers = createAsyncThunk(
  'system/updateSupplierCustomers',
  async (params, thunkAPI) => {
    const res = await categoryApi.updateSupplierCustomerInfo(params.id, params.params);
    return res;
  }
);

// kiem tra thong tin ncc/khach hang
export const thunkCheckSupplierCustomers = createAsyncThunk(
  'system/checkSupplierCustomers',
  async (params, thunkAPI) => {
    const res = await categoryApi.check();
    return res;
  }
);

// tai len file import
export const thunkImportSupplierCustomers = createAsyncThunk(
  'system/importSupplierCustomers',
  async (file, thunkAPI) => {
    const res = await categoryApi.import(file);
    return res;
  }
);

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    total: 0,
    supplierAndCustomers: [],

    isGettingSupplierAndCustomers: false,
    isUpdatingSupplierCustomer: false,

    isChecking: false,

    isImporting: false,
  },
  reducers: {},
  extraReducers: {
    // lay danh sach ncc/khach hang
    [thunkGetListSupplierCustomers.pending]: (state, action) => {
      state.isGettingSupplierAndCustomers = true;
    },
    [thunkGetListSupplierCustomers.rejected]: (state, action) => {
      state.isGettingSupplierAndCustomers = false;
    },
    [thunkGetListSupplierCustomers.fulfilled]: (state, action) => {
      state.isGettingSupplierAndCustomers = false;
      const { result, partnerCompanies, total, count } = action.payload;
      if (result === 'success' && partnerCompanies) {
        state.supplierAndCustomers = partnerCompanies;
      }
      state.total = total ?? count ?? 0;
    },

    // cap nhat thong tin ncc/khach hang
    [thunkUpdateSupplierCustomers.pending]: (state, action) => {
      state.isUpdatingSupplierCustomer = true;
    },
    [thunkUpdateSupplierCustomers.rejected]: (state, action) => {
      state.isUpdatingSupplierCustomer = false;
    },
    [thunkUpdateSupplierCustomers.fulfilled]: (state, action) => {
      state.isUpdatingSupplierCustomer = false;
    },

    // kiem tra thong tin ncc/khach hang
    [thunkCheckSupplierCustomers.pending]: (state, action) => {
      state.isChecking = true;
    },
    [thunkCheckSupplierCustomers.rejected]: (state, action) => {
      state.isChecking = false;
    },
    [thunkCheckSupplierCustomers.fulfilled]: (state, action) => {
      state.isChecking = false;
    },

    // tai len file import
    [thunkImportSupplierCustomers.pending]: (state, action) => {
      state.isImporting = true;
    },
    [thunkImportSupplierCustomers.rejected]: (state, action) => {
      state.isImporting = false;
    },
    [thunkImportSupplierCustomers.fulfilled]: (state, action) => {
      state.isImporting = false;
    },
  },
});

const { reducer, actions } = categorySlice;
export const {} = actions;
export default reducer;
