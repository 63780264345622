import VIOverViewBar from 'general/components/VietInvoice/VIOverViewBar';
import React, { useState } from 'react';
import UnitInformation from './UnitInformation';
import ContactInfo from './ContactInfo';
import BillingInformation from './BillingInformation';

function SupplierInformation(props) {
  const [activeTab, setActiveTab] = useState(0);
  const headerTab = [
    {
      title: 'Thông tin đơn vị',
      status: 'active',
    },
    {
      title: 'Thông tin thanh toán',
      status: '',
    },
    {
      title: 'Thông tin liên hệ',
      status: '',
    },
  ];
  const handleClickOverviewBar = (x) => {
    setActiveTab(x);
  };

  return (
    <div>
      <VIOverViewBar itemTab={headerTab} handleClick={handleClickOverviewBar} />
      {activeTab == 0 && <UnitInformation />}
      {activeTab == 1 && <BillingInformation />}
      {activeTab == 2 && <ContactInfo />}
    </div>
  );
}

export default SupplierInformation;
