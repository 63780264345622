import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import usePayment from 'hooks/usePayment';
import VIButton from 'general/components/VietInvoice/VIButton';

ConfirmPaymentStep1Info.propTypes = {
  onPressEdit: PropTypes.func,
};

ConfirmPaymentStep1Info.defaultProps = {
  onPressEdit: null,
};

/**
 *
 * @param {{
 * onPressEdit: function,
 * }} props
 * @returns
 */
function ConfirmPaymentStep1Info(props) {
  // ---- params ----
  const { onPressEdit } = props;
  const { t } = useTranslation();
  const { confirmPaymentInfo } = usePayment();

  // ---- methods ----
  function handleEdit() {
    if (onPressEdit) {
      onPressEdit();
    }
  }

  return (
    <div
      className="bg-white p-6"
      style={{
        borderRadius: '16px',
        border: '1px solid #E8E9EB',
      }}
    >
      <div className="">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <i
              className="fa-regular fa-user-circle text-primary"
              style={{
                fontSize: '24px',
              }}
            />
            <span className="font-weight-bolder text-grey font-size-lg">
              Thông tin đối tượng sử dụng
            </span>
          </div>
          <VIButton
            text={t('Edit')}
            className="btn-grey"
            style={{ padding: '8px' }}
            onClick={handleEdit}
            beforeIcon={<i className="fa-regular fa-edit" />}
          />
        </div>

        <div className="d-flex mt-3">
          <div className="mr-4 d-flex flex-column gap-2">
            <p className="m-0">{t('CompanyTaxCode')}:</p>
            <p className="m-0">{t('CompanyName')}:</p>
            <p className="m-0">{t('CompanyAddress')}:</p>
          </div>
          <div className="d-flex flex-column gap-2">
            <p className="font-weight-bold m-0">{confirmPaymentInfo.companyTaxCode}&nbsp;</p>
            <p className="font-weight-bold m-0">{confirmPaymentInfo.companyName}&nbsp;</p>
            <p className="font-weight-bold m-0">{confirmPaymentInfo.companyAddress}&nbsp;</p>
          </div>
        </div>

        <div className="d-flex mt-3">
          <div className="mr-4 d-flex flex-column gap-2">
            <p className="m-0">{t('BuyerName')}:</p>
            <p className="m-0">{t('BuyerPhone')}:</p>
            <p className="m-0">{t('BuyerEmail')}:</p>
            <p className="m-0">{t('BuyerPosition')}:</p>
          </div>
          <div className="d-flex flex-column gap-2">
            <p className="font-weight-bold m-0">{confirmPaymentInfo.buyerName}&nbsp;</p>
            <p className="font-weight-bold m-0">{confirmPaymentInfo.buyerPhone}&nbsp;</p>
            <p className="font-weight-bold m-0">{confirmPaymentInfo.buyerEmail}&nbsp;</p>
            <p className="font-weight-bold m-0">{confirmPaymentInfo.buyerPosition}&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPaymentStep1Info;
