import { setSelectedMail, setSelectedSpamMailIds } from 'features/Mail/mailSlice';
import EmptyView from 'features/Others/components/EmptyView';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTCheckbox, {
  KTCheckboxStyle,
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import VIButton from 'general/components/VietInvoice/VIButton';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import MailHelper from 'general/helpers/MailHelper';
import Global from 'general/utils/Global';
import useAccount from 'hooks/useAccount';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CellInvoiceMail from '../CellInvoiceMail';
import InvoiceDetailView from '../InvoiceDetailView';
import ViewMailFilter from '../ViewMailFilter';
import useRouter from 'hooks/useRouter';
import './style.scss';

MailSpamBoxView.propTypes = {
  height: PropTypes.string,
};

MailSpamBoxView.defaultProps = {
  height: '',
};

/**
 *
 * @param {{
 * height: string,
 * }} props
 * @returns
 */
function MailSpamBoxView(props) {
  // ---- params ----
  const { height } = props;
  const { t } = useTranslation();
  const account = useAccount();
  const {
    isGettingSpamMailList,
    spamMailList,
    totalSpamMailList,
    selectedMail,
    selectedSpamMailIds,
    hasMail,
  } = useSelector((state) => state.mail);
  const [mailCheckboxValue, setMailCheckboxValue] = useState(Global.gTempMailSpamCheckboxValue);
  const refHeader = useRef();
  const [contentHeight, setContentHeight] = useState(height);
  const [contentHeightMobile, setContentHeightMobile] = useState(height);

  const [filters, setFilters] = useState(Global.gFiltersSpamMailList);
  const dispatch = useDispatch();
  const selectedMode = selectedSpamMailIds.length > 0;
  const router = useRouter();

  useEffect(() => {
    setContentHeight(`calc(${height} - ${refHeader.current?.offsetHeight}px - 2rem)`);
    setContentHeightMobile(`calc(${height} - ${refHeader.current?.offsetHeight}px - 7rem)`);
  }, [height, refHeader.current]);

  useEffect(() => {
    Global.gTempMailSpamCheckboxValue = mailCheckboxValue;
    if (!_.isEmpty(selectedSpamMailIds) && mailCheckboxValue === '') {
      return;
    }
    let ids = [];

    if (mailCheckboxValue === 'ALL') {
      ids = spamMailList?.map((item) => item.mailId);
    } else {
      ids = spamMailList
        ?.filter((item) =>
          _.chain(item?.StatusMails?.map((item) => item.statusValidMail))
            .flatten()
            .uniq()
            .value()
            ?.includes(mailCheckboxValue)
        )
        ?.map((item) => item.mailId);
    }
    dispatch(setSelectedSpamMailIds(ids));
  }, [mailCheckboxValue]);

  useEffect(() => {
    if (!_.isEmpty(spamMailList)) {
      dispatch(setSelectedMail(spamMailList[0]));
    } else {
      dispatch(setSelectedMail(null));
    }
  }, []);

  // ---- methods ----
  function handleHideViewFilter() {
    const el = document.getElementsByClassName('DropdownViewFilterContent')?.item(0);
    if (el) {
      console.log(el.classList);
      if (el.classList.contains('show')) {
        el.classList.remove('show');
      }
    }
  }

  function handleDeleteMail(item) {
    const isTrash = item?.isTrash ?? false;
    const ids = [item?.mailId];
    if (isTrash) {
      // xoa vinh vien
      AppDialogHelper.show(
        'Xoá vĩnh viễn thư',
        'Bạn có chắc chắn muốn xoá vĩnh viễn thư này khỏi thùng rác không?',
        [
          {
            title: 'Không',
            onClick: () => {
              AppDialogHelper.hide();
            },
            type: 'neutral',
          },
          {
            title: 'Có',
            onClick: () => {
              AppDialogHelper.hide();
              MailHelper.callApiDeleteMail(ids);
            },
            type: 'positive',
          },
        ],
        false
      );
    } else {
      // di chuyen vao thung rac
      AppDialogHelper.show(
        'Xoá thư',
        'Bạn có chắc chắn muốn di chuyển thư này vào thùng rác không?',
        [
          {
            title: 'Không',
            onClick: () => {
              AppDialogHelper.hide();
            },
            type: 'neutral',
          },
          {
            title: 'Có',
            onClick: () => {
              AppDialogHelper.hide();
              MailHelper.callApiMoveMailIntoTrash(ids);
            },
            type: 'positive',
          },
        ],
        false
      );
    }
  }

  function handleDeleteMultiMails(ids) {
    // xoa vinh vien
    AppDialogHelper.show(
      'Xoá vĩnh viễn thư',
      `Bạn có chắc chắn muốn xoá vĩnh viễn ${ids?.length} thư khỏi thùng rác không?`,
      [
        {
          title: 'Không',
          onClick: () => {
            AppDialogHelper.hide();
          },
          type: 'neutral',
        },
        {
          title: 'Có',
          onClick: async () => {
            AppDialogHelper.hide();
            const res = await MailHelper.callApiDeleteMail(ids);
            if (res) {
              dispatch(setSelectedSpamMailIds([]));
            }
          },
          type: 'positive',
        },
      ],
      false
    );
  }

  async function handleRestoreMultiMails(ids) {
    const res = await MailHelper.callApiRestoreMailFromTrash(ids);
    if (res) {
      dispatch(setSelectedSpamMailIds([]));
    }
  }

  return (
    <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center mail-spam">
      {/* empty view */}
      {!hasMail ? (
        <div
          className=""
          style={{
            width: '50%',
          }}
        >
          <EmptyView
            title="Bạn chưa có Email"
            message={
              <>
                Bạn có thể gửi hóa đơn điện tử hoặc chuyển tiếp (forward) email hóa đơn điện tử của
                bạn đến email{' '}
                <a href={'mailto:' + account.organizationEmailReceiveInvoice}>
                  {account.organizationEmailReceiveInvoice}
                </a>
                .
              </>
            }
            image={AppResource.icons.icNoMail}
          />
        </div>
      ) : (
        <div className="d-flex flex-column w-100 h-100 pt-4 pb-0">
          {/* header */}
          <div
            className="d-flex gap-3 align-items-center justify-content-between px-4 mb-4"
            ref={refHeader}
          >
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <div className="d-flex align-items-center border rounded-lg p-2 gap-1">
                <KTCheckbox
                  name="checkbox_all"
                  style={KTCheckboxStyle.rounded}
                  type={KTCheckboxType.outline}
                  checked={selectedSpamMailIds.length > 0}
                  onChange={(selected) => {
                    if (!selected) {
                      dispatch(setSelectedSpamMailIds([]));
                      setMailCheckboxValue('');
                    } else {
                      const ids = spamMailList.map((item) => item.mailId);
                      dispatch(setSelectedSpamMailIds(ids));
                    }
                  }}
                />
                <KTBSDropdown
                  dropdownMenuItems={AppData.mailCheckboxOptions}
                  selectedValue={mailCheckboxValue}
                  onChange={(value) => {
                    setMailCheckboxValue(value);
                  }}
                  toggleElement={
                    <div className="cursor-pointer bg-hover-light rounded w-20px h-20px d-flex align-items-center justify-content-center">
                      <i className="fa-solid fa-caret-down text-grey" />
                    </div>
                  }
                />
              </div>
              {!selectedMode ? (
                <>
                  <VISearchField
                    placeholder="Nhập tiêu đề, người gửi để tìm kiếm"
                    value={Global.gFiltersSpamMailList.q}
                    className=""
                    inputClassName="w-auto"
                    typingTimeout={500}
                    onSubmit={(newValue) => {
                      Global.gFiltersSpamMailList = {
                        ...Global.gFiltersSpamMailList,
                        q: newValue,
                        page: 0,
                      };
                      MailHelper.callApiGetSpamMailList(Global.gFiltersSpamMailList);
                    }}
                  />
                  <KTBSDropdown
                    dropdownMenuClassName="DropdownViewFilterContent"
                    toggleElement={
                      <VIButton
                        beforeIcon={<i className="fa-regular fa-filter-list" />}
                        text={t('Filter')}
                        className="btn-white py-2"
                        onClick={() => {}}
                      />
                    }
                    contentEl={
                      <div>
                        <ViewMailFilter
                          initialFilters={Global.gFiltersSpamMailList}
                          onPressCancel={handleHideViewFilter}
                          onPressFilter={(filters) => {
                            Global.gFiltersSpamMailList = {
                              ...Global.gFiltersSpamMailList,
                              ...filters,
                            };
                            MailHelper.callApiGetSpamMailList(Global.gFiltersSpamMailList);
                            handleHideViewFilter();
                          }}
                        />
                      </div>
                    }
                    autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                  />
                </>
              ) : (
                <div className="d-flex align-items-center gap-2 flex-wrap">
                  <p className="mb-0" style={{ minWidth: 'max-content' }}>
                    Đã chọn {selectedSpamMailIds.length} thư
                  </p>
                  {account.checkPermission('DELETE_AND_RESTORE_MAILBOX') && (
                    <>
                      <VIButton
                        beforeIcon={<i className="fa-regular fa-trash" />}
                        text={`Xoá (${selectedSpamMailIds.length}) thư`}
                        className="btn-white p-2"
                        onClick={() => {
                          handleDeleteMultiMails(selectedSpamMailIds);
                        }}
                      />
                      <VIButton
                        beforeIcon={<i className="fa-regular fa-trash-undo" />}
                        text={`Khôi phục (${selectedSpamMailIds.length}) thư`}
                        className="btn-white p-2"
                        onClick={() => {
                          handleRestoreMultiMails(selectedSpamMailIds);
                        }}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
            <div>
              <p className="mb-0 font-weight-bolder text-grey" style={{ minWidth: 'max-content' }}>
                {`${spamMailList.length} trong số ${totalSpamMailList}`}
              </p>
            </div>
          </div>

          {/* body */}
          <div className="flex-grow-1 border-top row m-0">
            {/* list mail */}
            <div
              className="col-4 border-right p-0 overflow-auto list-mail"
              style={{
                height: contentHeight,
              }}
            >
              {spamMailList.length === 0 ? (
                <div className="d-flex align-items-center h-100">
                  <EmptyView message={'Không có bản ghi nào'} image={AppResource.icons.icNoMail} />
                </div>
              ) : (
                <>
                  {spamMailList.map((item, index) => {
                    return (
                      <div key={index}>
                        <CellInvoiceMail
                          item={item}
                          onChangeImportant={async (isImportant) => {
                            const ids = [item.mailId];
                            if (isImportant) {
                              MailHelper.callApiMarkMailImportant(ids);
                            } else {
                              MailHelper.callApiMarkMailUnImportant(ids);
                            }
                          }}
                          onPress={async () => {
                            dispatch(setSelectedMail(item));
                            if (item?.readStatus !== 'READ') {
                              const ids = [item.mailId];
                              MailHelper.callApiMarkMailRead(ids);
                            }
                          }}
                          onMarkUnread={async () => {
                            const ids = [item.mailId];
                            MailHelper.callApiMarkMailUnRead(ids);
                          }}
                          onDelete={async () => {
                            handleDeleteMail(item);
                          }}
                          onRestore={async () => {
                            const ids = [item.mailId];
                            MailHelper.callApiRestoreMailFromTrash(ids);
                          }}
                          selected={selectedSpamMailIds?.includes(item.mailId)}
                          onChangeSelectedState={(selected) => {
                            let tmp = [...selectedSpamMailIds];
                            if (selected) {
                              // chon
                              if (!tmp.includes(item.mailId)) {
                                tmp.push(item.mailId);
                              }
                            } else {
                              // bo chon
                              if (tmp.includes(item.mailId)) {
                                tmp = tmp.filter((id) => id !== item.mailId);
                              }
                            }
                            dispatch(setSelectedSpamMailIds(tmp));
                            if (mailCheckboxValue !== '') {
                              setMailCheckboxValue('');
                            }
                          }}
                          highlight={selectedMail?.mailId === item.mailId}
                          onViewInvoice={(invoiceId) => {
                            router.navigate(`/inbot/invoice/detail/${invoiceId}`);
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            {/* mail content */}
            <div
              className="col-md-8 col-12 p-0 overflow-auto"
              style={{
                height: contentHeight,
                backgroundColor: '#F8F8F8',
              }}
            >
              <InvoiceDetailView
                height={contentHeight}
                item={selectedMail}
                onDelete={(item) => {
                  handleDeleteMail(item);
                }}
                mobile={
                  <KTBSDropdown
                    dropdownMenuClassName="DropdownViewFilterContent"
                    toggleElement={
                      <div
                        className="w-30px h-30px d-flex align-items-center justify-content-center bg-hover-light rounded cursor-pointer text-grey"
                        id="mail-inbox"
                      >
                        <i
                          className="fa-regular fa-bars"
                          style={{
                            color: 'inherit',
                          }}
                        ></i>
                      </div>
                    }
                    contentEl={
                      <div
                        className="border-right p-0 overflow-auto"
                        style={{
                          height: contentHeightMobile,
                        }}
                      >
                        {spamMailList.length === 0 ? (
                          <div className="d-flex align-items-center h-100">
                            <EmptyView
                              message={'Không có bản ghi nào'}
                              image={AppResource.icons.icNoMail}
                            />
                          </div>
                        ) : (
                          <>
                            {spamMailList.map((item, index) => {
                              return (
                                <div key={index}>
                                  <CellInvoiceMail
                                    item={item}
                                    onChangeImportant={async (isImportant) => {
                                      const ids = [item.mailId];
                                      if (isImportant) {
                                        MailHelper.callApiMarkMailImportant(ids);
                                      } else {
                                        MailHelper.callApiMarkMailUnImportant(ids);
                                      }
                                    }}
                                    onPress={async () => {
                                      dispatch(setSelectedMail(item));
                                      if (item?.readStatus !== 'READ') {
                                        const ids = [item.mailId];
                                        MailHelper.callApiMarkMailRead(ids);
                                      }
                                    }}
                                    onMarkUnread={async () => {
                                      const ids = [item.mailId];
                                      MailHelper.callApiMarkMailUnRead(ids);
                                    }}
                                    onDelete={async () => {
                                      handleDeleteMail(item);
                                    }}
                                    onRestore={async () => {
                                      const ids = [item.mailId];
                                      MailHelper.callApiRestoreMailFromTrash(ids);
                                    }}
                                    selected={selectedSpamMailIds?.includes(item.mailId)}
                                    onChangeSelectedState={(selected) => {
                                      let tmp = [...selectedSpamMailIds];
                                      if (selected) {
                                        // chon
                                        if (!tmp.includes(item.mailId)) {
                                          tmp.push(item.mailId);
                                        }
                                      } else {
                                        // bo chon
                                        if (tmp.includes(item.mailId)) {
                                          tmp = tmp.filter((id) => id !== item.mailId);
                                        }
                                      }
                                      dispatch(setSelectedSpamMailIds(tmp));
                                      if (mailCheckboxValue !== '') {
                                        setMailCheckboxValue('');
                                      }
                                    }}
                                    highlight={selectedMail?.mailId === item.mailId}
                                    onViewInvoice={(invoiceId) => {
                                      router.navigate(`/inbot/invoice/detail/${invoiceId}`);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    }
                    autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                  />
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MailSpamBoxView;
