import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import NotificationHelper from 'general/helpers/NotificationHelper';
import Utils from 'general/utils/Utils';

CellNotification.propTypes = {
  item: PropTypes.object,
  onPress: PropTypes.func,
};

CellNotification.defaultProps = {
  item: null,
  onPress: null,
};

/**
 *
 * @param {{
 * item: object,
 * onPress: function,
 * }} props
 * @returns
 */
function CellNotification(props) {
  // ---- params ----
  const { item, onPress } = props;

  // ---- methods ----
  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  return (
    <div
      className="CellNotification d-flex gap-3 py-2 px-3 cursor-pointer align-items-center justify-content-between"
      onClick={handlePress}
    >
      <div className="CellNotification_Thumbnail mt-1">
        <img src={NotificationHelper.getNotificationIcon(item)} />
      </div>
      <div className="my-auto flex-grow-1">
        <p className="noti-title m-0 font-weight-bold font-size-normal">{item?.title}</p>
        <p className="noti-date m-0 font-weight-normal font-size-normal">
          {item?.createdAt ? Utils.formatDateTime(item?.createdAt, 'DD/MM/YYYY HH:mm') : ''}
        </p>
      </div>
      {!item?.isRead && <span className="label label-dot label-primary label-lg mr-2"></span>}
    </div>
  );
}

export default CellNotification;
