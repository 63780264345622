import VIButton from 'general/components/VietInvoice/VIButton';
import CategoryHelper from 'general/helpers/CategoryHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useConfig from 'hooks/useConfig';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import fileSample from './Danh_sach_NCC_khach_hang.xlsx';
import './style.scss';

ViewImportSupplier.propTypes = {
  onPressCancel: PropTypes.func,
};

ViewImportSupplier.defaultProps = {
  onPressCancel: null,
};

const fileTypes = ['XLS', 'XLSX', 'CSV', 'ODS'];

function ViewImportSupplier(props) {
  // ---- params ----
  const { onPressCancel } = props;
  const { t } = useTranslation();
  const appConfig = useConfig();
  // const stepList = useMemo(() => {
  //   return [
  //     '1. Tải tệp nhập khẩu',
  //     '2. Ghép trường thông tin',
  //     '3. Kiểm tra dữ liệu',
  //     '4. Nhập khẩu',
  //   ];
  // }, [t]);
  // const [step, setStep] = useState(0);
  const [file, setFile] = useState(null);

  // ---- methods -----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  function handleDownloadSample() {
    const link = document.createElement('a');
    link.href = appConfig.urlImportSupplierCustomerSample
      ? Utils.getFullUrl(appConfig.urlImportSupplierCustomerSample)
      : fileSample;
    link.download = 'Danh_sach_NCC_khach_hang.xlsx';
    link.click();
  }

  const handleChange = (file) => {
    setFile(file);
  };

  async function handleUploadFile() {
    const res = await CategoryHelper.callApiImportSupplierCustomer(file);
    if (res) {
      ToastHelper.showSuccess(t('ImportSupplierCustomerSuccess'));
      CategoryHelper.callApiGetSupplierCustomerList(Global.gFiltersSupplierAndCustomer);
      handlePressCancel();
    }
  }

  return (
    <div className="ViewImportSupplier">
      {/* steps */}
      {/* <div>
        <ul className="breadcrumb breadcrumb-transparent breadcrumb-item font-weight-bold p-0 mb-2">
          {stepList.map((item, index) => {
            return (
              <li key={index} className="breadcrumb-item">
                <a
                  href="#"
                  className={`${step === index ? '' : 'text-dark-75'}`}
                  onClick={(e) => {
                    e.preventDefault();
                    // setStep(index);
                  }}
                >
                  {item}{' '}
                </a>
              </li>
            );
          })}
        </ul>
      </div> */}

      {/* content */}
      <div className="">
        {/* step 1 */}
        <div>
          {/* file uploader */}
          <div className="mb-4">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              label={'Tải lên hoặc kéo tệp nguồn vào đây'}
              classes="w-100"
              children={
                <div
                  className="w-100 h-100 d-flex flex-column align-items-center justify-content-center gap-3 bg-light p-6 rounded"
                  style={{
                    border: '1px dashed #3f425455',
                  }}
                >
                  {file ? (
                    <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                      <i
                        className="fa-duotone fa-file-spreadsheet text-primary"
                        style={{
                          fontSize: '3rem',
                        }}
                      />
                      <h5 className="font-weight-bolder">{file?.name}</h5>
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                      <p className="m-0">Kéo thả tệp nguồn vào đây</p>
                      <i
                        className="fa-regular fa-upload text-primary"
                        style={{
                          fontSize: '3rem',
                        }}
                      />
                      <p className="m-0">Hoặc</p>
                    </div>
                  )}
                  <div
                    className="bg-white px-4 py-3 rounded text-primary font-weight-bolder cursor-pointer bg-hover-primary text-hover-white"
                    style={{ border: '1px solid #5180FB' }}
                  >
                    {file ? 'Chọn lại tệp' : 'Chọn tệp dữ liệu'}
                  </div>

                  <div>
                    Chương trình chỉ hỗ trợ các tệp có định dạng{' '}
                    {fileTypes
                      .map((item) => {
                        return `*.${item.toLowerCase()}`;
                      })
                      .join(', ')}
                  </div>
                </div>
              }
            />
          </div>
          <div>
            <span className="text-danger font-weight-bolder">Lưu ý:</span> Để có kết quả nhập khẩu
            chính xác nhất hãy sử dụng tệp mẫu.{' '}
            <a
              href="#"
              className="font-weight-bolder"
              onClick={(e) => {
                e.preventDefault();
                handleDownloadSample();
              }}
            >
              {t('DownloadFileSample')}
            </a>
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="d-flex align-items-center justify-content-end gap-3">
        <VIButton
          text={t('Cancel')}
          className="btn-grey"
          style={{ padding: '8px' }}
          onClick={handlePressCancel}
        />
        <VIButton
          disabled={_.isNull(file)}
          text={t('Upload')}
          className=""
          style={{ padding: '8px' }}
          onClick={handleUploadFile}
        />
      </div>
    </div>
  );
}

export default ViewImportSupplier;
