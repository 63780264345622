import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Fade, Zoom } from 'react-reveal';
import HeadShake from 'react-reveal/HeadShake';
import LandingPageFooter from './components/LandingPageFooter';
import LandingPageHeader from './components/LandingPageHeader';
import './style.scss';

import AppConfigs from 'general/constants/AppConfigs';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import useRouter from 'hooks/useRouter';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import FaqsAndContact from './components/FaqsAndContact';
import ServicePackCard from './components/ServicePackCard';

function LandingPage(props) {
  const { t } = useTranslation();
  const partnersRef = useRef(null);
  const priceRef = useRef(null);
  const newsRef = useRef(null);
  const feedbackRef = useRef(null);
  const PageUp = useRef();
  const location = useLocation();
  const scrollTop = () => {
    document.documentElement.scrollTop = 0;
  };

  window.onscroll = function () {
    if (PageUp.current) {
      PageUp.current.style.display = document.documentElement?.scrollTop > 450 ? 'block' : 'none';
      PageUp.current.style.animation = 'scroll-top-animation 0.5s';
    }
  };

  const navigate = useNavigate();

  const advantageList = [
    {
      title: 'Tiết kiệm',
      text: 'Tiết kiệm 90% chi phí in ấn, vận chuyển, lưu trữ và bảo quản hóa đơn',
      icon: <i className="fa-regular fa-hand-holding-dollar"></i>,
    },
    {
      title: 'An toàn',
      text: 'Không xảy ra cháy, hỏng, mất hóa đơn. Tăng độ tin cậy cho hóa đơn của doanh nghiệp',
      icon: <i className="fa-regular fa-shield-check"></i>,
    },
    {
      title: 'Nhanh chóng',
      text: 'Người mua tức thời nhận được hóa đơn, rút ngắn thời gian thu nợ',
      icon: <i className="fa-regular fa-bolt"></i>,
    },
    {
      title: 'Linh hoạt',
      text: 'Phát hành, quản lý, báo cáo hóa đơn mọi lúc mọi nơi qua Mobile, Website, Desktop',
      icon: <i className="fa-regular fa-laptop-mobile"></i>,
    },
  ];

  const partnersList = [
    {
      name: 'InDo',
      logo: AppResource.icons.icInDo,
    },
    {
      name: 'EBig',
      logo: AppResource.icons.icEBig,
    },
    {
      name: 'GMO',
      logo: AppResource.icons.icGmo,
    },
    {
      name: 'Sun',
      logo: AppResource.icons.icSun,
    },
    {
      name: 'Mat bao',
      logo: AppResource.icons.icMatBao,
    },
  ];
  const feedbackList = [
    {
      customerName: 'Nguyễn Thị Hiền',
      customerAvatar: 'https://www.w3schools.com/howto/img_avatar2.png',
      customerPosition: 'Kế toán trưởng Công ty TNHH Giải trí YG Entertaiment',
      commentCustomerContent:
        ' “VietInvoice là công ty công nghệ hàng đầu nên tôi đánh giá rất cao những giá trị mà ICORP - VietInvoice mang đến cho Doanh nghiệp”',
    },
    {
      customerName: 'Nguyễn Thị Cúc',
      customerAvatar: null,
      customerPosition: 'Chủ tịch hội tư vấn Thuế Việt Nam',
      commentCustomerContent:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque lauda, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
    },
    {
      customerName: 'Nguyễn Thị A',
      customerAvatar: null,
      customerPosition: 'Chủ tịch hội tư vấn Thuế Việt Nam',
      commentCustomerContent:
        'Sed ut psssss sss sssss ss sssss ssss sssss sssssss ssssses rspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque lauda, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
    },
  ];
  const { topCustomerComments } = useSelector((state) => state.app);

  const priceList = [
    {
      name: 'Basic',
      price: 360000,
      features: [
        'Không giới hạn số máy và địa điểm truy cập.',
        'Sử dụng miễn phí mẫu hoá đơn trong kho hóa đơn mẫu của Vietinvoice.',
        'Tư vấn, hỗ trợ thủ tục phát hành hóa đơn (bản mềm).',
        'Miễn phí trang tra cứu hóa đơn cho doanh nghiệp.',
        'Lưu trữ hóa đơn đã phát hành 10 năm trên hệ thống và trên điện toán đám mây, an toàn tuyệt đối.',
      ],
    },
    {
      name: 'Intermediate',
      price: 600000,
      features: [
        'Không giới hạn số máy và địa điểm truy cập.',
        'Sử dụng miễn phí mẫu hoá đơn trong kho hóa đơn mẫu của Vietinvoice.',
        'Tư vấn, hỗ trợ thủ tục phát hành hóa đơn (bản mềm).',
        'Miễn phí trang tra cứu hóa đơn cho doanh nghiệp.',
        'Lưu trữ hóa đơn đã phát hành 10 năm trên hệ thống và trên điện toán đám mây, an toàn tuyệt đối.',
      ],
    },
    {
      name: 'Professional',
      price: 1100000,
      features: [
        'Không giới hạn số máy và địa điểm truy cập.',
        'Sử dụng miễn phí mẫu hoá đơn trong kho hóa đơn mẫu của Vietinvoice.',
        'Tư vấn, hỗ trợ thủ tục phát hành hóa đơn (bản mềm).',
        'Miễn phí trang tra cứu hóa đơn cho doanh nghiệp.',
        'Lưu trữ hóa đơn đã phát hành 10 năm trên hệ thống và trên điện toán đám mây, an toàn tuyệt đối.',
      ],
    },
    {
      name: 'Ultra',
      price: 1100000,
      features: [
        'Không giới hạn số máy và địa điểm truy cập.',
        'Sử dụng miễn phí mẫu hoá đơn trong kho hóa đơn mẫu của Vietinvoice.',
        'Tư vấn, hỗ trợ thủ tục phát hành hóa đơn (bản mềm).',
        'Miễn phí trang tra cứu hóa đơn cho doanh nghiệp.',
        'Lưu trữ hóa đơn đã phát hành 10 năm trên hệ thống và trên điện toán đám mây, an toàn tuyệt đối.',
      ],
    },
    {
      name: 'Ultra Pro',
      price: 1100000,
      features: [
        'Không giới hạn số máy và địa điểm truy cập.',
        'Sử dụng miễn phí mẫu hoá đơn trong kho hóa đơn mẫu của Vietinvoice.',
        'Tư vấn, hỗ trợ thủ tục phát hành hóa đơn (bản mềm).',
        'Miễn phí trang tra cứu hóa đơn cho doanh nghiệp.',
        'Lưu trữ hóa đơn đã phát hành 10 năm trên hệ thống và trên điện toán đám mây, an toàn tuyệt đối.',
      ],
    },
  ];
  const { servicePackList } = useSelector((state) => state.payment);
  // console.log({ servicePackList });

  // const newsList = [
  //   {
  //     image: 'https://i.pinimg.com/236x/c4/32/d7/c432d7e74f7ce409c65dfac529039f3f.jpg',
  //     title: 'Sed ut perspiciatis unde omnis',
  //     description:
  //       'totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.',
  //   },
  //   {
  //     image: 'https://i.pinimg.com/236x/41/f0/2a/41f02a834e9c0f34d7e9a2bc268bf91f.jpg',
  //     title: 'Sed ut perspiciatis unde omnis',
  //     description:
  //       'totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.',
  //   },
  //   {
  //     image: 'https://i.pinimg.com/236x/6b/df/b9/6bdfb9813c03f073b00789fb1a831e31.jpg',
  //     title: 'Sed ut perspiciatis unde omnis',
  //     description:
  //       'totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.',
  //   },
  //   {
  //     image: 'https://i.pinimg.com/236x/41/f0/2a/41f02a834e9c0f34d7e9a2bc268bf91f.jpg',
  //     title: 'Sed ut perspiciatis unde omnis',
  //     description:
  //       'totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.',
  //   },
  //   {
  //     image: 'https://i.pinimg.com/236x/6b/df/b9/6bdfb9813c03f073b00789fb1a831e31.jpg',
  //     title: 'Sed ut perspiciatis unde omnis',
  //     description:
  //       'totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.',
  //   },
  // ];

  const featureList = [
    {
      title: 'Tự động tra cứu hóa đơn',
      description:
        'ICORP Invoice phân tích hóa đơn đầu vào tự động tra cứu và tải hóa đơn từ email nhận được từ nhà cung cấp, tự động đọc hóa đơn từ ảnh chụp, file hóa đơn có sẵn.',
      icon: <i className="fa-regular fa-magnifying-glass"></i>,
    },
    {
      title: 'Tự động phân tích và kiểm tra hóa đơn',
      description:
        'Tự động phân tích, kiểm tra thông tin hóa đơn, trạng thái hoạt động của người bán – đảm bảo tính hợp pháp, hợp lệ của hóa đơn',
      icon: <i className="fa-regular fa-chart-user"></i>,
    },
    {
      title: 'Tự động đồng bộ với phần mềm kế toán',
      description:
        'Tự động đồng bộ với phần mềm kế toán, giúp kế toán tiết kiệm thời gian nhập liệu thủ công mỗi khi phát sinh hóa đơn đầu vào',
      icon: <i className="fa-regular fa-network-wired"></i>,
    },
    {
      title: 'Tự động lưu trữ và quản lý hóa đơn tập trung',
      description:
        'Toàn bộ hóa đơn đầu vào được lưu trữ tập trung trên cùng một địa chỉ duy nhất giúp kế toán dễ dàng tìm kiếm, quản lý hóa đơn.',
      icon: <i className="fa-regular fa-cloud-arrow-down"></i>,
    },
  ];

  // helpers
  window.addEventListener('scroll', () => {
    const headerScroll = document.getElementById('landingpage-header');
    if (window.scrollY > 10) {
      headerScroll?.classList.add('header-scroll');
    } else {
      headerScroll?.classList.remove('header-scroll');
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const router = useRouter();
  const locationHash = router?.location?.hash;
  const [selectedService, setSelectedService] = useState('I_SMART');
  const filteredServicePackList = useMemo(() => {
    return _.filter(servicePackList, (item) => item.service === selectedService);
  }, [servicePackList, selectedService]);

  useEffect(() => {
    if (locationHash) {
      const id = _.replace(locationHash, '#', '');
      const element = document.getElementById(id);
      if (element) {
        window.scrollTo(0, element.offsetTop - 80);
      }
    }
  }, [locationHash]);

  return (
    <div className="LandingPage bg-white">
      <div className="LandingPage_Introduction" id="home">
        <LandingPageHeader />

        {/* Introduction */}
        <div className="container-xl text-left d-flex flex-column flex-sm-row align-items-center gap-2 gap-sm-5 pt-25 pt-md-35">
          <div className="w-100 w-sm-50 order-1 order-sm-0 pb-4">
            <Fade left>
              <p className="big-title">
                Phần mềm quản lý <br /> hóa đơn ICORP Invoice
              </p>
            </Fade>
            <Fade right>
              <p className="text-normal">
                Thay đổi hoàn toàn cách thức quản lý, báo cáo hóa đơn đầu vào và hóa đơn đầu ra của
                đơn vị bạn.
                <br />
                Đồng bộ dữ liệu hóa đơn với Cơ quan thuế.
                <br />
                Giảm 80% thời gian và 90% chi phí xử lý, kiểm tra, đối soát, nhập liệu
              </p>
              <VIButton
                className="btn-blue mt-4 font-size-h5"
                text={t('TryFree')}
                onClick={() => {
                  navigate('/');
                }}
              />
            </Fade>
          </div>

          <img
            className="w-75 w-sm-50 order-0 order-sm-1 px-8 pt-8 align-self-sm-end"
            src={AppResource.images.imgLandingPage1}
            style={{ objectFit: 'contain' }}
          />
        </div>
      </div>
      {/* Hotline */}
      <div className="LandingPage_Hotline py-7 mt-5 mt-sm-0">
        <div className="container-xl d-flex justify-content-between text-white">
          <Zoom left>
            <div className="w-50 d-none d-sm-block">
              <h1 className="font-size-h1-sm font-weight-bolder" style={{ fontSize: 32 }}>
                Chúng tôi luôn sẵn sàng hỗ trợ
              </h1>
              <p className="text-normal text-white mb-0">
                Nếu có bất kì thắc mắc nào về dịch vụ hay sản phẩm vui lòng liên hệ với chúng tôi để
                được giải đáp nhanh nhất.
              </p>
            </div>
          </Zoom>
          <Zoom right>
            <div className="text-center text-sm-right w-100 w-sm-50 d-flex justify-content-end right-hotline">
              <div
                style={{ width: 'max-content' }}
                className="d-flex justify-content-end flex-column"
              >
                <h4 className="d-flex justify-content-center">
                  <span>
                    <i className="fa-regular fa-headset me-2 font-size-h3 text-white"></i>
                  </span>
                  Hỗ trợ khách hàng
                </h4>
                <p
                  className=" mb-0"
                  style={{ color: '#FFDD64', fontSize: '44px', fontWeight: 800 }}
                >
                  {AppConfigs.callCenterNumber}
                </p>
              </div>
            </div>
          </Zoom>
        </div>
      </div>
      <div
        className="container-xl py-25 d-flex flex-column flex-sm-row gap-2 gap-md-5"
        id="introduction"
      >
        <div className="w-100 w-sm-50 pb-sm-10  pr-sm-5 pr-lg-15">
          <img
            className="w-100 rounded-xl"
            src={AppResource.images.imgLandingPage2}
            alt="image"
            style={{ objectFit: 'cover' }}
          />
        </div>
        <div className="w-100 w-sm-50 pl-sm-5 pl-md-10">
          <Fade right>
            <p className="medium-title">Công nghệ chuyển đổi hóa đơn đầu vào</p>
          </Fade>
          <Fade left>
            <p className="text-normal">
              Hóa đơn đầu vào là một loại tài liệu chứng từ do nhà cung cấp cung cấp cho khách hàng
              để xác nhận việc bán hàng hoặc dịch vụ đã được thực hiện và tiền đã được thanh toán.
              Đây là một bước quan trọng trong quá trình thanh toán tiền cho nhà cung cấp và đồng
              thời quản lý chi phí của doanh nghiệp.
            </p>
            <VIButton
              className="btn-blue mt-4 font-size-h5"
              text={t('TryFree')}
              onClick={() => {
                navigate('/');
              }}
            />
          </Fade>
        </div>
      </div>
      {/* Characteristics */}
      <div className="LandingPage_Characteristic">
        <div className="py-20 py-sm-30 py-xl-48 container-xl text-white">
          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <div className="pb-40 pb-sm-0 col-12 col-sm-6 pr-0 pr-sm-10 pr-lg-20">
              <p className="big-title text-white">
                Hóa đơn điện tử <br /> ICORP Invoice
              </p>
              <p className="text-normal text-white">
                Tự động tra cứu, phân tích và kiểm tra thông tin hóa đơn đầu vào. Giúp kế toán giảm
                80% thời gian làm việc, nâng cao hiệu suất công việc và đảm bảo công việc chính xác
                100%.
              </p>
            </div>

            <div className="pt-40 pt-sm-0 h-sm-100 col-12 col-sm-6 col-lg-5" style={{ top: '50%' }}>
              <Fade bottom delay={100}>
                <h3 className="font-weight-boldest">Đầy đủ nghiệp vụ</h3>
                <p className="text-normal mt-3 w-lg-75 text-white">
                  Đáp ứng đầy đủ nghiệp vụ theo Nghị định 51/2010/NĐ-CP, nghị định 04/2014/NĐ-CP và
                  các văn bản hướng dẫn (Thông tư 32/2011/TT-BTC, Thông tư 39/2014/TT-BTC,…) và sẵn
                  sàng đáp ứng Nghị định 123/2020/NĐ-CP, Thông tư 78/2021/TT-BTC.
                </p>
              </Fade>
              <Fade bottom delay={300}>
                <h3 className="font-weight-boldest mt-15">Quản trị toàn diện</h3>
                <p className="text-normal mt-3 w-lg-75 text-white">
                  Người bán thực hiện phát hành, quản lý, báo cáo hóa đơn mọi lúc, mọi nơi qua
                  mobile, website, desktop.
                </p>
              </Fade>
              <Fade bottom delay={500}>
                <h3 className="font-weight-boldest mt-15">An toàn, bảo mật</h3>
                <p className="text-normal mt-3 w-lg-75 text-white">
                  Phần mềm hóa đơn điện tử đầu tiên tại Việt Nam ứng dụng công nghệ Blockchain đảm
                  bảo an toàn, bảo mật và chống làm giả hóa đơn.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      {/* Advantages */}
      <div className="LandingPage_Advantage container-xl py-20" id="advantages">
        <p className="medium-title text-center">
          Lợi ích giải pháp quản lý
          <br />
          hóa đơn ICORP Invoice
        </p>
        <p className="text-center text-normal w-75 w-lg-50 mx-auto">
          Dịch vụ Hoá đơn điện tử là giải pháp cho các tổ chức, doanh nghiệp có thể phát hành, phân
          phối, xử lý các nghiệp vụ và lưu trữ HĐĐT thay thế cho việc phát hành và sử dụng hóa đơn
          giấy theo quy định hiện hành của Bộ Tài Chính và Tổng Cục Thuế.
        </p>

        <div className="row row-cols-4 row-cols-sm-2 row-cols-xl-1 g-4 justify-content-between mt-10">
          {advantageList?.map((item, index) => (
            <div key={index} className="col-12 col-sm-6 col-xl-3">
              <HeadShake>
                <div className="rounded-xl p-8 h-100" style={{ background: '#F3F5F8' }}>
                  <div
                    className="rounded-xl bg-white p-5 d-flex justify-content-center align-items-center"
                    style={{ width: 70, height: 70 }}
                  >
                    {item?.icon}
                  </div>
                  <h3 className="mb-2 mt-8 font-weight-bolder">{item?.title}</h3>
                  <p className="text-normal mb-0">{item?.text}</p>
                </div>
              </HeadShake>
            </div>
          ))}
        </div>
      </div>
      {/* features */}
      <div className="LandingPage_Feature d-table py-10">
        <div className="container-xl d-flex flex-column flex-xl-row">
          <Fade right>
            <div className="row w-100 w-xl-50 order-1 order-xl-0 pr-xl-15">
              {featureList?.map((item, index) => (
                <div key={index} className="col-12 col-sm-6 col-xl-12">
                  <Zoom right>
                    <div className={`d-flex gap-3 py-8 pr-8 ${index !== 0 ? 'border-top' : ''}`}>
                      {item?.icon}
                      <div>
                        <h3 className="font-weight-boldest">{item?.title}</h3>
                        <p className="text-normal mt-1">{item?.description}</p>
                      </div>
                    </div>
                  </Zoom>
                </div>
              ))}
            </div>
          </Fade>
          <Fade left>
            <div
              className="w-100 w-xl-50 order-0 order-xl-1"
              style={{ background: 'linear-gradient(90deg, #153165 3.03%, #12306C 98.45%)' }}
            >
              <div className="feature-banner p-10 h-100">
                <div className="w-75 w-sm-50 w-xl-100 mx-auto">
                  <p className="big-title text-white">
                    Tiết kiệm <span> 80% thời gian </span> xử lý hóa đơn
                  </p>
                  <h3 className="font-weight-normal text-white">
                    Tự động tra cứu, phân tích và kiểm tra thông tin hóa đơn đầu vào. Kết nối đồng
                    bộ trực tiếp với phần mềm kế toán
                  </h3>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      {/* Partners */}
      <div className="LandingPage_Partners container-xl py-15">
        <p className="medium-title text-center mb-15">Được tin cậy bởi các đối tác</p>

        <div className="d-flex">
          <div
            id="previousButton"
            className="cursor-pointer opacity-80 hover-opacity-100"
            onClick={() => partnersRef.current.swiper.slidePrev()}
          >
            <img width={50} height={150} src={AppResource.icons.icPrevCarousel} />
          </div>
          <Swiper
            ref={partnersRef}
            modules={[Pagination, Navigation, Autoplay]}
            slidesPerView={4}
            autoplay={{
              delay: 3000,
            }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            className="h-200px"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },

              576: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
              1400: {
                slidesPerView: 4,
              },
            }}
          >
            {partnersList?.map((item, index) => (
              <SwiperSlide key={index}>
                <img height={150} src={item?.logo} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            id="nextButton"
            className="cursor-pointer opacity-80 hover-opacity-100"
            onClick={() => partnersRef.current.swiper.slideNext()}
          >
            <img width={50} height={150} src={AppResource.icons.icNextCarousel} />
          </div>
        </div>
      </div>
      {/* binh luan cua khach hang */}
      {topCustomerComments?.length > 0 && (
        <div className="LandingPage_Feedback container-xl py-15">
          <div className="d-flex flex-column flex-sm-row">
            <Fade right>
              <div className="col-12 col-sm-5">
                <p className="medium-title">
                  Bình luận của <br /> khách hàng
                </p>
                <p className="text-normal">
                  Cảm nghĩ của những khách hàng đã trải nghiệm sản phẩm của ICORP VietInvoice
                </p>
              </div>
            </Fade>
            <Fade left>
              <div className="col-12 col-sm-7 d-flex gap-5 align-items-center mt-10 mt-sm-0 pl-0 pl-sm-10">
                <Swiper
                  className="h-265px h-sm-295px h-md-425px w-100"
                  ref={feedbackRef}
                  modules={[Pagination, Navigation, Autoplay]}
                  slidesPerView={2}
                  spaceBetween={25}
                  autoplay={{
                    delay: 3000,
                  }}
                  loop={true}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },

                    1200: {
                      slidesPerView: 2,
                    },
                  }}
                >
                  {topCustomerComments?.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          className="rounded-xl p-8 h-100 d-flex flex-column justify-content-between w-100"
                          style={{ background: '#F1F4F7' }}
                        >
                          <p className="text-normal mb-8">{item?.commentCustomerContent}</p>
                          <div className="bg-white rounded-lg d-flex gap-4 p-4 align-items-center">
                            {item?.customerAvatar ? (
                              <img
                                className="rounded-circle flex-shrink-0"
                                width={45}
                                height={45}
                                src={item?.customerAvatar}
                              />
                            ) : (
                              <div className="bg-primary rounded-circle w-45px h-45px d-flex align-items-center justify-content-center flex-shrink-0">
                                <i className="fa-solid fa-user text-white font-size-h4"></i>
                              </div>
                            )}
                            <div>
                              <p className="font-weight-bolder font-size-lg mb-0">
                                {item?.customerName}
                              </p>
                              <p className="feedback-position font-weight-normal font-size-lg mb-0 text-dark-50">
                                {item?.customerPosition}
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </Fade>
          </div>
        </div>
      )}
      {/* bang gia */}
      <div className="LandingPage_Price container-xl py-15" id="price">
        <p className="medium-title text-center">
          BẢNG GIÁ DỊCH VỤ QUẢN LÝ HÓA ĐƠN <br />
          ĐẦU VÀO - ĐẦU RA ICORP INVOICE
        </p>
        <Fade bottom>
          <p className="text-normal text-center w-75 mx-auto my-4">
            (Miễn phí khởi tạo/kích hoạt tài khoản đăng ký)
          </p>
        </Fade>
        {/* goi dich vu */}
        <div className="d-flex align-items-center justify-content-center">
          <div
            className="d-flex justify-content-center overflow-hidden border-secondary border"
            style={{
              borderRadius: '9999px',
            }}
          >
            <div
              className={`${
                selectedService === 'I_SMART' ? 'bg-primary' : 'bg-white'
              } px-6 py-4 cursor-pointer`}
              onClick={() => {
                setSelectedService('I_SMART');
              }}
            >
              <p
                className={`m-0 font-size-lg font-weight-bold ${
                  selectedService === 'I_SMART' ? 'text-white' : 'text-grey'
                }`}
              >
                Gói dịch vụ I-SMART
              </p>
            </div>
            <div className="w-1px bg-secondary"></div>
            <div
              className={`${
                selectedService === 'I_PRO' ? 'bg-primary' : 'bg-white'
              } px-6 py-4 cursor-pointer`}
              onClick={() => {
                setSelectedService('I_PRO');
              }}
            >
              <p
                className={`m-0 font-size-lg font-weight-bold ${
                  selectedService === 'I_PRO' ? 'text-white' : 'text-grey'
                }`}
              >
                Gói dịch vụ I-PRO
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex gap-3 align-items-center mt-10">
          {filteredServicePackList?.length > 3 && (
            <div
              id="previousButton"
              className="cursor-pointer opacity-80 hover-opacity-100"
              onClick={() => priceRef.current.swiper.slidePrev()}
            >
              <img width={50} height={50} src={AppResource.icons.icPrevCarousel} />
            </div>
          )}
          <Swiper
            className="w-100"
            ref={priceRef}
            modules={[Pagination, Navigation, Autoplay]}
            slidesPerView={3}
            spaceBetween={25}
            loop={filteredServicePackList?.length > 3}
            observer={true}
            observeParents={true}
            autoplay={{
              delay: 3000,
            }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
            }}
          >
            {filteredServicePackList?.map((item, index) => {
              return (
                <SwiperSlide key={index} className="h-auto">
                  <ServicePackCard
                    packageCode={item?.packageCode}
                    packageCost={item?.packageCost}
                    description={item?.description}
                    recommend={item?.recommend}
                    onClick={() => {
                      navigate('/store');
                    }}
                    unit={item?.unit}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          {filteredServicePackList?.length > 3 && (
            <div
              id="nextButton"
              className="cursor-pointer opacity-80 hover-opacity-100"
              onClick={() => priceRef.current.swiper.slideNext()}
            >
              <img width={50} height={50} src={AppResource.icons.icNextCarousel} />
            </div>
          )}
        </div>
      </div>
      {/* News */}
      {/* <div className="container-xl py-25">
        <p className="medium-title text-center mb-5">Tin tức nổi bật</p>

        <div className="d-flex gap-3 align-items-center">
          <div
            id="previousButton"
            className="cursor-pointer opacity-80 hover-opacity-100"
            onClick={() => newsRef.current.swiper.slidePrev()}
          >
            <img width={50} height={50} src={AppResource.icons.icPrevCarousel} />
          </div>
          <Swiper
            ref={newsRef}
            modules={[Pagination, Navigation, Autoplay]}
            slidesPerView={3}
            spaceBetween={25}
            autoplay={{
              delay: 3000,
            }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              }
            }}
          >
            {newsList?.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="border rounded-xl p-5 cursor-pointer bg-hover-secondary-o-1"
                  onClick={() => {}}
                >
                  <img
                    className="bg-secondary border-0 rounded-lg w-100"
                    height={180}
                    style={{ objectFit: 'cover' }}
                    src={item?.image}
                  />
                  <h3 className="font-weight-boldest mt-5">{item?.title}</h3>
                  <h5 className="text-normal">{item?.description}</h5>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            id="nextButton"
            className="cursor-pointer opacity-80 hover-opacity-100"
            onClick={() => newsRef.current.swiper.slideNext()}
          >
            <img width={50} height={50} src={AppResource.icons.icNextCarousel} />
          </div>
        </div>
      </div> */}
      <div className="fixed-button">
        <button
          id="page-up"
          style={{ marginBottom: '30px', display: 'none' }}
          onClick={scrollTop}
          ref={PageUp}
        >
          <img src={AppResource.icons.icPageUp} />
        </button>

        <div className="circle-fill" />
        <div className="phone">
          <a href={'tel:' + AppConfigs.phoneNumber} className="tel">
            <img src={AppResource.icons.icSendPhone} className="w-100" />
          </a>
        </div>

        <button
          style={{
            paddingTop: '30px',
            width: '86px',
          }}
        >
          <a target="_blank" href={'https://zalo.me/' + AppConfigs.zaloPhoneNumber} className="tel">
            <img
              src={AppResource.icons.icSendZaLo}
              style={{
                boxShadow: '0px 5px 12px 0px rgba(0, 0, 0, 0.20)',
                borderRadius: '50%',
                background: 'tranparent',
              }}
            />
          </a>
        </button>
      </div>

      <FaqsAndContact />
      <LandingPageFooter />
    </div>
  );
}

export default LandingPage;
