import AppData from 'general/constants/AppData';
import PaymentHelper from 'general/helpers/PaymentHelper';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function usePayment(props) {
  // ---- params ----
  const {
    orders,
    shoppingCart,
    servicePackList,
    confirmPaymentInfo,
    readyToPurchaseOrder,
    paymentType,
    selectedVoucher,
    orderCreatedSuccess,
  } = useSelector((state) => state.payment);

  return useMemo(() => {
    // don hang
    const canOrder = !_.isEmpty(orders);
    const orderQuantityValid = _.every(orders, (item) => {
      return parseInt(item.quantity) > 0;
    });
    const orderQuantityBuyMore = orders.reduce((sum, item) => {
      return sum + item.package?.quantity * item.quantity;
    }, 0);
    const orderDurationExpired = '../../....';

    // gio hang
    const canShoppingCart = !_.isEmpty(shoppingCart);
    const shoppingCartCount = shoppingCart.length;
    const cartTotalPrice = shoppingCart.reduce((sum, item) => {
      return sum + item.package?.packageCost * item.quantity;
    }, 0);
    const shoppingCartQuantityBuyMore = shoppingCart.reduce((sum, item) => {
      return sum + item.package?.quantity * item.quantity;
    }, 0);
    const shoppingCartDurationExpired = '../../....';
    const isBankTransfer = paymentType === AppData.paymentType.bankTransfer;
    const bankTransferContent = PaymentHelper.genClientOrderCode(
      confirmPaymentInfo?.companyTaxCode
    );
    let totalPromotionPrice = 0;
    if (selectedVoucher) {
      const { discountOnPercent, discountOnTotal } = selectedVoucher;
      if (discountOnTotal) {
        totalPromotionPrice += discountOnTotal;
      }
      if (discountOnPercent) {
        totalPromotionPrice += (discountOnPercent / 100) * cartTotalPrice;
      }
    }

    const lastPriceToPay = cartTotalPrice - totalPromotionPrice;

    // tham so de tao don hang
    const createOrderParams = {
      taxCode: confirmPaymentInfo?.companyTaxCode,
      companyName: confirmPaymentInfo?.companyName,
      address: confirmPaymentInfo?.companyAddress,
      orderName: confirmPaymentInfo?.buyerName,
      orderEmail: confirmPaymentInfo?.buyerEmail,
      orderPhone: confirmPaymentInfo?.buyerPhone,
      orderPosition: confirmPaymentInfo?.buyerPosition,
      invoiceCustomerName: confirmPaymentInfo?.invoiceInfoName,
      invoiceCustomerPhone: confirmPaymentInfo?.invoiceInfoPhone,
      invoiceCustomerEmail: confirmPaymentInfo?.invoiceInfoEmail,
      invoiceCustomerAddress: confirmPaymentInfo?.invoiceInfoAddress,
      voucherCode: selectedVoucher?.voucherCode,
      typePayment: paymentType,
      orderItems: shoppingCart,
      clientOrderCode: bankTransferContent,
    };

    return {
      // don hang
      canOrder,
      orderQuantityValid,
      orderList: orders,
      servicePackList: servicePackList,
      orderQuantityBuyMore: orderQuantityBuyMore,
      orderDurationExpired: orderDurationExpired,

      // gio hang
      shoppingCartCount,
      cartTotalPrice,
      totalPromotionPrice,
      lastPriceToPay,
      shoppingCartList: shoppingCart,
      cartPriceUnit: shoppingCart[0]?.package?.unit ?? '',
      canShoppingCart,
      shoppingCartQuantityBuyMore,
      shoppingCartDurationExpired,

      confirmPaymentInfo: confirmPaymentInfo,
      readyToPurchaseOrder,
      isBankTransfer,
      bankTransferContent,
      selectedVoucher,

      createOrderParams,
      orderCreatedSuccess,
    };
  }, [
    orders,
    shoppingCart,
    confirmPaymentInfo,
    readyToPurchaseOrder,
    paymentType,
    selectedVoucher,
  ]);
}

export default usePayment;
