import EmptyView from 'features/Others/components/EmptyView';
import Utils from 'general/utils/Utils';
import PropTypes, { string } from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

ModalSignContract.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  contractLink: string,
};

ModalSignContract.defaultProps = {
  show: false,
  onClose: null,
  contractLink: 'https://dev.hopdongdientu.vn/api/sign/0idPf6?compactMode=1',
};

/**
 *
 * @param {{
 * show: false,
 * onClose: null,
 * contractLink: string,
 * }} props
 * @returns
 */
function ModalSignContract(props) {
  // ---- params ----
  const { show, onClose, contractLink } = props;
  const { t } = useTranslation();

  // ---- methods ----
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <div>
      <Modal
        className=""
        show={show}
        backdrop="static"
        size="xl"
        onShow={() => {
          Utils.lockedBodyScroll();
        }}
        onHide={() => {
          handleClose();
          Utils.unlockedBodyScroll();
        }}
        onExited={() => {}}
      >
        {/* header */}
        <Modal.Header className="px-5 py-5">
          <Modal.Title>Ký hợp đồng với ICORP</Modal.Title>
          <div
            className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            <i className="far fa-times"></i>
          </div>
        </Modal.Header>
        {/* body */}
        <Modal.Body
          id="modal-contract-document-viewer-body"
          className="overflow-hidden p-0"
          style={{
            height: '80vh',
          }}
        >
          {contractLink ? (
            <div className="h-100">
              <iframe src={contractLink} className="w-100 h-100" type="application/pdf"></iframe>
            </div>
          ) : (
            <div className="h-100 d-flex align-items-center">
              <EmptyView title="không có hợp đồng" />
            </div>
          )}
          {/* </div> */}
        </Modal.Body>
        {/* footer */}
        <Modal.Footer>
          <div className="w-100 d-flex row gap-3">
            <Button
              className={`font-weight-bold flex-grow-1 col`}
              variant="secondary"
              onClick={handleClose}
            >
              {t('Close')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalSignContract;
