import 'assets/styles/keen/theme01/layout/aside/dark.css';
import AppConfigs from 'general/constants/AppConfigs';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useConfig from 'hooks/useConfig';
import useRouter from 'hooks/useRouter';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.scss';
import KTOffcanvas from 'assets/plugins/ktoffcanvas';

KT01Sidebar.propTypes = {};

function KT01Sidebar(props) {
  // MARK: --- Params ---
  const router = useRouter();
  const pathName = router.pathname;
  const totalUnreadMail = useAccount().totalUnreadMail;
  const { t } = useTranslation();
  const menuItems = useMemo(() => {
    return [
      { type: 'item', text: 'Dashboard', icon: 'fa-regular fa-grid-2', path: '/inbot/dashboard' },
      {
        type: 'item',
        text: 'InputInvoice',
        icon: 'fa-regular fa-file-import',
        path: '/inbot/invoice/input',
      },
      {
        type: 'item',
        text: 'OutputInvoice',
        icon: 'fa-regular fa-file-export',
        path: '/inbot/invoice/output',
      },
      {
        type: 'item',
        text: 'Mailbox',
        icon: 'fa-regular fa-envelope',
        path: '/inbot/mail',
        label: totalUnreadMail > 0 ? totalUnreadMail : null,
      },
      { type: 'item', text: 'NCC/KH', icon: 'fa-regular fa-list-check', path: '/inbot/category' },
      {
        type: 'item',
        text: 'Organization',
        icon: 'fa-regular fa-sitemap',
        path: '/inbot/organization',
      },
      {
        type: 'item',
        text: 'ConnectSupplier',
        icon: 'fa-regular fa-arrow-right-arrow-left',
        path: '/inbot/supplier',
      },
      { type: 'item', text: 'ConnectAxion', icon: 'fa-regular fa-landmark', path: '/inbot/axions' },
      {
        type: 'item',
        text: 'LookUp',
        icon: 'fa-regular fa-search',
        path: '/inbot/tool',
      },
      { type: 'item', text: 'System', icon: 'fa-regular fa-gear', path: '/inbot/system' },
      { type: 'item', text: 'AccountInfo', icon: 'fa-regular fa-user', path: '/inbot/account' },
    ];
  }, [totalUnreadMail]);
  const refOffCanvasAside = useRef(null);

  // MARK: --- Hooks ---
  useEffect(() => {
    // Init Aside
    if (KTLayoutAside !== undefined) {
      KTLayoutAside.init('kt_aside');
    }

    // Init Aside Toggle
    if (KTLayoutAsideToggle !== undefined) {
      KTLayoutAsideToggle.init('kt_aside_toggle');
    }

    // Init Aside Menu
    if (KTLayoutAsideMenu !== undefined) {
      KTLayoutAsideMenu.init('kt_aside_menu');
    }
    // Init Off Canvas
    if (typeof KTOffcanvas !== 'undefined') {
      const offCanvasAside = new KTOffcanvas('kt_aside', {
        baseClass: 'aside',
        overlay: true,
        toggleBy: {
          target: 'kt_aside_mobile_toggle',
          // state: 'mobile-toggle-active'
        },
      });
      refOffCanvasAside.current = offCanvasAside;
    }
  }, []);
  const appConfig = useConfig();
  const bannerHeight = appConfig.systemNotification.bannerHeight;
  const { sidebarImgDecor, sidebarShowFooter } = appConfig;

  return (
    <div
      className="aside aside-left px-0 aside-fixed d-flex flex-column flex-row-auto"
      id="kt_aside"
      style={{
        paddingTop: `${bannerHeight}px`,
      }}
    >
      {/* Aside Header */}
      <div className="brand flex-column-auto">
        {/* logo */}
        <a
          href="#"
          className="brand-logo"
          onClick={(e) => {
            e.preventDefault();
            // if (!router.pathname.includes('/dashboard')) {
            router.navigate('/landing-page');
            // }
          }}
        >
          <img className="h-30px" src={AppResource.icons.icLogoQuanLyHoaDonDark} alt="logo" />
        </a>
        {/* button toggle */}
        <button className="brand-toggle btn btn-sm px-0 border-0" id="kt_aside_toggle">
          <span className="svg-icon svg-icon svg-icon-xl">
            <img src={AppResource.icons.keens.toggleRight} alt="toggle" />
          </span>
        </button>
      </div>

      {/* Aside Menu */}
      <div
        id="kt_aside_menu"
        className="aside-menu my-0 h-100 position-relative"
        data-menu-scroll="1" // enable scroll
        data-menu-vertical="1"
        // data-menu-dropdown-timeout='500'
      >
        {/* anh decor */}
        {sidebarImgDecor && (
          <div
            className="position-absolute bottom-0 w-100"
            style={{
              zIndex: 0,
            }}
          >
            <img className="w-100" src={sidebarImgDecor} />
          </div>
        )}

        {/* Nav menu */}
        <ul className="menu-nav py-0 px-3" style={{ zIndex: 1 }}>
          {menuItems.map((item, index) => {
            // Item
            if (item?.type === 'item') {
              const hasSubMenuLV1Items = item?.subMenuItems !== undefined;

              return (
                <li
                  key={index}
                  className={`menu-item ${hasSubMenuLV1Items && 'menu-item-submenu'} ${
                    hasSubMenuLV1Items &&
                    item.path.length > 0 &&
                    pathName.includes(item.path) &&
                    'menu-item-open'
                  } ${
                    // !hasSubMenuLV1Items &&
                    item.path.length > 0 && pathName.includes(item.path) && 'menu-item-active'
                  }`}
                >
                  <Link
                    to={item?.path}
                    className={`menu-link ${hasSubMenuLV1Items && 'menu-toggle'}`}
                    onClick={() => {
                      if (refOffCanvasAside.current) {
                        refOffCanvasAside.current.hide();
                      }
                    }}
                  >
                    <span className="sgv-icon menu-icon">
                      <i className={`${item?.icon} text-white`} />
                    </span>
                    <span className="menu-text">{t(item?.text)}</span>
                    {item?.label && (
                      <span className="menu-label">
                        <span className="label label-rounded label-danger label-inline">
                          {item?.label}
                        </span>
                      </span>
                    )}
                    {hasSubMenuLV1Items && <i className="menu-arrow" />}
                  </Link>
                  {/* Sub menu items level 1 */}
                  {hasSubMenuLV1Items && (
                    <div className="menu-submenu">
                      <ul className="menu-subnav">
                        {item?.subMenuItems?.map((subItemLV1, subIndexLV1) => {
                          const hasSubMenuLV2Items = subItemLV1?.subMenuItems !== undefined;

                          return (
                            <li
                              key={subIndexLV1}
                              className={`menu-item ${hasSubMenuLV2Items && 'menu-item-submenu'} ${
                                hasSubMenuLV2Items &&
                                subItemLV1.path.length > 0 &&
                                pathName.includes(subItemLV1.path) &&
                                'menu-item-open'
                              } ${
                                // !hasSubMenuLV2Items &&
                                subItemLV1.path.length > 0 &&
                                pathName.includes(subItemLV1.path) &&
                                'menu-item-active'
                              }`}
                            >
                              <Link
                                className={`menu-link rounded-0 ${
                                  hasSubMenuLV2Items && 'menu-toggle'
                                }`}
                                to={subItemLV1?.path}
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span></span>
                                </i>
                                <span className="menu-text">{subItemLV1?.text}</span>
                                {subItemLV1?.label && (
                                  <span className="menu-label">
                                    <span className="label label-rounded label-danger label-inline">
                                      {subItemLV1?.label}
                                    </span>
                                  </span>
                                )}
                                {hasSubMenuLV2Items && <i className="menu-arrow" />}
                              </Link>
                              {/* Sub menu items level 2 */}
                              {hasSubMenuLV2Items && (
                                <div className="menu-submenu">
                                  <ul className="menu-subnav">
                                    {subItemLV1?.subMenuItems?.map((subItemLV2, subIndexLV2) => {
                                      const hasSubMenuLV3Items =
                                        subItemLV2?.subMenuItems !== undefined;

                                      return (
                                        <li
                                          key={subIndexLV2}
                                          className={`menu-item ${
                                            hasSubMenuLV3Items && 'menu-item-submenu'
                                          } ${
                                            hasSubMenuLV3Items &&
                                            subItemLV2.path.length > 0 &&
                                            pathName.includes(subItemLV2.path) &&
                                            'menu-item-open'
                                          } ${
                                            // !hasSubMenuLV3Items &&
                                            subItemLV2.path.length > 0 &&
                                            pathName.includes(subItemLV2.path) &&
                                            'menu-item-active'
                                          }`}
                                        >
                                          <Link
                                            className={`menu-link rounded-0 ${
                                              hasSubMenuLV3Items && 'menu-toggle'
                                            }`}
                                            to={subItemLV2?.path}
                                          >
                                            <i className="menu-bullet menu-bullet-dot">
                                              <span></span>
                                            </i>
                                            <span className="menu-text">{subItemLV2?.text}</span>
                                            {subItemLV2?.label && (
                                              <span className="menu-label">
                                                <span className="label label-rounded label-danger label-inline">
                                                  {subItemLV2?.label}
                                                </span>
                                              </span>
                                            )}
                                            {hasSubMenuLV3Items && <i className="menu-arrow" />}
                                          </Link>
                                          {/* Sub menu items level 3 */}
                                          {hasSubMenuLV3Items && (
                                            <div className="menu-submenu">
                                              <ul className="menu-subnav">
                                                {subItemLV2?.subMenuItems?.map(
                                                  (subItemLV3, subIndexLV3) => {
                                                    const hasSubMenuLV4Items =
                                                      subItemLV3?.subMenuItems !== undefined;

                                                    return (
                                                      <li
                                                        key={subIndexLV3}
                                                        className={`menu-item ${
                                                          hasSubMenuLV4Items && 'menu-item-submenu'
                                                        } ${
                                                          hasSubMenuLV4Items &&
                                                          subItemLV3.path.length > 0 &&
                                                          pathName.includes(subItemLV3.path) &&
                                                          'menu-item-open'
                                                        } ${
                                                          //   !hasSubMenuLV4Items &&
                                                          subItemLV3.path.length > 0 &&
                                                          pathName.includes(subItemLV3.path) &&
                                                          'menu-item-active'
                                                        }`}
                                                      >
                                                        <Link
                                                          className={`menu-link rounded-0 ${
                                                            hasSubMenuLV4Items && 'menu-toggle'
                                                          }`}
                                                          to={subItemLV3?.path}
                                                        >
                                                          <i className="menu-bullet menu-bullet-dot">
                                                            <span></span>
                                                          </i>
                                                          <span className="menu-text">
                                                            {subItemLV3?.text}
                                                          </span>
                                                          {subItemLV3?.label && (
                                                            <span className="menu-label">
                                                              <span className="label label-rounded label-danger label-inline">
                                                                {subItemLV3?.label}
                                                              </span>
                                                            </span>
                                                          )}
                                                          {hasSubMenuLV4Items && (
                                                            <i className="menu-arrow" />
                                                          )}
                                                        </Link>
                                                        {/* Sub menu items level 4 */}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </li>
              );
            }

            // Section
            if (item?.type === 'section') {
              return (
                <li key={index} className="menu-section">
                  <h4 className="menu-text">{t(item?.text)}</h4>
                </li>
              );
            }
          })}
        </ul>

        {/* ho tro khach hang */}
        {sidebarShowFooter === '1' && (
          <div
            className="text-center aside-support px-3 position-absolute bottom-0 w-100"
            style={{ zIndex: 1 }}
          >
            <div
              className="text-white py-4 mb-3"
              style={{
                borderRadius: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.15)',
              }}
            >
              <p className="font-size-h5 m-0">
                <i className="text-white fa-regular fa-headset mr-4"></i>
                {t('CustomerSupport')}
                {':'}
              </p>
              <p className="font-size-h5 m-0">
                <a
                  className="text-white font-weight-boldest"
                  href={`tel: ${AppConfigs.callCenterNumber}`}
                >
                  {AppConfigs.callCenterNumber}
                </a>{' '}
                {/* {' - '}
            <a style={{ color: '#00BBB3' }} href={`tel: ${AppConfigs.zaloPhoneNumber}`}>
              {AppConfigs.zaloPhoneNumber}
            </a> */}
              </p>
            </div>

            {/* thong tin tai nguyen */}
            <div className="d-none">
              <p className="text-white m-0">
                {t('ExpiryDateToDate')}
                {': '}
                <span style={{ color: '#00BBB3' }}>
                  {Utils.formatDateTime('2026/06/02', 'DD/MM/YYYY')}
                </span>
              </p>
              <p className="text-white">
                {t('RemainingInvoices')}
                {': '}
                <span style={{ color: '#00BBB3' }}>{Utils.formatNumber(2456)}</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default KT01Sidebar;
