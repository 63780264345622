import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

CardInvoiceInfo.propTypes = {
  onPressExtendBuyMore: PropTypes.func,
};

CardInvoiceInfo.defaultProps = {
  onPressExtendBuyMore: null,
};

/**
 *
 * @param {{
 * onPressExtendBuyMore: function,
 * }} props
 * @returns
 */
function CardInvoiceInfo(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { onPressExtendBuyMore } = props;
  const account = useAccount();

  // ----- methods -----
  function handlePressExtendBuyMore() {
    if (onPressExtendBuyMore) {
      onPressExtendBuyMore();
    }
  }

  return (
    <div
      className="p-6 d-flex align-items-start h-100"
      style={{
        backgroundColor: '#0430A3',
        borderRadius: '16px',
        backgroundImage: `url(${AppResource.images.imgSystemReourceLeft})`,
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div>
        <h5 className="card-label text-white font-weight-bolder">Số lượng hóa đơn chưa sử dụng</h5>
        <p className="font-weight-boldest text-white mb-0" style={{ fontSize: '3rem' }}>
          {Utils.formatNumber(account.company.resourceTotalUnusedInvoice)}
        </p>
        <p className="text-white font-size-lg">
          Hóa đơn dùng đến {account.company.resourceDateExpired}
        </p>
        <div className="d-flex gap-2">
          <VIButton
            text={t('BuyMore')}
            className="btn-white px-2 py-2"
            onClick={handlePressExtendBuyMore}
          />
          <VIButton
            text={t('Extend')}
            className="btn-white px-2 py-2"
            onClick={handlePressExtendBuyMore}
          />
        </div>
      </div>
      <div className="flex-grow-1 d-flex flex-column align-items-end">
        <img style={{ height: '60px' }} src={AppResource.images.imgInvoiceAccumulate} alt="" />
      </div>
    </div>
  );
}

export default CardInvoiceInfo;
