import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import { useTranslation } from 'react-i18next';
import VIPagination from 'general/components/VietInvoice/VIPagination';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import Global from 'general/utils/Global';
import _ from 'lodash';
import VISearchField from 'general/components/VietInvoice/VISearchField';

TestComponentDungScreen.propTypes = {};
const fakeData = [
  {
    companyTitleId: 1,
    name: 'test',
    description: 'test',
  },
  {
    companyTitleId: 2,
    name: 'test1',
    description: 'test2',
  },
  {
    companyTitleId: 2,
    name: 'test1',
    description: 'test2',
  },
  {
    companyTitleId: 2,
    name: 'test1',
    description: 'test2',
  },
  {
    companyTitleId: 2,
    name: 'test1',
    description: 'test2',
  },
  {
    companyTitleId: 2,
    name: 'test1',
    description: 'test2',
  },
];

function TestComponentDungScreen(props) {
  // MARK --- Params ---
  const { t } = useTranslation();
  const [data, setData] = useState(fakeData.slice(0, 5));
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    perPage: 5,
    total: fakeData.length,
    currentPage: 2,
    count: data.length,
  });
  const [filters, setFilter] = useState({
    page: 0,
    limit: 5,
    q: '',
  });

  // MARK --- Hooks ---
  useEffect(() => {
    setLoading(true);

    const text = filters.q;
    setData(
      fakeData
        .slice(filters.limit * filters.page, filters.limit * (filters.page + 1))
        .filter((item) => item.name.includes(text) || item.description.includes(text))
    );
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    setPagination({
      ...pagination,
      perPage: filters.limit,
      currentPage: filters.page + 1,
      count: data.length,
    });
  }, [data]);

  return (
    <div>
      <div className="row">
        {/* dropdown hien thi hanh dong trong bang */}
        <div className="d-flex">
          <span className="mr-10">Dropdown hiển thị hành động trong bảng:</span>
          <VITableRowDropDown
            toggleElement={
              <div>
                <i className="text-grey fas fa-ellipsis-h p-0"></i>
              </div>
            }
            menuItem={[
              {
                iconElement: <i className="far fa-edit"></i>,
                text: t('Edit'),
                click: () => {
                  // handleEditCustomer(row);
                },
              },
              {
                iconElement: <i className="far fa-trash-alt"></i>,
                text: t('Delete'),
                click: () => {
                  // handleDeleteCustomer(row);
                },
              },
              {
                iconElement: <i className="fa-regular fa-clone"></i>,
                text: t('Replicate'),
                click: () => {
                  // handleReplicateCustomer(row);
                },
              },
            ]}
          />
        </div>

        {/* data table */}
        <div className="mt-10">
          <VIDataTable
            title="Test Table"
            selectable={true}
            pagination={pagination}
            onChangePage={(page) => {
              let iNewPage = parseInt(page);
              iNewPage -= 1;
              if (iNewPage < 0) {
                iNewPage = 0;
              }
              setFilter({
                ...filters,
                page: iNewPage,
              });
            }}
            onChangeRowsPerPage={(perPage) => {
              setFilter({
                ...filters,
                limit: perPage,
                page: 0,
              });
            }}
            fullColumns={[
              {
                name: 'Tên chức danh',
                cell: (row) => {
                  return (
                    <p
                      data-tag="allowRowEvents"
                      className=" font-weight-normal m-0 text-maxline-3 mr-4"
                    >
                      {row?.name}
                    </p>
                  );
                },
              },
              {
                name: 'Mô tả',
                cell: (row) => {
                  return (
                    <p
                      data-tag="allowRowEvents"
                      className=" font-weight-normal m-0 text-maxline-3 mr-4"
                    >
                      {row?.description}
                    </p>
                  );
                },
              },
              {
                name: t('StopUsing'),

                width: '150px',
                center: true,
                cell: (row) => {
                  return (
                    <div data-tag="allowRowEvents">
                      <i
                        className={`text-${
                          row.status === 'ACTIVE' ? 'success' : 'danger'
                        } fa-regular fa-circle-${row.status === 'ACTIVE' ? 'check' : 'xmark'}`}
                      ></i>
                    </div>
                  );
                },
              },
              {
                name: '',
                center: true,
                width: '40px',
                style: {
                  position: 'sticky',
                  right: 0,
                  backgroundColor: '#fff',
                },
                cell: (row) => (
                  <div className="d-flex align-items-center justify-content-between">
                    <VITableRowDropDown
                      toggleElement={
                        <div>
                          <i className="text-grey fas fa-ellipsis-h p-0"></i>
                        </div>
                      }
                      menuItem={[
                        {
                          iconElement: <i className="far fa-edit"></i>,
                          text: t('Edit'),
                          click: () => {
                            // handleEditCompanyTitle(row);
                          },
                        },
                        {
                          iconElement: <i className="far fa-trash-alt"></i>,
                          text: t('Delete'),
                          click: () => {
                            // handleDeleteCompanyTitle(row);
                          },
                        },
                        {
                          iconElement: <i className="fa-regular fa-clone"></i>,
                          text: t('Replicate'),
                          click: () => {
                            // handleReplicateCompanyTitle(row);
                          },
                        },
                      ]}
                    />
                  </div>
                ),
              },
            ]}
            data={data}
            loading={loading}
            filterbar={
              <>
                <VISearchField
                  placeholder="Nhập số hóa đơn, tên nhà cung cấp, mã số thuế."
                  value={filters.q}
                  className="w-50 mb-3"
                  typingTimeout={500}
                  onSubmit={(newValue) => {
                    setFilter({
                      ...filters,
                      q: newValue,
                    });
                  }}
                />
              </>
            }
            onRowClick={(row) => {
              console.log(row);
            }}
            onSetSelectedMultiItems={(rows) => console.log(rows)}
            toolbar={
              <>
                <div
                  onClick={(e) => {}}
                  className="btn btn-primary font-weight-bold d-flex align-items-center mr-4"
                >
                  <i className="far fa-plus"></i>
                  {t('Thêm Item')}
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default TestComponentDungScreen;
