import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIDateRangePickerInput from 'general/components/VietInvoice/VIDateRangePicker';
import VIDateRangePicker2 from 'general/components/VietInvoice/VIDateRangePicker2';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import AccountHelper from 'general/helpers/AccountHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';

DailyLog.propTypes = {
  dropdownMenuItems: PropTypes.array,
};

DailyLog.defaultProps = {
  dropdownMenuItems: [],
};

/**
 *
 * @param {{
 *  dropdownMenuItems: object[],
 * }} props
 * @returns
 */
function DailyLog(props) {
  // MARK --- Params ---
  const { dropdownMenuItems } = props;
  const { t } = useTranslation();
  const { dailyLogs, isGettingDailyLog, totalDailyLogs } = useSelector((state) => state?.account);
  const [filters, setFilters] = useState(Global.gFiltersDailyLog);
  const columns = useMemo(
    () => [
      {
        name: t('LoggedInStatus'),
        minWidth: '240px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-bolder m-0 text-maxline-3">
              <span
                className={`font-weight-bolder font-size-lg ${
                  row?.status === 'SUCCESS' ? 'text-success' : 'text-danger'
                }`}
              >
                {row?.status === 'SUCCESS' ? t('Success') : t('Failed')}
              </span>
              <br />
              <span className="text-grey font-size-sm">
                {row?.device} - {row?.browser}
              </span>
            </p>
          );
        },
      },
      {
        name: t('LoggedInTime'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-grey">
              {row?.createdAt ? Utils.formatDateTime(row?.createdAt, 'DD/MM/YYYY HH:mm:ss') : ''}
            </p>
          );
        },
      },
      {
        name: t('IPAddress'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-grey">
              {row?.ipAddress}
            </p>
          );
        },
      },
    ],
    [dailyLogs]
  );

  useEffect(() => {
    AccountHelper.callApiGetLoggedInLogs(filters);
  }, [filters]);

  return (
    <div className="h-100">
      <VIDataTable
        additionalCardBodyClassName="px-5"
        minusHeight={55}
        // haveCardHeader={false}
        title={
          <div className="d-flex align-items-center justify-content-center gap-1">
            <div className="d-block d-lg-none">
              <KTBSDropdown
                toggleElement={
                  <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                    <i className="fa-solid fa-bars text-grey" />
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                dropdownMenuItems={dropdownMenuItems}
                selectedValue={t('ProtectAccount')}
                onChange={(newValue) => {}}
              />
            </div>{' '}
            {t('LoggedInLog')}
          </div>
        }
        draggable={false}
        pagination={{
          perPage: filters.limit,
          total: totalDailyLogs,
          currentPage: filters.page + 1,
          count: dailyLogs?.length + filters.limit * filters.page,
        }}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          Global.gFiltersDailyLog = {
            ...Global.gFiltersDailyLog,
            page: iNewPage,
          };
          setFilters(Global.gFiltersDailyLog);
        }}
        onChangeRowsPerPage={(perPage) => {
          Global.gFiltersDailyLog = {
            ...Global.gFiltersDailyLog,
            limit: perPage,
            page: 0,
          };
          setFilters(Global.gFiltersDailyLog);
        }}
        fullColumns={columns}
        data={dailyLogs}
        loading={isGettingDailyLog}
        filterbar={
          <>
            <VISearchField
              placeholder={`${t('EnterToSearch')}...`}
              value={filters.q}
              className=""
              typingTimeout={500}
              onSubmit={(newValue) => {
                Global.gFiltersDailyLog = {
                  ...Global.gFiltersDailyLog,
                  q: newValue,
                  page: 0,
                };
                setFilters(Global.gFiltersDailyLog);
              }}
            />

            <div className="d-flex align-items-center">
              <label className="mb-0 mr-2 text-grey font-weight-bolder">{t('FilterByDate')}</label>
              <VIDateRangePicker2
                alignRight={false}
                initialValue={Global.gFiltersDailyLog.dateRangeValue}
                onPressSave={(selectedValue, startDate, endDate) => {
                  Global.gFiltersDailyLog = {
                    ...Global.gFiltersDailyLog,
                    from: startDate,
                    to: endDate,
                    dateRangeValue: selectedValue,
                  };
                  setFilters(Global.gFiltersDailyLog);
                }}
              />
            </div>
          </>
        }
        onRowClick={(row) => {
          console.log(row);
        }}
      />
    </div>
  );
}

export default DailyLog;
