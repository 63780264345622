import KTAdvanceNav, {
  KTAdvanceNavFontWeight,
} from 'general/components/OtherKeenComponents/KTAdvanceNav';
import useRouter from 'hooks/useRouter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import PersonalTaxCodeInfoScreen from './screens/PersonalTaxCodeInfoScreen';
import TaxCodeInfoScreen from './screens/TaxCodeInfoScreen';
import TaxReducedItemScreen from './screens/TaxReducedItemScreen';
import TaxReducedDocumentScreen from './screens/TaxReducedDocumentScreen';
import RiskyBusinessesScreen from './screens/RiskyBusinessesScreen';

function Tool(props) {
  // ---- params ----
  const { t } = useTranslation();
  const router = useRouter();
  const { pathname } = router;
  const navItemList = useMemo(() => {
    const itemList = [
      {
        text: t('LookUpTaxCodeFromCCCD'),
        active: pathname.includes('/personal-taxcode-info'),
        iconElement: <i className="fa-regular fa-id-card" />,
        icon: 'fa-regular fa-id-card',
        onPress: () => {
          router.navigate('personal-taxcode-info');
        },
      },
      {
        text: t('LookUpActiveStateTaxCode'),
        active: pathname.includes('/taxcode-info'),
        iconElement: <i className="fa-regular fa-magnifying-glass" />,
        icon: 'fa-regular fa-magnifying-glass',
        onPress: () => {
          router.navigate('taxcode-info');
        },
      },
      {
        text: t('LookUpValueAddedTaxReductionGoods'),
        active: pathname.includes('/tax-reduced-items'),
        iconElement: <i className="fa-regular fa-shop" />,
        icon: 'fa-regular fa-shop',
        onPress: () => {
          router.navigate('tax-reduced-items');
        },
      },
      {
        text: t('LookUpDocumentGuidingTheReductionOfValueAddedTax'),
        active: pathname.includes('/tax-reduced-documents'),
        iconElement: <i className="fa-regular fa-file" />,
        icon: 'fa-regular fa-file',
        onPress: () => {
          router.navigate('tax-reduced-documents');
        },
      },
      {
        text: t('LookupRiskyBusinesses'),
        active: pathname.includes('/risky-businesses'),
        iconElement: <i className="fa-regular fa-building" />,
        icon: 'fa-regular fa-building',
        onPress: () => {
          router.navigate('risky-businesses');
        },
      },
    ];
    return itemList;
  }, [t, pathname]);

  return (
    <div className="d-flex flex-row h-100">
      {/* aside menu */}
      <div className="flex-row-auto offcanvas-mobile h-100">
        <div
          className="card card-custom h-100"
          style={{
            borderRadius: '16px',
          }}
        >
          <div className="card-body p-4 mt-6">
            <KTAdvanceNav
              enableHoverStyle
              enableLinkRounded={true}
              enableActiveStyle
              enableAccentStyle
              fontWeight={KTAdvanceNavFontWeight.bold}
              itemList={navItemList}
            />
          </div>
        </div>
      </div>

      {/* content */}
      <div
        className="flex-row-fluid ml-lg-6"
        style={{
          borderRadius: '16px',
        }}
      >
        <div className={`h-100`}>
          <Routes>
            <Route path="" element={<Navigate to="personal-taxcode-info" />} />
            <Route
              path="personal-taxcode-info/*"
              element={<PersonalTaxCodeInfoScreen navItemList={navItemList} />}
            />
            <Route
              path="taxcode-info/*"
              element={<TaxCodeInfoScreen navItemList={navItemList} />}
            />
            <Route
              path="tax-reduced-items/*"
              element={<TaxReducedItemScreen navItemList={navItemList} />}
            />
            <Route
              path="tax-reduced-documents/*"
              element={<TaxReducedDocumentScreen navItemList={navItemList} />}
            />
            <Route
              path="risky-businesses/*"
              element={<RiskyBusinessesScreen navItemList={navItemList} />}
            />

            <Route path="*" element={<Navigate to="personal-taxcode-info" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Tool;
