import React, { useEffect, useMemo, useRef, useState } from 'react';
import VIButton from 'general/components/VietInvoice/VIButton';
import { useTranslation } from 'react-i18next';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import DropdownFilterDeleteInvoice from 'features/Invoice/screens/components/DropdownFilterDeleteInvoice';
import VIModalResize from 'general/components/VietInvoice/VIModalResize';
import './style.scss';
import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import Utils from 'general/utils/Utils';
import { useDispatch } from 'react-redux';
import useAccount from 'hooks/useAccount';
import { thunkGetAllDeleteInInvoice, thunkGetAllInInvoice } from 'features/Invoice/invoiceSlice';
import Global from 'general/utils/Global';
import { useSelector } from 'react-redux';
import invoiceApi from 'api/invoiceApi';
import ToastHelper from 'general/helpers/ToastHelper';
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import InvoiceHelper from 'general/helpers/InvoiceHelper';
import DownLoadInvoice from 'features/Invoice/screens/components/DownLoadInvoice';
import VIModal from 'general/components/VietInvoice/VIModal';
import ModalFeedback from '../ModalFeedback';
import VILoading from 'general/components/VietInvoice/VILoading';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import KTTooltip, { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip';
import LookUpInvoice from 'features/Invoice/screens/components/LookUpInvoices';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import store from 'app/store';
import { useWindowSize } from '@uidotdev/usehooks';

function DeleteInvoices(props) {
  const { tabsActive } = props;
  const { t } = useTranslation();
  const [showModalDetailInvoices, setShowModalDetailInvoices] = useState(false);
  const dispatch = useDispatch();
  const account = useAccount();

  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId]
      ? JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId]
      : Global.gFiltersInputInvoiceDelete
  );
  const {
    listInputInvoiceDelete,
    isGettingInInvoiceListDelete,
    totalInputInvoiceDelete,
    totalAmountBeforeVATInputInvoiceDelete,
    totalAmountVATInputInvoiceDelete,
    totalFinalAmountInputInvoiceDelete,
  } = useSelector((state) => state?.invoice);
  const [showModalDownLoad, setShowModalDownLoad] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [currentDetail, setCurrentDetail] = useState();
  const [currentInvoices, setCurrentInvoices] = useState();
  const [showModalFeedback, setShowModalFeedback] = useState(false);
  const [modalLookUpInvoice, setModalLookUpInvoice] = useState(false);
  useEffect(() => {
    if (_.isEmpty(listInputInvoiceDelete)) {
    }
    dispatch(thunkGetAllDeleteInInvoice(filters));
  }, [filters, account.organizationId]);
  useEffect(() => {
    Global.gFiltersInputInvoiceDelete = {
      ...Global.gFiltersInputInvoiceDelete,
      page: 0,
    };
  }, [account.organizationId, account.organizationDepartmentId]);

  const handleActions = (row) => {
    let action = [];
    if (account?.role?.permissionCodes?.includes('DELETE_AND_RESTORE_INPUT_INVOICE')) {
      action.push({
        iconElement: <i className="fa-light fa-trash-can-undo"></i>,
        text: t('Restore'),
        click: async () => {
          const result = await invoiceApi.addInvoiceToTrash({
            invoiceIds: [row?.invoiceId],
            isDeleted: false,
          });
          if (result?.result === 'success') {
            ToastHelper.showSuccess(t('SuccessfulInvoiceRecovery'));
            dispatch(thunkGetAllDeleteInInvoice(filters));
            setClearSelectedRows(!clearSelectedRows);

            setDataCheck([]);
          }
        },
      });
    }
    if (
      !row?.InvoiceValidate?.checkResultBuyerAddress ||
      !row?.InvoiceValidate?.checkResultBuyerName ||
      !row?.InvoiceValidate?.checkResultBuyerTaxCode ||
      !row?.InvoiceValidate?.checkResultHasCQTRecord ||
      !row?.InvoiceValidate?.checkResultHasInvoiceCode ||
      !row?.InvoiceValidate?.checkResultSellerAddress ||
      !row?.InvoiceValidate?.checkResultSellerName ||
      !row?.InvoiceValidate?.checkResultSignatureCQT ||
      !row?.InvoiceValidate?.checkResultSignatureNCC
    ) {
      action.push({
        iconElement: <i className="fa-regular fa-message-smile"></i>,
        text: t('SendFeedbackToNCC'),
        click: () => {
          setShowModalFeedback(true);
          setCurrentInvoices(row);
          forMikFeedBack.setValues({
            ...forMikFeedBack.values,
            invoiceId: row?.invoiceId,
            emails: row?.partnerCompany?.contactEmail ?? '',
            html: `<p style="margin-left:0px;"><strong>Kính gửi: ${
              row?.sellerName
            }</strong></p><p style="margin-left:0px;">${
              account.organizationName + ' ' + 'đã nhận được hóa đơn dưới đây'
            }:</p><p style="margin-left:0px;">- Số hóa đơn: <strong>${
              row?.invoiceNumber
            }</strong></p><p style="margin-left:0px;">- Mẫu số hóa đơn: <strong>${
              row?.invoiceTypeCode
            }</strong></p><p style="margin-left:0px;">- Ký hiệu hóa đơn: <strong>${
              row?.serial
            }</strong></p><p style="margin-left:0px;">&nbsp;</p><p style="margin-left:0px;">Sau khi kiểm tra hóa đơn, chúng tôi nhận thấy các thông tin sau chưa chính xác. Đề nghị quý công ty xem xét lại:</p><p style="margin-left:0px;">&nbsp;</p>${
              !row?.InvoiceValidate?.checkResultSellerAddress
                ? `<p style="margin-left:0px;">-Sai địa chỉ người bán</p>`
                : ''
            }${
              !row?.InvoiceValidate?.checkResultSellerName
                ? `<p style="margin-left:0px;">-Sai tên người bán</p>`
                : ''
            }${
              !row?.InvoiceValidate?.checkResultBuyerAddress
                ? `<p style="margin-left:0px;">-Sai địa chỉ người mua</p>`
                : ''
            }${
              !row?.InvoiceValidate?.checkResultBuyerName
                ? `<p style="margin-left:0px;">-Sai tên người mua</p>`
                : ''
            }${
              !row?.InvoiceValidate?.checkResultBuyerTaxCode
                ? `<p style="margin-left:0px;">-Sai mã số thuế người mua</p>`
                : ''
            }${
              !row?.InvoiceValidate?.checkResultHasCQTRecord
                ? `<p style="margin-left:0px;">
            -Hóa đơn chưa tổn tại trên hệ thống tra cứu Cơ quan thuế tại
            thời điểm kiểm tra
          </p>`
                : ''
            }${
              !row?.InvoiceValidate?.checkResultHasInvoiceCode
                ? `<p style="margin-left:0px;">-Chưa cấp mã hóa đơn</p>`
                : ''
            }${
              !row?.InvoiceValidate?.checkResultSignatureCQT
                ? ` <p style="margin-left:0px;">-Chưa được ký bởi Cơ quan thuế</p>`
                : ''
            }${
              !row?.InvoiceValidate?.checkResultSignatureNCC
                ? `<p style="margin-left:0px;">-Chữ ký điện tử NCC không hợp lệ</p>`
                : ''
            }<p style="margin-left:0px;">&nbsp;</p><p style="margin-left:0px;">Trân trọng kính chào!</p><p style="margin-left:0px;">&nbsp;</p>`,
          });
        },
      });
    }

    return action;
  };
  const columns = useMemo(
    () => [
      {
        name: t('InvoiceNumber'),
        minWidth: '135px',
        selector: (row) => row.invoiceNumber,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceNumber ? row?.invoiceNumber : ''}
            </p>
          );
        },
      },
      {
        name: t('Denominator'),
        width: '98px',
        selector: (row) => row.invoiceTypeCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceTypeCode ? row?.invoiceTypeCode : ''}
            </p>
          );
        },
      },
      {
        name: t('Symbol'),
        width: '100px',
        selector: (row) => row.serial,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.serial ? row?.serial : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceDate'),
        width: '150px',
        selector: (row) => row.invoiceDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceDate ? Utils.formatDateTime(row?.invoiceDate, 'DD/MM/yyyy' ?? '') : ''}
            </p>
          );
        },
      },
      {
        name: t('SignDate'),
        width: '115px',
        selector: (row) => row.signDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.signDate ? Utils.formatDateTime(row?.signDate, 'DD/MM/yyyy') : ''}
            </p>
          );
        },
      },
      {
        name: t('CodeIssueDate'),
        width: '145px',
        selector: (row) => row.providedCodeDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.providedCodeDate
                ? Utils.formatDateTime(row?.providedCodeDate, 'DD/MM/yyyy')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('UnitCode'),
        width: '120px',
        selector: (row) => row.sellerCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.sellerCode ? row?.sellerCode : ''}
            </p>
          );
        },
      },
      {
        name: t('SellerTaxCode'),
        width: '200px',
        selector: (row) => row.sellerTaxCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.sellerTaxCode ? row?.sellerTaxCode : ''}
            </p>
          );
        },
      },
      {
        name: t('SellerInformations'),
        minWidth: '250px',
        selector: (row) => row.sellerName,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.sellerName ? row?.sellerName : ''}
            </p>
          );
        },
      },
      {
        name: t('SellerAddress'),
        minWidth: '250px',
        selector: (row) => row.sellerAddress,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.sellerAddress ? row?.sellerAddress : ''}
            </p>
          );
        },
      },
      {
        name: t('TotalPreTaxMoney'),
        minWidth: '215px',
        selector: (row) => row.amountBeforeVat,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.amountBeforeVat
                ? Utils.formatCurrency(row?.amountBeforeVat)
                : row?.amountBeforeVat}
            </p>
          );
        },
      },
      {
        name: t('taxMoney'),
        minWidth: '117px',
        selector: (row) => row.amountVat,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.amountVat ? Utils.formatCurrency(row?.amountVat) : row?.amountVat}
            </p>
          );
        },
      },
      {
        name: t('TotalPayment'),
        minWidth: '215px',
        selector: (row) => row.finalAmount,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.finalAmount ? Utils.formatCurrency(row?.finalAmount) : row?.finalAmount}
            </p>
          );
        },
      },
      {
        name: t('Currency'),
        selector: (row) => row.paymentCurrency,
        sortable: true,
        minWidth: '113px',

        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.paymentCurrency ? row?.paymentCurrency : ''}
            </p>
          );
        },
      },
      {
        name: t('ExchangeRate'),
        selector: (row) => row.paymentExchangeRate,
        sortable: true,
        width: '90px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.paymentExchangeRate ? row?.paymentExchangeRate : ''}
            </p>
          );
        },
      },
      {
        name: t('TotalAmountConverted'),
        width: '182px',
        selector: (row) => row.finalAmountExchange,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p className="font-weight-normal m-0 text-maxline-3 text-align-end">
              {row?.finalAmountExchange ? Utils.formatNumber(row?.finalAmountExchange) : ''}
            </p>
          );
        },
      },
      {
        name: t('invoiceStatus'),
        width: '200px',
        selector: (row) => row.statusInvoiceText,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.statusInvoiceText ? row?.statusInvoiceText : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceAdjusted/Replaced'),
        minWidth: '290px',

        cell: (row) => {
          return row?.statusInvoiceText === 'Hoá đơn thay thế' ? (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {`Thay thế cho hóa đơn ký hiệu ${
                row?.hdgocData?.khmshdon ? row?.hdgocData?.khmshdon : ''
              }${row?.hdgocData?.khhdon ? row?.hdgocData?.khhdon : ''}, số ${
                row?.hdgocData?.shdon ? row?.hdgocData?.shdon : ''
              }, ngày ${
                row?.hdgocData?.tdlap
                  ? Utils.formatDateTime(row?.hdgocData?.tdlap, 'DD/MM/yyyy' ?? '')
                  : ''
              }`}
            </p>
          ) : row?.statusInvoiceText === 'Hoá đơn điều chỉnh' ? (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {`Điều chỉnh cho hóa đơn ký hiệu ${
                row?.hdgocData?.khmshdon ? row?.hdgocData?.khmshdon : ''
              }${row?.hdgocData?.khhdon ? row?.hdgocData?.khhdon : ''}, số ${
                row?.hdgocData?.shdon ? row?.hdgocData?.shdon : ''
              }, ngày ${
                row?.hdgocData?.tdlap
                  ? Utils.formatDateTime(row?.hdgocData?.tdlap, 'DD/MM/yyyy' ?? '')
                  : ''
              }`}
            </p>
          ) : (
            ''
          );
        },
      },
      {
        name: t('TestResults'),
        width: '320px',

        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              {Utils.getInvoiceStatusValidate(
                row?.InvoiceValidate,
                row?.serial,
                row?.isManualInput,
                row
              )}
            </div>
          );
        },
      },
      {
        name: t('CheckTaxRates'),
        minWidth: '195px',
        selector: (row) => row.statusTax,
        sortable: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.statusTax ?? ''}
            </p>
          );
        },
      },
      {
        name: t('EmailToReceiveInvoice'),
        minWidth: '190px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.OrganizationDepartment
                ? store.getState()?.auth.account?.OrganizationDepartment?.invoiceMailbox
                : store.getState()?.auth.account?.Organization?.invoiceMailbox ?? ''}
            </p>
          );
        },
      },
      {
        name: t('EmailSendingStatus'),
        width: '190px',
        // center: true,
        // selector: (row) => row.statusEmail,
        // sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.isSendMailSupplier ? 'Đã gửi' : ''}
            </p>
          );
        },
      },
      {
        name: t('LookUpOriginalInvoice'),
        width: '200px',
        center: true,

        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 "
              style={{ width: '100%' }}
            >
              <span
                className="d-flex align-items-center py-5 px-4"
                style={{ color: '#22C55E' }}
                onClick={() => {
                  setCurrentInvoices(row), setModalLookUpInvoice(true);
                }}
              >
                <i className="fa-regular fa-lightbulb-on me-1" style={{ color: '#22C55E' }}></i>
                Thông tin tra cứu
              </span>
            </p>
          );
        },
      },
      {
        name: t('PaymentDueDate'),
        width: '185px',
        selector: (row) => row.reminderPaymentDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.reminderPaymentDate
                ? Utils.formatDateTime(row?.reminderPaymentDate, 'DD/MM/YYYY')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('PaymentTerm'),
        width: '168px',
        selector: (row) => row.expiredPaymentDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.expiredPaymentDate
                ? Utils.formatDateTime(row?.expiredPaymentDate, 'DD/MM/YYYY')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('TaxCodeCompany'),
        minWidth: '130px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.Organization?.OrganizationHQ
                ? store.getState()?.auth.account?.Organization?.OrganizationHQ?.taxCode
                : store.getState()?.auth.account?.Organization?.taxCode ?? ''}
            </p>
          );
        },
      },
      {
        name: t('Company'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.Organization?.OrganizationHQ
                ? store.getState()?.auth.account?.Organization?.OrganizationHQ?.organizationName
                : store.getState()?.auth.account?.Organization?.organizationName ?? ''}
            </p>
          );
        },
      },
      {
        name: t('TaxCodeBrand'),
        minWidth: '150px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.Organization?.organizationType === 'BRANCH'
                ? store.getState()?.auth.account?.Organization?.taxCode
                : '' ?? ''}
            </p>
          );
        },
      },
      {
        name: t('Branch'),
        minWidth: '250px',
        // selector: (row) => row.supplier,
        // sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.Organization?.organizationType === 'BRANCH'
                ? store.getState()?.auth.account?.Organization?.organizationName
                : '' ?? ''}
            </p>
          );
        },
      },
      {
        name: t('PurchaserName'),
        minWidth: '250px',
        selector: (row) => row.buyerName,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerName ? row?.buyerName : ''}
            </p>
          );
        },
      },
      {
        name: t('BuyerAddress'),
        minWidth: '250px',
        selector: (row) => row.buyerAddress,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerAddress ? row?.buyerAddress : ''}
            </p>
          );
        },
      },
      {
        name: t('Buyer'),
        minWidth: '200px',
        selector: (row) => row.buyerPersonName,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerPersonName ? row?.buyerPersonName : ''}
            </p>
          );
        },
      },
      {
        name: t('Department'),
        minWidth: '250px',
        // selector: (row) => row.supplier,
        // sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.OrganizationDepartment?.departmentName ?? ''}
            </p>
          );
        },
      },

      {
        name: t('InvoiceReceiptDate'),
        minWidth: '195px',
        selector: (row) => row.invoiceReceiveDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceReceiveDate
                ? Utils.formatDateTime(row.invoiceReceiveDate, 'DD/MM/YYYY')
                : ''}
            </p>
          );
        },
      },

      {
        name: t('TotalPaymentSuccess'),
        minWidth: '225px',
        // selector: (row) => row.amountBeforeVat,
        // sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.debtPayment ? Utils.formatCurrency(row?.finalAmount - row.debtPayment) : ''}
            </p>
          );
        },
      },
      {
        name: t('UnpaidMoney'),
        minWidth: '215px',
        right: true,
        selector: (row) => row.debtPayment,
        sortable: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.debtPayment ? Utils.formatCurrency(row?.debtPayment) : ''}
            </p>
          );
        },
      },
      {
        name: t('PaymentStatus'),
        width: '230px',
        center: true,
        selector: (row) => row.statusPayment,
        sortable: true,
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">{Utils.getInvoiceStatusPayment(row?.statusPayment)}</div>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            <VITableRowDropDown
              toggleElement={
                <div>
                  <i className="text-grey fas fa-ellipsis-h p-0"></i>
                </div>
              }
              menuItem={handleActions(row)}
            />
          </div>
        ),
      },
    ],
    [listInputInvoiceDelete]
  );

  const [pagination, setPagination] = useState({
    perPage: filters?.limit,
    total: totalInputInvoiceDelete,
    currentPage: filters?.page + 1,
    count: listInputInvoiceDelete?.length + filters.limit * filters.page,
  });
  useEffect(() => {
    setPagination({
      perPage: filters?.limit,
      total: totalInputInvoiceDelete,
      currentPage: filters?.page + 1,
      count: listInputInvoiceDelete?.length + filters.limit * filters.page,
    });
  }, [filters, listInputInvoiceDelete]);
  const [dataCheck, setDataCheck] = useState([]);

  // lọc hóa đơn
  function handleFilterInvoice(dataFilter) {
    setFilters({
      ...filters,
      fromDate: dataFilter?.fromDate,
      toDate: dataFilter?.toDate,
      status: dataFilter?.status,
      invoiceTypeCode: dataFilter?.invoiceTypeCode,
      invoiceSerialType: dataFilter?.invoiceSerialType,
    });
  }

  const refDropdownFilterDelete = useRef();
  const refDropdownFilterDeleteMenu = useRef();
  const refDateRangePickerFilter = useRef();

  // useOnClickOutside(refDropdownFilterDelete, () => {
  //   const isDateRangePickerDisplays = window?.getComputedStyle(
  //     refDateRangePickerFilter.current
  //   ).display;
  //   if (isDateRangePickerDisplays === 'block') {
  //     return;
  //   }
  //   if (refDropdownFilterDeleteMenu?.current) {
  //     if (refDropdownFilterDeleteMenu?.current.classList.contains('show')) {
  //       refDropdownFilterDeleteMenu?.current.classList.remove('show');
  //     } else {
  //     }
  //   }
  // });

  useEffect(() => {
    const el = document.getElementsByClassName('DropdownFilterMenu')?.item(0);
    if (el) {
      refDropdownFilterDeleteMenu.current = el;
    }

    if (document.getElementsByClassName('daterangepicker')?.item(1)) {
      refDateRangePickerFilter.current = document
        .getElementsByClassName('daterangepicker')
        ?.item(1);
    }
  }, []);
  const [countFilter, setCountFilter] = useState();

  useEffect(() => {
    let count = 0;
    if (
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[
        account.accountId
      ] !== undefined
    ) {
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId]
          ?.status !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId]
          ?.status
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId]
          ?.invoiceTypeCode !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId]
          ?.invoiceTypeCode
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId]
          ?.invoiceSerialType !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId]
          ?.invoiceSerialType
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId]
          ?.fromDate !== moment().subtract(29, 'days').format('YYYY-MM-DD') ||
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId]
          ?.toDate !== moment().format('YYYY-MM-DD')
      ) {
        count += 1;
      }
      setCountFilter(count);
    } else {
      setCountFilter(0);
    }
  }, [
    JSON.parse(localStorage.getItem(PreferenceKeys.saveInputDeleteInvoice))?.[account.accountId],
    account.accountId,
  ]);

  // tải xuống hóa đơn
  const [dataFormat, setDataFormat] = useState('0');
  let dataDownload = [];
  for (let i = 0; i < dataCheck.length; i++) {
    if (dataFormat === '0' && dataCheck[i]?.pdfFile) {
      dataDownload.push(dataCheck[i]);
    }
    if (dataFormat === '0' && dataCheck[i]?.previewPdfFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.previewPdfFile });
    }
    if (dataFormat === '0' && dataCheck[i]?.xmlFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.xmlFile });
    }
    if (dataFormat === '1' && dataCheck[i]?.pdfFile) {
      dataDownload.push(dataCheck[i]);
    }
    if (dataFormat === '1' && dataCheck[i]?.previewPdfFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.previewPdfFile });
    }
    if (dataFormat === '2' && dataCheck[i]?.xmlFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.xmlFile });
    }
  }
  const handleDownLoadInvoices = () => {
    const name = Global.gDownLoadZipName;
    const res = InvoiceHelper.DownLoadInvoices(name, dataDownload);
    setClearSelectedRows(!clearSelectedRows);
    setShowModalDownLoad(false);
    setDataCheck([]);
  };

  // Gửi phản hồi đến NCC
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const forMikFeedBack = useFormik({
    initialValues: {
      emails: currentInvoices?.partnerCompany?.contactEmail ?? '',
      subject: account.organizationName,
      invoiceId: currentInvoices?.invoiceId,
      html: `<p style="margin-left:0px;"><strong>Kính gửi: ${
        currentInvoices?.sellerName
      }</strong></p><p style="margin-left:0px;">${
        account.organizationName + ' ' + 'đã nhận được hóa đơn dưới đây'
      }:</p><p style="margin-left:0px;">- Số hóa đơn: <strong>${
        currentInvoices?.invoiceNumber
      }</strong></p><p style="margin-left:0px;">- Mẫu số hóa đơn: <strong>${
        currentInvoices?.invoiceTypeCode
      }</strong></p><p style="margin-left:0px;">- Ký hiệu hóa đơn: <strong>${
        currentInvoices?.serial
      }</strong></p><p style="margin-left:0px;">&nbsp;</p><p style="margin-left:0px;">Sau khi kiểm tra hóa đơn, chúng tôi nhận thấy các thông tin sau chưa chính xác. Đề nghị quý công ty xem xét lại:</p><p style="margin-left:0px;">&nbsp;</p><p style="margin-left:0px;">1. Sai địa chỉ người bán</p><p style="margin-left:0px;">&nbsp;</p><p style="margin-left:0px;">Trân trọng kính chào!</p><p style="margin-left:0px;">&nbsp;</p>`,
    },
    validationSchema: Yup.object({
      emails: Yup.string(t('EnterYourEmail'))
        .required(t('EnterYourEmail'))
        .email(t('EmailIsNotFormatted')),
      subject: Yup.string(t('EnterYourTitle')).required(t('EnterYourTitle')),
    }),
    onSubmit: async (values) => {
      setLoadingSendMail(true);
      try {
        const result = await invoiceApi.sendToEmailNCC({
          emails: [values?.emails],
          subject: values?.subject,
          invoiceId: values?.invoiceId,
          html: values?.html,
        });
        if (result.result === 'success') {
          forMikFeedBack.resetForm(), setShowModalFeedback(false);
          ToastHelper.showSuccess(t('SendFeedbackToSupplierSuccessfully'));
          dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
          setDataCheck([]);
          setClearSelectedRows(!clearSelectedRows);
          setLoadingSendMail(false);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  useEffect(() => {
    if (account) {
      forMikFeedBack.setValues({
        ...forMikFeedBack.values,
        subject: account.organizationName + ' ' + 'phản hồi về hóa đơn điện tử của quý khách',
      });
    }
  }, [account]);
  const reSize = useWindowSize();
  return (
    <div className="position-relative Input-invoice">
      <div className="d-flex justify-content-between ">
        <div
          className="w-100 bg-white"
          style={{
            borderRadius: '0px 0px 20px 20px',
            borderLeft: '1px solid #dbe3ef',
            borderBottom: '1px solid #dbe3ef',
            borderRight: '1px solid #dbe3ef',
          }}
        >
          <VIDataTable
            tabsActive={tabsActive}
            selectable={true}
            pagination={pagination}
            tableStyles={customDataTableActionStyle}
            scrollHeight="48"
            totalPreTaxMoney={totalAmountBeforeVATInputInvoiceDelete}
            totalTaxAmount={totalAmountVATInputInvoiceDelete}
            totalValue={totalFinalAmountInputInvoiceDelete}
            clearSelectedRows={clearSelectedRows}
            onChangePage={(page) => {
              let iNewPage = parseInt(page);
              iNewPage -= 1;
              if (iNewPage < 0) {
                iNewPage = 0;
              }
              Global.gFiltersInputInvoiceDelete = {
                ...Global.gFiltersInputInvoiceDelete,
                page: iNewPage,
              };
              setFilters({
                ...filters,
                page: iNewPage,
              });
            }}
            onChangeRowsPerPage={(perPage) => {
              Global.gFiltersInputInvoiceDelete = {
                ...Global.gFiltersInputInvoiceDelete,
                limit: perPage,
              };
              setFilters({
                ...filters,
                limit: perPage,
                page: 0,
              });
            }}
            fullColumns={columns}
            data={listInputInvoiceDelete}
            loading={isGettingInInvoiceListDelete}
            inputSearch={
              <>
                {dataCheck?.length === 0 && (
                  <div className="d-flex justify-content-between w-100">
                    <KTTooltip
                      className="w-100"
                      text="Nhập số hóa đơn, tên nhà cung cấp, mã số thuế, số chứng từ, ghi chú để tìm kiếm"
                      position={KTTooltipPositions.bottom}
                    >
                      <div className="w-100 h-100">
                        <VISearchField
                          placeholder="Nhập số hóa đơn, tên nhà cung cấp, mã số thuế, số chứng ..."
                          value={filters.q}
                          className="w-100 d-flex align-items-center"
                          typingTimeout={500}
                          onSubmit={(newValue) => {
                            Global.gFiltersInputInvoiceDelete = {
                              ...Global.gFiltersInputInvoiceDelete,
                              q: newValue,
                              page: 0,
                            };
                            setFilters({
                              ...filters,
                              q: newValue,
                              page: 0,
                            });
                          }}
                        />
                      </div>
                    </KTTooltip>
                  </div>
                )}
              </>
            }
            filterbar={
              <div className="show-func">
                {dataCheck?.length > 0 && (
                  <div className=" d-flex flex-wrap">
                    <div
                      className="px-3 py-3 me-3 mt-2"
                      style={{
                        background: 'rgba(81, 128, 251, 0.10)',
                        borderRadius: '8px',
                        color: '#5180FB',
                      }}
                    >
                      {t('Selected')} {dataCheck?.length} {t('Bill')}
                    </div>
                    <VIButton
                      text={t('Download')}
                      beforeIcon={<i className="fa-solid fa-download"></i>}
                      className="btn-white me-3 mt-2"
                      style={{ padding: '8px' }}
                      onClick={() => {
                        setShowModalDownLoad(true);
                      }}
                      afterIcon={<></>}
                    />
                  </div>
                )}
              </div>
            }
            onRowClick={(row) => {
              setShowModalDetailInvoices(true);
              setCurrentDetail(row);

              // console.log(row);
            }}
            onSetSelectedMultiItems={(rows) => setDataCheck(rows)}
            toolbar={
              <>
                {dataCheck?.length === 0 && (
                  <div className="">
                    <KTBSDropdown
                      contentEl={
                        <div ref={refDropdownFilterDelete}>
                          <DropdownFilterDeleteInvoice
                            handleFilterInvoice={handleFilterInvoice}
                            refDropdownFilterDeleteMenu={refDropdownFilterDeleteMenu}
                          />
                        </div>
                      }
                      dropdownMenuClassName="DropdownFilterMenu"
                      toggleElement={
                        <div className="d-flex align-items-center font-weight-bold justify-content-between DropdownFilter ">
                          <i
                            className="fa-regular fa-filter-list me-2"
                            style={{ color: '#1c2256' }}
                          ></i>
                          <span className="d-flex">
                            {t('FilterInvoices')}{' '}
                            {countFilter !== 0 && (
                              <div className="ms-1" style={{ color: '#F44141' }}>
                                ({countFilter})
                              </div>
                            )}
                          </span>
                        </div>
                      }
                      alignment={reSize?.width < 540 ? '' : KTBSDropdownAlignments.end}
                      autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                    />
                  </div>
                )}
              </>
            }
          />
        </div>
      </div>

      <VIModalResize
        show={showModalDetailInvoices}
        onHide={() => {
          setShowModalDetailInvoices(false), setCurrentDetail(null);
        }}
        currentDetail={currentDetail}
        listData={listInputInvoiceDelete}
        clearSelectedRows={clearSelectedRows}
        setClearSelectedRows={setClearSelectedRows}
        setDataCheck={setDataCheck}
      />
      <VIModal
        titleModal={t(`DownLoad`)}
        show={showModalDownLoad}
        onHide={() => setShowModalDownLoad(false)}
        contentModal={<DownLoadInvoice setDataFormat={setDataFormat} dataFormat={dataFormat} />}
        closeBtn={true}
        handleSubmit={() => {
          handleDownLoadInvoices();
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={t('Download')}
      />
      <VIModal
        titleModal={t(`SendALetterToTheSupplier`)}
        modalSize="lg"
        show={showModalFeedback}
        onHide={() => setShowModalFeedback(false)}
        contentModal={<ModalFeedback formik={forMikFeedBack} />}
        closeBtn={true}
        handleSubmit={() => {
          forMikFeedBack.handleSubmit();
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={
          !loadingSendMail ? (
            t('Mailing')
          ) : (
            <div className="d-flex align-items-center">
              {t('Mailing')}
              <div className="ms-2 ">
                <VILoading size="sm" variant="white" />
              </div>
            </div>
          )
        }
      />
      <VIModal
        titleModal={t('SearchSuggestions')}
        show={modalLookUpInvoice}
        contentModal={<LookUpInvoice currentLookUp={currentInvoices} />}
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalLookUpInvoice(false);
        }}
        buttonNegative={t('Close')}
        modalSize="lg"
      />
    </div>
  );
}

export default DeleteInvoices;
