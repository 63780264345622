import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KeenSelectOption from 'general/components/OtherKeenComponents/KeenSelectOption';
import AppResource from 'general/constants/AppResource';
import React from 'react';

function DownLoadInvoice(props) {
  const { dataFormat, setDataFormat } = props;
  const downLoadListInvoice = [
    {
      name: 'Tất Cả',
      value: '0',
    },
    {
      name: 'Bản thể hiện',
      value: '1',
    },
    {
      name: 'XML',
      value: '2',
    },
    {
      name: 'Hóa đơn gốc',
      value: '3',
    },
  ];

  return (
    <div>
      {/* anh banner */}
      <div className="bg-default">
        <img src={AppResource.images.imgExportInvoice} className="w-100" />
      </div>
      <div className="mt-3">
        <KTFormGroup
          label={'Chọn định dạng hóa đơn'}
          additionalClassName="mb-0"
          inputName="downLoadListInvoice"
          inputElement={
            <KeenSelectOption
              name={'downLoadListInvoice'}
              initialValue={dataFormat}
              options={downLoadListInvoice}
              containerClassName=""
              labelClassName=""
              selectClassName="w-100"
              onValueChanged={(value) => {
                setDataFormat(value);
              }}
            />
          }
        />
      </div>
    </div>
  );
}

export default DownLoadInvoice;
