import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import DropdownHeaderChooseCompany from 'features/Others/components/DropdownHeaderChooseCompany';
import EmptyView from 'features/Others/components/EmptyView';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import ToastHelper from 'general/helpers/ToastHelper';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

ViewChooseOrganizationByBuyerTaxCode.propTypes = {
  onPressedCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

ViewChooseOrganizationByBuyerTaxCode.defaultProps = {
  onPressedCancel: null,
  onSubmit: null,
};

/**
 *
 * @param {{
 * onPressedCancel: function,
 * onSubmit: function,
 * }} props
 * @returns
 */
function ViewChooseOrganizationByBuyerTaxCode(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { onPressedCancel, onSubmit } = props;
  const { organizations, listAutoTransferByBuyerTaxCode } = useSelector(
    (state) => state?.organization
  );
  const [selectedOrganization, setSelectedOrganization] = useState();
  const refViewChooseOrganizationContent = useRef();
  const [listItems, setListItems] = useState([]);
  const columns = useMemo(() => {
    return [
      {
        name: t('STT'),
        sortable: false,
        center: true,
        width: '80px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {index + 1}
            </p>
          );
        },
      },
      {
        name: t('BuyerTaxCode'),
        sortable: false,
        width: '180px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.taxCode}
            </p>
          );
        },
      },
      {
        name: t('UnitName'),
        sortable: false,
        minWidth: '220px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.organizationName ?? row?.departmentName}
            </p>
          );
        },
      },
      {
        name: t('EmailReceiveInvoice'),
        sortable: false,
        style: {
          borderRight: 0,
        },
        minWidth: '260px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.invoiceMailbox}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            <KTTooltip text={t('Delete')}>
              <i
                className="fa-regular fa-trash text-danger"
                onClick={() => {
                  const tmp = listItems.filter(
                    (item) => item.invoiceMailbox !== row.invoiceMailbox
                  );
                  setListItems(tmp);
                }}
              />
            </KTTooltip>
          </div>
        ),
      },
    ];
  }, [t, listItems]);
  // console.log({ listItems });

  useEffect(() => {
    const tmp = listAutoTransferByBuyerTaxCode.map(
      (item) => item?.Organization ?? item?.OrganizationDepartment
    );
    setListItems(tmp);
  }, [listAutoTransferByBuyerTaxCode]);

  useEffect(() => {
    const el = document.getElementsByClassName('DropdownViewChooseOrganizationContent')?.item(0);
    if (el) {
      refViewChooseOrganizationContent.current = el;
    }
  }, []);

  // ---- methods ----
  function handlePressedCancel() {
    if (onPressedCancel) {
      onPressedCancel();
    }
  }

  function handleSave() {
    if (onSubmit) {
      handlePressedCancel();
      onSubmit(listItems);
    }
  }

  function handleHideViewChooseOrganizationContent() {
    if (refViewChooseOrganizationContent?.current) {
      if (refViewChooseOrganizationContent?.current.classList.contains('show')) {
        refViewChooseOrganizationContent?.current.classList.remove('show');
      }
    }
  }

  return (
    <div>
      <div>
        <div className="d-flex align-items-center justify-content-between gap-3">
          <KTBSDropdown
            dropdownMenuClassName="DropdownViewChooseOrganizationContent"
            contentEl={
              <div className="p-4">
                <div className="">
                  <KTFormGroup
                    inputName="buyerTaxCodeOnInvoice"
                    additionalClassName="mb-4"
                    label={
                      <>
                        Mã số thuế người mua trên hoá đơn <span className="text-danger">(*)</span>
                      </>
                    }
                    inputElement={
                      <KTFormInput
                        name="buyerTaxCodeOnInvoice"
                        value={selectedOrganization?.taxCode}
                        onChange={(value) => {}}
                        onBlur={() => {}}
                        // enableCheckValid
                        // isValid={_.isEmpty(meta.error)}
                        // isTouched={meta.touched}
                        // feedbackText={meta.error}
                        type={KTFormInputType.email}
                        enableInputGroup
                        prependElements={[<i className="fa-regular fa-envelope" />]}
                      />
                    }
                  />
                </div>
                <KTFormGroup
                  inputName="chooseOrganization"
                  additionalClassName="mb-0"
                  label={<>Chi nhánh/phòng ban chuyển đến</>}
                  inputElement={
                    <DropdownHeaderChooseCompany
                      currentItem={selectedOrganization}
                      showViewAll={false}
                      tree={organizations}
                      footerEl={
                        <div className="d-flex align-items-center justify-content-center">
                          <VIButton
                            text={t('Add')}
                            className="btn-blue w-100"
                            style={{ padding: '8px' }}
                            onClick={() => {
                              if (selectedOrganization) {
                                // kiem tra trung lap
                                const isExisted = _.some(
                                  listItems,
                                  (item) =>
                                    item.taxCode === selectedOrganization.taxCode &&
                                    item.invoiceMailbox === selectedOrganization.invoiceMailbox
                                );
                                if (isExisted) {
                                  ToastHelper.showError(
                                    'Cơ cấu tổ chức đang chọn đã tồn tại trong danh sách'
                                  );
                                  return;
                                }
                                const tmp = [...listItems];
                                tmp.push(selectedOrganization);
                                setListItems(tmp);
                              }
                              handleHideViewChooseOrganizationContent();
                            }}
                            beforeIcon={<i className="fa-regular fa-check" />}
                          />
                        </div>
                      }
                      onChange={(item) => {
                        setSelectedOrganization(item);
                        // const tmp = [...listItems];
                        // // kiem tra da ton tai trong ds chua?
                        // if (
                        //   _.findIndex(
                        //     listItems,
                        //     (obj) => obj.organization?.invoiceMailbox === item.invoiceMailbox
                        //   ) !== -1
                        // ) {
                        //   // ToastHelper.showError(
                        //   //   `Đã tồn tại email nhận hoá đơn: ${item.invoiceMailbox} trong danh sách`
                        //   // );
                        //   return;
                        // }
                        // tmp.push({
                        //   organization: item,
                        //   suppliers: [],
                        // });
                        // setListItems(tmp);
                      }}
                    />
                  }
                />
              </div>
            }
            toggleElement={
              <VIButton
                text={t('AddNew')}
                className=""
                style={{ padding: '8px' }}
                onClick={() => {}}
                beforeIcon={<i className="fa-regular fa-add" />}
              />
            }
            alignment={KTBSDropdownAlignments.start}
            autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
          />
        </div>

        <VIDataTable
          draggable={false}
          tableStyles={customDataTableActionStyle}
          additionalClassName="p-0 shadow-none mt-2"
          additionalCardBodyClassName="p-0"
          selectable={false}
          pagination={null}
          onChangePage={(page) => {}}
          onChangeRowsPerPage={(perPage) => {}}
          fullColumns={columns}
          data={listItems}
          loading={false}
          onRowClick={(row) => {}}
          onSetSelectedMultiItems={(rows) => {}}
          haveCardHeader={false}
          emptyComponent={<EmptyView message="Không có bản ghi nào" />}
          minusHeight={340}
        />
      </div>

      {/* actions */}
      <div className="d-flex align-items-center justify-content-end gap-3 mt-3">
        <VIButton
          text={t('Cancel')}
          className="btn-grey"
          style={{ padding: '8px' }}
          onClick={handlePressedCancel}
        />
        <VIButton text={t('Save')} className="" style={{ padding: '8px' }} onClick={handleSave} />
      </div>
    </div>
  );
}

export default ViewChooseOrganizationByBuyerTaxCode;
