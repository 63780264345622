import React, { createRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import LogHelper from 'general/helpers/LogHelper';

export const KTOffcanvasPosition = {
  left: 'left',
  right: 'right',
};

AppOffcanvas.propTypes = {
  id: PropTypes.string.isRequired,
  position: PropTypes.oneOf(Object.values(KTOffcanvasPosition)),
  toggleByElementId: PropTypes.string.isRequired,
  closeByElementId: PropTypes.string,
  bodyEl: PropTypes.element,
};

AppOffcanvas.defaultProps = {
  position: KTOffcanvasPosition.left,
  closeByElementId: '',
  bodyEl: <></>,
};

const sTag = '[AppOffcanvas]';

/**
 *
 * @param {{
 * id: string,
 * position: string,
 * toggleByElementId: string,
 * closeByElementId: string,
 * }} props
 * @returns
 */
function AppOffcanvas(props) {
  // MARK: --- Params ---
  const { id, position, toggleByElementId, closeByElementId, bodyEl } = props;
  const refOffcanvas = useRef(null);

  // MARK: --- Hooks ---
  useEffect(() => {
    if (typeof KTOffcanvas !== undefined && refOffcanvas.current === null) {
      const offcanvas = new KTOffcanvas(id, {
        baseClass: 'offcanvas',
        overlay: true,
        closeBy: closeByElementId,
        toggleBy: {
          target: toggleByElementId,
        },
      });
      refOffcanvas.current = offcanvas;
    }

    return () => {};
  }, [toggleByElementId, closeByElementId]);

  return (
    <div
      id={id}
      className={`offcanvas offcanvas-${position} bg-white d-flex flex-column h-100 visible`}
      style={{
        zIndex: '9999',
      }}
    >
      {/* <p>Offcanvas</p>
      <button
        onClick={() => {
          refOffcanvas.current.hide();
        }}
      >
        Click Me
      </button> */}
      {bodyEl}
    </div>
  );
}

export default AppOffcanvas;
