import customDataTableStyle from 'assets/styles/customDataTableStyle';
import { thunkFindMSTFromCCCD } from 'features/Tools/toolSlice';
import KTFormTextArea from 'general/components/OtherKeenComponents/Forms/KTFormTextArea';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ClockLoader } from 'react-spinners';

PersonalTaxCodeInfoScreen.propTypes = {
  navItemList: PropTypes.array,
};

PersonalTaxCodeInfoScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function PersonalTaxCodeInfoScreen(props) {
  // ---- params ----
  const { navItemList } = props;
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      {
        name: t('CMND/CCCD'),
        sortable: false,
        width: '140px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 font-weight-bold m-0">
              {row?.cccd ?? row?.query}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        sortable: false,
        width: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 font-weight-bold m-0">
              {row?.taxCode}
            </p>
          );
        },
      },
      {
        name: t('LegalRepresentative'),
        sortable: false,
        minWidth: '220px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.companyName}
            </p>
          );
        },
      },
      {
        name: t('Address'),
        sortable: false,
        minWidth: '260px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.address}
            </p>
          );
        },
      },
      {
        name: t('ActiveDate'),
        sortable: false,
        width: '160px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.dateOfOperation ? Utils.formatDateTime(row?.dateOfOperation, 'DD/MM/YYYY') : ''}
            </p>
          );
        },
      },
      {
        name: t('ManagedBy'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.managedBy}
            </p>
          );
        },
      },
      {
        name: t('Status'),
        sortable: false,
        minWidth: '220px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.status}
            </p>
          );
        },
      },
      {
        name: t('DocumentRelated'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return <p data-tag="allowRowEvents" className="text-dark-75 m-0"></p>;
        },
      },
    ];
  }, [t]);
  const dispatch = useDispatch();
  const [textTaxCodes, setTextTaxCodes] = useState(Global.gFilterPersonalTaxCodeInfo.q);
  const { personalTaxCodeInfoItems, isGettingPersonalTaxCodeInfo } = useSelector(
    (state) => state.tool
  );
  const [queries, setQueries] = useState([]);
  const [exporting, setExporting] = useState(false);

  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  useEffect(() => {
    const breakpoint = /[,.\s]/;
    const arrTaxCodes = _.filter(
      textTaxCodes.split(breakpoint),
      (item) => !_.isEmpty(item.trim())
    )?.map((item) => item?.trim());
    setQueries(arrTaxCodes);
  }, [textTaxCodes]);

  // ---- methods -----
  async function findCompaniesByQueries() {
    if (queries.length > 20) {
      ToastHelper.showError('Bạn vui lòng chỉ nhập tối đa 20 mã');
      return;
    }
    dispatch(thunkFindMSTFromCCCD(JSON.stringify(queries)));
  }

  async function handleExport() {
    if (personalTaxCodeInfoItems?.length === 0) {
      return;
    }
    setExporting(true);
    const dataToExport = personalTaxCodeInfoItems.map((row) => {
      return {
        [t('CMND/CCCD')]: row?.query,
        [t('TaxCode')]: row?.taxCode,
        [t('LegalRepresentative')]: row?.companyName,
        [t('Address')]: row?.address,
        [t('ActiveDate')]: row?.dateOfOperation
          ? Utils.formatDateTime(row?.dateOfOperation, 'DD/MM/YYYY')
          : '',
        [t('ManagedBy')]: row?.managedBy,
        [t('Status')]: row?.status,
        [t('DocumentRelated')]: '',
      };
    });
    Utils.exportToCSV(dataToExport, 'personal_tax_code_info');
    setExporting(false);
  }

  return (
    <div className="h-100">
      <VIDataTable
        draggable={false}
        tableStyles={customDataTableStyle}
        title={
          <div className="d-flex align-items-center justify-content-center gap-1">
            <div className="d-block d-lg-none">
              <KTBSDropdown
                toggleElement={
                  <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                    <i className="fa-solid fa-bars text-grey" />
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                dropdownMenuItems={dropdownMenuItems}
                selectedValue={t('LookUpTaxCodeFromCCCD')}
                onChange={(newValue) => {}}
              />
            </div>{' '}
            {t('LookUpTaxCodeFromCCCD')}
          </div>
        }
        selectable={false}
        pagination={null}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
        }}
        onChangeRowsPerPage={(perPage) => {}}
        fullColumns={columns}
        data={personalTaxCodeInfoItems}
        loading={false}
        filterbar={
          <>
            <KTFormTextArea
              name="textAreaCCCD"
              text="Nhập tối đa 20 mã, mỗi mã phân cách nhau bởi dấu phẩy hoặc mỗi mã 1 dòng"
              placeholder="Nhập CMND/CCCD..."
              value={textTaxCodes}
              onChange={(value) => {
                Global.gFilterPersonalTaxCodeInfo.q = value;
                setTextTaxCodes(value);
              }}
            />
          </>
        }
        onRowClick={(row) => {}}
        onSetSelectedMultiItems={(rows) => {}}
        toolbar={
          <>
            <VIButton
              beforeIcon={
                isGettingPersonalTaxCodeInfo ? (
                  <ClockLoader color="white" size={20} />
                ) : (
                  <i className="fa-regular fa-search" />
                )
              }
              text={isGettingPersonalTaxCodeInfo ? `${t('LookingUp')}...` : t('LookUp')}
              className="btn-blue border-0 p-3"
              onClick={() => {
                findCompaniesByQueries(queries);
              }}
              disabled={isGettingPersonalTaxCodeInfo}
            />

            <VIButton
              beforeIcon={<i className="fa-regular fa-download" />}
              text={t('Download')}
              className="btn-blue border-0 p-3"
              onClick={handleExport}
            />
          </>
        }
      />
    </div>
  );
}

export default PersonalTaxCodeInfoScreen;
