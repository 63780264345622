import axiosClient from './axiosClient';

const organizationDepartmentApi = {
  // tao phong ban
  createNew: (params) => {
    const url = '/organization-department/create';
    return axiosClient.post(url, params);
  },

  // cap nhat thong tin phong ban
  updateInfo: (id, params) => {
    const url = `/organization-department/update/${id}`;
    return axiosClient.put(url, params);
  },

  // xoa phong ban
  delete: (ids) => {
    const url = '/organization-department/delete';
    return axiosClient.delete(url, {
      data: {
        organizationDepartmentIds: ids,
      },
    });
  },
};

export default organizationDepartmentApi;
