import axiosClient from './axiosClient';

const systemUserApi = {
  // lay danh sach tat ca nhan vien
  getAll: (params) => {
    const url = '/account/find';
    return axiosClient.get(url, { params });
  },

  // tao nhan vien
  createNew: (params) => {
    const url = '/account/create';
    return axiosClient.post(url, params);
  },

  // cap nhat thong tin nhan vien
  updateInfo: (id, params) => {
    const url = `/account/update/${id}`;
    return axiosClient.put(url, params);
  },

  // lay thong tin nhan vien
  getInfo: (id) => {
    const url = `/account/update/${id}`;
    return axiosClient.get(url);
  },

  // xoa nhan vien
  delete: (ids) => {
    const url = '/account/delete';
    return axiosClient.delete(url, {
      data: {
        accountIds: ids,
      },
    });
  },
};

export default systemUserApi;
