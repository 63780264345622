import { unwrapResult } from '@reduxjs/toolkit';
import store from 'app/store';
import {
  thunkCreateCompanyTitle,
  thunkCreateRole,
  thunkCreateSystemUser,
  thunkDeleteCompanyTitle,
  thunkDeleteRole,
  thunkDeleteSystemUsers,
  thunkGetAllCompanyTitles,
  thunkGetAllPermissionCodes,
  thunkGetAllSystemRoles,
  thunkGetAllSystemUsers,
  thunkGetAuditLogs,
  thunkGetPaymentHistory,
  thunkGetResourceHistory,
  thunkUpdateRole,
  thunkUpdateSystemUser,
} from 'features/System/systemSlice';
import AppData from 'general/constants/AppData';
import Global from 'general/utils/Global';
import { t } from 'i18next';
import ToastHelper from './ToastHelper';

const SystemHelper = {
  // goi api tao chuc danh moi
  callApiCreateCompanyTitle: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkCreateCompanyTitle(params)));
      const { result, companyTitle } = res;
      if (result === 'success' && companyTitle) {
        Global.gTempCompanyTitle = companyTitle;
        ToastHelper.showSuccess(t('CreateCompanyTitleSuccess'));
        store.dispatch(thunkGetAllCompanyTitles());
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api xoa chuc danh
  callApiDeleteCompanyTitle: async (ids) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkDeleteCompanyTitle(ids)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('DeleteCompanyTitleSuccess'));
        store.dispatch(thunkGetAllCompanyTitles());
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api cap nhat thong tin nhan vien
  callApiUpdateSystemUser: async (id, params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkUpdateSystemUser({ id, params })));
      const { result, account } = res;
      if (result === 'success' && account) {
        ToastHelper.showSuccess(t('UpdateSystemUserSuccess'));
        store.dispatch(thunkGetAllSystemUsers(Global.gFiltersAllUserList));
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api tao moi nhan vien
  callApiCreateSystemUser: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkCreateSystemUser(params)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('CreateSystemUserSuccess'));
        store.dispatch(thunkGetAllSystemUsers(Global.gFiltersAllUserList));
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api xoa nhan vien
  callApiDeleteSystemUsers: async (ids) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkDeleteSystemUsers(ids)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('DeleteSystemUsersSuccess'));
        store.dispatch(thunkGetAllSystemUsers(Global.gFiltersAllUserList));
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api tao role moi
  callApiCreateRole: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkCreateRole(params)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('CreateRoleSuccess'));
        store.dispatch(thunkGetAllSystemRoles({}));
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api xoa role
  callApiDeleteRole: async (ids) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkDeleteRole(ids)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('DeleteRoleSuccess'));
        store.dispatch(thunkGetAllSystemRoles({}));
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api cap nhat thong tin role
  callApiUpdateRole: async (id, params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkUpdateRole({ id, params })));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('UpdateRoleSuccess'));
        store.dispatch(thunkGetAllSystemRoles({}));
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay danh sach tat ca permission codes
  callApiGetAllPermissionCodes: async () => {
    try {
      unwrapResult(await store.dispatch(thunkGetAllPermissionCodes()));
      return true;
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay ds audit logs
  callApiGetAuditLogs: async (params) => {
    try {
      unwrapResult(await store.dispatch(thunkGetAuditLogs(params)));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  // chuc danh cua mot nhan vien
  getSystemUserTitle: (account) => {
    return account?.CompanyTitle?.name ?? '';
  },

  // vai tro cua mot nhan vien
  getSystemUserRole: (account) => {
    if (account?.isAdmin || account?.accountLevel === AppData.accountLevel.admin) {
      return t('Admin');
    } else {
      return account?.Role?.name ?? t('User');
    }
  },

  // goi api lay lich su mua hang
  callApiGetPaymentHistory: async (params) => {
    try {
      unwrapResult(await store.dispatch(thunkGetPaymentHistory(params)));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  // goi api lay lich su cap tai nguyen
  callApiGetResourceHistory: async (params) => {
    try {
      unwrapResult(await store.dispatch(thunkGetResourceHistory(params)));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

export default SystemHelper;
