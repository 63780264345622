import VIButton from 'general/components/VietInvoice/VIButton';
import VIDateRangePickerInput from 'general/components/VietInvoice/VIDateRangePicker';
import VISelectFilter from 'general/components/VietInvoice/VISelectFilter';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Global from 'general/utils/Global';
import { thunkGetAllInInvoice } from 'features/Invoice/invoiceSlice';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import PreferenceKeys from 'general/constants/PreferenceKeys';

function DropdownFilterInInvoice(props) {
  const { handleFilterInvoice, refDropdownFilterMenu } = props;
  const dispatch = useDispatch();

  const dataStatusDate = [
    {
      name: 'Hóa đơn',
      value: 1,
    },
    {
      name: 'Hạch toán',
      value: 2,
    },
    {
      name: 'Nhận hóa đơn',
      value: 3,
    },
    {
      name: 'Nhắc hạn thanh toán',
      value: 4,
    },
    {
      name: 'Quá hạn thanh toán',
      value: 5,
    },
    {
      name: 'Chưa thanh toán',
      value: 6,
    },
    {
      name: 'Thay đổi trạng thái trên CQT',
      value: 7,
    },
    {
      name: 'Phát hiện thay đổi trạng thái',
      value: 8,
    },
  ];
  const dataAccounTingStatus = [
    {
      name: 'Tất cả',
      value: 0,
    },
    {
      name: 'Đã hạch toán',
      value: 1,
    },
    {
      name: 'Chưa hạch toán',
      value: 2,
    },
  ];
  const dataStatusInvoice = [
    {
      name: 'Tất cả',
      value: 0,
    },
    {
      name: 'Đang kiểm tra',
      value: 1,
    },
    {
      name: 'Hợp lệ',
      value: 2,
    },
    {
      name: 'Không hợp lệ',
      value: 3,
    },
    {
      name: 'Không có bản thể hiện',
      value: 4,
    },
    {
      name: 'Bản nháp',
      value: 5,
    },
    {
      name: 'Không có XML',
      value: 6,
    },
  ];
  const account = useAccount();
  const { tagList } = useSelector((state) => state.tags);
  let dataTags = [{ name: 'Tất cả', value: 0 }];
  for (let i = 0; i < tagList.length; i++) {
    dataTags.push({ name: tagList[i].name, value: tagList[i].tagId });
  }
  const dataInvoiceSource = [
    {
      name: 'Tất cả',
      value: 0,
    },
    {
      name: 'Tải lên',
      value: 1,
    },
    {
      name: 'Email',
      value: 2,
    },
    {
      name: 'Tự động tải về',
      value: 3,
    },
    {
      name: 'Nhập tay',
      value: 4,
    },
  ];
  const status = [
    {
      name: 'Tất cả',
      value: 0,
    },
    {
      name: 'HĐ mới',
      value: 1,
    },
    {
      name: 'HĐ thay thế',
      value: 2,
    },
    {
      name: 'HĐ điều chỉnh',
      value: 3,
    },
    {
      name: 'HĐ đã bị thay thế',
      value: 4,
    },
    {
      name: 'HĐ đã bị điều chỉnh',
      value: 5,
    },
    {
      name: 'HĐ đã bị hủy',
      value: 6,
    },
    {
      name: 'HĐ chưa xác định',
      value: 7,
    },
    {
      name: 'HĐ lưu nháp',
      value: 8,
    },
  ];
  const TypeCode = [
    {
      name: 'Tất cả',
      value: 0,
    },
    {
      name: 'HĐ giá trị gia tăng',
      value: 1,
    },
    {
      name: 'HĐ bán hàng',
      value: 2,
    },
    {
      name: 'HĐ bán tài sản công',
      value: 3,
    },
    {
      name: 'HĐ bán hàng dự trữ quốc gia',
      value: 4,
    },
    {
      name: 'Tem, vé, thẻ điện tử',
      value: 5,
    },
    {
      name: 'Phiếu xuất kho điện tử',
      value: 6,
    },
  ];
  const serial = [
    {
      name: 'Tất cả',
      value: 0,
    },
    {
      name: 'Hóa đơn có mã',
      value: 'C',
    },
    {
      name: 'Hóa đơn không mã',
      value: 'K',
    },
  ];
  const { t } = useTranslation();
  const [dataFilters, setDataFilters] = useState({
    dateType:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.dateType ?? dataStatusDate[0]?.value,
    dateAccounting:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.dateAccounting ?? dataAccounTingStatus[0]?.value,
    tagId:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.tagId ?? dataTags[0]?.value,
    typeInvoiceReceive:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.typeInvoiceReceive ?? dataInvoiceSource[0]?.value,
    status:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.status ?? status[0]?.value,
    statusInvoice:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.statusInvoice ?? dataStatusInvoice[0]?.value,
    invoiceTypeCode:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.invoiceTypeCode ?? TypeCode[0]?.value,
    invoiceSerialType:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.invoiceSerialType ?? serial[0]?.value,
    fromDate:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.fromDate ?? moment().subtract(29, 'days').format('YYYY-MM-DD'),
    toDate:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.toDate ?? moment().format('YYYY-MM-DD'),
    dateRangeLabel: JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
      Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
    ]?.dateRangeLabel,
  });
  useEffect(() => {
    if (Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId)) !== undefined) {
      setDataFilters({
        dateType:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.dateType ?? dataStatusDate[0]?.value,
        dateAccounting:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.dateAccounting ?? dataAccounTingStatus[0]?.value,
        tagId:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.tagId ?? dataTags[0]?.value,
        typeInvoiceReceive:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.typeInvoiceReceive ?? dataInvoiceSource[0]?.value,
        status:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.status ?? status[0]?.value,
        statusInvoice:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.statusInvoice ?? dataStatusInvoice[0]?.value,
        invoiceTypeCode:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.invoiceTypeCode ?? TypeCode[0]?.value,
        invoiceSerialType:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.invoiceSerialType ?? serial[0]?.value,
        fromDate:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.fromDate ?? moment().subtract(29, 'days').format('YYYY-MM-DD'),
        toDate:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.toDate ?? moment().format('YYYY-MM-DD'),
        dateRangeLabel: JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
          Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
        ]?.dateRangeLabel,
      });
      Global.gFiltersInputInvoice = {
        ...Global.gFiltersInputInvoice,
        dateType:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.dateType ?? dataStatusDate[0]?.value,
        dateAccounting:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.dateAccounting ?? dataAccounTingStatus[0]?.value,
        tagId:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.tagId ?? dataTags[0]?.value,
        typeInvoiceReceive:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.typeInvoiceReceive ?? dataInvoiceSource[0]?.value,
        status:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.status ?? status[0]?.value,
        statusInvoice:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.statusInvoice ?? dataStatusInvoice[0]?.value,
        invoiceTypeCode:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.invoiceTypeCode ?? TypeCode[0]?.value,
        invoiceSerialType:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.invoiceSerialType ?? serial[0]?.value,
        fromDate:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.fromDate ?? moment().subtract(29, 'days').format('YYYY-MM-DD'),
        toDate:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.toDate ?? moment().format('YYYY-MM-DD'),
        dateRangeLabel: JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[
          Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
        ]?.dateRangeLabel,
      };
    }
  }, [Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))]);
  const handleSubmit = () => {
    if (handleFilterInvoice) {
      handleFilterInvoice(dataFilters);
    }
    Global.gFiltersInputInvoice = {
      ...Global.gFiltersInputInvoice,
      dateType: dataFilters?.dateType,
      dateAccounting: dataFilters?.dateAccounting,
      tagId: dataFilters?.tagId,
      typeInvoiceReceive: dataFilters?.typeInvoiceReceive,
      status: dataFilters?.status,
      statusInvoice: dataFilters?.statusInvoice,
      invoiceTypeCode: dataFilters?.invoiceTypeCode,
      invoiceSerialType: dataFilters?.invoiceSerialType,
      fromDate: dataFilters?.fromDate,
      toDate: dataFilters?.toDate,
      dateRangeLabel: dataFilters?.dateRangeLabel,
      page: 0,
    };
    localStorage.setItem(
      PreferenceKeys.saveInputInvoice,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice)),
        [Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))]: {
          ...Global.gFiltersInputInvoice,
          dateType: dataFilters?.dateType,
          dateAccounting: dataFilters?.dateAccounting,
          tagId: dataFilters?.tagId,
          typeInvoiceReceive: dataFilters?.typeInvoiceReceive,
          status: dataFilters?.status,
          statusInvoice: dataFilters?.statusInvoice,
          invoiceTypeCode: dataFilters?.invoiceTypeCode,
          invoiceSerialType: dataFilters?.invoiceSerialType,
          fromDate: dataFilters?.fromDate,
          toDate: dataFilters?.toDate,
          dateRangeLabel: dataFilters?.dateRangeLabel,
          page: 0,
        },
      })
    );
    refDropdownFilterMenu?.current.classList.remove('show');
  };
  const handleClose = () => {
    setDataFilters({
      dateType: dataStatusDate[0]?.value,
      dateAccounting: dataAccounTingStatus[0]?.value,
      tagId: dataTags[0]?.value,
      typeInvoiceReceive: dataInvoiceSource[0]?.value,
      statusInvoice: status[0]?.value,
      status: dataStatusInvoice[0]?.value,
      invoiceTypeCode: TypeCode[0]?.value,
      invoiceSerialType: serial?.[0]?.value,
      fromDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      dateRangeLabel: '30 ngày gần đây',
    });
    Global.gFiltersInputInvoice = {
      ...Global.gFiltersInputInvoice,
      dateType: dataStatusDate[0]?.value,
      dateAccounting: dataAccounTingStatus[0]?.value,
      tagId: dataTags[0]?.value,
      typeInvoiceReceive: dataInvoiceSource[0]?.value,
      status: status[0]?.value,
      statusInvoice: dataStatusInvoice[0]?.value,
      invoiceTypeCode: TypeCode[0]?.value,
      invoiceSerialType: serial?.[0]?.value,
      fromDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      dateRangeLabel: '30 ngày gần đây',
      page: 0,
    };
    localStorage.setItem(
      PreferenceKeys.saveInputInvoice,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice)),
        [Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))]: {
          ...Global.gFiltersInputInvoice,
          dateType: dataStatusDate[0]?.value,
          dateAccounting: dataAccounTingStatus[0]?.value,
          tagId: dataTags[0]?.value,
          typeInvoiceReceive: dataInvoiceSource[0]?.value,
          status: status[0]?.value,
          statusInvoice: dataStatusInvoice[0]?.value,
          invoiceTypeCode: TypeCode[0]?.value,
          invoiceSerialType: serial?.[0]?.value,
          fromDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
          dateRangeLabel: '30 ngày gần đây',
          page: 0,
        },
      })
    );
    dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
    refDropdownFilterMenu?.current.classList.remove('show');
  };

  return (
    <div className="px-3">
      <div className="font-size-h3 font-weight-bold mb-2">{t('FilterInvoices')}</div>
      <div className="d-flex mb-3 flex-column flex-md-row">
        <div className="w-100 me-4 ">
          <div className="font-size-lg font-weight-bold " style={{ minWidth: 'max-content' }}>
            {t('ByDate')}
          </div>
          <VISelectFilter
            data={dataStatusDate}
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, dateType: e?.value });
              // Global.gFiltersInputInvoice = {
              //   ...Global.gFiltersInputInvoice,
              //   dateType: e?.value,
              // };
            }}
            className="w-100 mt-2"
            CloseDropdown="ByDate"
            style={{ minWidth: '150px' }}
            CurrentSelect={dataFilters?.dateType}
          />
        </div>
        <div className="w-100 mt-screen">
          <div className="font-size-lg font-weight-bold " style={{ minWidth: 'max-content' }}>
            {t('AccountingStatus')}
          </div>
          <VISelectFilter
            data={dataAccounTingStatus}
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, dateAccounting: e?.value });
              // Global.gFiltersInputInvoice = {
              //   ...Global.gFiltersInputInvoice,
              //   dateAccounting: e?.value,
              // };
            }}
            CurrentSelect={dataFilters?.dateAccounting}
            className="w-100 mt-2"
            CloseDropdown="AccountingStatus"
            style={{ minWidth: '150px' }}
          />
        </div>
      </div>
      <div className="d-flex mb-3 flex-column flex-md-row">
        <div className="w-100 me-4">
          <div className="font-size-lg font-weight-bold " style={{ minWidth: 'max-content' }}>
            {t('InvoiceStatus')}
          </div>
          <VISelectFilter
            data={dataStatusInvoice}
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, status: e?.value });
              // Global.gFiltersInputInvoice = {
              //   ...Global.gFiltersInputInvoice,
              //   status: e?.value,
              // };
            }}
            CurrentSelect={dataFilters?.status}
            className="w-100 mt-2"
            CloseDropdown="InvoiceStatus"
            style={{ minWidth: '150px' }}
          />
        </div>
        <div className="w-100 mt-screen">
          <div className="font-size-lg font-weight-bold " style={{ minWidth: 'max-content' }}>
            {t('Stickers')}
          </div>
          <VISelectFilter
            data={dataTags}
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, tagId: e?.value });
              // Global.gFiltersInputInvoice = {
              //   ...Global.gFiltersInputInvoice,
              //   tagId: e?.value,
              // };
            }}
            CurrentSelect={dataFilters?.tagId}
            className="w-100 mt-2"
            CloseDropdown="Stickers"
            style={{ minWidth: '150px' }}
          />
        </div>
      </div>
      <div className="d-flex mb-3 flex-column flex-md-row">
        <div className="w-100 me-4">
          <div className="font-size-lg font-weight-bold " style={{ minWidth: 'max-content' }}>
            {t('InvoiceSource')}
          </div>
          <VISelectFilter
            data={dataInvoiceSource}
            // type="CheckBox"
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, typeInvoiceReceive: e?.value });
              // Global.gFiltersInputInvoice = {
              //   ...Global.gFiltersInputInvoice,
              //   typeInvoiceReceive: e?.value,
              // };
            }}
            CurrentSelect={dataFilters?.typeInvoiceReceive}
            className="w-100 mt-2"
            CloseDropdown="InvoiceSource"
            style={{
              minWidth: '150px',
              // maxWidth: '200px',
              textOverflow: 'clip',
              overflow: 'hidden',
            }}
          />
        </div>
        <div className="w-100 mt-screen">
          <div className="font-size-lg font-weight-bold " style={{ minWidth: 'max-content' }}>
            {t('Status')}
          </div>
          <VISelectFilter
            data={status}
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, statusInvoice: e?.value });
              // Global.gFiltersInputInvoice = {
              //   ...Global.gFiltersInputInvoice,
              //   statusInvoice: e?.value,
              // };
            }}
            CurrentSelect={dataFilters?.statusInvoice}
            className="w-100 mt-2 "
            CloseDropdown="Status"
            style={{ minWidth: '150px' }}
          />
        </div>
      </div>
      <div className="d-flex mb-3 flex-column flex-md-row">
        <div className="w-100 me-4">
          <div className="font-size-lg font-weight-bold " style={{ minWidth: 'max-content' }}>
            {t('InvoiceTemplate')}
          </div>
          <VISelectFilter
            data={TypeCode}
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, invoiceTypeCode: e?.value });
            }}
            CurrentSelect={dataFilters?.invoiceTypeCode}
            className="w-100 mt-2"
            CloseDropdown="InvoiceTemplate"
            style={{
              minWidth: '150px',
              // maxWidth: '230px',
              textOverflow: 'clip',
              overflow: 'hidden',
            }}
          />
        </div>
        <div className="w-100 mt-screen">
          <div className="font-size-lg font-weight-bold " style={{ minWidth: 'max-content' }}>
            {t('InvoiceForm')}
          </div>
          <VISelectFilter
            data={serial}
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, invoiceSerialType: e?.value });
              // Global.gFiltersInputInvoice = {
              //   ...Global.gFiltersInputInvoice,
              //   invoiceSerialType: e?.value,
              // };
            }}
            CurrentSelect={dataFilters?.invoiceSerialType}
            className="w-100 mt-2 "
            CloseDropdown="InvoiceForm"
            style={{ minWidth: '150px' }}
          />
        </div>
      </div>

      <div className="d-flex mb-2 flex-column flex-md-row">
        <div className="w-100 me-4">
          <p className={`mb-1 font-weight-bold`}>{t('FromDate')}</p>
          <KTFormGroup
            additionalClassName=" mb-3"
            inputName="date"
            inputElement={
              <div>
                <KTFormInput
                  name="date"
                  value={Utils.formatDateTime(dataFilters.fromDate, 'YYYY-MM-DD')}
                  onChange={(value) => {
                    setDataFilters({
                      ...dataFilters,
                      fromDate: moment(value)?.format('YYYY-MM-DD'),
                    });
                    Global.gFiltersInputInvoice = {
                      ...Global.gFiltersInputInvoice,
                      fromDate: moment(value)?.format('YYYY-MM-DD'),
                    };
                  }}
                  enableCheckValid
                  type={KTFormInputType.date}
                  placeholder={`${_.capitalize(t('SelectPaymentDate'))}`}
                  enableInputGroup={true}
                  // prependElements={[<i className="fa-regular fa-calendar-clock"></i>]}
                />
              </div>
            }
          />
        </div>
        <div className="w-100 mt-screen">
          <p className={`mb-1 font-weight-bold`}>{t('ToDate')}</p>
          <KTFormGroup
            additionalClassName=" mb-3"
            inputName="date"
            inputElement={
              <div>
                <KTFormInput
                  name="date"
                  value={Utils.formatDateTime(dataFilters.toDate, 'YYYY-MM-DD')}
                  onChange={(value) => {
                    setDataFilters({
                      ...dataFilters,
                      toDate: moment(value)?.format('YYYY-MM-DD'),
                    });
                    Global.gFiltersInputInvoice = {
                      ...Global.gFiltersInputInvoice,
                      toDate: moment(value)?.format('YYYY-MM-DD'),
                    };
                  }}
                  enableCheckValid
                  type={KTFormInputType.date}
                  placeholder={`${_.capitalize(t('SelectPaymentDate'))}`}
                  enableInputGroup={true}
                  // prependElements={[<i className="fa-regular fa-calendar-clock"></i>]}
                />
              </div>
            }
          />
        </div>
      </div>
      <div className="d-flex justify-content-between button-filter mb-1">
        <div className="btn-resets" style={{ width: '100px' }}>
          {/* <VIButton
                  text={t('ResetToDefault')}
                  beforeIcon={<i className="fa-solid fa-arrow-rotate-right"></i>}
                  className=" btn-white w-100 "
                  style={{ padding: '8px' }}
                  onClick={() => {}}
                  afterIcon={<></>}
                /> */}
        </div>
        <div className="d-flex btn-submit-filter">
          <VIButton
            text={t('DeselectFilter')}
            beforeIcon={<></>}
            className="btn-white me-3"
            style={{ padding: '8px' }}
            onClick={() => {
              handleClose();
            }}
            afterIcon={<></>}
          />
          <VIButton
            text={t('Save')}
            beforeIcon={<></>}
            className=""
            style={{ padding: '8px' }}
            onClick={() => handleSubmit()}
            afterIcon={<></>}
          />
        </div>
      </div>
    </div>
  );
}

export default DropdownFilterInInvoice;
