import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

VILoading.propTypes = {
  animation: PropTypes.string,
  variant: PropTypes.string,
  classNames: PropTypes.string,
  tableMaxHeight: PropTypes.any,
  size: PropTypes.any,
};
VILoading.defaultProps = {
  animation: 'border',
  variant: 'primary',
  classNames: '',
  tableMaxHeight: null,
  size: null,
};
function VILoading(props) {
  const { animation, variant, className, tableMaxHeight, size } = props;
  return (
    <div style={{ height: `${tableMaxHeight}` }} className="d-flex align-items-center">
      <Spinner animation={animation} variant={variant} className={`${className}`} size={size} />
    </div>
  );
}

export default VILoading;
