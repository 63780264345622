import accountApi from 'api/accountApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// lay danh sach nhat ky dang nhap
export const thunkGetLoggedInLogs = createAsyncThunk(
  'account/getLoggedInLogs',
  async (params, thunkAPI) => {
    const res = await accountApi.getLoggedInLogs(params);
    return res;
  }
);

// lay danh sach thiet bi da dang nhap
export const thunkGetLoggedInDevices = createAsyncThunk(
  'account/getLoggedInDevices',
  async (params, thunkAPI) => {
    const res = await accountApi.getLoggedInDevices(params);
    return res;
  }
);

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    // danh sach thiet bi da dang nhap
    loggedInDevices: [],
    isGettingLoggedInDevice: false,
    totalLoggedInDevices: 0,

    // danh sach nhat ky dang nhap
    dailyLogs: [],
    isGettingDailyLog: false,
    totalDailyLogs: 0,

    paymentHistories: [],
    isGettingPaymentHistory: false,

    notUsedYetVouchers: [],
    isGettingNotUsedYetVoucher: false,

    usingVouchers: [],
    isGettingUsingVoucher: false,

    expiredVouchers: [],
    isGettingExpiredVoucher: false,
  },
  reducers: {},
  extraReducers: {
    // lay danh sach nhat ky dang nhap
    [thunkGetLoggedInLogs.pending]: (state, action) => {
      state.isGettingDailyLog = true;
    },
    [thunkGetLoggedInLogs.rejected]: (state, action) => {
      state.isGettingDailyLog = false;
    },
    [thunkGetLoggedInLogs.fulfilled]: (state, action) => {
      state.isGettingDailyLog = false;
      const { result, authLogs, total } = action.payload;
      if (result === 'success' && authLogs) {
        state.dailyLogs = authLogs;
        state.totalDailyLogs = total;
      }
    },

    // lay danh sach thiet bi da dang nhap
    [thunkGetLoggedInDevices.pending]: (state, action) => {
      state.isGettingLoggedInDevice = true;
    },
    [thunkGetLoggedInDevices.rejected]: (state, action) => {
      state.isGettingLoggedInDevice = false;
    },
    [thunkGetLoggedInDevices.fulfilled]: (state, action) => {
      state.isGettingLoggedInDevice = false;
      const { result, devices, total } = action.payload;
      if (result === 'success' && devices) {
        state.loggedInDevices = devices;
        state.totalLoggedInDevices = total;
      }
    },
  },
});

const { reducer, actions } = accountSlice;
export const {} = actions;
export default reducer;
