import { Route, Routes } from 'react-router-dom';
import DashboardHomeScreen from './screens/DashboardHomeScreen';

function Dashboard(props) {
  return (
    <Routes>
      <Route path="" element={<DashboardHomeScreen />} />
    </Routes>
  );
}

export default Dashboard;
