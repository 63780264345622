import AccountHelper from 'general/helpers/AccountHelper';
import wsHelperInstance from 'general/helpers/WebsocketHelper';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

AccountListener.propTypes = {};

function AccountListener(props) {
  // ---- params -----
  const { account } = useSelector((state) => state.auth);
  useEffect(() => {
    if (account) {
      if (AccountHelper?.checkAccessTokenValid()) {
        const token = AccountHelper?.getAccessToken();
        wsHelperInstance?.loginByToken(token);
      }
    }
  }, [account]);

  return <></>;
}

export default AccountListener;
