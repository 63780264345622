import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function useAccount(props) {
  // ---- params ----
  const { account, online, totalUnreadMail } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  return useMemo(() => {
    // ten hien thi
    const displayName = !_.isEmpty(account?.fullname)
      ? account?.fullname
      : account?.isAdmin
      ? t('Admin')
      : t('User');
    const company = account?.Company;

    // cqt
    const organization = account?.Organization ?? account?.OrganizationDepartment;
    const isAxionConnected =
      !_.isUndefined(organization?.cqtToken) && !_.isNull(organization?.cqtToken);
    let organizationDepartment;
    let organizationBRANCH;
    let organizationHQ;
    if (account?.OrganizationDepartment) {
      organizationDepartment = account?.OrganizationDepartment;
      organizationBRANCH = organizationDepartment?.OrganizationBRANCH;
      organizationHQ = organizationBRANCH?.OrganizationHQ;
    } else {
      if (account?.Organization?.organizationType === AppData.organizationType.branch) {
        organizationBRANCH = account?.Organization;
        organizationHQ = organizationBRANCH?.OrganizationHQ;
      } else if (account?.Organization?.organizationType === AppData.organizationType.company) {
        organizationHQ = account?.Organization;
      }
    }

    // cai dat
    const nameFileBuyerTaxCode = organization?.nameFileBuyerTaxCode ?? false;
    const nameFileSerial = organization?.nameFileSerial ?? false;
    const nameFileInvoiceDate = organization?.nameFileInvoiceDate ?? false;
    const nameFileSample = organization?.nameFileSample ?? false;
    const nameFileInvoiceNum = organization?.nameFileInvoiceNum ?? true;
    const nameFileSupplierName = organization?.nameFileSupplierName ?? false;

    const warningInListInvoice = organization?.warningInListInvoice ?? false;

    const downloadAllOutputInvoice = organization?.downloadAllOutputInvoice ?? false;
    const downloadOutputInvoiceNotTable = organization?.downloadOutputInvoiceNotTable ?? false;
    const selectDownloadTypeOutputInvoice = organization?.selectDownloadTypeOutputInvoice ?? {};

    const adjustNotDownloadWithSupplier = organization?.adjustNotDownloadWithSupplier ?? false;
    const autoAdjustInvoice = organization?.autoAdjustInvoice ?? false;
    const downloadInputInvoiceNotTable = organization?.downloadInputInvoiceNotTable ?? false;
    const selectDownloadTypeInputInvoice = organization?.selectDownloadTypeInputInvoice ?? {};

    const autoAdjustWithBuyerTaxCode = organization?.autoAdjustWithBuyerTaxCode ?? false;

    return {
      totalUnreadMail,
      accountId: account?.accountId,
      displayName,
      isAdmin: account?.accountLevel === AppData.accountLevel.admin,
      isUser: account?.accountLevel === AppData.accountLevel.user,
      isOnline: online ?? false,
      role: account?.Role ?? null,
      rolePermissionCodes: account?.Role?.permissionCodes ?? [],
      // kiem tra quyen
      checkPermission: (code, _account = account) => {
        if (_account?.accountLevel === AppData.accountLevel.admin) {
          return true;
        }
        if (_account?.Role) {
          const ret = account?.Role?.permissionCodes?.includes(code);
          return ret;
        }
        if (_account?.accountLevel === AppData.accountLevel.user) {
          return false;
        }
      },

      // thong tin co ban
      fullname: account?.fullname ?? '',
      avatar: account?.avatar ?? AppResource.images.imgDefaultAvatar,
      dob: account?.dob ? moment(account?.dob).format('YYYY-MM-DD') : '',
      gender: account?.gender ?? AppData.gender.other,

      // thong tin lien he
      email: account?.email ?? '',
      phone: account?.phone ?? '',
      address: account?.address ?? '',

      // cmnd/cccd
      cccdNumber: account?.cccdNumber ?? '',
      cccdDate: account?.cccdDate ? moment(account?.cccdDate).format('YYYY-MM-DD') : '',
      cccdCreatePlace: account?.cccdCreatePlace ?? '',
      cccdBackImg: account?.cccdBackImg ?? '',
      cccdFrontImg: account?.cccdFrontImg ?? '',

      // khac
      refererCode: account?.refererCode ?? '',
      organizationId: account?.organizationId ?? null,
      organizationDepartmentId: account?.organizationDepartmentId ?? null,
      organizationName:
        account?.OrganizationDepartment?.departmentName ??
        account?.Organization?.organizationName ??
        '',
      organizationEmailReceiveInvoice:
        account?.OrganizationDepartment?.invoiceMailbox ??
        account?.Organization?.invoiceMailbox ??
        '',
      organizationType: account?.Organization?.organizationType ?? '',
      organization,

      organizationDepartment,
      organizationBRANCH,
      organizationHQ,

      // cong ty
      company: {
        name: company?.companyName ?? '',
        email: company?.companyEmail ?? '',
        taxCode: company?.taxCode ?? '',
        logo: company?.companyLogo ?? '',
        businessPermitAddress: company?.businessPermitAddress ?? '',
        businessPermitDate: company?.businessPermitDate ?? '',
        address: company?.address ?? '',
        phone: company?.phone ?? '',
        // loai dich vu su dung
        service: company?.service,
        serviceName:
          company?.service === AppData.serviceType.smart
            ? 'I-SMART'
            : company?.service === AppData.serviceType.pro
            ? 'I-PRO'
            : '',

        // thong ke tai nguyen
        resourceTotalInvoice: company?.quantityPurchased ?? 0,
        resourceTotalUnusedInvoice: company?.quantityRemain ?? 0,
        resourceDateExpired: company?.dateExpiredPackage
          ? Utils.formatDateTime(company?.dateExpiredPackage, 'DD/MM/YYYY')
          : '--/--/----',
      },

      // cqt
      isAxionConnected,
      axionUserName: organization?.username,
      axionPassword: organization?.password,
      axionIsInput: organization?.isInput ?? true,
      axionIsOutput: organization?.isOutput ?? false,
      axionToken: organization?.cqtToken,

      // cai dat
      organizationSetting: {
        // nhac han thanh toan
        remindNotify: organization?.remindNotify ?? true, // bat thong bao nhac han (mac dinh ON)
        remindBeforeDays: organization?.remindBeforeDays ?? 5, // nhac han truoc bao nhieu ngay

        // thiet lap ten file tai xuong
        nameFileBuyerTaxCode, // mst nguoi ban
        nameFileSerial, // ky hieu
        nameFileInvoiceDate, // ngay hoa don
        nameFileSample, // mau so
        nameFileInvoiceNum, // so hoa don (mac dinh ON)
        nameFileSupplierName, // ten ncc

        // thiet lap ket noi he thong co quan thue
        // hoa don dau ra
        downloadAllOutputInvoice, // tai toan bo hoa don dau ra (bao gom hoa don phat hanh boi ICORP Invoice)
        downloadOutputInvoiceNotTable, // Tải về hóa đơn không mã gửi theo hình thức bảng tổng hợp
        selectDownloadTypeOutputInvoice, // Chọn loại hóa đơn tải về
        // hoa don dau vao
        adjustNotDownloadWithSupplier, // Thiết lập không tải về hóa đơn theo nhà cung cấp
        autoAdjustInvoice, // Tự động điều chuyển hóa đơn cho chi nhánh/phòng ban
        downloadInputInvoiceNotTable, // Tải về hóa đơn không mã gửi theo hình thức bảng tổng hợp
        selectDownloadTypeInputInvoice, // Chọn loại hóa đơn tải về

        // thiet lap dieu chuyen hoa don tu nguon email
        autoAdjustWithBuyerTaxCode, // Tự động điều chuyển theo mã số thuế người mua

        // thiet lap canh bao hoa don chua chinh xac ve tinh toan so lieu
        warningInListInvoice, // Cảnh báo trên danh sách hóa đơn
      },
      /**
       *
       * @param {string} sellerTaxCode mst nguoi ban
       * @param {string} serial ky hieu hoa don
       * @param {string} invoiceDate ngay hoa don
       * @param {string} sample mau so hoa don
       * @param {string} invoiceNum so hoa don
       * @param {string} supplierName ten nha cung cap
       */
      genInvoiceDownloadFileName: (
        sellerTaxCode,
        serial,
        invoiceDate,
        sample,
        invoiceNum,
        supplierName = 'congtycophanicorp'
      ) => {
        const arrItems = [];
        if (nameFileBuyerTaxCode) {
          arrItems.push(sellerTaxCode);
        }
        if (nameFileSerial) {
          arrItems.push(serial);
        }
        if (nameFileSample) {
          arrItems.push(sample);
        }
        if (nameFileInvoiceNum) {
          arrItems.push(invoiceNum);
        }
        if (nameFileInvoiceDate) {
          arrItems.push(invoiceDate);
        }
        if (nameFileSupplierName) {
          arrItems.push(supplierName);
        }
        return arrItems.join('_');
      },
    };
  }, [account, online, t, totalUnreadMail]);
}

export default useAccount;
