import usePayment from 'hooks/usePayment';
import { Navigate } from 'react-router-dom';

function OrderSuccessRoute(props) {
  // MARK: --- Params ---
  const { orderCreatedSuccess } = usePayment();

  return !_.isNull(orderCreatedSuccess) ? props.children : <Navigate to="/store" />;
}

export default OrderSuccessRoute;
