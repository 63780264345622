import axiosClient from './axiosClient';

const axionApi = {
  // ket noi cqt
  connect: (params) => {
    const url = '/cqt/connection';
    return axiosClient.post(url, params);
  },

  // dong bo cqt
  sync: (params) => {
    const url = '/cqt/synchronization';
    return axiosClient.post(url, params);
  },

  // ngat ket noi
  disconnect: (params) => {
    const url = '/cqt/disconnection';
    return axiosClient.post(url, params);
  },

  // lay lich su dong bo
  getConnectionAxionHistory: (params) => {
    const url = '/cqt-invoice-history/find';
    return axiosClient.get(url, { params });
  },

  // lay chi tiet lich su
  getConnectioAxionHistoryDetail: (id, params) => {
    const url = `/cqt-invoice-history/detail/${id}`;
    return axiosClient.get(url, { params });
  },

  // reload
  reload: (id) => {
    const url = '/cqt-invoice-history/reload';
    return axiosClient.post(url, {
      cqtInvoiceHistoryId: id,
    });
  },

  // ket noi va tao co cau to chuc moi
  connectAndCreateOrganization: (params) => {
    const url = '/cqt/create-new-organization';
    return axiosClient.post(url, params);
  },
};

export default axionApi;
