import customDataTableExpandableStyle from 'assets/styles/customDataTableExpandableStyle';
import ExpandedOrganizationComponent from 'features/Organization/components/ExpendedOrganizationComponent';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import AccountHelper from 'general/helpers/AccountHelper';
import useAccount from 'hooks/useAccount';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

ViewAxionListAccount.propTypes = {
  onPressedCancel: PropTypes.func,
  onShowModalConnect: PropTypes.func,
};

ViewAxionListAccount.defaultProps = {
  onPressedCancel: null,
  onShowModalConnect: null,
};

/**
 *
 * @param {{
 * onPressedCancel: function,
 * onShowModalConnect: function,
 * }} props
 * @returns
 */
function ViewAxionListAccount(props) {
  // ---- params ----
  const { onPressedCancel, onShowModalConnect } = props;
  const { t } = useTranslation();
  const account = useAccount();
  const { organizations, isGettingOrganization } = useSelector((state) => state?.organization);
  const columns = useMemo(
    () => [
      {
        name: t('UnitName'),
        minWidth: '300px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-bolder m-0 text-maxline-3">
              {row?.organizationName}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        width: '130px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.taxCode}
            </p>
          );
        },
      },
      {
        name: t('EmailReceiveInvoice'),
        width: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.invoiceMailbox}
            </p>
          );
        },
      },
      {
        name: t('State'),
        sortable: false,
        width: '130px',
        center: true,
        cell: (row) => {
          return (
            <KTTooltip text={row?.active ?? true ? t('Active') : t('InActive')}>
              {row?.active ?? true ? (
                <i className="fa-regular fa-circle-check text-primary" />
              ) : (
                <i className="fa-regular fa-times-circle text-danger" />
              )}
            </KTTooltip>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '120px',
        // style: {
        //   position: 'sticky',
        //   right: 0,
        //   backgroundColor: '#fff',
        //   borderLeft: '1px solid #ebedf3',
        // },
        cell: (row) => {
          const isConnected =
            !_.isNull(account.axionToken) &&
            !_.isNull(row?.cqtToken) &&
            row?.cqtToken === account.axionToken;
          return (
            <div className="d-flex align-items-center justify-content-between">
              <VIButton
                text={isConnected ? t('Connected') : t('Connect')}
                className={isConnected ? 'btn-grey' : 'btn-blue'}
                style={{ padding: '8px' }}
                onClick={() => {
                  // console.log({ account, row });
                  // return;
                  handleConnect(row);
                }}
                disabled={isConnected}
              />
            </div>
          );
        },
      },
    ],
    [t, account]
  );

  // ---- methods ----
  function handlePressedCancel() {
    if (onPressedCancel) {
      onPressedCancel();
    }
  }

  function handleShowModalConnect(row) {
    if (onShowModalConnect) {
      onShowModalConnect(row, false);
    }
  }

  async function handleConnect(row) {
    if (row?.cqtToken) {
      // da ket noi voi cqt => thuc hien chuyen doi co cau to chuc
      const res = AccountHelper.callApiChangeOrganization(
        row?.organizationDepartmentId ? null : row?.organizationId,
        row?.organizationDepartmentId ? row?.organizationDepartmentId : null
      );
      if (res) {
        handlePressedCancel();
      }
    } else {
      // chua ket noi cqt => hien modal thuc hien ket noi cqt
      handlePressedCancel();
      handleShowModalConnect(row);
    }
  }

  return (
    <div
      className="overflow-auto"
      style={{
        maxHeight: '70vh',
      }}
    >
      <VIDataTable
        minusHeight={20}
        additionalClassName="p-0 shadow-none"
        additionalCardBodyClassName="p-0"
        expandable={true}
        expandedComponent={(row) => {
          return (
            <ExpandedOrganizationComponent
              addMoreAccountMode={true}
              level={2}
              data={row?.data?.ChildrenOrganizations}
              onPressedConnect={(row) => {
                handleConnect(row);
              }}
            />
          );
        }}
        tableStyles={customDataTableExpandableStyle}
        draggable={false}
        title={t('Organization')}
        haveCardHeader={false}
        selectable={false}
        pagination={null}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
        }}
        onChangeRowsPerPage={(perPage) => {}}
        fullColumns={columns}
        data={organizations}
        loading={isGettingOrganization}
        onRowClick={(row) => {}}
        onSetSelectedMultiItems={(rows) => {}}
      />
    </div>
  );
}

export default ViewAxionListAccount;
