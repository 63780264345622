const customDataTableSelectableStyle = {
  rows: {
    style: {
      minHeight: '40px', // override the row height
      fontSize: '1rem',
      color: '#3C4376',
      paddingLeft: '0px',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#ebedf3',
      },
      '&:last-of-type': {
        borderBottom: '1px solid #ebedf3',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: '#F3F6F9 ',
    },
  },
  headRow: {
    style: {
      borderBottomColor: '#ebedf3',
      // borderRight: '1px solid #ebedf3',
    },
  },
  cells: {
    style: {
      '&:not(:first-of-type)': { padding: '0.6rem 1rem' },
      // '&:not(:last-of-type)': {
      borderRight: '1px solid #ebedf3',
      // },
      '&:first-of-type': {
        position: 'sticky',
        left: 0,
        backgroundColor: '#fff',
        zIndex: '1',
        borderLeft: '1px solid #ebedf3',
      },
      '&:last-of-type': {
        borderLeft: '1px solid #ebedf3',
      },
      color: '#3C4376',
    },
  },
  headCells: {
    style: {
      fontSize: '0.9rem',
      fontWeight: 600,
      '&:not(:first-of-type)': {
        padding: '0.6rem 1rem',
      },
      '&:last-of-type': {
        position: 'sticky',
        right: 0,
        backgroundColor: '#fff',
        zIndex: '1',
        borderLeft: '1px solid #ebedf3',
        borderRight: '1px solid #ebedf3',
      },
      '&:first-of-type': {
        with: 'auto',
        position: 'sticky',
        left: 0,
        backgroundColor: '#fff',
        zIndex: 2,
        borderLeft: '1px solid #ebedf3',
      },
      color: '#3C4376',
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
      lineHeight: '1.5 !important',
      padding: '0 1rem',
      '&:not(:last-of-type)': {
        borderRight: '1px solid #ebedf3',
      },
      borderTop: '1px solid #ebedf3',
    },
    activeSortStyle: {
      '&:focus': {
        outline: 'none',
        color: '#3699FF',
      },
      '&:not(focus)': {
        color: '#3699FF',
      },
      '&:hover:not(:focus)': {
        color: '#3699FF',
      },
    },
    inactiveSortStyle: {
      '&:focus': {
        outline: 'none',
        color: '#B5B5C3',
      },
      '&:hover': {
        color: '#3699FF',
      },
    },
  },
};

export default customDataTableSelectableStyle;
