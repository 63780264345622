import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import EmptyView from 'features/Others/components/EmptyView';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VILoading from 'general/components/VietInvoice/VILoading';
import AppData from 'general/constants/AppData';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
ChartJS.register(ArcElement, Tooltip, Legend);
CardDonutChartV2.propTypes = {
  additionalClassName: PropTypes.string,
  chartSeries: PropTypes.array,
  chartLabels: PropTypes.array,
  title: PropTypes.string,
  chartColors: PropTypes.array,
  additonalElement: PropTypes.element,
  loading: PropTypes.bool,
  subTitle: PropTypes.string,
  toolbarElement: PropTypes.element,
  headerSidebar: PropTypes.element,
};

CardDonutChartV2.defaultProps = {
  additionalClassName: '',
  chartSeries: [],
  chartLabels: [],
  title: '',
  chartColors: AppData.chartColors,
  additonalElement: <></>,
  loading: false,
  subTitle: '',
  toolbarElement: null,
  headerSidebar: null,
};

/**
 *
 * @param {{
 * additionalClassName: string,
 * chartLabels: string[],
 * chartSeries: number[],
 * title: string,
 * }} props
 * @returns
 */
function CardDonutChartV2(props) {
  // MARK: --- Params ---
  const {
    additionalClassName,
    chartLabels,
    chartSeries,
    title,
    chartColors,
    additonalElement,
    loading,
    subTitle,
    toolbarElement,
    headerSidebar,
  } = props;
  const { t } = useTranslation();
  const refCardDonutChart = useRef(null);

  const options = useMemo(
    () => ({
      // responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 40, // khoảng cách từ đỉnh biểu đồ
        },
      },
      plugins: {
        legend: {
          display: false,
        },

        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }

              return label + Utils.formatNumber(context.parsed);
            },
          },
        },
      },
    }),
    [chartLabels]
  );

  const textCenter = useMemo(() => {
    const sum = 0;
    const sumWithInitial = chartSeries.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      sum
    );
    return {
      id: 'textCenter',
      beforeDatasetsDraw(chart, args, pluginOptions) {
        const { ctx, data } = chart;

        ctx.save();
        ctx.font = 'bolder 20px sans-serif';
        ctx.fillStyle = '#3F434A';
        ctx.textAlign = 'center';
        ctx.textBaseLine = 'top';
        ctx.fillText(Utils.formatNumber(sumWithInitial), chart.getDatasetMeta(0).data[0].x, 30);
      },
    };
  }, [chartSeries]);

  const data = {
    labels: chartLabels,

    datasets: [
      {
        label: '',
        data: chartSeries,
        backgroundColor: chartColors,
        borderColor: '#fff',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div ref={refCardDonutChart} className="CardDonutChartV2 h-100">
      <div
        style={{ borderRadius: '14px' }}
        className={`card card-custom card-stretch gutter-b ${additionalClassName}`}
      >
        {/* card header */}
        <div className="card-header border-0 py-6 d-flex flex-wrap align-items-center justify-content-between">
          <div
            className={`${headerSidebar ? 'd-flex flex-wrap justify-content-between w-100' : ''}`}
          >
            {/* card title */}
            <h3 className="card-title">
              <span className="card-label font-weight-bolder font-size-h4 text-dark-75">
                {title}
              </span>
              <span className="d-block text-muted mt-2 font-size-base">{subTitle}</span>
            </h3>
            <div>{headerSidebar}</div>
          </div>
        </div>

        {/* card body */}
        <div className="card-body d-flex align-items-center justify-content-center pb-7 pt-0 flex-wrap">
          <div className="w-100 h-lg-100">
            {loading ? (
              <div className="d-flex aligin-items-center justify-content-center">
                <VILoading />
              </div>
            ) : chartSeries.length > 0 || !chartSeries?.every((item) => (item = 0)) ? (
              <div>
                <div className="min-h-200px">
                  <Pie options={options} data={data} plugins={[textCenter]} />
                </div>
                <div className="mt-2 d-flex flex-column justify-content-center">
                  {chartLabels.map((item, index) => {
                    return (
                      <KTTooltip key={index} text={`${Utils.formatNumber(chartSeries[index])}`}>
                        <p
                          className={`cursor-pointer d-flex align-items-center ${
                            index === chartLabels.length - 1 ? 'm-0' : 'mb-1'
                          }`}
                        >
                          <i
                            style={{ color: chartColors[index] }}
                            className="fa-solid fa-circle mr-3"
                          ></i>
                          <span>{item}</span>
                        </p>
                      </KTTooltip>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="pt-12">
                <EmptyView message={t('NoDataToDisplay')} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardDonutChartV2;
