import accountApi from 'api/accountApi';
import AccountHelper from 'general/helpers/AccountHelper';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// dang nhap
export const thunkSignIn = createAsyncThunk('auth/signIn', async (params, thunkAPI) => {
  const res = await accountApi.signIn(params?.taxCode, params?.username, params?.password);
  return res;
});

// dang ky
export const thunkSignUp = createAsyncThunk('auth/signUp', async (params, thunkAPI) => {
  const res = await accountApi.signUp(params);
  return res;
});

// dang xuat
export const thunkSignOut = createAsyncThunk('auth/signOut', async (params, thunkAPI) => {
  const res = await accountApi.signOut();
  return res;
});

// lay thong tin profile
export const thunkGetProfile = createAsyncThunk('auth/getProfile', async (params, thunkAPI) => {
  const res = await accountApi.getProfile();
  return res;
});

// doi mat khau
export const thunkChangePassword = createAsyncThunk(
  'auth/changePassword',
  async (params, thunkAPI) => {
    const res = await accountApi.changePassword(params.oldPassword, params.newPassword);
    return res;
  }
);

// cap nhat thong tin profile
export const thunkUpdateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (params, thunkAPI) => {
    const res = await accountApi.updateInfo(params);
    return res;
  }
);

// doi co cau to chuc
export const thunkChangeOrganization = createAsyncThunk(
  'auth/changeOrganization',
  async (params, thunkAPI) => {
    const res = await accountApi.changeOrganization(
      params.organizationId,
      params.organizationDepartmentId
    );
    return res;
  }
);

// gui yc reset mat khau
export const thunkRequestResetPassword = createAsyncThunk(
  'auth/requestResetPassword',
  async (params, thunkAPI) => {
    const res = await accountApi.requestResetPassword(params);
    return res;
  }
);

// reset mat khau
export const thunkResetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (params, thunkAPI) => {
    const res = await accountApi.resetPassword(params);
    return res;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    totalUnreadMail: 0,

    isSigningIn: false,
    isSigningOut: false,
    account: null,
    isSigningUp: false,

    isChangingPassword: false,
    isUpdatingInfo: false,
    isChangingOrganization: false,

    // trang thai online ws
    online: false,

    // reset password
    isRequestingResetPassword: false,
    isResettingPassword: false,
  },
  reducers: {
    setTotalUnreadMail: (state, action) => {
      state.totalUnreadMail = action.payload;
    },
    incrementTotalUnreadMail: (state, action) => {
      state.totalUnreadMail += 1;
    },

    authSignOut: (state, action) => {
      state.account = null;
      state.isSigningIn = false;
      state.totalUnreadMail = 0;
      AccountHelper.removeAccountId();
    },

    // dat trang thai online ws
    setOnline: (state, action) => {
      state.online = action.payload ?? false;
    },

    // thay doi loai dich vu
    setAccountService: (state, action) => {
      state.account.Company.service = action.payload;
    },

    setAccount: (state, action) => {
      state.account = action.payload;
    },
  },
  extraReducers: {
    // dang nhap
    [thunkSignIn.pending]: (state, action) => {
      state.isSigningIn = true;
    },
    [thunkSignIn.rejected]: (state, action) => {
      state.isSigningIn = false;
    },
    [thunkSignIn.fulfilled]: (state, action) => {
      state.isSigningIn = false;
      const { result, account, accessToken, accessTokenExpireDate } = action?.payload;
      if (result === 'success') {
        if (accessToken) {
          AccountHelper.saveAccessToken(accessToken);
        }
        if (accessTokenExpireDate) {
          AccountHelper.saveAccessTokenExpireDate(accessTokenExpireDate);
        }
        state.account = account;
        AccountHelper.saveAccountId(account?.accountId);
      }
    },

    // dang ky
    [thunkSignUp.pending]: (state, action) => {
      state.isSigningUp = true;
    },
    [thunkSignUp.rejected]: (state, action) => {
      state.isSigningUp = false;
    },
    [thunkSignUp.fulfilled]: (state, action) => {
      state.isSigningUp = false;
    },

    // dang xuat
    [thunkSignOut.pending]: (state, action) => {
      state.isSigningOut = true;
    },
    [thunkSignOut.rejected]: (state, action) => {
      state.isSigningOut = false;
    },
    [thunkSignOut.fulfilled]: (state, action) => {
      state.isSigningOut = false;
    },

    // lay thong tin profile
    [thunkGetProfile.fulfilled]: (state, action) => {
      const { result, account, totalUnreadEmail } = action?.payload;
      if (result === 'success') {
        if (account) {
          state.account = {
            ...(state.account ?? {}),
            ...account,
          };
          AccountHelper.saveAccountId(account?.accountId);
        }
        state.totalUnreadMail = totalUnreadEmail;
      }
    },

    // doi mat khau
    [thunkChangePassword.pending]: (state, action) => {
      state.isChangingPassword = true;
    },
    [thunkChangePassword.rejected]: (state, action) => {
      state.isChangingPassword = false;
    },
    [thunkChangePassword.fulfilled]: (state, action) => {
      state.isChangingPassword = false;
    },

    // cap nhat thong tin
    [thunkUpdateProfile.pending]: (state, action) => {
      state.isUpdatingInfo = true;
    },
    [thunkUpdateProfile.rejected]: (state, action) => {
      state.isUpdatingInfo = false;
    },
    [thunkUpdateProfile.fulfilled]: (state, action) => {
      state.isUpdatingInfo = false;
    },

    // doi co cau to chuc
    [thunkChangeOrganization.pending]: (state, action) => {
      state.isChangingOrganization = true;
    },
    [thunkChangeOrganization.rejected]: (state, action) => {
      state.isChangingOrganization = false;
    },
    [thunkChangeOrganization.fulfilled]: (state, action) => {
      state.isChangingOrganization = false;
    },

    // request reset password
    [thunkRequestResetPassword.pending]: (state, action) => {
      state.isRequestingResetPassword = true;
    },
    [thunkRequestResetPassword.rejected]: (state, action) => {
      state.isRequestingResetPassword = false;
    },
    [thunkRequestResetPassword.fulfilled]: (state, action) => {
      state.isRequestingResetPassword = false;
    },

    // reset password
    [thunkResetPassword.pending]: (state, action) => {
      state.isResettingPassword = true;
    },
    [thunkResetPassword.rejected]: (state, action) => {
      state.isResettingPassword = false;
    },
    [thunkResetPassword.fulfilled]: (state, action) => {
      state.isResettingPassword = false;
    },
  },
});

const { reducer, actions } = authSlice;
export const {
  authSignOut,
  setOnline,
  setTotalUnreadMail,
  incrementTotalUnreadMail,
  setAccountService,
  setAccount,
} = actions;
export default reducer;
