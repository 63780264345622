import { unwrapResult } from '@reduxjs/toolkit';
import store from 'app/store';
import {
  thunkConnect,
  thunkConnectAddCreateOrganization,
  thunkDisconnect,
  thunkGetConnectionAxionInHistory,
  thunkGetConnectionAxionOutHistory,
  thunkReload,
  thunkSync,
} from 'features/Axions/axionSlice';
import { thunkGetAllOrganizations } from 'features/Organization/organizationSlice';
import Global from 'general/utils/Global';
import AccountHelper from './AccountHelper';

const { default: PreferenceKeys } = require('general/constants/PreferenceKeys');

const AxionHelper = {
  getSavedUsername: () => {
    return localStorage.getItem(PreferenceKeys.savedAxionUsername) ?? '';
  },
  getSavedPassword: () => {
    return localStorage.getItem(PreferenceKeys.savedAxionPassword) ?? '';
  },
  getSavedSwitchInvoiceIn: () => {
    return localStorage.getItem(PreferenceKeys.savedAxionSwitchInvoiceIn)
      ? localStorage.getItem(PreferenceKeys.savedAxionSwitchInvoiceIn) == 1
      : true;
  },
  getSavedSwitchInvoiceOut: () => {
    return localStorage.getItem(PreferenceKeys.savedAxionSwitchInvoiceOut)
      ? localStorage.getItem(PreferenceKeys.savedAxionSwitchInvoiceOut) == 1
      : false;
  },
  saveUsername: (value) => {
    localStorage.setItem(PreferenceKeys.savedAxionUsername, value);
  },
  savePassword: (value) => {
    localStorage.setItem(PreferenceKeys.savedAxionPassword, value);
  },
  saveSwitchInvoiceIn: (value) => {
    localStorage.setItem(PreferenceKeys.savedAxionSwitchInvoiceIn, value ? 1 : 0);
  },
  saveSwitchInvoiceOut: (value) => {
    localStorage.setItem(PreferenceKeys.savedAxionSwitchInvoiceOut, value ? 1 : 0);
  },

  // ---- api -----
  // goi api ket noi cqt
  callApiConnect: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkConnect(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api dong bo cqt
  callApiSync: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkSync(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api ngat ket noi cqt
  callApiDisconnect: async (isInput, isOutput) => {
    try {
      const params = {
        isInput,
        isOutput,
      };
      const res = unwrapResult(await store.dispatch(thunkDisconnect(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay lich su dong bo
  callApiGetConnectionAxionHistory: async (type = 'IN', params) => {
    const copiedParams = { ...params, type: type === 'IN' ? 'INPUT_INVOICE' : 'OUTPUT_INVOICE' };
    delete copiedParams['dateRangeLabel'];
    try {
      let res;
      if (type === 'IN') {
        res = unwrapResult(await store.dispatch(thunkGetConnectionAxionInHistory(copiedParams)));
      } else {
        res = unwrapResult(await store.dispatch(thunkGetConnectionAxionOutHistory(copiedParams)));
      }
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api reload
  callApiReload: async (id) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkReload(id)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api ket noi va tao co cau to chuc
  callApiConnectAndCreateOrganization: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkConnectAddCreateOrganization(params)));
      const { result, organization } = res;
      if (result === 'success' && organization) {
        Global.gTempStateAddAccountAndCreateOrganization = true;
        // lay danh sach co cau to chuc
        store.dispatch(thunkGetAllOrganizations());
        // doi co cau to chuc
        AccountHelper.callApiChangeOrganization(
          organization?.organizationDepartmentId ? null : organization?.organizationId,
          organization?.organizationDepartmentId ? organization?.organizationDepartmentId : null
        );
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
};

export default AxionHelper;
