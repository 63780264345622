import { Modal } from 'react-bootstrap';
import './style.scss';

import 'split-pane-react/esm/themes/default.css';

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import invoiceApi from 'api/invoiceApi';
import Utils from 'general/utils/Utils';
import VILoading from 'general/components/VietInvoice/VILoading';

const VIModalDetailOutput = (props) => {
  const { t } = useTranslation();

  const { show, onHide, currentDetail } = props;
  const [dataDetail, setDataDetail] = useState();
  const [loadingPdf, setLoadingPdf] = useState(false);

  useEffect(() => {
    if (currentDetail) {
      const fetchApi = async () => {
        setLoadingPdf(true);
        try {
          const result = await invoiceApi?.getDetailInvoice(currentDetail?.invoiceId);
          if (result?.result === 'success') {
            setDataDetail(result?.invoice);
            setLoadingPdf(false);
          }
        } catch (error) {}
      };
      fetchApi();
    }
  }, [currentDetail]);

  return (
    <Modal show={show} onHide={onHide} centered size="xl" className="Modal-resize-outInvoice">
      <Modal.Body className="p-0">
        <div className="p-2 d-flex justify-content-end">
          <i
            className="far fa-times px-3 py-2 rounded cursor-pointer button-close"
            onClick={() => onHide()}
          ></i>
        </div>
        <div style={{ height: '90vh' }} className="">
          <div className="position-relative h-100">
            {loadingPdf ? (
              <div className="w-100 h-100 d-flex justify-content-center">
                <VILoading tableMaxHeight="100%" />
              </div>
            ) : (
              <>
                {dataDetail?.pdfFile ? (
                  <iframe
                    src={Utils.getFullUrl(dataDetail?.pdfFile)}
                    className="w-100 h-100"
                    type="application/pdf"
                  ></iframe>
                ) : (
                  <iframe
                    src={Utils.getFullUrl(dataDetail?.previewPdfFile)}
                    className="w-100 h-100"
                    type="application/pdf"
                  ></iframe>
                )}
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VIModalDetailOutput;
