import { setAccountService } from 'features/Auth/authSlice';
import LandingPageFooter from 'features/LandingPage/components/LandingPageFooter';
import ServicePackCard from 'features/LandingPage/components/ServicePackCard';
import HeaderStore from 'features/Payment/components/HeaderStore';
import ViewOrder from 'features/Payment/components/ViewOrder';
import { addPackageToOrder, removeOrderData } from 'features/Payment/paymentSlice';
import VIAppTabs from 'general/components/VietInvoice/VIAppTabs';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIModal from 'general/components/VietInvoice/VIModal';
import AppData from 'general/constants/AppData';
import PaymentHelper from 'general/helpers/PaymentHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useConfig from 'hooks/useConfig';
import useScrollToTop from 'hooks/useScrollToTop';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import EmptyView from 'features/Others/components/EmptyView';
import AppResource from 'general/constants/AppResource';

function StoreScreen(props) {
  // ---- params ----
  const [modalOrderShowing, setModalOrderShowing] = useState(false);
  const { t } = useTranslation();
  const { servicePackList, isGettingServicePackList } = useSelector((state) => state.payment);
  useScrollToTop();
  const dispatch = useDispatch();
  const { urlBaoGia, urlThoaThuan } = useConfig();
  const account = useAccount();
  const accountCompanyServiceValid = !_.isNull(account.company.service);
  const [accountService, setService] = useState(Global.gTempChoosingServiceType);
  useEffect(() => {
    if (account?.company?.service) {
      setService(account?.company?.service);
    }
  }, [account]);
  const tabList = useMemo(() => {
    if (accountService === AppData.serviceType.smart) {
      return [t('PackageDuration'), t('PackageQuantity')];
    } else {
      return [t('PackageLicense'), t('PackageQuantity')];
    }
  }, [accountService]);
  const [packageType, setPackageType] = useState(AppData.packageType.quantity);
  const [activeTab, setActiveTab] = useState(tabList[1]);
  const filteredServicePackList = useMemo(() => {
    if (_.isNull(accountService)) {
      return servicePackList;
    } else {
      return _.filter(
        servicePackList,
        (item) => item.service === accountService && item.packageType === packageType
      );
    }
  }, [servicePackList, accountService, packageType]);

  useEffect(() => {
    switch (activeTab) {
      case t('PackageDuration'):
        setPackageType(AppData.packageType.duration);
        break;
      case t('PackageQuantity'):
        setPackageType(AppData.packageType.quantity);
        break;
      case t('PackageLicense'):
        setPackageType(AppData.packageType.license);
        break;
      default:
        break;
    }
  }, [activeTab, t]);

  // ---- methods ----
  function handleNext() {
    dispatch(setAccountService(accountService));
  }

  useEffect(() => {
    PaymentHelper.callApiGetVoucherList({});
  }, []);

  return (
    <div className="StoreScreen min-vh-100 d-flex flex-column">
      {/* header */}
      <HeaderStore />

      {/* body */}
      <div className="StoreScreen_Body pb-10">
        <div className="container-xl flex-grow-1 my-5">
          <h1 className="card-label font-weight-boldest text-center">
            {`${
              !accountCompanyServiceValid
                ? 'Chọn loại dịch vụ bạn muốn sử dụng'
                : 'Báo giá ICORP Invoice - Dịch vụ xử lý hóa đơn'
            }`}
          </h1>
          {/* <h6 className="text-center font-weight-boldest text-dark-50">
            Phí dịch vụ phần mềm hàng năm: 500.000 VNĐ/năm
          </h6> */}
          <h6 className="text-center font-weight-boldest text-dark-50">
            {`Danh sách các gói dịch vụ ${account.company.serviceName}`}
          </h6>

          <div className="my-6">
            <VIAppTabs
              tabs={tabList}
              activeTab={activeTab}
              handleClick={(tab) => {
                setActiveTab(tab);
              }}
            />
            {/* danh sach goi */}
            <div
              className="bg-white px-8"
              style={{
                borderRadius: '0px 0px 16px 16px',
                borderLeft: '1px solid #dbe3ef',
                borderRight: '1px solid #dbe3ef',
                borderBottom: '1px solid #dbe3ef',
              }}
            >
              {accountCompanyServiceValid ? (
                <div>
                  {filteredServicePackList?.length > 0 ? (
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 pt-8 pb-4">
                      {filteredServicePackList?.map((item, index) => (
                        <div key={index} className="mb-5 col">
                          <ServicePackCard
                            packageCode={item?.packageCode}
                            packageCost={item?.packageCost}
                            description={item?.description}
                            recommend={item?.recommend}
                            onClick={() => {
                              dispatch(addPackageToOrder(item));
                              setModalOrderShowing(true);
                            }}
                            unit={item?.unit}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="p-10">
                      <EmptyView
                        message={t('NoDataToDisplay')}
                        image={AppResource.images.imgEmptyPayment}
                      />
                    </div>
                  )}
                </div>
              ) : (
                // chon loai dich vu
                <div className="mb-8">
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 pt-8 pb-4 justify-content-center gap-4">
                    {/* I-SMART */}
                    <div
                      className="mb-5 col p-10 bg-white"
                      style={{
                        borderRadius: '16px',
                        border: '1px solid #DDE5EE',
                      }}
                    >
                      <h2 className="text-center font-weight-boldest">I-SMART</h2>
                      <div className="mb-3 font-size-lg">Gói I-SMART</div>
                      <VIButton
                        className={`${
                          accountService === AppData.serviceType.smart ? 'btn-blue' : 'btn-white'
                        } w-100 font-size-h5 border-2 border-light-dark`}
                        text={
                          accountService === AppData.serviceType.smart ? t('Choosing') : t('Choose')
                        }
                        onClick={() => {
                          Global.gTempChoosingServiceType = AppData.serviceType.smart;
                          setService(Global.gTempChoosingServiceType);
                        }}
                        disabled={accountService === AppData.serviceType.smart}
                        beforeIcon={
                          accountService === AppData.serviceType.smart ? (
                            <i
                              className="fa-regular fa-circle-check"
                              style={{ fontSize: '1.5rem' }}
                            />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </div>

                    {/* I-PRO */}
                    <div
                      className="mb-5 col p-10 bg-white"
                      style={{
                        borderRadius: '16px',
                        border: '1px solid #DDE5EE',
                      }}
                    >
                      <h2 className="text-center font-weight-boldest">I-PRO</h2>
                      <div className="mb-3 font-size-lg">Gói I-PRO</div>
                      <VIButton
                        className={`${
                          accountService === AppData.serviceType.pro ? 'btn-blue' : 'btn-white'
                        } w-100 font-size-h5 border-2 border-light-dark`}
                        text={
                          accountService === AppData.serviceType.pro ? t('Choosing') : t('Choose')
                        }
                        onClick={() => {
                          Global.gTempChoosingServiceType = AppData.serviceType.pro;
                          setService(Global.gTempChoosingServiceType);
                        }}
                        disabled={accountService === AppData.serviceType.pro}
                        beforeIcon={
                          accountService === AppData.serviceType.pro ? (
                            <i
                              className="fa-regular fa-circle-check"
                              style={{ fontSize: '1.5rem' }}
                            />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    <VIButton className="btn-blue" text={t('Next')} onClick={handleNext} />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* tai xuong */}
          <p className="text-center font-weight-boldest font-size-lg text-grey mb-4">
            Tải xuống ngay
          </p>
          <div className="d-flex justify-content-center gap-3">
            <VIButton
              text={t('Báo giá')}
              className="btn-grey"
              style={{ padding: '8px 16px' }}
              beforeIcon={<i className="fa-regular fa-file-pdf text-danger" />}
              onClick={() => {
                Utils.openInNewTab(urlBaoGia);
              }}
            />
            <VIButton
              text={t('Thoả thuận sử dụng')}
              className="btn-grey"
              style={{ padding: '8px 16px' }}
              beforeIcon={<i className="fa-regular fa-file-pdf text-danger" />}
              onClick={() => {
                Utils.openInNewTab(urlThoaThuan);
              }}
            />
          </div>

          {/* luu y */}
          <div style={{ color: '#1C2256' }}>
            <h3 className="font-weight-boldest">{t('Notice')}</h3>
            <ul className="font-size-lg">
              <li>
                Khách hàng có thể mua trước nhiều hóa đơn, sử dụng trong nhiều năm, số lượng hóa đơn
                chưa sử dụng hết trong thời gian sử dụng dịch vụ sẽ được tiếp tục sử dụng sau khi
                khách hàng gia hạn dịch vụ
              </li>
              <li>Không giới hạn số lượng người dùng</li>
              <li>Báo giá có hiệu lực từ ngày 01/09/2023</li>
            </ul>
          </div>
        </div>
      </div>

      {/* footer */}
      <LandingPageFooter />

      {/* modal order */}
      <VIModal
        titleModal={t('Order')}
        modalSize="lg"
        show={modalOrderShowing}
        contentModal={
          <ViewOrder
            onPressCancel={() => {
              setModalOrderShowing(false);
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalOrderShowing(false);
          dispatch(removeOrderData());
        }}
      />
    </div>
  );
}

export default StoreScreen;
