import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Overlay } from 'react-bootstrap';
import './style.scss';

VITableRowDropDown.propTypes = {
  menuItem: PropTypes.array,
  toggleElement: PropTypes.element,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  dropdwonContent: PropTypes.element,
};

VITableRowDropDown.defaultProps = {
  menuItem: [],
  toggleElement: null,
  name: '',
  className: '',
  dropdwonContent: null,
};

function VITableRowDropDown(props) {
  const { menuItem, toggleElement, name, className, dropdwonContent } = props;
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef(null);
  const refDropdownMenu = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        if (!dropdwonContent) {
          setShowMenu(false);
        } else if (!refDropdownMenu.current?.contains(event.target) && refDropdownMenu.current) {
          setShowMenu(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setShowMenu(!showMenu);
  };

  const handleClose = () => {
    setShowMenu(false);
  };

  return (
    <Dropdown className="VITableRowDropDown" ref={dropdownRef}>
      <Dropdown.Toggle className={`border-0 ${className}`} variant="" onClick={handleToggle}>
        {toggleElement}
      </Dropdown.Toggle>

      <Overlay show={showMenu} placement="bottom" target={(ref) => ref && ref.parentElement}>
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              position: 'absolute',
              zIndex: 9,
              boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              backgroundColor: 'white',
              padding: '10px',
            }}
          >
            <Dropdown.Menu ref={refDropdownMenu} show={showMenu}>
              {dropdwonContent ? (
                dropdwonContent
              ) : (
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  {menuItem.map((item, index) => {
                    return (
                      <div
                        className={`navi-item px-2`}
                        key={index}
                        onClick={() => {
                          handleClose();
                          if (item.click) {
                            item.click();
                          }
                        }}
                      >
                        <a
                          href="#"
                          className="navi-link rounded-3 px-3"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <span className="navi-icon mr-1">
                            <span className="svg-icon">{item?.iconElement}</span>
                          </span>
                          <span
                            className="navi-text font-size-lg me-3"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {item?.text}
                          </span>
                        </a>
                      </div>
                    );
                  })}
                </div>
              )}
            </Dropdown.Menu>
          </div>
        )}
      </Overlay>
    </Dropdown>
  );
}

export default VITableRowDropDown;
