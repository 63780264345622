import organizationApi from 'api/organizationApi';
import organizationDepartmentApi from 'api/organizationDepartmentApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// lay tat ca co cau to chuc
export const thunkGetAllOrganizations = createAsyncThunk(
  'organization/getAllOrganizations',
  async (params, thunkAPI) => {
    const res = await organizationApi.getAll();
    return res;
  }
);

// cap nhat thong tin cong ty, chi nhanh
export const thunkUpdateCompanyInfo = createAsyncThunk(
  'organization/updateCompanyInfo',
  async (params, thunkAPI) => {
    const res = await organizationApi.updateInfo(params?.id, params?.params);
    return res;
  }
);

// cap nhat thong tin cong ty, chi nhanh voi nhieu dia chi
export const thunkUpdateCompanyInfoMultiAddress = createAsyncThunk(
  'organization/updateCompanyInfoMultiAddress',
  async (params, thunkAPI) => {
    const res = await organizationApi.updateInfoMultiAddress(params?.id, params?.params);
    return res;
  }
);

// tao cong ty, chi nhanh moi
export const thunkCreateCompany = createAsyncThunk(
  'organization/createCompany',
  async (params, thunkAPI) => {
    const res = await organizationApi.createNew(params);
    return res;
  }
);

// tao cong ty, chi nhanh moi voi nhieu dia chi
export const thunkCreateCompanyMultiAddress = createAsyncThunk(
  'organization/createCompanyMultiAddress',
  async (params, thunkAPI) => {
    const res = await organizationApi.createNewMultiAddress(params);
    return res;
  }
);

// xoa cong ty, chi nhanh
export const thunkDeleteCompany = createAsyncThunk(
  'organization/deleteCompany',
  async (ids, thunkAPI) => {
    const res = await organizationApi.delete(ids);
    return res;
  }
);

// tao phong ban moi
export const thunkCreateDepartment = createAsyncThunk(
  'organization/createDepartment',
  async (params, thunkAPI) => {
    const res = await organizationDepartmentApi.createNew(params);
    return res;
  }
);

// xoa phong ban
export const thunkDeleteDepartment = createAsyncThunk(
  'organization/deleteDepartment',
  async (ids, thunkAPI) => {
    const res = await organizationDepartmentApi.delete(ids);
    return res;
  }
);

// cap nhat thong tin phong ban
export const thunkUpdateDepartmentInfo = createAsyncThunk(
  'organization/updateDepartmentInfo',
  async (params, thunkAPI) => {
    const res = await organizationDepartmentApi.updateInfo(params?.id, params?.params);
    return res;
  }
);

// thiet lap cai dat
export const thunkUpdateSetting = createAsyncThunk(
  'organization/updateSetting',
  async (params, thunkAPI) => {
    const res = await organizationApi.updateSetting(params);
    return res;
  }
);

// tao danh sach ncc khong tai ve hoa don
export const thunkCreateListSupplierNotDownloadInvoice = createAsyncThunk(
  'organization/createListSupplierNotDownloadInvoice',
  async (params, thunkAPI) => {
    const res = await organizationApi.createListSupplierNotDownloadInvoice(params);
    return res;
  }
);

// lay danh sach ncc khong tai ve hoa don
export const thunkGetListSupplierNotDownloadInvoice = createAsyncThunk(
  'organization/getListSupplierNotDownloadInvoice',
  async (params, thunkAPI) => {
    const res = await organizationApi.getListSupplierNotDownloadInvoice();
    return res;
  }
);

// tao ds tu dong dieu chuyen hoa don cho chi nhanh/phong ban
export const thunkCreateListAutoTransferInvoiceDepartment = createAsyncThunk(
  'organization/createListAutoTransferInvoiceDepartment',
  async (params, thunkAPI) => {
    const res = await organizationApi.createListAutoTransferInvoiceDepartment(params);
    return res;
  }
);

// lay ds tu dong dieu chuyen hoa don cho chi nhanh/phong ban
export const thunkGetListAutoTransferInvoiceDepartment = createAsyncThunk(
  'organization/getListAutoTransferInvoiceDepartment',
  async (params, thunkAPI) => {
    const res = await organizationApi.getListAutoTransferInvoiceDepartment();
    return res;
  }
);

// tao ds tu dong dieu chuyen hoa don theo mst nguoi mua
export const thunkCreateListAutoTransferByBuyerTaxCode = createAsyncThunk(
  'organization/createListAutoTransferByBuyerTaxCode',
  async (params, thunkAPI) => {
    const res = await organizationApi.createListAutoTransferByBuyerTaxCode(params);
    return res;
  }
);

// lay ds tu dong dieu chuyen hoa don theo mst nguoi mua
export const thunkGetListAutoTransferByBuyerTaxCode = createAsyncThunk(
  'organization/getListAutoTransferByBuyerTaxCode',
  async (params, thunkAPI) => {
    const res = await organizationApi.getListAutoTransferByBuyerTaxCode();
    return res;
  }
);

const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    organizations: [],
    isGettingOrganization: false,
    isUpdatingCompany: false,
    isCreatingCompany: false,
    isDeletingCompany: false,
    isCreatingDepartment: false,
    isDeletingDepartment: false,
    isUpdatingDepartment: false,
    isUpdatingSetting: false,

    isCreatingListSupplierNotDownloadInvoice: false,
    isGettingListSupplierNotDownloadInvoice: false,
    listSupplierNotDownloadInvoice: [],

    isCreatingListAutoTransferInvoiceDepartment: false,
    isGettingListAutoTransferInvoiceDepartment: false,
    listAutoTransferInvoiceDepartment: [],

    isCreatingListAutoTransferByBuyerTaxCode: false,
    isGettingListAutoTransferByBuyerTaxCode: false,
    listAutoTransferByBuyerTaxCode: [],
  },
  reducers: {
    organizationSignOut: (state, action) => {
      state.organizations = [];
      state.isGettingOrganization = false;
      state.isUpdatingCompany = false;
      state.isCreatingCompany = false;
      state.isDeletingCompany = false;
      state.isCreatingDepartment = false;
      state.isDeletingDepartment = false;
      state.isUpdatingDepartment = false;
      state.isUpdatingSetting = false;
      state.isCreatingListSupplierNotDownloadInvoice = false;
      state.isGettingListSupplierNotDownloadInvoice = false;
      state.listSupplierNotDownloadInvoice = [];

      state.isCreatingListAutoTransferInvoiceDepartment = false;
      state.isGettingListAutoTransferInvoiceDepartment = false;
      state.listAutoTransferInvoiceDepartment = [];
    },
  },
  extraReducers: {
    // lay tat ca co cau to chuc
    [thunkGetAllOrganizations.fulfilled]: (state, action) => {
      const { result, organizations } = action.payload;
      if (result === 'success' && organizations) {
        state.organizations = organizations;
      }
    },

    // cap nhat thong tin cong ty, chi nhanh
    [thunkUpdateCompanyInfo.pending]: (state, action) => {
      state.isUpdatingCompany = true;
    },
    [thunkUpdateCompanyInfo.rejected]: (state, action) => {
      state.isUpdatingCompany = false;
    },
    [thunkUpdateCompanyInfo.fulfilled]: (state, action) => {
      state.isUpdatingCompany = false;
    },

    // cap nhat thong tin cong ty, chi nhanh voi nhieu dia chi
    [thunkUpdateCompanyInfoMultiAddress.pending]: (state, action) => {
      state.isUpdatingCompany = true;
    },
    [thunkUpdateCompanyInfoMultiAddress.rejected]: (state, action) => {
      state.isUpdatingCompany = false;
    },
    [thunkUpdateCompanyInfoMultiAddress.fulfilled]: (state, action) => {
      state.isUpdatingCompany = false;
    },

    // tao cong ty, chi nhanh
    [thunkCreateCompany.pending]: (state, action) => {
      state.isCreatingCompany = true;
    },
    [thunkCreateCompany.rejected]: (state, action) => {
      state.isCreatingCompany = false;
    },
    [thunkCreateCompany.fulfilled]: (state, action) => {
      state.isCreatingCompany = false;
    },

    // tao cong ty, chi nhanh voi nhieu dia chi
    [thunkCreateCompanyMultiAddress.pending]: (state, action) => {
      state.isCreatingCompany = true;
    },
    [thunkCreateCompanyMultiAddress.rejected]: (state, action) => {
      state.isCreatingCompany = false;
    },
    [thunkCreateCompanyMultiAddress.fulfilled]: (state, action) => {
      state.isCreatingCompany = false;
    },

    // xoa cong ty, chi nhanh
    [thunkDeleteCompany.pending]: (state, action) => {
      state.isDeletingCompany = true;
    },
    [thunkDeleteCompany.rejected]: (state, action) => {
      state.isDeletingCompany = false;
    },
    [thunkDeleteCompany.fulfilled]: (state, action) => {
      state.isDeletingCompany = false;
    },

    // tao phong ban
    [thunkCreateDepartment.pending]: (state, action) => {
      state.isCreatingDepartment = true;
    },
    [thunkCreateDepartment.rejected]: (state, action) => {
      state.isCreatingDepartment = false;
    },
    [thunkCreateDepartment.fulfilled]: (state, action) => {
      state.isCreatingDepartment = false;
    },

    // xoa phong ban
    [thunkDeleteDepartment.pending]: (state, action) => {
      state.isDeletingDepartment = true;
    },
    [thunkDeleteDepartment.rejected]: (state, action) => {
      state.isDeletingDepartment = false;
    },
    [thunkDeleteDepartment.fulfilled]: (state, action) => {
      state.isDeletingDepartment = false;
    },

    // cap nhat thong tin phong ban
    [thunkUpdateDepartmentInfo.pending]: (state, action) => {
      state.isUpdatingDepartment = true;
    },
    [thunkUpdateDepartmentInfo.rejected]: (state, action) => {
      state.isUpdatingDepartment = false;
    },
    [thunkUpdateDepartmentInfo.fulfilled]: (state, action) => {
      state.isUpdatingDepartment = false;
    },

    // thiet lap cai dat
    [thunkUpdateSetting.pending]: (state, action) => {
      state.isUpdatingSetting = true;
    },
    [thunkUpdateSetting.rejected]: (state, action) => {
      state.isUpdatingSetting = false;
    },
    [thunkUpdateSetting.fulfilled]: (state, action) => {
      state.isUpdatingSetting = false;
    },

    // tao danh sach ncc khong tai ve hoa don
    [thunkCreateListSupplierNotDownloadInvoice.pending]: (state, action) => {
      state.isCreatingListSupplierNotDownloadInvoice = true;
    },
    [thunkCreateListSupplierNotDownloadInvoice.rejected]: (state, action) => {
      state.isCreatingListSupplierNotDownloadInvoice = false;
    },
    [thunkCreateListSupplierNotDownloadInvoice.fulfilled]: (state, action) => {
      state.isCreatingListSupplierNotDownloadInvoice = false;
    },

    // lay danh sach ncc khong tai ve hoa don
    [thunkGetListSupplierNotDownloadInvoice.pending]: (state, action) => {
      state.isGettingListSupplierNotDownloadInvoice = true;
    },
    [thunkGetListSupplierNotDownloadInvoice.rejected]: (state, action) => {
      state.isGettingListSupplierNotDownloadInvoice = false;
    },
    [thunkGetListSupplierNotDownloadInvoice.fulfilled]: (state, action) => {
      state.isGettingListSupplierNotDownloadInvoice = false;
      const { result, supplierNotDownloadInvoices } = action.payload;
      if (result === 'success' && supplierNotDownloadInvoices) {
        state.listSupplierNotDownloadInvoice = supplierNotDownloadInvoices;
      }
    },

    // tao ds tu dong dieu chuyen hoa don cho chi nhanh/phong ban
    [thunkCreateListAutoTransferInvoiceDepartment.pending]: (state, action) => {
      state.isCreatingListAutoTransferInvoiceDepartment = true;
    },
    [thunkCreateListAutoTransferInvoiceDepartment.rejected]: (state, action) => {
      state.isCreatingListAutoTransferInvoiceDepartment = false;
    },
    [thunkCreateListAutoTransferInvoiceDepartment.fulfilled]: (state, action) => {
      state.isCreatingListAutoTransferInvoiceDepartment = false;
    },

    // lay ds tu dong dieu chuyen hoa don cho chi nhanh/phong ban
    [thunkGetListAutoTransferInvoiceDepartment.pending]: (state, action) => {
      state.isGettingListAutoTransferInvoiceDepartment = true;
    },
    [thunkGetListAutoTransferInvoiceDepartment.rejected]: (state, action) => {
      state.isGettingListAutoTransferInvoiceDepartment = false;
    },
    [thunkGetListAutoTransferInvoiceDepartment.fulfilled]: (state, action) => {
      state.isGettingListAutoTransferInvoiceDepartment = false;
      const { result, autoTransferInvoices } = action.payload;
      if (result === 'success' && autoTransferInvoices) {
        state.listAutoTransferInvoiceDepartment = autoTransferInvoices;
      }
    },

    // tao ds tu dong dieu chuyen hoa don theo mst nguoi mua
    [thunkCreateListAutoTransferByBuyerTaxCode.pending]: (state, action) => {
      state.isCreatingListAutoTransferByBuyerTaxCode = true;
    },
    [thunkCreateListAutoTransferByBuyerTaxCode.rejected]: (state, action) => {
      state.isCreatingListAutoTransferByBuyerTaxCode = false;
    },
    [thunkCreateListAutoTransferByBuyerTaxCode.fulfilled]: (state, action) => {
      state.isCreatingListAutoTransferByBuyerTaxCode = false;
    },

    // lay ds tu dong dieu chuyen hoa don theo mst nguoi mua
    [thunkGetListAutoTransferByBuyerTaxCode.pending]: (state, action) => {
      state.isGettingListAutoTransferByBuyerTaxCode = true;
    },
    [thunkGetListAutoTransferByBuyerTaxCode.rejected]: (state, action) => {
      state.isGettingListAutoTransferByBuyerTaxCode = false;
    },
    [thunkGetListAutoTransferByBuyerTaxCode.fulfilled]: (state, action) => {
      state.isGettingListAutoTransferByBuyerTaxCode = false;
      const { result, autoTransferInvoiceFromEmails } = action.payload;
      if (result === 'success' && autoTransferInvoiceFromEmails) {
        state.listAutoTransferByBuyerTaxCode = autoTransferInvoiceFromEmails;
      }
    },
  },
});

const { reducer, actions } = organizationSlice;
export const { organizationSignOut } = actions;
export default reducer;
