import categoryApi from 'api/categoryApi';
import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import EmptyView from 'features/Others/components/EmptyView';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Global from 'general/utils/Global';

ViewChooseSupplier.propTypes = {
  onPressedCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onPressedAddSupplier: PropTypes.func,
};

ViewChooseSupplier.defaultProps = {
  onPressedCancel: null,
  onSubmit: null,
  onPressedAddSupplier: null,
};

/**
 *
 * @param {{
 * onPressedCancel: function,
 * onSubmit: function,
 * onPressedAddSupplier: function,
 * }} props
 * @returns
 */
function ViewChooseSupplier(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { onPressedCancel, onSubmit, onPressedAddSupplier } = props;
  const [flag, setFlag] = useState({});
  const columns = useMemo(() => {
    return [
      {
        name: t('STT'),
        sortable: false,
        center: true,
        width: '80px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {index + 1}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        sortable: false,
        width: '180px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.taxCode}
            </p>
          );
        },
      },
      {
        name: t('SupplierName'),
        sortable: false,
        minWidth: '220px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.partnerCompanyName}
            </p>
          );
        },
      },
      {
        name: t('Address'),
        sortable: false,
        style: {
          borderRight: 0,
        },
        minWidth: '260px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.address}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            <KTTooltip text={t('Delete')}>
              <i
                className="fa-regular fa-trash text-danger"
                onClick={() => {
                  const tmp = refSelectedSuppliers.current.filter(
                    (item) => item.partnerCompanyId !== row.partnerCompanyId
                  );
                  refSelectedSuppliers.current = tmp;
                  Global.gTempChoosingSupplierList = refSelectedSuppliers.current;
                  setFlag({});
                }}
              />
            </KTTooltip>
          </div>
        ),
      },
    ];
  }, [flag]);
  const columnsSearchResult = useMemo(() => {
    return [
      {
        name: t('STT'),
        sortable: false,
        center: true,
        width: '80px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {index + 1}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        sortable: false,
        width: '180px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.taxCode}
            </p>
          );
        },
      },
      {
        name: t('SupplierName'),
        sortable: false,
        minWidth: '220px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.partnerCompanyName}
            </p>
          );
        },
      },
      {
        name: t('Address'),
        sortable: false,
        style: {
          borderRight: 0,
        },
        minWidth: '260px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.address}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            {!refSelectedSuppliers.current
              .map((item) => item.partnerCompanyId)
              .includes(row.partnerCompanyId) && (
              <KTTooltip text={t('Add')}>
                <i
                  className="fa-regular fa-add text-primary"
                  onClick={() => {
                    refSelectedSuppliers.current.push(row);
                    Global.gTempChoosingSupplierList = refSelectedSuppliers.current;
                    setFlag({});
                  }}
                />
              </KTTooltip>
            )}
          </div>
        ),
      },
    ];
  }, [flag]);
  const [searchResultItems, setSearchResultItems] = useState([]);
  const [filterSearch, setFilterSearch] = useState({
    q: '',
  });
  const refSearchView = useRef();
  const [showSearchResultView, setShowSearchResultView] = useState(false);
  const refSelectedSuppliers = useRef([]);
  const { listSupplierNotDownloadInvoice } = useSelector((state) => state.organization);

  useEffect(() => {
    searchSupplier(filterSearch);
  }, [filterSearch]);

  useOnClickOutside(refSearchView, () => {
    setShowSearchResultView(false);
  });

  useEffect(() => {
    if (!Global.gTempChoosingSupplierList) {
      refSelectedSuppliers.current = [
        ...listSupplierNotDownloadInvoice?.map((item) => item?.PartnerCompany),
      ];
      Global.gTempChoosingSupplierList = refSelectedSuppliers.current;
    } else {
      refSelectedSuppliers.current = Global.gTempChoosingSupplierList;
    }
    setFlag({});
  }, [listSupplierNotDownloadInvoice]);

  // ---- methods ----
  function handlePressedCancel() {
    if (onPressedCancel) {
      onPressedCancel();
    }
  }

  async function searchSupplier(params) {
    try {
      const res = await categoryApi.getSupplierCustomerList(params);
      const { result, partnerCompanies, total, count } = res;
      if (result === 'success' && partnerCompanies) {
        setSearchResultItems(partnerCompanies);
        // if (!showSearchResultView) {
        //   setShowSearchResultView(true);
        // }
      }
    } catch (error) {
      console.log({ error });
    }
  }

  function handleSave() {
    if (onSubmit) {
      onSubmit(refSelectedSuppliers.current);
    }
  }

  return (
    <div>
      <div>
        <div className="d-flex align-items-center justify-content-between gap-3">
          <div className="flex-grow-1 position-relative" ref={refSearchView}>
            <VISearchField
              placeholder="Nhập MST hoặc tên NCC để tìm kiếm..."
              value={filterSearch.q}
              inputClassName="w-100"
              onFocus={() => {
                setShowSearchResultView(true);
              }}
              className="mb-1"
              typingTimeout={500}
              onSubmit={(newValue) => {
                setFilterSearch({
                  ...filterSearch,
                  q: newValue,
                });
              }}
            />
            {showSearchResultView && (
              <div
                className="bg-white px-4 position-absolute w-100 shadow"
                style={{
                  zIndex: 3,
                  borderRadius: '12px',
                }}
              >
                <VIDataTable
                  draggable={false}
                  tableStyles={customDataTableActionStyle}
                  additionalClassName="p-0 shadow-none mt-2"
                  additionalCardBodyClassName="p-0"
                  selectable={false}
                  pagination={null}
                  onChangePage={(page) => {}}
                  onChangeRowsPerPage={(perPage) => {}}
                  fullColumns={columnsSearchResult}
                  data={searchResultItems}
                  loading={false}
                  onRowClick={(row) => {}}
                  onSetSelectedMultiItems={(rows) => {}}
                  haveCardHeader={false}
                  emptyComponent={<EmptyView message="Không tìm thấy kết quả" />}
                  minusHeight={300}
                />
              </div>
            )}
          </div>
          <VIButton
            text={t('AddSupplier')}
            className=""
            style={{ padding: '8px' }}
            onClick={() => {
              if (onPressedAddSupplier) {
                onPressedAddSupplier();
              }
            }}
            beforeIcon={<i className="fa-regular fa-add" />}
          />
        </div>
        <VIDataTable
          draggable={false}
          tableStyles={customDataTableActionStyle}
          additionalClassName="p-0 shadow-none mt-2"
          additionalCardBodyClassName="p-0"
          selectable={false}
          pagination={null}
          onChangePage={(page) => {}}
          onChangeRowsPerPage={(perPage) => {}}
          fullColumns={columns}
          data={refSelectedSuppliers.current}
          loading={false}
          onRowClick={(row) => {}}
          onSetSelectedMultiItems={(rows) => {}}
          haveCardHeader={false}
          minusHeight={200}
          emptyComponent={<EmptyView message="Chưa có nhà cung cấp nào được chọn" />}
        />
      </div>

      {/* actions */}
      <div className="d-flex align-items-center justify-content-end gap-3 mt-3">
        <VIButton
          text={t('Cancel')}
          className="btn-grey"
          style={{ padding: '8px' }}
          onClick={handlePressedCancel}
        />
        <VIButton text={t('Save')} className="" style={{ padding: '8px' }} onClick={handleSave} />
      </div>
    </div>
  );
}

export default ViewChooseSupplier;
