import React from 'react';
import PropTypes from 'prop-types';
import Utils from 'general/utils/Utils';
import { useTranslation } from 'react-i18next';
import KTFormTextArea from 'general/components/OtherKeenComponents/Forms/KTFormTextArea';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';

AuditLogDetailView.propTypes = {
  item: PropTypes.object,
};

AuditLogDetailView.defaultProps = {
  item: null,
};

function AuditLogDetailView(props) {
  // ---- params ----
  const { item } = props;
  const { t } = useTranslation();

  return (
    <div
      className="overflow-auto"
      style={{
        maxHeight: '70vh',
      }}
    >
      {/* co cau to chuc */}
      <p>{item?.organization}</p>

      {/* nguoi dung */}
      <p>
        {item?.Account?.fullname} - {item?.action} -{' '}
        {`${t('At')}: ${
          item?.createdAt ? Utils.formatDateTime(item?.createdAt, 'DD/MM/YYYY HH:mm:ss') : ''
        }`}
      </p>
      {/* tham chieu */}
      <p>
        {t('Reference')}: {item?.reference}
      </p>
      {/* mo ta */}
      <KTFormGroup
        inputName="textAreaAuditLogDescription"
        label={t('Description')}
        inputElement={
          <KTFormTextArea name="textAreaAuditLogDescription" value={item?.description} disabled />
        }
      />
    </div>
  );
}

export default AuditLogDetailView;
