import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';
import AxionConnectScreen from './screens/AxionConnectScreen';

Axions.propTypes = {};

function Axions(props) {
  return (
    <Routes>
      <Route path="" element={<Navigate to="connect" />} />
      <Route path="connect/*" element={<AxionConnectScreen />} />
    </Routes>
  );
}

export default Axions;
