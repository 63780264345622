import invoiceApi from 'api/invoiceApi';
import customDataTableStyle from 'assets/styles/customDataTableStyle';
import EmptyView from 'features/Others/components/EmptyView';
import VILoading from 'general/components/VietInvoice/VILoading';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

function ListOfOccupations(props) {
  const { taxCode } = props;
  const { t } = useTranslation();
  const [listOfOccupations, setDataListOfOccupations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const fetchApi = async () => {
        setLoading(true);
        const res = await invoiceApi.getListGoodAndService({ taxCode: taxCode });
        if (res?.result === 'success') {
          setDataListOfOccupations(res?.businesses);
          setLoading(false);
        }
      };
      fetchApi();
    } catch (error) {}
  }, []);
  const columns = useMemo(
    () => [
      {
        name: 'STT',
        width: '70px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {index + 1}
            </p>
          );
        },
      },
      {
        name: t('IndustryCode'),
        width: '130px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.businessCode ?? ''}
            </p>
          );
        },
      },
      {
        name: t('IndustryName'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.name ?? ''}
            </p>
          );
        },
      },
      {
        name: 'NNKD chính',
        width: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.name === listOfOccupations?.mainBusinessName ? 'Y' : '' ?? ''}
            </p>
          );
        },
      },
    ],
    []
  );
  return (
    <div>
      <DataTable
        className="h-100"
        fixedHeader
        fixedHeaderScrollHeight={'80vh'}
        columns={columns}
        customStyles={customDataTableStyle}
        data={listOfOccupations}
        noDataComponent={<EmptyView message={t('NoDataToDisplay')} tableMaxHeight={'80vh'} />}
        progressPending={loading}
        progressComponent={<VILoading tableMaxHeight={'80vh'} />}
        responsive={true}
        noHeader
        striped
        pointerOnHover
        highlightOnHover
        selectableRowsHighlight
      />
    </div>
  );
}

export default ListOfOccupations;
