import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import './style.scss';
import { useSelector } from 'react-redux';

AppLoading.propTypes = {
  animation: PropTypes.string,
  variant: PropTypes.string,
  classNames: PropTypes.string,
};
AppLoading.defaultProps = {
  animation: 'border',
  variant: 'primary',
  classNames: '',
};

/**
 *
 * @param {} props
 * @returns sondang
 */
function AppLoading(props) {
  const { animation, variant, className } = props;
  const { showAppLoading } = useSelector((state) => state.app);

  return !showAppLoading ? null : (
    <div className="AppLoading flex-fill" style={{ backgroundColor: '#00000030' }}>
      <div className="spin">
        <Spinner animation={animation} variant={variant} className={className} />
      </div>
    </div>
  );
}

export default AppLoading;
