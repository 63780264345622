import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

VIButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.any,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  onClick: PropTypes.func,
  beforeIcon: PropTypes.element,
  afterIcon: PropTypes.element,
  style: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

VIButton.defaultProps = {
  id: null,
  className: '',
  text: null,
  fontSize: null,
  fontWeight: null,
  onClick: () => {},
  beforeIcon: null,
  afterIcon: null,
  style: {},
  type: '',
  disabled: false,
};

/**
 * @param {string} id Button id
 * @param {string} className Button className
 * @param {string} text Button text
 * @param {string} fontSize text's font size
 * @param {string} fontWeight text's font weight
 * @param {function} onClick Handle when click button
 * @param {element} beforeIcon Button icon before text
 * @param {element} afterIcon Button icon after text
 * @param {object} style Button style
 * @param {string} type Button type
 * @param {boolean} disabled
 */

function VIButton(props) {
  const {
    id,
    className,
    text,
    fontSize,
    fontWeight,
    onClick,
    beforeIcon,
    afterIcon,
    style,
    type,
    disabled,
  } = props;

  function clicked(e) {
    if (onClick) {
      onClick(e);
    }
  }

  return (
    <button
      id={id}
      className={`d-flex flex-row align-items-center justify-content-center ${
        className || 'btn-blue'
      }`}
      style={{
        whiteSpace: 'nowrap',
        fontSize: fontSize || '1rem',
        fontWeight: fontWeight || '600',
        ...style,
      }}
      onClick={clicked}
      type={type}
      disabled={disabled}
    >
      {/* before icon */}
      {beforeIcon}

      {/* text */}
      <span
        className={`${beforeIcon && text?.length ? 'ms-2' : ''} ${
          !!afterIcon ? 'me-2' : ''
        } mx-2 d-flex h-100 align-items-center`}
      >
        {text}
      </span>

      {/* after icon */}
      {afterIcon}
    </button>
  );
}

export default VIButton;
