import React, { useEffect, useMemo, useRef, useState } from 'react';
import VIButton from 'general/components/VietInvoice/VIButton';
import { useTranslation } from 'react-i18next';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import VIDeleteModal from 'general/components/VietInvoice/VIDeleteModal';
import VIBodyIcons from 'general/components/VietInvoice/VIBodyIcons';
import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import DropdownFilterOutInvoice from 'features/Invoice/screens/components/DropdownFilterOutInvoice';
import Utils from 'general/utils/Utils';
import './style.scss';
import { AppDialogHelper } from 'general/components/AppDialog';
import VIModalDetailOutput from '../ModalDetailInvoiceOutput';
import VIModal from 'general/components/VietInvoice/VIModal';
import InvoiceTransfer from 'features/Invoice/screens/InputInvoiceScreen/components/InvoiceTransfer';
import AppResource from 'general/constants/AppResource';
import { useDispatch } from 'react-redux';
import useAccount from 'hooks/useAccount';
import Global from 'general/utils/Global';
import { useSelector } from 'react-redux';
import { thunkGetAllOutInvoice } from 'features/Invoice/invoiceSlice';
import invoiceApi from 'api/invoiceApi';
import ToastHelper from 'general/helpers/ToastHelper';
import KTTooltip, { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip';
import LookUpInvoice from 'features/Invoice/screens/components/LookUpInvoices';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import ViewInvoiceExport from 'features/Invoice/components/ViewInvoiceExport';
import DownLoadInvoice from 'features/Invoice/screens/components/DownLoadInvoice';
import InvoiceHelper from 'general/helpers/InvoiceHelper';
import Tippy from '@tippyjs/react/headless';
import { useWindowSize } from '@uidotdev/usehooks';
function InInvoice(props) {
  const { tabsActive } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModalDetailInvoices, setShowModalDetailInvoices] = useState(false);
  const [showModalInvoiceTransfer, setShowModalInvoiceTransfer] = useState(false);
  const [showModalInvoices, setShowModalInvoices] = useState(false);
  const [organizationId, setOrganizationId] = useState();
  const [currentInvoices, setCurrentInvoices] = useState();
  const [showModalDownLoad, setShowModalDownLoad] = useState(false);
  const [dataFormat, setDataFormat] = useState('0');

  const account = useAccount();

  const [filters, setFilters] = useState(Global.gFiltersOutputInvoice);
  useEffect(() => {
    if (!_.isEqual(Global.gFiltersOutputInvoice, filters)) {
      setFilters(Global.gFiltersOutputInvoice);
    }
  }, [Global.gFiltersOutputInvoice]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [currentDetail, setCurrentDetail] = useState();
  const [modalLookUpInvoice, setModalLookUpInvoice] = useState(false);

  const {
    listOutputInvoice,
    isGettingOutputInvoiceList,
    totalOutputInvoice,
    totalAmountBeforeVATOutputInvoice,
    totalAmountVATOutputInvoice,
    totalFinalAmountOutputInvoice,
  } = useSelector((state) => state?.invoice);
  const [dataCheck, setDataCheck] = useState([]);
  useEffect(() => {
    if (_.isEmpty(listOutputInvoice)) {
    }
    if (account.organizationId) {
      dispatch(thunkGetAllOutInvoice(filters));
    }
    if (account.organizationDepartmentId) {
      dispatch(thunkGetAllOutInvoice(filters));
    }
  }, [filters, account.organizationId, account.organizationDepartmentId, account.accountId]);
  let idDataInvoices = [];
  for (let i = 0; i < dataCheck?.length; i++) {
    idDataInvoices.push(dataCheck[i]?.invoiceId);
  }
  const IconArr = [];
  if (account?.role?.permissionCodes?.includes('DELETE_AND_RESTORE_OUTPUT_INVOICE')) {
    IconArr.push({
      icon: <i className="fa-regular fa-trash-can"></i>,
      name: t('Delete'),
    });
  }
  if (account?.role?.permissionCodes?.includes('TRANSFER_OUTPUT_INVOICE')) {
    IconArr.push({
      icon: <i className="fa-regular fa-file-export"></i>,
      name: t('InvoiceTransfer'),
    });
  }

  useEffect(() => {
    Global.gFiltersOutputInvoice = {
      ...Global.gFiltersOutputInvoice,
      page: 0,
    };
  }, [account.organizationId, account.organizationDepartmentId]);
  const handleActions = (row) => {
    let action = [];
    if (account?.role?.permissionCodes?.includes('DELETE_AND_RESTORE_OUTPUT_INVOICE')) {
      action.push({
        iconElement: <i className="fa-regular fa-trash-can"></i>,
        text: t('Delete'),
        click: () => {
          handleDeleteInvoice(row);
        },
      });
    }
    if (account?.role?.permissionCodes?.includes('TRANSFER_OUTPUT_INVOICE')) {
      action.push({
        iconElement: <i className="fa-regular fa-file-export"></i>,
        text: t('InvoiceTransfer'),
        click: () => {
          setShowModalInvoiceTransfer(true);
          setCurrentInvoices(row);
        },
      });
    }
    return action;
  };
  const columns = useMemo(
    () => [
      {
        name: t('InvoiceNumber'),
        minWidth: '135px',
        selector: (row) => row.invoiceNumber,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceNumber ? row?.invoiceNumber : ''}
            </p>
          );
        },
      },
      {
        name: t('Denominator'),
        width: '100px',
        selector: (row) => row.invoiceTypeCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceTypeCode ? row?.invoiceTypeCode : ''}
            </p>
          );
        },
      },
      {
        name: t('Symbol'),
        width: '100px',
        selector: (row) => row.serial,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.serial ? row?.serial : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceDate'),
        width: '150px',
        selector: (row) => row.invoiceDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceDate ? Utils.formatDateTime(row?.invoiceDate, 'DD/MM/yyyy' ?? '') : ''}
            </p>
          );
        },
      },
      {
        name: t('SignDate'),
        width: '115px',
        selector: (row) => row.signDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.signDate ? Utils.formatDateTime(row?.signDate, 'DD/MM/yyyy') : ''}
            </p>
          );
        },
      },
      {
        name: t('CodeIssueDate'),
        width: '140px',
        selector: (row) => row.providedCodeDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.providedCodeDate
                ? Utils.formatDateTime(row?.providedCodeDate, 'DD/MM/yyyy')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('CodeAxion'),
        width: '190px',
        selector: (row) => row.maCQT,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.maCQT ? row?.maCQT : ''}
            </p>
          );
        },
      },
      {
        name: t('CodeClient'),
        minWidth: '165px',
        selector: (row) => row.buyerCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerCode ? row?.buyerCode : ''}
            </p>
          );
        },
      },
      {
        name: t('BuyerTaxCodes'),
        width: '200px',
        selector: (row) => row.buyerTaxCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerTaxCode ? row?.buyerTaxCode : ''}
            </p>
          );
        },
      },
      {
        name: t('BuyerInformation'),
        width: '200px',
        selector: (row) => row.buyerName,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerName ? row?.buyerName : ''}
            </p>
          );
        },
      },

      {
        name: t('BuyerAddress'),
        minWidth: '250px',
        selector: (row) => row.buyerAddress,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerAddress ? row?.buyerAddress : ''}
            </p>
          );
        },
      },

      {
        name: t('TotalPreTaxMoney'),
        minWidth: '215px',
        selector: (row) => row.amountBeforeVat,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.amountBeforeVat
                ? Utils.formatCurrency(row?.amountBeforeVat)
                : row?.amountBeforeVat}
            </p>
          );
        },
      },
      {
        name: t('taxMoney'),
        minWidth: '116px',
        selector: (row) => row.amountVat,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.amountVat ? Utils.formatCurrency(row?.amountVat) : row?.amountVat}
            </p>
          );
        },
      },
      {
        name: t('TotalPayment'),
        minWidth: '214px',
        selector: (row) => row.finalAmount,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.finalAmount ? Utils.formatCurrency(row?.finalAmount) : row?.finalAmount}
            </p>
          );
        },
      },
      {
        name: t('Currency'),
        selector: (row) => row.paymentCurrency,
        sortable: true,
        minWidth: '113px',

        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.paymentCurrency ? row?.paymentCurrency : ''}
            </p>
          );
        },
      },
      {
        name: t('ExchangeRate'),
        selector: (row) => row.paymentExchangeRate,
        sortable: true,
        width: '90px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.paymentExchangeRate ? row?.paymentExchangeRate : ''}
            </p>
          );
        },
      },
      {
        name: t('TotalAmountConverted'),
        width: '182px',
        selector: (row) => row.finalAmountExchange,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p className="font-weight-normal m-0 text-maxline-3 text-align-end">
              {row?.finalAmountExchange ? Utils.formatNumber(row?.finalAmountExchange) : ''}
            </p>
          );
        },
      },
      {
        name: t('invoiceStatus'),
        width: '200px',
        selector: (row) => row.statusInvoiceText,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.statusInvoiceText ? row?.statusInvoiceText : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceAdjusted/Replaced'),
        minWidth: '290px',

        cell: (row) => {
          return row?.statusInvoiceText === 'Hoá đơn thay thế' ? (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {`Thay thế cho hóa đơn ký hiệu ${
                row?.hdgocData?.khmshdon ? row?.hdgocData?.khmshdon : ''
              }${row?.hdgocData?.khhdon ? row?.hdgocData?.khhdon : ''}, số ${
                row?.hdgocData?.shdon ? row?.hdgocData?.shdon : ''
              }, ngày ${
                row?.hdgocData?.tdlap
                  ? Utils.formatDateTime(row?.hdgocData?.tdlap, 'DD/MM/yyyy' ?? '')
                  : ''
              }`}
            </p>
          ) : row?.statusInvoiceText === 'Hoá đơn điều chỉnh' ? (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {`Điều chỉnh cho hóa đơn ký hiệu ${
                row?.hdgocData?.khmshdon ? row?.hdgocData?.khmshdon : ''
              }${row?.hdgocData?.khhdon ? row?.hdgocData?.khhdon : ''}, số ${
                row?.hdgocData?.shdon ? row?.hdgocData?.shdon : ''
              }, ngày ${
                row?.hdgocData?.tdlap
                  ? Utils.formatDateTime(row?.hdgocData?.tdlap, 'DD/MM/yyyy' ?? '')
                  : ''
              }`}
            </p>
          ) : (
            ''
          );
        },
      },
      {
        name: t('LookUpOriginalInvoice'),
        width: '200px',
        center: true,

        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 "
              style={{ width: '100%' }}
            >
              <span
                className="d-flex align-items-center py-5 px-4"
                style={{ color: '#22C55E' }}
                onClick={() => {
                  setCurrentInvoices(row), setModalLookUpInvoice(true);
                }}
              >
                <i className="fa-regular fa-lightbulb-on me-1" style={{ color: '#22C55E' }}></i>
                Thông tin tra cứu
              </span>
            </p>
          );
        },
      },
      {
        name: t('InvoiceFile'),
        minWidth: '130px',
        center: true,
        cell: (row) => {
          return (
            <div
              className=" font-weight-normal m-0 text-maxline-3 d-flex justify-content-center"
              onClick={() => setCurrentInvoices(row)}
            >
              <input
                type="file"
                id="pdfFiles"
                className="d-none w-10"
                accept="application/pdf,image/*"
                onChange={(e) => {
                  handleUploadPdf(e);
                  document.getElementById('pdfFiles').value = null;
                }}
              />
              <input
                type="file"
                id="XMLFile"
                className="d-none w-10"
                accept="text/xml"
                onChange={(e) => {
                  handleUploadXml(e);
                  document.getElementById('XMLFile').value = null;
                }}
              />
              <KTTooltip
                text={'Chọn file pdf, ảnh để tải lên'}
                position={KTTooltipPositions.auto}
                onMouseOver={() => setCurrentInvoices(row)}
              >
                {row?.pdfFile ? (
                  <img
                    src={AppResource.icons.IcPdfAvailable}
                    className="me-3"
                    onClick={async () => {
                      (dataUploadPdf.current = row), document.getElementById('pdfFiles').click();
                    }}
                  />
                ) : (
                  <div
                    className="pdf-upload"
                    onClick={() => {
                      (dataUploadPdf.current = row), document.getElementById('pdfFiles').click();
                    }}
                  >
                    <img src={AppResource.icons.IcPdfDisable} className="me-3" />
                    <i className="fa-light fa-file-arrow-up icon-up d-none me-3"></i>
                  </div>
                )}
              </KTTooltip>
              {row?.originXmlFile ? (
                <Tippy
                  interactive
                  placement="auto"
                  delay={[0, 10]}
                  offset={[-10, 0]}
                  render={() => (
                    <div
                      className="bg-white tippy-tooltip p-5"
                      style={{
                        boxShadow: '0px 5px 10px 0px rgba(44, 63, 88, 0.1)',
                        border: '1px solid var(--system-stroke, #e8e9eb)',
                        borderRadius: '8px',
                      }}
                    >
                      <div
                        className="d-flex align-items-center pb-2"
                        style={{ minWidth: 'max-content' }}
                        onClick={() => {
                          (dataUploadXml.current = row), document.getElementById('XMLFile').click();
                        }}
                      >
                        {' '}
                        <i className="fa-regular fa-upload me-3"></i>Thay thế hóa đơn gốc
                      </div>
                      <div
                        className="d-flex align-items-center pt-2"
                        style={{ minWidth: 'max-content' }}
                        onClick={() => handleDownLoadInvoice(row)}
                      >
                        <i className="fa-regular fa-download me-3"></i>Tải xuống hóa đơn gốc
                      </div>
                    </div>
                  )}
                >
                  <img src={AppResource.icons.IcXmlAvailable} />
                </Tippy>
              ) : (
                <KTTooltip
                  text={'Chọn file *xml,*inv để tải lên'}
                  position={KTTooltipPositions.auto}
                  onMouseOver={() => setCurrentInvoices(row)}
                >
                  <div className="xml-upload">
                    <img
                      src={AppResource.icons.IcXmlDisable}
                      onClick={() => {
                        (dataUploadXml.current = row), document.getElementById('XMLFile').click();
                      }}
                    />
                    <i
                      className="fa-light fa-file-arrow-up icon-up d-none"
                      onClick={() => {
                        (dataUploadXml.current = row), document.getElementById('XMLFile').click();
                      }}
                    ></i>
                  </div>
                </KTTooltip>
              )}
            </div>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            {handleActions(row)?.length > 0 ? (
              <VITableRowDropDown
                toggleElement={
                  <div>
                    <i className="text-grey fas fa-ellipsis-h p-0"></i>
                  </div>
                }
                menuItem={handleActions(row)}
              />
            ) : (
              <KTTooltip
                text={'Bạn không có quyền thực hiện chức năng này'}
                position={KTTooltipPositions.auto}
              >
                <div className="">
                  <i className="text-grey fas fa-ellipsis-h p-0"></i>
                </div>
              </KTTooltip>
            )}
          </div>
        ),
      },
    ],
    [listOutputInvoice]
  );

  const [pagination, setPagination] = useState({
    perPage: filters?.limit,
    total: totalOutputInvoice,
    currentPage: filters?.page + 1,
    count: listOutputInvoice?.length + filters.limit * filters.page,
  });
  useEffect(() => {
    setPagination({
      perPage: filters?.limit,
      total: totalOutputInvoice,
      currentPage: filters?.page + 1,
      count: listOutputInvoice?.length + filters.limit * filters.page,
    });
  }, [filters, listOutputInvoice]);
  const [showModalDelete, setShowModalDelete] = useState(false);

  // xóa 1 hóa đơn
  function handleDeleteInvoice(row) {
    AppDialogHelper.show(
      t('DeleteInvoice'),
      t('ConfirmDeleteInvoice', { name: row?.invoiceNumber }),
      [
        {
          title: t('Cancel'),
          type: 'neutral',
          onClick: async () => {
            AppDialogHelper.hide();
          },
        },
        {
          title: t('Delete'),
          type: 'negative',
          onClick: async () => {
            const result = await invoiceApi.addInvoiceToTrash({
              invoiceIds: [row?.invoiceId],
              isDeleted: true,
            });
            if (result?.result === 'success') {
              AppDialogHelper.hide();
              ToastHelper.showSuccess(t('InvoiceDeletedSuccessfully'));
              dispatch(thunkGetAllOutInvoice(filters));
              setDataCheck([]);
            }
          },
        },
      ]
    );
  }
  // Xóa nhiều hóa đơn
  function handleDeleteInvoices() {
    AppDialogHelper.show(t('DeleteInvoice'), t('ConfirmDeleteInvoices'), [
      {
        title: t('Cancel'),
        type: 'neutral',
        onClick: () => {
          AppDialogHelper.hide();
        },
      },
      {
        title: t('Delete'),
        type: 'negative',
        onClick: async () => {
          const result = await invoiceApi.addInvoiceToTrash({
            invoiceIds: idDataInvoices,
            isDeleted: true,
          });
          if (result?.result === 'success') {
            AppDialogHelper.hide();
            ToastHelper.showSuccess(t('InvoiceDeletedSuccessfully'));
            dispatch(thunkGetAllOutInvoice(filters));
            setDataCheck([]);
            setClearSelectedRows(!clearSelectedRows);
          }
        },
      },
    ]);
  }

  //lọc hóa đơn
  function handleFilterInvoice(dataFilter) {
    setFilters({
      ...filters,
      fromDate: dataFilter?.fromDate,
      toDate: dataFilter?.toDate,
      statusInvoice: dataFilter?.statusInvoice,
      typeInvoice: dataFilter?.typeInvoice,
      invoiceTypeCode: dataFilter?.invoiceTypeCode,
      invoiceSerialType: dataFilter?.invoiceSerialType,
    });
  }

  //điều chuyển hóa đơn
  const handleInvoiceTransfer = async () => {
    const result = await invoiceApi.transferInvoice({
      invoiceIds: idDataInvoices?.length > 0 ? idDataInvoices : [currentInvoices?.invoiceId],
      organizationId: organizationId?.organizationId,
      organizationDepartmentId: organizationId?.organizationDepartmentId
        ? organizationId?.organizationDepartmentId
        : null,
    });
    if (result.result === 'success') {
      setShowModalInvoiceTransfer(false);
      ToastHelper.showSuccess(t('InvoiceTransferSuccessfully'));
      setDataCheck([]);
      dispatch(thunkGetAllOutInvoice(Global.gFiltersOutputInvoice));
      setClearSelectedRows(!clearSelectedRows);
    }
  };

  const refDropdownFilterOutput = useRef();
  const refDropdownFilterOutputMenu = useRef();
  const refDateRangePickerFilter = useRef();

  // useOnClickOutside(refDropdownFilterOutput, () => {
  //   const isDateRangePickerDisplays = window.getComputedStyle(
  //     refDateRangePickerFilter.current
  //   ).display;
  //   if (isDateRangePickerDisplays === 'block') {
  //     return;
  //   }
  //   if (refDropdownFilterOutputMenu?.current) {
  //     if (refDropdownFilterOutputMenu?.current.classList.contains('show')) {
  //       refDropdownFilterOutputMenu?.current.classList.remove('show');
  //     } else {
  //     }
  //   }
  // });

  useEffect(() => {
    const el = document.getElementsByClassName('DropdownFilterMenu')?.item(0);
    if (el) {
      refDropdownFilterOutputMenu.current = el;
    }

    if (document.getElementsByClassName('daterangepicker')?.item(1)) {
      refDateRangePickerFilter.current = document
        .getElementsByClassName('daterangepicker')
        ?.item(1);
    }
  }, []);

  const [countFilter, setCountFilter] = useState();
  useEffect(() => {
    let count = 0;

    if (
      JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId] !==
      undefined
    ) {
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId]
          ?.typeInvoice !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId]
          ?.typeInvoice
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId]
          ?.statusInvoice !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId]
          ?.statusInvoice
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId]
          ?.invoiceTypeCode !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId]
          ?.invoiceTypeCode
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId]
          ?.invoiceSerialType !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId]
          ?.invoiceSerialType
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId]
          ?.fromDate !== moment().subtract(29, 'days').format('YYYY-MM-DD') ||
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId]
          ?.toDate !== moment().format('YYYY-MM-DD')
      ) {
        count += 1;
      }
      setCountFilter(count);
    } else {
      setCountFilter(0);
    }
  }, [
    JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputInvoice))?.[account.accountId],
    account.accountId,
  ]);

  // upload pdf
  const dataUploadPdf = useRef();
  const handleUploadPdf = async (e) => {
    const form = new FormData();
    form.append('pdfFile', e?.target?.files[0]);
    if (e?.target?.files[0]) {
      try {
        const res = await invoiceApi.upFileInvoice(dataUploadPdf?.current?.invoiceId, form);
        if (res?.result === 'success') {
          ToastHelper.showSuccess(t('UploadFileSuccess'));
          dispatch(thunkGetAllOutInvoice(Global.gFiltersOutputInvoice));
        }
      } catch (error) {}
    }
  };
  // upload pdf

  function handleWarning() {
    AppDialogHelper.show(
      t('Warning'),
      t(
        'Mã số thuế người bán/người mua - loại hóa đơn - mẫu số/ký hiệu - số hóa đơn trên file *xml, *inv tải lên khác với thông tin hóa đơn đã tồn tại trên chương trình.'
      ),
      [
        {
          title: t('Close'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
      ]
    );
  }
  const dataUploadXml = useRef();
  const handleUploadXml = async (e) => {
    const form = new FormData();
    form.append('xmlFile', e?.target?.files[0]);
    if (e?.target?.files[0]) {
      try {
        const res = await invoiceApi.upFileInvoice(dataUploadXml?.current?.invoiceId, form);
        if (res?.result === 'success') {
          ToastHelper.showSuccess(t('UploadFileSuccess'));
          dispatch(thunkGetAllOutInvoice(Global.gFiltersOutputInvoice));
        }
      } catch (error) {
        handleWarning();
      }
    }
  };

  // column mặc định khi loading
  const defaultColumns = useMemo(() => {
    let columnsLocal = [];
    for (
      let i = 0;
      i <
      JSON.parse(localStorage.getItem(PreferenceKeys.saveColumnOutputInvoice))?.[account.accountId]
        ?.length;
      i++
    ) {
      for (let j = 0; j < columns.length; j++) {
        if (
          JSON.parse(localStorage.getItem(PreferenceKeys.saveColumnOutputInvoice))?.[
            account.accountId
          ][i] === columns[j]?.name
        ) {
          columnsLocal.push(columns[j]);
        }
      }
    }
    if (columnsLocal.length > 0) {
      return columnsLocal;
    } else {
      return columns.filter((item) =>
        Global[PreferenceKeys.saveColumnOutputInvoice]?.includes(item.name)
      );
      // .concat(columns[columns.length - 1]);
    }
  }, [
    JSON.parse(localStorage.getItem(PreferenceKeys.saveColumnOutputInvoice))?.[account.accountId],
  ]);

  // tải xuống hóa đơn

  let dataDownload = [];
  for (let i = 0; i < dataCheck.length; i++) {
    if (dataFormat === '0' && dataCheck[i]?.pdfFile) {
      dataDownload.push(dataCheck[i]);
    }
    if (dataFormat === '0' && dataCheck[i]?.previewPdfFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.previewPdfFile });
    }
    if (dataFormat === '0' && dataCheck[i]?.xmlFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.xmlFile });
    }
    if (dataFormat === '0' && dataCheck[i]?.originXmlFile) {
      dataDownload.push({ ...dataCheck[i], xmlFileOrigin: dataCheck[i]?.originXmlFile });
    }
    if (dataFormat === '1' && dataCheck[i]?.pdfFile) {
      dataDownload.push(dataCheck[i]);
    }
    if (dataFormat === '1' && dataCheck[i]?.previewPdfFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.previewPdfFile });
    }
    if (dataFormat === '2' && dataCheck[i]?.xmlFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.xmlFile });
    }
    if (dataFormat === '3' && dataCheck[i]?.originXmlFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.originXmlFile });
    }
    if (dataFormat === '3' && dataCheck[i]?.pdfFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.pdfFile });
    }
  }

  const handleDownLoadInvoices = () => {
    const name = Global.gDownLoadZipNameOut;
    const res = InvoiceHelper.DownLoadInvoices(name, dataDownload);
    setClearSelectedRows(!clearSelectedRows);
    setShowModalDownLoad(false);
    setDataCheck([]);
  };

  //tải xuống xml gốc
  const handleDownLoadInvoice = (row) => {
    const dataDownload = [
      { ...row, pdfFile: row?.originXmlFile },
      { ...row, pdfFile: row?.pdfFile },
    ];
    const name = Global.gDownLoadZipNameOut;
    const res = InvoiceHelper.DownLoadInvoices(name, dataDownload);
  };

  const reSize = useWindowSize();

  return (
    <div className="position-relative Output-invoice">
      <div className="d-flex justify-content-between ">
        <div
          className="w-100 bg-white"
          style={{
            borderRadius: '0px 0px 20px 20px',
            borderLeft: '1px solid #dbe3ef',
            borderBottom: '1px solid #dbe3ef',
            borderRight: '1px solid #dbe3ef',
          }}
        >
          <VIDataTable
            tabsActive={tabsActive}
            selectable={true}
            pagination={pagination}
            tableStyles={customDataTableActionStyle}
            scrollHeight="48"
            totalPreTaxMoney={totalAmountBeforeVATOutputInvoice}
            totalTaxAmount={totalAmountVATOutputInvoice}
            totalValue={totalFinalAmountOutputInvoice}
            clearSelectedRows={clearSelectedRows}
            maxHeightDrag="42vh"
            draggable={dataCheck?.length === 0}
            keyDefaultColumn={PreferenceKeys.saveColumnOutputInvoice}
            defaultColumns={defaultColumns}
            onChangePage={(page) => {
              let iNewPage = parseInt(page);
              iNewPage -= 1;
              if (iNewPage < 0) {
                iNewPage = 0;
              }
              Global.gFiltersOutputInvoice = {
                ...Global.gFiltersOutputInvoice,
                page: iNewPage,
              };
              setFilters({
                ...filters,
                page: iNewPage,
              });
            }}
            onChangeRowsPerPage={(perPage) => {
              setFilters({
                ...filters,
                limit: perPage,
                page: 0,
              });
              Global.gFiltersOutputInvoice = {
                ...Global.gFiltersOutputInvoice,
                limit: perPage,
              };
            }}
            fullColumns={columns}
            data={listOutputInvoice}
            loading={isGettingOutputInvoiceList}
            inputSearch={
              <>
                {dataCheck?.length === 0 && (
                  <div className="d-flex justify-content-between w-100">
                    <KTTooltip
                      className="w-100"
                      text="Nhập số hóa đơn, tên khách hàng, mã số thuế để tìm kiếm"
                      position={KTTooltipPositions.bottom}
                    >
                      <div className="w-100 h-100">
                        <VISearchField
                          placeholder="Nhập số hóa đơn, tên khách hàng, mã số thuế"
                          value={filters.q}
                          className="w-100 d-flex align-items-center"
                          typingTimeout={500}
                          onSubmit={(newValue) => {
                            setFilters({
                              ...filters,
                              q: newValue,
                              page: 0,
                            });
                            Global.gFiltersOutputInvoice = {
                              ...Global.gFiltersOutputInvoice,
                              q: newValue,
                              page: 0,
                            };
                          }}
                        />
                      </div>
                    </KTTooltip>
                  </div>
                )}
              </>
            }
            filterbar={
              <div className="show-func">
                {dataCheck?.length > 0 && (
                  <div className="d-flex flex-wrap">
                    <div
                      className="px-3 py-3 me-3 mt-2"
                      style={{
                        background: 'rgba(81, 128, 251, 0.10)',
                        borderRadius: '8px',
                        color: '#5180FB',
                      }}
                    >
                      {t('Selected')} {dataCheck?.length} {t('Bill')}
                    </div>

                    <VIButton
                      text={t('ExportList')}
                      beforeIcon={<i className="fa-solid fa-up-right-from-square"></i>}
                      className="btn-white me-3 mt-2"
                      style={{ padding: '8px' }}
                      onClick={() => {
                        setShowModalInvoices(true);
                      }}
                      afterIcon={<></>}
                    />
                    <VIButton
                      text={t('DownLoad')}
                      beforeIcon={<i className="fa-solid fa-download"></i>}
                      className="btn-white me-3 mt-2"
                      style={{ padding: '8px' }}
                      onClick={() => {
                        setShowModalDownLoad(true);
                      }}
                      afterIcon={<></>}
                    />
                    {IconArr?.length > 0 && (
                      <div
                        style={{ display: 'flex', justifyContent: 'end' }}
                        id={'show-more-func'}
                        className="mt-2"
                      >
                        <KTBSDropdown
                          contentEl={
                            <VIBodyIcons
                              IconArr={IconArr}
                              onClick={(x) => {
                                if (x?.name === 'Xóa') {
                                  handleDeleteInvoices();
                                }
                                if (x.name === 'Điều chuyển hóa đơn') {
                                  setShowModalInvoiceTransfer(true);
                                }
                              }}
                              id={'100'}
                            />
                          }
                          toggleElement={
                            <div className="topbar-item">
                              <div className="mr-1 btn-white py-3 cursor-pointer px-5 d-flex align-items-center">
                                <i className="fa-regular fa-ellipsis-vertical"></i>
                              </div>
                            </div>
                          }
                          alignment={KTBSDropdownAlignments.end}
                          autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
            onRowClick={(row) => {
              setShowModalDetailInvoices(true);
              setCurrentDetail(row);
              // console.log(row);
            }}
            onSetSelectedMultiItems={(rows) => setDataCheck(rows)}
            toolbar={
              <>
                {dataCheck?.length === 0 && (
                  <div className="">
                    <KTBSDropdown
                      contentEl={
                        <div ref={refDropdownFilterOutput}>
                          <DropdownFilterOutInvoice
                            handleFilterInvoice={handleFilterInvoice}
                            refDropdownFilterOutputMenu={refDropdownFilterOutputMenu}
                          />
                        </div>
                      }
                      dropdownMenuClassName="DropdownFilterMenu"
                      toggleElement={
                        <div className="d-flex align-items-center font-weight-bold justify-content-between DropdownFilter ">
                          <i
                            className="fa-regular fa-filter-list me-2"
                            style={{ color: '#1c2256' }}
                          ></i>
                          <span className="d-flex">
                            {t('FilterInvoices')}{' '}
                            {countFilter !== 0 && (
                              <div className="ms-1" style={{ color: '#F44141' }}>
                                ({countFilter})
                              </div>
                            )}
                          </span>
                        </div>
                      }
                      alignment={reSize?.width < 540 ? '' : KTBSDropdownAlignments.end}
                      autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                    />
                  </div>
                )}
              </>
            }
          />
        </div>
      </div>
      <VIDeleteModal
        show={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        deleteTitle={t('DeleteInvoice')}
        deleteText={'Bạn có chắc muốn xóa hóa đơn này không'}
        deleteItem={() => {}}
      />

      <VIModalDetailOutput
        show={showModalDetailInvoices}
        onHide={() => {
          setShowModalDetailInvoices(false), setCurrentDetail();
        }}
        currentDetail={currentDetail}
      />
      <VIModal
        titleModal={t('SendInvoices/ReceiptsTo')}
        show={showModalInvoiceTransfer}
        onHide={() => {
          setShowModalInvoiceTransfer(false);
        }}
        contentModal={<InvoiceTransfer setOrganizationId={setOrganizationId} />}
        closeBtn={true}
        handleSubmit={() => {
          handleInvoiceTransfer();
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={t('Save')}
      />
      <VIModal
        titleModal={t('Export')}
        // modalSize="lg"
        show={showModalInvoices}
        contentModal={
          <ViewInvoiceExport
            exportType="OUT"
            onPressedCancel={() => {
              setShowModalInvoices(false);
            }}
            invoiceList={dataCheck}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setShowModalInvoices(false);
        }}
      />
      <VIModal
        titleModal={t(`DownLoad`)}
        show={showModalDownLoad}
        onHide={() => setShowModalDownLoad(false)}
        contentModal={<DownLoadInvoice setDataFormat={setDataFormat} dataFormat={dataFormat} />}
        closeBtn={true}
        handleSubmit={() => {
          handleDownLoadInvoices();
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={t('Download')}
      />
      <VIModal
        titleModal={t('SearchSuggestions')}
        show={modalLookUpInvoice}
        contentModal={<LookUpInvoice currentLookUp={currentInvoices} />}
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalLookUpInvoice(false);
        }}
        buttonNegative={t('Close')}
        modalSize="lg"
      />
    </div>
  );
}

export default InInvoice;
