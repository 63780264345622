import categoryApi from 'api/categoryApi';
import miscApi from 'api/miscApi';
import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputIconPosition,
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTFormTextArea from 'general/components/OtherKeenComponents/Forms/KTFormTextArea';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppConfigs from 'general/constants/AppConfigs';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';

ViewAddSupplier.propTypes = {
  onPressCancel: PropTypes.func,
};

ViewAddSupplier.defaultProps = {
  onPressCancel: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * }} props
 * @returns
 */
function ViewAddSupplier(props) {
  // ---- params ----
  const { onPressCancel } = props;
  const { t } = useTranslation();
  const refFormik = useRef();
  const [creating, setCreating] = useState(false);

  // ---- methods ----
  function handlePressedCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  async function requestCompanyInfoByTaxCode(taxCode) {
    if (taxCode.match(AppConfigs.regexTaxCode10) || taxCode.match(AppConfigs.regexTaxCode13)) {
      try {
        const res = await miscApi.getCompanyInfoByTaxCode(taxCode);
        const { result, company } = res;
        if (result === 'success' && company) {
          const { companyName, address, businessPermitDate } = company;
          refFormik.current?.setFieldValue('unitName', companyName);
          refFormik.current?.setFieldValue('address', address);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          taxCode: '',
          unitCode: '',
          unitName: '',
          address: '',
        }}
        onSubmit={async (values) => {
          const params = {
            taxCode: values.taxCode,
            unitCode: values.unitCode,
            partnerCompanyName: values.unitName,
            address: values.address,
          };
          try {
            setCreating(true);
            const res = await categoryApi.create(params);
            const { result, partnerCompany } = res;
            if (result === 'success' && partnerCompany) {
              // tao ncc thanh cong
              ToastHelper.showSuccess('Tạo nhà cung cấp thành công');
              if (Global.gTempChoosingSupplierList !== null) {
                Global.gTempChoosingSupplierList.push(partnerCompany);
              } else {
                Global.gTempChoosingSupplierList = [partnerCompany];
              }
              handlePressedCancel();
            }
          } catch (error) {
            console.log({ error });
          }
          setCreating(false);
        }}
        enableReinitialize
        validationSchema={Yup.object({
          taxCode: Yup.string()
            .required(t('TaxCodeRequired'))
            .trim()
            .test('len', t('TaxCodeInvalid', { field: t('taxCode') }), (val) => {
              if (val) {
                return (
                  !val.toString().includes(' ') &&
                  (val.toString().match(/^[0-9]{10}$/) ||
                    val.toString().match(/^[0-9]{10}-[0-9]{3}$/))
                );
              }
            }),
          unitName: Yup.string().required(t('Required')),
          address: Yup.string().required(t('Required')),
        })}
      >
        {(formikProps) => {
          refFormik.current = formikProps;
          return (
            <div>
              <div className="row">
                {/* ma so thue */}
                <div className="col-12">
                  <KTFormGroup
                    label={
                      <>
                        {t('TaxCode')} <span className="text-danger">(*)</span>
                      </>
                    }
                    inputName="taxCode"
                    inputElement={
                      <FastField name="taxCode">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('TaxCode'))}...`}
                            enableInputIcon={true}
                            inputIconElement={
                              <i
                                className="fa-regular fa-search cursor-pointer"
                                onClick={() => {
                                  requestCompanyInfoByTaxCode(field.value);
                                }}
                              />
                            }
                            inputIconPosition={KTFormInputIconPosition.right}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* ma don vi */}
                <div className="col-12">
                  <KTFormGroup
                    label={<>{t('UnitCode')}</>}
                    inputName="unitCode"
                    inputElement={
                      <FastField name="unitCode">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('UnitCode'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* ten don vi */}
                <div className="col-12">
                  <KTFormGroup
                    label={
                      <>
                        {t('UnitName')} <span className="text-danger">(*)</span>
                      </>
                    }
                    inputName="unitName"
                    inputElement={
                      <FastField name="unitName">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('UnitName'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* dia chi ncc */}
                <div className="col-12">
                  <KTFormGroup
                    label={
                      <>
                        {t('SupplierAddress')} <span className="text-danger">(*)</span>
                      </>
                    }
                    inputName="address"
                    inputElement={
                      <FastField name="address">
                        {({ field, form, meta }) => (
                          <KTFormTextArea
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('SupplierAddress'))}`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>
              </div>

              {/* actions */}
              <div className="d-flex align-items-center justify-content-end gap-3 mt-3">
                <VIButton
                  text={t('Cancel')}
                  className="btn-grey"
                  style={{ padding: '8px' }}
                  onClick={handlePressedCancel}
                />
                <VIButton
                  text={t('Save')}
                  className=""
                  style={{ padding: '8px' }}
                  onClick={() => {
                    formikProps.handleSubmit();
                  }}
                  beforeIcon={
                    creating ? (
                      <ClockLoader color="white" size={20} />
                    ) : (
                      <i className="fa-regular fa-circle-check" />
                    )
                  }
                  disabled={creating}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default ViewAddSupplier;
