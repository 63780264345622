// icons logo
import keenLogo1 from 'assets/icons/Keens/Logo/logo-1.svg';
import keenLogo2 from 'assets/icons/Keens/Logo/logo-2.svg';
import keenLogo3 from 'assets/icons/Keens/Logo/logo-3.svg';
import keenLogo4 from 'assets/icons/Keens/Logo/logo-4.svg';
import keenLogo5 from 'assets/icons/Keens/Logo/logo-5.svg';
import keenLogo6 from 'assets/icons/Keens/Logo/logo-6.svg';
import keenLogo7 from 'assets/icons/Keens/Logo/logo-7.svg';
// icons text
import keenIcToggleRight from 'assets/icons/Keens/Text/Toggle-Right.svg';
import keenIcToggleLeft from 'assets/icons/Keens/Text/Toggle-Left.svg';
// icons general
import keenIcSearch from 'assets/icons/Keens/General/Search.svg';
import keenIcUser from 'assets/icons/Keens/General/User.svg';
import keenIcShieldCheck from 'assets/icons/Keens/General/Shield-check.svg';
// icons avatar
import keenIcAvatarBoy from 'assets/icons/Keens/Avatars/001-boy.svg';
import keenIcAvatarGirl from 'assets/icons/Keens/Avatars/002-girl.svg';
// icons flag
import keenIcFlagEn from 'assets/icons/Keens/Flags/226-united-states.svg';
import keenIcFlagVi from 'assets/icons/Keens/Flags/220-vietnam.svg';
// icons code
import keenIcCompiling from 'assets/icons/Keens/Code/Compiling.svg';
import keenIcCmd from 'assets/icons/Keens/Code/CMD.svg';
import keenIcLockOverturning from 'assets/icons/Keens/Code/Lock-overturning.svg';
// icons media
import keenIcEqualizer from 'assets/icons/Keens/Media/Equalizer.svg';
// icons layout
import keenIcLayout4Blocks from 'assets/icons/Keens/Layout/Layout-4-blocks.svg';
import keenIcLayoutPolygon from 'assets/icons/Keens/Layout/Layout-polygon.svg';
// icons communication
import keenIcCommunicationChat from 'assets/icons/Keens/Communication/Chat6.svg';
import keenIcCommunicationMailBox from 'assets/icons/Keens/Communication/Mail-box.svg';
import keenIcCommunicationSpam from 'assets/icons/Keens/Communication/Spam.svg';
import keenIcCommunicationSafeChat from 'assets/icons/Keens/Communication/Safe-chat.svg';
import keenIcCommunicationSend from 'assets/icons/Keens/Communication/Send.svg';
import keenIcCommunicationMailAt from 'assets/icons/Keens/Communication/Mail-at.svg';
import keenIcCommunicationChatLocked from 'assets/icons/Keens/Communication/Chat-locked.svg';
import keenIcCommunicationGroupChat from 'assets/icons/Keens/Communication/Group-chat.svg';
import keenIcCommunicationThumbtack from 'assets/icons/Keens/Communication/Thumbtack.svg';
import keenIcCommunicationOutgoingBox from 'assets/icons/Keens/Communication/Outgoing-box.svg';
import keenIcCommunicationClipboardCheck from 'assets/icons/Keens/Communication/Clipboard-check.svg';
import keenIcCommunicationGroup from 'assets/icons/Keens/Communication/Group.svg';
// icons clothes
import keenIcBriefcase from 'assets/icons/Keens/Clothes/Briefcase.svg';
import keenIcCrown from 'assets/icons/Keens/Clothes/Crown.svg';
// icons navigations
import keenIcNavigationUp from 'assets/icons/Keens/Navigation/Up-2.svg';
import keenIcNavigationArrowLeft from 'assets/icons/Keens/Navigation/Arrow-left.svg';
// icons tools
import keenIcToolCompass from 'assets/icons/Keens/Tools/Compass.svg';
// icons design
import keenIcDesignFlatten from 'assets/icons/Keens/Design/Flatten.svg';
import keenIcDesignSubtract from 'assets/icons/Keens/Design/Substract.svg';
// icons home
import keenIcLibrary from 'assets/icons/Keens/Home/Library.svg';
// icons files
import keenIcFilePlus from 'assets/icons/Keens/Files/File-plus.svg';
// illustrations
import keenIllustrationPayment from 'assets/icons/Keens/Illustrations/payment.svg';
import keenIllustrationAccomplishment from 'assets/icons/Keens/Illustrations/accomplishment.svg';
// socials
import keenIcSocialGoogle from 'assets/icons/Keens/SocialIcons/google.svg';
// users
import keenIcUser1 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser2 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser3 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser4 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser5 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser6 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser7 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser8 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser9 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser10 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser11 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser12 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser13 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser14 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser15 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUserBlank from 'assets/icons/Keens/Users/blank.png';

// partner companies
import icLogoGoogle from 'assets/icons/ic_logo_google.svg';
import icLogoUber from 'assets/icons/ic_logo_uber.svg';
import icLogoStripe from 'assets/icons/ic_logo_stripe.svg';
import icLogoEntrepreneur from 'assets/icons/ic_logo_entrepreneur.svg';
import icPrevCarousel from 'assets/icons/ic_prev_carousel.svg';
import icNextCarousel from 'assets/icons/ic_next_carousel.svg';
import icMail from 'assets/icons/ic_mail-magnifying 1.svg';
import icNoMail from 'assets/icons/ic_MAIL 1.svg';
import icTags from 'assets/icons/ico_tag_rainbow.svg';
import icTag from 'assets/icons/ico_tag_normal.svg';
import icNoTag from 'assets/icons/ico_tag_disable.svg';

// landingpage
import imgLandingPage1 from 'assets/images/img_landingpage_1.png';
import imgLandingPage2 from 'assets/images/img_landingpage_2.png';
import imgLandingPage3 from 'assets/images/img_landingpage_3.png';
import imgLandingPage6 from 'assets/images/img_landingpage_6.png';

import icTechcombank from 'assets/icons/ic_techcombank.png';
import icToyota from 'assets/icons/ic_toyota.png';
import icTanHoangMinh from 'assets/icons/ic_tanhoangminh.png';
import icAbbank from 'assets/icons/ic_abbank.png';
import icCall from 'assets/icons/ic_call.svg';
import icFacebook from 'assets/icons/ic_facebook.svg';
import icZalo from 'assets/icons/ic_zalo.svg';
import icBCT from 'assets/icons/ic_BCT.png';
import icLogoVNPay from 'assets/icons/ic_logo_vnpay.svg';
import icEmailOrder from 'assets/icons/ic_email_order.svg';
import icInDo from 'assets/icons/ic_indo.png';
import icEBig from 'assets/icons/ic_ebig.png';
import icGmo from 'assets/icons/ic_gmo.png';
import icSun from 'assets/icons/ic_sun.png';
import icMatBao from 'assets/icons/ic_matbao.png';
import icSendZaLo from 'assets/icons/ico_zalo.svg';
import icSendPhone from 'assets/icons/ico_phone.svg';
import icPageUp from 'assets/icons/ico_up.svg';

// ------ vietinvoice -------
import icLovoVietinvoice from 'assets/icons/ic_logo_vietinvoice.svg';
import icLogoVietinvoiceDark from 'assets/icons/ic_logo_vietinvoice_dark.svg';
import icLogoQuanLyHoaDon from 'assets/icons/ic_logo_quanlyhoadon.svg';
import icLogoQuanLyHoaDonDark from 'assets/icons/ic_logo_quanlyhoadon_dark.svg';
import icLabelVietInvoice from 'assets/icons/icon_label.svg';
import icUnLabelVietInvoice from 'assets/icons/icon_unlabel.svg';
import icNotificationPromotion from 'assets/icons/ic_notification_promotion.png';
import icNotificationSystem from 'assets/icons/ic_notification_system.png';
import icNotificationActivity from 'assets/icons/ic_notification_activity.png';
import icSignOut from 'assets/icons/ic_sign_out.svg';
import icDisconnect from 'assets/icons/ic_disconnect.svg';
import icWaiting from 'assets/icons/ic_waiting.svg';

import imgMisaProcedure from 'assets/images/img_misa_procedure.png';

import imgDefaultAvatar from 'assets/images/img_default_avatar.png';

import imgEmptyConnect from 'assets/images/img_empty_connect.png';
import imgEmptyCart from 'assets/images/img_empty_cart.png';
import imgEmptyNotFound from 'assets/images/img_empty_not_found.png';

import imgInvoiceInfor from 'assets/images/img_invoice_infor.png';
import imgInvoiceAccumulate from 'assets/images/img_invoice_accumulate.png';
import imgErrorNotificationImage from 'assets/images/img_erroe_notification.png';
import imgDecorUp from 'assets/images/img_decor_up.png';
import imgDecorDown from 'assets/images/img_decor_down.png';
import imgDefaultFrontCard from 'assets/images/img_default_frontside_card.png';
import imgDefaultBackCard from 'assets/images/img_default_backside_card.png';
import imgOutputInvoice from 'assets/images/img_output_invoice.png';
import imgInputInvoice from 'assets/images/img_input_invoice.png';
import imgExportInvoice from 'assets/images/img_export_invoice.png';
import imgTNN from 'assets/images/img_TNN.png';
import imgEmptyPayment from 'assets/images/img_empty_payment.png';

import imgHDDR from 'assets/images/img_hddr.svg';
import imgHDDV from 'assets/images/img_hddv.svg';
import imgTemVe from 'assets/images/img_tem_ve.svg';
import imgBienLai from 'assets/images/img_bien_lai.svg';
import IcXmlAvailable from 'assets/icons/XML_available.svg';
import IcXmlDisable from 'assets/icons/XML_disable.svg';
import IcPdfAvailable from 'assets/icons/PDF_available.svg';
import IcPdfDisable from 'assets/icons/PDF_disable.svg';

import imgContactUsSuccess from 'assets/images/img_contact_us_success.svg';

import imgSyncInvoice from 'assets/images/img_sync_invoice.png';
import imgAxionBg from 'assets/images/img_axion_bg.png';

import imgSystemReourceLeft from 'assets/images/img_system_resource_left.png';
import imgSystemReourceRight from 'assets/images/img_system_resource_right.png';
import imgBgInfo from 'assets/images/img_bg_info.png';
import imgAuthDecor from 'assets/images/img_auth_decor.png';

import imgIcorpEcontract from 'assets/images/img_icorp_econtract.png';
import imgIcorpEkyc from 'assets/images/img_icorp_ekyc.png';
import imgIcorpHsm from 'assets/images/img_icorp_hsm.png';
import imgIcorpIbh from 'assets/images/img_icorp_ibh.png';
import imgIcorpIca from 'assets/images/img_icorp_ica.png';

import imgPaymentFailed from 'assets/images/img_payment_failed.png';
import imgPaymentSuccess from 'assets/images/img_payment_success.png';

import imgMidAutumn from 'assets/images/img_mid_autumn.png';

// App resources: icons, images, fonts...
const AppResource = {
  // icons
  icons: {
    // keens
    keens: {
      // logo
      logo1: keenLogo1,
      logo2: keenLogo2,
      logo3: keenLogo3,
      logo4: keenLogo4,
      logo5: keenLogo5,
      logo6: keenLogo6,
      logo7: keenLogo7,
      // text
      toggleRight: keenIcToggleRight,
      toggleLeft: keenIcToggleLeft,
      // general
      search: keenIcSearch,
      user: keenIcUser,
      shieldCheck: keenIcShieldCheck,
      // avatar
      avatarBoy: keenIcAvatarBoy,
      avatarGirl: keenIcAvatarGirl,
      // flag
      flagEn: keenIcFlagEn,
      flagVi: keenIcFlagVi,
      // code
      compiling: keenIcCompiling,
      cmd: keenIcCmd,
      lockOverTurning: keenIcLockOverturning,
      // media
      equalizer: keenIcEqualizer,
      // layout
      layout4Blocks: keenIcLayout4Blocks,
      layoutPolygon: keenIcLayoutPolygon,
      // communication
      chat: keenIcCommunicationChat,
      mailbox: keenIcCommunicationMailBox,
      spam: keenIcCommunicationSpam,
      safeChat: keenIcCommunicationSafeChat,
      send: keenIcCommunicationSend,
      mailAt: keenIcCommunicationMailAt,
      chatLocked: keenIcCommunicationChatLocked,
      groupChat: keenIcCommunicationGroupChat,
      thumbtack: keenIcCommunicationThumbtack,
      outgoingTask: keenIcCommunicationOutgoingBox,
      clipboardCheck: keenIcCommunicationClipboardCheck,
      group: keenIcCommunicationGroup,
      // clothes
      briefcase: keenIcBriefcase,
      crown: keenIcCrown,
      // navigation
      up: keenIcNavigationUp,
      arrowLeft: keenIcNavigationArrowLeft,
      // tools
      compass: keenIcToolCompass,
      // design
      flatten: keenIcDesignFlatten,
      subtract: keenIcDesignSubtract,
      // home
      library: keenIcLibrary,
      // files
      filePlus: keenIcFilePlus,
      // illustrations
      illustrationPayment: keenIllustrationPayment,
      illustrationAccomplishment: keenIllustrationAccomplishment,
      // socials
      socialGoogle: keenIcSocialGoogle,
      // users
      user1: keenIcUser1,
      user2: keenIcUser2,
      user3: keenIcUser3,
      user4: keenIcUser4,
      user5: keenIcUser5,
      user6: keenIcUser6,
      user7: keenIcUser7,
      user8: keenIcUser8,
      user9: keenIcUser9,
      user10: keenIcUser10,
      user11: keenIcUser11,
      user12: keenIcUser12,
      user13: keenIcUser13,
      user14: keenIcUser14,
      user15: keenIcUser15,
      userBlank: keenIcUserBlank,
    },

    // partner companies
    icLogoGoogle,
    icLogoStripe,
    icLogoUber,
    icLogoEntrepreneur,
    icPrevCarousel,
    icNextCarousel,
    icSendZaLo,
    icSendPhone,
    icPageUp,
    // landingpage
    icCall,
    icFacebook,
    icZalo,
    icBCT,
    icTechcombank,
    icTanHoangMinh,
    icAbbank,
    icToyota,
    icInDo,
    icEBig,
    icGmo,
    icSun,
    icMatBao,

    // ------ vietinvoice -----
    icLovoVietinvoice,
    icLabelVietInvoice,
    icUnLabelVietInvoice,
    icLogoVietinvoiceDark,
    icLogoQuanLyHoaDon,
    icLogoQuanLyHoaDonDark,
    icNotificationActivity,
    icNotificationPromotion,
    icNotificationSystem,
    icMail,
    icNoMail,
    IcXmlAvailable,
    IcXmlDisable,
    IcPdfAvailable,
    IcPdfDisable,

    icTags,
    icTag,
    icNoTag,

    icLogoVNPay,
    icEmailOrder,

    icSignOut,
    icDisconnect,
    icWaiting,
  },

  // images
  images: {
    // keens
    keens: {
      bg1: require('assets/images/Keens/bg1.jpg'),
      bg2: require('assets/images/Keens/bg2.jpg'),
      bg3: require('assets/images/Keens/bg3.jpg'),
    },

    // ----- vietinvoice ------
    imgMisaProcedure,
    imgDefaultAvatar,
    imgEmptyCart,
    imgEmptyConnect,
    imgEmptyNotFound,

    imgInvoiceInfor,
    imgInvoiceAccumulate,

    // landingpage
    imgLandingPage1,
    imgLandingPage2,
    imgLandingPage3,
    imgLandingPage6,
    imgErrorNotificationImage,

    imgDecorDown,
    imgDecorUp,
    imgDefaultFrontCard,
    imgDefaultBackCard,

    imgInputInvoice,
    imgOutputInvoice,
    imgExportInvoice,

    imgTNN,
    imgEmptyPayment,

    imgBienLai,
    imgTemVe,
    imgHDDR,
    imgHDDV,

    imgContactUsSuccess,
    imgSyncInvoice,
    imgAxionBg,

    imgSystemReourceLeft,
    imgSystemReourceRight,
    imgBgInfo,
    imgAuthDecor,

    imgIcorpEcontract,
    imgIcorpEkyc,
    imgIcorpHsm,
    imgIcorpIbh,
    imgIcorpIca,

    imgPaymentFailed,
    imgPaymentSuccess,

    imgMidAutumn,
  },
};

export default AppResource;
