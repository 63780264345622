import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

MailTagView.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
};

MailTagView.defaultProps = {
  icon: '',
  text: '',
  color: '',
};

/**
 *
 * @param {{
 * icon: string,
 * text: string,
 * color: string,
 * }} props
 * @returns
 */
function MailTagView(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { icon, text, color } = props;

  return (
    <div
      className="d-flex align-items-center gap-1 h-25px p-2"
      style={{
        borderRadius: '999px',
        color: color,
        backgroundColor: color + '20',
      }}
    >
      {icon && (
        <i
          className={icon}
          style={{
            color: color,
          }}
        />
      )}
      {text}
    </div>
  );
}

export default MailTagView;
