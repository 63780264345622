import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './style.scss';
import VIButton from '../VIButton';

VIDeleteModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  deleteItem: PropTypes.func,
  deleteTitle: PropTypes.string,
  deleteText: PropTypes.string,
  icon: PropTypes.element,
  dialogIcon: PropTypes.element,
};

VIDeleteModal.defaultProps = {
  show: null,
  onClose: null,
  deleteItem: null,
  deleteTitle: null,
  deleteText: null,
  icon: null,
  dialogIcon: null,
};

function VIDeleteModal(props) {
  const { t } = useTranslation();

  const { show, onClose, deleteItem, deleteTitle, deleteText, icon, dialogIcon } = props;
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }
  function handleDeleteItem() {
    if (deleteItem) {
      deleteItem();
    }
  }
  return (
    <Modal show={show} onHide={handleClose} centered size="md" className="VIDeleteModal p-0">
      {/* modal header */}
      <Modal.Header className="d-flex align-items-center justify-content-center">
        <Modal.Title>{deleteTitle}</Modal.Title>
      </Modal.Header>

      {/* modal content */}
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center bg-light py-0">
        <div className="icon-delete">{icon ? icon : <i className="fa-solid fa-trash-can"></i>}</div>
        <p className="text-center font-weight-bold">{deleteText}</p>
      </Modal.Body>

      {/* modal footer */}
      <Modal.Footer className="d-flex flex-row align-items-center justify-content-center">
        <div className="w-100 d-flex">
          <VIButton
            className="btn-grey me-3 w-50"
            text={t('Cancel')}
            style={{ padding: '8px' }}
            onClick={handleClose}
          />
          <VIButton
            className="btn-blue w-50"
            text={t('Confirm')}
            style={{ padding: '8px' }}
            onClick={() => {
              handleClose();
              handleDeleteItem();
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default VIDeleteModal;
