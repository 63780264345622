// Toast
import store from 'app/store';
import AppToast from 'general/components/AppToast';
import LogHelper from 'general/helpers/LogHelper';
// import { websocketHelperInjectStore } from 'general/helpers/WebsocketHelper';
import DataCommonListener from 'general/listeners/DataCommonListener';
import React, { Suspense, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
// router
import AppDialog from 'general/components/AppDialog';
import GuestRoute from 'general/components/AppRoutes/GuestRoute';
import PrivateRoute from 'general/components/AppRoutes/PrivateRoute';
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';
import KTPageError01 from 'general/components/OtherKeenComponents/KTPageError01';
import KT01BaseLayout from 'general/components/BaseLayout/KT01BaseLayout';
import Dashboard from 'features/Dashboard';
import Invoice from 'features/Invoice';
import Mail from 'features/Mail';
import Supplier from 'features/Supplier';
import Organization from 'features/Organization';
import Axions from 'features/Axions';
import System from 'features/System';
import Auth from 'features/Auth';
import Category from 'features/Category';
import TestComponentScreen from 'features/Test/screens/TestComponentScreen';
import TestComponentDucTuScreen from 'features/Test/screens/TestComponentDucTuScreen';
import TestComponentDungScreen from 'features/Test/screens/TestComponentDungScreen';
import { useTranslation } from 'react-i18next';
import Account from 'features/Account';
import FAQ from 'features/FAQ';
import LookupRiskyBusinessScreen from 'features/Others/screens/LookupRiskyBusinessScreen';
import Instruction from 'features/Instruction';
import LandingPage from 'features/LandingPage';
import Tool from 'features/Tools';
import StoreScreen from 'features/Payment/screens/StoreScreen';
import ShoppingCartHomeScreen from 'features/Payment/screens/ShoppingCartHomeScreen';
import ConfirmPaymentScreen from 'features/Payment/screens/ConfirmPaymentScreen';
import StoreRoute from 'general/components/AppRoutes/StoreRoute';
import OrderSuccessScreen from 'features/Payment/screens/OrderSuccessScreen';
import OrderSuccessRoute from 'general/components/AppRoutes/OrderSuccessRoute';
import OrderInfoScreen from 'features/Payment/screens/OrderInfoScreen';
import OrderDetailRoute from 'general/components/AppRoutes/OrderDetailRoute';
import { websocketHelperInjectStore } from 'general/helpers/WebsocketHelper';
import AccountListener from 'general/listeners/AccountListener';
import VNPayReturnScreen from 'features/Payment/screens/VNPayReturnScreen';
import AppLoading from 'general/components/AppLoading';

// Load BS
require('bootstrap/dist/js/bootstrap.min');
// Load KT plugins
require('assets/plugins/ktutil');
require('assets/plugins/ktmenu');
require('assets/plugins/ktoffcanvas');
require('assets/plugins/ktcookie');
require('assets/plugins/kttoggle');
// aside
require('assets/plugins/aside/aside');
require('assets/plugins/aside/aside-menu');
require('assets/plugins/aside/aside-toggle');
// header
require('assets/plugins/header/ktheader-mobile');
require('assets/plugins/header/ktheader-topbar');

window.$ = window.jQuery = require('jquery');
window.moment = require('moment');

function App() {
  // MARK: --- Hooks ---
  useEffect(() => {
    websocketHelperInjectStore(store);
  }, []);

  return (
    <>
      {/* Router */}
      <BrowserRouter>
        {/* Suspense */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/*" element={<Navigate to="/inbot" />} />

            {/* landing page */}
            <Route path="/landing-page" element={<LandingPage />} />
            <Route path="/store" element={<StoreScreen />} />
            <Route path="/store/vnpay-return" element={<VNPayReturnScreen />} />
            <Route
              path="/store/shopping-cart"
              element={
                <StoreRoute>
                  <ShoppingCartHomeScreen />
                </StoreRoute>
              }
            />
            <Route
              path="/store/shopping-cart/confirm-payment"
              element={
                <StoreRoute>
                  <ConfirmPaymentScreen />
                </StoreRoute>
              }
            />
            <Route
              path="/store/shopping-cart/order-success"
              element={
                <OrderSuccessRoute>
                  <OrderSuccessScreen />
                </OrderSuccessRoute>
              }
            />
            <Route
              path="/store/order-info"
              element={
                <OrderDetailRoute>
                  <OrderInfoScreen />
                </OrderDetailRoute>
              }
            />

            {/* store */}

            {/* test */}
            <Route
              path="/test"
              element={
                <KT01BaseLayout>
                  <div className="container-fluid px-6">
                    <TestComponentScreen />
                  </div>
                </KT01BaseLayout>
              }
            />
            <Route
              path="/test-ductu"
              element={
                <KT01BaseLayout>
                  <div className="container-fluid px-6">
                    <TestComponentDucTuScreen />
                  </div>
                </KT01BaseLayout>
              }
            />
            <Route
              path="/test-dung"
              element={
                <KT01BaseLayout>
                  <div className="container-fluid px-6">
                    <TestComponentDungScreen />
                  </div>
                </KT01BaseLayout>
              }
            />

            <Route
              path="/inbot/*"
              element={
                <PrivateRoute>
                  <KT01BaseLayout>
                    <div className="container-fluid min-h-100 px-6">
                      <Routes>
                        <Route path="" element={<Navigate to="dashboard" />} />
                        <Route path="dashboard/*" element={<Dashboard />} />
                        <Route path="invoice/*" element={<Invoice />} />
                        <Route path="mail/*" element={<Mail />} />
                        <Route path="supplier/*" element={<Supplier />} />
                        <Route path="organization/*" element={<Organization />} />
                        <Route path="category/*" element={<Category />} />
                        <Route path="axions/*" element={<Axions />} />
                        <Route path="system/*" element={<System />} />
                        <Route path="account/*" element={<Account />} />
                        <Route path="faq/*" element={<FAQ />} />
                        <Route path="instruction/*" element={<Instruction />} />
                        <Route path="tool/*" element={<Tool />} />
                        <Route
                          path="lookup-risky-businesses"
                          element={<LookupRiskyBusinessScreen />}
                        />

                        <Route path="*" element={<Navigate to="dashboard" />} />
                      </Routes>
                    </div>
                  </KT01BaseLayout>
                </PrivateRoute>
              }
            />

            <Route
              path="/auth/*"
              element={
                <GuestRoute>
                  <Auth />
                </GuestRoute>
              }
            />

            <Route path="*" element={<KTPageError01 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>

      {/* App Dialog */}
      <AppDialog />
      {/* Toast */}
      <AppToast />
      {/* Listeners */}
      <DataCommonListener />
      {/* app loading */}
      <AppLoading />
      <AccountListener />
    </>
  );
}

export default App;
