import instructionApi from 'api/instructionApi';
import instructionCategoryApi from 'api/instructionCategoryApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// lay tat ca the loai instruction
export const thunkGetAllInstructionCategories = createAsyncThunk(
  'instruction/getAllInstructionCategories',
  async (params, thunkAPI) => {
    const res = await instructionCategoryApi.getAllCategories();
    return res;
  }
);

// lay tat ca instructions
export const thunkGetAllInstructions = createAsyncThunk(
  'instruction/getAllInstructions',
  async (params, thunkAPI) => {
    const res = await instructionApi.getAll();
    return res;
  }
);

const instructionSlice = createSlice({
  name: 'instruction',
  initialState: {
    allInstructionCategoryList: [],
    instructionList: [],
    isGettingInstructionList: false,
  },
  reducers: {},
  extraReducers: {
    // lay tat ca the loai
    [thunkGetAllInstructionCategories.fulfilled]: (state, action) => {
      const { result, instructionCategories } = action.payload;
      if (result === 'success' && instructionCategories) {
        state.allInstructionCategoryList = instructionCategories;
      }
    },

    // lay tat ca instructions
    [thunkGetAllInstructions.fulfilled]: (state, action) => {
      const { result, instructions } = action.payload;
      if (result === 'success' && instructions) {
        state.instructionList = instructions;
      }
    },
  },
});

const { reducer, actions } = instructionSlice;
export const {} = actions;
export default reducer;
