import EmptyView from 'features/Others/components/EmptyView';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import AppConfigs from 'general/constants/AppConfigs';
import AppDimension from 'general/constants/AppDimension';
import AppResource from 'general/constants/AppResource';
import StringUtils from 'general/utils/StringUtils';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './style.scss';
import useConfig from 'hooks/useConfig';

function FAQHomeScreen(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { faqList, isGettingFaqList } = useSelector((state) => state.faq);
  const [contentMaxHeight, setContentMaxHeight] = useState('55vh');
  const refCardHeader = useRef(null);
  const [filters, setFilters] = useState({
    q: '',
  });
  const [filteredFaqList, setFilteredFaqList] = useState([]);
  const bannerHeight = useConfig().systemNotification.bannerHeight;

  function resizeScreen() {
    setContentMaxHeight(
      `calc(100vh - ${
        bannerHeight +
        (AppConfigs.isHideFooter ? 0 : 65) +
        72 +
        refCardHeader?.current?.clientHeight
      }px - ${4.5 + 3 + 1}rem)`
    );
  }

  window.onresize = resizeScreen;

  useEffect(() => {
    resizeScreen();
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const { q } = filters;
    let filteredItems = _.chain(faqList);
    // loc theo text
    filteredItems = filteredItems.filter((item) => {
      return (
        StringUtils.removeVietnameseTones(item?.question)
          .toLowerCase()
          ?.includes(StringUtils.removeVietnameseTones(q).toLowerCase()) ||
        StringUtils.removeVietnameseTones(item?.answer)
          .toLowerCase()
          ?.includes(StringUtils.removeVietnameseTones(q).toLowerCase())
      );
    });
    setFilteredFaqList(filteredItems.value());
  }, [filters, faqList]);

  return (
    <div
      className="card card-custom p-9 h-100"
      style={{
        borderRadius: '16px',
      }}
    >
      <div className="d-flex flex-column align-items-start" ref={refCardHeader}>
        <h3 className="card-label text-grey font-weight-bolder mb-3">
          Câu hỏi thường gặp về hóa đơn đầu vào
        </h3>
        <VISearchField
          placeholder={`${t('EnterToSearch')}...`}
          value={''}
          className=""
          typingTimeout={500}
          onSubmit={(newValue) => {
            setFilters({ ...filters, q: newValue });
          }}
        />
      </div>

      {/* danh sach faqs */}
      <div
        className="mt-4 overflow-auto"
        style={{
          height: contentMaxHeight,
        }}
      >
        {filteredFaqList?.length > 0 ? (
          <Accordion defaultActiveKey="0" flush className="accordion-list">
            {filteredFaqList.map((item, index) => (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header className="">
                  {index + 1}. {item?.question}
                </Accordion.Header>
                <div className="">
                  <Accordion.Body>
                    <div className="mb-2">{item?.answer}</div>
                    {/* <a href={item?.url ?? '#'} className="" target="_blank">
                      {t('SeeDetail')}
                    </a> */}
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <div className="h-100">
            <EmptyView message={t('NoDataFound')} image={AppResource.images.imgEmptyNotFound} />
          </div>
        )}
      </div>
    </div>
  );
}

export default FAQHomeScreen;
