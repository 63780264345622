import VIButton from 'general/components/VietInvoice/VIButton';
import VIDateRangePickerInput from 'general/components/VietInvoice/VIDateRangePicker';
import VISelectFilter from 'general/components/VietInvoice/VISelectFilter';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

import Global from 'general/utils/Global';
import { thunkGetAllDeleteOutInvoice, thunkGetAllOutInvoice } from 'features/Invoice/invoiceSlice';
import { useDispatch } from 'react-redux';
import Utils from 'general/utils/Utils';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import useAccount from 'hooks/useAccount';

function DropdownFilterDeleteOutInvoice(props) {
  const { handleFilterInvoice, refDropdownFilterDeleteOutputMenu } = props;
  const dataStatusInvoice = [
    {
      name: 'Tất cả',
      value: 0,
    },
    {
      name: 'Đang kiểm tra',
      value: 1,
    },
    {
      name: 'Hợp lệ',
      value: 2,
    },
    {
      name: 'Không hợp lệ',
      value: 3,
    },
    {
      name: 'Không có bản thể hiện',
      value: 4,
    },
    {
      name: 'Bản nháp',
      value: 5,
    },
    {
      name: 'Không có XML',
      value: 6,
    },
  ];
  const TypeCode = [
    {
      name: 'Tất cả',
      value: 0,
    },
    {
      name: 'HĐ giá trị gia tăng',
      value: 1,
    },
    {
      name: 'HĐ bán hàng',
      value: 2,
    },
    {
      name: 'HĐ bán tài sản công',
      value: 3,
    },
    {
      name: 'HĐ bán hàng dự trữ quốc gia',
      value: 4,
    },
    {
      name: 'Tem, vé, thẻ điện tử',
      value: 5,
    },
    {
      name: 'Phiếu xuất kho điện tử',
      value: 6,
    },
  ];
  const serial = [
    {
      name: 'Tất cả',
      value: 0,
    },
    {
      name: 'Hóa đơn có mã',
      value: 'C',
    },
    {
      name: 'Hóa đơn không mã',
      value: 'K',
    },
  ];
  const account = useAccount();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataFilters, setDataFilters] = useState({
    status:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.status ?? dataStatusInvoice[0]?.value,
    invoiceTypeCode:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.invoiceTypeCode ?? TypeCode[0]?.value,
    invoiceSerialType:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.invoiceSerialType ?? serial[0]?.value,
    fromDate:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.fromDate ?? moment().subtract(29, 'days').format('YYYY-MM-DD'),
    toDate:
      JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
        Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
      ]?.toDate ?? moment().format('YYYY-MM-DD'),
    dateRangeLabel: JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
      Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
    ]?.dateRangeLabel,
  });
  const handleSubmit = () => {
    if (handleFilterInvoice) {
      handleFilterInvoice(dataFilters);
    }
    Global.gFiltersOutputInvoiceDelete = {
      ...Global.gFiltersOutputInvoiceDelete,
      fromDate: dataFilters?.fromDate,
      toDate: dataFilters?.toDate,
      status: dataFilters?.status,
      invoiceTypeCode: dataFilters?.invoiceTypeCode,
      invoiceSerialType: dataFilters?.invoiceSerialType,
      dateRangeLabel: dataFilters.dateRangeLabel,
      page: 0,
    };
    localStorage.setItem(
      PreferenceKeys.saveOutputDeleteInvoice,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice)),
        [Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))]: {
          ...Global.gFiltersOutputInvoiceDelete,
          fromDate: dataFilters?.fromDate,
          toDate: dataFilters?.toDate,
          status: dataFilters?.status,
          invoiceTypeCode: dataFilters?.invoiceTypeCode,
          invoiceSerialType: dataFilters?.invoiceSerialType,
          dateRangeLabel: dataFilters.dateRangeLabel,
          page: 0,
        },
      })
    );
    refDropdownFilterDeleteOutputMenu?.current.classList.remove('show');
  };
  const handleClose = () => {
    setDataFilters({
      status: dataStatusInvoice[0]?.value,
      fromDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      dateRangeLabel: '30 ngày gần đây',
      invoiceTypeCode: TypeCode[0]?.value,
      invoiceSerialType: serial[0]?.value,
    });
    Global.gFiltersOutputInvoiceDelete = {
      ...Global.gFiltersOutputInvoiceDelete,
      fromDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      status: dataStatusInvoice[0]?.value,
      dateRangeLabel: '30 ngày gần đây',
      invoiceTypeCode: TypeCode[0]?.value,
      invoiceSerialType: serial[0]?.value,
      page: 0,
    };
    localStorage.setItem(
      PreferenceKeys.saveOutputDeleteInvoice,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice)),
        [Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))]: {
          ...Global.gFiltersOutputInvoiceDelete,
          fromDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
          status: dataStatusInvoice[0]?.value,
          dateRangeLabel: '30 ngày gần đây',
          invoiceTypeCode: TypeCode[0]?.value,
          invoiceSerialType: serial[0]?.value,
          page: 0,
        },
      })
    );
    dispatch(thunkGetAllDeleteOutInvoice(Global.gFiltersOutputInvoiceDelete));
    refDropdownFilterDeleteOutputMenu?.current.classList.remove('show');
  };
  useEffect(() => {
    if (Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId)) !== undefined) {
      setDataFilters({
        status:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.status ?? dataStatusInvoice[0]?.value,
        invoiceTypeCode:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.invoiceTypeCode ?? TypeCode[0]?.value,
        invoiceSerialType:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.invoiceSerialType ?? serial[0]?.value,
        fromDate:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.fromDate ?? moment().subtract(29, 'days').format('YYYY-MM-DD'),
        toDate:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.toDate ?? moment().format('YYYY-MM-DD'),
        dateRangeLabel: JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
        ]?.dateRangeLabel,
      });
      Global.gFiltersOutputInvoiceDelete = {
        ...Global.gFiltersOutputInvoiceDelete,
        status:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.status ?? dataStatusInvoice[0]?.value,
        invoiceTypeCode:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.invoiceTypeCode ?? TypeCode[0]?.value,
        invoiceSerialType:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.invoiceSerialType ?? serial[0]?.value,
        fromDate:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.fromDate ?? moment().subtract(29, 'days').format('YYYY-MM-DD'),
        toDate:
          JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
            Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
          ]?.toDate ?? moment().format('YYYY-MM-DD'),
        dateRangeLabel: JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))
        ]?.dateRangeLabel,
      };
    }
  }, [Number(localStorage.getItem(PreferenceKeys.savedLoginAccountId))]);
  return (
    <div className="px-3">
      <div className="font-size-h3 font-weight-bold mb-3">{t('FilterInvoices')}</div>

      <div className="d-flex mb-3 ">
        <div className="w-100 ">
          <span className="font-size-lg font-weight-bold ">{t('Status')}</span>
          <VISelectFilter
            data={dataStatusInvoice}
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, status: e?.value });
              Global.gFiltersOutputInvoiceDelete = {
                ...Global.gFiltersOutputInvoiceDelete,
                status: e?.value,
              };
            }}
            CurrentSelect={dataFilters?.status}
            className="w-100 mt-2"
          />
        </div>
      </div>

      <div className="d-flex mb-3 ">
        <div className="w-100 ">
          <span className="font-size-lg font-weight-bold ">{t('InvoiceTemplate')}</span>
          <VISelectFilter
            data={TypeCode}
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, invoiceTypeCode: e?.value });
              Global.gFiltersOutputInvoiceDelete = {
                ...Global.gFiltersOutputInvoiceDelete,
                invoiceTypeCode: e?.value,
              };
            }}
            CloseDropdown="InvoiceTemplate"
            CurrentSelect={dataFilters?.invoiceTypeCode}
            className="w-100 mt-2"
          />
        </div>
      </div>
      <div className="d-flex mb-3 ">
        <div className="w-100 ">
          <span className="font-size-lg font-weight-bold ">{t('InvoiceForm')}</span>
          <VISelectFilter
            data={serial}
            onChangeValue={(e) => {
              setDataFilters({ ...dataFilters, invoiceSerialType: e?.value });
              Global.gFiltersOutputInvoiceDelete = {
                ...Global.gFiltersOutputInvoiceDelete,
                invoiceSerialType: e?.value,
              };
            }}
            CurrentSelect={dataFilters?.invoiceSerialType}
            CloseDropdown="InvoiceForm"
            className="w-100 mt-2"
          />
        </div>
      </div>

      <p className={`mb-1 font-weight-bold`}>{t('FromDate')}</p>
      <KTFormGroup
        additionalClassName=" mb-3"
        inputName="date"
        inputElement={
          <div>
            <KTFormInput
              name="date"
              value={Utils.formatDateTime(dataFilters.fromDate, 'YYYY-MM-DD')}
              onChange={(value) => {
                setDataFilters({
                  ...dataFilters,
                  fromDate: moment(value)?.format('YYYY-MM-DD'),
                });
                Global.gFiltersOutputInvoiceDelete = {
                  ...Global.gFiltersOutputInvoiceDelete,
                  fromDate: moment(value)?.format('YYYY-MM-DD'),
                };
              }}
              enableCheckValid
              type={KTFormInputType.date}
              placeholder={`${_.capitalize(t('SelectPaymentDate'))}`}
              enableInputGroup={true}
              prependElements={[<i className="fa-regular fa-calendar-clock"></i>]}
            />
          </div>
        }
      />
      <p className={`mb-1 font-weight-bold`}>{t('ToDate')}</p>
      <KTFormGroup
        additionalClassName=" mb-3"
        inputName="date"
        inputElement={
          <div>
            <KTFormInput
              name="date"
              value={Utils.formatDateTime(dataFilters.toDate, 'YYYY-MM-DD')}
              onChange={(value) => {
                setDataFilters({
                  ...dataFilters,
                  toDate: moment(value)?.format('YYYY-MM-DD'),
                });
                Global.gFiltersOutputInvoiceDelete = {
                  ...Global.gFiltersOutputInvoiceDelete,
                  toDate: moment(value)?.format('YYYY-MM-DD'),
                };
              }}
              enableCheckValid
              type={KTFormInputType.date}
              placeholder={`${_.capitalize(t('SelectPaymentDate'))}`}
              enableInputGroup={true}
              prependElements={[<i className="fa-regular fa-calendar-clock"></i>]}
            />
          </div>
        }
      />
      <div className="d-flex justify-content-between button-filter mb-2">
        <div className="btn-resets" style={{ width: '100px' }}>
          {/* <VIButton
          text={t('ResetToDefault')}
          beforeIcon={<i className="fa-solid fa-arrow-rotate-right"></i>}
          className=" btn-white w-100 "
          style={{ padding: '8px' }}
          onClick={() => {}}
          afterIcon={<></>}
        /> */}
        </div>
        <div className="d-flex btn-submit-filter">
          <VIButton
            text={t('DeselectFilter')}
            beforeIcon={<></>}
            className="btn-white me-3"
            style={{ padding: '8px' }}
            onClick={() => {
              handleClose();
            }}
            afterIcon={<></>}
          />
          <VIButton
            text={t('Save')}
            beforeIcon={<></>}
            className=""
            style={{ padding: '8px' }}
            onClick={() => {
              handleSubmit();
            }}
            afterIcon={<></>}
          />
        </div>
      </div>
    </div>
  );
}

export default DropdownFilterDeleteOutInvoice;
