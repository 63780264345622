import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KTAdvanceNav, {
  KTAdvanceNavFontWeight,
} from 'general/components/OtherKeenComponents/KTAdvanceNav';
import AppResource from 'general/constants/AppResource';
import AppConfigs from 'general/constants/AppConfigs';
import VIModal from 'general/components/VietInvoice/VIModal';
import CallCenterView from '../CallCenterView';
import useRouter from 'hooks/useRouter';
import Utils from 'general/utils/Utils';

DropdownHeaderHelpMenuInfo.propTypes = {};

function DropdownHeaderHelpMenuInfo(props) {
  // ----- Params -----
  const { t } = useTranslation();
  const router = useRouter();
  const [modalCallCenterShowing, setModalCallCenterShowing] = useState(false);

  // ---- methods ----

  return (
    <div className="min-w-300px">
      <div className="card-custom">
        <div className="card-body p-4">
          {/* nav */}
          <KTAdvanceNav
            enableHoverStyle
            fontWeight={KTAdvanceNavFontWeight.bold}
            enableLinkRounded={true}
            itemList={[
              {
                text: 'Hướng dẫn sử dụng',
                iconElement: <i className="fa-regular fa-circle-info" />,
                onPress: () => {
                  router.navigate('/inbot/instruction');
                },
              },
              {
                text: 'Nhân viên tư vấn trên Zalo',
                iconElement: <i className="fa-regular fa-user" />,
                onPress: () => {
                  Utils.openInNewTab(`https://zalo.me/${AppConfigs.zaloPhoneNumber}`);
                },
              },
              // {
              //   text: 'Cộng đồng hỗ trợ phần mềm',
              //   iconElement: <i className="fa-regular fa-user-group" />,
              // },
              // {
              //   text: 'Diễn đàn hỗ trợ ICORP',
              //   iconElement: <i className="fa-regular fa-chart-network" />,
              // },
              {
                text: `Tổng đài ICORP: ${AppConfigs.callCenterNumber}`,
                iconElement: <i className="fa-regular fa-phone" />,
                onPress: () => {
                  setModalCallCenterShowing(true);
                },
              },
            ]}
          />
        </div>
      </div>

      {/* modal call center */}
      <VIModal
        titleModal={t('CallCenterSupport')}
        modalSize="lg"
        show={modalCallCenterShowing}
        contentModal={<CallCenterView />}
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalCallCenterShowing(false);
        }}
        // buttonNegative={t('Close')}
      />
    </div>
  );
}

export default DropdownHeaderHelpMenuInfo;
