import { FastField, Field, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTCheckbox, {
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import KeenSelectOption from 'general/components/OtherKeenComponents/KeenSelectOption';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIOverViewBar from 'general/components/VietInvoice/VIOverViewBar';
import VISelectMultiOrganization from 'general/components/VietInvoice/VISelectMultiOrganization';
import AppData from 'general/constants/AppData';
import SystemHelper from 'general/helpers/SystemHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useSystem from 'hooks/useSystem';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

AddUserView.propTypes = {
  onPressCancel: PropTypes.func,
  item: PropTypes.object,
  onPressAddCompanyTitle: PropTypes.func,
};

AddUserView.defaultProps = {
  onPressCancel: null,
  item: null,
  onPressAddCompanyTitle: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * item: object,
 * onPressAddCompanyTitle: function,
 * }} props
 * @returns
 */
function AddUserView(props) {
  // ---- params -----
  const { t } = useTranslation();
  const { onPressCancel, item, onPressAddCompanyTitle } = props;
  const isEditMode = !_.isNull(item);
  const [switchAllOrganizationOn, setSwitchAllOrganizationOn] = useState(false);
  const { companyTitleOptions, companyRoleOptions } = useSystem();
  const refFormik = useRef();
  const { organizations } = useSelector((state) => state?.organization);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [listOrganization, setListOrganization] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);

  useEffect(() => {
    if (Global.gTempCompanyTitle?.companyTitleId) {
      refFormik.current?.setFieldValue('userTitle', Global.gTempCompanyTitle?.companyTitleId);
    }
  }, [Global.gTempCompanyTitle]);

  // ---- methods -----
  function handlePressCancel() {
    Global.resetTempSystemUserInfo();
    if (onPressCancel) {
      onPressCancel();
    }
  }

  function handlePressAddCompanyTitle() {
    if (onPressAddCompanyTitle) {
      onPressAddCompanyTitle();
    }
  }

  function handleCheckOrganization(item) {
    const allOrganization = Utils.getAllNodes(organizations);
    const childrens = Utils.getAllChildren(item);

    if (item?.organizationDepartmentId) {
      const branch = allOrganization.find((or) => or.organizationId === item.organizationId);
      const organization = allOrganization.find(
        (or) => or.organizationId === branch.parentOrganizationId
      );
      const newArray = [organization, branch, item];
      setListOrganization(Utils.mergeAndRemoveDuplicates(newArray, listOrganization));
    } else if (item.parentOrganizationId) {
      const organization = allOrganization.find(
        (or) => or.organizationId === item.parentOrganizationId
      );
      const newArray = [organization, item, ...childrens];
      setListOrganization(Utils.mergeAndRemoveDuplicates(newArray, listOrganization));
    } else {
      const newArray = [item, ...childrens];
      setListOrganization(Utils.mergeAndRemoveDuplicates(newArray, listOrganization));
    }
  }

  function handleUnCheckOrganization(item) {
    const allOrganization = Utils.getAllNodes(organizations);

    let cloneList = [...listOrganization];

    const branch = allOrganization.find(
      (or) => !or.organizationDepartmentId && or.organizationId === item.organizationId
    );

    const countBranch = listOrganization.filter(
      (or) => or.parentOrganizationId === branch.organizationDepartmentId
    ).length;

    if (item?.organizationDepartmentId) {
      const countDepartment = listOrganization.filter(
        (or) => or.organizationDepartmentId && or.organizationId === item.organizationId
      ).length;

      if (countDepartment === 1) {
        cloneList = cloneList.filter((or) => or.organizationId !== item.organizationId);
      } else {
        cloneList = cloneList.filter(
          (or) => or.organizationDepartmentId !== item.organizationDepartmentId
        );
      }

      if (countBranch === 1) {
        cloneList = cloneList.filter((or) => or.organizationId !== branch.parentOrganizationId);
      }
    } else if (item.parentOrganizationId) {
      cloneList = cloneList.filter((or) => or.organizationId !== item.organizationId);
      if (countBranch === 1) {
        cloneList = cloneList.filter((or) => or.organizationId !== item.parentOrganizationId);
      }
    } else {
      cloneList = cloneList.filter((or) => or.organizationId !== item.organizationId);
      // cloneList = cloneList.filter((or) => or.parentOrganizationId !== item.organizationId);
      const childrens = Utils.getAllChildren(item);

      childrens.forEach((child) => {
        cloneList = cloneList.filter((or) => or.organizationId != child.organizationId);
      });
    }

    setListOrganization(cloneList);
  }

  useEffect(() => {
    const organizationIds = listOrganization?.map((item) => {
      return {
        organizationId: item?.organizationDepartmentId ? null : item?.organizationId,
        organizationDepartmentId: item?.organizationDepartmentId ?? null,
      };
    });
    setSelectedOrganizations(organizationIds);
  }, [listOrganization]);

  useEffect(() => {
    let preList = [];
    const allOrganization = Utils.getAllNodes(organizations);
    if (item?.AccountOrganizationAccesses && item.AccountOrganizationAccesses.length > 0) {
      item?.AccountOrganizationAccesses.forEach((or) => {
        // console.log(or.organizationDepartmentId);
        if (or.organizationDepartmentId) {
          preList.push(
            allOrganization.find(
              (child) => child.organizationDepartmentId == or.organizationDepartmentId
            )
          );
        } else {
          preList.push(allOrganization.find((child) => child.organizationId == or.organizationId));
        }
      });
    }
    setListOrganization(preList);
  }, [item]);

  return (
    <div className="">
      <Formik
        initialValues={{
          username: item?.companyUsername ?? Global.gTempSystemUserInfo.username,
          // password: item?.password ?? '',
          email: item?.email ?? Global.gTempSystemUserInfo.email,
          phoneNumber: item?.phone ?? Global.gTempSystemUserInfo.phoneNumber,
          userTitle: item?.companyTitleId ?? Global.gTempSystemUserInfo.userTitle,
          fullname: item?.fullname ?? Global.gTempSystemUserInfo.fullname,
          userRole: item?.roleId
            ? item?.roleId
            : item?.accountLevel === AppData.accountLevel.user
            ? 0
            : Global.gTempSystemUserInfo.userRole,
        }}
        validationSchema={Yup.object({
          username: Yup.string().required(t('Required')),
          email: Yup.string().required(t('Required')),
          phoneNumber: Yup.string().required(t('Required')),
          userTitle: Yup.string().required(t('Required')),
          fullname: Yup.string().required(t('Required')),
          userRole: Yup.string().required(t('Required')),
        })}
        enableReinitialize
        onSubmit={async (values) => {
          // console.log(values);
          // if (values.userTitle === '') {
          //   ToastHelper.showError('Bạn chưa chọn chức danh');
          //   return;
          // }

          let res = false;
          const params = {
            companyUsername: values.username,
            email: values.email,
            fullname: values.fullname,
            phone: values.phoneNumber,
            roleId: values.userRole,
            companyTitleId: values.userTitle,
            accountOrganizationAccesses: selectedOrganizations,
          };
          // if (!_.isEmpty(values.password)) {
          //   params.password = Utils.sha256(values.password);
          // }
          // console.log({ params });
          // return;
          if (isEditMode) {
            // chinh sua
            res = await SystemHelper.callApiUpdateSystemUser(item?.accountId, params);
          } else {
            // tao moi
            res = await SystemHelper.callApiCreateSystemUser(params);
          }
          if (res) {
            handlePressCancel();
          }
        }}
      >
        {(formikProps) => {
          refFormik.current = formikProps;
          return (
            <div className="">
              <div
                style={{
                  maxHeight: '70vh',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                <div className="row m-0">
                  {/* username */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('Username')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="username"
                      inputElement={
                        <FastField name="username">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                Global.gTempSystemUserInfo.username = value;
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('Username'))}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                  {/* password */}
                  {/* <div className="col-lg-6">
                    <KTFormGroup
                      label={<>{t('Password')}</>}
                      inputName="password"
                      inputElement={
                        <FastField name="password">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.password}
                              placeholder={`${_.capitalize(t('Password'))}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div> */}
                  {/* email */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('Email')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="email"
                      inputElement={
                        <FastField name="email">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                Global.gTempSystemUserInfo.email = value;
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.email}
                              placeholder={`${_.capitalize(t('Email'))}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                  {/* so dien thoai */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('PhoneNumber')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="phoneNumber"
                      inputElement={
                        <FastField name="phoneNumber">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                Global.gTempSystemUserInfo.phoneNumber = value;
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('PhoneNumber'))}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                  {/* chuc danh */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('UserTitle')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="userTitle"
                      inputElement={
                        <Field name="userTitle">
                          {({ field, form, meta }) => (
                            <KeenSelectOption
                              name={field.name}
                              initialValue={formikProps.getFieldProps(field.name).value}
                              fieldProps={formikProps.getFieldProps(field.name)}
                              fieldMeta={formikProps.getFieldMeta(field.name)}
                              fieldHelpers={formikProps.getFieldHelpers(field.name)}
                              options={companyTitleOptions}
                              containerClassName=""
                              labelClassName=""
                              selectClassName="w-100"
                              onValueChanged={(value) => {
                                formikProps.getFieldHelpers(field.name).setValue(value);
                                Global.gTempSystemUserInfo.userTitle = value;
                              }}
                              showItemDelete={true}
                              autoClose={true}
                              onDeleteItem={(value) => {
                                // console.log('delete: ' + value);
                                if (value == formikProps.values.userTitle) {
                                  form.setFieldValue('userTitle', '');
                                }
                                SystemHelper.callApiDeleteCompanyTitle([value]);
                              }}
                              footerEl={
                                <div className="px-4 py-2">
                                  <VIButton
                                    beforeIcon={<i className="fa-regular fa-add" />}
                                    text={t('AddTitle')}
                                    className="btn-blue mt-2 p-2"
                                    onClick={handlePressAddCompanyTitle}
                                  />
                                </div>
                              }
                            />
                          )}
                        </Field>
                      }
                    />
                  </div>
                  {/* ho va ten */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('Fullname')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="fullname"
                      inputElement={
                        <FastField name="fullname">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                Global.gTempSystemUserInfo.fullname = value;
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('Fullname'))}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                  {/* vai tro quyen han */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('UserRole')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="userRole"
                      inputElement={
                        <FastField name="userRole">
                          {({ field, form, meta }) => (
                            <KeenSelectOption
                              name={field.name}
                              fieldProps={formikProps.getFieldProps(field.name)}
                              fieldMeta={formikProps.getFieldMeta(field.name)}
                              fieldHelpers={formikProps.getFieldHelpers(field.name)}
                              options={companyRoleOptions}
                              containerClassName=""
                              labelClassName=""
                              selectClassName="w-100"
                              onValueChanged={(value) => {
                                formikProps.getFieldHelpers(field.name).setValue(value);
                                Global.gTempSystemUserInfo.userRole = value;
                              }}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                </div>

                {/* phan quyen theo don vi */}
                <div>
                  <div className="separator separator-dashed mb-5"></div>
                  <div className="">
                    {/* <p className="mb-3 font-size-lg font-weight-bold">Phân quyền theo đơn vị</p> */}
                    <VIOverViewBar
                      itemTab={[
                        {
                          title: 'Phân quyền theo đơn vị',
                          status: 'active',
                        },
                      ]}
                      currentSelect={0}
                    />
                    <div className="">
                      <div className="checkbox-list border-bottom bg-light px-4 py-4 position-sticky top-0 zindex-5">
                        <KTCheckbox
                          name="switchAllOrganizationOn"
                          label={t('AllOrganization')}
                          // size={KTSwitchSize.sm}
                          // type={KTSwitchType.outline}
                          type={KTCheckboxType.outline}
                          checked={switchAllOrganizationOn}
                          onChange={(value) => {
                            setSwitchAllOrganizationOn(value);
                            if (value) {
                              setListOrganization(Utils.getAllNodes(organizations));
                            } else {
                              setListOrganization([]);
                              setCurrentOrganization(null);
                            }
                          }}
                          additionalClassName="switch-primary mr-4"
                        />
                      </div>
                      <div className="px-4">
                        <VISelectMultiOrganization
                          listItem={listOrganization}
                          onPushItem={(item) => {
                            handleCheckOrganization(item);
                          }}
                          onRemoveItem={(item) => handleUnCheckOrganization(item)}
                          level={1}
                          currentItem={currentOrganization}
                          data={organizations}
                          setItem={(item) => {
                            setCurrentOrganization(item);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* actions */}
              <div className="d-flex align-items-center justify-content-end gap-3 mt-3">
                <VIButton
                  text={t('Cancel')}
                  className="btn-grey"
                  style={{ padding: '8px' }}
                  onClick={handlePressCancel}
                />
                <VIButton
                  text={t('Save')}
                  className=""
                  style={{ padding: '8px' }}
                  onClick={() => {
                    formikProps.handleSubmit();
                  }}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddUserView;
