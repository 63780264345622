import 'bootstrap-daterangepicker/daterangepicker.css';
import Utils from 'general/utils/Utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useTranslation } from 'react-i18next';
import './style.scss';

VIDateRangePickerInput.propTypes = {
  format: PropTypes.string,
  className: PropTypes.string,
  haveTitle: PropTypes.bool,
  getDateRange: PropTypes.func,
  customRange: PropTypes.object,
  showingRange: PropTypes.string,
  initialLabel: PropTypes.string,
  initialStartDate: PropTypes.object,
  initialEndDate: PropTypes.object,
  drop: PropTypes.string,
  isSmall: PropTypes.bool,
  text: PropTypes.string,
};

VIDateRangePickerInput.defaultProps = {
  format: 'DD/MM/YYYY',
  className: '',
  haveTitle: true,
  getDateRange: null,
  customRange: {},
  showingRange: '',
  initialLabel: 'Tất cả',
  initialStartDate: null,
  initialEndDate: null,
  drop: '',
  isSmall: false,
  text: '',
};

/**
 * @param {string} format format date
 * @param {string} className
 * @param {boolean} haveTitle hide icon first
 * @param {function} getDateRange get date
 * @param {object} customRange custom date select field, (weekly, quarterly, year)
 * @param {string} showingRange selected date
 * @param {string} initialLabel default value
 * @param {object} initialStartDate start date
 * @param {object} initialEndDate and date
 * @param {boolean} isSmall and date
 *
 */

function VIDateRangePickerInput(props) {
  const {
    format,
    className,
    haveTitle,
    getDateRange,
    customRange,
    showingRange,
    initialLabel,
    initialStartDate,
    initialEndDate,
    drop,
    isSmall,
    text,
  } = props;
  const dateRangePickerInput = useRef(null);
  const [range, setRange] = useState(initialLabel);
  const { t } = useTranslation();

  useEffect(() => {
    setRange(initialLabel);
  }, [initialLabel]);

  useEffect(() => {
    dateRangePickerInput.current?.setStartDate(initialStartDate);
  }, [initialStartDate]);

  useEffect(() => {
    dateRangePickerInput.current?.setEndDate(initialEndDate);
  }, [initialEndDate]);

  function handleEvent(event, picker) {}
  function handleCallback(start, end, label) {
    let dateRange = {};
    if (label === 'Tất cả') {
      setRange('Tất cả');
      dateRange = {
        label: label,
        startDate: '',
        endDate: '',
      };
    } else if (label === 'Hôm nay' || label === 'Hôm qua') {
      label === 'Hôm nay' ? setRange('Hôm nay') : setRange('Hôm qua');
      dateRange = {
        label: label,
        startDate: start._d,
        endDate: end._d,
      };
    } else {
      // if (label) {
      //   setRange(label);
      // } else {
      // }
      setRange(
        `${Utils.formatDateTime(start._d, 'DD/MM/YYYY')} - ${Utils.formatDateTime(
          end._d,
          'DD/MM/YYYY'
        )}`
      );
      dateRange = {
        label: label,
        startDate: start._d,
        endDate: end._d,
      };
    }
    if (getDateRange) {
      getDateRange(dateRange);
    }
  }

  function handleFocus() {
    dateRangePickerInput.current.ref.focus();
  }

  function handleShowCalender(event, picker) {}

  useEffect(() => {
    if (showingRange) {
      setRange(showingRange);
    }
  }, [showingRange]);

  return (
    <div
      className={`DateRangePickerInput cursor-pointer d-flex flex-wrap align-items-center justify-content-between ${className} ${
        isSmall ? 'h-30px px-3' : 'px-4'
      }`}
      onClick={handleFocus}
      id="date-range-input"
    >
      <div className="d-flex align-items-center">
        {haveTitle && (
          <i
            className={`DateRangePickerInput_Icon_Calender fa-regular fa-calendar-clock me-2 ${
              isSmall ? 'fa-1x' : ''
            }`}
            style={{ color: '#535362' }}
          ></i>
        )}
        <div style={{ maxWidth: '90%' }} className=" d-flex align-items-center">
          <span
            className={`${isSmall ? 'font-size-sm font-weight-bold me-2' : 'font-size-lg me-2'}`}
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {range}
          </span>
        </div>
      </div>
      <i
        className={`DateRangePickerInput_Icon_Down far fa-angle-down d-flex align-items-center justify-content-center ${
          isSmall ? 'fa-1x' : ''
        }`}
        style={{ color: '#9A9AAF', right: '10px' }}
      ></i>

      <div className="position-absolute">
        <DateRangePicker
          onShow={handleShowCalender}
          ref={dateRangePickerInput}
          onEvent={handleEvent}
          onCallback={handleCallback}
          // drop="up"

          style={{ display: 'none', right: '0px' }}
          initialSettings={{
            startDate: initialStartDate ?? moment().subtract(28, 'days'),
            endDate: initialEndDate ?? moment(),
            alwaysShowCalendars: true,
            // opens: 'left',
            drops: drop,
            locale: {
              format: format,
              cancelLabel: t('Cancel'),
              applyLabel: t('Apply'),
              customRangeLabel: t('Custom'),
              daysOfWeek: ['CN', 'Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7'],
              monthNames: [
                'Tháng Một',
                'Tháng Hai',
                'Tháng Ba',
                'Tháng Tư',
                'Tháng Năm',
                'Tháng Sáu',
                'Tháng Bảy',
                'Tháng Tám',
                'Tháng Chín',
                'Tháng Mười',
                'Tháng Mười Một',
                'Tháng Mười Hai',
              ],
            },
            ranges: customRange ?? {
              'Tất cả': ['', ''],
              'Hôm qua': [
                moment().subtract(1, 'day').startOf('day'),
                moment().subtract(1, 'day').endOf('day'),
              ],
              'Tuần này': [moment().startOf('week').add(1, 'days'), moment()],
              '7 ngày gần đây': [moment().subtract(6, 'days'), moment()],
              '30 ngày gần đây': [moment().subtract(29, 'days'), moment()],
              'Tháng trước': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
              'Tháng này': [moment().startOf('month'), moment()],
            },
          }}
        >
          <input
            style={{
              color: 'transparent',
              height: '38px',
              width: '0px',
              left: '0px',
            }}
            className="cursor-pointer rounded p-0"
          />
        </DateRangePicker>
      </div>
    </div>
  );
}

export default VIDateRangePickerInput;
