import AppResource from './AppResource';

const AppData = {
  // phan trang
  perPageItems: [
    { value: 5 },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 50 },
    { value: 100 },
  ],

  // vai tro nguoi dung
  userRole: {
    admin: 'ADMIN',
    user: 'USER',
    accountant: 'ACCOUNTANT',
  },
  userRoleItems: [
    { name: 'Admin', value: 'ADMIN' },
    { name: 'User', value: 'USER' },
    { name: 'Accountant', value: 'ACCOUNTANT' },
  ],

  // chuc danh nguoi dung
  userTitle: {
    generalManager: 'GENERAL_MANAGER', // tong giam doc
    unitHeads: 'UNIT_HEADS', // thu truong don vi
    manager: 'MANAGER', // giam doc
    chiefAccountant: 'CHIEF_ACCOUNTANT', // ke toan truong
    accountant: 'ACCOUNTANT', // ke toan
  },
  userTitleItems: [
    { name: 'GeneralManager', value: 'GENERAL_MANAGER' },
    { name: 'UnitHeads', value: 'UNIT_HEADS' },
    { name: 'Manager', value: 'MANAGER' },
    { name: 'ChiefAccountant', value: 'CHIEF_ACCOUNTANT' },
    { name: 'Accountant', value: 'ACCOUNTANT' },
  ],

  // loai co cau to chuc
  organizationType: {
    company: 'HQ',
    branch: 'BRANCH',
  },

  // trang thai ncc, khach hang
  supplierStatus: {
    active: 'ACTIVE',
    inactive: 'INACTIVE',
    unknown: 'UNKNOWN',
  },
  supplierStatusItems: [
    { name: 'Active', value: 'ACTIVE' },
    { name: 'Inactive', value: 'INACTIVE' },
    { name: 'Unknown', value: 'UNKNOWN' },
  ],

  supplierTypeItems: [
    { name: 'Supplier', value: 'SELLER' },
    { name: 'Customer', value: 'BUYER' },
    { name: 'SupplierAndCustomer', value: 'SELLER|BUYER' },
  ],

  // gop y, khuyen nghi
  suggestionAndRecommendationItems: [
    { text: 'Do chưa thiết lập lấy hóa đơn từ bảng tổng hợp.', url: '#' },
    { text: 'Do đã thiết lập không lấy hóa đơn từ 1 nhà cung cấp cụ thể về.', url: '#' },
    {
      text: 'Do đã thiết lập hóa đơn của nhà cung cấp đó chuyển về địa chỉ email khác trong cơ cấu tổ chức.',
      url: '#',
    },
    { text: 'Chưa thiết lập lấy đủ các loại hóa đơn.', url: '#' },
  ],

  // cap do tai khoan trong cong ty
  accountLevel: {
    admin: 'COMPANY_ADMIN',
    user: 'COMPANY_USER',
  },
  accountLevelItems: [
    { name: 'Admin', value: 'COMPANY_ADMIN' },
    { name: 'User', value: 'COMPANY_USER' },
  ],

  // loai thong bao
  notificationType: {
    activity: 'ACTIVITY',
    system: 'SYSTEM',
    promotion: 'PROMOTION',
  },
  notificationTypeItems: [
    { name: 'NotificationActivity', value: 'ACTIVITY' },
    { name: 'NotificationSystem', value: 'SYSTEM' },
    { name: 'NotificationPromotion', value: 'PROMOTION' },
  ],

  // gioi tinh
  gender: {
    male: 'MALE',
    female: 'FEMALE',
    other: 'OTHER',
  },
  genderItems: [
    { name: 'Male', value: 'MALE' },
    { name: 'Female', value: 'FEMALE' },
    { name: 'Other', value: 'OTHER' },
  ],

  // supplier type
  supplierType: {
    invoice: 'SUPPLIER_E_INVOICE',
    service: 'SUPPLIER_SERVICE',
  },

  // package type
  packageType: {
    quantity: 'QUANTITY',
    duration: 'DURATION',
    license: 'LICENSE',
  },
  packageTypeItems: [
    {
      name: 'Quantity',
      value: 'QUANTITY',
    },
    {
      name: 'Duration',
      value: 'DURATION',
    },
    {
      name: 'License',
      value: 'LICENSE',
    },
  ],

  // package unit
  packageUnit: {
    vnd: 'VND',
    usd: 'USD',
  },
  packageUnitItems: [
    { name: 'VND', value: 'VND' },
    { name: 'USD', value: 'USD' },
  ],

  paymentType: {
    bankTransfer: 'TRANSFER',
    vnpay: 'VNPAY',
  },
  paymentTypeItems: [
    { name: 'PaymentTypeTransfer', value: 'TRANSFER' },
    { name: 'PaymentTypeVnpay', value: 'VNPAY' },
  ],

  paymentStatus: {
    pending: 'PENDING',
    completed: 'COMPLETE',
    canceled: 'CANCEL',
    failed: 'FAILED',
  },
  paymentStatusItems: [
    { name: 'PaymentPending', value: 'PENDING' },
    { name: 'PaymentCompleted', value: 'COMPLETE' },
    { name: 'PaymentCanceled', value: 'CANCEL' },
    { name: 'PaymentFailed', value: 'FAILED' },
  ],

  // danh sach san pham icorp
  icorpProducts: [
    {
      image: AppResource.images.imgIcorpEcontract,
      url: 'https://hopdongdientu.vn/',
    },
    {
      image: AppResource.images.imgIcorpEkyc,
      url: 'https://icorp.vn/san-pham/xac-thuc-dinh-danh-dien-tu-ekyc',
    },
    {
      image: AppResource.images.imgIcorpHsm,
      url: 'https://icorp.vn/san-pham/chu-ky-so-hsm',
    },
    {
      image: AppResource.images.imgIcorpIbh,
      url: 'https://ibh.ivan.vn/',
    },
    {
      image: AppResource.images.imgIcorpIca,
      url: 'https://i-ca.vn/',
    },
  ],

  // permission codes
  permissionCode: {
    hddv: {
      xoaVaKhoiPhuc: 'DELETE_AND_RESTORE_INPUT_INVOICE',
      danhDauVaHachToan: 'ACCOUNTED_MARK_INPUT_INVOICE',
      danhDauVaThanhToan: 'PAID_MARK_INPUT_INVOICE',
      dieuChuyen: 'TRANSFER_INPUT_INVOICE',
    },
    hddr: {
      xoaVaKhoiPhuc: 'DELETE_AND_RESTORE_OUTPUT_INVOICE',
      dieuChuyen: 'TRANSFER_OUTPUT_INVOICE',
    },
    hopThu: {
      xoaVaKhoiPhuc: 'DELETE_AND_RESTORE_MAILBOX',
    },
    coCauToChuc: {
      them: 'CREATE_ORGANIZATION_STRUCTURE',
      sua: 'UPDATE_ORGANIZATION_STRUCTURE',
      xoa: 'DELETE_ORGANIZATION_STRUCTURE',
    },
    chiTietHoaDon: {
      chapNhanThongTinNguoiMuaBan: 'ACCEPT_BUYER_SELLER_INFO',
      chapNhanThongTinCKS: 'ACCEPT_DIGITAL_SIGNATURE_INFO',
      chapNhanTinhTrangHoatDongVaRuiRoNguoiBan: 'ACCEPT_STATE_ACTIVE_AND_RISK_SELLER',
      khongKiemTraTonTaiTrenCQT: 'NOT_CHECK_EXIST_IN_TAX_AGENCY',
    },
    tuDongKetNoi: {
      cqt: 'TAX_ORGANIZATION',
      nccHoaDon: 'INVOICE_SUPPLIER',
    },
  },

  mailStatus: {
    validInvoice: 'VALID_INVOICE',
    invalidEmail: 'INVALID_EMAIL',
    invalidInvoice: 'INVALID_INVOICE',
    duplicateInvoice: 'DUPLICATE_INVOICE',
    malformedInvoice: 'MALFORMED_INVOICE',
    draft: 'DRAFT',
    checking: 'CHECKING',
    expireDate: 'EXPIRE_DATE',
    deleteInvoice: 'DELETE_INVOICE',
    invoiceNotFound: 'INVOICE_NOT_FOUND',
    outOfDate: 'OUT_OF_DATE',
  },
  mailCheckboxOptions: [
    {
      name: 'Tất cả',
      value: 'ALL',
    },
    {
      name: 'Hóa đơn hợp lệ',
      value: 'VALID_INVOICE',
    },
    {
      name: 'Email không hợp lệ',
      value: 'INVALID_EMAIL',
    },
    {
      name: 'Hóa đơn không hợp lệ',
      value: 'INVALID_INVOICE',
    },
    {
      name: 'Hóa đơn trùng',
      value: 'DUPLICATE_INVOICE',
    },
    {
      name: 'Không đọc được hóa đơn',
      value: 'MALFORMED_INVOICE',
    },
    {
      name: 'Bản nháp',
      value: 'DRAFT',
    },
    {
      name: 'Đang kiểm tra',
      value: 'CHECKING',
    },
    {
      name: 'Hết hạn sử dụng',
      value: 'EXPIRE_DATE',
    },
    {
      name: 'Hóa đơn xóa bỏ',
      value: 'DELETE_INVOICE',
    },
    {
      name: 'Không tìm thấy hoá đơn',
      value: 'INVOICE_NOT_FOUND',
    },
    {
      name: 'Hết tài nguyên sử dụng',
      value: 'OUT_OF_DATE',
    },
  ],

  // trang thai doc thu
  mailReadStatusOptions: [
    { name: 'Unread', value: 'UNREAD' },
    { name: 'Read', value: 'READ' },
  ],

  // loai mail
  mailType: {
    important: 'IMPORTANT',
    trash: 'TRASH',
  },

  // date filter options
  dateFilter: {
    homQua: 'HOM_QUA',
    homNay: 'HOM_NAY',
    tuanTruoc: 'TUAN_TRUOC',
    tuanNay: 'TUAN_NAY',
    thangTruoc: 'THANG_TRUOC',
    thangNay: 'THANG_NAY',
    baMuoiNgayGanNhat: '30_NGAY_GAN_NHAT',
    quyTruoc: 'QUY_TRUOC',
    quyNay: 'QUY_NAY',
    sauThangDauNam: 'SAU_THANG_DAU_NAM',
    sauThangCuoiNam: 'SAU_THANG_CUOI_NAM',
    namTruoc: 'NAM_TRUOC',
    namNay: 'NAM_NAY',
    thang1: 'THANG_1',
    thang2: 'THANG_2',
    thang3: 'THANG_3',
    thang4: 'THANG_4',
    thang5: 'THANG_5',
    thang6: 'THANG_6',
    thang7: 'THANG_7',
    thang8: 'THANG_8',
    thang9: 'THANG_9',
    thang10: 'THANG_10',
    thang11: 'THANG_11',
    thang12: 'THANG_12',
    quy1: 'QUY_1',
    quy2: 'QUY_2',
    quy3: 'QUY_3',
    quy4: 'QUY_4',
    tuyChon: 'TUY_CHON',
  },
  dateFilterOptions: [
    { name: 'Hôm qua', value: 'HOM_QUA' },
    { name: 'Hôm nay', value: 'HOM_NAY' },
    { name: 'Tuần trước', value: 'TUAN_TRUOC' },
    { name: 'Tuần này', value: 'TUAN_NAY' },
    { name: 'Tháng trước', value: 'THANG_TRUOC' },
    { name: 'Tháng này', value: 'THANG_NAY' },
    { name: '30 ngày gần nhất', value: '30_NGAY_GAN_NHAT' },
    { name: 'Quý trước', value: 'QUY_TRUOC' },
    { name: 'Quý này', value: 'QUY_NAY' },
    { name: 'Sáu tháng đầu năm', value: 'SAU_THANG_DAU_NAM' },
    { name: 'Sáu tháng cuối năm', value: 'SAU_THANG_CUOI_NAM' },
    { name: 'Năm trước', value: 'NAM_TRUOC' },
    { name: 'Năm nay', value: 'NAM_NAY' },
    { name: 'Tháng 1', value: 'THANG_1' },
    { name: 'Tháng 2', value: 'THANG_2' },
    { name: 'Tháng 3', value: 'THANG_3' },
    { name: 'Tháng 4', value: 'THANG_4' },
    { name: 'Tháng 5', value: 'THANG_5' },
    { name: 'Tháng 6', value: 'THANG_6' },
    { name: 'Tháng 7', value: 'THANG_7' },
    { name: 'Tháng 8', value: 'THANG_8' },
    { name: 'Tháng 9', value: 'THANG_9' },
    { name: 'Tháng 10', value: 'THANG_10' },
    { name: 'Tháng 11', value: 'THANG_11' },
    { name: 'Tháng 12', value: 'THANG_12' },
    { name: 'Quý 1', value: 'QUY_1' },
    { name: 'Quý 2', value: 'QUY_2' },
    { name: 'Quý 3', value: 'QUY_3' },
    { name: 'Quý 4', value: 'QUY_4' },
    { name: 'Tùy chọn', value: 'TUY_CHON' },
  ],

  chartColors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#4CAF50'],

  serviceType: {
    smart: 'I_SMART',
    pro: 'I_PRO',
  },
  vat: [
    { name: '0%', value: 0 },
    { name: '5%', value: 5 },
    { name: '8%', value: 8 },
    { name: '10%', value: 10 },
    { name: 'KKKNT', value: -1 },
    { name: 'KHAC', value: 'KHAC' },
  ],
};

export default AppData;
