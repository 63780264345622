import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import VIDragAndDrop from 'general/components/VietInvoice/VIAppDragAndDrop';
import VIAppTabs from 'general/components/VietInvoice/VIAppTabs';
import VISelectFilter from 'general/components/VietInvoice/VISelectFilter';
import VIDateRangePickerInput from 'general/components/VietInvoice/VIDateRangePicker';
import VIButton from 'general/components/VietInvoice/VIButton';
import { useTranslation } from 'react-i18next';
import VIOverViewBar from 'general/components/VietInvoice/VIOverViewBar';
import VIModal from 'general/components/VietInvoice/VIModal';
import VIInvoice from 'general/components/VietInvoice/VIInvoice';
import { useState } from 'react';
import VIFormCheck from 'general/components/VietInvoice/VIFormCheck';
import VIElements from 'general/components/VietInvoice/VIElements';
import VIBodyIcons from 'general/components/VietInvoice/VIBodyIcons';
import SupplierInformation from 'features/Supplier/components/SupplierInformation';
import VIDeleteModal from 'general/components/VietInvoice/VIDeleteModal';
import VIInputField from 'general/components/VietInvoice/VIInputField';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import AddLabel from './AddLabel';
import ModalLabeled from 'features/Tag/components/ModalLabeled';

TestComponentDucTuScreen.propTypes = {};

function TestComponentDucTuScreen(props) {
  const data = [
    { name: 'Cơ quan thuế' },
    { name: 'Khách hàng' },
    { name: 'Ngày hóa đơn' },
    { name: 'Số hóa đơn' },
    { name: 'Mã hóa đơn' },
    { name: 'Địa chỉ' },
    { name: 'Tax' },
    { name: 'Ngày kí' },
    { name: 'Nhà cung cấp' },
  ];
  const tabs = [
    'Nhật ký hoạt động',
    'Thành viên',
    'Nhóm vị trí',
    'Vị trí công việc',
    'Cơ cấu tổ chức',
    'Thông tin công ty ',
  ];
  const dataFilter = [
    {
      name: 'Nhật ký hoạt động',
      value: 0,
    },
    {
      name: 'Thành viên',
      value: 1,
    },
    {
      name: 'Nhóm vị trí',
      value: 2,
    },
    {
      name: 'Vị trí công việc',
      value: 3,
    },
    {
      name: 'Cơ cấu tổ chức',
      value: 4,
    },
  ];
  const handleClick = (e) => {
    // console.log(e);
  };
  const handleChangeDate = (e) => {
    console.log(e);
  };

  const headerTab = [
    {
      title: 'Tổng quan',
      status: 'active',
    },
    {
      title: 'Giá và khối lượng',
      status: '',
    },
    {
      title: 'Xếp hạng MoneyGain',
      status: '',
    },
    {
      title: 'Cơ bản',
      status: '',
    },
    {
      title: 'Hiệu suất',
      status: '',
    },
  ];

  const IconArr = [
    {
      icon: <i className="fa-solid fa-download"></i>,
      name: 'Tải xuống',
    },
    {
      icon: <i className="fa-solid fa-pen"></i>,
      name: 'Chỉnh sửa',
    },
    {
      icon: <i className="fa-solid fa-trash"></i>,
      name: 'Xóa',
    },
    {
      icon: <i className="fa-solid fa-share-from-square"></i>,
      name: 'Chia sẻ',
    },
  ];
  const handleClickOverviewBar = (x) => {
    console.log('x', x);
  };

  const handleClickIcon = (x) => {
    console.log('x', x);
  };
  const [filterDate, setFilterDate] = useState();
  console.log('Ngày đã chọn', filterDate);
  const { t } = useTranslation();
  const [showModalInvoices, setShowModalInvoices] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalAddLabel, setShowModalAddLabel] = useState(false);
  const [showModalLabeled, setShowModalLabeled] = useState(false);

  const dataFilters = [
    {
      name: 'USD',
      value: 0,
    },
    {
      name: 'VND',
      value: 1,
    },
  ];

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string('Vui lòng nhập mật khẩu').required('Vui lòng nhập mật khẩu'),
    }),
    onSubmit: async (values) => {},
  });
  return (
    <div>
      <VISelectFilter
        data={dataFilters}
        onChangeValue={(e) => console.log(e)}
        // disabled={true}
      />
      <div>
        <div style={{ display: 'flex', justifyContent: 'end' }} id={'vi-dropDrag'}>
          <KTBSDropdown
            contentEl={
              <VIDragAndDrop
                elements={data}
                onHide={() => document.getElementById('vi-dropDrag').click()}
              />
            }
            toggleElement={
              <div className="topbar-item">
                <div className="btn btn-icon btn-clean btn-lg mr-1">
                  <i className="fas fa-cog"></i>
                </div>
              </div>
            }
            alignment={KTBSDropdownAlignments.end}
            autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
          />
        </div>
        <VIAppTabs
          tabs={tabs}
          // activeTab={selectedTab}
          // handleClick={handleSelectedTab}
          className="flex-grow-1"
        />
        <div className="mt-3 ">
          <VISelectFilter
            data={dataFilter}
            onChangeValue={(e) => console.log(e)}
            // disabled={true}
          />
        </div>
        <div className="mt-3" style={{ width: '300px' }}>
          <VIDateRangePickerInput
            className="mb-2"
            initialLabel="7 ngày gần đây"
            initialEndDate={moment()}
            initialStartDate={moment().subtract(6, 'days')}
            getDateRange={(dateRange) => {
              setFilterDate(
                {
                  startDate: dateRange.startDate,
                  endDate: dateRange.endDate,
                }
                // Utils.getRangeDates({
                // })
              );
              // switch (dateRange.label) {
              //   case 'Tất cả':
              //     setTopFeaturesByTimeFilter({
              //       ...topFeaturesByTimeFilter,
              //       from: '',
              //       to: '',
              //     });
              //     break;
              //   default:
              //     setTopFeaturesByTimeFilter({
              //       ...topFeaturesByTimeFilter,
              //       from: Utils.formatDateTime(dateRange.startDate, 'YYYY-MM-DD'),
              //       to: Utils.formatDateTime(dateRange.endDate, 'YYYY-MM-DD'),
              //     });
              // }
            }}
            customRange={{
              'Hôm qua': [
                moment().subtract(1, 'day').startOf('day'),
                moment().subtract(1, 'day').endOf('day'),
              ],
              'Tuần này': [moment().startOf('week'), moment()],
              '7 ngày gần đây': [moment().subtract(6, 'days'), moment()],
              '30 ngày gần đây': [moment().subtract(29, 'days'), moment()],
              'Tháng trước': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
              'Tháng này': [moment().startOf('month'), moment()],
            }}
          />
        </div>
        {/* xuất hóa đơn */}
        <div style={{ width: '20px' }}>
          <VIButton
            text={t('Invoice')}
            beforeIcon={<i className="fas fa-external-link"></i>}
            className=""
            style={{ padding: '8px' }}
            onClick={() => setShowModalInvoices(true)}
            afterIcon={<></>}
          />
        </div>
        <VIModal
          titleModal={t(`ListOfInputInvoices`)}
          show={showModalInvoices}
          onHide={() => setShowModalInvoices(false)}
          contentModal={<VIInvoice />}
          closeBtn={true}
          handleSubmit={() => {}}
        />
        {/* thanh overview */}
        <VIOverViewBar itemTab={headerTab} handleClick={handleClickOverviewBar} />
        {/* modal dùng chung */}
        <div style={{ width: '20px', marginTop: '30px' }}>
          <VIButton
            text={'Thông tin'}
            className=""
            style={{ padding: '8px' }}
            onClick={() => setShowModal(true)}
          />
        </div>
        <VIModal
          titleModal="Thông tin nhà cung cấp/khách hàng"
          show={showModal}
          onHide={() => setShowModal(false)}
          contentModal={<SupplierInformation />}
          closeBtn={true}
          questionBtn={true}
          handleSubmit={() => {}}
        />
        thêm nhãn mới
        <div style={{ width: '20px', marginTop: '30px' }}>
          <VIButton
            text={'Thêm nhãn'}
            className=""
            style={{ padding: '8px' }}
            onClick={() => setShowModalAddLabel(true)}
          />
        </div>
        <VIModal
          titleModal="Thêm nhãn mới"
          show={showModalAddLabel}
          onHide={() => setShowModalAddLabel(false)}
          contentModal={<AddLabel />}
          closeBtn={true}
          questionBtn={true}
          handleSubmit={() => {}}
        />
        {/* * */}
        {/* gán nhãn */}
        <div style={{ width: '20px', marginTop: '30px' }}>
          <VIButton
            text={'gán nhãn'}
            className=""
            style={{ padding: '8px' }}
            onClick={() => setShowModalLabeled(true)}
          />
        </div>
        <VIModal
          titleModal="Gán nhãn"
          show={showModalLabeled}
          onHide={() => setShowModalLabeled(false)}
          contentModal={<ModalLabeled />}
          closeBtn={true}
          questionBtn={true}
          handleSubmit={() => {}}
          textButton={'Thêm nhãn'}
          onClickButtonAdd={() => {
            setShowModalAddLabel(true);
          }}
        />
        {/* * */}
        {/* delete */}
        <div style={{ width: '20px', marginTop: '30px' }}>
          <VIButton
            text={'Xóa'}
            className=""
            style={{ padding: '8px' }}
            beforeIcon={<i className="fa-solid fa-trash-can"></i>}
            onClick={() => setShowModalDelete(true)}
          />

          <VIDeleteModal
            show={showModalDelete}
            onClose={() => setShowModalDelete(false)}
            deleteTitle={'Xóa hóa đơn'}
            deleteText={'Bạn có chắc muốn xóa hóa đơn này không'}
            deleteItem={() => {}}
          />
        </div>
        {/* switch */}
        <div>
          <VIFormCheck type={'switch'} handleChange={() => {}} checkedItem={true} />
        </div>
        <VIElements
          id={'1'}
          text="HĐ không hợp lệ"
          style={{
            color: '#EE2334',
            backgroundColor: 'rgba(255, 87, 87, 0.10)',
            padding: '4px 8px',
            border: 'none',
          }}
          icon={<i className="fas fa-times-circle" style={{ color: '#EE2334' }}></i>}
        />
        <div className="mt-4">
          <VIElements
            id={'2'}
            text="Hóa đơn hợp lệ"
            style={{
              color: '#20A144',
              background: 'rgba(40, 160, 67, 0.1)',
              padding: '4px 8px',
              border: 'none',
            }}
            icon={<i className="fas fa-check-circle" style={{ color: '#20A144' }}></i>}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '10px' }} id="body-icons">
          <KTBSDropdown
            contentEl={
              <VIBodyIcons
                IconArr={IconArr}
                onClick={(x) => {
                  handleClickIcon(x), document.getElementById('body-icons').click();
                }}
                id={'100'}
              />
            }
            toggleElement={
              <div className="topbar-item">
                <div className="btn btn-icon btn-clean btn-lg mr-1">
                  <i className="fa-regular fa-ellipsis-vertical"></i>
                </div>
              </div>
            }
            alignment={KTBSDropdownAlignments.end}
            autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
          />
        </div>
        {/* input password */}
        <VIInputField
          className="w-50 mb-3"
          label="Nhập mật khẩu"
          required
          placeholder="Nhập mã đơn vị"
          password
          fieldHelper={formik.getFieldHelpers('password')}
          fieldProps={formik.getFieldProps('password')}
          fieldMeta={formik.getFieldMeta('password')}
        />
      </div>
    </div>
  );
}

export default TestComponentDucTuScreen;
