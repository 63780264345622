// api/axiosClient.js
import axios from 'axios';
import AppConfigs from 'general/constants/AppConfigs';
import AccountHelper from 'general/helpers/AccountHelper';
import LogHelper from 'general/helpers/LogHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import queryString from 'query-string';

const sTag = '[AxiosClient]';

// Cai dat config mac dinh cho http request
// Tham khao: `https://github.com/axios/axios#request-config`
// de xem chi tiet
const axiosClient = axios.create({
  baseURL: AppConfigs.apiBaseUrl,
  headers: {
    'content-type': 'application/json',
    'Accept-Encoding': 'gzip, deflate, br',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // LogHelper.log(`${sTag} - ${config.baseURL}${config.url}, ${config.method}, ${config.method === 'post' ? JSON.stringify(config.data) : JSON.stringify(config.params)}`);
  // LogHelper.log(`${sTag} - headers: ${JSON.stringify(config.headers.common)}`);
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  (error) => {
    LogHelper.log(`${sTag} - ${error}`);
    let errorMessage = null;
    const response = error.response;
    if (response && (response.status === 403 || response.status === 401)) {
      AccountHelper.signOut();
      Global.gUnauthorized = true;
      // window.location.href = '/sign-in';
      return;
    }
    if (response && response.data) {
      const data = response.data;
      const { result, reason, detail } = data;
      if (result === 'failed') {
        if (reason) {
          errorMessage = reason;
        } else if (detail) {
          errorMessage = detail;
        }
      }
    }
    if (errorMessage) {
      ToastHelper.showError(errorMessage);
    }
    throw error;
  }
);

// Update base url
const updateAxiosBaseURL = (baseUrl) => {
  axiosClient.defaults.baseURL = baseUrl;
};

// Update access token
const updateAxiosAccessToken = (accessToken) => {
  axiosClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
};

// Remove access token
const removeAxiosAccessToken = () => {
  delete axiosClient.defaults.headers.common['Authorization'];
};

(() => {
  const isTokenValid = AccountHelper.checkAccessTokenValid();
  if (isTokenValid) {
    const token = AccountHelper.getAccessToken();
    updateAxiosAccessToken(token);
  }
})();

export { removeAxiosAccessToken, updateAxiosAccessToken, updateAxiosBaseURL };

export default axiosClient;
