import VIInputField from 'general/components/VietInvoice/VIInputField';
import React from 'react';

function ContactInfo() {
  return (
    <div>
      <div className="row mt-5">
        <VIInputField
          className="col-6 w-100 mb-4"
          label="Tên người liên hệ"
          placeholder="Nhập tên người liên hệ"
        />
        <VIInputField className="col-6 w-100 mb-4" label="Email" placeholder="Nhập email" />
      </div>
      <div className="row ">
        <VIInputField
          className="col-6 w-100 mb-4"
          label="Số điện thoại"
          placeholder="Nhập số điện thoại"
          type="number"
        />
        <VIInputField className="col-6 w-100 mb-4" label="Chức vụ" placeholder="Nhập chức vụ" />
      </div>
    </div>
  );
}

export default ContactInfo;
