import axiosClient from './axiosClient';

const mailApi = {
  // lay ds thu
  getMailList: (params) => {
    // console.log({ params });
    const copiedValues = {
      ...params,
      statusValidMail: params.statusValidMail === 'ALL' ? '' : params.statusValidMail,
    };
    const url = '/mail/find';
    return axiosClient.get(url, { params: copiedValues });
  },

  // danh dau thu quan trong
  setImportant: (ids) => {
    const url = '/mail/set-important';
    const params = {
      mailIds: ids,
    };
    return axiosClient.put(url, params);
  },

  // danh dau thu khong quan trong
  setUnImportant: (ids) => {
    const url = '/mail/set-unimportant';
    const params = {
      mailIds: ids,
    };
    return axiosClient.put(url, params);
  },

  // danh dau thu chua doc
  setUnread: (ids) => {
    const url = '/mail/mark-unread';
    const params = {
      mailIds: ids,
    };
    return axiosClient.put(url, params);
  },

  // danh dau thu da doc
  setRead: (ids) => {
    const url = '/mail/mark-read';
    const params = {
      mailIds: ids,
    };
    return axiosClient.put(url, params);
  },

  // xoa mail
  deleteMails: (ids) => {
    const url = '/mail/delete';
    return axiosClient.delete(url, {
      data: {
        mailIds: ids,
      },
    });
  },

  // chuyen mail vao thung rac
  moveIntoTrash: (ids) => {
    const url = '/mail/into-trash';
    const params = {
      mailIds: ids,
    };
    return axiosClient.put(url, params);
  },

  // chuyen mail ra khoi thung rac
  restoreFromTrash: (ids) => {
    const url = '/mail/out-trash';
    const params = {
      mailIds: ids,
    };
    return axiosClient.put(url, params);
  },

  // lay chi tiet mail
  getDetail: (id) => {
    const url = `/mail/detail/${id}`;
    return axiosClient.get(url);
  },

  // chuyen tiep mail
  forwardMail: (id, params) => {
    const url = `/mail/forward/${id}`;
    return axiosClient.post(url, params);
  },

  // kiem tra mail
  validateMails: (ids) => {
    const url = '/mail/validate';
    const params = {
      mailIds: ids,
    };
    return axiosClient.post(url, params);
  },
};

export default mailApi;
