import { Modal, Table } from 'react-bootstrap';
import './style.scss';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Utils from 'general/utils/Utils';
import ListOfOccupations from 'features/Mail/screens/component/ListOfOccupations';
import VIModal from 'general/components/VietInvoice/VIModal';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIInputField from 'general/components/VietInvoice/VIInputField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import miscApi from 'api/miscApi';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';

import { useSelector } from 'react-redux';
import invoiceApi from 'api/invoiceApi';
import AppData from 'general/constants/AppData';
import KeenSelectOption from 'general/components/OtherKeenComponents/KeenSelectOption';
import Global from 'general/utils/Global';
import { useDispatch } from 'react-redux';
import { thunkGetAllInInvoice, thunkGetAllOutInvoice } from 'features/Invoice/invoiceSlice';
import CurrencyInput from 'react-currency-input-field';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import VIDropdownPosition from 'general/components/VietInvoice/VIDropdownPosition';
import ToastHelper from 'general/helpers/ToastHelper';

const ManualDataEntry = (props) => {
  const { t } = useTranslation();

  const {
    show,
    onHide,
    currentImage = '',
    setLoadingInput,
    setErrorFile,
    errorFile,
    setSuccessFile,
    successFile,
    duplicateFile,
    errorInvoiceFile,
    setErrorInvoiceFile,
    setDuplicateFile,
    currentEdit,
    text,
    invoice = '',
  } = props;
  const [sizes, setSizes] = useState(['40%', '60%']);
  const [showListOfOccupations, setShowListOfOccupations] = useState(false);
  const Organization = useSelector((state) => state?.auth?.account?.Organization);
  const dispatch = useDispatch();
  const [dataSaveDrat, setDataSaveDart] = useState();

  const formik = useFormik({
    initialValues: {
      sellerTaxCode: currentEdit?.sellerTaxCode ?? '',
      sellerName: currentEdit?.sellerName ?? '',
      sellerAddress: currentEdit?.sellerAddress ?? '',
      buyerName: Organization?.organizationName ?? '',
      buyerTaxCode: Organization?.taxCode ?? '',
      buyerAddress: Organization?.businessPermitAddress ?? '',
      serial: currentEdit?.serial ?? '',
      invoiceTypeCode: currentEdit?.invoiceTypeCode ?? '',
      invoiceNumber: currentEdit?.invoiceNumber ?? '',
      statusInvoice: currentEdit?.statusInvoice ?? '',
      invoiceDate: currentEdit?.invoiceDate ?? new Date().toString(),
      InvoiceProducts: [
        {
          id: 0,
          name: '',
          unit: '',
          quantity: 1,
          price: 0,
          discount: 0,
          discountAmount: 0,
          vat: 'KCT',
          vatAmount: 0,
          intoMoney: 0,
          finalAmount: 0,
        },
      ],
      amountBeforeVat: 0,
      amountVat: 0,
      finalAmount: 0,
      imgFile: currentImage?.type?.split('/').shift() === 'image' ? currentImage : '',
      pdfFile: currentImage?.type?.split('/').pop() === 'pdf' ? currentImage : '',
      xmlFile: currentImage?.type?.split('/').pop() === 'xml' ? currentImage : '',
    },

    validationSchema: Yup.object({
      sellerTaxCode: Yup.mixed()
        .required(t('EnterTaxCode'))
        .nullable()
        .test('len', t('InvalidTaxCode'), (val) => {
          if (val) {
            return (
              !val.toString().includes(' ') &&
              (val.toString().match(/^[0-9]{10}$/) || val.toString().match(/^[0-9]{10}-[0-9]{3}$/))
            );
          }
        }),
      sellerName: Yup.mixed().required(t('EnterNameUnit')),
      sellerAddress: Yup.mixed().required(t('EnterUnit')),
      buyerTaxCode: Yup.string()
        .required(t('EnterTaxCode'))
        .nullable()
        .test('len', t('InvalidTaxCode'), (val) => {
          if (val) {
            return (
              !val.toString().includes(' ') &&
              (val.toString().match(/^[0-9]{10}$/) || val.toString().match(/^[0-9]{10}-[0-9]{3}$/))
            );
          }
        }),
      buyerName: Yup.mixed().required(t('EnterNameUnit')),
      buyerAddress: Yup.mixed().required(t('EnterUnit')),
      serial: Yup.mixed().required(t('EnterSymbol')),
      invoiceTypeCode: Yup.mixed().required(t('EnterDenmominator')),
      invoiceNumber: Yup.mixed().required(t('EnterNumberInvoice')),
      invoiceDate: Yup.mixed().required(t('')),

      InvoiceProducts: Yup.array().of(
        Yup.object().shape({
          name: Yup.mixed().required(t('EnterNameOfGoodsServices')),
          quantity: Yup.number().test('len', t('Nhập vào số > 0'), (val) => {
            if (val || val === 0 || val === undefined) {
              return val >= 0 || val === undefined;
            }
          }),
          price: Yup.number().test('len', t('Nhập vào số > 0'), (val) => {
            if (val || val === 0 || val === undefined) {
              return val >= 0 || val === undefined;
            }
          }),
          discount: Yup.number().test('len', t('Nhập vào số > 0'), (val) => {
            if (val || val === 0 || val === undefined) {
              return val >= 0 || val === undefined;
            }
          }),
          discountAmount: Yup.number().test('len', t('Nhập vào số > 0'), (val) => {
            if (val || val === 0 || val === undefined) {
              return val >= 0 || val === undefined;
            }
          }),
        })
      ),
    }),

    onSubmit: async (values, { resetForm }) => {
      values.statusInvoice = dataSaveDrat === 'save' ? 8 : '';
      const formData = new FormData();
      if (text !== 'edit') {
        for (const [key, value] of Object.entries(values)) {
          if (key === 'imgFile') {
            for (let i = 0; i < values?.imgFile?.length; i++) {
              formData.append(key, values?.imgFile?.[i]);
            }
          }
          if (key === 'pdfFile') {
            for (let i = 0; i < values?.pdfFile?.length; i++) {
              formData.append(key, values?.pdfFile?.[i]);
            }
          }
          if (key === 'xmlFile') {
            for (let i = 0; i < values?.xmlFile?.length; i++) {
              formData.append(key, values?.xmlFile?.[i]);
            }
          }
          if (key === 'InvoiceProducts') {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        }

        setErrorFile(errorFile?.filter((item) => item?.name !== currentImage?.name));
      }
      try {
        onHide();
        if (text === 'edit') {
          const result = await invoiceApi.updateInvoiceManual(currentEdit?.invoiceId, values);
          if (result?.result === 'success') {
            dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
            dispatch(thunkGetAllOutInvoice(Global.gFiltersOutputInvoice));
            if (dataSaveDrat === 'save') {
              ToastHelper.showSuccess('Lưu nháp thành công');
            } else {
              ToastHelper.showSuccess('Sửa hóa đơn thành công');
            }
          }
        } else {
          setLoadingInput(true);
          const res = await invoiceApi.createInvoiceManual(formData);
          if (res?.result === 'success') {
            dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
            dispatch(thunkGetAllOutInvoice(Global.gFiltersOutputInvoice));

            setSuccessFile([...successFile, res]);
            setLoadingInput(false);
          }
          if (res?.result === 'failed' && res?.duplicateInvoiceId) {
            dispatch(thunkGetAllOutInvoice(Global.gFiltersOutputInvoice));
            dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
            setDuplicateFile([...duplicateFile, res]);
            setLoadingInput(false);
          }
          if (res?.result === 'failed' && res?.reason) {
            dispatch(thunkGetAllOutInvoice(Global.gFiltersOutputInvoice));
            dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
            setErrorInvoiceFile([...errorInvoiceFile, res]);
            setLoadingInput(false);
          }
        }
        resetForm();
      } catch (error) {
        const dataError = errorFile.filter((item) => item?.name !== currentImage?.name);
        setLoadingInput(false);
        setErrorFile([...dataError, error?.response?.data]);
      }
    },
  });
  const imageInputValue = useMemo(() => {
    if (currentImage) {
      return URL?.createObjectURL(currentImage ?? '');
    }
  }, [currentImage, show]);
  console.log('currentImage', currentImage);
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      amountBeforeVat: formik.values.InvoiceProducts?.reduce(
        (totals, i) =>
          parseFloat(i?.price ? i?.price : 0) * parseFloat(i?.quantity ? i?.quantity : 0) -
          parseFloat(i?.discountAmount ? i?.discountAmount : 0) +
          totals,
        0
      ),
      amountVat: formik.values.InvoiceProducts?.reduce(
        (totals, i) => parseFloat(i?.vatAmount ? i?.vatAmount : 0) + totals,
        0
      ),
      finalAmount:
        formik.values.InvoiceProducts?.reduce(
          (totals, i) =>
            parseFloat(i?.price ? i?.price : 0) * parseFloat(i?.quantity ? i?.quantity : 0) +
            totals,
          0
        ) +
        formik.values.InvoiceProducts?.reduce(
          (totals, i) => parseFloat(i?.vatAmount ? i?.vatAmount : 0) + totals,
          0
        ) -
        formik.values.InvoiceProducts?.reduce(
          (totals, i) => parseFloat(i?.discountAmount ? i?.discountAmount : 0) + totals,
          0
        ),
    });
  }, [formik?.values.InvoiceProducts]);
  useEffect(() => {
    if (Organization) {
      formik.setValues({
        ...formik.values,
        buyerName: Organization?.organizationName,
        buyerTaxCode: Organization.taxCode,
        buyerAddress: Organization.businessPermitAddress,
      });
    }
  }, [Organization, currentImage]);
  useEffect(() => {
    if (currentImage) {
      formik.setValues({
        ...formik.values,
        imgFile: currentImage?.type?.split('/').shift() === 'image' ? currentImage : '',
        pdfFile: currentImage?.type?.split('/').pop() === 'pdf' ? currentImage : '',
        xmlFile: currentImage?.type?.split('/').pop() === 'xml' ? currentImage : '',
      });
    }
  }, [currentImage]);
  useEffect(() => {
    if (currentEdit) {
      formik.setValues({
        ...formik.values,
        sellerTaxCode: currentEdit?.sellerTaxCode ?? '',
        sellerName: currentEdit?.sellerName ?? '',
        sellerAddress: currentEdit?.sellerAddress ?? '',
        buyerName: Organization ? Organization?.organizationName : '',
        buyerTaxCode: Organization ? Organization.taxCode : '',
        buyerAddress: Organization ? Organization.businessPermitAddress : '',
        serial: currentEdit?.serial ?? '',
        invoiceTypeCode: currentEdit?.invoiceTypeCode ?? '',
        invoiceNumber: currentEdit?.invoiceNumber ?? '',
        statusInvoice: currentEdit?.statusInvoice ?? '',
        invoiceDate: currentEdit?.invoiceDate ?? new Date().toString(),
        InvoiceProducts: currentEdit?.InvoiceProducts ?? [
          {
            id: 0,
            name: '',
            unit: '',
            quantity: 1,
            price: 0,
            discount: 0,
            discountAmount: 0,
            vat: 'KCT',
            vatAmount: 0,
            intoMoney: 0,
            finalAmount: 0,
          },
        ],
        amountBeforeVat: currentEdit?.amountBeforeVat ?? 0,
        amountVat: currentEdit?.amountVat ?? 0,
        finalAmount: currentEdit?.finalAmount ?? 0,
      });
    }
  }, [currentEdit]);

  return (
    <Modal show={show} onHide={onHide} centered size="xl" className="ManualDataEntry">
      <Modal.Body className="p-0">
        <div style={{ height: '100vh' }} className="pdf-default">
          <SplitPane split="vertical" sizes={sizes} onChange={(e) => setSizes(e)}>
            <Pane minSize={'400px'}>
              {currentEdit ? (
                <div className="position-relative h-100">
                  {currentEdit?.imageFile ? (
                    <img src={Utils.getFullUrl(currentEdit?.imageFile)} style={{ width: '100%' }} />
                  ) : (
                    <div className="position-relative h-100">
                      <iframe
                        src={Utils.getFullUrl(currentEdit?.pdfFile ?? currentEdit?.previewPdfFile)}
                        className="w-100 h-100"
                        type="application/pdf"
                      ></iframe>
                      <div
                        className="position-absolute"
                        style={{ top: '50px', height: '80%', width: '5%', right: '0' }}
                      ></div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="position-relative h-100">
                  {currentImage?.type?.split('/').shift() === 'image' ? (
                    <img src={imageInputValue} style={{ width: '100%' }} />
                  ) : (
                    <div className="position-relative h-100">
                      <iframe
                        src={imageInputValue}
                        className="w-100 h-100"
                        type="application/pdf"
                      ></iframe>
                      <div
                        className="position-absolute"
                        style={{ top: '50px', height: '80%', width: '5%', right: '0' }}
                      ></div>
                    </div>
                  )}
                </div>
              )}
            </Pane>

            <Pane maxSize="100%" minSize={'500px'}>
              <div style={{ background: '#fff', overflow: 'auto' }} className="border-left h-100">
                {/* title */}
                <div
                  className="d-flex justify-content-between align-items-center px-5 py-2"
                  style={{
                    borderBottom: '1px solid var(--system-stroke, #E8E9EB)',
                    minWidth: '360px',
                  }}
                >
                  <span>Thông tin và kết quả kiểm tra theo file XML của hóa đơn</span>
                  <VIButton
                    text={<i className="fa-regular fa-xmark d-flex align-items-center"></i>}
                    className="btn-grey px-2"
                    onClick={() => {
                      onHide(), formik.handleReset();
                    }}
                  />
                </div>
                {/* body */}
                <div
                  style={{
                    maxHeight: `calc((100vh - ${110}px))`,
                    minHeight: `calc((100vh - ${110}px))`,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    minWidth: '360px',
                  }}
                >
                  <div className="d-flex w-100 justify-content-between flex-wrap">
                    <div className="py-4 px-6 flex-grow-1" style={{ minWidth: '360px' }}>
                      <div className="">
                        <div className="py-3">
                          <i className="fa-solid fa-bag-shopping text-primary me-3"></i>
                          <span className="text-primary font-weight-bold font-size-lg">
                            {t('SalesUnit')}
                          </span>
                        </div>
                        <div className="py-4">
                          <div className="d-flex">
                            <div className="w-50 me-3">
                              <div className="position-relative">
                                <VIInputField
                                  className="w-100 mb-3"
                                  label={t('TaxCode')}
                                  name="taxCode"
                                  required
                                  placeholder=""
                                  iconSearch={true}
                                  id={21}
                                  value={Utils.formatNumber(formik.values.sellerTaxCode)}
                                  fieldHelper={formik.getFieldHelpers('sellerTaxCode')}
                                  fieldProps={formik.getFieldProps('sellerTaxCode')}
                                  fieldMeta={formik.getFieldMeta('sellerTaxCode')}
                                  onKeyDown={async (e) => {
                                    if (e.keyCode === 13) {
                                      if (
                                        (e?.target.value !== '' &&
                                          Utils.checkTaxCodes(e?.target.value)) ||
                                        (e?.target.value !== '' &&
                                          Utils.checkTaxCode(e?.target.value))
                                      ) {
                                        try {
                                          const res = await miscApi.getCompanyInfoByTaxCode(
                                            e?.target.value
                                          );
                                          const { result, company } = res;
                                          if (result === 'success' && company) {
                                            const { companyName, address } = company;

                                            formik.setValues({
                                              ...formik.values,
                                              sellerName: companyName,
                                              sellerAddress: address,
                                            });
                                          }
                                        } catch (error) {
                                          console.log(error);
                                        }
                                      }
                                    }
                                  }}
                                />
                                <i
                                  className="fa-regular fa-magnifying-glass position-absolute cursor-pointer"
                                  style={{ top: '35px', right: '13px' }}
                                  onClick={async () => {
                                    if (
                                      (formik.values.sellerTaxCode !== '' &&
                                        Utils.checkTaxCodes(formik.values.sellerTaxCode)) ||
                                      (formik.values.sellerTaxCode !== '' &&
                                        Utils.checkTaxCode(formik.values.sellerTaxCode))
                                    ) {
                                      try {
                                        const res = await miscApi.getCompanyInfoByTaxCode(
                                          formik.values.sellerTaxCode
                                        );
                                        const { result, company } = res;
                                        if (result === 'success' && company) {
                                          const { companyName, address } = company;

                                          formik.setValues({
                                            ...formik.values,
                                            sellerName: companyName,
                                            sellerAddress: address,
                                          });
                                        }
                                      } catch (error) {
                                        console.log(error);
                                      }
                                    }
                                  }}
                                ></i>
                              </div>
                            </div>
                            <VIInputField
                              className="w-50 mb-3"
                              label={t('UnitName')}
                              name="tên đơn vị"
                              required
                              placeholder=""
                              id={5}
                              value={Utils.formatNumber(formik.values.sellerName)}
                              fieldHelper={formik.getFieldHelpers('sellerName')}
                              fieldProps={formik.getFieldProps('sellerName')}
                              fieldMeta={formik.getFieldMeta('sellerName')}
                            />
                          </div>
                          <VIInputField
                            className="w-100"
                            label={t('Unit')}
                            name="đơn vị"
                            required
                            placeholder=""
                            id={6}
                            value={Utils.formatNumber(formik.values.sellerAddress)}
                            fieldHelper={formik.getFieldHelpers('sellerAddress')}
                            fieldProps={formik.getFieldProps('sellerAddress')}
                            fieldMeta={formik.getFieldMeta('sellerAddress')}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="py-3">
                          <i className="fa-solid fa-cart-shopping text-primary me-3"></i>
                          <span className="text-primary font-weight-bold font-size-lg">
                            {t('PurchaseUnit')}
                          </span>
                        </div>
                        <div className="py-4">
                          <div className="d-flex">
                            <div className="w-50 me-3">
                              <div className="position-relative">
                                <VIInputField
                                  className="w-100 mb-3"
                                  label={t('TaxCode')}
                                  required
                                  placeholder=""
                                  iconSearch={true}
                                  id={20}
                                  value={Utils.formatNumber(formik.values.buyerTaxCode)}
                                  fieldHelper={formik.getFieldHelpers('buyerTaxCode')}
                                  fieldProps={formik.getFieldProps('buyerTaxCode')}
                                  fieldMeta={formik.getFieldMeta('buyerTaxCode')}
                                  onKeyDown={async (e) => {
                                    if (e.keyCode === 13) {
                                      if (
                                        (e?.target.value !== '' &&
                                          Utils.checkTaxCodes(e?.target.value)) ||
                                        (e?.target.value !== '' &&
                                          Utils.checkTaxCode(e?.target.value))
                                      ) {
                                        try {
                                          const res = await miscApi.getCompanyInfoByTaxCode(
                                            e?.target.value
                                          );
                                          const { result, company } = res;
                                          if (result === 'success' && company) {
                                            const { companyName, address } = company;

                                            formik.setValues({
                                              ...formik.values,
                                              buyerName: companyName,
                                              buyerAddress: address,
                                            });
                                          }
                                        } catch (error) {
                                          console.log(error);
                                        }
                                      }
                                    }
                                  }}
                                />
                                <i
                                  className="fa-regular fa-magnifying-glass position-absolute cursor-pointer"
                                  style={{ top: '35px', right: '13px' }}
                                  onClick={async () => {
                                    if (
                                      (formik.values.buyerTaxCode !== '' &&
                                        Utils.checkTaxCodes(formik.values.buyerTaxCode)) ||
                                      (formik.values.buyerTaxCode !== '' &&
                                        Utils.checkTaxCode(formik.values.buyerTaxCode))
                                    ) {
                                      try {
                                        const res = await miscApi.getCompanyInfoByTaxCode(
                                          formik.values.buyerTaxCode
                                        );
                                        const { result, company } = res;
                                        if (result === 'success' && company) {
                                          const { companyName, address } = company;

                                          formik.setValues({
                                            ...formik.values,
                                            buyerName: companyName,
                                            buyerAddress: address,
                                          });
                                        }
                                      } catch (error) {
                                        console.log(error);
                                      }
                                    }
                                  }}
                                ></i>
                              </div>
                            </div>
                            <VIInputField
                              className="w-50 mb-3"
                              label={t('UnitName')}
                              required
                              placeholder=""
                              id={3}
                              value={Utils.formatNumber(formik.values.buyerName)}
                              fieldHelper={formik.getFieldHelpers('buyerName')}
                              fieldProps={formik.getFieldProps('buyerName')}
                              fieldMeta={formik.getFieldMeta('buyerName')}
                            />
                          </div>
                          <VIInputField
                            className="w-100"
                            label={t('Unit')}
                            required
                            placeholder=""
                            id={4}
                            value={Utils.formatNumber(formik.values.buyerAddress)}
                            fieldHelper={formik.getFieldHelpers('buyerAddress')}
                            fieldProps={formik.getFieldProps('buyerAddress')}
                            fieldMeta={formik.getFieldMeta('buyerAddress')}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="p-6 justify-items-center"
                      style={{ minWidth: '360px', margin: '0 auto' }}
                    >
                      <div className="d-flex justify-content-center my-7">
                        <div
                          className="p-6"
                          style={{
                            border: '1px solid var(--system-stroke, #E8E9EB)',
                            borderRadius: '8px',
                            width: '100%',
                          }}
                        >
                          <VIInputField
                            className="w-100"
                            label={t('Denominator')}
                            required
                            placeholder=""
                            id={11}
                            value={Utils.formatNumber(formik.values.invoiceTypeCode)}
                            fieldHelper={formik.getFieldHelpers('invoiceTypeCode')}
                            fieldProps={formik.getFieldProps('invoiceTypeCode')}
                            fieldMeta={formik.getFieldMeta('invoiceTypeCode')}
                          />
                          <VIInputField
                            className="w-100 mt-3"
                            label={t('Symbol')}
                            required
                            placeholder=""
                            id={12}
                            value={Utils.formatNumber(formik.values.serial)}
                            fieldHelper={formik.getFieldHelpers('serial')}
                            fieldProps={formik.getFieldProps('serial')}
                            fieldMeta={formik.getFieldMeta('serial')}
                          />
                          <VIInputField
                            className="w-100 mt-3"
                            label={t('InvoiceNumber')}
                            required
                            placeholder=""
                            id={13}
                            value={Utils.formatNumber(formik.values.invoiceNumber)}
                            fieldHelper={formik.getFieldHelpers('invoiceNumber')}
                            fieldProps={formik.getFieldProps('invoiceNumber')}
                            fieldMeta={formik.getFieldMeta('invoiceNumber')}
                          />
                          <p className={`mb-1 font-weight-bold mt-3`}>
                            {t('BillDate')}

                            <sup className="font-weight-boldest ms-1" style={{ color: '#F44141' }}>
                              *
                            </sup>
                          </p>
                          <KTFormGroup
                            additionalClassName=" mb-3"
                            inputName="date"
                            inputElement={
                              <div>
                                <KTFormInput
                                  name="date"
                                  value={Utils.formatDateTime(
                                    formik.values.invoiceDate,
                                    'YYYY-MM-DD'
                                  )}
                                  onChange={(value) => {
                                    formik.setFieldValue(
                                      'invoiceDate',
                                      Utils.formatAddDateTime(value)
                                    );
                                  }}
                                  onBlur={() => {
                                    formik.setFieldTouched('invoiceDate', true);
                                  }}
                                  enableCheckValid
                                  type={KTFormInputType.date}
                                  placeholder={`${_.capitalize(t('SelectPaymentDate'))}`}
                                  enableInputGroup={true}
                                  prependElements={[
                                    <i className="fa-regular fa-calendar-clock"></i>,
                                  ]}
                                />
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ maxWidth: `${sizes}px`, overflow: 'auto' }} className="mx-6">
                    <Table
                      className="position-relative"
                      style={{ borderCollapse: 'separate', borderSpacing: '0' }}
                    >
                      <thead>
                        <tr>
                          <th
                            className="position-sticky border-top border-left border-right"
                            style={{ left: '0', verticalAlign: 'middle' }}
                          >
                            STT
                          </th>
                          <th className="border-top  border-right">{t('NameOfGoods/Services')}</th>
                          <th className="border-top  border-right">ĐVT</th>
                          <th className="border-top  border-right">{t('Total')}</th>
                          <th className="border-top  border-right">{t('UnitPrice')}</th>
                          <th className="border-top  border-right">{t('Ratio')}</th>
                          <th className="border-top  border-right">{t('Discount')}</th>
                          <th className="border-top  border-right">{t('Tax(%)')}</th>
                          <th className="border-top  border-right">{t('taxMoney')}</th>
                          <th className="border-top ">{t('IntoMoney')}</th>
                          <th
                            className="position-sticky border-top border-right border-left"
                            style={{ right: '0' }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {formik.values?.InvoiceProducts?.map((item, index) => (
                          <tr key={index}>
                            <td
                              className="position-sticky border-left border-right"
                              style={{ left: '0', zIndex: '1000', verticalAlign: 'middle' }}
                            >
                              {index + 1}
                            </td>
                            <td className="border-right">
                              <VIInputField
                                placeholder=""
                                value={formik.values.InvoiceProducts?.[index]?.name ?? ''}
                                fieldHelper={formik.getFieldHelpers(
                                  `InvoiceProducts.${[index]}.name`
                                )}
                                fieldProps={formik.getFieldProps(`InvoiceProducts.${[index]}.name`)}
                                fieldMeta={formik.getFieldMeta(`InvoiceProducts.${[index]}.name`)}
                                style={{ minWidth: '250px' }}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    if (
                                      e?.target.value !== '' &&
                                      index === formik.values?.InvoiceProducts?.length - 1
                                    ) {
                                      formik.setValues({
                                        ...formik.values,
                                        InvoiceProducts: [
                                          ...formik.values.InvoiceProducts,
                                          {
                                            id: index + 1,
                                            name: '',
                                            unit: '',
                                            quantity: 1,
                                            price: 0,
                                            discount: 0,
                                            discountAmount: 0,
                                            vat: 'KCT',
                                            vatAmount: 0,
                                            finalAmount: 0,
                                          },
                                        ],
                                      });
                                    }
                                  }
                                }}
                                name="name"
                              />
                            </td>
                            <td className="border-right">
                              <VIInputField
                                placeholder=""
                                style={{ minWidth: '150px' }}
                                value={formik.values.InvoiceProducts?.[index]?.unit ?? ''}
                                onChange={(e) => {
                                  let newFormik = [...formik.values.InvoiceProducts];
                                  newFormik[index].unit = e.target.value;
                                  formik.setValues({
                                    ...formik.values,
                                    InvoiceProducts: newFormik,
                                  });
                                }}
                                name="unit"
                              />
                            </td>
                            <td className="border-right">
                              <VIInputField
                                placeholder=""
                                style={{ minWidth: '100px' }}
                                type="number"
                                min="1"
                                max="200"
                                value={formik.values.InvoiceProducts?.[index]?.quantity ?? ''}
                                onChange={(e) => {
                                  let newFormik = [...formik.values.InvoiceProducts];
                                  newFormik[index].quantity = e.target.value;
                                  newFormik[index].finalAmount =
                                    parseFloat(e.target.value !== '' ? e.target.value : 0) *
                                      parseFloat(formik.values.InvoiceProducts?.[index]?.price) -
                                    parseFloat(
                                      formik.values.InvoiceProducts?.[index]?.discountAmount
                                    ) +
                                    parseFloat(formik.values.InvoiceProducts?.[index]?.vatAmount);
                                  formik.setValues({
                                    ...formik.values,
                                    InvoiceProducts: newFormik,
                                  });
                                }}
                                fieldHelper={formik.getFieldHelpers(
                                  `InvoiceProducts.${[index]}.quantity`
                                )}
                                fieldProps={formik.getFieldProps(
                                  `InvoiceProducts.${[index]}.quantity`
                                )}
                                fieldMeta={formik.getFieldMeta(
                                  `InvoiceProducts.${[index]}.quantity`
                                )}
                                name="quantity"
                              />
                            </td>
                            <td className="border-right">
                              <div className="flex items-center mb-4">
                                <CurrencyInput
                                  placeholder=""
                                  groupSeparator="."
                                  decimalSeparator=","
                                  decimalsLimit={2}
                                  style={{ width: '100px' }}
                                  step={500}
                                  value={formik.values.InvoiceProducts?.[index]?.price}
                                  onValueChange={(e) => {
                                    let newFormik = [...formik.values.InvoiceProducts];
                                    newFormik[index].price = e;
                                    newFormik[index].finalAmount =
                                      parseFloat(e !== '' ? e : 0) *
                                        parseFloat(
                                          formik.values.InvoiceProducts?.[index]?.quantity
                                        ) -
                                      parseFloat(
                                        formik.values.InvoiceProducts?.[index]?.discountAmount
                                      ) +
                                      parseFloat(formik.values.InvoiceProducts?.[index]?.vatAmount);
                                    formik.setValues({
                                      ...formik.values,
                                      InvoiceProducts: newFormik,
                                    });
                                  }}
                                  className="transition duration-300 ease-in-out px-3 py-2 w-150px focus:outline-none focus:ring-2 focus:ring-blue-800"
                                />

                                {formik.errors.InvoiceProducts?.[index]?.price && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      {formik.errors.InvoiceProducts?.[index]?.price}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="border-right">
                              <VIInputField
                                placeholder=""
                                type="number"
                                style={{ minWidth: '150px' }}
                                min="1"
                                value={formik.values.InvoiceProducts?.[index]?.discount}
                                onChange={(e) => {
                                  let newFormik = [...formik.values.InvoiceProducts];
                                  newFormik[index].discount = e.target.value;
                                  newFormik[index].discountAmount =
                                    e.target.value !== ''
                                      ? (parseFloat(
                                          formik.values.InvoiceProducts?.[index]?.quantity
                                        ) *
                                          parseFloat(
                                            formik.values.InvoiceProducts?.[index]?.price
                                          ) *
                                          parseFloat(
                                            e.target.value !== '' ? Number(e.target.value) : 0
                                          )) /
                                        100
                                      : 0;
                                  newFormik[index].finalAmount =
                                    parseFloat(formik.values.InvoiceProducts?.[index]?.quantity) *
                                      parseFloat(formik.values.InvoiceProducts?.[index]?.price) -
                                    parseFloat(
                                      formik.values.InvoiceProducts?.[index]?.discountAmount
                                    ) +
                                    parseFloat(formik.values.InvoiceProducts?.[index]?.vatAmount);
                                  formik.setValues({
                                    ...formik.values,
                                    InvoiceProducts: newFormik,
                                  });
                                }}
                              />
                            </td>
                            <td className="border-right">
                              <CurrencyInput
                                placeholder=""
                                groupSeparator="."
                                decimalSeparator=","
                                decimalsLimit={2}
                                style={{ width: '100px' }}
                                step={500}
                                value={formik.values.InvoiceProducts?.[index]?.discountAmount}
                                onValueChange={(e) => {
                                  let newFormik = [...formik.values.InvoiceProducts];
                                  newFormik[index].discountAmount = e;
                                  newFormik[index].discount =
                                    e === '' || e === '0' || e === undefined
                                      ? '0'
                                      : (e /
                                          (parseFloat(
                                            formik.values.InvoiceProducts?.[index]?.quantity
                                          ) *
                                            parseFloat(
                                              formik.values.InvoiceProducts?.[index]?.price
                                            ))) *
                                        100;
                                  newFormik[index].finalAmount =
                                    parseFloat(formik.values.InvoiceProducts?.[index]?.quantity) *
                                      parseFloat(formik.values.InvoiceProducts?.[index]?.price) -
                                    parseFloat(e !== '' ? e : 0) +
                                    parseFloat(formik.values.InvoiceProducts?.[index]?.vatAmount);
                                  formik.setValues({
                                    ...formik.values,
                                    InvoiceProducts: newFormik,
                                  });
                                }}
                                className="transition duration-300 ease-in-out px-3 py-2 w-150px focus:outline-none focus:ring-2 focus:ring-blue-800"
                              />
                              {formik.errors.InvoiceProducts?.[index]?.discountAmount && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.InvoiceProducts?.[index]?.discountAmount}
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="border-right">
                              <VIDropdownPosition
                                onValueChanged={(e) => {
                                  let newFormik = [...formik.values.InvoiceProducts];
                                  newFormik[index].vat =
                                    e === ''
                                      ? 'KCT'
                                      : e === 0
                                      ? '0%'
                                      : e === 5
                                      ? '5%'
                                      : e === 8
                                      ? '8%'
                                      : e === -1
                                      ? 'KKKNT'
                                      : e === 'KHAC'
                                      ? 'KHAC'
                                      : '10%';
                                  newFormik[index].vatAmount =
                                    e === '' || e === 0 || e === -1 || e === 'KHAC'
                                      ? '0'
                                      : ((parseFloat(
                                          formik.values.InvoiceProducts?.[index]?.quantity
                                        ) *
                                          parseFloat(
                                            formik.values.InvoiceProducts?.[index]?.price
                                          ) -
                                          parseFloat(
                                            formik.values.InvoiceProducts?.[index]?.discountAmount
                                          )) *
                                          e) /
                                        100;
                                  newFormik[index].finalAmount =
                                    parseFloat(formik.values.InvoiceProducts?.[index]?.quantity) *
                                      parseFloat(formik.values.InvoiceProducts?.[index]?.price) -
                                    parseFloat(
                                      formik.values.InvoiceProducts?.[index]?.discountAmount
                                    ) +
                                    parseFloat(formik.values.InvoiceProducts?.[index]?.vatAmount);

                                  formik.setValues({
                                    ...formik.values,
                                    InvoiceProducts: newFormik,
                                  });
                                }}
                                options={[{ name: 'KCT', value: '' }, ...AppData.vat]}
                                className="w-100px"
                                defaultValue={formik.values.InvoiceProducts?.[index]?.vat}
                              />
                            </td>

                            <td className="border-right">
                              <CurrencyInput
                                placeholder=""
                                groupSeparator="."
                                decimalSeparator=","
                                decimalsLimit={2}
                                style={{ width: '100px' }}
                                step={500}
                                // disabled={true}
                                disabled={formik.values.InvoiceProducts?.[index]?.vat !== 'KHAC'}
                                value={formik.values.InvoiceProducts?.[index]?.vatAmount}
                                onValueChange={(e) => {
                                  let newFormik = [...formik.values.InvoiceProducts];
                                  newFormik[index].vatAmount = e;
                                  // newFormik[index].vat =
                                  //   (e /
                                  //     (parseFloat(
                                  //       formik.values.InvoiceProducts?.[index]?.quantity
                                  //     ) *
                                  //       parseFloat(
                                  //         formik.values.InvoiceProducts?.[index]?.price -
                                  //           parseFloat(
                                  //             formik.values.InvoiceProducts?.[index]?.discountAmount
                                  //           )
                                  //       ))) *
                                  //   100;
                                  newFormik[index].finalAmount =
                                    parseFloat(formik.values.InvoiceProducts?.[index]?.quantity) *
                                      parseFloat(formik.values.InvoiceProducts?.[index]?.price) -
                                    parseFloat(
                                      formik.values.InvoiceProducts?.[index]?.discountAmount
                                    ) +
                                    parseFloat(e !== '' ? e : 0);
                                  formik.setValues({
                                    ...formik.values,
                                    InvoiceProducts: newFormik,
                                  });
                                }}
                                className="transition duration-300 ease-in-out px-3 py-2 w-150px focus:outline-none focus:ring-2 focus:ring-blue-800"
                              />
                            </td>
                            <td className="border-right">
                              <CurrencyInput
                                placeholder=""
                                groupSeparator="."
                                decimalSeparator=","
                                decimalsLimit={2}
                                style={{ width: '100px' }}
                                step={500}
                                value={formik.values.InvoiceProducts?.[index]?.finalAmount}
                                onValueChange={(e) => {}}
                                className="transition duration-300 ease-in-out px-3 py-2 w-150px focus:outline-none focus:ring-2 focus:ring-blue-800"
                              />
                            </td>
                            <td
                              style={{ verticalAlign: 'middle', right: '0', zIndex: '1000' }}
                              className="position-sticky border-left border-right"
                            >
                              <i
                                className="fa-regular fa-trash-can cursor-pointer"
                                onClick={() => {
                                  if (formik.values?.InvoiceProducts?.length > 1) {
                                    formik.setValues({
                                      ...formik.values,
                                      InvoiceProducts: formik.values?.InvoiceProducts.filter(
                                        (x) => x?.id !== item?.id
                                      ),
                                    });
                                  } else {
                                    formik.setValues({
                                      ...formik.values,
                                      InvoiceProducts: [
                                        {
                                          id: 0,
                                          name: '',
                                          unit: '',
                                          quantity: 1,
                                          price: 0,
                                          discount: 0,
                                          discountAmount: 0,
                                          vat: 0,
                                          vatAmount: 0,
                                          intoMoney: 0,
                                        },
                                      ],
                                    });
                                  }
                                }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <div
                    className="m-6 p-7"
                    style={{ borderRadius: '20px', background: 'var(--system-bg-select, #F8F8F8)' }}
                  >
                    <div className="d-flex align-items-center">
                      <span className="w-50 text-end" style={{ minWidth: 'max-content' }}>
                        {t('TotalPreTaxMoney')}
                      </span>
                      <CurrencyInput
                        placeholder=""
                        groupSeparator="."
                        decimalSeparator=","
                        decimalsLimit={2}
                        style={{ width: '100px' }}
                        step={500}
                        value={formik.values.amountBeforeVat}
                        onValueChange={(e) => {}}
                        className="transition duration-300 ease-in-out px-3 py-2 w-50 focus:outline-none focus:ring-2 focus:ring-blue-800 mb-3 ms-3"
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="w-50 text-end" style={{ minWidth: 'max-content' }}>
                        {t('TaxMoneyGTGT')}
                      </span>
                      <CurrencyInput
                        placeholder=""
                        groupSeparator="."
                        decimalSeparator=","
                        decimalsLimit={2}
                        style={{ width: '100px' }}
                        step={500}
                        value={formik.values.amountVat}
                        onValueChange={(e) => {}}
                        className="transition duration-300 ease-in-out px-3 py-2 w-50 focus:outline-none focus:ring-2 focus:ring-blue-800 mb-3 ms-3"
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <span
                        className="w-50 text-end"
                        style={{ minWidth: 'max-content', color: '#F44141' }}
                      >
                        {t('TotalPayment')}
                      </span>
                      <CurrencyInput
                        placeholder=""
                        groupSeparator="."
                        decimalSeparator=","
                        decimalsLimit={2}
                        style={{ width: '100px' }}
                        step={500}
                        value={formik.values.finalAmount}
                        onValueChange={(e) => {}}
                        className="transition duration-300 ease-in-out px-3 py-2 w-50 focus:outline-none focus:ring-2 focus:ring-blue-800 mb-3 ms-3"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between px-5 py-3 border-top"
                  style={{ minWidth: '360px' }}
                >
                  <div className="d-flex">
                    {!currentEdit || (currentEdit && currentEdit?.statusInvoice === '8') ? (
                      <VIButton
                        text={t('SaveDraft')}
                        className="btn-grey px-3 me-3"
                        onClick={() => {
                          setDataSaveDart('save'), formik.handleSubmit();
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="d-flex">
                    <VIButton
                      text={t('Cancel')}
                      className="btn-grey px-3 me-3"
                      onClick={() => {
                        onHide(), formik.handleReset();
                      }}
                    />
                    <VIButton
                      text={t('Save')}
                      className="btn-blue px-3 me-3"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </div>
                </div>
              </div>
            </Pane>
          </SplitPane>
        </div>

        <VIModal
          titleModal={t(`ListOfLinesOfBusiness`)}
          show={showListOfOccupations}
          onHide={() => setShowListOfOccupations(false)}
          contentModal={<ListOfOccupations />}
          closeBtn={true}
          handleSubmit={() => {}}
          btnModal={false}
          modalSize="lg"
        />
      </Modal.Body>
    </Modal>
  );
};

export default ManualDataEntry;
