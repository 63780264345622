import VIDateRangePicker2 from 'general/components/VietInvoice/VIDateRangePicker2';
import Global from 'general/utils/Global';
import { useTranslation } from 'react-i18next';

ViewChooseDateRangeSync.propTypes = {};

function ViewChooseDateRangeSync(props) {
  // ---- params ----
  const { t } = useTranslation();

  return (
    <div>
      <div className="d-flex flex-column align-items-start">
        <label className="mb-2 mr-2 text-grey font-weight-bolder">{t('ChooseDateRangeSync')}</label>
        <VIDateRangePicker2
          inlineMode={true}
          alignRight={false}
          initialValue={Global.gFiltersAxionSync.dateRangeValue}
          onPressSave={(selectedValue, startDate, endDate) => {
            Global.gFiltersAxionSync = {
              ...Global.gFiltersAxionSync,
              from: startDate,
              to: endDate,
              dateRangeValue: selectedValue,
            };
          }}
        />
      </div>
    </div>
  );
}

export default ViewChooseDateRangeSync;
