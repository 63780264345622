import MailImportantBoxView from 'features/Mail/components/MailImportantBoxView';
import MailInboxView from 'features/Mail/components/MailInboxView';
import MailSpamBoxView from 'features/Mail/components/MailSpamBoxView';
import VIAppTabs from 'general/components/VietInvoice/VIAppTabs';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppConfigs from 'general/constants/AppConfigs';
import MailHelper from 'general/helpers/MailHelper';
import Global from 'general/utils/Global';
import useConfig from 'hooks/useConfig';
import useRouter from 'hooks/useRouter';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import './style.scss';
MailHomeScreen.propTypes = {};

function MailHomeScreen(props) {
  // ---- params ----
  const { t } = useTranslation();
  const tabList = useMemo(() => {
    return [t('SpamBox'), t('ImportantBox'), t('Inbox')];
  }, []);
  const [activeTab, setActiveTab] = useState(tabList[2]);
  const router = useRouter();
  const bannerHeight = useConfig().systemNotification.bannerHeight;
  const [contentMaxHeight, setContentMaxHeight] = useState('55vh');
  const refTabList = useRef();
  const { inboxMailList } = useSelector((state) => state.mail);

  useEffect(() => {
    // console.log(router.pathname);
    if (router.pathname.includes('/inbox')) {
      setActiveTab(tabList[2]);
    } else if (router.pathname.includes('/important-box')) {
      setActiveTab(tabList[1]);
    } else if (router.pathname.includes('/spam-box')) {
      setActiveTab(tabList[0]);
    }
  }, [router.pathname]);

  function resizeScreen() {
    setContentMaxHeight(
      `calc(100vh - ${
        bannerHeight + (AppConfigs.isHideFooter ? 0 : 65) + 72 + refTabList.current?.clientHeight
      }px - ${3}rem)`
    );
  }

  window.onresize = resizeScreen;

  useEffect(() => {
    resizeScreen();
  }, [window.innerWidth, window.innerHeight, bannerHeight, inboxMailList]);

  // ---- methods ---
  function handleRefresh() {
    MailHelper.callApiGetInboxMailList(Global.gFiltersInboxMailList);
    MailHelper.callApiGetImportantMailList(Global.gFiltersImportantMailList);
    MailHelper.callApiGetSpamMailList(Global.gFiltersSpamMailList);
  }

  return (
    <div className="d-flex flex-column h-100 mail">
      <div className="position-relative" ref={refTabList}>
        {/* button refresh */}
        <div className="position-absolute right-0 mail-btn">
          <VIButton
            text={t('Refresh')}
            className=""
            style={{ padding: '8px' }}
            onClick={handleRefresh}
            beforeIcon={<i className="fa-light fa-arrow-rotate-right"></i>}
          />
        </div>
        {/* tabs */}
        <VIAppTabs
          tabs={tabList}
          activeTab={activeTab}
          handleClick={(tab) => {
            // setActiveTab(tab);
            switch (tab) {
              case tabList[0]:
                router.navigate('/inbot/mail/spam-box');
                break;
              case tabList[1]:
                router.navigate('/inbot/mail/important-box');
                break;
              case tabList[2]:
                router.navigate('/inbot/mail/inbox');
                break;
              default:
                break;
            }
          }}
        />
      </div>

      {/* content */}
      <div
        className="bg-white border-top-0 overflow-hidden"
        style={{
          border: '1px solid #E8E9EB',
          boxShadow: '0px 8px 24px 0px rgba(37, 42, 91, 0.05)',
          borderRadius: '0px 0px 20px 20px',
          height: contentMaxHeight,
        }}
      >
        <Routes>
          <Route path="" element={<Navigate to="inbox" />} />
          <Route path="inbox/*" element={<MailInboxView height={contentMaxHeight} />} />
          <Route
            path="important-box/*"
            element={<MailImportantBoxView height={contentMaxHeight} />}
          />
          <Route path="spam-box/*" element={<MailSpamBoxView height={contentMaxHeight} />} />
          <Route path="*" element={<Navigate to="inbox" />} />
        </Routes>
      </div>
    </div>
  );
}

export default MailHomeScreen;
