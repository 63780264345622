import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

CellInstructionGrid.propTypes = {
  item: PropTypes.object,
  onPress: PropTypes.func,
};

CellInstructionGrid.defaultProps = {
  item: null,
  onPress: null,
};

function CellInstructionGrid(props) {
  // ---- params ----
  const { item, onPress } = props;
  const { t } = useTranslation();

  // ---- methods ----
  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  return (
    <div className="d-flex align-items-start">
      {/* anh thumbnail */}
      <div
        className="symbol symbol-60 symbol-2by3 flex-shrink-0 border cursor-pointer"
        onClick={handlePress}
      >
        <div
          className="symbol-label"
          style={{
            backgroundImage: `url('${item?.thumbnail ?? AppResource.images.imgBgInfo}')`,
          }}
        ></div>
      </div>

      {/* title */}
      <div className="d-flex flex-column ml-4 flex-grow-1 mr-2">
        <a
          href="#"
          className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg mb-1 text-maxline-3"
          onClick={(e) => {
            e.preventDefault();
            handlePress();
          }}
        >
          {item?.title}
        </a>
        <span className="text-dark-50 font-weight-normal text-maxline-2">
          {t('UpdatedAt')}: {Utils.formatDateTime(item?.updatedAt, 'DD/MM/YYYY')}
        </span>
      </div>

      {/* tag */}
      <span
        className={`label label-lg label-light-${
          item?.type === 'VIDEO' ? 'danger' : 'primary'
        } label-inline mt-lg-0 mb-lg-0 my-2 font-weight-bold py-4`}
      >
        {item?.type}
      </span>
    </div>
  );
}

export default CellInstructionGrid;
