import { unwrapResult } from '@reduxjs/toolkit';
import store from 'app/store';
import {
  thunkCheckSupplierCustomers,
  thunkGetListSupplierCustomers,
  thunkImportSupplierCustomers,
  thunkUpdateSupplierCustomers,
} from 'features/Category/categorySlice';
import AppData from 'general/constants/AppData';
import Global from 'general/utils/Global';
import { t } from 'i18next';
import ToastHelper from './ToastHelper';

const CategoryHelper = {
  // lay icon trang thai
  getIconStatus: (status) => {
    switch (status) {
      case AppData.supplierStatus.active:
        return 'fa-regular fa-circle-check text-primary';
      case AppData.supplierStatus.inactive:
        return 'fa-regular fa-times-circle text-danger';
      default:
        return 'fa-regular fa-circle-question text-warning';
    }
  },

  // lay thong tin loai khach hang
  getCustomerTypeInfo: (row) => {
    let text = '';
    let color = '';
    let bgColor = '';
    const { buyInvoices, sellInvoices } = row;
    if (buyInvoices === 0 && sellInvoices === 0) {
      text = 'Không xác định';
      color = '#1c2256';
      bgColor = 'rgb(28, 34, 86, 0.1)';
    }
    if (buyInvoices > 0 && sellInvoices > 0) {
      text = 'NCC + Khách hàng';
      color = '#20A144';
      bgColor = 'rgba(40, 160, 67, 0.10)';
    }
    if (buyInvoices > 0 && sellInvoices === 0) {
      text = 'Nhà cung cấp';
      color = '#EE2334';
      bgColor = 'rgba(255, 87, 87, 0.10)';
    }
    if (sellInvoices > 0 && buyInvoices === 0) {
      text = 'Khách hàng';
      color = '#E18700';
      bgColor = 'rgba(213, 133, 59, 0.10)';
    }
    return {
      text,
      color,
      bgColor,
    };
  },

  // goi api lay danh sach ncc/khach hang
  callApiGetSupplierCustomerList: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetListSupplierCustomers(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api cap nhat thong tin ncc/khach hang
  callApiUpdateSupplierCustomer: async (id, params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkUpdateSupplierCustomers({ id, params })));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('UpdateSupplierCustomerSuccess'));
        store.dispatch(thunkGetListSupplierCustomers(Global.gFiltersSupplierAndCustomer));
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api kiem tra ncc/khach hang
  callApiCheckSupplierCustomer: async () => {
    try {
      const res = unwrapResult(await store.dispatch(thunkCheckSupplierCustomers()));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api import ncc/khach hang
  callApiImportSupplierCustomer: async (file) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkImportSupplierCustomers(file)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
};

export default CategoryHelper;
