import DropdownHeaderAccountInfo from 'features/Others/components/DropdownHeaderAccountInfo';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTTooltip, { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import AccountHelper from 'general/helpers/AccountHelper';
import useAccount from 'hooks/useAccount';
import usePayment from 'hooks/usePayment';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './style.scss';

function LandingPageHeader(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuth = AccountHelper.checkAccessTokenValid();
  const account = useAccount();
  const { shoppingCartCount } = usePayment();

  const headerTabs = [
    { title: 'Trang chủ', id: 'home', href: '' },
    { title: 'Giới thiệu', id: 'introduction', href: '' },
    { title: 'Ưu thế', id: 'advantages', href: '' },
    { title: 'Bảng giá', id: 'price', href: '' },
    { title: 'Liên hệ', id: 'contact', href: '' },
  ];

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo(0, element.offsetTop - 80);
    }
  };

  return (
    <div id="landingpage-header" className="LandingPageHeader w-100 py-5 py-md-8">
      <div className="container-xl d-flex justify-content-between align-items-center">
        <img
          className="h-30px cursor-pointer"
          src={AppResource.icons.icLogoQuanLyHoaDon}
          alt="logo"
          onClick={() => {
            if (isAuth) navigate('/inbot/dashboard');
          }}
        />

        <div className="d-flex">
          <div className="LandingPageHeader_laptop header-tabs me-3">
            <div className="d-flex align-items-center h-100">
              {headerTabs?.map((item, index) => (
                <div
                  key={index}
                  className="header-tab font-size-h5 font-weight-bolder text-dark-65 cursor-pointer mx-1 mx-md-3 mx-lg-6 mx-xl-8"
                  onClick={() => {
                    handleScroll(item?.id);
                  }}
                >
                  {item?.title}
                </div>
              ))}
            </div>
          </div>

          <Dropdown className="LandingPageHeader_mobile my-auto mx-2">
            <Dropdown.Toggle className="p-0 d-flex align-items-center">
              <div className="btn btn-icon btn-clean font-weight-bolder btn-lg">
                <i className="fas fa-bars font-size-h2 cursor-pointer"></i>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu align="left" className="w-200px py-4 bg-white shadow-lg rounded-lg px-3">
              {headerTabs?.map((item, index) => (
                <Dropdown.Item
                  className="header-tab p-4 font-size-h6 font-weight-bolder text-dark-65 bg-hover-secondary-o-1"
                  key={index}
                  onClick={() => {
                    handleScroll(item?.id);
                  }}
                >
                  {item?.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {/* gio hang */}
          {shoppingCartCount > 0 && (
            <div className="topbar-item">
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 position-relative"
                onClick={() => {
                  navigate('/store/shopping-cart');
                }}
              >
                <i className="fa-regular fa-shopping-cart" />
                <span
                  className="position-absolute bg-danger text-white rounded"
                  style={{
                    top: '6px',
                    right: '8px',
                    fontSize: '0.8rem',
                    padding: '0 4px',
                  }}
                >
                  {shoppingCartCount}
                </span>
              </div>
            </div>
          )}

          <VIButton
            className="btn-blue mx-3 h-35px border-primary font-size-h5 w-100px"
            text={t('SignIn')}
            onClick={() => navigate('/auth/sign-in')}
          />

          {/* {!isAuth ? (
            <VIButton
              className="btn-blue mx-3 h-35px border-primary font-size-h5 w-100px"
              text={t('SignIn')}
              onClick={() => navigate('/auth/sign-in')}
            />
          ) : (
            <>
              <KTBSDropdown
                contentEl={<DropdownHeaderAccountInfo />}
                toggleElement={
                  <div className="topbar-item ml-2">
                    <KTTooltip text={account.displayName} position={KTTooltipPositions.auto}>
                      <div className="btn btn-icon btn-clean d-flex align-items-center btn-lg">
                        <div className="symbol symbol-circle symbol-35 bg-white overflow-hidden">
                          <div className="symbol-label bg-white border">
                            <img
                              alt="avatar"
                              src={account.avatar}
                              className="w-35px h-35px"
                              style={{
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </KTTooltip>
                  </div>
                }
                alignment={KTBSDropdownAlignments.end}
                // autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
              />
            </>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default LandingPageHeader;
