import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';
import SupplierConnectScreen from './screens/SupplierConnectScreen';

Supplier.propTypes = {};

function Supplier(props) {
  return (
    <Routes>
      <Route path="" element={<Navigate to="connect" />} />
      <Route path="connect/*" element={<SupplierConnectScreen />} />
    </Routes>
  );
}

export default Supplier;
