import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';
import KTCheckbox, {
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import { useEffect } from 'react';

VISelectMultiOrganization.propTypes = {
  data: PropTypes.array,
  setItem: PropTypes.func,
  currentItem: PropTypes.object,
  level: PropTypes.number,
  listItem: PropTypes.array,
  onPushItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

VISelectMultiOrganization.defaultProps = {
  data: [],
  setItem: null,
  currentItem: {},
  level: 1,
  listItem: [],
  onPushItem: null,
  onRemoveItem: null,
};

function VISelectMultiOrganization(props) {
  const { data, setItem, currentItem, level, listItem, onPushItem, onRemoveItem } = props;
  const { t } = useTranslation();

  function handleChange(item) {
    if (setItem) {
      setItem(item);
    }
  }

  function handlePushItem(item) {
    if (onPushItem) {
      onPushItem(item);
    }
  }

  function handleRemoveItem(item) {
    if (onRemoveItem) {
      onRemoveItem(item);
    }
  }

  return (
    <div className="VISelectMultiOrganization">
      {data?.map((item, index) => {
        return (
          <div key={index}>
            <div
              className={`checkbox-list d-flex align-items-center flex-row border-bottom py-1 pl-${
                level === 2 ? '4' : level === 3 ? '8' : ''
              }`}
              style={{ height: '46px' }}
            >
              <KTCheckbox
                name={`switch${item?.organizationName}On`}
                label={
                  item?.organizationName ?? (
                    <div>
                      {item?.departmentName} <br />
                      <span>{t('EmailReceiveInvoice')}:</span>
                      <span className="text-primary">{item?.invoiceMailbox}</span>
                    </div>
                  )
                }
                // size={KTSwitchSize.sm}
                // type={KTSwitchType.outline}
                type={KTCheckboxType.outline}
                checked={listItem?.includes(item)}
                onChange={(value) => {
                  if (value) {
                    handlePushItem(item);
                    handleChange(item);
                  } else {
                    handleRemoveItem(item);
                    handleChange(null);
                  }
                }}
                additionalClassName={`switch-primary mr-4 ${
                  !item?.departmentName ? 'font-weight-bolder' : ''
                }`}
              />
            </div>
            <VISelectMultiOrganization
              listItem={listItem}
              currentItem={currentItem}
              level={level + 1}
              data={item?.ChildrenOrganizations}
              setItem={(item, e) => handleChange(item, e)}
              onPushItem={(item) => handlePushItem(item)}
              onRemoveItem={(item) => handleRemoveItem(item)}
            />
          </div>
        );
      })}
    </div>
  );
}

export default VISelectMultiOrganization;
