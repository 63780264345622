import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import ViewPaymentHistoryDetail from 'features/Account/components/ViewPaymentHistoryDetail';
import EmptyView from 'features/Others/components/EmptyView';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIModal from 'general/components/VietInvoice/VIModal';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import PaymentHelper from 'general/helpers/PaymentHelper';
import SystemHelper from 'general/helpers/SystemHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

OrderManagementTable.propTypes = {
  minusHeight: PropTypes.number,
};

OrderManagementTable.defaultProps = {
  minusHeight: 0,
};

function OrderManagementTable(props) {
  // MARK --- Params ---
  const { minusHeight } = props;
  const [minusHeightSideEffect, setMinusHeightSideEffect] = useState(minusHeight);
  const { t } = useTranslation();
  const { paymentHistoryList, isGettingPaymentHistory, totalPaymentHistory } = useSelector(
    (state) => state?.system
  );
  const [filters, setFilters] = useState(Global.gFilterPaymentHistory);
  const columns = useMemo(
    () => [
      {
        name: t('OrderCode'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="font-weight-normal m-0 text-maxline-3"
              style={{
                color: PaymentHelper.getPaymentStatusColor(row?.statusOrder),
              }}
            >
              {row.orderCode}
            </p>
          );
        },
      },
      {
        name: t('PurchaseDate'),
        minWidth: '160px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="font-weight-normal m-0 text-maxline-3"
              style={{
                color: PaymentHelper.getPaymentStatusColor(row?.statusOrder),
              }}
            >
              {Utils.formatDateTime(row?.createdAt, 'DD/MM/YYYY HH:mm')}
            </p>
          );
        },
      },
      {
        name: `${t('TotalPayment')} (VND)`,
        minWidth: '250px',
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="font-weight-bolder m-0 text-maxline-3"
              style={{
                color: PaymentHelper.getPaymentStatusColor(row?.statusOrder),
              }}
            >
              {row.totalPayment ? Utils.formatCurrency(row.totalPayment) : ''}
            </p>
          );
        },
      },
      {
        name: t('PaymentType'),
        minWidth: '160px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="font-weight-normal m-0 text-maxline-3"
              style={{
                color: PaymentHelper.getPaymentStatusColor(row?.statusOrder),
              }}
            >
              {row?.typePayment}
            </p>
          );
        },
      },
      {
        name: t('BuyerName'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="font-weight-normal m-0 text-maxline-3"
              style={{
                color: PaymentHelper.getPaymentStatusColor(row?.statusOrder),
              }}
            >
              {row?.orderName}
            </p>
          );
        },
      },
      {
        name: t('BuyerEmail'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="font-weight-normal m-0 text-maxline-3"
              style={{
                color: PaymentHelper.getPaymentStatusColor(row?.statusOrder),
              }}
            >
              {row?.orderEmail}
            </p>
          );
        },
      },
      {
        name: t('State'),
        sortable: false,
        width: '160px',
        style: {
          borderRight: 0,
        },
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="font-weight-normal m-0 text-maxline-3"
              style={{
                color: PaymentHelper.getPaymentStatusColor(row?.statusOrder),
              }}
            >
              {t(
                _.find(AppData.paymentStatusItems, (item) => item.value === row?.statusOrder).name
              )}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            <KTTooltip text={t('ViewDetail')}>
              <i
                className="fa-regular fa-eye text-primary"
                onClick={() => {
                  setSelectedOrder(row);
                  setModalPaymentHistoryDetailShowing(true);
                }}
              />
            </KTTooltip>
          </div>
        ),
      },
    ],
    [t]
  );
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalPaymentHistoryDetailShowing, setModalPaymentHistoryDetailShowing] = useState(false);

  useEffect(() => {
    SystemHelper.callApiGetPaymentHistory(filters);
  }, [filters]);

  useEffect(() => {
    setMinusHeightSideEffect(minusHeight);
  }, [minusHeight]);

  // ---- methods ----
  function handleCancelOrder() {
    setModalPaymentHistoryDetailShowing(false);
    AppDialogHelper.show(t('CancelOrder'), t('TextConfirmCancelOrder'), [
      {
        title: t('No'),
        onClick: () => {
          AppDialogHelper.hide();
          setModalPaymentHistoryDetailShowing(true);
        },
        type: 'neutral',
      },
      {
        title: t('Yes'),
        onClick: async () => {
          // thuc hien huy don hang
          const orderId = selectedOrder?.orderId;
          const res = await PaymentHelper.callApiCancelOrder(orderId);
          if (res) {
            ToastHelper.showSuccess(t('CancelOrderSuccess'));
            AppDialogHelper.hide();
            setSelectedOrder(null);
          }
        },
        type: 'negative',
      },
    ]);
  }

  async function handleResendEmailOrder() {
    setModalPaymentHistoryDetailShowing(false);
    // goi api gui lai email xac nhan mua hang
    const res = await PaymentHelper.callApiResendEmailPayment(selectedOrder?.orderId);
    if (res) {
      AppDialogHelper.show(
        t('Notification'),
        `Email xác nhận mua hàng đã được gửi về địa chỉ ${selectedOrder?.orderEmail}. Vui lòng kiểm tra email để xem chi tiết thông tin.`,
        [
          {
            title: t('Close'),
            onClick: () => {
              AppDialogHelper.hide();
            },
            type: 'neutral',
          },
        ]
      );
    }
  }

  return (
    <div className="h-100">
      <VIDataTable
        additionalCardBodyClassName="px-5"
        minusHeight={Number(minusHeightSideEffect + 20)}
        tableStyles={customDataTableActionStyle}
        haveCardHeader={false}
        draggable={false}
        pagination={{
          perPage: filters.limit,
          total: totalPaymentHistory,
          currentPage: filters.page + 1,
          count: paymentHistoryList?.length + filters.limit * filters.page,
        }}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          Global.gFilterPaymentHistory = {
            ...Global.gFilterPaymentHistory,
            page: iNewPage,
          };
          setFilters(Global.gFilterPaymentHistory);
        }}
        onChangeRowsPerPage={(perPage) => {
          Global.gFilterPaymentHistory = {
            ...Global.gFilterPaymentHistory,
            limit: perPage,
            page: 0,
          };
          setFilters(Global.gFilterPaymentHistory);
        }}
        fullColumns={columns}
        data={paymentHistoryList}
        loading={false}
        onRowClick={(row) => {}}
        onRowDoubleClick={(row) => {
          setSelectedOrder(row);
          setModalPaymentHistoryDetailShowing(true);
        }}
        emptyComponent={
          <EmptyView message={t('NoTransactionsYet')} image={AppResource.images.imgEmptyPayment} />
        }
      />

      {/* modal payment history detail */}
      <VIModal
        titleModal={t('OrderDetail')}
        show={modalPaymentHistoryDetailShowing}
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalPaymentHistoryDetailShowing(false);
        }}
        modalSize="lg"
        contentModal={
          <ViewPaymentHistoryDetail
            item={selectedOrder}
            onPressCancelOrder={() => {
              handleCancelOrder();
            }}
            onPressResendEmail={() => {
              handleResendEmailOrder();
            }}
          />
        }
      />
    </div>
  );
}

export default OrderManagementTable;
