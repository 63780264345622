import EmptyView from 'features/Others/components/EmptyView';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import AppResource from 'general/constants/AppResource';
import SystemHelper from 'general/helpers/SystemHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

ResourceProvidingHistory.propTypes = {
  minusHeight: PropTypes.number,
};

ResourceProvidingHistory.defaultProps = {
  minusHeight: 0,
};

function ResourceProvidingHistory(props) {
  // MARK --- Params ---
  const { minusHeight } = props;
  const [minusHeightSideEffect, setMinusHeightSideEffect] = useState(minusHeight);
  const { t } = useTranslation();
  const { resourceHistoryList, isGettingResourceHistory, pagination } = useSelector(
    (state) => state?.system
  );
  const [filters, setFilters] = useState(Global.gFiltersResourceHistory);
  const columns = useMemo(
    () => [
      {
        name: t('IssueDate'),
        minWidth: '183px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatDateTime(row?.supplyDate, 'DD/MM/YYYY')}
            </p>
          );
        },
      },
      {
        name: t('ExpiredDate'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.expiredDate ? Utils.formatDateTime(row?.expiredDate, 'DD/MM/YYYY') : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceNumber'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.total)}
            </p>
          );
        },
      },
      {
        name: t('Description'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.description ?? 'Đang sử dụng'}
            </p>
          );
        },
      },
    ],
    [t]
  );

  useEffect(() => {
    setMinusHeightSideEffect(minusHeight);
  }, [minusHeight]);

  useEffect(() => {
    SystemHelper.callApiGetResourceHistory(filters);
  }, [filters]);

  return (
    <div className="h-100">
      <VIDataTable
        additionalCardBodyClassName="px-5"
        minusHeight={Number(minusHeightSideEffect + 20)}
        haveCardHeader={false}
        draggable={false}
        pagination={pagination}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          // setFilters({
          //   ...filters,
          //   page: iNewPage,
          // });
        }}
        onChangeRowsPerPage={(perPage) => {
          // setFilters({
          //   ...filters,
          //   limit: perPage,
          //   page: 0,
          // });
        }}
        fullColumns={columns}
        data={resourceHistoryList}
        loading={isGettingResourceHistory}
        onRowClick={(row) => {}}
        emptyComponent={
          <EmptyView message={t('NoTransactionsYet')} image={AppResource.images.imgEmptyPayment} />
        }
      />
    </div>
  );
}

export default ResourceProvidingHistory;
