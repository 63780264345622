import { Route, Routes } from 'react-router-dom';
import InstructionHomeScreen from './screens/InstructionHomeScreen';

Instruction.propTypes = {};

function Instruction(props) {
  return (
    <Routes>
      <Route path="" element={<InstructionHomeScreen />} />
    </Routes>
  );
}

export default Instruction;
