import { unwrapResult } from '@reduxjs/toolkit';
import store from 'app/store';
import {
  thunkGetInvoiceByTimeStatistic,
  thunkGetInvoiceReport,
  thunkGetInvoiceStatistic,
  thunkGetInvoiceValueStatistic,
} from 'features/Dashboard/dashboardSlice';

const DashboardHelper = {
  // goi api thong ke hoa don
  callApiGetInvoiceStatistic: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetInvoiceStatistic(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
  // goi api thong ke hoa don theo thoi gian
  callApiGetInvoiceStatisticByTime: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetInvoiceByTimeStatistic(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
  // goi api thong ke gia tri hoa don
  callApiGetInvoiceValueStatistic: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetInvoiceValueStatistic(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
  // goi api bao cao hoa don
  callApiGetInvoiceReport: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetInvoiceReport(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
};

export default DashboardHelper;
