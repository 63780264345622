/**
 * 
 * Usage: 
 *  AppDialogHelper.show('Title', 'Description', [
        {
            title: 'Yes', type: 'positive', onClick: () => {
                AppDialogHelper.hide();
            }
        },
        {
            title: 'Cancel', type: 'neutral', onClick: () => {
                AppDialogHelper.hide();
            }
        },
        {
            title: 'No', type: 'negative', onClick: () => {
                AppDialogHelper.hide();
            }
        }
    ]);
 * 
 */

import Utils from 'general/utils/Utils';
import { createRef, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import VIButton from '../VietInvoice/VIButton';

AppDialog.propTypes = {};

const ref = createRef();
const sTag = '[AppDialog]';

export const AppDialogButtonType = {
  positive: 'positive',
  negative: 'negative',
  neutral: 'neutral',
};

// MARK: --- Helper functions ---
function getButtonVariant(type) {
  let buttonVariant = 'primary';
  if (type === 'negative') {
    buttonVariant = 'danger';
  }
  if (type === 'neutral') {
    buttonVariant = 'secondary';
  }
  return buttonVariant;
}

function AppDialog(props) {
  // MARK: --- Params ---
  const [dialogShowing, setDialogShowing] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buttons, setButtons] = useState([]);
  const [canClickOutsideToDismiss, setCanClickOutsideToDismiss] = useState(true);
  const [image, setImage] = useState(null);
  const [imageFullWidth, setImageFullWidth] = useState(false);

  // MARK: --- Functions ---
  function handleClose() {
    setDialogShowing(false);

    let closeBtn = buttons?.find((item) => item?.type === 'neutral');
    if (closeBtn) {
      closeBtn?.onClick();
    }
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    ref.current = {
      show: (
        title,
        description,
        buttons,
        canClickOutsideToDismiss,
        image = null,
        imageFullWidth = false
      ) => {
        setDialogShowing(true);
        setTitle(title);
        setDescription(description);
        setButtons(buttons);
        setCanClickOutsideToDismiss(canClickOutsideToDismiss);
        setImage(image);
        setImageFullWidth(imageFullWidth);
      },
      hide: () => {
        setDialogShowing(false);
      },
    };

    return () => {};
  }, []);

  return (
    <div>
      <Modal
        className="AppDialog"
        show={dialogShowing}
        onHide={handleClose}
        centered
        backdrop={!canClickOutsideToDismiss ? 'static' : true}
        onEntered={() => {
          Utils.lockedBodyScroll();
        }}
        onExited={() => {
          Utils.unlockedBodyScroll();
        }}
      >
        {/* Header */}
        <Modal.Header className="pt-5 pb-2 border-0">
          <Modal.Title className="font-weight-bolder text-grey">{title}</Modal.Title>
          <div>
            <i
              className="far fa-times px-3 py-2 rounded cursor-pointer button-close"
              onClick={handleClose}
            />
          </div>
          {/* <div
            className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            <i className="far fa-times"></i>
          </div> */}
        </Modal.Header>

        {/* Body */}
        <Modal.Body className="py-4">
          <div
            className={`d-flex flex-column ${image ? 'align-items-center' : 'align-items-start'}`}
          >
            {image && <img src={image} className={`mb-4 ${imageFullWidth ? 'w-100' : ''}`} />}
            {description}
          </div>
        </Modal.Body>

        {buttons && buttons.length > 0 && (
          <Modal.Footer className="border-0 pt-0">
            <div className="d-flex align-items-center w-100 justify-content-end gap-3">
              {buttons.map((item, index) => {
                return (
                  <div className="" key={index}>
                    {/* <Button
                      className="font-weight-bold flex-grow-1 col"
                      variant={getButtonVariant(item?.type)}
                      onClick={() => {
                        if (item?.onClick) {
                          item?.onClick();
                        }
                      }}
                    >
                      {item?.title}
                    </Button> */}
                    {item?.type === 'neutral' && (
                      <VIButton
                        text={item?.title}
                        className="btn-grey"
                        style={{ padding: '8px' }}
                        onClick={() => {
                          if (item?.onClick) {
                            item?.onClick();
                          }
                        }}
                      />
                    )}
                    {item?.type === 'negative' && (
                      <VIButton
                        text={item?.title}
                        className="btn-red"
                        style={{ padding: '8px' }}
                        onClick={() => {
                          if (item?.onClick) {
                            item?.onClick();
                          }
                        }}
                      />
                    )}
                    {item?.type === 'positive' && (
                      <VIButton
                        text={item?.title}
                        className="btn-blue"
                        style={{ padding: '8px' }}
                        onClick={() => {
                          if (item?.onClick) {
                            item?.onClick();
                          }
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
}

export default AppDialog;
export const AppDialogHelper = {
  /**
   * Show dialog
   * @param {string} title Dialog title
   * @param {string} description Dialog description
   * @param {[{title: string, type: AppDialogButtonType, onClick: function}]} buttons Dialog buttons
   * @param {boolean} canClickOutsideToDismiss Dialog can click outside to dismiss
   * @param {boolean} clickHide Dialog can click close to dismiss
   *
   */
  show: (
    title,
    description,
    buttons = [],
    canClickOutsideToDismiss = false,
    image = null,
    imageFullWidth = false
  ) => {
    ref.current.show(title, description, buttons, canClickOutsideToDismiss, image, imageFullWidth);
  },
  /**
   * Hide dialog
   */
  hide: () => {
    ref.current.hide();
  },
};
