import paymentApi from 'api/paymentApi';
import AppData from 'general/constants/AppData';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// lay danh sach goi dich vu
export const thunkGetServicePackList = createAsyncThunk(
  'system/getServicePackList',
  async (params, thunkAPI) => {
    const res = await paymentApi.getAllServicePackList(params);
    return res;
  }
);

// lay danh sach vouchers
export const thunkGetVoucherList = createAsyncThunk(
  'system/getVoucherList',
  async (params, thunkAPI) => {
    const res = await paymentApi.getVoucherList(params);
    return res;
  }
);

// tao don hang
export const thunkCreateOrder = createAsyncThunk('system/createOrder', async (params, thunkAPI) => {
  const res = await paymentApi.createOrder(params);
  return res;
});

// huy don hang
export const thunkCancelOrder = createAsyncThunk(
  'system/cancelOrder',
  async (orderId, thunkAPI) => {
    const res = await paymentApi.cancelOrder(orderId);
    return res;
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    // danh sach goi dich vu
    servicePackList: [],
    isGettingServicePackList: false,

    // danh sach vouchers
    voucherList: [],
    isGettingVoucherList: false,

    isCreatingOrder: false,
    orderCreatedSuccess: null,

    // don hang
    orders: [],

    // gio hang
    shoppingCart: [],

    // thong tin xac nhan thanh toan
    confirmPaymentInfo: {},
    readyToPurchaseOrder: false,
    paymentType: AppData.paymentType.bankTransfer,
    selectedVoucher: null,

    // huy don hang
    isCancelingOrder: false,

    selectedOrderInfo: null,
  },
  reducers: {
    // them goi dich vu vao don hang
    addPackageToOrder: (state, action) => {
      const payload = action?.payload;
      if (payload) {
        const { packageId } = payload;
        // kiem tra da ton tai goi dich vu nay trong don hang chua?
        const findIndex = _.findIndex(
          state.orders,
          (item) => item.package?.packageId === packageId
        );
        if (findIndex === -1) {
          state.orders.push({
            package: payload,
            quantity: 1,
          });
        } else {
          state.orders[findIndex].quantity += 1;
        }
      }
    },
    // xoa du lieu don hang
    removeOrderData: (state, action) => {
      state.orders = [];
    },
    // xoa goi dich vu khoi don hang
    removePackage: (state, action) => {
      const packageId = action.payload;
      const findIndex = _.findIndex(state.orders, (item) => item.package?.packageId === packageId);
      if (findIndex > -1) {
        state.orders.splice(findIndex, 1);
      }
    },
    // tang, giam so luong goi dich vu trong don hang
    changeOrderPackageQuantity: (state, action) => {
      const { packageId, newQuantity } = action.payload;
      const findIndex = _.findIndex(state.orders, (item) => item.package?.packageId === packageId);
      if (findIndex > -1) {
        state.orders[findIndex].quantity = parseInt(newQuantity);
      }
    },

    // copy tu don hang sang gio hang
    copyFromOrderToCart: (state, action) => {
      state.shoppingCart = [...state.orders];
      state.orders = [];
    },
    // copy tu gio hang sang don hang
    copyFromCartToOrder: (state, action) => {
      state.orders = [...state.shoppingCart];
    },

    // xoa du lieu gio hang
    removeShoppingCartData: (state, action) => {
      state.shoppingCart = [];
    },

    // cap nhat thong tin xac nhan thanh toan
    updateConfirmPaymentInfo: (state, action) => {
      state.confirmPaymentInfo = {
        ...state.confirmPaymentInfo,
        ...(action.payload ?? {}),
      };
    },

    // xoa du lieu thong tin xac nhan thanh toan
    clearConfirmPaymentInfo: (state, action) => {
      state.confirmPaymentInfo = {};
    },

    // cap nhat trang thai san sang thuc hien gui yc mua hang
    updateReadyToPurchaseOrder: (state, action) => {
      state.readyToPurchaseOrder = action.payload;
    },

    // cap nhat hinh thuc thanh toan
    updatePaymentType: (state, action) => {
      state.paymentType = action.payload;
    },

    // ap dung voucher
    updateSelectedVoucher: (state, action) => {
      state.selectedVoucher = action.payload;
    },

    // xoa du lieu don hang
    clearOrderData: (state, action) => {
      state.shoppingCart = [];
      state.confirmPaymentInfo = {};
      state.readyToPurchaseOrder = false;
      state.selectedVoucher = null;
      state.orderCreatedSuccess = null;
      state.paymentType = AppData.paymentType.bankTransfer;
    },

    setSelectedOrderInfo: (state, action) => {
      state.selectedOrderInfo = action.payload;
    },
  },
  extraReducers: {
    // lay danh sach goi dich vu
    [thunkGetServicePackList.rejected]: (state, action) => {
      state.isGettingServicePackList = false;
    },
    [thunkGetServicePackList.pending]: (state, action) => {
      state.isGettingServicePackList = true;
    },
    [thunkGetServicePackList.fulfilled]: (state, action) => {
      state.isGettingServicePackList = false;
      const { result, packages } = action?.payload;
      if (result === 'success' && packages) {
        state.servicePackList = packages;
      }
    },

    // lay danh sach voucher
    [thunkGetVoucherList.rejected]: (state, action) => {
      state.isGettingVoucherList = false;
    },
    [thunkGetVoucherList.pending]: (state, action) => {
      state.isGettingVoucherList = true;
    },
    [thunkGetVoucherList.fulfilled]: (state, action) => {
      state.isGettingVoucherList = false;
      const { result, vouchers } = action?.payload;
      if (result === 'success' && vouchers) {
        state.voucherList = vouchers;
      }
    },

    // tao don hang
    [thunkCreateOrder.rejected]: (state, action) => {
      state.isCreatingOrder = false;
    },
    [thunkCreateOrder.pending]: (state, action) => {
      state.isCreatingOrder = true;
    },
    [thunkCreateOrder.fulfilled]: (state, action) => {
      state.isCreatingOrder = false;
      const { result, order, vnpayUrl } = action?.payload;
      if (result === 'success' && order) {
        state.orderCreatedSuccess = { ...order, vnpayUrl };
      }
    },

    // huy don hang
    [thunkCancelOrder.rejected]: (state, action) => {
      state.isCancelingOrder = false;
    },
    [thunkCancelOrder.pending]: (state, action) => {
      state.isCancelingOrder = true;
    },
    [thunkCancelOrder.fulfilled]: (state, action) => {
      state.isCancelingOrder = false;
    },
  },
});

const { actions, reducer } = paymentSlice;
export const {
  addPackageToOrder,
  removeOrderData,
  removePackage,
  changeOrderPackageQuantity,
  copyFromOrderToCart,
  copyFromCartToOrder,
  removeShoppingCartData,
  updateConfirmPaymentInfo,
  clearConfirmPaymentInfo,
  updateReadyToPurchaseOrder,
  updatePaymentType,
  updateSelectedVoucher,
  clearOrderData,
  setSelectedOrderInfo,
} = actions;
export default reducer;
