import _ from 'lodash';
import axiosClient from './axiosClient';

const accountApi = {
  // dang nhap
  signIn: (taxCode, username, password) => {
    const url = '/account/login';
    const params = {
      taxCode,
      companyUsername: username,
      password,
    };
    return axiosClient.post(url, params);
  },

  // dang ky tai khoan
  signUp: (params) => {
    const url = '/account/register';
    return axiosClient.post(url, params);
  },

  // lay thong tin profile
  getProfile: () => {
    const url = '/account/profile';
    return axiosClient.get(url);
  },

  // dang xuat
  signOut: () => {
    const url = '/account/logout';
    return axiosClient.post(url);
  },

  // doi mat khau
  changePassword: (oldPassword, newPassword) => {
    const params = {
      oldPassword,
      newPassword,
    };
    const url = '/account/change-password';
    return axiosClient.put(url, params);
  },

  // cap nhat thong tin
  updateInfo: (params) => {
    const url = '/account/update';
    const formData = new FormData();
    _.entries(params).forEach(([key, value]) => {
      if (!_.isNull(value) && !_.isUndefined(value)) formData.set(key, value);
    });
    return axiosClient.put(url, formData);
  },

  // doi co cau to chuc
  changeOrganization: (organizationId = null, organizationDepartmentId = null) => {
    const params = {
      organizationId,
      organizationDepartmentId,
    };
    const url = '/account/change-organization';
    return axiosClient.put(url, params);
  },

  // lay danh sach nhat ky dang nhap
  getLoggedInLogs: (params) => {
    const url = '/auth-log/mine';
    return axiosClient.get(url, { params });
  },

  // lay danh sach thiet bi da dang nhap
  getLoggedInDevices: (params) => {
    const url = '/auth-log/mine-devices';
    return axiosClient.get(url, { params });
  },

  // lay lich su cap tai nguyen
  getResourceHistory: (params = {}) => {
    const url = '/resource-history/find';
    return axiosClient.get(url, { params });
  },

  // gui yc reset mat khau
  requestResetPassword: (params) => {
    const url = '/account/request-reset-password';
    return axiosClient.post(url, params);
  },

  // reset mat khau
  resetPassword: (params) => {
    const url = '/account/reset-password';
    return axiosClient.post(url, params);
  },

  // check accessToken
  validateAccessToken: (accessToken) => {
    const url = `/account/validate-access-token?accessToken=${accessToken}`;
    return axiosClient.get(url);
  },
};

export default accountApi;
