import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIButton from 'general/components/VietInvoice/VIButton';
import SupplierHelper from 'general/helpers/SupplierHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

ModalContentSupplierConnect.propTypes = {
  item: PropTypes.object,
  onPressCancel: PropTypes.func,
  initialConnection: PropTypes.object,
};

ModalContentSupplierConnect.defaultProps = {
  item: null,
  onPressCancel: null,
  initialConnection: null,
};

/**
 *
 * @param {{
 * item: object,
 * onPressCancel: function,
 * initialConnection: object,
 * }} props
 * @returns
 */
function ModalContentSupplierConnect(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { item, onPressCancel, initialConnection } = props;
  const isEditMode = !_.isEmpty(initialConnection);

  // ---- methods -----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          url: item?.urls[0],
          username: initialConnection?.usernameConnection ?? '', // 0106870211_999
          password: initialConnection?.passwordConnection ?? '', // 12345678
        }}
        validationSchema={Yup.object({
          url: Yup.string().required(t('Required')),
          username: Yup.string().required(t('Required')),
          password: Yup.string().required(t('Required')),
        })}
        enableReinitialize
        onSubmit={async (values) => {
          const params = {
            supplierId: item?.supplierId,
            url: item?.urls[0],
            passwordConnection: values.password,
            usernameConnection: values.username,
          };
          // console.log({ params, initialConnection });
          // return;
          let res = null;
          if (!isEditMode) {
            res = await SupplierHelper.callApiConnectSupplier(params);
          } else {
            // ket noi lai
            res = await SupplierHelper.callApiUpdateConnectionSupplier(
              initialConnection?.connectionSupplierId,
              params
            );
          }
          if (res) {
            ToastHelper.showSuccess(t('ConnectSupplierSuccess'));
            handlePressCancel();
            SupplierHelper.callApiGetAllConnectionSuppliers();
          }
        }}
      >
        {(formikProps) => {
          return (
            <div>
              <div
                className="p-4 gutter-b"
                style={{
                  backgroundColor: '#F8F8F8',
                  borderRadius: '12px',
                }}
              >
                <p className="m-0">
                  Vui lòng thực hiện kết nối để ICORP giúp bạn tự động cập nhật hóa đơn nhanh nhất
                </p>
              </div>

              <div className="">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                      <img src={Utils.getFullUrl(item?.logo)} className="w-100" />
                    </div>
                  </div>
                  <div className="col-lg-6 mt-6 mt-lg-0">
                    {/* link ket noi */}
                    <KTFormGroup
                      label={
                        <>
                          {t('LinkToLookupWebsite')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="url"
                      inputElement={
                        <FastField name="url">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder=""
                              disabled
                            />
                          )}
                        </FastField>
                      }
                    />

                    {/* ten dang nhap */}
                    <KTFormGroup
                      label={
                        <>
                          {t('Username')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="username"
                      inputElement={
                        <FastField name="username">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${t('Username')}...`}
                            />
                          )}
                        </FastField>
                      }
                    />

                    {/* mat khau */}
                    <KTFormGroup
                      label={
                        <>
                          {t('Password')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="password"
                      inputElement={
                        <FastField name="password">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.password}
                              placeholder={`${t('Password')}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                </div>
              </div>

              {/* actions */}
              <div className="d-flex align-items-center justify-content-end gap-3 mt-3">
                <VIButton
                  text={t('Cancel')}
                  className="btn-grey"
                  style={{ padding: '8px' }}
                  onClick={handlePressCancel}
                />
                <VIButton
                  text={t('Connect')}
                  className=""
                  style={{ padding: '8px' }}
                  onClick={() => {
                    formikProps.handleSubmit();
                  }}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default ModalContentSupplierConnect;
