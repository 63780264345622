import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
VIBodyIcons.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  IconArr: PropTypes.array,
};

VIBodyIcons.defaultProps = {
  id: null,
  IconArr: [],
  onClick: null,
  disabled: false,
};

/**
 * @param {string} id Select id
 * @param {function} onClick Handle when click button
 * @param {boolean} disabled
 * @param {array} IconArr data array item
 *
 */

function VIBodyIcons(props) {
  const { id, IconArr, onClick, disabled } = props;
  return (
    <div id={id} className="VI-body-icons">
      {IconArr?.map((item, index) => (
        <div
          key={index}
          className="d-flex align-items-center font-size-lg mx-2 items-icon"
          onClick={() => onClick(item)}
        >
          <div className="icons d-flex align-items-center justify-content-center">{item?.icon}</div>
          <span className="">{item?.name}</span>
        </div>
      ))}
    </div>
  );
}

export default VIBodyIcons;
