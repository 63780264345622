import { unwrapResult } from '@reduxjs/toolkit';
import { removeAxiosAccessToken, updateAxiosAccessToken } from 'api/axiosClient';
import store from 'app/store';
import { thunkGetLoggedInDevices, thunkGetLoggedInLogs } from 'features/Account/accountSlice';
import {
  authSignOut,
  thunkChangeOrganization,
  thunkChangePassword,
  thunkGetProfile,
  thunkRequestResetPassword,
  thunkResetPassword,
  thunkSignOut,
  thunkUpdateProfile,
} from 'features/Auth/authSlice';
import { notificationSignOut } from 'features/Notification/notificationSlice';
import { organizationSignOut } from 'features/Organization/organizationSlice';
import { resetSupplier } from 'features/Supplier/supplierSlice';
import { systemSignOut } from 'features/System/systemSlice';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import Global from 'general/utils/Global';
import { t } from 'i18next';
import moment from 'moment';
import AxionHelper from './AxionHelper';
import CategoryHelper from './CategoryHelper';
import SupplierHelper from './SupplierHelper';
import ToastHelper from './ToastHelper';
import wsHelperInstance from './WebsocketHelper';
import { axionSignOut } from 'features/Axions/axionSlice';
import DashboardHelper from './DashboardHelper';
import OrganizationHelper from './OrganizationHelper';
import MailHelper from './MailHelper';
import { resetHasMail, setSelectedMail } from 'features/Mail/mailSlice';

const AccountHelper = {
  // dang xuat
  signOut: () => {
    localStorage.removeItem(PreferenceKeys.accessToken);
    localStorage.removeItem(PreferenceKeys.accessTokenExpired);
    store.dispatch(thunkSignOut());
    store.dispatch(authSignOut());
    store.dispatch(organizationSignOut());
    store.dispatch(systemSignOut());
    store.dispatch(notificationSignOut());
    store.dispatch(axionSignOut());
    removeAxiosAccessToken();
    wsHelperInstance.logout();
  },

  // kiem tra access token hop le
  checkAccessTokenValid: () => {
    const accessToken = AccountHelper.getAccessToken();
    const accessTokenExpireDate = AccountHelper.getAccessTokenExpireDate();
    if (accessToken && accessTokenExpireDate) {
      const momentNow = moment();
      const momentExpired = moment(accessTokenExpireDate);
      return momentExpired.isAfter(momentNow);
    }

    return false;
  },

  // access token
  saveAccessToken: (value) => {
    localStorage.setItem(PreferenceKeys.accessToken, value);
    updateAxiosAccessToken(value);
  },
  getAccessToken: () => {
    return localStorage.getItem(PreferenceKeys.accessToken);
  },

  // access token expire date
  saveAccessTokenExpireDate: (value) => {
    localStorage.setItem(PreferenceKeys.accessTokenExpired, value);
  },
  getAccessTokenExpireDate: () => {
    return localStorage.getItem(PreferenceKeys.accessTokenExpired);
  },

  // account id
  getAccountId: () => {
    return localStorage.getItem(PreferenceKeys.savedLoginAccountId);
  },
  saveAccountId: (value) => {
    localStorage.setItem(PreferenceKeys.savedLoginAccountId, value);
  },
  removeAccountId: () => {
    localStorage.removeItem(PreferenceKeys.savedLoginAccountId);
  },

  // email
  getAccountEmail: () => {
    return localStorage.getItem(PreferenceKeys.savedLoginEmail);
  },
  getAccountTaxCode: () => {
    return localStorage.getItem(PreferenceKeys.savedLoginTaxCode);
  },
  saveAccountEmail: (value) => {
    localStorage.setItem(PreferenceKeys.savedLoginEmail, value);
  },
  saveAccountTaxCode: (value) => {
    localStorage.setItem(PreferenceKeys.savedLoginTaxCode, value);
  },
  removeAccountEmail: () => {
    localStorage.removeItem(PreferenceKeys.savedLoginEmail);
  },

  // doi co cau to chuc thanh cong
  onChangeOrganizationSuccess: () => {
    store.dispatch(resetSupplier());
    // lay danh sach ket noi nha cung cap
    SupplierHelper.callApiGetAllConnectionSuppliers();
    SupplierHelper.callApiGetConnectionSupplierHistory(Global.gFiltersConnectionSupplierHistory);
    // lay danh sach ncc/khach hang
    CategoryHelper.callApiGetSupplierCustomerList(Global.gFiltersSupplierAndCustomer);
    if (Global.gTempStateAddAccountAndCreateOrganization) {
      Global.gTempStateAddAccountAndCreateOrganization = false;
      // goi api dong bo lan dau sau khi ket noi va tao co cau to chuc
      AxionHelper.callApiSync({
        from: Global.gFiltersAxionSync.from,
        to: Global.gFiltersAxionSync.to,
      });
      // lay ds hoa don dong bo cqt
      AxionHelper.callApiGetConnectionAxionHistory('IN', Global.gFiltersAxionInputInvoice);
      AxionHelper.callApiGetConnectionAxionHistory('OUT', Global.gFiltersAxionOutputInvoice);
    } else {
      // lay ds hoa don dong bo cqt
      AxionHelper.callApiGetConnectionAxionHistory('IN', Global.gFiltersAxionInputInvoice);
      AxionHelper.callApiGetConnectionAxionHistory('OUT', Global.gFiltersAxionOutputInvoice);
    }
    // load lai thong ke dashboard
    DashboardHelper.callApiGetInvoiceStatistic(Global.gFiltersDashboardInvoiceStatistic);
    DashboardHelper.callApiGetInvoiceStatisticByTime(
      Global.gFiltersDashboardInvoiceByTimeStatistic
    );
    DashboardHelper.callApiGetInvoiceValueStatistic(Global.gFiltersDashboardInvoiceValueStatistic);

    OrganizationHelper.callApiGetListSupplierNotDownloadInvoice();
    OrganizationHelper.callApiGetListAutoTransferInvoiceDepartment();
    OrganizationHelper.callApiGetListAutoTransferByBuyerTaxCode();

    // load lai ds mail
    MailHelper.callApiGetInboxMailList(Global.gFiltersInboxMailList);
    MailHelper.callApiGetImportantMailList(Global.gFiltersImportantMailList);
    MailHelper.callApiGetSpamMailList(Global.gFiltersSpamMailList);
    store.dispatch(setSelectedMail(null));
    store.dispatch(resetHasMail());
  },

  // goi api lay lai profile
  callApiReloadProfile: () => {
    store.dispatch(thunkGetProfile());
  },

  // goi api doi mat khau
  callApiChangePassword: async (oldPassword, newPassword) => {
    try {
      const res = unwrapResult(
        await store.dispatch(thunkChangePassword({ oldPassword, newPassword }))
      );
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('ChangePasswordSuccess'));
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api cap nhat thong tin profile
  callApiUpdateProfile: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkUpdateProfile(params)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('UpdateProfileSuccess'));
        store.dispatch(thunkGetProfile());
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api doi co cau to chuc
  callApiChangeOrganization: async (organizationId, organizationDepartmentId) => {
    try {
      const res = unwrapResult(
        await store.dispatch(
          thunkChangeOrganization({
            organizationId,
            organizationDepartmentId,
          })
        )
      );
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('ChangeOrganizationSuccess'));
        store.dispatch(thunkGetProfile());
        AccountHelper.onChangeOrganizationSuccess();
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay danh sach nhat ky dang nhap
  callApiGetLoggedInLogs: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetLoggedInLogs(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay danh sach thiet bi da dang nhap
  callApiGetLoggedInDevices: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetLoggedInDevices(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api request reset password
  callApiRequestResetPassword: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkRequestResetPassword(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api reset password
  callApiResetPassword: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkResetPassword(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
};

export default AccountHelper;
