import axionApi from 'api/axionApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// ket noi cqt
export const thunkConnect = createAsyncThunk('axion/connect', async (params, thunkAPI) => {
  const res = await axionApi.connect(params);
  return res;
});

// dong bo cqt
export const thunkSync = createAsyncThunk('axion/sync', async (params, thunkAPI) => {
  const res = await axionApi.sync(params);
  return res;
});

// ngat ket noi cqt
export const thunkDisconnect = createAsyncThunk('axion/disconnect', async (params, thunkAPI) => {
  const res = await axionApi.disconnect(params);
  return res;
});

// lay lich su dong bo
export const thunkGetConnectionAxionInHistory = createAsyncThunk(
  'axion/getConnectionAxionInHistory',
  async (params, thunkAPI) => {
    const res = await axionApi.getConnectionAxionHistory(params);
    return res;
  }
);
export const thunkGetConnectionAxionOutHistory = createAsyncThunk(
  'axion/getConnectionAxionOutHistory',
  async (params, thunkAPI) => {
    const res = await axionApi.getConnectionAxionHistory(params);
    return res;
  }
);

// reload
export const thunkReload = createAsyncThunk('axion/reload', async (id, thunkAPI) => {
  const res = await axionApi.reload(id);
  return res;
});

// ket noi tai khoan moi va tao co cau to chuc
export const thunkConnectAddCreateOrganization = createAsyncThunk(
  'axion/connectAddCreateOrganization',
  async (params) => {
    const res = await axionApi.connectAndCreateOrganization(params);
    return res;
  }
);

const axionSlice = createSlice({
  name: 'axion',
  initialState: {
    // ket noi
    isConnecting: false,

    // ngat ket noi
    isDisconnecting: false,

    // dong bo
    isSynchronizing: false,

    // lich su dong bo
    isGettingConnectionAxionInHistory: false,
    connectionAxionInHistoryInList: [],
    totalConnectionAxionInHistory: 0,

    isGettingConnectionAxionOutHistory: false,
    connectionAxionOutHistoryInList: [],
    totalConnectionAxionOutHistory: 0,

    isReloading: false,

    totalHDDV: 0,
    totalHDDR: 0,

    isCreatingMoreAccount: false,
  },
  reducers: {
    axionSignOut: (state, action) => {
      state.isConnecting = false;
      state.isDisconnecting = false;
      state.isSynchronizing = false;
      state.isGettingConnectionAxionInHistory = false;
      state.connectionAxionInHistoryInList = [];
      state.totalConnectionAxionInHistory = 0;
      state.isGettingConnectionAxionOutHistory = false;
      state.connectionAxionOutHistoryInList = [];
      state.totalConnectionAxionOutHistory = 0;
      state.isReloading = false;
      state.totalHDDV = 0;
      state.totalHDDR = 0;
      state.isCreatingMoreAccount = false;
    },
  },
  extraReducers: {
    // ket noi cqt
    [thunkConnect.pending]: (state, action) => {
      state.isConnecting = true;
    },
    [thunkConnect.rejected]: (state, action) => {
      state.isConnecting = false;
    },
    [thunkConnect.fulfilled]: (state, action) => {
      state.isConnecting = false;
      const { result } = action.payload;
      if (result === 'success') {
      }
    },

    // dong bo cqt
    [thunkSync.pending]: (state, action) => {
      state.isSynchronizing = true;
    },
    [thunkSync.rejected]: (state, action) => {
      state.isSynchronizing = false;
    },
    [thunkSync.fulfilled]: (state, action) => {
      state.isSynchronizing = false;
      const { result } = action.payload;
      if (result === 'success') {
      }
    },

    // ngat ket noi cqt
    [thunkDisconnect.pending]: (state, action) => {
      state.isDisconnecting = true;
    },
    [thunkDisconnect.rejected]: (state, action) => {
      state.isDisconnecting = false;
    },
    [thunkDisconnect.fulfilled]: (state, action) => {
      state.isDisconnecting = false;
      const { result } = action.payload;
      if (result === 'success') {
      }
    },

    // lay lich su dong bo
    [thunkGetConnectionAxionInHistory.pending]: (state, action) => {
      state.isGettingConnectionAxionInHistory = true;
    },
    [thunkGetConnectionAxionInHistory.rejected]: (state, action) => {
      state.isGettingConnectionAxionInHistory = false;
    },
    [thunkGetConnectionAxionInHistory.fulfilled]: (state, action) => {
      state.isGettingConnectionAxionInHistory = false;
      const { result, cqtInvoiceHistories, total, totalHDDV, totalHDDR } = action.payload;
      if (result === 'success' && cqtInvoiceHistories) {
        state.connectionAxionInHistoryInList = cqtInvoiceHistories;
        state.totalConnectionAxionInHistory = total;
        state.totalHDDV = totalHDDV;
        state.totalHDDR = totalHDDR;
      }
    },
    [thunkGetConnectionAxionOutHistory.pending]: (state, action) => {
      state.isGettingConnectionAxionOutHistory = true;
    },
    [thunkGetConnectionAxionOutHistory.rejected]: (state, action) => {
      state.isGettingConnectionAxionOutHistory = false;
    },
    [thunkGetConnectionAxionOutHistory.fulfilled]: (state, action) => {
      state.isGettingConnectionAxionOutHistory = false;
      const { result, cqtInvoiceHistories, total, totalHDDV, totalHDDR } = action.payload;
      if (result === 'success' && cqtInvoiceHistories) {
        state.connectionAxionOutHistoryInList = cqtInvoiceHistories;
        state.totalConnectionAxionOutHistory = total;
        state.totalHDDV = totalHDDV;
        state.totalHDDR = totalHDDR;
      }
    },

    // reload
    [thunkReload.pending]: (state, action) => {
      state.isReloading = true;
    },
    [thunkReload.rejected]: (state, action) => {
      state.isReloading = false;
    },
    [thunkReload.fulfilled]: (state, action) => {
      state.isReloading = false;
      const { result } = action.payload;
      if (result === 'success') {
      }
    },

    // ket noi vao tao co cau to chuc
    [thunkConnectAddCreateOrganization.pending]: (state, action) => {
      state.isCreatingMoreAccount = true;
    },
    [thunkConnectAddCreateOrganization.rejected]: (state, action) => {
      state.isCreatingMoreAccount = false;
    },
    [thunkConnectAddCreateOrganization.fulfilled]: (state, action) => {
      state.isCreatingMoreAccount = false;
      const { result } = action.payload;
      if (result === 'success') {
      }
    },
  },
});

const { reducer, actions } = axionSlice;
export const { axionSignOut } = actions;
export default reducer;
