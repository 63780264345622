import axiosClient from './axiosClient';

const toolApi = {
  // lay ds van ban huong dan giam thue GTGT
  getTaxReducedDocuments: (params) => {
    const url = '/tax-reduced-document/find';
    return axiosClient.get(url, { params });
  },

  // lay ds hang hoa, dich vu giam thue GTGT
  getTaxReducedItems: (params) => {
    const url = '/product/find';
    return axiosClient.get(url, { params });
  },

  // lay ds doanh nghiep rui ro
  getRiskyBusiness: (params) => {
    const url = '/risky-company/find';
    return axiosClient.get(url, { params });
  },
};

export default toolApi;
