import KTAdvanceNav, {
  KTAdvanceNavFontWeight,
} from 'general/components/OtherKeenComponents/KTAdvanceNav';
import useAccount from 'hooks/useAccount';
import useRouter from 'hooks/useRouter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import SystemAuditingLogScreen from './screens/SystemAuditingLogScreen';
import SystemResourceScreen from './screens/SystemResourceScreen';
import SystemRoleScreen from './screens/SystemRoleScreen';
import SystemSettingScreen from './screens/SystemSettingScreen';
import SystemUserScreen from './screens/SystemUserScreen';

function System(props) {
  // ---- params ----
  const { t } = useTranslation();
  const router = useRouter();
  const { pathname } = router;
  const account = useAccount();
  const navItemList = useMemo(() => {
    const itemList = [];
    if (account.isAdmin) {
      itemList.push([
        {
          text: t('ManageUser'),
          active: pathname.includes('/user'),
          iconElement: <i className="fa-regular fa-user" />,
          icon: 'fa-regular fa-user',
          onPress: () => {
            router.navigate('user');
          },
        },
        {
          text: t('ManageRole'),
          active: pathname.includes('/role'),
          iconElement: <i className="fa-regular fa-shield" />,
          icon: 'fa-regular fa-shield',
          onPress: () => {
            router.navigate('role');
          },
        },
      ]);
    }
    itemList.push([
      {
        text: t('ManageResource'),
        active: pathname.includes('/resource'),
        iconElement: <i className="fa-regular fa-cart-shopping" />,
        icon: 'fa-regular fa-cart-shopping',
        onPress: () => {
          router.navigate('resource');
        },
      },
      {
        text: t('AuditingLog'),
        active: pathname.includes('/auditing-log'),
        iconElement: <i className="fa-regular fa-rectangle-history" />,
        icon: 'fa-regular fa-rectangle-history',
        onPress: () => {
          router.navigate('auditing-log');
        },
      },
      {
        text: t('Optional'),
        active: pathname.includes('/setting'),
        iconElement: <i className="fa-regular fa-cog" />,
        icon: 'fa-regular fa-cog',
        onPress: () => {
          router.navigate('setting');
        },
      },
    ]);
    return _.flatten(itemList);
  }, [t, account, pathname]);

  return (
    <div className="d-flex flex-row h-100">
      {/* aside menu */}
      <div className="flex-row-auto offcanvas-mobile h-100">
        <div
          className="card card-custom h-100"
          style={{
            borderRadius: '16px',
          }}
        >
          <div className="card-body p-4 mt-6">
            <KTAdvanceNav
              enableHoverStyle
              enableLinkRounded={true}
              enableActiveStyle
              enableAccentStyle
              fontWeight={KTAdvanceNavFontWeight.bold}
              itemList={navItemList}
            />
          </div>
        </div>
      </div>

      {/* content */}
      <div
        className="flex-row-fluid ml-lg-6"
        style={{
          borderRadius: '16px',
        }}
      >
        <div className={`h-100 ${pathname.includes('/setting') ? '' : ''}`}>
          <Routes>
            <Route path="" element={<Navigate to={account.isAdmin ? 'user' : 'resource'} />} />
            <Route path="user/*" element={<SystemUserScreen navItemList={navItemList} />} />
            <Route path="role/*" element={<SystemRoleScreen navItemList={navItemList} />} />
            <Route path="resource/*" element={<SystemResourceScreen navItemList={navItemList} />} />
            <Route
              path="auditing-log/*"
              element={<SystemAuditingLogScreen navItemList={navItemList} />}
            />
            <Route path="setting/*" element={<SystemSettingScreen navItemList={navItemList} />} />

            <Route path="*" element={<Navigate to="user" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default System;
