import { FastField, Formik } from 'formik';
import { motion } from 'framer-motion';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIButton from 'general/components/VietInvoice/VIButton';
import AccountHelper from 'general/helpers/AccountHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Utils from 'general/utils/Utils';
import useRouter from 'hooks/useRouter';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';

ResetPasswordForm.propTypes = {};

function ResetPasswordForm(props) {
  // ---- params ----
  const { t } = useTranslation();
  const router = useRouter();
  const {} = props;
  const { isResettingPassword } = useSelector((state) => state.auth);

  // ---- methods ----

  return (
    <motion.div
      className="login-form"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      <Formik
        initialValues={{
          code: '',
          newPassword: '',
          retypeNewPassword: '',
        }}
        validationSchema={Yup.object({
          code: Yup.string().required(t('Required')),
          newPassword: Yup.string().required(t('PasswordRequired')),
          retypeNewPassword: Yup.string()
            .required(t('PasswordRequired'))
            .oneOf([Yup.ref('newPassword')], t('PasswordNotMatch')),
        })}
        onSubmit={async (values) => {
          const params = {
            resetCode: values.code,
            newPassword: Utils.sha256(values.newPassword),
            email: AccountHelper.getAccountEmail(),
            taxCode: AccountHelper.getAccountTaxCode(),
          };
          // console.log({ params });
          // return;
          const res = await AccountHelper.callApiResetPassword(params);
          if (res) {
            // reset password thanh cong
            ToastHelper.showSuccess(t('ResetPasswordSuccess'));
            router.navigate('/auth/sign-in', { replace: true });
            AccountHelper.removeAccountEmail();
          }
        }}
      >
        {(formikProps) => (
          <form
            className="form fv-plugins-bootstrap fv-plugins-framework"
            onSubmit={formikProps.handleSubmit}
          >
            {/* title */}
            <div className="pb-6 pt-lg-0 pt-5">
              <h3 className="font-weight-bolder text-dark font-size-h5 font-size-h2-lg">
                {t('ResetPassword')}
              </h3>
              <p className="text-dark font-weight-bold font-size-h6 mb-0">
                {t('EnterCodeToResetPassword')}
              </p>
            </div>

            {/* code */}
            <KTFormGroup
              inputName="code"
              label={
                <>
                  {t('ResetPasswordCode')} <span className="text-danger">(*)</span>
                </>
              }
              additionalClassName="fv-plugins-icon-container"
              // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
              inputElement={
                <FastField name="code">
                  {({ field, form, meta }) => (
                    <KTFormInput
                      name={field.name}
                      value={field.value}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      onBlur={() => {
                        form.setFieldTouched(field.name, true);
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(meta.error)}
                      isTouched={meta.touched}
                      feedbackText={meta.error}
                      // additionalInputClassName="h-auto p-6 rounded-lg"
                    />
                  )}
                </FastField>
              }
            />

            {/* mat khau moi */}
            <KTFormGroup
              inputName="newPassword"
              label={
                <>
                  {t('Password')} <span className="text-danger">(*)</span>
                </>
              }
              additionalClassName="fv-plugins-icon-container mb-4"
              // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
              inputElement={
                <FastField name="newPassword">
                  {({ field, form, meta }) => (
                    <KTFormInput
                      name={field.name}
                      value={field.value}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      onBlur={() => {
                        form.setFieldTouched(field.name, true);
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(meta.error)}
                      isTouched={meta.touched}
                      feedbackText={meta.error}
                      type={KTFormInputType.password}
                      // additionalInputClassName="h-auto p-6 rounded-lg"
                    />
                  )}
                </FastField>
              }
            />

            {/* xac nhan mat khau */}
            <KTFormGroup
              inputName="retypeNewPassword"
              label={
                <>
                  {t('ConfirmPassword')} <span className="text-danger">(*)</span>
                </>
              }
              additionalClassName="fv-plugins-icon-container mb-4"
              // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
              inputElement={
                <FastField name="retypeNewPassword">
                  {({ field, form, meta }) => (
                    <KTFormInput
                      name={field.name}
                      value={field.value}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      onBlur={() => {
                        form.setFieldTouched(field.name, true);
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(meta.error)}
                      isTouched={meta.touched}
                      feedbackText={meta.error}
                      type={KTFormInputType.password}
                      // additionalInputClassName="h-auto p-6 rounded-lg"
                    />
                  )}
                </FastField>
              }
            />

            <div className="form-group d-flex flex-wrap pb-lg-0 mb-0">
              <VIButton
                text={isResettingPassword ? `${t('Resetting')}...` : t('Reset')}
                className="btn-blue w-100"
                style={{ padding: '10px' }}
                disabled={isResettingPassword}
                beforeIcon={isResettingPassword ? <ClockLoader color="white" size={20} /> : <></>}
              />
            </div>
          </form>
        )}
      </Formik>
    </motion.div>
  );
}

export default ResetPasswordForm;
