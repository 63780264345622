import React from 'react';
import PropTypes from 'prop-types';

LookupRiskyBusinessScreen.propTypes = {};

function LookupRiskyBusinessScreen(props) {
  return <div>LookupRiskyBusinessScreen</div>;
}

export default LookupRiskyBusinessScreen;
