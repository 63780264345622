import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';
import EmptyView from 'features/Others/components/EmptyView';
import SupplierAndCustomerScreen from './screens/SupplierScreen';

Category.propTypes = {};

function Category(props) {
  return (
    <Routes>
      <Route path="" element={<Navigate to="supplier-customer" />} />
      <Route path="supplier-customer" element={<SupplierAndCustomerScreen />} />

      <Route path="*" element={<EmptyView />} />
    </Routes>
  );
}

export default Category;
