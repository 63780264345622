import axiosClient from './axiosClient';

const categoryApi = {
  // lay danh sach ncc/khach hang
  getSupplierCustomerList: (params) => {
    const url = '/partner-company/find';
    return axiosClient.get(url, { params });
  },

  // lay chi tiet theo id
  getSupplierCustomerDetail: (id) => {
    const url = `/partner-company/detail/${id}`;
    return axiosClient.get(url);
  },

  // cap nhat thong tin
  updateSupplierCustomerInfo: (id, params) => {
    const url = `/partner-company/update/${id}`;
    return axiosClient.put(url, params);
  },

  // them ncc
  create: (params) => {
    const url = '/partner-company/create';
    return axiosClient.post(url, params);
  },

  // xoa nhieu
  deleteSupplierCustomers: (ids) => {
    const url = '/partner-company/delete';
    return axiosClient.delete(url, {
      data: {
        partnerCompanyIds: ids,
      },
    });
  },

  // kiem tra
  check: () => {
    const url = '/partner-company/synchronize';
    return axiosClient.post(url);
  },

  // import file tai len
  import: (file) => {
    const url = '/partner-company/import';
    const formData = new FormData();
    formData.set('file', file);
    return axiosClient.post(url, formData);
  },
};

export default categoryApi;
