import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import _ from 'lodash';
import PropTypes, { func } from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

ViewModalListAccount.propTypes = {
  connectionList: PropTypes.array,
  onReconnect: PropTypes.func,
  onDisconnect: PropTypes.func,
  onAddAccount: PropTypes.func,
};

ViewModalListAccount.defaultProps = {
  connectionList: [],
  onReconnect: null,
  onDisconnect: null,
  onAddAccount: null,
};

/**
 *
 * @param {{
 * connectionList: {}[]
 * onReconnect: function,
 * onDisconnect: function,
 * onAddAccount: function,
 * }} props
 * @returns
 */
function ViewModalListAccount(props) {
  // ----- params -----
  const { t } = useTranslation();
  const { connectionList, onReconnect, onDisconnect, onAddAccount } = props;
  const columns = useMemo(() => {
    return [
      {
        name: t('STT'),
        sortable: false,
        width: '90px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {index + 1}
            </p>
          );
        },
      },
      {
        name: t('Account'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0 font-weight-bold">
              {row?.usernameConnection}
            </p>
          );
        },
      },
      {
        name: t('Password'),
        sortable: false,
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {_.repeat('*', row?.passwordConnection?.length)}
            </p>
          );
        },
      },
      {
        name: t('State'),
        sortable: false,
        width: '160px',
        style: {
          borderRight: 'none',
        },
        cell: (row) => {
          return (
            <div
              className="d-flex align-items-center gap-2"
              style={{
                color: '#28A043',
              }}
            >
              <i
                className="fa-regular fa-circle-check"
                style={{
                  color: 'inherit',
                }}
              />{' '}
              {t('Active')}
            </div>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '80px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            <KTTooltip text={t('ReConnect')}>
              <i
                className="far fa-rotate-left text-primary"
                onClick={() => {
                  handleReconnect(row);
                }}
              />
            </KTTooltip>
            <KTTooltip text={t('Disconnect')}>
              <i
                className="far fa-link-simple-slash text-danger"
                onClick={() => {
                  handleDisconnect(row);
                }}
              />
            </KTTooltip>
          </div>
        ),
      },
    ];
  }, [t]);

  // ----- methods -----
  function handleReconnect(row) {
    if (onReconnect) {
      onReconnect(row);
    }
  }

  function handleDisconnect(row) {
    if (onDisconnect) {
      onDisconnect(row);
    }
  }

  function handleAddAccount(row) {
    if (onAddAccount) {
      onAddAccount(row);
    }
  }

  return (
    <div>
      <p className="m-0">Bạn có thể kết nối nhiều tài khoản để lấy về hóa đơn</p>
      <div
        className="overflow-auto"
        style={{
          maxHeight: '70vh',
        }}
      >
        <VIDataTable
          draggable={false}
          tableStyles={customDataTableActionStyle}
          additionalClassName="p-0 shadow-none"
          additionalCardBodyClassName="p-0"
          selectable={false}
          pagination={null}
          onChangePage={(page) => {}}
          onChangeRowsPerPage={(perPage) => {}}
          fullColumns={columns}
          data={connectionList}
          loading={false}
          onRowClick={(row) => {}}
          onSetSelectedMultiItems={(rows) => {}}
          haveCardHeader={false}
        />
      </div>

      {/* actions */}
      <div className="d-flex align-items-center justify-content-between gap-3">
        <VIButton
          text={t('AddAccount')}
          className=""
          style={{ padding: '8px' }}
          onClick={handleAddAccount}
        />
      </div>
    </div>
  );
}

export default ViewModalListAccount;
