import KTCheckbox, {
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import VIButton from 'general/components/VietInvoice/VIButton';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

ViewChooseInvoiceDownloadType.propTypes = {
  onPressedCancel: PropTypes.func,
  invoiceDownloadTypes: PropTypes.object,
  onSubmit: PropTypes.func,
};

ViewChooseInvoiceDownloadType.defaultProps = {
  onPressedCancel: null,
  invoiceDownloadTypes: null,
  onSubmit: null,
};

/**
 *
 * @param {{
 * onPressedCancel: function,
 * invoiceDownloadTypes: object,
 * onSubmit: function,
 * }} props
 * @returns
 */
function ViewChooseInvoiceDownloadType(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { onPressedCancel, invoiceDownloadTypes, onSubmit } = props;
  const refInvoiceDownloadTypes = useRef();
  const [flag, setFlag] = useState({});

  useEffect(() => {
    refInvoiceDownloadTypes.current = { ...invoiceDownloadTypes };
    setFlag({});
  }, [invoiceDownloadTypes]);

  // ---- methods ----
  function handlePressedCancel() {
    if (onPressedCancel) {
      onPressedCancel();
    }
  }

  function handleSave() {
    if (onSubmit) {
      onSubmit(refInvoiceDownloadTypes.current);
      handlePressedCancel();
    }
  }

  return (
    <div>
      <div>
        {Object.keys(refInvoiceDownloadTypes.current ?? {}).map((key, indexKey) => {
          const value = refInvoiceDownloadTypes.current[key];
          return (
            <div key={indexKey} className="py-2">
              <div className="mb-2">
                <KTCheckbox
                  name={`checkbox_${indexKey}`}
                  label={t(key)}
                  type={KTCheckboxType.default}
                  checked={
                    _.isBoolean(value)
                      ? value
                      : _.some(Object.keys(value), (item) => value[item] === true)
                  }
                  onChange={(value) => {
                    if (_.isBoolean(refInvoiceDownloadTypes.current[key])) {
                      refInvoiceDownloadTypes.current[key] = value;
                    } else if (_.isObject(refInvoiceDownloadTypes.current[key])) {
                      const keys = Object.keys(refInvoiceDownloadTypes.current[key]);
                      keys.forEach((item) => {
                        refInvoiceDownloadTypes.current[key] = {
                          ...refInvoiceDownloadTypes.current[key],
                          [item]: value,
                        };
                      });
                      setFlag({});
                    }
                  }}
                  additionalClassName="switch-primary mr-4"
                />
              </div>
              {_.isObject(value) && (
                <div className="px-10 d-flex align-items-center gap-5">
                  {Object.keys(value).map((key2, indexKey2) => {
                    const value2 = value[key2];
                    return (
                      <div key={`${indexKey}_${indexKey2}`} className="py-2">
                        <KTCheckbox
                          name={`checkbox_${indexKey}_${indexKey2}`}
                          label={t(key2)}
                          type={KTCheckboxType.default}
                          checked={value2}
                          onChange={(value) => {
                            refInvoiceDownloadTypes.current[key] = {
                              ...refInvoiceDownloadTypes.current[key],
                              [key2]: value,
                            };
                            setFlag({});
                          }}
                          additionalClassName="switch-primary mr-4"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* actions */}
      <div className="d-flex align-items-center justify-content-end gap-3 mt-3">
        <VIButton
          text={t('Cancel')}
          className="btn-grey"
          style={{ padding: '8px' }}
          onClick={handlePressedCancel}
        />
        <VIButton text={t('Save')} className="" style={{ padding: '8px' }} onClick={handleSave} />
      </div>
    </div>
  );
}

export default ViewChooseInvoiceDownloadType;
