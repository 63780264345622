import { unwrapResult } from '@reduxjs/toolkit';
import store from 'app/store';
import { setTotalUnreadMail } from 'features/Auth/authSlice';
import {
  setImportantMail,
  setMailStatusChecking,
  setMarkingImportantMailIds,
  setMarkingReadMailIds,
  setMovingMailTrashIds,
  setReadMail,
  setSelectedMail,
  setUnImportantMail,
  setUnReadMail,
  setValidatingMailIds,
  thunkDeleteMail,
  thunkForwardMail,
  thunkGetImportantMailList,
  thunkGetInboxMailList,
  thunkGetSpamMailList,
  thunkMarkImportant,
  thunkMarkReadMail,
  thunkMarkUnImportant,
  thunkMarkUnReadMail,
  thunkMoveMailIntoTrash,
  thunkRestoreMailFromTrash,
  thunkValidateMails,
} from 'features/Mail/mailSlice';
import AppData from 'general/constants/AppData';
import Global from 'general/utils/Global';
import ToastHelper from './ToastHelper';

const MailHelper = {
  // goi api lay ds hop thu
  callApiGetInboxMailList: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetInboxMailList(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay ds thu quan trong
  callApiGetImportantMailList: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetImportantMailList(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay ds thu rac
  callApiGetSpamMailList: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetSpamMailList(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api danh dau thu quan trong
  callApiMarkMailImportant: async (ids) => {
    try {
      store.dispatch(setMarkingImportantMailIds(ids));
      const res = unwrapResult(await store.dispatch(thunkMarkImportant(ids)));
      const { result } = res;
      if (result === 'success') {
        store.dispatch(setImportantMail(ids));
        MailHelper.callApiGetImportantMailList(Global.gFiltersImportantMailList);
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
  // goi api danh dau thu khong quan trong
  callApiMarkMailUnImportant: async (ids) => {
    try {
      store.dispatch(setMarkingImportantMailIds(ids));
      const res = unwrapResult(await store.dispatch(thunkMarkUnImportant(ids)));
      const { result } = res;
      if (result === 'success') {
        store.dispatch(setUnImportantMail(ids));
        MailHelper.callApiGetImportantMailList(Global.gFiltersImportantMailList);
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api danh dau thu da doc
  callApiMarkMailRead: async (ids) => {
    try {
      store.dispatch(setMarkingReadMailIds(ids));
      const res = unwrapResult(await store.dispatch(thunkMarkReadMail(ids)));
      const { result, totalUnreadEmail } = res;
      if (result === 'success') {
        store.dispatch(setReadMail(ids));
        if (!_.isUndefined(totalUnreadEmail)) {
          store.dispatch(setTotalUnreadMail(totalUnreadEmail));
        }
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api danh dau thu chua doc
  callApiMarkMailUnRead: async (ids) => {
    try {
      store.dispatch(setMarkingReadMailIds(ids));
      const res = unwrapResult(await store.dispatch(thunkMarkUnReadMail(ids)));
      const { result, totalUnreadEmail } = res;
      if (result === 'success') {
        store.dispatch(setUnReadMail(ids));
        if (!_.isUndefined(totalUnreadEmail)) {
          store.dispatch(setTotalUnreadMail(totalUnreadEmail));
        }
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api di chuyen thu vao thung rac
  callApiMoveMailIntoTrash: async (ids) => {
    try {
      store.dispatch(setMovingMailTrashIds(ids));
      const res = unwrapResult(await store.dispatch(thunkMoveMailIntoTrash(ids)));
      const { result } = res;
      if (result === 'success') {
        MailHelper.callApiGetInboxMailList(Global.gFiltersInboxMailList);
        MailHelper.callApiGetImportantMailList(Global.gFiltersImportantMailList);
        MailHelper.callApiGetSpamMailList(Global.gFiltersSpamMailList);

        const selectedMail = store.getState().mail.selectedMail;
        if (selectedMail && ids.includes(selectedMail.mailId)) {
          store.dispatch(setSelectedMail(null));
        }
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api khoi phuc thu tu thung rac
  callApiRestoreMailFromTrash: async (ids) => {
    try {
      store.dispatch(setMovingMailTrashIds(ids));
      const res = unwrapResult(await store.dispatch(thunkRestoreMailFromTrash(ids)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess('Khôi phục thư thành công');
        MailHelper.callApiGetInboxMailList(Global.gFiltersInboxMailList);
        MailHelper.callApiGetImportantMailList(Global.gFiltersImportantMailList);
        MailHelper.callApiGetSpamMailList(Global.gFiltersSpamMailList);
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api xoa vinh vien thu
  callApiDeleteMail: async (ids) => {
    try {
      store.dispatch(setMovingMailTrashIds(ids));
      const res = unwrapResult(await store.dispatch(thunkDeleteMail(ids)));
      const { result } = res;
      if (result === 'success') {
        MailHelper.callApiGetSpamMailList(Global.gFiltersSpamMailList);

        const selectedMail = store.getState().mail.selectedMail;
        if (selectedMail && ids.includes(selectedMail.mailId)) {
          store.dispatch(setSelectedMail(null));
        }
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api kiem tra thu
  callApiValidateMail: async (ids) => {
    try {
      store.dispatch(setValidatingMailIds(ids));
      const res = unwrapResult(await store.dispatch(thunkValidateMails(ids)));
      const { result, mails } = res;
      if (result === 'success') {
        // MailHelper.callApiGetInboxMailList(Global.gFiltersInboxMailList);
        // MailHelper.callApiGetImportantMailList(Global.gFiltersImportantMailList);
        // MailHelper.callApiGetSpamMailList(Global.gFiltersSpamMailList);
        store.dispatch(setMailStatusChecking(mails));
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api forward thu
  callApiForwardMail: async (mailId, params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkForwardMail({ mailId: mailId, params })));
      const { result, mails } = res;
      if (result === 'success') {
        ToastHelper.showSuccess('Chuyển tiếp thư thành công');
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // ---- utils ----
  getMailStatusText: (status) => {
    switch (status) {
      case AppData.mailStatus.validInvoice:
        return 'Hoá đơn hợp lệ';
      case AppData.mailStatus.invalidEmail:
        return 'Email không hợp lệ';
      case AppData.mailStatus.invalidInvoice:
        return 'Hoá đơn không hợp lệ';
      case AppData.mailStatus.duplicateInvoice:
        return 'Hoá đơn trùng';
      case AppData.mailStatus.malformedInvoice:
        return 'Không đọc được hoá đơn';
      case AppData.mailStatus.draft:
        return 'Bản nháp';
      case AppData.mailStatus.checking:
        return 'Đang kiểm tra';
      case AppData.mailStatus.expireDate:
        return 'Hết hạn sử dụng';
      case AppData.mailStatus.deleteInvoice:
        return 'Hoá đơn xoá bỏ';
      case AppData.mailStatus.invoiceNotFound:
        return 'Không tìm thấy hoá đơn';
      case AppData.mailStatus.outOfDate:
        return 'Hết tài nguyên sử dụng';
      default:
        return '';
    }
  },
  getMailStatusColor: (status) => {
    switch (status) {
      case AppData.mailStatus.validInvoice:
        return '#20A144';
      case AppData.mailStatus.invalidEmail:
        return '#E54135';
      case AppData.mailStatus.invalidInvoice:
        return '#E54135';
      case AppData.mailStatus.duplicateInvoice:
        return '#5180FB';
      case AppData.mailStatus.malformedInvoice:
        return '#E54135';
      case AppData.mailStatus.draft:
        return '#7177A8';
      case AppData.mailStatus.checking:
        return '#F57E10';
      case AppData.mailStatus.expireDate:
        return '#E54135';
      case AppData.mailStatus.deleteInvoice:
        return '#E54135';
      case AppData.mailStatus.invoiceNotFound:
        return '#E54135';
      case AppData.mailStatus.outOfDate:
        return '#E54135';
      default:
        return '';
    }
  },
  getMailStatusIcon: (status) => {
    switch (status) {
      case AppData.mailStatus.validInvoice:
        return 'fa-regular fa-circle-check';
      case AppData.mailStatus.invalidEmail:
        return 'fa-regular fa-circle-xmark';
      case AppData.mailStatus.invalidInvoice:
        return 'fa-regular fa-circle-xmark';
      case AppData.mailStatus.duplicateInvoice:
        return 'fa-regular fa-copy';
      case AppData.mailStatus.malformedInvoice:
        return 'fa-regular fa-circle-xmark';
      case AppData.mailStatus.draft:
        return 'fa-regular fa-file';
      case AppData.mailStatus.checking:
        return 'fa-regular fa-circle-check';
      case AppData.mailStatus.expireDate:
        return 'fa-regular fa-circle-xmark';
      case AppData.mailStatus.deleteInvoice:
        return 'fa-regular fa-circle-xmark';
      case AppData.mailStatus.invoiceNotFound:
        return 'fa-regular fa-circle-xmark';
      case AppData.mailStatus.outOfDate:
        return 'fa-regular fa-circle-xmark';
      default:
        return '';
    }
  },
  getMailAttachmentIcon: (contentType) => {
    if (contentType.includes('xml')) {
      return 'fa-file-excel';
    }
    if (contentType.includes('pdf')) {
      return 'fa-file-pdf';
    }
    return 'fa-file';
  },
  getMailAttachmentIconColor: (contentType) => {
    if (contentType.includes('xml')) {
      return '#4570FE';
    }
    if (contentType.includes('pdf')) {
      return '#F44141';
    }
    return '#20A144';
  },
};

export default MailHelper;
