import { Route, Routes } from 'react-router-dom';
import FAQHomeScreen from './screens/FAQHomeScreen';

FAQ.propTypes = {};

function FAQ(props) {
  return (
    <Routes>
      <Route path="" element={<FAQHomeScreen />} />
    </Routes>
  );
}

export default FAQ;
