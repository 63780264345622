import axiosClient from './axiosClient';

const auditLogApi = {
  // lay danh sach nhat ky truy cap
  find: (params) => {
    const url = '/audit-log/find';
    return axiosClient.get(url, { params });
  },
};

export default auditLogApi;
