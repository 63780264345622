import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTCheckbox, {
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppConfigs from 'general/constants/AppConfigs';
import OrganizationHelper from 'general/helpers/OrganizationHelper';
import Global from 'general/utils/Global';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

ModalEditBranchContent.propTypes = {
  onPressCancel: PropTypes.func,
  branch: PropTypes.object,
  onCheckedAcceptMultiNameChange: PropTypes.func,
};

ModalEditBranchContent.defaultProps = {
  onPressCancel: null,
  branch: null,
  onCheckedAcceptMultiNameChange: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * branch: object,
 * onCheckedAcceptMultiNameChange: function,
 * }} props
 * @returns
 */
function ModalEditBranchContent(props) {
  // ---- params -----
  const { onPressCancel, branch, onCheckedAcceptMultiNameChange } = props;
  const { t } = useTranslation();
  console.log({ branch });

  // ---- methods -----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  return (
    <div
      className=""
      style={{
        maxHeight: '70vh',
      }}
    >
      <Formik
        initialValues={{
          taxCode: branch?.taxCode ?? '',
          branchBusinessPermitDate: branch?.businessPermitDate ?? '',
          branchName: branch?.organizationName ?? '',
          branchAddress: branch?.businessPermitAddress ?? '',

          representativeName:
            branch?.representativeName ?? Global.gTempOrganizationContactInfo?.name ?? '',
          representativeJobTitle:
            branch?.representativeJobTitle ?? Global.gTempOrganizationContactInfo?.jobTitle ?? '',
          representativeEmail:
            branch?.representativeEmail ?? Global.gTempOrganizationContactInfo?.email ?? '',
          representativePhone:
            branch?.representativePhone ?? Global.gTempOrganizationContactInfo?.phone ?? '',
        }}
        validationSchema={Yup.object({
          taxCode: Yup.string().required(t('Required')),
          branchName: Yup.string().required(t('Required')),
          branchAddress: Yup.string().required(t('Required')),
          branchBusinessPermitDate: Yup.string().required(t('Required')),

          representativeEmail: Yup.string().nullable().email(t('EmailInvalid')),
          representativePhone: Yup.string()
            .nullable()
            .test('len', t('PhoneInvalid', { field: t('phone') }), (val) => {
              if (val) {
                return !val.toString().includes(' ') && val.toString().match(AppConfigs.regexPhone);
              }
              return true;
            }),
        })}
        enableReinitialize
        onSubmit={(values) => {
          const params = {
            organizationName: values.branchName,
            businessPermitAddress: values.branchAddress,

            representativeName: values.representativeName,
            representativeJobTitle: values.representativeJobTitle,
            representativePhone: values.representativePhone,
            representativeEmail: values.representativeEmail,
          };
          const res = OrganizationHelper.callApiUpdateOrganization(
            branch?.organizationId,
            params,
            false,
            false
          );
          if (res) {
            handlePressCancel();
          }
        }}
      >
        {(formikProps) => (
          <div className="">
            <div className="row">
              {/* thong tin don vi */}
              <div className="col-12">
                <KTFormGroup
                  additionalClassName="mb-4"
                  label={t('UnitInformation')}
                  inputElement={
                    <div className="bg-light p-4 rounded" style={{}}>
                      Vui lòng khai báo thông tin{' '}
                      <span className="font-weight-bolder">
                        chính xác theo giấy phép kinh doanh.
                      </span>{' '}
                      Hệ thống sẽ sử dụng thông tin này để tra cứu tính pháp lý của hóa đơn.
                    </div>
                  }
                />
              </div>

              {/* ma so thue */}
              <div className="col-6">
                <KTFormGroup
                  additionalClassName="mb-4"
                  label={
                    <>
                      {t('TaxCode')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="taxCode"
                  inputElement={
                    <FastField name="taxCode">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                            Global.gTempBranchTaxCode = value;
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          disabled
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('TaxCode'))}`}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {/* Ngay hieu luc */}
              <div className="col-6">
                <KTFormGroup
                  additionalClassName="mb-4"
                  label={
                    <div className="d-flex">
                      {t('Ngày hiệu lực')} <span className="text-danger">(*)</span>
                      <KTTooltip text="Ngày hiệu lực bắt đầu của tên, địa chỉ theo GPKD">
                        <i className="fa-regular fa-circle-question text-primary ml-1 cursor-pointer" />
                      </KTTooltip>
                    </div>
                  }
                  inputName="branchBusinessPermitDate"
                  inputElement={
                    <FastField name="branchBusinessPermitDate">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.date}
                          placeholder={`${_.capitalize(t('Ngày hiệu lực'))}`}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {/* ten chi nhanh */}
              <div className="col-12">
                <KTFormGroup
                  additionalClassName="mb-4"
                  label={
                    <>
                      {t('BranchName')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="branchName"
                  inputElement={
                    <FastField name="branchName">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('BranchName'))}`}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {/* dia chi chi nhanh */}
              <div className="col-12">
                <KTFormGroup
                  additionalClassName="mb-4"
                  label={
                    <>
                      {t('BranchAddressByBusinessLicense')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="branchAddress"
                  inputElement={
                    <FastField name="branchAddress">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('BranchAddress'))}`}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {/* chap nhan nhieu ten, dia chi don vi hop le */}
              <div className="col-12">
                <div className="checkbox-list">
                  <KTCheckbox
                    name="checkboxAcceptMultiNameBranch"
                    label={
                      <div className="d-flex align-items-center">
                        Chấp nhận nhiều tên, địa chỉ đơn vị hợp lệ{' '}
                        <KTTooltip text="Kê khai thông tin tên/địa chỉ dựa theo các lần thay đổi giấy phép ĐKKD của đơn vị">
                          <i className="fa-regular fa-circle-question text-primary ml-2" />
                        </KTTooltip>
                      </div>
                    }
                    type={KTCheckboxType.outline}
                    onChange={(newValue) => {
                      if (onCheckedAcceptMultiNameChange) {
                        onCheckedAcceptMultiNameChange(newValue);
                      }
                    }}
                  />
                </div>
              </div>

              {/* thong tin lien he */}
              <div className="row mt-4">
                {/* ten nguoi lien he */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactName')}</>}
                    inputName="representativeName"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativeName">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.name = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('ContactName'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* chuc vu */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactPosition')}</>}
                    inputName="representativeJobTitle"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativeJobTitle">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.jobTitle = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('ContactPosition'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* email */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactEmail')}</>}
                    inputName="representativeEmail"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativeEmail">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.email = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.email}
                            placeholder={`${_.capitalize(t('ContactEmail'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* so dien thoai */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactPhone')}</>}
                    inputName="representativePhone"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativePhone">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.phone = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('ContactPhone'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>
              </div>
            </div>

            {/* actions */}
            <div className="d-flex align-items-center justify-content-end gap-3">
              <VIButton
                text={t('Cancel')}
                className="btn-grey"
                style={{ padding: '8px' }}
                onClick={handlePressCancel}
              />
              <VIButton
                text={t('Save')}
                className=""
                style={{ padding: '8px' }}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ModalEditBranchContent;
