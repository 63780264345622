/**
 * Su dung
 * <VIDateRangePicker2
      alignRight={false|true}
      initialValue={AppData.dateFilter...}
      onPressSave={(selectedValue, startDate, endDate) => {}
  />
 */

import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KeenSelectOption from 'general/components/OtherKeenComponents/KeenSelectOption';
import AppData from 'general/constants/AppData';
import Utils from 'general/utils/Utils';
import useOnClickOutside from 'hooks/useOnClickOutside';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VIButton from '../VIButton';

VIDateRangePicker2.propTypes = {
  additionalClassName: PropTypes.string,
  onPressCancel: PropTypes.func,
  onPressSave: PropTypes.func,
  alignRight: PropTypes.bool,
  initialValue: PropTypes.string,
  inlineMode: PropTypes.bool,
};

VIDateRangePicker2.defaultProps = {
  additionalClassName: '',
  onPressCancel: null,
  onPressSave: null,
  alignRight: false,
  initialValue: AppData.dateFilter.baMuoiNgayGanNhat,
  inlineMode: false,
};

/**
 *
 * @param {{
 *  additionalClassName: string,
 *  onPressCancel: string,
 *  onPressSave: string,
 *  alignRight: boolean,
 *  initialValue: string,
 *  inlineMode: boolean,
 * }} props
 * @returns
 */
function VIDateRangePicker2(props) {
  // ---- params ----
  const { additionalClassName, onPressCancel, onPressSave, alignRight, initialValue, inlineMode } =
    props;
  const { t } = useTranslation();
  const refDrp2 = useRef();
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const selectedName = useMemo(() => {
    return _.find(AppData.dateFilterOptions, (item) => item.value === selectedValue)?.name;
  }, [selectedValue]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [zIndex, setZIndex] = useState('');

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (inlineMode) {
      setShow(true);
    }
  }, [inlineMode]);

  useEffect(() => {
    const arr = Utils.getDateFilterOption(selectedValue);
    if (_.isArray(arr) && arr.length === 2) {
      const sStartDate = arr[0].format('YYYY-MM-DD');
      const sEndDate = arr[1].format('YYYY-MM-DD');
      // console.log({ sStartDate, sEndDate });
      setStartDate(sStartDate);
      setEndDate(sEndDate);
    }
  }, [selectedValue]);

  useOnClickOutside(refDrp2, () => {
    if (inlineMode) {
      return;
    }
    if (show) {
      setShow(false);
      handlePressSave();
    }
  });

  useEffect(() => {
    setZIndex(show ? '10' : '');
  }, [show]);

  useEffect(() => {
    if (inlineMode && onPressSave) {
      onPressSave(selectedValue, startDate, endDate);
    }
  }, [selectedValue, startDate, endDate, inlineMode]);

  // ---- methods ----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
    setShow(false);
  }

  function handlePressSave() {
    if (onPressSave) {
      onPressSave(selectedValue, startDate, endDate);
    }
  }

  return (
    <div
      ref={refDrp2}
      className={`position-relative ${inlineMode ? 'w-100' : ''}`}
      style={{
        zIndex: zIndex,
      }}
    >
      {!inlineMode && (
        <VIButton
          className="btn-white font-weight-normal rounded-lg shadow-sm"
          text={selectedName}
          beforeIcon={<i className="fa-regular fa-calendar-clock" />}
          afterIcon={<i className="fa-regular fa-angle-down" />}
          onClick={() => {
            if (inlineMode) {
              return;
            }
            setShow(!show);
          }}
        />
      )}

      {show && (
        <div
          className={`${
            !inlineMode ? 'position-absolute shadow px-4 pt-4 w-auto' : 'w-100'
          }  bg-white rounded-lg pb-4 mt-0`}
          style={{
            right: alignRight ? 0 : '',
            zIndex: zIndex,
          }}
        >
          <div>
            {/* chon khoang thoi gian */}
            <KTFormGroup
              label={!inlineMode ? <>{'Chọn khoảng thời gian'}</> : <></>}
              inputName="chooseRange"
              inputElement={
                <KeenSelectOption
                  name="chooseRange"
                  options={AppData.dateFilterOptions}
                  initialValue={selectedValue}
                  containerClassName=""
                  labelClassName=""
                  selectClassName="w-100"
                  onValueChanged={(value) => {
                    setSelectedValue(value);
                  }}
                />
              }
            />

            <div className="d-flex align-items-center gap-3">
              {/* ngay bat dau */}
              <KTFormGroup
                label={<>{'Ngày bắt đầu'}</>}
                inputName="startDate"
                additionalClassName="mb-0"
                inputElement={
                  <KTFormInput
                    name="startDate"
                    value={startDate}
                    onChange={(value) => {
                      if (selectedValue !== 'TUY_CHON') {
                        setSelectedValue('TUY_CHON');
                      }
                      setStartDate(value);
                    }}
                    type={KTFormInputType.date}
                    placeholder={'Ngày bắt đầu...'}
                  />
                }
              />

              {/* ngay ket thuc */}
              <KTFormGroup
                label={<>{'Ngày kết thúc'}</>}
                inputName="endDate"
                additionalClassName="mb-0"
                inputElement={
                  <KTFormInput
                    name="endDate"
                    value={endDate}
                    onChange={(value) => {
                      if (selectedValue !== 'TUY_CHON') {
                        setSelectedValue('TUY_CHON');
                      }
                      setEndDate(value);
                    }}
                    type={KTFormInputType.date}
                    placeholder={'Ngày kết thúc...'}
                  />
                }
              />
            </div>
          </div>

          {/* actions */}
          {!inlineMode && (
            <div className="d-flex align-items-center justify-content-end gap-3 mt-4">
              {/* <VIButton
              text={t('Cancel')}
              className="btn-grey"
              style={{ padding: '8px' }}
              onClick={handlePressCancel}
            /> */}
              <VIButton
                text={t('Save')}
                className=""
                style={{ padding: '8px' }}
                onClick={() => {
                  handlePressSave();
                  setShow(false);
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default VIDateRangePicker2;
