import axiosClient from './axiosClient';

const systemRoleApi = {
  // lay danh sach tat ca role
  getAll: (params) => {
    const url = '/role/find';
    return axiosClient.get(url, { params });
  },

  // tao role
  createNew: (params) => {
    const url = '/role/create';
    return axiosClient.post(url, params);
  },

  // cap nhat thong tin role
  updateInfo: (id, params) => {
    const url = `/role/update/${id}`;
    return axiosClient.put(url, params);
  },

  // xoa role
  delete: (ids) => {
    const url = '/role/delete';
    return axiosClient.delete(url, {
      data: {
        roleIds: ids,
      },
    });
  },
};

export default systemRoleApi;
