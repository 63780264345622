import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTFormTextArea from 'general/components/OtherKeenComponents/Forms/KTFormTextArea';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

ViewRiskyBusinessDetail.propTypes = {
  item: PropTypes.object,
};

ViewRiskyBusinessDetail.defaultProps = {
  item: null,
};

/**
 *
 * @param {{
 * item: object,
 * }} props
 * @returns
 */
function ViewRiskyBusinessDetail(props) {
  // ---- params ----
  const { item } = props;
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-12">
        <KTFormGroup
          label={<>{t('CompanyName')}</>}
          inputName="companyName"
          inputElement={
            <KTFormInput
              name="companyName"
              value={item?.companyName}
              type={KTFormInputType.text}
              disabled
            />
          }
        />
      </div>
      <div className="col-12">
        <KTFormGroup
          label={<>{t('TaxCode')}</>}
          inputName="taxCode"
          inputElement={
            <KTFormInput
              name="taxCode"
              value={item?.taxCode}
              type={KTFormInputType.text}
              disabled
            />
          }
        />
      </div>
      <div className="col-12">
        <KTFormGroup
          label={<>{t('CQT')}</>}
          inputName="cqt"
          inputElement={
            <KTFormInput name="cqt" value={item?.cqtName} type={KTFormInputType.text} disabled />
          }
        />
      </div>

      <div className="col-12">
        <KTFormGroup
          label={<>{t('Note')}</>}
          inputName="note"
          additionalClassName="mb-0"
          inputElement={<KTFormTextArea name="note" value={item?.note} disabled />}
        />
      </div>
    </div>
  );
}

export default ViewRiskyBusinessDetail;
