import notificationApi from 'api/notificationApi';
import NotificationHelper from 'general/helpers/NotificationHelper';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// lay danh sach thong bao
export const thunkGetNotificationList = createAsyncThunk(
  'notification/getNotificationList',
  async (params, thunkAPI) => {
    const res = await notificationApi.find(params);
    return res;
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notificationList: [],
    isGettingNotificationList: false,
    totalCount: 0,
  },
  reducers: {
    markNotificationRead: (state, action) => {
      const notificationId = action.payload;
      const foundIndex = _.findIndex(
        state.notificationList,
        (item) => item.notificationId == notificationId
      );
      if (foundIndex > -1) {
        state.notificationList[foundIndex].isRead = true;
      }
    },

    notificationSignOut: (state, action) => {
      state.notificationList = [];
      state.isGettingNotificationList = false;
      state.totalCount = 0;
    },
  },
  extraReducers: {
    // lay danh sach thong bao
    [thunkGetNotificationList.pending]: (state, action) => {
      state.isGettingNotificationList = true;
    },
    [thunkGetNotificationList.rejected]: (state, action) => {
      state.isGettingNotificationList = false;
    },
    [thunkGetNotificationList.fulfilled]: (state, action) => {
      state.isGettingNotificationList = false;
      const { result, notifications, total, totalNotifications } = action.payload;
      if (result === 'success' && notifications) {
        const checkedNotifications = notifications?.map((item) => {
          return {
            ...item,
            isRead: NotificationHelper.checkNotificationRead(item?.notificationId),
          };
        });
        state.notificationList = checkedNotifications;
        state.totalCount = totalNotifications ? totalNotifications : notifications?.length ?? 0;
      }
    },
  },
});

const { reducer, actions } = notificationSlice;
export const { markNotificationRead, notificationSignOut } = actions;
export default reducer;
