import LandingPageFooter from 'features/LandingPage/components/LandingPageFooter';
import HeaderStore from 'features/Payment/components/HeaderStore';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import ToastHelper from 'general/helpers/ToastHelper';
import Utils from 'general/utils/Utils';
import useConfig from 'hooks/useConfig';
import usePayment from 'hooks/usePayment';
import useRouter from 'hooks/useRouter';
import useScrollToTop from 'hooks/useScrollToTop';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import './style.scss';
import { useEffect } from 'react';
import { clearOrderData } from 'features/Payment/paymentSlice';

function OrderSuccessScreen(props) {
  // ---- params ----
  const { t } = useTranslation();
  useScrollToTop();
  const dispatch = useDispatch();
  const appConfig = useConfig();
  const {
    cartTotalPrice,
    cartPriceUnit,
    canShoppingCart,
    shoppingCartList,
    shoppingCartQuantityBuyMore,
    shoppingCartDurationExpired,
    confirmPaymentInfo,
    bankTransferContent,
    orderCreatedSuccess,
    lastPriceToPay,
  } = usePayment();
  const router = useRouter();

  useEffect(() => {
    return () => {
      dispatch(clearOrderData());
    };
  }, []);

  return (
    <div>
      <div className="OrderSuccessScreen min-vh-100 d-flex flex-column">
        {/* header */}
        <HeaderStore additionalClassName="bg-white" />

        {/* body */}
        <div className="OrderSuccessScreen_Body flex-grow-1 pb-10">
          <div className="container-xl py-10 d-flex align-items-center justify-content-center my-10">
            <div
              className="bg-white overflow-hidden"
              style={{
                borderRadius: '16px',
                boxShadow: '0px 8px 24px 0px rgba(37, 42, 91, 0.05)',
              }}
            >
              <div
                className="d-flex gap-2 align-items-center px-10 py-6 justify-content-center"
                style={{
                  color: '#28A043',
                }}
              >
                <i
                  className="fa-regular fa-circle-check"
                  style={{
                    color: 'inherit',
                    fontSize: '32px',
                  }}
                />
                <span className="font-size-h4 font-weight-bolder">
                  Đặt hàng thành công đơn hàng 380022
                </span>
              </div>

              <div
                className="p-10 d-flex flex-column gap-4"
                style={{
                  backgroundColor: '#F8F8F8',
                }}
              >
                <div className="d-flex align-items-center gap-2">
                  <img className="" src={AppResource.icons.icEmailOrder} />
                  <div className="font-size-lg">
                    Email xác nhận đặt hàng thành công đã được gửi về địa chỉ{' '}
                    <span className="text-primary">
                      {orderCreatedSuccess?.invoiceCustomerEmail}
                    </span>{' '}
                  </div>
                </div>

                <div className="d-flex flex-column align-items-center">
                  <p>Xem danh sách sản phẩm đã mua và bắt đầu sử dụng</p>
                  <VIButton
                    text={t('ViewProducts')}
                    className="btn-blue"
                    style={{ padding: '8px 16px' }}
                    onClick={() => {
                      router.navigate('/inbot/system/resource');
                    }}
                  />
                </div>

                <div>
                  <p>
                    Trong trường hợp chưa thanh toán, bạn vui lòng chuyển khoản theo đúng các thông
                    tin dưới đây:
                  </p>
                  <div className="d-flex mt-3">
                    <div className="mr-4 d-flex flex-column gap-2">
                      <p className="m-0">{t('BankName')}:</p>
                      <p className="m-0">{t('BankAccountName')}:</p>
                      <p className="m-0">{t('BankAccountNumber')}:</p>
                      <p className="m-0">{t('BankBranch')}:</p>
                      <p className="m-0">{t('BankTransferContent')}:</p>
                      <p className="m-0">{t('PriceNumber')}:</p>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <p className="font-weight-bold m-0">{appConfig.bankName}&nbsp;</p>
                      <p className="font-weight-bold m-0">{appConfig.bankAccountName}&nbsp;</p>
                      <p className="font-weight-bold m-0">{appConfig.bankAccountNumber}&nbsp;</p>
                      <p className="font-weight-bold m-0">{appConfig.bankBranch}&nbsp;</p>
                      <div className="font-weight-bold m-0 d-flex align-items-center gap-2">
                        {orderCreatedSuccess?.clientOrderCode}&nbsp;{' '}
                        <KTTooltip text={t('Copy')}>
                          <i
                            className="fa-regular fa-copy cursor-pointer text-primary"
                            onClick={() => {
                              Utils.copyTextToClipboard(orderCreatedSuccess?.clientOrderCode);
                              ToastHelper.showSuccess(t('Copied'));
                            }}
                          />
                        </KTTooltip>
                      </div>
                      <p className="font-weight-bold m-0">
                        {Utils.formatCurrency(lastPriceToPay)} {cartPriceUnit}&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <LandingPageFooter />
      </div>
    </div>
  );
}

export default OrderSuccessScreen;
