import CurrencyInput from 'react-currency-input-field';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIInputField from 'general/components/VietInvoice/VIInputField';
import Utils from 'general/utils/Utils';

import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

function ModalPayment(props) {
  const { currentInvoices, formik } = props;
  const { t } = useTranslation();

  return (
    <div className="Modal-payment">
      <div>
        <label className="text-lg-right" style={{ color: '#3F4254', fontWeight: '500' }}>
          {`${t('SelectPaymentDate')}`}
          <sup className="font-weight-boldest ms-1" style={{ color: '#F44141' }}>
            (*)
          </sup>
        </label>
        <KTFormGroup
          additionalClassName=" mb-3"
          inputName="paymentDate"
          inputElement={
            <div>
              <KTFormInput
                name="paymentDate"
                value={Utils.formatDateTime(formik.values.paymentDate, 'YYYY-MM-DD')}
                onChange={(value) => {
                  formik.setFieldValue('paymentDate', Utils.formatAddDateTime(value));
                }}
                onBlur={() => {
                  formik.setFieldTouched('paymentDate', true);
                }}
                enableCheckValid
                type={KTFormInputType.date}
                placeholder={`${_.capitalize(t('SelectPaymentDate'))}`}
                enableInputGroup={true}
                prependElements={[<i className="fa-regular fa-calendar-clock"></i>]}
              />
            </div>
          }
        />
      </div>
      <VIInputField
        className="w-100 mb-3"
        label={t('Payer')}
        placeholder={t('EnterPayer')}
        value={formik.values.paymentPersonName}
        fieldHelper={formik.getFieldHelpers('paymentPersonName')}
        fieldProps={formik.getFieldProps('paymentPersonName')}
        fieldMeta={formik.getFieldMeta('paymentPersonName')}
      />
      <label className="text-lg-right" style={{ color: '#3F4254', fontWeight: '500' }}>
        {`${t('EnterPaymentAmount')}`}
        <sup className="font-weight-boldest ms-1" style={{ color: '#F44141' }}>
          (*)
        </sup>
      </label>
      <div className="flex items-center mb-4">
        <CurrencyInput
          placeholder={t('EnterPaymentAmount')}
          groupSeparator="."
          decimalSeparator=","
          decimalsLimit={2}
          step={500}
          value={formik.values.paymentMoney}
          onValueChange={(value) => {
            formik.setFieldValue('paymentMoney', value);
          }}
          className="transition duration-300 ease-in-out px-3 py-2 w-100 focus:outline-none focus:ring-2 focus:ring-blue-800"
        />
        {formik.errors.paymentMoney && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.paymentMoney}</div>
          </div>
        )}
      </div>

      <div className="rounded border">
        <div className="d-flex align-items-center justify-content-between p-4 border-bottom">
          <div>{t('TotalAmountToPay')}</div>
          <div style={{ fontWeight: 700 }}>
            <span style={{ color: '#F44141' }} className="me-2">
              {Utils.formatNumber(currentInvoices?.debtPayment)}
            </span>
            <span>VND</span>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between p-4 border-bottom">
          <div>{t('AmountUnpaid')}</div>
          <div style={{ fontWeight: 700 }}>
            <span className="me-2">
              {currentInvoices?.debtPayment - formik.values.paymentMoney > 0 ? (
                <>{Utils.formatNumber(currentInvoices?.debtPayment - formik.values.paymentMoney)}</>
              ) : (
                0
              )}
            </span>
            <span>VND</span>
          </div>
        </div>
        {currentInvoices?.debtPayment - formik.values.paymentMoney > 0 ? (
          <>
            <div className="d-flex align-items-center justify-content-between p-4 border-bottom">
              <div>{t('NextPaymentDate')}</div>
              <div className="d-flex align-items-center" style={{ width: '40%', fontWeight: 700 }}>
                <VIInputField
                  className="w-100 me-3"
                  placeholder={t('EnterPaymentAmount')}
                  type="number"
                  value={Utils.formatNumber(formik.values.noDatePayment)}
                  fieldHelper={formik.getFieldHelpers('noDatePayment')}
                  fieldProps={formik.getFieldProps('noDatePayment')}
                  fieldMeta={formik.getFieldMeta('noDatePayment')}
                />
                <span>Ngày</span>
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-between p-4 bg-light m-2"
              style={{ borderRadius: '8px' }}
            >
              <div style={{ color: '#FFA318', fontWeight: 700 }}>{t('PartialPayment')}</div>
            </div>
          </>
        ) : (
          <div
            className="d-flex align-items-center justify-content-between p-4 bg-light m-2"
            style={{ borderRadius: '8px' }}
          >
            <div style={{ color: '#28A043', fontWeight: 700 }}>{t('FullPayment')}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModalPayment;
