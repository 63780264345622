import VIInputField from 'general/components/VietInvoice/VIInputField';
import React, { Fragment, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './style.scss';
import partnerCompanyApi from 'api/partnerCompanyApi';
import Utils from 'general/utils/Utils';
import Global from 'general/utils/Global';
import KTTooltip, { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip';

function AddLabel(props) {
  const { formik, currentChangeLabel } = props;

  const [currentColor, setCurrentColor] = useState('#FD7972');

  const [penColors, setPenColors] = useState(['#FD7972', '#FF965D', '#FFD240', '#304FFD']);

  const [dataTaxCode, setDataTaxCode] = useState([]);

  const [dataCompany, setDataCompany] = useState([]);
  const [filter, setFilter] = useState(Global.gFiltersPartnerCompany);
  const [total, setTotal] = useState();

  useEffect(() => {
    const fetchApi = async () => {
      const res = await partnerCompanyApi.getAll(filter);
      if (res.result === 'success') {
        if (filter?.q === '') {
          setDataCompany(res?.partnerCompanies.concat(dataCompany));
        } else {
          setDataCompany(res?.partnerCompanies);
        }
        setTotal(res?.total);
      }
    };
    fetchApi();
  }, [filter]);

  useEffect(() => {
    if (dataTaxCode) {
      formik.setValues({
        ...formik.values,
        partnerCompanyTaxCodes: dataTaxCode,
      });
    }
  }, [dataTaxCode]);
  useEffect(() => {
    formik.setValues({
      name: currentChangeLabel?.name,
      color: currentChangeLabel?.color ?? '#FD7972',
      partnerCompanyTaxCodes: currentChangeLabel?.partnerCompanyTaxCodes,
    });
    setCurrentColor(currentChangeLabel?.color ?? '#FD7972');
    setDataTaxCode(currentChangeLabel?.partnerCompanyTaxCodes ?? []);
  }, [currentChangeLabel]);

  // handle scroll content
  function handleScroll(e) {
    const bottom =
      Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 20;
    if (bottom) {
      if (total / 30 >= filter?.page + 1) {
        setFilter({
          ...filter,
          page: filter?.page + 1,
        });
      }
    }
  }

  return (
    <div className="Add-label">
      <div className="d-flex justify-content-between label-color">
        <VIInputField
          className="mb-3"
          label="Tên nhãn"
          placeholder="Nhập tên nhãn"
          style={{ width: '80%' }}
          fieldHelper={formik.getFieldHelpers('name')}
          fieldProps={formik.getFieldProps('name')}
          fieldMeta={formik.getFieldMeta('name')}
        />
        <div className="d-flex align-items-start mb-3 me-2 ">
          <div className="dropdown">
            <div
              className="dropdown-toggle"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div
                className="p-1 border  d-flex align-items-center cursor-pointer mt-7"
                style={{
                  background: `${currentColor}`,
                  minWidth: '75px',
                  borderRadius: '14px',
                  height: '37px',
                }}
              ></div>
            </div>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <div>
                <span className="font-size-lg ms-3">Đổi màu</span>
                <div className="d-flex flex-row align-items-center mx-3 mt-2">
                  {penColors?.map((color, index) => (
                    <div className="form-check position-relative" key={index}>
                      <input
                        className="pen-color form-check-input p-2 mr-5"
                        type="radio"
                        name={'Color'}
                        value={color}
                        checked={currentColor === color}
                        onChange={(e) => {
                          setCurrentColor(e.target.value);
                          formik.setValues({ ...formik.values, color: e.target.value });
                        }}
                        style={{ background: color }}
                      />
                      {currentColor === color && (
                        <div
                          className="position-absolute"
                          style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            background: '#fff',
                            top: '6.5px',
                            left: '8px',
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                  <div className="btn-addColor position-relative ">
                    <label htmlFor="" className="form-label w-100 h-100">
                      <input
                        type="color"
                        className="form-control form-control-color border-0 p-0"
                        defaultValue="#563d7c"
                        id="add-color"
                        onChange={(e) => {
                          const color = e.target.value;
                          setCurrentColor(color);
                          const penColorsTmp = [...penColors];
                          penColorsTmp.pop();
                          penColorsTmp.unshift(color);
                          setPenColors([...penColorsTmp]);
                          formik.setValues({ ...formik.values, color: color });
                        }}
                      />
                    </label>
                    <i
                      className="far fa-plus position-absolute add-btn border"
                      onClick={(e) => {
                        e.stopPropagation();
                        document.getElementById('add-color').click();
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <p
          className="font-weight-bold mb-1"
          onClick={() => document.getElementById(`Search-taxCode`)?.focus()}
        >
          Tự động gán nhãn theo mã số thuế NCC
        </p>
        <div></div>
        <div
          className="border p-2 d-flex input-tax-code position-relative justify-content-between"
          style={{ borderRadius: '12px' }}
        >
          <div className="w-100 ">
            <div className="d-flex flex-wrap ">
              {dataTaxCode?.map((item, index) => (
                <div
                  key={index}
                  className="me-2 d-flex align-items-center py-1 px-2 mb-1"
                  style={{ width: 'max-content', backgroundColor: '#f3f4f7', borderRadius: '8px' }}
                >
                  {item}

                  {!Utils.checkTaxCodes(item) && (
                    <KTTooltip text={'Mã số thuế không đúng'} position={KTTooltipPositions.auto}>
                      <i
                        className="fa-regular fa-circle-exclamation ms-1"
                        style={{ color: '#F44141' }}
                      ></i>
                    </KTTooltip>
                  )}
                  <i
                    className="fa-regular fa-xmark ms-1 cursor-pointer"
                    style={{ color: '#F44141' }}
                    onClick={() => {
                      setDataTaxCode(dataTaxCode.filter((e) => e !== item));
                    }}
                  ></i>
                </div>
              ))}
              <input
                id="Search-taxCode"
                className="flex-grow-1"
                style={{ width: '10px' }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (e.target.value !== '') {
                      setDataTaxCode([...dataTaxCode, e.target.value]);
                    }
                    document.getElementById('Search-taxCode').value = '';
                  }
                }}
                onChange={(e) => {
                  setFilter({ ...filter, q: e.target.value });

                  if (dataCompany.some((x) => x.taxCode.includes(e.target.value))) {
                    document.getElementById('dropdown-split-tax-code').click();
                  }
                }}
              ></input>
            </div>
          </div>
          <i
            className="fa-solid fa-angle-down d-flex align-items-center cursor-pointer me-3"
            onClick={() => {
              document.getElementById('dropdown-split-tax-code').click(),
                document.getElementById('Search-taxCode').focus();
            }}
          ></i>
          <div
            className="position-absolute w-100"
            style={{ right: '0px', bottom: '-1px', height: '1px' }}
          >
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-split-tax-code"
                style={{ opacity: 0 }}
                align={{ lg: 'end' }}
              />

              <Dropdown.Menu align={{ lg: 'end' }} className="w-100 py-3">
                <div
                  id="search-drop"
                  style={{ maxHeight: '30vh', overflow: 'auto' }}
                  onScroll={handleScroll}
                >
                  {dataCompany?.map((x, index) => (
                    <Fragment key={index}>
                      {!dataTaxCode?.some((i) => i === x?.taxCode) && (
                        <Dropdown.Item
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDataTaxCode([...dataTaxCode, x?.taxCode]);
                          }}
                        >
                          <div>
                            <span>{x?.taxCode}</span>
                            <span> - </span>
                            <span>{x?.partnerCompanyName}</span>
                          </div>
                        </Dropdown.Item>
                      )}
                    </Fragment>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddLabel;
