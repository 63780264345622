import React, { useEffect, useMemo, useRef, useState } from 'react';
import VIButton from 'general/components/VietInvoice/VIButton';
import { useTranslation } from 'react-i18next';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import VIDeleteModal from 'general/components/VietInvoice/VIDeleteModal';
import DropdownFilterDeleteInvoice from 'features/Invoice/screens/components/DropdownFilterDeleteInvoice';
import '../TabInInvoice/style.scss';

import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import VIModalDetailOutput from '../ModalDetailInvoiceOutput';
import useAccount from 'hooks/useAccount';
import { useSelector } from 'react-redux';
import Global from 'general/utils/Global';
import { useDispatch } from 'react-redux';
import { thunkGetAllDeleteOutInvoice } from 'features/Invoice/invoiceSlice';
import Utils from 'general/utils/Utils';
import invoiceApi from 'api/invoiceApi';
import ToastHelper from 'general/helpers/ToastHelper';
import DropdownFilterDeleteOutInvoice from 'features/Invoice/screens/components/DropdownFilterDeleteOutInvoice';
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import useOnClickOutside from 'hooks/useOnClickOutside';
import KTTooltip, { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import { useWindowSize } from '@uidotdev/usehooks';
function DeleteInvoices(props) {
  const { tabsActive } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModalDetailInvoices, setShowModalDetailInvoices] = useState(false);
  const account = useAccount();

  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[account.accountId]
      ? JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          account.accountId
        ]
      : Global.gFiltersOutputInvoiceDelete
  );
  const [currentDetail, setCurrentDetail] = useState();

  const {
    listOutputInvoiceDelete,
    isGettingOutputInvoiceListDelete,
    totalOutputInvoiceDelete,
    totalAmountBeforeVATOutputInvoiceDelete,
    totalAmountVATOutputInvoiceDelete,
    totalFinalAmountOutputInvoiceDelete,
  } = useSelector((state) => state?.invoice);

  useEffect(() => {
    if (_.isEmpty(listOutputInvoiceDelete)) {
    }
    dispatch(thunkGetAllDeleteOutInvoice(filters));
  }, [filters, account.organizationId]);

  useEffect(() => {
    Global.gFiltersOutputInvoiceDelete = {
      ...Global.gFiltersOutputInvoiceDelete,
      page: 0,
    };
  }, [account.organizationId, account.organizationDepartmentId]);
  const columns = useMemo(
    () => [
      {
        name: t('InvoiceNumber'),
        minWidth: '135px',
        selector: (row) => row.invoiceNumber,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceNumber ? row?.invoiceNumber : ''}
            </p>
          );
        },
      },
      {
        name: t('Denominator'),
        width: '100px',
        selector: (row) => row.invoiceTypeCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceTypeCode ? row?.invoiceTypeCode : ''}
            </p>
          );
        },
      },
      {
        name: t('Symbol'),
        width: '100px',
        selector: (row) => row.serial,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.serial ? row?.serial : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceDate'),
        width: '150px',
        selector: (row) => row.invoiceDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceDate ? Utils.formatDateTime(row?.invoiceDate, 'DD/MM/yyyy' ?? '') : ''}
            </p>
          );
        },
      },
      {
        name: t('SignDate'),
        width: '115px',
        selector: (row) => row.signDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.signDate ? Utils.formatDateTime(row?.signDate, 'DD/MM/yyyy') : ''}
            </p>
          );
        },
      },
      {
        name: t('CodeIssueDate'),
        width: '140px',
        selector: (row) => row.providedCodeDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.providedCodeDate
                ? Utils.formatDateTime(row?.providedCodeDate, 'DD/MM/yyyy')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('CodeAxion'),
        width: '190px',
        selector: (row) => row.maCQT,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.maCQT ? row?.maCQT : ''}
            </p>
          );
        },
      },
      {
        name: t('CodeClient'),
        minWidth: '165px',
        selector: (row) => row.buyerCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerCode ? row?.buyerCode : ''}
            </p>
          );
        },
      },
      {
        name: t('BuyerTaxCodes'),
        width: '200px',
        selector: (row) => row.buyerTaxCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerTaxCode ? row?.buyerTaxCode : ''}
            </p>
          );
        },
      },
      {
        name: t('BuyerInformation'),
        width: '200px',
        selector: (row) => row.buyerName,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerName ? row?.buyerName : ''}
            </p>
          );
        },
      },

      {
        name: t('BuyerAddress'),
        minWidth: '250px',
        selector: (row) => row.buyerAddress,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerAddress ? row?.buyerAddress : ''}
            </p>
          );
        },
      },

      {
        name: t('TotalPreTaxMoney'),
        minWidth: '215px',
        selector: (row) => row.amountBeforeVat,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.amountBeforeVat
                ? Utils.formatCurrency(row?.amountBeforeVat)
                : row?.amountBeforeVat}
            </p>
          );
        },
      },
      {
        name: t('taxMoney'),
        minWidth: '116px',
        selector: (row) => row.amountVat,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.amountVat ? Utils.formatCurrency(row?.amountVat) : row?.amountVat}
            </p>
          );
        },
      },
      {
        name: t('TotalPayment'),
        minWidth: '214px',
        selector: (row) => row.finalAmount,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.finalAmount ? Utils.formatCurrency(row?.finalAmount) : row?.finalAmount}
            </p>
          );
        },
      },
      {
        name: t('Currency'),
        selector: (row) => row.paymentCurrency,
        sortable: true,
        minWidth: '113px',

        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.paymentCurrency ? row?.paymentCurrency : ''}
            </p>
          );
        },
      },
      {
        name: t('ExchangeRate'),
        selector: (row) => row.paymentExchangeRate,
        sortable: true,
        width: '90px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.paymentExchangeRate ? row?.paymentExchangeRate : ''}
            </p>
          );
        },
      },
      {
        name: t('TotalAmountConverted'),
        width: '182px',
        selector: (row) => row.finalAmountExchange,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p className="font-weight-normal m-0 text-maxline-3 text-align-end">
              {row?.finalAmountExchange ? Utils.formatNumber(row?.finalAmountExchange) : ''}
            </p>
          );
        },
      },
      {
        name: t('invoiceStatus'),
        width: '200px',
        selector: (row) => row.statusInvoiceText,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.statusInvoiceText ? row?.statusInvoiceText : ''}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            {account?.role?.permissionCodes?.includes('DELETE_AND_RESTORE_OUTPUT_INVOICE') ? (
              <VITableRowDropDown
                toggleElement={
                  <div>
                    <i className="text-grey fas fa-ellipsis-h p-0"></i>
                  </div>
                }
                menuItem={[
                  {
                    iconElement: <i className="fa-light fa-trash-can-undo"></i>,
                    text: t('Restore'),
                    click: async () => {
                      const result = await invoiceApi.addInvoiceToTrash({
                        invoiceIds: [row?.invoiceId],
                        isDeleted: false,
                      });
                      if (result?.result === 'success') {
                        ToastHelper.showSuccess(t('SuccessfulInvoiceRecovery'));
                        dispatch(thunkGetAllDeleteOutInvoice(filters));
                      }
                    },
                  },
                ]}
              />
            ) : (
              <KTTooltip
                text={'Bạn không có quyền thực hiện chức năng này'}
                position={KTTooltipPositions.auto}
              >
                <div className="">
                  <i className="text-grey fas fa-ellipsis-h p-0"></i>
                </div>
              </KTTooltip>
            )}
          </div>
        ),
      },
    ],
    [listOutputInvoiceDelete]
  );

  const [pagination, setPagination] = useState({
    perPage: filters?.limit,
    total: totalOutputInvoiceDelete,
    currentPage: filters?.page + 1,
    count: listOutputInvoiceDelete?.length + filters.limit * filters.page,
  });
  useEffect(() => {
    setPagination({
      perPage: filters?.limit,
      total: totalOutputInvoiceDelete,
      currentPage: filters?.page + 1,
      count: listOutputInvoiceDelete?.length + filters.limit * filters.page,
    });
  }, [filters, listOutputInvoiceDelete]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataCheck, setDataCheck] = useState([]);
  // lọc hóa đơn
  function handleFilterInvoice(dataFilter) {
    setFilters({
      ...filters,
      fromDate: dataFilter?.fromDate,
      toDate: dataFilter?.toDate,
      status: dataFilter?.status,
      invoiceTypeCode: dataFilter?.invoiceTypeCode,
      invoiceSerialType: dataFilter?.invoiceSerialType,
    });
  }

  const refDropdownFilterDeleteOutput = useRef();
  const refDropdownFilterDeleteOutputMenu = useRef();
  const refDateRangePickerFilter = useRef();

  // useOnClickOutside(refDropdownFilterDeleteOutput, () => {
  //   const isDateRangePickerDisplays = window?.getComputedStyle(
  //     refDateRangePickerFilter.current
  //   ).display;
  //   if (isDateRangePickerDisplays === 'block') {
  //     return;
  //   }
  //   if (refDropdownFilterDeleteOutputMenu?.current) {
  //     if (refDropdownFilterDeleteOutputMenu?.current.classList.contains('show')) {
  //       refDropdownFilterDeleteOutputMenu?.current.classList.remove('show');
  //     } else {
  //     }
  //   }
  // });

  useEffect(() => {
    const el = document.getElementsByClassName('DropdownFilterMenu')?.item(0);
    if (el) {
      refDropdownFilterDeleteOutputMenu.current = el;
    }

    if (document.getElementsByClassName('daterangepicker')?.item(1)) {
      refDateRangePickerFilter.current = document
        .getElementsByClassName('daterangepicker')
        ?.item(1);
    }
  }, []);
  const [countFilter, setCountFilter] = useState();

  useEffect(() => {
    let count = 0;
    if (
      JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
        account.accountId
      ] !== undefined
    ) {
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          account.accountId
        ]?.status !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          account.accountId
        ]?.status
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          account.accountId
        ]?.invoiceTypeCode !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          account.accountId
        ]?.invoiceTypeCode
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          account.accountId
        ]?.invoiceSerialType !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          account.accountId
        ]?.invoiceSerialType
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          account.accountId
        ]?.fromDate !== moment().subtract(29, 'days').format('YYYY-MM-DD') ||
        JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[
          account.accountId
        ]?.toDate !== moment().format('YYYY-MM-DD')
      ) {
        count += 1;
      }
      setCountFilter(count);
    } else {
      setCountFilter(0);
    }
  }, [
    JSON.parse(localStorage.getItem(PreferenceKeys.saveOutputDeleteInvoice))?.[account.accountId],
    account.accountId,
  ]);

  const reSize = useWindowSize();

  return (
    <div className="position-relative Output-invoice">
      <div className="d-flex justify-content-between ">
        <div
          className="w-100 bg-white"
          style={{
            borderRadius: '0px 0px 20px 20px',
            borderLeft: '1px solid #dbe3ef',
            borderBottom: '1px solid #dbe3ef',
            borderRight: '1px solid #dbe3ef',
          }}
        >
          <VIDataTable
            tabsActive={tabsActive}
            // selectable={true}
            pagination={pagination}
            tableStyles={customDataTableActionStyle}
            scrollHeight="48"
            totalPreTaxMoney={totalAmountBeforeVATOutputInvoiceDelete}
            totalTaxAmount={totalAmountVATOutputInvoiceDelete}
            totalValue={totalFinalAmountOutputInvoiceDelete}
            onChangePage={(page) => {
              let iNewPage = parseInt(page);
              iNewPage -= 1;
              if (iNewPage < 0) {
                iNewPage = 0;
              }
              setFilters({
                ...filters,
                page: iNewPage,
              });
              Global.gFiltersOutputInvoiceDelete = {
                ...Global.gFiltersOutputInvoiceDelete,
                page: iNewPage,
              };
            }}
            onChangeRowsPerPage={(perPage) => {
              setFilters({
                ...filters,
                limit: perPage,
                page: 0,
              });
              Global.gFiltersOutputInvoiceDelete = {
                ...Global.gFiltersOutputInvoiceDelete,
                limit: perPage,
              };
            }}
            fullColumns={columns}
            data={listOutputInvoiceDelete}
            loading={isGettingOutputInvoiceListDelete}
            inputSearch={
              <>
                {dataCheck?.length === 0 && (
                  <div className="d-flex justify-content-between w-100">
                    <KTTooltip
                      className="w-100"
                      text="Nhập số hóa đơn, tên khách hàng, mã số thuế để tìm kiếm"
                      position={KTTooltipPositions.bottom}
                    >
                      <div className="w-100 h-100">
                        <VISearchField
                          placeholder="Nhập số hóa đơn, tên khách hàng, mã số thuế"
                          value={filters.q}
                          className="w-100 d-flex align-items-center"
                          typingTimeout={500}
                          onSubmit={(newValue) => {
                            setFilters({
                              ...filters,
                              q: newValue,
                              page: 0,
                            });
                            Global.gFiltersOutputInvoiceDelete = {
                              ...Global.gFiltersOutputInvoiceDelete,
                              q: newValue,
                              page: 0,
                            };
                          }}
                        />
                      </div>
                    </KTTooltip>
                  </div>
                )}
              </>
            }
            // filterbar={
            //   <div className="show-func">
            //     {dataCheck?.length > 0 && (
            //       <div className="d-flex flex-wrap">
            //         <div
            //           className="px-3 py-3 me-3 mt-2"
            //           style={{
            //             background: 'rgba(81, 128, 251, 0.10)',
            //             borderRadius: '8px',
            //             color: '#5180FB',
            //           }}
            //         >
            //           {t('Selected')} {dataCheck?.length} {t('Bill')}
            //         </div>
            //         <VIButton
            //           text={t('Download')}
            //           beforeIcon={<i className="fa-solid fa-download"></i>}
            //           className="btn-white me-3 mt-2"
            //           style={{ padding: '8px' }}
            //           onClick={() => {}}
            //           afterIcon={<></>}
            //         />
            //       </div>
            //     )}
            //   </div>
            // }
            onRowClick={(row) => {
              setShowModalDetailInvoices(true);
              setCurrentDetail(row);

              // console.log(row);
            }}
            onSetSelectedMultiItems={(rows) => setDataCheck(rows)}
            toolbar={
              <>
                {dataCheck?.length === 0 && (
                  <div className="">
                    <KTBSDropdown
                      contentEl={
                        <div ref={refDropdownFilterDeleteOutput}>
                          <DropdownFilterDeleteOutInvoice
                            handleFilterInvoice={handleFilterInvoice}
                            refDropdownFilterDeleteOutputMenu={refDropdownFilterDeleteOutputMenu}
                          />
                        </div>
                      }
                      dropdownMenuClassName="DropdownFilterMenu"
                      toggleElement={
                        <div className="d-flex align-items-center font-weight-bold justify-content-between DropdownFilter ">
                          <i
                            className="fa-regular fa-filter-list me-2"
                            style={{ color: '#1c2256' }}
                          ></i>
                          <span className="d-flex">
                            {t('FilterInvoices')}{' '}
                            {countFilter !== 0 && (
                              <div className="ms-1" style={{ color: '#F44141' }}>
                                ({countFilter})
                              </div>
                            )}
                          </span>
                        </div>
                      }
                      alignment={reSize?.width < 540 ? '' : KTBSDropdownAlignments.end}
                      autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                    />
                  </div>
                )}
              </>
            }
          />
        </div>
      </div>
      <VIDeleteModal
        show={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        deleteTitle={t('DeleteInvoice')}
        deleteText={'Bạn có chắc muốn xóa hóa đơn này không'}
        deleteItem={() => {}}
      />
      <VIModalDetailOutput
        show={showModalDetailInvoices}
        onHide={() => setShowModalDetailInvoices(false)}
        currentDetail={currentDetail}
      />
    </div>
  );
}

export default DeleteInvoices;
