import customDataTableStyle from 'assets/styles/customDataTableStyle';
import EmptyView from 'features/Others/components/EmptyView';
import {
  copyFromCartToOrder,
  removeOrderData,
  removeShoppingCartData,
} from 'features/Payment/paymentSlice';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIModal from 'general/components/VietInvoice/VIModal';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import usePayment from 'hooks/usePayment';
import useRouter from 'hooks/useRouter';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ViewOrder from '../ViewOrder';

CartPackageList.propTypes = {};

function CartPackageList(props) {
  // ---- params ----
  const {
    cartTotalPrice,
    cartPriceUnit,
    canShoppingCart,
    shoppingCartList,
    shoppingCartQuantityBuyMore,
    shoppingCartDurationExpired,
  } = usePayment();
  const { t } = useTranslation();
  const router = useRouter();
  const columns = useMemo(() => {
    return [
      {
        name: t('PackageOrService'),
        sortable: false,
        minWidth: '220px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.package?.packageCode}
            </p>
          );
        },
      },
      {
        name: t('Unit'),
        sortable: false,
        width: '90px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.package?.unit}
            </p>
          );
        },
      },
      {
        name: t('Quantity'),
        sortable: false,
        width: '120px',
        right: true,
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.quantity}
            </p>
          );
        },
      },
      {
        name: t('Price'),
        sortable: false,
        width: '140px',
        right: true,
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {Utils.formatCurrency(row?.package?.packageCost)}
            </p>
          );
        },
      },
      {
        name: t('LastPrice'),
        sortable: false,
        width: '140px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {Utils.formatCurrency(row?.package?.packageCost * row?.quantity)}
            </p>
          );
        },
      },
    ];
  }, [t]);
  const dispatch = useDispatch();
  const [modalOrderShowing, setModalOrderShowing] = useState(false);

  // ---- methods -----
  function handlePressEmptyCart() {
    AppDialogHelper.show(
      t('DeleteShoppingCart'),
      t('ConfirmTextDeleteShoppingCart'),
      [
        {
          title: t('Cancel'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
        {
          title: t('Yes'),
          type: 'negative',
          onClick: () => {
            AppDialogHelper.hide();
            dispatch(removeShoppingCartData());
          },
        },
      ],
      false
    );
  }

  function handlePressEditCart() {
    dispatch(copyFromCartToOrder());
    setModalOrderShowing(true);
  }

  return (
    <div
      className="bg-white p-6"
      style={{
        borderRadius: '16px',
        border: '1px solid #E8E9EB',
      }}
    >
      <div className="">
        {canShoppingCart && (
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-4">
              <span className="font-weight-bolder text-grey font-size-lg">
                ICORP Invoice - Hóa đơn đầu vào
              </span>
              <KTTooltip
                text={
                  <div className="d-flex flex-column align-items-start gap-2 font-size-base">
                    <div>
                      Ngày hết hạn:{' '}
                      <span className="font-weight-bolder">{shoppingCartDurationExpired}</span>
                    </div>
                    <div>
                      Số hoá đơn mua thêm:{' '}
                      <span className="font-weight-bolder">
                        {Utils.formatNumber(shoppingCartQuantityBuyMore)}
                      </span>
                    </div>
                  </div>
                }
              >
                <div className="d-flex align-items-center gap-2 cursor-pointer">
                  <i className="fa-regular fa-circle-question text-primary" />
                  <span className="text-primary">{t('ResourceInfo')}</span>
                </div>
              </KTTooltip>
            </div>

            <div className="d-flex align-items-center gap-4">
              <i
                className="fa-regular fa-edit text-primary cursor-pointer"
                onClick={handlePressEditCart}
              />
              <i
                className="fa-regular fa-trash text-danger cursor-pointer"
                onClick={handlePressEmptyCart}
              />
            </div>
          </div>
        )}
        <div
          className="mt-4"
          // style={{
          //   maxHeight: '40vh',
          // }}
        >
          <VIDataTable
            draggable={false}
            tableStyles={customDataTableStyle}
            additionalClassName="p-0 shadow-none"
            additionalCardBodyClassName="p-0"
            selectable={false}
            pagination={null}
            onChangePage={(page) => {}}
            onChangeRowsPerPage={(perPage) => {}}
            fullColumns={columns}
            data={shoppingCartList}
            loading={false}
            onRowClick={(row) => {}}
            onSetSelectedMultiItems={(rows) => {}}
            haveCardHeader={false}
            emptyComponent={
              <EmptyView
                message={t('TextShoppingCartEmpty')}
                image={AppResource.images.imgEmptyPayment}
                buttonText={t('BuyNow')}
                onPressButton={() => {
                  router.navigate('/store');
                }}
              />
            }
          />
        </div>
      </div>

      {/* modal order */}
      <VIModal
        titleModal={t('Order')}
        modalSize="lg"
        show={modalOrderShowing}
        contentModal={
          <ViewOrder
            onPressCancel={() => {
              setModalOrderShowing(false);
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalOrderShowing(false);
          dispatch(removeOrderData());
        }}
      />
    </div>
  );
}

export default CartPackageList;
