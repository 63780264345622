import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTFormTextArea from 'general/components/OtherKeenComponents/Forms/KTFormTextArea';
import VIButton from 'general/components/VietInvoice/VIButton';
import SystemHelper from 'general/helpers/SystemHelper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

AddCompanyTitleView.propTypes = {
  onPressCancel: PropTypes.func,
  onCreateCompanyTitleSuccess: PropTypes.func,
};

AddCompanyTitleView.defaultProps = {
  onPressCancel: null,
  onCreateCompanyTitleSuccess: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * onCreateCompanyTitleSuccess: function,
 * }} props
 * @returns
 */
function AddCompanyTitleView(props) {
  // ---- params -----
  const { onPressCancel, onCreateCompanyTitleSuccess } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // ---- methods -----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  return (
    <div
      className=""
      style={{
        maxHeight: '70vh',
        overflow: 'auto',
      }}
    >
      <Formik
        initialValues={{
          titleName: '',
          titleDescription: '',
        }}
        validationSchema={Yup.object({
          titleName: Yup.string().required(t('Required')),
        })}
        enableReinitialize
        onSubmit={(values) => {
          const params = {
            name: values.titleName,
            description: values.titleDescription,
          };
          const res = SystemHelper.callApiCreateCompanyTitle(params);
          if (res) {
            handlePressCancel();
          }
        }}
      >
        {(formikProps) => (
          <div className="">
            <div className="row m-0">
              {/* ten chuc danh */}
              <div className="col-12">
                <KTFormGroup
                  label={
                    <>
                      {t('TitleName')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="titleName"
                  inputElement={
                    <FastField name="titleName">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('TitleName'))}`}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {/* mo ta */}
              <div className="col-12">
                <KTFormGroup
                  label={<>{t('Description')}</>}
                  inputName="titleDescription"
                  inputElement={
                    <FastField name="titleDescription">
                      {({ field, form, meta }) => (
                        <KTFormTextArea
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('Description'))}`}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>
            </div>

            {/* actions */}
            <div className="d-flex align-items-center justify-content-end gap-3">
              <VIButton
                text={t('Cancel')}
                className="btn-grey"
                style={{ padding: '8px' }}
                onClick={handlePressCancel}
              />
              <VIButton
                text={t('Save')}
                className=""
                style={{ padding: '8px' }}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default AddCompanyTitleView;
