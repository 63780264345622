import KTLayoutFooter from 'assets/plugins/ktfooter';
import AppConfigs from 'general/constants/AppConfigs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function KT01Footer(props) {
  useEffect(() => {
    KTLayoutFooter.init('kt_footer');
  }, []);

  return (
    <div
      id="kt_footer"
      className={`footer bg-white py-4 ${
        AppConfigs.isHideFooter ? 'd-none' : 'd-flex'
      } flex-lg-column`}
    >
      <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        {/* copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">2023©</span>
          <a className="text-dark-75 text-hover-primary" target="_blank" href="https://icorp.vn/">
            ICORP
          </a>
        </div>

        {/* nav */}
        <div className="nav nav-dark">
          <Link to="/test" className="nav-link pl-0 pr-2">
            Quỳnh Anh
          </Link>
          <Link to="/test-ductu" className="nav-link pr-2">
            Đức Tư
          </Link>
          <Link to="/test-dung" className="nav-link pr-0">
            Anh Dũng
          </Link>
          <Link to="/landing-page" className="nav-link pr-0">
            Landing Page
          </Link>
        </div>
      </div>
    </div>
  );
}

export default KT01Footer;
