import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useMemo } from 'react';
import ta from 'date-fns/locale/ta';

CellSupplierConnect.propTypes = {
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
  connected: PropTypes.bool,
  onPressAction: PropTypes.func,

  connectionList: PropTypes.array,
  showLabelConnected: PropTypes.bool,
};

CellSupplierConnect.defaultProps = {
  className: '',
  imgClassName: '',
  label: '',
  buttonText: null,
  imageUrl: '',
  onClick: null,
  connected: false,
  onPressAction: null,

  connectionList: [],
  showLabelConnected: false,
};

/**
 * @param {string} className className
 * @param {string} imgClassName image className
 * @param {string} label
 * @param {string} buttonText text in button
 * @param {string} imageUrl img source
 * @param {function} onClick Handle when click button
 * @param {array} connectionList ds ket noi
 * @param {boolean} showLabelConnected
 */

function CellSupplierConnect(props) {
  const {
    imageUrl,
    label,
    buttonText,
    className,
    imgClassName,
    onClick,
    connected,
    onPressAction,

    connectionList,
    showLabelConnected,
  } = props;
  const { t } = useTranslation();
  const dropdownMenuItems = useMemo(() => {
    const menuItems = [
      { name: t('AddMoreAccount'), value: 'addMoreAccount', icon: 'fa-regular fa-add' },
      { name: t('ReConnect'), value: 'reconnect', icon: 'fa-regular fa-rotate-left' },
      {
        name: t('Disconnect'),
        value: 'disconnect',
        icon: 'fa-regular fa-link-simple-slash',
      },
    ];

    if (connectionList?.length > 1) {
      menuItems.splice(1, 1);
    }
    menuItems.unshift({
      name: t('ListAccounts'),
      value: 'listAccounts',
      icon: 'fa-regular fa-users',
    });

    return menuItems;
  }, [t, connectionList]);

  return (
    <div
      className={`CellSupplierConnect bg-white border-0 p-5 d-flex flex-column align-items-start justify-content-between ${className}`}
    >
      <div className="d-flex w-100 align-items-start">
        <div className="flex-grow-1">
          <img
            // height={50}
            className={`${imgClassName} h-50px`}
            style={{ objectFit: 'contain', maxWidth: 'calc(100%)' }}
            src={Utils.getFullUrl(imageUrl)}
          />
          <p className="mb-2 mt-3 font-size-lg font-weight-bolder">{label}</p>
        </div>
        {connected && (
          <KTBSDropdown
            toggleElement={
              <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center">
                <i className="fa-regular fa-ellipsis-vertical" />
              </div>
            }
            alignment={KTBSDropdownAlignments.end}
            dropdownMenuItems={dropdownMenuItems}
            selectedValue=""
            onChange={(newValue) => {
              if (onPressAction) {
                onPressAction(newValue);
              }
            }}
          />
        )}
      </div>
      {connected && (
        <KTTooltip
          text={
            <div className="d-flex flex-column align-items-start">
              <p className="mb-2">{t('ListConnectedAccount')}</p>
              {connectionList?.length > 0 &&
                connectionList?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`font-weight-bolder d-flex align-items-center gap-2 ${
                        index < connectionList?.length - 1 ? 'mb-2' : ''
                      }`}
                      style={{
                        color: '#28A043',
                      }}
                    >
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: '8px', color: 'inherit' }}
                      />
                      {item?.usernameConnection}
                    </div>
                  );
                })}
            </div>
          }
        >
          <div className="d-flex align-items-center mb-2">
            <div
              className="w-10px h-10px rounded-circle mr-2"
              style={{
                backgroundColor: '#28A043',
              }}
            ></div>
            <p
              className="mb-0 font-weight-bold"
              style={{
                color: '#28A043',
              }}
            >
              {t('Connecting')}
            </p>
          </div>
        </KTTooltip>
      )}

      <div className="mt-2 d-flex align-items-center w-100 justify-content-between">
        <VIButton
          disabled={_.isNull(onClick)}
          className="btn-blue border-0 p-2"
          text={buttonText ? buttonText : 'Kết nối'}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        />

        {showLabelConnected && !connected && (
          <div className="d-flex align-items-center mb-2">
            <div
              className="w-10px h-10px rounded-circle mr-2"
              style={{
                backgroundColor: '#28A043',
              }}
            ></div>
            <p
              className="mb-0 font-weight-bold"
              style={{
                color: '#28A043',
              }}
            >
              {t('Connecting')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default CellSupplierConnect;
