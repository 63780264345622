import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import VIButton from 'general/components/VietInvoice/VIButton';
import AccountHelper from 'general/helpers/AccountHelper';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';

AccountChangePasswordScreen.propTypes = {
  navItemList: PropTypes.array,
};

AccountChangePasswordScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function AccountChangePasswordScreen(props) {
  // MARK --- Params ---
  const { navItemList } = props;
  const { t } = useTranslation();
  const refCardHeader = useRef(null);
  const refChangePass = useRef(null);
  const [cardBodyMaxHeight, setCardBodyMaxHeight] = useState('55vh');
  const refFormik = useRef();
  const { isChangingPassword } = useSelector((state) => state.auth);
  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  // scroll into card
  function resizeScreen() {
    setCardBodyMaxHeight(`${window.innerHeight - refCardHeader?.current?.clientHeight - 207}px`);
  }

  window.onresize = resizeScreen;

  useEffect(() => {
    resizeScreen();
  }, [window.innerWidth, window.innerHeight]);

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }}
      enableReinitialize
      validationSchema={Yup.object({
        oldPassword: Yup.string().required(t('OldPasswordRequired')),
        newPassword: Yup.string()
          .required(t('NewPasswordRequired'))
          .min(6, t('PasswordMinInvalid'))
          .matches(/^\S*$/, t('PasswordContainSpaceInvalid')),
        confirmNewPassword: Yup.string()
          .required(t('ConfirmPasswordRequired'))
          .oneOf([Yup.ref('newPassword'), null], t('PasswordNotMatch')),
      })}
      onSubmit={async (values) => {
        const oldPassword = Utils.sha256(values.oldPassword);
        const newPassword = Utils.sha256(values.newPassword);
        const res = await AccountHelper.callApiChangePassword(oldPassword, newPassword);
        if (res) {
          // doi mat khau thanh cong
          refFormik.current?.handleReset();
        }
      }}
    >
      {(formikProps) => {
        refFormik.current = formikProps;
        return (
          <div className="h-100" ref={refChangePass}>
            <div
              style={{ height: '65px' }}
              ref={refCardHeader}
              className="px-7 py-4 border-bottom d-flex flex-wrap align-items-center justify-content-between gap-2 min-h-65px"
            >
              <div className="card-title align-items-start flex-column m-0">
                <div className="d-flex align-items-center justify-content-center gap-1">
                  <div className="d-block d-lg-none">
                    <KTBSDropdown
                      toggleElement={
                        <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                          <i className="fa-solid fa-bars text-grey" />
                        </div>
                      }
                      alignment={KTBSDropdownAlignments.start}
                      dropdownMenuItems={dropdownMenuItems}
                      selectedValue={t('ChangePassword')}
                      onChange={(newValue) => {}}
                    />
                  </div>{' '}
                  <h3 className="card-label font-weight-bolder text-grey m-0">
                    {t('ChangePassword')}
                  </h3>
                </div>
              </div>

              <div className="">
                <VIButton
                  className="btn-blue"
                  text={isChangingPassword ? `${t('Updating')}...` : t('Save')}
                  onClick={() => {
                    formikProps.handleSubmit();
                  }}
                  disabled={isChangingPassword}
                  beforeIcon={
                    isChangingPassword ? (
                      <ClockLoader color="white" size={20} />
                    ) : (
                      <i className="fa-regular fa-circle-check" />
                    )
                  }
                />
              </div>
            </div>

            <div className="card-body overflow-auto " style={{ maxHeight: cardBodyMaxHeight }}>
              <div className="h-100 d-flex flex-column justify-content-between gap-5">
                {/* note */}
                <div
                  className="bg-light p-4 mb-6 d-flex flex-column"
                  style={{ borderRadius: '12px' }}
                >
                  <span className="font-weight-bold mb-2" style={{ color: '#F44141' }}>
                    {t('Noted')}
                    {':'}
                  </span>{' '}
                  <span>{t('ChangePasswordTextNote')}</span>
                </div>
                <div className="row">
                  {/* oldPassword */}
                  <div className="row">
                    <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                      {`${t('OldPassword')}`}
                    </label>
                    <KTFormGroup
                      additionalClassName="col-lg-9 col-xl-6"
                      inputName="oldPassword"
                      inputElement={
                        <div>
                          <FastField name="oldPassword">
                            {({ field, form, meta }) => {
                              return (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.password}
                                  placeholder={`${_.capitalize(t('OldPassword'))}`}
                                  enableInputGroup={true}
                                  prependElements={[<i className="fa-regular fa-key"></i>]}
                                />
                              );
                            }}
                          </FastField>
                        </div>
                      }
                    />
                  </div>

                  {/* newPassword */}
                  <div className="row">
                    <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                      {`${t('NewPassword')}`}
                    </label>
                    <KTFormGroup
                      additionalClassName="col-lg-9 col-xl-6"
                      inputName="newPassword"
                      inputElement={
                        <div>
                          <FastField name="newPassword">
                            {({ field, form, meta }) => {
                              return (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.password}
                                  placeholder={`${_.capitalize(t('NewPassword'))}`}
                                  enableInputGroup={true}
                                  prependElements={[<i className="fa-regular fa-key"></i>]}
                                />
                              );
                            }}
                          </FastField>
                        </div>
                      }
                    />
                  </div>

                  {/* cònirmPassword */}
                  <div className="row">
                    <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                      {`${t('ConfirmPassword')}`}
                    </label>
                    <KTFormGroup
                      additionalClassName="col-lg-9 col-xl-6"
                      inputName="confirmNewPassword"
                      inputElement={
                        <div>
                          <FastField name="confirmNewPassword">
                            {({ field, form, meta }) => {
                              return (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.password}
                                  placeholder={`${_.capitalize(t('ConfirmPassword'))}`}
                                  enableInputGroup={true}
                                  prependElements={[<i className="fa-regular fa-key"></i>]}
                                />
                              );
                            }}
                          </FastField>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default AccountChangePasswordScreen;
