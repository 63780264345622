import EmptyView from 'features/Others/components/EmptyView';
import { FastField, Formik } from 'formik';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIButton from 'general/components/VietInvoice/VIButton';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import AppConfigs from 'general/constants/AppConfigs';
import AppResource from 'general/constants/AppResource';
import AppHelper from 'general/helpers/AppHelper';
import StringUtils from 'general/utils/StringUtils';
import useAccount from 'hooks/useAccount';
import useRouter from 'hooks/useRouter';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Fade } from 'react-reveal';
import * as Yup from 'yup';

function FaqsAndContact(props) {
  const refCardHeader = useRef(null);
  const { t } = useTranslation();
  const { faqList, isGettingFaqList } = useSelector((state) => state.faq);
  const [filters, setFilters] = useState({
    q: '',
  });
  const [filteredFaqList, setFilteredFaqList] = useState([]);
  const refFormik = useRef();
  const router = useRouter();
  const account = useAccount();

  useEffect(() => {
    const { q } = filters;
    let filteredItems = _.chain(faqList);
    // loc theo text
    filteredItems = filteredItems.filter((item) => {
      return (
        StringUtils.removeVietnameseTones(item?.question)
          .toLowerCase()
          ?.includes(StringUtils.removeVietnameseTones(q).toLowerCase()) ||
        StringUtils.removeVietnameseTones(item?.answer)
          .toLowerCase()
          ?.includes(StringUtils.removeVietnameseTones(q).toLowerCase())
      );
    });
    setFilteredFaqList(filteredItems.value());
  }, [filters, faqList]);

  return (
    <div className="FaqsAndContact bg-white py-20" id="contact">
      <div className="container-xl d-flex flex-column flex-lg-row">
        <Fade right delay={200}>
          <div className="col-12 col-lg-6 col-xl-7 pr-lg-9">
            <div className="d-flex flex-column align-items-start">
              <h1 className="card-label text-grey font-weight-bolder mb-3">{t('FAQ')}</h1>
              <VISearchField
                placeholder={`${t('EnterToSearch')}...`}
                value={''}
                className="w-100 h-50px"
                inputStyle={{ borderRadius: '50px' }}
                typingTimeout={500}
                onSubmit={(newValue) => {
                  setFilters({ ...filters, q: newValue });
                }}
              />
            </div>

            {/* danh sach faqs */}
            <div className="mt-4">
              {filteredFaqList?.length > 0 ? (
                <Accordion defaultActiveKey="0" flush className="accordion-list">
                  {_.take(filteredFaqList, AppConfigs.topFAQLandingPageNumber)?.map(
                    (item, index) => (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header className="">
                          {index + 1}. {item?.question}
                        </Accordion.Header>
                        <div className="">
                          <Accordion.Body>
                            <div className="mb-2">{item?.answer}</div>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                    )
                  )}
                </Accordion>
              ) : (
                <div className="h-100">
                  <EmptyView
                    message={t('NoDataToDisplay')}
                    imgClassName="mt-10"
                    image={AppResource.images.imgEmptyNotFound}
                  />
                </div>
              )}
            </div>

            {/* xem tat ca */}
            {account.accountId && filteredFaqList?.length > 0 && (
              <div className="d-flex">
                <div
                  onClick={() => {
                    router.navigate('/inbot/faq');
                  }}
                  className="p-4 rounded d-flex justify-content-start align-items-center text-primary text-hover-white bg-hover-primary bg-light-primary font-weight-bold mt-4"
                >
                  {t('ViewAll')}
                </div>
                <div className="flex-grow-1"></div>
              </div>
            )}
          </div>
        </Fade>

        <Fade left>
          <div className="col-12 col-lg-6 col-xl-5">
            <h1 className="card-label text-grey font-weight-bolder mb-3">{t('Contact')}</h1>
            <p className="font-size-lg font-weight-bold text-dark-65">
              Mọi thông tin chi tiết về chính sách hợp tác kinh doanh, các gói cước sản phẩm, dịch
              vụ, Quý Khách hàng vui lòng liên hệ hotline
            </p>
            <a href={`tel:${AppConfigs.phoneNumber}`}>
              <VIButton
                className="btn-green border-0 w-100 font-size-h3"
                text={AppConfigs.phoneNumber}
                beforeIcon={<i className="fa-solid fa-phone text-white me-2"></i>}
              />
            </a>
            <h1 className="card-label text-grey font-weight-bolder mt-10 mb-3">
              Để lại thông tin ngay để nhận tư vấn miễn phí
            </h1>

            <Formik
              initialValues={{
                fullname: '',
                email: '',
                phone: '',
              }}
              validationSchema={Yup.object({
                fullname: Yup.string().required(t('FullnameRequired')),
                email: Yup.string().required(t('EmailRequired')).email(t('EmailInvalid')),
                phone: Yup.string()
                  .required(t('PhoneNumberRequired'))
                  .test('len', t('PhoneInvalid', { field: t('phone') }), (val) => {
                    if (val) {
                      return (
                        !val.toString().includes(' ') && val.toString().match(AppConfigs.regexPhone)
                      );
                    }
                    return true;
                  }),
              })}
              onSubmit={async (values) => {
                console.log({ values });
                const params = {
                  nameCustomer: values.fullname,
                  emailCustomer: values.email,
                  phoneCustomer: values.phone,
                };
                const res = await AppHelper.callApiCreateContactCustomer(params);
                if (res) {
                  refFormik.current?.handleReset();
                  AppDialogHelper.show(
                    t('ThanksForContactUs'),
                    t('WeWillContactSoon'),
                    [
                      {
                        title: t('Close'),
                        type: 'neutral',
                        onClick: () => {
                          AppDialogHelper.hide();
                        },
                      },
                    ],
                    false,
                    AppResource.images.imgContactUsSuccess
                  );
                }
              }}
            >
              {(formikProps) => {
                refFormik.current = formikProps;
                return (
                  <div className="row">
                    {/* ho va ten */}
                    <div className="col-12">
                      <KTFormGroup
                        label={
                          <>
                            {t('Fullname')} <span className="text-danger">(*)</span>
                          </>
                        }
                        inputName="fullname"
                        inputElement={
                          <FastField name="fullname">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onBlur={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('Fullname'))}...`}
                                additionalInputClassName="rounded-0"
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* email */}
                    <div className="col-12">
                      <KTFormGroup
                        label={
                          <>
                            {t('Email')} <span className="text-danger">(*)</span>
                          </>
                        }
                        inputName="email"
                        inputElement={
                          <FastField name="email">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onBlur={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.email}
                                placeholder={`${_.capitalize(t('Email'))}...`}
                                additionalInputClassName="rounded-0"
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* phone number */}
                    <div className="col-12">
                      <KTFormGroup
                        label={
                          <>
                            {t('PhoneNumber')} <span className="text-danger">(*)</span>
                          </>
                        }
                        inputName="phone"
                        inputElement={
                          <FastField name="phone">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onBlur={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('PhoneNumber'))}...`}
                                additionalInputClassName="rounded-0"
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* action */}
                    <VIButton
                      onClick={() => {
                        formikProps.handleSubmit();
                      }}
                      className="btn-blue w-100 font-size-h5 mt-2"
                      text={t('SendInformation')}
                    />
                  </div>
                );
              }}
            </Formik>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default FaqsAndContact;
