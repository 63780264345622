import AppConfigs from 'general/constants/AppConfigs';
import AppDimension from 'general/constants/AppDimension';
import Utils from 'general/utils/Utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function useConfig() {
  // ---- params ----
  const { t } = useTranslation();
  const { appConfigList } = useSelector((state) => state.app);

  return useMemo(() => {
    return {
      // bank account info
      bankName: _.find(appConfigList, (item) => item?.key === 'BANK_NAME')?.value,
      bankAccountNumber: _.find(appConfigList, (item) => item?.key === 'BANK_ACCOUNT_NUMBER')
        ?.value,
      bankBranch: _.find(appConfigList, (item) => item?.key === 'BANK_BRANCH')?.value,
      bankAccountName: _.find(appConfigList, (item) => item?.key === 'BANK_ACCOUNT_NAME')?.value,

      // links
      urlBaoGia: _.find(appConfigList, (item) => item?.key === 'BAO_GIA')?.value,
      urlThoaThuan:
        _.find(appConfigList, (item) => item?.key === 'THOA_THUAN_SU_DUNG_DICH_VU')?.value ?? '',
      urlImportSupplierCustomerSample: _.find(
        appConfigList,
        (item) => item?.key === 'IMPORT_NCC_KH_SAMPLE'
      )?.value,

      // thong bao he thong tren dau trang
      systemNotification: {
        show: _.findIndex(appConfigList, (item) => item?.key === 'SYSTEM_BANNER_TEXT') !== -1,
        text: _.find(appConfigList, (item) => item?.key === 'SYSTEM_BANNER_TEXT')?.value,
        url: _.find(appConfigList, (item) => item?.key === 'SYSTEM_BANNER_LINK')?.value,
        bannerHeight:
          _.findIndex(appConfigList, (item) => item?.key === 'SYSTEM_BANNER_TEXT') !== -1
            ? AppDimension.notificationMarqueeHeight
            : 0,
      },

      // thiet lap ten mien email
      mailServerDomain:
        _.find(appConfigList, (item) => item?.key === 'MAIL_SERVER_DOMAIN')?.value ??
        AppConfigs.emailSuffix,

      // link dang ky website voi BCT
      urlBCT: _.find(appConfigList, (item) => item?.key === 'BCT_URL')?.value,

      // anh decor sidebar
      sidebarImgDecor: _.find(appConfigList, (item) => item?.key === 'SIDEBAR_IMG_DECOR')?.value,

      // hien thi thong tin cskh sidebar
      sidebarShowFooter: _.find(appConfigList, (item) => item?.key === 'SIDEBAR_SHOW_FOOTER')
        ?.value,

      // an banner thong bao het tai nguyen
      hideBannerResourceExpired: _.find(
        appConfigList,
        (item) => item?.key === 'HIDE_BANNER_RESOURCE_EXPIRED'
      )?.value,
    };
  }, [appConfigList]);
}

export default useConfig;
