const customDataTableStyle = {
  tableWrapper: {
    style: {
      height: '100%',
    },
  },
  rows: {
    style: {
      minHeight: '40px', // override the row height
      fontSize: '1rem',
      color: '#3C4376',
      paddingLeft: '0px',
      borderleft: '1px solid #ebedf3',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#ebedf3',
      },
      '&:last-of-type': {
        borderBottom: '1px solid #ebedf3',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: '#F3F6F9 ',
    },
  },
  headRow: {
    style: {
      borderBottomColor: '#ebedf3',
      borderTop: '1px solid #ebedf3',
    },
  },
  cells: {
    style: {
      padding: '0.6rem 1rem',
      // '&:not(:last-of-type)': {
      borderRight: '1px solid #ebedf3',
      // },
      '&:first-of-type': {
        // paddingLeft: '2.5rem',
        borderLeft: '1px solid #ebedf3',
      },
      color: '#3C4376',
    },
  },
  headCells: {
    style: {
      fontSize: '0.9rem',
      fontWeight: 600,
      paddingLeft: '0px',
      paddingRight: '0px',
      color: '#3C4376',
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
      lineHeight: '1.5 !important',
      padding: '0 1rem',
      // '&:not(:last-of-type)': {
      borderRight: '1px solid #ebedf3',
      // },
      '&:first-of-type': {
        // paddingLeft: '2.5rem',
        borderLeft: '1px solid #ebedf3',
      },
      whiteSpace: 'normal',
    },
    activeSortStyle: {
      '&:focus': {
        outline: 'none',
        color: '#3699FF',
      },
      '&:not(focus)': {
        color: '#3699FF',
      },
      '&:hover:not(:focus)': {
        color: '#3699FF',
      },
    },
    inactiveSortStyle: {
      '&:focus': {
        outline: 'none',
        color: '#B5B5C3',
      },
      '&:hover': {
        color: '#3699FF',
      },
    },
  },
  expanderCell: {
    style: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  expanderButton: {
    style: {
      display: 'block',
      color: '#7177A8',
      borderRadius: '2px',
      transition: '0.25s',
      height: '17px',
      width: '17px',
      padding: '0px',
      '&:hover:enabled': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        // color: theme.button.disabled,
      },
      '&:hover:not(:disabled)': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        // backgroundColor: theme.button.hover,
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: 'transparent',
        // backgroundColor: theme.button.focus,
      },
      svg: {
        margin: 'auto',
      },
    },
  },
};

export default customDataTableStyle;
