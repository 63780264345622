import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import VIElements from 'general/components/VietInvoice/VIElements';
import { sha256 } from 'js-sha256';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import _ from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';

// Util functions
const Utils = {
  // download url
  download: async (url, filename) => {
    // axiosClient
    //   .get(url, {
    //     httpHeaders: {
    //       'session-token': axiosClient.defaults.headers.common['session-token'],
    //     },
    //     responseType: 'blob',
    //   })
    //   .then((response) => {
    //     var objectUrl = URL.createObjectURL(response);
    //     const link = document.createElement('a');
    //     link.setAttribute('href', objectUrl);
    //     link.setAttribute('download', filename);
    //     link.style.display = 'none';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(objectUrl);
    //   })
    //   .catch((error) => console.log({ error }));

    const data = await fetch(url);
    const blob = await data.blob();
    const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', objectUrl);
    link.setAttribute('download', filename);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(objectUrl);
  },

  // download multi files -> zip file
  downloadMultiFiles: async (files, zipFilename) => {
    var zip = new JSZip();
    var count = 0;

    files.forEach(function (file) {
      const filename = file.filename;
      const url = file.url;
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count == files.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, zipFilename);
          });
        }
      });
    });
  },

  getDateFilterOption: (type) => {
    switch (type) {
      case 'HOM_QUA':
        return [
          moment().subtract(1, 'day').startOf('day'),
          moment().subtract(1, 'day').endOf('day'),
        ];
      case 'HOM_NAY':
        return [moment(), moment()];
      case 'TUAN_TRUOC':
        return [
          moment().subtract(1, 'weeks').startOf('isoWeek'),
          moment().subtract(1, 'weeks').endOf('isoWeek'),
        ];
      case 'TUAN_NAY':
        return [moment().startOf('week'), moment()];
      case 'THANG_TRUOC':
        return [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ];
      case 'THANG_NAY':
        return [moment().startOf('month'), moment()];
      case '30_NGAY_GAN_NHAT':
        return [moment().subtract(29, 'days'), moment()];
      case 'QUY_TRUOC':
        return [
          moment().subtract(1, 'quarters').startOf('quarter'),
          moment().subtract(1, 'quarters').endOf('quarter'),
        ];
      case 'QUY_NAY':
        return [moment().startOf('quarters'), moment()];
      case 'SAU_THANG_DAU_NAM':
        return [moment().startOf('year'), moment().startOf('year').add(6, 'months')];
      case 'SAU_THANG_CUOI_NAM':
        return [moment().endOf('year').subtract(6, 'months'), moment().endOf('year')];
      case 'NAM_TRUOC':
        return [
          moment().subtract(1, 'years').startOf('year'),
          moment().subtract(1, 'years').endOf('year'),
        ];
      case 'NAM_NAY':
        return [moment().startOf('years'), moment()];
      case 'THANG_1':
        return [moment().month(0).startOf('month'), moment().month(0).endOf('month')];
      case 'THANG_2':
        return [moment().month(1).startOf('month'), moment().month(1).endOf('month')];
      case 'THANG_3':
        return [moment().month(2).startOf('month'), moment().month(2).endOf('month')];
      case 'THANG_4':
        return [moment().month(3).startOf('month'), moment().month(3).endOf('month')];
      case 'THANG_5':
        return [moment().month(4).startOf('month'), moment().month(4).endOf('month')];
      case 'THANG_6':
        return [moment().month(5).startOf('month'), moment().month(5).endOf('month')];
      case 'THANG_7':
        return [moment().month(6).startOf('month'), moment().month(6).endOf('month')];
      case 'THANG_8':
        return [moment().month(7).startOf('month'), moment().month(7).endOf('month')];
      case 'THANG_9':
        return [moment().month(8).startOf('month'), moment().month(8).endOf('month')];
      case 'THANG_10':
        return [moment().month(9).startOf('month'), moment().month(9).endOf('month')];
      case 'THANG_11':
        return [moment().month(10).startOf('month'), moment().month(10).endOf('month')];
      case 'THANG_12':
        return [moment().month(11).startOf('month'), moment().month(11).endOf('month')];
      case 'QUY_1':
        return [moment().quarter(1).startOf('quarter'), moment().quarter(1).endOf('quarter')];
      case 'QUY_2':
        return [moment().quarter(2).startOf('quarter'), moment().quarter(2).endOf('quarter')];
      case 'QUY_3':
        return [moment().quarter(3).startOf('quarter'), moment().quarter(3).endOf('quarter')];
      case 'QUY_4':
        return [moment().quarter(4).startOf('quarter'), moment().quarter(4).endOf('quarter')];
      case 'TUY_CHON':
        return [];
      default:
        return null;
    }
  },
  /**
   * Lock body document scroll
   */
  lockedBodyScroll: () => {
    document.body.classList.add('body-overflow-hidden');
  },

  /**
   * Unlock body document scroll
   */
  unlockedBodyScroll: () => {
    document.body.classList.remove('body-overflow-hidden');
  },

  /**
   *
   * @param {string} text Text can copy vao clipboard
   * @param {function} callback Callback khi hoan thanh copy
   */
  copyTextToClipboard: (text, callback) => {
    navigator.clipboard.writeText(text);
    if (_.isFunction(callback)) {
      callback();
    }
  },
  // scroll to top
  scrollToTop: () => {
    window.scroll({ left: 0, top: 0, behavior: 'smooth' });
  },

  /**
   * Mo url trong tab moi
   * @param {string} url Duong dan
   */
  openInNewTab: (url) => {
    window.open(Utils.getFullUrl(url), '_blank').focus();
  },

  /**
   * Mo url trong tab hien tai
   * @param {string} url Duong dan
   */
  openInCurrentTab: (url) => {
    // window.open(url);
    window.location.replace(Utils.getFullUrl(url));
  },

  /**
   * Get url
   * @param {string} url Duong dan
   * @returns
   */
  getFullUrl: (url) => {
    if (url && !url.startsWith('http') && !url.startsWith('blob')) {
      // console.log(`${process.env.REACT_APP_BASE_URL}${encodeURI(url)}`);
      return `${process.env.REACT_APP_BASE_URL}${encodeURI(url)}`;
    }
    // console.log(encodeURI(url));
    return encodeURI(url);
  },
  // format date time
  formatDateTime: (sDateTime, sFormat = 'DD/MM/YYYY HH:mm', utc = false) => {
    if (utc) {
      return moment(sDateTime).utc().format(sFormat);
    }
    return moment(sDateTime).local().format(sFormat);
  },
  formatAddDateTime: (sDateTime, amount, unit, sFormat, utc = false) => {
    if (utc) {
      return moment(sDateTime).utc().add(amount, unit).format(sFormat);
    }
    return moment(sDateTime).local().add(amount, unit).format(sFormat);
  },
  /**
   * Dinh dang number: 100.300,345
   * @param {number} iNumber So can dinh dang
   * @returns
   */
  formatNumber: (iNumber) => {
    return new Intl.NumberFormat('de-DE').format(iNumber);
  },
  formatNumberDecimal: (iNumber, precision = 2) => {
    if (iNumber == null || typeof iNumber === 'undefined') {
      // return "n/a";
      return '0';
    }
    if (isNaN(iNumber)) {
      // return "n/a";
      return '0';
    }
    let iRet = new Intl.NumberFormat().format(iNumber);
    if (_.isNaN(iRet)) {
      return '0';
    }
    if (!isFinite(iNumber)) {
      // return "n/a";
      return '0';
    }

    return currency(iNumber, { symbol: '', precision: precision }).format();
  },
  // dinh dang gia tien
  formatCurrency: (str, currency = '', separation = '.') => {
    const format = str ? String(str).replace(/\B(?=(\d{3})+(?!\d))/g, separation) : 0;
    return format + currency;
  },

  /**
   * Set empty key to null
   * @param {object} items
   */
  setEmptyKeyToNull: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (value === '' || value === undefined) {
        items[key] = null;
      }
    }
  },

  /**
   * Remove null key
   * @param {object} items
   */
  removeNullKey: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (_.isNull(value) || _.isUndefined(value)) {
        delete items[key];
      }
    }
  },

  /**
   * Get current window url
   * @returns
   */
  getCurrentUrl: () => {
    return window.location.href;
  },
  // convert unsigned text
  removeAccents: (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D');
  },
  getRangeDates: (range) => {
    const startDate = moment.utc(range.startDate);
    const endDate = moment.utc(range.endDate);
    const datesArray = [];

    for (
      let d = moment(startDate).add(1, 'day');
      d < moment(endDate).add(1, 'day');
      d.add(1, 'day')
    ) {
      datesArray.push(moment(d).utc().format('DD/MM/YYYY'));
    }

    return datesArray;
  },

  /**
   * Scroll div to bottom
   * @param {string} id id of element
   */
  scrollToBottom: (id) => {
    var div = document.getElementById(id);
    if (div) {
      div.scrollTop = div.scrollHeight - div.clientHeight;
    }
  },

  convertString: (inputString) => {
    // Remove accents and convert to lowercase
    const normalizedString = inputString
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    // Remove spaces and convert "đ" to "d"
    const convertedString = normalizedString.replace(/\s+/g, '').replace(/đ/g, 'd');

    return convertedString;
  },

  insertElement: (array, element, index) => {
    return [...array.slice(0, index), element, ...array.slice(index)];
  },

  removeDuplicates: (arr) => {
    return [...new Set(arr)];
  },

  blurColor: (colorCode, opacity) => {
    return `rgba(${parseInt(colorCode.slice(1, 3), 16)}, ${parseInt(
      colorCode.slice(3, 5),
      16
    )}, ${parseInt(colorCode.slice(5, 7), 16)}, ${opacity})`;
  },

  sha256: (text) => {
    return sha256(text);
  },

  // Hàm duyệt qua cây folder và trả về mảng chứa tất cả các node
  getAllNodes: (folders) => {
    const allNodes = [];

    function traverse(folder) {
      allNodes.push(folder);

      if (folder.ChildrenOrganizations && folder.ChildrenOrganizations.length > 0) {
        folder.ChildrenOrganizations.forEach((child) => {
          traverse(child);
        });
      }
    }

    folders.forEach((rootFolder) => {
      traverse(rootFolder);
    });

    return allNodes;
  },

  // Function to get all children of a node in a folder tree
  getAllChildren: (node) => {
    let children = [];

    if (node.ChildrenOrganizations && node.ChildrenOrganizations.length > 0) {
      for (const childNode of node.ChildrenOrganizations) {
        children.push(childNode);
        children.push(...Utils.getAllChildren(childNode));
      }
    }

    return children;
  },

  mergeAndRemoveDuplicates: (arr1, arr2) => {
    // Combine both arrays using the spread operator
    const mergedArray = [...arr1, ...arr2];

    // Create a new Set to automatically remove duplicates
    const uniqueArray = [...new Set(mergedArray)];

    return uniqueArray;
  },
  checkTaxCodes(taxCode) {
    const taxCodeFormat = /^[0-9]{10}-[0-9]{3}$/;
    const taxCodeFormats = /^[0-9]{10}$/;

    if (taxCode) {
      const taxCode14 = taxCodeFormat.test(taxCode.toString());
      const taxCode9 = taxCodeFormats.test(taxCode.toString());
      if (taxCode14) {
        return true;
      }
      if (taxCode9) {
        return true;
      }
      return false;
    }

    return;
  },
  checkTaxCode(taxCode) {
    const taxCodeFormat = /^[0-9]{10}$/;
    if (taxCode) {
      return taxCodeFormat.test(taxCode.toString());
    }
    return;
  },

  exportToCSV: (csvData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  },
  getInvoiceStatusPayment: (type) => {
    switch (type) {
      case 'Chưa thanh toán':
        return (
          <VIElements
            id={'2'}
            text={type}
            style={{
              // color: '#212121',
              background: '#F3F4F7',
              padding: '4px 8px',
              border: 'none',
            }}
          />
        );
      case 'Thanh toán một phần':
        return (
          <VIElements
            id={'3'}
            text={type}
            style={{
              color: '#E18700',
              background: 'rgba(213, 133, 59, 0.10)',
              padding: '4px 8px',
              border: 'none',
            }}
          />
        );
      case 'Thanh toán toàn phần':
        return (
          <VIElements
            id={'4'}
            text={type}
            style={{
              color: '#20A144',
              background: 'rgba(40, 160, 67, 0.1)',
              padding: '4px 8px',
              border: 'none',
            }}
          />
        );
      case 'Đến hạn thanh toán':
        return (
          <VIElements
            id={'5'}
            text={type}
            style={{
              color: '#E18700',
              background: 'rgba(213, 133, 59, 0.10)',
              padding: '4px 8px',
              border: 'none',
            }}
          />
        );
      case 'Quá hạn thanh toán':
        return (
          <VIElements
            id={'6'}
            text={type}
            style={{
              color: '#FF6666',
              background: '#FF2F0033',
              padding: '4px 8px',
              border: 'none',
            }}
          />
        );
      default:
        return (
          <VIElements
            id={'6'}
            text="Không có trạng thái"
            style={{
              background: 'transparent',
              padding: '4px 8px',
              border: 'none',
            }}
          />
        );
    }
  },
  getInvoiceStatus: (type) => {
    switch (type) {
      case 'Hoá đơn mới':
        return (
          <VIElements
            id={'2'}
            icon={
              <i
                className="fa-light fa-file"
                style={{ color: 'var(--secondary-green, #28A043)' }}
              ></i>
            }
            text={type}
            style={{
              color: 'var(--secondary-green, #28A043)',
              background: 'rgba(40, 160, 67, 0.05)',
              padding: '8px',
              border: 'none',
              width: '100%',
              marginBottom: '8px',
              fontWeight: '700',
            }}
          />
        );

      case 'Hoá đơn thay thế':
        return (
          <VIElements
            id={'3'}
            icon={
              <i
                className="fa-regular fa-file-lines"
                style={{ color: 'var(--text-text-3, #7177A8)' }}
              ></i>
            }
            text={type}
            style={{
              color: 'var(--text-text-3, #7177A8)',
              background: 'rgba(113, 119, 168, 0.10)',
              padding: '8px',
              border: 'none',
              width: '100%',
              fontWeight: '700',
            }}
          />
        );
      case 'Hoá đơn điều chỉnh':
        return (
          <VIElements
            id={'3'}
            icon={
              <i
                className="fa-regular fa-file-pen"
                style={{ color: 'var(--text-text-3, #7177A8)' }}
              ></i>
            }
            text={type}
            style={{
              color: 'var(--text-text-3, #7177A8)',
              background: 'rgba(113, 119, 168, 0.10)',
              padding: '8px',
              border: 'none',
              width: '100%',
              fontWeight: '700',
            }}
          />
        );
      case 'Hoá đơn xoá bỏ':
        return (
          <VIElements
            id={'5'}
            icon={
              <i
                className="fa-light fa-circle-xmark"
                style={{ color: 'var(--secondary-red, #F44141)' }}
              ></i>
            }
            text={type}
            style={{
              color: 'var(--secondary-red, #F44141)',
              background: 'rgba(244, 65, 65, 0.10)',
              padding: '8px',
              border: 'none',
              width: '100%',
              marginBottom: '8px',
              fontWeight: '700',
            }}
          />
        );
      case 'Hoá đơn đã bị thay thế':
        return (
          <VIElements
            id={'5'}
            icon={
              <i
                className="fa-regular fa-file-exclamation"
                style={{ color: 'var(--secondary-red, #F44141)' }}
              ></i>
            }
            text={type}
            style={{
              color: 'var(--secondary-red, #F44141)',
              background: 'rgba(244, 65, 65, 0.10)',
              padding: '8px',
              border: 'none',
              width: '100%',
              marginBottom: '8px',
              fontWeight: '700',
            }}
          />
        );
      case 'Hóa đơn đã bị điều chỉnh':
        return (
          <VIElements
            id={'5'}
            icon={
              <i
                className="fa-regular fa-file-exclamation"
                style={{ color: '#var(--secondary-red, #F44141)' }}
              ></i>
            }
            text={type}
            style={{
              color: 'var(--secondary-red, #F44141)',
              background: 'rgba(244, 65, 65, 0.10)',
              padding: '8px',
              border: 'none',
              width: '100%',
              marginBottom: '8px',
              fontWeight: '700',
            }}
          />
        );
      default:
        return (
          <VIElements
            id={'5'}
            icon={<i className="fa-regular fa-pen-to-square" style={{ color: '#8255ff' }}></i>}
            text="Hóa đơn nhập tay"
            style={{
              color: '#8255ff',
              background: 'rgba(130,85,255,.1)',
              padding: '8px',
              border: 'none',
              width: '100%',
              marginBottom: '8px',
              fontWeight: '700',
            }}
          />
        );
    }
  },
  getInvoiceStatusValidate: (type, code, manual, invoice) => {
    if (!type) {
      return (
        <VIElements
          id={'1'}
          text="Chưa kiểm tra"
          style={{
            // color: '#212121',
            background: '#F3F4F7',
            padding: '4px 8px',
            border: 'none',
          }}
        />
      );
    }
    if (
      type?.checkResultBuyerAddress &&
      type?.checkResultBuyerName &&
      type?.checkResultBuyerTaxCode &&
      type?.checkResultHasCQTRecord &&
      type?.checkResultHasInvoiceCode &&
      type?.checkResultSellerAddress &&
      type?.checkResultSellerName &&
      type?.checkResultSignatureCQT &&
      type?.checkResultSignatureNCC &&
      type?.checkResultSellerTaxCode
    ) {
      return (
        <VIElements
          id={'2'}
          text="Hóa đơn hợp lệ"
          icon={<i className="fa-regular fa-circle-check" style={{ color: '#20A144' }}></i>}
          style={{
            color: '#20A144',
            background: 'rgba(40, 160, 67, 0.1)',
            padding: '4px 8px',
            border: 'none',
          }}
        />
      );
    }

    let dataValidate = [];
    if (type?.checkResultBuyerName === false) {
      dataValidate.push({
        name: 'Sai tên người mua',
        id: 4,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.checkResultBuyerAddress === false) {
      dataValidate.push({
        name: 'Sai địa chỉ người mua',
        id: 5,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.checkResultBuyerTaxCode === false) {
      dataValidate.push({
        name: 'Sai mã số thuế người mua',
        id: 6,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (!type?.checkResultHasCQTRecord && !manual) {
      dataValidate.push({
        name: 'Hóa đơn chưa tồn tại',
        id: 7,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (!type?.checkResultHasInvoiceCode && !code?.startsWith('K') && !manual) {
      dataValidate.push({
        name: 'Chưa có mã hóa đơn',
        id: 8,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.checkResultSellerTaxCode === false) {
      dataValidate.push({
        name: 'Sai mã số thuế người bán',
        id: 9,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.checkResultSellerAddress === false) {
      dataValidate.push({
        name: 'Sai địa chỉ người bán',
        id: 9,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.checkResultSellerName === false) {
      dataValidate.push({
        name: 'Sai tên người bán',
        id: 10,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.checkResultSignatureCQT === false && !code?.startsWith('K') && invoice.cqtCKS) {
      dataValidate.push({
        name: 'Sai chữ ký cơ quan thuế',
        id: 10,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.checkResultSignatureNCC === false && invoice.sellerCKS) {
      dataValidate.push({
        name: 'Sai chữ ký nhà cung cấp',
        id: 11,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.checkResultBuyerName === null) {
      dataValidate.push({
        name: 'Không có tên người mua',
        id: 12,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (type?.checkResultBuyerAddress === null) {
      dataValidate.push({
        name: 'Không có địa chỉ người mua',
        id: 13,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (type?.checkResultBuyerTaxCode === null) {
      dataValidate.push({
        name: 'Không có mã số thuế người mua',
        id: 14,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (type?.checkResultSellerAddress === null) {
      dataValidate.push({
        name: 'Không có địa chỉ người bán',
        id: 15,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (type?.checkResultSellerTaxCode === null) {
      dataValidate.push({
        name: 'Không có mã số thuế người bán',
        id: 15,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (type?.checkResultSellerName === null) {
      dataValidate.push({
        name: 'Không có tên người bán',
        id: 16,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (!invoice.sellerCKS && !manual) {
      dataValidate.push({
        name: 'Không có chữ ký nhà cung cấp',
        id: 17,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (type?.nb_khong_rui_ro_tai_tdlap === null && manual) {
      dataValidate.push({
        name: 'Không kiểm tra được trạng thái người bán',
        id: 18,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (type?.mauso_kyhieu_da_tb === null && manual) {
      dataValidate.push({
        name: 'Không kiểm tra được mẫu số kí hiệu',
        id: 19,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (type?.so_hd_thuoc_khoang_phat_hanh === null && manual) {
      dataValidate.push({
        name: 'Không kiểm tra được số hóa đơn',
        id: 20,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (type?.ngay_hoa_don_tu_ngay_su_dung === null && manual) {
      dataValidate.push({
        name: 'Không kiểm tra được ngày hóa đơn',
        id: 21,
        icon: <i className="fa-regular fa-hexagon-exclamation" style={{ color: '#E18700' }}></i>,
        color: '#E18700',
        background: 'rgba(213, 133, 59, 0.10)',
      });
    }
    if (type?.nb_khong_rui_ro_tai_tdlap === false && manual) {
      dataValidate.push({
        name: 'Người bán thuộc cảnh báo của tổng cục thuế',
        id: 22,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.mauso_kyhieu_da_tb === false && manual) {
      dataValidate.push({
        name: 'Mẫu số ký hiệu chưa được thông báo phát hành',
        id: 23,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.so_hd_thuoc_khoang_phat_hanh === false && manual) {
      dataValidate.push({
        name: 'Số hóa đơn không thuộc thông báo phát hành',
        id: 24,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (type?.ngay_hoa_don_tu_ngay_su_dung === false && manual) {
      dataValidate.push({
        name: 'Ngày hóa đơn nhỏ hơn ngày bắt đầu sử dụng',
        id: 25,
        icon: <i className="fa-regular fa-circle-xmark" style={{ color: '#EE2334' }}></i>,
        color: '#EE2334',
        background: 'rgba(255, 87, 87, 0.10)',
      });
    }
    if (dataValidate?.length > 0) {
      return dataValidate.map((item, index) => (
        <VIElements
          icon={item?.icon}
          key={index}
          id={item?.id}
          text={item?.name}
          style={{
            color: item?.color,
            background: item?.background,
            padding: '3px 5px',
            border: 'none',
            marginTop: '2px',
          }}
        />
      ));
    }
  },
  getCheckTaxRate: (type) => {
    switch (type) {
      case 'CHECKING':
        return (
          <VIElements
            icon={''}
            id={1}
            text={'Đang kiểm tra'}
            style={{
              color: '#F57E10',
              background: 'rgba(229, 110, 25, 0.10)',
              padding: '3px 5px',
              border: 'none',
              marginTop: '2px',
            }}
          />
        );
      case 'INVALID':
        return (
          <VIElements
            id={2}
            icon={''}
            text={'Không chính xác'}
            style={{
              color: '#EE2334',
              background: 'rgba(255, 87, 87, 0.10)',
              padding: '3px 5px',
              border: 'none',
              marginTop: '2px',
            }}
          />
        );
      case 'VALID':
        return (
          <VIElements
            id={3}
            text={'Chính xác'}
            icon={''}
            style={{
              color: '#20A144',
              background: 'rgba(40, 160, 67, 0.1)',
              padding: '4px 8px',
              border: 'none',
            }}
          />
        );
      case 'NOT_CHECKED':
        return (
          <VIElements
            id={4}
            icon={''}
            text={'Chưa được kiểm tra'}
            style={{
              color: '#7177A8',
              background: 'rgba(113, 119, 168, 0.10)',
              padding: '3px 5px',
              border: 'none',
              marginTop: '2px',
            }}
          />
        );
    }
  },

  breakStringByWidth: (inputStr, maxWidth) => {
    const words = inputStr.split(' '); // Tách chuỗi thành mảng các từ
    const result = [];
    let currentLine = '';

    for (const word of words) {
      // Kiểm tra xem thêm từ này vào dòng hiện tại có vượt quá chiều rộng tối đa không
      if (currentLine.length + word.length + 1 <= maxWidth) {
        // +1 để tính cả dấu cách
        if (currentLine !== '') {
          // Nếu dòng hiện tại không rỗng, thêm dấu cách
          currentLine += ' ';
        }
        currentLine += word;
      } else {
        // Nếu thêm từ này vào dòng hiện tại sẽ vượt quá chiều rộng tối đa
        // Thêm dòng hiện tại vào kết quả và bắt đầu dòng mới
        result.push(currentLine);
        currentLine = word;
      }
    }

    // Thêm dòng cuối cùng vào kết quả (nếu còn)
    if (currentLine !== '') {
      result.push(currentLine);
    }

    return result;
  },
  getTypeInvoice: (type) => {
    switch (type) {
      case '1':
        return 'Hóa đơn giá trị gia tăng';
      case '2':
        return 'Hóa đơn bán hàng';
      case '3':
        return 'Hóa đơn bán tài sản công';
      case '4':
        return 'Hóa đơn bán hàng dự trữ quốc gia';
      case '5':
        return 'Tem, vé, thẻ điện tử';
      case '6':
        return 'Phiếu xuất kho điện tử';
      default:
        return 'Không có';
    }
  },
};

export default Utils;
