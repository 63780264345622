import AppConfigs from 'general/constants/AppConfigs';
import axiosClient from './axiosClient';
import axios from 'axios';

const miscApi = {
  // lay thong tin cong ty theo mst
  getCompanyInfoByTaxCode: (taxCode) => {
    const url = '/misc/find-company-by-tax-code';
    const params = {
      q: taxCode,
    };
    return axiosClient.get(url, { params });
  },

  // lay nhieu thong tin cong ty theo mst
  getCompaniesInfoByTaxCodes: (queries) => {
    const url = '/misc/find-companies-by-queries';
    const params = {
      queries,
    };
    return axiosClient.get(url, { params });
  },

  // lay danh sach permission codes
  getAllPermissionCodes: () => {
    const url = '/permission-code';
    return axiosClient.get(url);
  },

  // lay thong tin thiet bi
  getDeviceInfo: () => {
    return axios.get(AppConfigs.getIpUrl);
  },

  // de lai thong tin lien he
  createContactCustomer: (params) => {
    const url = '/contact-customer/create';
    return axiosClient.post(url, params);
  },

  // lay ds top binh luan cua khach hang
  getTopCustomerComments: (params) => {
    const url = '/comment-customer/find';
    return axiosClient.get(url, { params });
  },

  // lay ds tat ca cau hinh
  getAppConfigs: () => {
    const url = '/config/find';
    return axiosClient.get(url);
  },
};

export default miscApi;
