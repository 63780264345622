import CardAreaChartV2 from 'features/Dashboard/components/CardAreaChartV2';
import CardDonutChartV2 from 'features/Dashboard/components/CardDonutChartV2';
import CardResourceInformation from 'features/Dashboard/components/CardResourceInformation';
import ViewOutOfResource from 'features/Dashboard/components/ViewOutOfResource';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDateRangePicker2 from 'general/components/VietInvoice/VIDateRangePicker2';
import VIStatisticCard from 'general/components/VietInvoice/VIStatisticCard';
import AppData from 'general/constants/AppData';
import DashboardHelper from 'general/helpers/DashboardHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useConfig from 'hooks/useConfig';
import useRouter from 'hooks/useRouter';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function DashboardHomeScreen(props) {
  // ---- params ------
  const { t } = useTranslation();
  const router = useRouter();
  const {
    // quybka
    isGettingInvoiceStatistic,
    succeededInvoices,
    validInvoices,
    invalidInvoices,

    isGettingInvoiceByTimeStatistic,
    invoiceStatisticByTimeList,

    isGettingInvoiceValueStatistic,
    invoiceStatisticValueList,

    // dungna
    invoices,
    inputInvoices,
    outputInvoices,
    isGettingInvoiceReport,
  } = useSelector((state) => state?.dashboard);
  const account = useAccount();
  const [filterInvoiceStatistic, setFilterInvoiceStatistic] = useState(
    Global.gFiltersDashboardInvoiceStatistic
  );
  const [filterInvoiceStatisticByTime, setFilterInvoiceStatisticByTime] = useState(
    Global.gFiltersDashboardInvoiceByTimeStatistic
  );
  const [filterInvoiceStatisticValue, setFilterInvoiceStatisticValue] = useState(
    Global.gFiltersDashboardInvoiceValueStatistic
  );
  const [filterReport, setFilterReport] = useState(Global.gFiltersDashboardReport);
  const appConfig = useConfig();

  useEffect(() => {
    DashboardHelper.callApiGetInvoiceStatistic(filterInvoiceStatistic);
  }, [filterInvoiceStatistic]);

  useEffect(() => {
    DashboardHelper.callApiGetInvoiceStatisticByTime(filterInvoiceStatisticByTime);
  }, [filterInvoiceStatisticByTime]);

  useEffect(() => {
    DashboardHelper.callApiGetInvoiceValueStatistic(filterInvoiceStatisticValue);
  }, [filterInvoiceStatisticValue]);

  useEffect(() => {
    DashboardHelper.callApiGetInvoiceReport(filterReport);
  }, [filterReport]);

  // tong quan nhan hoa don theo thoi gian
  const [receiveInvoicesOvertimeStatisticRange, setReceiveInvoicesOvertimeStatisticRange] =
    useState(
      Utils.getRangeDates({
        startDate: moment().subtract(6, 'days'),
        endDate: moment(),
      })
    );

  const invoiceStatisticByTimeLabels = useMemo(() => {
    if (
      [
        AppData.dateFilter.quyTruoc,
        AppData.dateFilter.quyNay,
        AppData.dateFilter.quy1,
        AppData.dateFilter.quy2,
        AppData.dateFilter.quy3,
        AppData.dateFilter.quy4,
        AppData.dateFilter.sauThangDauNam,
        AppData.dateFilter.sauThangCuoiNam,
        AppData.dateFilter.namTruoc,
        AppData.dateFilter.namNay,
      ].includes(Global.gFiltersDashboardInvoiceByTimeStatistic.dateRangeValue)
    ) {
      const copiedLabels = Object.keys(invoiceStatisticByTimeList).map((item) => {
        return moment(item, 'YYYY-MM-DD').format('YYYY-MM');
      });
      return _.uniq(copiedLabels);
    }
    return Object.keys(invoiceStatisticByTimeList);
  }, [invoiceStatisticByTimeList]);
  const invoiceStatisticByTimeTotalPayment = useMemo(() => {
    if (
      [
        AppData.dateFilter.quyTruoc,
        AppData.dateFilter.quyNay,
        AppData.dateFilter.quy1,
        AppData.dateFilter.quy2,
        AppData.dateFilter.quy3,
        AppData.dateFilter.quy4,
        AppData.dateFilter.sauThangDauNam,
        AppData.dateFilter.sauThangCuoiNam,
        AppData.dateFilter.namTruoc,
        AppData.dateFilter.namNay,
      ].includes(Global.gFiltersDashboardInvoiceByTimeStatistic.dateRangeValue)
    ) {
      const tmp = Object.keys(invoiceStatisticByTimeList)?.map((item) => {
        return {
          name: moment(item, 'YYYY-MM-DD').format('YYYY-MM'),
          value: parseInt(invoiceStatisticByTimeList[item]?.totalFinalAmount ?? 0),
        };
      });
      const grouped = _.groupBy(tmp, 'name');
      // console.log({ grouped });
      const data = [];
      invoiceStatisticByTimeLabels.forEach((label) => {
        const tmp = grouped[label];
        const value = tmp.reduce((sum, item) => {
          return sum + item.value;
        }, 0);
        data.push(value);
      });
      return data;
    } else {
      return invoiceStatisticByTimeLabels?.map((item) => {
        return parseInt(invoiceStatisticByTimeList[item]?.totalFinalAmount ?? 0);
      });
    }
  }, [invoiceStatisticByTimeList, invoiceStatisticByTimeLabels]);
  const invoiceStatisticByTimeTotalInvoice = useMemo(() => {
    if (
      [
        AppData.dateFilter.quyTruoc,
        AppData.dateFilter.quyNay,
        AppData.dateFilter.quy1,
        AppData.dateFilter.quy2,
        AppData.dateFilter.quy3,
        AppData.dateFilter.quy4,
        AppData.dateFilter.sauThangDauNam,
        AppData.dateFilter.sauThangCuoiNam,
        AppData.dateFilter.namTruoc,
        AppData.dateFilter.namNay,
      ].includes(Global.gFiltersDashboardInvoiceByTimeStatistic.dateRangeValue)
    ) {
      const tmp = Object.keys(invoiceStatisticByTimeList)?.map((item) => {
        return {
          name: moment(item, 'YYYY-MM-DD').format('YYYY-MM'),
          value: parseInt(invoiceStatisticByTimeList[item]?.total ?? 0),
        };
      });
      const grouped = _.groupBy(tmp, 'name');
      // console.log({ grouped });
      const data = [];
      invoiceStatisticByTimeLabels.forEach((label) => {
        const tmp = grouped[label];
        const value = tmp.reduce((sum, item) => {
          return sum + item.value;
        }, 0);
        data.push(value);
      });
      return data;
    } else {
      return invoiceStatisticByTimeLabels?.map((item) => {
        return parseInt(invoiceStatisticByTimeList[item]?.total ?? 0);
      });
    }
  }, [invoiceStatisticByTimeList, invoiceStatisticByTimeLabels]);

  // console.log({ invoiceStatisticByTimeLabels, invoiceStatisticByTimeList });
  const overviewChartOfReceiptOfInvoicesOverTime = useMemo(() => {
    return (
      <CardAreaChartV2
        fill={false}
        fullChartLabels={receiveInvoicesOvertimeStatisticRange}
        loading={isGettingInvoiceByTimeStatistic}
        title={_.capitalize(t('OverviewOfReceiptOfInvoicesOverTime'))}
        chartLabels={invoiceStatisticByTimeLabels}
        chartSeries={[
          {
            name: t('TotalPayment'),
            type: 'line',
            data: invoiceStatisticByTimeTotalPayment,
            position: 'left',
          },
          {
            name: t('TotalInvoice'),
            type: 'line',
            data: invoiceStatisticByTimeTotalInvoice,
            position: 'right',
          },
        ]}
        chartColors={['#4570FE', '#FFA318']}
        headerSidebar={
          <div>
            <VIDateRangePicker2
              alignRight={true}
              initialValue={Global.gFiltersDashboardInvoiceByTimeStatistic.dateRangeValue}
              onPressSave={(selectedValue, startDate, endDate) => {
                Global.gFiltersDashboardInvoiceByTimeStatistic = {
                  ...Global.gFiltersDashboardInvoiceByTimeStatistic,
                  from: startDate,
                  to: endDate,
                  dateRangeValue: selectedValue,
                };
                setFilterInvoiceStatisticByTime(Global.gFiltersDashboardInvoiceByTimeStatistic);
              }}
            />
          </div>
        }
      />
    );
  }, [isGettingInvoiceByTimeStatistic, invoiceStatisticByTimeLabels]);

  // tong gia tri hop dong
  const invoiceStatisticByValue = useMemo(() => {
    let ret = Object.values(invoiceStatisticValueList);
    ret = _.orderBy(ret, ['totalAmountBeforeVat'], ['desc']);
    const length = ret.length;
    let first4NCC = _.slice(ret, 0, 5);
    const remain = _.slice(ret, 5) ?? [];
    if (remain.length > 0) {
      const remainObj = _.reduce(
        ret,
        (obj, item) => {
          return {
            totalInvoice: obj.totalInvoice + item.totalInvoice,
            totalAmountBeforeVat: obj.totalAmountBeforeVat + item.totalAmountBeforeVat,
            sellerName: 'KHÁC',
          };
        },
        { totalInvoice: 0, totalAmountBeforeVat: 0, sellerName: 'KHÁC' }
      );
      first4NCC.push(remainObj);
    }
    return first4NCC;
  }, [invoiceStatisticValueList]);
  const totalContractValueChart = useMemo(() => {
    return (
      <CardDonutChartV2
        loading={isGettingInvoiceValueStatistic}
        title={t('TotalContractValue')}
        chartLabels={invoiceStatisticByValue?.map((item) => item.sellerName)}
        chartSeries={invoiceStatisticByValue?.map((item) => item.totalAmountBeforeVat)}
        chartColors={AppData.chartColors}
        headerSidebar={
          <div>
            <VIDateRangePicker2
              alignRight={true}
              initialValue={Global.gFiltersDashboardInvoiceValueStatistic.dateRangeValue}
              onPressSave={(selectedValue, startDate, endDate) => {
                Global.gFiltersDashboardInvoiceValueStatistic = {
                  ...Global.gFiltersDashboardInvoiceValueStatistic,
                  from: startDate,
                  to: endDate,
                  dateRangeValue: selectedValue,
                };
                setFilterInvoiceStatisticValue(Global.gFiltersDashboardInvoiceValueStatistic);
              }}
            />
          </div>
        }
      />
    );
  });

  // ---- methods -----

  return (
    <div className="d-flex flex-column min-h-100">
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="card-label font-weight-bolder text-grey m-0">{t('Dashboard')}</h3>
        {/* <div className="">
          <VISelectFilter
            data={[
              { name: 'VNĐ', value: 'VND' },
              { name: 'USD', value: 'USD' },
            ]}
            onChangeValue={(e) => console.log(e)}
          />
        </div> */}
      </div>

      {/* het tai nguyen */}
      {appConfig.hideBannerResourceExpired !== '1' &&
        account.accountId &&
        account.company.resourceTotalUnusedInvoice <= 0 && (
          <div className="mt-4">
            <ViewOutOfResource
              onPressedExtend={() => {
                router.navigate('/store');
              }}
            />
          </div>
        )}

      <div className="mt-4">
        {/* thong ke hoa don */}
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-6">
            <VIStatisticCard
              title="Tổng hóa đơn đã xử lý"
              statistics={succeededInvoices?.total ?? 0}
              icon={<i className="fa-solid fa-file-lines"></i>}
              color="#304FFD"
              subColor="#304FFD33"
              data={{
                'Trước thuế GTGT': Utils.formatCurrency(
                  succeededInvoices?.totalAmountBeforeVat ?? 0
                ),
                'Thuế GTGT': Utils.formatCurrency(succeededInvoices?.totalAmountVat ?? 0),
                'Tổng tiền thanh toán': Utils.formatCurrency(
                  succeededInvoices?.totalFinalAmount ?? 0
                ),
              }}
              filterDate={{
                value: filterInvoiceStatistic.dateRangeValue,
                from: filterInvoiceStatistic.from,
                to: filterInvoiceStatistic.to,
              }}
              setFilterDate={(dateRange) => {
                const from = dateRange?.startDate;
                const to = dateRange?.endDate;
                Global.gFiltersDashboardInvoiceStatistic = {
                  ...Global.gFiltersDashboardInvoiceStatistic,
                  from: from,
                  to: to,
                  dateRangeValue: dateRange?.selectedValue,
                };
                setFilterInvoiceStatistic(Global.gFiltersDashboardInvoiceStatistic);
              }}
              onPressedIcon={() => {
                router.navigate('/inbot/invoice');
              }}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-6">
            <VIStatisticCard
              title="Hóa đơn hợp lệ"
              statistics={validInvoices?.total ?? 0}
              icon={<i className="fa-solid fa-file-lines"></i>}
              color="#3ACE5A"
              subColor="#3ACE5A33"
              data={{
                'Trước thuế GTGT': Utils.formatCurrency(validInvoices?.totalAmountBeforeVat ?? 0),
                'Thuế GTGT': Utils.formatCurrency(validInvoices?.totalAmountVat ?? 0),
                'Tổng tiền thanh toán': Utils.formatCurrency(validInvoices?.totalFinalAmount ?? 0),
              }}
              filterDate={{
                value: filterInvoiceStatistic.dateRangeValue,
                from: filterInvoiceStatistic.from,
                to: filterInvoiceStatistic.to,
              }}
              setFilterDate={(dateRange) => {
                const from = dateRange?.startDate;
                const to = dateRange?.endDate;
                Global.gFiltersDashboardInvoiceStatistic = {
                  ...Global.gFiltersDashboardInvoiceStatistic,
                  from: from,
                  to: to,
                  dateRangeValue: dateRange?.selectedValue,
                };
                setFilterInvoiceStatistic(Global.gFiltersDashboardInvoiceStatistic);
              }}
              onPressedIcon={() => {
                router.navigate('/inbot/invoice');
              }}
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-6">
            <VIStatisticCard
              title="Hóa đơn không hợp lệ"
              statistics={invalidInvoices?.total ?? 0}
              icon={<i className="fa-solid fa-file-lines"></i>}
              color="#E54135"
              subColor="#E5413533"
              data={{
                'Trước thuế GTGT': Utils.formatCurrency(invalidInvoices?.totalAmountBeforeVat ?? 0),
                'Thuế GTGT': Utils.formatCurrency(invalidInvoices?.totalAmountVat ?? 0),
                'Tổng tiền thanh toán': Utils.formatCurrency(
                  invalidInvoices?.totalFinalAmount ?? 0
                ),
              }}
              filterDate={{
                value: filterInvoiceStatistic.dateRangeValue,
                from: filterInvoiceStatistic.from,
                to: filterInvoiceStatistic.to,
              }}
              setFilterDate={(dateRange) => {
                const from = dateRange?.startDate;
                const to = dateRange?.endDate;
                Global.gFiltersDashboardInvoiceStatistic = {
                  ...Global.gFiltersDashboardInvoiceStatistic,
                  from: from,
                  to: to,
                  dateRangeValue: dateRange?.selectedValue,
                };
                setFilterInvoiceStatistic(Global.gFiltersDashboardInvoiceStatistic);
              }}
              onPressedIcon={() => {
                router.navigate('/inbot/invoice');
              }}
            />
          </div>
        </div>

        {/* bao cao */}
        <div className="">
          <h4 className="card-label font-weight-bolder text-grey m-0">{t('Report')}</h4>
          <div className="row mt-3">
            {/* tong hoa don mua vao/ban ra */}
            <div className="col-lg-4 col-md-6 mb-6">
              <VIStatisticCard
                title="Tổng hóa đơn mua vào/bán ra"
                statistics={Utils.formatCurrency(invoices?.total ?? 0)}
                // icon={<i className="fa-solid fa-file-lines"></i>}
                color="#304FFD"
                subColor="#304FFD33"
                data={{
                  // 'Trước thuế GTGT': Utils.formatCurrency(invoices?.totalAmountBeforeVat),
                  'Tiền thuế GTGT dự kiến phải nộp': Utils.formatCurrency(invoices?.totalAmountVat),
                  // 'Tổng tiền thanh toán': Utils.formatCurrency(invoices?.totalFinalAmount),
                }}
                filterDate={{
                  value: filterReport.dateRangeValue,
                  from: filterReport.from,
                  to: filterReport.to,
                }}
                setFilterDate={(dateRange) => {
                  const from = dateRange?.startDate;
                  const to = dateRange?.endDate;
                  Global.gFiltersDashboardReport = {
                    ...Global.gFiltersDashboardReport,
                    from: from,
                    to: to,
                    dateRangeValue: dateRange?.selectedValue,
                  };
                  setFilterReport(Global.gFiltersDashboardReport);
                }}
                onPressedIcon={() => {
                  router.navigate('/inbot/invoice');
                }}
              />
            </div>
            {/* tong hoa don mua vao */}
            <div className="col-lg-4 col-md-6 mb-6">
              <VIStatisticCard
                title="Tổng hóa đơn mua vào"
                statistics={Utils.formatCurrency(inputInvoices?.total ?? 0)}
                // icon={<i className="fa-solid fa-file-lines"></i>}
                color="#304FFD"
                subColor="#304FFD33"
                data={{
                  'Trước thuế GTGT': Utils.formatCurrency(inputInvoices?.totalAmountBeforeVat),
                  'Thuế GTGT': Utils.formatCurrency(inputInvoices?.totalAmountVat),
                  'Tổng tiền thanh toán': Utils.formatCurrency(inputInvoices?.totalFinalAmount),
                }}
                filterDate={{
                  value: filterReport.dateRangeValue,
                  from: filterReport.from,
                  to: filterReport.to,
                }}
                setFilterDate={(dateRange) => {
                  const from = dateRange?.startDate;
                  const to = dateRange?.endDate;
                  Global.gFiltersDashboardReport = {
                    ...Global.gFiltersDashboardReport,
                    from: from,
                    to: to,
                    dateRangeValue: dateRange?.selectedValue,
                  };
                  setFilterReport(Global.gFiltersDashboardReport);
                }}
                onPressedIcon={() => {
                  router.navigate('/inbot/invoice');
                }}
              />
            </div>
            {/* tong hoa don ban ra */}
            <div className="col-lg-4 col-md-6 mb-6">
              <VIStatisticCard
                title="Tổng hóa đơn bán ra"
                statistics={Utils.formatCurrency(outputInvoices?.total ?? 0)}
                // icon={<i className="fa-solid fa-file-lines"></i>}
                color="#304FFD"
                subColor="#304FFD33"
                data={{
                  'Trước thuế GTGT': Utils.formatCurrency(outputInvoices?.totalAmountBeforeVat),
                  'Thuế GTGT': Utils.formatCurrency(outputInvoices?.totalAmountVat),
                  'Tổng tiền thanh toán': Utils.formatCurrency(outputInvoices?.totalFinalAmount),
                }}
                filterDate={{
                  value: filterReport.dateRangeValue,
                  from: filterReport.from,
                  to: filterReport.to,
                }}
                setFilterDate={(dateRange) => {
                  const from = dateRange?.startDate;
                  const to = dateRange?.endDate;
                  Global.gFiltersDashboardReport = {
                    ...Global.gFiltersDashboardReport,
                    from: from,
                    to: to,
                    dateRangeValue: dateRange?.selectedValue,
                  };
                  setFilterReport(Global.gFiltersDashboardReport);
                }}
                onPressedIcon={() => {
                  router.navigate('/inbot/invoice');
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          {/* line chart */}
          <div className="col-lg-8">{overviewChartOfReceiptOfInvoicesOverTime}</div>
          <div className="col-lg-4">{totalContractValueChart}</div>
        </div>

        <div className="row">
          <div className="col-lg-8 mb-6">
            <CardResourceInformation
              className="w-100"
              onBuy={() => {
                router.navigate('/store');
              }}
              onExtend={() => {
                router.navigate('/store');
              }}
            />
          </div>
          <div className="col-lg-4 mb-6">
            <div
              className="p-6 h-100 card card-custom d-flex flex-column justify-content-around"
              style={{
                borderRadius: '16px',
              }}
            >
              <p className="font-size-lg font-weight-bolder mb-1">Tự động kết nối nhà cung cấp</p>
              <p className="mb-1">
                ICORP Invoice đã kết nối với nhà cung cấp giúp tự động lấy về hóa đơn để tiết kiệm
                hơn 80% thời gian nhập liệu và xử lý hóa đơn.
              </p>
              <VIButton
                beforeIcon={<i className="fa-regular fa-landmark" />}
                text={t('ConnectNow')}
                className="btn-blue border-0 mt-2 p-3"
                onClick={() => {
                  router.navigate('/inbot/axions/connect');
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHomeScreen;
