import PropTypes from 'prop-types';
import VIDateRangePicker2 from '../VIDateRangePicker2';
import './style.scss';

VIStatisticCard.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  statistics: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.object,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  color: PropTypes.string,
  subColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  filterDate: PropTypes.object,
  setFilterDate: PropTypes.func,
  onPressedIcon: PropTypes.func,
};

VIStatisticCard.defaultProps = {
  id: null,
  className: '',
  title: '',
  statistics: null,
  data: null,
  onClick: () => {},
  icon: null,
  color: null,
  subColor: null,
  width: null,
  height: null,
  style: {},
  filterDate: {},
  setFilterDate: null,
  onPressedIcon: null,
};

/**
 * @param {string} id Card id
 * @param {string} className Card className
 * @param {string} title Card title (big text)
 * @param {number} statistics Card statistic (big number)
 * @param {object} data Data, truyen vao: data = {{"key": value}}
 * @param {function} onClick Handle when click button
 * @param {element} icon Card icon
 * @param {string} color Icon color
 * @param {string} subColor Icon background color
 * @param {string} width Card width
 * @param {string} height Card height
 * @param {object} style Card style
 * @param {object} filterDate // Neu k truyen vao filterDate va setFilterDate thi k hide block date picker
 * @param {function} setFilterDate handle select date range
 * @param {function} onPressedIcon
 */

function VIStatisticCard(props) {
  const {
    id,
    className,
    title,
    statistics,
    data,
    icon,
    color,
    subColor,
    width,
    height,
    onClick,
    style,
    children,
    filterDate,
    setFilterDate,
    onPressedIcon,
  } = props;

  function clicked(e) {
    if (onClick) {
      onClick(e);
    }
  }

  return (
    <div
      id={id}
      className={`VIStatisticCard d-flex flex-column align-items-center justify-content-start ${className} h-100`}
      style={{
        whiteSpace: 'nowrap',
        width: width || null,
        height: height || null,
        ...style,
      }}
      onClick={clicked}
    >
      <div className="VIStatisticCard_header w-100 d-flex justify-content-between align-items-center">
        <div className="text">
          <div className="d-flex align-items-center flex-wrap justify-content-between mb-2">
            <div className="mr-4">
              <p className="p-0 m-0 font-size-lg">{title}</p>
              <p className="p-0 m-0 font-size-h1">{statistics}</p>
            </div>
            {icon && (
              <div
                className="p-5 card-icon d-flex align-items-center justify-content-center cursor-pointer"
                style={{
                  color: color,
                  backgroundColor: subColor,
                  borderRadius: '16px',
                }}
                onClick={() => {
                  if (onPressedIcon) {
                    onPressedIcon();
                  }
                }}
              >
                {icon}
              </div>
            )}
          </div>
          {filterDate && (
            <VIDateRangePicker2
              alignRight={false}
              initialValue={filterDate?.value}
              onPressSave={(selectedValue, startDate, endDate) => {
                if (setFilterDate && _.isFunction(setFilterDate)) {
                  setFilterDate({ selectedValue, startDate, endDate });
                }
              }}
            />
          )}
        </div>
      </div>

      <div className="VIStatisticCard_content w-100 mt-5 pt-4 d-flex gap-2 flex-column">
        {Object.entries(data)?.map(([key, value]) => (
          <div className="w-100 d-flex justify-content-between flex-wrap" key={key}>
            <p className="p-0 mb-0">{key}:</p>
            <p className="font-weight-bolder p-0 mb-0">{value}</p>
          </div>
        ))}
      </div>
      {children}
    </div>
  );
}

export default VIStatisticCard;
