import supplierApi from 'api/supplierApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// lay danh sach tat ca ncc
export const thunkGetAllSuppliers = createAsyncThunk(
  'system/getAllSuppliers',
  async (params, thunkAPI) => {
    const res = await supplierApi.getAll();
    return res;
  }
);

// lay danh sach tai khoan dang ket noi
export const thunkGetConnectionSuppliers = createAsyncThunk(
  'system/getConnectionSuppliers',
  async (params, thunkAPI) => {
    const res = await supplierApi.getAllConnectionSuppliers();
    return res;
  }
);

// ket noi ncc
export const thunkConnectSupplier = createAsyncThunk(
  'system/connectSupplier',
  async (params, thunkAPI) => {
    const res = await supplierApi.connect(params);
    return res;
  }
);

// cap nhat ket noi
export const thunkUpdateConnectionSupplier = createAsyncThunk(
  'system/updateConnectionSupplier',
  async (params, thunkAPI) => {
    const res = await supplierApi.update(params?.id, params?.params);
    return res;
  }
);

// dong bo ncc
export const thunkSyncSupplier = createAsyncThunk(
  'system/syncSupplier',
  async (params, thunkAPI) => {
    const res = await supplierApi.synchronize(params);
    return res;
  }
);

// ngat ket noi ncc
export const thunkDisconnectSupplier = createAsyncThunk(
  'system/disconnectSupplier',
  async (ids, thunkAPI) => {
    const res = await supplierApi.disconnect(ids);
    return res;
  }
);

// lay lich su dong bo
export const thunkGetConnectionSupplierHistory = createAsyncThunk(
  'system/getConnectionSupplierHistory',
  async (params, thunkAPI) => {
    const res = await supplierApi.getConnectionSupplierHistory(params);
    return res;
  }
);

// reload
export const thunkReload = createAsyncThunk('system/reload', async (id, thunkAPI) => {
  const res = await supplierApi.reload(id);
  return res;
});

const supplierSlice = createSlice({
  name: 'supplier',
  initialState: {
    // danh sach ncc
    allSupplierList: [],
    isGettingSupplierList: false,

    // danh sach tai khoan dang ket noi
    connectionSupplierList: [],
    isGettingConnectSupplier: false,

    // ket noi
    isConnectingSupplier: false,

    // update
    isUpdatingSupplier: false,

    // ngat ket noi
    isDisconnectingSupplier: false,

    // dong bo ncc
    isSynchronizing: false,

    // lich su dong bo
    isGettingConnectionSupplierHistory: false,
    connectionSupplierHistoryList: [],
    totalConnectionSupplierHistory: 0,

    isReloading: false,
  },
  reducers: {
    resetSupplier: (state, action) => {
      state.connectionSupplierList = [];
      state.connectionSupplierHistoryList = [];
      state.totalConnectionSupplierHistory = 0;
    },
  },
  extraReducers: {
    // lay tat ca ncc
    [thunkGetAllSuppliers.pending]: (state, action) => {
      state.isGettingSupplierList = true;
    },
    [thunkGetAllSuppliers.rejected]: (state, action) => {
      state.isGettingSupplierList = false;
    },
    [thunkGetAllSuppliers.fulfilled]: (state, action) => {
      state.isGettingSupplierList = false;
      const { result, suppliers } = action.payload;
      if (result === 'success' && suppliers) {
        state.allSupplierList = suppliers;
      }
    },

    // lay tat ca ket noi
    [thunkGetConnectionSuppliers.pending]: (state, action) => {
      state.isGettingConnectSupplier = true;
    },
    [thunkGetConnectionSuppliers.rejected]: (state, action) => {
      state.isGettingConnectSupplier = false;
    },
    [thunkGetConnectionSuppliers.fulfilled]: (state, action) => {
      state.isGettingConnectSupplier = false;
      const { result, connectionSuppliers } = action.payload;
      if (result === 'success' && connectionSuppliers) {
        state.connectionSupplierList = connectionSuppliers;
      }
    },

    // ket noi ncc
    [thunkConnectSupplier.pending]: (state, action) => {
      state.isConnectingSupplier = true;
    },
    [thunkConnectSupplier.rejected]: (state, action) => {
      state.isConnectingSupplier = false;
    },
    [thunkConnectSupplier.fulfilled]: (state, action) => {
      state.isConnectingSupplier = false;
      const { result } = action.payload;
      if (result === 'success') {
      }
    },

    // cap nhat ket noi ncc
    [thunkUpdateConnectionSupplier.pending]: (state, action) => {
      state.isUpdatingSupplier = true;
    },
    [thunkUpdateConnectionSupplier.rejected]: (state, action) => {
      state.isConnectingSupplier = false;
    },
    [thunkUpdateConnectionSupplier.fulfilled]: (state, action) => {
      state.isConnectingSupplier = false;
      const { result } = action.payload;
      if (result === 'success') {
      }
    },

    // dong bo ncc
    [thunkSyncSupplier.pending]: (state, action) => {
      state.isSynchronizing = true;
    },
    [thunkSyncSupplier.rejected]: (state, action) => {
      state.isSynchronizing = false;
    },
    [thunkSyncSupplier.fulfilled]: (state, action) => {
      state.isSynchronizing = false;
      const { result } = action.payload;
      if (result === 'success') {
      }
    },

    // ngat ket noi ncc
    [thunkDisconnectSupplier.pending]: (state, action) => {
      state.isDisconnectingSupplier = true;
    },
    [thunkDisconnectSupplier.rejected]: (state, action) => {
      state.isDisconnectingSupplier = false;
    },
    [thunkDisconnectSupplier.fulfilled]: (state, action) => {
      state.isDisconnectingSupplier = false;
      const { result } = action.payload;
      if (result === 'success') {
      }
    },

    // lay lich su dong bo
    [thunkGetConnectionSupplierHistory.pending]: (state, action) => {
      state.isGettingConnectionSupplierHistory = true;
    },
    [thunkGetConnectionSupplierHistory.rejected]: (state, action) => {
      state.isGettingConnectionSupplierHistory = false;
    },
    [thunkGetConnectionSupplierHistory.fulfilled]: (state, action) => {
      state.isGettingConnectionSupplierHistory = false;
      const { result, connectionSupplierHistories, total } = action.payload;
      if (result === 'success' && connectionSupplierHistories) {
        state.connectionSupplierHistoryList = connectionSupplierHistories;
        state.totalConnectionSupplierHistory = total;
      }
    },

    // reload
    [thunkReload.pending]: (state, action) => {
      state.isReloading = true;
    },
    [thunkReload.rejected]: (state, action) => {
      state.isReloading = false;
    },
    [thunkReload.fulfilled]: (state, action) => {
      state.isReloading = false;
      const { result } = action.payload;
      if (result === 'success') {
      }
    },
  },
});

const { reducer, actions } = supplierSlice;
export const { resetSupplier } = actions;
export default reducer;
