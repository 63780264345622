import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import { useTranslation } from 'react-i18next';

CardInvoiceAccumulated.propTypes = {};

function CardInvoiceAccumulated(props) {
  // ---- params ----
  const { t } = useTranslation();
  const account = useAccount();

  return (
    <div
      className="p-6 d-flex flex-column align-items-start h-100"
      style={{
        backgroundColor: '#0485AD',
        borderRadius: '16px',
        backgroundImage: `url(${AppResource.images.imgSystemReourceRight})`,
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="w-100">
        <div className="d-flex justify-content-between">
          <h5 className="card-label text-white font-weight-bolder">Thông tin hoá đơn luỹ kế</h5>
          <img style={{ height: '60px' }} src={AppResource.images.imgInvoiceInfor} alt="" />
        </div>
        <div className="d-flex align-items-center w-100 mt-2 flex-wrap gap-3">
          <div className="d-flex flex-grow-1 flex-column align-items-center border-right pr-4">
            <p className="font-weight-boldest text-white mb-0" style={{ fontSize: '2rem' }}>
              {Utils.formatNumber(account.company.resourceTotalInvoice)}
            </p>
            <p className="text-white mb-0 font-size-lg text-center">Hóa đơn đã mua</p>
          </div>
          <div className="d-flex flex-grow-1 flex-column align-items-center border-right px-4">
            <p className="font-weight-boldest text-white mb-0" style={{ fontSize: '2rem' }}>
              {Utils.formatNumber(
                account.company.resourceTotalInvoice - account.company.resourceTotalUnusedInvoice
              )}
            </p>
            <p className="text-white mb-0 font-size-lg text-center">Đã sử dụng</p>
          </div>
          <div className="d-flex flex-grow-1 flex-column align-items-center pl-4">
            <p className="font-weight-boldest text-white mb-0" style={{ fontSize: '2rem' }}>
              {Utils.formatNumber(account.company.resourceTotalUnusedInvoice)}
            </p>
            <p className="text-white mb-0 font-size-lg text-center">Chưa sử dụng</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardInvoiceAccumulated;
