import axiosClient from './axiosClient';

const instructionCategoryApi = {
  // lay tat ca the loai
  getAllCategories: () => {
    const url = '/instruction-category/find';
    return axiosClient.get(url);
  },
};

export default instructionCategoryApi;
