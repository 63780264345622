import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import EmptyView from 'features/Others/components/EmptyView';
import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTFormTextArea from 'general/components/OtherKeenComponents/Forms/KTFormTextArea';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIOverViewBar from 'general/components/VietInvoice/VIOverViewBar';
import CategoryHelper from 'general/helpers/CategoryHelper';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';

ViewEditSupplier.propTypes = {
  onPressCancel: PropTypes.func,
  item: PropTypes.object,
};

ViewEditSupplier.defaultProps = {
  onPressCancel: null,
  item: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * item: object,
 * }} props
 * @returns
 */
function ViewEditSupplier(props) {
  // ---- params -----
  const { t } = useTranslation();
  const { onPressCancel, item } = props;
  const isEditMode = !_.isNull(item);
  const tabItemList = useMemo(() => {
    return [
      {
        title: t('UnitInfo'),
        status: 'active',
      },
      {
        title: t('PaymentInfo'),
        status: '',
      },
      {
        title: t('ContactInfo'),
        status: '',
      },
    ];
  }, [t]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const acceptedInfos = useRef([]);
  const [flag, setFlag] = useState({});
  const columns = useMemo(() => {
    return [
      {
        name: t('Tên được chấp nhận'),
        sortable: false,
        minWidth: '180px',
        cell: (row, index) => {
          return (
            <KTFormTextArea
              name={`name_${index}`}
              value={row?.name}
              onChange={(value) => {
                acceptedInfos.current[index].name = value;
              }}
            />
          );
        },
      },
      {
        name: t('Địa chỉ được chấp nhận'),
        sortable: false,
        minWidth: '180px',
        cell: (row, index) => {
          return (
            <KTFormTextArea
              name={`address_${index}`}
              value={row?.address}
              onChange={(value) => {
                acceptedInfos.current[index].address = value;
              }}
            />
          );
        },
      },
      {
        name: t('StartDate'),
        sortable: false,
        width: '180px',
        cell: (row, index) => {
          return (
            <KTFormInput
              name={`startDate_${index}`}
              value={row?.startDate}
              type={KTFormInputType.date}
              onChange={(value) => {
                acceptedInfos.current[index].startDate = value;
              }}
            />
          );
        },
      },
      {
        name: t('EndDate'),
        sortable: false,
        width: '180px',
        cell: (row, index) => {
          return (
            <KTFormInput
              name={`endDate_${index}`}
              value={row?.endDate}
              type={KTFormInputType.date}
              onChange={(value) => {
                acceptedInfos.current[index].endDate = value;
              }}
            />
          );
        },
      },
      {
        name: t('AcceptPerson'),
        sortable: false,
        minWidth: '180px',
        style: {
          borderRight: 0,
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.acceptPerson}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between">
            <KTTooltip text={t('Delete')}>
              <i
                className="far fa-trash-alt text-danger"
                onClick={() => {
                  acceptedInfos.current.splice(index, 1);
                  setFlag({});
                }}
              />
            </KTTooltip>
          </div>
        ),
      },
    ];
  }, [t, acceptedInfos.current]);
  const acceptPartnerCompanyDetails = item?.acceptPartnerCompanyDetails ?? [];
  useEffect(() => {
    acceptedInfos.current = acceptPartnerCompanyDetails?.map((item) => {
      return {
        name: item?.acceptName ?? '',
        address: item?.acceptAddress ?? '',
        startDate: moment(item?.from).format('YYYY-MM-DD'),
        endDate: moment(item?.to).format('YYYY-MM-DD'),
        acceptPerson: item?.Account?.fullname ?? item?.Account?.email ?? '',
      };
    });
  }, [acceptPartnerCompanyDetails]);
  const [loading, setLoading] = useState(false);

  // ---- methods -----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  const handleChangeTabIndex = (newIndex) => {
    setSelectedTabIndex(newIndex);
  };

  return (
    <div className="overflow-auto" style={{ maxHeight: '70vh' }}>
      <Formik
        initialValues={{
          // thong tin don vi
          unitCode: item?.unitCode ?? '',
          taxCode: item?.taxCode ?? '',
          partnerCompanyName: item?.partnerCompanyName ?? '',
          address: item?.address ?? '',
          // thong tin thanh toan
          bankAccountNumber: item?.bankAccountNumber ?? '',
          bankAccountName: item?.bankAccountName ?? '',
          bank: item?.bank ?? '',
          bankBranch: item?.bankBranch ?? '',
          expirePayment: item?.expirePayment ?? '',
          // thong tin lien he
          contactName: item?.contactName ?? '',
          contactEmail: item?.contactEmail ?? '',
          contactPhone: item?.contactPhone ?? '',
          contactPosition: item?.contactPosition ?? '',
        }}
        validationSchema={Yup.object({})}
        enableReinitialize
        onSubmit={async (values) => {
          const params = {
            ...values,
            acceptPartnerCompanyDetails: acceptedInfos.current?.map((item) => {
              return {
                acceptAddress: item.address,
                acceptName: item.name,
                from: item.startDate,
                to: item.endDate,
              };
            }),
          };
          // console.log({ params });
          // return;
          setLoading(true);
          const res = await CategoryHelper.callApiUpdateSupplierCustomer(
            item?.partnerCompanyId,
            params
          );
          setLoading(false);
          if (res) {
            handlePressCancel();
          }
        }}
      >
        {(formikProps) => {
          // refFormik.current = formikProps;
          return (
            <div className="">
              <VIOverViewBar
                itemTab={tabItemList}
                currentSelect={selectedTabIndex}
                handleClick={handleChangeTabIndex}
              />
              <div className="py-6">
                {selectedTabIndex === 0 ? (
                  // thong tin don vi
                  <div className="row m-0">
                    {/* ma don vi */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('UnitCode')}</>}
                        inputName="unitCode"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="unitCode">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('UnitCode'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>
                    {/* ma so thue */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('TaxCode')}</>}
                        inputName="taxCode"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="taxCode">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                disabled
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('TaxCode'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>
                    {/* ten don vi */}
                    <div className="col-12">
                      <KTFormGroup
                        label={<>{t('UnitName')}</>}
                        inputName="partnerCompanyName"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="partnerCompanyName">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                disabled
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('UnitName'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>
                    {/* dia chi */}
                    <div className="col-12">
                      <KTFormGroup
                        label={<>{t('Address')}</>}
                        inputName="address"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="address">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                disabled
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('Address'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* thong tin ncc/khach hang duoc chap nhan bo sung */}
                    {/* dia chi */}
                    <div className="col-12">
                      <KTFormGroup
                        label={<>{t('Thông tin NCC/Khách hàng được chấp nhận')}</>}
                        inputName="supplierCustomerAcceptInfo"
                        additionalClassName="mb-0"
                        additionalLabelClassName="mb-0"
                        inputElement={
                          <div
                          // className="overflow-auto"
                          // style={{
                          //   maxHeight: '40vh',
                          // }}
                          >
                            <VIDataTable
                              draggable={false}
                              tableStyles={customDataTableActionStyle}
                              additionalClassName="p-0 shadow-none"
                              additionalCardBodyClassName="p-0"
                              selectable={false}
                              pagination={null}
                              onChangePage={(page) => {}}
                              onChangeRowsPerPage={(perPage) => {}}
                              fullColumns={columns}
                              data={acceptedInfos.current}
                              loading={false}
                              onRowClick={(row) => {}}
                              onSetSelectedMultiItems={(rows) => {}}
                              haveCardHeader={false}
                              emptyComponent={
                                <div>
                                  <EmptyView message={t('NoDataToDisplay')} />
                                </div>
                              }
                            />
                          </div>
                        }
                      />
                    </div>
                  </div>
                ) : selectedTabIndex === 1 ? (
                  // thong tin thanh toan
                  <div className="row m-0">
                    {/* so tai khoan */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('BankAccountNumber')}</>}
                        inputName="bankAccountNumber"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="bankAccountNumber">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('BankAccountNumber'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* ten tai khoan */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('BankAccountName')}</>}
                        inputName="bankAccountName"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="bankAccountName">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('BankAccountName'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* ten ngan hang */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('BankName')}</>}
                        inputName="bank"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="bank">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('BankName'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* chi nhanh */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('BankBranch')}</>}
                        inputName="bankBranch"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="bankBranch">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('BankBranch'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* han thanh toan */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('PaymentDuration')}</>}
                        inputName="expirePayment"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="expirePayment">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.number}
                                text={t('FromInvoiceDate')}
                                placeholder={`${_.capitalize(t('PaymentDuration'))}...`}
                                enableInputGroup
                                appendElements={[t('Day')]}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* xem huong dan thiet lap */}
                    {/* <div className="col-12">
                      <p className="mb-0">
                        {t('SeeSetupGuide')}{' '}
                        <a
                          href="#"
                          className=""
                          style={{
                            textDecoration: 'underline',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {t('Here')}
                        </a>
                      </p>
                    </div> */}
                  </div>
                ) : (
                  // thong tin lien he
                  <div className="row m-0">
                    {/* ten nguoi lien he */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('ContactName')}</>}
                        inputName="contactName"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="contactName">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('ContactName'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* email */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('ContactEmail')}</>}
                        inputName="contactEmail"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="contactEmail">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.email}
                                placeholder={`${_.capitalize(t('ContactEmail'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* so dien thoai */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('ContactPhone')}</>}
                        inputName="contactPhone"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="contactPhone">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('ContactPhone'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>

                    {/* chuc vu */}
                    <div className="col-lg-6">
                      <KTFormGroup
                        label={<>{t('ContactPosition')}</>}
                        inputName="contactPosition"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="contactPosition">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(t('ContactPosition'))}...`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* actions */}
              <div className="d-flex align-items-center justify-content-end gap-3 sticky-bottom">
                <VIButton
                  text={t('Cancel')}
                  className="btn-grey"
                  style={{ padding: '8px' }}
                  onClick={handlePressCancel}
                />
                <VIButton
                  text={t('Save')}
                  className=""
                  style={{ padding: '8px' }}
                  onClick={() => {
                    formikProps.handleSubmit();
                  }}
                  beforeIcon={loading ? <ClockLoader color="white" size={20} /> : <></>}
                  disabled={loading}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default ViewEditSupplier;
