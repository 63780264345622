import invoiceApi from 'api/invoiceApi';
import { hideAppLoading, showAppLoading } from 'app/appSlice';
import store from 'app/store';
import ExcelJS from 'exceljs';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTRadio from 'general/components/OtherKeenComponents/KTFormControls/KTRadio';
import KeenSelectOption from 'general/components/OtherKeenComponents/KeenSelectOption';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import InvoiceHelper from 'general/helpers/InvoiceHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

ViewInvoiceExport.propTypes = {
  onPressedCancel: PropTypes.func,
  invoiceList: PropTypes.array,
  exportType: PropTypes.oneOf(['IN', 'OUT']),
};

ViewInvoiceExport.defaultProps = {
  onPressedCancel: null,
  invoiceList: null,
  exportType: 'IN',
};

const inExportListTypeItems = [
  { name: 'Danh sách hóa đơn đầu vào', value: '1' },
  { name: 'Bảng kê hóa đơn hàng hóa, dịch vụ mua vào (tổng hợp)', value: '2' },
  { name: 'Bảng kê hóa đơn hàng hóa, dịch vụ mua vào (chi tiết)', value: '3' },
];
const outExportListTypeItems = [
  { name: 'MISA AMIS Kế toán - Hàng hóa', value: '1' },
  { name: 'MISA AMIS Kế toán - Dịch vụ', value: '2' },
  { name: 'MISA AMIS Kế toán - Danh sách hóa đơn', value: '3' },
  { name: 'MISA SME - Hóa đơn bán hàng', value: '4' },
  { name: 'MISA SME - Bán hàng', value: '5' },
];
const importListSoftwareItems = [
  { name: 'MISA SME.NET - Dịch vụ', value: '1' },
  { name: 'MISA SME.NET - Hàng hóa', value: '2' },
  { name: 'FAST - Dịch vụ', value: '3' },
  { name: 'FAST - Hàng hóa', value: '4' },
];

/**
 *
 * @param {{
 * onPressedCancel: function,
 * invoiceList: {}[],
 * exportType: string,
 * }} props
 * @returns
 */
function ViewInvoiceExport(props) {
  // ---- params ----
  const { onPressedCancel, invoiceList, exportType } = props;
  const { t } = useTranslation();
  const [exportListType, setExportListType] = useState('1');
  const [importListType, setImportListType] = useState('1');
  const [checkedRadioId, setCheckedRadioId] = useState('radioInputInvoice');
  const account = useAccount();
  const dispatch = useDispatch();

  // ---- methods ----
  function handlePressCancel() {
    if (onPressedCancel) {
      onPressedCancel();
    }
  }

  async function handlePressExport() {
    // goi api lay ds hoa don theo dieu kien loc
    const params =
      exportType === 'IN'
        ? {
            ...Global.gFiltersInputInvoice,
          }
        : {
            ...Global.gFiltersOutputInvoice,
          };
    delete params.page;
    delete params.limit;

    let sheetFileName = '';
    const appendDateTime = moment().format('DDMMYYYY');
    const ext = '.xlsx';

    try {
      const workbook = new ExcelJS.Workbook();
      let sheet = null;
      let res = null;

      dispatch(showAppLoading());
      if (_.isNull(invoiceList)) {
        const result = await invoiceApi.getAllInInvoice({ ...params, exporting: true });
        //duc tu
        // lấy danh sách hàng hóa trước khi tải xuống
        if (result?.result === 'success') {
          let invoiceIds = [];
          for (let i = 0; i < (result?.invoices?.length ?? result?.invoiceIds?.length); i++) {
            invoiceIds.push(result?.invoices?.[i]?.invoiceId ?? result?.invoiceIds?.[i]);
          }
          if (true || invoiceIds.length > 0) {
            res = await invoiceApi.exportInvoice({
              invoiceIds: invoiceIds,
              checkedRadioId: `${exportType == 'IN' ? checkedRadioId : `${exportType}`}_${
                checkedRadioId === 'radioImportAccountantSoftware' ? importListType : exportListType
              }`,
            });
          }
        } else {
          dispatch(hideAppLoading());
        }
      } else {
        //duc tu
        // lấy danh sách hàng hóa trước khi tải xuống

        let invoiceIds = [];
        for (let i = 0; i < invoiceList.length; i++) {
          invoiceIds.push(invoiceList[i]?.invoiceId);
        }
        if (true || invoiceIds.length > 0) {
          res = await invoiceApi.exportInvoice({
            invoiceIds: invoiceIds,
            checkedRadioId: `${exportType == 'IN' ? checkedRadioId : `${exportType}`}_${
              checkedRadioId === 'radioImportAccountantSoftware' ? importListType : exportListType
            }`,
          });
        }

        // res = {
        //   result: 'success',
        //   invoices: invoiceList,
        // };
      }
      let { result, invoices } = res;
      if (result === 'success' && invoices) {
        // xuat khau hoa don dau vao
        if (exportType === 'IN') {
          if (checkedRadioId === 'radioInputInvoice') {
            // danh sach hoa don dau vao
            switch (exportListType) {
              case '1':
                {
                  // danh sach hoa don dau vao
                  sheetFileName = 'DS_hoa_don_dau_vao_' + appendDateTime;
                  sheet = workbook.addWorksheet(sheetFileName, {
                    headerFooter: { firstHeader: sheetFileName },
                  });
                  // them tieu de file sheet
                  sheet.mergeCells('A1', 'AQ1');
                  sheet.getCell('A1').value = 'DANH SÁCH HÓA ĐƠN ĐẦU VÀO';
                  sheet.getCell('A1').font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 14,
                    bold: true,
                  };
                  sheet.getCell('A1').alignment = { vertical: 'top', horizontal: 'center' };
                  sheet.mergeCells('A2', 'AQ2');
                  sheet.getCell('A2').value = _.isNull(invoiceList)
                    ? `Từ ngày ${moment(params.fromDate).format('DD/MM/YYYY')} đến ngày ${moment(
                        params.toDate
                      ).format('DD/MM/YYYY')}`
                    : '';
                  sheet.getCell('A2').font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 11,
                    bold: false,
                  };
                  sheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center' };
                  sheet.mergeCells('A3', 'AQ3');
                  // A -> AQ
                  const columns = [
                    // so thu tu
                    {
                      header: 'STT',
                      key: 'stt',
                      width: 6,
                    },
                    // ten cong ty
                    {
                      header: 'Tên công ty',
                      key: 'tenCongTy',
                      width: 30,
                    },
                    // mst cong ty
                    {
                      header: 'MST công ty',
                      key: 'mstCongTy',
                      width: 12,
                    },
                    // ten chi nhanh
                    {
                      header: 'Tên chi nhánh',
                      key: 'tenChiNhanh',
                      width: 16,
                    },
                    // mst chi nhanh
                    {
                      header: 'MST chi nhánh',
                      key: 'mstChiNhanh',
                      width: 18,
                    },
                    // ten phong ban
                    {
                      header: 'Tên phòng ban',
                      key: 'tenPhongBan',
                      width: 30,
                    },
                    // ten don vi mua hang
                    {
                      header: 'Tên đơn vị mua hàng',
                      key: 'tenDonViMuaHang',
                      width: 30,
                    },
                    // nguoi mua hang
                    {
                      header: 'Người mua hàng',
                      key: 'nguoiMuaHang',
                      width: 18,
                    },
                    // dia chi ben mua
                    {
                      header: 'Địa chỉ bên mua',
                      key: 'diaChiBenMua',
                      width: 60,
                    },
                    // email nhan hoa don
                    {
                      header: 'Email nhận hóa đơn',
                      key: 'emailNhanHoaDon',
                      width: 24,
                    },
                    // mau so
                    {
                      header: 'Mẫu số',
                      key: 'mauSo',
                      width: 10,
                    },
                    // ky hieu
                    {
                      header: 'Ký hiệu',
                      key: 'kyHieu',
                      width: 10,
                    },
                    // so hoa don
                    {
                      header: 'Số hóa đơn',
                      key: 'soHoaDon',
                      width: 20,
                    },
                    // ngay nhan hoa don
                    {
                      header: 'Ngày nhận hóa đơn',
                      key: 'ngayNhanHoaDon',
                      width: 24,
                    },
                    // ngay hoa don
                    {
                      header: 'Ngày hóa đơn',
                      key: 'ngayHoaDon',
                      width: 20,
                    },
                    // ngay ky
                    {
                      header: 'Ngày ký',
                      key: 'ngayKy',
                      width: 20,
                    },
                    // ngay cap ma
                    {
                      header: 'Ngày cấp mã',
                      key: 'ngayCapMa',
                      width: 20,
                    },
                    // ngay hach toan
                    {
                      header: 'Ngày hạch toán',
                      key: 'ngayHachToan',
                      width: 20,
                    },
                    // nguoi hach toan
                    {
                      header: 'Người hạch toán',
                      key: 'nguoiHachToan',
                      width: 30,
                    },
                    // ma don vi
                    {
                      header: 'Mã đơn vị',
                      key: 'maDonVi',
                      width: 16,
                    },
                    // nha cung cap
                    {
                      header: 'Nhà cung cấp',
                      key: 'nhaCungCap',
                      width: 60,
                    },
                    // dia chi ncc
                    {
                      header: 'Địa chỉ NCC',
                      key: 'diaChiNCC',
                      width: 60,
                    },
                    // ma so thue
                    {
                      header: 'Mã số thuế',
                      key: 'mstNcc',
                      width: 20,
                    },
                    // tong tien
                    {
                      header: 'Tổng tiền',
                      key: 'tongTien',
                      width: 20,
                    },
                    // loai tien
                    {
                      header: 'Loại tiền',
                      key: 'loaiTien',
                      width: 16,
                    },
                    // ty gia
                    {
                      header: 'Tỷ giá',
                      key: 'tyGia',
                      width: 6,
                    },
                    // tong tien quy doi
                    {
                      header: 'Tổng tiền quy đổi',
                      key: 'tongTienQuyDoi',
                      width: 20,
                    },
                    // ket qua kiem tra
                    {
                      header: 'Kết quả kiểm tra',
                      key: 'ketQuaKiemTra',
                      width: 30,
                    },
                    // trang thai gui email
                    {
                      header: 'Trạng thái gửi email',
                      key: 'trangThaiGuiEmail',
                      width: 20,
                    },
                    // kiem tra thue suat
                    {
                      header: 'Kiểm tra thuế suất',
                      key: 'kiemTraThueSuat',
                      width: 20,
                    },
                    // trang thai hoa don
                    {
                      header: 'Trạng thái hóa đơn',
                      key: 'trangThaiHoaDon',
                      width: 20,
                    },
                    // ngay thay doi trang thai tren CQT
                    {
                      header: 'Ngày thay đổi trạng thái trên CQT',
                      key: 'ngayThayDoiTrangThaiTrenCQT',
                      width: 20,
                    },
                    // ngay phat hien doi trang thai
                    {
                      header: 'Ngày phát hiện thay đổi trạng thái',
                      key: 'ngayPhatHienThayDoiTrangThai',
                      width: 20,
                    },
                    // ngay nhac han tt
                    {
                      header: 'Ngày nhắc hạn thanh toán',
                      key: 'ngayNhacHanThanhToan',
                      width: 20,
                    },
                    // han thanh toan
                    {
                      header: 'Hạn thanh toán',
                      key: 'hanThanhToan',
                      width: 20,
                    },
                    // trang thai thanh toan
                    {
                      header: 'Trạng thái thanh toán',
                      key: 'trangThaiThanhToan',
                      width: 30,
                    },
                    // thong tin thanh toan
                    {
                      header: 'Thông tin thanh toán',
                      key: 'thongTinThanhToan',
                      width: 30,
                    },
                    // tong tien da thanh toan
                    {
                      header: 'Tổng tiền đã thanh toán',
                      key: 'tongTienDaThanhToan',
                      width: 20,
                    },
                    // tien chua thanh toan
                    {
                      header: 'Tiền chưa thanh toán',
                      key: 'tienChuaThanhToan',
                      width: 20,
                    },
                    // nhan dan
                    {
                      header: 'Nhãn dán',
                      key: 'nhanDan',
                      width: 30,
                    },
                    // so chung tu
                    {
                      header: 'Số chứng từ',
                      key: 'soChungTu',
                      width: 16,
                    },
                    // ghi chu
                    {
                      header: 'Ghi chú',
                      key: 'ghiChu',
                      width: 20,
                    },
                    // tep hoa don
                    {
                      header: 'Tệp hóa đơn',
                      key: 'tepHoaDon',
                      width: 30,
                    },
                  ];

                  sheet.getRow(4).height = 40;
                  sheet.getRow(4).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.getRow(4).alignment = {
                    vertical: 'top',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  sheet.getRow(4).border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  sheet.getRow(4).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f5f5f5' },
                  };
                  sheet.getRow(4).values = columns.map((item) => item.header);

                  sheet.columns = columns.map((item) => {
                    return {
                      key: item.key,
                      width: item.width,
                    };
                  });
                  const checkResult = (e) => {
                    let result = '';
                    if (e?.checkResultBuyerAddress === false) {
                      result += 'Sai địa chỉ người mua';
                    }
                    if (e?.checkResultBuyerTaxCode === false) {
                      if (result !== '') {
                        result += ', Sai mã số thuế người mua';
                      } else {
                        result += 'Sai mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultBuyerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người mua';
                      } else {
                        result += 'Sai tên người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === false) {
                      if (result !== '') {
                        result += ', Sai địa chỉ người bán';
                      } else {
                        result += 'Sai địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultSellerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người bán';
                      } else {
                        result += 'Sai tên người bán';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Hóa đơn chưa tồn tại trên CQT';
                      } else {
                        result += 'Hóa đơn chưa tồn tại trên CQT';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Chưa cấp mã hóa đơn';
                      } else {
                        result += 'Chưa cấp mã hóa đơn';
                      }
                    }

                    if (e?.checkResultBuyerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người mua';
                      } else {
                        result += 'Không có tên người mua';
                      }
                    }
                    if (e?.checkResultBuyerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người mua';
                      } else {
                        result += 'Không có địa chỉ người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người bán';
                      } else {
                        result += 'Không có địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultBuyerTaxCode === null) {
                      if (result !== '') {
                        result += ', Không có mã số thuế người mua';
                      } else {
                        result += 'Không có mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultSellerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người bán';
                      } else {
                        result += 'Không có tên người bán';
                      }
                    }
                    if (e?.checkResultSignatureNCC === null) {
                      if (result !== '') {
                        result += ', Không có chữ ký nhà cung cấp';
                      } else {
                        result += 'Không có chữ ký nhà cung cấp';
                      }
                    }
                    if (
                      e?.checkResultBuyerAddress &&
                      e?.checkResultBuyerTaxCode &&
                      e?.checkResultBuyerName &&
                      e?.checkResultSellerName &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultSellerAddress
                    ) {
                      result += 'Hóa đơn hợp lệ';
                    }

                    return result;
                  };
                  invoices?.forEach((item, index) => {
                    sheet.addRow({
                      stt: `${index + 1}`,
                      tenCongTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                        ? store.getState()?.auth.account?.Organization?.OrganizationHQ
                            ?.organizationName
                        : store.getState()?.auth.account?.Organization?.organizationName ?? '',
                      mstCongTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                        ? store.getState()?.auth.account?.Organization?.OrganizationHQ?.taxCode
                        : store.getState()?.auth.account?.Organization?.taxCode ?? '',
                      tenChiNhanh: store.getState()?.auth.account?.Organization
                        ? store.getState()?.auth.account?.Organization?.organizationName
                        : '' ?? '',
                      mstChiNhanh: store.getState()?.auth.account?.Organization
                        ? store.getState()?.auth.account?.Organization?.taxCode
                        : '' ?? '',
                      tenPhongBan:
                        store.getState()?.auth.account?.OrganizationDepartment?.departmentName ??
                        '',
                      tenDonViMuaHang: store.getState()?.auth.account?.Organization
                        ? store.getState()?.auth.account?.Organization?.organizationName
                        : store.getState()?.auth.account?.OrganizationDepartment?.departmentName,
                      nguoiMuaHang: item?.buyerPersonName ?? '',
                      diaChiBenMua: item?.buyerAddress ?? '',

                      emailNhanHoaDon: store.getState()?.auth.account?.OrganizationDepartment
                        ? store.getState()?.auth.account?.OrganizationDepartment?.invoiceMailbox
                        : store.getState()?.auth.account?.Organization?.invoiceMailbox ?? '',
                      mauSo: item?.invoiceTypeCode ?? '',
                      kyHieu: item?.serial ?? '',
                      soHoaDon: item?.invoiceNumber ?? '',
                      ngayNhanHoaDon: item?.invoiceReceiveDate
                        ? Utils.formatDateTime(item?.invoiceReceiveDate, 'DD/MM/YYYY')
                        : '',
                      mstNcc: item?.sellerTaxCode ?? '',
                      ngayHoaDon: item?.invoiceDate
                        ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                        : '',
                      ngayKy: item?.signDate
                        ? Utils.formatDateTime(item?.signDate, 'DD/MM/YYYY')
                        : '',
                      ngayCapMa: item?.providedCodeDate
                        ? Utils.formatDateTime(item?.providedCodeDate, 'DD/MM/YYYY')
                        : '',
                      ngayHachToan: item?.accountingDate
                        ? Utils.formatDateTime(item?.accountingDate, 'DD/MM/YYYY')
                        : 'Chưa hạch toán',
                      nguoiHachToan: item?.accountingPersonName ?? '',
                      maDonVi: item?.sellerCode ?? '',
                      nhaCungCap: item?.sellerName ?? '',
                      diaChiNCC: item?.sellerAddress ?? '',
                      maSoThue: item?.sellerTaxCode ?? '',
                      tongTien: Utils.formatCurrency(item?.finalAmount, '', ',') ?? '',
                      loaiTien: item?.paymentCurrency ?? '',
                      tyGia: item?.paymentExchangeRate ?? '',
                      tongTienQuyDoi:
                        Utils.formatCurrency(item?.finalAmountExchange, '', ',') ?? '',
                      ketQuaKiemTra:
                        item?.ketQuaKiemTra ?? checkResult(item?.InvoiceValidate) ?? '',
                      trangThaiGuiEmail: item?.statusEmail ?? '',
                      kiemTraThueSuat: item?.statusTax ?? '',
                      trangThaiHoaDon: item?.statusInvoiceText ?? '',
                      ngayThayDoiTrangThayTrenCQT: item?.dateCQTChangeStatus
                        ? Utils.formatDateTime(item?.dateCQTChangeStatus, 'DD/MM/YYYY')
                        : '',
                      ngayPhatHienThayDoiTrangThai: item?.dateDetectChangeStatus
                        ? Utils.formatDateTime(item?.dateDetectChangeStatus, 'DD/MM/YYYY')
                        : '',
                      ngayNhacHanThanhToan: item?.reminderPaymentDate
                        ? Utils.formatDateTime(item?.reminderPaymentDate, 'DD/MM/YYYY')
                        : '',
                      hanThanhToan: item?.expiredPaymentDate
                        ? Utils.formatDateTime(item?.expiredPaymentDate, 'DD/MM/YYYY')
                        : '',
                      trangThaiThanhToan: item?.statusPayment ?? '',
                      thongTinThanhToan:
                        item.thongTinhThanhToan ?? InvoiceHelper.getExportInvoicePaymentInfo(item),
                      tongTienDaThanhToan: Utils.formatCurrency(item?.amountPayment, '', ',') ?? '',
                      tienChuaThanhToan: Utils.formatCurrency(item?.debtPayment, '', ',') ?? '',
                      nhanDan: item?.Tags?.map((i) => i.name).join(', '),
                      soChungTu: item?.noDocument ?? '',
                      ghiChu: item?.noteInvoice ?? '',
                      tepHoaDon: item.tepHoaDon ?? InvoiceHelper.getExportInvoiceFileStatus(item),
                    });
                  });
                  sheet?.eachRow((row, rowNumber) => {
                    if (rowNumber >= 5) {
                      row.alignment = {
                        vertical: 'top',
                        horizontal: 'left',
                        wrapText: true,
                      };
                      row.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } },
                      };
                      row.font = {
                        name: 'Times New Roman',
                        family: 4,
                        size: 11,
                        bold: false,
                      };
                    }
                  });
                }
                break;
              case '2':
                {
                  // bang ke hoa don hang hoa, dich vu mua vao (tong hop)
                  sheetFileName = 'Bang_ke_hoa_don_hang_hoa_dich_vu_mua_vao_tong_hop';
                  sheet = workbook.addWorksheet(sheetFileName, {
                    headerFooter: { firstHeader: sheetFileName },
                  });
                  // them tieu de file sheet
                  sheet.mergeCells('A1', 'AN1');
                  sheet.getCell('A1').value = 'BẢNG KÊ HOÁ ĐƠN HÀNG HOÁ, DỊCH VỤ MUA VÀO';
                  sheet.getCell('A1').font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 14,
                    bold: true,
                  };
                  sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
                  sheet.mergeCells('A2', 'AN2');
                  sheet.getCell('A2').value = _.isNull(invoiceList)
                    ? `Từ ngày ${moment(params.fromDate).format('DD/MM/YYYY')} đến ngày ${moment(
                        params.toDate
                      ).format('DD/MM/YYYY')}`
                    : '';
                  sheet.getCell('A2').font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 11,
                    bold: false,
                  };
                  sheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };
                  sheet.mergeCells('A3', 'AN3');

                  // A -> AN
                  const columns = [
                    // so thu tu
                    {
                      header: 'STT',
                      key: 'stt',
                      width: 6,
                    },
                    // ten cong ty
                    {
                      header: 'Tên công ty',
                      key: 'tenCongTy',
                      width: 30,
                    },
                    // mst cong ty
                    {
                      header: 'MST công ty',
                      key: 'mstCongTy',
                      width: 12,
                    },
                    // ten chi nhanh
                    {
                      header: 'Tên chi nhánh',
                      key: 'tenChiNhanh',
                      width: 16,
                    },
                    // mst chi nhanh
                    {
                      header: 'MST chi nhánh',
                      key: 'mstChiNhanh',
                      width: 18,
                    },
                    // ten phong ban
                    {
                      header: 'Tên phòng ban',
                      key: 'tenPhongBan',
                      width: 30,
                    },
                    // ten don vi mua hang
                    {
                      header: 'Tên đơn vị mua hàng',
                      key: 'tenDonViMuaHang',
                      width: 30,
                    },
                    // nguoi mua hang
                    {
                      header: 'Người mua hàng',
                      key: 'nguoiMuaHang',
                      width: 18,
                    },
                    // dia chi ben mua
                    {
                      header: 'Địa chỉ bên mua',
                      key: 'diaChiBenMua',
                      width: 60,
                    },
                    // email nhan hoa don
                    {
                      header: 'Email nhận hóa đơn',
                      key: 'emailNhanHoaDon',
                      width: 24,
                    },
                    // mau so
                    {
                      header: 'Mẫu số',
                      key: 'mauSo',
                      width: 15,
                    },
                    // ky hieu
                    {
                      header: 'Ký hiệu',
                      key: 'kyHieu',
                      width: 10,
                    },
                    // so hoa don
                    {
                      header: 'Số hóa đơn',
                      key: 'soHoaDon',
                      width: 20,
                    },
                    // ngay hoa don
                    {
                      header: 'Ngày hóa đơn',
                      key: 'ngayHoaDon',
                      width: 20,
                    },
                    // ngay cap ma
                    {
                      header: 'Ngày cấp mã',
                      key: 'ngayCapMa',
                      width: 20,
                    },
                    // ngay hach toan
                    {
                      header: 'Ngày hạch toán',
                      key: 'ngayHachToan',
                      width: 20,
                    },
                    // ma nguoi ban
                    {
                      header: 'Mã người bán',
                      key: 'maNguoiBan',
                      width: 30,
                    },
                    // ten nguoi ban
                    {
                      header: 'Tên người bán',
                      key: 'tenNguoiBan',
                      width: 50,
                    },
                    // dia chi ben ban
                    {
                      header: 'Địa chỉ bên bán',
                      key: 'diaChiBenBan',
                      width: 60,
                    },
                    // ma so thue
                    {
                      header: 'Mã số thuế',
                      key: 'mst',
                      width: 20,
                    },
                    // dien giai
                    {
                      header: 'Diễn giải',
                      key: 'dienGiai',
                      width: 100,
                    },
                    // tong tien hang
                    {
                      header: 'Tổng tiền hàng',
                      key: 'tongTienHang',
                      width: 20,
                    },
                    // tien chiet khau
                    {
                      header: 'Tiền chiết khấu',
                      key: 'tienChietKhau',
                      width: 20,
                    },
                    // doanh so ban chua thue
                    {
                      header: 'Doanh số bán chưa thuế',
                      key: 'doanhSoBanChuaThue',
                      width: 30,
                    },
                    // tong giam tru khong chiu thue
                    {
                      header: 'Tổng giảm trừ không chịu thuế',
                      key: 'tongGiamTruKhongChiuThue',
                      width: 40,
                    },
                    // thue suat
                    {
                      header: 'Thuế suất',
                      key: 'thueSuat',
                      width: 20,
                    },
                    // thue gtgt
                    {
                      header: 'Thuế GTGT',
                      key: 'thueGtgt',
                      width: 20,
                    },
                    // tong giam tru khac
                    {
                      header: 'Tổng giảm trừ khác',
                      key: 'tongGiamTruKhac',
                      width: 30,
                    },
                    // tong tien thanh toan
                    {
                      header: 'Tổng tiền thanh toán',
                      key: 'tongTienThanhToan',
                      width: 30,
                    },
                    // loai tien
                    {
                      header: 'Loại tiền',
                      key: 'loaiTien',
                      width: 16,
                    },
                    // ty gia
                    {
                      header: 'Tỷ giá',
                      key: 'tyGia',
                      width: 6,
                    },
                    // tong tien quy doi
                    {
                      header: 'Tổng tiền quy đổi',
                      key: 'tongTienQuyDoi',
                      width: 20,
                    },
                    // nhan dan
                    {
                      header: 'Nhãn dán',
                      key: 'nhanDan',
                      width: 30,
                    },
                    // so chung tu
                    {
                      header: 'Số chứng từ',
                      key: 'soChungTu',
                      width: 16,
                    },
                    // nguoi hach toan
                    {
                      header: 'Người hạch toán',
                      key: 'nguoiHachToan',
                      width: 30,
                    },
                    // ket qua kiem tra
                    {
                      header: 'Kết quả kiểm tra',
                      key: 'ketQuaKiemTra',
                      width: 30,
                    },
                    // trang thai hoa don
                    {
                      header: 'Trạng thái hóa đơn',
                      key: 'trangThaiHoaDon',
                      width: 20,
                    },
                    // ngay thay doi trang thai tren CQT
                    {
                      header: 'Ngày thay đổi trạng thái trên CQT',
                      key: 'ngayThayDoiTrangThaiTrenCQT',
                      width: 20,
                    },
                    // ngay phat hien doi trang thai
                    {
                      header: 'Ngày phát hiện thay đổi trạng thái',
                      key: 'ngayPhatHienThayDoiTrangThai',
                      width: 20,
                    },
                    // ghi chu
                    {
                      header: 'Ghi chú',
                      key: 'ghiChu',
                      width: 20,
                    },
                  ];

                  sheet.getRow(4).height = 40;
                  sheet.getRow(4).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.getRow(4).alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  sheet.getRow(4).border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  sheet.getRow(4).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f5f5f5' },
                  };
                  sheet.getRow(4).values = columns.map((item) => item.header);

                  sheet.columns = columns.map((item) => {
                    return {
                      key: item.key,
                      width: item.width,
                    };
                  });
                  const checkResult = (e) => {
                    let result = '';
                    if (e?.checkResultBuyerAddress === false) {
                      result += 'Sai địa chỉ người mua';
                    }
                    if (e?.checkResultBuyerTaxCode === false) {
                      if (result !== '') {
                        result += ', Sai mã số thuế người mua';
                      } else {
                        result += 'Sai mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultBuyerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người mua';
                      } else {
                        result += 'Sai tên người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === false) {
                      if (result !== '') {
                        result += ', Sai địa chỉ người bán';
                      } else {
                        result += 'Sai địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultSellerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người bán';
                      } else {
                        result += 'Sai tên người bán';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Hóa đơn chưa tồn tại trên CQT';
                      } else {
                        result += 'Hóa đơn chưa tồn tại trên CQT';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Chưa cấp mã hóa đơn';
                      } else {
                        result += 'Chưa cấp mã hóa đơn';
                      }
                    }

                    if (e?.checkResultBuyerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người mua';
                      } else {
                        result += 'Không có tên người mua';
                      }
                    }
                    if (e?.checkResultBuyerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người mua';
                      } else {
                        result += 'Không có địa chỉ người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người bán';
                      } else {
                        result += 'Không có địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultBuyerTaxCode === null) {
                      if (result !== '') {
                        result += ', Không có mã số thuế người mua';
                      } else {
                        result += 'Không có mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultSellerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người bán';
                      } else {
                        result += 'Không có tên người bán';
                      }
                    }
                    if (e?.checkResultSignatureNCC === null) {
                      if (result !== '') {
                        result += ', Không có chữ ký nhà cung cấp';
                      } else {
                        result += 'Không có chữ ký nhà cung cấp';
                      }
                    }
                    if (
                      e?.checkResultBuyerAddress &&
                      e?.checkResultBuyerTaxCode &&
                      e?.checkResultBuyerName &&
                      e?.checkResultSellerName &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultSellerAddress
                    ) {
                      result += 'Hóa đơn hợp lệ';
                    }

                    return result;
                  };

                  const checKThue = (e) => {
                    let arrThue = [];
                    for (let i = 0; i < e?.length; i++) {
                      if (e?.[i]?.vat && !arrThue?.includes(e?.[i]?.vat)) {
                        arrThue.push(e?.[i]?.vat);
                      }
                    }

                    return arrThue?.length > 0 ? arrThue.map((x) => x).join(',') : 0;
                  };

                  invoices?.forEach((item, index) => {
                    sheet.addRow({
                      stt: `${index + 1}`,
                      tenCongTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                        ? store.getState()?.auth.account?.Organization?.OrganizationHQ
                            ?.organizationName
                        : store.getState()?.auth.account?.Organization?.organizationName ?? '',
                      mstCongTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                        ? store.getState()?.auth.account?.Organization?.OrganizationHQ?.taxCode
                        : store.getState()?.auth.account?.Organization?.taxCode ?? '',
                      tenChiNhanh: store.getState()?.auth.account?.Organization
                        ? store.getState()?.auth.account?.Organization?.organizationName
                        : '' ?? '',
                      mstChiNhanh: store.getState()?.auth.account?.Organization
                        ? store.getState()?.auth.account?.Organization?.taxCode
                        : '' ?? '' ?? '',
                      tenPhongBan:
                        store.getState()?.auth.account?.OrganizationDepartment?.departmentName ??
                        '',
                      tenDonViMuaHang: store.getState()?.auth.account?.Organization
                        ? store.getState()?.auth.account?.Organization?.organizationName
                        : store.getState()?.auth.account?.OrganizationDepartment?.departmentName,
                      nguoiMuaHang: item?.buyerPersonName ?? '',
                      diaChiBenMua: item?.buyerAddress ?? '',
                      emailNhanHoaDon: store.getState()?.auth.account?.OrganizationDepartment
                        ? store.getState()?.auth.account?.OrganizationDepartment?.invoiceMailbox
                        : store.getState()?.auth.account?.Organization?.invoiceMailbox ?? '',
                      mauSo: item?.invoiceTypeCode ?? '',
                      kyHieu: item?.serial ?? '',
                      soHoaDon: item?.invoiceNumber ?? '',
                      ngayHoaDon: item?.invoiceDate
                        ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                        : '',
                      ngayCapMa: item?.providedCodeDate
                        ? Utils.formatDateTime(item?.providedCodeDate, 'DD/MM/YYYY')
                        : '',
                      ngayHachToan: item?.accountingDate
                        ? Utils.formatDateTime(item?.accountingDate, 'DD/MM/YYYY')
                        : 'Chưa hạch toán',
                      maNguoiBan: item?.sellerCode ?? '',
                      tenNguoiBan: item?.sellerName ?? '',
                      diaChiBenBan: item?.sellerAddress ?? '',
                      mst: item?.sellerTaxCode ?? '',
                      dienGiai: item?.InvoiceProducts?.map((i) => i.name).join(', ') ?? '',
                      tongTienHang: Utils.formatCurrency(item?.amountBeforeDiscount, '', ',') ?? '',
                      tienChietKhau: Utils.formatCurrency(item?.totalDiscountAmount, '', ',') ?? '',
                      doanhSoBanChuaThue:
                        Utils.formatCurrency(item?.amountBeforeVat, '', ',') ?? '',
                      tongGiamTruKhongChiuThue:
                        Utils.formatCurrency(item?.discountWithoutVAT, '', ',') ?? '',
                      thueSuat: item.thueSuat ?? checKThue(item?.InvoiceProducts) ?? 0,
                      thueGtgt: Utils.formatCurrency(item?.amountVat, '', ',') ?? '',
                      tongGiamTruKhac: Utils.formatCurrency(item?.discountOther, '', ',') ?? '',
                      tongTienThanhToan: Utils.formatCurrency(item?.finalAmount, '', ',') ?? '',
                      loaiTien: item?.paymentCurrency ?? '',
                      tyGia: item?.paymentExchangeRate ?? '',
                      tongTienQuyDoi:
                        Utils.formatCurrency(item?.finalAmountExchange, '', ',') ?? '',
                      nhanDan: item?.nhanDan ?? item?.Tags?.map((i) => i.name).join(', '),
                      soChungTu: item?.noDocument ?? '',
                      nguoiHachToan: item?.accountingPersonName ?? '',
                      ketQuaKiemTra: item.ketQuaKiemTra ?? checkResult(item?.InvoiceValidate) ?? '',
                      trangThaiHoaDon: item?.statusInvoiceText ?? '',
                      ngayThayDoiTrangThayTrenCQT: item?.dateCQTChangeStatus
                        ? Utils.formatDateTime(item?.dateCQTChangeStatus, 'DD/MM/YYYY')
                        : '',
                      ngayPhatHienThayDoiTrangThai: item?.dateDetectChangeStatus
                        ? Utils.formatDateTime(item?.dateDetectChangeStatus, 'DD/MM/YYYY')
                        : '',
                      ghiChu: item?.noteInvoice ?? '',
                    });
                  });
                  sheet?.eachRow((row, rowNumber) => {
                    if (rowNumber >= 5) {
                      row.alignment = {
                        vertical: 'top',
                        horizontal: 'left',
                        wrapText: true,
                      };
                      row.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } },
                      };
                      row.font = {
                        name: 'Times New Roman',
                        family: 4,
                        size: 11,
                        bold: false,
                      };
                    }
                  });

                  sheet.mergeCells(`K${invoices?.length + 5}`);
                  sheet.getCell(`K${invoices?.length + 5}`).value = 'Tổng cộng';
                  sheet.getCell(`K${invoices?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.mergeCells(`V${invoices?.length + 5}`);
                  sheet.getCell(`V${invoices?.length + 5}`).value = Utils.formatCurrency(
                    invoices.reduce((total, item) => total + item?.amountBeforeDiscount ?? 0, 0),
                    '',
                    ','
                  );
                  sheet.getCell(`V${invoices?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.mergeCells(`X${invoices?.length + 5}`);
                  sheet.getCell(`X${invoices?.length + 5}`).value = Utils.formatCurrency(
                    invoices.reduce((total, item) => total + item?.amountBeforeVat ?? 0, 0),
                    '',
                    ','
                  );
                  sheet.getCell(`X${invoices?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.mergeCells(`AA${invoices?.length + 5}`);
                  sheet.getCell(`AA${invoices?.length + 5}`).value = Utils.formatCurrency(
                    invoices.reduce((total, item) => total + item?.amountVat ?? 0, 0),
                    '',
                    ','
                  );
                  sheet.getCell(`AA${invoices?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.mergeCells(`AC${invoices?.length + 5}`);
                  sheet.getCell(`AC${invoices?.length + 5}`).value = Utils.formatCurrency(
                    invoices.reduce((total, item) => total + item?.finalAmount ?? 0, 0),
                    '',
                    ','
                  );
                  sheet.getCell(`AC${invoices?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.mergeCells(`AF${invoices?.length + 5}`);
                  sheet.getCell(`AF${invoices?.length + 5}`).value = Utils.formatCurrency(
                    invoices.reduce((total, item) => total + item?.finalAmountExchange ?? 0, 0),
                    '',
                    ','
                  );
                  sheet.getCell(`AF${invoices?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.getRow(invoices?.length + 5).alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  sheet.getRow(invoices?.length + 5).border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                }
                break;
              case '3':
                {
                  // bang ke hoa don hang hoa, dich vu mua vao (chi tiet)
                  sheetFileName = 'Bang_ke_hoa_don_hang_hoa_dich_vu_mua_vao_chi_tiet';
                  sheet = workbook.addWorksheet(sheetFileName, {
                    headerFooter: { firstHeader: sheetFileName },
                  });
                  // them tieu de file sheet
                  sheet.mergeCells('A1', 'AM1');
                  sheet.getCell('A1').value = 'BẢNG KÊ HOÁ ĐƠN HÀNG HOÁ, DỊCH VỤ MUA VÀO';
                  sheet.getCell('A1').font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 14,
                    bold: true,
                  };
                  sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
                  sheet.mergeCells('A2', 'AM2');
                  sheet.getCell('A2').value = _.isNull(invoiceList)
                    ? `Từ ngày ${moment(params.fromDate).format('DD/MM/YYYY')} đến ngày ${moment(
                        params.toDate
                      ).format('DD/MM/YYYY')}`
                    : '';
                  sheet.getCell('A2').font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 11,
                    bold: false,
                  };
                  sheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };
                  sheet.mergeCells('A3', 'AM3');
                  // A -> AM
                  const columns = [
                    // so thu tu
                    {
                      header: 'STT',
                      key: 'stt',
                      width: 6,
                    },
                    // ten cong ty
                    {
                      header: 'Tên công ty',
                      key: 'tenCongTy',
                      width: 30,
                    },
                    // mst cong ty
                    {
                      header: 'MST công ty',
                      key: 'mstCongTy',
                      width: 12,
                    },
                    // ten chi nhanh
                    {
                      header: 'Tên chi nhánh',
                      key: 'tenChiNhanh',
                      width: 16,
                    },
                    // mst chi nhanh
                    {
                      header: 'MST chi nhánh',
                      key: 'mstChiNhanh',
                      width: 18,
                    },
                    // ten phong ban
                    {
                      header: 'Tên phòng ban',
                      key: 'tenPhongBan',
                      width: 30,
                    },
                    // ten don vi mua hang
                    {
                      header: 'Tên đơn vị mua hàng',
                      key: 'tenDonViMuaHang',
                      width: 30,
                    },
                    // nguoi mua hang
                    {
                      header: 'Người mua hàng',
                      key: 'nguoiMuaHang',
                      width: 18,
                    },
                    // dia chi ben mua
                    {
                      header: 'Địa chỉ bên mua',
                      key: 'diaChiBenMua',
                      width: 60,
                    },
                    // email nhan hoa don
                    {
                      header: 'Email nhận hóa đơn',
                      key: 'emailNhanHoaDon',
                      width: 24,
                    },
                    // mau so
                    {
                      header: 'Mẫu số',
                      key: 'mauSo',
                      width: 15,
                    },
                    // ky hieu
                    {
                      header: 'Ký hiệu',
                      key: 'kyHieu',
                      width: 10,
                    },
                    // so hoa don
                    {
                      header: 'Số hóa đơn',
                      key: 'soHoaDon',
                      width: 20,
                    },
                    // ngay hoa don
                    {
                      header: 'Ngày hóa đơn',
                      key: 'ngayHoaDon',
                      width: 20,
                    },
                    // ngay cap ma
                    {
                      header: 'Ngày cấp mã',
                      key: 'ngayCapMa',
                      width: 20,
                    },
                    // ngay hach toan
                    {
                      header: 'Ngày hạch toán',
                      key: 'ngayHachToan',
                      width: 20,
                    },
                    // ma nguoi ban
                    {
                      header: 'Mã người bán',
                      key: 'maNguoiBan',
                      width: 30,
                    },
                    // ten nguoi ban
                    {
                      header: 'Tên người bán',
                      key: 'tenNguoiBan',
                      width: 50,
                    },
                    // dia chi ben ban
                    {
                      header: 'Địa chỉ bên bán',
                      key: 'diaChiBenBan',
                      width: 60,
                    },
                    // ma so thue
                    {
                      header: 'Mã số thuế',
                      key: 'mst',
                      width: 20,
                    },
                    // dien giai
                    {
                      header: 'Tên hàng hóa, dịch vụ',
                      key: 'dienGiai',
                      width: 100,
                    },
                    // tong tien hang
                    {
                      header: 'ĐVT',
                      key: 'donViTinh',
                      width: 20,
                    },
                    {
                      header: 'Số lượng',
                      key: 'soLuong',
                      width: 20,
                    },
                    {
                      header: 'Đơn giá',
                      key: 'donGia',
                      width: 20,
                    },
                    {
                      header: 'Tổng tiền hàng',
                      key: 'tongTienHang',
                      width: 20,
                    },
                    // tien chiet khau
                    {
                      header: 'Tiền chiết khấu',
                      key: 'tienChietKhau',
                      width: 20,
                    },
                    // doanh so ban chua thue
                    {
                      header: 'Doanh số bán chưa thuế',
                      key: 'doanhSoBanChuaThue',
                      width: 30,
                    },
                    // thue suat
                    {
                      header: 'Thuế suất',
                      key: 'thueSuat',
                      width: 20,
                    },
                    // thue gtgt
                    {
                      header: 'Thuế GTGT',
                      key: 'thueGtgt',
                      width: 20,
                    },
                    // tong giam tru khac
                    {
                      header: 'Tổng giảm trừ khác',
                      key: 'tongGiamTruKhac',
                      width: 30,
                    },
                    // tong tien thanh toan
                    {
                      header: 'Tổng tiền thanh toán',
                      key: 'tongTienThanhToan',
                      width: 30,
                    },
                    // loai tien
                    {
                      header: 'Loại tiền',
                      key: 'loaiTien',
                      width: 16,
                    },
                    // ty gia
                    {
                      header: 'Tỷ giá',
                      key: 'tyGia',
                      width: 6,
                    },
                    // tong tien quy doi
                    {
                      header: 'Tổng tiền quy đổi',
                      key: 'tongTienQuyDoi',
                      width: 20,
                    },
                    // so lo
                    {
                      header: 'Số lô',
                      key: 'soLo',
                      width: 15,
                    },
                    // han su dung
                    {
                      header: 'Hạn sử dụng',
                      key: 'hanSuDung',
                      width: 20,
                    },
                    // nhan dan
                    {
                      header: 'Nhãn dán',
                      key: 'nhanDan',
                      width: 30,
                    },
                    // so chung tu
                    {
                      header: 'Số chứng từ',
                      key: 'soChungTu',
                      width: 16,
                    },
                    // nguoi hach toan
                    {
                      header: 'Người hạch toán',
                      key: 'nguoiHachToan',
                      width: 30,
                    },
                    // ghi chu
                    {
                      header: 'Ghi chú',
                      key: 'ghiChu',
                      width: 20,
                    },
                    // ket qua kiem tra
                    {
                      header: 'Kết quả kiểm tra',
                      key: 'ketQuaKiemTra',
                      width: 30,
                    },
                    // trang thai hoa don
                    {
                      header: 'Trạng thái hóa đơn',
                      key: 'trangThaiHoaDon',
                      width: 20,
                    },
                  ];

                  sheet.getRow(4).height = 40;
                  sheet.getRow(4).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.getRow(4).alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  sheet.getRow(4).border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  sheet.getRow(4).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f5f5f5' },
                  };
                  sheet.getRow(4).values = columns.map((item) => item.header);

                  sheet.columns = columns.map((item) => {
                    return {
                      key: item.key,
                      width: item.width,
                    };
                  });
                  let exportDetail = [];
                  invoices?.forEach((item) => {
                    // if (item.InvoiceProducts?.length <= 1) {
                    //   exportDetail.push(item);
                    // }
                    // if (item.InvoiceProducts?.length > 1) {
                    //   item?.InvoiceProducts.forEach((e) => {
                    //     exportDetail.push({ ...item, InvoiceProducts: [e] });
                    //   });
                    // }
                    exportDetail.push(item);
                  });
                  //check result
                  const checkResult = (e) => {
                    let result = '';
                    if (e?.checkResultBuyerAddress === false) {
                      result += 'Sai địa chỉ người mua';
                    }
                    if (e?.checkResultBuyerTaxCode === false) {
                      if (result !== '') {
                        result += ', Sai mã số thuế người mua';
                      } else {
                        result += 'Sai mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultBuyerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người mua';
                      } else {
                        result += 'Sai tên người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === false) {
                      if (result !== '') {
                        result += ', Sai địa chỉ người bán';
                      } else {
                        result += 'Sai địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultSellerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người bán';
                      } else {
                        result += 'Sai tên người bán';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Hóa đơn chưa tồn tại trên CQT';
                      } else {
                        result += 'Hóa đơn chưa tồn tại trên CQT';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Chưa cấp mã hóa đơn';
                      } else {
                        result += 'Chưa cấp mã hóa đơn';
                      }
                    }

                    if (e?.checkResultBuyerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người mua';
                      } else {
                        result += 'Không có tên người mua';
                      }
                    }
                    if (e?.checkResultBuyerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người mua';
                      } else {
                        result += 'Không có địa chỉ người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người bán';
                      } else {
                        result += 'Không có địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultBuyerTaxCode === null) {
                      if (result !== '') {
                        result += ', Không có mã số thuế người mua';
                      } else {
                        result += 'Không có mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultSellerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người bán';
                      } else {
                        result += 'Không có tên người bán';
                      }
                    }
                    if (e?.checkResultSignatureNCC === null) {
                      if (result !== '') {
                        result += ', Không có chữ ký nhà cung cấp';
                      } else {
                        result += 'Không có chữ ký nhà cung cấp';
                      }
                    }
                    if (
                      e?.checkResultBuyerAddress &&
                      e?.checkResultBuyerTaxCode &&
                      e?.checkResultBuyerName &&
                      e?.checkResultSellerName &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultSellerAddress
                    ) {
                      result += 'Hóa đơn hợp lệ';
                    }

                    return result;
                  };

                  exportDetail?.length > 0 &&
                    exportDetail?.forEach((item, index) => {
                      sheet.addRow({
                        stt: `${index + 1}`,
                        tenCongTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                          ? store.getState()?.auth.account?.Organization?.OrganizationHQ
                              ?.organizationName
                          : store.getState()?.auth.account?.Organization?.organizationName ?? '',
                        mstCongTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                          ? store.getState()?.auth.account?.Organization?.OrganizationHQ?.taxCode
                          : store.getState()?.auth.account?.Organization?.taxCode ?? '',
                        tenChiNhanh: store.getState()?.auth.account?.Organization
                          ? store.getState()?.auth.account?.Organization?.organizationName
                          : '' ?? '',
                        mstChiNhanh: store.getState()?.auth.account?.Organization
                          ? store.getState()?.auth.account?.Organization?.taxCode
                          : '' ?? '' ?? '',
                        tenPhongBan:
                          store.getState()?.auth.account?.OrganizationDepartment?.departmentName ??
                          '',
                        tenDonViMuaHang: store.getState()?.auth.account?.Organization
                          ? store.getState()?.auth.account?.Organization?.organizationName
                          : store.getState()?.auth.account?.OrganizationDepartment?.departmentName,
                        nguoiMuaHang: item?.buyerName ?? '',
                        diaChiBenMua: item?.buyerAddress ?? '',
                        emailNhanHoaDon: store.getState()?.auth.account?.OrganizationDepartment
                          ? store.getState()?.auth.account?.OrganizationDepartment?.invoiceMailbox
                          : store.getState()?.auth.account?.Organization?.invoiceMailbox ?? '',
                        mauSo: item?.invoiceTypeCode ?? '',
                        kyHieu: item?.serial ?? '',
                        soHoaDon: item?.invoiceNumber ?? '',
                        ngayHoaDon: item?.invoiceDate
                          ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                          : '',
                        ngayCapMa: item?.providedCodeDate
                          ? Utils.formatDateTime(item?.providedCodeDate, 'DD/MM/YYYY')
                          : '',
                        ngayHachToan: item?.accountingDate
                          ? Utils.formatDateTime(item?.accountingDate, 'DD/MM/YYYY')
                          : 'Chưa hạch toán',
                        maNguoiBan: item?.sellerCode ?? '',
                        tenNguoiBan: item?.sellerName ?? '',
                        diaChiBenBan: item?.sellerAddress ?? '',
                        mst: item?.sellerTaxCode ?? '',
                        dienGiai: item.dienGiai ?? item?.InvoiceProducts?.[0]?.name ?? '',
                        tongTienHang: Utils.formatCurrency(item?.tongTienHang, '', ',' ?? 0),
                        tienChietKhau: Utils.formatCurrency(item?.tienChietKhau, '', ',') ?? '',
                        doanhSoBanChuaThue:
                          Utils.formatCurrency(item?.doanhSoBanChuaThue, '', ',') ?? '',
                        thueSuat: item?.thueSuat ?? '',
                        thueGtgt: Utils.formatCurrency(item?.thueGtgt, '', ',') ?? '',
                        tongGiamTruKhac: Utils.formatCurrency(item?.discountOther, '', ',') ?? '',
                        tongTienThanhToan:
                          Utils.formatCurrency(item?.tongTienThanhToan, '', '') ?? '',
                        loaiTien: item?.paymentCurrency ?? '',
                        tyGia: item?.paymentExchangeRate ?? '',
                        tongTienQuyDoi:
                          Utils.formatCurrency(item.finalAmountExchange, '', ',') ?? '',
                        soLo: '',
                        soLuong: item?.soLuong ?? item?.InvoiceProducts?.[0]?.quantity ?? '',
                        donViTinh: item?.donViTinh ?? item?.InvoiceProducts?.[0]?.unit ?? '',
                        donGia:
                          item?.donGia ??
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.price, '', ',') ??
                          '',
                        hanSuDung: '',
                        nhanDan: item?.nhanDan ?? item?.Tags?.map((i) => i.name).join(', '),
                        soChungTu: item?.noDocument ?? '',
                        nguoiHachToan: item?.accountingPersonName ?? '',
                        ghiChu: item?.noteInvoice ?? '',
                        ketQuaKiemTra:
                          item?.ketQuaKiemTra ?? checkResult(item?.InvoiceValidate) ?? '',
                        trangThaiHoaDon: item?.statusInvoiceText ?? '',
                      });
                    });
                  sheet?.eachRow((row, rowNumber) => {
                    if (rowNumber >= 5) {
                      row.alignment = {
                        vertical: 'top',
                        horizontal: 'left',
                        wrapText: true,
                      };
                      row.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } },
                      };
                      row.font = {
                        name: 'Times New Roman',
                        family: 4,
                        size: 11,
                        bold: false,
                      };
                    }
                  });

                  sheet.mergeCells(`K${exportDetail?.length + 5}`);
                  sheet.getCell(`K${exportDetail?.length + 5}`).value = 'Tổng cộng';
                  sheet.getCell(`K${exportDetail?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.mergeCells(`Y${exportDetail?.length + 5}`);
                  sheet.getCell(`Y${exportDetail?.length + 5}`).value = Utils.formatCurrency(
                    exportDetail.reduce((total, item) => total + item?.tongTienHang ?? 0, 0),
                    '',
                    ','
                  );
                  sheet.getCell(`Y${exportDetail?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.mergeCells(`AA${exportDetail?.length + 5}`);
                  sheet.getCell(`AA${exportDetail?.length + 5}`).value = Utils.formatCurrency(
                    exportDetail.reduce((total, item) => total + item?.tongTienHang ?? 0, 0),
                    '',
                    ','
                  );
                  sheet.getCell(`AA${exportDetail?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.mergeCells(`AC${exportDetail?.length + 5}`);
                  sheet.getCell(`AC${exportDetail?.length + 5}`).value = Utils.formatCurrency(
                    exportDetail.reduce((total, item) => total + item?.tongTienHang ?? 0, 0),
                    '',
                    ','
                  );
                  sheet.getCell(`AC${exportDetail?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.mergeCells(`AE${exportDetail?.length + 5}`);
                  sheet.getCell(`AE${exportDetail?.length + 5}`).value = Utils.formatCurrency(
                    exportDetail.reduce((total, item) => total + item?.tongTienSauThue ?? 0, 0),
                    '',
                    ','
                  );
                  sheet.getCell(`AE${exportDetail?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.mergeCells(`AH${exportDetail?.length + 5}`);
                  sheet.getCell(`AH${exportDetail?.length + 5}`).value = Utils.formatCurrency(
                    exportDetail.reduce((total, item) => total + item?.tongTienSauThue ?? 0, 0),
                    '',
                    ','
                  );
                  sheet.getCell(`AH${exportDetail?.length + 5}`).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.getRow(exportDetail?.length + 5).alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  sheet.getRow(exportDetail?.length + 5).border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                }
                break;
              default:
                break;
            }
          } else if (checkedRadioId === 'radioImportAccountantSoftware') {
            // nhap khau phan mem ke toan
            switch (importListType) {
              case '1':
                {
                  // misa sme.net dich vu
                  sheetFileName = 'Misa_dich_vu_' + appendDateTime;
                  sheet = workbook.addWorksheet(sheetFileName, {
                    headerFooter: { firstHeader: sheetFileName },
                  });
                  // A -> AX
                  const columns = [
                    // hien thi tren so
                    {
                      header: 'Hiển thị trên sổ',
                      key: 'hienThiTrenSo',
                      width: 12,
                    },
                    // ten cong ty
                    {
                      header: 'Tên công ty',
                      key: 'tenCongTy',
                      width: 30,
                    },
                    // mst cong ty
                    {
                      header: 'MST công ty',
                      key: 'mstCongTy',
                      width: 12,
                    },
                    // ten chi nhanh/phong ban
                    {
                      header: 'Tên chi nhánh/phòng ban',
                      key: 'tenChiNhanhPhongBan',
                      width: 16,
                    },
                    // mst chi nhanh
                    {
                      header: 'MST chi nhánh',
                      key: 'mstChiNhanh',
                      width: 18,
                    },
                    // phong ban
                    {
                      header: 'Phòng ban',
                      key: 'phongBan',
                      width: 30,
                    },
                    // ten don vi mua hang
                    {
                      header: 'Tên đơn vị mua hàng',
                      key: 'tenDonViMuaHang',
                      width: 30,
                    },
                    // email nhan hoa don
                    {
                      header: 'Email nhận hóa đơn',
                      key: 'emailNhanHoaDon',
                      width: 24,
                    },
                    // phuong thuc thanh toan
                    {
                      header: 'Phương thức thanh toán',
                      key: 'phuongThucThanhToan',
                      width: 24,
                    },
                    // nhan kem hoa don
                    {
                      header: 'Nhận kèm hóa đơn',
                      key: 'nhanKemHoaDon',
                      width: 16,
                    },
                    // la cp mua hang
                    {
                      header: 'Là CP mua hàng',
                      key: 'laCPMuaHang',
                      width: 16,
                    },
                    // ngay hach toan
                    {
                      header: 'Ngày hạch toán (*)',
                      key: 'ngayHachToan',
                      width: 20,
                    },
                    // ngay chung tu
                    {
                      header: 'Ngày chứng từ (*)',
                      key: 'ngayChungTu',
                      width: 20,
                    },
                    // so chung tu
                    {
                      header: 'Số chứng từ (*)',
                      key: 'soChungTu',
                      width: 20,
                    },
                    // ma nha cung cap
                    {
                      header: 'Mã nhà cung cấp',
                      key: 'maNhaCungCap',
                      width: 20,
                    },
                    // ten nha cung cap
                    {
                      header: 'Tên nhà cung cấp',
                      key: 'tenNhaCungCap',
                      width: 60,
                    },
                    // dia chi
                    {
                      header: 'Địa chỉ',
                      key: 'diaChi',
                      width: 100,
                    },
                    // dien giai/ly do chi/noi dung thanh toan
                    {
                      header: 'Diễn giải/Lý do chi/Nội dung thanh toán',
                      key: 'dienGiai',
                      width: 100,
                    },
                    // nv mua hang
                    {
                      header: 'NV mua hàng',
                      key: 'nvMuaHang',
                      width: 20,
                    },
                    // loai tien
                    {
                      header: 'Loại tiền',
                      key: 'loaiTien',
                      width: 16,
                    },
                    // ty gia
                    {
                      header: 'Tỷ giá',
                      key: 'tyGia',
                      width: 10,
                    },
                    // tinh chat
                    {
                      header: 'Tính chất',
                      key: 'tinhchat',
                      width: 20,
                    },
                    // ma dich vu
                    {
                      header: 'Mã dịch vụ (*)',
                      key: 'maDichVu',
                      width: 20,
                    },
                    // ten dich vu
                    {
                      header: 'Tên dịch vụ',
                      key: 'tenDichVu',
                      width: 60,
                    },
                    // tk chi phi/tk kho
                    {
                      header: 'TK chi phí/TK kho (*)',
                      key: 'tkChiPhi',
                      width: 30,
                    },
                    // tk cong no/tk tien
                    {
                      header: 'TK công nợ/TK tiền (*)',
                      key: 'tkCongNo',
                      width: 30,
                    },
                    // doi tuong
                    {
                      header: 'Đối tượng',
                      key: 'doiTuong',
                      width: 16,
                    },
                    // don vi tinh
                    {
                      header: 'ĐVT',
                      key: 'dvt',
                      width: 10,
                    },
                    // so luong
                    {
                      header: 'Số lượng',
                      key: 'soLuong',
                      width: 20,
                    },
                    // don gia
                    {
                      header: 'Đơn giá',
                      key: 'donGia',
                      width: 20,
                    },
                    // thanh tien
                    {
                      header: 'Thành tiền',
                      key: 'thanhTien',
                      width: 20,
                    },
                    // thanh tien quy doi
                    {
                      header: 'Thành tiền quy đổi',
                      key: 'thanhTienQuyDoi',
                      width: 30,
                    },
                    // ty le ck %
                    {
                      header: 'Tỷ lệ CK (%)',
                      key: 'tyLeCKPhanTram',
                      width: 16,
                    },
                    // tien chiet khau
                    {
                      header: 'Tiền chiết khấu',
                      key: 'tienChietKhau',
                      width: 20,
                    },
                    // tien chiet khau quy doi
                    {
                      header: 'Tiền chiết khấu quy đổi',
                      key: 'tienChietKhauQuyDoi',
                      width: 40,
                    },
                    // % thue gtgt
                    {
                      header: '% thuế GTGT',
                      key: 'phanTramThueGTGT',
                      width: 20,
                    },
                    // tien thue gtgt
                    {
                      header: 'Tiền thuế GTGT',
                      key: 'tienThueGTGT',
                      width: 20,
                    },
                    // tien thue gtgt quy doi
                    {
                      header: 'Tiền thuế GTGT quy đổi',
                      key: 'tienThueGTGTQuyDoi',
                      width: 30,
                    },
                    // tk thue gtgt
                    {
                      header: 'TK thuế GTGT',
                      key: 'tkThueGTGT',
                      width: 20,
                    },
                    // mau so
                    {
                      header: 'Mẫu số',
                      key: 'mauSo',
                      width: 10,
                    },
                    // ky hieu
                    {
                      header: 'Ký hiệu',
                      key: 'kyHieu',
                      width: 10,
                    },
                    // so hoa don
                    {
                      header: 'Số hóa đơn',
                      key: 'soHoaDon',
                      width: 20,
                    },
                    // ngay hoa don
                    {
                      header: 'Ngày hóa đơn',
                      key: 'ngayHoaDon',
                      width: 20,
                    },
                    // nhom hhdv mua vao
                    {
                      header: 'Nhóm HHDV mua vào',
                      key: 'nhomHHDVMuaVao',
                      width: 30,
                    },
                    // ma ncc
                    {
                      header: 'Mã NCC',
                      key: 'maNCC',
                      width: 20,
                    },
                    // ten ncc
                    {
                      header: 'Tên NCC',
                      key: 'tenNCC',
                      width: 60,
                    },
                    // ma so thue ncc
                    {
                      header: 'Mã số thuế NCC',
                      key: 'maSoThueNCC',
                      width: 30,
                    },
                    // dia chi ncc
                    {
                      header: 'Địa chỉ NCC',
                      key: 'diaChiNCC',
                      width: 100,
                    },
                    // ket qua kiem tra
                    {
                      header: 'Kết quả kiểm tra',
                      key: 'ketQuaKiemTra',
                      width: 30,
                    },
                    // trang thai hoa don
                    {
                      header: 'Trạng thái hóa đơn',
                      key: 'trangThaiHoaDon',
                      width: 20,
                    },
                    // ghi chu
                    {
                      header: 'Ghi chú',
                      key: 'ghiChu',
                      width: 20,
                    },
                  ];

                  sheet.getRow(1).height = 40;
                  sheet.getRow(1).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.getRow(1).alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  sheet.getRow(1).border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f5f5f5' },
                  };
                  sheet.getRow(1).values = columns.map((item) => item.header);

                  sheet.columns = columns.map((item) => {
                    return {
                      key: item.key,
                      width: item.width,
                    };
                  });

                  let exportDetail = [];
                  invoices?.forEach((item) => {
                    // if (item.InvoiceProducts?.length <= 1) {
                    //   exportDetail.push(item);
                    // }
                    // if (item.InvoiceProducts?.length > 1) {
                    //   item?.InvoiceProducts.forEach((e) => {
                    //     exportDetail.push({ ...item, InvoiceProducts: [e] });
                    //   });
                    // }
                    exportDetail.push(item);
                  });
                  //check result

                  const checkResult = (e) => {
                    let result = '';
                    if (e?.checkResultBuyerAddress === false) {
                      result += 'Sai địa chỉ người mua';
                    }
                    if (e?.checkResultBuyerTaxCode === false) {
                      if (result !== '') {
                        result += ', Sai mã số thuế người mua';
                      } else {
                        result += 'Sai mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultBuyerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người mua';
                      } else {
                        result += 'Sai tên người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === false) {
                      if (result !== '') {
                        result += ', Sai địa chỉ người bán';
                      } else {
                        result += 'Sai địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultSellerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người bán';
                      } else {
                        result += 'Sai tên người bán';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Hóa đơn chưa tồn tại trên CQT';
                      } else {
                        result += 'Hóa đơn chưa tồn tại trên CQT';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Chưa cấp mã hóa đơn';
                      } else {
                        result += 'Chưa cấp mã hóa đơn';
                      }
                    }

                    if (e?.checkResultBuyerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người mua';
                      } else {
                        result += 'Không có tên người mua';
                      }
                    }
                    if (e?.checkResultBuyerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người mua';
                      } else {
                        result += 'Không có địa chỉ người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người bán';
                      } else {
                        result += 'Không có địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultBuyerTaxCode === null) {
                      if (result !== '') {
                        result += ', Không có mã số thuế người mua';
                      } else {
                        result += 'Không có mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultSellerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người bán';
                      } else {
                        result += 'Không có tên người bán';
                      }
                    }
                    if (e?.checkResultSignatureNCC === null) {
                      if (result !== '') {
                        result += ', Không có chữ ký nhà cung cấp';
                      } else {
                        result += 'Không có chữ ký nhà cung cấp';
                      }
                    }
                    if (
                      e?.checkResultBuyerAddress &&
                      e?.checkResultBuyerTaxCode &&
                      e?.checkResultBuyerName &&
                      e?.checkResultSellerName &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultSellerAddress
                    ) {
                      result += 'Hóa đơn hợp lệ';
                    }

                    return result;
                  };
                  exportDetail?.length > 0 &&
                    exportDetail?.forEach((item, index) => {
                      sheet.addRow({
                        hienThiTrenSo: '',
                        tenCongTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                          ? store.getState()?.auth.account?.Organization?.OrganizationHQ
                              ?.organizationName
                          : store.getState()?.auth.account?.Organization?.organizationName ?? '',
                        mstCongTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                          ? store.getState()?.auth.account?.Organization?.OrganizationHQ?.taxCode
                          : store.getState()?.auth.account?.Organization?.taxCode ?? '',
                        tenChiNhanhPhongBan: store.getState()?.auth.account?.OrganizationDepartment
                          ? store.getState()?.auth.account?.OrganizationDepartment.departmentName
                          : store.getState()?.auth.account?.Organization?.organizationName ?? '',
                        mstChiNhanh: store.getState()?.auth.account?.Organization
                          ? store.getState()?.auth.account?.Organization?.taxCode
                          : '',
                        tenPhongBan:
                          store.getState()?.auth.account?.OrganizationDepartment?.departmentName ??
                          '',
                        tenDonViMuaHang: store.getState()?.auth.account?.Organization
                          ? store.getState()?.auth.account?.Organization?.organizationName
                          : store.getState()?.auth.account?.OrganizationDepartment?.departmentName,
                        emailNhanHoaDon: store.getState()?.auth.account?.OrganizationDepartment
                          ? store.getState()?.auth.account?.OrganizationDepartment?.invoiceMailbox
                          : store.getState()?.auth.account?.Organization?.invoiceMailbox ?? '',
                        phuongThucThanhToan: item?.paymentMethod ?? '',
                        nhanKemHoaDon: '',
                        laCPMuaHang: '',
                        ngayHachToan: item?.accountingDate
                          ? Utils.formatDateTime(item?.accountingDate, 'DD/MM/YYYY')
                          : 'Chưa hạch toán',
                        ngayChungTu: '',
                        soChungTu: item?.noDocument ?? '',
                        maNhaCungCap: item?.sellerCode ?? '',
                        tenNhaCungCap: item?.sellerName ?? '',
                        diaChi: item?.sellerAddress ?? '',
                        dienGiai: item?.InvoiceProducts?.[0]?.name ?? '',
                        nvMuaHang: '',
                        loaiTien: item?.paymentCurrency ?? '',
                        tyGia: item?.paymentExchangeRate ?? '',
                        maDichVu: item?.InvoiceProducts?.[0]?.code ?? '',
                        tenDichVu: item?.InvoiceProducts?.[0]?.name ?? '',
                        tkChiPhi: '',
                        tkCongNo: '',
                        doiTuong: '',
                        tinhchat: item?.tinhchat ?? item?.InvoiceProducts?.[0]?.productType ?? '',
                        dvt: item?.dvt ?? item?.InvoiceProducts?.[0]?.unit ?? '',
                        soLuong: item?.soLuong ?? item?.InvoiceProducts?.[0]?.quantity ?? '',
                        donGia:
                          item?.donGia ??
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.price, '', ',') ??
                          '',
                        thanhTien:
                          item?.thanhTien ??
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.amountTotal, '', ',') ??
                          '',
                        thanhTienQuyDoi: '',
                        tyLeCKPhanTram:
                          item?.tyLeCKPhanTram ?? item?.InvoiceProducts?.[0]?.discount ?? '',
                        tienChietKhau:
                          item?.tienChietKhau ??
                          Utils.formatCurrency(
                            item?.InvoiceProducts?.[0]?.discountAmount,
                            '',
                            ','
                          ) ??
                          '',
                        tienChietKhauQuyDoi: '',
                        phanTramThueGTGT:
                          item?.phanTramThueGTGT ?? item?.InvoiceProducts?.[0]?.vat ?? '',
                        tienThueGTGT:
                          item?.tienThueGTGT ??
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.vatAmount, '', ',') ??
                          '',
                        tienThueGTGTQuyDoi: '',
                        tkThueGTGT: '',
                        mauSo: item?.invoiceTypeCode ?? '',
                        kyHieu: item?.serial ?? '',
                        soHoaDon: item?.invoiceNumber ?? '',
                        ngayHoaDon: item?.invoiceDate
                          ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                          : '',
                        nhomHHDVMuaVao: '',
                        maNCC: item?.sellerCode ?? '',
                        tenNCC: item?.sellerName ?? '',
                        maSoThueNCC: item?.sellerTaxCode ?? '',
                        diaChiNCC: item?.sellerAddress ?? '',
                        ketQuaKiemTra:
                          item?.ketQuaKiemTra ?? checkResult(item?.InvoiceValidate) ?? '',
                        trangThaiHoaDon: item?.statusInvoiceText ?? '',
                        ghiChu: item?.noteInvoice ?? '',
                      });
                    });
                  sheet?.eachRow((row, rowNumber) => {
                    if (rowNumber >= 2) {
                      row.alignment = {
                        vertical: 'top',
                        horizontal: 'left',
                        wrapText: true,
                      };
                      row.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } },
                      };
                      row.font = {
                        name: 'Times New Roman',
                        family: 4,
                        size: 11,
                        bold: false,
                      };
                    }
                  });
                }
                break;
              case '2':
                {
                  // misa sme.net hang hoa
                  sheetFileName = 'Misa_hang_hoa_' + appendDateTime;
                  sheet = workbook.addWorksheet(sheetFileName, {
                    headerFooter: { firstHeader: sheetFileName },
                  });
                  // A -> BI
                  const columns = [
                    // hien thi tren so
                    {
                      header: 'Hiển thị trên sổ',
                      key: 'hienThiTrenSo',
                      width: 12,
                    },
                    // ten cong ty
                    {
                      header: 'Tên công ty',
                      key: 'tenCongTy',
                      width: 30,
                    },
                    // mst cong ty
                    {
                      header: 'MST công ty',
                      key: 'mstCongTy',
                      width: 12,
                    },
                    // ten chi nhanh
                    {
                      header: 'Tên chi nhánh',
                      key: 'tenChiNhanh',
                      width: 16,
                    },
                    // mst chi nhanh
                    {
                      header: 'MST chi nhánh',
                      key: 'mstChiNhanh',
                      width: 18,
                    },
                    // phong ban
                    {
                      header: 'Phòng ban',
                      key: 'phongBan',
                      width: 30,
                    },
                    // ten don vi mua hang
                    {
                      header: 'Tên đơn vị mua hàng',
                      key: 'tenDonViMuaHang',
                      width: 30,
                    },
                    // email nhan hoa don
                    {
                      header: 'Email nhận hóa đơn',
                      key: 'emailNhanHoaDon',
                      width: 24,
                    },
                    // hinh thuc mua hang
                    {
                      header: 'Hình thức mua hàng',
                      key: 'hinhThucMuaHang',
                      width: 24,
                    },
                    // phuong thuc thanh toan
                    {
                      header: 'Phương thức thanh toán',
                      key: 'phuongThucThanhToan',
                      width: 24,
                    },
                    // nhan kem hoa don
                    {
                      header: 'Nhận kèm hóa đơn',
                      key: 'nhanKemHoaDon',
                      width: 16,
                    },
                    // ngay hach toan
                    {
                      header: 'Ngày hạch toán (*)',
                      key: 'ngayHachToan',
                      width: 20,
                    },
                    // ngay chung tu
                    {
                      header: 'Ngày chứng từ (*)',
                      key: 'ngayChungTu',
                      width: 20,
                    },
                    // so phieu nhap
                    {
                      header: 'Số phiếu nhập (*)',
                      key: 'soPhieuNhap',
                      width: 24,
                    },
                    // so chung tu thanh toan
                    {
                      header: 'Số chứng từ thanh toán',
                      key: 'soChungTuThanhToan',
                      width: 30,
                    },
                    // mau so
                    {
                      header: 'Mẫu số',
                      key: 'mauSo',
                      width: 10,
                    },
                    // ky hieu
                    {
                      header: 'Ký hiệu',
                      key: 'kyHieu',
                      width: 10,
                    },
                    // so hoa don
                    {
                      header: 'Số hóa đơn',
                      key: 'soHoaDon',
                      width: 20,
                    },
                    // ngay hoa don
                    {
                      header: 'Ngày hóa đơn',
                      key: 'ngayHoaDon',
                      width: 20,
                    },
                    // ma nha cung cap
                    {
                      header: 'Mã nhà cung cấp',
                      key: 'maNhaCungCap',
                      width: 20,
                    },
                    // ten nha cung cap
                    {
                      header: 'Tên nhà cung cấp',
                      key: 'tenNhaCungCap',
                      width: 60,
                    },
                    // mst nha cung cap
                    {
                      header: 'MST nhà cung cấp',
                      key: 'mstNhaCungCap',
                      width: 24,
                    },
                    // nguoi giao hang
                    {
                      header: 'Người giao hàng',
                      key: 'nguoiGiaoHang',
                      width: 24,
                    },
                    // dien giai
                    {
                      header: 'Diễn giải/Lý do chi/Nội dung thanh toán',
                      key: 'dienGiai',
                      width: 100,
                    },
                    // nv mua hang
                    {
                      header: 'NV mua hàng',
                      key: 'nvMuaHang',
                      width: 20,
                    },
                    // loai tien
                    {
                      header: 'Loại tiền',
                      key: 'loaiTien',
                      width: 16,
                    },
                    // ty gia
                    {
                      header: 'Tỷ giá',
                      key: 'tyGia',
                      width: 6,
                    },
                    // tinh chat
                    {
                      header: 'Tính chất',
                      key: 'tinhchat',
                      width: 20,
                    },
                    // ma hang
                    {
                      header: 'Mã hàng (*)',
                      key: 'maHang',
                      width: 30,
                    },
                    // ten hang
                    {
                      header: 'Tên hàng',
                      key: 'tenHang',
                      width: 60,
                    },
                    // kho
                    {
                      header: 'Kho',
                      key: 'kho',
                      width: 12,
                    },
                    // hang hoa giu ho/ban ho
                    {
                      header: 'Hàng hoá giữ hộ/bán hộ',
                      key: 'hangHoaGiuHo',
                      width: 30,
                    },
                    // tk kho
                    {
                      header: 'TK kho (*)',
                      key: 'tkKho',
                      width: 24,
                    },
                    // tk cong no/tk tien
                    {
                      header: 'TK công nợ/TK tiền (*)',
                      key: 'tkCongNo',
                      width: 40,
                    },
                    // dvt
                    {
                      header: 'ĐVT',
                      key: 'dvt',
                      width: 12,
                    },
                    // so luong
                    {
                      header: 'Số lượng',
                      key: 'soLuong',
                      width: 24,
                    },
                    // don gia
                    {
                      header: 'Đơn giá',
                      key: 'donGia',
                      width: 30,
                    },
                    // thanh tien
                    {
                      header: 'Thành tiền',
                      key: 'thanhTien',
                      width: 30,
                    },
                    // thanh tien quy doi
                    {
                      header: 'Thành tiền quy đổi',
                      key: 'thanhTienQuyDoi',
                      width: 40,
                    },
                    // ty le CK
                    {
                      header: 'Tỷ lệ CK',
                      key: 'tyLeCK',
                      width: 24,
                    },
                    // tien chiet khau
                    {
                      header: 'Tiền chiết khấu',
                      key: 'tienChietKhau',
                      width: 30,
                    },
                    // tien chiet khau quy doi
                    {
                      header: 'Tiền chiết khấu quy đổi',
                      key: 'tienChietKhauQuyDoi',
                      width: 40,
                    },
                    // phi hang ve kho/chi phi mua hang
                    {
                      header: 'Phí hàng về kho/Chi phí mua hàng',
                      key: 'phiHangVeKho',
                      width: 60,
                    },
                    // so lo
                    {
                      header: 'Số lô',
                      key: 'soLo',
                      width: 16,
                    },
                    // han su dung
                    {
                      header: 'Hạn sử dụng',
                      key: 'hanSuDung',
                      width: 30,
                    },
                    // phan tram thue GTGT
                    {
                      header: '% thuế GTGT',
                      key: 'phanTramThueGTGT',
                      width: 30,
                    },
                    // tien thue GTGT
                    {
                      header: 'Tiền thuế GTGT',
                      key: 'tienThueGTGT',
                      width: 30,
                    },
                    // tien thue GTGT quy doi
                    {
                      header: 'Tiền thuế GTGT quy đổi',
                      key: 'tienThueGTGTQuyDoi',
                      width: 40,
                    },
                    // TKĐƯ thue GTGT
                    {
                      header: 'TKĐƯ thuế GTGT',
                      key: 'tkduThueGTGT',
                      width: 30,
                    },
                    // tk thue GTGT
                    {
                      header: 'TK thuế GTGT',
                      key: 'tkThueGTGT',
                      width: 30,
                    },
                    // nhom HHDV mua vao
                    {
                      header: 'Nhóm HHDV mua vào',
                      key: 'nhomHHDVMuaVao',
                      width: 40,
                    },
                    // phi truoc hai quan
                    {
                      header: 'Phí trước hải quan',
                      key: 'phiTruocHaiQuan',
                      width: 30,
                    },
                    // gia tinh thue NK
                    {
                      header: 'Giá tính thuế NK',
                      key: 'giaTinhThueNK',
                      width: 30,
                    },
                    // phan tram thue NK
                    {
                      header: '% thuế NK',
                      key: 'phanTramThueNK',
                      width: 20,
                    },
                    // tien thue NK
                    {
                      header: 'Tiền thuế NK',
                      key: 'tienThueNK',
                      width: 30,
                    },
                    // tk thue NK
                    {
                      header: 'TK thuế NK',
                      key: 'tkThueNK',
                      width: 30,
                    },
                    // phan tram thue TTDB
                    {
                      header: '% thuế TTĐB',
                      key: 'phanTramThueTTDB',
                      width: 30,
                    },
                    // tien thue TTDB
                    {
                      header: 'Tiền thuế TTĐB',
                      key: 'tienThueTTDB',
                      width: 30,
                    },
                    // tk thue TTDB
                    {
                      header: 'TK thuế TTĐB',
                      key: 'tkThueTTĐB',
                      width: 30,
                    },
                    // ket qua kiem tra
                    {
                      header: 'Kết quả kiểm tra',
                      key: 'ketQuaKiemTra',
                      width: 30,
                    },
                    // trang thai hoa don
                    {
                      header: 'Trạng thái hóa đơn',
                      key: 'trangThaiHoaDon',
                      width: 20,
                    },
                    // ghi chu
                    {
                      header: 'Ghi chú',
                      key: 'ghiChu',
                      width: 20,
                    },
                  ];

                  sheet.getRow(1).height = 40;
                  sheet.getRow(1).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.getRow(1).alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  sheet.getRow(1).border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f5f5f5' },
                  };
                  sheet.getRow(1).values = columns.map((item) => item.header);

                  sheet.columns = columns.map((item) => {
                    return {
                      key: item.key,
                      width: item.width,
                    };
                  });

                  let exportDetail = [];
                  invoices?.forEach((item) => {
                    // if (item.InvoiceProducts?.length <= 1) {
                    //   exportDetail.push(item);
                    // }
                    // if (item.InvoiceProducts?.length > 1) {
                    //   item?.InvoiceProducts.forEach((e) => {
                    //     exportDetail.push({ ...item, InvoiceProducts: [e] });
                    //   });
                    // }
                    exportDetail.push(item);
                  });
                  //check result
                  const checkResult = (e) => {
                    let result = '';
                    if (e?.checkResultBuyerAddress === false) {
                      result += 'Sai địa chỉ người mua';
                    }
                    if (e?.checkResultBuyerTaxCode === false) {
                      if (result !== '') {
                        result += ', Sai mã số thuế người mua';
                      } else {
                        result += 'Sai mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultBuyerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người mua';
                      } else {
                        result += 'Sai tên người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === false) {
                      if (result !== '') {
                        result += ', Sai địa chỉ người bán';
                      } else {
                        result += 'Sai địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultSellerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người bán';
                      } else {
                        result += 'Sai tên người bán';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Hóa đơn chưa tồn tại trên CQT';
                      } else {
                        result += 'Hóa đơn chưa tồn tại trên CQT';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Chưa cấp mã hóa đơn';
                      } else {
                        result += 'Chưa cấp mã hóa đơn';
                      }
                    }

                    if (e?.checkResultBuyerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người mua';
                      } else {
                        result += 'Không có tên người mua';
                      }
                    }
                    if (e?.checkResultBuyerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người mua';
                      } else {
                        result += 'Không có địa chỉ người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người bán';
                      } else {
                        result += 'Không có địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultBuyerTaxCode === null) {
                      if (result !== '') {
                        result += ', Không có mã số thuế người mua';
                      } else {
                        result += 'Không có mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultSellerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người bán';
                      } else {
                        result += 'Không có tên người bán';
                      }
                    }
                    if (e?.checkResultSignatureNCC === null) {
                      if (result !== '') {
                        result += ', Không có chữ ký nhà cung cấp';
                      } else {
                        result += 'Không có chữ ký nhà cung cấp';
                      }
                    }
                    if (
                      e?.checkResultBuyerAddress &&
                      e?.checkResultBuyerTaxCode &&
                      e?.checkResultBuyerName &&
                      e?.checkResultSellerName &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultSellerAddress
                    ) {
                      result += 'Hóa đơn hợp lệ';
                    }

                    return result;
                  };
                  exportDetail?.length > 0 &&
                    exportDetail?.forEach((item, index) => {
                      sheet.addRow({
                        hienThiTrenSo: '',
                        tenCongTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                          ? store.getState()?.auth.account?.Organization?.OrganizationHQ
                              ?.organizationName
                          : store.getState()?.auth.account?.Organization?.organizationName ?? '',
                        mstCongTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                          ? store.getState()?.auth.account?.Organization?.OrganizationHQ?.taxCode
                          : store.getState()?.auth.account?.Organization?.taxCode ?? '',
                        tenChiNhanh: store.getState()?.auth.account?.Organization
                          ? store.getState()?.auth.account?.Organization?.organizationName
                          : '' ?? '',
                        mstChiNhanh: store.getState()?.auth.account?.Organization
                          ? store.getState()?.auth.account?.Organization?.taxCode
                          : '' ?? '' ?? '',
                        tenPhongBan:
                          store.getState()?.auth.account?.OrganizationDepartment?.departmentName ??
                          '',
                        tenDonViMuaHang: store.getState()?.auth.account?.Organization
                          ? store.getState()?.auth.account?.Organization?.organizationName
                          : store.getState()?.auth.account?.OrganizationDepartment?.departmentName,
                        emailNhanHoaDon: store.getState()?.auth.account?.OrganizationDepartment
                          ? store.getState()?.auth.account?.OrganizationDepartment?.invoiceMailbox
                          : store.getState()?.auth.account?.Organization?.invoiceMailbox ?? '',
                        hinhThucMuaHang: '',
                        phuongThucThanhToan: item?.paymentMethod ?? '',
                        nhanKemHoaDon: '',
                        ngayHachToan: item?.accountingDate
                          ? Utils.formatDateTime(item?.accountingDate, 'DD/MM/YYYY')
                          : 'Chưa hạch toán',
                        ngayChungTu: '',
                        soPhieuNhap: '',
                        tinhchat: item.tinhchat ?? item?.InvoiceProducts?.[0]?.productType ?? '',
                        soChungTuThanhToan: '',
                        mauSo: item?.invoiceTypeCode ?? '',
                        kyHieu: item?.serial ?? '',
                        soHoaDon: item?.invoiceNumber ?? '',
                        ngayHoaDon: item?.invoiceDate
                          ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                          : '',
                        maNhaCungCap: item?.sellerCode ?? '',
                        tenNhaCungCap: item?.sellerName ?? '',
                        mstNhaCungCap: item?.sellerTaxCode ?? '',
                        nguoiGiaoHang: '',
                        dienGiai: item.dienGiai ?? item?.InvoiceProducts?.[0]?.name ?? '',
                        nvMuaHang: '',
                        loaiTien: item?.paymentCurrency ?? '',
                        tyGia: item?.paymentExchangeRate ?? '',
                        maHang: item.maHang ?? item?.InvoiceProducts?.[0]?.code ?? '',
                        tenHang: item.tenHang ?? item?.InvoiceProducts?.[0]?.name ?? '',
                        kho: '',
                        hangHoaGiuHo: '',
                        tkKho: '',
                        tkCongNo: '',
                        dvt: item.dvt ?? item?.InvoiceProducts?.[0]?.unit ?? '',
                        soLuong: item.soLuong ?? item?.InvoiceProducts?.[0]?.quantity ?? '',
                        donGia:
                          item.donGia ??
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.price, '', ',') ??
                          '',
                        thanhTien:
                          item.thanhTien ??
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.amountTotal, '', ',') ??
                          '',
                        thanhTienQuyDoi: '',
                        tyLeCK: item?.InvoiceProducts?.[0]?.discount ?? '',
                        tienChietKhau:
                          Utils.formatCurrency(
                            item?.InvoiceProducts?.[0]?.discountAmount,
                            '',
                            ','
                          ) ?? '',
                        tienChietKhauQuyDoi: '',
                        phiHangVeKho: '',
                        soLo: '',
                        hanSuDung: '',
                        phanTramThueGTGT: item?.InvoiceProducts?.[0]?.vat ?? '',
                        tienThueGTGT:
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.vatAmount, '', ',') ??
                          '',
                        tienThueGTGTQuyDoi: '',
                        tkduThueGTGT: '',
                        tkThueGTGT: '',
                        nhomHHDVMuaVao: '',
                        phiTruocHaiQuan: '',
                        giaTinhThueNK: '',
                        phanTramThueNK: '',
                        tienThueNK: '',
                        tkThueNK: '',
                        phanTramThueTTDB: '',
                        tienThueTTDB: '',
                        tkThueTTDB: '',
                        ketQuaKiemTra:
                          item.ketQuaKiemTra ?? checkResult(item?.InvoiceValidate) ?? '',
                        trangThaiHoaDon: item?.statusInvoiceText ?? '',
                        ghiChu: item?.noteInvoice ?? '',
                      });
                    });
                  sheet?.eachRow((row, rowNumber) => {
                    if (rowNumber >= 2) {
                      row.alignment = {
                        vertical: 'top',
                        horizontal: 'left',
                        wrapText: true,
                      };
                      row.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } },
                      };
                      row.font = {
                        name: 'Times New Roman',
                        family: 4,
                        size: 11,
                        bold: false,
                      };
                    }
                  });
                }
                break;
              case '3':
                {
                  // fast - dich vu
                  sheetFileName = 'Fast_dich_vu_' + appendDateTime;
                  sheet = workbook.addWorksheet(sheetFileName, {
                    headerFooter: { firstHeader: sheetFileName },
                  });
                  // A -> AT
                  const columns = [
                    // cong ty
                    {
                      header: 'Công ty',
                      key: 'congTy',
                      width: 30,
                    },
                    // chi nhanh
                    {
                      header: 'Chi nhánh',
                      key: 'chiNhanh',
                      width: 20,
                    },
                    // phong ban
                    {
                      header: 'Phòng ban',
                      key: 'phongBan',
                      width: 20,
                    },
                    // email nhan hoa don
                    {
                      header: 'Email nhận hóa đơn',
                      key: 'emailNhanHoaDon',
                      width: 30,
                    },
                    // don vi
                    {
                      header: '* Đơn vị',
                      key: 'donVi',
                      width: 40,
                    },
                    // ma ncc
                    {
                      header: '* Mã ncc',
                      key: 'maNCC',
                      width: 24,
                    },
                    // ten nha cung cap
                    {
                      header: 'Tên nhà cung cấp',
                      key: 'tenNhaCungCap',
                      width: 60,
                    },
                    // so chung tu
                    {
                      header: '* Số chứng từ',
                      key: 'soChungTu',
                      width: 24,
                    },
                    // ngay chung tu
                    {
                      header: '* Ngày chứng từ',
                      key: 'ngayChungTu',
                      width: 24,
                    },
                    // ngay hach hoa don
                    {
                      header: 'Ngày hoá đơn',
                      key: 'ngayHoaDons',
                      width: 24,
                    },
                    // so hoa don
                    {
                      header: 'Số hoá đơn',
                      key: 'soHoaDons',
                      width: 24,
                    },
                    // ky hieu
                    {
                      header: 'Ký hiệu',
                      key: 'kyHieus',
                      width: 20,
                    },
                    // dien giai
                    {
                      header: 'Diễn giải',
                      key: 'dienGiai',
                      width: 60,
                    },
                    // tai khoan co
                    {
                      header: '* Tài khoản có',
                      key: 'taiKhoanCo',
                      width: 24,
                    },
                    // tai khoan no
                    {
                      header: '* Tài khoản nợ',
                      key: 'taiKhoanNo',
                      width: 24,
                    },
                    // tien
                    {
                      header: 'Tiền',
                      key: 'tien',
                      width: 24,
                    },
                    // ma nt
                    {
                      header: 'Mã nt',
                      key: 'maNt',
                      width: 16,
                    },
                    // ty gia
                    {
                      header: 'Tỷ giá',
                      key: 'tyGia',
                      width: 16,
                    },
                    // dien giai (trong phan chi tiet)
                    {
                      header: 'Diễn giải (Trong phần chi tiết)',
                      key: 'dienGiaiTrongPhanChiTiet',
                      width: 60,
                    },
                    // ma thanh toan
                    {
                      header: 'Mã thanh toán',
                      key: 'maThanhToan',
                      width: 24,
                    },
                    // mau bao cao
                    {
                      header: '* Mẫu báo cáo',
                      key: 'mauBaoCao',
                      width: 24,
                    },
                    // ma tinh chat
                    {
                      header: '* Mã tính chất',
                      key: 'maTinhChat',
                      width: 24,
                    },
                    // mau hoa don
                    {
                      header: 'Mẫu hoá đơn',
                      key: 'mauHoaDon',
                      width: 24,
                    },
                    // so hoa don
                    {
                      header: '* Số hoá đơn',
                      key: 'soHoaDon',
                      width: 24,
                    },
                    // ky hieu
                    {
                      header: 'Ký hiệu',
                      key: 'kyHieu',
                      width: 16,
                    },
                    // ngay hoa don
                    {
                      header: '* Ngày hoá đơn',
                      key: 'ngayHoaDon',
                      width: 30,
                    },
                    // ma nha cung cap (trong phan thue)
                    {
                      header: 'Mã nhà cung cấp (Trong phần thuế)',
                      key: 'maNhaCungCapTrongPhanThue',
                      width: 40,
                    },
                    // ten nha cung cap (trong phan thue)
                    {
                      header: 'Tên nhà cung cấp (Trong phần thuế)',
                      key: 'tenNhaCungCapTrongPhanThue',
                      width: 60,
                    },
                    // dia chi
                    {
                      header: 'Địa chỉ',
                      key: 'diaChi',
                      width: 100,
                    },
                    // ma so thue
                    {
                      header: 'Mã số thuế',
                      key: 'maSoThue',
                      width: 30,
                    },
                    // ten hang hoa dich vu
                    {
                      header: 'Tên hàng hoá - dịch vụ',
                      key: 'tenHangHoaDichVu',
                      width: 80,
                    },
                    // tien hang
                    {
                      header: 'Tiền hàng',
                      key: 'tienHang',
                      width: 30,
                    },
                    // ma thue
                    {
                      header: '* Mã thuế',
                      key: 'maThue',
                      width: 20,
                    },
                    // tai khoan thue
                    {
                      header: '* Tài khoản thuế',
                      key: 'taiKhoanThue',
                      width: 40,
                    },
                    // thue
                    {
                      header: 'Thuế',
                      key: 'thue',
                      width: 20,
                    },
                    // cuc thue
                    {
                      header: '* Cục thuế',
                      key: 'cucThue',
                      width: 30,
                    },
                    // ket qua kiem tra
                    {
                      header: 'Kết quả kiểm tra',
                      key: 'ketQuaKiemTra',
                      width: 30,
                    },
                    // trang thai hoa don
                    {
                      header: 'Trạng thái hóa đơn',
                      key: 'trangThaiHoaDon',
                      width: 20,
                    },
                    // ghi chu
                    {
                      header: 'Ghi chú',
                      key: 'ghiChu',
                      width: 20,
                    },
                    // vu viec
                    {
                      header: 'Vụ việc',
                      key: 'vuViec',
                      width: 20,
                    },
                    // bo phan
                    {
                      header: 'Bộ phận',
                      key: 'boPhan',
                      width: 20,
                    },
                    // lsx
                    {
                      header: 'Lsx',
                      key: 'lsx',
                      width: 20,
                    },
                    // san pham
                    {
                      header: 'Sản phẩm',
                      key: 'sanPham',
                      width: 30,
                    },
                    // hop dong
                    {
                      header: 'Hợp đồng',
                      key: 'hopDong',
                      width: 30,
                    },
                    // phi
                    {
                      header: 'Phí',
                      key: 'phi',
                      width: 10,
                    },
                    // khe uoc
                    {
                      header: 'Khế ước',
                      key: 'kheUoc',
                      width: 20,
                    },
                  ];

                  sheet.getRow(1).height = 40;
                  sheet.getRow(1).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.getRow(1).alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  sheet.getRow(1).border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f5f5f5' },
                  };
                  sheet.getRow(1).values = columns.map((item) => item.header);

                  sheet.columns = columns.map((item) => {
                    return {
                      key: item.key,
                      width: item.width,
                    };
                  });
                  const checkResult = (e) => {
                    let result = '';
                    if (e?.checkResultBuyerAddress === false) {
                      result += 'Sai địa chỉ người mua';
                    }
                    if (e?.checkResultBuyerTaxCode === false) {
                      if (result !== '') {
                        result += ', Sai mã số thuế người mua';
                      } else {
                        result += 'Sai mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultBuyerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người mua';
                      } else {
                        result += 'Sai tên người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === false) {
                      if (result !== '') {
                        result += ', Sai địa chỉ người bán';
                      } else {
                        result += 'Sai địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultSellerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người bán';
                      } else {
                        result += 'Sai tên người bán';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Hóa đơn chưa tồn tại trên CQT';
                      } else {
                        result += 'Hóa đơn chưa tồn tại trên CQT';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Chưa cấp mã hóa đơn';
                      } else {
                        result += 'Chưa cấp mã hóa đơn';
                      }
                    }

                    if (e?.checkResultBuyerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người mua';
                      } else {
                        result += 'Không có tên người mua';
                      }
                    }
                    if (e?.checkResultBuyerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người mua';
                      } else {
                        result += 'Không có địa chỉ người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người bán';
                      } else {
                        result += 'Không có địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultBuyerTaxCode === null) {
                      if (result !== '') {
                        result += ', Không có mã số thuế người mua';
                      } else {
                        result += 'Không có mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultSellerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người bán';
                      } else {
                        result += 'Không có tên người bán';
                      }
                    }
                    if (e?.checkResultSignatureNCC === null) {
                      if (result !== '') {
                        result += ', Không có chữ ký nhà cung cấp';
                      } else {
                        result += 'Không có chữ ký nhà cung cấp';
                      }
                    }
                    if (
                      e?.checkResultBuyerAddress &&
                      e?.checkResultBuyerTaxCode &&
                      e?.checkResultBuyerName &&
                      e?.checkResultSellerName &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultSellerAddress
                    ) {
                      result += 'Hóa đơn hợp lệ';
                    }

                    return result;
                  };
                  invoices?.forEach((item, index) => {
                    sheet.addRow({
                      congTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                        ? store.getState()?.auth.account?.Organization?.OrganizationHQ
                            ?.organizationName
                        : store.getState()?.auth.account?.Organization?.organizationName ?? '',
                      chiNhanh: store.getState()?.auth.account?.Organization
                        ? store.getState()?.auth.account?.Organization?.organizationName
                        : '' ?? '',
                      phongBan:
                        store.getState()?.auth.account?.OrganizationDepartment?.departmentName ??
                        '',
                      emailNhanHoaDon: store.getState()?.auth.account?.OrganizationDepartment
                        ? store.getState()?.auth.account?.OrganizationDepartment?.invoiceMailbox
                        : store.getState()?.auth.account?.Organization?.invoiceMailbox ?? '',
                      donVi: store.getState()?.auth.account?.Organization
                        ? store.getState()?.auth.account?.Organization?.organizationName
                        : store.getState()?.auth.account?.OrganizationDepartment?.departmentName ??
                          '',
                      maNCC: item?.sellerCode ?? '',
                      tenNhaCungCap: item?.sellerName ?? '',
                      soChungTu: item?.noDocument ?? '',
                      ngayChungTu: '',
                      ngayHoaDon: item?.invoiceDate
                        ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                        : '',
                      soHoaDon: item?.invoiceNumber ?? '',
                      kyHieu: item?.serial ?? '',
                      ngayHoaDons: item?.invoiceDate
                        ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                        : '',
                      soHoaDons: item?.invoiceNumber ?? '',
                      kyHieus: item?.serial ?? '',
                      dienGiai:
                        item.dienGiai ?? item?.InvoiceProducts?.map((e) => e.name).join(',') ?? '',
                      taiKhoanCo: '',
                      taiKhoanNo: '',
                      tien: Utils.formatCurrency(item?.amountBeforeVat, '', ',') ?? '',
                      maNt: '',
                      tyGia: item?.paymentExchangeRate ?? '',
                      dienGiaiTrongPhanChiTiet:
                        item.dienGiaiTrongPhanChiTiet ??
                        item?.InvoiceProducts?.map((e) => e.name).join(',') ??
                        '',
                      maThanhToan: '',
                      mauBaoCao: '',
                      maTinhChat: '',
                      mauHoaDon: '',
                      maNhaCungCapTrongPhanThue: item?.sellerCode ?? '',
                      tenNhaCungCapTrongPhanThue: item?.sellerName ?? '',
                      diaChi: item?.sellerAddress ?? '',
                      maSoThue: item?.sellerTaxCode ?? '',
                      tenHangHoaDichVu:
                        item.tenHangHoaDichVu ??
                        item?.InvoiceProducts?.map((e) => e.name).join(',') ??
                        '',
                      tienHang: Utils.formatCurrency(item?.amountBeforeVat, '', ',') ?? '',
                      maThue: '',
                      taiKhoanThue: '',
                      thue: Utils.formatCurrency(item?.amountVat, '', ',') ?? '',
                      cucThue: '',
                      ketQuaKiemTra: item.ketQuaKiemTra ?? checkResult(item?.InvoiceValidate) ?? '',
                      trangThaiHoaDon: item?.statusInvoiceText ?? '',
                      ghiChu: item?.noteInvoice ?? '',
                      vuViec: '',
                      boPhan: '',
                      lsx: '',
                      sanPham: '',
                      hopDong: '',
                      phi: '',
                      kheUoc: '',
                    });
                  });
                  sheet?.eachRow((row, rowNumber) => {
                    if (rowNumber >= 2) {
                      row.alignment = {
                        vertical: 'top',
                        horizontal: 'left',
                        wrapText: true,
                      };
                      row.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } },
                      };
                      row.font = {
                        name: 'Times New Roman',
                        family: 4,
                        size: 11,
                        bold: false,
                      };
                    }
                  });
                }
                break;
              case '4':
                {
                  // fast - hang hoa
                  sheetFileName = 'Fast_hang_hoa_' + appendDateTime;
                  sheet = workbook.addWorksheet(sheetFileName, {
                    headerFooter: { firstHeader: sheetFileName },
                  });
                  // A -> AZ
                  const columns = [
                    // cong ty
                    {
                      header: 'Công ty',
                      key: 'congTy',
                      width: 30,
                    },
                    // chi nhanh
                    {
                      header: 'Chi nhánh',
                      key: 'chiNhanh',
                      width: 20,
                    },
                    // phong ban
                    {
                      header: 'Phòng ban',
                      key: 'phongBan',
                      width: 20,
                    },
                    // email nhan hoa don
                    {
                      header: 'Email nhận hóa đơn',
                      key: 'emailNhanHoaDon',
                      width: 30,
                    },
                    // ma ncc
                    {
                      header: '* Mã ncc',
                      key: 'maNCC',
                      width: 24,
                    },
                    // ten nha cung cap
                    {
                      header: 'Tên nhà cung cấp',
                      key: 'tenNhaCungCap',
                      width: 60,
                    },
                    // nguoi giao hang
                    {
                      header: 'Người giao hàng',
                      key: 'nguoiGiaoHang',
                      width: 30,
                    },
                    // ngay chung tu
                    {
                      header: '* Ngày chứng từ',
                      key: 'ngayChungTu',
                      width: 24,
                    },
                    // so chung tu
                    {
                      header: '* Số chứng từ',
                      key: 'soChungTu',
                      width: 24,
                    },
                    // ngay hach hoa don
                    {
                      header: 'Ngày hoá đơn',
                      key: 'ngayHoaDons',
                      width: 24,
                    },
                    // so hoa don
                    {
                      header: 'Số hoá đơn',
                      key: 'soHoaDons',
                      width: 24,
                    },
                    // ky hieu
                    {
                      header: 'Ký hiệu',
                      key: 'kyHieus',
                      width: 20,
                    },
                    // dien giai
                    {
                      header: 'Diễn giải',
                      key: 'dienGiai',
                      width: 60,
                    },
                    // ma hang
                    {
                      header: '* Mã hàng',
                      key: 'maHang',
                      width: 30,
                    },
                    // ten mat hang
                    {
                      header: 'Tên mặt hàng',
                      key: 'tenMatHang',
                      width: 40,
                    },
                    // don vi tinh
                    {
                      header: 'Đvt',
                      key: 'dvt',
                      width: 10,
                    },
                    // ma kho
                    {
                      header: '* Mã kho',
                      key: 'maKho',
                      width: 20,
                    },
                    // so luong
                    {
                      header: 'Số lượng',
                      key: 'soLuong',
                      width: 20,
                    },
                    // gia
                    {
                      header: 'Giá',
                      key: 'gia',
                      width: 24,
                    },
                    // tien hang
                    {
                      header: 'Tiền hàng',
                      key: 'tienHang',
                      width: 24,
                    },
                    // ma nt
                    {
                      header: 'Mã nt',
                      key: 'maNt',
                      width: 16,
                    },
                    // ty gia
                    {
                      header: 'Tỷ giá',
                      key: 'tyGia',
                      width: 16,
                    },

                    // tai khoan co
                    {
                      header: '* Tài khoản có',
                      key: 'taiKhoanCo',
                      width: 24,
                    },
                    // tai khoan no
                    {
                      header: 'Tài khoản nợ',
                      key: 'taiKhoanNo',
                      width: 24,
                    },
                    // ma thanh toan
                    {
                      header: '* Mã thanh toán',
                      key: 'maThanhToan',
                      width: 24,
                    },
                    // mau bao cao
                    {
                      header: '* Mẫu báo cáo',
                      key: 'mauBaoCao',
                      width: 24,
                    },
                    // ma tinh chat
                    {
                      header: '* Mã tính chất',
                      key: 'maTinhChat',
                      width: 24,
                    },
                    // mau hoa don
                    {
                      header: 'Mẫu hoá đơn',
                      key: 'mauHoaDon',
                      width: 24,
                    },
                    // so hoa don
                    {
                      header: '* Số hoá đơn',
                      key: 'soHoaDon',
                      width: 24,
                    },
                    // ky hieu
                    {
                      header: 'Ký hiệu',
                      key: 'kyHieu',
                      width: 16,
                    },
                    // ngay hoa don
                    {
                      header: '* Ngày hoá đơn',
                      key: 'ngayHoaDon',
                      width: 30,
                    },
                    // ma nha cung cap (trong phan thue)
                    {
                      header: 'Mã nhà cung cấp (Trong phần thuế)',
                      key: 'maNhaCungCapTrongPhanThue',
                      width: 40,
                    },
                    // ten nha cung cap (trong phan thue)
                    {
                      header: 'Tên nhà cung cấp (Trong phần thuế)',
                      key: 'tenNhaCungCapTrongPhanThue',
                      width: 60,
                    },
                    // dia chi
                    {
                      header: 'Địa chỉ',
                      key: 'diaChi',
                      width: 100,
                    },
                    // ma so thue
                    {
                      header: 'Mã số thuế',
                      key: 'maSoThue',
                      width: 30,
                    },
                    // tinh chat
                    {
                      header: 'Tính chất',
                      key: 'tinhchat',
                      width: 20,
                    },
                    // ma thue
                    {
                      header: '* Mã thuế',
                      key: 'maThue',
                      width: 20,
                    },
                    // tk thue
                    {
                      header: '* Tk thuế',
                      key: 'tkThue',
                      width: 40,
                    },
                    // thue
                    {
                      header: 'Thuế',
                      key: 'thue',
                      width: 20,
                    },
                    // cuc thue
                    {
                      header: '* Cục thuế',
                      key: 'cucThue',
                      width: 30,
                    },
                    // ket qua kiem tra
                    {
                      header: 'Kết quả kiểm tra',
                      key: 'ketQuaKiemTra',
                      width: 30,
                    },
                    // trang thai hoa don
                    {
                      header: 'Trạng thái hóa đơn',
                      key: 'trangThaiHoaDon',
                      width: 20,
                    },
                    // ghi chu
                    {
                      header: 'Ghi chú',
                      key: 'ghiChu',
                      width: 20,
                    },
                    // vu viec
                    {
                      header: 'Vụ việc',
                      key: 'vuViec',
                      width: 20,
                    },
                    // bo phan
                    {
                      header: 'Bộ phận',
                      key: 'boPhan',
                      width: 20,
                    },
                    // lsx
                    {
                      header: 'Lsx',
                      key: 'lsx',
                      width: 20,
                    },
                    // san pham
                    {
                      header: 'Sản phẩm',
                      key: 'sanPham',
                      width: 30,
                    },
                    // hop dong
                    {
                      header: 'Hợp đồng',
                      key: 'hopDong',
                      width: 30,
                    },
                    // phi
                    {
                      header: 'Phí',
                      key: 'phi',
                      width: 10,
                    },
                    // khe uoc
                    {
                      header: 'Khế ước',
                      key: 'kheUoc',
                      width: 20,
                    },
                    // nguoi mua hang
                    {
                      header: 'Người mua hàng',
                      key: 'nguoiMuaHang',
                      width: 30,
                    },
                  ];

                  sheet.getRow(1).height = 40;
                  sheet.getRow(1).font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  sheet.getRow(1).alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  sheet.getRow(1).border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f5f5f5' },
                  };
                  sheet.getRow(1).values = columns.map((item) => item.header);

                  sheet.columns = columns.map((item) => {
                    return {
                      key: item.key,
                      width: item.width,
                    };
                  });

                  let exportDetail = [];
                  invoices?.forEach((item) => {
                    // if (item.InvoiceProducts?.length <= 1) {
                    //   exportDetail.push(item);
                    // }
                    // if (item.InvoiceProducts?.length > 1) {
                    //   item?.InvoiceProducts.forEach((e) => {
                    //     exportDetail.push({ ...item, InvoiceProducts: [e] });
                    //   });
                    // }
                    exportDetail.push(item);
                  });
                  //check result

                  const checkResult = (e) => {
                    let result = '';
                    if (e?.checkResultBuyerAddress === false) {
                      result += 'Sai địa chỉ người mua';
                    }
                    if (e?.checkResultBuyerTaxCode === false) {
                      if (result !== '') {
                        result += ', Sai mã số thuế người mua';
                      } else {
                        result += 'Sai mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultBuyerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người mua';
                      } else {
                        result += 'Sai tên người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === false) {
                      if (result !== '') {
                        result += ', Sai địa chỉ người bán';
                      } else {
                        result += 'Sai địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultSellerName === false) {
                      if (result !== '') {
                        result += ', Sai tên người bán';
                      } else {
                        result += 'Sai tên người bán';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Hóa đơn chưa tồn tại trên CQT';
                      } else {
                        result += 'Hóa đơn chưa tồn tại trên CQT';
                      }
                    }
                    if (e?.checkResultHasInvoiceCode === false) {
                      if (result !== '') {
                        result += ', Chưa cấp mã hóa đơn';
                      } else {
                        result += 'Chưa cấp mã hóa đơn';
                      }
                    }

                    if (e?.checkResultBuyerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người mua';
                      } else {
                        result += 'Không có tên người mua';
                      }
                    }
                    if (e?.checkResultBuyerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người mua';
                      } else {
                        result += 'Không có địa chỉ người mua';
                      }
                    }
                    if (e?.checkResultSellerAddress === null) {
                      if (result !== '') {
                        result += ', Không có địa chỉ người bán';
                      } else {
                        result += 'Không có địa chỉ người bán';
                      }
                    }
                    if (e?.checkResultBuyerTaxCode === null) {
                      if (result !== '') {
                        result += ', Không có mã số thuế người mua';
                      } else {
                        result += 'Không có mã số thuế người mua';
                      }
                    }
                    if (e?.checkResultSellerName === null) {
                      if (result !== '') {
                        result += ', Không có tên người bán';
                      } else {
                        result += 'Không có tên người bán';
                      }
                    }
                    if (e?.checkResultSignatureNCC === null) {
                      if (result !== '') {
                        result += ', Không có chữ ký nhà cung cấp';
                      } else {
                        result += 'Không có chữ ký nhà cung cấp';
                      }
                    }
                    if (
                      e?.checkResultBuyerAddress &&
                      e?.checkResultBuyerTaxCode &&
                      e?.checkResultBuyerName &&
                      e?.checkResultSellerName &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultHasInvoiceCode &&
                      e?.checkResultSellerAddress
                    ) {
                      result += 'Hóa đơn hợp lệ';
                    }

                    return result;
                  };
                  exportDetail?.length > 0 &&
                    exportDetail?.forEach((item, index) => {
                      sheet.addRow({
                        congTy: store.getState()?.auth.account?.Organization?.OrganizationHQ
                          ? store.getState()?.auth.account?.Organization?.OrganizationHQ
                              ?.organizationName
                          : store.getState()?.auth.account?.Organization?.organizationName ?? '',
                        chiNhanh: store.getState()?.auth.account?.Organization
                          ? store.getState()?.auth.account?.Organization?.organizationName
                          : '' ?? '',
                        phongBan:
                          store.getState()?.auth.account?.OrganizationDepartment?.departmentName ??
                          '',
                        emailNhanHoaDon: store.getState()?.auth.account?.OrganizationDepartment
                          ? store.getState()?.auth.account?.OrganizationDepartment?.invoiceMailbox
                          : store.getState()?.auth.account?.Organization?.invoiceMailbox ?? '',
                        maNCC: item?.sellerCode ?? '',
                        tenNhaCungCap: item?.sellerName ?? '',
                        nguoiGiaoHang: '',
                        ngayChungTu: '',
                        soChungTu: item?.noDocument ?? '',
                        ngayHoaDons: item?.invoiceDate
                          ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                          : '',
                        soHoaDons: item?.invoiceNumber ?? '',
                        kyHieus: item?.serial ?? '',
                        ngayHoaDon: item?.invoiceDate
                          ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                          : '',
                        soHoaDon: item?.invoiceNumber ?? '',
                        kyHieu: item?.serial ?? '',
                        dienGiai: item.dienGiai ?? item?.InvoiceProducts?.[0]?.name ?? '',
                        maHang: item.maHang ?? item?.InvoiceProducts?.[0]?.code ?? '',
                        tenMatHang: item.tenMatHang ?? item?.InvoiceProducts?.[0]?.name ?? '',
                        dvt: item.dvt ?? item?.InvoiceProducts?.[0]?.unit ?? '',
                        tinhchat: item.tinhchat ?? item?.InvoiceProducts?.[0]?.productType ?? '',
                        maKho: item.maKho ?? item?.InvoiceProducts?.[0]?.code ?? '',
                        soLuong: item.soLuong ?? item?.InvoiceProducts?.[0]?.quantity ?? '',
                        gia:
                          item.gia ??
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.price, '', ',') ??
                          '',
                        tienHang:
                          item.tienHang ??
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.amountTotal, '', ',') ??
                          '',
                        maNt: '',
                        tyGia: item?.paymentExchangeRate ?? '',
                        taiKhoanCo: '',
                        taiKhoanNo: '',
                        maThanhToan: '',
                        mauBaoCao: '',
                        maTinhChat: '',
                        mauHoaDon: '',
                        maNhaCungCapTrongPhanThue: item?.sellerCode ?? '',
                        tenNhaCungCapTrongPhanThue: item?.sellerName ?? '',
                        diaChi: item?.sellerAddress ?? '',
                        maSoThue: item?.sellerTaxCode ?? '',
                        tenHangHoaDichVu:
                          item.tenHangHoaDichVu ?? item?.InvoiceProducts?.[0]?.name ?? '',

                        maThue: '',
                        tkThue: '',
                        thue:
                          item.thue ??
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.vatAmount, '', ',') ??
                          '',
                        cucThue: '',
                        ketQuaKiemTra:
                          item.ketQuaKiemTra ?? checkResult(item?.InvoiceValidate) ?? '',
                        trangThaiHoaDon: item?.statusInvoiceText ?? '',
                        ghiChu: item?.noteInvoice ?? '',
                        vuViec: '',
                        soLuong: item.soLuong ?? item?.InvoiceProducts?.[0]?.quantity ?? '',
                        donViTinh: item.donViTinh ?? item?.InvoiceProducts?.[0]?.unit ?? '',
                        donGia:
                          item.donGia ??
                          Utils.formatCurrency(item?.InvoiceProducts?.[0]?.price, '', ',') ??
                          '',
                        boPhan: '',
                        lsx: '',
                        sanPham: '',
                        hopDong: '',
                        phi: '',
                        kheUoc: '',
                        nguoiMuaHang: item?.buyerPersonName ?? '',
                      });
                    });
                  sheet?.eachRow((row, rowNumber) => {
                    if (rowNumber >= 2) {
                      row.alignment = {
                        vertical: 'top',
                        horizontal: 'left',
                        wrapText: true,
                      };
                      row.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } },
                      };
                      row.font = {
                        name: 'Times New Roman',
                        family: 4,
                        size: 11,
                        bold: false,
                      };
                    }
                  });
                }
                break;
              default:
                break;
            }
          }
        } else if (exportType === 'OUT') {
          // xuat khau hoa don dau ra
          switch (exportListType) {
            case '1':
              // misa amis ke toan - hang hoa
              {
                sheetFileName = 'MISA_AMIS_Ke_toan_Hang_hoa_' + appendDateTime;
                sheet = workbook.addWorksheet(sheetFileName, {
                  headerFooter: { firstHeader: sheetFileName },
                });
                sheet.mergeCells('AA7', 'BD7');
                sheet.getCell('AA7').value = 'Chi tiết hàng tiền';
                sheet.getCell('AA7').fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '85E6C5' },
                };
                sheet.getCell('AA7').border = {
                  top: { style: 'thin', color: { argb: '000000' } },
                  left: { style: 'thin', color: { argb: '000000' } },
                  bottom: { style: 'thin', color: { argb: '000000' } },
                  right: { style: 'thin', color: { argb: '000000' } },
                };
                sheet.mergeCells('BE7', 'BK7');
                sheet.getCell('BE7').value = 'Chi tiết giá vốn';
                sheet.getCell('BE7').fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '8BE8E5' },
                };
                sheet.getCell('BE7').border = {
                  top: { style: 'thin', color: { argb: '000000' } },
                  left: { style: 'thin', color: { argb: '000000' } },
                  bottom: { style: 'thin', color: { argb: '000000' } },
                  right: { style: 'thin', color: { argb: '000000' } },
                };
                // A -> BK
                const columns = [
                  {
                    header: 'Hình thức bán hàng',
                    key: 'hinhThucBanHang',
                    width: 20,
                  },
                  {
                    header: 'Phương thức thanh toán',
                    key: 'phuongThucThanhToan',
                    width: 20,
                  },
                  {
                    header: 'Kiêm phiếu xuất kho',
                    key: 'kiemPhieuXuatKho',
                    width: 20,
                  },
                  {
                    header: 'Lập kèm hóa đơn',
                    key: 'lapKemHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Đã lập hoá đơn',
                    key: 'maNCC',
                    width: 16,
                  },
                  {
                    header: 'Ngày hạch toán (*)',
                    key: 'ngayHachToan',
                    width: 20,
                  },
                  {
                    header: 'Ngày chứng từ (*)',
                    key: 'ngayChungTu',
                    width: 20,
                  },
                  {
                    header: 'Số chứng từ (*)',
                    key: 'soChungTu',
                    width: 20,
                  },
                  {
                    header: 'Số phiếu xuất',
                    key: 'soPhieuXuat',
                    width: 20,
                  },
                  {
                    header: 'Mẫu số HĐ',
                    key: 'mauSoHD',
                    width: 20,
                  },
                  {
                    header: 'Ký hiệu HĐ',
                    key: 'kyHieuHD',
                    width: 20,
                  },
                  {
                    header: 'Số hoá đơn',
                    key: 'soHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Ngày hoá đơn',
                    key: 'ngayHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Mã khách hàng',
                    key: 'maKhachHang',
                    width: 20,
                  },
                  {
                    header: 'Tên khách hàng',
                    key: 'tenKhachHang',
                    width: 40,
                  },
                  {
                    header: 'Địa chỉ',
                    key: 'diaChi',
                    width: 60,
                  },
                  {
                    header: 'Mã số thuế',
                    key: 'maSoThue',
                    width: 20,
                  },
                  {
                    header: 'Đơn vị giao đại lý',
                    key: 'donViGiaoDaiLy',
                    width: 30,
                  },
                  {
                    header: 'Người nộp',
                    key: 'nguoiNop',
                    width: 20,
                  },
                  {
                    header: 'Nộp vào TK',
                    key: 'nopVaoTK',
                    width: 20,
                  },
                  {
                    header: 'Diễn giải/Lý do nộp',
                    key: 'dienGiai',
                    width: 30,
                  },
                  {
                    header: 'Lý do xuất',
                    key: 'lyDoXuat',
                    width: 20,
                  },
                  {
                    header: 'Mã nhân viên bán hàng',
                    key: 'maNhanVienBanHang',
                    width: 30,
                  },
                  {
                    header: 'Số chứng từ kèm theo (Phiếu thu)',
                    key: 'soChungTuKemTheoPhieuThu',
                    width: 30,
                  },
                  {
                    header: 'Số chứng từ kèm theo (Phiếu xuất)',
                    key: 'soChungTuKemTheoPhieuXuat',
                    width: 30,
                  },
                  {
                    header: 'Hạn thanh toán',
                    key: 'hanThanhToan',
                    width: 20,
                  },
                  {
                    header: 'Mã hàng (*)',
                    key: 'maHang',
                    width: 20,
                  },
                  {
                    header: 'Tên hàng',
                    key: 'tenHang',
                    width: 40,
                  },
                  {
                    header: 'Là dòng ghi chú',
                    key: 'laDongGhiChu',
                    width: 20,
                  },
                  {
                    header: 'Hàng khuyến mại',
                    key: 'hangKhuyenMai',
                    width: 20,
                  },
                  {
                    header: 'TK Tiền/Chi phí/Nợ (*)',
                    key: 'tkTienChiPhiNo',
                    width: 20,
                  },
                  {
                    header: 'TK Doanh thu/Co (*)',
                    key: 'tkDoanhThuCo',
                    width: 20,
                  },
                  {
                    header: 'ĐVT',
                    key: 'dvt',
                    width: 10,
                  },
                  {
                    header: 'Số lượng',
                    key: 'soLuong',
                    width: 10,
                  },
                  {
                    header: 'Đơn giá',
                    key: 'donGia',
                    width: 10,
                  },
                  {
                    header: 'Thành tiền',
                    key: 'thanhTien',
                    width: 20,
                  },
                  {
                    header: 'Tỷ lệ CK (%)',
                    key: 'tyLeCKPhanTram',
                    width: 20,
                  },
                  {
                    header: 'Tiền chiết khấu',
                    key: 'tienChietKhau',
                    width: 20,
                  },
                  {
                    header: 'TK chiết khấu',
                    key: 'tkChietKhau',
                    width: 20,
                  },
                  {
                    header: 'Giá tính thuế XK',
                    key: 'giaTinhThueXK',
                    width: 30,
                  },
                  {
                    header: '% thuế xuất khẩu',
                    key: 'phanTramThueXuatKhau',
                    width: 30,
                  },
                  {
                    header: 'Tiền thuế xuất khẩu',
                    key: 'tienThueXuatKhau',
                    width: 30,
                  },
                  {
                    header: 'TK thuế xuất khẩu',
                    key: 'tkThueXuatKhau',
                    width: 30,
                  },
                  {
                    header: '% thuế GTGT',
                    key: 'phanTramThueGTGT',
                    width: 20,
                  },
                  {
                    header: '% thuế suất KHÁC',
                    key: 'phanTramThueSuatKhac',
                    width: 20,
                  },
                  {
                    header: 'Tiền thuế GTGT',
                    key: 'tienThueGTGT',
                    width: 30,
                  },
                  {
                    header: 'TK thuế GTGT',
                    key: 'tkThueGTGT',
                    width: 20,
                  },
                  {
                    header: 'HH không TH trên tờ khai thuế GTGT',
                    key: 'hhKhongThTrenToKhaiThueGTGT',
                    width: 30,
                  },
                  {
                    header: 'Mã khoản mục chi phí',
                    key: 'maKhoanMucChiPhi',
                    width: 30,
                  },
                  {
                    header: 'Mã đơn vị',
                    key: 'maDonVi',
                    width: 20,
                  },
                  {
                    header: 'Mã đối tượng THCP',
                    key: 'maDoiTuongTHCP',
                    width: 20,
                  },
                  {
                    header: 'Mã công trình',
                    key: 'maCongTrinh',
                    width: 20,
                  },
                  {
                    header: 'Số đơn đặt hàng',
                    key: 'soDonDatHang',
                    width: 20,
                  },
                  {
                    header: 'Số hợp đồng bán',
                    key: 'soHopDongBan',
                    width: 20,
                  },
                  {
                    header: 'Mã thống kê',
                    key: 'maThongKe',
                    width: 20,
                  },
                  {
                    header: 'CP không hợp lý',
                    key: 'cpKhongHopLy',
                    width: 20,
                  },
                  {
                    header: 'Mã kho',
                    key: 'maKho',
                    width: 20,
                  },
                  {
                    header: 'TK giá vốn',
                    key: 'tkGiaVon',
                    width: 20,
                  },
                  {
                    header: 'TK Kho',
                    key: 'tkKho',
                    width: 20,
                  },
                  {
                    header: 'Đơn giá vốn',
                    key: 'donGiaVon',
                    width: 20,
                  },
                  {
                    header: 'Tiền vốn',
                    key: 'tienVon',
                    width: 20,
                  },
                  {
                    header: 'Hàng hóa giữ hộ/bán hộ',
                    key: 'hangHoaGiuHoBanHo',
                    width: 20,
                  },
                  {
                    header: 'Không cập nhật giá xuất',
                    key: 'khongCapNhatGiaXuat',
                    width: 20,
                  },
                ];
                const rows = sheet.getRows(7, 2);
                rows.forEach((row) => {
                  row.font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  row.alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  row.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  // row.fill = {
                  //   type: 'pattern',
                  //   pattern: 'solid',
                  //   fgColor: { argb: 'f5f5f5' },
                  // };
                });
                sheet.getRow(8).values = columns.map((item) => item.header);

                sheet.columns = columns.map((item) => {
                  return {
                    key: item.key,
                    width: item.width,
                  };
                });
                let exportDetail = [];
                invoices?.forEach((item) => {
                  // if (item.InvoiceProducts?.length <= 1) {
                  //   exportDetail.push(item);
                  // }
                  // if (item.InvoiceProducts?.length > 1) {
                  //   item?.InvoiceProducts.forEach((e) => {
                  //     exportDetail.push({ ...item, InvoiceProducts: [e] });
                  //   });
                  // }
                  exportDetail.push(item);
                });
                exportDetail?.length > 0 &&
                  exportDetail?.forEach((item, index) => {
                    sheet.addRow({
                      hinhThucBanHang: '',
                      phuongThucThanhToan: item?.paymentMethod ?? '',
                      kiemPhieuXuatKho: '',
                      lapKemHoaDon: '',
                      maNCC: item?.sellerCode ?? '',
                      ngayHachToan: item?.accountingDate
                        ? Utils.formatDateTime(item?.accountingDate, 'DD/MM/YYYY')
                        : 'Chưa hạch toán',
                      ngayChungTu: '',
                      soChungTu: item?.noDocument ?? '',
                      soPhieuXuat: '',
                      mauSoHD: item?.invoiceTypeCode ?? '',
                      kyHieuHD: item?.serial ?? '',
                      soHoaDon: item?.invoiceNumber ?? '',
                      ngayHoaDon: item?.invoiceDate
                        ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                        : '',
                      maKhachHang: item?.buyerCode ?? '',
                      tenKhachHang: item?.buyerName ?? '',
                      diaChi: item?.buyerAddress ?? '',
                      maSoThue: item?.buyerTaxCode ?? '',
                      donViGiaoDaiLy: '',
                      nguoiNop: '',
                      nopVaoTK: '',
                      dienGiai: item.dienGiai ?? item?.InvoiceProducts?.[0]?.name ?? '',
                      lyDoXuat: '',
                      maNhanVienBanHang: '',
                      soChungTuKemTheoPhieuThu: '',
                      soChungTuKemTheoPhieuXuat: '',
                      hanThanhToan: item?.expiredPaymentDate ?? '',
                      maHang: item.maHang ?? item?.InvoiceProducts?.[0]?.code ?? '',
                      tenHang: item.tenHang ?? item?.InvoiceProducts?.[0]?.name ?? '',
                      laDongGhiChu: '',
                      hangKhuyenMai: 'Không',
                      tkTienChiPhiNo: '',
                      tkDoanhThuCo: '',
                      dvt: item.dvt ?? item?.InvoiceProducts?.[0]?.unit ?? '',
                      soLuong: item.soLuong ?? item?.InvoiceProducts?.[0]?.quantity ?? '',
                      donGia:
                        item.donGia ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.amountTotal, '', ',') ??
                        '',
                      thanhTien:
                        item.thanhTien ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.finalAmount, '', ',') ??
                        '',
                      tyLeCKPhanTram:
                        item.tyLeCKPhanTram ?? item?.InvoiceProducts?.[0]?.discount ?? '',
                      tienChietKhau:
                        item.tienChietKhau ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.discountAmount, '', ',') ??
                        '',
                      tkChietKhau: '',
                      giaTinhThueXK: '',
                      phanTramThueXuatKhau: '',
                      tienThueXuatKhau: '',
                      tkThueXuatKhau: '',
                      phanTramThueGTGT:
                        item.phanTramThueGTGT ?? item?.InvoiceProducts?.[0]?.vat ?? '',
                      phanTramThueSuatKhac: '',
                      tienThueGTGT:
                        item.tienThueGTGT ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.vatAmount, '', ',') ??
                        '',
                      tkThueGTGT: '',
                      hhKhongThTrenToKhaiThueGTGT: '',
                      maKhoanMucChiPhi: '',
                      maDonVi: '',
                      maDoiTuongTHCP: '',
                      maCongTrinh: '',
                      soDonDatHang: '',
                      soHopDongBan: '',
                      maThongKe: '',
                      cpKhongHopLy: '',
                      maKho: '',
                      tkGiaVon: '',
                      tkKho: '',
                      donGiaVon: '',
                      tienVon: '',
                      hangHoaGiuHoBanHo: '',
                      khongCapNhatGiaXuat: '',
                    });
                  });
                sheet?.eachRow((row, rowNumber) => {
                  if (rowNumber >= 9) {
                    row.alignment = {
                      vertical: 'top',
                      horizontal: 'left',
                      wrapText: true,
                    };
                    row.border = {
                      top: { style: 'thin', color: { argb: '000000' } },
                      left: { style: 'thin', color: { argb: '000000' } },
                      bottom: { style: 'thin', color: { argb: '000000' } },
                      right: { style: 'thin', color: { argb: '000000' } },
                    };
                    row.font = {
                      name: 'Times New Roman',
                      family: 4,
                      size: 11,
                      bold: false,
                    };
                  }
                });
              }
              break;
            case '2':
              // misa amis ke toan - dich vu
              {
                sheetFileName = 'MISA_AMIS_Ke_toan_Dich_Vu_' + appendDateTime;
                sheet = workbook.addWorksheet(sheetFileName, {
                  headerFooter: { firstHeader: sheetFileName },
                });
                sheet.mergeCells('V7', 'AU7');
                sheet.getCell('V7').value = 'Chi tiết hàng tiền';
                sheet.getCell('V7').fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '85E6C5' },
                };
                sheet.getCell('V7').border = {
                  top: { style: 'thin', color: { argb: '000000' } },
                  left: { style: 'thin', color: { argb: '000000' } },
                  bottom: { style: 'thin', color: { argb: '000000' } },
                  right: { style: 'thin', color: { argb: '000000' } },
                };

                // A -> AU
                const columns = [
                  {
                    header: 'Hình thức bán hàng',
                    key: 'hinhThucBanHang',
                    width: 20,
                  },
                  {
                    header: 'Lập kèm hóa đơn',
                    key: 'lapKemHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Đã lập hoá đơn',
                    key: 'daLapHoadon',
                    width: 16,
                  },
                  {
                    header: 'Ngày hạch toán (*)',
                    key: 'ngayHachToan',
                    width: 20,
                  },
                  {
                    header: 'Ngày chứng từ (*)',
                    key: 'ngayChungTu',
                    width: 20,
                  },
                  {
                    header: 'Số chứng từ (*)',
                    key: 'soChungTu',
                    width: 20,
                  },
                  {
                    header: 'Mẫu số HĐ',
                    key: 'mauSoHD',
                    width: 20,
                  },
                  {
                    header: 'Ký hiệu HĐ',
                    key: 'kyHieuHD',
                    width: 20,
                  },
                  {
                    header: 'Số hoá đơn',
                    key: 'soHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Ngày hoá đơn',
                    key: 'ngayHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Mã khách hàng',
                    key: 'maKhachHang',
                    width: 20,
                  },
                  {
                    header: 'Tên khách hàng',
                    key: 'tenKhachHang',
                    width: 40,
                  },
                  {
                    header: 'Địa chỉ',
                    key: 'diaChi',
                    width: 60,
                  },
                  {
                    header: 'Mã số thuế',
                    key: 'maSoThue',
                    width: 20,
                  },
                  {
                    header: 'Người nộp',
                    key: 'nguoiNop',
                    width: 20,
                  },
                  {
                    header: 'Nộp vào TK',
                    key: 'nopVaoTK',
                    width: 20,
                  },
                  {
                    header: 'Diễn giải/Lý do nộp',
                    key: 'dienGiai',
                    width: 30,
                  },
                  {
                    header: 'Lý do xuất',
                    key: 'lyDoXuat',
                    width: 20,
                  },
                  {
                    header: 'Mã nhân viên bán hàng',
                    key: 'maNhanVienBanHang',
                    width: 30,
                  },
                  {
                    header: 'Hạn thanh toán',
                    key: 'hanThanhToan',
                    width: 20,
                  },
                  {
                    header: 'Số chứng từ kèm theo (Phiếu thu)',
                    key: 'soChungTuKemTheoPhieuThu',
                    width: 30,
                  },
                  {
                    header: 'Mã dịch vụ (*)',
                    key: 'maDichVu',
                    width: 20,
                  },
                  {
                    header: 'Tên dịch vụ',
                    key: 'tenDichVu',
                    width: 40,
                  },
                  {
                    header: 'Là dòng ghi chú',
                    key: 'laDongGhiChu',
                    width: 20,
                  },
                  {
                    header: 'Hàng khuyến mại',
                    key: 'hangKhuyenMai',
                    width: 20,
                  },
                  {
                    header: 'TK Tiền/Chi phí/Nợ (*)',
                    key: 'tkTienChiPhiNo',
                    width: 20,
                  },
                  {
                    header: 'TK Doanh thu/Co (*)',
                    key: 'tkDoanhThuCo',
                    width: 20,
                  },
                  {
                    header: 'ĐVT',
                    key: 'dvt',
                    width: 10,
                  },
                  {
                    header: 'Số lượng',
                    key: 'soLuong',
                    width: 10,
                  },
                  {
                    header: 'Đơn giá',
                    key: 'donGia',
                    width: 10,
                  },
                  {
                    header: 'Thành tiền',
                    key: 'thanhTien',
                    width: 20,
                  },
                  {
                    header: 'Tỷ lệ CK (%)',
                    key: 'tyLeCKPhanTram',
                    width: 20,
                  },
                  {
                    header: 'Tiền chiết khấu',
                    key: 'tienChietKhau',
                    width: 20,
                  },
                  {
                    header: 'TK chiết khấu',
                    key: 'tkChietKhau',
                    width: 20,
                  },
                  {
                    header: '% thuế GTGT',
                    key: 'phanTramThueGTGT',
                    width: 20,
                  },
                  {
                    header: '% thuế suất KHÁC',
                    key: 'phanTramThueSuatKhac',
                    width: 20,
                  },
                  {
                    header: 'Tiền thuế GTGT',
                    key: 'tienThueGTGT',
                    width: 30,
                  },
                  {
                    header: 'TK thuế GTGT',
                    key: 'tkThueGTGT',
                    width: 20,
                  },
                  {
                    header: 'HH không TH trên tờ khai thuế GTGT',
                    key: 'hhKhongThTrenToKhaiThueGTGT',
                    width: 30,
                  },
                  {
                    header: 'Mã khoản mục chi phí',
                    key: 'maKhoanMucChiPhi',
                    width: 30,
                  },
                  {
                    header: 'Mã đơn vị',
                    key: 'maDonVi',
                    width: 20,
                  },
                  {
                    header: 'Mã đối tượng THCP',
                    key: 'maDoiTuongTHCP',
                    width: 20,
                  },
                  {
                    header: 'Mã công trình',
                    key: 'maCongTrinh',
                    width: 20,
                  },
                  {
                    header: 'Số đơn đặt hàng',
                    key: 'soDonDatHang',
                    width: 20,
                  },
                  {
                    header: 'Số hợp đồng bán',
                    key: 'soHopDongBan',
                    width: 20,
                  },
                  {
                    header: 'Mã thống kê',
                    key: 'maThongKe',
                    width: 20,
                  },
                  {
                    header: 'CP không hợp lý',
                    key: 'cpKhongHopLy',
                    width: 20,
                  },
                ];
                const rows = sheet.getRows(7, 2);
                rows.forEach((row) => {
                  row.font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  row.alignment = {
                    vertical: 'top',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  row.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  // row.fill = {
                  //   type: 'pattern',
                  //   pattern: 'solid',
                  //   fgColor: { argb: 'f5f5f5' },
                  // };
                });
                sheet.getRow(8).values = columns.map((item) => item.header);

                sheet.columns = columns.map((item) => {
                  return {
                    key: item.key,
                    width: item.width,
                  };
                });
                let exportDetail = [];
                invoices?.forEach((item) => {
                  // if (item.InvoiceProducts?.length <= 1) {
                  //   exportDetail.push(item);
                  // }
                  // if (item.InvoiceProducts?.length > 1) {
                  //   item?.InvoiceProducts.forEach((e) => {
                  //     exportDetail.push({ ...item, InvoiceProducts: [e] });
                  //   });
                  // }
                  exportDetail.push(item);
                });
                exportDetail?.length > 0 &&
                  exportDetail?.forEach((item, index) => {
                    sheet.addRow({
                      hinhThucBanHang: '',
                      lapKemHoaDon: '',
                      daLapHoadon: '',
                      ngayHachToan: item?.accountingDate
                        ? Utils.formatDateTime(item?.accountingDate, 'DD/MM/YYYY')
                        : 'Chưa hạch toán',
                      ngayChungTu: '',
                      soChungTu: item?.noDocument ?? '',
                      mauSoHD: item?.invoiceTypeCode ?? '',
                      kyHieuHD: item?.serial ?? '',
                      soHoaDon: item?.invoiceNumber ?? '',
                      ngayHoaDon: item?.invoiceDate
                        ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                        : '',
                      maKhachHang: item?.buyerCode ?? '',
                      tenKhachHang: item?.buyerName ?? '',
                      diaChi: item?.buyerAddress ?? '',
                      maSoThue: item?.buyerTaxCode ?? '',
                      nguoiNop: '',
                      nopVaoTK: '',
                      dienGiai: item.dienGiai ?? item?.InvoiceProducts?.[0]?.name ?? '',
                      lyDoXuat: '',
                      maNhanVienBanHang: '',
                      soChungTuKemTheoPhieuThu: '',
                      hanThanhToan: item?.expiredPaymentDate ?? '',
                      maDichVu: item.maDichVu ?? item?.InvoiceProducts?.[0]?.code ?? '',
                      tenDichVu: item.tenDichVu ?? item?.InvoiceProducts?.[0]?.name ?? '',
                      laDongGhiChu: '',
                      hangKhuyenMai: 'Không',
                      tkTienChiPhiNo: '',
                      tkDoanhThuCo: '',
                      dvt: item.dvt ?? item?.InvoiceProducts?.[0]?.unit ?? '',
                      soLuong: item.soLuong ?? item?.InvoiceProducts?.[0]?.quantity ?? '',
                      donGia:
                        item.donGia ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.amountTotal, '', ',') ??
                        '',
                      thanhTien:
                        item.thanhTien ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.finalAmount, '', ',') ??
                        '',
                      tyLeCKPhanTram:
                        item?.tyLeCKPhanTram ?? item?.InvoiceProducts?.[0]?.discount ?? '',
                      tienChietKhau:
                        item?.tienChietKhau ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.discountAmount, '', ',') ??
                        '',
                      tkChietKhau: '',
                      giaTinhThueXK: '',
                      phanTramThueGTGT:
                        item.phanTramThueGTGT ?? item?.InvoiceProducts?.[0]?.vat ?? '',
                      phanTramThueSuatKhac: '',
                      tienThueGTGT:
                        item.tienThueGTGT ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.vatAmount, '', ',') ??
                        '',
                      tkThueGTGT: '',
                      hhKhongThTrenToKhaiThueGTGT: '',
                      maKhoanMucChiPhi: '',
                      maDonVi: '',
                      maDoiTuongTHCP: '',
                      maCongTrinh: '',
                      soDonDatHang: '',
                      soHopDongBan: '',
                      maThongKe: '',
                      cpKhongHopLy: '',
                    });
                  });
                sheet?.eachRow((row, rowNumber) => {
                  if (rowNumber >= 9) {
                    row.alignment = {
                      vertical: 'top',
                      horizontal: 'left',
                      wrapText: true,
                    };
                    row.border = {
                      top: { style: 'thin', color: { argb: '000000' } },
                      left: { style: 'thin', color: { argb: '000000' } },
                      bottom: { style: 'thin', color: { argb: '000000' } },
                      right: { style: 'thin', color: { argb: '000000' } },
                    };
                    row.font = {
                      name: 'Times New Roman',
                      family: 4,
                      size: 11,
                      bold: false,
                    };
                  }
                });
              }

              break;
            case '3':
              // misa amis ke toan - danh sach hoa don
              {
                sheetFileName = 'MISA_AMIS_Ke_toan_Danh_Sach_Hoa_Don_' + appendDateTime;
                sheet = workbook.addWorksheet(sheetFileName, {
                  headerFooter: { firstHeader: sheetFileName },
                });
                sheet.mergeCells('P7', 'AC7');
                sheet.getCell('P7').value = 'Chi tiết hàng tiền';
                sheet.getCell('P7').fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '85E6C5' },
                };
                sheet.getCell('P7').border = {
                  top: { style: 'thin', color: { argb: '000000' } },
                  left: { style: 'thin', color: { argb: '000000' } },
                  bottom: { style: 'thin', color: { argb: '000000' } },
                  right: { style: 'thin', color: { argb: '000000' } },
                };

                // A -> AU
                const columns = [
                  {
                    header: 'Số thứ tự hóa đơn (*)',
                    key: 'stt',
                    width: 20,
                  },
                  {
                    header: 'Hình thức bán hàng',
                    key: 'hinhThucBanHang',
                    width: 20,
                  },

                  {
                    header: 'Mẫu số HĐ',
                    key: 'mauSoHD',
                    width: 20,
                  },
                  {
                    header: 'Ký hiệu HĐ',
                    key: 'kyHieuHD',
                    width: 20,
                  },
                  {
                    header: 'Số hoá đơn',
                    key: 'soHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Ngày hoá đơn (*)',
                    key: 'ngayHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Mã khách hàng',
                    key: 'maKhachHang',
                    width: 20,
                  },
                  {
                    header: 'Tên khách hàng',
                    key: 'tenKhachHang',
                    width: 40,
                  },
                  {
                    header: 'Địa chỉ',
                    key: 'diaChi',
                    width: 60,
                  },
                  {
                    header: 'Mã số thuế',
                    key: 'maSoThue',
                    width: 20,
                  },
                  {
                    header: 'Tài khoản ngân hàng',
                    key: 'tkNganHang',
                    width: 20,
                  },
                  {
                    header: 'Hình thức thanh toán',
                    key: 'hinhThucThanhToan',
                    width: 20,
                  },
                  {
                    header: 'Người mua hàng',
                    key: 'nguoiMuaHang',
                    width: 30,
                  },
                  {
                    header: 'Mã nhân viên bán hàng',
                    key: 'maNhanVienBanHang',
                    width: 30,
                  },
                  {
                    header: 'Tình trạng hạch toán',
                    key: 'tinhTrangHachToan',
                    width: 20,
                  },
                  {
                    header: 'Mã Hàng (*)',
                    key: 'maHang',
                    width: 20,
                  },
                  {
                    header: 'Tên Hàng',
                    key: 'tenHang',
                    width: 40,
                  },
                  {
                    header: 'Là dòng ghi chú',
                    key: 'laDongGhiChu',
                    width: 20,
                  },
                  {
                    header: 'Hàng khuyến mại',
                    key: 'hangKhuyenMai',
                    width: 20,
                  },
                  {
                    header: 'ĐVT',
                    key: 'dvt',
                    width: 10,
                  },
                  {
                    header: 'Số lượng',
                    key: 'soLuong',
                    width: 10,
                  },
                  {
                    header: 'Đơn giá',
                    key: 'donGia',
                    width: 10,
                  },
                  {
                    header: 'Thành tiền',
                    key: 'thanhTien',
                    width: 20,
                  },
                  {
                    header: 'Tỷ lệ CK (%)',
                    key: 'tyLeCKPhanTram',
                    width: 20,
                  },
                  {
                    header: 'Tiền chiết khấu',
                    key: 'tienChietKhau',
                    width: 20,
                  },
                  {
                    header: '% thuế GTGT',
                    key: 'phanTramThueGTGT',
                    width: 20,
                  },
                  {
                    header: '% thuế suất KHÁC',
                    key: 'phanTramThueSuatKhac',
                    width: 20,
                  },
                  {
                    header: 'Tiền thuế GTGT',
                    key: 'tienThueGTGT',
                    width: 30,
                  },
                  {
                    header: 'HH không TH trên tờ khai thuế GTGT',
                    key: 'hhKhongThTrenToKhaiThueGTGT',
                    width: 30,
                  },
                ];
                const rows = sheet.getRows(7, 2);
                rows.forEach((row) => {
                  row.font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  row.alignment = {
                    vertical: 'top',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  row.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  // row.fill = {
                  //   type: 'pattern',
                  //   pattern: 'solid',
                  //   fgColor: { argb: 'f5f5f5' },
                  // };
                });
                sheet.getRow(8).values = columns.map((item) => item.header);

                sheet.columns = columns.map((item) => {
                  return {
                    key: item.key,
                    width: item.width,
                  };
                });
                let exportDetail = [];
                invoices?.forEach((item) => {
                  // if (item.InvoiceProducts?.length <= 1) {
                  //   exportDetail.push(item);
                  // }
                  // if (item.InvoiceProducts?.length > 1) {
                  //   item?.InvoiceProducts.forEach((e) => {
                  //     exportDetail.push({ ...item, InvoiceProducts: [e] });
                  //   });
                  // }
                  exportDetail.push(item);
                });
                exportDetail?.length > 0 &&
                  exportDetail?.forEach((item, index) => {
                    sheet.addRow({
                      stt: `${index + 1}`,
                      hinhThucBanHang: '',
                      mauSoHD: item?.invoiceTypeCode ?? '',
                      kyHieuHD: item?.serial ?? '',
                      soHoaDon: item?.invoiceNumber ?? '',
                      ngayHoaDon: item?.invoiceDate
                        ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                        : '',
                      maKhachHang: item?.buyerCode ?? '',
                      tenKhachHang: item?.buyerName ?? '',
                      diaChi: item?.buyerAddress ?? '',
                      maSoThue: item?.buyerTaxCode ?? '',
                      tkNganHang: item?.buyerBankAccount ?? '',
                      hinhThucThanhToan: item?.paymentMethod ?? '',
                      nguoiMuaHang: '',
                      maNhanVienBanHang: '',
                      tinhTrangHachToan: '',
                      maHang: item.maHang ?? item?.InvoiceProducts?.[0]?.code ?? '',
                      tenHang: item.tenHang ?? item?.InvoiceProducts?.[0]?.name ?? '',
                      laDongGhiChu: '',
                      hangKhuyenMai: 'Không',
                      dvt: item.dvt ?? item?.InvoiceProducts?.[0]?.unit ?? '',
                      soLuong: item.soLuong ?? item?.InvoiceProducts?.[0]?.quantity ?? '',
                      donGia:
                        item.donGia ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.amountTotal, '', ',') ??
                        '',
                      thanhTien:
                        item.thanhTien ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.finalAmount, '', ',') ??
                        '',
                      tyLeCKPhanTram:
                        item.tyLeCKPhanTram ?? item?.InvoiceProducts?.[0]?.discount ?? '',
                      tienChietKhau:
                        item.tienChietKhau ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.discountAmount, '', ',') ??
                        '',
                      phanTramThueGTGT:
                        item.phanTramThueGTGT ?? item?.InvoiceProducts?.[0]?.vat ?? '',
                      phanTramThueSuatKhac: '',
                      tienThueGTGT:
                        item.tienThueGTGT ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.vatAmount, '', ',') ??
                        '',
                      tkThueGTGT: '',
                      hhKhongThTrenToKhaiThueGTGT: '',
                    });
                  });
                sheet?.eachRow((row, rowNumber) => {
                  if (rowNumber >= 9) {
                    row.alignment = {
                      vertical: 'top',
                      horizontal: 'left',
                      wrapText: true,
                    };
                    row.border = {
                      top: { style: 'thin', color: { argb: '000000' } },
                      left: { style: 'thin', color: { argb: '000000' } },
                      bottom: { style: 'thin', color: { argb: '000000' } },
                      right: { style: 'thin', color: { argb: '000000' } },
                    };
                    row.font = {
                      name: 'Times New Roman',
                      family: 4,
                      size: 11,
                      bold: false,
                    };
                  }
                });
              }
              break;
            case '4':
              // misa sme - hoa don ban hang
              {
                sheetFileName = 'MISA_SME_Hoa_Don_Ban_Hang_' + appendDateTime;
                sheet = workbook.addWorksheet(sheetFileName, {
                  headerFooter: { firstHeader: sheetFileName },
                });

                // A -> AU
                const columns = [
                  {
                    header: 'STT hóa đơn',
                    key: 'stt',
                    width: 20,
                  },
                  {
                    header: 'Hiển thị trên sổ',
                    key: 'hienThiTrenSo',
                    width: 20,
                  },
                  {
                    header: 'Hình thức bán hàng',
                    key: 'hinhThucBanHang',
                    width: 20,
                  },
                  {
                    header: 'Là hóa đơn giảm thuế NQ406',
                    key: 'laHoadonGiamThue',
                    width: 20,
                  },
                  {
                    header: 'Mẫu số HĐ',
                    key: 'mauSoHD',
                    width: 20,
                  },
                  {
                    header: 'Ký hiệu HĐ',
                    key: 'kyHieuHD',
                    width: 20,
                  },
                  {
                    header: 'Số hoá đơn',
                    key: 'soHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Ngày hoá đơn (*)',
                    key: 'ngayHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Mã khách hàng',
                    key: 'maKhachHang',
                    width: 20,
                  },
                  {
                    header: 'Tên khách hàng',
                    key: 'tenKhachHang',
                    width: 40,
                  },
                  {
                    header: 'Địa chỉ',
                    key: 'diaChi',
                    width: 60,
                  },
                  {
                    header: 'Mã số thuế',
                    key: 'maSoThue',
                    width: 20,
                  },
                  {
                    header: 'Tài khoản ngân hàng',
                    key: 'tkNganHang',
                    width: 20,
                  },
                  {
                    header: 'Hình thức thanh toán',
                    key: 'hinhThucThanhToan',
                    width: 20,
                  },
                  {
                    header: 'Người mua hàng',
                    key: 'nguoiMuaHang',
                    width: 30,
                  },
                  {
                    header: 'Nhân viên bán hàng',
                    key: 'nhanVienBanHang',
                    width: 30,
                  },
                  {
                    header: 'Đã hạch toán',
                    key: 'daHachToan',
                    width: 20,
                  },
                  {
                    header: 'Mã hàng (*)',
                    key: 'maHang',
                    width: 20,
                  },
                  {
                    header: 'Tên hàng',
                    key: 'tenHang',
                    width: 40,
                  },
                  {
                    header: 'Hàng khuyến mại',
                    key: 'hangKhuyenMai',
                    width: 20,
                  },
                  {
                    header: 'ĐVT',
                    key: 'dvt',
                    width: 10,
                  },
                  {
                    header: 'Số lượng',
                    key: 'soLuong',
                    width: 10,
                  },
                  {
                    header: 'Đơn giá',
                    key: 'donGia',
                    width: 10,
                  },
                  {
                    header: 'Thành tiền',
                    key: 'thanhTien',
                    width: 20,
                  },
                  {
                    header: 'Tỷ lệ CK (%)',
                    key: 'tyLeCKPhanTram',
                    width: 20,
                  },
                  {
                    header: 'Tiền chiết khấu',
                    key: 'tienChietKhau',
                    width: 20,
                  },
                  {
                    header: '% thuế GTGT',
                    key: 'phanTramThueGTGT',
                    width: 20,
                  },
                  {
                    header: 'Tiền thuế GTGT',
                    key: 'tienThueGTGT',
                    width: 30,
                  },
                  {
                    header: 'Tiền thuế GTGT quy đổi',
                    key: 'tienThueGTGTQuyDoi',
                    width: 20,
                  },
                  {
                    header: 'HH không TH trên tờ khai thuế GTGT',
                    key: 'hhKhongThTrenToKhaiThueGTGT',
                    width: 30,
                  },
                ];
                const rows = sheet.getRows(7, 2);
                rows.forEach((row) => {
                  row.font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  row.alignment = {
                    vertical: 'top',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  row.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  // row.fill = {
                  //   type: 'pattern',
                  //   pattern: 'solid',
                  //   fgColor: { argb: 'f5f5f5' },
                  // };
                });
                sheet.getRow(8).values = columns.map((item) => item.header);

                sheet.columns = columns.map((item) => {
                  return {
                    key: item.key,
                    width: item.width,
                  };
                });
                let exportDetail = [];
                invoices?.forEach((item) => {
                  // if (item.InvoiceProducts?.length <= 1) {
                  //   exportDetail.push(item);
                  // }
                  // if (item.InvoiceProducts?.length > 1) {
                  //   item?.InvoiceProducts.forEach((e) => {
                  //     exportDetail.push({ ...item, InvoiceProducts: [e] });
                  //   });
                  // }
                  exportDetail.push(item);
                });
                exportDetail?.length > 0 &&
                  exportDetail?.forEach((item, index) => {
                    sheet.addRow({
                      stt: `${index + 1}`,
                      hienThiTrenSo: '',
                      hinhThucBanHang: '',
                      laHoadonGiamThue: '',
                      mauSoHD: item?.invoiceTypeCode ?? '',
                      kyHieuHD: item?.serial ?? '',
                      soHoaDon: item?.invoiceNumber ?? '',
                      ngayHoaDon: item?.invoiceDate
                        ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                        : '',
                      maKhachHang: item?.buyerCode ?? '',
                      tenKhachHang: item?.buyerName ?? '',
                      diaChi: item?.buyerAddress ?? '',
                      maSoThue: item?.buyerTaxCode ?? '',
                      tkNganHang: item?.buyerBankAccount ?? '',
                      hinhThucThanhToan: item?.paymentMethod ?? '',
                      nguoiMuaHang: '',
                      nhanVienBanHang: '',
                      daHachToan: '',
                      maHang: item.maHang ?? item?.InvoiceProducts?.[0]?.code ?? '',
                      tenHang: item.tenHang ?? item?.InvoiceProducts?.[0]?.name ?? '',
                      hangKhuyenMai: 'Không',
                      dvt: item.dvt ?? item?.InvoiceProducts?.[0]?.unit ?? '',
                      soLuong: item.soLuong ?? item?.InvoiceProducts?.[0]?.quantity ?? '',
                      donGia:
                        item.donGia ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.amountTotal, '', ',') ??
                        '',
                      thanhTien:
                        item.thanhTien ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.finalAmount, '', ',') ??
                        '',
                      tyLeCKPhanTram:
                        item.tyLeCKPhanTram ?? item?.InvoiceProducts?.[0]?.discount ?? '',
                      tienChietKhau:
                        item.tienChietKhau ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.discountAmount, '', ',') ??
                        '',
                      phanTramThueGTGT:
                        item.phanTramThueGTGT ?? item?.InvoiceProducts?.[0]?.vat ?? '',
                      phanTramThueSuatKhac: '',
                      tienThueGTGT:
                        item.tienThueGTGT ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.vatAmount, '', ',') ??
                        '',
                      tienThueGTGTQuyDoi: '',
                      hhKhongThTrenToKhaiThueGTGT: '',
                    });
                  });
                sheet?.eachRow((row, rowNumber) => {
                  if (rowNumber >= 9) {
                    row.alignment = {
                      vertical: 'top',
                      horizontal: 'left',
                      wrapText: true,
                    };
                    row.border = {
                      top: { style: 'thin', color: { argb: '000000' } },
                      left: { style: 'thin', color: { argb: '000000' } },
                      bottom: { style: 'thin', color: { argb: '000000' } },
                      right: { style: 'thin', color: { argb: '000000' } },
                    };
                    row.font = {
                      name: 'Times New Roman',
                      family: 4,
                      size: 11,
                      bold: false,
                    };
                  }
                });
              }
              break;
            case '5':
              // misa sme - ban hang
              {
                sheetFileName = 'MISA_SME_Ban_Hang_' + appendDateTime;
                sheet = workbook.addWorksheet(sheetFileName, {
                  headerFooter: { firstHeader: sheetFileName },
                });

                const columns = [
                  {
                    header: 'Hiển thị trên sổ',
                    key: 'hienThiTrenSo',
                    width: 20,
                  },
                  {
                    header: 'Hình thức bán hàng',
                    key: 'hinhThucBanHang',
                    width: 20,
                  },
                  {
                    header: 'Là hóa đơn giảm thuế NQ406',
                    key: 'laHoadonGiamThue',
                    width: 20,
                  },
                  {
                    header: 'Phương thức thanh toán',
                    key: 'phuongThucThanhToan',
                    width: 20,
                  },
                  {
                    header: 'Kiêm phiếu xuất kho',
                    key: 'kiemPhieuXuatKho',
                    width: 20,
                  },
                  {
                    header: 'XK vào khu phi thuế quan và cá TH được coi như XK',
                    key: 'phiThueQuan',
                    width: 20,
                  },
                  {
                    header: 'Lập kèm hóa đơn',
                    key: 'lapKemHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Đã lập hóa đơn',
                    key: 'daLapHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Ngày hạch toán (*)',
                    key: 'ngayHachToan',
                    width: 20,
                  },
                  {
                    header: 'Ngày chứng từ (*)',
                    key: 'ngayChungTu',
                    width: 20,
                  },
                  {
                    header: 'Số chứng từ (*)',
                    key: 'soChungTu',
                    width: 20,
                  },
                  {
                    header: 'Số phiếu xuất',
                    key: 'soPhieuXuat',
                    width: 20,
                  },
                  {
                    header: 'Lý do xuất',
                    key: 'lyDoXuat',
                    width: 20,
                  },
                  {
                    header: 'Số hóa đơn',
                    key: 'soHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Ngày hoá đơn (*)',
                    key: 'ngayHoaDon',
                    width: 20,
                  },
                  {
                    header: 'Mã khách hàng',
                    key: 'maKhachHang',
                    width: 20,
                  },
                  {
                    header: 'Tên khách hàng',
                    key: 'tenKhachHang',
                    width: 40,
                  },
                  {
                    header: 'Địa chỉ',
                    key: 'diaChi',
                    width: 60,
                  },
                  {
                    header: 'Mã số thuế',
                    key: 'maSoThue',
                    width: 20,
                  },
                  {
                    header: 'Diễn giải',
                    key: 'dienGiai',
                    width: 20,
                  },
                  {
                    header: 'Nộp vào TK',
                    key: 'nopVaoTk',
                    width: 20,
                  },
                  {
                    header: 'Nhân viên bán hàng',
                    key: 'nhanVienBanHang',
                    width: 30,
                  },
                  {
                    header: 'Mã hàng (*)',
                    key: 'maHang',
                    width: 20,
                  },
                  {
                    header: 'Tên hàng',
                    key: 'tenHang',
                    width: 40,
                  },
                  {
                    header: 'Hàng khuyến mại',
                    key: 'hangKhuyenMai',
                    width: 20,
                  },
                  {
                    header: 'Tk tiền/Chi phí/Nợ (*)',
                    key: 'tienChiPhiNo',
                    width: 20,
                  },
                  {
                    header: 'Tk Doanh thu có (*)',
                    key: 'tkDoanhThuCo',
                    width: 20,
                  },
                  {
                    header: 'ĐVT',
                    key: 'dvt',
                    width: 10,
                  },
                  {
                    header: 'Số lượng',
                    key: 'soLuong',
                    width: 10,
                  },
                  {
                    header: 'Đơn giá sau thuế',
                    key: 'donGiaSauThue',
                    width: 10,
                  },
                  {
                    header: 'Đơn giá',
                    key: 'donGia',
                    width: 10,
                  },
                  {
                    header: 'Thành tiền',
                    key: 'thanhTien',
                    width: 20,
                  },
                  {
                    header: 'Tỷ lệ CK (%)',
                    key: 'tyLeCKPhanTram',
                    width: 20,
                  },
                  {
                    header: 'Tiền chiết khấu',
                    key: 'tienChietKhau',
                    width: 20,
                  },
                  {
                    header: 'TK chiết khấu',
                    key: 'tkChietKhau',
                    width: 20,
                  },

                  {
                    header: 'Giá tính thuế XK',
                    key: 'giaTinhThueXK',
                    width: 20,
                  },
                  {
                    header: '% thuế XK',
                    key: 'phanTramThueXK',
                    width: 20,
                  },
                  {
                    header: 'Tiền thuế XK',
                    key: 'tienThueXK',
                    width: 20,
                  },
                  {
                    header: 'Tk thuế XK',
                    key: 'tkThueXK',
                    width: 20,
                  },
                  {
                    header: '% thuế GTGT',
                    key: 'phanTramThueGTGT',
                    width: 20,
                  },
                  {
                    header: 'Tiền thuế GTGT',
                    key: 'tienThueGTGT',
                    width: 30,
                  },
                  {
                    header: 'TK thuế GTGT',
                    key: 'tkThueGTGT',
                    width: 20,
                  },
                  {
                    header: 'HH không TH trên tờ khai thuế GTGT',
                    key: 'hhKhongThTrenToKhaiThueGTGT',
                    width: 30,
                  },
                  {
                    header: 'Kho',
                    key: 'kho',
                    width: 20,
                  },
                  {
                    header: 'TK giá vốn',
                    key: 'tkGiaVon',
                    width: 20,
                  },
                  {
                    header: 'TK kho',
                    key: 'tkKho',
                    width: 20,
                  },
                  {
                    header: 'Đơn giá vốn',
                    key: 'donGiaVon',
                    width: 20,
                  },
                  {
                    header: 'Tiền vốn',
                    key: 'tienVon',
                    width: 20,
                  },
                  {
                    header: 'Hàng hóa dữ hộ/bán hộ',
                    key: 'hangHoaDuHo',
                    width: 20,
                  },
                ];
                const rows = sheet.getRows(7, 2);
                rows.forEach((row) => {
                  row.font = {
                    name: 'Times New Roman',
                    family: 4,
                    size: 12,
                    bold: true,
                  };
                  row.alignment = {
                    vertical: 'top',
                    horizontal: 'center',
                    wrapText: true,
                  };
                  row.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } },
                  };
                  // row.fill = {
                  //   type: 'pattern',
                  //   pattern: 'solid',
                  //   fgColor: { argb: 'f5f5f5' },
                  // };
                });
                sheet.getRow(8).values = columns.map((item) => item.header);

                sheet.columns = columns.map((item) => {
                  return {
                    key: item.key,
                    width: item.width,
                  };
                });
                let exportDetail = [];
                invoices?.forEach((item) => {
                  // if (item.InvoiceProducts?.length <= 1) {
                  //   exportDetail.push(item);
                  // }
                  // if (item.InvoiceProducts?.length > 1) {
                  //   item?.InvoiceProducts.forEach((e) => {
                  //     exportDetail.push({ ...item, InvoiceProducts: [e] });
                  //   });
                  // }
                  exportDetail.push(item);
                });
                exportDetail?.length > 0 &&
                  exportDetail?.forEach((item, index) => {
                    sheet.addRow({
                      hienThiTrenSo: '',
                      hinhThucBanHang: '',
                      laHoadonGiamThue: '',
                      phuongThucThanhToan: item?.paymentMethod ?? '',
                      kiemPhieuXuatKho: '',
                      phiThueQuan: '',
                      lapKemHoaDon: '',
                      daLapHoaDon: '',
                      ngayHachToan: item?.accountingDate
                        ? Utils.formatDateTime(item?.accountingDate, 'DD/MM/YYYY')
                        : 'Chưa hạch toán',
                      ngayChungTu: '',
                      soChungTu: item?.noDocument ?? '',
                      soPhieuXuat: '',
                      lyDoXuat: '',
                      soHoaDon: item?.invoiceNumber ?? '',
                      ngayHoaDon: item?.invoiceDate
                        ? Utils.formatDateTime(item?.invoiceDate, 'DD/MM/YYYY')
                        : '',
                      maKhachHang: item?.buyerCode ?? '',
                      tenKhachHang: item?.buyerName ?? '',
                      diaChi: item?.buyerAddress ?? '',
                      maSoThue: item?.buyerTaxCode ?? '',
                      dienGiai: item.dienGiai ?? item?.InvoiceProducts?.[0]?.name ?? '',
                      nopVaoTk: '',
                      nhanVienBanHang: '',
                      maHang: item.maHang ?? item?.InvoiceProducts?.[0]?.code ?? '',
                      tenHang: item.tenHang ?? item?.InvoiceProducts?.[0]?.name ?? '',
                      hangKhuyenMai: 'không',
                      tienChiPhiNo: '',
                      tkDoanhThuCo: '',
                      dvt: item.dvt ?? item?.InvoiceProducts?.[0]?.unit ?? '',
                      soLuong: item.soLuong ?? item?.InvoiceProducts?.[0]?.quantity ?? '',
                      donGiaSauThue:
                        item.donGiaSauThue ??
                        Utils.formatCurrency(
                          item?.InvoiceProducts?.[0]?.vatAmount +
                            item?.InvoiceProducts?.[0]?.amountTotal,
                          '',
                          ','
                        ) ??
                        '',
                      donGia:
                        item.donGia ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.amountTotal, '', ',') ??
                        '',
                      thanhTien:
                        item.thanhTien ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.finalAmount, '', ',') ??
                        '',
                      tyLeCKPhanTram:
                        item.tyLeCKPhanTram ?? item?.InvoiceProducts?.[0]?.discount ?? '',
                      tienChietKhau:
                        item.tienChietKhau ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.discountAmount, '', ',') ??
                        '',
                      tkChietKhau: '',
                      giaTinhThueXK: '',
                      phanTramThueXK: '',
                      tienThueXK: '',
                      phanTramThueGTGT:
                        item.phanTramThueGTGT ?? item?.InvoiceProducts?.[0]?.vat ?? '',
                      tienThueGTGT:
                        item.tienThueGTGT ??
                        Utils.formatCurrency(item?.InvoiceProducts?.[0]?.vatAmount, '', ',') ??
                        '',
                      tkThueGTGT: '',
                      hhKhongThTrenToKhaiThueGTGT: '',
                      kho: '',
                      tkGiaVon: '',
                      tkKho: '',
                      donGiaVon: '',
                      tienVon: '',
                      hangHoaDuHo: '',
                    });
                  });
                sheet?.eachRow((row, rowNumber) => {
                  if (rowNumber >= 9) {
                    row.alignment = {
                      vertical: 'top',
                      horizontal: 'left',
                      wrapText: true,
                    };
                    row.border = {
                      top: { style: 'thin', color: { argb: '000000' } },
                      left: { style: 'thin', color: { argb: '000000' } },
                      bottom: { style: 'thin', color: { argb: '000000' } },
                      right: { style: 'thin', color: { argb: '000000' } },
                    };
                    row.font = {
                      name: 'Times New Roman',
                      family: 4,
                      size: 11,
                      bold: false,
                    };
                  }
                });
              }
              break;
            default:
              break;
          }
        }
        workbook.xlsx.writeBuffer().then(function (data) {
          dispatch(hideAppLoading());

          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = sheetFileName + ext;
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      }
    } catch (error) {
      dispatch(hideAppLoading());
      console.log({ error });
    }
  }

  return (
    <div className="d-flex flex-column gap-4">
      <div
        className="d-flex flex-column gap-3"
        style={
          {
            // maxHeight: '70vh',
          }
        }
      >
        {/* anh banner */}
        <div className="bg-default">
          <img src={AppResource.images.imgExportInvoice} className="w-100" />
        </div>

        {/* noi dung */}
        {/* xuat khau hoa don dau vao */}
        {exportType === 'IN' && (
          <div>
            <div className="radio-inline">
              <div className="mr-4 mb-2">
                <KTRadio
                  name="radioInputInvoice"
                  checked={checkedRadioId === 'radioInputInvoice'}
                  label="Danh sách hóa đơn đầu vào"
                  onChange={(newValue) => {
                    setCheckedRadioId('radioInputInvoice');
                  }}
                />
              </div>
              <div className="mr-4 mb-2">
                <KTRadio
                  name="radioImportAccountantSoftware"
                  checked={checkedRadioId === 'radioImportAccountantSoftware'}
                  label="Nhập khẩu phần mềm kế toán"
                  onChange={(newValue) => {
                    setCheckedRadioId('radioImportAccountantSoftware');
                  }}
                />
              </div>
            </div>
            {checkedRadioId === 'radioInputInvoice' && (
              <div>
                <KTFormGroup
                  label={'Chọn danh sách muốn tải về'}
                  additionalClassName="mb-0"
                  inputName="exportListType"
                  inputElement={
                    <KeenSelectOption
                      name={'exportListType'}
                      initialValue={exportListType}
                      options={inExportListTypeItems}
                      containerClassName=""
                      labelClassName=""
                      selectClassName="w-100"
                      onValueChanged={(value) => {
                        setExportListType(value);
                      }}
                    />
                  }
                />
              </div>
            )}

            {checkedRadioId === 'radioImportAccountantSoftware' && (
              <div>
                <KTFormGroup
                  label={'Chọn phần mềm muốn nhập khẩu'}
                  additionalClassName="mb-0"
                  inputName="importListType"
                  inputElement={
                    <KeenSelectOption
                      name={'importListType'}
                      initialValue={importListType}
                      options={importListSoftwareItems}
                      containerClassName=""
                      labelClassName=""
                      selectClassName="w-100"
                      onValueChanged={(value) => {
                        setImportListType(value);
                      }}
                    />
                  }
                />
              </div>
            )}
          </div>
        )}

        {/* xuat khau hoa don dau ra */}
        {exportType === 'OUT' && (
          <div>
            <div>
              <KTFormGroup
                label={'Chọn danh sách muốn tải về'}
                additionalClassName="mb-0"
                inputName="exportListType"
                inputElement={
                  <KeenSelectOption
                    name={'exportListType'}
                    initialValue={exportListType}
                    options={outExportListTypeItems}
                    containerClassName=""
                    labelClassName=""
                    selectClassName="w-100"
                    onValueChanged={(value) => {
                      setExportListType(value);
                    }}
                  />
                }
              />
            </div>
          </div>
        )}

        <div className="bg-light p-4 rounded d-flex align-items-center gap-2" style={{}}>
          <i className="fa-regular fa-lightbulb-on text-primary" />{' '}
          <span className="text-primary">
            Dữ liệu xuất khẩu sẽ được lấy theo điều kiện lọc trên danh sách hóa đơn.
          </span>
        </div>
      </div>

      {/* actions */}
      <div className="d-flex align-items-center justify-content-end gap-3">
        <VIButton
          text={t('Cancel')}
          className="btn-grey"
          style={{ padding: '8px' }}
          onClick={handlePressCancel}
        />
        <VIButton
          text={t('Export')}
          className="btn-blue"
          style={{ padding: '8px' }}
          onClick={handlePressExport}
        />
      </div>
    </div>
  );
}

export default ViewInvoiceExport;
