import PropTypes from 'prop-types';
import { useState } from 'react';
import './style.scss';

VIInputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,

  fieldProps: PropTypes.object,
  fieldMeta: PropTypes.object,
  fieldHelpers: PropTypes.object,

  style: PropTypes.object,
  inputStyle: PropTypes.object,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,

  password: PropTypes.bool,
  id: PropTypes.number,
  onKeyDown: PropTypes.func,
  iconSearch: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  min: PropTypes.string,
  max: PropTypes.string,
};

VIInputField.defaultProps = {
  type: 'text',
  name: '',
  placeholder: '',
  className: '',
  label: null,
  required: false,
  disabled: false,

  fieldProps: {},
  fieldHelpers: {},
  fieldMeta: {},

  style: {},
  inputStyle: {},
  inputClassName: '',
  labelClassName: '',

  password: false,
  id: null,
  iconSearch: false,
  onKeyDown: () => {},
  onChange: () => {},
  value: null,
  min: '',
  max: '',
};

/**
 * @param {string} name
 * @param {string} label label input
 * @param {string} type type of text input
 * @param {string} placeholder
 * @param {string} className
 * @param {boolean} required if input is required
 * @param {boolean} disabled
 * @param {string} style
 * @param {string} inputStyle
 * @param {string} inputClassName
 * @param {string} labelClassName
 * @param {object} fieldProps
 * @param {object} fieldHelpers
 * @param {object} fieldMeta
 * @param {boolean} password if input is password
 *
 */

function VIInputField(props) {
  // MARK: --- Params ---
  const {
    name,
    type,
    label,
    required,
    disabled,
    placeholder,
    className,

    fieldProps,
    fieldMeta,
    fieldHelpers,

    // custom style & className
    style,
    inputStyle,
    inputClassName,
    labelClassName,
    password,
    id,
    onKeyDown,
    iconSearch,
    onChange,
    value = '',
    min = '',
    max = '',
  } = props;

  const { error, touched } = fieldMeta || {};
  const showError = error && touched;
  const [showIconPassword, setShowIconPassword] = useState(false);
  const [typePassword, setTypePassword] = useState('password');

  return (
    <div className={`VIInputField input-icon ${className}`} style={{ ...style }}>
      {label ? (
        <p
          className={`mb-1 font-weight-bold ${labelClassName}`}
          onClick={() => document.getElementById(`${id ? id : label}`).focus()}
        >
          {label}
          {required ? (
            <sup className="font-weight-boldest ms-1" style={{ color: '#F44141' }}>
              (*)
            </sup>
          ) : null}
        </p>
      ) : null}

      <div className="d-flex VIInputField_Form align-items-center position-relative">
        <input
          className={`form-control font-size-lg pl-4 h-100 w-100 ${inputClassName} ${
            password ? 'pr-11' : iconSearch ? 'pr-11' : 'pr-4'
          } ${showError ? 'error-input' : ''} ${label?.includes('Tạo email') ? 'pr-40' : ''}`}
          style={{ ...inputStyle }}
          id={id ? id : label ? label : name}
          type={password ? typePassword : type}
          disabled={disabled}
          placeholder={placeholder}
          spellCheck={false}
          value={value ? value : null}
          min={min ?? ''}
          onKeyDown={onKeyDown ? onKeyDown : null}
          onChange={onChange ? onChange : null}
          {...fieldProps}
        />
        {password && fieldProps?.value && (
          <div
            className="position-absolute h-100 d-flex align-items-center cursor-pointer"
            style={{ right: '1rem' }}
          >
            {showIconPassword ? (
              <i
                className="fas fa-eye-slash"
                onClick={() => {
                  setShowIconPassword(false), setTypePassword('password');
                }}
              ></i>
            ) : (
              <i
                className="fas fa-eye "
                onClick={() => {
                  setShowIconPassword(true), setTypePassword('text');
                }}
              ></i>
            )}
          </div>
        )}

        {label?.includes('Tạo email') && (
          <input className="email-input font-size-lg px-2 my-auto" value="@meinvoice.vn" disabled />
        )}
      </div>

      {showError && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{error}</div>
        </div>
      )}
    </div>
  );
}

export default VIInputField;
