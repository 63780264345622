import categoryApi from 'api/categoryApi';
import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import DropdownHeaderChooseCompany from 'features/Others/components/DropdownHeaderChooseCompany';
import EmptyView from 'features/Others/components/EmptyView';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import useOnClickOutside from 'hooks/useOnClickOutside';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

ViewChooseOrganization.propTypes = {
  onPressedCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onPressedAddSupplier: PropTypes.func,
};

ViewChooseOrganization.defaultProps = {
  onPressedCancel: null,
  onSubmit: null,
  onPressedAddSupplier: null,
};

/**
 *
 * @param {{
 * onPressedCancel: function,
 * onSubmit: function,
 * onPressedAddSupplier: function,
 * }} props
 * @returns
 */
function ViewChooseOrganization(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { onPressedCancel, onSubmit, onPressedAddSupplier } = props;
  const { organizations } = useSelector((state) => state?.organization);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [searchResultItems, setSearchResultItems] = useState([]);
  const [filterSearch, setFilterSearch] = useState({
    q: '',
  });
  const refSearchView = useRef();
  const [showSearchResultView, setShowSearchResultView] = useState(false);
  const refSelectedSuppliers = useRef([]);
  const [listItems, setListItems] = useState([]);
  const columns = useMemo(() => {
    return [
      {
        name: t('STT'),
        sortable: false,
        center: true,
        width: '80px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {index + 1}
            </p>
          );
        },
      },
      {
        name: t('EmailReceiveInvoice'),
        sortable: false,
        minWidth: '180px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.organization?.invoiceMailbox}
            </p>
          );
        },
      },
      {
        name: t('UnitName'),
        sortable: false,
        minWidth: '220px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.organization?.organizationName ?? row?.organization?.departmentName ?? ''}
            </p>
          );
        },
      },
      {
        name: t('QuantitySupplier'),
        sortable: false,
        style: {
          borderRight: 0,
        },
        minWidth: '180px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.suppliers?.length}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            <KTTooltip text={t('Delete')}>
              <i
                className="fa-regular fa-trash text-danger"
                onClick={() => {
                  const tmp = [...listItems].filter(
                    (item) => item.organization?.invoiceMailbox !== row.organization?.invoiceMailbox
                  );
                  setListItems(tmp);
                  Global.gTempChoosingOrganizationList = tmp;
                  if (selectedOrganization?.invoiceMailbox === row.organization?.invoiceMailbox) {
                    setSelectedOrganization(null);
                  }
                }}
              />
            </KTTooltip>
          </div>
        ),
      },
    ];
  }, [listItems]);
  const columnsSearchResult = useMemo(() => {
    return [
      {
        name: t('STT'),
        sortable: false,
        center: true,
        width: '80px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {index + 1}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        sortable: false,
        width: '180px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.taxCode}
            </p>
          );
        },
      },
      {
        name: t('SupplierName'),
        sortable: false,
        minWidth: '220px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.partnerCompanyName}
            </p>
          );
        },
      },
      {
        name: t('Address'),
        sortable: false,
        style: {
          borderRight: 0,
        },
        minWidth: '260px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.address}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            {!_.find(
              listItems,
              (item) => item.organization?.invoiceMailbox === selectedOrganization?.invoiceMailbox
            )
              ?.suppliers?.map((item) => item?.partnerCompanyId)
              ?.includes(row.partnerCompanyId) && (
              <KTTooltip text={t('Add')}>
                <i
                  className="fa-regular fa-add text-primary"
                  onClick={() => {
                    if (!!selectedOrganization) {
                      const tmp = [...listItems];
                      const foundIndex = _.findIndex(
                        tmp,
                        (item) =>
                          item?.organization?.invoiceMailbox === selectedOrganization.invoiceMailbox
                      );
                      if (foundIndex !== -1) {
                        tmp[foundIndex].suppliers = [...tmp[foundIndex].suppliers, row];
                        setListItems(tmp);
                        Global.gTempChoosingOrganizationList = tmp;
                      }
                    } else {
                      ToastHelper.showError('Bạn cần chọn email nhận hoá đơn trước');
                    }
                  }}
                />
              </KTTooltip>
            )}
          </div>
        ),
      },
    ];
  }, [selectedOrganization, listItems]);
  const { listAutoTransferInvoiceDepartment } = useSelector((state) => state.organization);
  // console.log({ listAutoTransferInvoiceDepartment });

  useEffect(() => {
    if (!Global.gTempChoosingOrganizationList) {
      if (listAutoTransferInvoiceDepartment?.length > 0) {
        const firstEl = listAutoTransferInvoiceDepartment[0];
        setSelectedOrganization(
          firstEl?.organizationDepartmentId
            ? firstEl?.OrganizationDepartment
            : firstEl?.Organization
        );

        const tmp = listAutoTransferInvoiceDepartment?.map((item) => {
          return {
            organization: item?.OrganizationDepartment
              ? item?.OrganizationDepartment
              : item?.Organization,
            suppliers: item?.PartnerCompanies ?? item?.partnerCompanyIds ?? [],
          };
        });
        // console.log({ tmp });
        setListItems(tmp);
      }
    } else {
      setListItems(Global.gTempChoosingOrganizationList);
    }
  }, [listAutoTransferInvoiceDepartment]);

  useEffect(() => {
    searchSupplier(filterSearch);
  }, [filterSearch]);

  useOnClickOutside(refSearchView, () => {
    setShowSearchResultView(false);
  });

  useEffect(() => {
    if (Global.gTempChoosingOrganization) {
      setSelectedOrganization(Global.gTempChoosingOrganization);
    }
  }, []);

  // ---- methods ----
  function handlePressedCancel() {
    if (onPressedCancel) {
      onPressedCancel();
    }
  }

  async function searchSupplier(params) {
    try {
      const res = await categoryApi.getSupplierCustomerList(params);
      const { result, partnerCompanies, total, count } = res;
      if (result === 'success' && partnerCompanies) {
        setSearchResultItems(partnerCompanies);
      }
    } catch (error) {
      console.log({ error });
    }
  }

  function handleSave() {
    if (onSubmit) {
      onSubmit(listItems);
    }
  }

  return (
    <div>
      <div>
        {/* chon email nhan hoa don */}
        <div>
          <KTFormGroup
            label={
              <>
                Email nhận hóa đơn <span className="text-danger">(*)</span>
              </>
            }
            inputElement={
              <KTBSDropdown
                contentEl={
                  <DropdownHeaderChooseCompany
                    currentItem={selectedOrganization}
                    tree={organizations}
                    onChange={(item) => {
                      setSelectedOrganization(item);
                      Global.gTempChoosingOrganization = item;
                      const tmp = [...listItems];
                      // kiem tra da ton tai trong ds chua?
                      if (
                        _.findIndex(
                          listItems,
                          (obj) => obj.organization?.invoiceMailbox === item.invoiceMailbox
                        ) !== -1
                      ) {
                        // ToastHelper.showError(
                        //   `Đã tồn tại email nhận hoá đơn: ${item.invoiceMailbox} trong danh sách`
                        // );
                        return;
                      }
                      tmp.push({
                        organization: item,
                        suppliers: [],
                      });
                      setListItems(tmp);
                      Global.gTempChoosingOrganizationList = tmp;
                    }}
                  />
                }
                toggleElement={
                  <div
                    className="border bg-white px-4 py-3 d-flex align-items-center justify-content-between cursor-pointer hover-opacity-60"
                    style={{
                      borderRadius: '12px',
                    }}
                  >
                    <div className="mr-4">
                      <p className="m-0">
                        {selectedOrganization
                          ? selectedOrganization?.invoiceMailbox
                          : t('EmailReceiveInvoice')}
                      </p>
                    </div>
                    <i style={{ color: '#7177A8' }} className="fa-regular fa-angle-down"></i>
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                // autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
              />
            }
          />
        </div>

        {/* chon ncc */}
        <div className="d-flex align-items-end justify-content-between gap-3">
          <div className="flex-grow-1 position-relative" ref={refSearchView}>
            <label htmlFor="searchFieldNCC" className="text-grey font-weight-bolder">
              Nhận hóa đơn từ nhà cung cấp
            </label>
            <VISearchField
              name="searchFieldNCC"
              placeholder="Nhập MST hoặc tên NCC để tìm kiếm..."
              value={filterSearch.q}
              inputClassName="w-100"
              onFocus={() => {
                setShowSearchResultView(true);
              }}
              className=""
              typingTimeout={500}
              onSubmit={(newValue) => {
                setFilterSearch({
                  ...filterSearch,
                  q: newValue,
                });
              }}
            />
            {showSearchResultView && (
              <div
                className="bg-white px-4 position-absolute w-100 shadow"
                style={{
                  zIndex: 3,
                  borderRadius: '12px',
                }}
              >
                <VIDataTable
                  draggable={false}
                  tableStyles={customDataTableActionStyle}
                  additionalClassName="p-0 shadow-none mt-2"
                  additionalCardBodyClassName="p-0"
                  selectable={false}
                  pagination={null}
                  onChangePage={(page) => {}}
                  onChangeRowsPerPage={(perPage) => {}}
                  fullColumns={columnsSearchResult}
                  data={searchResultItems}
                  loading={false}
                  onRowClick={(row) => {}}
                  onSetSelectedMultiItems={(rows) => {}}
                  haveCardHeader={false}
                  emptyComponent={<EmptyView message="Không tìm thấy kết quả" />}
                  minusHeight={340}
                />
              </div>
            )}
          </div>
          <VIButton
            text={t('AddSupplier')}
            className=""
            style={{ padding: '8px' }}
            onClick={() => {
              if (onPressedAddSupplier) {
                onPressedAddSupplier();
              }
            }}
            beforeIcon={<i className="fa-regular fa-add" />}
          />
        </div>

        <VIDataTable
          draggable={false}
          tableStyles={customDataTableActionStyle}
          additionalClassName="p-0 shadow-none"
          additionalCardBodyClassName="p-0"
          selectable={false}
          pagination={null}
          onChangePage={(page) => {}}
          onChangeRowsPerPage={(perPage) => {}}
          fullColumns={columns}
          data={listItems}
          loading={false}
          onRowClick={(row) => {}}
          onSetSelectedMultiItems={(rows) => {}}
          haveCardHeader={false}
          emptyComponent={<EmptyView message="Không có bản ghi nào" imgClassName="pt-10" />}
        />
      </div>

      {/* actions */}
      <div className="d-flex align-items-center justify-content-end gap-3 mt-3">
        <VIButton
          text={t('Cancel')}
          className="btn-grey"
          style={{ padding: '8px' }}
          onClick={handlePressedCancel}
        />
        <VIButton text={t('Save')} className="" style={{ padding: '8px' }} onClick={handleSave} />
      </div>
    </div>
  );
}

export default ViewChooseOrganization;
