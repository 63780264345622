import VIInputField from 'general/components/VietInvoice/VIInputField';
import React from 'react';

function BillingInformation() {
  return (
    <div>
      <div
        className="d-flex align-items-center px-3 py-2 mt-5"
        style={{ backgroundColor: '#F8F8F8', borderRadius: '12px' }}
      >
        <p className="mb-0" style={{ color: '#3C4376', fontWeight: '400' }}>
          Xem hướng dẫn thiết lập
        </p>
        <span
          className="ms-1 text-primary"
          style={{ fontWeight: '700', textDecorationLine: 'underline' }}
        >
          Tại đây
        </span>
      </div>
      <div className="row mt-4">
        <VIInputField
          className="col-6 w-100 mb-4"
          label="Số tài khoản"
          placeholder="Nhập mã số tài khoản"
        />
        <VIInputField
          className="col-6 w-100 mb-4"
          label="Tên tài khoản"
          placeholder="Nhập tên tài khoản"
        />
      </div>
      <div className="row ">
        <VIInputField
          className="col-6 w-100 mb-4"
          label="Tên ngân hàng"
          placeholder="Nhập mã tên ngân hàng"
        />
        <VIInputField className="col-6 w-100 mb-4" label="Chi nhánh" placeholder="Nhập chi nhánh" />
      </div>
      <div className="d-flex align-items-center">
        <VIInputField className="w-25 mb-4" label="Hạn thanh toán" type={'number'} />
        <div className="ms-3 mt-3" style={{ fontWeight: 500, color: '#1C2256' }}>
          Ngày kể từ ngày lập hóa đơn
        </div>
      </div>
    </div>
  );
}

export default BillingInformation;
