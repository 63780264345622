import VIFolderTree from 'general/components/VietInvoice/VIFolderTree';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EmptyView from '../EmptyView';
import './style.scss';

DropdownHeaderChooseCompany.propTypes = {
  tree: PropTypes.array,
  onChange: PropTypes.func,
  currentItem: PropTypes.object,
  showViewAll: PropTypes.bool,
  footerEl: PropTypes.element,
};

DropdownHeaderChooseCompany.defaultProps = {
  tree: [],
  onChange: null,
  currentItem: {},
  showViewAll: true,
  footerEl: null,
};

function DropdownHeaderChooseCompany(props) {
  // MARK --- Params ---
  const { tree, onChange, currentItem, showViewAll, footerEl } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  // MARK --- Function ---
  function handleChange(item) {
    if (onChange) {
      onChange(item);
    }
  }

  return (
    <div className="DropdownHeaderChooseCompany min-w-375px" style={{}}>
      {tree?.length > 0 ? (
        <div>
          <VIFolderTree
            currentItem={currentItem}
            data={tree}
            setItem={(item) => {
              handleChange(item);
            }}
          />
          {showViewAll && (
            <div
              onClick={() => navigate('/inbot/organization')}
              className="border-top p-5 d-flex justify-content-center align-items-center text-primary bg-hover-light-primary font-weight-bold"
            >
              {t('ViewAll')}
            </div>
          )}
          {footerEl && footerEl}
        </div>
      ) : (
        <div className="p-12">
          <EmptyView
            message={'Bạn chưa có cơ cấu tổ chức nào'}
            buttonText={t('CreateNew')}
            onPressButton={() => {
              navigate(`/inbot/organization#create`);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default DropdownHeaderChooseCompany;
