import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import KTFormInput from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import { useTranslation } from 'react-i18next';
import VIButton from 'general/components/VietInvoice/VIButton';
import usePayment from 'hooks/usePayment';
import Utils from 'general/utils/Utils';
import useRouter from 'hooks/useRouter';
import PaymentHelper from 'general/helpers/PaymentHelper';
import { useDispatch } from 'react-redux';
import { updateSelectedVoucher } from 'features/Payment/paymentSlice';
import ToastHelper from 'general/helpers/ToastHelper';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';

CartInputVoucher.propTypes = {};

function CartInputVoucher(props) {
  // ---- params ----
  const { t } = useTranslation();
  const {
    cartTotalPrice,
    cartPriceUnit,
    canShoppingCart,
    selectedVoucher,
    totalPromotionPrice,
    lastPriceToPay,
  } = usePayment();
  const router = useRouter();
  const refVoucherCode = useRef('');
  const dispatch = useDispatch();

  useEffect(() => {
    refVoucherCode.current = selectedVoucher?.voucherCode;
  }, [selectedVoucher]);

  // ---- methods -----
  function handleNext() {
    router.navigate('/store/shopping-cart/confirm-payment');
  }

  async function handleApplyVoucher(code) {
    const voucher = await PaymentHelper.callApiFindVoucherByCode(code);
    if (voucher) {
      dispatch(updateSelectedVoucher(voucher));
      ToastHelper.showSuccess(t('ApplyVoucherSuccess'));
    } else {
    }
  }

  return (
    <div
      className="bg-white"
      style={{
        borderRadius: '16px',
        border: '1px solid #E8E9EB',
      }}
    >
      <div className="p-6 border-bottom">
        <div className="d-flex gap-2 align-items-center">
          <KTFormInput
            name="cartInputVoucher"
            additionalClassName="flex-grow-1"
            placeholder={`${t('VoucherCode')}...`}
            value={selectedVoucher?.voucherCode ?? ''}
            enableInputGroup
            prependElements={[<i className="fa-regular fa-gift" />]}
            readonly={!canShoppingCart}
            onChange={(text) => {
              refVoucherCode.current = text;
            }}
          />
          <VIButton
            text={t('Apply')}
            className="btn-blue"
            style={{ padding: '8px' }}
            onClick={() => {
              handleApplyVoucher(refVoucherCode.current);
            }}
            disabled={!canShoppingCart}
          />
        </div>
      </div>

      {/* thanh tien */}
      {/* tong cong */}
      <div className="px-6 border-bottom">
        <div className="d-flex py-4 align-items-start justify-content-between">
          <div className="">{t('TotalPrice')}</div>
          <div className="font-size-lg text-grey">
            {Utils.formatCurrency(cartTotalPrice)} {cartPriceUnit}
          </div>
        </div>
      </div>
      {/* khuyen mai */}
      <div className="px-6 border-bottom">
        <div className="d-flex py-4 align-items-start justify-content-between">
          <div className="d-flex align-items-center gap-2">
            {t('Promotion')}{' '}
            {selectedVoucher && (
              <KTTooltip
                text={
                  <div className="d-flex flex-column align-items-start">
                    <p className="m-0">
                      <span>{t('VoucherCode')}:</span>{' '}
                      <span className="font-weight-bolder">{selectedVoucher.voucherCode}</span>
                    </p>
                    <p className="m-0">
                      {t('VoucherName')}: {selectedVoucher.voucherName}
                    </p>
                  </div>
                }
              >
                <i className="fa-regular fa-circle-question text-primary" />
              </KTTooltip>
            )}
          </div>
          <div className="font-size-lg text-grey">
            {Utils.formatCurrency(totalPromotionPrice)} {cartPriceUnit}
          </div>
        </div>
      </div>
      {/* tong phai tra */}
      <div className="px-6">
        <div className="d-flex py-4 align-items-start justify-content-between">
          <div className="">{t('TotalPricePay')}</div>
          <div className="d-flex flex-column align-items-center">
            <span className="font-size-lg text-grey font-weight-bolder">
              {Utils.formatCurrency(lastPriceToPay)} {cartPriceUnit}
            </span>{' '}
            <span>({t('IncludedVAT')})</span>
          </div>
        </div>
      </div>

      {/* tiep tuc */}
      <div className="d-flex px-6 pb-4">
        <VIButton
          text={t('Next')}
          className="btn-blue w-100"
          style={{ padding: '8px' }}
          onClick={handleNext}
          disabled={!canShoppingCart}
        />
      </div>
    </div>
  );
}

export default CartInputVoucher;
