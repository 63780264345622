import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import ViewTaxReducedDocumentDetail from 'features/Tools/components/ViewTaxReducedDocumentDetail';
import { thunkTaxReducedDocuments } from 'features/Tools/toolSlice';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIModal from 'general/components/VietInvoice/VIModal';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

TaxReducedDocumentScreen.propTypes = {
  navItemList: PropTypes.array,
};

TaxReducedDocumentScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function TaxReducedDocumentScreen(props) {
  // ---- params ----
  const { navItemList } = props;
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      // {
      //   name: t('STT'),
      //   sortable: false,
      //   width: '80px',
      //   cell: (row, index) => {
      //     return (
      //       <p data-tag="allowRowEvents" className="text-dark-75 font-weight-bold m-0">
      //         {index + 1}
      //       </p>
      //     );
      //   },
      // },
      {
        name: t('DocumentName'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.title}
            </p>
          );
        },
      },
      {
        name: t('AgencyIssued'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.author}
            </p>
          );
        },
      },
      {
        name: t('Description'),
        sortable: false,
        minWidth: '280px',
        style: {
          borderRight: 0,
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0 text-maxline-3">
              {row?.description}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            <KTTooltip text={t('View')}>
              <i
                className="fa-regular fa-eye text-primary"
                onClick={() => {
                  handleShowTaxReducedDocumentDetail(row);
                }}
              />
            </KTTooltip>
          </div>
        ),
      },
    ];
  }, [t]);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(Global.gFilterTaxReducedDocuments);
  const { taxReducedDocumentItems, isGettingTaxReducedDocuments } = useSelector(
    (state) => state.tool
  );
  const [modalTaxReducedDocumentDetailShowing, setModalTaxReducedDocumentDetailShowing] =
    useState(false);
  const [currentSelectedDocument, setCurrentSelectedDocument] = useState(null);

  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  useEffect(() => {
    dispatch(thunkTaxReducedDocuments(filters));
  }, [filters]);

  // ----- methods ----
  function handleShowTaxReducedDocumentDetail(row) {
    setCurrentSelectedDocument(row);
    setModalTaxReducedDocumentDetailShowing(true);
  }

  return (
    <div className="h-100">
      <VIDataTable
        draggable={false}
        tableStyles={customDataTableActionStyle}
        title={
          <div className="d-flex align-items-center justify-content-center gap-1">
            <div className="d-block d-lg-none">
              <KTBSDropdown
                toggleElement={
                  <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                    <i className="fa-solid fa-bars text-grey" />
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                dropdownMenuItems={dropdownMenuItems}
                selectedValue={t('LookUpDocumentGuidingTheReductionOfValueAddedTax')}
                onChange={(newValue) => {}}
              />
            </div>{' '}
            {t('LookUpDocumentGuidingTheReductionOfValueAddedTax')}
          </div>
        }
        selectable={false}
        pagination={null}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          // Global.gFiltersAllUserList = {
          //   ...Global.gFiltersAllUserList,
          //   page: iNewPage,
          // };
          // setFilters(Global.gFiltersAllUserList);
        }}
        onChangeRowsPerPage={(perPage) => {
          // Global.gFiltersAllUserList = {
          //   ...Global.gFiltersAllUserList,
          //   limit: perPage,
          //   page: 0,
          // };
          // setFilters(Global.gFiltersAllUserList);
        }}
        fullColumns={columns}
        data={taxReducedDocumentItems}
        loading={false}
        filterbar={
          <>
            <VISearchField
              placeholder={`${t('EnterToSearch')}`}
              value={''}
              className=""
              typingTimeout={500}
              onSubmit={(newValue) => {
                Global.gFilterTaxReducedDocuments = {
                  ...Global.gFilterTaxReducedDocuments,
                  q: newValue,
                };
                setFilters(Global.gFilterTaxReducedDocuments);
              }}
            />
          </>
        }
        onRowClick={(row) => {
          handleShowTaxReducedDocumentDetail(row);
        }}
        onSetSelectedMultiItems={(rows) => {}}
        // toolbar={
        //   <>
        //     <VIButton
        //       beforeIcon={<i className="fa-regular fa-search" />}
        //       text={t('LookUp')}
        //       className="btn-blue border-0 p-3"
        //       onClick={() => {}}
        //     />

        //     <VIButton
        //       beforeIcon={<i className="fa-regular fa-download" />}
        //       text={t('Download')}
        //       className="btn-blue border-0 p-3"
        //       onClick={() => {}}
        //     />
        //   </>
        // }
      />

      {/* modal tax reduced document detail */}
      <VIModal
        titleModal={t('DocumentDetail')}
        modalSize="lg"
        show={modalTaxReducedDocumentDetailShowing}
        contentModal={<ViewTaxReducedDocumentDetail item={currentSelectedDocument} />}
        closeBtn
        handleSubmit={() => {
          Utils.openInNewTab(currentSelectedDocument?.pdfUrl);
        }}
        onHide={() => {
          setModalTaxReducedDocumentDetailShowing(false);
          setCurrentSelectedDocument(null);
        }}
        buttonNegative={t('Close')}
        buttonPositive={t('ViewPdf')}
      />
    </div>
  );
}

export default TaxReducedDocumentScreen;
