import AppConfigs from 'general/constants/AppConfigs';
import AppResource from 'general/constants/AppResource';
import useRouter from 'hooks/useRouter';
import './style.scss';
import useConfig from 'hooks/useConfig';

LandingPageFooter.propTypes = {};

LandingPageFooter.defaltProps = {};

function LandingPageFooter(props) {
  // ---- params ----
  const router = useRouter();
  const config = useConfig();

  return (
    <div className="LandingPageFooter bg-white" id="footer">
      <div className="container-xl pt-20">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="LandingPageFooter_Title font-size-h5">ICORP - Invoice</div>
            <div className="text-uppercase font-size-lg font-weight-normal mt-4">
              TIÊN PHONG GIẢI PHÁP SỐ HOÁ DOANH NGHIỆP
            </div>
            <div className="d-flex flex-column" style={{ maxWidth: '366px', rowGap: '8px' }}>
              <div className="LandingPageFooter_Title font-size-h5 mt-4">CÔNG TY CỔ PHẦN ICORP</div>

              <div>
                <span className="LandingPageFooter_Title me-2">Giấy ĐKKD số: </span>{' '}
                {AppConfigs.numberDKKD}
              </div>
              <div>
                <span className="LandingPageFooter_Title me-2">Nơi cấp: </span>
                {AppConfigs.licensingAgency}
              </div>
              <div>
                <span className="LandingPageFooter_Title me-2">Ngày cấp: </span>
                {AppConfigs.licenseDate}
              </div>

              <div>
                <span className="LandingPageFooter_Title me-2">Trụ sở chính: </span>{' '}
                {AppConfigs.headquarters}
              </div>
            </div>
          </div>
          <div className="col-md-5 LandingPageFooter_Section mb-4">
            <div className="row">
              <div className="col">
                <div className="LandingPageFooter_Title font-size-h5">Sản phẩm</div>
                <div className="LandingPageFooter_Nav">
                  <div
                    className="LandingPageFooterNavItem"
                    onClick={() => {
                      window.open('https://i-ca.vn/');
                    }}
                  >
                    <span>Chữ ký số</span>
                  </div>
                  <div
                    className="LandingPageFooterNavItem"
                    onClick={() => {
                      window.open('https://vietinvoice.vn/');
                    }}
                  >
                    <span>Hóa đơn điện tử</span>
                  </div>
                  <div
                    className="LandingPageFooterNavItem"
                    onClick={() => {
                      window.open('https://ibh.ivan.vn/');
                    }}
                  >
                    <span>Bảo hiểm Xã hội điện tử</span>
                  </div>
                  <div
                    className="LandingPageFooterNavItem"
                    onClick={() => {
                      window.open('https://hopdongdientu.vn/');
                    }}
                  >
                    <span>Hợp đồng điện tử</span>
                  </div>
                  <div className="LandingPageFooterNavItem">
                    <span>Chữ ký số HSM</span>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="LandingPageFooter_Title font-size-h5">Chính sách</div>
                <div className="LandingPageFooter_Nav">
                  <div className="LandingPageFooterNavItem" onClick={() => {}}>
                    <span>Chính sách bảo mật thông tin</span>
                  </div>
                  <div className="LandingPageFooterNavItem" onClick={() => {}}>
                    <span>Chính sách sử dụng, hoàn hủy dịch vụ</span>
                  </div>
                  <div className="LandingPageFooterNavItem" onClick={() => {}}>
                    <span>Chính sách thanh toán</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 LandingPageFooter_Section mb-4">
            <div className="LandingPageFooter_Title font-size-h5">Hỗ trợ khách hàng</div>
            <div className="LandingPageFooter_Nav">
              <div className="FooterDashboardUnderline">
                <i className="fas fa-envelope text-primary"></i>{' '}
                <a
                  target="_blank"
                  className="text-primary ms-3"
                  href={`mailto:${AppConfigs.emailSupport}`}
                >
                  <span>{AppConfigs.emailSupport}</span>
                </a>
              </div>
              <div className="FooterDashboardUnderline">
                <i className="fa-solid fa-phone text-primary"></i>
                <a href={`tel:${AppConfigs.phoneNumber}`}>
                  <span className="text-primary ms-3">{AppConfigs.phoneNumber}</span>
                </a>
              </div>

              <div className="FooterDashboardUnderline">
                <i className="fa-solid fa-headset text-primary"></i>
                <a href={`tel:${AppConfigs.callCenterNumber}`}>
                  <span className="text-primary ms-3">{AppConfigs.callCenterNumber}</span>
                </a>
              </div>

              <div className="d-flex gap-4 mt-4">
                <a href={`tel:${AppConfigs.callCenterNumber}`} className="hover-opacity-90">
                  <img src={AppResource.icons.icCall} />
                </a>
                <a target="_blank" href="https://www.facebook.com" className="hover-opacity-90">
                  <img src={AppResource.icons.icFacebook} />
                </a>
                <a
                  target="_blank"
                  href={'https://zalo.me/' + AppConfigs.phoneNumber}
                  className="hover-opacity-90"
                >
                  <img src={AppResource.icons.icZalo} />
                </a>
              </div>
              {config.urlBCT && !_.isEmpty(config.urlBCT) && (
                <a href={config.urlBCT} target="_blank">
                  <img src={AppResource.icons.icBCT} width={150} style={{ marginLeft: '-5px' }} />
                </a>
              )}
            </div>
          </div>
          <div className="LandingPageFooter_Copyright border-top mt-10">
            Bản quyền © 2023{' '}
            <span
              role="button"
              className="text-primary"
              onClick={() => {
                router.navigate(AppConfigs.icorpHomepage);
              }}
            >
              ICORP
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageFooter;
