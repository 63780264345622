import { unwrapResult } from '@reduxjs/toolkit';
import Tippy from '@tippyjs/react/headless';
import invoiceApi from 'api/invoiceApi';
import store from 'app/store';
import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import ViewInvoiceExport from 'features/Invoice/components/ViewInvoiceExport';
import { thunkGetAllInInvoice } from 'features/Invoice/invoiceSlice';
import DownLoadInvoice from 'features/Invoice/screens/components/DownLoadInvoice';
import DropdownFilterInInvoice from 'features/Invoice/screens/components/DropdownFilterInInvoice';
import LookUpInvoice from 'features/Invoice/screens/components/LookUpInvoices';
import AddLabel from 'features/Tag/components/AddLabel';
import ModalLabeled from 'features/Tag/components/ModalLabeled';
import {
  thunkCreateTagList,
  thunkDeleteTagList,
  thunkGetTagList,
  thunkUpdateTagList,
} from 'features/Tag/tagSlice';
import { useFormik } from 'formik';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
  KTBSDropdownDirections,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTTooltip, { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip';
import VIBodyIcons from 'general/components/VietInvoice/VIBodyIcons';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VILoading from 'general/components/VietInvoice/VILoading';
import VIModal from 'general/components/VietInvoice/VIModal';
import VIModalResize from 'general/components/VietInvoice/VIModalResize';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import AppResource from 'general/constants/AppResource';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import InvoiceHelper from 'general/helpers/InvoiceHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import InvoiceTransfer from '../InvoiceTransfer';
import ModalAccountingDate from '../ModalAccountingDate/ModalAccountingDate';
import ModalFeedback from '../ModalFeedback';
import NoteInvoice from '../ModalNoteInvoice';
import ModalPayment from '../ModalPayment/ModalPayment';
import ViewListInvoiceNote from '../ViewListInvoiceNote';
import './style.scss';
import ManualInput from '../../../components/ManualDataEntry';
import { useWindowSize } from '@uidotdev/usehooks';

function InInvoice(props) {
  const { tabsActive, dataChecked } = props;
  const { t } = useTranslation();
  // label
  const [showModalLabeled, setShowModalLabeled] = useState(false);
  const [showModalAddLabeled, setShowModalAddLabeled] = useState(false);
  const [modalShowEditLabel, setShowModalEditLabel] = useState(false);
  const [currentChangeLabel, setCurrentChangeLabel] = useState();
  const [showModalDetailInvoices, setShowModalDetailInvoices] = useState(false);
  const [showModalNoteInvoices, setShowModalNoteInvoices] = useState(false);
  const [showModalInvoiceTransfer, setShowModalInvoiceTransfer] = useState(false);
  const [showModalAccountingDate, setShowModalAccountingDate] = useState(false);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [showModalFeedback, setShowModalFeedback] = useState(false);
  const [listCurrentLabels, setListCurrentLabels] = useState([]);
  const [modalCheckShowing, setModalCheckShowing] = useState(false);
  const [currentInvoices, setCurrentInvoices] = useState();
  const [showModalDeleteLabel, setShowModalDeleteLabel] = useState(false);
  const [showModalDownLoad, setShowModalDownLoad] = useState(false);
  const [modalInvoiceExportShowing, setModalInvoiceExportShowing] = useState(false);
  const [modalLookUpInvoice, setModalLookUpInvoice] = useState(false);
  const [modalViewListNoteInvoice, setModalViewListNoteInvoice] = useState(false);
  const [showModalManualInput, setShowModalManualInput] = useState(false);
  const [currentEdit, setCurrentEdit] = useState();
  const [dataCheck, setDataCheck] = useState([]);
  const dispatch = useDispatch();
  const account = useAccount();
  const [filters, setFilters] = useState(Global.gFiltersInputInvoice);
  useEffect(() => {
    if (!_.isEqual(Global.gFiltersInputInvoice, filters)) {
      setFilters(Global.gFiltersInputInvoice);
    }
  }, [Global.gFiltersInputInvoice]);
  const {
    listInputInvoice,
    isGettingInInvoiceList,
    totalInputInvoice,
    totalAmountBeforeVATInputInvoice,
    totalAmountVATInputInvoice,
    totalFinalAmountInputInvoice,
  } = useSelector((state) => state?.invoice);
  const Account = useSelector((state) => state?.auth?.account);
  const [organizationId, setOrganizationId] = useState();
  const [dataCurrentAddLabel, setDataCurrentAddLabel] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const dataUpload = useRef();

  useEffect(() => {
    setDataCurrentAddLabel(dataCheck);
  }, [dataCheck]);

  const [currentDetail, setCurrentDetail] = useState();
  useEffect(() => {
    if (_.isEmpty(listInputInvoice)) {
    }
    if (account.organizationId) {
      dispatch(thunkGetAllInInvoice(filters));
    }
    if (account.organizationDepartmentId) {
      dispatch(thunkGetAllInInvoice(filters));
    }
    forMikAccountingDate.setValues({
      ...forMikAccountingDate?.values,
      accountingPersonName: account?.fullname,
    });
  }, [filters, account.organizationId, account.organizationDepartmentId, account.accountId]);
  useEffect(() => {
    Global.gFiltersInputInvoice = {
      ...Global.gFiltersInputInvoice,
      page: 0,
    };
  }, [account.organizationId, account.organizationDepartmentId]);
  let idDataInvoices = [];
  let currentDeleteLabel = [];
  for (let i = 0; i < dataCurrentAddLabel?.length; i++) {
    idDataInvoices.push(dataCurrentAddLabel[i]?.invoiceId);
    for (let j = 0; j < dataCurrentAddLabel[i]?.Tags?.length; j++) {
      if (currentDeleteLabel?.length <= 0) {
        currentDeleteLabel.push(dataCurrentAddLabel[i]?.Tags[j]);
      } else {
        let check = true;
        for (let k = 0; k < currentDeleteLabel?.length; k++) {
          if (currentDeleteLabel[k]?.tagId === dataCurrentAddLabel[i]?.Tags[j]?.tagId) {
            check = false;
            break;
          }
        }
        if (check) {
          currentDeleteLabel.push(dataCurrentAddLabel[i]?.Tags[j]);
        }
      }
    }
  }

  let idTag = [];
  for (let i = 0; i < listCurrentLabels?.length; i++) {
    idTag.push(listCurrentLabels[i]?.tagId);
  }
  const IconArr = [
    {
      icon: <i className="fa-regular fa-note-sticky"></i>,
      name: t('Note'),
    },
    {
      icon: <i className="fa-solid fa-check-double"></i>,
      name: t('Review'),
    },
  ];
  if (dataCurrentAddLabel.some((e) => e?.Tags?.length > 0)) {
    IconArr.push(
      {
        icon: <img src={AppResource.icons.icLabelVietInvoice} alt="logo" />,
        name: t('Labeled'),
      },
      {
        icon: <img src={AppResource.icons.icUnLabelVietInvoice} alt="logo" />,
        name: t('RemoveTheLabel'),
      }
    );
  }
  if (account?.role?.permissionCodes?.includes('TRANSFER_INPUT_INVOICE')) {
    IconArr.push({
      icon: <i className="fa-regular fa-file-export"></i>,
      name: t('InvoiceTransfer'),
    });
  }

  const handleActions = (row) => {
    let action = [];
    if (!row?.InvoiceValidate) {
      action.push(
        {
          iconElement: <i className="fa-regular fa-check-double"></i>,
          text: t('InvoiceCheck'),
          name: 'CHECK',
          click: () => {
            handleCheck();
            setCurrentCheck([row?.invoiceId]);
          },
        },
        {
          iconElement: row?.accountingDate ? (
            <i className="fa-regular fa-rectangle-xmark"></i>
          ) : (
            <i className="fa-regular fa-circle-check"></i>
          ),
          text: row?.accountingDate ? t('MarkUnaccountedFor') : t('AccountedForMark'),
          name: 'ACCOUNTED_MARK_INPUT_INVOICE',
          click: () => {
            if (row?.accountingDate) {
              handleDeleteAccounting(row);
              setCurrentInvoices(row);
            } else {
              setShowModalAccountingDate(true);
              setCurrentInvoices(row);
            }
          },
        },
        {
          iconElement: <i className="fa-regular fa-file-export"></i>,
          text: t('InvoiceTransfer'),
          click: () => {
            setCurrentInvoices(row);
            setShowModalInvoiceTransfer(true);
            // handleReplicateCompanyTitle(row);
          },
          name: 'TRANSFER_INPUT_INVOICE',
        },
        {
          iconElement: <i className="fa-regular fa-trash-can"></i>,
          text: t('Delete'),
          click: () => {
            handleDeleteInvoice(row);
          },
          name: 'DELETE_AND_RESTORE_INPUT_INVOICE',
        }
      );
      if (row?.debtPayment > 0) {
        action.push({
          iconElement:
            row?.statusPayment !== 'Thanh toán toàn phần' ? (
              <i className="fa-regular fa-badge-dollar"></i>
            ) : (
              <i className="fa-regular fa-handshake-slash"></i>
            ),
          text:
            row?.statusPayment !== 'Thanh toán toàn phần'
              ? t('CheckoutPayment')
              : t('UncheckPayment'),
          name: 'PAID_MARK_INPUT_INVOICE',
          click: () => {
            if (row?.statusPayment === 'Thanh toán toàn phần') {
              setCurrentInvoices(row);
              handleUnPayment(row);
            } else {
              setCurrentInvoices(row);
              setShowModalPayment(true);
              forMikPayment.setValues({
                ...forMikPayment.values,
                paymentMoney: row?.debtPayment,
                total: row?.debtPayment,
                noDatePayment: row?.partnerCompany?.expirePayment,
              });
            }
          },
        });
      }
      if (row?.isManualInput) {
        action.push({
          iconElement: <i className="fa-regular fa-pen-to-square"></i>,
          text: t('Edit'),
          name: 'EDIT',
          click: async () => {
            const res = await invoiceApi.getDetailInvoice(row?.invoiceId);
            if (res?.result === 'success') {
              setShowModalManualInput(true);
              setCurrentEdit(res?.invoice);
            }
          },
        });
      }
    } else {
      if (
        !row?.InvoiceValidate?.checkResultBuyerAddress ||
        !row?.InvoiceValidate?.checkResultBuyerName ||
        !row?.InvoiceValidate?.checkResultBuyerTaxCode ||
        !row?.InvoiceValidate?.checkResultHasCQTRecord ||
        !row?.InvoiceValidate?.checkResultHasInvoiceCode ||
        !row?.InvoiceValidate?.checkResultSellerAddress ||
        !row?.InvoiceValidate?.checkResultSellerName ||
        !row?.InvoiceValidate?.checkResultSignatureCQT ||
        !row?.InvoiceValidate?.checkResultSignatureNCC
      ) {
        action.push(
          {
            iconElement: <i className="fa-regular fa-message-smile"></i>,
            text: t('SendFeedbackToNCC'),
            name: 'SEND_FEEDBACK_TO_NCC',
            click: () => {
              setShowModalFeedback(true);
              setCurrentInvoices(row);
              forMikFeedBack.setValues({
                ...forMikFeedBack.values,
                invoiceId: row?.invoiceId,
                emails: row?.partnerCompany?.contactEmail ?? '',
                html: `<p style="margin-left:0px;"><strong>Kính gửi: ${
                  row?.sellerName
                }</strong></p><p style="margin-left:0px;">${
                  account.organizationName + ' ' + 'đã nhận được hóa đơn dưới đây'
                }:</p><p style="margin-left:0px;">- Số hóa đơn: <strong>${
                  row?.invoiceNumber
                }</strong></p><p style="margin-left:0px;">- Mẫu số hóa đơn: <strong>${
                  row?.invoiceTypeCode
                }</strong></p><p style="margin-left:0px;">- Ký hiệu hóa đơn: <strong>${
                  row?.serial
                }</strong></p><p style="margin-left:0px;">&nbsp;</p><p style="margin-left:0px;">Sau khi kiểm tra hóa đơn, chúng tôi nhận thấy các thông tin sau chưa chính xác. Đề nghị quý công ty xem xét lại:</p><p style="margin-left:0px;">&nbsp;</p>${
                  !row?.InvoiceValidate?.checkResultSellerAddress
                    ? `<p style="margin-left:0px;">-Sai địa chỉ người bán</p>`
                    : ''
                }${
                  !row?.InvoiceValidate?.checkResultSellerName
                    ? `<p style="margin-left:0px;">-Sai tên người bán</p>`
                    : ''
                }${
                  !row?.InvoiceValidate?.checkResultBuyerAddress
                    ? `<p style="margin-left:0px;">-Sai địa chỉ người mua</p>`
                    : ''
                }${
                  !row?.InvoiceValidate?.checkResultBuyerName
                    ? `<p style="margin-left:0px;">-Sai tên người mua</p>`
                    : ''
                }${
                  !row?.InvoiceValidate?.checkResultBuyerTaxCode
                    ? `<p style="margin-left:0px;">-Sai mã số thuế người mua</p>`
                    : ''
                }${
                  !row?.InvoiceValidate?.checkResultHasCQTRecord
                    ? `<p style="margin-left:0px;">
                  -Hóa đơn chưa tổn tại trên hệ thống tra cứu Cơ quan thuế tại
                  thời điểm kiểm tra
                </p>`
                    : ''
                }${
                  !row?.InvoiceValidate?.checkResultHasInvoiceCode
                    ? `<p style="margin-left:0px;">-Chưa cấp mã hóa đơn</p>`
                    : ''
                }${
                  !row?.InvoiceValidate?.checkResultSignatureCQT
                    ? ` <p style="margin-left:0px;">-Chưa được ký bởi Cơ quan thuế</p>`
                    : ''
                }${
                  !row?.InvoiceValidate?.checkResultSignatureNCC
                    ? `<p style="margin-left:0px;">-Chữ ký điện tử NCC không hợp lệ</p>`
                    : ''
                }<p style="margin-left:0px;">&nbsp;</p><p style="margin-left:0px;">Trân trọng kính chào!</p><p style="margin-left:0px;">&nbsp;</p>`,
              });
            },
          },
          {
            iconElement: row?.accountingDate ? (
              <i className="fa-regular fa-rectangle-xmark"></i>
            ) : (
              <i className="fa-regular fa-circle-check"></i>
            ),
            text: row?.accountingDate ? t('MarkUnaccountedFor') : t('AccountedForMark'),
            name: 'ACCOUNTED_MARK_INPUT_INVOICE',
            click: () => {
              if (row?.accountingDate) {
                handleDeleteAccounting(row);
                setCurrentInvoices(row);
              } else {
                setShowModalAccountingDate(true);
                setCurrentInvoices(row);
              }
            },
          },

          {
            iconElement: <i className="fa-regular fa-file-export"></i>,
            text: t('InvoiceTransfer'),
            click: () => {
              setCurrentInvoices(row);
              setShowModalInvoiceTransfer(true);
              // handleReplicateCompanyTitle(row);
            },
            name: 'TRANSFER_INPUT_INVOICE',
          },
          {
            iconElement: <i className="fa-regular fa-trash-can"></i>,
            text: t('Delete'),
            click: () => {
              handleDeleteInvoice(row);
            },
            name: 'DELETE_AND_RESTORE_INPUT_INVOICE',
          }
        );
        if (row?.debtPayment > 0) {
          action.push({
            iconElement:
              row?.statusPayment !== 'Thanh toán toàn phần' ? (
                <i className="fa-regular fa-badge-dollar"></i>
              ) : (
                <i className="fa-regular fa-handshake-slash"></i>
              ),
            text:
              row?.statusPayment !== 'Thanh toán toàn phần'
                ? t('CheckoutPayment')
                : t('UncheckPayment'),
            name: 'PAID_MARK_INPUT_INVOICE',
            click: () => {
              if (row?.statusPayment === 'Thanh toán toàn phần') {
                setCurrentInvoices(row);
                handleUnPayment(row);
              } else {
                setCurrentInvoices(row);
                setShowModalPayment(true);
                forMikPayment.setValues({
                  ...forMikPayment.values,
                  paymentMoney: row?.debtPayment,
                  total: row?.debtPayment,
                  noDatePayment: row?.partnerCompany?.expirePayment,
                });
              }
            },
          });
        }
        if (row?.isManualInput) {
          action.push({
            iconElement: <i className="fa-regular fa-pen-to-square"></i>,
            text: t('Edit'),
            name: 'EDIT',
            click: async () => {
              const res = await invoiceApi.getDetailInvoice(row?.invoiceId);
              if (res?.result === 'success') {
                setShowModalManualInput(true);
                setCurrentEdit(res?.invoice);
              }
            },
          });
        }
      } else {
        action.push(
          {
            iconElement: row?.accountingDate ? (
              <i className="fa-regular fa-rectangle-xmark"></i>
            ) : (
              <i className="fa-regular fa-circle-check"></i>
            ),
            text: row?.accountingDate ? t('MarkUnaccountedFor') : t('AccountedForMark'),
            click: () => {
              if (row?.accountingDate) {
                handleDeleteAccounting(row);
                setCurrentInvoices(row);
              } else {
                setShowModalAccountingDate(true);
                setCurrentInvoices(row);
              }
            },
            name: 'ACCOUNTED_MARK_INPUT_INVOICE',
          },
          {
            iconElement: <i className="fa-regular fa-file-export"></i>,
            text: t('InvoiceTransfer'),
            click: () => {
              setCurrentInvoices(row);
              setShowModalInvoiceTransfer(true);
              // handleReplicateCompanyTitle(row);
            },
            name: 'TRANSFER_INPUT_INVOICE',
          },
          {
            iconElement: <i className="fa-regular fa-trash-can"></i>,
            text: t('Delete'),
            click: () => {
              handleDeleteInvoice(row);
            },
            name: 'DELETE_AND_RESTORE_INPUT_INVOICE',
          }
        );
        if (row?.debtPayment > 0) {
          action.push({
            iconElement:
              row?.statusPayment !== 'Thanh toán toàn phần' ? (
                <i className="fa-regular fa-badge-dollar"></i>
              ) : (
                <i className="fa-regular fa-handshake-slash"></i>
              ),
            text:
              row?.statusPayment !== 'Thanh toán toàn phần'
                ? t('CheckoutPayment')
                : t('UncheckPayment'),
            name: 'PAID_MARK_INPUT_INVOICE',
            click: () => {
              if (row?.statusPayment === 'Thanh toán toàn phần') {
                setCurrentInvoices(row);
                handleUnPayment(row);
              } else {
                setCurrentInvoices(row);
                setShowModalPayment(true);
                forMikPayment.setValues({
                  ...forMikPayment.values,
                  paymentMoney: row?.debtPayment,
                  total: row?.debtPayment,
                  noDatePayment: row?.partnerCompany?.expirePayment,
                });
              }
            },
          });
        }
        if (row?.isManualInput) {
          action.push({
            iconElement: <i className="fa-regular fa-pen-to-square"></i>,
            text: t('Edit'),
            name: 'EDIT',
            click: async () => {
              const res = await invoiceApi.getDetailInvoice(row?.invoiceId);
              if (res?.result === 'success') {
                setShowModalManualInput(true);
                setCurrentEdit(res?.invoice);
              }
            },
          });
        }
      }
    }

    let newAction = [];
    const dataSendTo = action?.filter(
      (item) => item?.name === 'SEND_FEEDBACK_TO_NCC' || item?.name === 'EDIT'
    );
    const actions = action?.filter((item) => account.checkPermission(item?.name));

    if (dataSendTo?.length > 0 && account.role?.name !== 'Quản trị viên') {
      newAction.push(...dataSendTo);
    }
    if (actions?.length > 0) {
      newAction.push(...actions);
    }

    if (newAction?.length === 0) {
      return null;
    } else {
      return newAction;
    }
  };
  const columns = useMemo(
    () => [
      {
        name: t('Label'),
        width: '67px',
        center: true,
        cell: (row) => {
          return (
            <div className="d-flex align-items-center mb-1">
              {row?.Tags?.length === 1 ? (
                <KTTooltip
                  text={
                    <div>
                      {row?.Tags?.map((e, index) => (
                        <div key={index}>{e?.name}</div>
                      ))}
                    </div>
                  }
                  position={KTTooltipPositions.auto}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="12"
                    viewBox="0 0 18 14"
                    fill="none"
                    onClick={() => {
                      setShowModalLabeled(true), setListCurrentLabels(row?.Tags);
                      setDataCurrentAddLabel([row]);
                    }}
                  >
                    <path
                      d="M0 1C0 0.447716 0.447715 0 1 0H12.6706C13.0002 0 13.3086 0.162442 13.4951 0.434246L17.6118 6.43425C17.8457 6.77515 17.8457 7.22485 17.6118 7.56575L13.4951 13.5658C13.3086 13.8376 13.0002 14 12.6706 14H1C0.447716 14 0 13.5523 0 13V1Z"
                      fill={row?.Tags?.[0]?.color}
                    />
                  </svg>
                </KTTooltip>
              ) : row?.Tags?.length > 1 ? (
                <KTTooltip
                  text={
                    <div>
                      {row?.Tags?.map((e, index) => (
                        <div key={index}>{e?.name}</div>
                      ))}
                    </div>
                  }
                  position={KTTooltipPositions.auto}
                >
                  <div
                    className="p-2"
                    onClick={() => {
                      setShowModalLabeled(true), setListCurrentLabels(row?.Tags);
                      setDataCurrentAddLabel([row]);
                    }}
                  >
                    <img style={{ height: '12px' }} src={AppResource.icons.icTags} />
                  </div>
                </KTTooltip>
              ) : (
                <KTTooltip text={t('Stickers')} position={KTTooltipPositions.auto}>
                  <div
                    className="p-2"
                    onClick={() => {
                      setShowModalLabeled(true);
                      setDataCurrentAddLabel([row]);
                    }}
                  >
                    <img style={{ height: '12px' }} src={AppResource.icons.icNoTag} />
                  </div>
                </KTTooltip>
              )}
            </div>
          );
        },
      },
      {
        name: t('File'),
        minWidth: '60px',
        center: true,
        cell: (row) => {
          return (
            <div>
              {row?.InvoiceAttachs?.length > 0 ? (
                <Tippy
                  interactive
                  placement="auto"
                  delay={[0, 10]}
                  offset={[-10, 0]}
                  render={() => (
                    <div
                      className="bg-white tippy-tooltip p-5"
                      style={{
                        boxShadow: '0px 5px 10px 0px rgba(44, 63, 88, 0.1)',
                        border: '1px solid var(--system-stroke, #e8e9eb)',
                      }}
                    >
                      {row?.InvoiceAttachs?.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-between align-items-center bg-white mb-2 py-2 rounded"
                        >
                          <div className="w-100 d-flex align-items-center justify-content-between">
                            <div style={{ width: '300px' }}>{item?.fileName}</div>
                            <div
                              className="d-flex align-items-center justify-content-end"
                              style={{ width: '100px', textAlign: 'end' }}
                            >
                              <div className="me-2">({Math.floor(item?.size / 1024)} KB)</div>

                              <i
                                className="fa-regular fa-eye cursor-pointer"
                                onClick={() =>
                                  window.open(process.env.REACT_APP_BASE_URL + item.url)
                                }
                              ></i>
                            </div>
                          </div>
                        </div>
                      ))}
                      <input
                        type="file"
                        multiple
                        className="d-none"
                        id="input-add-file"
                        onChange={async (e) => {
                          if (e?.target?.files[0]?.size / 1048576 >= 5) {
                            ToastHelper.showError('Dung lượng tệp tải lên vượt quá 5MB');
                            e.target.value = null;
                          } else {
                            const form = new FormData();

                            for (var i = 0; i < e?.target?.files.length; i++) {
                              form.append('attachFiles', e?.target?.files[i]);
                            }

                            if (e?.target?.files?.length > 0) {
                              const result = await invoiceApi.upFileInvoice(
                                dataUpload.current.invoiceId,
                                form
                              );
                              if (result.result === 'success') {
                                dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
                                ToastHelper.showSuccess('Thêm tệp đính kèm thành công');
                              }
                            }
                          }
                        }}
                      />
                      <VIButton
                        text="Thêm tệp đính kèm"
                        className="w-100 btn-grey mt-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          dataUpload.current = row;
                          setCurrentInvoices(row);
                          document.getElementById('input-add-file').click();
                        }}
                      />
                    </div>
                  )}
                >
                  <i
                    className="fa-regular fa-paperclip-vertical text-primary"
                    onClick={() => {
                      setShowModalDetailInvoices(true);
                      setCurrentDetail(row);
                    }}
                  ></i>
                </Tippy>
              ) : (
                <div
                  className="p-5"
                  onClick={() => {
                    dataUpload.current = row;
                    setCurrentInvoices(row);
                    document.getElementById('input-add-file').click();
                  }}
                >
                  <input
                    type="file"
                    multiple
                    className="d-none"
                    id="input-add-file"
                    onChange={async (e) => {
                      if (e?.target?.files[0]?.size / 1048576 >= 5) {
                        ToastHelper.showError('Dung lượng tệp tải lên vượt quá 5MB');
                        e.target.value = null;
                      } else {
                        const form = new FormData();
                        for (var i = 0; i < e?.target?.files.length; i++) {
                          form.append('attachFiles', e?.target?.files[i]);
                        }

                        if (e?.target?.files?.length > 0) {
                          const result = await invoiceApi.upFileInvoice(
                            dataUpload.current.invoiceId,
                            form
                          );
                          if (result.result === 'success') {
                            dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
                            ToastHelper.showSuccess('Thêm tệp đính kèm thành công');
                          }
                        }
                      }
                    }}
                  />
                  <KTTooltip text={t('Attachments')} position={KTTooltipPositions.auto}>
                    <i className="fa-regular fa-paperclip-vertical"></i>
                  </KTTooltip>
                </div>
              )}
            </div>
          );
        },
      },
      {
        name: t('InvoiceNumber'),
        minWidth: '135px',
        selector: (row) => row.invoiceNumber,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceNumber ? row?.invoiceNumber : ''}
            </p>
          );
        },
      },
      {
        name: t('Denominator'),
        width: '98px',
        selector: (row) => row.invoiceTypeCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceTypeCode ? row?.invoiceTypeCode : ''}
            </p>
          );
        },
      },
      {
        name: t('Symbol'),
        width: '100px',
        selector: (row) => row.serial,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.serial ? row?.serial : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceDate'),
        width: '150px',
        selector: (row) => row.invoiceDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceDate ? Utils.formatDateTime(row?.invoiceDate, 'DD/MM/yyyy' ?? '') : ''}
            </p>
          );
        },
      },
      {
        name: t('SignDate'),
        width: '115px',
        selector: (row) => row.signDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.signDate ? Utils.formatDateTime(row?.signDate, 'DD/MM/yyyy') : ''}
            </p>
          );
        },
      },
      {
        name: t('CodeIssueDate'),
        width: '140px',
        selector: (row) => row.providedCodeDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.providedCodeDate
                ? Utils.formatDateTime(row?.providedCodeDate, 'DD/MM/yyyy')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('UnitCode'),
        width: '118px',
        selector: (row) => row.sellerCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.sellerCode ? row?.sellerCode : ''}
            </p>
          );
        },
      },
      {
        name: t('SellerTaxCode'),
        width: '195px',
        selector: (row) => row.sellerTaxCode,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.sellerTaxCode ? row?.sellerTaxCode : ''}
            </p>
          );
        },
      },
      {
        name: t('SellerInformations'),
        minWidth: '220px',
        selector: (row) => row.sellerName,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.sellerName ? row?.sellerName : ''}
            </p>
          );
        },
      },
      {
        name: t('SellerAddress'),
        minWidth: '250px',
        selector: (row) => row.sellerAddress,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.sellerAddress ? row?.sellerAddress : ''}
            </p>
          );
        },
      },
      {
        name: t('TotalPreTaxMoney'),
        minWidth: '215px',
        selector: (row) => row.amountBeforeVat,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.amountBeforeVat
                ? Utils.formatCurrency(row?.amountBeforeVat)
                : row?.amountBeforeVat}
            </p>
          );
        },
      },
      {
        name: t('taxMoney'),
        minWidth: '116px',
        selector: (row) => row.amountVat,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.amountVat ? Utils.formatCurrency(row?.amountVat) : row?.amountVat}
            </p>
          );
        },
      },
      {
        name: t('TotalPayment'),
        minWidth: '214px',
        selector: (row) => row.finalAmount,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.finalAmount ? Utils.formatCurrency(row?.finalAmount) : row?.finalAmount}
            </p>
          );
        },
      },
      {
        name: t('Currency'),
        selector: (row) => row.paymentCurrency,
        sortable: true,
        minWidth: '113px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.paymentCurrency ? row?.paymentCurrency : ''}
            </p>
          );
        },
      },
      {
        name: t('ExchangeRate'),
        selector: (row) => row.paymentExchangeRate,
        sortable: true,
        width: '90px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.paymentExchangeRate ? row?.paymentExchangeRate : ''}
            </p>
          );
        },
      },
      {
        name: t('TotalAmountConverted'),
        width: '182px',
        selector: (row) => row.finalAmountExchange,
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p className="font-weight-normal m-0 text-maxline-3 text-align-end">
              {row?.finalAmountExchange ? Utils.formatNumber(row?.finalAmountExchange) : ''}
            </p>
          );
        },
      },
      {
        name: t('invoiceStatus'),
        width: '200px',
        selector: (row) => row.statusInvoiceText,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.statusInvoiceText ? row?.statusInvoiceText : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceAdjusted/Replaced'),
        minWidth: '290px',

        cell: (row) => {
          return row?.statusInvoiceText === 'Hoá đơn thay thế' ? (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {`Thay thế cho hóa đơn ký hiệu ${
                row?.hdgocData?.khmshdon ? row?.hdgocData?.khmshdon : ''
              }${row?.hdgocData?.khhdon ? row?.hdgocData?.khhdon : ''}, số ${
                row?.hdgocData?.shdon ? row?.hdgocData?.shdon : ''
              }, ngày ${
                row?.hdgocData?.tdlap
                  ? Utils.formatDateTime(row?.hdgocData?.tdlap, 'DD/MM/yyyy' ?? '')
                  : ''
              }`}
            </p>
          ) : row?.statusInvoiceText === 'Hoá đơn điều chỉnh' ? (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {`Điều chỉnh cho hóa đơn ký hiệu ${
                row?.hdgocData?.khmshdon ? row?.hdgocData?.khmshdon : ''
              }${row?.hdgocData?.khhdon ? row?.hdgocData?.khhdon : ''}, số ${
                row?.hdgocData?.shdon ? row?.hdgocData?.shdon : ''
              }, ngày ${
                row?.hdgocData?.tdlap
                  ? Utils.formatDateTime(row?.hdgocData?.tdlap, 'DD/MM/yyyy' ?? '')
                  : ''
              }`}
            </p>
          ) : (
            ''
          );
        },
      },
      {
        name: t('TestResults'),
        width: '320px',
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              {Utils.getInvoiceStatusValidate(
                row?.InvoiceValidate,
                row?.serial,
                row?.isManualInput,
                row
              )}
            </div>
          );
        },
      },
      {
        name: t('CheckTaxRates'),
        minWidth: '195px',
        selector: (row) => row.statusTax,
        sortable: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.statusTax ?? ''}
            </p>
          );
        },
      },
      {
        name: t('EmailToReceiveInvoice'),
        minWidth: '190px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.OrganizationDepartment
                ? store.getState()?.auth.account?.OrganizationDepartment?.invoiceMailbox
                : store.getState()?.auth.account?.Organization?.invoiceMailbox ?? ''}
            </p>
          );
        },
      },
      {
        name: t('EmailSendingStatus'),
        width: '190px',
        // center: true,
        // selector: (row) => row.statusEmail,
        // sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.isSendMailSupplier ? 'Đã gửi' : ''}
            </p>
          );
        },
      },
      {
        name: t('LookUpOriginalInvoice'),
        width: '200px',
        center: true,

        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3"
              style={{ width: '100%' }}
            >
              <span
                className="d-flex align-items-center py-5 px-4"
                style={{ color: '#22C55E' }}
                onClick={(e) => {
                  setCurrentInvoices(row), setModalLookUpInvoice(true);
                }}
              >
                <i className="fa-regular fa-lightbulb-on me-1" style={{ color: '#22C55E' }}></i>
                Thông tin tra cứu
              </span>
            </p>
          );
        },
      },
      {
        name: t('PaymentDueDate'),
        width: '185px',
        selector: (row) => row.reminderPaymentDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.reminderPaymentDate
                ? Utils.formatDateTime(row?.reminderPaymentDate, 'DD/MM/YYYY')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('PaymentTerm'),
        width: '168px',
        selector: (row) => row.expiredPaymentDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.expiredPaymentDate
                ? Utils.formatDateTime(row?.expiredPaymentDate, 'DD/MM/YYYY')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('TaxCodeCompany'),
        minWidth: '130px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.Organization?.OrganizationHQ
                ? store.getState()?.auth.account?.Organization?.OrganizationHQ?.taxCode
                : store.getState()?.auth.account?.Organization?.taxCode ?? ''}
            </p>
          );
        },
      },
      {
        name: t('Company'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.Organization?.OrganizationHQ
                ? store.getState()?.auth.account?.Organization?.OrganizationHQ?.organizationName
                : store.getState()?.auth.account?.Organization?.organizationName ?? ''}
            </p>
          );
        },
      },
      {
        name: t('TaxCodeBrand'),
        minWidth: '150px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.Organization?.organizationType === 'BRANCH'
                ? store.getState()?.auth.account?.Organization?.taxCode
                : '' ?? ''}
            </p>
          );
        },
      },
      {
        name: t('Branch'),
        minWidth: '250px',
        // selector: (row) => row.supplier,
        // sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.Organization?.organizationType === 'BRANCH'
                ? store.getState()?.auth.account?.Organization?.organizationName
                : '' ?? ''}
            </p>
          );
        },
      },
      {
        name: t('PurchaserName'),
        minWidth: '250px',
        selector: (row) => row.buyerName,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerName ? row?.buyerName : ''}
            </p>
          );
        },
      },
      {
        name: t('BuyerAddress'),
        minWidth: '250px',
        selector: (row) => row.buyerAddress,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerAddress ? row?.buyerAddress : ''}
            </p>
          );
        },
      },
      {
        name: t('Buyer'),
        minWidth: '200px',
        selector: (row) => row.buyerPersonName,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.buyerPersonName ? row?.buyerPersonName : ''}
            </p>
          );
        },
      },
      {
        name: t('Department'),
        minWidth: '250px',
        // selector: (row) => row.supplier,
        // sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {store.getState()?.auth.account?.OrganizationDepartment?.departmentName ?? ''}
            </p>
          );
        },
      },
      {
        name: t('NumberVouchers'),
        minWidth: '145px',
        selector: (row) => row.noDocument,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.noDocument ? row?.noDocument : ''}
            </p>
          );
        },
      },
      {
        name: t('AccountingDate'),
        minWidth: '168px',
        selector: (row) => row.accountingDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.accountingDate ? Utils.formatDateTime(row.accountingDate, 'DD/MM/YYYY') : ''}
            </p>
          );
        },
      },
      {
        name: t('Accountants'),
        minWidth: '180px',
        selector: (row) => row.accountingPersonName,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.accountingPersonName ? row?.accountingPersonName : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceReceiptDate'),
        minWidth: '195px',
        selector: (row) => row.invoiceReceiveDate,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceReceiveDate
                ? Utils.formatDateTime(row.invoiceReceiveDate, 'DD/MM/YYYY')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('DateOfStatusChangeOnTaxAuthority'),
        minWidth: '205px',
        selector: (row) => row.dateCQTChangeStatus,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.dateCQTChangeStatus
                ? Utils.formatDateTime(row.dateCQTChangeStatus, 'DD/MM/YYYY')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('StatusChangeDetectionDate'),
        minWidth: '205px',
        selector: (row) => row.dateDetectChangeStatus,
        sortable: true,
        // style: {
        //   borderRight: 'none',
        // },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.dateDetectChangeStatus
                ? Utils.formatDateTime(row.dateDetectChangeStatus, 'DD/MM/YYYY')
                : ''}
            </p>
          );
        },
      },
      {
        name: t('BillingInformation'),
        minWidth: '200px',
        // selector: (row) => row.amountBeforeVat,
        // sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {/* {row?.debtPayment ? Utils.formatCurrency(row?.finalAmount - row.debtPayment) : ''} */}
            </p>
          );
        },
      },
      {
        name: t('TotalPaymentSuccess'),
        minWidth: '225px',
        // selector: (row) => row.amountBeforeVat,
        // sortable: true,
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.debtPayment ? Utils.formatCurrency(row?.finalAmount - row.debtPayment) : ''}
            </p>
          );
        },
      },
      {
        name: t('UnpaidMoney'),
        minWidth: '215px',
        right: true,
        selector: (row) => row.debtPayment,
        sortable: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.debtPayment ? Utils.formatCurrency(row?.debtPayment) : ''}
            </p>
          );
        },
      },
      {
        name: t('PaymentStatus'),
        width: '230px',
        center: true,
        selector: (row) => row.statusPayment,
        sortable: true,
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">{Utils.getInvoiceStatusPayment(row?.statusPayment)}</div>
          );
        },
      },
      {
        name: t('InvoiceFile'),
        minWidth: '130px',
        center: true,
        cell: (row) => {
          return (
            <div
              className=" font-weight-normal m-0 text-maxline-3 d-flex justify-content-center"
              onClick={() => setCurrentInvoices(row)}
            >
              <input
                type="file"
                id="pdfFiles"
                className="d-none w-10"
                accept="application/pdf,image/*"
                onChange={(e) => {
                  handleUploadPdf(e);
                  document.getElementById('pdfFiles').value = null;
                }}
              />
              <input
                type="file"
                id="XMLFile"
                className="d-none w-10"
                accept="text/xml"
                onChange={(e) => {
                  handleUploadXml(e);
                  document.getElementById('XMLFile').value = null;
                }}
              />
              <KTTooltip
                text={'Chọn file pdf, ảnh để tải lên'}
                position={KTTooltipPositions.auto}
                onMouseOver={() => setCurrentInvoices(row)}
              >
                {row?.pdfFile ? (
                  <img
                    src={AppResource.icons.IcPdfAvailable}
                    className="me-3"
                    onClick={async () => {
                      (dataUploadPdf.current = row), document.getElementById('pdfFiles').click();
                    }}
                  />
                ) : (
                  <div
                    className="pdf-upload"
                    onClick={() => {
                      (dataUploadPdf.current = row), document.getElementById('pdfFiles').click();
                    }}
                  >
                    <img src={AppResource.icons.IcPdfDisable} className="me-3" />
                    <i className="fa-light fa-file-arrow-up icon-up d-none me-3"></i>
                  </div>
                )}
              </KTTooltip>
              {row?.originXmlFile ? (
                <Tippy
                  interactive
                  placement="auto"
                  delay={[0, 10]}
                  offset={[-10, 0]}
                  render={() => (
                    <div
                      className="bg-white tippy-tooltip p-5"
                      style={{
                        boxShadow: '0px 5px 10px 0px rgba(44, 63, 88, 0.1)',
                        border: '1px solid var(--system-stroke, #e8e9eb)',
                        borderRadius: '8px',
                      }}
                    >
                      <div
                        className="d-flex align-items-center pb-2"
                        style={{ minWidth: 'max-content' }}
                        onClick={() => {
                          (dataUploadXml.current = row), document.getElementById('XMLFile').click();
                        }}
                      >
                        {' '}
                        <i className="fa-regular fa-upload me-3"></i>Thay thế hóa đơn gốc
                      </div>
                      <div
                        className="d-flex align-items-center pt-2"
                        style={{ minWidth: 'max-content' }}
                        onClick={() => handleDownLoadInvoice(row)}
                      >
                        <i className="fa-regular fa-download me-3"></i>Tải xuống hóa đơn gốc
                      </div>
                    </div>
                  )}
                >
                  <img src={AppResource.icons.IcXmlAvailable} />
                </Tippy>
              ) : (
                <KTTooltip
                  text={'Chọn file *xml,*inv để tải lên'}
                  position={KTTooltipPositions.auto}
                  onMouseOver={() => setCurrentInvoices(row)}
                >
                  <div className="xml-upload">
                    <img
                      src={AppResource.icons.IcXmlDisable}
                      onClick={() => {
                        (dataUploadXml.current = row), document.getElementById('XMLFile').click();
                      }}
                    />
                    <i
                      className="fa-light fa-file-arrow-up icon-up d-none"
                      onClick={() => {
                        (dataUploadXml.current = row), document.getElementById('XMLFile').click();
                      }}
                    ></i>
                  </div>
                </KTTooltip>
              )}
            </div>
          );
        },
      },
      {
        name: t('Note'),
        minWidth: '130px',
        selector: (row) => row.noteInvoice,
        sortable: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.noteInvoice ? row?.noteInvoice : ''}
            </p>
          );
        },
      },

      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            {handleActions(row)?.length > 0 ? (
              <VITableRowDropDown
                toggleElement={
                  <div>
                    <i className="text-grey fas fa-ellipsis-h p-0"></i>
                  </div>
                }
                menuItem={handleActions(row)}
              />
            ) : (
              <KTTooltip
                text={'Bạn không có quyền thực hiện chức năng này'}
                position={KTTooltipPositions.auto}
              >
                <div className="">
                  <i className="text-grey fas fa-ellipsis-h p-0"></i>
                </div>
              </KTTooltip>
            )}
          </div>
        ),
      },
    ],
    [listInputInvoice]
  );
  // xóa 1 hóa đơn
  function handleDeleteInvoice(row) {
    AppDialogHelper.show(
      t('DeleteInvoice'),
      t('ConfirmDeleteInvoice', { name: row?.invoiceNumber }),
      [
        {
          title: t('Cancel'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
        {
          title: t('Delete'),
          type: 'negative',
          onClick: async () => {
            const result = await invoiceApi.addInvoiceToTrash({
              invoiceIds: [row?.invoiceId],
              isDeleted: true,
            });
            if (result?.result === 'success') {
              AppDialogHelper.hide();
              ToastHelper.showSuccess(t('InvoiceDeletedSuccessfully'));
              dispatch(thunkGetAllInInvoice(filters));
              setDataCheck([]);
            }
          },
        },
      ]
    );
  }
  // Xóa nhiều hóa đơn
  function handleDeleteInvoices() {
    AppDialogHelper.show(t('DeleteInvoice'), t('ConfirmDeleteInvoices'), [
      {
        title: t('Cancel'),
        type: 'neutral',
        onClick: () => {
          AppDialogHelper.hide();
        },
      },
      {
        title: t('Delete'),
        type: 'negative',
        onClick: async () => {
          const result = await invoiceApi.addInvoiceToTrash({
            invoiceIds: idDataInvoices,
            isDeleted: true,
          });
          if (result?.result === 'success') {
            AppDialogHelper.hide();
            ToastHelper.showSuccess(t('InvoiceDeletedSuccessfully'));
            dispatch(thunkGetAllInInvoice(filters));
            setDataCheck([]);
            setClearSelectedRows(!clearSelectedRows);
          }
        },
      },
    ]);
  }
  // Xóa nhãn
  const [showDeleteLabel, setShowDeleteLabel] = useState(false);
  function handleDeleteLabel(label) {
    setShowDeleteLabel(true);
    AppDialogHelper.show(t('DeleteLabel'), t('ConfirmDeleteInvoice', { name: label?.name }), [
      {
        title: t('Cancel'),
        type: 'neutral',
        onClick: () => {
          setShowDeleteLabel(false);
          AppDialogHelper.hide();
        },
      },
      {
        title: t('Delete'),
        type: 'negative',
        onClick: () => {
          handleDeleteLabels(label);
        },
      },
    ]);
  }

  const handleDeleteLabels = async (label) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkDeleteTagList([label?.tagId])));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('LabelRemovalSuccessful'));
        store.dispatch(thunkGetTagList());
        AppDialogHelper.hide();
        setShowDeleteLabel(false);
        setListCurrentLabels(listCurrentLabels?.filter((x) => x.tagId !== label?.tagId));
        setClearSelectedRows(!clearSelectedRows);
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  };
  // phân trang
  const [pagination, setPagination] = useState({
    perPage: filters?.limit,
    total: totalInputInvoice,
    currentPage: filters?.page + 1,
    count: listInputInvoice?.length + filters.limit * filters.page,
  });
  useEffect(() => {
    setPagination({
      perPage: filters?.limit,
      total: totalInputInvoice,
      currentPage: filters?.page + 1,
      count: listInputInvoice?.length + filters.limit * filters.page,
    });
  }, [filters, listInputInvoice]);

  const [textLabel, setTextLabel] = useState('');
  // MARK --- Hooks ---

  // tạo, sửa nhãn
  const formik = useFormik({
    initialValues: {
      name: '',
      color: '#FD7972',
      partnerCompanyTaxCodes: '',
    },
    validationSchema: Yup.object({
      name: Yup.string('Vui lòng nhập tên nhãn').required('Vui lòng nhập tên nhãn'),
    }),
    onSubmit: async (values) => {
      if (textLabel === 'AddLabel') {
        try {
          const res = unwrapResult(await store.dispatch(thunkCreateTagList(values)));
          const { result, tag } = res;
          if (result === 'success' && tag) {
            ToastHelper.showSuccess(t('CreateNewLabelSuccessfully'));
            store.dispatch(thunkGetTagList());
            setShowModalAddLabeled(false);
            formik.resetForm();
            return true;
          }
        } catch (error) {
          console.log({ error });
          return false;
        }
      } else {
        try {
          const res = unwrapResult(
            await store.dispatch(
              thunkUpdateTagList({ id: currentChangeLabel?.tagId, params: values })
            )
          );
          const { result, tag } = res;
          if (result === 'success' && tag) {
            ToastHelper.showSuccess(t('EditLabelSuccessfully'));
            store.dispatch(thunkGetTagList());
            setShowModalEditLabel(false);
            formik.resetForm();
            return true;
          }
        } catch (error) {
          console.log({ error });
          return false;
        }
      }
    },
  });

  //đánh dấu thanh toán
  const forMikPayment = useFormik({
    initialValues: {
      paymentPersonName: '',
      noDatePayment: currentInvoices?.partnerCompany?.expirePayment ?? 30,
      paymentMoney: currentInvoices?.debtPayment ?? '',
      paymentDate: Utils.formatAddDateTime([moment().startOf('week'), moment()][1]?._d),
      total: '',
    },
    validationSchema: (props) => {
      return Yup.lazy((values) => {
        return Yup.object({
          paymentMoney: Yup.string(t('EnterPaymentAmount'))
            .required(t('EnterPaymentAmount'))
            .test('len', t('ExceededTotalPayment'), (val) => Number(val) <= Number(values?.total)),
        });
      });
    },
    onSubmit: async (values) => {
      try {
        const result = await invoiceApi.updateInvoicePayment(currentInvoices?.invoiceId, values);
        if (result.result === 'success') {
          forMikPayment.resetForm(), setShowModalPayment(false);
          ToastHelper.showSuccess(t('PaymentSuccess'));
          dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
          setClearSelectedRows(!clearSelectedRows);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  // bỏ đánh dấu thanh toán
  function handleUnPayment(row) {
    AppDialogHelper.show(
      t('Warning'),
      t(
        'Chương trình sẽ xóa toàn bộ lịch sử thanh toán của hóa đơn. Bạn có thực sự muốn bỏ đánh dấu thanh toán hóa đơn đã chọn không?'
      ),
      [
        {
          title: t('Cancel'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
        {
          title: t('Continue'),
          type: 'negative',
          onClick: async () => {
            const result = await invoiceApi.returnPaymentInvoice(row?.invoiceId);
            if (result?.result === 'success') {
              AppDialogHelper.hide();
              ToastHelper.showSuccess(t('UncheckPaymentSuccess'));
              dispatch(thunkGetAllInInvoice(filters));
              setDataCheck([]);
            }
          },
        },
      ]
    );
  }

  // Đánh dấu hạch toán
  let dataAccounting = [];
  let dataDeleteAccounting = [];
  for (let i = 0; i < dataCheck?.length; i++) {
    if (dataCheck[i]?.accountingDate) {
      dataDeleteAccounting.push(dataCheck[i]?.invoiceId);
    } else {
      dataAccounting.push(dataCheck[i]?.invoiceId);
    }
  }
  const forMikAccountingDate = useFormik({
    initialValues: {
      accountingPersonName: account?.fullname ? account?.fullname : '',
      noDocument: '',
      accountingDate: Utils.formatAddDateTime([moment().startOf('week'), moment()][1]?._d),
    },
    validationSchema: Yup.object({
      // accountingDate: Yup.string(t('EnterPaymentAmount'))
      //   .required(t('EnterPaymentAmount'))
      //   .test('len', t('ExceededTotalPayment'), (val) => val <= currentInvoices?.debtPayment ?? ''),
    }),
    onSubmit: async (values) => {
      try {
        const result = await invoiceApi.accountingInvoice({
          invoiceIds: dataAccounting?.length > 0 ? dataAccounting : [currentInvoices?.invoiceId],
          accountingPersonName: values?.accountingPersonName,
          noDocument: values?.noDocument,
          accountingDate: values?.accountingDate,
        });
        if (result.result === 'success') {
          forMikAccountingDate.resetForm(), setShowModalAccountingDate(false);
          ToastHelper.showSuccess(t('SuccessfulAccounting'));
          dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
          setDataCheck([]);
          setClearSelectedRows(!clearSelectedRows);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  // bỏ đánh dấu hạch toán
  const handleDeleteAccounting = async (row) => {
    const res = await invoiceApi.returnAccountingInvoice({
      invoiceIds: dataDeleteAccounting?.length > 0 ? dataDeleteAccounting : [row.invoiceId],
    });
    if (res?.result === 'success') {
      ToastHelper.showSuccess('Đã bỏ đánh dấu hạch toán');
      dispatch(thunkGetAllInInvoice(filters));
      setDataCheck([]);
      setClearSelectedRows(!clearSelectedRows);
    } else {
      ToastHelper.showSuccess(res?.result);
    }
  };

  //điều chuyển hóa đơn
  const handleInvoiceTransfer = async () => {
    const result = await invoiceApi.transferInvoice({
      invoiceIds: idDataInvoices?.length > 0 ? idDataInvoices : [currentInvoices?.invoiceId],
      organizationId: organizationId?.organizationId,
      organizationDepartmentId: organizationId?.organizationDepartmentId
        ? organizationId?.organizationDepartmentId
        : null,
    });
    if (result.result === 'success') {
      forMikAccountingDate.resetForm(), setShowModalInvoiceTransfer(false);
      ToastHelper.showSuccess(t('InvoiceTransferSuccessfully'));
      dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
      setDataCheck([]);
      setClearSelectedRows(!clearSelectedRows);
    }
  };

  // useEffect(() => {
  //   forMikPayment.setValues({
  //     ...forMikPayment.values,
  //     paymentMoney: currentInvoices?.debtPayment,
  //   });
  // }, [currentInvoices]);

  // thêm nhãn vào hóa đơn

  //gán nhãn
  const [loadingTag, setLoaddingTag] = useState(false);
  const handleAddTagInvoices = async () => {
    try {
      setLoaddingTag(true);
      const result = await invoiceApi.addTagsInvoice({ tagIds: idTag, invoiceIds: idDataInvoices });
      if (result?.result === 'success') {
        setShowModalLabeled(false);
        setLoaddingTag(false);
        ToastHelper.showSuccess(t('LabelingSuccess'));
        dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
        setDataCheck([]);
        setClearSelectedRows(!clearSelectedRows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //gỡ nhãn
  const handleDeleteTagInvoices = async () => {
    const result = await invoiceApi.addTagsInvoice({ tagIds: idTag, invoiceIds: idDataInvoices });
    if (result?.result === 'success') {
      setShowModalDeleteLabel(false);
      ToastHelper.showSuccess(t('RemoveTheLabelSuccess'));
      dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
      setDataCheck([]);
      setClearSelectedRows(!clearSelectedRows);
    }
  };
  //lọc hóa đơn
  function handleFilterInvoice(dataFilter) {
    setFilters({
      ...filters,
      fromDate: dataFilter?.fromDate,
      toDate: dataFilter?.toDate,
      dateAccounting: dataFilter?.dateAccounting,
      dateType: dataFilter?.dateType,
      status: dataFilter?.status,
      statusInvoice: dataFilter?.statusInvoice,
      typeInvoiceReceive: dataFilter?.typeInvoiceReceive,
      tagId: dataFilter?.tagId,
      invoiceTypeCode: dataFilter?.invoiceTypeCode,
      invoiceSerialType: dataFilter?.invoiceSerialType,
    });
  }

  // Gửi phản hồi đến NCC
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const forMikFeedBack = useFormik({
    initialValues: {
      emails: currentInvoices?.partnerCompany?.contactEmail ?? '',
      subject: account.organizationName,
      invoiceId: currentInvoices?.invoiceId,
      html: `<p style="margin-left:0px;"><strong>Kính gửi: ${
        currentInvoices?.sellerName
      }</strong></p><p style="margin-left:0px;">${
        account.organizationName + ' ' + 'đã nhận được hóa đơn dưới đây'
      }:</p><p style="margin-left:0px;">- Số hóa đơn: <strong>${
        currentInvoices?.invoiceNumber
      }</strong></p><p style="margin-left:0px;">- Mẫu số hóa đơn: <strong>${
        currentInvoices?.invoiceTypeCode
      }</strong></p><p style="margin-left:0px;">- Ký hiệu hóa đơn: <strong>${
        currentInvoices?.serial
      }</strong></p><p style="margin-left:0px;">&nbsp;</p><p style="margin-left:0px;">Sau khi kiểm tra hóa đơn, chúng tôi nhận thấy các thông tin sau chưa chính xác. Đề nghị quý công ty xem xét lại:</p><p style="margin-left:0px;">&nbsp;</p><p style="margin-left:0px;">1. Sai địa chỉ người bán</p><p style="margin-left:0px;">&nbsp;</p><p style="margin-left:0px;">Trân trọng kính chào!</p><p style="margin-left:0px;">&nbsp;</p>`,
    },
    validationSchema: Yup.object({
      emails: Yup.string(t('EnterYourEmail'))
        .required(t('EnterYourEmail'))
        .email(t('EmailIsNotFormatted')),
      subject: Yup.string(t('EnterYourTitle')).required(t('EnterYourTitle')),
    }),
    onSubmit: async (values) => {
      setLoadingSendMail(true);
      try {
        const result = await invoiceApi.sendToEmailNCC({
          emails: [values?.emails],
          subject: values?.subject,
          invoiceId: values?.invoiceId,
          html: values?.html,
        });
        if (result.result === 'success') {
          forMikFeedBack.resetForm(), setShowModalFeedback(false);
          ToastHelper.showSuccess(t('SendFeedbackToSupplierSuccessfully'));
          dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
          setDataCheck([]);
          setClearSelectedRows(!clearSelectedRows);
          setLoadingSendMail(false);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  useEffect(() => {
    if (account) {
      forMikFeedBack.setValues({
        ...forMikFeedBack.values,
        subject: account.organizationName + ' ' + 'phản hồi về hóa đơn điện tử của quý khách',
      });
    }
  }, [account]);

  const [dataNote, setDataNote] = useState();
  //ghi chú hóa đơn
  const handleNoteInvoice = async () => {
    if (dataCheck.every((e) => !e.noteInvoice)) {
      const note = await invoiceApi.noteInvoice({
        invoiceIds: idDataInvoices,
        noteInvoice: dataNote ? dataNote : '',
      });
      if (note.result === 'success') {
        setShowModalNoteInvoices(false);
        if (idDataInvoices?.length === 1) {
          ToastHelper.showSuccess(t('CreateNoteSuccessfully'));
        } else {
          ToastHelper.showSuccess(t('SuccessfulBatchNotes'));
        }
        dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
        setDataCheck([]);
        setClearSelectedRows(!clearSelectedRows);
      }
    } else {
      setModalViewListNoteInvoice(true);
    }
  };
  //thay thế ghi chú
  const [dataNoteReplace, setDataNoteReplace] = useState([]);
  const [loadingNoteInvoiceReplace, setLoadingNoteInvoiceReplace] = useState(false);
  let dataNoteReplaces = [];
  for (
    let i = 0;
    i < dataCheck.filter((e) => !e?.noteInvoice).concat(dataNoteReplace)?.length;
    i++
  ) {
    dataNoteReplaces.push(
      dataCheck.filter((e) => !e?.noteInvoice).concat(dataNoteReplace)?.[i]?.invoiceId
    );
  }
  const handleNoteInvoiceReplace = async () => {
    setLoadingNoteInvoiceReplace(true);
    const note = await invoiceApi.noteInvoice({
      invoiceIds: dataNoteReplaces,
      noteInvoice: dataNote ? dataNote : '',
    });
    if (note.result === 'success') {
      setShowModalNoteInvoices(false);
      setModalViewListNoteInvoice(false);
      setDataNote();
      setLoadingNoteInvoiceReplace(false);
      if (dataNoteReplaces?.length === 1) {
        ToastHelper.showSuccess(t('CreateNoteSuccessfully'));
      } else {
        ToastHelper.showSuccess(t('SuccessfulBatchNotes'));
      }
      dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
      setDataCheck([]);
      setClearSelectedRows(!clearSelectedRows);
    }
  };
  //Kiểm tra hóa đơn
  const [currentCheck, setCurrentCheck] = useState([]);
  const handleCheck = async () => {
    setModalCheckShowing(true);
    setTimeout(() => {
      setModalCheckShowing(false);
    }, 2000);
    const note = await invoiceApi.validateInvoice({
      invoiceIds: currentCheck?.length > 0 ? currentCheck : idDataInvoices,
    });
    if (note.result === 'success') {
      // setShowModalNoteInvoices(false);
      // ToastHelper.showSuccess(t('SuccessfulBatchNotes'));
      dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
      setDataCheck([]);
      setClearSelectedRows(!clearSelectedRows);
      // setCurrentCheck([]);
    }
  };

  const refDropdownFilter = useRef();
  const refDropdownFilterMenu = useRef();
  const refDateRangePickerFilter = useRef();

  // useOnClickOutside(refDropdownFilter, () => {
  //   const isDateRangePickerDisplays = window.getComputedStyle(
  //     refDateRangePickerFilter.current
  //   ).display;
  //   if (isDateRangePickerDisplays === 'block') {
  //     return;
  //   }
  //   if (refDropdownFilterMenu?.current) {
  //     if (refDropdownFilterMenu?.current.classList.contains('show')) {
  //       refDropdownFilterMenu?.current.classList.remove('show');
  //     } else {
  //     }
  //   }
  // });

  useEffect(() => {
    const el = document.getElementsByClassName('DropdownFilterMenu')?.item(0);
    if (el) {
      refDropdownFilterMenu.current = el;
    }

    if (document.getElementsByClassName('daterangepicker')?.item(1)) {
      refDateRangePickerFilter.current = document
        .getElementsByClassName('daterangepicker')
        ?.item(1);
    }
  }, []);

  const [countFilter, setCountFilter] = useState();

  useEffect(() => {
    let count = 0;
    if (
      JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId] !==
      undefined
    ) {
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.dateAccounting !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.dateAccounting
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.dateType !== 1
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.status !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.status
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.statusInvoice !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.statusInvoice
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.tagId !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.tagId
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.typeInvoiceReceive !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.typeInvoiceReceive
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.invoiceTypeCode !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.invoiceTypeCode
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.invoiceSerialType !== 0 &&
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.invoiceSerialType
      ) {
        count += 1;
      }
      if (
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.fromDate !== moment().subtract(29, 'days').format('YYYY-MM-DD') ||
        JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId]
          ?.toDate !== moment().format('YYYY-MM-DD')
      ) {
        count += 1;
      }
      setCountFilter(count);
    } else {
      setCountFilter(0);
    }
  }, [
    JSON.parse(localStorage.getItem(PreferenceKeys.saveInputInvoice))?.[account.accountId],
    account.accountId,
  ]);

  // tải xuống hóa đơn
  const [dataFormat, setDataFormat] = useState('0');
  let dataDownload = [];
  for (let i = 0; i < dataCheck.length; i++) {
    if (dataFormat === '0' && dataCheck[i]?.pdfFile) {
      dataDownload.push(dataCheck[i]);
    }
    if (dataFormat === '0' && dataCheck[i]?.previewPdfFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.previewPdfFile });
    }
    if (dataFormat === '0' && dataCheck[i]?.xmlFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.xmlFile });
    }
    if (dataFormat === '0' && dataCheck[i]?.originXmlFile) {
      dataDownload.push({ ...dataCheck[i], xmlFileOrigin: dataCheck[i]?.originXmlFile });
    }
    if (dataFormat === '1' && dataCheck[i]?.pdfFile) {
      dataDownload.push(dataCheck[i]);
    }
    if (dataFormat === '1' && dataCheck[i]?.previewPdfFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.previewPdfFile });
    }
    if (dataFormat === '2' && dataCheck[i]?.xmlFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.xmlFile });
    }
    if (dataFormat === '3' && dataCheck[i]?.originXmlFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.originXmlFile });
    }
    if (dataFormat === '3' && dataCheck[i]?.pdfFile) {
      dataDownload.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.pdfFile });
    }
  }

  const handleDownLoadInvoices = () => {
    const name = Global.gDownLoadZipName;
    const res = InvoiceHelper.DownLoadInvoices(name, dataDownload);
    setClearSelectedRows(!clearSelectedRows);
    setShowModalDownLoad(false);
    setDataCheck([]);
  };
  let dataPrint = [];
  for (let i = 0; i < dataCheck.length; i++) {
    if (dataCheck[i]?.pdfFile) {
      dataPrint.push(dataCheck[i]);
    }
    if (dataCheck[i]?.previewPdfFile) {
      dataPrint.push({ ...dataCheck[i], pdfFile: dataCheck[i]?.previewPdfFile });
    }
  }
  //in hóa đơn
  const handlePrintInvoices = () => {
    const res = InvoiceHelper.PrintInvoice(dataPrint);
    setClearSelectedRows(!clearSelectedRows);
    setDataCheck([]);
  };
  // upload pdf
  const dataUploadPdf = useRef();
  const handleUploadPdf = async (e) => {
    const form = new FormData();
    form.append('pdfFile', e?.target?.files[0]);
    if (e?.target?.files[0]) {
      try {
        const res = await invoiceApi.upFileInvoice(dataUploadPdf?.current?.invoiceId, form);
        if (res?.result === 'success') {
          setCurrentDetail();
          ToastHelper.showSuccess(t('UploadFileSuccess'));
          dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
        }
      } catch (error) {}
    }
  };
  // upload pdf

  function handleWarning() {
    AppDialogHelper.show(
      t('Warning'),
      t(
        'Mã số thuế người bán/người mua - loại hóa đơn - mẫu số/ký hiệu - số hóa đơn trên file *xml, *inv tải lên khác với thông tin hóa đơn đã tồn tại trên chương trình.'
      ),
      [
        {
          title: t('Close'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
      ]
    );
  }
  const dataUploadXml = useRef();
  const handleUploadXml = async (e) => {
    const form = new FormData();
    form.append('xmlFile', e?.target?.files[0]);
    if (e?.target?.files[0]) {
      try {
        const res = await invoiceApi.upFileInvoice(dataUploadXml?.current?.invoiceId, form);
        if (res?.result === 'success') {
          setCurrentDetail();
          ToastHelper.showSuccess(t('UploadFileSuccess'));
          dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
        }
      } catch (error) {
        setCurrentDetail();
        handleWarning();
      }
    }
  };
  // column mặc định khi loading
  const defaultColumns = useMemo(() => {
    let columnsLocal = [];
    for (
      let i = 0;
      i <
      JSON.parse(localStorage.getItem(PreferenceKeys.saveColumnInputInvoice))?.[account.accountId]
        ?.length;
      i++
    ) {
      for (let j = 0; j < columns.length; j++) {
        if (
          JSON.parse(localStorage.getItem(PreferenceKeys.saveColumnInputInvoice))?.[
            account.accountId
          ][i] === columns[j]?.name
        ) {
          columnsLocal.push(columns[j]);
        }
      }
    }
    if (columnsLocal.length > 0) {
      return columnsLocal;
    } else {
      return columns.filter((item) =>
        Global[PreferenceKeys.saveColumnInputInvoice]?.includes(item.name)
      );
      // .concat(columns[columns.length - 1]);
    }
  }, [
    JSON.parse(localStorage.getItem(PreferenceKeys.saveColumnInputInvoice))?.[account.accountId],
    columns,
  ]);
  //tải xuống xml gốc
  const handleDownLoadInvoice = (row) => {
    const dataDownload = [
      { ...row, pdfFile: row?.originXmlFile },
      { ...row, pdfFile: row?.pdfFile },
    ];
    const name = Global.gDownLoadZipName;
    const res = InvoiceHelper.DownLoadInvoices(name, dataDownload);
  };

  const reSize = useWindowSize();

  return (
    <div className="position-relative Input-invoice">
      <div className="d-flex justify-content-between ">
        <div
          className="w-100 bg-white"
          style={{
            borderRadius: '0px 0px 20px 20px',
            borderLeft: '1px solid #dbe3ef',
            borderBottom: '1px solid #dbe3ef',
            borderRight: '1px solid #dbe3ef',
          }}
        >
          <VIDataTable
            tabsActive={tabsActive}
            selectable={true}
            pagination={pagination}
            tableStyles={customDataTableActionStyle}
            scrollHeight="48"
            clearSelectedRows={clearSelectedRows}
            totalPreTaxMoney={totalAmountBeforeVATInputInvoice}
            totalTaxAmount={totalAmountVATInputInvoice}
            totalValue={totalFinalAmountInputInvoice}
            maxHeightDrag="42vh"
            draggable={dataCheck?.length === 0}
            keyDefaultColumn={PreferenceKeys.saveColumnInputInvoice}
            defaultColumns={defaultColumns}
            onChangePage={(page) => {
              let iNewPage = parseInt(page);
              iNewPage -= 1;
              if (iNewPage < 0) {
                iNewPage = 0;
              }
              Global.gFiltersInputInvoice = {
                ...Global.gFiltersInputInvoice,
                page: iNewPage,
              };
              setFilters({
                ...filters,
                page: iNewPage,
              });
            }}
            onChangeRowsPerPage={(perPage) => {
              Global.gFiltersInputInvoice = {
                ...Global.gFiltersInputInvoice,
                limit: perPage,
              };
              setFilters({
                ...filters,
                limit: perPage,
                page: 0,
              });
            }}
            fullColumns={columns}
            data={listInputInvoice}
            loading={isGettingInInvoiceList}
            inputSearch={
              <>
                {dataCheck?.length === 0 && (
                  <div className="d-flex justify-content-between w-100">
                    <KTTooltip
                      className="w-100"
                      text="Nhập số hóa đơn, tên nhà cung cấp, mã số thuế, số chứng từ, ghi chú để tìm kiếm"
                      position={KTTooltipPositions.bottom}
                    >
                      <div className="w-100 h-100">
                        <VISearchField
                          placeholder="Nhập số hóa đơn, tên nhà cung cấp, mã số thuế, số chứng ..."
                          value={filters.q}
                          className="w-100 d-flex align-items-center"
                          typingTimeout={500}
                          onSubmit={(newValue) => {
                            setFilters({
                              ...filters,
                              q: newValue,
                              page: 0,
                            });
                            Global.gFiltersInputInvoice = {
                              ...Global.gFiltersInputInvoice,
                              page: 0,
                              q: newValue,
                            };
                          }}
                        />
                      </div>
                    </KTTooltip>
                  </div>
                )}
              </>
            }
            filterbar={
              <div className="show-func">
                {dataCheck?.length > 0 && (
                  <div className=" d-flex flex-wrap ">
                    <div
                      className="px-3 py-3 me-3 mt-2"
                      style={{
                        background: 'rgba(81, 128, 251, 0.10)',
                        borderRadius: '8px',
                        color: '#5180FB',
                      }}
                    >
                      {t('Selected')} {dataCheck?.length} {t('Bill')}
                    </div>
                    {dataAccounting?.length > 0 &&
                      account?.role?.permissionCodes?.includes('ACCOUNTED_MARK_INPUT_INVOICE') && (
                        <VIButton
                          text={t('AccountedForMark') + ' ' + `(${dataAccounting?.length})`}
                          beforeIcon={<i className="fa-regular fa-circle-check"></i>}
                          className="btn-white me-3 mt-2"
                          style={{ padding: '8px' }}
                          onClick={() => {
                            setShowModalAccountingDate(true);
                          }}
                          afterIcon={<></>}
                        />
                      )}
                    {dataDeleteAccounting?.length > 0 &&
                      account?.role?.permissionCodes?.includes('ACCOUNTED_MARK_INPUT_INVOICE') && (
                        <VIButton
                          text={t('MarkUnaccountedFor') + ' ' + `(${dataDeleteAccounting?.length})`}
                          beforeIcon={<i className="fa-light fa-square-xmark"></i>}
                          className="btn-white me-3 mt-2"
                          style={{ padding: '8px' }}
                          onClick={() => {
                            handleDeleteAccounting();
                          }}
                          afterIcon={<></>}
                        />
                      )}
                    <VIButton
                      text={t('PrintInvoice')}
                      beforeIcon={<i className="fa-regular fa-print"></i>}
                      className="btn-white me-3 mt-2"
                      style={{ padding: '8px' }}
                      onClick={() => handlePrintInvoices()}
                      afterIcon={<></>}
                    />
                    <VIButton
                      text={t('ExportList')}
                      beforeIcon={<i className="fa-solid fa-up-right-from-square"></i>}
                      className="btn-white me-3 mt-2"
                      style={{ padding: '8px' }}
                      onClick={() => {
                        setModalInvoiceExportShowing(true);
                      }}
                      afterIcon={<></>}
                    />
                    <VIButton
                      text={t('DownLoad')}
                      beforeIcon={<i className="fa-solid fa-download"></i>}
                      className="btn-white me-3 mt-2"
                      style={{ padding: '8px' }}
                      onClick={() => {
                        setShowModalDownLoad(true);
                      }}
                      afterIcon={<></>}
                    />
                    {account?.role?.permissionCodes?.includes(
                      'DELETE_AND_RESTORE_INPUT_INVOICE'
                    ) && (
                      <VIButton
                        text={t('Delete')}
                        beforeIcon={<i className="fa-solid fa-trash-can"></i>}
                        className="btn-white mt-2 me-3"
                        style={{ padding: '8px' }}
                        onClick={() => handleDeleteInvoices()}
                        afterIcon={<></>}
                      />
                    )}
                    <div
                      style={{ display: 'flex', justifyContent: 'end' }}
                      id={'show-more-func'}
                      className="mt-2"
                    >
                      <KTBSDropdown
                        contentEl={
                          <VIBodyIcons
                            IconArr={IconArr}
                            onClick={(x) => {
                              if (x?.name === 'Gán nhãn') {
                                setShowModalLabeled(true);
                              }
                              if (x.name === 'Ghi chú') {
                                setShowModalNoteInvoices(true);
                              }
                              if (x.name === 'Điều chuyển hóa đơn') {
                                setShowModalInvoiceTransfer(true);
                              }
                              if (x.name === 'Kiểm tra lại') {
                                handleCheck();
                              }
                              if (x.name === 'Gỡ nhãn') {
                                setShowModalDeleteLabel(true);
                              }
                              // if (x.name === 'Tải xuống') {
                              //   setShowModalDownLoad(true);
                              // }

                              document.getElementById('show-more-func').click();
                            }}
                            id={'100'}
                          />
                        }
                        toggleElement={
                          <div className="topbar-item">
                            <div className="mr-1 btn-white py-3 cursor-pointer px-6 d-flex align-items-center">
                              <i className="fa-regular fa-ellipsis-vertical"></i>
                            </div>
                          </div>
                        }
                        alignment={KTBSDropdownAlignments.end}
                        autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                      />
                    </div>
                  </div>
                )}
              </div>
            }
            onRowClick={(row) => {
              setShowModalDetailInvoices(true);
              setCurrentDetail(row);
            }}
            dataDefaults={dataCheck}
            // onRowDoubleClick={(row) => {
            //   setShowModalDetailInvoices(true);
            //   setCurrentDetail(row);
            // }}
            onSetSelectedMultiItems={(rows) => {
              setDataCheck(rows);
            }}
            toolbar={
              <>
                {dataCheck?.length === 0 && (
                  <>
                    <div className="">
                      <KTBSDropdown
                        contentEl={
                          <div ref={refDropdownFilter}>
                            <DropdownFilterInInvoice
                              handleFilterInvoice={handleFilterInvoice}
                              refDropdownFilterMenu={refDropdownFilterMenu}
                            />
                          </div>
                        }
                        dropdownMenuClassName="DropdownFilterMenu"
                        toggleElement={
                          <div className="d-flex align-items-center font-weight-bold justify-content-between DropdownFilter ">
                            <i
                              className="fa-regular fa-filter-list me-2"
                              style={{ color: '#1c2256' }}
                            ></i>
                            <span className="d-flex">
                              {t('FilterInvoices')}{' '}
                              {countFilter !== 0 && (
                                <div className="ms-1" style={{ color: '#F44141' }}>
                                  ({countFilter})
                                </div>
                              )}
                            </span>
                          </div>
                        }
                        alignment={reSize?.width < 540 ? '' : KTBSDropdownAlignments.end}
                        autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                      />
                    </div>
                  </>
                )}
              </>
            }
          />
        </div>
      </div>

      <VIModal
        titleModal={t('Labeled')}
        show={showModalLabeled}
        onHide={() => {
          setShowModalLabeled(false), setListCurrentLabels([]);
        }}
        contentModal={
          <ModalLabeled
            handleDeleteLabel={handleDeleteLabel}
            setShowModalEditLabel={setShowModalEditLabel}
            setCurrentChangeLabel={setCurrentChangeLabel}
            setTextLabel={setTextLabel}
            setListCurrentLabels={setListCurrentLabels}
            listCurrentLabels={listCurrentLabels}
          />
        }
        closeBtn={true}
        handleSubmit={() => {
          handleAddTagInvoices();
        }}
        textButton={t('AddLabel')}
        onClickButtonAdd={() => {
          setShowModalAddLabeled(true);
          setTextLabel('AddLabel');
        }}
        style={
          showModalAddLabeled || showDeleteLabel || modalShowEditLabel
            ? { visibility: 'hidden' }
            : { visibility: 'visible' }
        }
        buttonPositive={
          !loadingTag ? (
            t('Save')
          ) : (
            <div className="d-flex align-items-center">
              {t('Save')}
              <div className="ms-2 ">
                <VILoading size="sm" variant="white" />
              </div>
            </div>
          )
        }
        buttonNegative={t('Cancel')}
      />
      <VIModal
        titleModal={t('RemoveTheLabel')}
        show={showModalDeleteLabel}
        onHide={() => {
          setShowModalDeleteLabel(false), setListCurrentLabels([]);
        }}
        contentModal={
          <ModalLabeled
            handleDeleteLabel={handleDeleteLabel}
            setCurrentChangeLabel={setCurrentChangeLabel}
            setListCurrentLabels={setListCurrentLabels}
            listCurrentLabels={currentDeleteLabel}
            deleteLabel={true}
          />
        }
        closeBtn={true}
        handleSubmit={() => {
          handleDeleteTagInvoices();
        }}
        textClear={t('DeselectAll')}
        onClickButtonClear={() => {
          setListCurrentLabels([]);
        }}
        buttonPositive={t('Save')}
        buttonNegative={t('Cancel')}
      />
      <VIModal
        titleModal={t('AddLabel')}
        show={showModalAddLabeled}
        onHide={() => {
          setShowModalAddLabeled(false), formik.resetForm();
        }}
        contentModal={<AddLabel formik={formik} />}
        closeBtn={true}
        handleSubmit={() => {
          formik.handleSubmit();
        }}
        buttonPositive={t('Save')}
      />
      <VIModal
        titleModal={t('EditLabel')}
        show={modalShowEditLabel}
        onHide={() => {
          setShowModalEditLabel(false), formik.resetForm();
        }}
        contentModal={<AddLabel currentChangeLabel={currentChangeLabel} formik={formik} />}
        closeBtn={true}
        handleSubmit={() => {
          formik.handleSubmit();
        }}
        buttonPositive={t('Save')}
      />
      <VIModal
        titleModal={t('BatchNotes')}
        show={showModalNoteInvoices}
        onHide={() => {
          setShowModalNoteInvoices(false);
          setDataNote();
        }}
        contentModal={
          <NoteInvoice
            handleTextArea={(e) => setDataNote(e)}
            dataInvoice={dataCheck}
            setDataCheck={setDataCheck}
            showInvoice={true}
            dataNote={dataNote}
          />
        }
        closeBtn={true}
        handleSubmit={() => {
          handleNoteInvoice();
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={t('Save')}
      />
      <VIModal
        titleModal={t('SendInvoices/ReceiptsTo')}
        show={showModalInvoiceTransfer}
        onHide={() => {
          setShowModalInvoiceTransfer(false);
        }}
        contentModal={<InvoiceTransfer setOrganizationId={setOrganizationId} />}
        closeBtn={true}
        handleSubmit={() => {
          handleInvoiceTransfer();
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={t('Save')}
      />

      <VIModalResize
        show={showModalDetailInvoices}
        onHide={() => {
          setShowModalDetailInvoices(false), setCurrentDetail(null);
        }}
        currentDetail={currentDetail}
        listData={listInputInvoice}
        clearSelectedRows={clearSelectedRows}
        setClearSelectedRows={setClearSelectedRows}
        setDataCheck={setDataCheck}
      />

      <VIModal
        titleModal={t(`AccountingDate`)}
        show={showModalAccountingDate}
        onHide={() => {
          setShowModalAccountingDate(false), forMikAccountingDate.resetForm();
        }}
        contentModal={<ModalAccountingDate formik={forMikAccountingDate} />}
        closeBtn={true}
        handleSubmit={() => {
          forMikAccountingDate.handleSubmit();
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={t('Save')}
      />
      <VIModal
        titleModal={t(`Pay`)}
        show={showModalPayment}
        onHide={() => {
          forMikPayment.resetForm(), setShowModalPayment(false);
        }}
        contentModal={<ModalPayment currentInvoices={currentInvoices} formik={forMikPayment} />}
        closeBtn={true}
        handleSubmit={() => {
          forMikPayment.handleSubmit();
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={t('Save')}
      />
      <VIModal
        titleModal={t(`SendALetterToTheSupplier`)}
        modalSize="lg"
        show={showModalFeedback}
        onHide={() => setShowModalFeedback(false)}
        contentModal={<ModalFeedback formik={forMikFeedBack} />}
        closeBtn={true}
        handleSubmit={() => {
          forMikFeedBack.handleSubmit();
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={
          !loadingSendMail ? (
            t('Mailing')
          ) : (
            <div className="d-flex align-items-center">
              {t('Mailing')}
              <div className="ms-2 ">
                <VILoading size="sm" variant="white" />
              </div>
            </div>
          )
        }
      />
      <VIModal
        titleModal={t(`DownLoad`)}
        show={showModalDownLoad}
        onHide={() => setShowModalDownLoad(false)}
        contentModal={<DownLoadInvoice setDataFormat={setDataFormat} dataFormat={dataFormat} />}
        closeBtn={true}
        handleSubmit={() => {
          handleDownLoadInvoices();
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={t('Download')}
      />
      {/* modal checking */}
      <VIModal
        show={modalCheckShowing}
        contentModal={
          <div className="d-flex flex-column align-items-center">
            <img src="https://inbotapp.misacdn.net/img/success-bot.9221cd17.png" />
            <p className="mt-2">Hệ thống đang kiểm tra và sẽ trả về kết quả sau ít phút</p>
          </div>
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalCheckShowing(false);
        }}
        buttonPositive=""
        buttonNegative=""
      />
      <VIModal
        titleModal={t('Export')}
        // modalSize="lg"
        show={modalInvoiceExportShowing}
        contentModal={
          <ViewInvoiceExport
            onPressedCancel={() => {
              setModalInvoiceExportShowing(false);
            }}
            invoiceList={dataCheck}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalInvoiceExportShowing(false);
        }}
      />
      <VIModal
        titleModal={t('InvoiceLookupInformation')}
        show={modalLookUpInvoice}
        contentModal={<LookUpInvoice currentLookUp={currentInvoices} />}
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalLookUpInvoice(false);
        }}
        buttonNegative={t('Close')}
        modalSize="lg"
      />
      <VIModal
        titleModal={t('ListOfInvoicesWithNotes')}
        show={modalViewListNoteInvoice}
        contentModal={
          <ViewListInvoiceNote
            item={dataCheck.filter((e) => e?.noteInvoice)}
            onPressCancel={() => {
              setModalViewListNoteInvoice(false);
            }}
            setDataNoteReplace={setDataNoteReplace}
          />
        }
        closeBtn
        modalSize="xl"
        handleSubmit={() => {
          handleNoteInvoiceReplace();
        }}
        onHide={() => {
          setModalViewListNoteInvoice(false);
        }}
        buttonNegative={t('Cancel')}
        buttonPositive={
          !loadingNoteInvoiceReplace ? (
            t('Replace')
          ) : (
            <div className="d-flex align-items-center">
              {t('Replace')}
              <div className="ms-2 ">
                <VILoading size="sm" variant="white" />
              </div>
            </div>
          )
        }
      />
      <ManualInput
        show={showModalManualInput}
        onHide={() => {
          setShowModalManualInput(false), setCurrentEdit('');
        }}
        currentEdit={currentEdit}
        text="edit"
      />
    </div>
  );
}

export default InInvoice;
