import customDataTableStyle from 'assets/styles/customDataTableStyle';
import EmptyView from 'features/Others/components/EmptyView';
import { setSelectedOrderInfo } from 'features/Payment/paymentSlice';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

ViewPaymentHistoryDetail.propTypes = {
  item: PropTypes.object,
  onPressCancelOrder: PropTypes.func,
  onPressResendEmail: PropTypes.func,
};

ViewPaymentHistoryDetail.defaultProps = {
  item: null,
  onPressCancelOrder: null,
  onPressResendEmail: null,
};

/**
 *
 * @param {{
 * item: object,
 * onPressCancelOrder: function,
 * onPressResendEmail: function,
 * }} props
 * @returns
 */
function ViewPaymentHistoryDetail(props) {
  // ---- params -----
  const { item, onPressCancelOrder, onPressResendEmail } = props;
  const { t } = useTranslation();
  const orderStatus = item?.statusOrder;
  const paymentType = item?.typePayment;
  const orderItems = item?.OrderItems;
  // console.log({ orderItems, item });
  const columns = useMemo(() => {
    return [
      {
        name: t('PackageOrService'),
        sortable: false,
        minWidth: '220px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.package?.packageCode}
            </p>
          );
        },
      },
      {
        name: t('Unit'),
        sortable: false,
        width: '90px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.package?.unit}
            </p>
          );
        },
      },
      {
        name: t('Quantity'),
        sortable: false,
        width: '120px',
        right: true,
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.quantity}
            </p>
          );
        },
      },
      {
        name: t('Price'),
        sortable: false,
        width: '140px',
        right: true,
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {Utils.formatCurrency(row?.package?.packageCost)}
            </p>
          );
        },
      },
      {
        name: t('LastPrice'),
        sortable: false,
        width: '140px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {Utils.formatCurrency(row?.package?.packageCost * row?.quantity)}
            </p>
          );
        },
      },
    ];
  }, [t]);
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (item) {
      dispatch(setSelectedOrderInfo(item));
    }
  }, [item]);

  // ---- methods ----
  function handleCancelOrder() {
    if (onPressCancelOrder) {
      onPressCancelOrder();
    }
  }

  function handleResendEmail() {
    if (onPressResendEmail) {
      onPressResendEmail();
    }
  }

  return (
    <div>
      {/* chi tiet don hang */}
      <div>
        <VIDataTable
          draggable={false}
          tableStyles={customDataTableStyle}
          additionalClassName="p-0 shadow-none"
          additionalCardBodyClassName="p-0"
          selectable={false}
          pagination={null}
          onChangePage={(page) => {}}
          onChangeRowsPerPage={(perPage) => {}}
          fullColumns={columns}
          data={orderItems}
          loading={false}
          onRowClick={(row) => {}}
          onSetSelectedMultiItems={(rows) => {}}
          haveCardHeader={false}
          emptyComponent={
            <EmptyView message={t('OrderDetailEmpty')} image={AppResource.images.imgEmptyPayment} />
          }
        />
      </div>

      <div className="separator separator-dashed mt-2 mb-4"></div>

      <div className="d-flex justify-content-between flex-wrap align-items-start">
        <div>
          {t('PaymentType')}:{' '}
          <span className="font-weight-bolder">
            {t(_.find(AppData.paymentTypeItems, (item) => item.value === paymentType)?.name)}
          </span>
        </div>

        <div>
          {/* tong cong */}
          <div className="">
            <div className="d-flex pb-4 align-items-start justify-content-between gap-4">
              <div className="">{t('TotalPrice')}</div>
              <div className="font-size-lg text-grey">
                {Utils.formatCurrency(item?.totalCost ?? 0)} {'VND'}
              </div>
            </div>
          </div>
          {/* khuyen mai */}
          <div className="">
            <div className="d-flex pb-4 align-items-start justify-content-between gap-4">
              <div className="d-flex align-items-center gap-2">
                {t('Promotion')} {/* {selectedVoucher && ( */}
                {item?.voucherCode && (
                  <KTTooltip
                    text={
                      <div className="d-flex flex-column align-items-start">
                        <p className="m-0">
                          <span>{t('VoucherCode')}:</span>{' '}
                          <span className="font-weight-bolder">{item?.voucherCode}</span>
                        </p>
                        {/* <p className="m-0">
                        {t('VoucherName')}: {'voucherName'}
                      </p> */}
                      </div>
                    }
                  >
                    <i className="fa-regular fa-circle-question text-primary" />
                  </KTTooltip>
                )}
                {/* )} */}
              </div>
              <div className="font-size-lg text-grey">
                {Utils.formatCurrency(item?.promotion ?? 0)} {'VND'}
              </div>
            </div>
          </div>
          {/* tong phai tra */}
          <div className="">
            <div className="d-flex pb-0 align-items-start justify-content-between gap-4">
              <div className="">{t('TotalPricePay')}</div>
              <div className="d-flex flex-column align-items-end">
                <span className="font-size-lg text-grey font-weight-bolder">
                  {Utils.formatCurrency(item?.totalPayment ?? 0)} {'VND'}
                </span>{' '}
                <span>({t('IncludedVAT')})</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* actions */}
      {orderStatus === AppData.paymentStatus.pending && (
        <div className="d-flex align-items-center justify-content-end gap-3 mt-6">
          {paymentType === AppData.paymentType.bankTransfer && (
            <VIButton
              text={t('ResendEmailConfirmPayment')}
              className="btn-grey"
              style={{ padding: '8px' }}
              onClick={handleResendEmail}
            />
          )}
          <VIButton
            text={t('CancelOrder')}
            className="btn-red"
            style={{ padding: '8px' }}
            onClick={handleCancelOrder}
          />
          {paymentType === AppData.paymentType.vnpay && (
            <VIButton
              text={t('PaymentNow')}
              className="btn-blue"
              style={{ padding: '8px' }}
              onClick={() => {
                Global.gTempPrevPath = router.pathname;
                router.navigate('/store/order-info');
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ViewPaymentHistoryDetail;
