import { Modal } from 'react-bootstrap';
import './style.scss';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { useEffect, useState } from 'react';
import VIButton from '../VIButton';
import { useTranslation } from 'react-i18next';
import Utils from 'general/utils/Utils';
import ToastHelper from 'general/helpers/ToastHelper';
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
  KTBSDropdownDirections,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import VIBodyIcons from '../VIBodyIcons';
import VIModal from '../VIModal';
import ListOfOccupations from 'features/Mail/screens/component/ListOfOccupations';
import invoiceApi from 'api/invoiceApi';
import NoteInvoice from 'features/Invoice/screens/InputInvoiceScreen/components/ModalNoteInvoice';
import ModalLabeled from 'features/Tag/components/ModalLabeled';
import AddLabel from 'features/Tag/components/AddLabel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  thunkCreateTagList,
  thunkDeleteTagList,
  thunkGetTagList,
  thunkUpdateTagList,
} from 'features/Tag/tagSlice';
import { AppDialogHelper } from 'general/components/AppDialog';
import store from 'app/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { thunkGetAllInInvoice } from 'features/Invoice/invoiceSlice';
import Global from 'general/utils/Global';
import InvoiceHelper from 'general/helpers/InvoiceHelper';
import VILoading from '../VILoading';
import Tippy from '@tippyjs/react/headless';
import ModalAccept from 'features/Invoice/screens/InputInvoiceScreen/components/ModalAccept';
import KTTooltip, { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip';
import axiosClient from 'api/axiosClient';
import PropTypes from 'prop-types';
import VIElements from '../VIElements';
import CheckTaxRate from 'features/Invoice/screens/components/CheckTaxRate';
import { useWindowSize } from '@uidotdev/usehooks';
import useAccount from 'hooks/useAccount';
import { useSelector } from 'react-redux';

VIModalResize.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  currentDetail: PropTypes.any,
  listData: PropTypes.any,
};

VIModalResize.defaultProps = {
  show: false,
  onHide: () => {},
  currentDetail: null,
  listData: [],
};
function VIModalResize(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    show,
    onHide,
    currentDetail,
    listData,
    clearSelectedRows,
    setClearSelectedRows,
    setDataCheck,
  } = props;
  const size = useWindowSize();
  const [sizes, setSizes] = useState(['45%', '55%']);
  const [showListOfOccupations, setShowListOfOccupations] = useState(false);
  const [dataDetail, setDataDetail] = useState();
  const [loading, setLoading] = useState();
  const [modalCheckShowing, setModalCheckShowing] = useState(false);
  const [dataNote, setDataNote] = useState();
  const [showModalNoteInvoices, setShowModalNoteInvoices] = useState(false);
  const [showModalLabeled, setShowModalLabeled] = useState(false);
  const [showModalAddLabeled, setShowModalAddLabeled] = useState(false);
  const [modalShowEditLabel, setShowModalEditLabel] = useState(false);
  const [currentChangeLabel, setCurrentChangeLabel] = useState();
  const [listCurrentLabels, setListCurrentLabels] = useState([]);
  const [showModalDeleteLabel, setShowModalDeleteLabel] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [modalShowAcceptSignNCC, setShowModalAcceptSignNCC] = useState(false);
  const [modalShowAcceptSignCQT, setShowModalAcceptSignCQT] = useState(false);
  const [modalShowAcceptNameBuyers, setShowModalAcceptNameBuyers] = useState(false);
  const [modalShowAcceptNameSellers, setShowModalAcceptNameSellers] = useState(false);
  const [modalShowAcceptTaxCodeBuyers, setShowModalAcceptTaxCodeBuyers] = useState(false);
  const [modalShowAcceptAddressBuyers, setShowModalAcceptAddressBuyers] = useState(false);
  const [modalShowAcceptAddressSellers, setShowModalAcceptAddressSellers] = useState(false);
  const [modalShowAcceptTaxCodeSellers, setShowModalAcceptTaxCodeSellers] = useState(false);
  const account = useAccount();

  const [modalShowCheckTaxRate, setShowModalCheckTaxRate] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [dateNameBuyers, setDateNameBuyers] = useState({
    fromDate: new Date(),
    toDate: moment().add(7, 'days')?._d,
  });
  const [dateNameSellers, setDateNameSellers] = useState({
    fromDate: new Date(),
    toDate: moment().add(7, 'days')?._d,
  });
  const [dateTaxCodeBuyers, setDateTaxCodeBuyers] = useState({
    fromDate: new Date(),
    toDate: moment().add(7, 'days')?._d,
  });
  const [dateTaxCodeSeller, setDateTaxCodeSeller] = useState({
    fromDate: new Date(),
    toDate: moment().add(7, 'days')?._d,
  });
  const [dateAddressBuyers, setDateAddressBuyers] = useState({
    fromDate: new Date(),
    toDate: moment().add(7, 'days')?._d,
  });
  const [dateAddressSellers, setDateAddressSellers] = useState({
    fromDate: new Date(),
    toDate: moment().add(7, 'days')?._d,
  });
  const [dataNext, setDataNext] = useState();
  const [loadingResult, setLoadingResult] = useState(false);
  const IconArr = [
    {
      icon: <i className="fa-regular fa-print"></i>,
      name: !loadingResult ? (
        t('PrintTestResults')
      ) : (
        <div className="d-flex align-items-center">
          {t('PrintTestResults')}
          <div className="ms-2 ">
            <VILoading size="sm" variant="blue" />
          </div>
        </div>
      ),
    },
    {
      icon: <i className="fa-regular fa-print"></i>,
      name: t('PrintInvoice'),
    },
    {
      icon: <i className="fa-regular fa-tag"></i>,
      name: t('Labeled'),
    },
    {
      icon: <i className="fa-regular fa-message-lines"></i>,
      name: t('Note'),
    },
    {
      icon: <i className="fa-regular fa-download"></i>,
      name: t('Download'),
    },
  ];

  const [labelDetail, setLabelDetail] = useState();

  const { forceReload: forceReloadInvoice } = useSelector((state) => state?.invoice);

  useEffect(() => {
    if (currentDetail && listData) {
      setDataNext(listData.findIndex((x) => x?.invoiceId === currentDetail?.invoiceId));
    }
  }, [currentDetail, listData]);

  useEffect(() => {
    if (currentDetail?.invoiceId) {
      const fetchApi = async () => {
        setLoadingPdf(true);
        try {
          const result = await invoiceApi?.getDetailInvoice(listData?.[dataNext]?.invoiceId);
          if (result?.result === 'success') {
            setDataDetail(result?.invoice);
            setLoadingPdf(false);
          }
        } catch (error) {}
      };
      fetchApi();
    }
  }, [dataNext, loading, forceReloadInvoice]);
  //Kiểm tra hóa đơn
  const handleCheck = async () => {
    setModalCheckShowing(true);
    setTimeout(() => {
      setModalCheckShowing(false);
    }, 2000);
    const check = await invoiceApi.validateInvoice({
      invoiceIds: [currentDetail?.invoiceId],
    });
    if (check.result === 'success') {
      // setLoading(Math.random());
      // dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
      if (clearSelectedRows) {
        setDataCheck([]);
        setClearSelectedRows(!clearSelectedRows);
      }
    }
  };
  //ghi chú hóa đơn
  useEffect(() => {
    setDataNote(dataDetail?.noteInvoice ?? '');
  }, [dataDetail]);
  const handleNoteInvoice = async () => {
    const note = await invoiceApi.noteInvoice({
      invoiceIds: [currentDetail?.invoiceId],
      noteInvoice: dataNote ? dataNote : '',
    });
    if (note.result === 'success') {
      setShowModalNoteInvoices(false);
      ToastHelper.showSuccess(t('CreateNoteSuccessfully'));
      setLoading(Math.random());
      dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
      if (clearSelectedRows) {
        setDataCheck([]);
        setClearSelectedRows(!clearSelectedRows);
      }
    }
  };

  //gán nhãn
  let idTag = [];
  for (let i = 0; i < listCurrentLabels?.length; i++) {
    idTag.push(listCurrentLabels[i]?.tagId);
  }

  const handleAddTagInvoices = async () => {
    const result = await invoiceApi.addTagsInvoice({
      tagIds: idTag,
      invoiceIds: [currentDetail?.invoiceId],
    });
    if (result?.result === 'success') {
      setShowModalLabeled(false);
      ToastHelper.showSuccess(t('LabelingSuccess'));
      setLoading(Math.random());
      dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
      if (clearSelectedRows) {
        setDataCheck([]);
        setClearSelectedRows(!clearSelectedRows);
      }
    }
  };
  const [showDeleteLabel, setShowDeleteLabel] = useState(false);
  function handleDeleteLabel(label) {
    setShowDeleteLabel(true);
    AppDialogHelper.show(t('DeleteLabel'), t('ConfirmDeleteInvoice', { name: label?.name }), [
      {
        title: t('Cancel'),
        type: 'neutral',
        onClick: () => {
          setShowDeleteLabel(false);
          AppDialogHelper.hide();
        },
      },
      {
        title: t('Delete'),
        type: 'negative',
        onClick: () => {
          handleDeleteLabels(label);
        },
      },
    ]);
  }

  const handleDeleteLabels = async (label) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkDeleteTagList([label?.tagId])));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('LabelRemovalSuccessful'));
        store.dispatch(thunkGetTagList());
        AppDialogHelper.hide();
        setShowDeleteLabel(false);
        setListCurrentLabels(listCurrentLabels?.filter((x) => x.tagId !== label?.tagId));
        setClearSelectedRows(!clearSelectedRows);

        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  };
  const [textLabel, setTextLabel] = useState('');
  // MARK --- Hooks ---

  // tạo, sửa nhãn
  const formik = useFormik({
    initialValues: {
      name: '',
      color: '#FD7972',
      partnerCompanyTaxCodes: '',
    },
    validationSchema: Yup.object({
      name: Yup.string('Vui lòng nhập tên nhãn').required('Vui lòng nhập tên nhãn'),
    }),
    onSubmit: async (values) => {
      if (textLabel === 'AddLabel') {
        try {
          const res = unwrapResult(await store.dispatch(thunkCreateTagList(values)));
          const { result, tag } = res;
          if (result === 'success' && tag) {
            ToastHelper.showSuccess(t('CreateNewLabelSuccessfully'));
            store.dispatch(thunkGetTagList());
            setShowModalAddLabeled(false);
            formik.resetForm();
            return true;
          }
        } catch (error) {
          console.log({ error });
          return false;
        }
      } else {
        try {
          const res = unwrapResult(
            await store.dispatch(
              thunkUpdateTagList({ id: currentChangeLabel?.tagId, params: values })
            )
          );
          const { result, tag } = res;
          if (result === 'success' && tag) {
            ToastHelper.showSuccess(t('EditLabelSuccessfully'));
            store.dispatch(thunkGetTagList());
            setShowModalEditLabel(false);
            formik.resetForm();
            return true;
          }
        } catch (error) {
          console.log({ error });
          return false;
        }
      }
    },
  });

  //in hóa đơn
  const handlePrintInvoices = () => {
    const res = InvoiceHelper.PrintInvoice(
      dataDetail?.pdfFile ? [dataDetail] : [{ ...dataDetail, pdfFile: dataDetail?.previewPdfFile }]
    );
  };
  // in kết quả kiểm tra

  const handlePrintResult = () => {
    setLoadingResult(true);
    axiosClient
      .get(process.env.REACT_APP_API_URL + `/invoice/print-result/${dataDetail?.invoiceId}`, {
        httpHeaders: {
          'session-token': axiosClient.defaults.headers.common['session-token'],
        },
        responseType: 'blob',
      })
      .then((response) => {
        setLoadingResult(false);
        var pdf = URL.createObjectURL(response);
        var iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = pdf;
        document.body.append(iframe);
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      })
      .catch((error) => {
        console.log(error), setLoadingResult(false);
      });
  };

  // tải xuống hóa đơn

  const [dataDownloads, setDataDownloads] = useState([]);
  let dataDownload = [];

  useEffect(() => {
    if (dataDetail?.pdfFile) {
      dataDownload.push(dataDetail);
    }
    if (dataDetail?.previewPdfFile) {
      dataDownload.push({ ...dataDetail, pdfFile: dataDetail?.previewPdfFile });
    }
    if (dataDetail?.xmlFile) {
      dataDownload.push({ ...dataDetail, pdfFile: dataDetail.xmlFile });
    }
    setLabelDetail(dataDetail?.Tags);
    setDataDownloads(dataDownload);
  }, [dataDetail]);

  const handleDownLoadInvoices = () => {
    const name = Global.gDownLoadZipName;
    const res = InvoiceHelper.DownLoadInvoices(name, dataDownloads);
  };

  const handleUploadPdf = async (e) => {
    const form = new FormData();
    form.append('pdfFile', e?.target?.files[0]);
    if (e?.target?.files[0]) {
      try {
        const res = await invoiceApi.upFileInvoice(dataDetail?.invoiceId, form);
        if (res?.result === 'success') {
          ToastHelper.showSuccess(t('UploadFileSuccess'));
          setLoading(Math.random());
          dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
          if (clearSelectedRows) {
            setDataCheck([]);
            setClearSelectedRows(!clearSelectedRows);
          }
        }
      } catch (error) {}
    }
  };
  const handleDownLoadFile = async (item) => {
    let alink = document.createElement('a');
    alink.download = item?.fileName;
    axiosClient
      .get(process.env.REACT_APP_BASE_URL + item.url, {
        httpHeaders: {
          'session-token': axiosClient.defaults.headers.common['session-token'],
        },
        responseType: 'blob',
      })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(response);
        alink.href = fileURL;
        alink.click();
      })
      .catch((error) => {});
  };

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }
  return (
    <Modal
      show={show}
      onHide={() => {
        onHide(), setDataNext();
      }}
      centered
      size="xl"
      className="Modal-resize"
    >
      <Modal.Body className="p-0">
        <div style={{ height: '100vh' }} className="pdf-default">
          <SplitPane split="vertical" sizes={sizes} onChange={(e) => setSizes(e)}>
            <Pane minSize={'500px'}>
              <div className="position-relative h-100">
                {loadingPdf ? (
                  <div className="w-100 h-100 d-flex justify-content-center">
                    <VILoading tableMaxHeight="100%" />
                  </div>
                ) : (
                  <>
                    {dataDetail?.pdfFile ? (
                      <>
                        {dataDetail?.pdfFile && dataDetail?.pdfFile?.split('.')?.pop() === 'pdf' ? (
                          <iframe
                            src={Utils.getFullUrl(dataDetail?.pdfFile)}
                            className="h-100 w-100 "
                            type="application/pdf"
                          ></iframe>
                        ) : (
                          <img src={Utils.getFullUrl(dataDetail?.pdfFile)} className="w-100 " />
                        )}
                      </>
                    ) : (
                      <iframe
                        src={Utils.getFullUrl(dataDetail?.previewPdfFile)}
                        className="h-100 w-100 "
                        type="application/pdf"
                      ></iframe>
                    )}
                  </>
                )}
                {/* <div
                  className="position-absolute"
                  style={{ top: '50px', height: '80%', width: '5%', right: '0' }}
                ></div> */}
                <div
                  className="position-absolute d-flex align-items-center w-100 px-3 py-2"
                  style={{
                    bottom: '0px',
                    background: 'var(--secondary-yeallow, #FFA318)',
                    minWidth: '500px',
                    overflow: 'auto',
                  }}
                >
                  <div className="d-flex justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <i
                        className="fa-regular fa-triangle-exclamation me-3"
                        style={{ color: 'var(--text-text-1, #1C2256)', fontSize: '48px' }}
                      ></i>
                      <div
                        style={{
                          color: '#1C2256',
                          fontWeight: '500',
                        }}
                      >
                        <div>
                          Đây là bản thể hiện của hóa đơn dựa trên nội dung file{' '}
                          {dataDetail?.pdfFile ? 'pdf/ảnh' : 'XML'}.
                        </div>
                        <div>
                          Nội dung này có thể không chính xác theo đúng biểu mẫu của hóa đơn đã phát
                          hành.
                        </div>
                        <div className="d-flex align-items-center">
                          Bạn có thể tải lên file pdf/ảnh mới
                          <p
                            className="mb-0 text-primary ms-2 cursor-pointer"
                            onClick={() => document.getElementById('upload-file-warning').click()}
                          >
                            Tại đây
                          </p>
                        </div>
                        <input
                          type="file"
                          id="upload-file-warning"
                          className="d-none"
                          onChange={(e) => {
                            handleUploadPdf(e);
                          }}
                        />
                      </div>
                    </div>
                    {dataDetail?.pdfFile && (
                      <VIButton
                        text={t('Delete')}
                        className="p-3 btn-grey my-3"
                        onClick={async () => {
                          try {
                            const res = await invoiceApi.deleteFilePdfInvoice(
                              dataDetail?.invoiceId
                            );
                            if (res.result === 'success') {
                              ToastHelper.showSuccess('Xóa tệp thành công');
                              setLoading(Math.random());
                              dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
                            }
                          } catch (error) {}
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Pane>
            <Pane maxSize="100%" minSize={'550px'}>
              <div
                style={{ background: '#fff', overflow: 'auto', height: 'auto' }}
                className="border-left"
              >
                {/* title */}
                <div
                  className="d-flex justify-content-between align-items-center px-5 py-2"
                  style={{
                    borderBottom: '1px solid var(--system-stroke, #E8E9EB)',
                    // minWidth: '770px',
                  }}
                >
                  <span>Thông tin và kết quả kiểm tra theo file XML của hóa đơn</span>
                  <VIButton
                    text={<i className="fa-regular fa-xmark d-flex align-items-center"></i>}
                    className="btn-grey px-2"
                    onClick={() => {
                      onHide(), setDataNext();
                    }}
                  />
                </div>
                {/* body */}
                {loadingPdf ? (
                  <div className="w-100 d-flex justify-content-center" style={{ height: '90vh' }}>
                    <VILoading tableMaxHeight="100%" />
                  </div>
                ) : (
                  <>
                    <div
                      id="result-print"
                      style={{
                        maxHeight: `calc((100vh - ${110}px))`,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                      }}
                    >
                      <div
                        className={`d-flex w-100 justify-content-between ${
                          sizes?.[1] < 700 ? 'flex-wrap' : ''
                        }`}
                      >
                        <div className="py-4 px-6 flex-grow-1" style={{ minWidth: '360px' }}>
                          <div className="border-bottom py-4">
                            <div className="">
                              <i className="fa-solid fa-bag-shopping text-primary me-3"></i>
                              <span className="text-primary font-weight-bold font-size-lg">
                                {t('SalesUnit')}
                              </span>
                            </div>
                            <div className="d-flex mt-2">
                              <div
                                className="font-weight-bold"
                                style={{ minWidth: '80px', color: '#3C4376' }}
                              >
                                {t('Unit')}:
                              </div>
                              {dataDetail?.sellerName ? (
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.sellerName}
                                </span>
                              ) : (
                                <span
                                  className="ms-2 font-weight-bold"
                                  style={{ color: '#7177A8' }}
                                >
                                  Không có thông tin
                                </span>
                              )}
                            </div>
                            <div className="d-flex mt-2">
                              <div
                                className="font-weight-bold"
                                style={{ minWidth: '80px', color: '#3C4376' }}
                              >
                                {t('TaxCode')}:
                              </div>
                              {dataDetail?.sellerTaxCode ? (
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.sellerTaxCode}
                                </span>
                              ) : (
                                <span
                                  className="ms-2 font-weight-bold"
                                  style={{ color: '#7177A8' }}
                                >
                                  Không có thông tin
                                </span>
                              )}
                            </div>
                            <div className="d-flex mt-2">
                              <div
                                className="font-weight-bold"
                                style={{ minWidth: '80px', color: '#3C4376' }}
                              >
                                {t('Address')}:
                              </div>
                              {dataDetail?.sellerAddress ? (
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.sellerAddress}
                                </span>
                              ) : (
                                <span
                                  className="ms-2 font-weight-bold"
                                  style={{ color: '#7177A8' }}
                                >
                                  Không có thông tin
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="border-bottom py-4">
                            <div>
                              <i className="fa-solid fa-cart-shopping text-primary me-3"></i>
                              <span className="text-primary font-weight-bold font-size-lg">
                                {t('PurchaseUnit')}
                              </span>
                            </div>
                            <div className="d-flex mt-2">
                              <div
                                className="font-weight-bold"
                                style={{ minWidth: '80px', color: '#3C4376' }}
                              >
                                {t('Unit')}:
                              </div>
                              {dataDetail?.buyerName ? (
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.buyerName}
                                </span>
                              ) : (
                                <span
                                  className="ms-2 font-weight-bold"
                                  style={{ color: '#7177A8' }}
                                >
                                  Không có thông tin
                                </span>
                              )}
                            </div>
                            <div className="d-flex mt-2">
                              <div
                                className="font-weight-bold"
                                style={{ minWidth: '80px', color: '#3C4376' }}
                              >
                                {t('TaxCode')}:
                              </div>
                              {dataDetail?.buyerTaxCode ? (
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.buyerTaxCode}
                                </span>
                              ) : (
                                <span
                                  className="ms-2 font-weight-bold"
                                  style={{ color: '#7177A8' }}
                                >
                                  Không có thông tin
                                </span>
                              )}
                            </div>
                            <div className="d-flex mt-2">
                              <div
                                className="font-weight-bold"
                                style={{ minWidth: '80px', color: '#3C4376' }}
                              >
                                {t('Address')}:
                              </div>
                              {dataDetail?.buyerAddress ? (
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.buyerAddress}
                                </span>
                              ) : (
                                <span
                                  className="ms-2 font-weight-bold"
                                  style={{ color: '#7177A8' }}
                                >
                                  Không có thông tin
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="py-4">
                            <div>
                              <i className="fa-solid fa-money-bill text-primary me-3"></i>
                              <span className="text-primary font-weight-bold font-size-lg">
                                {t('BillingInformation')}
                              </span>
                            </div>
                            <div className="d-flex mt-2 font-weight-bold">
                              <div
                                className="font-weight-bold"
                                style={{ minWidth: '150px', color: '#3C4376' }}
                              >
                                {t('TotalAmountOfGoods')}:
                              </div>
                              <span
                                className="ms-2 text-grey font-weight-bold"
                                style={{ color: '#1C2256' }}
                              >
                                {dataDetail?.finalAmount
                                  ? Utils.formatNumber(dataDetail?.amountBeforeVat)
                                  : 0}
                              </span>
                            </div>
                            {dataDetail?.amountAfterVatKCT > 0 && (
                              <div className="d-flex mt-2 font-weight-bold">
                                <div
                                  className="font-weight-bold"
                                  style={{ minWidth: '150px', color: '#3C4376' }}
                                >
                                  {t('Tổng giảm trừ không chịu thuế')}:
                                </div>
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.amountAfterVatKCT
                                    ? Utils.formatNumber(dataDetail?.amountAfterVatKCT)
                                    : 0}
                                </span>
                              </div>
                            )}
                            {dataDetail?.amountVat5 > 0 && (
                              <div className="d-flex mt-2 font-weight-bold">
                                <div
                                  className="font-weight-bold"
                                  style={{ minWidth: '150px', color: '#3C4376' }}
                                >
                                  {t('Thuế GTGT (thuế 5%)')}:
                                </div>
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.amountVat5
                                    ? Utils.formatNumber(dataDetail?.amountVat5)
                                    : 0}
                                </span>
                              </div>
                            )}
                            {dataDetail?.amountVat8 > 0 && (
                              <div className="d-flex mt-2 font-weight-bold">
                                <div
                                  className="font-weight-bold"
                                  style={{ minWidth: '150px', color: '#3C4376' }}
                                >
                                  {t('Thuế GTGT (thuế 8%)')}:
                                </div>
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.amountVat8
                                    ? Utils.formatNumber(dataDetail?.amountVat8)
                                    : 0}
                                </span>
                              </div>
                            )}
                            {dataDetail?.amountVat10 > 0 && (
                              <div className="d-flex mt-2 font-weight-bold">
                                <div
                                  className="font-weight-bold"
                                  style={{ minWidth: '150px', color: '#3C4376' }}
                                >
                                  {t('Thuế GTGT (thuế 10%)')}:
                                </div>
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.amountVat10
                                    ? Utils.formatNumber(dataDetail?.amountVat10)
                                    : 0}
                                </span>
                              </div>
                            )}
                            {dataDetail?.discountOther > 0 && (
                              <div className="d-flex mt-2 font-weight-bold">
                                <div
                                  className="font-weight-bold"
                                  style={{ minWidth: '150px', color: '#3C4376' }}
                                >
                                  {t('Tổng giảm trừ khác')}:
                                </div>
                                <span
                                  className="ms-2 text-grey font-weight-bold"
                                  style={{ color: '#1C2256' }}
                                >
                                  {dataDetail?.discountOther
                                    ? Utils.formatNumber(dataDetail?.discountOther)
                                    : 0}
                                </span>
                              </div>
                            )}
                            <div className="d-flex mt-2 font-weight-bold">
                              <div
                                className="font-weight-bold"
                                style={{ minWidth: '150px', color: '#3C4376' }}
                              >
                                {t('TotalPayment')}:
                              </div>
                              <span className="ms-2 font-weight-bold" style={{ color: '#1C2256' }}>
                                {dataDetail?.finalAmount
                                  ? Utils.formatNumber(dataDetail?.finalAmount)
                                  : 0}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`p-6 justify-items-center`}
                          style={
                            sizes?.[1] < 700
                              ? { width: '100%', margin: '0 auto' }
                              : { minWidth: '285px', margin: '0 auto' }
                          }
                        >
                          {dataDetail?.InvoiceValidate?.checkResultBuyerAddress &&
                          dataDetail?.InvoiceValidate?.checkResultBuyerName &&
                          dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode &&
                          dataDetail?.InvoiceValidate?.checkResultHasCQTRecord &&
                          dataDetail?.InvoiceValidate?.checkResultHasInvoiceCode &&
                          dataDetail?.InvoiceValidate?.checkResultSellerAddress &&
                          dataDetail?.InvoiceValidate?.checkResultSellerName &&
                          dataDetail?.InvoiceValidate?.checkResultSignatureCQT &&
                          dataDetail?.InvoiceValidate?.checkResultSignatureNCC ? (
                            <VIElements
                              id={'33'}
                              text={'Hóa đơn hợp lệ'}
                              style={{
                                color: 'var(--secondary-green, #28A043)',
                                background: 'rgba(40, 160, 67, 0.05)',
                                padding: '8px',
                                border: 'none',
                                width: '100%',
                                marginBottom: '8px',
                                fontWeight: '700',
                              }}
                            />
                          ) : (
                            <VIElements
                              id={'5'}
                              text={'Hóa đơn không hợp lệ'}
                              style={{
                                color: 'var(--secondary-red, #F44141)',
                                background: 'rgba(244, 65, 65, 0.10)',
                                padding: '8px',
                                border: 'none',
                                width: '100%',
                                marginBottom: '8px',
                                fontWeight: '700',
                              }}
                            />
                          )}
                          <span className="" style={{ color: '#7177A8', fontWeight: 400 }}>
                            Tại thời điểm tra cứu ngày{' '}
                            {dataDetail?.InvoiceValidate?.checkDate
                              ? Utils.formatDateTime(
                                  dataDetail?.InvoiceValidate?.checkDate,
                                  'DD/MM/YYYY'
                                )
                              : ''}
                          </span>
                          <div className="d-flex justify-content-center my-7">
                            <div
                              className="p-6"
                              style={{
                                border: '1px solid var(--system-stroke, #E8E9EB)',
                                borderRadius: '8px',
                                width: '100%',
                              }}
                            >
                              <div
                                className="w-100 d-flex justify-content-between mb-4"
                                style={{ width: 'max-content' }}
                              >
                                <span style={{ color: '#3C4376', fontWeight: 500 }}>
                                  {t('Type')}:
                                </span>

                                {dataDetail?.invoiceTypeCode ? (
                                  <span
                                    className="ms-2"
                                    style={{ color: '#1C2256', fontWeight: 700 }}
                                  >
                                    {Utils.getTypeInvoice(dataDetail?.invoiceTypeCode)}
                                  </span>
                                ) : (
                                  <span
                                    className="ms-2 "
                                    style={{ color: '#7177A8', fontWeight: 700 }}
                                  >
                                    Không có thông tin
                                  </span>
                                )}
                              </div>
                              <div
                                className="w-100 d-flex justify-content-between mb-4"
                                style={{ width: 'max-content' }}
                              >
                                <span style={{ color: '#3C4376', fontWeight: 500 }}>
                                  {t('Denominator')}:
                                </span>

                                {dataDetail?.invoiceTypeCode ? (
                                  <span
                                    className="ms-2"
                                    style={{ color: '#1C2256', fontWeight: 700 }}
                                  >
                                    {dataDetail?.invoiceTypeCode}
                                  </span>
                                ) : (
                                  <span
                                    className="ms-2 "
                                    style={{ color: '#7177A8', fontWeight: 700 }}
                                  >
                                    Không có thông tin
                                  </span>
                                )}
                              </div>

                              <div
                                className="w-100 d-flex justify-content-between mb-4"
                                style={{ width: 'max-content' }}
                              >
                                <span style={{ color: '#3C4376', fontWeight: 500 }}>
                                  {t('Symbol')}:
                                </span>

                                {dataDetail?.serial ? (
                                  <span
                                    className="ms-2"
                                    style={{ color: '#1C2256', fontWeight: 700 }}
                                  >
                                    {dataDetail?.serial}
                                  </span>
                                ) : (
                                  <span
                                    className="ms-2 "
                                    style={{ color: '#7177A8', fontWeight: 700 }}
                                  >
                                    Không có thông tin
                                  </span>
                                )}
                              </div>
                              <div
                                className="w-100 d-flex justify-content-between mb-4"
                                style={{ width: 'max-content' }}
                              >
                                <span style={{ color: '#3C4376', fontWeight: 500 }}>
                                  {t('InvoiceNumber')}:
                                </span>

                                {dataDetail?.invoiceNumber ? (
                                  <span
                                    className="ms-2"
                                    style={{ color: '#1C2256', fontWeight: 700 }}
                                  >
                                    {dataDetail?.invoiceNumber}
                                  </span>
                                ) : (
                                  <span
                                    className="ms-2 "
                                    style={{ color: '#7177A8', fontWeight: 700 }}
                                  >
                                    Không có thông tin
                                  </span>
                                )}
                              </div>
                              <div
                                className="w-100 d-flex justify-content-between mb-4"
                                style={{ width: 'max-content' }}
                              >
                                <span style={{ color: '#3C4376', fontWeight: 500 }}>
                                  {t('DateFounded')}:
                                </span>

                                {dataDetail?.invoiceDate ? (
                                  <span
                                    className="ms-2"
                                    style={{ color: '#1C2256', fontWeight: 700 }}
                                  >
                                    {Utils.formatDateTime(dataDetail?.invoiceDate, 'DD/MM/YYYY')}
                                  </span>
                                ) : (
                                  <span
                                    className="ms-2 "
                                    style={{ color: '#7177A8', fontWeight: 700 }}
                                  >
                                    Không có thông tin
                                  </span>
                                )}
                              </div>
                              <div
                                className="w-100 d-flex justify-content-between mb-4"
                                style={{ width: 'max-content' }}
                              >
                                <span style={{ color: '#3C4376', fontWeight: 500 }}>
                                  {t('SignDate')}:
                                </span>

                                {dataDetail?.sellerCKS ? (
                                  <span
                                    className="ms-2"
                                    style={{ color: '#1C2256', fontWeight: 700 }}
                                  >
                                    {Utils.formatDateTime(
                                      dataDetail?.sellerCKS?.SigningTime,
                                      'DD/MM/YYYY'
                                    )}
                                  </span>
                                ) : (
                                  <span
                                    className="ms-2 "
                                    style={{ color: '#7177A8', fontWeight: 700 }}
                                  >
                                    Không có thông tin
                                  </span>
                                )}
                              </div>
                              <div
                                className="w-100 d-flex justify-content-between mb-4"
                                style={{ width: 'max-content' }}
                              >
                                <span style={{ color: '#3C4376', fontWeight: 500 }}>
                                  {t('CodeIssuanceDate')}:
                                </span>

                                {dataDetail?.cqtCKS ? (
                                  <span
                                    className="ms-2"
                                    style={{ color: '#1C2256', fontWeight: 700 }}
                                  >
                                    {Utils.formatDateTime(
                                      dataDetail?.cqtCKS?.SigningTime,
                                      'DD/MM/YYYY'
                                    )}
                                  </span>
                                ) : (
                                  <span
                                    className="ms-2 "
                                    style={{ color: '#7177A8', fontWeight: 700 }}
                                  >
                                    Không có thông tin
                                  </span>
                                )}
                              </div>
                              {dataDetail?.sellerSignDate &&
                                moment(dataDetail?.sellerSignDate)?.isSame(
                                  dataDetail?.invoiceDate,
                                  'day'
                                ) && (
                                  <div className="mb-4 text-primary" style={{ fontWeight: 500 }}>
                                    Ngày ký trùng ngày lập
                                  </div>
                                )}
                              {dataDetail?.sellerSignDate &&
                                moment(dataDetail?.sellerCKS?.SigningTime)?.isSame(
                                  dataDetail?.cqtCKS?.SigningTime,
                                  'day'
                                ) && (
                                  <div className="text-primary" style={{ fontWeight: 500 }}>
                                    Ngày cấp mã trùng ngày ký
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="w-100 d-flex align-items-center">
                            {Utils.getInvoiceStatus(dataDetail?.statusInvoiceText)}
                          </div>
                          {((dataDetail?.amountVat8 >= 0 &&
                            dataDetail?.TaxReduceValidates?.length > 0) ||
                            (dataDetail?.amountVat10 >= 0 &&
                              dataDetail?.TaxReduceValidates?.length > 0)) && (
                            <div className="w-100 mt-2">
                              {dataDetail?.TaxReduceValidates.some(
                                (item) => item.checkResult === 'INVALID'
                              ) ? (
                                <div className="text-center">
                                  <VIButton
                                    className="w-100 btn-pauses mb-2"
                                    text={'Thuế suất chưa chính xác'}
                                    beforeIcon={<i className="fa-regular fa-badge-percent"></i>}
                                  />
                                  <span
                                    className="text-primary w-auto text-center pt-3 cursor-pointer"
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() => setShowModalCheckTaxRate(true)}
                                  >
                                    Kiểm tra lại
                                  </span>
                                </div>
                              ) : dataDetail?.TaxReduceValidates.every(
                                  (item) => item.checkResult === 'VALID'
                                ) ? (
                                <div className="text-center">
                                  <VIButton
                                    className="w-100 btn-greens mb-2"
                                    text={'Thuế suất chính xác'}
                                    beforeIcon={<i className="fa-regular fa-badge-percent"></i>}
                                  />
                                  <span
                                    className="text-primary w-auto text-center pt-3 cursor-pointer"
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() => setShowModalCheckTaxRate(true)}
                                  >
                                    Kiểm tra lại
                                  </span>
                                </div>
                              ) : (
                                <VIButton
                                  className="w-100 btn-Check"
                                  text={'Kiểm tra thuế suất hóa đơn'}
                                  beforeIcon={<i className="fa-regular fa-badge-percent"></i>}
                                  onClick={() => setShowModalCheckTaxRate(true)}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="d-flex mx-7 p-7"
                        style={{
                          border: '2px solid var(--system-stroke, #E8E9EB)',
                          borderRadius: '20px',
                        }}
                      >
                        <div className="w-50">
                          <span className="text-primary font-weight-bold font-size-lg ">
                            {t('InvoiceCheckResults')}
                          </span>
                          {dataDetail?.InvoiceValidate?.checkResultBuyerName &&
                          dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode &&
                          dataDetail?.InvoiceValidate?.checkResultBuyerAddress ? (
                            <div className="d-flex algin-items-center my-2">
                              <i
                                className="fa-regular fa-circle-check me-3 d-flex"
                                style={{ color: '#28A043', alignItems: 'center' }}
                              ></i>
                              <span className="text-grey font-weight-bold font-size-lg">
                                {t('CorrectBuyerInformation')}
                              </span>
                            </div>
                          ) : (
                            <div className="d-flex algin-items-center my-2">
                              <i
                                className="fa-regular fa-circle-minus me-3 d-flex "
                                style={{ color: '#FFA318', alignItems: 'center' }}
                              ></i>
                              <span className="text-grey font-weight-bold font-size-lg">
                                {t('InsufficientBuyerInformation')}
                              </span>
                            </div>
                          )}

                          <div className="" style={{ marginLeft: '29px', marginBottom: '15px' }}>
                            <Tippy
                              interactive
                              placement="bottom-end"
                              delay={[0, 20]}
                              offset={[-10, 0]}
                              render={() => (
                                <div
                                  className="bg-white tippy-tooltip p-5"
                                  style={{ maxWidth: '500px' }}
                                >
                                  <div className="d-flex justify-content-start flex-column">
                                    <p
                                      className="mb-0 font-weight-bold text-start"
                                      style={{ color: '#1C2256' }}
                                    >
                                      Tên người mua trên hóa đơn
                                    </p>
                                    <span className="children-p text-start">
                                      {dataDetail?.buyerName ?? 'không có tên người mua'}
                                    </span>
                                  </div>
                                  <div className="mt-4">
                                    <p className="mb-0 font-weight-bold text-grey text-start">
                                      Tên người mua đã khai báo
                                    </p>
                                    <span className="children-p text-start">
                                      {dataDetail?.InvoiceValidate?.resultBuyerName ??
                                        'Không có tên người mua'}
                                    </span>
                                  </div>
                                  {dataDetail?.buyerName &&
                                    !dataDetail?.InvoiceValidate?.checkResultBuyerName &&
                                    account?.role?.permissionCodes?.includes(
                                      'ACCEPT_BUYER_SELLER_INFO'
                                    ) && (
                                      <VIButton
                                        text="Chấp nhận tên người mua này"
                                        className="w-100 btn-grey mt-2"
                                        onClick={() => {
                                          setShowModalAcceptNameBuyers(true);
                                        }}
                                      />
                                    )}
                                </div>
                              )}
                            >
                              <div className="mt-2">
                                <i
                                  className="fas fa-circle me-3"
                                  style={
                                    dataDetail?.InvoiceValidate?.checkResultBuyerName
                                      ? { fontSize: '7px' }
                                      : dataDetail?.InvoiceValidate?.checkResultBuyerName === null
                                      ? { fontSize: '7px', color: '#E18700' }
                                      : { fontSize: '7px', color: '#F44141' }
                                  }
                                ></i>
                                {dataDetail?.InvoiceValidate?.checkResultBuyerName ? (
                                  <span>Đúng tên người mua</span>
                                ) : dataDetail?.InvoiceValidate?.checkResultBuyerName === null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có tên người mua trên cơ quan thuế
                                  </span>
                                ) : dataDetail?.buyerName === null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có tên người mua trên hóa đơn
                                  </span>
                                ) : (
                                  <span style={{ color: '#F44141' }}>Sai tên người mua</span>
                                )}
                              </div>
                            </Tippy>
                            <Tippy
                              interactive
                              placement="bottom-end"
                              delay={[0, 20]}
                              offset={[-130, 0]}
                              render={() => (
                                <div
                                  className="bg-white tippy-tooltip p-5"
                                  style={{ maxWidth: '500px' }}
                                >
                                  <div className="d-flex justify-content-start flex-column">
                                    <p className="mb-0 font-weight-bold text-grey text-start">
                                      Mã số thuế trên hóa đơn
                                    </p>
                                    <span className="children-p text-start">
                                      {dataDetail?.buyerTaxCode ?? 'Không có mã số thuế'}
                                    </span>
                                  </div>
                                  <div className="mt-4 d-flex justify-content-start flex-column">
                                    <p className="mb-0 font-weight-bold text-grey text-start">
                                      Mã số thuế đã khai báo
                                    </p>
                                    <span className="children-p text-start">
                                      {dataDetail?.InvoiceValidate?.resultBuyerTaxCode ??
                                        'Không có mã số thuế'}
                                    </span>
                                  </div>
                                  {/* {dataDetail?.buyerTaxCode &&
                                    !dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode && (
                                      <VIButton
                                        text="Chấp nhận mã số thuế này"
                                        className="w-100 btn-grey mt-2"
                                        onClick={() => {
                                          setShowModalAcceptTaxCodeBuyers(true);
                                        }}
                                      />
                                    )} */}
                                </div>
                              )}
                            >
                              <div className="mt-2">
                                <i
                                  className="fas fa-circle me-3"
                                  style={
                                    dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode
                                      ? { fontSize: '7px' }
                                      : dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode ===
                                        null
                                      ? { fontSize: '7px', color: '#E18700' }
                                      : { fontSize: '7px', color: '#F44141' }
                                  }
                                ></i>
                                {dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode ? (
                                  <span>Đúng mã số thuế người mua</span>
                                ) : dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode ===
                                  null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có mã số thuế người mua trên cơ quan thuế
                                  </span>
                                ) : dataDetail?.buyerTaxCode === null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có mã số thuế người mua trên hóa đơn
                                  </span>
                                ) : (
                                  <span style={{ color: '#F44141' }}>Sai mã số thuế người mua</span>
                                )}
                              </div>
                            </Tippy>
                            <Tippy
                              interactive
                              placement="bottom-end"
                              delay={[0, 20]}
                              offset={[20, 0]}
                              render={() => (
                                <div
                                  className="bg-white tippy-tooltip p-5"
                                  style={{ maxWidth: '500px' }}
                                >
                                  <div className="d-flex justify-content-start flex-column">
                                    <p className="mb-0 font-weight-bold text-grey text-start">
                                      Địa chỉ trên hóa đơn
                                    </p>
                                    <span className="children-p text-start">
                                      {dataDetail?.buyerAddress ?? 'Không có địa chỉ người mua'}
                                    </span>
                                  </div>
                                  <div className="mt-4 d-flex justify-content-start flex-column">
                                    <p className="mb-0 font-weight-bold text-grey text-start">
                                      Địa chỉ đã khai báo
                                    </p>
                                    <span className="children-p text-start">
                                      {dataDetail?.InvoiceValidate?.resultBuyerAddress ??
                                        'Không có địa chỉ người mua'}
                                    </span>
                                  </div>
                                  {dataDetail?.buyerAddress &&
                                    !dataDetail?.InvoiceValidate?.checkResultBuyerAddress &&
                                    account?.role?.permissionCodes?.includes(
                                      'ACCEPT_BUYER_SELLER_INFO'
                                    ) && (
                                      <VIButton
                                        text="Chấp nhận địa chỉ này"
                                        className="w-100 btn-grey mt-2"
                                        onClick={() => {
                                          setShowModalAcceptAddressBuyers(true);
                                        }}
                                      />
                                    )}
                                </div>
                              )}
                            >
                              <div className="mt-2">
                                <i
                                  className="fas fa-circle me-3"
                                  style={
                                    dataDetail?.InvoiceValidate?.checkResultBuyerAddress
                                      ? { fontSize: '7px' }
                                      : dataDetail?.InvoiceValidate?.checkResultBuyerAddress ===
                                        null
                                      ? { fontSize: '7px', color: '#E18700' }
                                      : { fontSize: '7px', color: '#F44141' }
                                  }
                                ></i>
                                {dataDetail?.InvoiceValidate?.checkResultBuyerAddress ? (
                                  <span>Đúng địa chỉ người mua</span>
                                ) : dataDetail?.buyerAddress === null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có địa chỉ người mua trên hóa đơn
                                  </span>
                                ) : dataDetail?.InvoiceValidate?.checkResultBuyerAddress ===
                                  null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có địa chỉ người mua trên cơ quan thuế
                                  </span>
                                ) : (
                                  <span style={{ color: '#F44141' }}>Sai địa chỉ người mua</span>
                                )}
                              </div>
                            </Tippy>
                          </div>
                          {dataDetail?.InvoiceValidate?.checkResultSellerName &&
                          dataDetail?.InvoiceValidate?.checkResultSellerAddress &&
                          dataDetail?.InvoiceValidate?.checkResultSellerTaxCode ? (
                            <div className="d-flex algin-items-center my-2">
                              <i
                                className="fa-regular fa-circle-check me-3 d-flex"
                                style={{ color: '#28A043', alignItems: 'center' }}
                              ></i>
                              <span className="text-grey font-weight-bold font-size-lg">
                                {t('CorrectSellerInformation')}
                              </span>
                            </div>
                          ) : (
                            <div className="d-flex algin-items-center mt-5 mb-2">
                              <i
                                className="fa-regular fa-circle-minus me-3 d-flex"
                                style={{ color: '#FFA318', alignItems: 'center' }}
                              ></i>
                              <span className="text-grey font-weight-bold font-size-lg">
                                {t('NotEnoughSellerInformation')}
                              </span>
                            </div>
                          )}

                          <div style={{ marginLeft: '29px', marginBottom: '15px' }}>
                            <Tippy
                              interactive
                              placement="bottom-end"
                              delay={[0, 20]}
                              offset={[80, 0]}
                              render={() => (
                                <div
                                  className="bg-white tippy-tooltip p-5"
                                  style={{ maxWidth: '500px' }}
                                >
                                  <div className="d-flex justify-content-start flex-column">
                                    <p className="mb-0 font-weight-bold text-grey d-flex justify-content-start">
                                      Tên người bán trên hóa đơn
                                    </p>
                                    <span className="children-p text-start">
                                      {dataDetail?.sellerName ?? 'Không có tên người bán'}
                                    </span>
                                  </div>
                                  <div className=" d-flex justify-content-start flex-column mt-4">
                                    <p className="mb-0 font-weight-bold text-grey text-start">
                                      Tên người bán kiểm tra trên website của cơ quan thuế
                                    </p>
                                    <span className="children-p text-start">
                                      {dataDetail?.InvoiceValidate?.resultSellerName ??
                                        'Không có tên người bán'}
                                    </span>
                                  </div>
                                  {dataDetail?.sellerName &&
                                    !dataDetail?.InvoiceValidate?.checkResultSellerName &&
                                    account?.role?.permissionCodes?.includes(
                                      'ACCEPT_BUYER_SELLER_INFO'
                                    ) && (
                                      <VIButton
                                        text="Chấp nhận tên người bán này"
                                        className="w-100 btn-grey mt-2"
                                        onClick={() => {
                                          setShowModalAcceptNameSellers(true);
                                        }}
                                      />
                                    )}
                                </div>
                              )}
                            >
                              <div className="mt-2">
                                <i
                                  className="fas fa-circle me-3"
                                  style={
                                    dataDetail?.InvoiceValidate?.checkResultSellerName
                                      ? { fontSize: '7px' }
                                      : dataDetail?.InvoiceValidate?.checkResultSellerName === null
                                      ? { fontSize: '7px', color: '#E18700' }
                                      : { fontSize: '7px', color: '#F44141' }
                                  }
                                ></i>
                                {dataDetail?.InvoiceValidate?.checkResultSellerName ? (
                                  <span>Đúng tên người bán</span>
                                ) : dataDetail?.sellerName === null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có tên người bán trên hóa đơn
                                  </span>
                                ) : dataDetail?.InvoiceValidate?.checkResultSellerName === null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có tên người bán trên cơ quan thuế
                                  </span>
                                ) : (
                                  <span style={{ color: '#F44141' }}>Sai tên người bán</span>
                                )}
                              </div>
                            </Tippy>
                            <Tippy
                              interactive
                              placement="bottom-end"
                              delay={[0, 20]}
                              offset={[80, 0]}
                              render={() => (
                                <div
                                  className="bg-white tippy-tooltip p-5"
                                  style={{ maxWidth: '500px' }}
                                >
                                  <div className="d-flex justify-content-start flex-column">
                                    <p className="mb-0 font-weight-bold text-grey d-flex justify-content-start">
                                      Mã số thuế người bán trên hóa đơn
                                    </p>
                                    <span className="children-p text-start">
                                      {dataDetail?.sellerTaxCode ?? 'Không có mã số thuế người bán'}
                                    </span>
                                  </div>
                                  <div className=" d-flex justify-content-start flex-column mt-4">
                                    <p className="mb-0 font-weight-bold text-grey text-start">
                                      Mã số thuế người bán kiểm tra trên website của cơ quan thuế
                                    </p>
                                    <span className="children-p text-start">
                                      {dataDetail?.InvoiceValidate?.resultSellerTaxCode ??
                                        'Không có mã số thuế người bán'}
                                    </span>
                                  </div>
                                  {/* {dataDetail?.sellerName &&
                                    !dataDetail?.InvoiceValidate?.checkResultSellerTaxCode && (
                                      <VIButton
                                        text="Chấp nhận mã số thuế người bán này"
                                        className="w-100 btn-grey mt-2"
                                        onClick={() => {
                                          setShowModalAcceptTaxCodeSellers(true);
                                        }}
                                      />
                                    )} */}
                                </div>
                              )}
                            >
                              <div className="mt-2">
                                <i
                                  className="fas fa-circle me-3"
                                  style={
                                    dataDetail?.InvoiceValidate?.checkResultSellerTaxCode
                                      ? { fontSize: '7px' }
                                      : dataDetail?.InvoiceValidate?.checkResultSellerTaxCode ===
                                        null
                                      ? { fontSize: '7px', color: '#E18700' }
                                      : { fontSize: '7px', color: '#F44141' }
                                  }
                                ></i>
                                {dataDetail?.InvoiceValidate?.checkResultSellerTaxCode ? (
                                  <span>Đúng mã số thuế người bán</span>
                                ) : dataDetail?.sellerTaxCode === null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có mã số thuế người bán trên hóa đơn
                                  </span>
                                ) : dataDetail?.InvoiceValidate?.checkResultSellerTaxCode ===
                                  null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có mã số thuế người bán trên cơ quan thuế
                                  </span>
                                ) : (
                                  <span style={{ color: '#F44141' }}>Sai mã số thuế người bán</span>
                                )}
                              </div>
                            </Tippy>
                            <Tippy
                              interactive
                              placement="bottom-end"
                              delay={[0, 20]}
                              offset={[20, 0]}
                              render={() => (
                                <div className="bg-white tippy-tooltip p-5">
                                  <div className="d-flex justify-content-start flex-column">
                                    <p className="mb-0 font-weight-bold text-grey text-start">
                                      Địa chỉ trên hóa đơn
                                    </p>
                                    <span
                                      className="children-p text-start"
                                      style={{ maxWidth: '450px', wordWrap: 'break-word' }}
                                    >
                                      {dataDetail?.sellerAddress ?? 'Không có địa chỉ người bán'}
                                    </span>
                                  </div>
                                  <div className="mt-4 d-flex justify-content-start flex-column">
                                    <p className="mb-0 font-weight-bold text-grey text-start">
                                      Địa chỉ kiểm tra trên website của cơ quan thuế
                                    </p>
                                    <span
                                      className="children-p text-start"
                                      style={{ maxWidth: '450px', wordWrap: 'break-word' }}
                                    >
                                      {dataDetail?.InvoiceValidate?.resultSellerAddress ??
                                        'Không có địa chỉ người bán'}
                                    </span>
                                  </div>
                                  {dataDetail?.sellerAddress &&
                                    !dataDetail?.InvoiceValidate?.checkResultSellerAddress &&
                                    account?.role?.permissionCodes?.includes(
                                      'ACCEPT_BUYER_SELLER_INFO'
                                    ) && (
                                      <VIButton
                                        text="Chấp nhận địa chỉ người bán này"
                                        className="w-100 btn-grey mt-2"
                                        onClick={() => {
                                          setShowModalAcceptAddressSellers(true);
                                        }}
                                      />
                                    )}
                                </div>
                              )}
                            >
                              <div className="mt-2">
                                <i
                                  className="fas fa-circle me-3"
                                  style={
                                    dataDetail?.InvoiceValidate?.checkResultSellerAddress
                                      ? { fontSize: '7px' }
                                      : dataDetail?.InvoiceValidate?.checkResultSellerAddress ===
                                        null
                                      ? { fontSize: '7px', color: '#E18700' }
                                      : { fontSize: '7px', color: '#F44141' }
                                  }
                                ></i>
                                {dataDetail?.InvoiceValidate?.checkResultSellerAddress ? (
                                  <span>Đúng địa chỉ người bán</span>
                                ) : dataDetail?.sellerAddress === null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có địa chỉ người bán trên hóa đơn
                                  </span>
                                ) : dataDetail?.InvoiceValidate?.checkResultSellerAddress ===
                                  null ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không có địa chỉ người bán trên cơ quan thuế
                                  </span>
                                ) : (
                                  <span style={{ color: '#F44141' }}>Sai địa chỉ người bán</span>
                                )}
                              </div>
                            </Tippy>

                            <span className="" style={{ fontStyle: 'italic' }}>
                              Đối chiếu với dữ liệu CQT ngày{' '}
                              {Utils.formatDateTime(
                                dataDetail?.InvoiceValidate?.checkDate,
                                'DD/MM/YYYY'
                              )}
                            </span>
                          </div>

                          {!dataDetail?.isManualInput && (
                            <>
                              {dataDetail?.InvoiceValidate?.checkResultSignatureCQT &&
                              dataDetail?.InvoiceValidate?.checkResultSignatureNCC ? (
                                <div className="d-flex algin-items-center my-2">
                                  <i
                                    className="fa-regular fa-circle-check me-3 d-flex"
                                    style={{ color: '#28A043', alignItems: 'center' }}
                                  ></i>
                                  <span className="text-grey font-weight-bold font-size-lg">
                                    {t('ValidElectronicSignature')}
                                  </span>
                                </div>
                              ) : (
                                <div className="d-flex algin-items-center mt-5 mb-2">
                                  <i
                                    className="fa-regular fa-circle-minus me-3 d-flex"
                                    style={{ color: '#FFA318', alignItems: 'center' }}
                                  ></i>
                                  <span className="text-grey font-weight-bold font-size-lg">
                                    {t('InvalidElectronicSignature')}
                                  </span>
                                </div>
                              )}

                              <div style={{ marginLeft: '29px' }}>
                                <Tippy
                                  interactive
                                  placement="bottom-end"
                                  delay={[0, 20]}
                                  offset={[20, 0]}
                                  render={() => (
                                    <div
                                      className="bg-white tippy-tooltip p-5"
                                      style={{ maxWidth: '500px' }}
                                    >
                                      <div className="d-flex justify-content-start flex-column">
                                        <p
                                          className="mb-0 font-weight-bold d-flex justify-content-start"
                                          style={{ color: '#1C2256' }}
                                        >
                                          Thông tin chứng thư
                                        </p>

                                        <span className="children-p text-start ms-3">
                                          <div className="d-flex">
                                            <div style={{ width: '40%' }}>
                                              Nhà phát hành chứng thư:{' '}
                                            </div>
                                            <div style={{ width: '60%', color: '#1C2256' }}>
                                              {dataDetail?.sellerCKS?.Issuer}
                                            </div>
                                          </div>
                                          <br></br>
                                          <div className="d-flex">
                                            <div style={{ width: '40%' }}>Đơn vị ký:</div>
                                            <div style={{ width: '60%', color: '#1C2256' }}>
                                              {dataDetail?.sellerName}
                                            </div>
                                          </div>

                                          <div className="d-flex">
                                            <div style={{ width: '40%' }}>
                                              {' '}
                                              Ngày bắt đầu hiệu lực:
                                            </div>{' '}
                                            <div style={{ width: '60%', color: '#1C2256' }}>
                                              {' '}
                                              {dataDetail?.sellerCKS?.NotBefore
                                                ? Utils.formatDateTime(
                                                    dataDetail?.sellerCKS?.NotBefore,
                                                    'DD/MM/YYYY'
                                                  )
                                                : 'Không có ngày bắt đầu hiệu lực'}
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div style={{ width: '40%' }}>
                                              Ngày kết thúc hiệu lực:
                                            </div>{' '}
                                            <div style={{ width: '60%', color: '#1C2256' }}>
                                              {dataDetail?.sellerCKS?.NotAfter
                                                ? Utils.formatDateTime(
                                                    dataDetail?.sellerCKS?.NotAfter,
                                                    'DD/MM/YYYY'
                                                  )
                                                : 'Không có ngày kết thúc hiệu lực'}
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div style={{ width: '40%' }}>Ngày ký hóa đơn:</div>{' '}
                                            <div style={{ width: '60%', color: '#1C2256' }}>
                                              {dataDetail?.sellerCKS?.SigningTime
                                                ? Utils.formatDateTime(
                                                    dataDetail?.sellerCKS?.SigningTime,
                                                    'DD/MM/YYYY'
                                                  )
                                                : 'Không có ngày ký hóa đơn'}
                                            </div>
                                          </div>
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-start flex-column mt-4">
                                        {dataDetail?.InvoiceValidate?.nb_cts_hop_le ? (
                                          <span className="children-p text-start d-flex me-2 mb-2">
                                            <i
                                              className="fa-regular fa-circle-check me-3 d-flex"
                                              style={{ color: '#28A043', alignItems: 'center' }}
                                            ></i>
                                            <span className="text-grey font-weight-bold font-size-lg">
                                              Thông tin thuê bao chứng thư số hợp lệ
                                            </span>
                                          </span>
                                        ) : dataDetail?.InvoiceValidate?.nb_cts_hop_le === null ? (
                                          <div className="d-flex algin-items-center mb-2">
                                            <i
                                              className="fa-regular fa-circle-minus me-3 d-flex align-items-center"
                                              style={{ color: '#FFA318', alignItems: 'center' }}
                                            ></i>

                                            <span className="text-grey font-weight-bold font-size-lg">
                                              Không có thông tin thuê bao chứng thư số
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="d-flex algin-items-center mb-2">
                                            <i
                                              className="fa-solid fa-circle-xmark me-3 d-flex align-items-center"
                                              style={{ color: '#F44141', alignItems: 'center' }}
                                            ></i>
                                            <span className="text-grey font-weight-bold font-size-lg">
                                              Thông tin thuê bao chứng thư số không hợp lệ
                                            </span>
                                          </div>
                                        )}
                                        {dataDetail?.InvoiceValidate?.nb_cts_con_hieu_luc ? (
                                          <span className="children-p text-start d-flex me-2 mb-2">
                                            <i
                                              className="fa-regular fa-circle-check me-3 d-flex"
                                              style={{ color: '#28A043', alignItems: 'center' }}
                                            ></i>
                                            <span className="text-grey font-weight-bold font-size-lg">
                                              Chứng thư số còn hiệu lực
                                            </span>
                                          </span>
                                        ) : dataDetail?.InvoiceValidate?.nb_cts_con_hieu_luc ===
                                          null ? (
                                          <div className="d-flex algin-items-center mb-2">
                                            <i
                                              className="fa-regular fa-circle-minus me-3 d-flex align-items-center"
                                              style={{ color: '#FFA318', alignItems: 'center' }}
                                            ></i>

                                            <span className="text-grey font-weight-bold font-size-lg">
                                              Không có chứng thư số
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="d-flex algin-items-center mb-2">
                                            <i
                                              className="fa-solid fa-circle-xmark me-3 d-flex align-items-center"
                                              style={{ color: '#F44141', alignItems: 'center' }}
                                            ></i>
                                            <span className="text-grey font-weight-bold font-size-lg">
                                              Chứng thư số hết hiệu lực
                                            </span>
                                          </div>
                                        )}
                                        {dataDetail?.InvoiceValidate?.nb_file_xml_chua_bi_sua ? (
                                          <span className="children-p text-start d-flex me-2 mb-2">
                                            <i
                                              className="fa-regular fa-circle-check me-3 d-flex"
                                              style={{ color: '#28A043', alignItems: 'center' }}
                                            ></i>
                                            <span className="text-grey font-weight-bold font-size-lg">
                                              File XML chưa bị sửa đổi sau khi ký
                                            </span>
                                          </span>
                                        ) : dataDetail?.InvoiceValidate?.nb_file_xml_chua_bi_sua ===
                                          null ? (
                                          <div className="d-flex algin-items-center mb-2">
                                            <i
                                              className="fa-regular fa-circle-minus me-3 d-flex align-items-center"
                                              style={{ color: '#FFA318', alignItems: 'center' }}
                                            ></i>

                                            <span className="text-grey font-weight-bold font-size-lg">
                                              Không có trạng thái File XML sau khi ký
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="d-flex algin-items-center mb-2">
                                            <i
                                              className="fa-solid fa-circle-xmark me-3 d-flex align-items-center"
                                              style={{ color: '#F44141', alignItems: 'center' }}
                                            ></i>
                                            <span className="text-grey font-weight-bold font-size-lg">
                                              File XML đã bị sửa đổi sau khi ký
                                            </span>
                                          </div>
                                        )}
                                        {dataDetail?.InvoiceValidate?.nb_thong_tin_cts_hop_le ? (
                                          <span className="children-p text-start d-flex me-2 mb-2">
                                            <i
                                              className="fa-regular fa-circle-check me-3 d-flex"
                                              style={{ color: '#28A043', alignItems: 'center' }}
                                            ></i>
                                            <span className="text-grey font-weight-bold font-size-lg">
                                              Chứng thư số hợp lệ
                                            </span>
                                          </span>
                                        ) : dataDetail?.InvoiceValidate?.nb_thong_tin_cts_hop_le ===
                                          null ? (
                                          <div className="d-flex algin-items-center mb-2">
                                            <i
                                              className="fa-regular fa-circle-minus me-3 d-flex align-items-center"
                                              style={{ color: '#FFA318', alignItems: 'center' }}
                                            ></i>

                                            <span className="text-grey font-weight-bold font-size-lg">
                                              Không có trạng thái chứng thư số
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="d-flex algin-items-center mb-2">
                                            <i
                                              className="fa-solid fa-circle-xmark me-3 d-flex align-items-center"
                                              style={{ color: '#F44141', alignItems: 'center' }}
                                            ></i>
                                            <span className="text-grey font-weight-bold font-size-lg">
                                              Chứng thư số không hợp lệ
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                >
                                  <div className="mt-2">
                                    <i
                                      className="fas fa-circle me-3"
                                      style={
                                        dataDetail?.InvoiceValidate?.checkResultSignatureNCC
                                          ? { fontSize: '7px' }
                                          : dataDetail?.InvoiceValidate?.checkResultSignatureNCC ===
                                            null
                                          ? { fontSize: '7px', color: '#E18700' }
                                          : { fontSize: '7px', color: '#F44141' }
                                      }
                                    ></i>
                                    {dataDetail?.InvoiceValidate?.checkResultSignatureNCC ? (
                                      <span>Chữ ký điện tử NCC hợp lệ</span>
                                    ) : dataDetail?.InvoiceValidate?.checkResultSignatureNCC ===
                                      null ? (
                                      <span style={{ color: '#E18700' }}>
                                        Không có chữ ký điện tử NCC
                                      </span>
                                    ) : (
                                      <span style={{ color: '#F44141' }}>
                                        Chữ ký điện tử NCC không hợp lệ
                                      </span>
                                    )}
                                  </div>
                                </Tippy>
                                <Tippy
                                  interactive
                                  placement="bottom-end"
                                  delay={[0, 20]}
                                  offset={[20, 0]}
                                  render={() => (
                                    <div
                                      className="bg-white tippy-tooltip p-5"
                                      style={{ maxWidth: '500px' }}
                                    >
                                      <div className="d-flex justify-content-start flex-column">
                                        <p className="mb-0 font-weight-bold text-grey d-flex justify-content-start">
                                          Thông tin chứng thư
                                        </p>
                                        <span className="children-p text-start ms-3">
                                          <div className="d-flex">
                                            <div style={{ width: '40%' }}>
                                              Nhà phát hành chứng thư:{' '}
                                            </div>
                                            <div style={{ width: '60%', color: '#1C2256' }}>
                                              {dataDetail?.cqtCKS?.Issuer}
                                            </div>
                                          </div>
                                          <br></br>
                                          <div className="d-flex">
                                            <div style={{ width: '40%' }}>Đơn vị ký:</div>
                                            <div style={{ width: '60%', color: '#1C2256' }}>
                                              {dataDetail?.cqtCKS?.Subject}
                                            </div>
                                          </div>

                                          <div className="d-flex">
                                            <div style={{ width: '40%' }}>
                                              {' '}
                                              Ngày bắt đầu hiệu lực:
                                            </div>{' '}
                                            <div style={{ width: '60%', color: '#1C2256' }}>
                                              {' '}
                                              {dataDetail?.cqtCKS?.NotBefore
                                                ? Utils.formatDateTime(
                                                    dataDetail?.cqtCKS?.NotBefore,
                                                    'DD/MM/YYYY'
                                                  )
                                                : 'Không có ngày bắt đầu hiệu lực'}
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div style={{ width: '40%' }}>
                                              Ngày kết thúc hiệu lực:
                                            </div>{' '}
                                            <div style={{ width: '60%', color: '#1C2256' }}>
                                              {dataDetail?.cqtCKS?.NotAfter
                                                ? Utils.formatDateTime(
                                                    dataDetail?.cqtCKS?.NotAfter,
                                                    'DD/MM/YYYY'
                                                  )
                                                : 'Không có ngày kết thúc hiệu lực'}
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div style={{ width: '40%' }}>Ngày ký hóa đơn:</div>{' '}
                                            <div style={{ width: '60%', color: '#1C2256' }}>
                                              {dataDetail?.cqtCKS?.SigningTime
                                                ? Utils.formatDateTime(
                                                    dataDetail?.cqtCKS?.SigningTime,
                                                    'DD/MM/YYYY'
                                                  )
                                                : 'Không có ngày ký hóa đơn'}
                                            </div>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                >
                                  {!dataDetail?.serial.startsWith('K') && (
                                    <div className="mt-2">
                                      <i
                                        className="fas fa-circle me-3"
                                        style={
                                          dataDetail?.InvoiceValidate?.checkResultSignatureCQT
                                            ? { fontSize: '7px' }
                                            : dataDetail?.InvoiceValidate
                                                ?.checkResultSignatureCQT === null
                                            ? { fontSize: '7px', color: '#E18700' }
                                            : { fontSize: '7px', color: '#F44141' }
                                        }
                                      ></i>

                                      {dataDetail?.InvoiceValidate?.checkResultSignatureCQT ? (
                                        <span>Đã được ký bởi Cơ quan thuế</span>
                                      ) : dataDetail?.InvoiceValidate?.checkResultSignatureCQT ? (
                                        <span style={{ color: '#E18700' }}>
                                          Không được ký bởi Cơ quan thuế
                                        </span>
                                      ) : (
                                        <span style={{ color: '#F44141' }}>
                                          Chưa được ký bởi Cơ quan thuế
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Tippy>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="w-50">
                          <span className="text-primary font-weight-bold font-size-lg">
                            {t('CheckWithTheGeneralDepartmentOfTaxationSystem')}
                          </span>
                          {dataDetail?.isManualInput ? (
                            <>
                              {dataDetail?.InvoiceValidate?.nb_dang_hoat_dong ? (
                                <div className="d-flex algin-items-center my-2">
                                  <i
                                    className="fa-regular fa-circle-check me-3 d-flex"
                                    style={{ color: '#28A043', alignItems: 'center' }}
                                  ></i>
                                  <span className="text-greyfont-weight-bold font-size-lg">
                                    {t('Người bán đang hoạt động')}
                                  </span>
                                </div>
                              ) : dataDetail?.InvoiceValidate?.nb_dang_hoat_dong === null ? (
                                <div className="d-flex algin-items-center my-2">
                                  <i
                                    className="fa-regular fa-circle-minus me-3 d-flex"
                                    style={{ color: '#FFA318', alignItems: 'center' }}
                                  ></i>
                                  <span className="text-grey font-weight-bold font-size-lg">
                                    {t('Không kiểm tra được trạng thái của người bán')}
                                  </span>
                                </div>
                              ) : (
                                <div className="d-flex algin-items-center my-2">
                                  <i
                                    className="fa-regular fa-circle-exclamation me-3 d-flex"
                                    style={{ color: '#F44141', alignItems: 'center' }}
                                  ></i>
                                  <span className="text-grey font-weight-bold font-size-lg">
                                    {t('Người bán không hoạt động')}
                                  </span>
                                </div>
                              )}
                              {dataDetail?.InvoiceValidate?.nb_khong_rui_ro_tai_tdlap ? (
                                <div className="d-flex algin-items-center my-2">
                                  <i
                                    className="fa-regular fa-circle-exclamation me-3 d-flex"
                                    style={{ color: '#F44141', alignItems: 'center' }}
                                  ></i>
                                  <span className="text-grey font-weight-bold font-size-lg">
                                    {t(
                                      'Người bán thuộc danh sách cảnh báo của Tổng Cục thuế tại thời điểm phát hành hóa đơn'
                                    )}
                                  </span>
                                </div>
                              ) : dataDetail?.InvoiceValidate?.nb_khong_rui_ro_tai_tdlap ===
                                null ? (
                                <div className="d-flex algin-items-center my-2">
                                  <i
                                    className="fa-regular fa-circle-minus me-3 d-flex"
                                    style={{ color: '#FFA318', alignItems: 'center' }}
                                  ></i>
                                  <span className="text-grey font-weight-bold font-size-lg">
                                    {t(
                                      'Không kiểm tra được rủi ro của người bán tại thời điểm phát hành hóa đơn'
                                    )}
                                  </span>
                                </div>
                              ) : (
                                <div className="d-flex algin-items-center my-2">
                                  <i
                                    className="fa-regular fa-circle-check me-3 d-flex"
                                    style={{ color: '#28A043', alignItems: 'center' }}
                                  ></i>
                                  <span className="text-grey font-weight-bold font-size-lg">
                                    {t('Người bán không rủi ro tại thời điểm phát hành hóa đơn')}
                                  </span>
                                </div>
                              )}
                              {dataDetail?.InvoiceValidate?.mauso_kyhieu_da_tb &&
                                dataDetail?.InvoiceValidate?.so_hd_thuoc_khoang_phat_hanh &&
                                dataDetail?.InvoiceValidate?.ngay_hoa_don_tu_ngay_su_dung && (
                                  <div className="d-flex algin-items-center my-2">
                                    <i
                                      className="fa-regular fa-circle-check me-3 d-flex"
                                      style={{ color: '#28A043', alignItems: 'center' }}
                                    ></i>
                                    <span className="text-grey font-weight-bold font-size-lg">
                                      {t('Hóa đơn đúng thông báo phát hành')}
                                    </span>
                                  </div>
                                )}
                              {(dataDetail?.InvoiceValidate?.mauso_kyhieu_da_tb === null ||
                                dataDetail?.InvoiceValidate?.so_hd_thuoc_khoang_phat_hanh ===
                                  nulll ||
                                dataDetail?.InvoiceValidate?.ngay_hoa_don_tu_ngay_su_dung ===
                                  null) && (
                                <div className="d-flex algin-items-center my-2">
                                  <i
                                    className="fa-regular fa-circle-minus me-3 d-flex"
                                    style={{ color: '#FFA318', alignItems: 'center' }}
                                  ></i>
                                  <span className="text-grey font-weight-bold font-size-lg">
                                    {t('Hóa đơn chưa thông báo phát hành')}
                                  </span>
                                </div>
                              )}
                              {(dataDetail?.InvoiceValidate?.mauso_kyhieu_da_tb === false ||
                                dataDetail?.InvoiceValidate?.so_hd_thuoc_khoang_phat_hanh ===
                                  false ||
                                dataDetail?.InvoiceValidate?.ngay_hoa_don_tu_ngay_su_dung ===
                                  false) && (
                                <div className="d-flex algin-items-center my-2">
                                  <i
                                    className="fa-regular fa-circle-exclamation me-3 d-flex"
                                    style={{ color: '#F44141', alignItems: 'center' }}
                                  ></i>
                                  <span className="text-grey font-weight-bold font-size-lg">
                                    {t('Hóa đơn chưa đúng với thông báo phát hành')}
                                  </span>
                                </div>
                              )}
                              {dataDetail?.InvoiceValidate?.mauso_kyhieu_da_tb ? (
                                <div style={{ marginLeft: '28px' }}>
                                  <i
                                    className="fas fa-circle me-3"
                                    style={{ fontSize: '7px', color: '#28A043' }}
                                  ></i>
                                  <span style={{ color: '#28A043' }}>
                                    Mẫu số ký hiệu đã được thông báo phát hành
                                  </span>
                                </div>
                              ) : dataDetail?.InvoiceValidate?.mauso_kyhieu_da_tb === null ? (
                                <div style={{ marginLeft: '28px' }}>
                                  <i
                                    className="fas fa-circle me-3"
                                    style={{ fontSize: '7px', color: '#FFA318' }}
                                  ></i>
                                  <span style={{ color: '#FFA318' }}>
                                    Không kiểm tra được mẫu số ký hiệu
                                  </span>
                                </div>
                              ) : (
                                <div style={{ marginLeft: '28px' }}>
                                  <i
                                    className="fas fa-circle me-3"
                                    style={{ fontSize: '7px', color: '#F44141' }}
                                  ></i>
                                  <span style={{ color: '#F44141' }}>
                                    Mẫu số ký hiệu chưa được thông báo phát hành
                                  </span>
                                </div>
                              )}
                              {dataDetail?.InvoiceValidate?.so_hd_thuoc_khoang_phat_hanh ? (
                                <div style={{ marginLeft: '28px' }}>
                                  <i
                                    className="fas fa-circle me-3"
                                    style={{ fontSize: '7px', color: '#28A043' }}
                                  ></i>
                                  <span style={{ color: '#28A043' }}>
                                    Số hóa đơn thuộc khoảng hóa đơn thông báo phát hành
                                  </span>
                                </div>
                              ) : dataDetail?.InvoiceValidate?.so_hd_thuoc_khoang_phat_hanh ===
                                null ? (
                                <div style={{ marginLeft: '28px' }}>
                                  <i
                                    className="fas fa-circle me-3"
                                    style={{ fontSize: '7px', color: '#FFA318' }}
                                  ></i>
                                  <span style={{ color: '#FFA318' }}>
                                    Không kiểm tra được số hóa đơn
                                  </span>
                                </div>
                              ) : (
                                <div style={{ marginLeft: '28px' }}>
                                  <i
                                    className="fas fa-circle me-3"
                                    style={{ fontSize: '7px', color: '#F44141' }}
                                  ></i>
                                  <span style={{ color: '#F44141' }}>
                                    Số hóa đơn không thuộc khoảng hóa đơn thông báo phát hành
                                  </span>
                                </div>
                              )}
                              {dataDetail?.InvoiceValidate?.ngay_hoa_don_tu_ngay_su_dung ? (
                                <div style={{ marginLeft: '28px' }}>
                                  <i
                                    className="fas fa-circle me-3"
                                    style={{ fontSize: '7px', color: '#28A043' }}
                                  ></i>
                                  <span style={{ color: '#28A043' }}>
                                    Ngày hóa đơn lớn hơn ngày bắt đầu sử dụng
                                  </span>
                                </div>
                              ) : dataDetail?.InvoiceValidate?.ngay_hoa_don_tu_ngay_su_dung ===
                                null ? (
                                <div style={{ marginLeft: '28px' }}>
                                  <i
                                    className="fas fa-circle me-3"
                                    style={{ fontSize: '7px', color: '#FFA318' }}
                                  ></i>
                                  <span style={{ color: '#FFA318' }}>
                                    Không kiểm tra được ngày hóa đơn
                                  </span>
                                </div>
                              ) : (
                                <div style={{ marginLeft: '28px' }}>
                                  <i
                                    className="fas fa-circle me-3"
                                    style={{ fontSize: '7px', color: '#F44141' }}
                                  ></i>
                                  <span style={{ color: '#F44141' }}>
                                    Ngày hóa đơn nhỏ hơn ngày bắt đầu sử dụng
                                  </span>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="d-flex algin-items-center my-2">
                                {dataDetail?.InvoiceValidate?.checkResultHasCQTRecord &&
                                dataDetail?.InvoiceValidate?.checkResultHasInvoiceCode ? (
                                  <i
                                    className="fa-regular fa-circle-check me-3 d-flex"
                                    style={{ color: '#28A043', alignItems: 'center' }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa-regular fa-circle-minus me-3 d-flex"
                                    style={{ color: '#FFA318', alignItems: 'center' }}
                                  ></i>
                                )}
                                <span className="text-grey font-weight-bold font-size-lg">
                                  {t('CheckOnTheSystemOfTheGeneralDepartmentOfTaxation')}
                                </span>
                              </div>
                              <div className="mb-4" style={{ marginLeft: '29px' }}>
                                <div>
                                  <i className="fas fa-circle me-3" style={{ fontSize: '7px' }}></i>
                                  {dataDetail?.InvoiceValidate?.checkResultHasCQTRecord ? (
                                    <span>
                                      Hóa đơn đã tồn tại trên hệ thống tra cứu Cơ quan thuế tại thời
                                      điểm kiểm tra
                                    </span>
                                  ) : (
                                    <span>
                                      Hóa đơn chưa tồn tại trên hệ thống tra cứu Cơ quan thuế tại
                                      thời điểm kiểm tra
                                    </span>
                                  )}
                                </div>
                                <div className="mt-2">
                                  {!dataDetail?.InvoiceValidate?.checkResultHasInvoiceCode &&
                                  dataDetail?.serial.startsWith('K') ? (
                                    ''
                                  ) : dataDetail?.InvoiceValidate?.checkResultHasInvoiceCode &&
                                    dataDetail?.serial.startsWith('K') ? (
                                    <>
                                      <i
                                        className="fas fa-circle me-3"
                                        style={{ fontSize: '7px' }}
                                      ></i>
                                      <span>
                                        Trạng thái xử lý hóa đơn: Tổng cục thuế đã nhận không mã
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <i
                                        className="fas fa-circle me-3"
                                        style={{ fontSize: '7px' }}
                                      ></i>
                                      <span>Trạng thái xử lý hóa đơn: Đã cấp mã hóa đơn</span>
                                    </>
                                  )}
                                </div>
                              </div>
                              {dataDetail?.InvoiceProducts?.length > 0 &&
                                store.getState()?.auth?.account?.Organization
                                  ?.warningInListInvoice && (
                                  <>
                                    <span className="text-primary font-weight-bold font-size-lg mt-2">
                                      {t('CheckTheDataCalculation')}
                                    </span>
                                    <div className="d-flex align-items-center">
                                      <i
                                        className="fa-regular fa-calculator me-3 d-flex align-items-center mt-2"
                                        style={
                                          dataDetail?.amountVat5 !==
                                            dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                              if (item?.vat === '5%') {
                                                total + item?.vatAmount, 0;
                                              }
                                            }) &&
                                          dataDetail?.amountVat8 !==
                                            dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                              if (item?.vat === '8%') {
                                                total + item?.vatAmount, 0;
                                              }
                                            }) &&
                                          dataDetail?.amountVat10 !==
                                            dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                              if (item?.vat === '10%') {
                                                total + item?.vatAmount, 0;
                                              }
                                            }) &&
                                          dataDetail?.amountBeforeVat5 !==
                                            dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                              if (item?.vat === '5%') {
                                                total + item?.amountTotal, 0;
                                              }
                                            }) &&
                                          dataDetail?.amountBeforeVat8 !==
                                            dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                              if (item?.vat === '8%') {
                                                total + item?.amountTotal, 0;
                                              }
                                            }) &&
                                          dataDetail?.amountBeforeVat10 !==
                                            dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                              if (item?.vat === '10%') {
                                                total + item?.amountTotal, 0;
                                              }
                                            }) &&
                                          dataDetail?.amountBeforeVat ===
                                            dataDetail?.InvoiceProducts?.reduce(
                                              (total, item) => total + item?.amountTotal,
                                              0
                                            ) &&
                                          dataDetail?.amountVat ===
                                            dataDetail?.InvoiceProducts?.reduce(
                                              (total, item) => total + item?.vatAmount,
                                              0
                                            )
                                            ? { alignItems: 'center' }
                                            : { alignItems: 'center', color: '#FFA318' }
                                        }
                                      ></i>

                                      {dataDetail?.amountVat5 !==
                                        dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                          if (item?.vat === '5%') {
                                            total + item?.vatAmount, 0;
                                          }
                                        }) &&
                                      dataDetail?.amountVat8 !==
                                        dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                          if (item?.vat === '8%') {
                                            total + item?.vatAmount, 0;
                                          }
                                        }) &&
                                      dataDetail?.amountVat10 !==
                                        dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                          if (item?.vat === '10%') {
                                            total + item?.vatAmount, 0;
                                          }
                                        }) &&
                                      dataDetail?.amountBeforeVat5 !==
                                        dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                          if (item?.vat === '5%') {
                                            total + item?.amountTotal, 0;
                                          }
                                        }) &&
                                      dataDetail?.amountBeforeVat8 !==
                                        dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                          if (item?.vat === '8%') {
                                            total + item?.amountTotal, 0;
                                          }
                                        }) &&
                                      dataDetail?.amountBeforeVat10 !==
                                        dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                          if (item?.vat === '10%') {
                                            total + item?.amountTotal, 0;
                                          }
                                        }) &&
                                      dataDetail?.amountBeforeVat ===
                                        dataDetail?.InvoiceProducts?.reduce(
                                          (total, item) => total + item?.amountTotal,
                                          0
                                        ) &&
                                      dataDetail?.amountVat ===
                                        dataDetail?.InvoiceProducts?.reduce(
                                          (total, item) => total + item?.vatAmount,
                                          0
                                        ) ? (
                                        <span className="font-weight-bold font-size-lg mt-2">
                                          Hóa đơn chính xác về tính toán số liệu
                                        </span>
                                      ) : (
                                        <span className="font-weight-bold font-size-lg mt-2">
                                          Hóa đơn chưa chính xác về tính toán số liệu
                                        </span>
                                      )}
                                    </div>

                                    {dataDetail?.amountVat5 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '5%') {
                                          return total + item?.vatAmount;
                                        }
                                      }, 0) &&
                                      dataDetail?.amountVat5 > 0 && (
                                        <div>
                                          - Tiền thuế GTGT của mức thuế suất 5% ≠ (thành tiền chưa
                                          thuế * thuế suất) của mức thuế suất 5%
                                        </div>
                                      )}
                                    {dataDetail?.amountVat8 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '8%') {
                                          return total + item?.vatAmount;
                                        }
                                      }, 0) &&
                                      dataDetail?.amountVat8 > 0 && (
                                        <div>
                                          - Tiền thuế GTGT của mức thuế suất 8% ≠ (thành tiền chưa
                                          thuế * thuế suất) của mức thuế suất 8%
                                        </div>
                                      )}
                                    {dataDetail?.amountVat10 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '10%') {
                                          return total + item?.vatAmount;
                                        }
                                      }, 0) &&
                                      dataDetail?.amountVat10 > 0 && (
                                        <div>
                                          - Tiền thuế GTGT của mức thuế suất 10% ≠ (thành tiền chưa
                                          thuế * thuế suất) của mức thuế suất 10%
                                        </div>
                                      )}

                                    {dataDetail?.amountBeforeVat5 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '5%') {
                                          return total + item?.amountTotal;
                                        }
                                      }, 0) &&
                                      dataDetail?.amountBeforeVat5 !== null && (
                                        <div>
                                          - Thành tiền chưa thuế tổng hợp của mức thuế suất 5% ≠
                                          tổng thành tiền chưa thuế của các dòng hàng hóa dịch vụ có
                                          mức thuế suất 5%
                                        </div>
                                      )}
                                    {dataDetail?.amountBeforeVat8 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '8%') {
                                          return total + item?.amountTotal;
                                        }
                                      }, 0) &&
                                      dataDetail?.amountBeforeVat8 !== null && (
                                        <div>
                                          - Thành tiền chưa thuế tổng hợp của mức thuế suất 8% ≠
                                          tổng thành tiền chưa thuế của các dòng hàng hóa dịch vụ có
                                          mức thuế suất 8%
                                        </div>
                                      )}
                                    {dataDetail?.amountBeforeVat10 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '10%') {
                                          return total + item?.amountTotal;
                                        }
                                      }, 0) &&
                                      dataDetail?.amountBeforeVat10 !== null && (
                                        <div>
                                          - Thành tiền chưa thuế tổng hợp của mức thuế suất 10% ≠
                                          tổng thành tiền chưa thuế của các dòng hàng hóa dịch vụ có
                                          mức thuế suất 10%
                                        </div>
                                      )}

                                    {dataDetail?.amountBeforeVat !==
                                      dataDetail?.InvoiceProducts?.reduce(
                                        (total, item) => total + item?.amountTotal,
                                        0
                                      ) && (
                                      <div>
                                        - Tổng tiền chưa thuế trên thẻ thanh toán ≠ tổng thành tiền
                                        chưa thuế trên các dòng của thẻ thông tin tổng hợp
                                      </div>
                                    )}

                                    {dataDetail?.amountVat !==
                                      dataDetail?.InvoiceProducts?.reduce(
                                        (total, item) => total + item?.vatAmount,
                                        0
                                      ) && (
                                      <div>
                                        - Tổng tiền thuế GTGT ≠ tổng tiền thuế của các dòng tổng hợp
                                        theo mức thuế suất
                                      </div>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                          <div
                            className="d-flex align-items-center cursor-pointer"
                            onClick={() => setShowListOfOccupations(true)}
                          >
                            <i
                              className="fa-solid fa-list-ul me-3 d-flex align-items-center mt-2 text-primary"
                              style={{ alignItems: 'center' }}
                            ></i>
                            <span className="font-weight-bold font-size-lg mt-2 text-primary">
                              Danh sách ngành nghề kinh doanh
                            </span>
                          </div>
                          {dataDetail?.isRiskyInvoice && (
                            <>
                              <div className="d-flex algin-items-center my-2">
                                <i
                                  className="fa-regular fa-circle-exclamation me-3 d-flex"
                                  style={{ color: '#F44141', alignItems: 'center' }}
                                ></i>

                                <div>
                                  <span
                                    className="font-weight-bold font-size-lg"
                                    style={{ color: '#F44141' }}
                                  >
                                    {t('Hoá đơn rủi ro')}
                                  </span>
                                </div>
                              </div>
                              <div className="" style={{ marginLeft: '28px' }}>
                                <i className="fas fa-circle me-3" style={{ fontSize: '7px' }}></i>
                                <span>
                                  Hoá đơn rủi ro do doanh nghiệp thuộc danh sách cảnh báo của Tổng
                                  Cục thuế
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between m-6 p-7 align-items-center"
                        style={{
                          borderRadius: '20px',
                          background: 'var(--system-bg-select, #F8F8F8)',
                        }}
                      >
                        <div>
                          <p className="mb-2">{t('Attachments')}</p>
                          <span>Phần mềm chỉ hỗ trợ tệp đính kèm nhỏ hơn 5MB</span>
                          {dataDetail?.InvoiceAttachs?.length > 0 && (
                            <div className="me-5">
                              {dataDetail?.InvoiceAttachs?.map((item, index) => (
                                <div
                                  key={index}
                                  className="d-flex justify-content-between align-items-center bg-white mb-2 py-2 px-4 rounded item-file"
                                >
                                  <div className="w-100 me-3 d-flex align-items-center justify-content-between">
                                    <div
                                      style={{ wordBreak: 'break-word' }}
                                      className="text-primary cursor-pointer"
                                      onClick={() => handleDownLoadFile(item)}
                                    >
                                      {item?.fileName}
                                    </div>
                                    <div style={{ minWidth: 'max-content' }}>
                                      ({Math.floor(item?.size / 1024)} KB)
                                    </div>
                                  </div>
                                  <div className="hover-action d-flex align-item-center">
                                    <KTTooltip text={t('View')} position={KTTooltipPositions.auto}>
                                      <i
                                        className="fa-regular fa-eye cursor-pointer me-3"
                                        onClick={() =>
                                          window.open(process.env.REACT_APP_BASE_URL + item.url)
                                        }
                                      ></i>
                                    </KTTooltip>
                                  </div>
                                  <KTTooltip text={t('Delete')} position={KTTooltipPositions.auto}>
                                    <i
                                      className="fa-regular fa-xmark cursor-pointer"
                                      onClick={async (e) => {
                                        e.stopPropagation();
                                        const res = await invoiceApi.deleteFileInvoice({
                                          invoiceAttachId: item?.invoiceAttachId,
                                        });
                                        if (res.result === 'success') {
                                          ToastHelper.showSuccess('Xóa tệp đính kèm thành công ');
                                          setLoading(Math.random());
                                          dispatch(
                                            thunkGetAllInInvoice(Global.gFiltersInputInvoice)
                                          );
                                        }
                                      }}
                                    ></i>
                                  </KTTooltip>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <VIButton
                          text={t('AddAttachment')}
                          className="my-3 btn btn-primary"
                          beforeIcon={<i className="fa-regular fa-circle-plus"></i>}
                          onClick={() => document.getElementById('input-file').click()}
                        />
                        <input
                          type="file"
                          multiple
                          className="d-none"
                          id={'input-file'}
                          onChange={async (e) => {
                            if (e?.target?.files[0]?.size / 1048576 >= 5) {
                              ToastHelper.showError('Dung lượng tệp tải lên vượt quá 5MB');
                              e.target.value = null;
                            } else {
                              const form = new FormData();
                              for (var i = 0; i < e?.target?.files.length; i++) {
                                form.append('attachFiles', e?.target?.files[i]);
                              }
                              if (e?.target?.files?.length > 0) {
                                const result = await invoiceApi.upFileInvoice(
                                  dataDetail.invoiceId,
                                  form
                                );
                                if (result.result === 'success') {
                                  setLoading(Math.random());
                                  dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
                                }
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    {sizes?.[1] < 777 || size?.width < 1406 ? (
                      <div className="d-flex justify-content-between px-5 py-3 border-top">
                        <div className="d-flex">
                          <VIButton
                            text={<i className="fa-regular fa-chevron-left"></i>}
                            className="btn-grey px-3 me-3"
                            disabled={dataNext === 0}
                            onClick={() => {
                              if (dataNext > 0) {
                                setDataNext(dataNext - 1);
                              }
                            }}
                          />
                          <VIButton
                            text={<i className="fa-regular fa-chevron-right"></i>}
                            className="btn-grey px-3 me-3"
                            disabled={listData?.length === dataNext + 1}
                            onClick={() => {
                              if (listData?.length > dataNext) setDataNext(dataNext + 1);
                            }}
                          />
                        </div>
                        <div className="d-flex">
                          <VIButton
                            text={'Kiểm tra lại'}
                            beforeIcon={<i className="fa-regular fa-list-check"></i>}
                            className="btn-white px-3 me-3"
                            onClick={() => {
                              handleCheck();
                            }}
                          />

                          <KTBSDropdown
                            contentEl={
                              <VIBodyIcons
                                IconArr={IconArr}
                                onClick={(x) => {
                                  if (x.name === 'Ghi chú') {
                                    setShowModalNoteInvoices(true);
                                  }
                                  if (x.name === 'Gán nhãn') {
                                    setShowModalLabeled(true);
                                    setListCurrentLabels(dataDetail?.Tags);
                                  }
                                  if (x.name === 'In hóa đơn') {
                                    handlePrintInvoices();
                                  }
                                  if (x.name === 'Tải xuống') {
                                    handleDownLoadInvoices();
                                  }
                                  if (x.name === 'In kết quả kiểm tra') {
                                    handlePrintResult();
                                  }
                                }}
                              />
                            }
                            toggleElement={
                              <div className="topbar-item">
                                <div className="mr-1 btn-white py-3 cursor-pointer px-6 d-flex align-items-center">
                                  <i className="fa-regular fa-ellipsis-vertical"></i>
                                </div>
                              </div>
                            }
                            dropdownMenuClassName="w-auto"
                            alignment={KTBSDropdownAlignments.end}
                            direction={KTBSDropdownDirections.up}
                            autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between px-5 py-3 border-top">
                        <div className="d-flex">
                          <VIButton
                            text={
                              <i className="fa-regular fa-chevron-left d-flex h-100 align-items-center"></i>
                            }
                            className="btn-grey px-3 me-3"
                            disabled={dataNext === 0}
                            onClick={() => {
                              if (dataNext > 0) {
                                setDataNext(dataNext - 1);
                              }
                            }}
                          />
                          <VIButton
                            text={
                              <i className="fa-regular fa-chevron-right d-flex h-100 align-items-center"></i>
                            }
                            className="btn-grey px-3 me-3"
                            disabled={listData?.length === dataNext + 1}
                            onClick={() => {
                              if (listData?.length > dataNext) setDataNext(dataNext + 1);
                            }}
                          />
                        </div>
                        <div className="d-flex flex-wrap">
                          <VIButton
                            text={'Kiểm tra lại'}
                            beforeIcon={
                              <i className="fa-regular fa-list-check d-flex h-100 align-items-center"></i>
                            }
                            className="btn-grey px-3 me-3"
                            onClick={() => {
                              handleCheck();
                            }}
                          />
                          <VIButton
                            text={
                              !loadingResult ? (
                                'In kết quả'
                              ) : (
                                <div className="d-flex align-items-center">
                                  {'In kết quả'}
                                  <div className="ms-2 ">
                                    <VILoading size="sm" variant="blue" />
                                  </div>
                                </div>
                              )
                            }
                            beforeIcon={<i className="fa-regular fa-print"></i>}
                            className="btn-grey px-3 me-3"
                            onClick={() => handlePrintResult()}
                          />
                          <VIButton
                            text={'In hóa đơn'}
                            beforeIcon={<i className="fa-regular fa-print"></i>}
                            className="btn-grey px-3 me-3"
                            onClick={() => handlePrintInvoices()}
                          />
                          <VIButton
                            text={'Tải xuống'}
                            beforeIcon={<i className="fa-regular fa-download"></i>}
                            className="btn-grey px-3 me-3"
                            onClick={() => handleDownLoadInvoices()}
                          />
                          <VIButton
                            text={
                              <i className="fa-regular fa-tag d-flex h-100 align-items-center"></i>
                            }
                            className="btn-grey px-3 me-3"
                            onClick={() => {
                              setShowModalLabeled(true);
                              setListCurrentLabels(dataDetail?.Tags);
                            }}
                          />
                          <VIButton
                            text={
                              <i className="fa-regular fa-message-lines d-flex h-100 align-items-center"></i>
                            }
                            className="btn-grey px-3"
                            onClick={() => setShowModalNoteInvoices(true)}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Pane>
          </SplitPane>
        </div>
        {/* mobile */}
        <div
          style={{ background: '#fff', overflow: 'auto', height: 'auto' }}
          className="border-left right-deital-mobile d-none"
        >
          {/* title */}
          <div
            className="d-flex justify-content-between align-items-center px-5 py-2 position-fixed w-100"
            style={{
              borderBottom: '1px solid var(--system-stroke, #E8E9EB)',
              top: '0px',
              zIndex: '1000',
              background: '#FFF',
              boxShadow: ' 0px 5px 10px 0px rgba(44, 63, 88, 0.10)',
            }}
          >
            <span className="me-3">Thông tin và kết quả kiểm tra theo file XML của hóa đơn</span>
            <VIButton
              text={<i className="fa-regular fa-xmark d-flex align-items-center"></i>}
              className="btn-grey px-2"
              onClick={onHide}
            />
          </div>
          {/* body */}
          <div style={{ height: '65vh' }} className="mt-15">
            <div className="position-relative h-100">
              {loadingPdf ? (
                <div className="w-100 h-100 d-flex justify-content-center">
                  <VILoading tableMaxHeight="100%" />
                </div>
              ) : (
                <>
                  {dataDetail?.pdfFile ? (
                    <iframe
                      src={Utils.getFullUrl(dataDetail?.pdfFile)}
                      className="h-100 w-100"
                      type="application/pdf"
                    ></iframe>
                  ) : (
                    <iframe
                      src={Utils.getFullUrl(dataDetail?.previewPdfFile)}
                      className="h-100 w-100"
                      type="application/pdf"
                      frameborder="0"
                    ></iframe>
                  )}
                </>
              )}
            </div>
          </div>
          {loadingPdf ? (
            <div className="w-100 d-flex justify-content-center" style={{ height: '90vh' }}>
              <VILoading tableMaxHeight="100%" />
            </div>
          ) : (
            <>
              <div
                id="result-print"
                style={
                  {
                    // maxHeight: `calc((100vh - ${110}px))`,
                    // overflowY: 'auto',
                    // overflowX: 'hidden',
                  }
                }
              >
                <div className="d-flex w-100 justify-content-between flex-wrap">
                  <div className="py-4 px-6 flex-grow-1" style={{ minWidth: '360px' }}>
                    <div className="border-bottom py-4">
                      <div className="">
                        <i className="fa-solid fa-bag-shopping text-primary me-3"></i>
                        <span className="text-primary font-weight-bold font-size-lg">
                          {t('SalesUnit')}
                        </span>
                      </div>
                      <div className="d-flex mt-2">
                        <div
                          className="font-weight-bold"
                          style={{ minWidth: '80px', color: '#3C4376' }}
                        >
                          {t('Unit')}:
                        </div>
                        {dataDetail?.sellerName ? (
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.sellerName}
                          </span>
                        ) : (
                          <span className="ms-2 font-weight-bold" style={{ color: '#7177A8' }}>
                            Không có thông tin
                          </span>
                        )}
                      </div>
                      <div className="d-flex mt-2">
                        <div
                          className="font-weight-bold"
                          style={{ minWidth: '80px', color: '#3C4376' }}
                        >
                          {t('TaxCode')}:
                        </div>
                        {dataDetail?.sellerTaxCode ? (
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.sellerTaxCode}
                          </span>
                        ) : (
                          <span className="ms-2 font-weight-bold" style={{ color: '#7177A8' }}>
                            Không có thông tin
                          </span>
                        )}
                      </div>
                      <div className="d-flex mt-2">
                        <div
                          className="font-weight-bold"
                          style={{ minWidth: '80px', color: '#3C4376' }}
                        >
                          {t('Address')}:
                        </div>
                        {dataDetail?.sellerAddress ? (
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.sellerAddress}
                          </span>
                        ) : (
                          <span className="ms-2 font-weight-bold" style={{ color: '#7177A8' }}>
                            Không có thông tin
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="border-bottom py-4">
                      <div>
                        <i className="fa-solid fa-cart-shopping text-primary me-3"></i>
                        <span className="text-primary font-weight-bold font-size-lg">
                          {t('PurchaseUnit')}
                        </span>
                      </div>
                      <div className="d-flex mt-2">
                        <div
                          className="font-weight-bold"
                          style={{ minWidth: '80px', color: '#3C4376' }}
                        >
                          {t('Unit')}:
                        </div>
                        {dataDetail?.buyerName ? (
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.buyerName}
                          </span>
                        ) : (
                          <span className="ms-2 font-weight-bold" style={{ color: '#7177A8' }}>
                            Không có thông tin
                          </span>
                        )}
                      </div>
                      <div className="d-flex mt-2">
                        <div
                          className="font-weight-bold"
                          style={{ minWidth: '80px', color: '#3C4376' }}
                        >
                          {t('TaxCode')}:
                        </div>
                        {dataDetail?.buyerTaxCode ? (
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.buyerTaxCode}
                          </span>
                        ) : (
                          <span className="ms-2 font-weight-bold" style={{ color: '#7177A8' }}>
                            Không có thông tin
                          </span>
                        )}
                      </div>
                      <div className="d-flex mt-2">
                        <div
                          className="font-weight-bold"
                          style={{ minWidth: '80px', color: '#3C4376' }}
                        >
                          {t('Address')}:
                        </div>
                        {dataDetail?.buyerAddress ? (
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.buyerAddress}
                          </span>
                        ) : (
                          <span className="ms-2 font-weight-bold" style={{ color: '#7177A8' }}>
                            Không có thông tin
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="py-4">
                      <div>
                        <i className="fa-solid fa-money-bill text-primary me-3"></i>
                        <span className="text-primary font-weight-bold font-size-lg">
                          {t('BillingInformation')}
                        </span>
                      </div>
                      <div className="d-flex mt-2 font-weight-bold">
                        <div
                          className="font-weight-bold"
                          style={{ minWidth: '150px', color: '#3C4376' }}
                        >
                          {t('TotalAmountOfGoods')}:
                        </div>
                        <span
                          className="ms-2 text-grey font-weight-bold"
                          style={{ color: '#1C2256' }}
                        >
                          {dataDetail?.finalAmount
                            ? Utils.formatNumber(dataDetail?.amountBeforeVat)
                            : 0}
                        </span>
                      </div>
                      {dataDetail?.amountAfterVatKCT > 0 && (
                        <div className="d-flex mt-2 font-weight-bold">
                          <div
                            className="font-weight-bold"
                            style={{ minWidth: '150px', color: '#3C4376' }}
                          >
                            {t('Tổng giảm trừ không chịu thuế')}:
                          </div>
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.amountAfterVatKCT
                              ? Utils.formatNumber(dataDetail?.amountAfterVatKCT)
                              : 0}
                          </span>
                        </div>
                      )}
                      {dataDetail?.amountVat5 > 0 && (
                        <div className="d-flex mt-2 font-weight-bold">
                          <div
                            className="font-weight-bold"
                            style={{ minWidth: '150px', color: '#3C4376' }}
                          >
                            {t('Thuế GTGT (thuế 5%)')}:
                          </div>
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.amountVat5
                              ? Utils.formatNumber(dataDetail?.amountVat5)
                              : 0}
                          </span>
                        </div>
                      )}
                      {dataDetail?.amountVat8 > 0 && (
                        <div className="d-flex mt-2 font-weight-bold">
                          <div
                            className="font-weight-bold"
                            style={{ minWidth: '150px', color: '#3C4376' }}
                          >
                            {t('Thuế GTGT (thuế 8%)')}:
                          </div>
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.amountVat8
                              ? Utils.formatNumber(dataDetail?.amountVat8)
                              : 0}
                          </span>
                        </div>
                      )}
                      {dataDetail?.amountVat10 > 0 && (
                        <div className="d-flex mt-2 font-weight-bold">
                          <div
                            className="font-weight-bold"
                            style={{ minWidth: '150px', color: '#3C4376' }}
                          >
                            {t('Thuế GTGT (thuế 10%)')}:
                          </div>
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.amountVat10
                              ? Utils.formatNumber(dataDetail?.amountVat10)
                              : 0}
                          </span>
                        </div>
                      )}
                      {dataDetail?.discountOther > 0 && (
                        <div className="d-flex mt-2 font-weight-bold">
                          <div
                            className="font-weight-bold"
                            style={{ minWidth: '150px', color: '#3C4376' }}
                          >
                            {t('Tổng giảm trừ khác')}:
                          </div>
                          <span
                            className="ms-2 text-grey font-weight-bold"
                            style={{ color: '#1C2256' }}
                          >
                            {dataDetail?.discountOther
                              ? Utils.formatNumber(dataDetail?.discountOther)
                              : 0}
                          </span>
                        </div>
                      )}
                      <div className="d-flex mt-2 font-weight-bold">
                        <div
                          className="font-weight-bold"
                          style={{ minWidth: '150px', color: '#3C4376' }}
                        >
                          {t('TotalPayment')}:
                        </div>
                        <span className="ms-2 font-weight-bold" style={{ color: '#1C2256' }}>
                          {dataDetail?.finalAmount
                            ? Utils.formatNumber(dataDetail?.finalAmount)
                            : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="p-6 justify-items-center w-100"
                    style={{ minWidth: '285px', margin: '0 auto' }}
                  >
                    {dataDetail?.InvoiceValidate?.checkResultBuyerAddress &&
                    dataDetail?.InvoiceValidate?.checkResultBuyerName &&
                    dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode &&
                    dataDetail?.InvoiceValidate?.checkResultHasCQTRecord &&
                    dataDetail?.InvoiceValidate?.checkResultHasInvoiceCode &&
                    dataDetail?.InvoiceValidate?.checkResultSellerAddress &&
                    dataDetail?.InvoiceValidate?.checkResultSellerName &&
                    dataDetail?.InvoiceValidate?.checkResultSignatureCQT &&
                    dataDetail?.InvoiceValidate?.checkResultSignatureNCC ? (
                      <VIElements
                        id={'22'}
                        text={'Hóa đơn hợp lệ'}
                        style={{
                          color: '#28A043',
                          background: 'rgba(40, 160, 67, 0.10)',
                          padding: '8px',
                          border: 'none',
                          width: '100%',
                          marginBottom: '8px',
                        }}
                      />
                    ) : (
                      <VIElements
                        id={'5'}
                        text={'Hóa đơn không hợp lệ'}
                        style={{
                          color: '#F44141',
                          background: 'rgba(244, 65, 65, 0.10)',
                          padding: '8px',
                          border: 'none',
                          width: '100%',
                          marginBottom: '8px',
                        }}
                      />
                    )}
                    <span className="" style={{ color: '#7177A8', fontWeight: 400 }}>
                      Tại thời điểm tra cứu ngày{' '}
                      {dataDetail?.InvoiceValidate?.checkDate
                        ? Utils.formatDateTime(dataDetail?.InvoiceValidate?.checkDate, 'DD/MM/YYYY')
                        : ''}
                    </span>
                    <div className="d-flex justify-content-center my-7">
                      <div
                        className="p-6"
                        style={{
                          border: '1px solid var(--system-stroke, #E8E9EB)',
                          borderRadius: '8px',
                          width: '100%',
                        }}
                      >
                        <div
                          className="w-100 d-flex justify-content-between mb-4"
                          style={{ width: 'max-content' }}
                        >
                          <span style={{ color: '#3C4376', fontWeight: 500 }}>{t('Type')}:</span>

                          {dataDetail?.invoiceTypeCode ? (
                            <span className="ms-2" style={{ color: '#1C2256', fontWeight: 700 }}>
                              {Utils.getTypeInvoice(dataDetail?.invoiceTypeCode)}
                            </span>
                          ) : (
                            <span className="ms-2 " style={{ color: '#7177A8', fontWeight: 700 }}>
                              Không có thông tin
                            </span>
                          )}
                        </div>
                        <div
                          className="w-100 d-flex justify-content-between mb-4"
                          style={{ width: 'max-content' }}
                        >
                          <span style={{ color: '#3C4376', fontWeight: 500 }}>
                            {t('Denominator')}:
                          </span>

                          {dataDetail?.invoiceTypeCode ? (
                            <span className="ms-2" style={{ color: '#1C2256', fontWeight: 700 }}>
                              {dataDetail?.invoiceTypeCode}
                            </span>
                          ) : (
                            <span className="ms-2 " style={{ color: '#7177A8', fontWeight: 700 }}>
                              Không có thông tin
                            </span>
                          )}
                        </div>

                        <div
                          className="w-100 d-flex justify-content-between mb-4"
                          style={{ width: 'max-content' }}
                        >
                          <span style={{ color: '#3C4376', fontWeight: 500 }}>{t('Symbol')}:</span>

                          {dataDetail?.serial ? (
                            <span className="ms-2" style={{ color: '#1C2256', fontWeight: 700 }}>
                              {dataDetail?.serial}
                            </span>
                          ) : (
                            <span className="ms-2 " style={{ color: '#7177A8', fontWeight: 700 }}>
                              Không có thông tin
                            </span>
                          )}
                        </div>
                        <div
                          className="w-100 d-flex justify-content-between mb-4"
                          style={{ width: 'max-content' }}
                        >
                          <span style={{ color: '#3C4376', fontWeight: 500 }}>
                            {t('InvoiceNumber')}:
                          </span>

                          {dataDetail?.invoiceNumber ? (
                            <span className="ms-2" style={{ color: '#1C2256', fontWeight: 700 }}>
                              {dataDetail?.invoiceNumber}
                            </span>
                          ) : (
                            <span className="ms-2 " style={{ color: '#7177A8', fontWeight: 700 }}>
                              Không có thông tin
                            </span>
                          )}
                        </div>
                        <div
                          className="w-100 d-flex justify-content-between mb-4"
                          style={{ width: 'max-content' }}
                        >
                          <span style={{ color: '#3C4376', fontWeight: 500 }}>
                            {t('DateFounded')}:
                          </span>

                          {dataDetail?.invoiceDate ? (
                            <span className="ms-2" style={{ color: '#1C2256', fontWeight: 700 }}>
                              {Utils.formatDateTime(dataDetail?.invoiceDate, 'DD/MM/YYYY')}
                            </span>
                          ) : (
                            <span className="ms-2 " style={{ color: '#7177A8', fontWeight: 700 }}>
                              Không có thông tin
                            </span>
                          )}
                        </div>
                        <div
                          className="w-100 d-flex justify-content-between mb-4"
                          style={{ width: 'max-content' }}
                        >
                          <span style={{ color: '#3C4376', fontWeight: 500 }}>
                            {t('SignDate')}:
                          </span>

                          {dataDetail?.sellerCKS ? (
                            <span className="ms-2" style={{ color: '#1C2256', fontWeight: 700 }}>
                              {Utils.formatDateTime(
                                dataDetail?.sellerCKS?.SigningTime,
                                'DD/MM/YYYY'
                              )}
                            </span>
                          ) : (
                            <span className="ms-2 " style={{ color: '#7177A8', fontWeight: 700 }}>
                              Không có thông tin
                            </span>
                          )}
                        </div>
                        <div
                          className="w-100 d-flex justify-content-between mb-4"
                          style={{ width: 'max-content' }}
                        >
                          <span style={{ color: '#3C4376', fontWeight: 500 }}>
                            {t('CodeIssuanceDate')}:
                          </span>

                          {dataDetail?.cqtCKS ? (
                            <span className="ms-2" style={{ color: '#1C2256', fontWeight: 700 }}>
                              {Utils.formatDateTime(dataDetail?.cqtCKS?.SigningTime, 'DD/MM/YYYY')}
                            </span>
                          ) : (
                            <span className="ms-2 " style={{ color: '#7177A8', fontWeight: 700 }}>
                              Không có thông tin
                            </span>
                          )}
                        </div>
                        {dataDetail?.sellerSignDate &&
                          moment(dataDetail?.sellerSignDate)?.isSame(
                            dataDetail?.invoiceDate,
                            'day'
                          ) && (
                            <div className="mb-4 text-primary" style={{ fontWeight: 500 }}>
                              Ngày ký trùng ngày lập
                            </div>
                          )}
                        {dataDetail?.sellerSignDate &&
                          moment(dataDetail?.sellerCKS?.SigningTime)?.isSame(
                            dataDetail?.cqtCKS?.SigningTime,
                            'day'
                          ) && (
                            <div className="text-primary" style={{ fontWeight: 500 }}>
                              Ngày cấp mã trùng ngày ký
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center">
                      {Utils.getInvoiceStatus(dataDetail?.statusInvoiceText)}
                    </div>
                    {((dataDetail?.amountVat8 >= 0 && dataDetail?.TaxReduceValidates?.length > 0) ||
                      (dataDetail?.amountVat10 >= 0 &&
                        dataDetail?.TaxReduceValidates?.length > 0)) && (
                      <div className="w-100 mt-2">
                        {dataDetail?.TaxReduceValidates.some(
                          (item) => item.checkResult === 'INVALID'
                        ) ? (
                          <div className="text-center">
                            <VIButton
                              className="w-100 btn-pauses mb-2"
                              text={'Thuế suất chưa chính xác'}
                              beforeIcon={<i className="fa-regular fa-badge-percent"></i>}
                            />
                            <span
                              className="text-primary w-auto text-center pt-3 cursor-pointer"
                              style={{ textDecoration: 'underline' }}
                              onClick={() => setShowModalCheckTaxRate(true)}
                            >
                              Kiểm tra lại
                            </span>
                          </div>
                        ) : dataDetail?.TaxReduceValidates.every(
                            (item) => item.checkResult === 'VALID'
                          ) ? (
                          <div className="text-center">
                            <VIButton
                              className="w-100 btn-greens mb-2"
                              text={'Thuế suất chính xác'}
                              beforeIcon={<i className="fa-regular fa-badge-percent"></i>}
                            />
                            <span
                              className="text-primary w-auto text-center pt-3 cursor-pointer"
                              style={{ textDecoration: 'underline' }}
                              onClick={() => setShowModalCheckTaxRate(true)}
                            >
                              Kiểm tra lại
                            </span>
                          </div>
                        ) : (
                          <VIButton
                            className="w-100 btn-Check"
                            text={'Kiểm tra thuế suất hóa đơn'}
                            beforeIcon={<i className="fa-regular fa-badge-percent"></i>}
                            onClick={() => setShowModalCheckTaxRate(true)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="mx-6 p-4"
                  style={{
                    border: '2px solid var(--system-stroke, #E8E9EB)',
                    borderRadius: '20px',
                  }}
                >
                  <div className="w-100">
                    <span className="text-primary font-weight-bold font-size-lg ">
                      {t('InvoiceCheckResults')}
                    </span>
                    {dataDetail?.InvoiceValidate?.checkResultBuyerName &&
                    dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode &&
                    dataDetail?.InvoiceValidate?.checkResultBuyerAddress ? (
                      <div className="d-flex algin-items-center my-2">
                        <i
                          className="fa-regular fa-circle-check me-3 d-flex"
                          style={{ color: '#28A043', alignItems: 'center' }}
                        ></i>
                        <span className="text-grey font-weight-bold font-size-lg">
                          {t('CorrectBuyerInformation')}
                        </span>
                      </div>
                    ) : (
                      <div className="d-flex algin-items-center my-2">
                        <i
                          className="fa-regular fa-circle-minus me-3 d-flex "
                          style={{ color: '#FFA318', alignItems: 'center' }}
                        ></i>
                        <span className="text-grey font-weight-bold font-size-lg">
                          {t('InsufficientBuyerInformation')}
                        </span>
                      </div>
                    )}

                    <div className="" style={{ marginLeft: '29px', marginBottom: '15px' }}>
                      <Tippy
                        interactive
                        placement="bottom-end"
                        delay={[0, 20]}
                        offset={[-10, 0]}
                        render={() => (
                          <div className="bg-white tippy-tooltip p-5" style={{ maxWidth: '500px' }}>
                            <div className="d-flex justify-content-start flex-column">
                              <p
                                className="mb-0 font-weight-bold text-start"
                                style={{ color: '#1C2256' }}
                              >
                                Tên người mua trên hóa đơn
                              </p>
                              <span className="children-p text-start">
                                {dataDetail?.buyerName ?? 'không có tên người mua'}
                              </span>
                            </div>
                            <div className="mt-4">
                              <p className="mb-0 font-weight-bold text-grey text-start">
                                Tên người mua đã khai báo
                              </p>
                              <span className="children-p text-start">
                                {dataDetail?.InvoiceValidate?.resultBuyerName ??
                                  'Không có tên người mua'}
                              </span>
                            </div>
                            {dataDetail?.buyerName &&
                              !dataDetail?.InvoiceValidate?.checkResultBuyerName &&
                              account?.role?.permissionCodes?.includes(
                                'ACCEPT_BUYER_SELLER_INFO'
                              ) && (
                                <VIButton
                                  text="Chấp nhận tên người mua này"
                                  className="w-100 btn-grey mt-2"
                                  onClick={() => {
                                    setShowModalAcceptNameBuyers(true);
                                  }}
                                />
                              )}
                          </div>
                        )}
                      >
                        <div className="mt-2">
                          <i
                            className="fas fa-circle me-3"
                            style={
                              dataDetail?.InvoiceValidate?.checkResultBuyerName
                                ? { fontSize: '7px' }
                                : dataDetail?.InvoiceValidate?.checkResultBuyerName === null
                                ? { fontSize: '7px', color: '#E18700' }
                                : { fontSize: '7px', color: '#F44141' }
                            }
                          ></i>
                          {dataDetail?.InvoiceValidate?.checkResultBuyerName ? (
                            <span>Đúng tên người mua</span>
                          ) : dataDetail?.InvoiceValidate?.checkResultBuyerName === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có tên người mua trên cơ quan thuế
                            </span>
                          ) : dataDetail?.buyerName === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có tên người mua trên hóa đơn
                            </span>
                          ) : (
                            <span style={{ color: '#F44141' }}>Sai tên người mua</span>
                          )}
                        </div>
                      </Tippy>
                      <Tippy
                        interactive
                        placement="bottom-end"
                        delay={[0, 20]}
                        offset={[-130, 0]}
                        render={() => (
                          <div className="bg-white tippy-tooltip p-5" style={{ maxWidth: '500px' }}>
                            <div className="d-flex justify-content-start flex-column">
                              <p className="mb-0 font-weight-bold text-grey text-start">
                                Mã số thuế trên hóa đơn
                              </p>
                              <span className="children-p text-start">
                                {dataDetail?.buyerTaxCode ?? 'Không có mã số thuế'}
                              </span>
                            </div>
                            <div className="mt-4 d-flex justify-content-start flex-column">
                              <p className="mb-0 font-weight-bold text-grey text-start">
                                Mã số thuế đã khai báo
                              </p>
                              <span className="children-p text-start">
                                {dataDetail?.InvoiceValidate?.resultBuyerTaxCode ??
                                  'Không có mã số thuế'}
                              </span>
                            </div>
                            {/* {dataDetail?.buyerTaxCode &&
                              !dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode && (
                                <VIButton
                                  text="Chấp nhận mã số thuế này"
                                  className="w-100 btn-grey mt-2"
                                  onClick={() => {
                                    setShowModalAcceptTaxCodeBuyers(true);
                                  }}
                                />
                              )} */}
                          </div>
                        )}
                      >
                        <div className="mt-2">
                          <i
                            className="fas fa-circle me-3"
                            style={
                              dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode
                                ? { fontSize: '7px' }
                                : dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode === null
                                ? { fontSize: '7px', color: '#E18700' }
                                : { fontSize: '7px', color: '#F44141' }
                            }
                          ></i>
                          {dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode ? (
                            <span>Đúng mã số thuế người mua</span>
                          ) : dataDetail?.InvoiceValidate?.checkResultBuyerTaxCode === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có mã số thuế người mua trên cơ quan thuế
                            </span>
                          ) : dataDetail?.buyerTaxCode === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có mã số thuế người mua trên hóa đơn
                            </span>
                          ) : (
                            <span style={{ color: '#F44141' }}>Sai mã số thuế người mua</span>
                          )}
                        </div>
                      </Tippy>
                      <Tippy
                        interactive
                        placement="bottom-end"
                        delay={[0, 20]}
                        offset={[20, 0]}
                        render={() => (
                          <div className="bg-white tippy-tooltip p-5" style={{ maxWidth: '500px' }}>
                            <div className="d-flex justify-content-start flex-column">
                              <p className="mb-0 font-weight-bold text-grey text-start">
                                Địa chỉ trên hóa đơn
                              </p>
                              <span className="children-p text-start">
                                {dataDetail?.buyerAddress ?? 'Không có địa chỉ người mua'}
                              </span>
                            </div>
                            <div className="mt-4 d-flex justify-content-start flex-column">
                              <p className="mb-0 font-weight-bold text-grey text-start">
                                Địa chỉ đã khai báo
                              </p>
                              <span className="children-p text-start">
                                {dataDetail?.InvoiceValidate?.resultBuyerAddress ??
                                  'Không có địa chỉ người mua'}
                              </span>
                            </div>
                            {dataDetail?.buyerAddress &&
                              !dataDetail?.InvoiceValidate?.checkResultBuyerAddress &&
                              account?.role?.permissionCodes?.includes(
                                'ACCEPT_BUYER_SELLER_INFO'
                              ) && (
                                <VIButton
                                  text="Chấp nhận địa chỉ này"
                                  className="w-100 btn-grey mt-2"
                                  onClick={() => {
                                    setShowModalAcceptAddressBuyers(true);
                                  }}
                                />
                              )}
                          </div>
                        )}
                      >
                        <div className="mt-2">
                          <i
                            className="fas fa-circle me-3"
                            style={
                              dataDetail?.InvoiceValidate?.checkResultBuyerAddress
                                ? { fontSize: '7px' }
                                : dataDetail?.InvoiceValidate?.checkResultBuyerAddress === null
                                ? { fontSize: '7px', color: '#E18700' }
                                : { fontSize: '7px', color: '#F44141' }
                            }
                          ></i>
                          {dataDetail?.InvoiceValidate?.checkResultBuyerAddress ? (
                            <span>Đúng địa chỉ người mua</span>
                          ) : dataDetail?.buyerAddress === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có địa chỉ người mua trên hóa đơn
                            </span>
                          ) : dataDetail?.InvoiceValidate?.checkResultBuyerAddress === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có địa chỉ người mua trên cơ quan thuế
                            </span>
                          ) : (
                            <span style={{ color: '#F44141' }}>Sai địa chỉ người mua</span>
                          )}
                        </div>
                      </Tippy>
                    </div>
                    {dataDetail?.InvoiceValidate?.checkResultSellerName &&
                    dataDetail?.InvoiceValidate?.checkResultSellerAddress ? (
                      <div className="d-flex algin-items-center my-2">
                        <i
                          className="fa-regular fa-circle-check me-3 d-flex"
                          style={{ color: '#28A043', alignItems: 'center' }}
                        ></i>
                        <span className="text-grey font-weight-bold font-size-lg">
                          {t('CorrectSellerInformation')}
                        </span>
                      </div>
                    ) : (
                      <div className="d-flex algin-items-center mt-5 mb-2">
                        <i
                          className="fa-regular fa-circle-minus me-3 d-flex"
                          style={{ color: '#FFA318', alignItems: 'center' }}
                        ></i>
                        <span className="text-grey font-weight-bold font-size-lg">
                          {t('NotEnoughSellerInformation')}
                        </span>
                      </div>
                    )}

                    <div style={{ marginLeft: '29px', marginBottom: '15px' }}>
                      <Tippy
                        interactive
                        placement="bottom-end"
                        delay={[0, 20]}
                        offset={[80, 0]}
                        render={() => (
                          <div className="bg-white tippy-tooltip p-5" style={{ maxWidth: '500px' }}>
                            <div className="d-flex justify-content-start flex-column">
                              <p className="mb-0 font-weight-bold text-grey d-flex justify-content-start">
                                Tên người bán trên hóa đơn
                              </p>
                              <span className="children-p text-start">
                                {dataDetail?.sellerName ?? 'Không có tên người bán'}
                              </span>
                            </div>
                            <div className=" d-flex justify-content-start flex-column mt-4">
                              <p className="mb-0 font-weight-bold text-grey text-start">
                                Tên người bán kiểm tra trên website của cơ quan thuế
                              </p>
                              <span className="children-p text-start">
                                {dataDetail?.InvoiceValidate?.resultSellerName ??
                                  'Không có tên người bán'}
                              </span>
                            </div>
                            {dataDetail?.sellerName &&
                              !dataDetail?.InvoiceValidate?.checkResultSellerName &&
                              account?.role?.permissionCodes?.includes(
                                'ACCEPT_BUYER_SELLER_INFO'
                              ) && (
                                <VIButton
                                  text="Chấp nhận tên người bán này"
                                  className="w-100 btn-grey mt-2"
                                  onClick={() => {
                                    setShowModalAcceptNameSellers(true);
                                  }}
                                />
                              )}
                          </div>
                        )}
                      >
                        <div className="mt-2">
                          <i
                            className="fas fa-circle me-3"
                            style={
                              dataDetail?.InvoiceValidate?.checkResultSellerName
                                ? { fontSize: '7px' }
                                : dataDetail?.InvoiceValidate?.checkResultSellerName === null
                                ? { fontSize: '7px', color: '#E18700' }
                                : { fontSize: '7px', color: '#F44141' }
                            }
                          ></i>
                          {dataDetail?.InvoiceValidate?.checkResultSellerName ? (
                            <span>Đúng tên người bán</span>
                          ) : dataDetail?.sellerName === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có tên người bán trên hóa đơn
                            </span>
                          ) : dataDetail?.InvoiceValidate?.checkResultSellerName === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có tên người bán trên cơ quan thuế
                            </span>
                          ) : (
                            <span style={{ color: '#F44141' }}>Sai tên người bán</span>
                          )}
                        </div>
                      </Tippy>
                      <Tippy
                        interactive
                        placement="bottom-end"
                        delay={[0, 20]}
                        offset={[80, 0]}
                        render={() => (
                          <div className="bg-white tippy-tooltip p-5" style={{ maxWidth: '500px' }}>
                            <div className="d-flex justify-content-start flex-column">
                              <p className="mb-0 font-weight-bold text-grey d-flex justify-content-start">
                                Mã số thuế người bán trên hóa đơn
                              </p>
                              <span className="children-p text-start">
                                {dataDetail?.sellerTaxCode ?? 'Không có mã số thuế người bán'}
                              </span>
                            </div>
                            <div className=" d-flex justify-content-start flex-column mt-4">
                              <p className="mb-0 font-weight-bold text-grey text-start">
                                Mã số thuế người bán kiểm tra trên website của cơ quan thuế
                              </p>
                              <span className="children-p text-start">
                                {dataDetail?.InvoiceValidate?.resultSellerTaxCode ??
                                  'Không có mã số thuế người bán'}
                              </span>
                            </div>
                            {/* {dataDetail?.sellerTaxCode &&
                              dataDetail?.InvoiceValidate?.checkResultSellerTaxCode === false && (
                                <VIButton
                                  text="Chấp nhận mã số thuế người bán này"
                                  className="w-100 btn-grey mt-2"
                                  onClick={() => {
                                    setShowModalAcceptTaxCodeSellers(true);
                                  }}
                                />
                              )} */}
                          </div>
                        )}
                      >
                        <div className="mt-2">
                          <i
                            className="fas fa-circle me-3"
                            style={
                              dataDetail?.InvoiceValidate?.checkResultSellerTaxCode
                                ? { fontSize: '7px' }
                                : dataDetail?.InvoiceValidate?.checkResultSellerTaxCode === null
                                ? { fontSize: '7px', color: '#E18700' }
                                : { fontSize: '7px', color: '#F44141' }
                            }
                          ></i>
                          {dataDetail?.InvoiceValidate?.checkResultSellerTaxCode ? (
                            <span>Đúng mã số thuế người bán</span>
                          ) : dataDetail?.sellerTaxCode === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có mã số thuế người bán trên hóa đơn
                            </span>
                          ) : dataDetail?.InvoiceValidate?.checkResultSellerTaxCode === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có mã số thuế người bán trên cơ quan thuế
                            </span>
                          ) : (
                            <span style={{ color: '#F44141' }}>Sai mã số thuế người bán</span>
                          )}
                        </div>
                      </Tippy>
                      <Tippy
                        interactive
                        placement="bottom-end"
                        delay={[0, 20]}
                        offset={[20, 0]}
                        render={() => (
                          <div className="bg-white tippy-tooltip p-5">
                            <div className="d-flex justify-content-start flex-column">
                              <p className="mb-0 font-weight-bold text-grey text-start">
                                Địa chỉ trên hóa đơn
                              </p>
                              <span
                                className="children-p text-start"
                                style={{ maxWidth: '450px', wordWrap: 'break-word' }}
                              >
                                {dataDetail?.sellerAddress ?? 'Không có địa chỉ người bán'}
                              </span>
                            </div>
                            <div className="mt-4 d-flex justify-content-start flex-column">
                              <p className="mb-0 font-weight-bold text-grey text-start">
                                Địa chỉ kiểm tra trên website của cơ quan thuế
                              </p>
                              <span
                                className="children-p text-start"
                                style={{ maxWidth: '450px', wordWrap: 'break-word' }}
                              >
                                {dataDetail?.InvoiceValidate?.resultSellerAddress ??
                                  'Không có địa chỉ người bán'}
                              </span>
                            </div>
                            {dataDetail?.sellerAddress &&
                              !dataDetail?.InvoiceValidate?.checkResultSellerAddress &&
                              account?.role?.permissionCodes?.includes(
                                'ACCEPT_BUYER_SELLER_INFO'
                              ) && (
                                <VIButton
                                  text="Chấp nhận địa chỉ người bán này"
                                  className="w-100 btn-grey mt-2"
                                  onClick={() => {
                                    setShowModalAcceptAddressSellers(true);
                                  }}
                                />
                              )}
                          </div>
                        )}
                      >
                        <div className="mt-2">
                          <i
                            className="fas fa-circle me-3"
                            style={
                              dataDetail?.InvoiceValidate?.checkResultSellerAddress
                                ? { fontSize: '7px' }
                                : dataDetail?.InvoiceValidate?.checkResultSellerAddress === null
                                ? { fontSize: '7px', color: '#E18700' }
                                : { fontSize: '7px', color: '#F44141' }
                            }
                          ></i>
                          {dataDetail?.InvoiceValidate?.checkResultSellerAddress ? (
                            <span>Đúng địa chỉ người bán</span>
                          ) : dataDetail?.sellerAddress === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có địa chỉ người bán trên hóa đơn
                            </span>
                          ) : dataDetail?.InvoiceValidate?.checkResultSellerAddress === null ? (
                            <span style={{ color: '#E18700' }}>
                              Không có địa chỉ người bán trên cơ quan thuế
                            </span>
                          ) : (
                            <span style={{ color: '#F44141' }}>Sai địa chỉ người bán</span>
                          )}
                        </div>
                      </Tippy>

                      <span className="" style={{ fontStyle: 'italic' }}>
                        Đối chiếu với dữ liệu CQT ngày{' '}
                        {Utils.formatDateTime(dataDetail?.InvoiceValidate?.checkDate, 'DD/MM/YYYY')}
                      </span>
                    </div>

                    {!dataDetail?.isManualInput && (
                      <>
                        {dataDetail?.InvoiceValidate?.checkResultSignatureCQT &&
                        dataDetail?.InvoiceValidate?.checkResultSignatureNCC ? (
                          <div className="d-flex algin-items-center my-2">
                            <i
                              className="fa-regular fa-circle-check me-3 d-flex"
                              style={{ color: '#28A043', alignItems: 'center' }}
                            ></i>
                            <span className="text-grey font-weight-bold font-size-lg">
                              {t('ValidElectronicSignature')}
                            </span>
                          </div>
                        ) : (
                          <div className="d-flex algin-items-center mt-5 mb-2">
                            <i
                              className="fa-regular fa-circle-minus me-3 d-flex"
                              style={{ color: '#FFA318', alignItems: 'center' }}
                            ></i>
                            <span className="text-grey font-weight-bold font-size-lg">
                              {t('InvalidElectronicSignature')}
                            </span>
                          </div>
                        )}

                        <div style={{ marginLeft: '29px' }}>
                          <Tippy
                            interactive
                            placement="bottom-end"
                            delay={[0, 20]}
                            offset={[20, 0]}
                            render={() => (
                              <div
                                className="bg-white tippy-tooltip p-5"
                                style={{ maxWidth: '500px' }}
                              >
                                <div className="d-flex justify-content-start flex-column">
                                  <p
                                    className="mb-0 font-weight-bold d-flex justify-content-start"
                                    style={{ color: '#1C2256' }}
                                  >
                                    Thông tin chứng thư
                                  </p>

                                  <span className="children-p text-start ms-3">
                                    <div className="d-flex">
                                      <div style={{ width: '40%' }}>Nhà phát hành chứng thư: </div>
                                      <div style={{ width: '60%', color: '#1C2256' }}>
                                        {dataDetail?.sellerCKS?.Issuer}
                                      </div>
                                    </div>
                                    <br></br>
                                    <div className="d-flex">
                                      <div style={{ width: '40%' }}>Đơn vị ký:</div>
                                      <div style={{ width: '60%', color: '#1C2256' }}>
                                        {dataDetail?.sellerName}
                                      </div>
                                    </div>

                                    <div className="d-flex">
                                      <div style={{ width: '40%' }}> Ngày bắt đầu hiệu lực:</div>{' '}
                                      <div style={{ width: '60%', color: '#1C2256' }}>
                                        {' '}
                                        {dataDetail?.sellerCKS?.NotBefore
                                          ? Utils.formatDateTime(
                                              dataDetail?.sellerCKS?.NotBefore,
                                              'DD/MM/YYYY'
                                            )
                                          : 'Không có ngày bắt đầu hiệu lực'}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div style={{ width: '40%' }}>Ngày kết thúc hiệu lực:</div>{' '}
                                      <div style={{ width: '60%', color: '#1C2256' }}>
                                        {dataDetail?.sellerCKS?.NotAfter
                                          ? Utils.formatDateTime(
                                              dataDetail?.sellerCKS?.NotAfter,
                                              'DD/MM/YYYY'
                                            )
                                          : 'Không có ngày kết thúc hiệu lực'}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div style={{ width: '40%' }}>Ngày ký hóa đơn:</div>{' '}
                                      <div style={{ width: '60%', color: '#1C2256' }}>
                                        {dataDetail?.sellerCKS?.SigningTime
                                          ? Utils.formatDateTime(
                                              dataDetail?.sellerCKS?.SigningTime,
                                              'DD/MM/YYYY'
                                            )
                                          : 'Không có ngày ký hóa đơn'}
                                      </div>
                                    </div>
                                  </span>
                                </div>
                                <div className="d-flex justify-content-start flex-column mt-4">
                                  {dataDetail?.InvoiceValidate?.nb_cts_hop_le ? (
                                    <span className="children-p text-start d-flex me-2 mb-2">
                                      <i
                                        className="fa-regular fa-circle-check me-3 d-flex"
                                        style={{ color: '#28A043', alignItems: 'center' }}
                                      ></i>
                                      <span className="text-grey font-weight-bold font-size-lg">
                                        Thông tin thuê bao chứng thư số hợp lệ
                                      </span>
                                    </span>
                                  ) : dataDetail?.InvoiceValidate?.nb_cts_hop_le === null ? (
                                    <div className="d-flex algin-items-center mb-2">
                                      <i
                                        className="fa-regular fa-circle-minus me-3 d-flex align-items-center"
                                        style={{ color: '#FFA318', alignItems: 'center' }}
                                      ></i>

                                      <span className="text-grey font-weight-bold font-size-lg">
                                        Không có thông tin thuê bao chứng thư số
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="d-flex algin-items-center mb-2">
                                      <i
                                        className="fa-solid fa-circle-xmark me-3 d-flex align-items-center"
                                        style={{ color: '#F44141', alignItems: 'center' }}
                                      ></i>
                                      <span className="text-grey font-weight-bold font-size-lg">
                                        Thông tin thuê bao chứng thư số không hợp lệ
                                      </span>
                                    </div>
                                  )}
                                  {dataDetail?.InvoiceValidate?.nb_cts_con_hieu_luc ? (
                                    <span className="children-p text-start d-flex me-2 mb-2">
                                      <i
                                        className="fa-regular fa-circle-check me-3 d-flex"
                                        style={{ color: '#28A043', alignItems: 'center' }}
                                      ></i>
                                      <span className="text-grey font-weight-bold font-size-lg">
                                        Chứng thư số còn hiệu lực
                                      </span>
                                    </span>
                                  ) : dataDetail?.InvoiceValidate?.nb_cts_con_hieu_luc === null ? (
                                    <div className="d-flex algin-items-center mb-2">
                                      <i
                                        className="fa-regular fa-circle-minus me-3 d-flex align-items-center"
                                        style={{ color: '#FFA318', alignItems: 'center' }}
                                      ></i>

                                      <span className="text-grey font-weight-bold font-size-lg">
                                        Không có chứng thư số
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="d-flex algin-items-center mb-2">
                                      <i
                                        className="fa-solid fa-circle-xmark me-3 d-flex align-items-center"
                                        style={{ color: '#F44141', alignItems: 'center' }}
                                      ></i>
                                      <span className="text-grey font-weight-bold font-size-lg">
                                        Chứng thư số hết hiệu lực
                                      </span>
                                    </div>
                                  )}
                                  {dataDetail?.InvoiceValidate?.nb_file_xml_chua_bi_sua ? (
                                    <span className="children-p text-start d-flex me-2 mb-2">
                                      <i
                                        className="fa-regular fa-circle-check me-3 d-flex"
                                        style={{ color: '#28A043', alignItems: 'center' }}
                                      ></i>
                                      <span className="text-grey font-weight-bold font-size-lg">
                                        File XML chưa bị sửa đổi sau khi ký
                                      </span>
                                    </span>
                                  ) : dataDetail?.InvoiceValidate?.nb_file_xml_chua_bi_sua ===
                                    null ? (
                                    <div className="d-flex algin-items-center mb-2">
                                      <i
                                        className="fa-regular fa-circle-minus me-3 d-flex align-items-center"
                                        style={{ color: '#FFA318', alignItems: 'center' }}
                                      ></i>

                                      <span className="text-grey font-weight-bold font-size-lg">
                                        Không có trạng thái File XML sau khi ký
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="d-flex algin-items-center mb-2">
                                      <i
                                        className="fa-solid fa-circle-xmark me-3 d-flex align-items-center"
                                        style={{ color: '#F44141', alignItems: 'center' }}
                                      ></i>
                                      <span className="text-grey font-weight-bold font-size-lg">
                                        File XML đã bị sửa đổi sau khi ký
                                      </span>
                                    </div>
                                  )}
                                  {dataDetail?.InvoiceValidate?.nb_thong_tin_cts_hop_le ? (
                                    <span className="children-p text-start d-flex me-2 mb-2">
                                      <i
                                        className="fa-regular fa-circle-check me-3 d-flex"
                                        style={{ color: '#28A043', alignItems: 'center' }}
                                      ></i>
                                      <span className="text-grey font-weight-bold font-size-lg">
                                        Chứng thư số hợp lệ
                                      </span>
                                    </span>
                                  ) : dataDetail?.InvoiceValidate?.nb_thong_tin_cts_hop_le ===
                                    null ? (
                                    <div className="d-flex algin-items-center mb-2">
                                      <i
                                        className="fa-regular fa-circle-minus me-3 d-flex align-items-center"
                                        style={{ color: '#FFA318', alignItems: 'center' }}
                                      ></i>

                                      <span className="text-grey font-weight-bold font-size-lg">
                                        Không có trạng thái chứng thư số
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="d-flex algin-items-center mb-2">
                                      <i
                                        className="fa-solid fa-circle-xmark me-3 d-flex align-items-center"
                                        style={{ color: '#F44141', alignItems: 'center' }}
                                      ></i>
                                      <span className="text-grey font-weight-bold font-size-lg">
                                        Chứng thư số không hợp lệ
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          >
                            <div className="mt-2">
                              <i
                                className="fas fa-circle me-3"
                                style={
                                  dataDetail?.InvoiceValidate?.checkResultSignatureNCC
                                    ? { fontSize: '7px' }
                                    : dataDetail?.InvoiceValidate?.checkResultSignatureNCC === null
                                    ? { fontSize: '7px', color: '#E18700' }
                                    : { fontSize: '7px', color: '#F44141' }
                                }
                              ></i>
                              {dataDetail?.InvoiceValidate?.checkResultSignatureNCC ? (
                                <span>Chữ ký điện tử NCC hợp lệ</span>
                              ) : dataDetail?.InvoiceValidate?.checkResultSignatureNCC === null ? (
                                <span style={{ color: '#E18700' }}>
                                  Không có chữ ký điện tử NCC
                                </span>
                              ) : (
                                <span style={{ color: '#F44141' }}>
                                  Chữ ký điện tử NCC không hợp lệ
                                </span>
                              )}
                            </div>
                          </Tippy>
                          <Tippy
                            interactive
                            placement="bottom-end"
                            delay={[0, 20]}
                            offset={[20, 0]}
                            render={() => (
                              <div
                                className="bg-white tippy-tooltip p-5"
                                style={{ maxWidth: '500px' }}
                              >
                                <div className="d-flex justify-content-start flex-column">
                                  <p className="mb-0 font-weight-bold text-grey d-flex justify-content-start">
                                    Thông tin chứng thư
                                  </p>
                                  <span className="children-p text-start ms-3">
                                    <div className="d-flex">
                                      <div style={{ width: '40%' }}>Nhà phát hành chứng thư: </div>
                                      <div style={{ width: '60%', color: '#1C2256' }}>
                                        {dataDetail?.cqtCKS?.Issuer}
                                      </div>
                                    </div>
                                    <br></br>
                                    <div className="d-flex">
                                      <div style={{ width: '40%' }}>Đơn vị ký:</div>
                                      <div style={{ width: '60%', color: '#1C2256' }}>
                                        {dataDetail?.cqtCKS?.Subject}
                                      </div>
                                    </div>

                                    <div className="d-flex">
                                      <div style={{ width: '40%' }}> Ngày bắt đầu hiệu lực:</div>{' '}
                                      <div style={{ width: '60%', color: '#1C2256' }}>
                                        {' '}
                                        {dataDetail?.cqtCKS?.NotBefore
                                          ? Utils.formatDateTime(
                                              dataDetail?.cqtCKS?.NotBefore,
                                              'DD/MM/YYYY'
                                            )
                                          : 'Không có ngày bắt đầu hiệu lực'}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div style={{ width: '40%' }}>Ngày kết thúc hiệu lực:</div>{' '}
                                      <div style={{ width: '60%', color: '#1C2256' }}>
                                        {dataDetail?.cqtCKS?.NotAfter
                                          ? Utils.formatDateTime(
                                              dataDetail?.cqtCKS?.NotAfter,
                                              'DD/MM/YYYY'
                                            )
                                          : 'Không có ngày kết thúc hiệu lực'}
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div style={{ width: '40%' }}>Ngày ký hóa đơn:</div>{' '}
                                      <div style={{ width: '60%', color: '#1C2256' }}>
                                        {dataDetail?.cqtCKS?.SigningTime
                                          ? Utils.formatDateTime(
                                              dataDetail?.cqtCKS?.SigningTime,
                                              'DD/MM/YYYY'
                                            )
                                          : 'Không có ngày ký hóa đơn'}
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}
                          >
                            {!dataDetail?.serial.startsWith('K') && (
                              <div className="mt-2">
                                <i
                                  className="fas fa-circle me-3"
                                  style={
                                    dataDetail?.InvoiceValidate?.checkResultSignatureCQT
                                      ? { fontSize: '7px' }
                                      : dataDetail?.InvoiceValidate?.checkResultSignatureCQT ===
                                        null
                                      ? { fontSize: '7px', color: '#E18700' }
                                      : { fontSize: '7px', color: '#F44141' }
                                  }
                                ></i>

                                {dataDetail?.InvoiceValidate?.checkResultSignatureCQT ? (
                                  <span>Đã được ký bởi Cơ quan thuế</span>
                                ) : dataDetail?.InvoiceValidate?.checkResultSignatureCQT ? (
                                  <span style={{ color: '#E18700' }}>
                                    Không được ký bởi Cơ quan thuế
                                  </span>
                                ) : (
                                  <span style={{ color: '#F44141' }}>
                                    Chưa được ký bởi Cơ quan thuế
                                  </span>
                                )}
                              </div>
                            )}
                          </Tippy>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="w-100 mt-4">
                    <span className="text-primary font-weight-bold font-size-lg">
                      {t('CheckWithTheGeneralDepartmentOfTaxationSystem')}
                    </span>

                    {dataDetail?.isManualInput ? (
                      <>
                        {dataDetail?.InvoiceValidate?.nb_dang_hoat_dong ? (
                          <div className="d-flex algin-items-center my-2">
                            <i
                              className="fa-regular fa-circle-check me-3 d-flex"
                              style={{ color: '#28A043', alignItems: 'center' }}
                            ></i>
                            <span className="text-greyfont-weight-bold font-size-lg">
                              {t('Người bán đang hoạt động')}
                            </span>
                          </div>
                        ) : dataDetail?.InvoiceValidate?.nb_dang_hoat_dong === null ? (
                          <div className="d-flex algin-items-center my-2">
                            <i
                              className="fa-regular fa-circle-minus me-3 d-flex"
                              style={{ color: '#FFA318', alignItems: 'center' }}
                            ></i>
                            <span className="text-grey font-weight-bold font-size-lg">
                              {t('Không kiểm tra được trạng thái của người bán')}
                            </span>
                          </div>
                        ) : (
                          <div className="d-flex algin-items-center my-2">
                            <i
                              className="fa-regular fa-circle-exclamation me-3 d-flex"
                              style={{ color: '#F44141', alignItems: 'center' }}
                            ></i>
                            <span className="text-grey font-weight-bold font-size-lg">
                              {t('Người bán không hoạt động')}
                            </span>
                          </div>
                        )}
                        {dataDetail?.InvoiceValidate?.nb_khong_rui_ro_tai_tdlap ? (
                          <div className="d-flex algin-items-center my-2">
                            <i
                              className="fa-regular fa-circle-exclamation me-3 d-flex"
                              style={{ color: '#F44141', alignItems: 'center' }}
                            ></i>
                            <span className="text-grey font-weight-bold font-size-lg">
                              {t(
                                'Người bán thuộc danh sách cảnh báo của Tổng Cục thuế tại thời điểm phát hành hóa đơn'
                              )}
                            </span>
                          </div>
                        ) : dataDetail?.InvoiceValidate?.nb_khong_rui_ro_tai_tdlap === null ? (
                          <div className="d-flex algin-items-center my-2">
                            <i
                              className="fa-regular fa-circle-minus me-3 d-flex"
                              style={{ color: '#FFA318', alignItems: 'center' }}
                            ></i>
                            <span className="text-grey font-weight-bold font-size-lg">
                              {t(
                                'Không kiểm tra được rủi ro của người bán tại thời điểm phát hành hóa đơn'
                              )}
                            </span>
                          </div>
                        ) : (
                          <div className="d-flex algin-items-center my-2">
                            <i
                              className="fa-regular fa-circle-check me-3 d-flex"
                              style={{ color: '#28A043', alignItems: 'center' }}
                            ></i>
                            <span className="text-grey font-weight-bold font-size-lg">
                              {t('Người bán không rủi ro tại thời điểm phát hành hóa đơn')}
                            </span>
                          </div>
                        )}
                        {dataDetail?.InvoiceValidate?.mauso_kyhieu_da_tb &&
                          dataDetail?.InvoiceValidate?.so_hd_thuoc_khoang_phat_hanh &&
                          dataDetail?.InvoiceValidate?.ngay_hoa_don_tu_ngay_su_dung && (
                            <div className="d-flex algin-items-center my-2">
                              <i
                                className="fa-regular fa-circle-check me-3 d-flex"
                                style={{ color: '#28A043', alignItems: 'center' }}
                              ></i>
                              <span className="text-grey font-weight-bold font-size-lg">
                                {t('Hóa đơn đúng thông báo phát hành')}
                              </span>
                            </div>
                          )}
                        {(dataDetail?.InvoiceValidate?.mauso_kyhieu_da_tb === null ||
                          dataDetail?.InvoiceValidate?.so_hd_thuoc_khoang_phat_hanh === nulll ||
                          dataDetail?.InvoiceValidate?.ngay_hoa_don_tu_ngay_su_dung === null) && (
                          <div className="d-flex algin-items-center my-2">
                            <i
                              className="fa-regular fa-circle-minus me-3 d-flex"
                              style={{ color: '#FFA318', alignItems: 'center' }}
                            ></i>
                            <span className="text-grey font-weight-bold font-size-lg">
                              {t('Hóa đơn chưa thông báo phát hành')}
                            </span>
                          </div>
                        )}
                        {(dataDetail?.InvoiceValidate?.mauso_kyhieu_da_tb === false ||
                          dataDetail?.InvoiceValidate?.so_hd_thuoc_khoang_phat_hanh === false ||
                          dataDetail?.InvoiceValidate?.ngay_hoa_don_tu_ngay_su_dung === false) && (
                          <div className="d-flex algin-items-center my-2">
                            <i
                              className="fa-regular fa-circle-exclamation me-3 d-flex"
                              style={{ color: '#F44141', alignItems: 'center' }}
                            ></i>
                            <span className="text-grey font-weight-bold font-size-lg">
                              {t('Hóa đơn chưa đúng với thông báo phát hành')}
                            </span>
                          </div>
                        )}
                        {dataDetail?.InvoiceValidate?.mauso_kyhieu_da_tb ? (
                          <div style={{ marginLeft: '28px' }}>
                            <i
                              className="fas fa-circle me-3"
                              style={{ fontSize: '7px', color: '#28A043' }}
                            ></i>
                            <span style={{ color: '#28A043' }}>
                              Mẫu số ký hiệu đã được thông báo phát hành
                            </span>
                          </div>
                        ) : dataDetail?.InvoiceValidate?.mauso_kyhieu_da_tb === null ? (
                          <div style={{ marginLeft: '28px' }}>
                            <i
                              className="fas fa-circle me-3"
                              style={{ fontSize: '7px', color: '#FFA318' }}
                            ></i>
                            <span style={{ color: '#FFA318' }}>
                              Không kiểm tra được mẫu số ký hiệu
                            </span>
                          </div>
                        ) : (
                          <div style={{ marginLeft: '28px' }}>
                            <i
                              className="fas fa-circle me-3"
                              style={{ fontSize: '7px', color: '#F44141' }}
                            ></i>
                            <span style={{ color: '#F44141' }}>
                              Mẫu số ký hiệu chưa được thông báo phát hành
                            </span>
                          </div>
                        )}
                        {dataDetail?.InvoiceValidate?.so_hd_thuoc_khoang_phat_hanh ? (
                          <div style={{ marginLeft: '28px' }}>
                            <i
                              className="fas fa-circle me-3"
                              style={{ fontSize: '7px', color: '#28A043' }}
                            ></i>
                            <span style={{ color: '#28A043' }}>
                              Số hóa đơn thuộc khoảng hóa đơn thông báo phát hành
                            </span>
                          </div>
                        ) : dataDetail?.InvoiceValidate?.so_hd_thuoc_khoang_phat_hanh === null ? (
                          <div style={{ marginLeft: '28px' }}>
                            <i
                              className="fas fa-circle me-3"
                              style={{ fontSize: '7px', color: '#FFA318' }}
                            ></i>
                            <span style={{ color: '#FFA318' }}>Không kiểm tra được số hóa đơn</span>
                          </div>
                        ) : (
                          <div style={{ marginLeft: '28px' }}>
                            <i
                              className="fas fa-circle me-3"
                              style={{ fontSize: '7px', color: '#F44141' }}
                            ></i>
                            <span style={{ color: '#F44141' }}>
                              Số hóa đơn không thuộc khoảng hóa đơn thông báo phát hành
                            </span>
                          </div>
                        )}
                        {dataDetail?.InvoiceValidate?.ngay_hoa_don_tu_ngay_su_dung ? (
                          <div style={{ marginLeft: '28px' }}>
                            <i
                              className="fas fa-circle me-3"
                              style={{ fontSize: '7px', color: '#28A043' }}
                            ></i>
                            <span style={{ color: '#28A043' }}>
                              Ngày hóa đơn lớn hơn ngày bắt đầu sử dụng
                            </span>
                          </div>
                        ) : dataDetail?.InvoiceValidate?.ngay_hoa_don_tu_ngay_su_dung === null ? (
                          <div style={{ marginLeft: '28px' }}>
                            <i
                              className="fas fa-circle me-3"
                              style={{ fontSize: '7px', color: '#FFA318' }}
                            ></i>
                            <span style={{ color: '#FFA318' }}>
                              Không kiểm tra được ngày hóa đơn
                            </span>
                          </div>
                        ) : (
                          <div style={{ marginLeft: '28px' }}>
                            <i
                              className="fas fa-circle me-3"
                              style={{ fontSize: '7px', color: '#F44141' }}
                            ></i>
                            <span style={{ color: '#F44141' }}>
                              Ngày hóa đơn nhỏ hơn ngày bắt đầu sử dụng
                            </span>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="d-flex algin-items-center my-2">
                          {dataDetail?.InvoiceValidate?.checkResultHasCQTRecord &&
                          dataDetail?.InvoiceValidate?.checkResultHasInvoiceCode ? (
                            <i
                              className="fa-regular fa-circle-check me-3 d-flex"
                              style={{ color: '#28A043', alignItems: 'center' }}
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-circle-minus me-3 d-flex"
                              style={{ color: '#FFA318', alignItems: 'center' }}
                            ></i>
                          )}
                          <span className="text-grey font-weight-bold font-size-lg">
                            {t('CheckOnTheSystemOfTheGeneralDepartmentOfTaxation')}
                          </span>
                        </div>
                        <div className="mb-4" style={{ marginLeft: '29px' }}>
                          <div>
                            <i className="fas fa-circle me-3" style={{ fontSize: '7px' }}></i>
                            {dataDetail?.InvoiceValidate?.checkResultHasCQTRecord ? (
                              <span>
                                Hóa đơn đã tồn tại trên hệ thống tra cứu Cơ quan thuế tại thời điểm
                                kiểm tra
                              </span>
                            ) : (
                              <span>
                                Hóa đơn chưa tồn tại trên hệ thống tra cứu Cơ quan thuế tại thời
                                điểm kiểm tra
                              </span>
                            )}
                          </div>
                          <div className="mt-2">
                            {!dataDetail?.InvoiceValidate?.checkResultHasInvoiceCode &&
                            dataDetail?.serial.startsWith('K') ? (
                              ''
                            ) : dataDetail?.InvoiceValidate?.checkResultHasInvoiceCode &&
                              dataDetail?.serial.startsWith('K') ? (
                              <>
                                <i className="fas fa-circle me-3" style={{ fontSize: '7px' }}></i>
                                <span>
                                  Trạng thái xử lý hóa đơn: Tổng cục thuế đã nhận không mã
                                </span>
                              </>
                            ) : (
                              <>
                                <i className="fas fa-circle me-3" style={{ fontSize: '7px' }}></i>
                                <span>Trạng thái xử lý hóa đơn: Đã cấp mã hóa đơn</span>
                              </>
                            )}
                          </div>
                        </div>
                        {dataDetail?.InvoiceProducts?.length > 0 &&
                          store.getState()?.auth?.account?.Organization?.warningInListInvoice && (
                            <>
                              <span className="text-primary font-weight-bold font-size-lg mt-2">
                                {t('CheckTheDataCalculation')}
                              </span>
                              <div className="d-flex align-items-center">
                                <i
                                  className="fa-regular fa-calculator me-3 d-flex align-items-center mt-2"
                                  style={
                                    dataDetail?.amountVat5 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '5%') {
                                          total + item?.vatAmount, 0;
                                        }
                                      }) &&
                                    dataDetail?.amountVat8 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '8%') {
                                          total + item?.vatAmount, 0;
                                        }
                                      }) &&
                                    dataDetail?.amountVat10 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '10%') {
                                          total + item?.vatAmount, 0;
                                        }
                                      }) &&
                                    dataDetail?.amountBeforeVat5 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '5%') {
                                          total + item?.amountTotal, 0;
                                        }
                                      }) &&
                                    dataDetail?.amountBeforeVat8 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '8%') {
                                          total + item?.amountTotal, 0;
                                        }
                                      }) &&
                                    dataDetail?.amountBeforeVat10 !==
                                      dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                        if (item?.vat === '10%') {
                                          total + item?.amountTotal, 0;
                                        }
                                      }) &&
                                    dataDetail?.amountBeforeVat ===
                                      dataDetail?.InvoiceProducts?.reduce(
                                        (total, item) => total + item?.amountTotal,
                                        0
                                      ) &&
                                    dataDetail?.amountVat ===
                                      dataDetail?.InvoiceProducts?.reduce(
                                        (total, item) => total + item?.vatAmount,
                                        0
                                      )
                                      ? { alignItems: 'center' }
                                      : { alignItems: 'center', color: '#FFA318' }
                                  }
                                ></i>

                                {dataDetail?.amountVat5 !==
                                  dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                    if (item?.vat === '5%') {
                                      total + item?.vatAmount, 0;
                                    }
                                  }) &&
                                dataDetail?.amountVat8 !==
                                  dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                    if (item?.vat === '8%') {
                                      total + item?.vatAmount, 0;
                                    }
                                  }) &&
                                dataDetail?.amountVat10 !==
                                  dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                    if (item?.vat === '10%') {
                                      total + item?.vatAmount, 0;
                                    }
                                  }) &&
                                dataDetail?.amountBeforeVat5 !==
                                  dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                    if (item?.vat === '5%') {
                                      total + item?.amountTotal, 0;
                                    }
                                  }) &&
                                dataDetail?.amountBeforeVat8 !==
                                  dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                    if (item?.vat === '8%') {
                                      total + item?.amountTotal, 0;
                                    }
                                  }) &&
                                dataDetail?.amountBeforeVat10 !==
                                  dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                    if (item?.vat === '10%') {
                                      total + item?.amountTotal, 0;
                                    }
                                  }) &&
                                dataDetail?.amountBeforeVat ===
                                  dataDetail?.InvoiceProducts?.reduce(
                                    (total, item) => total + item?.amountTotal,
                                    0
                                  ) &&
                                dataDetail?.amountVat ===
                                  dataDetail?.InvoiceProducts?.reduce(
                                    (total, item) => total + item?.vatAmount,
                                    0
                                  ) ? (
                                  <span className="font-weight-bold font-size-lg mt-2">
                                    Hóa đơn chính xác về tính toán số liệu
                                  </span>
                                ) : (
                                  <span className="font-weight-bold font-size-lg mt-2">
                                    Hóa đơn chưa chính xác về tính toán số liệu
                                  </span>
                                )}
                              </div>

                              {dataDetail?.amountVat5 !==
                                dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                  if (item?.vat === '5%') {
                                    return total + item?.vatAmount;
                                  }
                                }, 0) &&
                                dataDetail?.amountVat5 > 0 && (
                                  <div>
                                    - Tiền thuế GTGT của mức thuế suất 5% ≠ (thành tiền chưa thuế *
                                    thuế suất) của mức thuế suất 5%
                                  </div>
                                )}
                              {dataDetail?.amountVat8 !==
                                dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                  if (item?.vat === '8%') {
                                    return total + item?.vatAmount;
                                  }
                                }, 0) &&
                                dataDetail?.amountVat8 > 0 && (
                                  <div>
                                    - Tiền thuế GTGT của mức thuế suất 8% ≠ (thành tiền chưa thuế *
                                    thuế suất) của mức thuế suất 8%
                                  </div>
                                )}
                              {dataDetail?.amountVat10 !==
                                dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                  if (item?.vat === '10%') {
                                    return total + item?.vatAmount;
                                  }
                                }, 0) &&
                                dataDetail?.amountVat10 > 0 && (
                                  <div>
                                    - Tiền thuế GTGT của mức thuế suất 10% ≠ (thành tiền chưa thuế *
                                    thuế suất) của mức thuế suất 10%
                                  </div>
                                )}

                              {dataDetail?.amountBeforeVat5 !==
                                dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                  if (item?.vat === '5%') {
                                    return total + item?.amountTotal;
                                  }
                                }, 0) &&
                                dataDetail?.amountBeforeVat5 !== null && (
                                  <div>
                                    - Thành tiền chưa thuế tổng hợp của mức thuế suất 5% ≠ tổng
                                    thành tiền chưa thuế của các dòng hàng hóa dịch vụ có mức thuế
                                    suất 5%
                                  </div>
                                )}
                              {dataDetail?.amountBeforeVat8 !==
                                dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                  if (item?.vat === '8%') {
                                    return total + item?.amountTotal;
                                  }
                                }, 0) &&
                                dataDetail?.amountBeforeVat8 !== null && (
                                  <div>
                                    - Thành tiền chưa thuế tổng hợp của mức thuế suất 8% ≠ tổng
                                    thành tiền chưa thuế của các dòng hàng hóa dịch vụ có mức thuế
                                    suất 8%
                                  </div>
                                )}
                              {dataDetail?.amountBeforeVat10 !==
                                dataDetail?.InvoiceProducts?.reduce((total, item) => {
                                  if (item?.vat === '10%') {
                                    return total + item?.amountTotal;
                                  }
                                }, 0) &&
                                dataDetail?.amountBeforeVat10 !== null && (
                                  <div>
                                    - Thành tiền chưa thuế tổng hợp của mức thuế suất 10% ≠ tổng
                                    thành tiền chưa thuế của các dòng hàng hóa dịch vụ có mức thuế
                                    suất 10%
                                  </div>
                                )}

                              {dataDetail?.amountBeforeVat !==
                                dataDetail?.InvoiceProducts?.reduce(
                                  (total, item) => total + item?.amountTotal,
                                  0
                                ) && (
                                <div>
                                  - Tổng tiền chưa thuế trên thẻ thanh toán ≠ tổng thành tiền chưa
                                  thuế trên các dòng của thẻ thông tin tổng hợp
                                </div>
                              )}

                              {dataDetail?.amountVat !==
                                dataDetail?.InvoiceProducts?.reduce(
                                  (total, item) => total + item?.vatAmount,
                                  0
                                ) && (
                                <div>
                                  - Tổng tiền thuế GTGT ≠ tổng tiền thuế của các dòng tổng hợp theo
                                  mức thuế suất
                                </div>
                              )}
                            </>
                          )}
                      </>
                    )}

                    <div
                      className="d-flex align-items-center cursor-pointer"
                      onClick={() => setShowListOfOccupations(true)}
                    >
                      <i
                        className="fa-solid fa-list-ul me-3 d-flex align-items-center mt-2 text-primary"
                        style={{ alignItems: 'center' }}
                      ></i>
                      <span className="font-weight-bold font-size-lg mt-2 text-primary">
                        Danh sách ngành nghề kinh doanh
                      </span>
                    </div>
                    {dataDetail?.isRiskyInvoice && (
                      <>
                        <div className="d-flex algin-items-center my-2">
                          <i
                            className="fa-regular fa-circle-exclamation me-3 d-flex"
                            style={{ color: '#F44141', alignItems: 'center' }}
                          ></i>

                          <div>
                            <span
                              className="font-weight-bold font-size-lg"
                              style={{ color: '#F44141' }}
                            >
                              {t('Hoá đơn rủi ro')}
                            </span>
                          </div>
                        </div>
                        <div className="" style={{ marginLeft: '28px' }}>
                          <i className="fas fa-circle me-3" style={{ fontSize: '7px' }}></i>
                          <span>
                            Hoá đơn rủi ro do doanh nghiệp thuộc danh sách cảnh báo của Tổng Cục
                            thuế
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="mt-6 ml-6 mr-6 p-5"
                  style={{
                    borderRadius: '20px',
                    background: 'var(--system-bg-select, #F8F8F8)',
                    marginBottom: '80px',
                  }}
                >
                  <div>
                    <p className="">{t('Attachments')}</p>
                    <span className="pb-2">Phần mềm chỉ hỗ trợ tệp đính kèm nhỏ hơn 5MB</span>
                    {dataDetail?.InvoiceAttachs?.length > 0 && (
                      <div className="me-5 w-100 mt-4">
                        {dataDetail?.InvoiceAttachs?.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex justify-content-between align-items-center bg-white mb-2 py-2 px-4 rounded item-file"
                          >
                            <div className="w-100 me-3 d-flex align-items-center justify-content-between">
                              <div
                                style={{ wordBreak: 'break-word' }}
                                className="text-primary cursor-pointer"
                                onClick={() => handleDownLoadFile(item)}
                              >
                                {item?.fileName}
                              </div>
                              <div style={{ minWidth: 'max-content' }}>
                                ({Math.floor(item?.size / 1024)} KB)
                              </div>
                            </div>
                            <div className="hover-action d-flex align-item-center">
                              <KTTooltip text={t('View')} position={KTTooltipPositions.auto}>
                                <i
                                  className="fa-regular fa-eye cursor-pointer me-3"
                                  onClick={() =>
                                    window.open(process.env.REACT_APP_BASE_URL + item.url)
                                  }
                                ></i>
                              </KTTooltip>
                            </div>
                            <KTTooltip text={t('Delete')} position={KTTooltipPositions.auto}>
                              <i
                                className="fa-regular fa-xmark cursor-pointer"
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  const res = await invoiceApi.deleteFileInvoice({
                                    invoiceAttachId: item?.invoiceAttachId,
                                  });
                                  if (res.result === 'success') {
                                    ToastHelper.showSuccess('Xóa tệp đính kèm thành công ');
                                    setLoading(Math.random());
                                    dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
                                  }
                                }}
                              ></i>
                            </KTTooltip>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <VIButton
                    text={t('AddAttachment')}
                    className="mt-3 btn btn-primary w-100"
                    beforeIcon={<i className="fa-regular fa-circle-plus"></i>}
                    onClick={() => document.getElementById('input-file').click()}
                  />
                  <input
                    type="file"
                    multiple
                    className="d-none"
                    id={'input-file'}
                    onChange={async (e) => {
                      if (e?.target?.files[0]?.size / 1048576 >= 5) {
                        ToastHelper.showError('Dung lượng tệp tải lên vượt quá 5MB');
                        e.target.value = null;
                      } else {
                        const form = new FormData();
                        for (var i = 0; i < e?.target?.files.length; i++) {
                          form.append('attachFiles', e?.target?.files[i]);
                        }
                        // console.log('ssss', e?.target?.files);
                        if (e?.target?.files?.length > 0) {
                          const result = await invoiceApi.upFileInvoice(dataDetail.invoiceId, form);
                          if (result.result === 'success') {
                            setLoading(Math.random());
                            dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>

              <div
                className="d-flex justify-content-between px-5 py-3 border-top position-fixed w-100"
                style={{
                  bottom: '0px',
                  boxShadow: '0px 5px 10px 0px rgba(44, 63, 88, 0.10)',
                  background: '#fff',
                }}
              >
                <div className="d-flex">
                  <VIButton
                    text={<i className="fa-regular fa-chevron-left"></i>}
                    className="btn-grey px-3 me-3"
                    disabled={dataNext === 0}
                    onClick={() => {
                      if (dataNext > 0) {
                        setDataNext(dataNext - 1);
                      }
                    }}
                  />
                  <VIButton
                    text={<i className="fa-regular fa-chevron-right"></i>}
                    className="btn-grey px-3 me-3"
                    disabled={listData?.length === dataNext + 1}
                    onClick={() => {
                      if (listData?.length > dataNext) setDataNext(dataNext + 1);
                    }}
                  />
                </div>
                <div className="d-flex">
                  <VIButton
                    text={'Kiểm tra lại'}
                    beforeIcon={<i className="fa-regular fa-list-check"></i>}
                    className="btn-white px-3 me-3"
                    onClick={() => {
                      handleCheck();
                    }}
                  />
                  <KTBSDropdown
                    contentEl={
                      <VIBodyIcons
                        IconArr={IconArr}
                        onClick={(x) => {
                          if (x.name === 'Ghi chú') {
                            setShowModalNoteInvoices(true);
                          }
                          if (x.name === 'Gán nhãn') {
                            setShowModalLabeled(true);
                            setListCurrentLabels(dataDetail?.Tags);
                          }
                          if (x.name === 'In hóa đơn') {
                            handlePrintInvoices();
                          }
                          if (x.name === 'Tải xuống') {
                            handleDownLoadInvoices();
                          }
                          if (x.name === 'In kết quả kiểm tra') {
                            handlePrintResult();
                          }
                        }}
                      />
                    }
                    toggleElement={
                      <div className="topbar-item">
                        <div className="mr-1 btn-white py-3 cursor-pointer px-6 d-flex align-items-center">
                          <i className="fa-regular fa-ellipsis-vertical"></i>
                        </div>
                      </div>
                    }
                    dropdownMenuClassName="w-auto"
                    alignment={KTBSDropdownAlignments.end}
                    direction={KTBSDropdownDirections.up}
                    autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <VIModal
          titleModal={t(`ListOfLinesOfBusiness`)}
          show={showListOfOccupations}
          onHide={() => setShowListOfOccupations(false)}
          contentModal={<ListOfOccupations taxCode={dataDetail?.sellerTaxCode} />}
          closeBtn={true}
          handleSubmit={() => {}}
          btnModal={false}
          modalSize="lg"
        />
        <VIModal
          show={modalCheckShowing}
          contentModal={
            <div className="d-flex flex-column align-items-center">
              <img src="https://inbotapp.misacdn.net/img/success-bot.9221cd17.png" />
              <p className="mt-2">Hệ thống đang kiểm tra và sẽ trả về kết quả sau ít phút</p>
            </div>
          }
          closeBtn
          handleSubmit={() => {}}
          onHide={() => {
            setModalCheckShowing(false);
          }}
          buttonPositive=""
          buttonNegative=""
        />

        <VIModal
          titleModal={t('Note')}
          show={showModalNoteInvoices}
          onHide={() => {
            setShowModalNoteInvoices(false);
          }}
          contentModal={
            <NoteInvoice
              handleTextArea={(e) => setDataNote(e)}
              dataInvoice={[dataDetail]}
              dataNote={dataNote}
            />
          }
          closeBtn={true}
          handleSubmit={() => {
            handleNoteInvoice();
          }}
          buttonNegative={t('Cancel')}
          buttonPositive={t('Save')}
        />

        <VIModal
          titleModal={t('Labeled')}
          show={showModalLabeled}
          onHide={() => {
            setShowModalLabeled(false), setListCurrentLabels([]);
          }}
          contentModal={
            <ModalLabeled
              handleDeleteLabel={handleDeleteLabel}
              setShowModalEditLabel={setShowModalEditLabel}
              setCurrentChangeLabel={setCurrentChangeLabel}
              setTextLabel={setTextLabel}
              setListCurrentLabels={setListCurrentLabels}
              listCurrentLabels={listCurrentLabels}
            />
          }
          closeBtn={true}
          handleSubmit={() => {
            if (!_.isEqual(listCurrentLabels, labelDetail)) {
              handleAddTagInvoices();
            }
          }}
          disableSubmit={_.isEqual(listCurrentLabels, labelDetail)}
          textButton={t('AddLabel')}
          onClickButtonAdd={() => {
            setShowModalAddLabeled(true);
            setTextLabel('AddLabel');
          }}
          style={
            showModalAddLabeled || showDeleteLabel || modalShowEditLabel
              ? { visibility: 'hidden' }
              : { visibility: 'visible' }
          }
          buttonPositive={t('Save')}
          buttonNegative={t('Cancel')}
        />
        <VIModal
          titleModal={t('RemoveTheLabel')}
          show={showModalDeleteLabel}
          onHide={() => {
            setShowModalDeleteLabel(false), setListCurrentLabels([]);
          }}
          contentModal={
            <ModalLabeled
              handleDeleteLabel={handleDeleteLabel}
              setCurrentChangeLabel={setCurrentChangeLabel}
              setListCurrentLabels={setListCurrentLabels}
              listCurrentLabels={listCurrentLabels}
            />
          }
          closeBtn={true}
          handleSubmit={() => {
            if (_.isEqual(listCurrentLabels, labelDetail)) {
              ToastHelper.showSuccess('Bạn chưa thay đổi nhãn');
            } else {
              // handleAddTagInvoices();
            }
          }}
          textClear={t('DeselectAll')}
          onClickButtonClear={() => {
            setListCurrentLabels([]);
          }}
          buttonPositive={t('Save')}
          buttonNegative={t('Cancel')}
        />
        <VIModal
          titleModal={t('AddLabel')}
          show={showModalAddLabeled}
          onHide={() => {
            setShowModalAddLabeled(false), formik.resetForm();
          }}
          contentModal={<AddLabel formik={formik} />}
          closeBtn={true}
          handleSubmit={() => {
            formik.handleSubmit();
          }}
          buttonPositive={t('Save')}
        />
        <VIModal
          titleModal={t('EditLabel')}
          show={modalShowEditLabel}
          onHide={() => {
            setShowModalEditLabel(false), formik.resetForm();
          }}
          contentModal={<AddLabel currentChangeLabel={currentChangeLabel} formik={formik} />}
          closeBtn={true}
          handleSubmit={() => {
            formik.handleSubmit();
          }}
          buttonPositive={t('Save')}
        />

        <VIModal
          titleModal={t('AcceptThisDigitalSignature')}
          show={modalShowAcceptSignNCC}
          onHide={async () => {
            setShowModalAcceptSignNCC(false);
          }}
          contentModal={<span>{t('DoYouWantToAcceptForAllSubsequentTimes')}</span>}
          closeBtn={true}
          handleSubmit={() => {}}
          buttonNegative={t('Cancel')}
          buttonPositive={t('Accept')}
        />
        <VIModal
          titleModal={t('AcceptThisDigitalSignature')}
          show={modalShowAcceptSignCQT}
          onHide={async () => {
            setShowModalAcceptSignCQT(false);
          }}
          contentModal={<span>{t('DoYouWantToAcceptForAllSubsequentTimes')}</span>}
          closeBtn={true}
          handleSubmit={() => {}}
          buttonNegative={t('Cancel')}
          buttonPositive={t('Accept')}
        />

        <VIModal
          titleModal={t('AcceptSdditionalBuyers')}
          show={modalShowAcceptNameBuyers}
          onHide={() => {
            setShowModalAcceptNameBuyers(false);
          }}
          contentModal={
            <ModalAccept
              name="Người mua"
              taxCode={dataDetail?.InvoiceValidate?.resultBuyerTaxCode}
              company={dataDetail?.InvoiceValidate?.resultBuyerName}
              address={dataDetail?.InvoiceValidate?.resultBuyerAddress}
              companyAccept={dataDetail?.buyerName}
              setDate={setDateNameBuyers}
              date={dateNameBuyers}
            />
          }
          closeBtn={true}
          handleSubmit={async () => {
            setLoadingAccept(true);
            const res = await invoiceApi.acceptPartnerCompany(dataDetail?.invoiceId, {
              from: dateNameBuyers?.fromDate,
              to: dateNameBuyers?.toDate,
              acceptName: dataDetail?.buyerName,
              acceptAddress: '',
              taxCode: dataDetail?.buyerTaxCode,
            });
            if (res?.result === 'success') {
              setLoadingAccept(false);
              ToastHelper.showSuccess(t('AcceptedBuyerNameSuccessfully'));
              setLoading(Math.random());
              dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
              setShowModalAcceptNameBuyers(false);
            }
          }}
          buttonNegative={t('Cancel')}
          buttonPositive={
            !loadingAccept ? (
              t('Save')
            ) : (
              <div className="d-flex align-items-center">
                {t('Save')}
                <div className="ms-2 ">
                  <VILoading size="sm" variant="white" />
                </div>
              </div>
            )
          }
          modalSize="lg"
        />
        <VIModal
          titleModal={t('AcceptSdditionalTaxCodeBuyers')}
          show={modalShowAcceptTaxCodeBuyers}
          onHide={() => {
            setShowModalAcceptTaxCodeBuyers(false);
          }}
          contentModal={
            <ModalAccept
              name="Mã số thuế"
              taxCode={dataDetail?.InvoiceValidate?.resultBuyerTaxCode}
              company={dataDetail?.InvoiceValidate?.resultBuyerName}
              address={dataDetail?.InvoiceValidate?.resultBuyerAddress}
              setDate={setDateTaxCodeBuyers}
              companyAccept={dataDetail?.buyerTaxCode}
              date={dateTaxCodeBuyers}
            />
          }
          closeBtn={true}
          handleSubmit={async () => {
            setLoadingAccept(true);
            const res = await invoiceApi.acceptPartnerCompany(dataDetail?.invoiceId, {
              from: dateTaxCodeBuyers?.fromDate,
              to: dateTaxCodeBuyers?.toDate,
              acceptName: '',
              acceptAddress: '',
              taxCode: dataDetail?.buyerTaxCode,
            });
            if (res?.result === 'success') {
              setLoadingAccept(false);
              dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
              ToastHelper.showSuccess(t('AcceptedBuyerTaxcodeSuccessfully'));
              setLoading(Math.random());
              setShowModalAcceptTaxCodeBuyers(false);
            }
          }}
          buttonNegative={t('Cancel')}
          buttonPositive={
            !loadingAccept ? (
              t('Save')
            ) : (
              <div className="d-flex align-items-center">
                {t('Save')}
                <div className="ms-2 ">
                  <VILoading size="sm" variant="white" />
                </div>
              </div>
            )
          }
          modalSize="lg"
        />

        <VIModal
          titleModal={t('AcceptSdditionalAddressBuyers')}
          show={modalShowAcceptAddressBuyers}
          onHide={() => {
            setShowModalAcceptAddressBuyers(false);
          }}
          contentModal={
            <ModalAccept
              name="Địa chỉ"
              taxCode={dataDetail?.InvoiceValidate?.resultBuyerTaxCode}
              company={dataDetail?.InvoiceValidate?.resultBuyerName}
              address={dataDetail?.InvoiceValidate?.resultBuyerAddress}
              companyAccept={dataDetail?.buyerAddress}
              setDate={setDateAddressBuyers}
              date={dateAddressBuyers}
            />
          }
          closeBtn={true}
          handleSubmit={async () => {
            setLoadingAccept(true);
            const res = await invoiceApi.acceptPartnerCompany(dataDetail?.invoiceId, {
              from: dateAddressBuyers?.fromDate,
              to: dateAddressBuyers?.toDate,
              acceptName: '',
              acceptAddress: dataDetail?.buyerAddress,
              taxCode: dataDetail?.buyerTaxCode,
            });
            if (res?.result === 'success') {
              setLoadingAccept(false);
              dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
              ToastHelper.showSuccess(t('AcceptedBuyerAddressSuccessfully'));
              setLoading(Math.random());
              setShowModalAcceptAddressBuyers(false);
            }
          }}
          buttonNegative={t('Cancel')}
          buttonPositive={
            !loadingAccept ? (
              t('Save')
            ) : (
              <div className="d-flex align-items-center">
                {t('Save')}
                <div className="ms-2 ">
                  <VILoading size="sm" variant="white" />
                </div>
              </div>
            )
          }
          modalSize="lg"
        />
        <VIModal
          titleModal={t('AcceptSdditionalSellers')}
          show={modalShowAcceptNameSellers}
          onHide={() => {
            setShowModalAcceptNameSellers(false);
          }}
          contentModal={
            <ModalAccept
              name="Người bán"
              taxCode={dataDetail?.sellerTaxCode}
              company={dataDetail?.InvoiceValidate?.resultSellerName}
              address={dataDetail?.InvoiceValidate?.resultSellerAddress}
              companyAccept={dataDetail?.sellerName}
              setDate={setDateNameSellers}
              date={dateNameSellers}
            />
          }
          closeBtn={true}
          handleSubmit={async () => {
            setLoadingAccept(true);
            const res = await invoiceApi.acceptPartnerCompany(dataDetail?.invoiceId, {
              from: dateNameSellers?.fromDate,
              to: dateNameSellers?.toDate,
              acceptName: dataDetail?.sellerName,
              acceptAddress: '',
              taxCode: dataDetail?.sellerTaxCode,
            });
            if (res?.result === 'success') {
              setLoadingAccept(false);
              dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
              ToastHelper.showSuccess(t('AcceptedSellerNameSuccessfully'));
              setLoading(Math.random());
              setShowModalAcceptNameSellers(false);
            }
          }}
          buttonNegative={t('Cancel')}
          buttonPositive={
            !loadingAccept ? (
              t('Save')
            ) : (
              <div className="d-flex align-items-center">
                {t('Save')}
                <div className="ms-2 ">
                  <VILoading size="sm" variant="white" />
                </div>
              </div>
            )
          }
          modalSize="lg"
        />

        <VIModal
          titleModal={t('AcceptSdditionalAddresSellers')}
          show={modalShowAcceptAddressSellers}
          onHide={() => {
            setShowModalAcceptAddressSellers(false);
          }}
          contentModal={
            <ModalAccept
              name="Địa chỉ"
              taxCode={dataDetail?.sellerTaxCode}
              company={dataDetail?.InvoiceValidate?.resultSellerName}
              address={dataDetail?.InvoiceValidate?.resultSellerAddress}
              companyAccept={dataDetail?.sellerAddress}
              setDate={setDateAddressSellers}
              date={dateAddressSellers}
            />
          }
          closeBtn={true}
          handleSubmit={async () => {
            setLoadingAccept(true);
            const res = await invoiceApi.acceptPartnerCompany(dataDetail?.invoiceId, {
              from: dateAddressSellers?.fromDate,
              to: dateAddressSellers?.toDate,
              acceptName: '',
              acceptAddress: dataDetail?.sellerAddress,
              taxCode: dataDetail?.sellerTaxCode,
            });
            if (res?.result === 'success') {
              setLoadingAccept(false);
              dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
              ToastHelper.showSuccess(t('AcceptedSellerAddressSuccessfully'));
              setLoading(Math.random());
              setShowModalAcceptAddressSellers(false);
            }
          }}
          buttonNegative={t('Cancel')}
          buttonPositive={
            !loadingAccept ? (
              t('Save')
            ) : (
              <div className="d-flex align-items-center">
                {t('Save')}
                <div className="ms-2 ">
                  <VILoading size="sm" variant="white" />
                </div>
              </div>
            )
          }
          modalSize="lg"
        />

        <VIModal
          titleModal={t('CheckInvoiceTaxRate')}
          show={modalShowCheckTaxRate}
          onHide={() => {
            setShowModalCheckTaxRate(false);
          }}
          contentModal={
            <CheckTaxRate invoice={dataDetail?.TaxReduceValidates ?? []} setLoading={setLoading} />
          }
          closeBtn={true}
          handleSubmit={async () => {}}
          modalSize="xl"
        />
        <VIModal
          titleModal={t('AcceptSdditionalTaxCodeSeller')}
          show={modalShowAcceptTaxCodeSellers}
          onHide={() => {
            setShowModalAcceptTaxCodeSellers(false);
          }}
          contentModal={
            <ModalAccept
              name="Mã số thuế"
              taxCode={dataDetail?.InvoiceValidate?.resultSellerTaxCode}
              company={dataDetail?.InvoiceValidate?.resultSellerName}
              address={dataDetail?.InvoiceValidate?.resultSellerAddress}
              setDate={setDateTaxCodeSeller}
              companyAccept={dataDetail?.sellerTaxCode}
              date={dateTaxCodeSeller}
            />
          }
          closeBtn={true}
          handleSubmit={async () => {
            setLoadingAccept(true);
            const res = await invoiceApi.acceptPartnerCompany(dataDetail?.invoiceId, {
              from: dateTaxCodeSeller?.fromDate,
              to: dateTaxCodeSeller?.toDate,
              acceptName: '',
              acceptAddress: '',
              taxCode: dataDetail?.sellerTaxCode,
            });
            if (res?.result === 'success') {
              setLoadingAccept(false);
              dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
              ToastHelper.showSuccess(t('Chấp nhận mã số thuế người bán thành công'));
              setLoading(Math.random());
              setShowModalAcceptTaxCodeBuyers(false);
            }
          }}
          buttonNegative={t('Cancel')}
          buttonPositive={
            !loadingAccept ? (
              t('Save')
            ) : (
              <div className="d-flex align-items-center">
                {t('Save')}
                <div className="ms-2 ">
                  <VILoading size="sm" variant="white" />
                </div>
              </div>
            )
          }
          modalSize="lg"
        />
      </Modal.Body>
    </Modal>
  );
}

export default VIModalResize;
