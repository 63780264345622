import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import './style.scss';
import VIFormCheck from '../VIFormCheck';
import { useTranslation } from 'react-i18next';

VISelectFilter.propTypes = {
  CloseDropdown: PropTypes.string,
  className: PropTypes.string,
  onChangeValue: PropTypes.func,
  disabled: PropTypes.bool,
  data: PropTypes.array,
  type: PropTypes.string,
  CurrentSelect: PropTypes.any,
  style: PropTypes.any,
};

VISelectFilter.defaultProps = {
  CloseDropdown: null,
  className: null,
  data: [],
  onChangeValue: null,
  disabled: false,
  type: '',
  CurrentSelect: null,
  style: null,
};

/**
 * @param {string} CloseDropdown Select CloseDropdown
 * @param {string} type Select type
 * @param {function} onChangeValue Handle when click button
 * @param {boolean} disabled
 * @param {array} data data array item
 *
 */

function VISelectFilter(props) {
  const { CloseDropdown, onChangeValue, disabled, data, type, CurrentSelect, className, style } =
    props;
  const { t } = useTranslation();
  let textRender = '';
  const [valueRender, setValueRender] = useState(CurrentSelect ? CurrentSelect : data[0]?.value);

  function clicked(e) {
    setValueRender(e?.value);
    if (onChangeValue) {
      onChangeValue(e);
    }
    // setTextRender(e?.name);
  }
  for (let i = 0; i < data?.length; i++) {
    if (data[i]?.value === valueRender) {
      textRender = data[i]?.name;
    }
  }

  useEffect(() => {
    setValueRender(CurrentSelect ? CurrentSelect : data[0]?.value);
    // setTextRender(data[0]?.name);
  }, [data, CurrentSelect]);
  const [textCheckBoxRender, setTextCheckBoxRender] = useState([]);
  const handleChange = (e, item) => {
    if (e?.target.checked) {
      setTextCheckBoxRender([...textCheckBoxRender, item]);
    } else {
      setTextCheckBoxRender(textCheckBoxRender?.filter((i) => i !== item));
    }
  };
  return (
    <div className="VISelect-filter">
      <Dropdown>
        <Dropdown.Toggle
          variant="success"
          id={`dropdown-basic${CloseDropdown}`}
          className={`position-relative ${className} d-flex - justify-content-between align-items-center`}
          disabled={disabled}
          style={style}
        >
          {type === 'CheckBox' ? (
            <div className="render-checkbox">
              {textCheckBoxRender?.length === 0 ? (
                <div className="font-size-lg d-flex align-items-center">{t('All')}</div>
              ) : (
                <>
                  {textCheckBoxRender?.map((ele, ind) => (
                    <div className="font-size-lg d-flex align-items-center" key={ind}>
                      {ele + `${ind < textCheckBoxRender?.length - 1 ? ',' : ''}`}
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : (
            <span
              className="font-size-lg d-flex align-items-center "
              style={{ minWidth: 'max-content' }}
            >
              {textRender}
            </span>
          )}
          <i
            className="far fa-angle-down font-size-xs text-secondary icons-down d-flex align-items-center ms-2"
            style={{ marginTop: '1px' }}
            onClick={() => document.getElementById(`dropdown-basic${CloseDropdown}`).click()}
          ></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div style={{ maxHeight: '30vh', overflowX: 'hidden', overflowY: 'auto' }}>
            {data?.map((item, index) => (
              <Fragment key={index}>
                {type === 'CheckBox' ? (
                  <div className="Checkbox-items px-3 py-1">
                    <VIFormCheck
                      name={item?.name}
                      id={item?.name}
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                ) : (
                  <Dropdown.Item
                    className="p-0"
                    onClick={(e) => {
                      e.stopPropagation(),
                        clicked(item),
                        document.getElementById(`dropdown-basic${CloseDropdown}`).click();
                    }}
                  >
                    <div className="d-flex align-items-center font-size-lg justify-content-between px-3 py-2 mx-2 me-5 items-name">
                      <span className="me-4">{item?.name}</span>
                      {valueRender === item?.value && (
                        <i className="fas fa-check text-primary "></i>
                      )}
                    </div>
                  </Dropdown.Item>
                )}
              </Fragment>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default VISelectFilter;
