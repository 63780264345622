import { unwrapResult } from '@reduxjs/toolkit';
import {
  thunkCreateContactCustomer,
  thunkGetAppConfigs,
  thunkGetTopCustomerComments,
} from 'app/appSlice';
import store from 'app/store';

const AppHelper = {
  // goi api tao thong tin lien he
  callApiCreateContactCustomer: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkCreateContactCustomer(params)));
      const { result, contact } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay top binh luan cua khach hang
  callApiGetTopCustomerComments: async () => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetTopCustomerComments({ display: 1 })));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // get api lay cau hinh
  callApiGetAppConfigs: async () => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetAppConfigs()));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
};

export default AppHelper;
