import paymentApi from 'api/paymentApi';
import customDataTableStyle from 'assets/styles/customDataTableStyle';
import LandingPageFooter from 'features/LandingPage/components/LandingPageFooter';
import EmptyView from 'features/Others/components/EmptyView';
import HeaderStore from 'features/Payment/components/HeaderStore';
import { setSelectedOrderInfo } from 'features/Payment/paymentSlice';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ClockLoader } from 'react-spinners';

OrderInfoScreen.propTypes = {};

function OrderInfoScreen(props) {
  // ---- params ----
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.payment.selectedOrderInfo);
  const orderStatus = order?.statusOrder;
  const paymentType = order?.typePayment;
  const orderItems = order?.OrderItems;
  const totalQuantityBuyMore = orderItems?.reduce((sum, item) => {
    return sum + item.package?.quantity * item.quantity;
  }, 0);
  const columns = useMemo(() => {
    return [
      {
        name: t('PackageOrService'),
        sortable: false,
        minWidth: '220px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.package?.packageCode}
            </p>
          );
        },
      },
      {
        name: t('Unit'),
        sortable: false,
        width: '90px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.package?.unit}
            </p>
          );
        },
      },
      {
        name: t('Quantity'),
        sortable: false,
        width: '120px',
        right: true,
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.quantity}
            </p>
          );
        },
      },
      {
        name: t('Price'),
        sortable: false,
        width: '140px',
        right: true,
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {Utils.formatCurrency(row?.package?.packageCost)}
            </p>
          );
        },
      },
      {
        name: t('LastPrice'),
        sortable: false,
        width: '140px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {Utils.formatCurrency(row?.package?.packageCost * row?.quantity)}
            </p>
          );
        },
      },
    ];
  }, [t]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setSelectedOrderInfo(null));
    };
  }, []);

  // ---- methods ----
  async function handlePayment() {
    const orderId = order?.orderId;
    try {
      setLoading(true);
      const res = await paymentApi.repayment(orderId);
      const { result, vnpayUrl } = res;
      if (result === 'success' && vnpayUrl) {
        Utils.openInCurrentTab(vnpayUrl);
      }
    } catch (error) {
      console.log({ error });
    }
    setLoading(false);
  }

  return (
    <div>
      <div className="min-vh-100 d-flex flex-column">
        {/* header */}
        <HeaderStore additionalClassName="bg-white" />

        {/* body */}
        <div className="flex-grow-1">
          <div
            className="container-xl py-10 d-flex flex-column align-items-center justify-content-center bg-white my-6"
            style={{
              borderRadius: '16px',
            }}
          >
            <div className="d-flex flex-column gap-4">
              <img className="h-50px" src={AppResource.icons.icLogoQuanLyHoaDon} alt="logo" />
              <div className="d-flex flex-column align-items-center gap-2">
                <h3 className="text-grey font-weight-bolder mb-0">Đơn hàng đang chờ thanh toán</h3>
                <div className="d-flex align-items-center justify-content-center">
                  {t('OrderCode')}:&nbsp;
                  <span className="font-weight-bolder">{order?.orderCode}</span>
                </div>
              </div>
              <div className="px-10">
                <span className="font-weight-bolder">Lưu ý</span>: Vui lòng đọc kĩ đơn hàng, nếu
                thông tin đơn hàng đã đầy đủ và chính xác vui lòng bấm{' '}
                <span className="font-weight-bolder">Thanh toán</span> để hoàn tất thủ tục, lấy mã
                và bắt đầu sử dụng phần mềm
              </div>
              <div className="d-flex align-items-center flex-column">
                <VIButton
                  beforeIcon={
                    loading ? (
                      <ClockLoader color="white" size={20} />
                    ) : (
                      <i className="fa-regular fa-money-bill" />
                    )
                  }
                  text={t('Payment')}
                  className="btn-blue"
                  style={{ padding: '8px' }}
                  onClick={handlePayment}
                />
              </div>

              {/* chi tiet don hang */}
              <div>
                <h5 className="text-grey font-weight-bolder mb-0">{t('OrderInfo')}</h5>
                <VIDataTable
                  draggable={false}
                  tableStyles={customDataTableStyle}
                  additionalClassName="p-0 shadow-none"
                  additionalCardBodyClassName="p-0"
                  selectable={false}
                  pagination={null}
                  onChangePage={(page) => {}}
                  onChangeRowsPerPage={(perPage) => {}}
                  fullColumns={columns}
                  data={orderItems}
                  loading={false}
                  onRowClick={(row) => {}}
                  onSetSelectedMultiItems={(rows) => {}}
                  haveCardHeader={false}
                  emptyComponent={
                    <EmptyView
                      message={t('OrderDetailEmpty')}
                      image={AppResource.images.imgEmptyPayment}
                    />
                  }
                />

                <div className="d-flex justify-content-between flex-wrap align-items-start mt-6">
                  <div>
                    {t('PaymentType')}:{' '}
                    <span className="font-weight-bolder">
                      {t(
                        _.find(AppData.paymentTypeItems, (item) => item.value === paymentType)?.name
                      )}
                    </span>
                  </div>

                  <div>
                    {/* tong cong */}
                    <div className="">
                      <div className="d-flex pb-4 align-items-start justify-content-between gap-4">
                        <div className="">{t('TotalPrice')}</div>
                        <div className="font-size-lg text-grey">
                          {Utils.formatCurrency(order?.totalCost ?? 0)} {'VND'}
                        </div>
                      </div>
                    </div>
                    {/* khuyen mai */}
                    <div className="">
                      <div className="d-flex pb-4 align-items-start justify-content-between gap-4">
                        <div className="d-flex align-items-center gap-2">
                          {t('Promotion')} {/* {selectedVoucher && ( */}
                          {order?.voucherCode && (
                            <KTTooltip
                              text={
                                <div className="d-flex flex-column align-items-start">
                                  <p className="m-0">
                                    <span>{t('VoucherCode')}:</span>{' '}
                                    <span className="font-weight-bolder">{order?.voucherCode}</span>
                                  </p>
                                  {/* <p className="m-0">
                        {t('VoucherName')}: {'voucherName'}
                      </p> */}
                                </div>
                              }
                            >
                              <i className="fa-regular fa-circle-question text-primary" />
                            </KTTooltip>
                          )}
                          {/* )} */}
                        </div>
                        <div className="font-size-lg text-grey">
                          {Utils.formatCurrency(order?.promotion ?? 0)} {'VND'}
                        </div>
                      </div>
                    </div>
                    {/* tong phai tra */}
                    <div className="">
                      <div className="d-flex pb-0 align-items-start justify-content-between gap-4">
                        <div className="">{t('TotalPricePay')}</div>
                        <div className="d-flex flex-column align-items-end">
                          <span className="font-size-lg text-grey font-weight-bolder">
                            {Utils.formatCurrency(order?.totalPayment ?? 0)} {'VND'}
                          </span>{' '}
                          <span>({t('IncludedVAT')})</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* thong tin tai nguyen */}
              <div>
                <h5 className="text-grey font-weight-bolder mb-2">Thông tin tài nguyên</h5>
                <div className="">
                  <div className="d-flex flex-column gap-2">
                    <p className="text-grey font-weight-bolder mb-0 font-size-lg">
                      ICORP Invoice - Hóa đơn đầu vào
                    </p>
                    <div className="d-flex gap-4">
                      <div className="d-flex flex-column gap-2">
                        <p className="mb-0">{t('ExpireDate')}:</p>
                        <p className="mb-0">{t('Resource')}:</p>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <p className="font-weight-bold mb-0">{'--/--/----'}&nbsp;</p>
                        <p className="font-weight-bold mb-0">
                          {Utils.formatNumber(totalQuantityBuyMore)}&nbsp;
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* thong tin doi tuong su dung */}
              <div>
                <h5 className="text-grey font-weight-bolder mb-2">Thông tin đối tượng sử dụng</h5>
                <div className="d-flex gap-4">
                  <div className="d-flex flex-column gap-2">
                    <p className="mb-0">Tên doanh nghiệp:</p>
                    <p className="mb-0">Mã số thuế:</p>
                    <p className="mb-0">Người mua hàng:</p>
                    <p className="mb-0">Điện thoại:</p>
                    <p className="mb-0">Email:</p>
                    <p className="mb-0">Địa chỉ:</p>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <p className="font-weight-bold mb-0">{order?.companyName}&nbsp;</p>
                    <p className="font-weight-bold mb-0">{order?.taxCode}&nbsp;</p>
                    <p className="font-weight-bold mb-0">{order?.orderName}&nbsp;</p>
                    <p className="font-weight-bold mb-0">{order?.orderPhone}&nbsp;</p>
                    <p className="font-weight-bold mb-0">{order?.orderEmail}&nbsp;</p>
                    <p className="font-weight-bold mb-0">{order?.address}&nbsp;</p>
                  </div>
                </div>
              </div>

              {/* thong tin cap hoa don tai chinh */}
              <div>
                <h5 className="text-grey font-weight-bolder mb-2">
                  Thông tin cấp hóa đơn tài chính
                </h5>
                <div className="d-flex gap-4">
                  <div className="d-flex flex-column gap-2">
                    <p className="mb-0">Tên doanh nghiệp:</p>
                    <p className="mb-0">Mã số thuế:</p>
                    <p className="mb-0">Người nhận hóa đơn:</p>
                    <p className="mb-0">Email nhận hóa đơn:</p>
                    <p className="mb-0">Địa chỉ cấp hóa đơn:</p>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <p className="font-weight-bold mb-0">{order?.companyName}&nbsp;</p>
                    <p className="font-weight-bold mb-0">{order?.taxCode}&nbsp;</p>
                    <p className="font-weight-bold mb-0">{order?.invoiceCustomerName}&nbsp;</p>
                    <p className="font-weight-bold mb-0">{order?.invoiceCustomerEmail}&nbsp;</p>
                    <p className="font-weight-bold mb-0">{order?.invoiceCustomerAddress}&nbsp;</p>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <VIButton
                  beforeIcon={
                    loading ? (
                      <ClockLoader color="white" size={20} />
                    ) : (
                      <i className="fa-regular fa-money-bill" />
                    )
                  }
                  text={t('Payment')}
                  className="btn-blue"
                  style={{ padding: '8px' }}
                  onClick={handlePayment}
                />
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <LandingPageFooter />
      </div>
    </div>
  );
}

export default OrderInfoScreen;
