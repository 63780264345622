import { unwrapResult } from '@reduxjs/toolkit';
import paymentApi from 'api/paymentApi';
import store from 'app/store';
import {
  thunkCancelOrder,
  thunkCreateOrder,
  thunkGetServicePackList,
  thunkGetVoucherList,
} from 'features/Payment/paymentSlice';
import AppData from 'general/constants/AppData';
import Global from 'general/utils/Global';
import SystemHelper from './SystemHelper';

const PaymentHelper = {
  // goi api lay danh sach goi dich vu
  callApiGetServicePackList: async (params = {}) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetServicePackList(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay danh sach vouchers
  callApiGetVoucherList: async (params = {}) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetVoucherList(params)));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api tim voucher theo code
  callApiFindVoucherByCode: async (code) => {
    try {
      const res = await paymentApi.findVoucherByCode(code);
      const { result, voucher } = res;
      if (result === 'success' && voucher) {
        return voucher;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  // goi api tao don hang
  callApiCreateOrder: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkCreateOrder(params)));
      const { result, order, vnpayUrl } = res;
      if (result === 'success' && order) {
        SystemHelper.callApiGetPaymentHistory(Global.gFilterPaymentHistory);
        return { ...order, vnpayUrl };
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  // goi api huy don hang
  callApiCancelOrder: async (orderId) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkCancelOrder(orderId)));
      const { result } = res;
      if (result === 'success') {
        SystemHelper.callApiGetPaymentHistory(Global.gFilterPaymentHistory);
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  // goi api gui lai email thanh toan
  callApiResendEmailPayment: async (orderId) => {
    try {
      const res = await paymentApi.resendEmailConfirmPayment(orderId);
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  // tao ma don hang
  genClientOrderCode: (taxCode) => {
    const uniqString = new Date().getTime();
    return `DH${uniqString}-MST${taxCode}`;
  },

  getPaymentStatusColor: (status) => {
    switch (status) {
      case AppData.paymentStatus.pending:
        return '#E18700';
      case AppData.paymentStatus.completed:
        return '#28A043';
      case AppData.paymentStatus.canceled:
      case AppData.paymentStatus.failed:
        return '#EE2334';
      default:
        return '#7177A8';
    }
  },
};

export default PaymentHelper;
