import supplierApi from 'api/supplierApi';
import customDataTableStyle from 'assets/styles/customDataTableStyle';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import AppResource from 'general/constants/AppResource';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

ViewConnectionSupplierHistoryDetail.propTypes = {
  item: PropTypes.object,
  onPressCancel: PropTypes.func,
};

ViewConnectionSupplierHistoryDetail.defaultProps = {
  item: null,
  onPressCancel: null,
};

/**
 *
 * @param {{
 * item: object,
 * onPressCancel: function,
 * }} props
 * @returns
 */
function ViewConnectionSupplierHistoryDetail(props) {
  // ---- params ----
  const { item, onPressCancel } = props;
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    limit: Global.gDefaultPagination,
    page: 0,
    q: '',
  });
  const [total, setTotal] = useState(0);
  const [connectionSupplierInvoices, setConnectionSupplierInvoices] = useState([]);
  const columns = useMemo(
    () => [
      {
        name: t('Supplier'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-bolder m-0 text-maxline-3">
              {row?.ConnectionSupplier?.Supplier?.companyName}
            </p>
          );
        },
      },
      {
        name: t('InvoiceNo'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.invoiceNumber ?? ''}
            </p>
          );
        },
      },
      {
        name: t('Denominator'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.invoiceTypeCode ?? ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceSymbol'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.serial ?? ''}
            </p>
          );
        },
      },
      {
        name: t('State'),
        minWidth: '120px',
        style: {},
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.issueStatus ?? ''}
            </p>
          );
        },
      },
      {
        name: 'Tải hoá đơn gốc',
        width: '100px',
        center: true,
        style: {},
        cell: (row) => {
          return (
            <div className="d-flex align-items-center gap-3">
              {row?.pdfFile && (
                <KTTooltip text={'Tải xuống'}>
                  <img
                    src={AppResource.icons.IcPdfAvailable}
                    className=""
                    onClick={() => {
                      Utils.download(Utils.getFullUrl(row?.pdfFile), 'invoice.pdf');
                    }}
                  />
                </KTTooltip>
              )}
              {row?.originXmlFile && (
                <KTTooltip text={'Tải xuống'}>
                  <img
                    src={AppResource.icons.IcXmlAvailable}
                    className=""
                    onClick={() => {
                      Utils.download(Utils.getFullUrl(row?.originXmlFile), 'invoice.xml');
                    }}
                  />
                </KTTooltip>
              )}
            </div>
          );
        },
      },
    ],
    [t]
  );

  useEffect(() => {
    const id = item?.connectionSupplierHistoryId;
    if (id) {
      requestGetConnectionSupplierHistory(id, filters);
    }
  }, [item, filters]);

  // ---- methods ----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  async function requestGetConnectionSupplierHistory(id, params) {
    try {
      const res = await supplierApi.getConnectionSupplierHistoryDetail(id, params);
      const { result, connectionSupplierInvoices, total } = res;
      // console.log(connectionSupplierInvoices);
      if (result === 'success' && connectionSupplierInvoices) {
        setConnectionSupplierInvoices(connectionSupplierInvoices);
        setTotal(total);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap overflow-auto"
        style={{
          maxHeight: '70vh',
        }}
      >
        <VISearchField
          placeholder={`${t('EnterToSearch')}`}
          inputClassName="w-auto"
          value={filters.q}
          className=""
          typingTimeout={500}
          onSubmit={(newValue) => {
            setFilters({
              ...filters,
              q: newValue,
              page: 0,
            });
          }}
        />

        <div className="d-flex align-items-center gap-5 flex-wrap">
          <p className="mb-0">
            Hoá đơn có mã: <span className="font-weight-bolder">{item?.hasMaCQT}</span>
          </p>
          <p className="mb-0">
            Hoá đơn không mã:{' '}
            <span className="font-weight-bolder">{item?.total - item?.hasMaCQT}</span>
          </p>
        </div>
      </div>
      <div
        className="mt-4"
        style={
          {
            // maxHeight: '70vh',
          }
        }
      >
        <VIDataTable
          haveCardHeader={false}
          additionalCardBodyClassName="p-0"
          additionalClassName="shadow-none rounded-0"
          tableStyles={customDataTableStyle}
          minusHeight={95}
          draggable={false}
          pagination={{
            perPage: filters.limit,
            total: total,
            currentPage: filters.page + 1,
            count: connectionSupplierInvoices?.length,
          }}
          onChangePage={(page) => {
            let iNewPage = parseInt(page);
            iNewPage -= 1;
            if (iNewPage < 0) {
              iNewPage = 0;
            }
            setFilters({
              ...filters,
              page: iNewPage,
            });
          }}
          onChangeRowsPerPage={(perPage) => {
            setFilters({
              ...filters,
              limit: perPage,
              page: 0,
            });
          }}
          fullColumns={columns}
          data={connectionSupplierInvoices}
          loading={false}
          onRowClick={(row) => {}}
        />
      </div>
      {/* actions */}
      {/* <div className="d-flex align-items-center justify-content-end gap-3">
        <VIButton
          text={t('Cancel')}
          className="btn-grey"
          style={{ padding: '8px' }}
          onClick={handlePressCancel}
        />
      </div> */}
    </div>
  );
}

export default ViewConnectionSupplierHistoryDetail;
