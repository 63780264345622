import axiosClient from './axiosClient';

const notificationApi = {
  // lay danh sach thong bao
  find: (params) => {
    const url = '/notification/find';
    return axiosClient.get(url, { params });
  },
};

export default notificationApi;
