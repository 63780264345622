import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import ExcelJS from 'exceljs';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import KeenSelectOption from 'general/components/OtherKeenComponents/KeenSelectOption';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIElements from 'general/components/VietInvoice/VIElements';
import VIModal from 'general/components/VietInvoice/VIModal';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import CategoryHelper from 'general/helpers/CategoryHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ViewEditSupplier from '../../components/ViewEditSupplier';
import ViewImportSupplier from '../../components/ViewImportSupplier';

function SupplierAndCustomerScreen(props) {
  // MARK --- Params ---
  const { t } = useTranslation();
  const [filters, setFilters] = useState(Global.gFiltersSupplierAndCustomer);
  const { supplierAndCustomers, isGettingSupplierAndCustomers, total } = useSelector(
    (state) => state?.category
  );
  const account = useAccount();
  const columns = useMemo(() => {
    return [
      {
        name: t('UnitCode'),
        width: '150px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.unitCode}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        width: '160px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.taxCode}
            </p>
          );
        },
      },
      {
        name: t('Supplier/Customer'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.partnerCompanyName}
            </p>
          );
        },
      },
      {
        name: t('Address'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="q-max-line-3 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.address}
            </p>
          );
        },
      },
      {
        name: t('Company'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3 mr-4">
              {/* {row?.organizationId && account?.organizationType === AppData.organizationType.company
                ? account.organizationName
                : ''} */}
              {account.organizationHQ?.organizationName ?? ''}
            </p>
          );
        },
      },
      {
        name: t('Branch'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {/* {row?.organizationId && account?.organizationType === AppData.organizationType.branch
                ? account.organizationName
                : ''} */}
              {account.organizationBRANCH?.organizationName ?? ''}
            </p>
          );
        },
      },
      {
        name: t('Department'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {/* {row?.organizationDepartmentId ? account.organizationName : ''} */}
              {account.organizationDepartment?.departmentName ?? ''}
            </p>
          );
        },
      },
      {
        name: t('CustomerType'),
        minWidth: '180px',
        cell: (row, index) => {
          const objInfo = CategoryHelper.getCustomerTypeInfo(row);
          return (
            <div>
              <VIElements
                id={`customer_type_${index}`}
                text={objInfo.text}
                style={{
                  color: objInfo.color,
                  backgroundColor: objInfo.bgColor,
                  padding: '4px 8px',
                  border: 'none',
                }}
                // icon={
                //   <i
                //     className={`fas ${isRisky ? 'fa-times-circle' : 'fa-check-circle'}`}
                //     style={{ color: isRisky ? '#EE2334' : '#20A144' }}
                //   ></i>
                // }
              />
            </div>
          );
        },
      },
      {
        name: t('InInvoice'),
        width: '160px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {Utils.formatNumber(row?.buyInvoices)}
            </p>
          );
        },
      },
      {
        name: t('OutInvoice'),
        width: '160px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {Utils.formatNumber(row?.sellInvoices)}
            </p>
          );
        },
      },
      {
        name: `${t('PaymentDuration')} (${t('Day')})`,
        width: '240px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {Utils.formatNumber(row?.expirePayment)}
            </p>
          );
        },
      },
      {
        name: t('AccountNumber'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.bankAccountNumber}
            </p>
          );
        },
      },
      {
        name: t('AccountName'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.bankAccountName}
            </p>
          );
        },
      },
      {
        name: t('BankName'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.bank}
            </p>
          );
        },
      },
      {
        name: t('BankBranch'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.bankBranch}
            </p>
          );
        },
      },
      {
        name: t('ContactPerson'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.contactName}
            </p>
          );
        },
      },
      {
        name: t('ContactEmail'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.contactEmail}
            </p>
          );
        },
      },
      {
        name: t('ContactPhone'),
        width: '140px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.contactPhone}
            </p>
          );
        },
      },
      {
        name: t('ContactPosition'),
        minWidth: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.contactPosition}
            </p>
          );
        },
      },
      {
        name: t('CheckedDate'),
        width: '140px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.dateCheck ? Utils.formatDateTime(row?.dateCheck, 'DD/MM/YYYY') : ''}
            </p>
          );
        },
      },
      {
        name: t('State'),
        width: '140px',
        center: true,
        style: {
          borderRight: 0,
        },
        cell: (row) => {
          return (
            <KTTooltip
              text={
                row?.nntStatus ??
                t(_.find(AppData.supplierStatusItems, (item) => item.value === row?.status)?.name)
              }
            >
              <i className={CategoryHelper.getIconStatus(row?.status)} />
            </KTTooltip>
          );
        },
      },
      {
        name: t('InvoiceRisk'),
        width: '120px',
        style: {
          borderRight: 'none',
        },
        cell: (row, index) => {
          const isRisky = row?.isRisky ?? false;
          return (
            <div data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              <VIElements
                id={`invoice_risk_${index}`}
                text={isRisky ? 'Có' : 'Không'}
                style={{
                  color: isRisky ? '#EE2334' : '#20A144',
                  backgroundColor: isRisky ? 'rgba(255, 87, 87, 0.10)' : 'rgba(40, 160, 67, 0.1)',
                  padding: '4px 8px',
                  border: 'none',
                }}
                icon={
                  <i
                    className={`fas ${isRisky ? 'fa-times-circle' : 'fa-check-circle'}`}
                    style={{ color: isRisky ? '#EE2334' : '#20A144' }}
                  ></i>
                }
              />
            </div>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            <KTTooltip text={t('Edit')}>
              <i
                className="fa-regular fa-edit text-primary"
                onClick={() => {
                  handleEdit(row);
                }}
              />
            </KTTooltip>
          </div>
        ),
      },
    ];
  }, [t, account]);
  const [modalCheckShowing, setModalCheckShowing] = useState(false);
  const [modalEditShowing, setModalEditShowing] = useState(false);
  const [currentSelectedSupplier, setCurrentSelectedSupplier] = useState(null);
  const [modalImportShowing, setModalImportShowing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    CategoryHelper.callApiGetSupplierCustomerList(filters);
  }, [filters]);

  // ---- methods -----
  function handleEdit(item) {
    setCurrentSelectedSupplier(item);
    setModalEditShowing(true);
  }

  async function handleCheck() {
    setModalCheckShowing(true);
    setTimeout(() => {
      setModalCheckShowing(false);
    }, 3000);
    const res = await CategoryHelper.callApiCheckSupplierCustomer();
    if (res) {
    }
  }

  function handleImport() {
    setModalImportShowing(true);
  }

  function handleExport() {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(t('Danh_sach_NCC_Khach_hang'), {
      headerFooter: { firstHeader: 'Danh sách Nhà cung cấp/Khách hàng' },
    });

    sheet.columns = [
      {
        header: t('UnitCode'),
        key: 'unitCode',
        width: 20,
      },
      { header: t('TaxCode'), key: 'taxCode', width: 20 },
      {
        header: t('Supplier/Customer'),
        key: 'partnerCompanyName',
        width: 80,
      },
      {
        header: t('Address'),
        key: 'address',
        width: 60,
      },
      {
        header: t('Company'),
        key: 'company',
        width: 50,
      },
      {
        header: t('Branch'),
        key: 'branch',
        width: 50,
      },
      {
        header: t('Department'),
        key: 'department',
        width: 50,
      },
      {
        header: t('CustomerType'),
        key: 'customerType',
        width: 20,
      },
      {
        header: t('InInvoice'),
        key: 'buyInvoices',
        width: 20,
      },
      {
        header: t('OutInvoice'),
        key: 'sellInvoices',
        width: 20,
      },
      {
        header: `${t('PaymentDuration')} (${t('Day')})`,
        key: 'expirePayment',
        width: 30,
      },
      {
        header: t('AccountNumber'),
        key: 'bankAccountNumber',
        width: 20,
      },
      {
        header: t('AccountName'),
        key: 'bankAccountName',
        width: 60,
      },
      {
        header: t('BankName'),
        key: 'bank',
        width: 30,
      },
      {
        header: t('BankBranch'),
        key: 'bankBranch',
        width: 30,
      },
      {
        header: t('ContactPerson'),
        key: 'contactName',
        width: 30,
      },
      {
        header: t('ContactEmail'),
        key: 'contactEmail',
        width: 30,
      },
      {
        header: t('ContactPhone'),
        key: 'contactPhone',
        width: 30,
      },
      {
        header: t('ContactPosition'),
        key: 'contactPosition',
        width: 30,
      },
      {
        header: t('CheckedDate'),
        key: 'dateCheck',
        width: 20,
      },
      {
        header: t('State'),
        key: 'status',
        width: 20,
      },
      {
        header: t('InvoiceRisk'),
        key: 'isRisky',
        width: 30,
      },
    ];

    const promise = Promise.all(
      supplierAndCustomers.concat([{}]).map(async (row, index) => {
        const rowNumber = index + 1;
        sheet.getRow(rowNumber).border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };

        sheet.getRow(rowNumber).font = {
          name: 'Times New Roman',
          family: 4,
          size: rowNumber > 1 ? 11 : 14,
          bold: rowNumber > 1 ? false : true,
        };

        sheet.getRow(1).alignment = { vertical: 'center', horizontal: 'center' };
        sheet.getColumn(rowNumber).alignment = {
          vertical: rowNumber > 8 && rowNumber < 12 ? 'right' : 'left',
          horizontal: rowNumber > 8 && rowNumber < 12 ? 'right' : 'left',
        };
        sheet.addRow({
          unitCode: row?.unitCode,
          taxCode: row?.taxCode,
          partnerCompanyName: row?.partnerCompanyName,
          company:
            row?.organizationId && account?.organizationType === AppData.organizationType.company
              ? account.organizationName
              : '',
          branch:
            row?.organizationId && account?.organizationType === AppData.organizationType.branch
              ? account.organizationName
              : '',
          department: row?.organizationDepartmentId ? account.organizationName : '',
          customerType: CategoryHelper.getCustomerTypeInfo(row).text,
          buyInvoices: isNaN(Utils.formatNumber(row?.buyInvoices))
            ? ''
            : Utils.formatNumber(row?.buyInvoices),
          sellInvoices: isNaN(Utils.formatNumber(row?.sellInvoices))
            ? ''
            : Utils.formatNumber(row?.sellInvoices),
          expirePayment: isNaN(Utils.formatNumber(row?.expirePayment))
            ? ''
            : Utils.formatNumber(row?.expirePayment),
          bankAccountNumber: row?.bankAccountNumber,
          bankAccountName: row?.bankAccountName,
          bank: row?.bank,
          address: row?.address,
          bankBranch: row?.bankBranch,
          contactName: row?.contactName,
          contactEmail: row?.contactEmail,
          contactPhone: row?.contactPhone,
          contactPosition: row?.contactPosition,
          dateCheck: row?.dateCheck ? Utils.formatDateTime(row?.dateCheck, 'DD/MM/YYYY') : '',
          status:
            row?.nntStatus ??
            t(_.find(AppData.supplierStatusItems, (item) => item.value === row?.status)?.name),
          isRisky: row?.isRisky === true ? 'Có' : row?.isRisky === false ? 'Không' : '',
        });
      })
    );

    promise.then(() => {
      const priceCol = sheet.getColumn(22);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        // console.log(cell?.value);
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling

        sheet.getCell(cell?.address).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: cellValue === 'Có' ? 'EE2334' : cellValue === 'Không' ? '20A144' : 'FFFFFF',
          },
        };
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'Danh_sach_NCC_Khach_hang.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  }

  // duc tu
  // column mặc định khi reload
  const defaultColumns = useMemo(() => {
    let columnsLocal = [];
    for (
      let i = 0;
      i <
      JSON.parse(localStorage.getItem(PreferenceKeys.saveColumnNCC))?.[account.accountId]?.length;
      i++
    ) {
      for (let j = 0; j < columns.length; j++) {
        if (
          JSON.parse(localStorage.getItem(PreferenceKeys.saveColumnNCC))?.[account.accountId][i] ===
          columns[j]?.name
        ) {
          columnsLocal.push(columns[j]);
        }
      }
    }
    if (columnsLocal.length > 0) {
      return columnsLocal;
    } else {
      return columns.filter((item) => Global[PreferenceKeys.saveColumnNCC]?.includes(item.name));
      // .concat(columns[columns.length - 1]);
    }
  }, [JSON.parse(localStorage.getItem(PreferenceKeys.saveColumnNCC))?.[account.accountId]]);

  return (
    <div className="">
      <div
        className="bg-white border overflow-hidden"
        style={{
          borderRadius: '20px',
        }}
      >
        <VIDataTable
          // minusHeight={65}
          defaultColumns={defaultColumns}
          title={t('Supplier/Customer')}
          keyDefaultColumn={PreferenceKeys.saveColumnNCC}
          selectable={false}
          pagination={{
            perPage: filters.limit,
            total: total,
            currentPage: filters.page + 1,
            count: supplierAndCustomers?.length + filters.limit * filters.page,
          }}
          tableStyles={customDataTableActionStyle}
          additionalClassName="rounded-0"
          onChangePage={(page) => {
            let iNewPage = parseInt(page);
            iNewPage -= 1;
            if (iNewPage < 0) {
              iNewPage = 0;
            }
            Global.gFiltersSupplierAndCustomer = {
              ...Global.gFiltersSupplierAndCustomer,
              page: iNewPage,
            };
            setFilters(Global.gFiltersSupplierAndCustomer);
          }}
          onChangeRowsPerPage={(perPage) => {
            Global.gFiltersSupplierAndCustomer = {
              ...Global.gFiltersSupplierAndCustomer,
              page: 0,
              limit: perPage,
            };
            setFilters(Global.gFiltersSupplierAndCustomer);
          }}
          fullColumns={columns}
          data={supplierAndCustomers}
          loading={isGettingSupplierAndCustomers}
          filterbar={
            <>
              <VISearchField
                placeholder={`${t('EnterToSearch')}`}
                value={filters.q}
                className=""
                typingTimeout={500}
                onSubmit={(newValue) => {
                  Global.gFiltersSupplierAndCustomer = {
                    ...Global.gFiltersSupplierAndCustomer,
                    q: newValue,
                    page: 0,
                  };
                  setFilters(Global.gFiltersSupplierAndCustomer);
                }}
              />

              <div className="d-flex align-items-center">
                <label
                  htmlFor="filterStatusSupplier"
                  className="mb-0 mr-2 text-grey font-weight-bolder"
                >
                  {t('FilterByStatus')}
                </label>
                <KeenSelectOption
                  name="filterStatusSupplier"
                  initialValue={filters.status}
                  options={[{ name: 'All', value: '' }, ...AppData.supplierStatusItems]}
                  containerClassName=""
                  labelClassName=""
                  selectClassName="w-100"
                  menuClassName="w-200px"
                  onValueChanged={(value) => {
                    Global.gFiltersSupplierAndCustomer = {
                      ...Global.gFiltersSupplierAndCustomer,
                      status: value,
                    };
                    setFilters(Global.gFiltersSupplierAndCustomer);
                  }}
                />
              </div>

              <div className="d-flex align-items-center">
                <label
                  htmlFor="filterTypeSupplier"
                  className="mb-0 mr-2 text-grey font-weight-bolder"
                >
                  {t('FilterByType')}
                </label>
                <KeenSelectOption
                  name="filterTypeSupplier"
                  initialValue={filters.type}
                  options={[{ name: 'All', value: '' }, ...AppData.supplierTypeItems]}
                  containerClassName=""
                  labelClassName=""
                  selectClassName="w-100"
                  menuClassName="w-200px"
                  onValueChanged={(value) => {
                    Global.gFiltersSupplierAndCustomer = {
                      ...Global.gFiltersSupplierAndCustomer,
                      type: value,
                    };
                    setFilters(Global.gFiltersSupplierAndCustomer);
                  }}
                />
              </div>
            </>
          }
          onRowClick={(row) => {}}
          onRowDoubleClick={(row) => {
            handleEdit(row);
          }}
          onSetSelectedMultiItems={(rows) => {}}
          toolbar={
            <>
              <VIButton
                className="btn-green"
                text={t('Check')}
                beforeIcon={<i className="fa-regular fa-ballot-check"></i>}
                onClick={handleCheck}
              />
              <VIButton
                className="btn-blue"
                text={t('Upload')}
                beforeIcon={<i className="fa-regular fa-upload"></i>}
                onClick={handleImport}
              />
              <VIButton
                className="btn-blue"
                text={t('Download')}
                beforeIcon={<i className="fa-regular fa-download"></i>}
                onClick={() => handleExport()}
              />
            </>
          }
        />
      </div>

      {/* modal checking */}
      <VIModal
        show={modalCheckShowing}
        contentModal={
          <div className="d-flex flex-column align-items-center">
            <img src={AppResource.icons.icWaiting} />
            <p className="mt-2">Hệ thống đang kiểm tra và sẽ trả về kết quả sau ít phút</p>
          </div>
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalCheckShowing(false);
        }}
        buttonPositive=""
        buttonNegative=""
      />

      {/* modal edit supplier customer */}
      <VIModal
        titleModal={t('InfoSupplierOrCustomer')}
        modalSize="xl"
        centered={false}
        show={modalEditShowing}
        contentModal={
          <ViewEditSupplier
            item={currentSelectedSupplier}
            onPressCancel={() => {
              setCurrentSelectedSupplier(null);
              setModalEditShowing(false);
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setCurrentSelectedSupplier(null);
          setModalEditShowing(false);
        }}
      />

      {/* modal import */}
      <VIModal
        titleModal={t('Import')}
        modalSize="lg"
        show={modalImportShowing}
        contentModal={
          <ViewImportSupplier
            onPressCancel={() => {
              setModalImportShowing(false);
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalImportShowing(false);
        }}
      />
    </div>
  );
}

export default SupplierAndCustomerScreen;
