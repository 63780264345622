import miscApi from 'api/miscApi';
import toolApi from 'api/toolApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// tim ma so thue tu cmnd/cccd
export const thunkFindMSTFromCCCD = createAsyncThunk(
  'tool/findMSTFromCCCD',
  async (params, thunkAPI) => {
    const res = await miscApi.getCompaniesInfoByTaxCodes(params);
    return res;
  }
);

// tinh trang hoat dong ma so thue
export const thunkTaxCodeInfo = createAsyncThunk('tool/taxCodeInfo', async (params, thunkAPI) => {
  const res = await miscApi.getCompaniesInfoByTaxCodes(params);
  return res;
});

// danh sach van ban huong dan giam thue GTGT
export const thunkTaxReducedDocuments = createAsyncThunk(
  'tool/taxReducedDocuments',
  async (params, thunkAPI) => {
    const res = await toolApi.getTaxReducedDocuments(params);
    return res;
  }
);

// danh sach hang hoa, dich vu giam thue GTGT
export const thunkTaxReducedItems = createAsyncThunk(
  'tool/taxReducedItems',
  async (params, thunkAPI) => {
    const res = await toolApi.getTaxReducedItems(params);
    return res;
  }
);

// danh sach doanh nghiep rui ro
export const thunkRiskyBusiness = createAsyncThunk(
  'tool/riskyBusiness',
  async (params, thunkAPI) => {
    const res = await toolApi.getRiskyBusiness(params);
    return res;
  }
);

const toolSlice = createSlice({
  name: 'tool',
  initialState: {
    personalTaxCodeInfoItems: [],
    isGettingPersonalTaxCodeInfo: false,

    taxCodeInfoItems: [],
    isGettingTaxCodeInfo: false,

    taxReducedDocumentItems: [],
    isGettingTaxReducedDocuments: false,

    taxReducedItems: [],
    isGettingTaxReducedItems: false,
    totalTaxReducedItems: 0,

    riskyBusiness: [],
    totalRiskyBusiness: 0,
    isGettingRiskyBusiness: false,
  },
  reducers: {},
  extraReducers: {
    // tim ma so thue theo cmnd/cccd
    [thunkFindMSTFromCCCD.pending]: (state, action) => {
      state.isGettingPersonalTaxCodeInfo = true;
    },
    [thunkFindMSTFromCCCD.rejected]: (state, action) => {
      state.isGettingPersonalTaxCodeInfo = false;
    },
    [thunkFindMSTFromCCCD.fulfilled]: (state, action) => {
      state.isGettingPersonalTaxCodeInfo = false;
      const { result, companies } = action.payload;
      if (result === 'success' && companies) {
        state.personalTaxCodeInfoItems = companies;
      }
    },

    // tinh trang hoat dong mst
    [thunkTaxCodeInfo.pending]: (state, action) => {
      state.isGettingTaxCodeInfo = true;
    },
    [thunkTaxCodeInfo.rejected]: (state, action) => {
      state.isGettingTaxCodeInfo = false;
    },
    [thunkTaxCodeInfo.fulfilled]: (state, action) => {
      state.isGettingTaxCodeInfo = false;
      const { result, companies } = action.payload;
      if (result === 'success' && companies) {
        state.taxCodeInfoItems = companies;
      }
    },

    // danh sach van ban huong dan giam thue GTGT
    [thunkTaxReducedDocuments.pending]: (state, action) => {
      state.isGettingTaxReducedDocuments = true;
    },
    [thunkTaxReducedDocuments.rejected]: (state, action) => {
      state.isGettingTaxReducedDocuments = false;
    },
    [thunkTaxReducedDocuments.fulfilled]: (state, action) => {
      state.isGettingTaxReducedDocuments = false;
      const { result, taxReducedDocuments } = action.payload;
      if (result === 'success' && taxReducedDocuments) {
        state.taxReducedDocumentItems = taxReducedDocuments;
      }
    },

    // danh sach hang hoa, dich vu giam thue GTGT
    [thunkTaxReducedItems.pending]: (state, action) => {
      state.isGettingTaxReducedItems = true;
    },
    [thunkTaxReducedItems.rejected]: (state, action) => {
      state.isGettingTaxReducedItems = false;
    },
    [thunkTaxReducedItems.fulfilled]: (state, action) => {
      state.isGettingTaxReducedItems = false;
      const { result, products, total } = action.payload;
      if (result === 'success' && products) {
        state.taxReducedItems = products;
        state.totalTaxReducedItems = total;
      }
    },

    // danh sach doanh nghiep rui ro
    [thunkRiskyBusiness.pending]: (state, action) => {
      state.isGettingRiskyBusiness = true;
    },
    [thunkRiskyBusiness.rejected]: (state, action) => {
      state.isGettingRiskyBusiness = false;
    },
    [thunkRiskyBusiness.fulfilled]: (state, action) => {
      state.isGettingRiskyBusiness = false;
      const { result, RiskyCompanies, total } = action.payload;
      if (result === 'success' && RiskyCompanies) {
        state.riskyBusiness = RiskyCompanies;
      }
      state.totalRiskyBusiness = total;
    },
  },
});

const { reducer, actions } = toolSlice;
export const {} = actions;
export default reducer;
