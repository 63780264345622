import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '@ckeditor/ckeditor5-build-decoupled-document';
import _ from 'lodash';
import './style.scss';

CKEditorDocument.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,

  enableCheckValid: PropTypes.bool,
  isTouched: PropTypes.bool,
  isValid: PropTypes.bool,
  feedbackText: PropTypes.string,
};

CKEditorDocument.defaultProps = {
  value: '',
  text: '',
  disabled: false,

  onChange: null,
  onBlur: null,
  onFocus: null,

  enableCheckValid: false,
  isValid: true,
  isTouched: false,
  feedbackText: '',
};

/**
 *
 * @param {{
 * value: string,
 * text: string,
 * disabled: boolean,
 * onChange: function,
 * onBlur: function,
 * onFocus: function,
 * enableCheckValid: boolean,
 * isValid: boolean,
 * isTouched: boolean,
 * feedbackText: string,
 * }} props
 * @returns
 */
function CKEditorDocument(props) {
  // MARK: --- Params ---
  const {
    value,
    text,
    disabled,

    onChange,
    onBlur,
    onFocus,

    enableCheckValid,
    isValid,
    isTouched,
    feedbackText,
  } = props;
  const [inputValue, setInputValue] = useState(value);
  const showError = enableCheckValid && !isValid && isTouched;

  // MARK: --- Functions ---
  function handleChange(value) {
    setInputValue(value);
    if (onChange) {
      onChange(value);
    }
  }

  function handleBlur() {
    if (onBlur) {
      onBlur();
    }
  }

  function handleFocus() {
    if (onFocus) {
      onFocus();
    }
  }

  return (
    <div className="CKEditorDocument">
      <div
        className={`${showError ? 'border border-danger border-1 overflow-hidden' : ''}`}
        style={{
          borderRadius: '8px',
        }}
      >
        <CKEditor
          editor={Editor}
          data={inputValue}
          disabled={disabled}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log('Editor is ready to use!', editor);
            const elements = document.getElementsByClassName('ck-toolbar');
            for (let index = 0; index < elements.length; index++) {
              const element = elements[index];
              element.remove();
            }

            // Insert the toolbar before the editable area.
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
              );
          }}
          onError={(error, { willEditorRestart }) => {
            // If the editor is restarted, the toolbar element will be created once again.
            // The `onReady` callback will be called again and the new toolbar will be added.
            // This is why you need to remove the older toolbar.
            if (willEditorRestart) {
              this.editor.ui.view.toolbar.element.remove();
            }
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            // console.log({ event, editor, data });
            handleChange(data);
          }}
          onBlur={(event, editor) => {
            // console.log('Blur.', editor);
            handleBlur();
          }}
          onFocus={(event, editor) => {
            // console.log('Focus.', editor);
            handleFocus();
          }}
        />
      </div>
      {!_.isEmpty(text) && <span className="form-text text-muted">{text}</span>}
      {showError && !_.isEmpty(feedbackText) && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{feedbackText}</div>
        </div>
      )}
    </div>
  );
}

export default CKEditorDocument;
