import customDataTableStyle from 'assets/styles/customDataTableStyle';
import { thunkTaxReducedItems } from 'features/Tools/toolSlice';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIElements from 'general/components/VietInvoice/VIElements';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import Global from 'general/utils/Global';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

TaxReducedItemScreen.propTypes = {
  navItemList: PropTypes.array,
};

TaxReducedItemScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function TaxReducedItemScreen(props) {
  // ---- params ----
  const { navItemList } = props;
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      {
        name: t('NameOfGoodsOrServices'),
        sortable: false,
        minWidth: '240px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.productName}
            </p>
          );
        },
      },
      {
        name: t('CodeOfGoodsOrServices'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.productCode}
            </p>
          );
        },
      },
      {
        name: t('Content'),
        sortable: false,
        minWidth: '280px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0 text-maxline-3">
              {row?.content}
            </p>
          );
        },
      },
      {
        name: t('CodeHSND44'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.hsCode}
            </p>
          );
        },
      },
      {
        name: t('AppendixND44'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return <p data-tag="allowRowEvents" className="text-dark-75 m-0"></p>;
        },
      },
      {
        name: t('Result'),
        sortable: false,
        minWidth: '180px',
        cell: (row, index) => {
          const reduceTax = row?.reduceTax ?? false;
          return (
            <div data-tag="allowRowEvents" className="">
              <VIElements
                id={`result_${index}`}
                text={reduceTax ? 'Có giảm thuế' : 'Không giảm thuế'}
                style={{
                  color: !reduceTax ? '#EE2334' : '#20A144',
                  backgroundColor: !reduceTax
                    ? 'rgba(255, 87, 87, 0.10)'
                    : 'rgba(40, 160, 67, 0.1)',
                  padding: '4px 8px',
                  border: 'none',
                }}
                icon={
                  <i
                    className={`fas ${!reduceTax ? 'fa-times-circle' : 'fa-check-circle'}`}
                    style={{ color: !reduceTax ? '#EE2334' : '#20A144' }}
                  ></i>
                }
              />
            </div>
          );
        },
      },
    ];
  }, [t]);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(Global.gFilterTaxReducedItems);
  const { taxReducedItems, isGettingTaxReducedItems, totalTaxReducedItems } = useSelector(
    (state) => state.tool
  );
  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  useEffect(() => {
    dispatch(thunkTaxReducedItems(filters));
  }, [filters]);

  return (
    <div className="h-100">
      <VIDataTable
        draggable={false}
        tableStyles={customDataTableStyle}
        title={
          <div className="d-flex align-items-center justify-content-center gap-1">
            <div className="d-block d-lg-none">
              <KTBSDropdown
                toggleElement={
                  <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                    <i className="fa-solid fa-bars text-grey" />
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                dropdownMenuItems={dropdownMenuItems}
                selectedValue={t('LookUpValueAddedTaxReductionGoods')}
                onChange={(newValue) => {}}
              />
            </div>{' '}
            {t('Tra cứu mặt hàng giảm thuế theo nghị quyết 101/2023/QH15')}
          </div>
        }
        selectable={false}
        pagination={{
          perPage: filters.limit,
          total: totalTaxReducedItems,
          currentPage: filters.page + 1,
          count: taxReducedItems?.length + filters.limit * filters.page,
        }}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          Global.gFilterTaxReducedItems = {
            ...Global.gFilterTaxReducedItems,
            page: iNewPage,
          };
          setFilters(Global.gFilterTaxReducedItems);
        }}
        onChangeRowsPerPage={(perPage) => {
          Global.gFilterTaxReducedItems = {
            ...Global.gFilterTaxReducedItems,
            limit: perPage,
            page: 0,
          };
          setFilters(Global.gFilterTaxReducedItems);
        }}
        fullColumns={columns}
        data={taxReducedItems}
        loading={false}
        filterbar={
          <div className="d-flex flex-column gap-2">
            <VISearchField
              placeholder={`${t('EnterToSearch')}`}
              value={''}
              className=""
              inputClassName="w-auto"
              typingTimeout={500}
              onSubmit={(newValue) => {
                Global.gFilterTaxReducedItems = {
                  ...Global.gFilterTaxReducedItems,
                  q: newValue,
                  page: 0,
                };
                setFilters(Global.gFilterTaxReducedItems);
              }}
            />
            {!_.isEmpty(taxReducedItems) && (
              <div
                className="py-4 px-6 rounded"
                style={{
                  backgroundColor: 'rgba(255,127,44, 0.15)',
                }}
              >
                <div className="d-flex align-items-center gap-3">
                  <p className="mb-0 text-dark-75">GỢI Ý CỦA CHƯƠNG TRÌNH</p>
                  <div
                    className="bg-white px-4 py-2 font-weight-bold"
                    style={{
                      border: '1px solid #FF7F2C',
                      color: '#FF7F2C',
                      borderRadius: '999px',
                    }}
                  >
                    Có thể không được giảm thuế GTGT
                  </div>
                </div>
                <p className="mt-4 mb-0 font-weight-bold">
                  Đã tìm thấy một số kết quả của mặt hàng này trong Danh mục hàng hóa, dịch vụ không
                  được giảm thuế GTGT theo phụ lục I, II, III của Nghị định 44/2023/NĐ-CP. Vui lòng
                  kiểm tra lại thông tin giảm thuế của mặt hàng.
                </p>
              </div>
            )}
          </div>
        }
        onRowClick={(row) => {}}
        onSetSelectedMultiItems={(rows) => {}}
      />
    </div>
  );
}

export default TaxReducedItemScreen;
