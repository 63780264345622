import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';
import InputInvoiceScreen from './screens/InputInvoiceScreen';
import OutputInvoiceScreen from './screens/OutputInvoiceScreen';
import KTPageError01 from 'general/components/OtherKeenComponents/KTPageError01';

import { useDispatch } from 'react-redux';
import DetailInvoice from './components/DetailInvoice';

Invoice.propTypes = {};

function Invoice(props) {
  return (
    <Routes>
      <Route path="" element={<Navigate to="input" />} />
      <Route path="input/*" element={<InputInvoiceScreen />} />
      <Route path="output/*" element={<OutputInvoiceScreen />} />
      <Route path="detail/:id" element={<DetailInvoice />} />

      <Route path="*" element={<KTPageError01 />} />
    </Routes>
  );
}

export default Invoice;
