import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import { indexOf } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import VIButton from '../VIButton';
import VISearchField from '../VISearchField';
import './style.scss';
import Global from 'general/utils/Global';

VIDragAndDropV2.propTypes = {
  elements: PropTypes.array,
  onSetUpColumn: PropTypes.func,
  onSetDefault: PropTypes.func,
  defaultElements: PropTypes.array,
  maxHeightDrag: PropTypes.string,
  keyDefaultColumn: PropTypes.string,
};

VIDragAndDropV2.defaultProps = {
  elements: [],
  onSetUpColumn: null,
  onSetDefault: null,
  defaultElements: [],
  maxHeightDrag: '',
  keyDefaultColumn: '',
};

function VIDragAndDropV2(props) {
  // MARK --- Params ---
  const {
    elements,
    onSetUpColumn,
    onSetDefault,
    defaultElements,
    maxHeightDrag,
    keyDefaultColumn,
  } = props;
  const { accountId } = useAccount();
  const elementNoActions = elements.filter((item) => !!item.name);

  const defaultElementsNoActions = defaultElements.filter((item) => !!item.name);
  const { t } = useTranslation();
  const [rowItems, setRowItems] = useState(elementNoActions);
  const [showingColumns, setShowingColumn] = useState(defaultElementsNoActions);
  const [tempColumns, setTempColumns] = useState(elementNoActions);
  const [filters, setFilters] = useState('');
  const [rememberColumns, setRememberColumns] = useState(elementNoActions);
  const [remembercheckedColumns, setRememberCheckedColumns] = useState(defaultElementsNoActions);
  const [showMenu, setShowMenu] = useState(false);

  const account = useAccount();
  useEffect(() => {
    setShowingColumn(defaultElementsNoActions);
  }, [defaultElements]);
  useEffect(() => {
    setRowItems(elements.filter((item) => !!item.name));
  }, [elements]);

  // MARK --- Function ---
  function onDragEnd(result) {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newItems = Array.from(rowItems);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setRememberColumns(newItems);
    setRowItems(newItems);
    setShowingColumn(
      newItems.filter((item) => !!showingColumns.find((co) => co.name == item.name))
    );
  }

  function handleSetDefault() {
    if (onSetDefault) {
      setShowingColumn(
        elementNoActions.filter(
          (item) => !!Global?.[`${keyDefaultColumn}`].find((co) => co == item.name)
        )
      );
      setRowItems(elementNoActions);
      onSetDefault();
      document.getElementById('dropdown-dragAndDrop').click();
    }
  }

  function handleShowColumn(e) {
    const checked = e.target.checked;
    const id = e.target.id;
    // debugger;
    const column = rowItems.find((item) => item.name === id);
    let index = indexOf(rowItems, column);
    const copyShowingColumns = [...showingColumns];
    let sliceIndex = index;
    for (let i = 0; i < index; i++) {
      if (!copyShowingColumns.find((item) => item.name === rowItems[i].name)) {
        sliceIndex = sliceIndex - 1;
      }
    }
    if (checked) {
      setShowingColumn((prev) => Utils.insertElement(prev, column, sliceIndex));
    } else {
      setShowingColumn((prev) => prev.filter((item) => item.name != column.name));
    }
  }
  function handleSaveColumnState() {
    if (onSetUpColumn) {
      onSetUpColumn(showingColumns.concat(elements[elements.length - 1]));
      setTempColumns(rememberColumns);
      setRememberCheckedColumns(showingColumns.concat(elements[elements.length - 1]));
    }
    localStorage.setItem(
      `${keyDefaultColumn}_element`,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(`${keyDefaultColumn}_element`)),
        [account.accountId]: rememberColumns,
      })
    );
  }

  function handleCancel() {
    setRowItems(tempColumns);
    setShowingColumn(remembercheckedColumns);
    setFilters('');
    document.getElementById('dropdown-dragAndDrop').click();
  }

  // MARK --- Hooks ---
  useEffect(() => {
    const filterText = Utils.convertString(filters);
    const filterItem = elementNoActions.filter((item) =>
      Utils.convertString(item.name).includes(filterText)
    );
    setRowItems(filterItem);
  }, [filters]);

  return (
    <div className="VIDragAndDropV2">
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          className="p-3 border d-flex justify-content-center align-items-center"
          style={{ borderRadius: '8px', height: '38px' }}
        >
          <i className="fa-regular fa-gear text-grey cursor-pointer p-0"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu
          popperConfig={{
            strategy: 'fixed',
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent('scroll')),
          }}
        >
          <div className="px-4 py-2 font-weight-bolder font-size-h3">{t('ColumnSetting')}</div>

          <VISearchField
            placeholder={`${t('TypeToSearch')}...`}
            iconPosition="right"
            value={filters}
            className="mx-4 mb-4"
            typingTimeout={500}
            onSubmit={(newValue) => {
              setFilters(newValue);
            }}
          />
          <div
            // ref={containerRef}
            className="mx-4 border-top border-bottom border-right min-w-400px"
            style={
              maxHeightDrag
                ? { maxHeight: maxHeightDrag, overflow: 'auto', height: 'fit-content !important' }
                : { maxHeight: '45vh', overflow: 'auto', height: 'fit-content !important' }
            }
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => {
                  return (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <>
                        {/* ductu */}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderLeft: '1px solid #DBE3EF',
                            borderTop: '1px solid #DBE3EF',
                            borderBottom: '1px solid #DBE3EF',
                          }}
                        >
                          <div className="p-3 border-right">
                            <div className="py-1 rounded">
                              <i className="text-muted fa-solid fa-bars text-white fa-xs"></i>
                            </div>
                          </div>
                          <div className="p-3 d-flex align-items-center w-100">
                            <input
                              className="ml-0 mr-4 cursor-pointer"
                              type="checkbox"
                              name="Tất cả"
                              checked={showingColumns?.length === rememberColumns?.length}
                              id="Tất cả"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setShowingColumn(rememberColumns);
                                } else {
                                  setShowingColumn([]);
                                }
                              }}
                              style={{ scale: '1.4' }}
                            />
                            <label
                              className="m-0 text-grey"
                              style={{ whiteSpace: 'nowrap' }}
                              htmlFor="Tất cả"
                            >
                              Tất cả
                            </label>
                          </div>
                        </div>
                        {/* tat ca */}
                        {rowItems.map((item, index) => {
                          return (
                            <Draggable key={item.name} draggableId={item.name} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  className="snapshot-drag"
                                  // style={{ position: snapshot.isDragging && 'unset' }}
                                  ref={provided.innerRef}
                                  snapshot={snapshot}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      borderLeft: '1px solid #DBE3EF',
                                      borderTop: index !== 0 && '1px solid #DBE3EF',
                                    }}
                                  >
                                    <div className="p-3 border-right">
                                      <KTTooltip text="Di chuyển">
                                        <div className="py-1 rounded">
                                          <i className="text-muted fa-solid fa-bars text-white fa-xs"></i>
                                        </div>
                                      </KTTooltip>
                                    </div>
                                    <div className="p-3 d-flex align-items-center w-100">
                                      <input
                                        className="ml-0 mr-4 cursor-pointer"
                                        type="checkbox"
                                        name="Tất cả"
                                        checked={
                                          !!showingColumns.find(
                                            (column) => column.name == item.name
                                          )
                                        }
                                        id={item.name}
                                        onChange={(e) => handleShowColumn(e)}
                                        style={{ scale: '1.4' }}
                                      />
                                      <label
                                        className="m-0 text-grey"
                                        style={{ whiteSpace: 'nowrap' }}
                                        htmlFor={item.name}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </>
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="d-flex justify-content-between w-100 px-4 pb-2 pt-3">
            <div className="w-50">
              <VIButton
                beforeIcon={<i className="fas fa-redo"></i>}
                text={t('ResetToDefault')}
                className="btn-white me-5"
                style={{ padding: '8px' }}
                afterIcon={<></>}
                onClick={() => handleSetDefault()}
              />
            </div>
            <div className="d-flex justify-content-end w-50">
              <VIButton
                text={t('Cancel')}
                className="btn-white me-2"
                style={{ padding: '8px' }}
                onClick={() => handleCancel()}
              />
              <Dropdown.Item style={{ width: 'fit-content', padding: '0px' }}>
                <VIButton
                  text={t('Save')}
                  className=""
                  style={{ padding: '8px' }}
                  onClick={() => {
                    handleSaveColumnState();
                    // const
                  }}
                />
              </Dropdown.Item>
            </div>
          </div>
          {/* set default button
            <div className="d-flex flex-wrap gap-3 flex-md-row justify-content-between align-items-center py-3 px-6">
              <VIButton
                className="btn-green"
                text={t('ResetToDefault')}
                onClick={handleSetDefault}
                beforeIcon={<i className="fa-regular fa-rotate-right fa-flip-horizontal mr-4"></i>}
              />
              <div className="d-flex flex-row gap-3">
                <VIButton className="btn-grey" text={t('Cancel')} onClick={handleCancel} />
                <VIButton className="btn-blue" text={t('Save')} onClick={handleSaveColumnState} />
              </div>
            </div> */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default VIDragAndDropV2;
