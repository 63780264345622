/**
 * 
 * Usage: 
 *  <KTStickyToolbar
        items={[
            { icon: 'fad fa-desktop text-success', hoverClassName: 'btn-hover-success', onClick: () => alert('0') },
            { icon: 'fad fa-cog text-primary', hoverClassName: 'btn-hover-primary', onClick: () => alert('1') },
            { icon: 'fsrc/general/components/OtherKeenComponents/KTStickyToolbar/index.jsad fa-book text-warning', hoverClassName: 'btn-hover-warning', onClick: () => alert('2') },
            { icon: 'fad fa-comment-alt-dots text-info', hoverClassName: 'btn-hover-info', onClick: () => alert('3') }
        ]}
    />
 * 
 */

import PropTypes from 'prop-types';
import { useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import KTTooltip, { KTTooltipPositions } from '../KTTooltip';

KTStickyToolbar.propTypes = {
  items: PropTypes.array,
};

KTStickyToolbar.defaultProps = {
  items: [],
};

/**
 *
 * @param {{
 * items: []
 * }} props
 * @returns
 */
function KTStickyToolbar(props) {
  // MARK: --- Params ---
  const { items } = props;
  const [toolbarShow, setToolbarShow] = useState(true);
  const { t } = useTranslation();

  return (
    <Draggable axis="y" bounds={{ top: -110, bottom: 350 }} cancel=".caret">
      <div
        className="KTStickyToolbar sticky-toolbar w-auto shadow-none bg-transparent d-flex align-items-end"
        id="draggable-toolbar"
      >
        <div
          style={{
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          }}
          className={`caret bg-primary shadow px-1 py-4 mb-4 rounded-top-left rounded-bottom-left cursor-pointer hover-opacity-60`}
          onClick={() => {
            setToolbarShow(!toolbarShow);
          }}
        >
          <i className={`fas text-white ${toolbarShow ? 'fa-caret-right' : 'fa-caret-left'}`} />
        </div>
        <div
          className=" d-flex flex-column align-items-end"
          style={{
            transition: 'transform 0.5s',
            transform: `translateX(${toolbarShow ? 0 : 100}px)`,
          }}
        >
          <div>
            <ul
              className={`nav flex-column px-2 py-3 gap-2 bg-white shadow-lg rounded-top-left rounded-bottom-left`}
            >
              {items.map((item, index) => {
                return (
                  <li key={index} className="nav-item">
                    <KTTooltip
                      position={KTTooltipPositions.left}
                      text={item?.tooltipText ? t(item?.tooltipText) : item?.tooltipBody}
                      clickMode={item?.tooltipClickMode}
                      initialShow={item?.tooltipShow}
                    >
                      <div
                        id={item?.tooltipId}
                        className={`caret btn btn-sm btn-icon btn-bg-light ${
                          item?.hoverClassName ?? 'btn-hover-primary'
                        }`}
                        onClick={(e) => {
                          if (item?.onClick) {
                            item?.onClick();
                          }
                        }}
                      >
                        {item?.icon ? (
                          <i className={item?.icon} />
                        ) : (
                          <img
                            src={item.iconResource}
                            className="w-20px h-20px"
                            style={{
                              objectFit: 'cover',
                            }}
                          />
                        )}
                      </div>
                    </KTTooltip>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </Draggable>
  );
}

export default KTStickyToolbar;
