import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import EmptyView from 'features/Others/components/EmptyView';
import AddRoleView from 'features/System/components/AddRoleView';
import { thunkGetAllSystemRoles } from 'features/System/systemSlice';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIModal from 'general/components/VietInvoice/VIModal';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import SystemHelper from 'general/helpers/SystemHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

SystemRoleScreen.propTypes = {
  navItemList: PropTypes.array,
};

SystemRoleScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function SystemRoleScreen(props) {
  // ---- params ----
  const { navItemList } = props;
  const { t } = useTranslation();
  const [modalAddRoleShowing, setModalAddRoleShowing] = useState(false);
  const { allSystemRoleList, isGettingSystemRoleList } = useSelector((state) => state.system);
  const [filters, setFilters] = useState(Global.gFiltersAllRoleList);
  const columns = useMemo(() => {
    return [
      {
        name: t('RoleName'),
        sortable: false,
        minWidth: '80px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 font-weight-bold m-0">
              {row?.name}
            </p>
          );
        },
      },
      {
        name: t('TotalUserCount'),
        sortable: false,
        minWidth: '200px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.totalEmployees ?? 0}
            </p>
          );
        },
      },
      {
        name: t('DateCreated'),
        sortable: false,
        minWidth: '120px',
        style: {
          borderRight: 'none',
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {Utils.formatDateTime(row?.createdAt, 'DD/MM/YYYY')}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row) =>
          row?.readonly ? (
            <></>
          ) : (
            <div className="d-flex align-items-center justify-content-between">
              <VITableRowDropDown
                toggleElement={
                  <div>
                    <i className="text-grey fas fa-ellipsis-h p-0"></i>
                  </div>
                }
                menuItem={[
                  {
                    iconElement: <i className="far fa-edit"></i>,
                    text: t('Edit'),
                    click: () => {
                      handleEditRole(row);
                    },
                  },
                  {
                    iconElement: <i className="far fa-trash-alt"></i>,
                    text: t('Delete'),
                    click: () => {
                      handleDeleteRole(row);
                    },
                  },
                ]}
              />
            </div>
          ),
      },
    ];
  }, [t]);
  const [currentSelectedRole, setCurrentSelectedRole] = useState(null);
  const dispatch = useDispatch();
  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  useEffect(() => {
    dispatch(thunkGetAllSystemRoles(filters));
  }, [filters]);

  // ---- methods -----
  function handleEditRole(row) {
    if (row?.readonly) {
      return;
    }
    setCurrentSelectedRole(row);
    setModalAddRoleShowing(true);
  }

  function handleDeleteRole(row) {
    AppDialogHelper.show(t('DeleteRole'), t('ConfirmDeleteRole', { name: row?.name }), [
      {
        title: t('Cancel'),
        type: 'neutral',
        onClick: () => {
          AppDialogHelper.hide();
        },
      },
      {
        title: t('Delete'),
        type: 'negative',
        onClick: () => {
          SystemHelper.callApiDeleteRole([row?.roleId]);
          AppDialogHelper.hide();
        },
      },
    ]);
  }

  return (
    <div className="h-100">
      <VIDataTable
        tableStyles={customDataTableActionStyle}
        draggable={false}
        title={
          <div className="d-flex align-items-center justify-content-center gap-1">
            <div className="d-block d-lg-none">
              <KTBSDropdown
                toggleElement={
                  <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                    <i className="fa-solid fa-bars text-grey" />
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                dropdownMenuItems={dropdownMenuItems}
                selectedValue={t('ManageRole')}
                onChange={(newValue) => {}}
              />
            </div>{' '}
            {t('ManageRole')}
          </div>
        }
        selectable={false}
        pagination={null}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          Global.gFiltersAllRoleList = {
            ...Global.gFiltersAllRoleList,
            page: iNewPage,
          };
          setFilters(Global.gFiltersAllRoleList);
        }}
        onChangeRowsPerPage={(perPage) => {
          Global.gFiltersAllRoleList = {
            ...Global.gFiltersAllRoleList,
            limit: perPage,
            page: 0,
          };
          setFilters(Global.gFiltersAllRoleList);
        }}
        fullColumns={columns}
        data={allSystemRoleList}
        loading={isGettingSystemRoleList}
        filterbar={
          <>
            <VISearchField
              placeholder={`${t('EnterToSearch')}...`}
              value={filters.q}
              className=""
              typingTimeout={500}
              onSubmit={(newValue) => {
                Global.gFiltersAllRoleList = {
                  ...Global.gFiltersAllRoleList,
                  q: newValue,
                  page: 0,
                };
                setFilters(Global.gFiltersAllRoleList);
              }}
            />
          </>
        }
        onRowClick={(row) => {
          handleEditRole(row);
        }}
        onSetSelectedMultiItems={(rows) => console.log(rows)}
        toolbar={
          <>
            <VIButton
              beforeIcon={<i className="fa-regular fa-plus" />}
              text={t('AddRole')}
              className="btn-blue border-0 p-3"
              onClick={() => {
                setModalAddRoleShowing(true);
              }}
            />
          </>
        }
        emptyComponent={<EmptyView message={t('NoRoleDataYet')} />}
      />

      {/* modal add role */}
      <VIModal
        titleModal={_.isNull(currentSelectedRole) ? t('AddRole') : t('EditRole')}
        modalSize="lg"
        show={modalAddRoleShowing}
        contentModal={
          <AddRoleView
            item={currentSelectedRole}
            onPressCancel={() => {
              setModalAddRoleShowing(false);
              setCurrentSelectedRole(null);
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalAddRoleShowing(false);
          setCurrentSelectedRole(null);
        }}
        // buttonPositive={t('Save')}
        // buttonNegative={t('Cancel')}
      />
    </div>
  );
}

export default SystemRoleScreen;
