import VIFormCheck from 'general/components/VietInvoice/VIFormCheck';
import AxionHelper from 'general/helpers/AxionHelper';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

ModalContentAxionChangeInvoiceType.propTypes = {
  onChangeOutInvoice: PropTypes.func,
  outInvoice: PropTypes.bool,
};

ModalContentAxionChangeInvoiceType.defaultProps = {
  onChangeOutInvoice: null,
  outInvoice: false,
};

/**
 *
 * @param {{
 * onChangeOutInvoice: function,
 * outInvoice: boolean,
 * }} props
 * @returns
 */
function ModalContentAxionChangeInvoiceType(props) {
  // ---- params ----
  const { onChangeOutInvoice, outInvoice } = props;
  const { t } = useTranslation();
  const [outInvoiceOn, setOutInvoiceOn] = useState(false);

  useEffect(() => {
    setOutInvoiceOn(outInvoice);
  }, [outInvoice]);

  // ---- methods ----
  function handleChangeOutInvoice(isOn) {
    if (onChangeOutInvoice) {
      onChangeOutInvoice(isOn);
    }
  }

  return (
    <div className="ModalContentAxionChangeInvoiceType">
      <div className="d-flex">
        <img src="https://inbotapp.misacdn.net/img/logo_confirm.6f34b71e.png" />
        <div className="flex-grow-1 ml-10">
          <div className="sub-content border border-warning bg-light-warning p-4 rounded-lg position-relative">
            Mỗi hóa đơn đầu ra đồng bộ về sẽ bị trừ tài nguyên giống như hóa đơn đầu vào.
          </div>

          <VIFormCheck
            className="mt-4"
            type="checkbox"
            id="checkboxAllOutInvoice"
            name={t('AllOutInvoice')}
            checkedItem={outInvoiceOn}
            handleChange={() => {
              const newValue = !outInvoiceOn;
              AxionHelper.saveSwitchInvoiceOut(newValue);
              setOutInvoiceOn(newValue);
              handleChangeOutInvoice(newValue);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ModalContentAxionChangeInvoiceType;
