import CKEditorDocument from 'general/components/OtherKeenComponents/Forms/CKEditor/CKEditorDocument';
import VIInputField from 'general/components/VietInvoice/VIInputField';
import Utils from 'general/utils/Utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ModalFeedback(props) {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
      <VIInputField
        required
        className="w-100 mb-3"
        label={t('Receiver')}
        placeholder={t('ReceiverEmail')}
        value={Utils.formatNumber(formik.values.emails)}
        fieldHelper={formik.getFieldHelpers('emails')}
        fieldProps={formik.getFieldProps('emails')}
        fieldMeta={formik.getFieldMeta('emails')}
      />
      <VIInputField
        required
        className="w-100 mb-4"
        label={t('title')}
        placeholder={t('EnterTitle')}
        value={Utils.formatNumber(formik.values.subject)}
        fieldHelper={formik.getFieldHelpers('subject')}
        fieldProps={formik.getFieldProps('subject')}
        fieldMeta={formik.getFieldMeta('subject')}
      />
      <CKEditorDocument
        onChange={(e) => formik.setValues({ ...formik.values, html: e })}
        value={formik?.values?.html}
      />
    </div>
  );
}

export default ModalFeedback;
