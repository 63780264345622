import axiosClient from './axiosClient';

const companyTitleApi = {
  // lay tat cac chuc danh trong cong ty
  getAll: () => {
    const url = '/company-title/find';
    return axiosClient.get(url);
  },

  // tao chuc danh moi
  createNew: (params) => {
    const url = '/company-title/create';
    return axiosClient.post(url, params);
  },

  // xoa chuc danh
  delete: (ids) => {
    const url = '/company-title/delete';
    return axiosClient.delete(url, {
      data: {
        companyTitleIds: ids,
      },
    });
  },
};

export default companyTitleApi;
