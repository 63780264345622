import NotificationMarqueeView from 'features/Others/components/NotificationMarqueeView';
import ReminderView from 'features/Others/components/ReminderView';
import SuggestionHintView from 'features/Others/components/SuggestionHintView';
import AppOffcanvas, { KTOffcanvasPosition } from 'general/components/AppOffcanvas';
import KT01Content from 'general/components/Contents/KT01Content';
import KT01Footer from 'general/components/Footers/KT01Footer';
import KT01Header from 'general/components/Headers/KT01Header';
import KT01HeaderMobile from 'general/components/Headers/KT01HeaderMobile';
import KTStickyToolbar from 'general/components/OtherKeenComponents/KTStickyToolbar';
import KT01Sidebar from 'general/components/Sidebars/KT01Sidebar';
import AppConfigs from 'general/constants/AppConfigs';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import useConfig from 'hooks/useConfig';
import { useEffect, useMemo } from 'react';

function KT01BaseLayout(props) {
  // ---- params ----
  const suggestionHintView = useMemo(() => {
    return <SuggestionHintView />;
  }, []);
  const appConfig = useConfig();
  const bannerHeight = appConfig.systemNotification.bannerHeight;

  useEffect(() => {
    const wrapperEl = document.getElementById('kt_wrapper');
    wrapperEl.style.minHeight = `calc(100vh - ${bannerHeight}px)`;
  }, [bannerHeight]);

  return (
    <div>
      {appConfig.systemNotification.show && (
        <div className="position-sticky sticky-top">
          <NotificationMarqueeView />
        </div>
      )}

      {/* Header mobile */}
      <KT01HeaderMobile />

      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          {/* Sidebar */}
          <KT01Sidebar />

          <div id="kt_wrapper" className="d-flex flex-column flex-row-fluid wrapper">
            {/* Header */}
            <KT01Header />

            {/* Content */}
            <KT01Content>{props.children}</KT01Content>

            {/* Footer */}
            <KT01Footer />
          </div>
        </div>
      </div>

      {/* Scroll Top */}
      {/* <KTScrollTop /> */}

      {/* Sticky Toolbar */}
      <KTStickyToolbar
        items={[
          // {
          //   icon: 'fa-solid fa-lightbulb text-success',
          //   hoverClassName: 'btn-hover-success',
          //   tooltipBody: suggestionHintView,
          //   tooltipClickMode: true,
          //   tooltipShow: false,
          // },
          // {
          //   icon: 'fa-solid fa-bell text-primary',
          //   hoverClassName: 'btn-hover-primary',
          //   tooltipText: '',
          //   tooltipClickMode: true,
          //   tooltipId: 'toggleOffcanvasReminderId',
          // },
          {
            icon: 'fa-solid fa-phone text-primary',
            hoverClassName: 'btn-hover-primary',
            tooltipText: 'Liên hệ: ' + AppConfigs.callCenterNumber,
            // tooltipClickMode: true,
            // tooltipId: 'toggleOffcanvasReminderId',
            onClick: () => {
              Utils.openInCurrentTab('tel:' + AppConfigs.callCenterNumber);
            },
          },
          {
            iconResource: AppResource.icons.icSendZaLo,
            hoverClassName: 'btn-hover-primary',
            tooltipText: 'Hỗ trợ Zalo',
            // tooltipClickMode: true,
            // tooltipId: 'toggleOffcanvasReminderId',
            onClick: () => {
              Utils.openInNewTab('https://zalo.me/' + AppConfigs.phoneNumber);
            },
          },
        ]}
      />

      {/* Offcanvas reminder */}
      <AppOffcanvas
        id="offcanvasReminder"
        toggleByElementId="toggleOffcanvasReminderId"
        closeByElementId="closeOffcanvasReminderId"
        position={KTOffcanvasPosition.right}
        bodyEl={<ReminderView />}
      />
    </div>
  );
}

export default KT01BaseLayout;
