import KTPageError02 from 'general/components/OtherKeenComponents/KTPageError02';
import { Route, Routes } from 'react-router-dom';
import KeenAuth01BaseScreen from './screens/KeenAuth01BaseScreen';

function Auth(props) {
  return (
    <Routes>
      <Route path="/*" element={<KeenAuth01BaseScreen />} />

      <Route path="*" element={<KTPageError02 />} />
    </Routes>
  );
}

export default Auth;
