import ViewInvoiceExport from 'features/Invoice/components/ViewInvoiceExport';
import KTTooltip, { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip';
import VIAppTabs from 'general/components/VietInvoice/VIAppTabs';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIInvoice from 'general/components/VietInvoice/VIInvoice';
import InInvoice from './components/TabInInvoice';
import DeleteInvoices from './components/TabDeleteInvoice';
import './style.scss';
import ManualInput from '../components/ManualDataEntry';
import VIModal from 'general/components/VietInvoice/VIModal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalResize from '../components/ModalResize';
import { useDispatch } from 'react-redux';
import invoiceApi from 'api/invoiceApi';
import VILoading from 'general/components/VietInvoice/VILoading';
import { thunkGetAllOutInvoice } from 'features/Invoice/invoiceSlice';
import Global from 'general/utils/Global';
import VIModalDetailOutput from './components/ModalDetailInvoiceOutput';
import useAccount from 'hooks/useAccount';
OutputInvoiceScreen.propTypes = {};

function OutputInvoiceScreen(props) {
  const { t } = useTranslation();

  const tabList = [t('DeleteInvoices'), t('OutInvoice')];
  const [tabsActive, setTabActive] = useState('Hoá đơn đầu ra');
  const [showModalInvoices, setShowModalInvoices] = useState(false);
  const [fileUpload, setFileUpload] = useState([]);
  const [showModalManualInput, setShowModalManualInput] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [errorFile, setErrorFile] = useState([]);
  const [successFile, setSuccessFile] = useState([]);
  const [duplicateFile, setDuplicateFile] = useState([]);
  const [errorInvoiceFile, setErrorInvoiceFile] = useState([]);
  const [tabFileUpload, setTabFileUpload] = useState('Tất cả');
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [upCurrentFile, setUpCurrentFile] = useState();
  const [showModalDetailInvoices, setShowModalDetailInvoices] = useState(false);
  const [currentDetail, setCurrentDetail] = useState();
  const [loadingInput, setLoadingInput] = useState(false);

  const dispatch = useDispatch();
  const account = useAccount();

  useEffect(() => {
    setFileUpload([]);
  }, [account.organizationId, account.organizationDepartmentId]);
  const [modalInvoiceExportShowing, setModalInvoiceExportShowing] = useState(false);
  const handleUpload = async (e) => {
    setLoadingUpload(true);
    const data = new FormData();
    data.append('file', e?.target.files[0]);
    data.append('id', id);

    try {
      const res = await invoiceApi.createInvoiceXml(data);
      setLoadingUpload(false);

      if (res?.result === 'success') {
        dispatch(thunkGetAllOutInvoice(Global.gFiltersOutputInvoice));
        setSuccessFile([...successFile, res]);
        setLoadingUpload(false);
      }
      if (res?.result === 'failed' && res?.duplicateInvoiceId) {
        dispatch(thunkGetAllOutInvoice(Global.gFiltersOutputInvoice));
        setDuplicateFile([...duplicateFile, res]);
        setLoadingUpload(false);
      }
      if (res?.result === 'failed' && res?.reason) {
        dispatch(thunkGetAllOutInvoice(Global.gFiltersOutputInvoice));
        setErrorInvoiceFile([...errorInvoiceFile, res]);
        setLoadingUpload(false);
      } else {
      }
    } catch (error) {
      setErrorFile([...errorFile, error?.response?.data]);
      setLoadingUpload(false);
    }
  };
  const [id, setId] = useState(1);

  return (
    <div className="position-relative output-main-invoice">
      {tabsActive === 'Hoá đơn đầu ra' && (
        <div className="d-flex position-absolute button-nav" style={{ right: '0px', top: '0px' }}>
          <input
            type="file"
            id="uploadFilesInvoice"
            name="myfile"
            style={{ display: 'none' }}
            accept="text/xml"
            onChange={(e) => {
              if (e.target.files[0] !== undefined) {
                setFileUpload([
                  ...fileUpload,
                  {
                    files: e?.target?.files[0],
                    id: id,
                  },
                ]);
              }
              handleUpload(e);
              document.getElementById('uploadFilesInvoice').value = null;
            }}
          />
          <VIButton
            text={t('AddInvoice')}
            className="btn-white me-3"
            style={{ padding: '8px' }}
            onClick={() => {
              document.getElementById('uploadFilesInvoice').click(), setId(id + 1);
            }}
            beforeIcon={<i className="far fa-upload"></i>}
          />
          <VIButton
            text={t('DownloadList')}
            className=""
            style={{ padding: '8px' }}
            onClick={() => {
              // setShowModalInvoices(true);
              // quybka
              setModalInvoiceExportShowing(true);
            }}
            beforeIcon={<i className="fa-regular fa-download"></i>}
          />
        </div>
      )}
      <div className="">
        <VIAppTabs tabs={tabList} activeTab={tabsActive} handleClick={(tab) => setTabActive(tab)} />
        {tabsActive === 'Hoá đơn đầu ra' && <InInvoice tabsActive={tabsActive} />}
        {tabsActive === 'Hóa đơn đã xóa' && <DeleteInvoices tabsActive={tabsActive} />}
      </div>

      <VIModal
        titleModal={t(`ListOfInputInvoices`)}
        show={showModalInvoices}
        onHide={() => setShowModalInvoices(false)}
        contentModal={<VIInvoice text="Đầu ra" />}
        closeBtn={true}
        handleSubmit={() => {}}
        buttonPositive={t('Download')}
      />
      {fileUpload?.length > 0 && tabsActive === 'Hoá đơn đầu ra' && (
        <div
          id="check-upload-file"
          className="position-fixed bg-white shadow-lg"
          style={{
            right: '20px',
            bottom: '20px',
            zIndex: '1000',
            borderRadius: '16px',
            minWidth: '500px',
          }}
        >
          <div className="d-flex  align-items-center justify-content-between px-5 my-4">
            <h3 className="card-label mb-0 me-3">
              Đã tải lên {fileUpload?.length} hóa đơn
              {successFile?.length > 0 && `, thành công (${successFile?.length})`}
              {errorFile?.length > 0 && `, thất bại (${errorFile?.length} )`}
            </h3>
            <VIButton
              text={<i className="fa-solid fa-xmark d-flex align-items-center"></i>}
              className="btn-white "
              style={{ padding: '7px 5px' }}
              onClick={() => {
                setFileUpload([]), setErrorFile([]), setSuccessFile([]), setDuplicateFile([]);
                setErrorInvoiceFile([]);
              }}
            />
          </div>
          {errorFile?.length > 0 && (
            <div
              className="d-flex align-items-center px-5 py-2"
              style={{ background: 'rgba(255, 163, 24, 0.80)' }}
            >
              <i
                className="fa-regular fa-triangle-exclamation me-2"
                style={{ fontSize: '24px', color: '#1C2256' }}
              ></i>

              <div style={{ color: '#1C2256' }}>
                Có {errorFile?.length} hóa đơn chưa xử lý được. Hệ thống sẽ xử lý tốt nhất đối với
                hóa đơn XML.
                <br></br>
                Vui lòng tải hóa đơn XML để kiểm tra
                <span
                  className="text-primary ms-1 cursor-pointer"
                  onClick={() => document.getElementById('uploadFilesInvoice').click()}
                >
                  tại đây
                </span>
              </div>
            </div>
          )}
          <div className="d-flex px-6 py-4">
            <VIButton
              text={t('All') + ' ' + `(${fileUpload?.length})`}
              className="btn-grey me-3 "
              style={{ padding: '4px' }}
              onClick={() => setTabFileUpload('Tất cả')}
            />
            {errorFile?.length > 0 && (
              <VIButton
                text={t('CantReadInvoice') + ' ' + `(${errorFile?.length})`}
                className="btn-pause me-3"
                style={{ padding: '4px' }}
                onClick={() => setTabFileUpload('Không hợp lệ')}
              />
            )}
            {errorInvoiceFile?.length > 0 && (
              <VIButton
                text={t('HĐ không hợp lệ') + ' ' + `(${errorInvoiceFile?.length})`}
                className="btn-red-opacity"
                style={{ padding: '4px' }}
                onClick={() => setTabFileUpload('Sai')}
              />
            )}
            {successFile?.length > 0 && (
              <VIButton
                text={t('Hóa đơn hợp lệ') + ' ' + `(${successFile?.length})`}
                className="btn-success-opacity me-3"
                style={{ padding: '4px' }}
                onClick={() => setTabFileUpload('Hợp lệ')}
              />
            )}
            {duplicateFile?.length > 0 && (
              <VIButton
                text={t('Hóa đơn trùng') + ' ' + `(${duplicateFile?.length})`}
                className="btn-pause"
                style={{ padding: '4px' }}
                onClick={() => setTabFileUpload('Trùng')}
              />
            )}
          </div>
          <div
            style={{
              background: 'var(--system-bg-select, #F8F8F8)',
              // borderRadius: '0 0 30px 30px',
              maxHeight: '20vh',
              overflow: 'auto',
            }}
          >
            {tabFileUpload === 'Tất cả' && (
              <>
                {fileUpload?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center px-6 py-4"
                  >
                    <div style={{ maxWidth: '300px', wordBreak: 'break-word' }}>
                      {item?.files?.name}
                    </div>
                    <div className="d-flex">
                      {loadingUpload && item === upCurrentFile ? (
                        <div className="me-3">
                          <VILoading />
                        </div>
                      ) : (
                        <>
                          {errorFile?.some(
                            (e) => e?.name === item?.files?.name && e?.id === item?.id
                          ) ? (
                            <>
                              <VIButton
                                text={t('CantReadInvoice')}
                                className="btn-pause me-3"
                                style={{ padding: '4px' }}
                              />
                              <KTTooltip
                                text={t('EnterInvoiceData')}
                                position={KTTooltipPositions.topEnd}
                              >
                                <div
                                  onClick={() => {
                                    setShowModalManualInput(true), setCurrentImage(item);
                                  }}
                                >
                                  <i
                                    className="fa-regular fa-pen-line p-2 btn-white cursor-pointer"
                                    style={{ color: '#4A5677' }}
                                  ></i>
                                </div>
                              </KTTooltip>
                            </>
                          ) : duplicateFile?.some(
                              (e) => e?.name === item?.files?.name && e?.id === item?.id
                            ) ? (
                            <>
                              <VIButton
                                text={t('Hóa đơn trùng')}
                                className="btn-pause"
                                style={{ padding: '4px' }}
                                beforeIcon={
                                  <i
                                    className="fa-light fa-circle-minus"
                                    style={{ color: '#e56e19' }}
                                  ></i>
                                }
                              />
                              <KTTooltip
                                text={t('Xem chi tiết')}
                                position={KTTooltipPositions.topEnd}
                              >
                                <div
                                  onClick={() => {
                                    duplicateFile?.some(
                                      (e) =>
                                        e?.name === item?.name &&
                                        e?.id === item?.id &&
                                        setCurrentDetail(e?.duplicateInvoiceId)
                                    );
                                    // setCurrentDetail(item?.duplicateInvoiceId);
                                    setShowModalDetailInvoices(true);
                                  }}
                                  className="ms-3"
                                >
                                  <i
                                    className="fa-regular fa-eye p-2 cursor-pointer"
                                    style={{ color: '#4A5677' }}
                                  ></i>
                                </div>
                              </KTTooltip>
                            </>
                          ) : errorInvoiceFile?.some(
                              (e) => e?.name === item?.files?.name && e?.id === item?.id
                            ) ? (
                            <>
                              <VIButton
                                text={t('HĐ không hợp lệ')}
                                className="btn-red-opacity"
                                style={{ padding: '4px' }}
                                beforeIcon={
                                  <i
                                    className="fa-light fa-circle-minus"
                                    style={{ color: '#F44141' }}
                                  ></i>
                                }
                              />

                              <KTTooltip
                                text={t('Xem chi tiết')}
                                position={KTTooltipPositions.topEnd}
                              >
                                <div
                                  onClick={() => {
                                    errorInvoiceFile?.some(
                                      (e) =>
                                        e?.name === item?.name &&
                                        e?.id === item?.id &&
                                        setCurrentDetail(e?.invoiceId)
                                    );
                                    setShowModalDetailInvoices(true);
                                  }}
                                  className="ms-3"
                                >
                                  <i
                                    className="fa-regular fa-eye p-2 cursor-pointer"
                                    style={{ color: '#4A5677' }}
                                  ></i>
                                </div>
                              </KTTooltip>
                            </>
                          ) : successFile?.some(
                              (e) => e?.name === item?.files?.name && e?.id === item?.id
                            ) ? (
                            <>
                              <VIButton
                                text={t('Hóa đơn hợp lệ')}
                                className="btn-greens"
                                style={{ padding: '4px' }}
                                beforeIcon={<i className="fa-light fa-circle-minus"></i>}
                              />

                              <KTTooltip
                                text={t('Xem chi tiết')}
                                position={KTTooltipPositions.topEnd}
                              >
                                <div
                                  onClick={() => {
                                    successFile?.some(
                                      (e) =>
                                        e?.name === item?.name && setCurrentDetail(e?.invoiceId)
                                    );
                                    // setCurrentDetail(item?.invoiceId);
                                    setShowModalDetailInvoices(true);
                                  }}
                                  className="ms-3"
                                >
                                  <i
                                    className="fa-regular fa-eye p-2 cursor-pointer"
                                    style={{ color: '#4A5677' }}
                                  ></i>
                                </div>
                              </KTTooltip>
                            </>
                          ) : currentImage?.name === item?.name && loadingInput ? (
                            <>
                              <VIButton
                                text={t('Đang kiểm tra')}
                                className="btn-grays"
                                style={{ padding: '4px' }}
                                beforeIcon={<i className="fa-light fa-circle-minus"></i>}
                              />
                            </>
                          ) : (
                            <div className="me-3">
                              <VILoading />
                            </div>
                            // <VIButton
                            //   text={t('UploadSuccess')}
                            //   className="btn-grays me-3"
                            //   style={{ padding: '4px' }}
                            // />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            {tabFileUpload === 'Hợp lệ' && (
              <>
                {successFile?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center px-6 py-4"
                  >
                    <div style={{ maxWidth: '300px', wordBreak: 'break-word' }}>{item?.name}</div>
                    <div className="d-flex">
                      {loadingUpload && item === upCurrentFile ? (
                        <div className="me-3">
                          <VILoading />
                        </div>
                      ) : (
                        <>
                          <VIButton
                            text={t('Hóa đơn hợp lệ')}
                            className="btn-greens"
                            style={{ padding: '4px' }}
                            beforeIcon={<i className="fa-light fa-circle-minus"></i>}
                          />
                        </>
                      )}
                      <KTTooltip text={t('Xem chi tiết')} position={KTTooltipPositions.topEnd}>
                        <div
                          onClick={() => {
                            setCurrentDetail(item?.invoiceId);
                            setShowModalDetailInvoices(true);
                          }}
                        >
                          <i
                            className="fa-regular fa-eye p-2 cursor-pointer"
                            style={{ color: '#20a144' }}
                          ></i>
                        </div>
                      </KTTooltip>
                    </div>
                  </div>
                ))}
              </>
            )}
            {tabFileUpload === 'Trùng' && (
              <>
                {duplicateFile?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center px-6 py-4"
                  >
                    <div style={{ maxWidth: '300px', wordBreak: 'break-word' }}>{item?.name}</div>
                    <div className="d-flex">
                      {loadingUpload && item === upCurrentFile ? (
                        <div className="me-3">
                          <VILoading />
                        </div>
                      ) : (
                        <>
                          <VIButton
                            text={t('Hóa đơn trùng')}
                            className="btn-pause"
                            style={{ padding: '4px' }}
                            beforeIcon={
                              <i
                                className="fa-light fa-circle-minus"
                                style={{ color: '#e56e19' }}
                              ></i>
                            }
                          />
                        </>
                      )}
                      <KTTooltip text={t('Xem chi tiết')} position={KTTooltipPositions.topEnd}>
                        <div
                          onClick={() => {
                            setCurrentDetail(item?.duplicateInvoiceId);
                            setShowModalDetailInvoices(true);
                          }}
                        >
                          <i
                            className="fa-regular fa-eye p-2 cursor-pointer"
                            style={{ color: '#e56e19' }}
                          ></i>
                        </div>
                      </KTTooltip>
                    </div>
                  </div>
                ))}
              </>
            )}
            {tabFileUpload === 'Không hợp lệ' && (
              <>
                {fileUpload
                  ?.filter((x) => errorFile?.map((e) => x?.name === e?.name))
                  ?.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center px-6 py-4"
                    >
                      <div style={{ maxWidth: '300px', wordBreak: 'break-word' }}>{item?.name}</div>
                      <div className="d-flex">
                        <VIButton
                          text={t('CantReadInvoice')}
                          className="btn-pause me-3"
                          style={{ padding: '4px' }}
                        />
                        <KTTooltip
                          text={t('EnterInvoiceData')}
                          position={KTTooltipPositions.topEnd}
                        >
                          <div
                            onClick={() => {
                              setShowModalManualInput(true), setCurrentImage(item);
                            }}
                          >
                            <i
                              className="fa-regular fa-pen-line p-2 btn-white cursor-pointer"
                              style={{ color: '#4A5677' }}
                            ></i>
                          </div>
                        </KTTooltip>
                      </div>
                    </div>
                  ))}
              </>
            )}
            {tabFileUpload === 'Sai' && (
              <>
                {errorInvoiceFile?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center px-6 py-4"
                  >
                    <div style={{ maxWidth: '300px', wordBreak: 'break-word' }}>{item?.name}</div>
                    <div className="d-flex">
                      {loadingUpload && item === upCurrentFile ? (
                        <div className="me-3">
                          <VILoading />
                        </div>
                      ) : (
                        <>
                          <VIButton
                            text={t('HĐ không hợp lệ')}
                            className="btn-red-opacity"
                            style={{ padding: '4px' }}
                            beforeIcon={
                              <i
                                className="fa-light fa-circle-minus"
                                style={{ color: '#F44141' }}
                              ></i>
                            }
                          />
                        </>
                      )}
                      <KTTooltip text={t('Xem chi tiết')} position={KTTooltipPositions.topEnd}>
                        <div
                          onClick={() => {
                            setCurrentDetail(item?.invoiceId);
                            setShowModalDetailInvoices(true);
                          }}
                        >
                          <i
                            className="fa-regular fa-eye p-2 cursor-pointer"
                            style={{ color: '#F44141' }}
                          ></i>
                        </div>
                      </KTTooltip>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div
            style={{
              height: '15px',
              background: 'var(--system-bg-select, #F8F8F8)',
              borderRadius: '0 0 30px 30px',
            }}
          ></div>
        </div>
      )}
      <ManualInput
        show={showModalManualInput}
        onHide={() => {
          setShowModalManualInput(false), setCurrentImage('');
        }}
        currentImage={currentImage}
        setLoadingInput={setLoadingInput}
        setErrorFile={setErrorFile}
        errorFile={errorFile}
        setSuccessFile={setSuccessFile}
        successFile={successFile}
        duplicateFile={duplicateFile}
        errorInvoiceFile={errorInvoiceFile}
        setErrorInvoiceFile={setErrorInvoiceFile}
        setDuplicateFile={setDuplicateFile}
        invoice="OUTPUT"
      />
      <ModalResize
        show={showModalDetailInvoices}
        onHide={() => {
          setShowModalDetailInvoices(false), setCurrentDetail(null);
        }}
        currentDetail={currentDetail}
      />

      {/* quybka */}
      {/* modal export */}
      <VIModal
        titleModal={t('Export')}
        // modalSize="lg"
        show={modalInvoiceExportShowing}
        contentModal={
          <ViewInvoiceExport
            exportType="OUT"
            onPressedCancel={() => {
              setModalInvoiceExportShowing(false);
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalInvoiceExportShowing(false);
        }}
      />
    </div>
  );
}

export default OutputInvoiceScreen;
