import VIInputField from 'general/components/VietInvoice/VIInputField';
import VISelectFilter from 'general/components/VietInvoice/VISelectFilter';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './style.scss';

function AddLabel(props) {
  const { currentChangeLabel } = props;
  const dataFilter = [
    {
      name: 'Nhật ký hoạt động',
      value: 0,
    },
    {
      name: 'Thành viên',
      value: 1,
    },
    {
      name: 'Nhóm vị trí',
      value: 2,
    },
    {
      name: 'Vị trí công việc',
      value: 3,
    },
    {
      name: 'Cơ cấu tổ chức',
      value: 4,
    },
  ];
  const [currentColor, setCurrentColor] = useState(
    currentChangeLabel ? currentChangeLabel?.color : '#FD7972'
  );

  const [penColors, setPenColors] = useState(['#FD7972', '#FF965D', '#FFD240', '#304FFD']);

  return (
    <div className="Add-label">
      <div className="d-flex justify-content-between label-color">
        <VIInputField
          className="w-75 mb-3"
          label="Tên nhãn"
          placeholder="Nhập tên nhãn"
          fieldProps={{ defaultValue: currentChangeLabel ? currentChangeLabel?.name : '' }}
          // fieldHelper={formik.getFieldHelpers('password')}
          // fieldMeta={formik.getFieldMeta('password')}
        />
        <div className="d-flex align-items-end mb-3 position-relative">
          <Dropdown>
            <Dropdown.Toggle
              split
              variant="success"
              id="dropdown-split-basic-add-label"
            ></Dropdown.Toggle>
            <div
              className="p-1 border position-absolute h-100 d-flex align-items-center cursor-pointer"
              onClick={() => document.getElementById('dropdown-split-basic-add-label').click()}
              style={{
                top: 0,
                right: 0,
                zIndex: 10000,
                background: `${currentColor}`,
                minWidth: '75px',
                borderRadius: '14px',
              }}
            ></div>

            <Dropdown.Menu>
              <div>
                <span className="font-size-lg ms-3">Đổi màu</span>
                <div className="d-flex flex-row align-items-center mx-3 mt-2">
                  {penColors?.map((color, index) => (
                    <div className="form-check position-relative" key={index}>
                      <input
                        className="pen-color form-check-input p-2 mr-5"
                        type="radio"
                        name={'Color'}
                        value={color}
                        checked={currentColor === color}
                        onChange={(e) => setCurrentColor(e.target.value)}
                        style={{ background: color }}
                      />
                      {currentColor === color && (
                        <div
                          className="position-absolute"
                          style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            background: '#fff',
                            top: '6.5px',
                            left: '8px',
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                  <div className="btn-addColor position-relative ">
                    <label htmlFor="" className="form-label w-100 h-100">
                      <input
                        type="color"
                        className="form-control form-control-color border-0 p-0"
                        defaultValue="#563d7c"
                        id="add-color"
                        onChange={(e) => {
                          const color = e.target.value;
                          setCurrentColor(color);
                          const penColorsTmp = [...penColors];
                          penColorsTmp.pop();
                          penColorsTmp.unshift(color);
                          setPenColors([...penColorsTmp]);
                        }}
                      />
                    </label>
                    <i
                      className="far fa-plus position-absolute add-btn border"
                      onClick={() => document.getElementById('add-color').click()}
                    ></i>
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="mt-2">
        <p className="font-weight-bold mb-1">Tự động gán nhãn theo mã số thuế NCC</p>
        <VISelectFilter
          data={dataFilter}
          onChangeValue={(e) => console.log(e)}
          className="w-100"
          // disabled={true}
        />
      </div>
    </div>
  );
}

export default AddLabel;
