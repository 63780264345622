import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIStatisticCard from 'general/components/VietInvoice/VIStatisticCard';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import VIInputField from 'general/components/VietInvoice/VIInputField';
import CellSupplierConnect from 'features/Supplier/components/CellSupplierConnect';
import CardResourceInformation from 'features/Dashboard/components/CardResourceInformation';
import HelpComponentView from 'features/Others/components/HelpComponentView';

TestComponentScreen.propTypes = {};

function TestComponentScreen(props) {
  const [searchText, setSearchText] = useState('');
  const [filterDate, setFilterDate] = useState();

  return (
    <div className="d-flex flex-column gap-3">
      Test components screen
      <div className="d-flex justify-content-between mb-3">
        <VIButton
          className="btn-blue"
          text="Xuất khẩu"
          beforeIcon={<i className="fal fa-plus-circle"></i>}
        />
        <VIButton
          className="btn-green"
          text="Xuất khẩu"
          beforeIcon={<i className="fal fa-plus-circle"></i>}
        />
        <VIButton
          className="btn-grey"
          text="Xuất khẩu"
          beforeIcon={<i className="fal fa-plus-circle"></i>}
        />
      </div>
      <div className="d-flex justify-content-between mb-3">
        <VIButton
          className="btn-turquoise"
          text="Xuất khẩu"
          beforeIcon={<i className="fal fa-plus-circle"></i>}
        />
        <VIButton
          className="btn-red"
          text="Xuất khẩu"
          beforeIcon={<i className="fal fa-plus-circle"></i>}
        />
        <VIButton
          className="btn-white"
          text="Xuất khẩu"
          afterIcon={<i className="fal fa-check"></i>}
        />
      </div>
      <div className="d-flex w-50 justify-content-between mb-3">
        <VIButton className="btn-playing" text="Đang phát" />
        <VIButton className="btn-stop" text="Đã kết thúc" />
        <VIButton
          className="btn-normal"
          text="Cấp độ"
          beforeIcon={<i className="fa-solid fa-hexagon-exclamation"></i>}
        />
        <VIButton className="btn-pause" text="Đang nghỉ" />
      </div>
      <div className="d-flex gap-3">
        <VIStatisticCard
          title="Tổng hóa đơn đã xử lý"
          statistics={879}
          icon={<i className="fa-solid fa-file-lines"></i>}
          color="#304FFD"
          subColor="#EDF5FF"
          width="300px"
          data={{ 'Trước thuế GTGT': 143, 'Thuế GTGT': 22, 'Tổng tiền TT': 121 }}
          filterDate={{}}
          setFilterDate={() => {}}
        />

        <VIStatisticCard
          title="Hóa đơn hợp lệ"
          statistics={879}
          icon={<i className="fa-solid fa-file-check"></i>}
          color="#28A043"
          subColor="#E8FFEA"
          width="300px"
          data={{ 'Trước thuế GTGT': 143, 'Thuế GTGT': 22, 'Tổng tiền TT': 121 }}
        />
      </div>
      {/* search field */}
      <VISearchField
        placeholder="Nhập số hóa đơn, tên nhà cung cấp, mã số thuế"
        value={searchText}
        className="w-50 mb-3"
        typingTimeout={500}
      />
      <VISearchField
        placeholder="Nhập số hóa đơn, tên nhà cung cấp, mã số thuế"
        value={searchText}
        iconPosition="right"
        typingTimeout={500}
        className="w-50 mb-3"
      />
      {/* input field */}
      <VIInputField className="w-50 mb-3" label="Mã đơn vị" required placeholder="Nhập mã đơn vị" />
      <VIInputField
        className="w-50 mb-3"
        label="Tạo email để nhận hóa đơn"
        placeholder="Nhập email đơn vị"
      />
      <div className="d-flex w-75 gap-4 mb-3">
        <CellSupplierConnect
          className="w-100"
          imageUrl="https://www.freepnglogos.com/uploads/coca-cola-png/coca-cola-logopedia-fandom-powered-wikia-13.png"
          label="NCC hóa đơn MISA"
        />
        <CellSupplierConnect
          className="w-100"
          imageUrl="https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Logo_BIDV.svg/2560px-Logo_BIDV.svg.png"
          label="NCC hóa đơn MISA"
          buttonText="ABC DEF"
        />
      </div>
      <CardResourceInformation />
      <HelpComponentView
        interval={3000}
        data={[
          {
            title: 'Thiết lập kết nối',
            text: 'Thực hiện đăng nhập để ICORP Invoice kết nối trược tiếp dữ liệu hóa đơn của nhà cung cấp',
            image: 'https://i.pinimg.com/564x/c0/aa/f5/c0aaf53fc0f258e9939d773de6c4d660.jpg',
          },
          {
            title: 'Title 2',
            text: 'Thực hiện đăng nhập để ICORP Invoice kết nối trược tiếp dữ liệu hóa đơn của nhà cung cấp',
            image: 'https://i.pinimg.com/564x/8c/3b/9f/8c3b9f6e95e3aa6550a2ca8a647b5d47.jpg',
          },
          {
            title: 'Title 3',
            text: 'content 3',
            image: 'https://i.pinimg.com/564x/aa/ce/db/aacedb76015dc0afade0c37fa5d7dc97.jpg',
          },
        ]}
      />
    </div>
  );
}

export default TestComponentScreen;
