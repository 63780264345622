import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIDateRangePickerInput from 'general/components/VietInvoice/VIDateRangePicker';
import VIInputField from 'general/components/VietInvoice/VIInputField';
import Utils from 'general/utils/Utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ModalAccept(props) {
  const { company, address, companyAccept, name, taxCode, setDate, date } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div>
        {taxCode && (
          <div className="row mb-2">
            <div className="col-4">Mã số thuế</div>
            <div className="col-8">{taxCode}</div>
          </div>
        )}
        <div className="row mb-2">
          <div className="col-4">Tên công ty</div>
          <div className="col -8">{company}</div>
        </div>
        <div className="row">
          <div className="col-4">Địa chỉ</div>
          <div className="col -8">{address}</div>
        </div>
      </div>
      <VIInputField
        className="w-100 my-3"
        label={`${name} chấp nhận`}
        value={companyAccept}
        disabled={true}
      />
      <div className="d-flex my-4 w-100">
        <div className="w-50 me-4">
          <p
            className={`mb-1 font-weight-bold`}
            onClick={() => document.getElementById('date-range-input').focus()}
          >
            {t('StartDate')}
          </p>
          <KTFormGroup
            additionalClassName=" mb-3"
            inputName="date"
            inputElement={
              <div>
                <KTFormInput
                  name="date"
                  value={Utils.formatDateTime(new Date(), 'YYYY-MM-DD')}
                  onChange={(value) => {
                    setDate({ ...date, fromDate: moment(value)?.format('YYYY-MM-DD') });
                  }}
                  enableCheckValid
                  type={KTFormInputType.date}
                  placeholder={`${_.capitalize(t('SelectPaymentDate'))}`}
                  enableInputGroup={true}
                  prependElements={[<i className="fa-regular fa-calendar-clock"></i>]}
                />
              </div>
            }
          />
        </div>
        <div className="w-50">
          <p
            className={`mb-1 font-weight-bold`}
            onClick={() => document.getElementById('date-range-input').focus()}
          >
            {t('EndDate')}
          </p>
          <KTFormGroup
            additionalClassName=" mb-3"
            inputName="date"
            inputElement={
              <div>
                <KTFormInput
                  name="date"
                  value={Utils.formatDateTime(moment().add(7, 'days')?._d, 'YYYY-MM-DD')}
                  onChange={(value) => {
                    setDate({ ...date, toDate: moment(value)?.format('YYYY-MM-DD') });
                  }}
                  enableCheckValid
                  type={KTFormInputType.date}
                  placeholder={`${_.capitalize(t('SelectPaymentDate'))}`}
                  enableInputGroup={true}
                  prependElements={[<i className="fa-regular fa-calendar-clock"></i>]}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ModalAccept;
