import { unwrapResult } from '@reduxjs/toolkit';
import accountApi from 'api/accountApi';
import { setAccount, thunkSignIn } from 'features/Auth/authSlice';
import { FastField, Formik } from 'formik';
import { motion } from 'framer-motion';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppConfigs from 'general/constants/AppConfigs';
import AccountHelper from 'general/helpers/AccountHelper';
import Utils from 'general/utils/Utils';
import useRouter from 'hooks/useRouter';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';

function SignInForm(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { isSigningIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const router = useRouter();
  const refFormik = useRef();
  const [usernameTaxCode, setUsernameTaxCode] = useState(false);

  useEffect(() => {
    handleCheckUsername(refFormik.current?.values?.username);
  }, []);

  // MARK: --- Functions ---
  async function handleSubmit(values) {
    const params = {
      password: Utils.sha256(values?.password),
    };
    if (usernameTaxCode) {
      params.taxCode = values?.username;
    } else {
      params.username = values?.username;
      params.taxCode = values?.taxCode;
    }
    // console.log({ params });
    // return;
    try {
      const res = unwrapResult(await dispatch(thunkSignIn(params)));
      if (res?.result === 'success') {
        router.navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleCheckUsername(value) {
    // kiem tra co phai mst
    if (value?.match(AppConfigs.regexTaxCode10) || value?.match(AppConfigs.regexTaxCode13)) {
      if (!usernameTaxCode) {
        setUsernameTaxCode(true);
      }
    } else {
      if (usernameTaxCode) {
        setUsernameTaxCode(false);
      }
    }
  }

  /* use effect */
  useEffect(() => {
    (async function () {
      let params = router.query;

      // debugger;

      if (params?.accessToken) {
        let accessToken = params?.accessToken;
        /* set to store and redirect to / */
        console.log('[SignUpForm]', {});

        let response = await accountApi.validateAccessToken(accessToken).catch((err) => {});

        if (response?.result == 'success') {
          let { account, accessTokenExpireDate, accessToken } = response;

          if (accessToken) {
            AccountHelper.saveAccessToken(accessToken);
          }
          if (accessTokenExpireDate) {
            AccountHelper.saveAccessTokenExpireDate(accessTokenExpireDate);
          }

          if (account) {
            dispatch(setAccount(account));
          }

          AccountHelper.saveAccountId(account?.accountId);

          router.navigate('/');

          return;
        }
      }

      router.navigate(router.location.pathname);
    })();
  }, []);

  return (
    <motion.div
      className="login-form"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      {/* form */}
      <Formik
        initialValues={{
          taxCode: '', // 0916264096
          password: '', // 123456
          username: '', // 0916264096
        }}
        validationSchema={Yup.object({
          // taxCode: Yup.string()
          //   .required(t('TaxCodeRequired'))
          //   .trim()
          //   .test('len', t('TaxCodeInvalid', { field: t('taxCode') }), (val) => {
          //     if (val) {
          //       return (
          //         !val.toString().includes(' ') &&
          //         (val.toString().match(/^[0-9]{10}$/) ||
          //           val.toString().match(/^[0-9]{10}-[0-9]{3}$/))
          //       );
          //     }
          //   }),
          // code: Yup.string().when('hasCode', {
          //   is: (hasCode) => hasCode === true,
          //   then: Yup.string().required(t('CodeIsRequired')),
          // }),
          username: Yup.string().required('Bạn chưa nhập MST/Tên đăng nhập'),
          taxCode: Yup.string().when('username', {
            is: (username) => {
              return (
                !username?.match(AppConfigs.regexTaxCode10) &&
                !username?.match(AppConfigs.regexTaxCode13)
              );
            },
            then: Yup.string()
              .required(t('TaxCodeRequired'))
              .trim()
              .test('len', t('TaxCodeInvalid'), (val) => {
                if (val) {
                  return (
                    !val.toString().includes(' ') &&
                    (val.toString().match(AppConfigs.regexTaxCode10) ||
                      val.toString().match(AppConfigs.regexTaxCode13))
                  );
                }
              }),
          }),
          password: Yup.string().required(t('PasswordRequired')),
        })}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formikProps) => {
          refFormik.current = formikProps;
          return (
            <form
              className="form fv-plugins-bootstrap fv-plugins-framework"
              onSubmit={formikProps.handleSubmit}
            >
              {/* title */}
              <div className="pb-6 pt-lg-0 pt-5">
                <h3 className="font-weight-bolder text-dark font-size-h5 font-size-h2-lg">
                  Đăng nhập ICORP Invoice
                </h3>
                <span className="text-dark font-weight-bold font-size-h6">
                  Bạn chưa có tài khoản? &nbsp;
                  <Link to="/auth/sign-up" className="text-primary font-weight-bolder">
                    Đăng ký ngay
                  </Link>
                </span>
              </div>

              {/* mst/ten dang nhap */}
              {/* {formikProps.getFieldMeta('taxCode').error && ( */}
              <KTFormGroup
                label={
                  <>
                    {'MST/Tên đăng nhập'} <span className="text-danger">(*)</span>
                  </>
                }
                inputName="username"
                additionalClassName="fv-plugins-icon-container"
                // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
                inputElement={
                  <FastField name="username">
                    {({ field, form, meta }) => (
                      <KTFormInput
                        name={field.name}
                        value={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                          handleCheckUsername(value);
                        }}
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        enableCheckValid
                        isValid={_.isEmpty(meta.error)}
                        isTouched={meta.touched}
                        feedbackText={meta.error}
                        type={KTFormInputType.text}
                        // solidBackground
                        // additionalInputClassName="h-auto p-6 rounded-lg"
                        // text={t('UseForEmployeeInCompany')}
                      />
                    )}
                  </FastField>
                }
              />
              {/* )} */}

              {/* ma so thue */}
              {!usernameTaxCode && !_.isEmpty(formikProps.values.username) && (
                <KTFormGroup
                  label={
                    <>
                      {t('TaxCode')} {!usernameTaxCode && <span className="text-danger">(*)</span>}
                    </>
                  }
                  inputName="taxCode"
                  additionalClassName="fv-plugins-icon-container"
                  // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
                  inputElement={
                    <FastField name="taxCode">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          // solidBackground
                          // additionalInputClassName="h-auto p-6 rounded-lg"
                        />
                      )}
                    </FastField>
                  }
                />
              )}

              {/* mat khau */}
              <KTFormGroup
                label={
                  <>
                    {t('Password')} <span className="text-danger">(*)</span>
                  </>
                }
                inputName="password"
                additionalClassName="fv-plugins-icon-container"
                // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
                labelAdditionalComponent={
                  <Link
                    to="/auth/forgot-password"
                    className="text-primary font-size-h6 font-weight-bolder text-hover-primary"
                  >
                    {`${t('ForgotPassword')} ?`}
                  </Link>
                }
                inputElement={
                  <FastField name="password">
                    {({ field, form, meta }) => (
                      <KTFormInput
                        name={field.name}
                        value={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        enableCheckValid
                        isValid={_.isEmpty(meta.error)}
                        isTouched={meta.touched}
                        feedbackText={meta.error}
                        type={KTFormInputType.password}
                        // solidBackground
                        // additionalInputClassName="h-auto p-6 rounded-lg"
                      />
                    )}
                  </FastField>
                }
              />

              {/* action */}
              <div className="pb-lg-0 pb-5">
                <VIButton
                  text={isSigningIn ? `${t('SigningIn')}...` : t('SignIn')}
                  className="btn-blue w-100"
                  style={{ padding: '10px' }}
                  // onClick={() => {
                  //   formikProps.handleSubmit();
                  // }}
                  disabled={isSigningIn}
                  beforeIcon={isSigningIn ? <ClockLoader color="white" size={20} /> : <></>}
                />
                {/* <button
                  type="submit"
                  className={`d-flex align-items-center gap-2 btn btn-primary font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 ${
                    isSigningIn ? 'disabled' : ''
                  }`}
                >
                  {isSigningIn && <ClockLoader color="white" size={20} />}
                  {t('SignIn')}
                </button> */}
                {/* <button
                  type="button"
                  className="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span className="svg-icon svg-icon-md">
                    <img alt="google" src={AppResource.icons.keens.socialGoogle} />
                  </span>
                  Sign in with Google
                </button> */}
              </div>
            </form>
          );
        }}
      </Formik>
    </motion.div>
  );
}

export default SignInForm;
