import { unwrapResult } from '@reduxjs/toolkit';
import { thunkSignUp } from 'features/Auth/authSlice';
import { FastField, Formik } from 'formik';
import { motion } from 'framer-motion';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTCheckbox, {
  KTCheckboxStyle,
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppConfigs from 'general/constants/AppConfigs';
import Utils from 'general/utils/Utils';
import useRouter from 'hooks/useRouter';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';

SignUpForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

SignUpForm.defaultProps = {
  onSubmit: null,
  onCancel: null,
};

/**
 *
 * @param {{
 * onSubmit: function,
 * onCancel: function,
 * }} props
 * @returns
 */
function SignUpForm(props) {
  // MARK: --- Params ---
  const { onSubmit, onCancel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const dealerCode = router?.query?.dealerCode;
  const refFormik = useRef();
  const { isSigningUp } = useSelector((state) => state.auth);
  const [captchaOk, setCaptchaOk] = useState(false);

  // MARK: --- Functions ---
  async function handleSubmit(values) {
    const params = { ...values, password: Utils.sha256(values?.password) };
    delete params['acceptTerms'];
    delete params['confirmPassword'];
    if (dealerCode) {
      params.dealerCode = dealerCode;
    }
    // console.log({ params });
    // return;
    try {
      const res = unwrapResult(await dispatch(thunkSignUp(params)));
      if (res?.result === 'success') {
        AppDialogHelper.show(
          t('Success'),
          t('SignUpSuccessText'),
          [
            {
              title: t('SignIn'),
              type: 'positive',
              onClick: () => {
                refFormik.current?.handleReset();
                router.navigate('/auth/sign-in');
                AppDialogHelper.hide();
              },
            },
          ],
          false
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleCancel() {
    if (onCancel) {
      onCancel();
    }
  }

  return (
    <motion.div
      className="login-form"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      {/* form */}
      <Formik
        initialValues={{
          fullname: '',
          email: '',
          taxCode: '',
          password: '',
          confirmPassword: '',
          acceptTerms: true,
        }}
        validationSchema={Yup.object({
          fullname: Yup.string().required(t('FullnameRequired')),
          email: Yup.string().required(t('EmailRequired')).email(t('EmailRequired')),
          password: Yup.string().required(t('PasswordRequired')),
          confirmPassword: Yup.string()
            .required(t('PasswordRequired'))
            .oneOf([Yup.ref('password')], t('PasswordNotMatch')),
          acceptTerms: Yup.boolean().test(
            'isTrue',
            t('YouNeedAcceptTerms'),
            (value) => value === true
          ),
          taxCode: Yup.string()
            .required(t('TaxCodeRequired'))
            .trim()
            .test('len', t('TaxCodeInvalid', { field: t('taxCode') }), (val) => {
              if (val) {
                return (
                  !val.toString().includes(' ') &&
                  (val.toString().match(/^[0-9]{10}$/) ||
                    val.toString().match(/^[0-9]{10}-[0-9]{3}$/))
                );
              }
            }),
        })}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formikProps) => {
          refFormik.current = formikProps;
          return (
            <form
              className="form fv-plugins-bootstrap fv-plugins-framework"
              onSubmit={formikProps.handleSubmit}
            >
              {/* title */}
              <div className="pb-6 pt-lg-0 pt-5">
                <h3 className="font-weight-bolder text-dark font-size-h5 font-size-h2-lg">
                  Đăng ký ICORP Invoice
                </h3>
                <span className="text-dark font-weight-bold font-size-h6">
                  Bạn đã có tài khoản? &nbsp;
                  <Link to="/auth/sign-in" className="text-primary font-weight-bolder">
                    {t('SignIn')}
                  </Link>
                </span>
              </div>

              {/* form group */}
              <KTFormGroup
                label={
                  <>
                    {t('BusinessName')} <span className="text-danger">(*)</span>
                  </>
                }
                inputName="fullname"
                additionalClassName="fv-plugins-icon-container mb-4"
                // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
                inputElement={
                  <FastField name="fullname">
                    {({ field, form, meta }) => (
                      <KTFormInput
                        name={field.name}
                        value={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        enableCheckValid
                        isValid={_.isEmpty(meta.error)}
                        isTouched={meta.touched}
                        feedbackText={meta.error}
                        type={KTFormInputType.text}
                        // additionalInputClassName="h-auto p-6 rounded-lg"
                      />
                    )}
                  </FastField>
                }
              />

              {/* ma so thue */}
              <KTFormGroup
                label={
                  <>
                    {t('TaxCode')} <span className="text-danger">(*)</span>
                  </>
                }
                inputName="taxCode"
                additionalClassName="fv-plugins-icon-container mb-4"
                // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
                inputElement={
                  <FastField name="taxCode">
                    {({ field, form, meta }) => (
                      <KTFormInput
                        name={field.name}
                        value={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        enableCheckValid
                        isValid={_.isEmpty(meta.error)}
                        isTouched={meta.touched}
                        feedbackText={meta.error}
                        type={KTFormInputType.text}
                        // additionalInputClassName="h-auto p-6 rounded-lg"
                      />
                    )}
                  </FastField>
                }
              />

              {/* email */}
              <KTFormGroup
                inputName="email"
                label={
                  <>
                    {t('Email')} <span className="text-danger">(*)</span>
                  </>
                }
                additionalClassName="fv-plugins-icon-container mb-4"
                // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
                inputElement={
                  <FastField name="email">
                    {({ field, form, meta }) => (
                      <KTFormInput
                        name={field.name}
                        value={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        enableCheckValid
                        isValid={_.isEmpty(meta.error)}
                        isTouched={meta.touched}
                        feedbackText={meta.error}
                        type={KTFormInputType.email}
                        // additionalInputClassName="h-auto p-6 rounded-lg"
                      />
                    )}
                  </FastField>
                }
              />

              {/* mat khau */}
              <KTFormGroup
                inputName="password"
                label={
                  <>
                    {t('Password')} <span className="text-danger">(*)</span>
                  </>
                }
                additionalClassName="fv-plugins-icon-container mb-4"
                // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
                inputElement={
                  <FastField name="password">
                    {({ field, form, meta }) => (
                      <KTFormInput
                        name={field.name}
                        value={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        enableCheckValid
                        isValid={_.isEmpty(meta.error)}
                        isTouched={meta.touched}
                        feedbackText={meta.error}
                        type={KTFormInputType.password}
                        // additionalInputClassName="h-auto p-6 rounded-lg"
                      />
                    )}
                  </FastField>
                }
              />

              {/* xac nhan mat khau */}
              <KTFormGroup
                inputName="confirmPassword"
                label={
                  <>
                    {t('ConfirmPassword')} <span className="text-danger">(*)</span>
                  </>
                }
                additionalClassName="fv-plugins-icon-container mb-4"
                // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
                inputElement={
                  <FastField name="confirmPassword">
                    {({ field, form, meta }) => (
                      <KTFormInput
                        name={field.name}
                        value={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        enableCheckValid
                        isValid={_.isEmpty(meta.error)}
                        isTouched={meta.touched}
                        feedbackText={meta.error}
                        type={KTFormInputType.password}
                        // additionalInputClassName="h-auto p-6 rounded-lg"
                      />
                    )}
                  </FastField>
                }
              />
              <div className="checkbox-list form-group mb-4">
                <FastField name="acceptTerms">
                  {({ field, form, meta }) => (
                    <KTCheckbox
                      name={field.name}
                      checked={field.value}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      onFocus={() => {
                        form.setFieldTouched(field.name, true);
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(meta.error)}
                      isTouched={meta.touched}
                      feedbackText={meta.error}
                      label={
                        <>
                          {t('IAgreeWith')}{' '}
                          <a
                            href="#"
                            className="mx-1"
                            onClick={(e) => {
                              e.preventDefault();
                              Utils.openInNewTab(AppConfigs.termsAndConditionsUrl);
                            }}
                          >
                            {t('TermsAndConditions').toLowerCase()}
                          </a>{' '}
                          {t('ByICORPProvided')}
                        </>
                      }
                      style={KTCheckboxStyle.rounded}
                      type={KTCheckboxType.outline}
                    />
                  )}
                </FastField>
              </div>

              {/* google captcha */}
              <div className="form-group mb-4">
                <ReCAPTCHA
                  sitekey={AppConfigs.recaptchaSiteKeyV2}
                  hl="vi"
                  onChange={(value) => {
                    if (value) {
                      setCaptchaOk(true);
                    } else {
                      setCaptchaOk(false);
                    }
                  }}
                />
              </div>

              {/* action */}
              <div className="form-group d-flex flex-wrap pb-lg-0 mb-0">
                <VIButton
                  text={isSigningUp ? `${t('SigningUp')}...` : t('SignUp')}
                  className="btn-blue w-100"
                  style={{ padding: '10px' }}
                  // onClick={() => {
                  //   formikProps.handleSubmit();
                  // }}
                  disabled={isSigningUp || !captchaOk}
                  beforeIcon={isSigningUp ? <ClockLoader color="white" size={20} /> : <></>}
                />
                {/* <button
                  type="submit"
                  className={`btn btn-primary font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-4 ${
                    formikProps.values.acceptTerms ? '' : 'disabled'
                  }`}
                >
                  {t('SignUp')}
                </button>
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bolder font-size-h6 px-6 py-3 my-3"
                  onClick={handleCancel}
                >
                  {t('Cancel')}
                </button> */}
              </div>
            </form>
          );
        }}
      </Formik>
    </motion.div>
  );
}

export default SignUpForm;
