import React from 'react';
import PropTypes from 'prop-types';
import useRouter from 'hooks/useRouter';
import AppResource from 'general/constants/AppResource';

KTPageError02.propTypes = {

};

function KTPageError02(props) {
    // MARK: --- Params ---
    const router = useRouter();

    // MARK: --- Functions ---
    function handleBack() {
        router.navigate(-1);
    }

    return (
        <div className='d-flex flex-column flex-root min-vh-100 bgi-position-center bgi-size-cover' style={{
            backgroundImage: `url(${AppResource.images.keens.bg2})`
        }}>
            <div className='d-flex flex-column-fluid align-items-end justify-content-center'>
                <div className='d-flex flex-column px-10 pb-10 pb-lg-30 flex-center'>
                    <p className='font-size-h1 text-center text-white font-weight-normal py-3'>
                        OOPS! Page Not Found...
                    </p>
                    <a href='#' className='btn btn-pill btn-transparent-white font-weight-bolder py-4 px-8' onClick={handleBack}>
                        <span className='svg-icon svg-icon-md mr-3'>
                            <i className="far fa-arrow-left" />
                        </span>
                        Return Home
                    </a>
                </div>
            </div>
        </div>
    );
}

export default KTPageError02;