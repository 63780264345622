import AppData from 'general/constants/AppData';
import axiosClient from './axiosClient';

const paymentApi = {
  // lay danh sach goi dich vu
  getAllServicePackList: (params) => {
    const url = '/package/find';
    return axiosClient.get(url, { params });
  },

  // lay danh sach voucher
  getVoucherList: (params) => {
    const url = '/misc/find-voucher';
    return axiosClient.get(url, { params });
  },

  // tim voucher theo code
  findVoucherByCode: (code) => {
    const url = '/misc/find-voucher-by-code';
    const params = {
      voucherCode: code,
    };
    return axiosClient.get(url, { params });
  },

  // tao don hang
  createOrder: (params) => {
    const url = '/order/create';
    return axiosClient.post(url, params);
  },

  // lich su mua hang
  getOrderHistory: (params) => {
    const url = 'order/history';
    return axiosClient.get(url, { params });
  },

  // huy don hang
  cancelOrder: (orderId) => {
    const url = `order/update/${orderId}`;
    const params = {
      statusOrder: AppData.paymentStatus.canceled,
    };
    return axiosClient.put(url, params);
  },

  // thanh toan lai
  repayment: (orderId) => {
    const url = `order/repayment/${orderId}`;
    return axiosClient.post(url);
  },

  // gui lai email xac nhan thanh toan
  resendEmailConfirmPayment: (orderId) => {
    const url = `order/email_payment/${orderId}`;
    return axiosClient.get(url);
  },
};

export default paymentApi;
