import axionApi from 'api/axionApi';
import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

ViewConnectionAxionHistoryDetail.propTypes = {
  item: PropTypes.object,
  onPressCancel: PropTypes.func,
};

ViewConnectionAxionHistoryDetail.defaultProps = {
  item: null,
  onPressCancel: null,
};

/**
 *
 * @param {{
 * item: object,
 * onPressCancel: function,
 * }} props
 * @returns
 */
function ViewConnectionAxionHistoryDetail(props) {
  // ---- params ----
  const { item, onPressCancel } = props;
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    limit: Global.gDefaultPagination,
    page: 0,
    q: '',
  });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalHasCode, setTotalHasCode] = useState(0);
  const [totalNoCode, setTotalNoCode] = useState(0);
  const [connectionAxionInvoices, setConnectionAxionInvoices] = useState([]);
  const router = useRouter();
  const columns = useMemo(
    () => [
      {
        name: t('STT'),
        width: '80px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="m-0 text-maxline-3">
              {Utils.formatNumber(index + 1)}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        width: '160px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0 text-maxline-3">
              {row?.sellerTaxCode}
            </p>
          );
        },
      },
      {
        name: t('Supplier'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0 text-maxline-3">
              {row?.sellerName}
            </p>
          );
        },
      },
      {
        name: t('InvoiceDate'),
        width: '140px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0 text-maxline-3">
              {row?.invoiceDate ? Utils.formatDateTime(row?.invoiceDate, 'DD/MM/YYYY') : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceNo'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.invoiceNumber ?? ''}
            </p>
          );
        },
      },
      {
        name: t('Denominator'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.invoiceTypeCode ?? ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceSymbol'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.serial ?? ''}
            </p>
          );
        },
      },
      {
        name: t('State'),
        minWidth: '120px',
        style: {},
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.invoiceCheckStatusText ?? ''}
            </p>
          );
        },
      },
      {
        name: t('Detail'),
        minWidth: '220px',
        style: {
          borderRight: 0,
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.description}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) =>
          row?.invoiceId ? (
            <div className="d-flex align-items-center justify-content-between gap-3 px-3">
              <KTTooltip text={t('ViewDetail')}>
                <i
                  className="fa-regular fa-eye text-primary"
                  onClick={() => {
                    router.navigate(`/inbot/invoice/detail/${row?.invoiceId}`);
                  }}
                />
              </KTTooltip>
            </div>
          ) : null,
      },
    ],
    [t, router]
  );

  useEffect(() => {
    const id = item?.cqtInvoiceHistoryId;
    if (id) {
      requestGetConnectionAxionHistory(id, filters);
    }
  }, [item, filters]);

  // ---- methods ----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  async function requestGetConnectionAxionHistory(id, params) {
    try {
      setLoading(true);
      const res = await axionApi.getConnectioAxionHistoryDetail(id, params);
      const { result, cqtInvoices, total, hasCode, noCode } = res;
      if (result === 'success' && cqtInvoices) {
        setConnectionAxionInvoices(cqtInvoices);
        setTotal(total);
        setTotalHasCode(hasCode);
        setTotalNoCode(noCode);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap overflow-auto"
        style={{
          maxHeight: '70vh',
        }}
      >
        <VISearchField
          placeholder={`${t('EnterToSearch')}`}
          inputClassName="w-auto"
          value={filters.q}
          className=""
          typingTimeout={500}
          onSubmit={(newValue) => {
            setFilters({
              ...filters,
              q: newValue,
              page: 0,
            });
          }}
        />

        <div className="d-flex align-items-center gap-5 flex-wrap">
          <p className="mb-0">
            Hoá đơn có mã: <span className="font-weight-bolder">{totalHasCode}</span>
          </p>
          <p className="mb-0">
            Hoá đơn không mã: <span className="font-weight-bolder">{totalNoCode}</span>
          </p>
        </div>
      </div>
      <div
        className="mt-4"
        style={
          {
            // maxHeight: '70vh',
          }
        }
      >
        <VIDataTable
          haveCardHeader={false}
          additionalCardBodyClassName="p-0"
          additionalClassName="shadow-none rounded-0"
          tableStyles={customDataTableActionStyle}
          minusHeight={95}
          draggable={false}
          pagination={{
            perPage: filters.limit,
            total: total,
            currentPage: filters.page + 1,
            count: connectionAxionInvoices?.length,
          }}
          onChangePage={(page) => {
            let iNewPage = parseInt(page);
            iNewPage -= 1;
            if (iNewPage < 0) {
              iNewPage = 0;
            }
            setFilters({
              ...filters,
              page: iNewPage,
            });
          }}
          onChangeRowsPerPage={(perPage) => {
            setFilters({
              ...filters,
              limit: perPage,
              page: 0,
            });
          }}
          fullColumns={columns}
          data={connectionAxionInvoices}
          loading={loading}
          onRowClick={(row) => {}}
        />
      </div>
      {/* actions */}
      {/* <div className="d-flex align-items-center justify-content-end gap-3">
        <VIButton
          text={t('Cancel')}
          className="btn-grey"
          style={{ padding: '8px' }}
          onClick={handlePressCancel}
        />
      </div> */}
    </div>
  );
}

export default ViewConnectionAxionHistoryDetail;
