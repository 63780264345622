import { AppDialogHelper } from 'general/components/AppDialog';
import KTAdvanceNav, {
  KTAdvanceNavFontWeight,
} from 'general/components/OtherKeenComponents/KTAdvanceNav';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import AccountHelper from 'general/helpers/AccountHelper';
import useRouter from 'hooks/useRouter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import AccountChangePasswordScreen from './screens/AccountChangePasswordScreen';
import AccountPaymentHistoryScreen from './screens/AccountPaymentHistoryScreen';
import AccountPersonalInfoScreen from './screens/AccountPersonalInfoScreen';
import AccountPrivacyPolicyScreen from './screens/AccountPrivacyPolicyScreen';
import AccountSetupSecurityScreen from './screens/AccountSetupSecurityScreen';

function Account(props) {
  // ---- params ----
  const { t } = useTranslation();
  const router = useRouter();
  const { pathname } = router;
  const navItemList = useMemo(() => {
    const itemList = [
      {
        text: t('PersonalInfo'),
        active: pathname.includes('/personal-info'),
        iconElement: <i className="fa-regular fa-user-gear" />,
        icon: 'fa-regular fa-user-gear',
        onPress: () => {
          router.navigate('personal-info');
        },
      },
      {
        text: t('ProtectAccount'),
        active: pathname.includes('/setup-security'),
        iconElement: <i className="fa-regular fa-shield-check" />,
        icon: 'fa-regular fa-shield-check',
        onPress: () => {
          router.navigate('setup-security');
        },
      },
      {
        text: t('PrivacyPolicy'),
        active: pathname.includes('/privacy-policy'),
        iconElement: <i className="fa-regular fa-newspaper" />,
        icon: 'fa-regular fa-newspaper',
        onPress: () => {
          router.navigate('privacy-policy');
        },
      },
      {
        text: t('ChangePassword'),
        active: pathname.includes('/change-password'),
        iconElement: <i className="fa-regular fa-key" />,
        icon: 'fa-regular fa-key',
        onPress: () => {
          router.navigate('change-password');
        },
      },
      {
        text: t('PaymentHistory'),
        active: pathname.includes('/payment-history'),
        iconElement: <i className="fa-regular fa-clock-rotate-left" />,
        icon: 'fa-regular fa-clock-rotate-left',
        onPress: () => {
          router.navigate('payment-history');
        },
      },
      // {
      //   text: t('Voucher'),
      //   active: pathname.includes('/voucher'),
      //   iconElement: <i className="fa-regular fa-gift" />,
      //   onPress: () => {
      //     router.navigate('voucher');
      //   },
      // },
    ];
    return itemList;
  }, [t, pathname]);

  // ---- methods ----
  function handleSignOut() {
    AppDialogHelper.show(
      t('SignOut'),
      t('ConfirmSignOutText'),
      [
        {
          title: t('Cancel'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
        {
          title: t('Yes'),
          type: 'negative',
          onClick: () => {
            AppDialogHelper.hide();
            AccountHelper.signOut();
            router.navigate('/');
          },
        },
      ],
      false,
      AppResource.icons.icSignOut
    );
  }

  return (
    <div className="d-flex flex-row h-100">
      {/* aside menu */}
      <div className="flex-row-auto offcanvas-mobile h-100">
        <div
          className="card card-custom h-100"
          style={{
            borderRadius: '16px',
          }}
        >
          <div className="card-body p-4 mt-6">
            <KTAdvanceNav
              enableHoverStyle
              enableLinkRounded={true}
              enableActiveStyle
              enableAccentStyle
              fontWeight={KTAdvanceNavFontWeight.bold}
              itemList={navItemList}
              additionalComponent={
                <div>
                  <div className="separator separator-dashed mb-5 mt-3"></div>
                  <VIButton
                    beforeIcon={<i className="fa-regular fa-arrow-right-from-bracket" />}
                    text={t('SignOut')}
                    className="btn-red mt-2 p-3 w-100"
                    onClick={handleSignOut}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>

      {/* content */}
      <div
        className={`flex-row-fluid ml-lg-6 ${
          !pathname.includes('/setup-security') && !pathname.includes('account/voucher')
            ? 'card card-custom card-stretch'
            : ''
        }`}
        style={{
          borderRadius: '16px',
        }}
      >
        <div className="h-100">
          <Routes>
            <Route path="" element={<Navigate to="personal-info" />} />
            <Route
              path="personal-info/*"
              element={<AccountPersonalInfoScreen navItemList={navItemList} />}
            />
            <Route
              path="setup-security/*"
              element={<AccountSetupSecurityScreen navItemList={navItemList} />}
            />
            <Route
              path="privacy-policy/*"
              element={<AccountPrivacyPolicyScreen navItemList={navItemList} />}
            />
            <Route
              path="change-password/*"
              element={<AccountChangePasswordScreen navItemList={navItemList} />}
            />
            <Route
              path="payment-history/*"
              element={<AccountPaymentHistoryScreen navItemList={navItemList} />}
            />
            {/* <Route path="voucher/*" element={<AccountVoucherScreen />} /> */}

            <Route path="*" element={<Navigate to="personal-info" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Account;
