import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAccount from 'hooks/useAccount';
import AppDimension from 'general/constants/AppDimension';
import AppConfigs from 'general/constants/AppConfigs';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import { osName, osVersion, deviceType, browserName } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { thunkGetDeviceInfo } from 'app/appSlice';
import { useSelector } from 'react-redux';
import Utils from 'general/utils/Utils';
import useConfig from 'hooks/useConfig';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';

AccountPrivacyPolicyScreen.propTypes = {
  navItemList: PropTypes.array,
};

AccountPrivacyPolicyScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function AccountPrivacyPolicyScreen(props) {
  // MARK --- Params ---
  const { navItemList } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const refCardHeader = useRef(null);
  const [cardBodyMaxHeight, setCardBodyMaxHeight] = useState('55vh');
  const account = useAccount();
  const deviceInfo = useSelector((state) => state.app.deviceInfo);
  const bannerHeight = useConfig().systemNotification.bannerHeight;
  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  // scroll into card
  function resizeScreen() {
    setCardBodyMaxHeight(
      `${
        window.innerHeight -
        refCardHeader?.current?.clientHeight -
        187 -
        bannerHeight +
        (AppConfigs.isHideFooter ? 65 : 0)
      }px`
    );
  }
  window.onresize = resizeScreen;

  useEffect(() => {
    resizeScreen();
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    dispatch(thunkGetDeviceInfo());
  }, []);

  return (
    <div>
      <div
        ref={refCardHeader}
        className="px-7 py-4 border-bottom d-flex flex-wrap align-items-center justify-content-between gap-2 min-h-65px"
      >
        <div className="card-title align-items-start flex-column m-0">
          <div className="d-flex align-items-center justify-content-center gap-1">
            <div className="d-block d-lg-none">
              <KTBSDropdown
                toggleElement={
                  <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                    <i className="fa-solid fa-bars text-grey" />
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                dropdownMenuItems={dropdownMenuItems}
                selectedValue={t('PrivacyPolicy')}
                onChange={(newValue) => {}}
              />
            </div>{' '}
            <h3 className="card-label font-weight-bolder text-grey m-0">{t('PrivacyPolicy')}</h3>
          </div>
        </div>
      </div>

      <div className="card-body overflow-auto" style={{ maxHeight: cardBodyMaxHeight }}>
        <div className="row">
          {/* left */}
          <div className="col-lg-6">
            <img className="gutter-b" src={AppResource.icons.icLogoQuanLyHoaDon} />
            <p className="text-grey font-weight-bolder">Thông tin người xác nhận</p>
            <div className="d-flex">
              <div className="mr-4">
                <p>{t('Fullname')}:</p>
                <p>{t('Phone')}:</p>
                <p>{t('Email')}:</p>
                {/* <p>{t('ConfirmDate')}:</p> */}
              </div>
              <div>
                <p className="font-weight-bold">{account.fullname}&nbsp;</p>
                <p className="font-weight-bold">{account.phone}&nbsp;</p>
                <p className="font-weight-bold">{account.email}&nbsp;</p>
                {/* <p className="font-weight-bold">&nbsp;</p> */}
              </div>
            </div>
            <div className="separator separator-dashed mt-2 mb-4"></div>

            <p className="text-grey font-weight-bolder">Thông tin thiết bị xác nhận</p>
            <div className="d-flex">
              <div className="mr-4">
                <p>{t('BrowserName')}:</p>
                <p>{t('OS')}:</p>
                <p>{t('IPAddress')}:</p>
                <p>{t('Position')}:</p>
              </div>
              <div>
                <p className="font-weight-bold">{browserName}&nbsp;</p>
                <p className="font-weight-bold">{`${osName} ${osVersion}`}&nbsp;</p>
                <p className="font-weight-bold">{deviceInfo?.IPv4}&nbsp;</p>
                <p className="font-weight-bold">{deviceInfo?.city}&nbsp;</p>
              </div>
            </div>

            <VIButton
              text={t('ViewPrivacyPolicy')}
              className="btn-white"
              onClick={() => {
                Utils.openInNewTab(AppConfigs.privacyPolicyUrl);
              }}
            />
          </div>

          {/* right */}
          <div className="col-lg-6">
            <div className="d-flex align-items-center justify-content-center h-100">
              <img src="https://id.misacdn.net/img/thoa_thuan.svg" className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountPrivacyPolicyScreen;
