import mailApi from 'api/mailApi';
import { id } from 'date-fns/locale';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// lay ds hop thu
export const thunkGetInboxMailList = createAsyncThunk(
  'mail/getInboxMailList',
  async (params, thunkAPI) => {
    const res = await mailApi.getMailList(params);
    return res;
  }
);
// lay ds thu quan trong
export const thunkGetImportantMailList = createAsyncThunk(
  'mail/getImportantMailList',
  async (params, thunkAPI) => {
    const res = await mailApi.getMailList(params);
    return res;
  }
);
// lay ds thu rac
export const thunkGetSpamMailList = createAsyncThunk(
  'mail/getSpamMailList',
  async (params, thunkAPI) => {
    const res = await mailApi.getMailList(params);
    return res;
  }
);
// danh dau thu quan trong
export const thunkMarkImportant = createAsyncThunk('mail/markImportant', async (ids, thunkAPI) => {
  const res = await mailApi.setImportant(ids);
  return res;
});
// danh dau thu khong quan trong
export const thunkMarkUnImportant = createAsyncThunk(
  'mail/markUnImportant',
  async (ids, thunkAPI) => {
    const res = await mailApi.setUnImportant(ids);
    return res;
  }
);

// danh dau thu da doc
export const thunkMarkReadMail = createAsyncThunk('mail/markReadMail', async (ids, thunkAPI) => {
  const res = await mailApi.setRead(ids);
  return res;
});
// danh dau thu chua doc
export const thunkMarkUnReadMail = createAsyncThunk(
  'mail/markUnReadMail',
  async (ids, thunkAPI) => {
    const res = await mailApi.setUnread(ids);
    return res;
  }
);

// di chuyen thu vao thung rac
export const thunkMoveMailIntoTrash = createAsyncThunk(
  'mail/moveMailIntoTrash',
  async (ids, thunkAPI) => {
    const res = await mailApi.moveIntoTrash(ids);
    return res;
  }
);
// khoi phuc tu thung rac
export const thunkRestoreMailFromTrash = createAsyncThunk(
  'mail/restoreMailFromTrash',
  async (ids, thunkAPI) => {
    const res = await mailApi.restoreFromTrash(ids);
    return res;
  }
);

// xoa vinh vien thu
export const thunkDeleteMail = createAsyncThunk('mail/deleteMail', async (ids, thunkAPI) => {
  const res = await mailApi.deleteMails(ids);
  return res;
});

// kiem tra mail
export const thunkValidateMails = createAsyncThunk('mail/validateMails', async (ids, thunkAPI) => {
  const res = await mailApi.validateMails(ids);
  return res;
});

// forward mail
export const thunkForwardMail = createAsyncThunk('mail/forwardMail', async (params, thunkAPI) => {
  const res = await mailApi.forwardMail(params.mailId, params.params);
  return res;
});

const mailSlice = createSlice({
  name: 'mail',
  initialState: {
    hasMail: false,

    isGettingInboxMailList: false,
    inboxMailList: [{}],
    totalInboxMailList: 0,

    isGettingImportantMailList: false,
    importantMailList: [],
    totalImportantMailList: 0,

    isGettingSpamMailList: false,
    spamMailList: [],
    totalSpamMailList: 0,

    markingImportantMailIds: [],
    markingReadMailIds: [],

    movingMailTrashIds: [],

    selectedMail: null,

    selectedInboxMailIds: [],
    selectedImportantMailIds: [],
    selectedSpamMailIds: [],

    validatingMailIds: [],

    isForwarding: false,
  },
  reducers: {
    // reset hasMail
    resetHasMail: (state, action) => {
      state.hasMail = false;
    },
    // cai dat ds cac mail dang duoc danh dau quan trong
    setMarkingImportantMailIds: (state, action) => {
      const ids = action.payload ?? [];
      state.markingImportantMailIds = ids;
    },
    // danh dau thu quan trong
    setImportantMail: (state, action) => {
      const ids = action.payload ?? [];
      ids.forEach((id) => {
        const inboxMailIndex = _.findIndex(state.inboxMailList, (item) => item.mailId === id);
        if (inboxMailIndex !== -1) {
          state.inboxMailList[inboxMailIndex].isImportant = true;
        }
      });
    },
    // danh dau thu khong quan trong
    setUnImportantMail: (state, action) => {
      const ids = action.payload ?? [];
      ids.forEach((id) => {
        const inboxMailIndex = _.findIndex(state.inboxMailList, (item) => item.mailId === id);
        if (inboxMailIndex !== -1) {
          state.inboxMailList[inboxMailIndex].isImportant = false;
        }
      });
    },

    // cai dat ds cac mail dang duoc danh dau trang thai doc
    setMarkingReadMailIds: (state, action) => {
      const ids = action.payload ?? [];
      state.markingReadMailIds = ids;
    },
    // danh dau thu chua doc
    setUnReadMail: (state, action) => {
      const ids = action.payload ?? [];
      ids.forEach((id) => {
        const inboxMailIndex = _.findIndex(state.inboxMailList, (item) => item.mailId === id);
        if (inboxMailIndex !== -1) {
          state.inboxMailList[inboxMailIndex].readStatus = 'UNREAD';
        }
      });
    },
    // danh dau thu da doc
    setReadMail: (state, action) => {
      const ids = action.payload ?? [];
      ids.forEach((id) => {
        const inboxMailIndex = _.findIndex(state.inboxMailList, (item) => item.mailId === id);
        if (inboxMailIndex !== -1) {
          state.inboxMailList[inboxMailIndex].readStatus = 'READ';
        }
      });
    },

    // cai dat ds cac mail dang duoc di chuyen vao/ra thung rac
    setMovingMailTrashIds: (state, action) => {
      const ids = action.payload ?? [];
      state.movingMailTrashIds = ids;
    },

    // cai dat thu dang duoc chon
    setSelectedMail: (state, action) => {
      state.selectedMail = action.payload;
    },

    // cap nhat ds cac mail inbox dang duoc chon
    setSelectedInboxMailIds: (state, action) => {
      state.selectedInboxMailIds = action.payload;
    },

    // cap nhat ds cac mail important dang duoc chon
    setSelectedImportantMailIds: (state, action) => {
      state.selectedImportantMailIds = action.payload;
    },

    // cap nhat ds cac mail spam dang duoc chon
    setSelectedSpamMailIds: (state, action) => {
      state.selectedSpamMailIds = action.payload;
    },

    // cap nhat ds mailIds dang duoc kiem tra
    setValidatingMailIds: (state, action) => {
      const ids = action.payload ?? [];
      state.validatingMailIds = ids;
    },

    // cap nhat trang thai mail CHECKING
    setMailStatusChecking: (state, action) => {
      const mails = action.payload ?? [];
      mails.forEach((mail) => {
        {
          const foundIndex = _.findIndex(
            state.inboxMailList,
            (item) => item.mailId === mail.mailId
          );
          if (foundIndex !== -1) {
            state.inboxMailList[foundIndex] = mail;
          }
        }
        {
          const foundIndex = _.findIndex(
            state.importantMailList,
            (item) => item.mailId === mail.mailId
          );
          if (foundIndex !== -1) {
            state.importantMailList[foundIndex] = mail;
          }
        }
      });
    },
  },
  extraReducers: {
    // lay ds hop thu
    [thunkGetInboxMailList.pending]: (state, action) => {
      state.isGettingInboxMailList = true;
    },
    [thunkGetInboxMailList.rejected]: (state, action) => {
      state.isGettingInboxMailList = false;
    },
    [thunkGetInboxMailList.fulfilled]: (state, action) => {
      state.isGettingInboxMailList = false;
      const { result, mails, total } = action?.payload;
      if (result === 'success' && mails) {
        state.inboxMailList = mails;
        state.totalInboxMailList = total;
      }
      if (!state.hasMail && total > 0) {
        state.hasMail = true;
      }
    },

    // lay ds thu quan trong
    [thunkGetImportantMailList.pending]: (state, action) => {
      state.isGettingImportantMailList = true;
    },
    [thunkGetImportantMailList.rejected]: (state, action) => {
      state.isGettingImportantMailList = false;
    },
    [thunkGetImportantMailList.fulfilled]: (state, action) => {
      state.isGettingImportantMailList = false;
      const { result, mails, total } = action?.payload;
      if (result === 'success' && mails) {
        state.importantMailList = mails;
        state.totalImportantMailList = total;
      }
    },

    // lay ds thu rac
    [thunkGetSpamMailList.pending]: (state, action) => {
      state.isGettingImportantMailList = true;
    },
    [thunkGetSpamMailList.rejected]: (state, action) => {
      state.isGettingImportantMailList = false;
    },
    [thunkGetSpamMailList.fulfilled]: (state, action) => {
      state.isGettingImportantMailList = false;
      const { result, mails, total } = action?.payload;
      if (result === 'success' && mails) {
        state.spamMailList = mails;
        state.totalSpamMailList = total;
      }
    },

    // danh dau thu quan trong
    [thunkMarkImportant.pending]: (state, action) => {},
    [thunkMarkImportant.rejected]: (state, action) => {
      state.markingImportantMailIds = [];
    },
    [thunkMarkImportant.fulfilled]: (state, action) => {
      state.markingImportantMailIds = [];
      const { result } = action?.payload;
      if (result === 'success') {
      }
    },
    // danh dau thu khong quan trong
    [thunkMarkUnImportant.pending]: (state, action) => {},
    [thunkMarkUnImportant.rejected]: (state, action) => {
      state.markingImportantMailIds = [];
    },
    [thunkMarkUnImportant.fulfilled]: (state, action) => {
      state.markingImportantMailIds = [];
      const { result } = action?.payload;
      if (result === 'success') {
      }
    },

    // danh dau thu da doc
    [thunkMarkReadMail.pending]: (state, action) => {},
    [thunkMarkReadMail.rejected]: (state, action) => {
      state.markingReadMailIds = [];
    },
    [thunkMarkReadMail.fulfilled]: (state, action) => {
      state.markingReadMailIds = [];
      const { result } = action?.payload;
      if (result === 'success') {
      }
    },
    // danh dau thu chua doc
    [thunkMarkUnReadMail.pending]: (state, action) => {},
    [thunkMarkUnReadMail.rejected]: (state, action) => {
      state.markingReadMailIds = [];
    },
    [thunkMarkUnReadMail.fulfilled]: (state, action) => {
      state.markingReadMailIds = [];
      const { result } = action?.payload;
      if (result === 'success') {
      }
    },

    // di chuyen thu vao thung rac
    [thunkMoveMailIntoTrash.pending]: (state, action) => {},
    [thunkMoveMailIntoTrash.rejected]: (state, action) => {
      state.movingMailTrashIds = [];
    },
    [thunkMoveMailIntoTrash.fulfilled]: (state, action) => {
      state.movingMailTrashIds = [];
      const { result } = action?.payload;
      if (result === 'success') {
      }
    },
    // khoi phuc mail tu thung rac
    [thunkRestoreMailFromTrash.pending]: (state, action) => {},
    [thunkRestoreMailFromTrash.rejected]: (state, action) => {
      state.movingMailTrashIds = [];
    },
    [thunkRestoreMailFromTrash.fulfilled]: (state, action) => {
      state.movingMailTrashIds = [];
      const { result } = action?.payload;
      if (result === 'success') {
      }
    },

    // xoa vinh vien thu
    [thunkDeleteMail.pending]: (state, action) => {},
    [thunkDeleteMail.rejected]: (state, action) => {
      state.movingMailTrashIds = [];
    },
    [thunkDeleteMail.fulfilled]: (state, action) => {
      state.movingMailTrashIds = [];
      const { result } = action?.payload;
      if (result === 'success') {
      }
    },

    // kiem tra thu
    [thunkValidateMails.pending]: (state, action) => {},
    [thunkValidateMails.rejected]: (state, action) => {
      state.validatingMailIds = [];
    },
    [thunkValidateMails.fulfilled]: (state, action) => {
      state.validatingMailIds = [];
      const { result } = action?.payload;
      if (result === 'success') {
      }
    },

    // forward thu
    [thunkForwardMail.pending]: (state, action) => {
      state.isForwarding = true;
    },
    [thunkForwardMail.rejected]: (state, action) => {
      state.isForwarding = false;
    },
    [thunkForwardMail.fulfilled]: (state, action) => {
      state.isForwarding = false;
      const { result } = action?.payload;
      if (result === 'success') {
      }
    },
  },
});

const { reducer, actions } = mailSlice;
export const {
  resetHasMail,
  setMarkingImportantMailIds,
  setImportantMail,
  setUnImportantMail,
  setMarkingReadMailIds,
  setReadMail,
  setUnReadMail,
  setMovingMailTrashIds,
  setSelectedMail,
  setSelectedInboxMailIds,
  setSelectedImportantMailIds,
  setSelectedSpamMailIds,
  setValidatingMailIds,
  setMailStatusChecking,
} = actions;
export default reducer;
