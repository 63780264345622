import customDataTableExpandableStyle from 'assets/styles/customDataTableExpandableStyle';
import ExpandedOrganizationComponent from 'features/Organization/components/ExpendedOrganizationComponent';
import ModalAddBranchContent from 'features/Organization/components/ModalAddBranchContent';
import ModalAddCompanyContent from 'features/Organization/components/ModalAddCompanyContent';
import ModalAddMultiBranchContent from 'features/Organization/components/ModalAddMultiBranchContent';
import ModalAddMultiCompanyContent from 'features/Organization/components/ModalAddMultiCompanyContent';
import ModalEditBranchContent from 'features/Organization/components/ModalEditBranchContent';
import ModalEditCompanyContent from 'features/Organization/components/ModalEditCompanyContent';
import { thunkGetAllOrganizations } from 'features/Organization/organizationSlice';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import KeenSelectOption from 'general/components/OtherKeenComponents/KeenSelectOption';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIModal from 'general/components/VietInvoice/VIModal';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import AppData from 'general/constants/AppData';
import OrganizationHelper from 'general/helpers/OrganizationHelper';
import Global from 'general/utils/Global';
import useAccount from 'hooks/useAccount';
import useRouter from 'hooks/useRouter';
import useSystem from 'hooks/useSystem';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

function OrganizationHomeScreen(props) {
  // ---- params ----
  const { t } = useTranslation();
  const [modalAddCompanyShowing, setModalAddCompanyShowing] = useState(false);
  const [modalAddMultiCompanyShowing, setModalAddMultiCompanyShowing] = useState(false);
  const [modalEditCompanyShowing, setModalEditCompanyShowing] = useState(false);
  const [modalEditCompanyWithTaxCodeShowing, setModalEditCompanyWithTaxCodeShowing] =
    useState(false);
  const [modalEditCompanyMultiAddressShowing, setModalEditCompanyMultiAddressShowing] =
    useState(false);
  const [modalAddBranchShowing, setModalAddBranchShowing] = useState(false);
  const [modalAddMultiBranchShowing, setModalAddMultiBranchShowing] = useState(false);
  const [modalEditBranchShowing, setModalEditBranchShowing] = useState(false);
  const [modalAddDepartmentShowing, setModalAddDepartmentShowing] = useState(false);
  const [selectedOrganizationItem, setSelectedOrganizationItem] = useState(null);
  const [filters, setFilters] = useState({
    q: '',
    page: 0,
    limit: Global.gDefaultPagination,
    employeeId: '',
  });
  const account = useAccount();
  const { organizations, isGettingOrganization } = useSelector((state) => state?.organization);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const columns = useMemo(
    () => [
      {
        name: t('UnitName'),
        minWidth: '300px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-bolder m-0 text-maxline-3">
              {row?.organizationName}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        width: '150px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.taxCode}
            </p>
          );
        },
      },
      {
        name: t('EmailReceiveInvoice'),
        width: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.invoiceMailbox}
            </p>
          );
        },
      },
      {
        name: t('Người quản lý'),
        width: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {OrganizationHelper.getEmployeeList(row)}
            </p>
          );
        },
      },
      {
        name: t('InputInvoices'),
        width: '130px',
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="q-max-line-3 font-weight-normal m-0 text-maxline-3"
            >
              {row?.buyInvoices}
            </p>
          );
        },
      },
      {
        name: t('OutputInvoices'),
        width: '130px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.sellInvoices}
            </p>
          );
        },
      },
      {
        name: t('ContactPerson'),
        width: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.representativeName}
            </p>
          );
        },
      },
      {
        name: t('ContactPosition'),
        width: '220px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.representativeJobTitle}
            </p>
          );
        },
      },
      {
        name: t('ContactEmail'),
        width: '220px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.representativeEmail}
            </p>
          );
        },
      },
      {
        name: t('ContactPhone'),
        width: '160px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.representativePhone}
            </p>
          );
        },
      },
      {
        name: t('State'),
        sortable: false,
        width: '130px',
        center: true,
        cell: (row) => {
          return (
            <KTTooltip text={row?.active ?? true ? t('Active') : t('InActive')}>
              {row?.active ?? true ? (
                <i className="fa-regular fa-circle-check text-primary" />
              ) : (
                <i className="fa-regular fa-times-circle text-danger" />
              )}
            </KTTooltip>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        // style: {
        //   position: 'sticky',
        //   right: 0,
        //   backgroundColor: '#fff',
        //   borderLeft: '1px solid #ebedf3',
        // },
        cell: (row) => {
          const menuItems = [];
          if (account.checkPermission(AppData.permissionCode.coCauToChuc.them)) {
            menuItems.push({
              iconElement: <i className="far fa-plus"></i>,
              text: t('AddBranch'),
              click: () => {
                handleAddBranch(row);
              },
            });
          }
          if (account.checkPermission(AppData.permissionCode.coCauToChuc.sua)) {
            menuItems.push({
              iconElement: <i className="far fa-edit"></i>,
              text: t('Edit'),
              click: () => {
                handleEditCompany(row);
              },
            });
          }
          if (account.checkPermission(AppData.permissionCode.coCauToChuc.xoa)) {
            menuItems.push({
              iconElement: <i className="far fa-trash"></i>,
              text: t('Delete'),
              click: () => {
                handleDeleteCompany(row);
              },
            });
          }
          return (
            <div className="d-flex align-items-center justify-content-between">
              {!_.isEmpty(menuItems) && (
                <VITableRowDropDown
                  toggleElement={
                    <div>
                      <i className="text-grey fas fa-ellipsis-h p-0"></i>
                    </div>
                  }
                  menuItem={menuItems}
                />
              )}
            </div>
          );
        },
      },
    ],
    [filteredOrganizations, account]
  );
  const router = useRouter();
  const locationHash = router?.location?.hash;
  const isSelectedOrganizationHasMultiAddresses = useMemo(() => {
    const organizationAddresses = selectedOrganizationItem?.OrganizationAddresses;
    return (
      organizationAddresses && _.isArray(organizationAddresses) && organizationAddresses.length > 0
    );
  }, [selectedOrganizationItem]);
  const { companyEmployeeOptions } = useSystem();

  useEffect(() => {
    if (locationHash) {
      const id = _.replace(locationHash, '#', '');
      if (id === 'create') {
        handleAddCompany();
      }
    }
  }, [locationHash]);

  useEffect(() => {
    const { q, employeeId } = filters;
    const filterItems = OrganizationHelper.filter(organizations, q, employeeId);
    setFilteredOrganizations(filterItems);
  }, [filters, organizations]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isEmpty(organizations)) {
      dispatch(thunkGetAllOrganizations());
    }

    return () => {
      Global.resetTempOrganizationContactInfo();
    };
  }, []);

  // ----- methods -----
  function handleAddCompany() {
    setModalAddCompanyShowing(true);
  }

  function handleEditCompany(row) {
    if (!account.checkPermission(AppData.permissionCode.coCauToChuc.sua)) {
      return;
    }

    const organizationAddresses = row?.OrganizationAddresses;
    const isHasMultiAddresses =
      organizationAddresses && _.isArray(organizationAddresses) && organizationAddresses.length > 0;

    const taxCode = row?.taxCode;
    if (taxCode && taxCode.length > 0) {
      setSelectedOrganizationItem(row);
      if (isHasMultiAddresses) {
        setModalEditCompanyMultiAddressShowing(true);
      } else {
        setModalEditCompanyWithTaxCodeShowing(true);
      }
      return;
    }

    setSelectedOrganizationItem(row);
    setModalEditCompanyShowing(true);
  }

  function handleAddBranch(row) {
    setSelectedOrganizationItem(row);
    setModalAddBranchShowing(true);
  }

  function handleEditBranch() {
    if (!account.checkPermission(AppData.permissionCode.coCauToChuc.sua)) {
      return;
    }
    setModalEditBranchShowing(true);
  }

  function handleAddDepartment() {
    setModalAddDepartmentShowing(true);
  }

  function handleEditDepartment() {
    if (!account.checkPermission(AppData.permissionCode.coCauToChuc.sua)) {
      return;
    }
    setModalAddDepartmentShowing(true);
  }

  function handleDeleteCompany(row) {
    if (row?.organizationId === account.organizationId) {
      AppDialogHelper.show(
        t('Warning'),
        t('CannotDeleteCurrentSelectedOrganization'),
        [
          {
            title: t('Close'),
            onClick: () => {
              AppDialogHelper.hide();
            },
            type: 'neutral',
          },
        ],
        false
      );
      return;
    }
    AppDialogHelper.show(
      t('ConfirmDelete'),
      t('DoYouWantToDeleteCompany', {
        name: row?.organizationName,
      }),
      [
        {
          title: t('Cancel'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
        {
          title: t('Delete'),
          type: 'negative',
          onClick: () => {
            OrganizationHelper.callApiDeleteCompany([row?.organizationId]);
            AppDialogHelper.hide();
          },
        },
      ]
    );
  }

  return (
    <div>
      <VIDataTable
        minusHeight={20}
        additionalClassName="pb-6"
        expandable={true}
        expandedComponent={(row) => {
          return (
            <ExpandedOrganizationComponent level={2} data={row?.data?.ChildrenOrganizations} />
          );
        }}
        tableStyles={customDataTableExpandableStyle}
        draggable={false}
        title={t('Organization')}
        selectable={false}
        pagination={null}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          setFilters({
            ...filters,
            page: iNewPage,
          });
        }}
        onChangeRowsPerPage={(perPage) => {
          setFilters({
            ...filters,
            limit: perPage,
            page: 0,
          });
        }}
        fullColumns={columns}
        data={filteredOrganizations}
        loading={isGettingOrganization}
        filterbar={
          <>
            <VISearchField
              placeholder={`${t('EnterToSearch')}`}
              value={filters.q}
              className=""
              typingTimeout={500}
              onSubmit={(newValue) => {
                setFilters({
                  ...filters,
                  q: newValue,
                  page: 0,
                });
              }}
            />

            {/* loc theo nhan vien */}
            <div className="d-flex align-items-center">
              <label htmlFor="filterByEmployee" className="mb-0 mr-2 text-grey font-weight-bolder">
                {t('FilterByEmployee')}
              </label>
              <KeenSelectOption
                name="filterByEmployee"
                initialValue={filters.employeeId}
                options={[{ name: 'All', value: '' }, ...companyEmployeeOptions]}
                containerClassName=""
                labelClassName=""
                selectClassName="w-100"
                menuClassName="w-200px"
                onValueChanged={(value) => {
                  setFilters({
                    ...filters,
                    employeeId: value,
                  });
                }}
              />
            </div>
          </>
        }
        onRowClick={(row) => {}}
        onRowDoubleClick={(row) => {
          handleEditCompany(row);
        }}
        onSetSelectedMultiItems={(rows) => {}}
        toolbar={
          account.checkPermission(AppData.permissionCode.coCauToChuc.them) ? (
            <>
              <VIButton
                className="btn-blue"
                text={t('AddCompany')}
                beforeIcon={<i className="fa-regular fa-plus"></i>}
                onClick={() => {
                  setModalAddCompanyShowing(true);
                }}
              />
            </>
          ) : null
        }
      />

      {/* modal add company */}
      <VIModal
        titleModal={t('AddCompany')}
        closeBtn
        modalSize="lg"
        contentModal={
          <ModalAddCompanyContent
            onPressCancel={() => {
              setModalAddCompanyShowing(false);
              Global.gTempCompanyInvoiceMailbox = '';
              Global.gTempCompanyTaxCode = '';
            }}
            onCheckedAcceptMultiNameChange={(checked) => {
              if (checked) {
                setModalAddCompanyShowing(false);
                setModalAddMultiCompanyShowing(true);
              }
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setModalAddCompanyShowing(false);
        }}
        show={modalAddCompanyShowing}
      />

      {/* modal add multi company */}
      <VIModal
        titleModal={t('AddCompany')}
        closeBtn
        modalSize="xl"
        contentModal={
          <ModalAddMultiCompanyContent
            onPressCancel={() => {
              setModalAddMultiCompanyShowing(false);
              if (!Global.gTempStateCreateCompanyMultiAddresses) {
                setModalAddCompanyShowing(true);
              }
              Global.gTempStateCreateCompanyMultiAddresses = false;
            }}
            onCheckedAcceptMultiNameChange={(checked) => {
              if (!checked) {
                setModalAddCompanyShowing(true);
                setModalAddMultiCompanyShowing(false);
              }
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setModalAddMultiCompanyShowing(false);
          if (!Global.gTempStateCreateCompanyMultiAddresses) {
            setModalAddCompanyShowing(true);
          }
          Global.gTempStateCreateCompanyMultiAddresses = false;
        }}
        show={modalAddMultiCompanyShowing}
      />

      {/* modal edit company without tax code */}
      <VIModal
        titleModal={t('EditCompany')}
        closeBtn
        contentModal={
          <ModalEditCompanyContent
            item={selectedOrganizationItem}
            onPressCancel={() => {
              setSelectedOrganizationItem(null);
              setModalEditCompanyShowing(false);
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setSelectedOrganizationItem(null);
          setModalEditCompanyShowing(false);
        }}
        show={modalEditCompanyShowing}
      />

      {/* modal edit company with tax code */}
      <VIModal
        titleModal={t('EditCompany')}
        closeBtn
        modalSize="lg"
        contentModal={
          <ModalAddCompanyContent
            company={selectedOrganizationItem}
            onPressCancel={() => {
              setModalEditCompanyWithTaxCodeShowing(false);
            }}
            onCheckedAcceptMultiNameChange={(checked) => {
              if (checked) {
                setModalEditCompanyWithTaxCodeShowing(false);
                setModalEditCompanyMultiAddressShowing(true);
              }
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setModalEditCompanyWithTaxCodeShowing(false);
        }}
        show={modalEditCompanyWithTaxCodeShowing}
      />

      {/* modal edit company with multi addresses */}
      <VIModal
        titleModal={t('EditCompany')}
        closeBtn
        modalSize="xl"
        contentModal={
          <ModalAddMultiCompanyContent
            company={selectedOrganizationItem}
            onPressCancel={() => {
              setModalEditCompanyMultiAddressShowing(false);
              if (!Global.gTempStateCreateCompanyMultiAddresses) {
                if (!isSelectedOrganizationHasMultiAddresses) {
                  setModalEditCompanyWithTaxCodeShowing(true);
                }
                Global.gTempStateCreateCompanyMultiAddresses = false;
              }
            }}
            onCheckedAcceptMultiNameChange={(checked) => {
              if (!checked) {
                setModalEditCompanyWithTaxCodeShowing(true);
                setModalEditCompanyMultiAddressShowing(false);
              }
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setModalEditCompanyMultiAddressShowing(false);
          if (!Global.gTempStateCreateCompanyMultiAddresses) {
            if (!isSelectedOrganizationHasMultiAddresses) {
              setModalEditCompanyWithTaxCodeShowing(true);
            }
            Global.gTempStateCreateCompanyMultiAddresses = false;
          }
        }}
        show={modalEditCompanyMultiAddressShowing}
      />

      {/* modal add branch */}
      <VIModal
        titleModal={t('AddBranch')}
        closeBtn
        modalSize="lg"
        contentModal={
          <ModalAddBranchContent
            parentCompany={selectedOrganizationItem}
            onPressCancel={() => {
              setSelectedOrganizationItem(null);
              setModalAddBranchShowing(false);
              Global.gTempBranchInvoiceMailbox = '';
              Global.gTempBranchTaxCode = '';
            }}
            onCheckedAcceptMultiNameChange={(checked) => {
              if (checked) {
                setModalAddBranchShowing(false);
                setModalAddMultiBranchShowing(true);
              }
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setSelectedOrganizationItem(null);
          setModalAddBranchShowing(false);
          Global.gTempBranchInvoiceMailbox = '';
          Global.gTempBranchTaxCode = '';
        }}
        show={modalAddBranchShowing}
      />

      {/* modal add multi branch */}
      <VIModal
        titleModal={t('AddBranch')}
        closeBtn
        modalSize="xl"
        contentModal={
          <ModalAddMultiBranchContent
            parentCompany={selectedOrganizationItem}
            onPressCancel={() => {
              setModalAddMultiBranchShowing(false);
              if (!Global.gTempStateCreateBranchMultiAddresses) {
                setModalAddBranchShowing(true);
              }
              Global.gTempStateCreateBranchMultiAddresses = false;
            }}
            onCheckedAcceptMultiNameChange={(checked) => {
              if (!checked) {
                setModalAddBranchShowing(true);
                setModalAddMultiBranchShowing(false);
              }
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setModalAddMultiBranchShowing(false);
          if (!Global.gTempStateCreateBranchMultiAddresses) {
            setModalAddBranchShowing(true);
          }
          Global.gTempStateCreateBranchMultiAddresses = false;
        }}
        show={modalAddMultiBranchShowing}
      />

      {/* modal edit branch */}
      <VIModal
        titleModal={t('EditBranch')}
        closeBtn
        modalSize="lg"
        contentModal={
          <ModalEditBranchContent
            onPressCancel={() => {
              setModalEditBranchShowing(false);
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setModalEditBranchShowing(false);
        }}
        show={modalEditBranchShowing}
      />
    </div>
  );
}

export default OrganizationHomeScreen;
