import CardInvoiceAccumulated from 'features/System/components/CardInvoiceAccumulated';
import CardInvoiceInfo from 'features/System/components/CardInvoiceInfo';
import OrderManagementTable from 'features/System/components/OrderManagementTable';
import ResourceProvidingHistory from 'features/System/components/ResourceProvidingHistory';
import VIAppTabs from 'general/components/VietInvoice/VIAppTabs';
import useAccount from 'hooks/useAccount';
import useRouter from 'hooks/useRouter';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function SystemResourceScreen(props) {
  // ---- params ----
  const { t } = useTranslation();
  const tabList = useMemo(() => {
    return [t('HistoryByResource'), t('PurchasePaymentHistory')];
  }, []);
  const [activeTab, setActiveTab] = useState(tabList[1]);
  const router = useRouter();
  const refCards = useRef(null);
  const refTabs = useRef(null);
  const account = useAccount();

  return (
    // py-6 px-9
    <div className="h-100 d-flex flex-column justify-content-between">
      {/* card info */}
      <div ref={refCards} className="row">
        {/* hoa don duoc su dung */}
        <div className="col-lg-6 mb-6">
          <CardInvoiceInfo
            onPressExtendBuyMore={() => {
              router.navigate('/store');
            }}
          />
        </div>

        {/* thong tin hoa don luy ke */}
        <div className="col-lg-6 mb-6">
          <CardInvoiceAccumulated />
        </div>
      </div>

      {/* lich su cap tai nguyen + quan ly don hang */}
      <div className="flex-grow-1 d-flex flex-column justify-content-between">
        <div ref={refTabs}>
          <VIAppTabs
            tabs={tabList}
            activeTab={activeTab}
            handleClick={(tab) => {
              setActiveTab(tab);
            }}
          />
        </div>
        <div
          className="bg-white border-left border-right border-bottom flex-grow-1"
          style={{
            borderBottomRightRadius: '20px',
            borderBottomLeftRadius: '20px',
          }}
        >
          {activeTab === t('PurchasePaymentHistory') ? (
            <OrderManagementTable
              minusHeight={refCards.current?.offsetHeight + refTabs.current?.offsetHeight}
            />
          ) : (
            <ResourceProvidingHistory
              minusHeight={refCards.current?.offsetHeight + refTabs.current?.offsetHeight}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SystemResourceScreen;
