import axiosClient from './axiosClient';

const instructionApi = {
  // lay tat ca instructions
  getAll: () => {
    const url = '/instruction/find';
    return axiosClient.get(url);
  },
};

export default instructionApi;
