import AppConfigs from './AppConfigs';

// All app preference keys
const PreferenceKeys = {
  // token
  accessToken: `${AppConfigs.packageName}_accessToken`,
  accessTokenExpired: `${AppConfigs.packageName}_accessTokenExpired`,
  // login info
  savedLoginEmail: `${AppConfigs.packageName}_savedLoginEmail`,
  savedLoginTaxCode: `${AppConfigs.packageName}_savedLoginTaxCode`,
  savedLoginPassword: `${AppConfigs.packageName}_savedLoginPassword`,
  savedLoginAccountId: `${AppConfigs.packageName}_savedLoginAccountId`,

  // notification
  savedNotificationReadIds: `${AppConfigs.packageName}_savedNotificationReadIds`,

  // axion
  savedAxionUsername: `${AppConfigs.packageName}_savedAxionUsername`,
  savedAxionPassword: `${AppConfigs.packageName}_savedAxionPassword`,
  savedAxionSwitchInvoiceIn: `${AppConfigs.packageName}_savedAxionSwitchInvoiceIn`,
  savedAxionSwitchInvoiceOut: `${AppConfigs.packageName}_savedAxionSwitchInvoiceOut`,

  //invoice
  //ductu
  saveInputInvoice: `${AppConfigs.packageName}_saveInputInvoice`,
  saveInputDeleteInvoice: `${AppConfigs.packageName}_saveInputDeleteInvoice`,
  saveOutputInvoice: `${AppConfigs.packageName}_saveOutputInvoice`,
  saveOutputDeleteInvoice: `${AppConfigs.packageName}_saveOutputDeleteInvoice`,

  //thiết lập cột
  saveColumnInputInvoice: `${AppConfigs.packageName}_saveColumnInputInvoice`,
  saveColumnOutputInvoice: `${AppConfigs.packageName}_saveColumnOutputInvoice`,
  saveColumnNCC: `${AppConfigs.packageName}_saveColumnNCC`,
};

export default PreferenceKeys;
