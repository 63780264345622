import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function usePermissionCode(props) {
  // ---- params ----
  const { allPermissionCodes } = useSelector((state) => state.system);

  return useMemo(() => {
    const groupedPermissionCodes = _.groupBy(allPermissionCodes, 'groupName');

    return {
      groupedPermissionCodes,
      allPermissionCodes,
    };
  }, [allPermissionCodes]);
}

export default usePermissionCode;
