import axiosClient from './axiosClient';

const partnerCompanyApi = {
  // lay tat cả danh sach cong ty
  getAll: (params) => {
    const url = '/partner-company/find';
    return axiosClient.get(url, { params });
  },
};

export default partnerCompanyApi;
