import CellNotification from 'features/Notification/components/CellNotification';
import {
  markNotificationRead,
  thunkGetNotificationList,
} from 'features/Notification/notificationSlice';
import VIDateRangePicker2 from 'general/components/VietInvoice/VIDateRangePicker2';
import VIOverViewBar from 'general/components/VietInvoice/VIOverViewBar';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import NotificationHelper from 'general/helpers/NotificationHelper';
import Global from 'general/utils/Global';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EmptyView from '../EmptyView';
import './style.scss';

DropdownHeaderNotification.propTypes = {
  onPressNotification: PropTypes.func,
};

DropdownHeaderNotification.defaultProps = {
  onPressNotification: null,
};

function DropdownHeaderNotification(props) {
  // ---- params ----
  const router = useRouter();
  const { showDropdown, onPressNotification } = props;
  const { t } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { notificationList, isGettingNotificationList } = useSelector(
    (state) => state.notification
  );
  const headerTab = useMemo(() => {
    return [
      {
        title: t('All'),
        status: 'active',
      },
      {
        title: t('Activity'),
        status: '',
      },
      {
        title: t('System'),
        status: '',
      },
      {
        title: t('Promotion'),
        status: '',
      },
    ];
  }, [t]);
  const activityNotificationList = useMemo(() => {
    return _.filter(notificationList, (item) => item?.type === AppData.notificationType.activity);
  }, [notificationList]);
  const systemNotificationList = useMemo(() => {
    return _.filter(notificationList, (item) => item?.type === AppData.notificationType.system);
  }, [notificationList]);
  const promotionNotificationList = useMemo(() => {
    return _.filter(notificationList, (item) => item?.type === AppData.notificationType.promotion);
  }, [notificationList]);
  const dispatch = useDispatch();

  // ---- methods ----
  const handleClickOverviewBar = (x) => {
    setSelectedTabIndex(x);
    switch (x) {
      case 0:
        // tat ca
        Global.gFiltersNotificationList.type = '';
        break;
      case 1:
        // hoat dong
        Global.gFiltersNotificationList.type = AppData.notificationType.activity;
        break;
      case 2:
        // he thong
        Global.gFiltersNotificationList.type = AppData.notificationType.system;
        break;
      case 3:
        // khuyen mai
        Global.gFiltersNotificationList.type = AppData.notificationType.promotion;
        break;
      default:
        break;
    }
    dispatch(thunkGetNotificationList(Global.gFiltersNotificationList));
  };

  function handlePressNotification(item) {
    NotificationHelper.markReadNotification(item?.notificationId);
    if (!item?.isRead) {
      dispatch(markNotificationRead(item?.notificationId));
    }
    if (onPressNotification) {
      onPressNotification(item);
    }
    console.log({ item });
    const { targetCode } = item;
    if (targetCode === 'PARTNER_SYNC') {
      router.navigate('/inbot/category/supplier-customer');
    }
    if (targetCode === 'SUPPLIER_SYNC') {
      router.navigate('/inbot/supplier/connect');
    }
    if (targetCode === 'CQT_SYNC') {
      router.navigate('/inbot/axions/connect');
    }
  }

  return (
    <div className="DropdownHeaderNotification px-4 py-3 w-100">
      <div className="DropdownHeaderNotification_Header py-2">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <p className="font-size-h3 font-weight-bolder mb-0">{t('Notification')}</p>
          <div className="d-flex align-items-center">
            <VIDateRangePicker2
              alignRight={true}
              initialValue={Global.gFiltersNotificationList.dateRangeValue}
              onPressSave={(selectedValue, startDate, endDate) => {
                Global.gFiltersNotificationList = {
                  ...Global.gFiltersNotificationList,
                  from: startDate,
                  to: endDate,
                  dateRangeValue: selectedValue,
                };
                dispatch(thunkGetNotificationList(Global.gFiltersNotificationList));
              }}
            />
          </div>
        </div>
        <VIOverViewBar
          itemTab={headerTab}
          currentSelect={selectedTabIndex}
          handleClick={handleClickOverviewBar}
          showDropdown={showDropdown}
        />
      </div>
      <div className="DropdownHeaderNotification_Content">
        {selectedTabIndex === 1 ? (
          activityNotificationList?.length > 0 ? (
            <div>
              {activityNotificationList?.map((item, index) => {
                return (
                  <div key={index}>
                    <CellNotification
                      item={item}
                      onPress={() => {
                        handlePressNotification(item);
                      }}
                    />
                    <div className="separator separator-dashed my-1"></div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="h-100">
              <EmptyView
                message={t('NoNotifications')}
                image={AppResource.icons.icNotificationActivity}
                imgClassName="w-50"
              />
            </div>
          )
        ) : selectedTabIndex === 2 ? (
          systemNotificationList?.length > 0 ? (
            <div>
              {systemNotificationList?.map((item, index) => {
                return (
                  <div key={index}>
                    <CellNotification
                      item={item}
                      onPress={() => {
                        handlePressNotification(item);
                      }}
                    />
                    <div className="separator separator-dashed my-1"></div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="h-100">
              <EmptyView
                message={t('NoNotifications')}
                image={AppResource.icons.icNotificationSystem}
                imgClassName="w-50"
              />
            </div>
          )
        ) : selectedTabIndex === 3 ? (
          promotionNotificationList?.length > 0 ? (
            <div>
              {promotionNotificationList?.map((item, index) => {
                return (
                  <div key={index}>
                    <CellNotification
                      item={item}
                      onPress={() => {
                        handlePressNotification(item);
                      }}
                    />
                    <div className="separator separator-dashed my-1"></div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="h-100">
              <EmptyView
                message={t('NoNotifications')}
                image={AppResource.icons.icNotificationPromotion}
                imgClassName="w-50"
              />
            </div>
          )
        ) : notificationList?.length > 0 ? (
          <div>
            {notificationList?.map((item, index) => {
              return (
                <div key={index}>
                  <CellNotification
                    item={item}
                    onPress={() => {
                      handlePressNotification(item);
                    }}
                  />
                  <div className="separator separator-dashed my-1"></div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="h-100">
            <EmptyView
              message={t('NoNotifications')}
              image={AppResource.icons.icNotificationActivity}
              imgClassName="w-50"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DropdownHeaderNotification;
