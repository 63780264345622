import accountApi from 'api/accountApi';
import auditLogApi from 'api/auditLogApi';
import companyTitleApi from 'api/companyTitleApi';
import miscApi from 'api/miscApi';
import paymentApi from 'api/paymentApi';
import systemRoleApi from 'api/systemRoleApi';
import systemUserApi from 'api/systemUserApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks -----
// lay tat ca chuc danh trong cong ty
export const thunkGetAllCompanyTitles = createAsyncThunk(
  'system/getAllCompanyTitles',
  async (params, thunkAPI) => {
    const res = await companyTitleApi.getAll();
    return res;
  }
);

// tao chuc danh moi
export const thunkCreateCompanyTitle = createAsyncThunk(
  'system/createCompanyTitle',
  async (params, thunkAPI) => {
    const res = await companyTitleApi.createNew(params);
    return res;
  }
);

// xoa chuc danh
export const thunkDeleteCompanyTitle = createAsyncThunk(
  'system/deleteCompanyTitle',
  async (ids, thunkAPI) => {
    const res = await companyTitleApi.delete(ids);
    return res;
  }
);

// lay tat ca nhan vien
export const thunkGetAllSystemUsers = createAsyncThunk(
  'system/getAllSystemUsers',
  async (params, thunkAPI) => {
    const res = await systemUserApi.getAll(params);
    return res;
  }
);

// tao nhan vien
export const thunkCreateSystemUser = createAsyncThunk(
  'system/createSystemUser',
  async (params, thunkAPI) => {
    const res = await systemUserApi.createNew(params);
    return res;
  }
);

// cap nhat thong tin nhan vien
export const thunkUpdateSystemUser = createAsyncThunk(
  'system/updateSystemUser',
  async (params, thunkAPI) => {
    const res = await systemUserApi.updateInfo(params?.id, params?.params);
    return res;
  }
);

// xoa nhan vien
export const thunkDeleteSystemUsers = createAsyncThunk(
  'system/thunkDeleteSystemUsers',
  async (ids, thunkAPI) => {
    const res = await systemUserApi.delete(ids);
    return res;
  }
);

// lat tat ca roles
export const thunkGetAllSystemRoles = createAsyncThunk(
  'system/getAllSystemRoles',
  async (params, thunkAPI) => {
    const res = await systemRoleApi.getAll(params);
    return res;
  }
);

// tao role moi
export const thunkCreateRole = createAsyncThunk('system/createRole', async (params, thunkAPI) => {
  const res = await systemRoleApi.createNew(params);
  return res;
});

// xoa role
export const thunkDeleteRole = createAsyncThunk('system/deleteRole', async (ids, thunkAPI) => {
  const res = await systemRoleApi.delete(ids);
  return res;
});

// cap nhat thong tin role
export const thunkUpdateRole = createAsyncThunk('system/updateRole', async (params, thunkAPI) => {
  const res = await systemRoleApi.updateInfo(params?.id, params?.params);
  return res;
});

// lat tat ca permission codes
export const thunkGetAllPermissionCodes = createAsyncThunk(
  'system/getAllPermissionCodes',
  async (params, thunkAPI) => {
    const res = await miscApi.getAllPermissionCodes();
    return res;
  }
);

// lay danh sach nhat ky truy cap
export const thunkGetAuditLogs = createAsyncThunk(
  'system/getAuditLogs',
  async (params, thunkAPI) => {
    const res = await auditLogApi.find(params);
    return res;
  }
);

// lay danh sach lich su mua hang
export const thunkGetPaymentHistory = createAsyncThunk(
  'system/getPaymentHistory',
  async (params, thunkAPI) => {
    const res = await paymentApi.getOrderHistory(params);
    return res;
  }
);

// lay danh sach lich su cap tai nguyen
export const thunkGetResourceHistory = createAsyncThunk(
  'system/getResourceHistory',
  async (params, thunkAPI) => {
    const res = await accountApi.getResourceHistory(params);
    return res;
  }
);

const systemSlice = createSlice({
  name: 'system',
  initialState: {
    allCompanyTitleList: [],
    isCreatingCompanyTitle: false,
    isDeletingCompanyTitle: false,

    allSystemUserList: [],
    isGettingSystemUserList: false,
    isCreatingSystemUser: false,
    isUpdatingSystemUser: false,
    isDeletingSystemUser: false,

    allSystemRoleList: [],
    isGettingSystemRoleList: false,
    isCreatingSystemRole: false,
    isUpdatingSystemRole: false,
    isDeletingSystemRole: false,

    allPermissionCodes: [],

    auditingLogList: [],
    isGettingAuditingLogList: false,
    totalAuditingLog: 0,

    orders: [],
    isGettingOrder: false,

    // lich su mua hang
    paymentHistoryList: [],
    isGettingPaymentHistory: false,
    totalPaymentHistory: 0,

    // lich su cap tai nguyen
    resourceHistoryList: [],
    isGettingResourceHistory: false,
  },
  reducers: {
    systemSignOut: (state, action) => {
      state.allCompanyTitleList = [];
      state.isCreatingCompanyTitle = false;
      state.isDeletingCompanyTitle = false;
      state.allSystemUserList = [];
      state.isGettingSystemUserList = false;
      state.isCreatingSystemUser = false;
      state.isUpdatingSystemUser = false;
      state.isDeletingSystemUser = false;
      state.allSystemRoleList = [];
      state.isGettingSystemRoleList = false;
      state.isCreatingSystemRole = false;
      state.isUpdatingSystemRole = false;
      state.isDeletingSystemRole = false;
      state.allPermissionCodes = [];
      state.auditingLogList = [];
      state.isGettingAuditingLogList = false;
      state.totalAuditingLog = 0;
      state.orders = [];
      state.isGettingOrder = false;
      state.paymentHistoryList = [];
      state.isGettingPaymentHistory = false;
      state.totalPaymentHistory = 0;
      state.resourceHistoryList = [];
      state.isGettingResourceHistory = false;
    },
  },
  extraReducers: {
    // lay tat ca chuc danh trong cong ty
    [thunkGetAllCompanyTitles.fulfilled]: (state, action) => {
      const { result, companyTitle } = action.payload;
      if (result === 'success' && companyTitle) {
        state.allCompanyTitleList = companyTitle;
      }
    },

    // tao chuc danh moi
    [thunkCreateCompanyTitle.pending]: (state, action) => {
      state.isCreatingCompanyTitle = true;
    },
    [thunkCreateCompanyTitle.rejected]: (state, action) => {
      state.isCreatingCompanyTitle = false;
    },
    [thunkCreateCompanyTitle.fulfilled]: (state, action) => {
      state.isCreatingCompanyTitle = false;
    },

    // xoa chuc danh
    [thunkDeleteCompanyTitle.pending]: (state, action) => {
      state.isDeletingCompanyTitle = true;
    },
    [thunkDeleteCompanyTitle.rejected]: (state, action) => {
      state.isDeletingCompanyTitle = false;
    },
    [thunkDeleteCompanyTitle.fulfilled]: (state, action) => {
      state.isDeletingCompanyTitle = false;
    },

    // lay tat ca nhan vien
    [thunkGetAllSystemUsers.fulfilled]: (state, action) => {
      const { result, accounts } = action.payload;
      if (result === 'success' && accounts) {
        state.allSystemUserList = accounts;
      }
    },

    // cap nhat thong tin nhan vien
    [thunkUpdateSystemUser.pending]: (state, action) => {
      state.isUpdatingSystemUser = true;
    },
    [thunkUpdateSystemUser.rejected]: (state, action) => {
      state.isUpdatingSystemUser = false;
    },
    [thunkUpdateSystemUser.fulfilled]: (state, action) => {
      state.isUpdatingSystemUser = false;
    },

    // tao moi nhan vien
    [thunkCreateSystemUser.pending]: (state, action) => {
      state.isCreatingSystemUser = true;
    },
    [thunkCreateSystemUser.rejected]: (state, action) => {
      state.isCreatingSystemUser = false;
    },
    [thunkCreateSystemUser.fulfilled]: (state, action) => {
      state.isCreatingSystemUser = false;
    },

    // xoa nhan vien
    [thunkDeleteSystemUsers.pending]: (state, action) => {
      state.isDeletingSystemUser = true;
    },
    [thunkDeleteSystemUsers.rejected]: (state, action) => {
      state.isDeletingSystemUser = false;
    },
    [thunkDeleteSystemUsers.fulfilled]: (state, action) => {
      state.isDeletingSystemUser = false;
    },

    // lay tat ca roles
    [thunkGetAllSystemRoles.fulfilled]: (state, action) => {
      const { result, role } = action.payload;
      if (result === 'success' && role) {
        state.allSystemRoleList = role;
      }
    },

    // tao role moi
    [thunkCreateRole.pending]: (state, action) => {
      state.isCreatingSystemRole = true;
    },
    [thunkCreateRole.rejected]: (state, action) => {
      state.isCreatingSystemRole = false;
    },
    [thunkCreateRole.fulfilled]: (state, action) => {
      state.isCreatingSystemRole = false;
    },

    // xoa role
    [thunkDeleteRole.pending]: (state, action) => {
      state.isDeletingSystemRole = true;
    },
    [thunkDeleteRole.rejected]: (state, action) => {
      state.isDeletingSystemRole = false;
    },
    [thunkDeleteRole.fulfilled]: (state, action) => {
      state.isDeletingSystemRole = false;
    },

    // cap nhat role
    [thunkUpdateRole.pending]: (state, action) => {
      state.isUpdatingSystemRole = true;
    },
    [thunkUpdateRole.rejected]: (state, action) => {
      state.isUpdatingSystemRole = false;
    },
    [thunkUpdateRole.fulfilled]: (state, action) => {
      state.isUpdatingSystemRole = false;
    },

    // lay tat ca permission codes
    [thunkGetAllPermissionCodes.fulfilled]: (state, action) => {
      const { result, permissionCode } = action.payload;
      if (result === 'success' && permissionCode) {
        state.allPermissionCodes = permissionCode;
      }
    },

    // lay danh sach audit log
    [thunkGetAuditLogs.pending]: (state, action) => {
      state.isGettingAuditingLogList = true;
    },
    [thunkGetAuditLogs.rejected]: (state, action) => {
      state.isGettingAuditingLogList = false;
    },
    [thunkGetAuditLogs.fulfilled]: (state, action) => {
      state.isGettingAuditingLogList = false;
      const { result, auditLogs, total } = action.payload;
      if (result === 'success' && auditLogs) {
        state.auditingLogList = auditLogs;
        state.totalAuditingLog = total;
      }
    },

    // lay danh sach lich su mua hang
    [thunkGetPaymentHistory.pending]: (state, action) => {
      state.isGettingPaymentHistory = true;
    },
    [thunkGetPaymentHistory.rejected]: (state, action) => {
      state.isGettingPaymentHistory = false;
    },
    [thunkGetPaymentHistory.fulfilled]: (state, action) => {
      state.isGettingPaymentHistory = false;
      const { result, orders, total } = action.payload;
      if (result === 'success' && orders) {
        state.paymentHistoryList = orders;
        state.totalPaymentHistory = total;
      }
    },

    // lay danh sach lich su cap tai nguyen
    [thunkGetResourceHistory.pending]: (state, action) => {
      state.isGettingResourceHistory = true;
    },
    [thunkGetResourceHistory.rejected]: (state, action) => {
      state.isGettingResourceHistory = false;
    },
    [thunkGetResourceHistory.fulfilled]: (state, action) => {
      state.isGettingResourceHistory = false;
      const { result, resourceHistories } = action.payload;
      if (result === 'success' && resourceHistories) {
        state.resourceHistoryList = resourceHistories;
      }
    },
  },
});

const { reducer, actions } = systemSlice;
export const { systemSignOut } = actions;
export default reducer;
