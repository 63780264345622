import { updateConfirmPaymentInfo, updatePaymentType } from 'features/Payment/paymentSlice';
import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTFormTextArea from 'general/components/OtherKeenComponents/Forms/KTFormTextArea';
import KTRadio from 'general/components/OtherKeenComponents/KTFormControls/KTRadio';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppConfigs from 'general/constants/AppConfigs';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import ToastHelper from 'general/helpers/ToastHelper';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useConfig from 'hooks/useConfig';
import usePayment from 'hooks/usePayment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import ConfirmPaymentStep1Info from '../ConfirmPaymentStep1Info';
import ConfirmPaymentStep2Info from '../ConfirmPaymentStep2Info';
import './style.scss';
import { ClockLoader } from 'react-spinners';

CartConfirmPaymentInfo.propTypes = {
  step: PropTypes.number,
  onStepDone: PropTypes.func,
  onEditStep1: PropTypes.func,
  onEditStep2: PropTypes.func,
  onPayment: PropTypes.func,
  creatingOrder: PropTypes.bool,
};

CartConfirmPaymentInfo.defaultProps = {
  step: 1,
  onStepDone: null,
  onEditStep1: null,
  onEditStep2: null,
  onPayment: null,
  creatingOrder: false,
};

/**
 *
 * @param {{
 * step: number,
 * onStepDone: function,
 * onEditStep1: function,
 * onEditStep2: function,
 * onPayment: function,
 * creatingOrder: boolean,
 * }} props
 * @returns
 */
function CartConfirmPaymentInfo(props) {
  // ---- params -----
  const { step, onStepDone, onEditStep1, onEditStep2, onPayment, creatingOrder } = props;
  const { t } = useTranslation();
  const {
    cartTotalPrice,
    cartPriceUnit,
    canShoppingCart,
    shoppingCartList,
    shoppingCartQuantityBuyMore,
    shoppingCartDurationExpired,
    confirmPaymentInfo,
    bankTransferContent,
    lastPriceToPay,
    isBankTransfer,
  } = usePayment();
  const account = useAccount();
  const dispatch = useDispatch();
  const appConfig = useConfig();
  const [accordionActiveKey, setAccordionActiveKey] = useState(isBankTransfer ? '0' : '1');

  // ---- methods ----
  function handleStepDone() {
    if (onStepDone) {
      onStepDone(step);
    }
  }

  function handleEditStep1() {
    if (onEditStep1) {
      onEditStep1();
    }
  }

  function handleEditStep2() {
    if (onEditStep2) {
      onEditStep2();
    }
  }

  function handlePayment() {
    if (onPayment) {
      onPayment();
    }
  }

  return (
    <div
    // className="bg-white p-6"
    // style={{
    //   borderRadius: '16px',
    //   border: '1px solid #E8E9EB',
    // }}
    >
      {step === 1 ? (
        // thong tin doi tuong su dung
        <div
          className="bg-white p-6"
          style={{
            borderRadius: '16px',
            border: '1px solid #E8E9EB',
          }}
        >
          <Formik
            initialValues={{
              // thong tin don vi
              companyTaxCode: confirmPaymentInfo.companyTaxCode ?? account.company.taxCode,
              companyName: confirmPaymentInfo.companyName ?? account.company.name,
              companyAddress: confirmPaymentInfo.companyAddress ?? account.company.address,

              // nguoi dai dien mua hang
              buyerName: confirmPaymentInfo.buyerName ?? account.fullname,
              buyerPhone: confirmPaymentInfo.buyerPhone ?? account.phone,
              buyerEmail: confirmPaymentInfo.buyerEmail ?? account.email,
              buyerPosition: confirmPaymentInfo.buyerPosition ?? '',
            }}
            validationSchema={Yup.object({
              companyTaxCode: Yup.string()
                .required(t('TaxCodeRequired'))
                .trim()
                .test('len', t('TaxCodeInvalid', { field: t('taxCode') }), (val) => {
                  if (val) {
                    return (
                      !val.toString().includes(' ') &&
                      (val.toString().match(/^[0-9]{10}$/) ||
                        val.toString().match(/^[0-9]{10}-[0-9]{3}$/))
                    );
                  }
                }),
              companyName: Yup.string().required(t('Required')),
              companyAddress: Yup.string().required(t('Required')),

              buyerName: Yup.string().required(t('Required')),
              buyerPhone: Yup.string()
                .required(t('Required'))
                .test('len', t('PhoneInvalid', { field: t('buyerPhone') }), (val) => {
                  if (val) {
                    return (
                      !val.toString().includes(' ') && val.toString().match(AppConfigs.regexPhone)
                    );
                  }
                  return true;
                }),
              buyerEmail: Yup.string().required(t('Required')).email(t('EmailInvalid')),
              buyerPosition: Yup.string().required(t('Required')),
            })}
            enableReinitialize
            onSubmit={(values) => {
              dispatch(updateConfirmPaymentInfo(values));
              handleStepDone();
            }}
          >
            {(formikProps) => {
              return (
                <div>
                  <div className="">
                    <div className="d-flex align-items-center gap-2">
                      <i
                        className="fa-regular fa-circle-user text-primary"
                        style={{
                          fontSize: '24px',
                        }}
                      />
                      <span className="font-weight-bolder text-grey font-size-lg">
                        Thông tin đối tượng sử dụng
                      </span>
                    </div>
                  </div>

                  {/* thong tin don vi */}
                  <div
                    className="mt-6 p-4 rounded-lg"
                    style={{
                      border: '1px solid #E8E9EB',
                    }}
                  >
                    <span className="font-weight-bolder text-grey font-size-lg">
                      Thông tin đơn vị
                    </span>
                    <div className="row mt-4">
                      {/* ma so thue */}
                      <div className="col-lg-6">
                        <KTFormGroup
                          label={
                            <>
                              {t('TaxCode')} <span className="text-danger">(*)</span>
                            </>
                          }
                          inputName="companyTaxCode"
                          inputElement={
                            <FastField name="companyTaxCode">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(t('TaxCode'))}`}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>

                      {/* ten cong ty */}
                      <div className="col-lg-6">
                        <KTFormGroup
                          label={
                            <>
                              {t('CompanyName')} <span className="text-danger">(*)</span>
                            </>
                          }
                          inputName="companyName"
                          inputElement={
                            <FastField name="companyName">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(t('CompanyName'))}`}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>

                      {/* dia chi cong ty */}
                      <div className="col-12">
                        <KTFormGroup
                          label={
                            <>
                              {t('CompanyAddress')} <span className="text-danger">(*)</span>
                            </>
                          }
                          additionalClassName="mb-0"
                          inputName="companyAddress"
                          inputElement={
                            <FastField name="companyAddress">
                              {({ field, form, meta }) => (
                                <KTFormTextArea
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  placeholder={`${_.capitalize(t('CompanyAddress'))}`}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* nguoi dai dien mua hang */}
                  <div
                    className="mt-6 p-4 rounded-lg"
                    style={{
                      border: '1px solid #E8E9EB',
                    }}
                  >
                    <span className="font-weight-bolder text-grey font-size-lg">
                      Người đại diện mua hàng
                    </span>
                    <div className="row mt-4">
                      {/* ho va ten */}
                      <div className="col-lg-6">
                        <KTFormGroup
                          label={
                            <>
                              {t('Fullname')} <span className="text-danger">(*)</span>
                            </>
                          }
                          inputName="buyerName"
                          inputElement={
                            <FastField name="buyerName">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(t('Fullname'))}`}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>

                      {/* so dien thoai */}
                      <div className="col-lg-6">
                        <KTFormGroup
                          label={
                            <>
                              {t('PhoneNumber')} <span className="text-danger">(*)</span>
                            </>
                          }
                          inputName="buyerPhone"
                          inputElement={
                            <FastField name="buyerPhone">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(t('PhoneNumber'))}`}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>

                      {/* email */}
                      <div className="col-lg-6">
                        <KTFormGroup
                          label={
                            <>
                              {t('Email')} <span className="text-danger">(*)</span>
                            </>
                          }
                          inputName="buyerEmail"
                          inputElement={
                            <FastField name="buyerEmail">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(t('Email'))}`}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>

                      {/* chuc vu */}
                      <div className="col-lg-6">
                        <KTFormGroup
                          label={
                            <>
                              {t('Position')} <span className="text-danger">(*)</span>
                            </>
                          }
                          inputName="buyerPosition"
                          inputElement={
                            <FastField name="buyerPosition">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(t('Position'))}`}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* actions */}
                  <div className="mt-6 d-flex align-items-center justify-content-end">
                    <VIButton
                      text={t('Next')}
                      className="btn-blue"
                      style={{ padding: '8px' }}
                      onClick={() => {
                        formikProps.handleSubmit();
                      }}
                    />
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      ) : step === 2 ? (
        <div className="d-flex flex-column gap-4">
          {/* thong tin buoc 1 */}
          <ConfirmPaymentStep1Info onPressEdit={handleEditStep1} />

          {/* thong tin cap hoa don tai chinh */}
          <div
            className="bg-white p-6"
            style={{
              borderRadius: '16px',
              border: '1px solid #E8E9EB',
            }}
          >
            <Formik
              initialValues={{
                invoiceInfoName: confirmPaymentInfo.invoiceInfoName ?? account.fullname,
                invoiceInfoPhone: confirmPaymentInfo.invoiceInfoPhone ?? account.phone,
                invoiceInfoEmail: confirmPaymentInfo.invoiceInfoEmail ?? account.email,
                invoiceInfoAddress: confirmPaymentInfo.invoiceInfoAddress ?? account.address,
              }}
              validationSchema={Yup.object({
                invoiceInfoName: Yup.string().required(t('Required')),
                invoiceInfoPhone: Yup.string()
                  .required(t('Required'))
                  .test('len', t('PhoneInvalid', { field: t('buyerPhone') }), (val) => {
                    if (val) {
                      return (
                        !val.toString().includes(' ') && val.toString().match(AppConfigs.regexPhone)
                      );
                    }
                    return true;
                  }),
                invoiceInfoEmail: Yup.string().required(t('Required')).email(t('EmailInvalid')),
                invoiceInfoAddress: Yup.string().required(t('Required')),
              })}
              enableReinitialize
              onSubmit={(values) => {
                dispatch(updateConfirmPaymentInfo(values));
                handleStepDone();
              }}
            >
              {(formikProps) => {
                return (
                  <div>
                    <div className="">
                      <div className="d-flex align-items-center gap-2">
                        <i
                          className="fa-regular fa-file-invoice-dollar text-primary"
                          style={{
                            fontSize: '24px',
                          }}
                        />
                        <span className="font-weight-bolder text-grey font-size-lg">
                          Thông tin cấp hóa đơn tài chính
                        </span>
                      </div>
                    </div>

                    <div
                      className="mt-6 p-4 rounded-lg"
                      style={{
                        border: '1px solid #E8E9EB',
                      }}
                    >
                      <div className="row mt-4">
                        {/* ho va ten */}
                        <div className="col-12">
                          <KTFormGroup
                            label={
                              <>
                                {t('Fullname')} <span className="text-danger">(*)</span>
                              </>
                            }
                            inputName="invoiceInfoName"
                            inputElement={
                              <FastField name="invoiceInfoName">
                                {({ field, form, meta }) => (
                                  <KTFormInput
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value);
                                    }}
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true);
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    type={KTFormInputType.text}
                                    placeholder={`${_.capitalize(t('Fullname'))}`}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>

                        {/* so dien thoai */}
                        <div className="col-lg-6">
                          <KTFormGroup
                            label={
                              <>
                                {t('PhoneNumber')} <span className="text-danger">(*)</span>
                              </>
                            }
                            inputName="invoiceInfoPhone"
                            inputElement={
                              <FastField name="invoiceInfoPhone">
                                {({ field, form, meta }) => (
                                  <KTFormInput
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value);
                                    }}
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true);
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    type={KTFormInputType.text}
                                    placeholder={`${_.capitalize(t('PhoneNumber'))}`}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>

                        {/* email */}
                        <div className="col-lg-6">
                          <KTFormGroup
                            label={
                              <>
                                {t('Email')} <span className="text-danger">(*)</span>
                              </>
                            }
                            inputName="invoiceInfoEmail"
                            inputElement={
                              <FastField name="invoiceInfoEmail">
                                {({ field, form, meta }) => (
                                  <KTFormInput
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value);
                                    }}
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true);
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    type={KTFormInputType.text}
                                    placeholder={`${_.capitalize(t('Email'))}`}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>

                        {/* dia chi */}
                        <div className="col-12">
                          <KTFormGroup
                            label={
                              <>
                                {t('Address')} <span className="text-danger">(*)</span>
                              </>
                            }
                            inputName="invoiceInfoAddress"
                            inputElement={
                              <FastField name="invoiceInfoAddress">
                                {({ field, form, meta }) => (
                                  <KTFormTextArea
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value);
                                    }}
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true);
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    placeholder={`${_.capitalize(t('Address'))}`}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* actions */}
                    <div className="mt-6 d-flex align-items-center justify-content-end">
                      <VIButton
                        text={t('Next')}
                        className="btn-blue"
                        style={{ padding: '8px' }}
                        onClick={() => {
                          formikProps.handleSubmit();
                        }}
                      />
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column gap-4">
          {/* thong tin buoc 1 */}
          <ConfirmPaymentStep1Info onPressEdit={handleEditStep1} />

          {/* thong tin buoc 2 */}
          <ConfirmPaymentStep2Info onPressEdit={handleEditStep2} />

          {/* thong tin thanh toan */}
          <div
            className="bg-white p-6"
            style={{
              borderRadius: '16px',
              border: '1px solid #E8E9EB',
            }}
          >
            <div className="">
              <div className="d-flex align-items-center gap-2">
                <i
                  className="fa-regular fa-money-check-dollar-pen text-primary"
                  style={{
                    fontSize: '24px',
                  }}
                />
                <span className="font-weight-bolder text-grey font-size-lg">
                  Thông tin thanh toán
                </span>
              </div>

              <div className="mt-4">
                <Accordion
                  defaultActiveKey="0"
                  className="accordion-payment"
                  onSelect={(eventKey) => {
                    setAccordionActiveKey(eventKey);

                    if (eventKey === '0') {
                      dispatch(updatePaymentType(AppData.paymentType.bankTransfer));
                    } else if (eventKey === '1') {
                      dispatch(updatePaymentType(AppData.paymentType.vnpay));
                    } else {
                      dispatch(updatePaymentType(''));
                    }
                  }}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="d-flex align-items-center gap-2">
                        <KTRadio
                          readonly={true}
                          name="radioBankTransfer"
                          checked={accordionActiveKey === '0'}
                        />
                        <i
                          className="fa-regular fa-credit-card"
                          style={{
                            fontSize: '30px',
                            color: '#00bbb3',
                          }}
                        />{' '}
                        <span className="font-weight-bolder">{t('BankTransfer')}</span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <p>
                          Để việc xác nhận thanh toán được nhanh chóng và tự động, sau khi đặt hàng
                          quý khách vui lòng chuyển khoản theo đúng các thông tin dưới đây.
                        </p>
                        <div className="d-flex mt-3">
                          <div className="mr-4 d-flex flex-column gap-2">
                            <p className="m-0">{t('BankName')}:</p>
                            <p className="m-0">{t('BankAccountName')}:</p>
                            <p className="m-0">{t('BankAccountNumber')}:</p>
                            <p className="m-0">{t('BankBranch')}:</p>
                            <p className="m-0">{t('BankTransferContent')}:</p>
                            <p className="m-0">{t('PriceNumber')}:</p>
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <p className="font-weight-bold m-0">{appConfig.bankName}&nbsp;</p>
                            <p className="font-weight-bold m-0">
                              {appConfig.bankAccountName}&nbsp;
                            </p>
                            <p className="font-weight-bold m-0">
                              {appConfig.bankAccountNumber}&nbsp;
                            </p>
                            <p className="font-weight-bold m-0">{appConfig.bankBranch}&nbsp;</p>
                            <div className="font-weight-bold m-0 d-flex align-items-center gap-2">
                              {bankTransferContent}&nbsp;{' '}
                              <KTTooltip text={t('Copy')}>
                                <i
                                  className="fa-regular fa-copy cursor-pointer text-primary"
                                  onClick={() => {
                                    Utils.copyTextToClipboard(bankTransferContent);
                                    ToastHelper.showSuccess(t('Copied'));
                                  }}
                                />
                              </KTTooltip>
                            </div>
                            <p className="font-weight-bold m-0">
                              {Utils.formatCurrency(lastPriceToPay)} {cartPriceUnit}&nbsp;
                            </p>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="d-flex align-items-center gap-2">
                        <KTRadio
                          readonly={true}
                          name="radioVNPay"
                          checked={accordionActiveKey === '1'}
                        />
                        <img className="h-30px" src={AppResource.icons.icLogoVNPay} />{' '}
                        <span className="font-weight-bolder">{t('VNPay')}</span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>Nhấn nút Thanh toán ở bên dưới để tiếp tục</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div
            // className="mt-6 p-4 rounded-lg"
            // style={{
            //   border: '1px solid #E8E9EB',
            // }}
            ></div>

            {/* actions */}
            <div className="mt-6 d-flex align-items-center justify-content-end">
              <VIButton
                text={accordionActiveKey === '0' ? t('Order') : t('Payment')}
                className="btn-blue"
                style={{ padding: '8px' }}
                onClick={handlePayment}
                disabled={creatingOrder}
                beforeIcon={creatingOrder ? <ClockLoader color="white" size={20} /> : <></>}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CartConfirmPaymentInfo;
