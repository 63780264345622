import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Utils from 'general/utils/Utils';
import VIButton from 'general/components/VietInvoice/VIButton';
const parse = require('html-react-parser');

ServicePackCompactCard.propTypes = {
  item: PropTypes.object,
  onPressAddToCart: PropTypes.func,
};

ServicePackCompactCard.defaultProps = {
  item: null,
  onPressAddToCart: null,
};

/**
 *
 * @param {{
 * item: object,
 * onPressAddToCart,
 * }} props
 * @returns
 */
function ServicePackCompactCard(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { item, onPressAddToCart } = props;

  // ----- methods ------
  function handlePressAddToCart() {
    if (onPressAddToCart) {
      onPressAddToCart();
    }
  }

  return (
    <div
      className="bg-white p-6 d-flex flex-column align-items-center h-100"
      style={{
        border: '1px solid #E8E9EB',
        borderRadius: '8px',
      }}
    >
      <p className="text-center font-weight-bolder text-grey font-size-h5 mb-0">
        {item?.packageCode}
      </p>

      <div className="separator separator-dashed my-4 w-100"></div>

      <div>
        <span className="font-weight-bolder font-size-h6">
          {Utils.formatCurrency(item?.packageCost)} {item?.unit}/
        </span>
        <span className="font-size-sm">{t('Package')}</span>
      </div>

      <VIButton
        beforeIcon={<i className="fa-regular fa-cart-plus text-primary" />}
        className={`btn-grey text-primary mt-3`}
        onClick={handlePressAddToCart}
        text={t('AddToCart')}
      />

      <div className="mt-3 font-size-lg">{parse(item?.description)}</div>
    </div>
  );
}

export default ServicePackCompactCard;
