import miscApi from 'api/miscApi';
import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputIconPosition,
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTCheckbox, {
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppConfigs from 'general/constants/AppConfigs';
import OrganizationHelper from 'general/helpers/OrganizationHelper';
import Global from 'general/utils/Global';
import useConfig from 'hooks/useConfig';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

ModalAddBranchContent.propTypes = {
  onPressCancel: PropTypes.func,
  parentCompany: PropTypes.object,
  onCheckedAcceptMultiNameChange: PropTypes.func,
};

ModalAddBranchContent.defaultProps = {
  onPressCancel: null,
  parentCompany: null,
  onCheckedAcceptMultiNameChange: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * parentCompany: object,
 * onCheckedAcceptMultiNameChange: function,
 * }} props
 * @returns
 */
function ModalAddBranchContent(props) {
  // ---- params -----
  const { onPressCancel, parentCompany, onCheckedAcceptMultiNameChange } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refFormik = useRef();
  const appConfig = useConfig();

  // ---- methods -----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  async function requestCompanyInfoByTaxCode(taxCode) {
    if (
      Global.gTempBranchTaxCode.match(/^[0-9]{10}$/) ||
      Global.gTempBranchTaxCode.match(/^[0-9]{10}-[0-9]{3}$/)
    ) {
      try {
        const res = await miscApi.getCompanyInfoByTaxCode(taxCode);
        const { result, company } = res;
        if (result === 'success' && company) {
          const { companyName, address, businessPermitDate } = company;
          refFormik.current?.setFieldValue('companyName', companyName);
          refFormik.current?.setFieldValue('companyAddress', address);
          refFormik.current?.setFieldValue(
            'effectiveDate',
            businessPermitDate ? moment(businessPermitDate).format('YYYY-MM-DD') : ''
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    requestCompanyInfoByTaxCode(Global.gTempBranchTaxCode);
  }, []);

  return (
    <div
      className=""
      style={{
        maxHeight: '70vh',
      }}
    >
      <Formik
        initialValues={{
          emailReceiveInvoice: Global.gTempBranchInvoiceMailbox,
          taxCode: Global.gTempBranchTaxCode,
          effectiveDate: '',
          companyName: '',
          companyAddress: '',

          representativeName: Global.gTempOrganizationContactInfo?.name ?? '',
          representativeJobTitle: Global.gTempOrganizationContactInfo?.jobTitle ?? '',
          representativeEmail: Global.gTempOrganizationContactInfo?.email ?? '',
          representativePhone: Global.gTempOrganizationContactInfo?.phone ?? '',
        }}
        validationSchema={Yup.object({
          emailReceiveInvoice: Yup.string()
            .required(t('Required'))
            .test('len', t('EmailInvalid'), (val) => {
              if (val) {
                return (
                  !val.toString().includes(' ') &&
                  val.toString().match(AppConfigs.regexInvoiceMailbox)
                );
              }
            }),
          taxCode: Yup.string()
            .required(t('TaxCodeRequired'))
            .trim()
            .test('len', t('TaxCodeInvalid', { field: t('taxCode') }), (val) => {
              if (val) {
                return (
                  !val.toString().includes(' ') &&
                  (val.toString().match(AppConfigs.regexTaxCode10) ||
                    val.toString().match(AppConfigs.regexTaxCode13))
                );
              }
            }),
          effectiveDate: Yup.string().required(t('Required')),
          companyName: Yup.string().required(t('Required')),
          companyAddress: Yup.string().required(t('Required')),
          representativeEmail: Yup.string().nullable().email(t('EmailInvalid')),
          representativePhone: Yup.string()
            .nullable()
            .test('len', t('PhoneInvalid', { field: t('phone') }), (val) => {
              if (val) {
                return !val.toString().includes(' ') && val.toString().match(AppConfigs.regexPhone);
              }
              return true;
            }),
        })}
        enableReinitialize
        onSubmit={(values) => {
          const params = {
            organizationName: values.companyName,
            taxCode: values.taxCode,
            organizationType: 'BRANCH',
            parentOrganizationId: parentCompany?.organizationId ?? null,
            invoiceMailbox: values.emailReceiveInvoice + appConfig.mailServerDomain,
            businessPermitAddress: values.companyAddress,
            businessPermitDate: values.effectiveDate,

            representativeName: values.representativeName,
            representativeJobTitle: values.representativeJobTitle,
            representativePhone: values.representativePhone,
            representativeEmail: values.representativeEmail,
          };
          const res = OrganizationHelper.callApiCreateOrganization(params, false);
          if (res) {
            handlePressCancel();
          }
        }}
      >
        {(formikProps) => {
          refFormik.current = formikProps;
          return (
            <div className="">
              <div className="row" style={{ maxHeight: '65vh', overflow: 'auto' }}>
                {/* email nhan hoa don */}
                <div className="col-12">
                  <KTFormGroup
                    additionalClassName="mb-4"
                    label={
                      <>
                        {t('CreateEmailToReceiveInvoice')} <span className="text-danger">(*)</span>
                      </>
                    }
                    inputName="emailReceiveInvoice"
                    inputElement={
                      <FastField name="emailReceiveInvoice">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempBranchInvoiceMailbox = value;
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableInputGroup
                            appendElements={[appConfig.mailServerDomain]}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('EmailReceiveInvoice'))}`}
                            text={t('InvoiceMailboxHint')}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {_.isEmpty(formikProps.errors.emailReceiveInvoice) && (
                  <div className="col-12">
                    <KTFormGroup
                      additionalClassName="mb-4"
                      inputElement={
                        <div
                          className="border-warning bg-light-warning p-4 rounded"
                          style={{
                            borderWidth: '1px',
                            borderStyle: 'solid',
                          }}
                        >
                          Email nhận hóa đơn của bạn là:{' '}
                          <span className="text-primary">{`${formikProps.values.emailReceiveInvoice}${appConfig.mailServerDomain}`}</span>{' '}
                          gửi hoặc chuyển tiếp (forward) email hóa đơn điện tử đến email này, hệ
                          thống sẽ tự động xử lý hóa đơn.
                        </div>
                      }
                    />
                  </div>
                )}

                {/* thong tin don vi */}
                <div className="col-12">
                  <KTFormGroup
                    additionalClassName="mb-4"
                    label={t('UnitInformation')}
                    inputElement={
                      <div className="bg-light p-4 rounded" style={{}}>
                        Vui lòng khai báo thông tin{' '}
                        <span className="font-weight-bolder">
                          chính xác theo giấy phép kinh doanh.
                        </span>{' '}
                        Hệ thống sẽ sử dụng thông tin này để tra cứu tính pháp lý của hóa đơn.
                      </div>
                    }
                  />
                </div>

                {/* ma so thue */}
                <div className="col-lg-6">
                  <KTFormGroup
                    additionalClassName="mb-4"
                    label={
                      <>
                        {t('TaxCode')} <span className="text-danger">(*)</span>
                      </>
                    }
                    inputName="taxCode"
                    inputElement={
                      <FastField name="taxCode">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempBranchTaxCode = value;
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('TaxCode'))}`}
                            enableInputIcon={true}
                            inputIconElement={
                              <i
                                className="fa-regular fa-search cursor-pointer"
                                onClick={() => {
                                  requestCompanyInfoByTaxCode(field.value);
                                }}
                              />
                            }
                            inputIconPosition={KTFormInputIconPosition.right}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* ngay hieu luc */}
                <div className="col-lg-6">
                  <KTFormGroup
                    additionalClassName="mb-4"
                    label={
                      <div className="d-flex">
                        {t('EffectiveDate')} <span className="text-danger">(*)</span>{' '}
                        <KTTooltip text="Ngày hiệu lực bắt đầu của tên, địa chỉ theo GPKD">
                          <i className="fa-regular fa-circle-question text-primary ml-1 cursor-pointer" />
                        </KTTooltip>
                      </div>
                    }
                    inputName="effectiveDate"
                    inputElement={
                      <FastField name="effectiveDate">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.date}
                            placeholder={`${_.capitalize(t('EffectiveDate'))}`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* ten chi nhanh */}
                <div className="col-12">
                  <KTFormGroup
                    additionalClassName="mb-4"
                    label={
                      <>
                        {t('BranchName')} <span className="text-danger">(*)</span>
                      </>
                    }
                    inputName="companyName"
                    inputElement={
                      <FastField name="companyName">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('BranchName'))}`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* dia chi cong ty */}
                <div className="col-12">
                  <KTFormGroup
                    additionalClassName="mb-4"
                    label={
                      <>
                        {t('AddressByBusinessLicense')} <span className="text-danger">(*)</span>
                      </>
                    }
                    inputName="companyAddress"
                    inputElement={
                      <FastField name="companyAddress">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('Address'))}`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* chap nhan nhieu ten, dia chi don vi hop le */}
                <div className="col-12">
                  <div className="checkbox-list">
                    <KTCheckbox
                      name="checkboxAcceptMultiNameBranch"
                      label={
                        <div className="d-flex align-items-center">
                          Chấp nhận nhiều tên, địa chỉ đơn vị hợp lệ{' '}
                          <KTTooltip text="Kê khai thông tin tên/địa chỉ dựa theo các lần thay đổi giấy phép ĐKKD của đơn vị">
                            <i className="fa-regular fa-circle-question text-primary ml-2" />
                          </KTTooltip>
                        </div>
                      }
                      type={KTCheckboxType.outline}
                      onChange={(newValue) => {
                        if (onCheckedAcceptMultiNameChange) {
                          onCheckedAcceptMultiNameChange(newValue);
                        }
                      }}
                    />
                  </div>
                </div>

                {/* thong tin lien he */}
                <div className="row mt-4">
                  {/* ten nguoi lien he */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={<>{t('ContactName')}</>}
                      inputName="representativeName"
                      additionalClassName="mb-4"
                      inputElement={
                        <FastField name="representativeName">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                Global.gTempOrganizationContactInfo.name = value;
                              }}
                              onFocus={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('ContactName'))}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* chuc vu */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={<>{t('ContactPosition')}</>}
                      inputName="representativeJobTitle"
                      additionalClassName="mb-4"
                      inputElement={
                        <FastField name="representativeJobTitle">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                Global.gTempOrganizationContactInfo.jobTitle = value;
                              }}
                              onFocus={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('ContactPosition'))}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* email */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={<>{t('ContactEmail')}</>}
                      inputName="representativeEmail"
                      additionalClassName="mb-4"
                      inputElement={
                        <FastField name="representativeEmail">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                Global.gTempOrganizationContactInfo.email = value;
                              }}
                              onFocus={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.email}
                              placeholder={`${_.capitalize(t('ContactEmail'))}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* so dien thoai */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={<>{t('ContactPhone')}</>}
                      inputName="representativePhone"
                      additionalClassName="mb-4"
                      inputElement={
                        <FastField name="representativePhone">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                Global.gTempOrganizationContactInfo.phone = value;
                              }}
                              onFocus={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('ContactPhone'))}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                </div>
              </div>

              {/* actions */}
              <div className="d-flex align-items-center justify-content-end gap-3">
                <VIButton
                  text={t('Cancel')}
                  className="btn-grey"
                  style={{ padding: '8px' }}
                  onClick={handlePressCancel}
                />
                <VIButton
                  text={t('Save')}
                  className=""
                  style={{ padding: '8px' }}
                  onClick={() => {
                    formikProps.handleSubmit();
                  }}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default ModalAddBranchContent;
