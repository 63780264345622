import VIButton from 'general/components/VietInvoice/VIButton';
import useAccount from 'hooks/useAccount';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ClockLoader } from 'react-spinners';
import CellAxionStat from '../CellAxionStat';

ModalContentAxionDisconnect.propTypes = {
  onPressCancel: PropTypes.func,
  onPressDisconnect: PropTypes.func,
};

ModalContentAxionDisconnect.defaultProps = {
  onPressCancel: null,
  onPressDisconnect: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * onPressDisconnect: function,
 * }} props
 * @returns
 */
function ModalContentAxionDisconnect(props) {
  // ---- params ----
  const { onPressCancel, onPressDisconnect } = props;
  const { t } = useTranslation();
  const [inInvoiceOn, setInInvoiceOn] = useState(false);
  const [outInvoiceOn, setOutInvoiceOn] = useState(false);
  const { axionIsInput, axionIsOutput } = useAccount();
  const { totalHDDV, totalHDDR, isDisconnecting } = useSelector((state) => state.axion);
  const [disabledButtonDisconnect, setDisabledButtonDisconnect] = useState(true);

  useEffect(() => {
    setInInvoiceOn(axionIsInput);
    setOutInvoiceOn(axionIsOutput);
  }, [axionIsInput, axionIsOutput]);

  useEffect(() => {
    if ((axionIsInput && !inInvoiceOn) || (axionIsOutput && !outInvoiceOn)) {
      setDisabledButtonDisconnect(false);
    } else {
      setDisabledButtonDisconnect(true);
    }
  }, [inInvoiceOn, outInvoiceOn, axionIsOutput, axionIsInput]);

  // ----- methods ----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  function handlePressDisconnect() {
    if (onPressDisconnect) {
      onPressDisconnect({ isInput: inInvoiceOn, isOutput: outInvoiceOn });
    }
  }

  return (
    <div className="">
      <p className="">
        Chọn phân hệ ngắt kết nối với hệ thống tự động tải về và xử lý hóa đơn từ CQT
      </p>
      <div className="row gutter-t">
        <div className="col-12 mb-6">
          <CellAxionStat
            text={t('InInvoice')}
            value={totalHDDV}
            active={axionIsInput}
            showBorder
            selected={axionIsInput ? !inInvoiceOn : false}
            interactable={axionIsInput}
            onPress={() => {
              setInInvoiceOn(!inInvoiceOn);
            }}
          />
        </div>
        <div className="col-12">
          <CellAxionStat
            text={t('OutInvoice')}
            value={totalHDDR}
            active={axionIsOutput}
            selected={axionIsOutput ? !outInvoiceOn : false}
            showBorder
            interactable={axionIsOutput}
            onPress={() => {
              setOutInvoiceOn(!outInvoiceOn);
            }}
          />
        </div>
      </div>

      {/* actions */}
      <div className="d-flex align-items-center justify-content-end gap-3 mt-6">
        <VIButton
          text={t('Cancel')}
          className="btn-grey"
          style={{ padding: '8px' }}
          onClick={handlePressCancel}
        />
        <VIButton
          text={isDisconnecting ? `${t('Disconnecting')}...` : t('Disconnect')}
          className=""
          style={{ padding: '8px' }}
          onClick={handlePressDisconnect}
          disabled={isDisconnecting || disabledButtonDisconnect}
          beforeIcon={isDisconnecting ? <ClockLoader color="white" size={20} /> : <></>}
        />
      </div>
    </div>
  );
}

export default ModalContentAxionDisconnect;
