import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Carousel } from 'react-bootstrap';

HelpComponentView.propTypes = {
  data: PropTypes.array,
  interval: PropTypes.number,
  fade: PropTypes.bool,
  onClick: PropTypes.func,
};

HelpComponentView.defaultProps = {
  data: [],
  interval: 3000,
  fade: true,
  onClick: () => {},
};

/**
 * @param {array} data {title, text, image}
 * @param {number} interval change carousel item every {interval} ms
 * @param {boolean} fade
 * @param {function} onClick Handle when click carousel item
 */

function HelpComponentView(props) {
  const { data, interval, fade, onClick } = props;
  return (
    <div className="HelpComponentView bg-white p-4">
      <Carousel interval={interval} fade={fade}>
        {data?.map((item, index) => (
          <Carousel.Item key={index} onClick={onClick}>
            <div className="d-flex flex-column flex-sm-row justify-content-between gap-3">
              <Carousel.Caption className="my-auto w-100 w-sm-50 order-2 order-sm-1 px-4 px-sm-10">
                <p className="w-100 font-size-h4 font-weight-bolder mb-1">
                  {index + 1}. {item?.title}
                </p>
                <p className="w-100 m-0 font-size-lg font-weight-normal">{item?.text}</p>
              </Carousel.Caption>
              <div className="HelpComponentView_image order-1 order-sm-2">
                <img className="w-100 h-100" src={item?.image} alt={`slide ${index + 1}`} />
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default HelpComponentView;
