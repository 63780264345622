import AppData from 'general/constants/AppData';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useSystem(props) {
  // ---- params ----
  const { allCompanyTitleList, allSystemRoleList, allSystemUserList } = useSelector(
    (state) => state.system
  );

  return useMemo(() => {
    return {
      // title items
      companyTitleOptions: allCompanyTitleList?.map((item) => {
        return {
          name: item.name,
          value: item.companyTitleId,
        };
      }),

      // role items
      companyRoleOptions: allSystemRoleList
        ?.filter((item) => item?.roleId !== -1)
        ?.map((item) => {
          return {
            name: item?.name,
            value: item?.roleId,
          };
        }),

      // employee items
      companyEmployeeOptions: allSystemUserList
        ?.filter((item) => item?.accountLevel === AppData.accountLevel.user)
        ?.map((item) => {
          return {
            name: item?.fullname ?? item?.email ?? '',
            value: item?.accountId,
          };
        }),
    };
  }, [allCompanyTitleList, allSystemRoleList, allSystemUserList]);
}

export default useSystem;
