import AppData from 'general/constants/AppData';
import moment from 'moment';
import Utils from './Utils';
import AppConfigs from 'general/constants/AppConfigs';

// Global variables

const Global = {
  gUnauthorized: false,
  //dungna
  gDefaultPagination: 30,

  gFiltersSupplierAndCustomer: { q: '', page: 0, limit: 30, status: '', type: '' },
  gFiltersLoggedInDevice: {
    q: '',
    page: 0,
    limit: 30,
  },
  gFiltersDailyLog: {
    q: '',
    page: 0,
    limit: 30,
    from: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.baMuoiNgayGanNhat,
  },
  gFiltersNotUsedYetVoucher: { q: '', page: 0, limit: 30 },
  gFiltersUsingVoucher: { q: '', page: 0, limit: 30 },
  gFiltersExpiredVoucher: { q: '', page: 0, limit: 30 },

  // system
  gFiltersAllUserList: { q: '' },
  gFiltersAllRoleList: { q: '' },
  gFiltersOrder: { q: '', page: 0, limit: 30 },
  gFiltersAuditingLogList: {
    q: '',
    page: 0,
    limit: 30,
    from: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.baMuoiNgayGanNhat,
  },

  gFiltersNotificationList: {
    from: Utils.getDateFilterOption(AppData.dateFilter.namNay)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.namNay)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.namNay,
    type: '',
  },

  // tools
  gFilterPersonalTaxCodeInfo: {
    q: '',
  },
  gFilterTaxCodeInfo: {
    q: '',
  },
  gFilterTaxReducedDocuments: {
    q: '',
  },
  gFilterTaxReducedItems: {
    q: '',
    limit: 30,
    page: 0,
  },
  gFilterRiskyBusiness: {
    q: '',
    limit: 30,
    page: 0,
  },

  // supplier
  gFiltersConnectionSupplierHistory: {
    limit: 30,
    page: 0,
    from: Utils.getDateFilterOption(AppData.dateFilter.namNay)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.namNay)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.namNay,
  },

  // axion
  gFiltersAxionInputInvoice: {
    page: 0,
    limit: 30,
    from: Utils.getDateFilterOption(AppData.dateFilter.namNay)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.namNay)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.namNay,
  },
  gFiltersAxionOutputInvoice: {
    page: 0,
    limit: 30,
    from: Utils.getDateFilterOption(AppData.dateFilter.namNay)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.namNay)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.namNay,
  },
  gFilterPaymentHistory: {
    page: 0,
    limit: 30,
  },
  gFiltersAxionSync: {
    from: Utils.getDateFilterOption(AppData.dateFilter.namNay)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.namNay)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.namNay,
  },
  // ---- thong ke ----
  // thong ke hoa don
  gFiltersDashboardInvoiceStatistic: {
    from: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.baMuoiNgayGanNhat,
  },
  // thong ke hoa don theo thoi gian
  gFiltersDashboardInvoiceByTimeStatistic: {
    from: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.baMuoiNgayGanNhat,
  },
  // thong ke gia tri hoa don
  gFiltersDashboardInvoiceValueStatistic: {
    from: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.baMuoiNgayGanNhat,
  },
  // bao cao
  gFiltersDashboardReport: {
    from: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.baMuoiNgayGanNhat,
  },
  // mail
  gFiltersInboxMailList: {
    limit: 100,
    page: 0,
    q: '',
    from: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.baMuoiNgayGanNhat,
    statusValidMail: 'ALL',
    readStatus: '',
    typeMail: '',
  },
  gFiltersImportantMailList: {
    limit: 100,
    page: 0,
    q: '',
    from: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.baMuoiNgayGanNhat,
    statusValidMail: 'ALL',
    readStatus: '',
    typeMail: AppData.mailType.important,
  },
  gFiltersSpamMailList: {
    limit: 100,
    page: 0,
    q: '',
    from: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[0].format('YYYY-MM-DD'),
    to: Utils.getDateFilterOption(AppData.dateFilter.baMuoiNgayGanNhat)[1].format('YYYY-MM-DD'),
    dateRangeValue: AppData.dateFilter.baMuoiNgayGanNhat,
    statusValidMail: 'ALL',
    readStatus: '',
    typeMail: AppData.mailType.trash,
  },

  // temp data
  gTempCompanyTitle: null,
  gTempCompanyInvoiceMailbox: '',
  gTempCompanyTaxCode: '',
  gTempStateCreateCompanyMultiAddresses: false,

  gTempBranchInvoiceMailbox: '',
  gTempBranchTaxCode: '',
  gTempStateCreateBranchMultiAddresses: false,
  gTempStateAddAccountAndCreateOrganization: false,

  gTempPrevPath: '',
  gTempSelectedOrganization: null,

  gTempMailInboxCheckboxValue: '',
  gTempMailImportantCheckboxValue: '',
  gTempMailSpamCheckboxValue: '',

  gTempChoosingSupplierList: null,
  gTempChoosingOrganizationList: null,
  gTempChoosingOrganization: null,
  gTempChoosingServiceType: AppData.serviceType.smart,
  gTempOrganizationContactInfo: { name: '', jobTitle: '', email: '', phone: '' },
  resetTempOrganizationContactInfo: () => {
    Global.gTempOrganizationContactInfo = { name: '', jobTitle: '', email: '', phone: '' };
  },
  gTempSystemUserInfo: {
    username: '',
    email: '',
    phoneNumber: '',
    userTitle: '',
    fullname: '',
    userRole: '',
  },
  resetTempSystemUserInfo: () => {
    Global.gTempSystemUserInfo = {
      username: '',
      email: '',
      phoneNumber: '',
      userTitle: '',
      fullname: '',
      userRole: '',
    };
  },

  // ductu
  gFiltersPartnerCompany: { page: 0, limit: 30 },
  gFiltersInputInvoice: {
    page: 0,
    limit: 30,
    q: '',
    invoiceCategory: 'INPUT_INVOICE',
    fromDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    dateType: 1,
    dateRangeLabel: '30 ngày gần đây',
  },
  gFiltersInputInvoiceDelete: {
    page: 0,
    limit: 30,
    q: '',
    invoiceCategory: 'INPUT_INVOICE',
    isDeleted: true,
    fromDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    dateType: 1,
    dateRangeLabel: '30 ngày gần đây',
  },
  gFiltersOutputInvoice: {
    page: 0,
    limit: 30,
    q: '',
    invoiceCategory: 'OUTPUT_INVOICE',
    fromDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    dateType: 1,
    dateRangeLabel: '30 ngày gần đây',
  },
  gFiltersOutputInvoiceDelete: {
    page: 0,
    limit: 30,
    q: '',
    invoiceCategory: 'OUTPUT_INVOICE',
    isDeleted: true,
    fromDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    dateType: 1,
    dateRangeLabel: '30 ngày gần đây',
  },
  [`${AppConfigs.packageName}_saveColumnInputInvoice`]: [
    'Nhãn',
    'Tệp',
    'Số hóa đơn',
    'Ngày hóa đơn',
    'Đơn vị bán hàng',
    'Mã số thuế',
    'Mẫu số',
    'Ký hiệu',
    'Tổng tiền',
    'In kết quả kiểm tra',
    'Kiểm tra thuế suất',
    'Tra cứu hóa đơn gốc',
    'Tệp hóa đơn',
    'Hóa đơn bị điều chỉnh/bị thay thế',
    'Ngày thay đổi trạng thái trên CQT',
    'Ngày phát hiện thay đổi trạng thái',
    '',
  ],
  [`${AppConfigs.packageName}_saveColumnOutputInvoice`]: [
    'Số hóa đơn',
    'Ngày hóa đơn',
    'Mã cơ quan thuế',
    'Khách hàng',
    'Mã số thuế',
    'Địa chỉ',
    'Mẫu số',
    'Ký hiệu',
    'Tổng tiền',
    'Tra cứu hóa đơn gốc',
    'Tệp hóa đơn',
    'Hóa đơn bị điều chỉnh/bị thay thế',
    '',
  ],
  [`${AppConfigs.packageName}_saveColumnNCC`]: [
    'Mã số thuế',
    'Nhà cung cấp/Khách hàng',
    'Địa chỉ',
    'Hóa đơn đầu vào',
    'Hóa đơn đầu ra',
    'Hạn thanh toán (Ngày)',
    'Người liên hệ',
    'Email',
    'Ngày kiểm tra',
    'Trạng thái',
    'Rủi ro về hóa đơn',
    '',
  ],
  gDownLoadZipName: 'HOA_DON_DAU_VAO',
  gDownLoadZipNameOut: 'HOA_DON_DAU_RA',
};

export default Global;
