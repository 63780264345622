import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import ViewRiskyBusinessDetail from 'features/Tools/components/ViewRiskyBusinessDetail';
import { thunkRiskyBusiness } from 'features/Tools/toolSlice';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIModal from 'general/components/VietInvoice/VIModal';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import Global from 'general/utils/Global';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';

RiskyBusinessesScreen.propTypes = {
  navItemList: PropTypes.array,
};

RiskyBusinessesScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function RiskyBusinessesScreen(props) {
  // ---- params ----
  const { navItemList } = props;
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      {
        name: t('CompanyName'),
        sortable: false,
        minWidth: '220px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0 text-maxline-3">
              {row?.companyName}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        sortable: false,
        width: '140px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.taxCode}
            </p>
          );
        },
      },
      {
        name: t('CQT'),
        sortable: false,
        minWidth: '220px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.cqtName}
            </p>
          );
        },
      },
      {
        name: t('Note'),
        sortable: false,
        minWidth: '280px',
        style: {
          borderRight: 0,
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0 text-maxline-3">
              {row?.note}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            <KTTooltip text={t('View')}>
              <i
                className="fa-regular fa-eye text-primary"
                onClick={() => {
                  handleShowCompanyDetail(row);
                }}
              />
            </KTTooltip>
          </div>
        ),
      },
    ];
  }, [t]);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(Global.gFilterRiskyBusiness);
  const { riskyBusiness, isGettingRiskyBusiness, totalRiskyBusiness } = useSelector(
    (state) => state.tool
  );
  const [modalRiskyBusinessShowing, setModalRiskyBusinessShowing] = useState(false);
  const [currentSelectedCompany, setCurrentSelectedCompany] = useState(null);

  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  useEffect(() => {
    dispatch(thunkRiskyBusiness(filters));
  }, [filters]);

  // ----- methods ----
  function handleShowCompanyDetail(row) {
    setCurrentSelectedCompany(row);
    setModalRiskyBusinessShowing(true);
  }

  return (
    <div className="h-100">
      <VIDataTable
        draggable={false}
        tableStyles={customDataTableActionStyle}
        title={
          <div className="d-flex align-items-center justify-content-center gap-1">
            <div className="d-block d-lg-none">
              <KTBSDropdown
                toggleElement={
                  <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                    <i className="fa-solid fa-bars text-grey" />
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                dropdownMenuItems={dropdownMenuItems}
                selectedValue={t('LookupRiskyBusinesses')}
                onChange={(newValue) => {}}
              />
            </div>{' '}
            {t('ListRiskyBusinesses')}
          </div>
        }
        selectable={false}
        pagination={{
          perPage: filters.limit,
          total: totalRiskyBusiness,
          currentPage: filters.page + 1,
          count: riskyBusiness?.length + filters.limit * filters.page,
        }}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          Global.gFilterRiskyBusiness = {
            ...Global.gFilterRiskyBusiness,
            page: iNewPage,
          };
          setFilters(Global.gFilterRiskyBusiness);
        }}
        onChangeRowsPerPage={(perPage) => {
          Global.gFilterRiskyBusiness = {
            ...Global.gFilterRiskyBusiness,
            limit: perPage,
            page: 0,
          };
          setFilters(Global.gFilterRiskyBusiness);
        }}
        fullColumns={columns}
        data={riskyBusiness}
        loading={false}
        filterbar={
          <>
            <VISearchField
              placeholder={`${t('EnterToSearch')}`}
              value={filters.q}
              className=""
              typingTimeout={500}
              onSubmit={(newValue) => {
                Global.gFilterRiskyBusiness = {
                  ...Global.gFilterRiskyBusiness,
                  q: newValue,
                  page: 0,
                };
                setFilters(Global.gFilterRiskyBusiness);
              }}
            />
          </>
        }
        onRowClick={(row) => {
          handleShowCompanyDetail(row);
        }}
        onSetSelectedMultiItems={(rows) => {}}
        // toolbar={
        //   <>
        //     <VIButton
        //       beforeIcon={<i className="fa-regular fa-search" />}
        //       text={t('LookUp')}
        //       className="btn-blue border-0 p-3"
        //       onClick={() => {}}
        //     />

        //     <VIButton
        //       beforeIcon={<i className="fa-regular fa-download" />}
        //       text={t('Download')}
        //       className="btn-blue border-0 p-3"
        //       onClick={() => {}}
        //     />
        //   </>
        // }
      />

      {/* modal risky business */}
      <VIModal
        titleModal={t('BusinessDetail')}
        modalSize="lg"
        show={modalRiskyBusinessShowing}
        contentModal={<ViewRiskyBusinessDetail item={currentSelectedCompany} />}
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalRiskyBusinessShowing(false);
          setCurrentSelectedCompany(null);
        }}
        buttonNegative={t('Close')}
      />
    </div>
  );
}

export default RiskyBusinessesScreen;
