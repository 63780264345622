import { FastField, Formik } from 'formik';
import CKEditorDocument from 'general/components/OtherKeenComponents/Forms/CKEditor/CKEditorDocument';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIButton from 'general/components/VietInvoice/VIButton';
import MailHelper from 'general/helpers/MailHelper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import MailAttachmentView from '../MailAttachmentView';
import { useSelector } from 'react-redux';
import { ClockLoader } from 'react-spinners';

ViewForwardMail.propTypes = {
  item: PropTypes.object,
  onPressCancel: PropTypes.func,
};

ViewForwardMail.defaultProps = {
  item: null,
  onPressCancel: null,
};

/**
 *
 * @param {{
 * item: object,
 * onPressCancel: null,
 * }} props
 * @returns
 */
function ViewForwardMail(props) {
  // ---- params ----
  const { item, onPressCancel } = props;
  const { t } = useTranslation();
  console.log({ item });
  const refContent = useRef();
  const refAttachment = useRef();
  const refActions = useRef();
  const [mailHtmlHeight, setMailHtmlHeight] = useState('');
  const { isForwarding } = useSelector((state) => state.mail);

  useEffect(() => {
    setMailHtmlHeight(
      `calc(80vh - ${refContent?.current?.offsetHeight}px - ${refAttachment?.current?.offsetHeight}px - ${refActions?.current?.offsetHeight}px)`
    );
  }, [refContent?.current, refAttachment?.current, refActions?.current]);

  // ---- methods ----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          receiver: '',
          subject: item?.subject ?? '',
          content: '',
        }}
        validationSchema={Yup.object({
          receiver: Yup.string().required(t('Required')).email(t('EmailInvalid')),
          subject: Yup.string().required(t('Required')),
        })}
        enableReinitialize
        onSubmit={async (values) => {
          const params = {
            ccSomeone: [{ name: '', email: values.receiver }],
            subject: values.subject,
            body: values.content,
          };
          const res = await MailHelper.callApiForwardMail(item?.mailId, params);
          if (res) {
            handlePressCancel();
          }
        }}
      >
        {(formikProps) => (
          <div className="d-flex flex-column justify-content-between">
            <div className="row" ref={refContent}>
              {/* nguoi nhan */}
              <div className="col-12">
                <KTFormGroup
                  label={
                    <>
                      {t('Receiver')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="receiver"
                  inputElement={
                    <FastField name="receiver">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('ReceiverEmail'))}...`}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {/* tieu de */}
              <div className="col-12">
                <KTFormGroup
                  label={
                    <>
                      {t('Subject')} <span className="text-danger">(*)</span>
                    </>
                  }
                  additionalClassName="mb-2"
                  inputName="subject"
                  inputElement={
                    <FastField name="subject">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('Subject'))}...`}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {/* noi dung forward */}
              <div className="col-12">
                <KTFormGroup
                  additionalClassName="mb-0"
                  inputName="content"
                  inputElement={
                    <FastField name="content">
                      {({ field, form, meta }) => (
                        <CKEditorDocument
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>
            </div>

            {/* noi dung thu */}
            <div
              className="overflow-auto"
              style={{
                height: mailHtmlHeight,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: item?.content ?? '' }} className=""></div>
            </div>

            {/* attachments */}
            <div className="d-flex flex-wrap align-items-center gap-2 mt-2" ref={refAttachment}>
              {item?.attachments?.map((obj, index) => {
                return (
                  <div key={index}>
                    <MailAttachmentView item={obj} onPress={() => {}} />
                  </div>
                );
              })}
            </div>

            {/* actions */}
            <div
              className="d-flex align-items-center justify-content-end gap-3 mt-3"
              ref={refActions}
            >
              <VIButton
                text={t('Cancel')}
                className="btn-grey"
                style={{ padding: '8px' }}
                onClick={handlePressCancel}
              />
              <VIButton
                text={t('Forward')}
                className=""
                style={{ padding: '8px' }}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
                beforeIcon={isForwarding ? <ClockLoader color="white" size={20} /> : <></>}
                disabled={isForwarding}
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ViewForwardMail;
