import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KeenSelectOption from 'general/components/OtherKeenComponents/KeenSelectOption';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDateRangePicker2 from 'general/components/VietInvoice/VIDateRangePicker2';
import AppData from 'general/constants/AppData';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

ViewMailFilter.propTypes = {
  onPressCancel: PropTypes.func,
  onPressFilter: PropTypes.func,
  initialFilters: PropTypes.object,
};

ViewMailFilter.defaultProps = {
  onPressCancel: null,
  onPressFilter: null,
  initialFilters: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * onPressFilter: function,
 * initialFilters: object,
 * }} props
 * @returns
 */
function ViewMailFilter(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { onPressCancel, initialFilters, onPressFilter } = props;
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  // ---- methods ----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  return (
    <div className="p-4 min-w-300px">
      <div className="font-size-h3 font-weight-bold mb-3">{t('MailFilter')}</div>

      <div>
        <KTFormGroup
          label={t('ReceiveDate')}
          inputName="receiveDate"
          additionalClassName="mb-2"
          inputElement={
            <VIDateRangePicker2
              inlineMode={true}
              alignRight={false}
              initialValue={filters?.dateRangeValue}
              onPressSave={(selectedValue, startDate, endDate) => {
                setFilters({
                  ...filters,
                  dateRangeValue: selectedValue,
                  from: startDate,
                  to: endDate,
                });
              }}
            />
          }
        />

        <KTFormGroup
          label={<>{t('ReadMailStatus')}</>}
          inputName="readMailStatus"
          // additionalClassName="mb-2"
          inputElement={
            <KeenSelectOption
              name="readMailStatus"
              menuClassName="p-2"
              initialValue={filters.readStatus}
              options={[{ name: 'All', value: '' }, ...AppData.mailReadStatusOptions]}
              containerClassName=""
              labelClassName=""
              selectClassName="w-100"
              onValueChanged={(value) => {
                setFilters({
                  ...filters,
                  readStatus: value,
                });
              }}
            />
          }
        />

        <KTFormGroup
          label={<>{t('Status')}</>}
          inputName="statusValidMail"
          additionalClassName="mb-2"
          inputElement={
            <KeenSelectOption
              name="statusValidMail"
              menuClassName="p-2"
              initialValue={filters.statusValidMail}
              options={[...AppData.mailCheckboxOptions]}
              containerClassName=""
              labelClassName=""
              selectClassName="w-100"
              onValueChanged={(value) => {
                setFilters({
                  ...filters,
                  statusValidMail: value,
                });
              }}
            />
          }
        />
      </div>

      {/* actions */}
      <div className="d-flex align-items-center justify-content-end gap-3 mt-3">
        <VIButton
          text={t('Cancel')}
          className="btn-grey"
          style={{ padding: '8px' }}
          onClick={handlePressCancel}
        />
        <VIButton
          text={t('Filter')}
          className=""
          style={{ padding: '8px' }}
          onClick={() => {
            if (onPressFilter) {
              handlePressCancel();
              if (filters.statusValidMail === 'ALL') {
                filters.statusValidMail = '';
              }
              onPressFilter(filters);
            }
          }}
        />
      </div>
    </div>
  );
}

export default ViewMailFilter;
