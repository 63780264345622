import { FastField, Formik } from 'formik';
import KTImageInput from 'general/components/OtherKeenComponents/FileUpload/KTImageInput';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KeenSelectOption from 'general/components/OtherKeenComponents/KeenSelectOption';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppConfigs from 'general/constants/AppConfigs';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import AccountHelper from 'general/helpers/AccountHelper';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useConfig from 'hooks/useConfig';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';

AccountPersonalInfoScreen.propTypes = {
  navItemList: PropTypes.array,
};

AccountPersonalInfoScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function AccountPersonalInfoScreen(props) {
  // MARK --- Params ---
  const { navItemList } = props;
  const { t } = useTranslation();
  const refCardHeader = useRef(null);
  const [cardBodyMaxHeight, setCardBodyMaxHeight] = useState('55vh');
  const account = useAccount();
  const isUpdating = useSelector((state) => state.auth.isUpdatingInfo);
  const refFileAvatar = useRef();
  const refFileFrontCard = useRef();
  const refFileBackCard = useRef();
  const bannerHeight = useConfig().systemNotification.bannerHeight;
  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  // scroll into card
  function resizeScreen() {
    setCardBodyMaxHeight(
      `${
        window.innerHeight -
        refCardHeader?.current?.clientHeight -
        187 -
        bannerHeight +
        (AppConfigs.isHideFooter ? 65 : 0)
      }px`
    );
  }
  window.onresize = resizeScreen;

  useEffect(() => {
    resizeScreen();
  }, [window.innerWidth, window.innerHeight]);

  // ---- methods -----

  return (
    <Formik
      initialValues={{
        // thong tin co ban
        avatar: account.avatar,
        fullname: account.fullname,
        dob: account.dob,
        gender: account.gender,
        // thong tin lien he
        email: account.email,
        phone: account.phone,
        address: account.address,
        // cmnd/cccd
        peopleIdentification: account.cccdNumber,
        issueDate: account.cccdDate,
        issuePlace: account.cccdCreatePlace,
        frontCard: account.cccdFrontImg,
        backCard: account.cccdBackImg,
      }}
      enableReinitialize
      validationSchema={Yup.object({
        email: Yup.string()
          .nullable()
          .test('len', t('EmailInvalid', { field: t('email') }), (val) => {
            if (val) {
              return !val.toString().includes(' ') && val.toString().match(AppConfigs.regexEmail);
            }
            return true;
          }),
        phone: Yup.string()
          .nullable()
          .test('len', t('PhoneInvalid', { field: t('phone') }), (val) => {
            if (val) {
              return !val.toString().includes(' ') && val.toString().match(AppConfigs.regexPhone);
            }
            return true;
          }),
        peopleIdentification: Yup.string()
          .nullable()
          .test('len', t('CCCDNumberInvalid', { field: t('peopleIdentification') }), (val) => {
            if (val) {
              return (
                (!val.toString().includes(' ') && val.toString().match(AppConfigs.regexCCCD9)) ||
                val.toString().match(AppConfigs.regexCCCD12)
              );
            }
            return true;
          }),
      })}
      onSubmit={async (values) => {
        const params = {
          // thong tin co ban
          avatar: refFileAvatar.current,
          fullname: values.fullname,
          gender: values.gender,
          dob: values.dob,
          // thong tin lien he
          email: values.email,
          phone: values.phone,
          address: values.address,
          // cccd
          cccdNumber: values.peopleIdentification,
          cccdDate: values.issueDate,
          cccdCreatePlace: values.issuePlace,
          cccdFrontImg: refFileFrontCard.current,
          cccdBackImg: refFileBackCard.current,
        };
        Utils.removeNullKey(params);
        await AccountHelper.callApiUpdateProfile(params);
      }}
    >
      {(formikProps) => (
        <div className="">
          <div
            ref={refCardHeader}
            className="px-7 py-4 border-bottom d-flex flex-wrap align-items-center justify-content-between gap-2 min-h-65px"
          >
            <div className="card-title align-items-start flex-column m-0">
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div className="d-block d-lg-none">
                  <KTBSDropdown
                    toggleElement={
                      <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                        <i className="fa-solid fa-bars text-grey" />
                      </div>
                    }
                    alignment={KTBSDropdownAlignments.start}
                    dropdownMenuItems={dropdownMenuItems}
                    selectedValue={t('PersonalInfo')}
                    onChange={(newValue) => {}}
                  />
                </div>{' '}
                <h3 className="card-label font-weight-bolder text-grey m-0">
                  {t('PersonalInformation')}
                </h3>
              </div>
            </div>

            {/* toolbars */}
            <div className="card-toolbar">
              <VIButton
                className="btn-blue"
                text={isUpdating ? `${t('Updating')}...` : t('Save')}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
                disabled={isUpdating}
                beforeIcon={
                  isUpdating ? (
                    <ClockLoader color="white" size={20} />
                  ) : (
                    <i className="fa-regular fa-circle-check" />
                  )
                }
              />
            </div>
          </div>

          <div className="card-body overflow-auto" style={{ maxHeight: cardBodyMaxHeight }}>
            {/* thong tin co ban */}
            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bolder mb-6 text-grey">{t('BasicInformation')}</h5>
              </div>
            </div>

            {/* avatar */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('Avatar')}`}
              </label>
              <KTFormGroup
                additionalClassName="d-flex flex-row col-lg-9 col-xl-6"
                inputName="avatar"
                inputElement={
                  <div>
                    <FastField name="avatar">
                      {({ field, form, meta }) => {
                        return (
                          <KTImageInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            defaultImage={AppResource.images.imgDefaultAvatar}
                            acceptImageTypes={AppConfigs.acceptImages}
                            onSelectedFile={(file) => {
                              refFileAvatar.current = file;
                            }}
                            onRemovedFile={() => {
                              refFileAvatar.current = null;
                              form.setFieldValue(field.name, '');
                            }}
                            additionalClassName=""
                            editable={false}
                          />
                        );
                      }}
                    </FastField>
                    <span className="form-text text-muted">{`${t(
                      'SupportedFormats'
                    )}: png, jpg, jpeg.`}</span>
                  </div>
                }
              />
            </div>

            {/* ho va ten */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('Fullname')}`}
              </label>
              <KTFormGroup
                additionalClassName="col-lg-9 col-xl-6"
                inputName="fullname"
                inputElement={
                  <div>
                    <FastField name="fullname">
                      {({ field, form, meta }) => {
                        return (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('Fullname'))}`}
                            enableInputGroup={true}
                            prependElements={[<i className="fa-regular fa-user"></i>]}
                          />
                        );
                      }}
                    </FastField>
                  </div>
                }
              />
            </div>

            {/* gioi tinh */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('Gender')}`}
              </label>
              <KTFormGroup
                additionalClassName="col-lg-9 col-xl-6"
                inputName="gender"
                inputElement={
                  <div>
                    <FastField name="gender">
                      {({ field, form, meta }) => {
                        return (
                          <KeenSelectOption
                            name={field.name}
                            fieldProps={formikProps.getFieldProps(field.name)}
                            fieldMeta={formikProps.getFieldMeta(field.name)}
                            fieldHelpers={formikProps.getFieldHelpers(field.name)}
                            options={AppData.genderItems}
                            containerClassName=""
                            labelClassName=""
                            selectClassName="w-100"
                            onValueChanged={(value) => {
                              formikProps.getFieldHelpers(field.name).setValue(value);
                            }}
                          />
                        );
                      }}
                    </FastField>
                  </div>
                }
              />
            </div>

            {/* ngay sinh */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('DateOfBirth')}`}
              </label>
              <KTFormGroup
                additionalClassName="col-lg-9 col-xl-6"
                inputName="dob"
                inputElement={
                  <div>
                    <FastField name="dob">
                      {({ field, form, meta }) => {
                        return (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.date}
                            placeholder={`${_.capitalize(t('DateOfBirth'))}`}
                            enableInputGroup={true}
                            prependElements={[<i className="fa-regular fa-calendar-plus"></i>]}
                          />
                        );
                      }}
                    </FastField>
                  </div>
                }
              />
            </div>

            <div className="separator separator-dashed mt-2 gutter-b"></div>

            {/* thong tin lien he */}
            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bolder mb-6 text-grey">{t('ContactInformation')}</h5>
              </div>
            </div>

            {/* email */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('Email')}`}
              </label>
              <KTFormGroup
                additionalClassName="col-lg-9 col-xl-6"
                inputName="email"
                inputElement={
                  <div>
                    <FastField name="email">
                      {({ field, form, meta }) => {
                        return (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.email}
                            placeholder={`${_.capitalize(t('Email'))}`}
                            enableInputGroup={true}
                            prependElements={[<i className="fa-regular fa-envelope"></i>]}
                          />
                        );
                      }}
                    </FastField>
                  </div>
                }
              />
            </div>

            {/* so dien thoai */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('PhoneNumber')}`}
              </label>
              <KTFormGroup
                additionalClassName="col-lg-9 col-xl-6"
                inputName="phone"
                inputElement={
                  <div>
                    <FastField name="phone">
                      {({ field, form, meta }) => {
                        return (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('PhoneNumber'))}`}
                            enableInputGroup={true}
                            prependElements={[<i className="fa-regular fa-phone"></i>]}
                          />
                        );
                      }}
                    </FastField>
                  </div>
                }
              />
            </div>

            {/* dia chi */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('Address')}`}
              </label>
              <KTFormGroup
                additionalClassName="col-lg-9 col-xl-6"
                inputName="address"
                inputElement={
                  <div>
                    <FastField name="address">
                      {({ field, form, meta }) => {
                        return (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('Address'))}`}
                            enableInputGroup={true}
                            prependElements={[<i className="fa-regular fa-map"></i>]}
                          />
                        );
                      }}
                    </FastField>
                  </div>
                }
              />
            </div>

            <div className="separator separator-dashed mt-2 gutter-b"></div>

            {/* CMND/CCCD */}
            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bolder mb-6 text-grey">{t('CitizenIdentification')}</h5>
              </div>
            </div>

            {/* so cmnd/cccd */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('CitizenIdentification')}`}
              </label>
              <KTFormGroup
                additionalClassName="col-lg-9 col-xl-6"
                inputName="peopleIdentification"
                inputElement={
                  <div>
                    <FastField name="peopleIdentification">
                      {({ field, form, meta }) => {
                        return (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('CitizenIdentification'))}`}
                            enableInputGroup={true}
                            prependElements={[<i className="fa-regular fa-circle-user"></i>]}
                          />
                        );
                      }}
                    </FastField>
                  </div>
                }
              />
            </div>

            {/* ngay cap */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('IssueDate')}`}
              </label>
              <KTFormGroup
                additionalClassName="col-lg-9 col-xl-6"
                inputName="issueDate"
                inputElement={
                  <div>
                    <FastField name="issueDate">
                      {({ field, form, meta }) => {
                        return (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.date}
                            placeholder={`${_.capitalize(t('IssueDate'))}`}
                            enableInputGroup={true}
                            prependElements={[<i className="fa-regular fa-calendar-day"></i>]}
                          />
                        );
                      }}
                    </FastField>
                  </div>
                }
              />
            </div>

            {/* noi cap */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('IssuePlace')}`}
              </label>
              <KTFormGroup
                additionalClassName="col-lg-9 col-xl-6"
                inputName="issuePlace"
                inputElement={
                  <div>
                    <FastField name="issuePlace">
                      {({ field, form, meta }) => {
                        return (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('IssuePlace'))}`}
                            enableInputGroup={true}
                            prependElements={[<i className="fa-regular fa-address-card"></i>]}
                          />
                        );
                      }}
                    </FastField>
                  </div>
                }
              />
            </div>

            {/* anh */}
            <div className="row">
              <label className="col-xl-3 col-lg-3 col-form-label text-lg-right text-grey font-weight-bolder">
                {`${t('Image')}`}
              </label>
              <div className="d-flex gap-4 flex-wrap flex-row col-lg-9 col-xl-6">
                {/* mat truoc */}
                <KTFormGroup
                  additionalClassName=""
                  inputName="frontCard"
                  inputElement={
                    <div>
                      <FastField name="frontCard">
                        {({ field, form, meta }) => {
                          return (
                            <KTImageInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              defaultImage={AppResource.images.imgDefaultFrontCard}
                              acceptImageTypes={AppConfigs.acceptImages}
                              onSelectedFile={(file) => {
                                refFileFrontCard.current = file;
                              }}
                              onRemovedFile={() => {
                                refFileFrontCard.current = null;
                                form.setFieldValue(field.name, '');
                              }}
                              additionalImageInputWrapperClass="w-180px h-120px"
                              editable={false}
                            />
                          );
                        }}
                      </FastField>
                      <span className="form-text text-muted">{t('FrontCard')}</span>
                    </div>
                  }
                />

                {/* mat sau */}
                <KTFormGroup
                  additionalClassName=""
                  inputName="backCard"
                  inputElement={
                    <div>
                      <FastField name="backCard">
                        {({ field, form, meta }) => {
                          return (
                            <KTImageInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              defaultImage={AppResource.images.imgDefaultBackCard}
                              acceptImageTypes={AppConfigs.acceptImages}
                              onSelectedFile={(file) => {
                                refFileBackCard.current = file;
                              }}
                              onRemovedFile={() => {
                                refFileBackCard.current = null;
                                form.setFieldValue(field.name, '');
                              }}
                              additionalImageInputWrapperClass="w-180px h-120px"
                              editable={false}
                            />
                          );
                        }}
                      </FastField>
                      <span className="form-text text-muted">{t('BackCard')}</span>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default AccountPersonalInfoScreen;
