import React from 'react';
import PropTypes from 'prop-types';
// import './style.scss';

VIElements.propTypes = {
  id: PropTypes.any,
  text: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  icon: PropTypes.element,
  style: PropTypes.object,
};

VIElements.defaultProps = {
  id: null,
  text: null,
  fontSize: null,
  fontWeight: null,
  icon: <></>,
  style: {},
};

/**
 * @param {any} id Button id
 * @param {string} text Button text
 * @param {string} fontSize text's font size
 * @param {string} fontWeight text's font weight
 * @param {element} icon Button icon before text
 * @param {object} style Button style
 */

function VIElements(props) {
  const { id, text, fontSize, fontWeight, icon, style } = props;

  return (
    <button
      id={id}
      className={`d-flex flex-row align-items-center justify-content-center `}
      style={{
        whiteSpace: 'nowrap',
        fontSize: fontSize || '1rem',
        fontWeight: fontWeight || '400',
        borderRadius: '12px',
        ...style,
      }}
    >
      {/* before icon */}
      {icon}

      {/* text */}
      <span className={`${icon ? 'ms-2' : ''}`}>{text}</span>
    </button>
  );
}

export default VIElements;
