import NotificationHelper from 'general/helpers/NotificationHelper';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useNotification(props) {
  // ---- params ----
  const { notificationList, totalCount } = useSelector((state) => state.notification);

  return useMemo(() => {
    const unreadCount = totalCount - NotificationHelper.countUnreadNotifications();

    return {
      unreadCount,
      totalCount,
    };
  }, [notificationList]);
}

export default useNotification;
