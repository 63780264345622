import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import './style.scss';

VIOverViewBar.propTypes = {
  itemTab: PropTypes.array,
  handleClick: PropTypes.func,
  currentSelect: PropTypes.number,
  showDropdown: PropTypes.number,
};

VIOverViewBar.defaultProps = {
  itemTab: [],
  handleClick: () => {},
  currentSelect: null,
  showDropdown: null,
};

/**
 * @param {array} itemTab overviewBar element
 * @param {function} handleClick click function selects active value
 * @param {number} currentSelect current selected
 * @param {number} showDropdown click vào button dropdown để lấy giá trị ngẫu nhiên
 */

function VIOverViewBar(props) {
  const { itemTab, handleClick, currentSelect, showDropdown } = props;
  const headerRef = useRef([]);
  const markerRef = useRef();
  const [active, setActive] = useState(currentSelect ?? 0);

  useEffect(() => {
    markerRef.current.style.left = headerRef.current[currentSelect ?? 0]?.offsetLeft + 'px';
    markerRef.current.style.width = headerRef.current[currentSelect ?? 0]?.offsetWidth + 'px';
    setActive(currentSelect ?? 0);
  }, [currentSelect, showDropdown]);

  const handleClickOne = (index, title) => {
    markerRef.current.style.left = headerRef.current[index]?.offsetLeft + 'px';
    markerRef.current.style.width = headerRef.current[index]?.offsetWidth + 'px';
    handleClick(index);
    setActive(index);
  };

  return (
    <div className="VIOverViewBar d-flex flex-row overflow-auto hide-scrollbar">
      {itemTab.map((item, index) => {
        return (
          <div
            key={index}
            className={`itemBx flex-shrink-0 mr-4 ${active === index ? 'active' : ''} mb-2`}
            onClick={() => handleClickOne(index, item.title)}
            ref={(el) => (headerRef.current[index] = el)}
          >
            {item.title}
            {item?.contentRight}
          </div>
        );
      })}
      <div className="marker" ref={markerRef}></div>
    </div>
  );
}

export default VIOverViewBar;
