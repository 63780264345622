import Global from 'general/utils/Global';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function OrderDetailRoute(props) {
  // MARK: --- Params ---
  const { selectedOrderInfo } = useSelector((state) => state.payment);
  // console.log({ selectedOrderInfo });

  return !_.isNull(selectedOrderInfo) ? (
    props.children
  ) : (
    <Navigate
      to={
        !_.isEmpty(Global.gTempPrevPath) ? Global.gTempPrevPath : '/inbot/account/payment-history'
      }
    />
  );
}

export default OrderDetailRoute;
