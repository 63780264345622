import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import EmptyView from 'features/Others/components/EmptyView';
import VILoading from 'general/components/VietInvoice/VILoading';
import AppData from 'general/constants/AppData';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

CardAreaChartV2.propTypes = {
  additionalClassName: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  chartSeries: PropTypes.array,
  chartLabels: PropTypes.array,
  toolbarElement: PropTypes.element,
  sideBarElement: PropTypes.element,
  chartColors: PropTypes.array,
  headerSidebar: PropTypes.element,
  loading: PropTypes.bool,
  fullChartLabels: PropTypes.array,
  customYAxis: PropTypes.string,
  fill: PropTypes.bool,
};

CardAreaChartV2.defaultProps = {
  additionalClassName: '',
  title: '',
  subTitle: '',
  chartSeries: [],
  chartLabels: [],
  toolbarElement: null,
  sideBarElement: null,
  chartColors: AppData.chartColors,
  headerSidebar: null,
  loading: false,
  fullChartLabels: [],
  customYAxis: '',
  fill: true,
};
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarController
);
function CardAreaChartV2(props) {
  const {
    additionalClassName,
    title,
    subTitle,
    chartSeries,
    chartLabels,
    toolbarElement,
    sideBarElement,
    chartColors,
    headerSidebar,
    loading,
    fullChartLabels,
    customYAxis,
    fill,
  } = props;
  const [newSeries, setNewSeries] = useState([]);
  const { t } = useTranslation();

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,

      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            font: {
              size: 12,
            },
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }

              return label + Utils.formatNumber(context.parsed.y);
            },
          },
        },
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
      scales: {
        x: {
          // ticks: {
          //   callback: function (value, index, ticks) {
          //     return chartLabels[index].slice(0, 2);
          //   },
          // },
        },
        y: {
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            callback: function (value, index, ticks) {
              if (customYAxis) {
                return value + customYAxis;
              } else {
                return Utils.formatNumber(value);
              }
            },
          },
        },
        y: {
          type: 'linear',
          display: true,
          position: 'right',
          ticks: {
            callback: function (value, index, ticks) {
              if (customYAxis) {
                return value + customYAxis;
              } else {
                return Utils.formatNumber(value);
              }
            },
          },
        },
      },
    }),
    [chartLabels]
  );

  const series = useMemo(() => {
    const result = [];
    for (let i = 0; i < chartSeries.length; i++) {
      const data = [];
      for (let j = 0; j < fullChartLabels.length; j++) {
        if (
          Utils.removeDuplicates(
            chartLabels.map((item) => Utils.formatDateTime(item, 'DD/MM/YYYY'))
          )?.includes(fullChartLabels[j])
        ) {
          const index = Utils.removeDuplicates(
            chartLabels.map((item) => Utils.formatDateTime(item, 'DD/MM/YYYY'))
          ).indexOf(fullChartLabels[j]);
          data.push(chartSeries[i].data[index]);
        } else {
          data.push(0);
        }
      }
      result.push({ name: chartSeries[i].name, data });
    }
    return result;
  }, [chartSeries, newSeries]);

  const data = useMemo(() => {
    return {
      labels: chartLabels,
      datasets: chartSeries.map((item, index) => {
        return {
          label: item.name,
          type: item.name === t('TotalPayment') ? 'line' : 'bar',
          yAxisID: `y${index > 0 ? index : ''}`,
          data: item.data,
          borderColor: chartColors[index],
          backgroundColor: chartColors[index],
          fill: fill,
          // backgroundColor: [0.6].map((item) => Utils.blurColor(chartColors[index], item)),
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
        };
      }),
    };
  }, [chartSeries, chartLabels]);

  const apexChartEl = useMemo(() => {
    return <Line options={options} data={data} />;
  }, [options, chartSeries]);

  useEffect(() => {
    setNewSeries(
      chartSeries?.map((item) => {
        return {
          ...item,
          data: item?.data?.slice(0, Utils.removeDuplicates(chartLabels)?.length),
        };
      })
    );
  }, [chartSeries, chartLabels]);

  return (
    <div
      className={`CardAreaChartV2 card card-custom bg-white mb-8 ${
        sideBarElement ? 'row m-0 pr-0' : ''
      }`}
      style={{ borderRadius: '14px' }}
    >
      <div className={`${sideBarElement ? 'col-9' : ''}`}>
        <div
          className={`card-header bg-transparent px-6 border-0 py-6 ${
            toolbarElement ? '' : 'pb-0'
          }`}
        >
          <div
            className={`${toolbarElement ? 'mb-6' : ''} ${
              headerSidebar ? 'd-flex flex-wrap align-items-center justify-content-between' : ''
            }`}
          >
            {/* card title */}
            <h3 className="card-title m-0">
              <span className="card-label font-weight-bolder font-size-h4 ">{title}</span>
              <span className="d-block text-muted mt-2 font-size-base">{subTitle}</span>
            </h3>
            <div>{headerSidebar}</div>
          </div>
          {/* card toolbar */}
          {toolbarElement && <div className="card-toolbar">{toolbarElement}</div>}
        </div>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '340px' }}
          >
            <VILoading />
          </div>
        ) : chartSeries?.some((item) => item.data?.length > 0) ? (
          <div>
            <div className="d-flex flex-row justify-content-between font-weight-bolder font-size-h6 mb-3 px-6">
              <span>{t('Total')}</span>
              <span>{t('TotalAmount')}</span>
            </div>
            <div className="h-100 mb-8 pr-3 pl-6" style={{ minHeight: '340px' }}>
              {apexChartEl}
            </div>
          </div>
        ) : (
          <div className="pt-12" style={{ height: '340px' }}>
            <EmptyView message={t('NoDataToDisplay')} />
          </div>
        )}
      </div>
      {sideBarElement && <div className="border-left col-3 px-0">{sideBarElement}</div>}
    </div>
  );
}

export default CardAreaChartV2;
