import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import AddCompanyTitleView from 'features/System/components/AddCompanyTitleView';
import AddUserView from 'features/System/components/AddUserView';
import {
  thunkGetAllCompanyTitles,
  thunkGetAllSystemRoles,
  thunkGetAllSystemUsers,
} from 'features/System/systemSlice';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIModal from 'general/components/VietInvoice/VIModal';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import SystemHelper from 'general/helpers/SystemHelper';
import Global from 'general/utils/Global';
import useAccount from 'hooks/useAccount';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

SystemUserScreen.propTypes = {
  navItemList: PropTypes.array,
};

SystemUserScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function SystemUserScreen(props) {
  // ---- params ----
  const { navItemList } = props;
  const { t } = useTranslation();
  const account = useAccount();
  const [modalAddUserShowing, setModalAddUserShowing] = useState(false);
  const [modalAddCompanyTitleShowing, setModalAddCompanyTitleShowing] = useState(false);
  const { allSystemUserList, isGettingSystemUserList, allSystemRoleList, allCompanyTitleList } =
    useSelector((state) => state.system);
  const [filters, setFilters] = useState(Global.gFiltersAllUserList);
  const columns = useMemo(() => {
    return [
      {
        name: t('Fullname'),
        sortable: false,
        minWidth: '200px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 font-weight-bold m-0">
              {row?.fullname}
            </p>
          );
        },
      },
      {
        name: t('UserTitle'),
        sortable: false,
        minWidth: '160px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {SystemHelper.getSystemUserTitle(row)}
            </p>
          );
        },
      },
      {
        name: t('Username'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.companyUsername}
            </p>
          );
        },
      },
      {
        name: t('Email'),
        sortable: false,
        minWidth: '280px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.email}
            </p>
          );
        },
      },
      {
        name: t('PhoneNumber'),
        sortable: false,
        minWidth: '160px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.phone}
            </p>
          );
        },
      },
      {
        name: t('Role'),
        sortable: false,
        minWidth: '160px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {SystemHelper.getSystemUserRole(row)}
            </p>
          );
        },
      },
      {
        name: t('State'),
        sortable: false,
        width: '140px',
        center: true,
        style: {
          borderRight: 'none',
        },
        cell: (row) => {
          return (
            <KTTooltip text={row?.active ? t('Active') : t('InActive')}>
              {row?.active ? (
                <i className="fa-regular fa-circle-check text-primary" />
              ) : (
                <i className="fa-regular fa-times-circle text-danger" />
              )}
            </KTTooltip>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            {account?.accountId !== row?.accountId ? (
              <VITableRowDropDown
                toggleElement={
                  <div>
                    <i className="text-grey fas fa-ellipsis-h p-0"></i>
                  </div>
                }
                menuItem={
                  account?.accountId !== row?.accountId
                    ? [
                        {
                          iconElement: <i className="far fa-edit"></i>,
                          text: t('Edit'),
                          click: () => {
                            handleEditUser(row);
                          },
                        },
                        {
                          iconElement: <i className="far fa-trash-alt"></i>,
                          text: t('Delete'),
                          click: () => {
                            handleDeleteUser(row);
                          },
                        },
                      ]
                    : [
                        {
                          iconElement: <i className="far fa-edit"></i>,
                          text: t('Edit'),
                          click: () => {
                            handleEditUser(row);
                          },
                        },
                      ]
                }
              />
            ) : (
              <></>
            )}
          </div>
        ),
      },
    ];
  }, [t]);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  useEffect(() => {
    dispatch(thunkGetAllSystemUsers(filters));
  }, [filters]);

  useEffect(() => {
    dispatch(thunkGetAllCompanyTitles());
  }, []);

  useEffect(() => {
    if (_.isEmpty(allSystemRoleList)) {
      dispatch(thunkGetAllSystemRoles());
    }
  }, [allSystemRoleList]);

  // ---- methods -----
  function handleEditUser(row) {
    if (account.accountId === row?.accountId) {
      return;
    }

    setCurrentSelectedUser(row);
    setModalAddUserShowing(true);
  }

  function handleDeleteUser(row) {
    AppDialogHelper.show(
      t('DeleteUser'),
      t('ConfirmDeleteUser', { name: row?.fullname ?? row?.email }),
      [
        {
          title: t('Cancel'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
        {
          title: t('Delete'),
          type: 'negative',
          onClick: () => {
            SystemHelper.callApiDeleteSystemUsers([row?.accountId]);
            AppDialogHelper.hide();
          },
        },
      ]
    );
  }

  return (
    <div className="h-100">
      <VIDataTable
        draggable={false}
        tableStyles={customDataTableActionStyle}
        title={
          <div className="d-flex align-items-center justify-content-center gap-1">
            <div className="d-block d-lg-none">
              <KTBSDropdown
                toggleElement={
                  <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                    <i className="fa-solid fa-bars text-grey" />
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                dropdownMenuItems={dropdownMenuItems}
                selectedValue={t('ManageUser')}
                onChange={(newValue) => {}}
              />
            </div>{' '}
            {t('ManageUser')}
          </div>
        }
        selectable={false}
        pagination={null}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          Global.gFiltersAllUserList = {
            ...Global.gFiltersAllUserList,
            page: iNewPage,
          };
          setFilters(Global.gFiltersAllUserList);
        }}
        onChangeRowsPerPage={(perPage) => {
          Global.gFiltersAllUserList = {
            ...Global.gFiltersAllUserList,
            limit: perPage,
            page: 0,
          };
          setFilters(Global.gFiltersAllUserList);
        }}
        fullColumns={columns}
        data={allSystemUserList}
        loading={isGettingSystemUserList}
        filterbar={
          <>
            <VISearchField
              placeholder={`${t('EnterToSearch')}`}
              value={filters.q}
              className=""
              typingTimeout={500}
              onSubmit={(newValue) => {
                Global.gFiltersAllUserList = {
                  ...Global.gFiltersAllUserList,
                  q: newValue,
                  page: 0,
                };
                setFilters(Global.gFiltersAllUserList);
              }}
            />
          </>
        }
        onRowClick={(row) => {
          handleEditUser(row);
        }}
        onSetSelectedMultiItems={(rows) => {}}
        toolbar={
          <>
            <VIButton
              beforeIcon={<i className="fa-regular fa-plus" />}
              text={t('AddUser')}
              className="btn-blue border-0 p-3"
              onClick={() => {
                Global.resetTempSystemUserInfo();
                setModalAddUserShowing(true);
              }}
            />
          </>
        }
      />

      {/* modal add user */}
      <VIModal
        titleModal={_.isNull(currentSelectedUser) ? t('AddUser') : t('EditUser')}
        modalSize="lg"
        show={modalAddUserShowing}
        contentModal={
          <AddUserView
            item={currentSelectedUser}
            onPressCancel={() => {
              setModalAddUserShowing(false);
              setCurrentSelectedUser(null);
              Global.gTempCompanyTitle = null;
            }}
            onPressAddCompanyTitle={() => {
              setModalAddUserShowing(false);
              setModalAddCompanyTitleShowing(true);
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalAddUserShowing(false);
          setCurrentSelectedUser(null);
          Global.gTempCompanyTitle = null;
        }}
      />

      {/* modal add company title */}
      <VIModal
        titleModal={t('AddCompanyTitle')}
        show={modalAddCompanyTitleShowing}
        contentModal={
          <AddCompanyTitleView
            onPressCancel={() => {
              setModalAddCompanyTitleShowing(false);
              setModalAddUserShowing(true);
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalAddCompanyTitleShowing(false);
          setModalAddUserShowing(true);
        }}
      />
    </div>
  );
}

export default SystemUserScreen;
