import miscApi from 'api/miscApi';
import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import EmptyView from 'features/Others/components/EmptyView';
import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputIconPosition,
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTFormTextArea from 'general/components/OtherKeenComponents/Forms/KTFormTextArea';
import KTCheckbox, {
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIAppTabs from 'general/components/VietInvoice/VIAppTabs';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import AppConfigs from 'general/constants/AppConfigs';
import OrganizationHelper from 'general/helpers/OrganizationHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import useAccount from 'hooks/useAccount';
import useConfig from 'hooks/useConfig';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

ModalAddMultiCompanyContent.propTypes = {
  onPressCancel: PropTypes.func,
  onCheckedAcceptMultiNameChange: PropTypes.func,
  company: PropTypes.object,
};

ModalAddMultiCompanyContent.defaultProps = {
  onPressCancel: null,
  onCheckedAcceptMultiNameChange: null,
  company: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * onCheckedAcceptMultiNameChange: function,
 * company: object,
 * }} props
 * @returns
 */
function ModalAddMultiCompanyContent(props) {
  // ---- params ----
  const { onPressCancel, onCheckedAcceptMultiNameChange, company } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyInfos = useRef([{ name: '', address: '', startDate: '', endDate: '' }]);
  const account = useAccount();
  const [flag, setFlag] = useState({});
  const columns = useMemo(() => {
    return [
      {
        name: t('CompanyName'),
        sortable: false,
        minWidth: '180px',
        cell: (row, index) => {
          return (
            // <KTFormInput
            //   name={`name_${index}`}
            //   value={row?.name}
            //   type={KTFormInputType.text}
            //   onChange={(value) => {
            //     companyInfos.current[index].name = value;
            //   }}
            // />
            <KTFormTextArea
              name={`name_${index}`}
              value={row?.name}
              rows={5}
              onChange={(value) => {
                companyInfos.current[index].name = value;
              }}
            />
          );
        },
      },
      {
        name: t('Address'),
        sortable: false,
        minWidth: '180px',
        cell: (row, index) => {
          return (
            <KTFormTextArea
              name={`address_${index}`}
              value={row?.address}
              rows={5}
              onChange={(value) => {
                companyInfos.current[index].address = value;
              }}
            />
          );
        },
      },
      {
        name: t('StartDate'),
        sortable: false,
        width: '180px',
        cell: (row, index) => {
          return (
            <KTFormInput
              name={`startDate_${index}`}
              value={row?.startDate}
              type={KTFormInputType.date}
              onChange={(value) => {
                companyInfos.current[index].startDate = value;
              }}
            />
          );
        },
      },
      {
        name: t('EndDate'),
        sortable: false,
        width: '180px',
        cell: (row, index) => {
          return (
            <KTFormInput
              name={`endDate_${index}`}
              value={row?.endDate}
              type={KTFormInputType.date}
              onChange={(value) => {
                companyInfos.current[index].endDate = value;
              }}
            />
          );
        },
      },
      {
        name: t('AcceptPerson'),
        sortable: false,
        minWidth: '180px',
        style: {
          borderRight: 0,
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row.acceptBy ?? account.displayName}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between">
            <i
              className="far fa-trash-alt text-danger"
              onClick={() => {
                companyInfos.current.splice(index, 1);
                if (companyInfos.current.length === 0) {
                  companyInfos.current.push({ name: '', address: '', startDate: '', endDate: '' });
                }
                setFlag({});
              }}
            />
          </div>
        ),
      },
    ];
  }, [t, account, companyInfos.current]);
  const appConfig = useConfig();
  const isEditMode = !_.isNull(company);
  const tabList = useMemo(() => {
    return [t('Thông tin chấp nhận được bổ sung'), t('Lịch sử thay đổi GPKD')];
  }, []);
  const [activeTab, setActiveTab] = useState(tabList[1]);
  const acceptPartnerCompanyDetailInfos = useRef([]);
  const columnsAcceptPartnerDetailInfo = useMemo(() => {
    return [
      {
        name: t('Tên được chấp nhận'),
        sortable: false,
        minWidth: '180px',
        cell: (row, index) => {
          return (
            <KTFormTextArea
              name={`detail_name_${index}`}
              value={row?.name}
              rows={5}
              onChange={(value) => {
                acceptPartnerCompanyDetailInfos.current[index].name = value;
              }}
            />
          );
        },
      },
      {
        name: t('Địa chỉ được chấp nhận'),
        sortable: false,
        minWidth: '180px',
        cell: (row, index) => {
          return (
            <KTFormTextArea
              name={`detail_address_${index}`}
              value={row?.address}
              rows={5}
              onChange={(value) => {
                acceptPartnerCompanyDetailInfos.current[index].address = value;
              }}
            />
          );
        },
      },
      {
        name: t('StartDate'),
        sortable: false,
        width: '180px',
        cell: (row, index) => {
          return (
            <KTFormInput
              name={`detail_startDate_${index}`}
              value={row?.startDate}
              type={KTFormInputType.date}
              onChange={(value) => {
                acceptPartnerCompanyDetailInfos.current[index].startDate = value;
              }}
            />
          );
        },
      },
      {
        name: t('EndDate'),
        sortable: false,
        width: '180px',
        cell: (row, index) => {
          return (
            <KTFormInput
              name={`detail_endDate_${index}`}
              value={row?.endDate}
              type={KTFormInputType.date}
              onChange={(value) => {
                acceptPartnerCompanyDetailInfos.current[index].endDate = value;
              }}
            />
          );
        },
      },
      {
        name: t('AcceptPerson'),
        sortable: false,
        minWidth: '180px',
        style: {
          borderRight: 0,
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.acceptBy}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between">
            <i
              className="far fa-trash-alt text-danger"
              onClick={() => {
                acceptPartnerCompanyDetailInfos.current.splice(index, 1);
                setFlag({});
              }}
            />
          </div>
        ),
      },
    ];
  }, [t, acceptPartnerCompanyDetailInfos.current]);

  const isHasMultiAddresses = useMemo(() => {
    const organizationAddresses = company?.OrganizationAddresses;
    return (
      organizationAddresses && _.isArray(organizationAddresses) && organizationAddresses.length > 0
    );
  }, [company]);

  useEffect(() => {
    if (!_.isNull(company)) {
      const organizationAddresses = company?.OrganizationAddresses ?? [];
      if (organizationAddresses?.length > 0) {
        companyInfos.current = organizationAddresses?.map((item) => {
          return {
            name: item?.organizationName,
            address: item?.businessPermitAddress,
            startDate: item?.businessPermitDateStart,
            endDate: item?.businessPermitDateEnd,
            acceptBy: item?.acceptBy,
          };
        });
      }
      const acceptPartnerCompanyDetails = company?.acceptPartnerCompanyDetails ?? [];
      if (acceptPartnerCompanyDetails?.length > 0) {
        acceptPartnerCompanyDetailInfos.current = acceptPartnerCompanyDetails?.map((item) => {
          return {
            name: item?.acceptName ?? '',
            address: item?.acceptAddress ?? '',
            startDate: item?.from ? moment(item?.from).format('YYYY-MM-DD') : '',
            endDate: item?.to ? moment(item?.to).format('YYYY-MM-DD') : '',
            acceptBy: item?.Account?.fullname ?? item?.Account?.email ?? '',
          };
        });
      }
    }
  }, [company]);

  // ---- methods -----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  function handleAddRow() {
    const tmpCompanyInfos = [...companyInfos.current];
    tmpCompanyInfos.push({ name: '', address: '', startDate: '', endDate: '' });
    companyInfos.current = tmpCompanyInfos;
    setFlag({});
  }

  async function requestCompanyInfoByTaxCode(taxCode) {
    if (taxCode.match(AppConfigs.regexTaxCode10) || taxCode.match(AppConfigs.regexTaxCode13)) {
      try {
        const res = await miscApi.getCompanyInfoByTaxCode(taxCode);
        const { result, company } = res;
        if (result === 'success' && company) {
          const { companyName, address, businessPermitDate } = company;
          companyInfos.current[0] = {
            name: companyName,
            address: address,
            startDate: businessPermitDate ? moment(businessPermitDate).format('YYYY-MM-DD') : '',
            endDate: '',
          };
          setFlag({});
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    if (isEditMode) {
      if (!_.isNull(company) && !isHasMultiAddresses) {
        const taxCode = company?.taxCode;
        if (taxCode) {
          requestCompanyInfoByTaxCode(taxCode); // Global.gTempCompanyTaxCode
        }
      }
    } else {
      requestCompanyInfoByTaxCode(Global.gTempCompanyTaxCode);
    }
  }, [company]);

  return (
    <div className="">
      <Formik
        initialValues={{
          emailReceiveInvoice: company
            ? company?.taxCode?.replace(appConfig.mailServerDomain, '')
            : Global.gTempCompanyInvoiceMailbox,
          taxCode: company?.taxCode ?? Global.gTempCompanyTaxCode,

          representativeName:
            company?.representativeName ?? Global.gTempOrganizationContactInfo?.name ?? '',
          representativeJobTitle:
            company?.representativeJobTitle ?? Global.gTempOrganizationContactInfo?.jobTitle ?? '',
          representativeEmail:
            company?.representativeEmail ?? Global.gTempOrganizationContactInfo?.email ?? '',
          representativePhone:
            company?.representativePhone ?? Global.gTempOrganizationContactInfo?.phone ?? '',
        }}
        validationSchema={Yup.object({
          emailReceiveInvoice: Yup.string()
            .required(t('Required'))
            .test('len', t('EmailInvalid'), (val) => {
              if (val) {
                return (
                  !val.toString().includes(' ') &&
                  val.toString().match(AppConfigs.regexInvoiceMailbox)
                );
              }
            }),
          taxCode: Yup.string()
            .required(t('TaxCodeRequired'))
            .trim()
            .test('len', t('TaxCodeInvalid', { field: t('taxCode') }), (val) => {
              if (val) {
                return (
                  !val.toString().includes(' ') &&
                  (val.toString().match(AppConfigs.regexTaxCode10) ||
                    val.toString().match(AppConfigs.regexTaxCode13))
                );
              }
            }),

          representativeEmail: Yup.string().nullable().email(t('EmailInvalid')),
          representativePhone: Yup.string()
            .nullable()
            .test('len', t('PhoneInvalid', { field: t('phone') }), (val) => {
              if (val) {
                return !val.toString().includes(' ') && val.toString().match(AppConfigs.regexPhone);
              }
              return true;
            }),
        })}
        enableReinitialize
        onSubmit={(values) => {
          if (
            _.some(companyInfos.current, (item) => {
              return (
                _.isEmpty(item.name) ||
                _.isEmpty(item.address) ||
                _.isEmpty(item.startDate) ||
                _.isEmpty(item.endDate)
              );
            })
          ) {
            ToastHelper.showError(t('NotEnoughParams'));
            return;
          }
          if (
            _.some(companyInfos.current, (item) => {
              return moment(item.startDate).isAfter(moment(item.endDate));
            })
          ) {
            ToastHelper.showError(t('Ngày kết thúc không được nhỏ hơn ngày bắt đầu'));
            return;
          }

          // thong tin chap nhan bo sung
          if (
            _.some(acceptPartnerCompanyDetailInfos.current, (item) => {
              return (
                (_.isEmpty(item.name) && _.isEmpty(item.address)) ||
                _.isEmpty(item.startDate) ||
                _.isEmpty(item.endDate)
              );
            })
          ) {
            ToastHelper.showError(t('NotEnoughParams'));
            return;
          }
          if (
            _.some(acceptPartnerCompanyDetailInfos.current, (item) => {
              return moment(item.startDate).isAfter(moment(item.endDate));
            })
          ) {
            ToastHelper.showError(t('Ngày kết thúc không được nhỏ hơn ngày bắt đầu'));
            return;
          }

          const addresses = companyInfos.current?.map((item) => {
            return {
              organizationName: item.name,
              businessPermitDateStart: item.startDate,
              businessPermitDateEnd: item.endDate,
              businessPermitAddress: item.address,
            };
          });
          const acceptedDetails = acceptPartnerCompanyDetailInfos.current?.map((item) => {
            return {
              acceptName: item.name,
              acceptAddress: item.address,
              from: item.startDate,
              to: item.endDate,
            };
          });
          const params = {
            taxCode: values.taxCode,
            organizationType: 'HQ',
            invoiceMailbox: values.emailReceiveInvoice + appConfig.mailServerDomain,
            OrganizationAddresses: addresses,
            acceptPartnerCompanyDetails: acceptedDetails,

            representativeName: values.representativeName,
            representativeJobTitle: values.representativeJobTitle,
            representativePhone: values.representativePhone,
            representativeEmail: values.representativeEmail,
          };
          // console.log({ params });
          // return;

          let res = null;
          if (!isEditMode) {
            res = OrganizationHelper.callApiCreateOrganization(params, true, true);
          } else {
            res = OrganizationHelper.callApiUpdateOrganization(
              company?.organizationId,
              params,
              true,
              true
            );
          }
          if (res) {
            Global.gTempStateCreateCompanyMultiAddresses = true;
            handlePressCancel();
          }
        }}
      >
        {(formikProps) => (
          <div className="">
            <div className="row m-0">
              {/* email nhan hoa don */}
              <div className="col-12">
                <KTFormGroup
                  additionalClassName="mb-4"
                  label={
                    <>
                      {t('CreateEmailToReceiveInvoice')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="emailReceiveInvoice"
                  inputElement={
                    <FastField name="emailReceiveInvoice">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                            Global.gTempCompanyInvoiceMailbox = value;
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableInputGroup
                          appendElements={[appConfig.mailServerDomain]}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('EmailReceiveInvoice'))}`}
                          text={t('InvoiceMailboxHint')}
                          disabled={isEditMode}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {_.isEmpty(formikProps.errors.emailReceiveInvoice) && (
                <div className="col-12">
                  <KTFormGroup
                    additionalClassName="mb-4"
                    inputElement={
                      <div
                        className="border-warning bg-light-warning p-4 rounded"
                        style={{
                          borderWidth: '1px',
                          borderStyle: 'solid',
                        }}
                      >
                        Email nhận hóa đơn của bạn là:{' '}
                        <span className="text-primary">{`${formikProps.values.emailReceiveInvoice}${appConfig.mailServerDomain}`}</span>{' '}
                        gửi hoặc chuyển tiếp (forward) email hóa đơn điện tử đến email này, hệ thống
                        sẽ tự động xử lý hóa đơn.
                      </div>
                    }
                  />
                </div>
              )}

              {/* ma so thue */}
              <div className="col-12">
                <KTFormGroup
                  additionalClassName="mb-4"
                  label={
                    <>
                      {t('TaxCode')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="taxCode"
                  inputElement={
                    <FastField name="taxCode">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                            Global.gTempCompanyTaxCode = value;
                          }}
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('TaxCode'))}`}
                          enableInputIcon={true}
                          inputIconElement={
                            <i
                              className="fa-regular fa-search cursor-pointer"
                              onClick={() => {
                                if (!isEditMode) {
                                  requestCompanyInfoByTaxCode(field.value);
                                }
                              }}
                            />
                          }
                          inputIconPosition={KTFormInputIconPosition.right}
                          disabled={isEditMode}
                        />
                      )}
                    </FastField>
                  }
                />
              </div>

              {/* thong tin lien he */}
              <div className="row">
                {/* ten nguoi lien he */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactName')}</>}
                    inputName="representativeName"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativeName">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.name = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('ContactName'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* chuc vu */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactPosition')}</>}
                    inputName="representativeJobTitle"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativeJobTitle">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.jobTitle = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('ContactPosition'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* email */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactEmail')}</>}
                    inputName="representativeEmail"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativeEmail">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.email = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.email}
                            placeholder={`${_.capitalize(t('ContactEmail'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>

                {/* so dien thoai */}
                <div className="col-lg-6">
                  <KTFormGroup
                    label={<>{t('ContactPhone')}</>}
                    inputName="representativePhone"
                    additionalClassName="mb-4"
                    inputElement={
                      <FastField name="representativePhone">
                        {({ field, form, meta }) => (
                          <KTFormInput
                            name={field.name}
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              Global.gTempOrganizationContactInfo.phone = value;
                            }}
                            onFocus={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            enableCheckValid
                            isValid={_.isEmpty(meta.error)}
                            isTouched={meta.touched}
                            feedbackText={meta.error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('ContactPhone'))}...`}
                          />
                        )}
                      </FastField>
                    }
                  />
                </div>
              </div>

              {/* chap nhan nhieu ten, dia chi don vi hop le */}
              <div className="col-12">
                <div className="checkbox-list">
                  <KTCheckbox
                    name="checkboxAcceptMultiNameCompany"
                    checked={true}
                    label={
                      <div className="d-flex align-items-center">
                        Chấp nhận nhiều tên, địa chỉ đơn vị hợp lệ{' '}
                        <KTTooltip text="Kê khai thông tin tên/địa chỉ dựa theo các lần thay đổi giấy phép ĐKKD của đơn vị">
                          <i className="fa-regular fa-circle-question text-primary ml-2" />
                        </KTTooltip>
                      </div>
                    }
                    type={KTCheckboxType.outline}
                    onChange={(newValue) => {
                      if (onCheckedAcceptMultiNameChange) {
                        onCheckedAcceptMultiNameChange(newValue);
                      }
                    }}
                    disabled={isHasMultiAddresses}
                  />
                </div>
              </div>

              {/* bang danh sach ten, dia chi don vi hop le */}
              {isEditMode && (
                <div className="mt-4">
                  <VIAppTabs
                    tabs={tabList}
                    activeTab={activeTab}
                    handleClick={(tab) => {
                      setActiveTab(tab);
                    }}
                  />
                </div>
              )}

              <div>
                <div
                  className="overflow-auto"
                  style={{
                    maxHeight: '40vh',
                  }}
                >
                  <VIDataTable
                    draggable={false}
                    tableStyles={customDataTableActionStyle}
                    additionalClassName="p-0 shadow-none"
                    additionalCardBodyClassName="p-0"
                    selectable={false}
                    pagination={null}
                    onChangePage={(page) => {}}
                    onChangeRowsPerPage={(perPage) => {}}
                    fullColumns={
                      activeTab === tabList[1] ? columns : columnsAcceptPartnerDetailInfo
                    }
                    data={
                      activeTab === tabList[1]
                        ? companyInfos.current
                        : acceptPartnerCompanyDetailInfos.current
                    }
                    loading={false}
                    onRowClick={(row) => {}}
                    onSetSelectedMultiItems={(rows) => {}}
                    haveCardHeader={false}
                    emptyComponent={
                      <div>
                        <EmptyView message={t('NoDataToDisplay')} />
                      </div>
                    }
                  />
                </div>

                {activeTab === tabList[1] && (
                  <div>
                    <VIButton
                      text={t('AddRow')}
                      beforeIcon={<i className="fa-regular fa-plus" />}
                      style={{ padding: '8px' }}
                      onClick={handleAddRow}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* actions */}
            <div className="d-flex align-items-center justify-content-end gap-3">
              <VIButton
                text={t('Cancel')}
                className="btn-grey"
                style={{ padding: '8px' }}
                onClick={handlePressCancel}
              />
              <VIButton
                text={t('Save')}
                className=""
                style={{ padding: '8px' }}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ModalAddMultiCompanyContent;
