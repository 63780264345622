// All app configs: app name, email, phone, copyright...
const AppConfigs = {
  // enable show log
  enableLog: true,

  acceptImages: ['image/png, image/jpeg'],
  regexPhone: /^[0-9]{10,11}$/,
  regexCCCD9: /^[0-9]{9}$/,
  regexCCCD12: /^[0-9]{12}$/,
  regexEmail: /^\S+@\S+\.\S+$/,
  regexTaxCode10: /^[0-9]{10}$/,
  regexTaxCode13: /^[0-9]{10}-[0-9]{3}$/,
  regexInvoiceMailbox: /^[A-Za-z0-9_.]+$/,

  isProductionMode: process.env.REACT_APP_DEV_TOOLS_ENABLE != 1,
  isHideFooter: true, //process.env.REACT_APP_DEV_TOOLS_ENABLE != 1,

  zaloPhoneNumber: '0988.399.516',
  callCenterNumber: '1900.0099',
  emailSupport: 'cskh@icorp.com.vn',
  headquarters: 'Số 32/21 Phố Trương Công Giai, Phường Dịch Vọng, Quận Cầu Giấy, Thành phố Hà Nội',
  numberDKKD: '0106870211',
  licenseDate: '04/06/2015',
  licensingAgency: 'Sở Kế hoạch và Đầu tư thành phố Hà Nội',
  phoneNumber: '0988.399.516',
  icorpHomepage: 'https://icorp.vn/',
  icorpTaxCode: '0106870211',

  emailSuffix: '@icorp.vn',

  packageName: 'com.icorp.hddv',
  appName: 'Hoá đơn đầu vào',
  version: '1.0',
  build: 1,

  axionConnectUrl: process.env.REACT_APP_AXION_CONNECT_URL ?? 'https://hoadondientu.gdt.gov.vn',
  getIpUrl: process.env.REACT_APP_GET_IP_URL ?? 'https://geolocation-db.com/json/',
  apiBaseUrl: process.env.REACT_APP_API_URL,
  vietInvoiceV2Url: process.env.REACT_APP_VIETINVOICE_V2_URL ?? 'https://v2.vietinvoice.vn/',

  termsAndConditionsUrl: '',
  privacyPolicyUrl: '',

  topFAQLandingPageNumber: 10,

  wsUrl: process.env.REACT_APP_WS_URL ?? '',
  recaptchaSiteKeyV2:
    process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? '6LcNtdInAAAAACGdhzo-zBzebhcTb6ppYmKZIgLI',
};

export default AppConfigs;
