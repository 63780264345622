import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import EmptyView from 'features/Others/components/EmptyView';
import {
  addPackageToOrder,
  changeOrderPackageQuantity,
  copyFromOrderToCart,
  removePackage,
} from 'features/Payment/paymentSlice';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import AppResource from 'general/constants/AppResource';
import ToastHelper from 'general/helpers/ToastHelper';
import Utils from 'general/utils/Utils';
import usePayment from 'hooks/usePayment';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ServicePackCompactCard from '../ServicePackCompactCard';
import _ from 'lodash';
import Global from 'general/utils/Global';

ViewOrder.propTypes = {
  onPressCancel: PropTypes.func,
};

ViewOrder.defaultProps = {
  onPressCancel: null,
};

/**
 *
 * @param {{
 * onPressCancel: function,
 * }} props
 * @returns
 */
function ViewOrder(props) {
  // ---- params ----
  const { onPressCancel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    canOrder,
    orderList,
    orderQuantityValid,
    servicePackList,
    orderDurationExpired,
    orderQuantityBuyMore,
  } = usePayment();
  const columns = useMemo(() => {
    return [
      {
        name: t('PackageOrService'),
        sortable: false,
        minWidth: '220px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.package?.packageCode}
            </p>
          );
        },
      },
      {
        name: t('Unit'),
        sortable: false,
        width: '120px',
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.package?.unit}
            </p>
          );
        },
      },
      {
        name: t('Quantity'),
        sortable: false,
        width: '120px',
        cell: (row, index) => {
          return (
            <KTFormInput
              name={`quantity_${index}`}
              value={row?.quantity}
              type={KTFormInputType.number}
              onChange={(value) => {
                const iValue = parseInt(value);
                if (iValue) {
                  dispatch(
                    changeOrderPackageQuantity({
                      packageId: row?.package?.packageId,
                      newQuantity: iValue,
                    })
                  );
                }
              }}
            />
          );
        },
      },
      {
        name: t('Price'),
        sortable: false,
        width: '120px',
        right: true,
        cell: (row, index) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {Utils.formatCurrency(row?.package?.packageCost)}
            </p>
          );
        },
      },
      {
        name: t('LastPrice'),
        sortable: false,
        width: '120px',
        right: true,
        style: {
          borderRight: 0,
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {Utils.formatCurrency(row?.package?.packageCost * row?.quantity)}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between">
            <i
              className="far fa-trash-alt text-danger"
              onClick={() => {
                dispatch(removePackage(row?.package?.packageId));
              }}
            />
          </div>
        ),
      },
    ];
  }, [t, dispatch]);
  const router = useRouter();
  const [accountService, setService] = useState(Global.gTempChoosingServiceType);
  const filteredServicePackList = useMemo(() => {
    if (_.isNull(accountService)) {
      return servicePackList;
    } else {
      return _.filter(servicePackList, (item) => item.service === accountService);
    }
  }, [servicePackList, accountService]);

  // ---- methods -----
  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  function handleAddToCart() {
    if (!orderQuantityValid) {
      ToastHelper.showError(t('QuantityInvalid'));
    } else {
      dispatch(copyFromOrderToCart());
      ToastHelper.showSuccess(t('AddToCartSuccess'));
      handlePressCancel();
    }
  }

  function handlePayment() {
    if (!orderQuantityValid) {
      ToastHelper.showError(t('QuantityInvalid'));
    } else {
      dispatch(copyFromOrderToCart());
      router.navigate('/store/shopping-cart/confirm-payment');
      handlePressCancel();
    }
  }

  return (
    <div
      className="overflow-auto"
      style={{
        maxHeight: '70vh',
      }}
    >
      <div>
        {/* thong tin thue bao dang su dung */}
        <div>
          <div
            className="bg-light p-4 rounded d-flex flex-wrap justify-content-between gap-4"
            style={{}}
          >
            <div className="font-weight-bolder text-grey">Thông tin thuê bao đang sử dụng</div>
            <div className="d-flex flex-wrap gap-4">
              <div>
                Ngày hết hạn: <span className="font-weight-bolder">02/06/2026</span>
              </div>
              <div>
                (Thời gian sử dụng còn lại: <span className="font-weight-bolder">2.82 năm</span>)
              </div>
            </div>
          </div>
        </div>

        {/* tinh gia chi tiet */}
        <div className="gutter-t">
          <div className="font-weight-bolder text-grey">Tính giá chi tiết</div>
          <div
          // className="overflow-auto"
          // style={{
          //   maxHeight: '40vh',
          // }}
          >
            <VIDataTable
              draggable={false}
              tableStyles={customDataTableActionStyle}
              additionalClassName="p-0 shadow-none"
              additionalCardBodyClassName="p-0"
              selectable={false}
              pagination={null}
              onChangePage={(page) => {}}
              onChangeRowsPerPage={(perPage) => {}}
              fullColumns={columns}
              data={orderList}
              loading={false}
              onRowClick={(row) => {}}
              onSetSelectedMultiItems={(rows) => {}}
              haveCardHeader={false}
              emptyComponent={
                <EmptyView
                  message={t('NoChoosePackageYet')}
                  image={AppResource.images.imgEmptyPayment}
                />
              }
            />
          </div>
        </div>

        {/* thong tin tai nguyen */}
        <div className="">
          <div className="font-weight-bolder text-grey">Thông tin tài nguyên</div>
          <div className="d-flex flex-wrap gap-4 mt-2">
            <div>
              Ngày hết hạn: <span className="font-weight-bolder">{orderDurationExpired}</span>
            </div>
            <div>
              Số hoá đơn mua thêm:{' '}
              <span className="font-weight-bolder">{Utils.formatNumber(orderQuantityBuyMore)}</span>
            </div>
          </div>

          {/* actions */}
          {canOrder && (
            <div className="d-flex justify-content-end gap-3">
              <VIButton
                text={t('AddToCart')}
                className="btn-grey"
                style={{ padding: '8px' }}
                beforeIcon={<i className="fa-regular fa-cart-plus" />}
                onClick={handleAddToCart}
              />
              <VIButton
                text={t('Payment')}
                className="btn-blue"
                style={{ padding: '8px' }}
                beforeIcon={<i className="fa-regular fa-dollar" />}
                onClick={handlePayment}
              />
            </div>
          )}
        </div>

        <div className="separator separator-dashed my-6"></div>

        {/* mua them goi san pham */}
        <div>
          <div className="font-weight-bolder text-grey">Mua thêm gói sản phẩm</div>
          <div className="row m-0 mt-2">
            {filteredServicePackList?.map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6" key={index}>
                  <ServicePackCompactCard
                    item={item}
                    onPressAddToCart={() => {
                      dispatch(addPackageToOrder(item));
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewOrder;
