import KTLayoutContent from 'assets/plugins/ktcontent';
import { useEffect } from 'react';

function KT01Content(props) {
  useEffect(() => {
    KTLayoutContent.init('kt_content');
  }, []);

  return (
    <div id="kt_content" className="content d-flex flex-column flex-column-fluid py-6">
      {props.children}
    </div>
  );
}

export default KT01Content;
