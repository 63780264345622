import { Route, Routes } from 'react-router-dom';
import MailHomeScreen from './screens/MailHomeScreen';

function Mail(props) {
  return (
    <Routes>
      <Route path="*" element={<MailHomeScreen />} />
    </Routes>
  );
}

export default Mail;
