import axiosClient from 'api/axiosClient';
import { saveAs } from 'file-saver';
import Utils from 'general/utils/Utils';
import JSZip from 'jszip';
import moment from 'moment';
import { utils, writeFileXLSX } from 'xlsx';
import ToastHelper from './ToastHelper';
import store from 'app/store';
const InvoiceHelper = {
  PrintInvoice: (invoice) => {
    console.log(invoice);
    for (var i = 0; i < invoice.length; i++) {
      axiosClient
        .get(process.env.REACT_APP_BASE_URL + invoice[i]?.pdfFile, {
          httpHeaders: {
            'session-token': axiosClient.defaults.headers.common['session-token'],
          },
          responseType: 'blob',
        })
        .then((response) => {
          var pdf = URL.createObjectURL(response);
          var iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = pdf;
          document.body.append(iframe);
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
        })
        .catch((error) => ToastHelper.showError('Không có file tải hợp lệ !'));
    }
  },
  DownLoadInvoices: async (names, invoice) => {
    if (invoice) {
      const zip = new JSZip();
      const listFilePdf = zip.folder(`Bản thế hiện`);
      const listFileOrigin = zip.folder(`Hóa đơn gốc`);

      let arrFile = [];
      let arrFileOrigin = [];
      for (let i = 0; i < invoice?.length; i++) {
        await axiosClient
          .get(Utils.getFullUrl(invoice?.[i]?.pdfFile), {
            httpHeaders: {
              'session-token': axiosClient.defaults.headers.common['session-token'],
            },
            responseType: 'blob',
          })
          .then((response) => {
            arrFile.push({
              name: `${
                store.getState()?.auth?.account?.Organization?.nameFileBuyerTaxCode
                  ? invoice[i]?.sellerTaxCode + '_'
                  : ''
              }${
                store.getState()?.auth?.account?.Organization?.nameFileSample
                  ? invoice[i]?.invoiceTypeCode + '_'
                  : ''
              }${
                store.getState()?.auth?.account?.Organization?.nameFileSerial
                  ? invoice[i]?.serial + '_'
                  : ''
              }${invoice[i]?.invoiceNumber + '_'}${
                store.getState()?.auth?.account?.Organization?.nameFileInvoiceDate
                  ? invoice[i]?.invoiceDate + '_'
                  : ''
              }${
                store.getState()?.auth?.account?.Organization?.nameFileSupplierName
                  ? invoice[i]?.sellerName + '_'
                  : ''
              }.${invoice[i]?.pdfFile?.split('.').pop()}`,

              file: response,
            });
          })
          .catch((error) => {});
      }
      // hóa đơn gốc
      for (let i = 0; i < invoice?.length; i++) {
        await axiosClient
          .get(Utils.getFullUrl(invoice?.[i]?.xmlFileOrigin), {
            httpHeaders: {
              'session-token': axiosClient.defaults.headers.common['session-token'],
            },
            responseType: 'blob',
          })
          .then((response) => {
            arrFileOrigin.push({
              name: `${
                store.getState()?.auth?.account?.Organization?.nameFileBuyerTaxCode
                  ? invoice[i]?.sellerTaxCode + '_'
                  : ''
              }${
                store.getState()?.auth?.account?.Organization?.nameFileSample
                  ? invoice[i]?.invoiceTypeCode + '_'
                  : ''
              }${
                store.getState()?.auth?.account?.Organization?.nameFileSerial
                  ? invoice[i]?.serial + '_'
                  : ''
              }${invoice[i]?.invoiceNumber + '_'}${
                store.getState()?.auth?.account?.Organization?.nameFileInvoiceDate
                  ? invoice[i]?.invoiceDate + '_'
                  : ''
              }${
                store.getState()?.auth?.account?.Organization?.nameFileSupplierName
                  ? invoice[i]?.sellerName + '_'
                  : ''
              }.${invoice[i]?.xmlFileOrigin?.split('.').pop()}`,

              file: response,
            });
          })
          .catch((error) => {});
      }
      //tải folder hóa đơn gốc
      if (arrFileOrigin?.length > 0) {
        arrFileOrigin?.forEach((element) => {
          listFileOrigin.file(element?.name, element?.file);
        });
        arrFile?.forEach((element) => {
          listFilePdf.file(element?.name, element?.file);
        });
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, `${names}.zip`);
        });
      }
      if (arrFile?.length > 0 && arrFileOrigin?.length === 0) {
        arrFile?.forEach((element) => {
          listFilePdf.file(element?.name, element?.file);
        });
        listFilePdf.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, `${names}.zip`);
        });
      }
      if (arrFile?.length === 0 && arrFileOrigin?.length === 0) {
        ToastHelper.showError('Không có file tải hợp lệ !');
      }
      // setLoadingDown(false);
    }
  },
  ExportInvoices: (dataInvoice, sheetName, fileName) => {
    const ws = utils.json_to_sheet(dataInvoice);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);
    writeFileXLSX(wb, `${fileName}.xlsx`);
  },

  // ---- quybka ----
  getExportInvoiceFileStatus: (row) => {
    const arrRet = [];
    arrRet.push(row?.xmlFile ? 'Có XML' : 'Không có XML');
    arrRet.push(row?.previewPdfFile ? 'Có bản thể hiện' : 'Không có bản thể hiện');
    return arrRet.join(', ');
  },

  getExportInvoicePaymentInfo: (row) => {
    const arrRet = [];
    const invoiceHistories = row?.InvoiceHistories;
    if (invoiceHistories) {
      const histories = invoiceHistories?.map((item) => {
        return {
          '- Ngày thanh toán': item?.paymentDate
            ? moment(item?.paymentDate).format('YYYY-MM-DD')
            : '',
          '- Người thanh toán': item?.paymentPersonName ?? '',
          '- Số tiền thanh toán': item?.paymentMoney ?? '',
        };
      });

      histories?.forEach((item) => {
        const arrStr = [];
        const keys = Object.keys(item);
        keys.forEach((key) => {
          const value = item[key];
          arrStr.push(`${key}: ${value}`);
        });
        arrRet.push(arrStr.join('\n'));
      });
    }

    return arrRet.join('\n');
  },

  /**
   *
   * @param {boolean} nameFileBuyerTaxCode
   * @param {boolean} nameFileSerial
   * @param {boolean} nameFileInvoiceDate
   * @param {boolean} nameFileSample
   * @param {boolean} nameFileInvoiceNum
   * @param {boolean} nameFileSupplierName
   */
  genSampleFileNameDownload(
    nameFileBuyerTaxCode,
    nameFileSerial,
    nameFileInvoiceDate,
    nameFileSample,
    nameFileInvoiceNum,
    nameFileSupplierName
  ) {
    const arrItems = [];
    if (nameFileBuyerTaxCode) {
      arrItems.push('0101243150');
    }
    if (nameFileSample) {
      arrItems.push('PT20E');
    }
    if (nameFileSerial) {
      arrItems.push('01GTKT003');
    }
    if (nameFileInvoiceNum) {
      arrItems.push('0000034');
    }
    if (nameFileInvoiceDate) {
      arrItems.push('26092022');
    }
    if (nameFileSupplierName) {
      arrItems.push('congtycophanicorp');
    }
    return arrItems.join('_');
  },
};
export default InvoiceHelper;
