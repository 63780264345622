import axiosClient from './axiosClient';

const invoiceApi = {
  // lấy danh sách hóa đơn đầu vào
  getAllInInvoice: (params) => {
    const url = '/invoice/find';
    return axiosClient.get(url, { params });
  },
  // Đánh dấu thanh toán
  updateInvoicePayment: (id, params) => {
    const url = `/invoice/payment/${id}`;
    return axiosClient.put(url, params);
  },
  // Thêm nhãn vào hóa đơn
  addTagsInvoice: (params) => {
    const url = `/invoice/tag`;
    return axiosClient.put(url, params);
  },
  // Thêm hóa đơn vào thùng rác/khôi phục
  addInvoiceToTrash: (params) => {
    const url = `/invoice/isDeleted`;
    return axiosClient.put(url, params);
  },
  // Hạch toán hóa đơn
  accountingInvoice: (params) => {
    const url = `/invoice/accounting`;
    return axiosClient.put(url, params);
  },
  // Điều chuyển hóa đơn
  transferInvoice: (params) => {
    const url = `/invoice/move`;
    return axiosClient.put(url, params);
  },
  // lấy chi tiết hóa đơn
  getDetailInvoice: (id) => {
    const url = `/invoice/detail/${id}`;
    return axiosClient.get(url);
  },
  // up file
  upFileInvoice: (id, params) => {
    const url = `/invoice/upload/${id}`;
    return axiosClient.put(url, params);
  },
  // gửi phản hồi tói NCC
  sendToEmailNCC: (params) => {
    const url = `/invoice/email`;
    return axiosClient.post(url, params);
  },
  // Ghi chú hóa đơn
  noteInvoice: (params) => {
    const url = `/invoice/note`;
    return axiosClient.put(url, params);
  },
  // Kiểm tra hóa đơn
  validateInvoice: (params) => {
    const url = `/invoice/validate`;
    return axiosClient.post(url, params);
  },
  // delete file
  deleteFileInvoice: (params) => {
    const url = `/invoice-attach/delete`;
    return axiosClient.delete(url, { data: params });
  },
  // Bỏ thanh toán
  returnPaymentInvoice: (id) => {
    const url = `/invoice/return-payment/${id}`;
    return axiosClient.put(url);
  },
  // Bỏ hạch toán
  returnAccountingInvoice: (params) => {
    const url = `/invoice/return-accountings`;
    return axiosClient.put(url, params);
  },
  // Chấp nhận lỗi
  acceptInvoice: (id, params) => {
    const url = `/invoice/accept/${id}`;
    return axiosClient.put(url, params);
  },
  // delete file
  deleteFilePdfInvoice: (id) => {
    const url = `/invoice/delete-pdf/${id}`;
    return axiosClient.delete(url);
  },

  // export danh sách hàng hóa
  exportInvoice: (params) => {
    const url = `/invoice/export`;
    return axiosClient.post(url, params, { timeout: 864000 * 1000 });
  },
  // im kết quả kiểm tra
  printValidateInvoice: (id) => {
    const url = `/invoice/print-result/${id}`;
    return axiosClient.get(url);
  },
  // Chấp nhận địa chỉ/ tên/mã số thế
  acceptPartnerCompany: (id, params) => {
    const url = `/accept-partner-company-detail/create/${id}`;
    return axiosClient.post(url, params);
  },
  // lấy danh sách hàng hóa dịch vụ
  getListGoodAndService: (params) => {
    const url = `/partner-company/businesses`;
    return axiosClient.get(url, { params });
  },
  // chấp nhận mặt hàng giảm thuế
  acceptTaxReduce: (params) => {
    const url = `/invoice/accept-tax-reduce`;
    return axiosClient.post(url, params);
  },
  // tạo mới hóa đơn từ xml
  createInvoiceXml: (params) => {
    const url = `/invoice/manual-create/file`;
    return axiosClient.post(url, params);
  },
  // tạo mới hóa đơn nhập tay
  createInvoiceManual: (params) => {
    const url = `/invoice/manual-create/input`;
    return axiosClient.post(url, params);
  },
  // cập nhật hóa đơn nhập tay
  updateInvoiceManual: (id, params) => {
    const url = `/invoice/update-manual-invoice-input/${id}`;
    return axiosClient.put(url, params);
  },
};

export default invoiceApi;
