import { Route, Routes } from 'react-router-dom';
import OrganizationHomeScreen from './screens/OrganizationHomeScreen';

Organization.propTypes = {};

function Organization(props) {
  return (
    <Routes>
      <Route path="" element={<OrganizationHomeScreen />} />
    </Routes>
  );
}

export default Organization;
