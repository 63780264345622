import customDataTableStyle from 'assets/styles/customDataTableStyle';
import { thunkTaxReducedItems } from 'features/Tools/toolSlice';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIElements from 'general/components/VietInvoice/VIElements';
import VIPagination from 'general/components/VietInvoice/VIPagination';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import Global from 'general/utils/Global';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import './style.scss';
import EmptyView from 'features/Others/components/EmptyView';
import VILoading from 'general/components/VietInvoice/VILoading';
import invoiceApi from 'api/invoiceApi';
import { thunkGetAllInInvoice } from 'features/Invoice/invoiceSlice';

UpDateStatusTax.propTypes = {
  currentSelected: PropTypes.object,
};

UpDateStatusTax.defaultProps = {
  currentSelected: null,
};

/**
 *
 * @param {{
 * currentSelected: object,
 *
 * }} props
 * @returns
 */
function UpDateStatusTax(props) {
  // ---- params ----
  const { currentSelected, setShowModalUpdateStatusTax, setLoading } = props;
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      {
        name: t('NameOfGoodsOrServices'),
        sortable: false,
        minWidth: '240px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.productName}
            </p>
          );
        },
      },
      {
        name: t('CodeOfGoodsOrServices'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.productCode}
            </p>
          );
        },
      },
      {
        name: t('Content'),
        sortable: false,
        minWidth: '280px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0 text-maxline-3">
              {row?.content}
            </p>
          );
        },
      },
      {
        name: t('CodeHSND44'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.hsCode}
            </p>
          );
        },
      },
      {
        name: t('AppendixND44'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return <p data-tag="allowRowEvents" className="text-dark-75 m-0"></p>;
        },
      },
      {
        name: t('Result'),
        sortable: false,
        minWidth: '180px',
        cell: (row, index) => {
          const reduceTax = row?.reduceTax ?? false;
          return (
            <div data-tag="allowRowEvents" className="">
              <VIElements
                id={`result_${index}`}
                text={reduceTax ? 'Có giảm thuế' : 'Không giảm thuế'}
                style={{
                  color: !reduceTax ? '#EE2334' : '#20A144',
                  backgroundColor: !reduceTax
                    ? 'rgba(255, 87, 87, 0.10)'
                    : 'rgba(40, 160, 67, 0.1)',
                  padding: '4px 8px',
                  border: 'none',
                }}
                icon={
                  <i
                    className={`fas ${!reduceTax ? 'fa-times-circle' : 'fa-check-circle'}`}
                    style={{ color: !reduceTax ? '#EE2334' : '#20A144' }}
                  ></i>
                }
              />
            </div>
          );
        },
      },
    ];
  }, [t]);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(Global.gFilterTaxReducedItems);
  const { taxReducedItems, isGettingTaxReducedItems, totalTaxReducedItems } = useSelector(
    (state) => state.tool
  );

  useEffect(() => {
    dispatch(thunkTaxReducedItems(filters));
  }, [filters]);
  useEffect(() => {
    setFilters({ ...filters, q: currentSelected?.productName });
  }, [currentSelected]);
  const handleAcceptReduce = async (bol) => {
    try {
      const res = await invoiceApi.acceptTaxReduce({
        isTaxReduce: bol,
        productName: currentSelected?.productName,
      });
      if (res?.result === 'success') {
        setShowModalUpdateStatusTax(false);
        setLoading(Math.random());
        setTimeout(() => {
          dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
        }, 2000);
      }
      console.log(res);
    } catch (error) {}
  };
  return (
    <div>
      <div
        className="d-flex p-4  flex-wrap"
        style={{ borderRadius: '8px', background: 'var(--system-grey, #F5F5F5)' }}
      >
        <div
          className="d-flex align-items-center me-3 "
          style={{ minWidth: 'max-content', color: '#1C2256', fontWeight: 400 }}
        >
          Hàng hóa, dịch vụ/ Mã số HS
        </div>
        <VISearchField
          placeholder="Nhập tên hàng hóa, dịch vụ/ Mã số HS"
          value={filters?.q}
          className="w-auto d-flex align-items-center flex-grow-1"
          typingTimeout={500}
          iconPosition="right"
          onSubmit={(newValue) => {
            setFilters({ ...filters, q: newValue, page: 0, limit: 30 });
          }}
        />
      </div>
      <div
        className="d-flex align-items-center p-4 my-4 flex-wrap"
        style={{ borderRadius: '8px', background: 'var(--system-grey, #F5F5F5)' }}
      >
        <div className="pe-3" style={{ borderRight: '1px solid #D9D9D9', maxWidth: '730px' }}>
          <div className="d-flex align-items-center  flex-wrap">
            <div
              className="d-flex align-items-center me-3"
              style={{ minWidth: 'max-content', color: '#3C4376', fontWeight: 600 }}
            >
              GỢI Ý CỦA CHƯƠNG TRÌNH
            </div>
            {currentSelected?.checkResult === 'NOT_CHECKED' ? (
              <VIElements
                text="Có thể giảm được thuế GTGT"
                style={{
                  color: '#20A144',
                  background: 'rgba(32, 161, 68, 0.10)',
                  padding: '5px',
                  border: 'none',
                  fontWeight: '400',
                }}
              />
            ) : (
              <VIElements
                text="Có thể không giảm được thuế GTGT"
                style={{
                  color: '#FF7F2C',
                  background: '#ffeadc',
                  padding: '5px',
                  border: 'none',
                  fontWeight: '400',
                }}
              />
            )}
          </div>
          <div className="mt-2" style={{ color: '#3C4376', fontWeight: 400 }}>
            Do không tìm thấy mặt hàng này trong danh mục hàng hóa, dịch vụ không được giảm thuế
            GTGT theo phụ lục I, II, III của Nghị định 44/2023/NĐ-CP. Vui lòng kiểm tra lại và xác
            nhận thông tin giảm thuế của mặt hàng.
          </div>
        </div>
        <div className="ps-3">
          <div>Cập nhật trạng thái</div>
          <div className="d-flex align-items-center mt-2  flex-wrap">
            <VIButton
              className="btn-green me-3"
              text="Có giảm thuế"
              onClick={() => handleAcceptReduce(true)}
            />
            <VIButton
              text="Không giảm thuế"
              className="btn-white"
              onClick={() => handleAcceptReduce(false)}
            />
          </div>
        </div>
      </div>
      <div className="row px-4 mx-0" style={{ border: '1px solid #D9D9D9' }}>
        <div
          className="col-4 py-4"
          style={{ borderRight: '1px solid #D9D9D9', color: '#7177A8', fontWeight: 600 }}
        >
          MÃ HHDV
          <div className="Name-Check" style={{ color: '#3C4376', fontWeight: 600 }}>
            {currentSelected?.productCode}
          </div>
        </div>
        <div className="col-5 py-4" style={{ borderRight: '1px solid #D9D9D9' }}>
          <div style={{ color: '#7177A8', fontWeight: 600 }}>Tên HDDV</div>
          <div className="Name-Check" style={{ color: '#3C4376', fontWeight: 600 }}>
            {currentSelected?.productName}
          </div>
        </div>
        <div className="col-3 py-4">
          <div style={{ color: '#7177A8', fontWeight: 600 }} className="mb-2">
            TT GIẢM THUẾ
          </div>
          {currentSelected?.isReduceTax ? (
            <VIElements
              text="Có giảm thuế"
              icon={''}
              style={{
                color: '#20A144',
                background: 'rgba(40, 160, 67, 0.10)',
                padding: '5px',
                border: 'none',
                fontWeight: '400',
                margin: '0px',
              }}
            />
          ) : (
            <VIElements
              text="Không giảm thuế"
              icon={''}
              style={{
                color: '#EE2334',
                background: 'rgba(255, 87, 87, 0.10)',
                padding: '5px',
                border: 'none',
                fontWeight: '400',
                margin: '0px',
              }}
            />
          )}
        </div>
      </div>
      <div className="">
        <DataTable
          className="h-100"
          fixedHeader
          fixedHeaderScrollHeight={'34vh'}
          columns={columns}
          data={taxReducedItems}
          responsive={true}
          progressPending={isGettingTaxReducedItems}
          progressComponent={<VILoading tableMaxHeight={'34vh'} />}
          noHeader
          striped
          pointerOnHover
          highlightOnHover
          selectableRowsHighlight
          customStyles={customDataTableStyle}
          noDataComponent={<EmptyView message={t('NoDataToDisplay')} tableMaxHeight={'34vh'} />}
        />
        <div className="d-flex align-items-center justify-content-center mt-3">
          <VIPagination
            rowsPerPage={filters.limit}
            rowCount={totalTaxReducedItems ?? 0}
            currentPage={filters.page + 1}
            displayRows={taxReducedItems?.length + filters.limit * filters.page}
            widthTotal="200px"
            onChangePage={(page) => {
              let iNewPage = parseInt(page);
              iNewPage -= 1;
              if (iNewPage < 0) {
                iNewPage = 0;
              }
              Global.gFilterTaxReducedItems = {
                ...Global.gFilterTaxReducedItems,
                page: iNewPage,
              };
              setFilters(Global.gFilterTaxReducedItems);
            }}
            onChangeRowsPerPage={(perPage) => {
              Global.gFilterTaxReducedItems = {
                ...Global.gFilterTaxReducedItems,
                limit: perPage,
                page: 0,
              };
              setFilters(Global.gFilterTaxReducedItems);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UpDateStatusTax;
