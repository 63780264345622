import DropdownHeaderAccountInfo from 'features/Others/components/DropdownHeaderAccountInfo';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTTooltip, { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import AccountHelper from 'general/helpers/AccountHelper';
import useAccount from 'hooks/useAccount';
import usePayment from 'hooks/usePayment';
import useRouter from 'hooks/useRouter';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

HeaderStore.propTypes = {
  additionalClassName: PropTypes.string,
};

HeaderStore.defaultProps = {
  additionalClassName: '',
};

function HeaderStore(props) {
  // ---- params -----
  const { t } = useTranslation();
  const { additionalClassName } = props;
  const navigate = useNavigate();
  const isAuth = AccountHelper.checkAccessTokenValid();
  const account = useAccount();
  const { shoppingCartCount } = usePayment();
  const router = useRouter();
  const isShoppingCartScreen = router.pathname.includes('/store/shopping-cart');

  return (
    <div className={`w-100 py-5 py-md-8 ${additionalClassName}`}>
      <div className="container-xl d-flex justify-content-between align-items-center">
        <img
          className="h-30px cursor-pointer"
          src={AppResource.icons.icLogoQuanLyHoaDon}
          alt="logo"
          onClick={() => {
            navigate('/inbot/dashboard');
          }}
        />

        <div className="d-flex align-items-center">
          <div className="LandingPageHeader_laptop header-tabs me-3 d-flex align-items-center">
            {/* <div className="d-flex align-items-center h-100">
              {headerTabs?.map((item, index) => (
                <div
                  key={index}
                  className="header-tab font-size-h5 font-weight-bolder text-dark-65 cursor-pointer mx-1 mx-md-3 mx-lg-6 mx-xl-8"
                  onClick={() => {
                    handleScroll(item?.id);
                  }}
                >
                  {item?.title}
                </div>
              ))}
            </div> */}

            {/* gio hang */}
            {shoppingCartCount > 0 && !isShoppingCartScreen && (
              <div className="topbar-item">
                <div
                  className="btn btn-icon btn-clean btn-lg mr-1 position-relative"
                  onClick={() => {
                    navigate('/store/shopping-cart');
                  }}
                >
                  <i className="fa-regular fa-shopping-cart" />
                  <span
                    className="position-absolute bg-danger text-white rounded"
                    style={{
                      top: '6px',
                      right: '8px',
                      fontSize: '0.8rem',
                      padding: '0 4px',
                    }}
                  >
                    {shoppingCartCount}
                  </span>
                </div>
              </div>
            )}
          </div>

          {!isAuth ? (
            <VIButton
              className="btn-blue mx-3 h-35px border-primary font-size-h5 w-100px"
              text={t('SignIn')}
              onClick={() => navigate('/auth/sign-in')}
            />
          ) : (
            <>
              <KTBSDropdown
                contentEl={<DropdownHeaderAccountInfo />}
                toggleElement={
                  <div className="topbar-item ml-2">
                    <KTTooltip text={account.displayName} position={KTTooltipPositions.auto}>
                      <div className="btn btn-icon btn-clean d-flex align-items-center btn-lg">
                        <div className="symbol symbol-circle symbol-35 bg-white overflow-hidden">
                          <div className="symbol-label bg-white border">
                            <img
                              alt="avatar"
                              src={account.avatar}
                              className="w-35px h-35px"
                              style={{
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </KTTooltip>
                  </div>
                }
                alignment={KTBSDropdownAlignments.end}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeaderStore;
