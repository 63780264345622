import CellInstructionGrid from 'features/Instruction/components/CellInstructionGrid';
import EmptyView from 'features/Others/components/EmptyView';
import KeenSelectOption from 'general/components/OtherKeenComponents/KeenSelectOption';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import AppConfigs from 'general/constants/AppConfigs';
import AppResource from 'general/constants/AppResource';
import StringUtils from 'general/utils/StringUtils';
import Utils from 'general/utils/Utils';
import useConfig from 'hooks/useConfig';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function InstructionHomeScreen(props) {
  // ---- params ----
  const { t } = useTranslation();
  const [contentMaxHeight, setContentMaxHeight] = useState('55vh');
  const refCardHeader = useRef(null);
  const { allInstructionCategoryList, instructionList, isGettingInstructionList } = useSelector(
    (state) => state.instruction
  );
  const instructionCategoryOptions = useMemo(() => {
    return allInstructionCategoryList?.map((item) => {
      return {
        name: item.title,
        value: item.instructionCategoryId,
      };
    });
  }, [allInstructionCategoryList]);
  const [filters, setFilters] = useState({
    q: '',
    instructionCategoryId: '',
  });

  const [filteredInstructionList, setFilteredInstructionList] = useState([]);
  const bannerHeight = useConfig().systemNotification.bannerHeight;

  function resizeScreen() {
    setContentMaxHeight(
      `calc(100vh - ${
        bannerHeight +
        (AppConfigs.isHideFooter ? 0 : 65) +
        72 +
        refCardHeader?.current?.clientHeight
      }px - ${4.5 + 3 + 1}rem)`
    );
  }

  window.onresize = resizeScreen;

  useEffect(() => {
    resizeScreen();
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const { q, instructionCategoryId } = filters;
    let filteredItems = _.chain(instructionList);
    // loc theo the loai
    if (instructionCategoryId !== '') {
      filteredItems = filteredItems.filter(
        (item) => item.instructionCategoryId === instructionCategoryId
      );
    }
    // loc theo text
    filteredItems = filteredItems.filter((item) => {
      return (
        StringUtils.removeVietnameseTones(item?.title)
          .toLowerCase()
          ?.includes(StringUtils.removeVietnameseTones(q).toLowerCase()) ||
        StringUtils.removeVietnameseTones(item?.description)
          .toLowerCase()
          ?.includes(StringUtils.removeVietnameseTones(q).toLowerCase())
      );
    });
    setFilteredInstructionList(filteredItems.value());
  }, [filters, instructionList]);

  return (
    <div
      className="card card-custom p-9 h-100"
      style={{
        borderRadius: '16px',
      }}
    >
      <div className="d-flex flex-column align-items-start" ref={refCardHeader}>
        <h3 className="card-label text-grey font-weight-bolder mb-3">{t('UserManual')}</h3>
        <div className="d-flex align-items-center flex-wrap">
          <div className="mb-4">
            <VISearchField
              placeholder={`${t('EnterToSearch')}...`}
              value={''}
              className=""
              typingTimeout={500}
              onSubmit={(newValue) => {
                setFilters({ ...filters, q: newValue });
              }}
            />
          </div>
          {allInstructionCategoryList?.length > 0 && (
            <div className="d-flex align-items-center ml-4 mb-4">
              <label
                htmlFor="filterInstructionCategory"
                className="mb-0 mr-2 text-grey font-weight-bolder"
              >
                {t('FilterByCategory')}
              </label>
              <KeenSelectOption
                name="filterInstructionCategory"
                initialValue={filters.instructionCategoryId}
                options={[{ name: 'All', value: '' }, ...instructionCategoryOptions]}
                containerClassName=""
                labelClassName=""
                selectClassName="w-100"
                menuClassName="min-w-300px"
                onValueChanged={(value) => {
                  setFilters({ ...filters, instructionCategoryId: value });
                }}
              />
            </div>
          )}
        </div>
      </div>

      {/* danh sach instruction */}
      <div
        className="overflow-auto pt-4"
        style={{
          height: contentMaxHeight,
        }}
      >
        {filteredInstructionList?.length > 0 ? (
          <div className="row w-100">
            {filteredInstructionList?.map((item, index) => {
              return (
                <div className="col-lg-6 mb-6" key={index}>
                  <CellInstructionGrid
                    item={item}
                    onPress={() => {
                      if (item?.url) {
                        Utils.openInNewTab(item?.url);
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="h-100">
            <EmptyView message={t('NoDataFound')} image={AppResource.images.imgEmptyNotFound} />
          </div>
        )}
      </div>
    </div>
  );
}

export default InstructionHomeScreen;
