import tagApi from 'api/tagApi';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// ---- thunks ----
// lay danh sach tags
export const thunkGetTagList = createAsyncThunk('tag/getTagList', async (params, thunkAPI) => {
  const res = await tagApi.findTag(params);
  return res;
});
//cập nhật tags
export const thunkUpdateTagList = createAsyncThunk(
  'tag/updateTagList',
  async (params, thunkAPI) => {
    const res = await tagApi.updateTag(params?.id, params?.params);
    return res;
  }
);
//thêm tags
export const thunkCreateTagList = createAsyncThunk(
  'tag/createTagList',
  async (params, thunkAPI) => {
    const res = await tagApi.createTag(params);
    return res;
  }
);
//xóa tags
export const thunkDeleteTagList = createAsyncThunk(
  'tag/deleteTagList',
  async (params, thunkAPI) => {
    const res = await tagApi.deleteTag(params);
    return res;
  }
);
//lấy chi tiết tags
export const thunkFindDetailTag = createAsyncThunk(
  'tag/findDetailTag',
  async (params, thunkAPI) => {
    const res = await tagApi.findDetailTag(params);
    return res;
  }
);

const tagSlice = createSlice({
  name: 'tags',
  initialState: {
    tagList: [],
    isGettingTagList: false,
    tagDetails: {},
    isGettingTagDetails: false,
    isGettingUpdateTag: false,
    isGettingDeleteTag: false,
    isGettingCreateTag: false,
  },
  reducers: {},
  extraReducers: {
    // lay danh sach tags
    [thunkGetTagList.pending]: (state, action) => {
      state.isGettingNotificationList = true;
    },
    [thunkGetTagList.rejected]: (state, action) => {
      state.isGettingTagList = false;
    },
    [thunkGetTagList.fulfilled]: (state, action) => {
      state.isGettingTagList = false;
      const { result, tags } = action.payload;
      if (result === 'success' && tags) {
        state.tagList = tags;
      }
    },
    //tạo mới tag
    [thunkCreateTagList.pending]: (state, action) => {
      state.isGettingCreateTag = true;
    },
    [thunkCreateTagList.rejected]: (state, action) => {
      state.isGettingCreateTag = false;
    },
    [thunkCreateTagList.fulfilled]: (state, action) => {
      state.isGettingCreateTag = false;
    },
  },
});

const { reducer, actions } = tagSlice;
export const {} = actions;
export default reducer;
