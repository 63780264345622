import AppConfigs from 'general/constants/AppConfigs';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

DropdownHeaderMenuInfo.propTypes = {};

function DropdownHeaderMenuInfo(props) {
  // ---- params ----
  const { t } = useTranslation();
  const issuingEInvoiceItems = useMemo(() => {
    return [
      {
        icon: AppResource.images.imgHDDR,
        name: 'Hoá đơn, PXK',
        url: AppConfigs.vietInvoiceV2Url,
      },
      {
        icon: AppResource.images.imgTemVe,
        name: 'Vé điện tử',
        url: AppConfigs.vietInvoiceV2Url,
      },
      {
        icon: AppResource.images.imgBienLai,
        name: 'Biên lai',
        url: AppConfigs.vietInvoiceV2Url,
      },
    ];
  }, [t]);

  return (
    <div className="DropdownHeaderMenuInfo p-6 w-400px">
      <div className="gutter-b">
        <h4 className="font-weight-bolder m-0 mb-4 text-grey">Phát hành hóa đơn điện tử</h4>
        <div className="row">
          {issuingEInvoiceItems.map((item, index) => {
            return (
              <div
                className={`col-4 ${index < issuingEInvoiceItems.length - 1 ? 'pr-2' : ''}`}
                key={index}
              >
                <div
                  className="btn btn-block btn-white btn-hover-primary text-dark-50 text-center p-4 h-100 d-flex flex-column align-items-center justify-content-center"
                  onClick={() => {
                    Utils.openInNewTab(item.url);
                  }}
                >
                  <img src={item.icon} className="h-50px" />
                  <span className="d-block font-weight-bold font-size-normal mt-2 text-dark">
                    {item.name}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <h4 className="font-weight-bolder text-grey m-0 mb-4">Xử lý hoá đơn</h4>
        <div className="row">
          <div className="col-4 pr-2">
            <div className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center p-4 h-100 d-flex flex-column align-items-center justify-content-center active">
              <img src={AppResource.images.imgHDDV} className="h-50px" />
              <span className="d-block font-weight-bold font-size-normal mt-2">Xử lý hoá đơn</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DropdownHeaderMenuInfo;
