import 'assets/styles/keenpages/login-1.css';
import AuthHeader from 'features/Auth/components/AuthHeader';
import ForgotPasswordForm from 'features/Auth/components/ForgotPasswordForm';
import ResetPasswordForm from 'features/Auth/components/ResetPasswordForm';
import SignInForm from 'features/Auth/components/SignInForm';
import SignUpForm from 'features/Auth/components/SignUpForm';
import LandingPageFooter from 'features/LandingPage/components/LandingPageFooter';
import { AnimatePresence } from 'framer-motion';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import useRouter from 'hooks/useRouter';
import useScrollToTop from 'hooks/useScrollToTop';
import { useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

function KeenAuth01BaseScreen(props) {
  // MARK: --- Params ---
  const router = useRouter();
  const { location } = router;
  const [headerHeight, setHeaderHeight] = useState(0);

  // MARK: --- Functions ---
  function handleSubmitForgotPassword(values) {}

  function handleCancelForgotPassword() {
    router.navigate(-1);
  }

  function handleSubmitSignUp(values) {}

  function handleCancelSignUp() {
    router.navigate(-1);
  }

  useEffect(() => {
    const el = document.getElementById('auth-header');
    if (el) {
      setHeaderHeight(el.offsetHeight);
    }
  }, []);

  // MARK: --- Hooks ---
  useScrollToTop();

  return (
    <div className="d-flex flex-column flex-root">
      {/* header */}
      <AuthHeader />
      <div
        className="d-flex flex-column flex-column-fluid flex-lg-row login login-1 login-signin-on min-vh-100 position-relative"
        style={{
          background: 'linear-gradient(180deg, #020070 0%, #0B3BBA 100%)',
          paddingTop: `${headerHeight}px`,
        }}
      >
        {/* decor */}
        <div
          className="position-absolute right-0 top-0 w-100 h-100"
          style={{
            backgroundImage: `url(${AppResource.images.imgAuthDecor})`,
            backgroundSize: 'contain',
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
        {/* aside */}
        <div
          className="login-aside d-flex flex-column flex-row-auto d-flex flex-column justify-content-around max-w-100 max-w-lg-50 align-items-center"
          style={
            {
              /*backgroundColor: '#A60000'*/
              // background: 'linear-gradient(180deg, #020070 0%, #0B3BBA 100%)',
              // maxWidth: '50%',
            }
          }
        >
          {/* aside top */}
          <div
            className="d-flex flex-column-auto flex-column pt-lg-40 pt-15"
            style={{
              maxWidth: '75%',
            }}
          >
            {/* aside header */}
            <Link className="text-center mb-15" to="/landing-page">
              <img alt="logo" src={AppResource.icons.icLogoQuanLyHoaDonDark} className="h-70px" />
            </Link>
            {/* aside title */}
            <h3
              className="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
              style={{
                color: '#FFCC18',
              }}
            >
              Giải pháp quản lý hoá đơn
              <br />
              <span className="text-white" style={{ fontSize: '1.4rem' }}>
                Hiệu quả - An toàn - Tiện ích
              </span>
            </h3>
            <ul className="font-size-h5 text-white line-height-xl">
              <li>
                Thay đổi hoàn toàn cách thức quản lý, báo cáo hóa đơn đầu vào và hóa đơn đầu ra của
                doanh nghiệp bạn
              </li>
              <li>Tự động đồng bộ hóa đơn từ Tổng cục thuế</li>
              <li>Kiểm tra tính hợp lệ của hóa đơn và cảnh báo hóa đơn rủi ro</li>
              <li>Kết xuất bảng kê chi tiết hàng hóa ra Excel</li>
              <li>Lưu trữ, tra cứu và tải hóa đơn PDF/XML hàng loạt</li>
              <li>Kiểm tra trạng thái hoạt động doanh nghiệp, tra cứu doanh nghiệp rủi ro</li>
              <li>Giảm 80% thời gian và 90% chi phí xử lý, kiểm tra, đối soát, nhập liệu</li>
            </ul>
          </div>

          {/* aside bottom */}
          {/* <div
            className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
            style={{
              backgroundImage: `url(${AppResource.images.imgDecorDown})`,
              backgroundSize: 'contain',
            }}
          ></div> */}
          {/* slider intro other apps */}
          <div className="p-12 w-100">
            <Swiper
              // ref={partnersRef}
              modules={[Pagination, Navigation, Autoplay]}
              slidesPerView={2}
              spaceBetween={16}
              autoplay={{
                delay: 3000,
              }}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              className="h-250px"
              style={{
                '--swiper-pagination-color': '#fff',
                '--swiper-pagination-bullet-inactive-color': '#fff',
                '--swiper-pagination-bullet-inactive-opacity': '0.4',
                '--swiper-pagination-bullet-size': '8px',
                '--swiper-pagination-bullet-horizontal-gap': '4px',
              }}
              // breakpoints={{
              //   0: {
              //     slidesPerView: 1,
              //   },

              //   576: {
              //     slidesPerView: 2,
              //   },
              //   1200: {
              //     slidesPerView: 3,
              //   },
              //   1400: {
              //     slidesPerView: 4,
              //   },
              // }}
            >
              {AppData.icorpProducts.map((item, index) => (
                <SwiperSlide key={index}>
                  <img
                    height={200}
                    src={item.image}
                    className="cursor-pointer"
                    style={{
                      backgroundColor: '#E1F0FF',
                      borderRadius: '8px',
                      border: '8px solid #fff',
                      width: 'calc(100%)',
                      objectFit: 'contain',
                    }}
                    onClick={() => {
                      const url = item.url;
                      if (!_.isEmpty(url)) {
                        Utils.openInNewTab(url);
                      }
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        {/* content */}
        <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
          {/* content body */}
          <div className="d-flex flex-column-fluid flex-center">
            <div
              className="bg-white w-100 p-10"
              style={{
                borderRadius: '8px',
                boxShadow: '0px 5px 10px 0px rgba(44, 63, 88, 0.10)',
              }}
            >
              <AnimatePresence mode="wait">
                <Routes location={router.location} key={router.location.pathname}>
                  <Route path="" element={<Navigate to="sign-in" />} />
                  <Route path="sign-in" element={<SignInForm />} />
                  <Route
                    path="sign-up"
                    element={
                      <SignUpForm onSubmit={handleSubmitSignUp} onCancel={handleCancelSignUp} />
                    }
                  />
                  <Route
                    path="forgot-password"
                    element={
                      <ForgotPasswordForm
                        onSubmit={handleSubmitForgotPassword}
                        onCancel={handleCancelForgotPassword}
                      />
                    }
                  />
                  <Route path="reset-password" element={<ResetPasswordForm />} />

                  <Route path="*" element={<>Not Found</>} />
                </Routes>
              </AnimatePresence>
            </div>
          </div>

          {/* content footer */}
          <div className="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
            {/* <a href="#" className="text-primary font-weight-bolder font-size-h5">
              Terms
            </a>
            <a href="#" className="text-primary ml-10 font-weight-bolder font-size-h5">
              Plans
            </a>
            <a href="#" className="text-primary ml-10 font-weight-bolder font-size-h5">
              Contact Us
            </a> */}
          </div>
        </div>
      </div>

      {/* footer */}
      <LandingPageFooter />
    </div>
  );
}

export default KeenAuth01BaseScreen;
