import AppData from 'general/constants/AppData';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useSupplier(props) {
  // ---- params ----
  const {
    allSupplierList,
    connectionSupplierList,
    isGettingConnectSupplier,
    connectionSupplierHistoryList,
    totalConnectionSupplierHistory,
  } = useSelector((state) => state.supplier);

  return useMemo(() => {
    const supplierInvoiceList = allSupplierList.filter(
      (item) => item.supplierType === AppData.supplierType.invoice && item.visible === 'SHOW'
    );
    const supplierServiceList = allSupplierList.filter(
      (item) => item.supplierType === AppData.supplierType.service && item.visible === 'SHOW'
    );

    // nhom ds ket noi theo id ncc
    const groupedConnectionSuppliers = _.groupBy(connectionSupplierList, 'supplierId');
    // console.log(groupedConnectionSuppliers);
    const connectedSupplierIds = Object.keys(groupedConnectionSuppliers);
    const connectedSupplierList = connectedSupplierIds.map((item) => {
      return _.find(allSupplierList, (obj) => obj.supplierId == item);
    });
    // console.log(connectedSupplierList);

    return {
      allSupplierList,
      // ncc dich vu hoa don
      supplierInvoiceList: supplierInvoiceList,
      // ncc dich vu hang hoa
      supplierServiceList: supplierServiceList,

      connectionSupplierList,

      // danh sach ncc da ket noi
      connectedSupplierList,

      groupedConnectionSuppliers,

      // lich su dong bo
      history: {
        items: connectionSupplierHistoryList ?? [],
        total: totalConnectionSupplierHistory ?? 0,
      },
    };
  }, [
    allSupplierList,
    connectionSupplierList,
    connectionSupplierHistoryList,
    totalConnectionSupplierHistory,
  ]);
}

export default useSupplier;
