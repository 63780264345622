import VIButton from 'general/components/VietInvoice/VIButton';
import PropTypes from 'prop-types';
import './style.scss';
import useAccount from 'hooks/useAccount';
import { useTranslation } from 'react-i18next';
import Utils from 'general/utils/Utils';

CardResourceInformation.propTypes = {
  notUsed: PropTypes.number,
  used: PropTypes.number,
  purchased: PropTypes.number,
  expiredOn: PropTypes.string,
  onExtend: PropTypes.func,
  onBuy: PropTypes.func,
  className: PropTypes.string,
};

CardResourceInformation.defaultProps = {
  notUsed: 0,
  used: 0,
  purchased: 0,
  expiredOn: '--/--/----',
  className: '',
  onBuy: () => {},
  onExtend: () => {},
};

/**
 * @param {number} notUsed
 * @param {number} used
 * @param {number} purchased
 * @param {string} expiredOn
 * @param {string} className
 * @param {function} onBuy handle onclick "mua them"
 * @param {function} onExtend handle onclick "gia han"
 *
 */

function CardResourceInformation(props) {
  // ---- params ----
  const { onBuy, onExtend } = props;
  const { t } = useTranslation();
  const account = useAccount();

  return (
    <div
      className={`CardResourceInformation d-flex flex-column flex-sm-row flex-lg-column flex-xxl-row gap-2 p-5 h-100`}
    >
      <div className="w-100 w-sm-50 w-lg-100 w-xxl-50 d-flex flex-column justify-content-between">
        <p className="CardResourceInformation_title font-size-h3 mb-2 font-weight-bolder text-white">
          {t('ResourceInfo')}
        </p>
        <div className="d-flex gap-3 mb-2">
          <VIButton
            text="Gia hạn"
            style={{ background: '#182748', borderRadius: 8 }}
            className="btn-blue font-weight-boldest font-size-h5 p-1 border-0"
            onClick={() => {
              if (onExtend) {
                onExtend();
              }
            }}
          />

          <VIButton
            text="Mua thêm"
            style={{ color: '#1C2256', borderRadius: 8 }}
            className="btn-white font-weight-boldest font-size-h5 p-1 border-0"
            onClick={() => {
              if (onBuy) {
                onBuy();
              }
            }}
          />
        </div>
      </div>
      <div className="CardResourceInformation_data w-100 w-sm-50 w-lg-100 w-xxl-50 font-weight-bolder text-white d-flex flex-column gap-3 justify-content-between">
        <div className="d-flex">
          <div className="w-50 font-size-lg">
            Hóa đơn chưa sử dụng
            <p className="font-size-h1 m-0">
              {Utils.formatNumber(account.company.resourceTotalUnusedInvoice)}
            </p>
          </div>
          <div className="w-50 font-size-lg">
            Hóa đơn đã sử dụng
            <p className="font-size-h1 m-0">
              {Utils.formatNumber(
                account.company.resourceTotalInvoice - account.company.resourceTotalUnusedInvoice
              )}
            </p>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 font-size-lg">
            Hóa đơn đã mua
            <p className="font-size-h1 m-0">
              {Utils.formatNumber(account.company.resourceTotalInvoice)}
            </p>
          </div>
          <div className="w-50 font-size-lg">
            Thời hạn sử dụng đến
            <p className="font-size-h1 m-0">{account.company.resourceDateExpired}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardResourceInformation;
