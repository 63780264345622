import AppData from 'general/constants/AppData';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import VIButton from '../VIButton';
import './style.scss';

VIPagination.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  displayRows: PropTypes.number,
  totalPreTaxMoney: PropTypes.number,
  totalTaxAmount: PropTypes.number,
  totalValue: PropTypes.number,
  widthTotal: PropTypes.string,
};

VIPagination.defaultProps = {
  rowsPerPage: Global.gDefaultPagination,
  rowCount: 0,
  onChangePage: null,
  onChangeRowsPerPage: null,
  currentPage: 1,
  displayRows: 0,
  totalPreTaxMoney: null,
  totalTaxAmount: null,
  totalValue: null,
  widthTotal: null,
};

function VIPagination(props) {
  let {
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
    displayRows,
    totalPreTaxMoney,
    totalTaxAmount,
    totalValue,
    widthTotal,
  } = props;
  // console.log(props);
  const [newPage, setNewPage] = useState(currentPage);
  const [validPage, setValidPage] = useState(true);
  const refPageToggle = useRef(null);
  rowsPerPage = parseInt(rowsPerPage);
  currentPage = parseInt(currentPage);
  const totalPages = Math.ceil(rowCount / rowsPerPage);

  const arrButtons = [];

  for (let j = 0; j < Math.ceil(rowCount / rowsPerPage); j++) {
    arrButtons.push({ value: j + 1 });
  }

  function handlePageChange(page) {
    const iNewPage = parseInt(page);
    if (onChangePage && iNewPage !== currentPage) {
      onChangePage(iNewPage);
    }
  }

  const handlePerPageChange = (page) => {
    const intValue = parseInt(page);
    if (onChangeRowsPerPage && intValue) {
      onChangeRowsPerPage(intValue);
    }
  };

  return (
    <div className="VIPagination w-100 bg-transparent py-2">
      <div className="d-flex justify-content-between align-items-center pagination-flex">
        <div className="d-flex align-items-center flex-wrap">
          <div className="d-flex align-items-center flex-wrap">
            <p
              className="me-5 mb-0"
              style={widthTotal ? { width: `${widthTotal}` } : { width: '175px' }}
            >
              {t('TotalRecord')}
              {': '}
              <span className="font-weight-bolder">{`${Utils.formatNumber(
                displayRows
              )}/${Utils.formatNumber(rowCount)}`}</span>
            </p>
            {totalPreTaxMoney ? (
              <p className="me-5 mb-0">
                {t('TotalPreTaxMoney')}
                {': '}
                <span className="font-weight-bolder">{`${Utils.formatNumber(
                  totalPreTaxMoney
                )}`}</span>
              </p>
            ) : (
              ''
            )}
          </div>
          <div className="d-flex align-items-center flex-wrap">
            {totalTaxAmount ? (
              <p className="me-5 mb-0" style={{ width: '175px' }}>
                {t('TotalTaxAmount')}
                {': '}
                <span className="font-weight-bolder">{`${Utils.formatNumber(
                  totalTaxAmount
                )}`}</span>
              </p>
            ) : (
              ''
            )}
            {totalValue ? (
              <p className="me-5 mb-0">
                {t('TotalValue')}
                {': '}
                <span className="font-weight-bolder">{`${Utils.formatNumber(totalValue)}`}</span>
              </p>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="d-flex align-items-center page-pagination">
          <div className="border-right px-4 mr-4">
            <Dropdown>
              <Dropdown.Toggle className="vi-btn btn-outline-secondary font-weight-bolder py-2 d-flex align-items-center">
                {t('Show')}
                {': '}
                {rowsPerPage}
                <i className="fa-solid fa-caret-down ml-2 mr-0"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  {AppData.perPageItems.map((item, index) => (
                    <Dropdown.Item
                      className="d-flex justify-content-between"
                      key={index}
                      onClick={() => {
                        handlePerPageChange(item.value);
                      }}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>{item?.value}</span>
                      {rowsPerPage === item.value && (
                        <i className="text-primary fa-solid fa-check"></i>
                      )}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="d-flex flex-row align-items-center">
            <button
              onClick={() => {
                if (onChangePage) {
                  onChangePage(currentPage - 1);
                }
              }}
              className="btn btn-icon btn-sm btn-outline-secondary btn-hover-light-primary vi-btn"
              disabled={currentPage <= 1}
            >
              <i className="fa-regular fa-angle-left fa-lg"></i>
            </button>

            <Dropdown drop="up" className="mx-2">
              <Dropdown.Toggle
                ref={refPageToggle}
                className="vi-btn btn-outline-secondary font-weight-bolder py-2 d-flex align-items-center"
              >
                {currentPage}
                <i className="fa-solid fa-caret-down ml-2 mr-0"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-6 min-w-250px">
                <div className="d-flex">
                  <KTFormInput
                    name={`page`}
                    value={String(currentPage)}
                    type={KTFormInputType.number}
                    onChange={(value) => {
                      const iValue = parseInt(value);
                      if (iValue >= 0) {
                        setNewPage(iValue);
                      }
                    }}
                    enableCheckValid
                    isValid={validPage}
                    isTouched={!validPage}
                    feedbackText={<span style={{ whiteSpace: 'nowrap' }}>{t('InvalidPage')}</span>}
                  />

                  <VIButton
                    text={t('GoTo')}
                    className="btn-blue ml-4"
                    style={{ padding: '8px' }}
                    onClick={() => {
                      if (newPage && /^[1-9]\d*$/.test(newPage)) {
                        handlePageChange(newPage);
                        setValidPage(true);
                        refPageToggle.current.click();
                      } else {
                        setValidPage(false);
                      }
                    }}
                  />
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <button
              onClick={() => {
                if (onChangePage) {
                  onChangePage(currentPage + 1);
                }
              }}
              className="btn btn-icon btn-sm btn-hover-light-primary btn-outline-secondary vi-btn"
              disabled={currentPage >= totalPages}
            >
              <i className="fa-regular fa-angle-left fa-rotate-180 fa-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VIPagination;
