import { updateSelectedVoucher } from 'features/Payment/paymentSlice';
import { AnimatePresence, motion } from 'framer-motion';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import PaymentHelper from 'general/helpers/PaymentHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Utils from 'general/utils/Utils';
import usePayment from 'hooks/usePayment';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ClockLoader } from 'react-spinners';

CartConfirmPaymentOrder.propTypes = {
  onPressEditOrder: PropTypes.func,
  onPayment: PropTypes.func,
  creatingOrder: PropTypes.bool,
};

CartConfirmPaymentOrder.defaultProps = {
  onPressEditOrder: null,
  onPayment: null,
  creatingOrder: false,
};

/**
 *
 * @param {{
 * onPressEditOrder: function,
 * onPayment: function,
 * creatingOrder: boolean,
 * }} props
 * @returns
 */
function CartConfirmPaymentOrder(props) {
  // ---- params ----
  const { onPressEditOrder, onPayment, creatingOrder } = props;
  const { t } = useTranslation();
  const {
    cartTotalPrice,
    cartPriceUnit,
    canShoppingCart,
    shoppingCartCount,
    shoppingCartList,
    readyToPurchaseOrder,
    isBankTransfer,
    selectedVoucher,
    lastPriceToPay,
    totalPromotionPrice,
  } = usePayment();
  const router = useRouter();
  const [expand, setExpand] = useState(false);
  const refVoucherCode = useRef('');
  const dispatch = useDispatch();

  useEffect(() => {
    refVoucherCode.current = selectedVoucher?.voucherCode;
  }, [selectedVoucher]);

  // ---- methods -----
  function handlePayment() {
    if (onPayment) {
      onPayment();
    }
  }

  function handleEditOrder() {
    if (onPressEditOrder) {
      onPressEditOrder();
    }
  }

  async function handleApplyVoucher(code) {
    const voucher = await PaymentHelper.callApiFindVoucherByCode(code);
    if (voucher) {
      dispatch(updateSelectedVoucher(voucher));
      ToastHelper.showSuccess(t('ApplyVoucherSuccess'));
    } else {
    }
  }

  return (
    <div
      className="bg-white"
      style={{
        borderRadius: '16px',
        border: '1px solid #E8E9EB',
      }}
    >
      <div className="px-6 py-4 border-bottom">
        <div className="d-flex gap-2 align-items-center justify-content-between">
          <div>
            <span className="font-weight-bolder text-grey font-size-lg">{t('PurchaseOrder')}</span>
            <div className="d-flex align-items-center gap-2">
              <span>
                {shoppingCartCount} {t('Product').toLowerCase()}
              </span>
              {shoppingCartCount > 0 && (
                <div
                  className="text-primary cursor-pointer d-flex align-items-center gap-1"
                  onClick={() => {
                    setExpand(!expand);
                  }}
                >
                  {t('Detail')}{' '}
                  <i
                    className={`fa-regular fa-angle-${expand ? 'up' : 'down'}`}
                    style={{
                      color: 'inherit',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <VIButton
            text={t('Edit')}
            className="btn-grey"
            style={{ padding: '8px' }}
            onClick={handleEditOrder}
            beforeIcon={<i className="fa-regular fa-edit" />}
            disabled={!canShoppingCart}
          />
        </div>

        {/* mo rong */}
        <AnimatePresence>
          {expand && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, scaleY: 1 }}
              exit={{ opacity: 0, scaleY: 0 }}
              className="mt-4 bg-light px-4 py-2 rounded"
            >
              {shoppingCartList?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`d-flex gap-3 align-items-center justify-content-between py-4 ${
                      index < shoppingCartCount - 1 ? 'border-bottom border-secondary' : ''
                    }`}
                  >
                    <div>{item?.quantity}x</div>
                    <div className="flex-grow-1">{item?.package?.packageCode}</div>
                    <div>
                      {Utils.formatCurrency(item?.package?.packageCost * item?.quantity)}{' '}
                      {item?.package?.unit}
                    </div>
                  </div>
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>

        <div className="d-flex gap-2 align-items-center mt-3">
          <KTFormInput
            name="cartInputVoucher"
            additionalClassName="flex-grow-1"
            placeholder={`${t('VoucherCode')}...`}
            value={selectedVoucher?.voucherCode ?? ''}
            enableInputGroup
            prependElements={[<i className="fa-regular fa-gift" />]}
            readonly={!canShoppingCart}
            type={KTFormInputType.text}
            onChange={(text) => {
              refVoucherCode.current = text;
            }}
          />
          <VIButton
            text={t('Apply')}
            className="btn-blue"
            style={{ padding: '8px' }}
            onClick={() => {
              handleApplyVoucher(refVoucherCode.current);
            }}
            disabled={!canShoppingCart}
          />
        </div>
      </div>

      {/* thanh tien */}
      {/* tong cong */}
      <div className="px-6 border-bottom">
        <div className="d-flex py-4 align-items-start justify-content-between">
          <div className="">{t('TotalPrice')}</div>
          <div className="font-size-lg text-grey">
            {Utils.formatCurrency(cartTotalPrice)} {cartPriceUnit}
          </div>
        </div>
      </div>
      {/* khuyen mai */}
      <div className="px-6 border-bottom">
        <div className="d-flex py-4 align-items-start justify-content-between">
          <div className="d-flex align-items-center gap-2">
            {t('Promotion')}{' '}
            {selectedVoucher && (
              <KTTooltip
                text={
                  <div className="d-flex flex-column align-items-start">
                    <p className="m-0">
                      <span>{t('VoucherCode')}:</span>{' '}
                      <span className="font-weight-bolder">{selectedVoucher.voucherCode}</span>
                    </p>
                    <p className="m-0">
                      {t('VoucherName')}: {selectedVoucher.voucherName}
                    </p>
                  </div>
                }
              >
                <i className="fa-regular fa-circle-question text-primary" />
              </KTTooltip>
            )}
          </div>
          <div className="font-size-lg text-grey">
            {Utils.formatCurrency(totalPromotionPrice)} {cartPriceUnit}
          </div>
        </div>
      </div>
      {/* tong phai tra */}
      <div className="px-6">
        <div className="d-flex py-4 align-items-start justify-content-between">
          <div className="">{t('TotalPricePay')}</div>
          <div className="d-flex flex-column align-items-center">
            <span className="font-size-lg text-grey font-weight-bolder">
              {Utils.formatCurrency(lastPriceToPay)} {cartPriceUnit}
            </span>{' '}
            <span>({t('IncludedVAT')})</span>
          </div>
        </div>
      </div>

      {/* tiep tuc */}
      <div className="d-flex px-6 pb-4">
        <VIButton
          text={isBankTransfer ? t('Order') : t('Payment')}
          className="btn-blue w-100"
          style={{ padding: '8px' }}
          onClick={handlePayment}
          disabled={!readyToPurchaseOrder || creatingOrder}
          beforeIcon={creatingOrder ? <ClockLoader color="white" size={20} /> : <></>}
        />
      </div>
    </div>
  );
}

export default CartConfirmPaymentOrder;
