import axiosClient from './axiosClient';

const faqApi = {
  // lay tat ca faqs
  getAll: () => {
    const url = '/faq/find';
    return axiosClient.get(url);
  },
};

export default faqApi;
