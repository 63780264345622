import invoiceApi from 'api/invoiceApi';
import moment from 'moment';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// lay danh sách hóa đơn đầu vào
export const thunkGetAllInInvoice = createAsyncThunk(
  'invoice/getAllINInvoices',
  async (params, thunkAPI) => {
    const copiedParams = { ...params };
    delete copiedParams['dateRangeLabel'];
    try {
      const res = await invoiceApi.getAllInInvoice(copiedParams);
      return res;
    } catch (error) {
      console.log({ error });
      return false;
    }
  }
);

// lay danh sách hóa đơn đầu vào đã xóa
export const thunkGetAllDeleteInInvoice = createAsyncThunk(
  'invoice/getAllINInvoicesDelete',
  async (params, thunkAPI) => {
    const copiedParams = { ...params };
    delete copiedParams['dateRangeLabel'];
    try {
      const res = await invoiceApi.getAllInInvoice(copiedParams);
      return res;
    } catch (error) {
      console.log({ error });
      return false;
    }
  }
);
// lay danh sách hóa đơn đầu ra
export const thunkGetAllOutInvoice = createAsyncThunk(
  'invoice/getAllOutInvoices',
  async (params, thunkAPI) => {
    const copiedParams = { ...params };
    delete copiedParams['dateRangeLabel'];
    try {
      const res = await invoiceApi.getAllInInvoice(copiedParams);
      return res;
    } catch (error) {
      console.log({ error });
      return false;
    }
  }
);
// lay danh sách hóa đơn đầu ra đã xóa
export const thunkGetAllDeleteOutInvoice = createAsyncThunk(
  'invoice/getAllOutInvoicesDelete',
  async (params, thunkAPI) => {
    const copiedParams = { ...params };
    delete copiedParams['dateRangeLabel'];
    try {
      const res = await invoiceApi.getAllInInvoice(copiedParams);
      return res;
    } catch (error) {
      console.log({ error });
      return false;
    }
  }
);
const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    listInputInvoice: [],
    totalInputInvoice: null,
    totalAmountBeforeVATInputInvoice: null,
    totalAmountVATInputInvoice: null,
    totalFinalAmountInputInvoice: null,
    isGettingInInvoiceList: false,
    listInputInvoiceDelete: [],
    totalInputInvoiceDelete: null,
    totalAmountBeforeVATInputInvoiceDelete: null,
    totalAmountVATInputInvoiceDelete: null,
    totalFinalAmountInputInvoiceDelete: null,
    isGettingInInvoiceListDelete: false,
    listOutputInvoice: [],
    totalOutputInvoice: null,
    totalAmountBeforeVATOutputInvoice: null,
    totalAmountVATOutputInvoice: null,
    totalFinalAmountOutputInvoice: null,
    isGettingOutputInvoiceList: false,
    listOutputInvoiceDelete: [],
    totalOutputInvoiceDelete: null,
    totalAmountBeforeVATOutputInvoiceDelete: null,
    totalAmountVATOutputInvoiceDelete: null,
    totalFinalAmountOutputInvoiceDelete: null,
    isGettingOutputInvoiceListDelete: false,
    forceReload: '',
  },
  reducers: {
    setForceReloadInvoice(state) {
      state.forceReload = moment().format('YYYYMMDDHHmmss');
    },
  },
  extraReducers: {
    // lay danh sách hóa đơn đầu vào
    [thunkGetAllInInvoice.pending]: (state, action) => {
      state.isGettingInInvoiceList = true;
    },
    [thunkGetAllInInvoice.rejected]: (state, action) => {
      state.isGettingInInvoiceList = false;
    },
    [thunkGetAllInInvoice.fulfilled]: (state, action) => {
      state.isGettingInInvoiceList = false;
      const { result, invoices, total, totalAmountBeforeVAT, totalAmountVAT, totalFinalAmount } =
        action.payload;
      if (result === 'success' && invoices) {
        state.listInputInvoice = invoices;
        state.totalInputInvoice = total;
        state.totalAmountBeforeVATInputInvoice = totalAmountBeforeVAT;
        state.totalAmountVATInputInvoice = totalAmountVAT;
        state.totalFinalAmountInputInvoice = totalFinalAmount;
      }
    },
    // lay danh sách hóa đơn đầu vào đã xóa
    [thunkGetAllDeleteInInvoice.pending]: (state, action) => {
      state.isGettingInInvoiceListDelete = true;
    },
    [thunkGetAllDeleteInInvoice.rejected]: (state, action) => {
      state.isGettingInInvoiceListDelete = false;
    },
    [thunkGetAllDeleteInInvoice.fulfilled]: (state, action) => {
      state.isGettingInInvoiceListDelete = false;
      const { result, invoices, total, totalAmountBeforeVAT, totalAmountVAT, totalFinalAmount } =
        action.payload;
      if (result === 'success' && invoices) {
        state.listInputInvoiceDelete = invoices;
        state.totalInputInvoiceDelete = total;
        state.totalAmountBeforeVATInputInvoiceDelete = totalAmountBeforeVAT;
        state.totalAmountVATInputInvoiceDelete = totalAmountVAT;
        state.totalFinalAmountInputInvoiceDelete = totalFinalAmount;
      }
    },

    // lay danh sách hóa đơn đầu ra
    [thunkGetAllOutInvoice.pending]: (state, action) => {
      state.isGettingOutputInvoiceList = true;
    },
    [thunkGetAllOutInvoice.rejected]: (state, action) => {
      state.isGettingOutputInvoiceList = false;
    },
    [thunkGetAllOutInvoice.fulfilled]: (state, action) => {
      state.isGettingOutputInvoiceList = false;
      const { result, invoices, total, totalAmountBeforeVAT, totalAmountVAT, totalFinalAmount } =
        action.payload;
      if (result === 'success' && invoices) {
        state.listOutputInvoice = invoices;
        state.totalOutputInvoice = total;
        state.totalAmountBeforeVATOutputInvoice = totalAmountBeforeVAT;
        state.totalAmountVATOutputInvoice = totalAmountVAT;
        state.totalFinalAmountOutputInvoice = totalFinalAmount;
      }
    },

    // lay danh sách hóa đơn đầu ra đã xóa
    [thunkGetAllDeleteOutInvoice.pending]: (state, action) => {
      state.isGettingOutputInvoiceListDelete = true;
    },
    [thunkGetAllDeleteOutInvoice.rejected]: (state, action) => {
      state.isGettingOutputInvoiceListDelete = false;
    },
    [thunkGetAllDeleteOutInvoice.fulfilled]: (state, action) => {
      state.isGettingOutputInvoiceListDelete = false;
      const { result, invoices, total, totalAmountBeforeVAT, totalAmountVAT, totalFinalAmount } =
        action.payload;
      if (result === 'success' && invoices) {
        state.listOutputInvoiceDelete = invoices;
        state.totalOutputInvoiceDelete = total;
        state.totalAmountBeforeVATOutputInvoiceDelete = totalAmountBeforeVAT;
        state.totalAmountVATOutputInvoiceDelete = totalAmountVAT;
        state.totalFinalAmountOutputInvoiceDelete = totalFinalAmount;
      }
    },
  },
});

const { reducer, actions } = invoiceSlice;
export const { setForceReloadInvoice } = actions;
export default reducer;
