import AppConfigs from "general/constants/AppConfigs";

const LogHelper = {
    // show log
    log: (message) => {
        if (AppConfigs.enableLog ?? true) {
            console.log(message);
        }
    },
};

export default LogHelper;