import customDataTableSelectableStyle from 'assets/styles/customDataTableSelectableStyle';
import customDataTableStyle from 'assets/styles/customDataTableStyle';
import EmptyView from 'features/Others/components/EmptyView';
import AppConfigs from 'general/constants/AppConfigs';
import Global from 'general/utils/Global';
import useAccount from 'hooks/useAccount';
import useConfig from 'hooks/useConfig';
import { t } from 'i18next';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import VIDragAndDropV2 from '../VIDragAndDropV2';
import VILoading from '../VILoading';
import VIPagination from '../VIPagination';

VIDataTable.propTypes = {
  fullColumns: PropTypes.array,
  data: PropTypes.array,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  selectable: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  toolbar: PropTypes.element,
  filterbar: PropTypes.element,
  modalEditItem: PropTypes.element,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onSetSelectedMultiItems: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  inputSearch: PropTypes.element,
  defaultColumns: PropTypes.array,
  additionalClassName: PropTypes.string,
  tabsActive: PropTypes.string,
  scrollHeight: PropTypes.string,
  draggable: PropTypes.bool,
  haveCardHeader: PropTypes.bool,
  minusHeight: PropTypes.number,
  additionalCardBodyClassName: PropTypes.string,
  emptyComponent: PropTypes.element,
  loadingComponent: PropTypes.element,
  expandable: PropTypes.bool,
  expandedComponent: PropTypes.func,
  tableStyles: PropTypes.object,
  clearSelectedRows: PropTypes.bool,
  totalPreTaxMoney: PropTypes.number,
  totalTaxAmount: PropTypes.number,
  totalValue: PropTypes.number,
  maxHeightDrag: PropTypes.string,
  keyDefaultColumn: PropTypes.string,
};

VIDataTable.defaultProps = {
  fullColumns: [],
  data: [],
  pagination: null,
  loading: false,
  selectable: false,
  title: '',
  toolbar: null,
  filterbar: null,
  modalEditItem: null,
  onChangePage: null,
  onChangeRowsPerPage: null,
  onSetSelectedMultiItems: null,
  onRowClick: null,
  onRowDoubleClick: null,
  inputSearch: null,
  defaultColumns: [],
  additionalClassName: '',
  tabsActive: '',
  scrollHeight: '',
  draggable: true,
  haveCardHeader: true,
  minusHeight: 0,
  additionalCardBodyClassName: '',
  emptyComponent: null,
  loadingComponent: null,
  expandable: false,
  expandedComponent: null,
  tableStyles: customDataTableStyle,
  clearSelectedRows: false,
  totalPreTaxMoney: null,
  totalTaxAmount: null,
  totalValue: null,
  maxHeightDrag: '',
  keyDefaultColumn: '',
};

function VIDataTable(props) {
  // MARK --- Parmas ---
  const {
    fullColumns,
    data,
    pagination,
    loading,
    selectable,
    title,
    toolbar,
    filterbar,
    modalEditItem,
    onChangePage,
    onChangeRowsPerPage,
    onSetSelectedMultiItems,
    onRowClick,
    onRowDoubleClick,
    inputSearch,
    defaultColumns,
    additionalClassName,
    tabsActive,
    scrollHeight,
    draggable,
    haveCardHeader,
    minusHeight,
    additionalCardBodyClassName,
    emptyComponent,
    loadingComponent,
    expandable,
    expandedComponent,
    tableStyles,
    clearSelectedRows,
    totalPreTaxMoney,
    totalTaxAmount,
    totalValue,
    maxHeightDrag,
    keyDefaultColumn,
    maxheight,
  } = props;
  const cardHeader = useRef(null);
  const refPaginations = useRef(null);
  const [tableMaxHeight, setTableMaxHeight] = useState('55vh');
  const [dynamicColumns, setDynamicColumns] = useState(
    defaultColumns.length > 0 ? defaultColumns : fullColumns
  );
  const [toggleClearItems, setToggledClearItems] = useState(false);
  const [selectedMultiItems, setSelectedMultiItems] = useState([]);
  const selectedRow = useRef([]);
  const bannerHeight = useConfig().systemNotification.bannerHeight;
  const [showingColumns, setShowingColumns] = useState(dynamicColumns);
  const account = useAccount();

  useEffect(() => {
    // setDynamicColumns(fullColumns);
    if (!!defaultColumns.length) {
      setDynamicColumns(defaultColumns);
    } else {
      setDynamicColumns(fullColumns);
    }
  }, [fullColumns, defaultColumns]);

  useEffect(() => {
    setDynamicColumns(
      showingColumns?.map((item) => {
        return fullColumns?.find((co) => co?.name === item.name);
      })
    );
  }, [showingColumns]);

  const columns = useMemo(() => {
    return dynamicColumns;
  }, [dynamicColumns]);
  const columnsFull = useMemo(() => {
    return JSON.parse(localStorage.getItem(`${keyDefaultColumn}_element`))?.[
      account.accountId
    ]?.concat(fullColumns[fullColumns.length - 1]);
  }, [JSON.parse(localStorage.getItem(`${keyDefaultColumn}_element`))?.[account.accountId]]);

  const dragAndDropElement = useMemo(() => {
    let defaultElements = defaultColumns?.length > 0 ? defaultColumns : fullColumns;
    return (
      <div id="dropdown-dragAndDrop">
        <VIDragAndDropV2
          maxHeightDrag={maxHeightDrag}
          elements={columnsFull?.length > 0 ? columnsFull : fullColumns}
          onSetDefault={() => {
            localStorage.setItem(
              keyDefaultColumn,
              JSON.stringify({
                ...JSON.parse(localStorage.getItem(keyDefaultColumn)),
                [account.accountId]: Global[keyDefaultColumn],
              })
            );
            setShowingColumns(
              fullColumns.filter((item) => Global[keyDefaultColumn]?.includes(item.name))
            );
            localStorage.setItem(
              `${keyDefaultColumn}_element`,
              JSON.stringify({
                ...JSON.parse(localStorage.getItem(`${keyDefaultColumn}_element`)),
                [account.accountId]: fullColumns,
              })
            );
          }}
          defaultElements={defaultElements}
          keyDefaultColumn={keyDefaultColumn}
          onSetUpColumn={(columns) => {
            localStorage.setItem(
              keyDefaultColumn,
              JSON.stringify({
                ...JSON.parse(localStorage.getItem(keyDefaultColumn)),
                [account.accountId]: columns?.map((e) => e?.name),
              })
            );

            setShowingColumns(columns);
          }}
        />
      </div>
    );
  }, [
    defaultColumns,
    columns,
    fullColumns,
    JSON.parse(localStorage.getItem(`${keyDefaultColumn}_element`))?.[account.accountId],
  ]);

  // MARK --- Functions ---

  function handleChangePage(newValue) {
    if (onChangePage) {
      onChangePage(newValue);
    }
  }

  function handleChangeRowPerPage(newValue) {
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(newValue);
    }
  }

  const [dataRowCheck, setDataRowCheck] = useState([]);
  function handleSelectedItemChanged(state) {
    setSelectedMultiItems(state.selectedRows);
    setDataRowCheck(state?.selectedRows);
    selectedRow.current = state?.selectedRows;
  }

  function handleClickRow(row) {
    if (onRowClick) {
      onRowClick(row);
    }
  }

  function handleDoubleClickRow(row) {
    if (onRowDoubleClick) {
      onRowDoubleClick(row);
    }
  }

  // MARK --- Hooks ---

  useEffect(() => {
    if (onSetSelectedMultiItems) {
      onSetSelectedMultiItems(selectedMultiItems);
    }
  }, [selectedMultiItems]);

  // fix header
  function resizeScreen() {
    // debugger;
    if (cardHeader?.current?.clientHeight && refPaginations?.current?.clientHeight) {
      setTableMaxHeight(
        `${
          window.innerHeight -
          cardHeader?.current?.clientHeight -
          refPaginations?.current?.clientHeight -
          minusHeight -
          150 -
          scrollHeight -
          bannerHeight
        }px`
      );
    } else if (cardHeader?.current?.clientHeight && !refPaginations?.current?.clientHeight) {
      setTableMaxHeight(
        `${
          window.innerHeight -
          cardHeader?.current?.clientHeight -
          minusHeight -
          150 -
          scrollHeight -
          bannerHeight
        }px`
      );
    } else if (!cardHeader?.current?.clientHeight && refPaginations?.current?.clientHeight) {
      setTableMaxHeight(
        `${
          window.innerHeight -
          refPaginations?.current?.clientHeight -
          minusHeight -
          150 -
          scrollHeight -
          bannerHeight
        }px`
      );
    } else {
      setTableMaxHeight(
        `${
          window.innerHeight -
          minusHeight -
          208 -
          scrollHeight -
          bannerHeight +
          (AppConfigs.isHideFooter ? 65 : 0)
        }px`
      );
    }
  }

  window.onresize = resizeScreen;

  useEffect(() => {
    resizeScreen();
  }, [window.innerWidth, window.innerHeight, minusHeight]);

  return (
    <div className="h-100">
      <div
        className={`card card-custom card-stretch ${additionalClassName}`}
        style={{ borderRadius: '20px' }}
      >
        {/* card header */}
        {haveCardHeader && (
          <div className="card-header border-0 pt-4 pb-0" ref={cardHeader}>
            <div className="w-100 d-flex justify-content-between flex-wrap">
              <div className="d-flex align-items-center" style={{ minWidth: '40%' }}>
                {inputSearch && inputSearch}
                {!_.isEmpty(title) && (
                  <div
                    className={`font-size-h3 card-label mb-0 font-weight-bolder mt-2 ${
                      isNull(toolbar) ? 'mb-2' : ''
                    }`}
                  >
                    {title}
                  </div>
                )}
              </div>

              {/* header toolbar */}
              <div className="card-toolbar d-flex flex-wrap gap-3 align-items-center">
                {toolbar}
                {tabsActive !== 'Hóa đơn đã xóa' && draggable ? (
                  <div style={{ display: 'flex', justifyContent: 'end' }} id={'vi-dropDrag'}>
                    {dragAndDropElement}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {filterbar && (
              <div className="d-flex flex-wrap gap-3 w-100 align-items-center">{filterbar}</div>
            )}
          </div>
        )}

        {/* card body */}
        <div className={`card-body d-flex flex-column pt-3 pb-4 ${additionalCardBodyClassName}`}>
          <DataTable
            className="flex-grow-1"
            fixedHeader
            fixedHeaderScrollHeight={maxheight ? maxheight : tableMaxHeight}
            columns={columns}
            data={data}
            customStyles={
              !!tableStyles
                ? tableStyles
                : !selectable
                ? customDataTableStyle
                : customDataTableSelectableStyle
            }
            responsive={true}
            noHeader
            selectableRows={selectable}
            // selectableRowSelected={(row) => console.log(row)}
            // selectableRowSelected={
            //   (row) => console.log(row)
            //   // selectedRow?.current.some((item) => item?.companyTitleId === row.companyTitleId)
            // }
            striped
            noDataComponent={
              emptyComponent ?? (
                <EmptyView message={t('NoDataToDisplay')} tableMaxHeight={tableMaxHeight} />
              )
            }
            progressPending={loading}
            progressComponent={loadingComponent ?? <VILoading tableMaxHeight={tableMaxHeight} />}
            onSelectedRowsChange={handleSelectedItemChanged}
            clearSelectedRows={clearSelectedRows ? clearSelectedRows : toggleClearItems}
            onRowClicked={(row) => {
              handleClickRow(row);
            }}
            onRowDoubleClicked={(row) => {
              handleDoubleClickRow(row);
            }}
            pointerOnHover
            highlightOnHover
            selectableRowsHighlight
            expandableRows={expandable}
            expandableRowExpanded={(row) => true}
            expandableRowsComponent={expandedComponent}
            expandableIcon={{
              expanded: <i className="fa-regular fa-square-minus"></i>,
              collapsed: <i className="fa-regular fa-square-plus"></i>,
            }}
          />

          {/* Pagination */}
          {pagination && (
            <div
              ref={refPaginations}
              className="d-flex align-items-center justify-content-center mt-3"
            >
              <VIPagination
                totalPreTaxMoney={totalPreTaxMoney}
                totalTaxAmount={totalTaxAmount}
                totalValue={totalValue}
                rowsPerPage={pagination?.perPage}
                rowCount={pagination?.total ?? 0}
                currentPage={pagination?.currentPage}
                displayRows={pagination?.count}
                onChangePage={(newPage) => {
                  let iNewPage = parseInt(newPage);
                  handleChangePage(iNewPage);
                }}
                onChangeRowsPerPage={(newPerPage) => {
                  const iNewPerPage = parseInt(newPerPage);
                  handleChangeRowPerPage(iNewPerPage);
                }}
              />
            </div>
          )}
        </div>
      </div>

      {/* Modal edit item */}
      {modalEditItem}
    </div>
  );
}

export default VIDataTable;
