import SearchBarNoFormik from 'general/components/SearchBarNoFormik';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './style.scss';

KeenSelectOption.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldProps: PropTypes.object,
  fieldMeta: PropTypes.object,
  fieldHelpers: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  options: PropTypes.array,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  onValueChanged: PropTypes.func,
  searchable: PropTypes.bool,
  footerEl: PropTypes.element,
  menuClassName: PropTypes.string,
  showItemDelete: PropTypes.bool,
  autoClose: PropTypes.bool,
  onDeleteItem: PropTypes.func,
};

KeenSelectOption.defaultProps = {
  label: '',
  initialValue: '',
  disabled: false,
  text: '',
  options: [],
  containerClassName: 'form-group row',
  labelClassName: 'col-xl-2 col-lg-3',
  selectClassName: 'col-lg-2',
  onValueChanged: null,
  searchable: false,
  footerEl: null,
  menuClassName: 'w-100',
  showItemDelete: false,
  autoClose: true,
  onDeleteItem: null,
};

function KeenSelectOption(props) {
  // MARK: --- Params ---
  const {
    fieldProps,
    initialValue,
    fieldMeta,
    fieldHelpers,
    name,
    label,
    disabled,
    text,
    options,
    containerClassName,
    labelClassName,
    selectClassName,
    onValueChanged,
    searchable,
    footerEl,
    menuClassName,
    showItemDelete,
    autoClose,
    onDeleteItem,
  } = props;
  const value = fieldProps?.value ?? initialValue;
  // console.log('value', value);
  const { t } = useTranslation();
  const [dropdownValue, setDropdownValue] = useState(t('NoChoose'));
  const [searchText, setSearchText] = useState('');
  const refOptionMenu = useMemo(() => {
    let filteredOptions = options;
    if (searchable) {
      filteredOptions = _.filter(options, (item) => {
        const itemName = Utils.removeVietnameseTones(item?.name).toLowerCase();
        return itemName?.includes(Utils.removeVietnameseTones(searchText).toLowerCase());
      });
    }
    return (
      <>
        {filteredOptions.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              value={item.value}
              onClick={() => {
                setDropdownValue(item.text ?? item.name);
                handleOptionChanged(item.value);
              }}
              className={`d-flex flex-row align-items-center border-top border-light ${
                index === 0 ? 'border-top-0' : ''
              }`}
            >
              {item?.avatar && (
                <div
                  className={`w-40px h-40px rounded-circle overflow-hidden shadow border ${
                    item.value === value ? 'border-primary' : 'border-light'
                  } mr-2`}
                >
                  <img
                    className="w-100 h-100"
                    style={{
                      objectFit: 'cover',
                    }}
                    src={Utils.getFullUrl(item?.avatar)}
                  />
                </div>
              )}
              <div className="d-flex align-items-center justify-content-between flex-grow-1">
                <span
                  style={{
                    whiteSpace: 'normal',
                  }}
                  className={`${item.value === value ? 'text-primary' : ''}`}
                >
                  {t(item.text ?? item.name)}
                </span>
                {item.value === value && <i className="far fa-check text-primary ml-4"></i>}
              </div>
            </Dropdown.Item>
          );
        })}
      </>
    );
  }, [options, searchText, searchable]);

  const showError = fieldMeta?.touched && fieldMeta?.error;
  const sError = fieldMeta?.error;

  const [autoCloseMode, setAutoCloseMode] = useState(false);

  useEffect(() => {
    setAutoCloseMode(autoClose);
  }, [autoClose]);

  useEffect(() => {
    // console.log({ initialValue, options });
    setDropdownValue(_.find(options, (item) => item.value === initialValue)?.name ?? t('NoChoose'));
  }, [initialValue, options]);

  // MARK: --- Functions ---
  function handleOptionChanged(e) {
    fieldHelpers?.setValue(e);
    if (onValueChanged) {
      onValueChanged(e);
    }
  }

  useEffect(() => {
    if (value === '') {
      setDropdownValue(t('NoChoose'));
    } else {
      setDropdownValue(
        (options.find((item) => item.value === value)?.text ??
          options.find((item) => item.value === value)?.name) ||
          dropdownValue
      );
    }
  }, [value]);

  return (
    <div className={`KeenSelectOption ${containerClassName}`}>
      {label && (
        <label
          htmlFor={name}
          className={`col-form-label text-left text-xl-right ${labelClassName}`}
        >
          {label}
        </label>
      )}
      <div className={`${selectClassName}`}>
        <Dropdown autoClose={autoCloseMode}>
          <Dropdown.Toggle
            id={name}
            disabled={disabled}
            className={`w-100 cursor-pointer border shadow-none d-flex align-items-center justify-content-between ${
              showError ? 'form-invalid' : ''
            }`}
            style={{
              borderRadius: '12px',
            }}
            multiple={fieldProps?.multiple}
            value={value}
            variant=""
          >
            {t(`${dropdownValue}`)}
          </Dropdown.Toggle>

          <Dropdown.Menu className={`${menuClassName} overflow-hidden`}>
            {searchable && (
              <SearchBarNoFormik
                name={`${name}_searchbar`}
                value={searchText}
                placeholder={`${t('TypeToSearch')}...`}
                onSubmit={({ searchTerm }) => setSearchText(searchTerm)}
                containerStyle="mr-0 w-100 mx-4 my-2"
              />
            )}
            <div className="overflow-auto max-h-300px">
              {searchable
                ? refOptionMenu
                : options.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        value={item.value}
                        onClick={() => {
                          setDropdownValue(item.text ?? item.name);
                          handleOptionChanged(item.value);
                          setAutoCloseMode(true);
                        }}
                        className={`d-flex flex-row align-items-center justify-content-between border-bottom border-light w-100 ${
                          index === options?.length - 1 && !footerEl ? 'border-bottom-0' : ''
                        }`}
                      >
                        <span
                          style={{
                            whiteSpace: 'normal',
                          }}
                          className={`${item.value === value ? 'text-primary' : ''}`}
                        >
                          {t(item.text ?? item.name)}
                        </span>

                        <div className="d-flex align-items-center">
                          {item.value === value && (
                            <i className="fa-regular fa-check text-primary"></i>
                          )}
                          {showItemDelete && (
                            <i
                              className="fa-regular fa-trash text-danger cursor-pointer ml-4"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (onDeleteItem) {
                                  onDeleteItem(item.value);
                                }
                              }}
                            />
                          )}
                        </div>
                      </Dropdown.Item>
                    );
                  })}
            </div>
            {footerEl && footerEl}
          </Dropdown.Menu>
        </Dropdown>
        {text.length > 0 && <span className="form-text text-muted">{text}</span>}
        {showError && sError?.length > 0 && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{sError}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default KeenSelectOption;
