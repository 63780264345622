import React from 'react';
import PropTypes from 'prop-types';
import AppResource from 'general/constants/AppResource';
import VIButton from 'general/components/VietInvoice/VIButton';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import { useTranslation } from 'react-i18next';
import MailHelper from 'general/helpers/MailHelper';
import { filesize } from 'filesize';

MailAttachmentView.propTypes = {
  item: PropTypes.object,
  onPress: PropTypes.func,
};

MailAttachmentView.defaultProps = {
  item: null,
  onPress: null,
};

/**
 *
 * @param {{
 * item: object,
 * onPress: function,
 * }} props
 * @returns
 */
function MailAttachmentView(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { item, onPress } = props;

  // ---- methods ----
  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  return (
    <div
      className="rounded p-4 cursor-pointer d-flex align-items-center gap-2 w-100"
      onClick={handlePress}
      style={{
        border: '1px solid #dbe3ef',
      }}
    >
      <i
        className={`fa-regular ${MailHelper.getMailAttachmentIcon(item?.contentType)} me-1`}
        style={{
          fontSize: '2rem',
          color: MailHelper.getMailAttachmentIconColor(item?.contentType),
        }}
      ></i>
      <div className="flex-grow-1">
        <p
          className="text-grey font-weight-bolder mb-0"
          style={{
            wordBreak: 'break-word',
          }}
        >
          {item?.filename}
        </p>
        <div className="text-start text-grey font-weight-normal">
          {filesize(item?.size ?? 0, { base: 2, standard: 'jedec' })}
        </div>
      </div>
      <KTTooltip text={t('Download')}>
        <i className="fa-regular text-grey fa-download ms-3" />
      </KTTooltip>

      {/* <VIButton
        text={
          <div>
            <div className="text-start text-grey font-weight-bolder">{item?.filename}</div>
            <div className="text-start text-grey font-weight-normal">
              {filesize(item?.size ?? 0, { base: 2, standard: 'jedec' })}
            </div>
          </div>
        }
        className="btn-white py-2"
        beforeIcon={
          <i
            className={`fa-regular ${MailHelper.getMailAttachmentIcon(item?.contentType)} me-1`}
            style={{
              fontSize: '2rem',
              color: MailHelper.getMailAttachmentIconColor(item?.contentType),
            }}
          ></i>
        }
        afterIcon={
          <KTTooltip text={t('Download')}>
            <i className="fa-regular fa-download ms-3" />
          </KTTooltip>
        }
        onClick={handlePress}
      /> */}
    </div>
  );
}

export default MailAttachmentView;
