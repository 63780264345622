import { AppDialogHelper } from 'general/components/AppDialog';
import KTAdvanceNav, {
  KTAdvanceNavFontWeight,
} from 'general/components/OtherKeenComponents/KTAdvanceNav';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import AccountHelper from 'general/helpers/AccountHelper';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useRouter from 'hooks/useRouter';
import { useTranslation } from 'react-i18next';

DropdownHeaderAccountInfo.propTypes = {};

function DropdownHeaderAccountInfo(props) {
  // ----- Params -----
  const { t } = useTranslation();
  const router = useRouter();
  const account = useAccount();

  // ----- Methods ------
  function handleSignOut() {
    AppDialogHelper.show(
      t('SignOut'),
      t('ConfirmSignOutText'),
      [
        {
          title: t('Cancel'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
        {
          title: t('Yes'),
          type: 'negative',
          onClick: () => {
            AppDialogHelper.hide();
            AccountHelper.signOut();
            router.navigate('/');
          },
        },
      ],
      false,
      AppResource.icons.icSignOut
    );
  }

  return (
    <div className="min-w-260px">
      <div className="card-custom">
        <div className="card-body px-2 pt-4 pb-2">
          <div className="text-center">
            <div className="symbol symbol-90 symbol-circle symbol-xl-90 bg-danger position-relative border overflow-hidden">
              <div
                className="symbol-label"
                style={{ backgroundImage: `url('${account.avatar}')` }}
              ></div>
              {!_.isEmpty(account.company.serviceName) && (
                <div className="bg-danger-o-90 py-2 position-absolute bottom-0 w-100 font-size-sm font-weight-boldest text-white">
                  <span>{account.company.serviceName}</span>
                </div>
              )}
            </div>
            <h4 className="font-weight-bolder my-2">{account.displayName}</h4>
            <div className="">
              <span className="font-italic">Email: </span>
              <span className="font-weight-bolder">{account.email}</span>
            </div>
            <div className="">
              <span className="font-italic">SĐT: </span>
              <span className="font-weight-bolder">{account.phone}</span>
            </div>
            <div className="mb-2">
              <span className="font-italic">MST công ty: </span>
              <span className="font-weight-bolder">{account.company.taxCode}</span>
            </div>

            {/* thong tin tai nguyen */}
            <div
              className="px-4 py-3"
              style={{
                backgroundColor: '#f8f8f8',
                borderRadius: '12px',
              }}
            >
              <p className="m-0">
                {t('ExpiryDateToDate')}
                {': '}
                <span className="text-primary font-weight-bold">
                  {account.company.resourceDateExpired}
                </span>
              </p>
              <p className="m-0">
                {t('RemainingInvoices')}
                {': '}
                <span className="text-primary font-weight-bold">
                  {Utils.formatNumber(account.company.resourceTotalUnusedInvoice)}
                </span>
              </p>
            </div>
          </div>
          <div className="separator separator-dashed mt-4 mb-2"></div>
          {/* nav */}
          <KTAdvanceNav
            enableHoverStyle
            collapseEnable={false}
            enableLinkRounded={true}
            fontWeight={KTAdvanceNavFontWeight.bold}
            itemList={[
              {
                text: t('PersonalInfo'),
                iconElement: <i className="fa-regular fa-user-gear" />,
                onPress: () => {
                  router.navigate('/inbot/account/personal-info');
                },
              },
              {
                text: t('ProtectAccount'),
                iconElement: <i className="fa-regular fa-shield-check" />,
                onPress: () => {
                  router.navigate('/inbot/account/setup-security');
                },
              },
              {
                text: t('PrivacyPolicy'),
                iconElement: <i className="fa-regular fa-newspaper" />,
                onPress: () => {
                  router.navigate('/inbot/account/privacy-policy');
                },
              },
              {
                text: t('ChangePassword'),
                iconElement: <i className="fa-regular fa-key" />,
                onPress: () => {
                  router.navigate('/inbot/account/change-password');
                },
              },
              {
                text: t('PaymentHistory'),
                iconElement: <i className="fa-regular fa-clock-rotate-left" />,
                onPress: () => {
                  router.navigate('/inbot/account/payment-history');
                },
              },
              // {
              //   text: t('Voucher'),
              //   iconElement: <i className="fa-regular fa-gift" />,
              //   onPress: () => {
              //     router.navigate('/inbot/account/voucher');
              //   },
              // },
              // {
              //   text: t('Language'),
              //   iconElement: <img src={AppResource.icons.keens.flagEn} className="h-20px" />,
              //   showArrow: true,
              // },
            ]}
          />
        </div>

        <div className="separator separator-dashed mb-2"></div>

        <div className="card-footer px-4 pb-4 pt-1 border-0">
          <VIButton
            beforeIcon={<i className="fa-regular fa-arrow-right-from-bracket" />}
            text={t('SignOut')}
            className="btn-red mt-2 p-3 w-100"
            onClick={handleSignOut}
          />
        </div>
      </div>
    </div>
  );
}

export default DropdownHeaderAccountInfo;
