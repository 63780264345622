import VIInputField from 'general/components/VietInvoice/VIInputField';
import React from 'react';

function UnitInformation() {
  return (
    <div>
      <div className="row mt-5">
        <VIInputField className="col-6 w-100 mb-4" label="Mã đơn vị" placeholder="Nhập mã đơn vị" />
        <VIInputField
          className="col-6 w-100 mb-4"
          label="Mã số thuế"
          placeholder="0106870211"
          disabled={true}
        />
      </div>
      <VIInputField
        className="w-100 mb-4"
        label="Tên đơn vị"
        placeholder="CÔNG TY CỔ PHẦN ICORP"
        disabled={true}
      />
      <VIInputField
        className="w-100 mb-4"
        label="Loại hóa đơn"
        disabled={true}
        placeholder="Số 32/21 phố Trương Công Giai, Phường Dịch Vọng, Quận Cầu Giấy, Thành phố Hà Nội, Việt Nam"
      />
    </div>
  );
}

export default UnitInformation;
