import { incrementTotalUnreadMail, setOnline } from 'features/Auth/authSlice';
import AppConfigs from 'general/constants/AppConfigs';
import Global from 'general/utils/Global';
import AccountHelper from './AccountHelper';
import AxionHelper from './AxionHelper';
import CategoryHelper from './CategoryHelper';
import NotificationHelper from './NotificationHelper';
import SupplierHelper from './SupplierHelper';
import ToastHelper from './ToastHelper';
import MailHelper from './MailHelper';
import { setForceReloadInvoice, thunkGetAllInInvoice } from 'features/Invoice/invoiceSlice';

const sTag = '[WebsocketHelper]';
var W3CWebSocket = require('websocket').w3cwebsocket;
let store;
const wsUrl = AppConfigs.wsUrl;

const wsCode = {
  LOG_IN: '00',
  LOG_OUT: '01',
  NEW_NOTIFICATION: '600',
  SYNC_SUCCESS: '03',
};

class WebsocketHelper {
  // MARK: --- Params ---
  wsClient = null;

  // MARK: --- Functions ---
  // constructor
  constructor() {
    this.initWebsocket();
  }

  initWebsocket() {
    if (wsUrl && wsUrl.length > 0) {
      if (this.wsClient) {
        // this.wsClient.disconnect();
      }

      this.wsClient = new W3CWebSocket(wsUrl, '');

      // connect error
      this.wsClient.onerror = () => {
        console.log(`${sTag} connect error`);
        store?.dispatch(setOnline(false));
        // do reconnect
        // this.autoReconnect();
      };

      // connect success !!
      this.wsClient.onopen = () => {
        console.log(`${sTag} websocket client connected`);

        // dang nhap lai
        if (AccountHelper.checkAccessTokenValid()) {
          const token = AccountHelper.getAccessToken();
          this.loginByToken(token);
        }
      };

      // connect closed
      this.wsClient.onclose = () => {
        console.log(`${sTag} echo-protocol client closed`);
        store?.dispatch(setOnline(false));
        // do reconnect
        this.autoReconnect();
      };

      // received message
      this.wsClient.onmessage = (e) => {
        const data = e.data;
        if (typeof data === 'string') {
          this.processReceivedMessage(data);
        }
      };
    } else {
      console.log(`${sTag} websocket url invalid: ${wsUrl}`);
    }
  }

  // auto reconnect websocket after 5 seconds
  autoReconnect() {
    setTimeout(() => {
      this.initWebsocket();
    }, 5000);
  }

  // ---- methods ------
  // dang nhap ws
  loginByToken(token) {
    if (this.wsClient?.readyState === 1) {
      const data = {
        code: wsCode.LOG_IN,
        accessToken: token,
      };
      this.wsClient.send(JSON.stringify(data));
    }
  }

  // dang xuat ws
  logout() {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: wsCode.LOG_OUT,
      };
      this.wsClient.send(JSON.stringify(data));
    }
  }

  // MARK: --- Utils functions ---
  processReceivedMessage(data) {
    console.log(`${sTag} received: ${data}`);
    try {
      const jsonData = JSON.parse(data);
      if (jsonData) {
        const { code, result } = jsonData;
        const isSuccess = result === 'success';
        if (isSuccess) {
          switch (code) {
            // dang nhap
            case wsCode.LOG_IN:
              store?.dispatch(setOnline(true));
              const { email, message } = jsonData;
              // ToastHelper.showSuccess(message);
              break;
            // dang xuat
            case wsCode.LOG_OUT:
              store?.dispatch(setOnline(false));
              break;
            // thong bao moi
            case wsCode.NEW_NOTIFICATION:
              ToastHelper.showSuccess('Bạn có thông báo mới');
              // load lai danh sach thong bao
              NotificationHelper.callApiGetNotificationList(Global.gFiltersNotificationList);
              break;
            // co ket qua dong bo
            case wsCode.SYNC_SUCCESS:
              const targetCode = jsonData.targetCode;
              if (targetCode === 'PARTNER_SYNC') {
                // kiem tra danh muc (ncc/khach hang) hoan tat
                ToastHelper.showSuccess('Quá trình kiểm tra nhà cung cấp/khách hàng đã hoàn tất.');
                // load lai danh sach ncc/khach hang
                CategoryHelper.callApiGetSupplierCustomerList(Global.gFiltersSupplierAndCustomer);
              }
              if (targetCode === 'SUPPLIER_SYNC') {
                // dong bo hoa don NCC hoan tat
                ToastHelper.showSuccess('Quá trình đồng bộ hoá đơn nhà cung cấp đã hoàn tất.');
                // load lai danh sach hoa don ncc
                SupplierHelper.callApiGetConnectionSupplierHistory(
                  Global.gFiltersConnectionSupplierHistory
                );
              }
              if (targetCode === 'CQT_SYNC') {
                // dong bo hoa don cqt hoan tat
                ToastHelper.showSuccess('Quá trình đồng bộ hoá đơn với cơ quan thuế đã hoàn tất.');
                // load lai danh sach hoa don cqt
                AxionHelper.callApiGetConnectionAxionHistory(
                  'IN',
                  Global.gFiltersAxionInputInvoice
                );
                AxionHelper.callApiGetConnectionAxionHistory(
                  'OUT',
                  Global.gFiltersAxionOutputInvoice
                );
              }
              if (targetCode === 'NEW_MAIL') {
                // co mail moi => reload inbox mail
                ToastHelper.showSuccess('Bạn có thư mới');
                MailHelper.callApiGetInboxMailList(Global.gFiltersInboxMailList);
                store?.dispatch(incrementTotalUnreadMail());
              }
              if (targetCode === 'VALIDATE_MAIL') {
                ToastHelper.showSuccess('Kiểm tra mail hoàn tất');
                // kiem tra mail thanh cong
                MailHelper.callApiGetInboxMailList(Global.gFiltersInboxMailList);
                MailHelper.callApiGetImportantMailList(Global.gFiltersImportantMailList);
              }
              if (targetCode == 'VALIDATE_INVOICE') {
                store.dispatch(thunkGetAllInInvoice(Global.gFiltersInputInvoice));
                store.dispatch(setForceReloadInvoice());
              }
              break;
            default:
              break;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}

// prevents modification to properties and values of an object
const wsHelperInstance = new WebsocketHelper();

// Object.freeze(wsHelperInstance);

// inject store
export const websocketHelperInjectStore = (_store) => {
  // console.log(`${sTag} inject store`);
  store = _store;
};

// export
export default wsHelperInstance;
