import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

CellAxionStat.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showBorder: PropTypes.bool,
  selected: PropTypes.bool,
  interactable: PropTypes.bool,
  onPress: PropTypes.func,
  icon: PropTypes.element,
};

CellAxionStat.defaultProps = {
  text: '',
  active: true,
  value: '',
  showBorder: false,
  selected: false,
  interactable: false,
  onPress: null,
  icon: null,
};

/**
 *
 * @param {{
 * text: string,
 * active: boolean,
 * value: string|number,
 * showBorder: boolean,
 * selected: boolean,
 * interactable: boolean,
 * onPress: function,
 * icon: element,
 * }} props
 * @returns
 */
function CellAxionStat(props) {
  // ---- params -----
  const { t } = useTranslation();
  const { text, active, value, showBorder, selected, interactable, onPress, icon } = props;

  // ---- methods ----
  function handlePress() {
    if (interactable && onPress) {
      onPress();
    }
  }

  return (
    <div
      style={{
        boxShadow: '0px 8px 24px 0px #252a5b0d',
        border: showBorder ? `1px solid ${selected ? '#5180FB' : '#EBEDF3'}` : '',
        backgroundColor: interactable ? 'bg-white' : '#F2F3F7',
      }}
      className={`p-6 rounded-lg d-flex align-items-center ${interactable ? 'cursor-pointer' : ''}`}
      onClick={handlePress}
    >
      <div className="symbol symbol-70 symbol-light mr-4">
        <span className="font-size-h4">{icon}</span>
      </div>
      <div className="flex-grow-1">
        <p
          className="font-weight-bolder mb-0"
          style={{
            fontSize: '2rem',
          }}
        >
          {value}
        </p>
        <p className="mb-0 font-size-lg">{text}</p>
        <div className="mt-1 d-flex align-items-center">
          <div
            className="w-10px h-10px rounded-circle mr-2"
            style={{
              backgroundColor: active ? '#28A043' : '#B5B5C3',
            }}
          ></div>
          <p
            className="mb-0"
            style={{
              color: active ? '#28A043' : '#3f4254',
            }}
          >
            {active ? t('Active') : t('NotConnect')}
          </p>
        </div>
      </div>

      {selected ? (
        <div>
          <i className="fa-regular fa-circle-check fa-2x text-primary" />
        </div>
      ) : interactable ? (
        <div>
          <i className="fa-regular fa-circle-check fa-2x text-light-secondary" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default CellAxionStat;
