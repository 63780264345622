import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import EmptyView from 'features/Others/components/EmptyView';
import CellSupplierConnect from 'features/Supplier/components/CellSupplierConnect';
import ModalContentSupplierConnect from 'features/Supplier/components/ModalContentSupplierConnect';
import ViewChooseDateRangeSync from 'features/Supplier/components/ViewChooseDateRangeSync';
import ViewConnectionSupplierHistoryDetail from 'features/Supplier/components/ViewConnectionSupplierHistoryDetail';
import ViewModalListAccount from 'features/Supplier/components/ViewModalListAccount';
import { thunkGetAllSuppliers } from 'features/Supplier/supplierSlice';
import { AppDialogHelper } from 'general/components/AppDialog';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIModal from 'general/components/VietInvoice/VIModal';
import VIOverViewBar from 'general/components/VietInvoice/VIOverViewBar';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import SupplierHelper from 'general/helpers/SupplierHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useSupplier from 'hooks/useSupplier';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

function SupplierConnectScreen(props) {
  // ------ Params -----
  const { t } = useTranslation();
  const account = useAccount();
  const tabItemList = useMemo(() => {
    return [
      {
        title: t('Supplier'),
        status: 'active',
      },
      {
        title: t('Connected'),
        status: '',
      },
    ];
  }, [t]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [modalConnectShowing, setModalConnectShowing] = useState(false);
  const {
    supplierInvoiceList,
    supplierServiceList,
    connectionSupplierList,
    connectedSupplierList,
    groupedConnectionSuppliers,
    history,
  } = useSupplier();
  const [currentSelectedSupplierItem, setCurrentSelectedSupplierItem] = useState();
  const dispatch = useDispatch();
  const [modalChooseDateRangeSyncShowing, setModalChooseDateRangeSyncShowing] = useState(false);
  const [currentSelectedSupplierConnectedItem, setCurrentSelectedSupplierConnectedItem] =
    useState();
  const [modalListAccountShowing, setModalListAccountShowing] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const refShowModalListAccountAgain = useRef(false);
  const [filters, setFilters] = useState(Global.gFiltersConnectionSupplierHistory);
  const [
    modalConnectionSupplierHistoryDetailShowing,
    setModalConnectionSupplierHistoryDetailShowing,
  ] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState(null);

  const columns = useMemo(
    () => [
      {
        name: t('TakeInvoiceDate'),
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.syncDate ? Utils.formatDateTime(row?.syncDate, 'DD/MM/YYYY') : ''}
            </p>
          );
        },
      },
      {
        name: t('DownloadSucceed'),
        minWidth: '160px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.succeededDownload ?? 0)}
            </p>
          );
        },
      },
      {
        name: t('DownloadFailed'),
        minWidth: '120px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.failedDownload ?? 0)}
            </p>
          );
        },
      },
      {
        name: t('InvoiceProcessed'),
        minWidth: '120px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.processed ?? 0)}
            </p>
          );
        },
      },
      {
        name: t('DuplicatedInvoice'),
        minWidth: '160px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.duplicated ?? 0)}
            </p>
          );
        },
      },
      {
        name: t('InvoiceProcessing'),
        minWidth: '200px',
        right: true,
        style: {},
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.processing ?? 0)}
            </p>
          );
        },
      },
      {
        name: t('InvoiceSkipped'),
        minWidth: '160px',
        right: true,
        style: {
          borderRight: 'none',
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.malformed ?? 0)}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedfe',
        },
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            <VITableRowDropDown
              toggleElement={
                <div>
                  <i className="text-grey fas fa-ellipsis-h p-0"></i>
                </div>
              }
              menuItem={[
                {
                  iconElement: <i className="fa-regular fa-circle-info"></i>,
                  text: t('Detail'),
                  click: () => {
                    setSelectedHistory(row);
                    setModalConnectionSupplierHistoryDetailShowing(true);
                  },
                },
                {
                  iconElement: <i className="fa-regular fa-rotate-right"></i>,
                  text: t('ReDownload'),
                  click: () => {
                    handleReloadConnectionHistory(row);
                  },
                },
              ]}
            />
          </div>
        ),
      },
    ],
    [t]
  );

  useEffect(() => {
    if (_.isEmpty(connectionSupplierList)) {
      SupplierHelper.callApiGetAllConnectionSuppliers();
    }
  }, []);
  const connectionList = useMemo(() => {
    return groupedConnectionSuppliers[currentSelectedSupplierItem?.supplierId];
  }, [currentSelectedSupplierItem, groupedConnectionSuppliers]);

  useEffect(() => {
    SupplierHelper.callApiGetConnectionSupplierHistory(filters);
  }, [filters]);

  useEffect(() => {
    if (connectedSupplierList?.length > 0) {
      setSelectedTabIndex(1);
    }
  }, [connectedSupplierList]);

  // ----- Methods ------
  const handleChangeTabIndex = (newIndex) => {
    setSelectedTabIndex(newIndex);
  };

  async function handleSyncSupplier(row) {
    const params = {
      supplierId: row?.supplierId,
      from: Global.gFiltersConnectionSupplierHistory.from,
      to: Global.gFiltersConnectionSupplierHistory.to,
    };
    AppDialogHelper.show(
      t('SyncInvoice'),
      t('SyncInvoiceText'),
      [
        {
          title: t('Close'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
            setCurrentSelectedSupplierConnectedItem(null);
          },
        },
      ],
      false,
      AppResource.images.imgSyncInvoice,
      true
    );
    setTimeout(() => {
      AppDialogHelper.hide();
      setCurrentSelectedSupplierConnectedItem(null);
    }, 3000);
    const res = await SupplierHelper.callApiSyncSupplier(params);
    if (res) {
      // goi api lay lich su dong bo hoa don
    }
  }

  function handleReconnectSupplier(row) {
    const supplierId = row?.supplierId;
    const connection = groupedConnectionSuppliers[supplierId][0];
    setSelectedConnection(connection);
    setCurrentSelectedSupplierItem(row);
    setModalConnectShowing(true);
  }

  function handleDisconnectSupplier(supplierId) {
    const connectionList = groupedConnectionSuppliers[supplierId];
    const connectionIds = connectionList?.map((item) => item.connectionSupplierId);
    const connectionNames = connectionList?.map((item) => item?.usernameConnection);
    AppDialogHelper.show(
      t('DisconnectConnection'),
      t('DisconnectConnectText', { name: connectionNames.join(', ') }),
      [
        {
          title: t('Cancel'),
          onClick: () => {
            AppDialogHelper.hide();
          },
          type: 'neutral',
        },
        {
          title: t('Disconnect'),
          onClick: async () => {
            console.log({ connectionIds });
            const res = await SupplierHelper.callApiDisconnectSupplier(connectionIds);
            if (res) {
              ToastHelper.showSuccess(t('DisconnectSupplierSuccess'));
              SupplierHelper.callApiGetAllConnectionSuppliers();
              AppDialogHelper.hide();
            }
          },
          type: 'positive',
        },
      ],
      false,
      AppResource.icons.icDisconnect
    );
  }

  function handleDisconnectConnection(row) {
    setModalListAccountShowing(false);
    const connectionIds = [row?.connectionSupplierId];
    AppDialogHelper.show(
      t('DisconnectConnection'),
      t('DisconnectConnectText', { name: row?.usernameConnection }),
      [
        {
          title: t('Cancel'),
          onClick: () => {
            AppDialogHelper.hide();
            setModalListAccountShowing(true);
          },
          type: 'neutral',
        },
        {
          title: t('Disconnect'),
          onClick: async () => {
            const res = await SupplierHelper.callApiDisconnectSupplier(connectionIds);
            if (res) {
              ToastHelper.showSuccess(t('DisconnectSupplierSuccess'));
              SupplierHelper.callApiGetAllConnectionSuppliers();
              AppDialogHelper.hide();
              setModalListAccountShowing(true);
            }
          },
          type: 'positive',
        },
      ]
    );
  }

  function handleReconnectConnection(row) {
    refShowModalListAccountAgain.current = true;
    setSelectedConnection(row);
    setModalConnectShowing(true);
    setModalListAccountShowing(false);
  }

  function handleAddAccount() {
    setModalListAccountShowing(false);
    setModalConnectShowing(true);
  }

  function handleListAccounts(row) {
    setCurrentSelectedSupplierItem(row);
    setModalListAccountShowing(true);
  }

  function handleAddMoreAccount(row) {
    setCurrentSelectedSupplierItem(row);
    setModalConnectShowing(true);
  }

  async function handleReloadConnectionHistory(row) {
    const id = row?.connectionSupplierHistoryId;
    if (id) {
      AppDialogHelper.show(t('Reload'), 'Hệ thống đang xử lý và sẽ trả về kết quả sau ít phút.', [
        {
          title: t('Close'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
      ]);
      const res = await SupplierHelper.callApiReload(id);
      if (res) {
      }
    }
  }

  return (
    <div className="d-flex flex-column min-h-100">
      <VIOverViewBar
        itemTab={tabItemList}
        currentSelect={selectedTabIndex}
        handleClick={handleChangeTabIndex}
      />

      {selectedTabIndex === 0 ? (
        <>
          {supplierInvoiceList?.length > 0 || supplierServiceList?.length > 0 ? (
            // danh sach nha cung cap
            <div className="gutter-t">
              {/* nha cung cap hoa don dien tu */}
              {supplierInvoiceList?.length > 0 && (
                <div className="">
                  <h6 className="card-label text-grey font-weight-bolder mb-4">
                    {t('EInvoiceProvider')}
                  </h6>
                  <div className="row">
                    {supplierInvoiceList.map((item, index) => {
                      const isConnected = connectedSupplierList
                        .map((i) => i.supplierId)
                        ?.includes(item.supplierId);
                      return (
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-8" key={index}>
                          <CellSupplierConnect
                            className="h-100"
                            imageUrl={item.logo}
                            showLabelConnected={isConnected}
                            label={item.description}
                            buttonText={t('Connect')}
                            onClick={
                              account.checkPermission(AppData.permissionCode.tuDongKetNoi.nccHoaDon)
                                ? () => {
                                    setCurrentSelectedSupplierItem(item);
                                    setModalConnectShowing(true);
                                  }
                                : null
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* nha cung cap hang hoa dich vu */}
              {supplierServiceList?.length > 0 && (
                <div className="gutter-t">
                  <h6 className="card-label text-grey font-weight-bolder mb-4">
                    {t('ServiceProvider')}
                  </h6>
                  <div className="row">
                    {supplierServiceList.map((item, index) => {
                      const isConnected = connectedSupplierList
                        .map((i) => i.supplierId)
                        ?.includes(item.supplierId);
                      return (
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-8" key={index}>
                          <CellSupplierConnect
                            className="h-100"
                            showLabelConnected={isConnected}
                            imageUrl={item.logo}
                            label={item.description}
                            buttonText={t('Connect')}
                            onClick={
                              account.checkPermission(AppData.permissionCode.tuDongKetNoi.nccHoaDon)
                                ? () => {
                                    setModalConnectShowing(true);
                                  }
                                : null
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* <div className="">
            <HelpComponentView
              interval={3000}
              data={[
                {
                  title: 'Thiết lập kết nối',
                  text: 'Thực hiện đăng nhập để ICORP Invoice kết nối trược tiếp dữ liệu hóa đơn của nhà cung cấp',
                  image: 'https://i.pinimg.com/564x/c0/aa/f5/c0aaf53fc0f258e9939d773de6c4d660.jpg',
                },
                {
                  title: 'Title 2',
                  text: 'Thực hiện đăng nhập để ICORP Invoice kết nối trược tiếp dữ liệu hóa đơn của nhà cung cấp',
                  image: 'https://i.pinimg.com/564x/8c/3b/9f/8c3b9f6e95e3aa6550a2ca8a647b5d47.jpg',
                },
                {
                  title: 'Title 3',
                  text: 'content 3',
                  image: 'https://i.pinimg.com/564x/aa/ce/db/aacedb76015dc0afade0c37fa5d7dc97.jpg',
                },
              ]}
            />
          </div> */}
            </div>
          ) : (
            <div className="gutter-t d-flex flex-column flex-grow-1 align-items-center justify-content-center">
              <EmptyView
                image={AppResource.images.imgEmptyConnect}
                message={t('NoDataToDisplay')}
                buttonText={t('Refresh')}
                onPressButton={() => {
                  dispatch(thunkGetAllSuppliers());
                }}
              />
            </div>
          )}
        </>
      ) : (
        // da ket noi
        <>
          {connectedSupplierList.length === 0 ? (
            <div className="gutter-t d-flex flex-column flex-grow-1 align-items-center justify-content-center">
              <EmptyView
                title={t('NotConnectionYet')}
                message={t('NotConnectionYetMessage')}
                image={AppResource.images.imgEmptyConnect}
                buttonText={t('ConnectNow')}
                onPressButton={() => {
                  setSelectedTabIndex(0);
                }}
              />
            </div>
          ) : (
            <div className="">
              <div className="gutter-t">
                {/* <h6 className="card-title mb-4 text-grey font-weight-bolder">
                  {t('ServiceProvider')}
                </h6> */}
                <div className="row">
                  {connectedSupplierList.map((item, index) => {
                    return (
                      <div className="col-xl-4 col-lg-6 col-md-6 mb-8" key={index}>
                        <CellSupplierConnect
                          className="h-100"
                          connectionList={groupedConnectionSuppliers[item?.supplierId]}
                          imageUrl={item?.logo}
                          label={item?.description}
                          buttonText={t('SyncNow')}
                          onClick={() => {
                            setCurrentSelectedSupplierConnectedItem(item);
                            setModalChooseDateRangeSyncShowing(true);
                          }}
                          connected
                          onPressAction={(action) => {
                            if (action === 'reconnect') {
                              handleReconnectSupplier(item);
                            } else if (action === 'disconnect') {
                              handleDisconnectSupplier(item?.supplierId);
                            } else if (action === 'addMoreAccount') {
                              handleAddMoreAccount(item);
                            } else if (action === 'listAccounts') {
                              handleListAccounts(item);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>

                {/* lich su nhan hoa don */}
                <div>
                  <VIDataTable
                    title={t('InvoiceReceiptHistory')}
                    additionalCardBodyClassName="px-5"
                    tableStyles={customDataTableActionStyle}
                    minusHeight={95}
                    draggable={false}
                    pagination={{
                      perPage: filters.limit,
                      total: history.total,
                      currentPage: filters.page + 1,
                      count: history.items?.length + filters.limit * filters.page,
                    }}
                    onChangePage={(page) => {
                      let iNewPage = parseInt(page);
                      iNewPage -= 1;
                      if (iNewPage < 0) {
                        iNewPage = 0;
                      }
                      Global.gFiltersConnectionSupplierHistory = {
                        ...Global.gFiltersConnectionSupplierHistory,
                        page: iNewPage,
                      };
                      setFilters(Global.gFiltersConnectionSupplierHistory);
                    }}
                    onChangeRowsPerPage={(perPage) => {
                      Global.gFiltersConnectionSupplierHistory = {
                        ...Global.gFiltersConnectionSupplierHistory,
                        limit: perPage,
                        page: 0,
                      };
                      setFilters(Global.gFiltersConnectionSupplierHistory);
                    }}
                    fullColumns={columns}
                    data={history.items}
                    loading={false}
                    onRowClick={(row) => {
                      setSelectedHistory(row);
                      setModalConnectionSupplierHistoryDetailShowing(true);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {/* modal connect supplier */}
      <VIModal
        titleModal={t('SetupConnection')}
        show={modalConnectShowing}
        closeBtn
        onHide={() => {
          setModalConnectShowing(false);
          setSelectedConnection(null);
          if (refShowModalListAccountAgain.current) {
            setModalListAccountShowing(true);
            refShowModalListAccountAgain.current = false;
          }
        }}
        modalSize="lg"
        contentModal={
          <ModalContentSupplierConnect
            item={currentSelectedSupplierItem}
            onPressCancel={() => {
              setModalConnectShowing(false);
              if (refShowModalListAccountAgain.current) {
                setModalListAccountShowing(true);
                refShowModalListAccountAgain.current = false;
              }
            }}
            initialConnection={selectedConnection}
          />
        }
      />

      {/* modal choose date range sync */}
      <VIModal
        titleModal={t('SyncInvoice')}
        show={modalChooseDateRangeSyncShowing}
        contentModal={<ViewChooseDateRangeSync />}
        closeBtn
        handleSubmit={() => {
          handleSyncSupplier(currentSelectedSupplierConnectedItem);
          setModalChooseDateRangeSyncShowing(false);
        }}
        onHide={() => {
          setModalChooseDateRangeSyncShowing(false);
          setCurrentSelectedSupplierConnectedItem(null);
        }}
        buttonNegative={t('Close')}
        buttonPositive={t('Sync')}
      />

      {/* modal list account */}
      <VIModal
        titleModal={t('ListAccountsConnected')}
        show={modalListAccountShowing}
        contentModal={
          <ViewModalListAccount
            connectionList={connectionList}
            onDisconnect={(row) => {
              handleDisconnectConnection(row);
            }}
            onReconnect={(row) => {
              handleReconnectConnection(row);
            }}
            onAddAccount={handleAddAccount}
          />
        }
        closeBtn
        modalSize="lg"
        handleSubmit={() => {
          // handleSyncSupplier(currentSelectedSupplierConnectedItem);
          // setModalChooseDateRangeSyncShowing(false);
        }}
        onHide={() => {
          setModalListAccountShowing(false);
          setCurrentSelectedSupplierItem(null);
        }}
        // buttonNegative={t('Close')}
        // buttonPositive={t('Sync')}
      />

      {/* modal connection supplier history detail */}
      <VIModal
        titleModal={t('InvoiceList')}
        show={modalConnectionSupplierHistoryDetailShowing}
        contentModal={
          <ViewConnectionSupplierHistoryDetail
            item={selectedHistory}
            onPressCancel={() => {
              setModalConnectionSupplierHistoryDetailShowing(false);
              setSelectedHistory(null);
            }}
          />
        }
        closeBtn
        modalSize="xl"
        centered={false}
        handleSubmit={() => {}}
        onHide={() => {
          setModalConnectionSupplierHistoryDetailShowing(false);
          setSelectedHistory(null);
        }}
      />
    </div>
  );
}

export default SupplierConnectScreen;
