import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIInputField from 'general/components/VietInvoice/VIInputField';
import Utils from 'general/utils/Utils';

import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

function ModalAccountingDate(props) {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <label className="text-lg-right text-grey font-weight-bolder">
          {`${t('ChooseAnAccountingDate')}`}
          <sup className="font-weight-boldest ms-1" style={{ color: '#F44141' }}>
            (*)
          </sup>
        </label>
        <KTFormGroup
          additionalClassName=" mb-3"
          inputName="dob"
          inputElement={
            <div>
              <KTFormInput
                name={'accountingDate'}
                value={Utils.formatDateTime(formik.values.accountingDate, 'YYYY-MM-DD')}
                onChange={(value) => {
                  formik.setFieldValue('accountingDate', Utils.formatAddDateTime(value));
                }}
                onBlur={() => {
                  formik.setFieldTouched('accountingDate', true);
                }}
                enableCheckValid
                type={KTFormInputType.date}
                placeholder={`${_.capitalize(t('ChooseAnAccountingDate'))}`}
                enableInputGroup={true}
                prependElements={[<i className="fa-regular fa-calendar-clock"></i>]}
              />
            </div>
          }
        />
        <VIInputField
          className="w-100 mb-3"
          label={t('NumberVouchers')}
          placeholder={t('EnterTheVoucherNumber')}
          value={formik.values.noDocument}
          fieldHelper={formik.getFieldHelpers('noDocument')}
          fieldProps={formik.getFieldProps('noDocument')}
          fieldMeta={formik.getFieldMeta('noDocument')}
        />
        <VIInputField
          className="w-100 mb-3"
          label={t('Accountants')}
          placeholder={t('EnterTheAccountant')}
          value={formik.values.accountingPersonName}
          fieldHelper={formik.getFieldHelpers('accountingPersonName')}
          fieldProps={formik.getFieldProps('accountingPersonName')}
          fieldMeta={formik.getFieldMeta('accountingPersonName')}
        />
      </div>
    </div>
  );
}

export default ModalAccountingDate;
