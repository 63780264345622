import useOnClickOutside from 'hooks/useOnClickOutside';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const KTTooltipPositions = {
  autoStart: 'auto-start',
  auto: 'auto',
  autoEnd: 'auto-end',
  topStart: 'top-start',
  top: 'top',
  topEnd: 'top-end',
  rightStart: 'right-start',
  right: 'right',
  rightEnd: 'right-end',
  bottomEnd: 'bottom-end',
  bottom: 'bottom',
  bottomStart: 'bottom-start',
  leftEnd: 'left-end',
  left: 'left',
  leftStart: 'left-start',
};

KTTooltip.propTypes = {
  position: PropTypes.oneOf(Object.values(KTTooltipPositions)),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  clickMode: PropTypes.bool,
  initialShow: PropTypes.bool,
  className: PropTypes.string,
};

KTTooltip.defaultProps = {
  position: KTTooltipPositions.top,
  text: '',
  clickMode: false,
  initialShow: false,
  className: '',
};

/**
 *
 * @param {{position: string, text: string|element, clickMode: boolean, initialShow: boolean}} props
 * @returns
 */
function KTTooltip(props) {
  // MARK: --- Params ---
  const { position, text, clickMode, initialShow, className } = props;
  const [show, setShow] = useState(false);
  const refTooltipBody = useRef();

  useEffect(() => {
    setShow(initialShow);
  }, [initialShow]);

  useOnClickOutside(refTooltipBody, () => {
    if (show) {
      setShow(false);
    }
  });

  return clickMode ? (
    <div>
      {!!text ? (
        <OverlayTrigger
          show={show}
          placement={position}
          overlay={
            <Tooltip>
              <div ref={refTooltipBody}>{text}</div>
            </Tooltip>
          }
        >
          <div
            className=""
            onClick={() => {
              setShow(!show);
            }}
          >
            {props.children}
          </div>
        </OverlayTrigger>
      ) : (
        <div
          className=""
          onClick={() => {
            setShow(!show);
          }}
        >
          {props.children}
        </div>
      )}
    </div>
  ) : (
    <div className={className}>
      {!!text ? (
        <OverlayTrigger placement={position} overlay={<Tooltip>{text}</Tooltip>}>
          {props.children}
        </OverlayTrigger>
      ) : (
        <>{props.children}</>
      )}
    </div>
  );
}

export default KTTooltip;
