import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import './style.scss';
import Utils from 'general/utils/Utils';
import VIButton from '../VIButton';
import { useTranslation } from 'react-i18next';
import VIFormCheck from '../VIFormCheck';
import VISearchField from '../VISearchField';

// duc tu
VIDragAndDrop.propTypes = {
  elements: PropTypes.array,
  lengthChecked: PropTypes.array,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  onHide: PropTypes.func,
};

VIDragAndDrop.defaultProps = {
  elements: [],
  lengthChecked: [],
  handleChange: () => {},
  handleSubmit: () => {},
  onHide: () => {},
};

/**
 * @param {array} element dragDrop element
 * @param {function} handleChange Handle when click checkbox is checked
 * @param {function} handleSubmit Handle when click button save
 * @param {boolean} onHide handle close dropdown
 * @param {boolean} lengthChecked element is checked
 */

function VIDragAndDrop(props) {
  const { elements, lengthChecked, handleChange, setCheck, handleSubmit, onHide } = props;

  const [items, setItems] = useState(elements);
  // const [items, setItems] = useState(
  //   localStorage.getItem('dataDropDrag') === null || localStorage.getItem('dataDropDrag') === ''
  //     ? elements
  //     : JSON.parse(localStorage.getItem('dataDropDrag'))
  // );
  useEffect(() => {
    // if (mathReset > 0) {
    setItems(elements);
    // }
  }, []);
  const onDragEnd = (result) => {
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItems(newItems);
  };
  // useEffect(() => {
  //   localStorage.setItem('dataDropDrag', JSON.stringify(items));
  //   setCheck(items);
  // }, [items]);
  const handleSearch = (e) => {
    setItems(
      elements.filter((x) =>
        Utils.removeAccents(x?.name).toLowerCase().includes(Utils.removeAccents(e).toLowerCase())
      )
    );
  };
  const { t } = useTranslation();

  return (
    <div className="AppDropDrag">
      <div className="title-appDrop ps-3">{t('ColumnSetting')}</div>
      <div className="position-relative px-3">
        <VISearchField
          iconPosition="right"
          typingTimeout={500}
          className="w-100 mb-3"
          onSubmit={handleSearch}
          name=""
        />
      </div>
      <div className="mt-3 filter-drop-drag px-3" style={{ maxHeight: '45vh', overflow: 'auto' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items?.map((item, index) => (
                  <Draggable key={item.name} draggableId={item.name} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className="snapshot-drag mb-1"
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="d-flex justify-content-between pe-5 border">
                          <div className="modal-filter-item-left">
                            <div className="d-flex align-items-center">
                              <i
                                className="fas fa-bars py-2 px-1 border-right"
                                style={{ background: '#FCFCFC' }}
                              ></i>
                              <VIFormCheck
                                name={item?.name}
                                id={item?.name}
                                handleChange={handleChange}
                                type="checkbox"
                                className="ps-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="d-flex justify-content-between w-100 px-3 border-top pb-2 pt-3">
        <div className="w-50">
          <VIButton
            beforeIcon={<i className="fas fa-redo"></i>}
            text={t('ResetToDefault')}
            className="btn-grey me-5"
            style={{ padding: '8px' }}
            afterIcon={<></>}
          />
        </div>
        <div className="d-flex justify-content-end w-50">
          <VIButton
            text={t('Cancel')}
            className="btn-grey me-2"
            style={{ padding: '8px' }}
            onClick={() => onHide()}
          />
          <VIButton
            text={t('Save')}
            className=""
            style={{ padding: '8px' }}
            onClick={() => {
              handleSubmit(), onHide();
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default VIDragAndDrop;
