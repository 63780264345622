import axiosClient from './axiosClient';

const dashboardApi = {
  // thong ke tong quan hoa don
  getInvoiceStatistic: (params) => {
    const url = '/dashboard/analyze';
    return axiosClient.get(url, { params });
  },

  // thong ke tong quan hoa don theo thoi gian
  getInvoiceStatisticByTime: (params) => {
    const url = '/dashboard/analyze-day';
    return axiosClient.get(url, { params });
  },

  // thong ke gia tri hop dong
  getInvoiceValue: (params) => {
    const url = '/dashboard/analyze-total-value';
    return axiosClient.get(url, { params });
  },

  // bao cao
  getInvoiceReport: (params) => {
    const url = '/dashboard/report-total-invoice';
    return axiosClient.get(url, { params });
  },
};

export default dashboardApi;
