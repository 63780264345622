import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmptyView from '../EmptyView';
import AppResource from 'general/constants/AppResource';

ReminderView.propTypes = {};

function ReminderView(props) {
  // ---- params ----
  const { t } = useTranslation();

  return (
    <div className="px-6 py-6 d-flex flex-column h-100">
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="card-label font-weight-bolder text-grey m-0">{t('Reminder')}</h3>
          <i
            className="far fa-times px-3 py-2 rounded cursor-pointer button-close"
            id="closeOffcanvasReminderId"
          ></i>
        </div>
        <div className="separator separator-dashed my-5"></div>
      </div>

      <div className="flex-grow-1">
        <EmptyView message={t('ReminderListEmpty')} image={AppResource.images.imgEmptyNotFound} />
      </div>
    </div>
  );
}

export default ReminderView;
