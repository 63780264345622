import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import Utils from 'general/utils/Utils';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

ViewListInvoiceNote.propTypes = {
  item: PropTypes.array,
  onPressCancel: PropTypes.func,
  setDataNoteReplace: PropTypes.func,
};

ViewListInvoiceNote.defaultProps = {
  item: [],
  onPressCancel: null,
  setDataNoteReplace: null,
};

/**
 *
 * @param {{
 * item: array,
 * onPressCancel: function,
 * }} props
 * @returns
 */
function ViewListInvoiceNote(props) {
  // ---- params ----
  const { item, onPressCancel, setDataNoteReplace } = props;
  const { t } = useTranslation();
  const router = useRouter();
  const columns = useMemo(
    () => [
      {
        name: t('InvoiceNumber'),
        minWidth: '150px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceNumber ? row?.invoiceNumber : ''}
            </p>
          );
        },
      },
      {
        name: t('InvoiceDate'),
        width: '150px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceDate ? Utils.formatDateTime(row?.invoiceDate, 'DD/MM/yyyy' ?? '') : ''}
            </p>
          );
        },
      },
      {
        name: t('Supplier'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0 text-maxline-3">
              {row?.sellerName}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        width: '150px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.sellerTaxCode ? row?.sellerTaxCode : ''}
            </p>
          );
        },
      },
      {
        name: t('TotalAmount'),
        minWidth: '130px',
        right: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 text-align-end"
            >
              {row?.finalAmount ? Utils.formatCurrency(row?.finalAmount) : row?.finalAmount}
            </p>
          );
        },
      },
      {
        name: t('Note'),
        minWidth: '170px',
        style: { borderRight: 'none' },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.noteInvoice ? row?.noteInvoice : ''}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '50px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedf3',
        },
        cell: (row, index) => (
          <div className="d-flex align-items-center justify-content-between gap-3 px-3">
            <KTTooltip text={t('ViewDetail')}>
              <i
                className="fa-regular fa-eye text-primary"
                onClick={() => {
                  router.navigate(`/inbot/invoice/detail/${row?.invoiceId}`);
                }}
              />
            </KTTooltip>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap overflow-auto"
        style={{
          maxHeight: '70vh',
        }}
      >
        <div className="d-flex align-items-center gap-3 flex-wrap" style={{ fontStyle: 'italic' }}>
          Có {item?.length} đã tồn tại ghi chú. Vui lòng chọn hóa đơn bạn muốn thay thế ghi chú
        </div>
      </div>
      <div className="mt-4">
        <VIDataTable
          haveCardHeader={false}
          additionalCardBodyClassName="p-0"
          additionalClassName="shadow-none rounded-0"
          tableStyles={customDataTableActionStyle}
          minusHeight={95}
          draggable={false}
          selectable={true}
          fullColumns={columns}
          data={item}
          onRowClick={(row) => {}}
          onSetSelectedMultiItems={(rows) => {
            setDataNoteReplace(rows);
          }}
        />
      </div>
    </div>
  );
}

export default ViewListInvoiceNote;
