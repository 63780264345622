import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppData from 'general/constants/AppData';

SuggestionHintView.propTypes = {};

function SuggestionHintView(props) {
  // ---- params ----
  const { t } = useTranslation();

  return (
    <div className="p-6 d-flex flex-column align-items-start">
      <h4 className="font-weight-bold m-0 mb-4">
        {t('SuggestionsAndRecommendations')}{' '}
        <i className="fa-solid fa-lightbulb text-success fa-2x ml-2" />
      </h4>
      <p className="font-size-lg">Kết nối cơ quan thuế bị thiếu hóa đơn?</p>
      <ul
        className="m-0 p-0 font-size-normal w-100"
        style={{
          listStylePosition: 'inside',
        }}
      >
        {AppData.suggestionAndRecommendationItems.map((item, index) => {
          return (
            <li
              className={`text-left ${
                index < AppData.suggestionAndRecommendationItems.length - 1 ? 'mb-2' : 'mb-0'
              }`}
              key={index}
            >
              {item.text} <a href={item.url}>{t('SeeTheInstructions')}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default SuggestionHintView;
