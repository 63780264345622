import axiosClient from './axiosClient';

const supplierApi = {
  // lay danh sach tat ca ncc
  getAll: () => {
    const url = '/supplier/find';
    return axiosClient.get(url);
  },

  // ket noi ncc
  connect: (params) => {
    const url = '/connection-supplier/connect';
    return axiosClient.post(url, params);
  },

  // cap nhat
  update: (id, params) => {
    const url = `/connection-supplier/update/${id}`;
    return axiosClient.put(url, params);
  },

  // thuc hien dong bo
  synchronize: (params) => {
    const url = '/connection-supplier/synchronize';
    return axiosClient.post(url, params);
  },

  // ngat ket noi
  disconnect: (ids) => {
    const url = '/connection-supplier/delete';
    return axiosClient.delete(url, {
      data: {
        connectionSupplierIds: ids,
      },
    });
  },

  // danh sach tat ca tai khoan dang ket noi
  getAllConnectionSuppliers: () => {
    const url = '/connection-supplier/find';
    return axiosClient.get(url);
  },

  // lay lich su dong bo
  getConnectionSupplierHistory: (params) => {
    const url = '/connection-supplier-history/find';
    return axiosClient.get(url, { params });
  },

  // lay chi tiet lich su
  getConnectionSupplierHistoryDetail: (id, params) => {
    const url = `/connection-supplier-history/detail/${id}`;
    return axiosClient.get(url, { params });
  },

  // reload
  reload: (id) => {
    const url = '/connection-supplier-history/reload';
    return axiosClient.post(url, {
      connectionSupplierHistoryId: id,
    });
  },
};

export default supplierApi;
