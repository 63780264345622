import ViewAddSupplier from 'features/System/components/ViewAddSupplier';
import ViewChooseInvoiceDownloadType from 'features/System/components/ViewChooseInvoiceDownloadType';
import ViewChooseOrganization from 'features/System/components/ViewChooseOrganization';
import ViewChooseOrganizationByBuyerTaxCode from 'features/System/components/ViewChooseOrganizationByBuyerTaxCode';
import ViewChooseSupplier from 'features/System/components/ViewChooseSupplier';
import { FastField, Formik } from 'formik';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import KTCheckbox, {
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIModal from 'general/components/VietInvoice/VIModal';
import InvoiceHelper from 'general/helpers/InvoiceHelper';
import OrganizationHelper from 'general/helpers/OrganizationHelper';
import Global from 'general/utils/Global';
import useAccount from 'hooks/useAccount';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';

SystemSettingScreen.propTypes = {
  navItemList: PropTypes.array,
};

SystemSettingScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function SystemSettingScreen(props) {
  // ---- params ----
  const { navItemList } = props;
  const { t } = useTranslation();
  const [contentHeight, setContentHeight] = useState('70vh');
  const account = useAccount();
  // console.log(account.organizationSetting);
  const [modalChooseInvoiceDownloadTypeShowing, setModalChooseInvoiceDownloadTypeShowing] =
    useState(false);
  const [selectedInvoiceDownloadTypes, setSelectedInvoiceDownloadTypes] = useState();
  const refInvoiceDownloadTypes = useRef();
  const refFormik = useRef();
  const [modalChooseOrganizationShowing, setModalChooseOrganizationShowing] = useState(false);
  const [modalChooseSupplierShowing, setModalChooseSupplierShowing] = useState(false);
  const [
    modalChooseOrganizationByBuyerTaxCodeShowing,
    setModalChooseOrganizationByBuyerTaxCodeShowing,
  ] = useState(false);
  const [modalAddSupplierShowing, setModalAddSupplierShowing] = useState(false);
  const { isUpdatingSetting, listSupplierNotDownloadInvoice, listAutoTransferByBuyerTaxCode } =
    useSelector((state) => state.organization);
  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);
  const refPrevModalChooseSupplierShowing = useRef(false);

  function resizeScreen() {
    setContentHeight(`${window.innerHeight - 141}px`);
  }

  window.onresize = resizeScreen;

  useEffect(() => {
    resizeScreen();
  }, [window.innerWidth, window.innerHeight]);

  return (
    <div className="">
      <Formik
        initialValues={{
          // nhac han thanh toan
          remindNotify: account.organizationSetting.remindNotify,
          remindBeforeDays: `${account.organizationSetting.remindBeforeDays}`,

          // thiet lap ten file tai xuong
          nameFileBuyerTaxCode: account.organizationSetting.nameFileBuyerTaxCode,
          nameFileSerial: account.organizationSetting.nameFileSerial,
          nameFileInvoiceDate: account.organizationSetting.nameFileInvoiceDate,
          nameFileSample: account.organizationSetting.nameFileSample,
          nameFileInvoiceNum: account.organizationSetting.nameFileInvoiceNum,
          nameFileSupplierName: account.organizationSetting.nameFileSupplierName,

          // thiet lap ket noi he thong co quan thue
          // hoa don dau ra
          downloadAllOutputInvoice: account.organizationSetting.downloadAllOutputInvoice, // Tải toàn bộ hóa đơn đầu ra (bao gồm hóa đơn phát hành bởi MISA meInvoice)
          downloadOutputInvoiceNotTable: account.organizationSetting.downloadOutputInvoiceNotTable, // Tải về hóa đơn không mã gửi theo hình thức bảng tổng hợp
          selectDownloadTypeOutputInvoice:
            account.organizationSetting.selectDownloadTypeOutputInvoice, // Chọn loại hóa đơn tải về
          // hoa don dau vao
          adjustNotDownloadWithSupplier: account.organizationSetting.adjustNotDownloadWithSupplier, // Thiết lập không tải về hóa đơn theo nhà cung cấp
          autoAdjustInvoice: account.organizationSetting.autoAdjustInvoice, // Tự động điều chuyển hóa đơn cho chi nhánh/phòng ban
          downloadInputInvoiceNotTable: account.organizationSetting.downloadInputInvoiceNotTable, // Tải về hóa đơn không mã gửi theo hình thức bảng tổng hợp
          selectDownloadTypeInputInvoice:
            account.organizationSetting.selectDownloadTypeInputInvoice, // Chọn loại hóa đơn tải về

          // thiet lap dieu chuyen hoa don tu nguon email
          autoAdjustWithBuyerTaxCode: account.organizationSetting.autoAdjustWithBuyerTaxCode, // Tự động điều chuyển theo mã số thuế người mua

          // thiet lap canh bao hoa don chua chinh xac ve tinh toan so lieu
          warningInListInvoice: account.organizationSetting.warningInListInvoice, // Cảnh báo trên danh sách hóa đơn
        }}
        validationSchema={Yup.object({
          remindBeforeDays: Yup.number().integer().min(1, 'Ngày không được nhỏ hơn 1'),
        })}
        enableReinitialize
        onSubmit={async (values) => {
          const params = { ...values };
          const res = await OrganizationHelper.callApiUpdateSetting(params);
          if (res) {
          }
        }}
      >
        {(formikProps) => {
          refFormik.current = formikProps;
          return (
            <div
              className="overflow-auto"
              style={{ maxHeight: contentHeight, borderRadius: '16px' }}
            >
              <div
                // ref={refCardHeader}
                className="px-7 py-4 border-bottom d-flex flex-wrap align-items-center justify-content-between gap-2 min-h-65px bg-white sticky-top"
                style={{
                  borderRadius: '16px',
                  zIndex: '10',
                }}
              >
                <div className="card-title align-items-start flex-column m-0">
                  <div className="d-flex align-items-center justify-content-center gap-1">
                    <div className="d-block d-lg-none">
                      <KTBSDropdown
                        toggleElement={
                          <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                            <i className="fa-solid fa-bars text-grey" />
                          </div>
                        }
                        alignment={KTBSDropdownAlignments.start}
                        dropdownMenuItems={dropdownMenuItems}
                        selectedValue={t('Optional')}
                        onChange={(newValue) => {}}
                      />
                    </div>
                    <h3 className="card-label font-weight-bolder text-grey m-0">{t('Setting')}</h3>
                  </div>
                </div>

                {/* toolbars */}
                <div className="card-toolbar">
                  <VIButton
                    className="btn-blue"
                    text={isUpdatingSetting ? `${t('Updating')}...` : t('Save')}
                    onClick={() => {
                      formikProps.handleSubmit();
                    }}
                    disabled={isUpdatingSetting}
                    beforeIcon={
                      isUpdatingSetting ? (
                        <ClockLoader color="white" size={20} />
                      ) : (
                        <i className="fa-regular fa-circle-check" />
                      )
                    }
                  />
                </div>
              </div>

              {/* nhac han thanh toan */}
              {/* <div className="card card-custom p-6 mt-6" style={{ borderRadius: '16px' }}>
                <h3 className="card-label text-grey font-weight-bolder mb-3">
                  {t('ReminderDuePayment')}
                </h3>
                <div className="d-flex align-items-start">
                  <FastField name="remindNotify">
                    {({ field, form, meta }) => (
                      <KTCheckbox
                        name={field.name}
                        label={t('Notification')}
                        type={KTCheckboxType.default}
                        checked={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        additionalClassName="switch-primary mr-4 mt-3"
                      />
                    )}
                  </FastField>

                  <FastField name="remindBeforeDays">
                    {({ field, form, meta }) => (
                      <div className="d-flex align-items-start">
                        <label className="mb-0 mr-2 mt-3" htmlFor={field.name}>
                          {t('ReminderBefore')}
                        </label>
                        <KTFormInput
                          name={field.name}
                          value={field.value}
                          enableInputGroup
                          appendElements={[t('Days')]}
                          onFocus={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          disabled={!formikProps.values.remindNotify}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.number}
                          placeholder={`${_.capitalize(t('TypeNumber'))}...`}
                        />
                      </div>
                    )}
                  </FastField>
                </div>
              </div> */}

              {/* thiet lap ten file tai xuong */}
              <div className="card card-custom p-6 mt-6" style={{ borderRadius: '16px' }}>
                <h3 className="card-label text-grey font-weight-bolder mb-3">
                  Thiết lập tên file tải xuống
                </h3>
                <p className="mb-3 font-size-lg font-weight-bold">Tên file tải xuống gồm:</p>
                <div className="row">
                  <div className="col-4">
                    <FastField name="nameFileBuyerTaxCode">
                      {({ field, form, meta }) => (
                        <KTCheckbox
                          name={field.name}
                          label="Mã số thuế người bán"
                          type={KTCheckboxType.default}
                          checked={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          additionalClassName="switch-primary mr-4"
                        />
                      )}
                    </FastField>
                    <div className="my-4">
                      <FastField name="nameFileSerial">
                        {({ field, form, meta }) => (
                          <KTCheckbox
                            name={field.name}
                            label="Ký hiệu"
                            type={KTCheckboxType.default}
                            checked={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            additionalClassName="switch-primary mr-4"
                          />
                        )}
                      </FastField>
                    </div>
                    <FastField name="nameFileInvoiceDate">
                      {({ field, form, meta }) => (
                        <KTCheckbox
                          name={field.name}
                          label="Ngày hóa đơn"
                          type={KTCheckboxType.default}
                          checked={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          additionalClassName="switch-primary mr-4"
                        />
                      )}
                    </FastField>
                  </div>
                  <div className="col-4">
                    <FastField name="nameFileSample">
                      {({ field, form, meta }) => (
                        <KTCheckbox
                          name={field.name}
                          label="Mẫu số"
                          type={KTCheckboxType.default}
                          checked={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          additionalClassName="switch-primary mr-4"
                        />
                      )}
                    </FastField>
                    <div className="my-4">
                      <FastField name="nameFileInvoiceNum">
                        {({ field, form, meta }) => (
                          <KTCheckbox
                            name={field.name}
                            label="Số hóa đơn"
                            type={KTCheckboxType.default}
                            disabled
                            checked={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                            additionalClassName="switch-primary mr-4"
                          />
                        )}
                      </FastField>
                    </div>
                    <FastField name="nameFileSupplierName">
                      {({ field, form, meta }) => (
                        <KTCheckbox
                          name={field.name}
                          label="Tên nhà cung cấp"
                          type={KTCheckboxType.default}
                          checked={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          additionalClassName="switch-primary mr-4"
                        />
                      )}
                    </FastField>
                  </div>

                  <div className="col-4">
                    <p className="mb-2">Ví dụ:</p>
                    <div>
                      <div className="mt-2 d-flex align-items-center">
                        <i className="fa-regular fa-memo text-primary mr-2" />
                        <span
                          className=""
                          style={{
                            lineBreak: 'anywhere',
                          }}
                        >
                          {InvoiceHelper.genSampleFileNameDownload(
                            formikProps.values.nameFileBuyerTaxCode,
                            formikProps.values.nameFileSerial,
                            formikProps.values.nameFileInvoiceDate,
                            formikProps.values.nameFileSample,
                            formikProps.values.nameFileInvoiceNum,
                            formikProps.values.nameFileSupplierName
                          )}
                          .xml
                        </span>
                      </div>
                      <div className="mt-2 d-flex align-items-center">
                        <i className="fa-regular fa-file-pdf text-danger mr-2" />
                        <span
                          className=""
                          style={{
                            lineBreak: 'anywhere',
                          }}
                        >
                          {InvoiceHelper.genSampleFileNameDownload(
                            formikProps.values.nameFileBuyerTaxCode,
                            formikProps.values.nameFileSerial,
                            formikProps.values.nameFileInvoiceDate,
                            formikProps.values.nameFileSample,
                            formikProps.values.nameFileInvoiceNum,
                            formikProps.values.nameFileSupplierName
                          )}
                          .pdf
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* thiet lap ket noi he thong co quan thue */}
              <div className="card card-custom p-6 mt-6" style={{ borderRadius: '16px' }}>
                <h3 className="card-label text-grey font-weight-bolder mb-3">
                  Thiết lập kết nối hệ thống cơ quan thuế
                </h3>
                <p className="mb-0 font-size-lg font-weight-bolder">Hóa đơn đầu ra</p>
                <div>
                  <div className="my-4">
                    <FastField name="downloadAllOutputInvoice">
                      {({ field, form, meta }) => (
                        <KTCheckbox
                          name={field.name}
                          label="Tải toàn bộ hóa đơn đầu ra (bao gồm hóa đơn phát hành bởi ICORP Invoice)"
                          type={KTCheckboxType.default}
                          checked={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          additionalClassName="switch-primary mr-4"
                        />
                      )}
                    </FastField>
                  </div>

                  <div className="mt-2">
                    <FastField name="downloadOutputInvoiceNotTable">
                      {({ field, form, meta }) => (
                        <KTCheckbox
                          name={field.name}
                          label="Tải về hóa đơn không mã gửi theo hình thức bảng tổng hợp"
                          type={KTCheckboxType.default}
                          checked={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          additionalClassName="switch-primary mr-4"
                        />
                      )}
                    </FastField>

                    <div className="mt-1">
                      Chọn loại hóa đơn tải về:{' '}
                      <a
                        href="#"
                        className=""
                        style={{
                          textDecoration: 'underline',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedInvoiceDownloadTypes(
                            formikProps.values.selectDownloadTypeOutputInvoice
                          );
                          refInvoiceDownloadTypes.current = 'OUT';
                          setModalChooseInvoiceDownloadTypeShowing(true);
                        }}
                      >
                        {t('Setup')}
                      </a>
                    </div>
                  </div>
                </div>
                <p className="mt-4 mb-0 font-size-lg font-weight-bolder">Hóa đơn đầu vào</p>
                <div>
                  {/* <div className="my-4 d-flex">
                    <FastField name="adjustNotDownloadWithSupplier">
                      {({ field, form, meta }) => (
                        <KTCheckbox
                          name={field.name}
                          label="Thiết lập không tải về hóa đơn theo nhà cung cấp"
                          type={KTCheckboxType.default}
                          checked={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          additionalClassName="switch-primary mr-2"
                        />
                      )}
                    </FastField>

                    {formikProps.values.adjustNotDownloadWithSupplier && (
                      <div className="">
                        <a
                          href="#"
                          className=""
                          style={{
                            textDecoration: 'underline',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setModalChooseSupplierShowing(true);
                            Global.gTempChoosingSupplierList = null;
                          }}
                        >
                          {t('ChooseSupplier')}
                        </a>

                        {listSupplierNotDownloadInvoice?.length > 0 && (
                          <span>
                            {' '}
                            ({`Đang chọn ${listSupplierNotDownloadInvoice?.length} nhà cung cấp`})
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="my-4 d-flex">
                    <FastField name="autoAdjustInvoice">
                      {({ field, form, meta }) => (
                        <KTCheckbox
                          name={field.name}
                          label="
                          Tự động điều chuyển hóa đơn cho chi nhánh/phòng ban"
                          type={KTCheckboxType.default}
                          checked={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          additionalClassName="switch-primary mr-2"
                        />
                      )}
                    </FastField>

                    {formikProps.values.autoAdjustInvoice && (
                      <div className="">
                        <a
                          href="#"
                          className=""
                          style={{
                            textDecoration: 'underline',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setModalChooseOrganizationShowing(true);
                            Global.gTempChoosingOrganizationList = null;
                            Global.gTempChoosingOrganization = null;
                          }}
                        >
                          {t('Setup')}
                        </a>
                      </div>
                    )}
                  </div> */}

                  <div className="mt-2">
                    <FastField name="downloadInputInvoiceNotTable">
                      {({ field, form, meta }) => (
                        <KTCheckbox
                          name={field.name}
                          label="Tải về hóa đơn không mã gửi theo hình thức bảng tổng hợp"
                          type={KTCheckboxType.default}
                          checked={field.value}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          additionalClassName="switch-primary mr-4"
                        />
                      )}
                    </FastField>

                    <div className="mt-1">
                      Chọn loại hóa đơn tải về:{' '}
                      <a
                        href="#"
                        className=""
                        style={{
                          textDecoration: 'underline',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedInvoiceDownloadTypes(
                            formikProps.values.selectDownloadTypeInputInvoice
                          );
                          refInvoiceDownloadTypes.current = 'IN';
                          setModalChooseInvoiceDownloadTypeShowing(true);
                        }}
                      >
                        {t('Setup')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* thiet lap dieu chuyen hoa don tu nguon email */}
              {/* <div className="card card-custom p-6 mt-6" style={{ borderRadius: '16px' }}>
                <h3 className="card-label text-grey font-weight-bolder mb-3">
                  Thiết lập điều chuyển hóa đơn từ nguồn email
                </h3>
                <div className="d-flex">
                  <FastField name="autoAdjustWithBuyerTaxCode">
                    {({ field, form, meta }) => (
                      <KTCheckbox
                        name={field.name}
                        label="Tự động điều chuyển theo mã số thuế người mua"
                        type={KTCheckboxType.default}
                        checked={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        additionalClassName="switch-primary mr-2"
                      />
                    )}
                  </FastField>
                  {formikProps.values.autoAdjustWithBuyerTaxCode && (
                    <div>
                      <a
                        href="#"
                        className=""
                        style={{
                          textDecoration: 'underline',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setModalChooseOrganizationByBuyerTaxCodeShowing(true);
                        }}
                      >
                        {t('ChooseBranchDepartment')}
                      </a>

                      {listAutoTransferByBuyerTaxCode?.length > 0 && (
                        <span>
                          {' '}
                          ({`Đang chọn ${listAutoTransferByBuyerTaxCode?.length} MST người mua`})
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div> */}

              {/* thiet lap canh bao hoa don chua chinh xac ve tinh toan so lieu */}
              <div className="card card-custom p-6 mt-6" style={{ borderRadius: '16px' }}>
                <h3 className="card-label text-grey font-weight-bolder mb-3">
                  Thiết lập cảnh báo hóa đơn chưa chính xác về tính toán số liệu
                </h3>
                <div>
                  <FastField name="warningInListInvoice">
                    {({ field, form, meta }) => (
                      <KTCheckbox
                        name={field.name}
                        label="Cảnh báo trên danh sách hóa đơn"
                        type={KTCheckboxType.default}
                        checked={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        additionalClassName="switch-primary mr-4"
                      />
                    )}
                  </FastField>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>

      {/* modal choose invoice download type */}
      <VIModal
        titleModal={t('ChooseInvoiceDownloadType')}
        // modalSize="lg"
        show={modalChooseInvoiceDownloadTypeShowing}
        contentModal={
          <ViewChooseInvoiceDownloadType
            invoiceDownloadTypes={selectedInvoiceDownloadTypes}
            onPressedCancel={() => {
              setModalChooseInvoiceDownloadTypeShowing(false);
            }}
            onSubmit={(invoiceDownloadTypes) => {
              if (refInvoiceDownloadTypes.current === 'OUT') {
                refFormik.current?.setFieldValue(
                  'selectDownloadTypeOutputInvoice',
                  invoiceDownloadTypes
                );
              }
              if (refInvoiceDownloadTypes.current === 'IN') {
                refFormik.current?.setFieldValue(
                  'selectDownloadTypeInputInvoice',
                  invoiceDownloadTypes
                );
              }
              refInvoiceDownloadTypes.current = null;
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalChooseInvoiceDownloadTypeShowing(false);
        }}
      />

      {/* modal chon ds dieu chuyen hoa don cho chi nhanh/phong ban */}
      <VIModal
        titleModal="Danh sách tự động điều chuyển hóa đơn cho chi nhánh/phòng ban"
        modalSize="xl"
        centered={false}
        show={modalChooseOrganizationShowing}
        contentModal={
          <ViewChooseOrganization
            onPressedCancel={() => {
              setModalChooseOrganizationShowing(false);
            }}
            onPressedAddSupplier={() => {
              refPrevModalChooseSupplierShowing.current = false;
              setModalChooseOrganizationShowing(false);
              setModalAddSupplierShowing(true);
            }}
            onSubmit={async (items) => {
              // console.log({ items });
              const params = items?.map((item) => {
                const obj = {};
                if (item?.organization?.organizationDepartmentId) {
                  obj.autoTransferOrganizationDepartmentId =
                    item?.organization?.organizationDepartmentId;
                } else if (item?.organization?.organizationId) {
                  obj.autoTransferOrganizationId = item?.organization?.organizationId;
                }
                obj.partnerCompanyIds = item?.suppliers?.map((i) => i.partnerCompanyId);
                return obj;
              });
              console.log(JSON.stringify(params, null, 4));
              const res = await OrganizationHelper.callApiCreateListAutoTransferInvoiceDepartment({
                autoTransferInvoices: params,
              });
              if (res) {
                // thanh cong
                setModalChooseOrganizationShowing(false);
                OrganizationHelper.callApiGetListAutoTransferInvoiceDepartment();
              }
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalChooseOrganizationShowing(false);
        }}
      />

      {/* modal chon ds ncc khong tai ve hoa don */}
      <VIModal
        titleModal="Danh sách nhà cung cấp không tải về hóa đơn"
        modalSize="xl"
        centered={false}
        show={modalChooseSupplierShowing}
        contentModal={
          <ViewChooseSupplier
            onPressedCancel={() => {
              setModalChooseSupplierShowing(false);
              Global.gTempChoosingSupplierList = null;
            }}
            onSubmit={async (items) => {
              // console.log({ items });
              const ids = items.map((item) => item.partnerCompanyId);
              // console.log({ ids });
              const params = {
                partnerCompanyIds: ids,
              };
              const res = await OrganizationHelper.callApiCreateListSupplierNotDownloadInvoice(
                params
              );
              if (res) {
                // thanh cong
                setModalChooseSupplierShowing(false);
                OrganizationHelper.callApiGetListSupplierNotDownloadInvoice();
              }
            }}
            onPressedAddSupplier={() => {
              refPrevModalChooseSupplierShowing.current = true;
              setModalChooseSupplierShowing(false);
              setModalAddSupplierShowing(true);
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalChooseSupplierShowing(false);
          Global.gTempChoosingSupplierList = null;
        }}
      />

      {/* modal chon ds tu dong dieu chuyen hoa don tu nguon email */}
      <VIModal
        titleModal="Danh sách tự động điều chuyển hóa đơn từ nguồn email"
        modalSize="xl"
        centered={false}
        show={modalChooseOrganizationByBuyerTaxCodeShowing}
        contentModal={
          <ViewChooseOrganizationByBuyerTaxCode
            onPressedCancel={() => {
              setModalChooseOrganizationByBuyerTaxCodeShowing(false);
            }}
            onSubmit={async (items) => {
              console.log({ items });
              const organizationBIds = [];
              const organizationDepartmentBIds = [];
              items.forEach((item) => {
                if (item?.organizationDepartmentId) {
                  organizationDepartmentBIds.push(item?.organizationDepartmentId);
                } else if (item?.organizationId) {
                  organizationBIds.push(item?.organizationId);
                }
              });
              const params = {
                organizationBIds,
                organizationDepartmentBIds,
              };
              console.log({ params });
              const res = await OrganizationHelper.callApiCreateListAutoTransferByBuyerTaxCode(
                params
              );
              if (res) {
                // thanh cong
                setModalChooseOrganizationByBuyerTaxCodeShowing(false);
                OrganizationHelper.callApiGetListAutoTransferByBuyerTaxCode();
              }
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalChooseOrganizationByBuyerTaxCodeShowing(false);
        }}
      />

      {/* modal tao ncc */}
      <VIModal
        titleModal={t('CreateSupplier')}
        centered={false}
        show={modalAddSupplierShowing}
        contentModal={
          <ViewAddSupplier
            onPressCancel={() => {
              setModalAddSupplierShowing(false);
              if (refPrevModalChooseSupplierShowing.current) {
                setModalChooseSupplierShowing(true);
              } else {
                setModalChooseOrganizationShowing(true);
              }
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalAddSupplierShowing(false);
          if (refPrevModalChooseSupplierShowing.current) {
            setModalChooseSupplierShowing(true);
          } else {
            setModalChooseOrganizationShowing(true);
          }
        }}
      />
    </div>
  );
}

export default SystemSettingScreen;
