import { thunkGetDeviceInfo } from 'app/appSlice';
import { thunkGetProfile } from 'features/Auth/authSlice';
import { thunkGetAllFaqs } from 'features/FAQ/faqSlice';
import {
  thunkGetAllInstructionCategories,
  thunkGetAllInstructions,
} from 'features/Instruction/instructionSlice';
import { thunkGetNotificationList } from 'features/Notification/notificationSlice';
import { thunkGetAllOrganizations } from 'features/Organization/organizationSlice';
import { thunkGetAllSuppliers } from 'features/Supplier/supplierSlice';
import {
  thunkGetAllCompanyTitles,
  thunkGetAllPermissionCodes,
  thunkGetAllSystemRoles,
  thunkGetAllSystemUsers,
} from 'features/System/systemSlice';
import { thunkGetTagList } from 'features/Tag/tagSlice';
import AccountHelper from 'general/helpers/AccountHelper';
import AppHelper from 'general/helpers/AppHelper';
import MailHelper from 'general/helpers/MailHelper';
import OrganizationHelper from 'general/helpers/OrganizationHelper';
import PaymentHelper from 'general/helpers/PaymentHelper';
import SystemHelper from 'general/helpers/SystemHelper';
import Global from 'general/utils/Global';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function DataCommonListener(props) {
  // MARK: --- Params ---
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.auth);

  // ----- methods -----
  function getAllCommonData() {
    // console.log('getAllCommonData');
    dispatch(thunkGetDeviceInfo());
    dispatch(thunkGetAllInstructions());
    dispatch(thunkGetAllInstructionCategories());
    dispatch(thunkGetAllFaqs());
    dispatch(thunkGetAllOrganizations());
    dispatch(thunkGetAllCompanyTitles());
    dispatch(thunkGetAllSystemUsers({}));
    dispatch(thunkGetAllSystemRoles({}));
    dispatch(thunkGetNotificationList(Global.gFiltersNotificationList));
    dispatch(thunkGetAllPermissionCodes());
    dispatch(thunkGetAllSuppliers());
    dispatch(thunkGetTagList());
    AppHelper.callApiGetTopCustomerComments();
    SystemHelper.callApiGetPaymentHistory(Global.gFilterPaymentHistory);
    OrganizationHelper.callApiGetListSupplierNotDownloadInvoice();
    OrganizationHelper.callApiGetListAutoTransferInvoiceDepartment();
    OrganizationHelper.callApiGetListAutoTransferByBuyerTaxCode();

    MailHelper.callApiGetInboxMailList(Global.gFiltersInboxMailList);
    MailHelper.callApiGetImportantMailList(Global.gFiltersImportantMailList);
    MailHelper.callApiGetSpamMailList(Global.gFiltersSpamMailList);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    if (_.isNull(account) && AccountHelper.checkAccessTokenValid()) {
      dispatch(thunkGetProfile());
      getAllCommonData();
    }
    PaymentHelper.callApiGetServicePackList();
    dispatch(thunkGetAllFaqs());
    AppHelper.callApiGetTopCustomerComments();
    AppHelper.callApiGetAppConfigs();

    return () => {};
  }, []);

  useEffect(() => {
    if (account?.accountId) {
      if (AccountHelper.checkAccessTokenValid()) {
        dispatch(thunkGetProfile());
        getAllCommonData();
      }
    }
  }, [account?.accountId]);

  return <></>;
}

export default DataCommonListener;
