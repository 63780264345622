import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import VISelectFilter from '../VISelectFilter';
import './style.scss';
import AppResource from 'general/constants/AppResource';
import { Form } from 'react-bootstrap';

VIInvoice.propTypes = {
  handleClick: PropTypes.func,
  handleClickRadio: PropTypes.func,
  text: PropTypes.string,
};

VIInvoice.defaultProps = {
  handleClick: () => {},
  handleClickRadio: () => {},
  text: '',
};

function VIInvoice(props) {
  const { handleClick, handleClickRadio, text } = props;
  const { t } = useTranslation();

  const dataFilter = [
    {
      name: 'Nhật ký hoạt động',
      value: 0,
    },
    {
      name: 'Thành viên',
      value: 1,
    },
    {
      name: 'Nhóm vị trí',
      value: 2,
    },
    {
      name: 'Vị trí công việc',
      value: 3,
    },
    {
      name: 'Cơ cấu tổ chức',
      value: 4,
    },
  ];
  return (
    <div className="vi-invoice">
      <div className="bg-default">
        <img src={AppResource.images.imgExportInvoice} className="w-100" />
      </div>
      {text === 'Đầu vào' && (
        <div className="d-flex justify-content-between mt-4 mx-2">
          <Form.Check
            type="radio"
            className="check cursor-pointer d-flex aligns-items-center"
            label={<div className="cursor-pointer label-checkbox">{t('ListOfInputInvoices')}</div>}
            id="121"
            name="radio1"
            onChange={(e) => {
              handleClickRadio('ListOfInputInvoices');
            }}
          />
          <Form.Check
            type="radio"
            className="check cursor-pointer d-flex aligns-items-center"
            label={
              <div className="cursor-pointer label-checkbox">
                {t('ExportFromAccountingSoftware')}
              </div>
            }
            id="110"
            name="radio1"
            onChange={(e) => {
              handleClickRadio('ExportFromAccountingSoftware');
            }}
          />
        </div>
      )}
      <div className="mt-4">
        <span className="title-select">{t('SelectTheListYouWantToDownload')}</span>
        <VISelectFilter
          data={dataFilter}
          id={20}
          type="CheckBox"
          onClick={(e) => handleClick(e)}
          className="w-100"
          // disabled={true}
        />
      </div>
      <div className="warning d-flex align-items-center">
        <i className="fa-regular fa-diamond-exclamation me-2" style={{ color: '#f44141' }}></i>
        <span>Dữ liệu xuất bản sẽ được lấy theo điều kiện lọc trên danh sách hóa đơn</span>
      </div>
    </div>
  );
}
export default VIInvoice;
