import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KTTooltip from '../../KTTooltip';

KTImageInput.propTypes = {
  // required
  name: PropTypes.string.isRequired,

  // optional
  value: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  outlineStyle: PropTypes.bool,
  circleStyle: PropTypes.bool,
  acceptImageTypes: PropTypes.arrayOf(PropTypes.string),
  defaultImage: PropTypes.string,
  editable: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  enableCheckValid: PropTypes.bool,
  isTouched: PropTypes.bool,
  isValid: PropTypes.bool,
  feedbackText: PropTypes.string,

  additionalImageInputWrapperClass: PropTypes.string,
  onSelectedFile: PropTypes.func,
  onRemovedFile: PropTypes.func,
};

KTImageInput.defaultProps = {
  value: '',
  text: '',
  outlineStyle: true,
  circleStyle: false,
  acceptImageTypes: [],
  defaultImage: '',
  editable: false,

  onChange: null,
  onBlur: null,
  onFocus: null,
  enableCheckValid: false,
  isValid: true,
  isTouched: false,
  feedbackText: '',

  additionalImageInputWrapperClass: '',
  onSelectedFile: null,
  onRemovedFile: null,
};

/**
 *
 * @param {{
 * name: string,
 * value: string,
 * text: string | element,
 * outlineStyle: boolean,
 * circleStyle: boolean,
 * acceptImageTypes: string[],
 * editable: boolean,
 * onChange: function,
 * onBlur: function,
 * onFocus: function,
 * enableCheckValid: boolean,
 * isValid: boolean,
 * isTouched: boolean,
 * feedbackText: string,
 * additionalImageInputWrapperClass: string,
 * onSelectedFile: function,
 * onRemovedFile: function,
 * }} props
 * @returns
 */
function KTImageInput(props) {
  // MARK: --- Params ---
  const {
    name,

    value,
    text,
    outlineStyle,
    circleStyle,
    acceptImageTypes,
    defaultImage,
    editable,

    onChange,
    onBlur,
    onFocus,
    enableCheckValid,
    isValid,
    isTouched,
    feedbackText,

    additionalImageInputWrapperClass,
    onSelectedFile,
    onRemovedFile,
  } = props;

  const [selectedImage, setSelectedImage] = useState(null);
  const [inputValue, setInputValue] = useState(value);
  const isShowError = enableCheckValid && !isValid && isTouched && !_.isEmpty(feedbackText);
  const { t } = useTranslation();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // MARK: --- Functions ---
  function handleChange(e) {
    if (!_.isEmpty(inputValue)) {
      URL.revokeObjectURL(inputValue);
    }
    const images = e.target.files;
    if (images && images.length > 0) {
      const selectedImage = images[0];
      setSelectedImage(selectedImage);
      const imageInputValue = URL.createObjectURL(selectedImage);
      setInputValue(imageInputValue);
      if (onChange) {
        onChange(imageInputValue);
      }
      if (onSelectedFile) {
        onSelectedFile(selectedImage);
      }
    }
    if (onFocus) {
      onFocus();
    }
  }

  function handleBlur() {
    if (onBlur) {
      onBlur();
    }
  }

  function handleFocus() {
    if (onFocus) {
      onFocus();
    }
  }

  function handleRemoveImage() {
    if (!_.isEmpty(inputValue)) {
      URL.revokeObjectURL(inputValue);
    }
    setInputValue('');
    if (onRemovedFile) {
      onRemovedFile();
    }
  }

  return (
    <div>
      <div
        className={`
                image-input 
                ${outlineStyle ? 'image-input-outline' : ''}
                ${circleStyle ? 'image-input-circle' : ''}
            `}
      >
        {/* wrapper */}
        <div
          className={`image-input-wrapper ${
            isShowError ? 'border-1 border-danger' : ''
          } ${additionalImageInputWrapperClass}`}
          style={{
            backgroundImage: `url(${!_.isEmpty(inputValue) ? inputValue : defaultImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></div>
        <KTTooltip text={t('Change')}>
          <label
            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
            data-action="change"
          >
            <i className="fa-regular fa-pen"></i>

            <input
              type="file"
              name={name}
              id={name}
              accept={_.join(acceptImageTypes, ', ')}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
            <input type="hidden" />
          </label>
        </KTTooltip>

        {/* editable */}
        {editable && !_.isEmpty(inputValue) && (
          <KTTooltip text={t('Remove')}>
            <span
              className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="remove"
              onClick={handleRemoveImage}
            >
              <i className="ki ki-bold-close icon-xs text-grey" />
            </span>
          </KTTooltip>
        )}
      </div>

      {isShowError && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{feedbackText}</div>
        </div>
      )}
      {!_.isEmpty(text) && <span className="form-text text-muted">{text}</span>}
    </div>
  );
}

export default KTImageInput;
