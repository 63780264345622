import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './style.scss';

VIFolderTree.propTypes = {
  data: PropTypes.array,
  setItem: PropTypes.func,
  currentItem: PropTypes.object,
  enableMaxHeight: PropTypes.bool,
};

VIFolderTree.defaultProps = {
  data: [],
  setItem: null,
  currentItem: {},
  enableMaxHeight: true,
};

function VIFolderTree(props) {
  const { data, setItem, currentItem, enableMaxHeight } = props;
  const { t } = useTranslation();

  const organizationId = currentItem?.organizationDepartmentId
    ? currentItem?.organizationDepartmentId
    : currentItem?.organizationId;
  const organizationName = currentItem?.departmentName
    ? currentItem?.departmentName
    : currentItem?.organizationName;

  function handleChange(item) {
    if (setItem) {
      setItem(item);
    }
  }

  return (
    <div
      className="VIFolderTree overflow-auto"
      style={{
        maxHeight: enableMaxHeight ? '40vh' : '',
      }}
    >
      {data?.map((item, index) => {
        let showBorder = index !== 0;
        let itemName = item?.departmentName ? item?.departmentName : item?.organizationName;
        let itemId = item?.organizationDepartmentId
          ? item?.organizationDepartmentId
          : item?.organizationId;
        return (
          <div className={`accordion-items ${!showBorder ? 'border-0' : ''}`} key={index}>
            <Accordion defaultActiveKey="1">
              <Accordion.Item
                className={`${false ? 'border-0' : ''} ${
                  !!item?.organizationDepartmentId ? 'DepartmentItem' : ''
                }`}
                eventKey="1"
              >
                <div
                  onClick={() => handleChange(item)}
                  style={{ minHeight: '67px' }}
                  className={`btn btn-hover-light-primary d-flex justify-content-between align-items-center p-4 ${
                    organizationId === itemId && itemName === organizationName
                      ? 'bg-light-primary'
                      : ''
                  }`}
                >
                  <div className="d-flex flex-row align-items-center">
                    <Accordion.Header
                      onClick={(event) => {
                        event.stopPropagation(); // Ngăn lan truyền sự kiện đến cha
                      }}
                      style={
                        item?.ChildrenOrganizations?.length === 0 ? { pointerEvents: 'none' } : {}
                      }
                    ></Accordion.Header>
                    <div
                      className={`ml-4 text-left font-weight-bolder ${
                        organizationId === itemId && itemName === organizationName
                          ? 'text-primary'
                          : ''
                      }`}
                    >
                      <span className="font-size-lg">
                        {' '}
                        {item?.organizationName ?? item?.departmentName}{' '}
                      </span>{' '}
                      <br />
                      {item.invoiceMailbox && (
                        <span className="text-muted font-weight-bold">
                          {t('EmailReceiveInvoice')}
                          {': '}
                          <span className="text-primary font-weight-bolder">
                            {item.invoiceMailbox}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                  {organizationId === itemId && itemName === organizationName && (
                    <i className="text-primary fa-solid fa-check"></i>
                  )}
                </div>

                <Accordion.Body
                  className="p-0"
                  style={{
                    display: item?.ChildrenOrganizations?.length > 0 ? 'absolute' : 'none',
                    marginLeft: '30px',
                  }}
                >
                  <div className="">
                    {
                      <VIFolderTree
                        currentItem={currentItem}
                        data={item?.ChildrenOrganizations}
                        setItem={(item, e) => handleChange(item, e)}
                        enableMaxHeight={false}
                      />
                    }
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
}

export default VIFolderTree;
