import 'assets/styles/keen/theme01/layout/brand/dark.css';
import DropdownHeaderAccountInfo from 'features/Others/components/DropdownHeaderAccountInfo';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import AppResource from 'general/constants/AppResource';
import useAccount from 'hooks/useAccount';
import useConfig from 'hooks/useConfig';
import useRouter from 'hooks/useRouter';
import { useEffect } from 'react';
import './style.scss';

function KT01HeaderMobile(props) {
  // ---- params ----
  const router = useRouter();
  const account = useAccount();

  // MARK: --- Hooks ---
  useEffect(() => {
    // Init header mobile
    if (typeof KTLayoutHeaderMobile !== undefined) {
      KTLayoutHeaderMobile.init('kt_header_mobile');
    }

    // Init header mobile top bar
    if (typeof KTLayoutHeaderTopbar !== undefined) {
      KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');
    }
  }, []);
  const bannerHeight = useConfig().systemNotification.bannerHeight;

  return (
    <div
      id="kt_header_mobile"
      className="KT01HeaderMobile header-mobile align-items-center header-mobile-fixed shadow"
      style={{
        marginTop: `${bannerHeight}px`,
        backgroundColor: '#182748',
      }}
    >
      {/* sidebar */}
      <i className="fa-solid fa-bars text-white fa-2x cursor-pointer" id="kt_aside_mobile_toggle" />

      {/* logo */}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          router.navigate('/landing-page');
        }}
      >
        <img src={AppResource.icons.icLogoQuanLyHoaDonDark} alt="logo" />
      </a>

      {/* toolbar */}
      {/* <div className="d-flex align-items-center">
        <button id="kt_header_mobile_topbar_toggle" className="btn btn-hover-text-primary p-0 ml-3">
          <span className="svg-icon svg-icon-xl">
            <img alt="avatar" src={AppResource.icons.keens.user} />
          </span>
        </button>
      </div> */}

      {/* user */}
      <KTBSDropdown
        contentEl={<DropdownHeaderAccountInfo />}
        toggleElement={
          <div className="topbar-item ml-2">
            <div className="btn btn-icon btn-clean d-flex align-items-center btn-lg">
              <div className="symbol symbol-circle symbol-35 bg-white">
                <div className="symbol-label bg-white border rounded overflow-hidden">
                  <img
                    alt="avatar"
                    src={account.avatar}
                    className="w-35px h-35px"
                    style={{
                      objectFit: 'cover',
                    }}
                  />
                </div>
                {account?.isOnline && (
                  <i
                    className="symbol-badge"
                    style={{
                      backgroundColor: 'rgb(58, 206, 90)',
                    }}
                  ></i>
                )}
              </div>
            </div>
          </div>
        }
        alignment={KTBSDropdownAlignments.end}
        // autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
      />
    </div>
  );
}

export default KT01HeaderMobile;
