import VIAppTabs from 'general/components/VietInvoice/VIAppTabs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DailyLog from './components/DailyLog';
import LoggedInDevice from './components/LoggedInDevice';
import PropTypes from 'prop-types';

AccountSetupSecurityScreen.propTypes = {
  navItemList: PropTypes.array,
};

AccountSetupSecurityScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */
function AccountSetupSecurityScreen(props) {
  // MARK --- Params ---
  const { navItemList } = props;
  const { t } = useTranslation();
  const tabList = useMemo(() => {
    return [t('LoggedInLog'), t('LoggedInDevices')];
  }, [t]);
  const [activeTab, setActiveTab] = useState(tabList[1]);
  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <VIAppTabs
        tabs={tabList}
        activeTab={activeTab}
        handleClick={(tab) => {
          setActiveTab(tab);
        }}
      />
      <div
        className="bg-white border-left border-right border-bottom flex-grow-1"
        style={{
          borderBottomRightRadius: '20px',
          borderBottomLeftRadius: '20px',
        }}
      >
        {activeTab === t('LoggedInLog') ? (
          <DailyLog dropdownMenuItems={dropdownMenuItems} />
        ) : (
          <LoggedInDevice dropdownMenuItems={dropdownMenuItems} />
        )}
      </div>
    </div>
  );
}

export default AccountSetupSecurityScreen;
