import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import './style.scss';

VISearchField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  typingTimeout: PropTypes.number,
  onSubmit: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  inputClassName: PropTypes.string,
  iconPosition: PropTypes.string,
};

VISearchField.defaultProps = {
  type: 'text',
  name: '',
  placeholder: '',
  className: '',
  value: '',
  typingTimeout: 500,
  onSubmit: null,
  onFocus: null,
  onBlur: null,
  style: {},
  inputStyle: {},
  inputClassName: '',
  iconPosition: 'left',
};

/**
 * @param {string} name
 * @param {string} type type of text input
 * @param {string} placeholder
 * @param {string} className
 * @param {string} value
 * @param {number} typingTimeout
 * @param {function} onFocus
 * @param {function} onBlur
 * @param {function} onSubmit handle onSubmit
 * @param {string} style
 * @param {string} inputStyle
 * @param {string} inputClassName
 * @param {string} iconPosition icon position: left/right input
 */

function VISearchField(props) {
  // MARK: --- Params ---
  const {
    name,
    type,
    placeholder,
    className,
    value,
    typingTimeout,
    onSubmit,
    onFocus,
    onBlur,
    style,
    inputStyle,
    inputClassName,
    iconPosition,
  } = props;

  const typingTimeoutRef = useRef(null);
  const [text, setText] = useState(value);
  useEffect(() => {
    setText(value);
  }, [value]);

  // MARK: --- Functions ---
  function handleTextChanged(e) {
    const value = e.target.value;
    setText(value);

    if (onSubmit === null) {
      return;
    }

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      onSubmit(value);
    }, typingTimeout);
  }

  function handleFocus() {
    if (onFocus) {
      onFocus();
    }
  }

  return (
    <div className={`VISearchField input-icon ${className}`} style={{ ...style }}>
      <input
        className={`form-control font-size-lg ${inputClassName} ${
          iconPosition === 'right' ? 'pl-4 pr-12' : ''
        } h-100`}
        style={{ ...inputStyle }}
        id={name}
        type={type}
        placeholder={placeholder}
        spellCheck={false}
        autoComplete="off"
        value={text}
        onFocus={handleFocus}
        onBlur={onBlur}
        onChange={handleTextChanged}
      />
      <span className={`${iconPosition === 'left' ? 'ms-1' : 'icon-right'}`}>
        <i className="far fa-search text-muted" />
      </span>
    </div>
  );
}

export default VISearchField;
