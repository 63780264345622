import axiosClient from './axiosClient';

const organizationApi = {
  // lay tat ca co cau to chuc
  getAll: () => {
    const url = '/organization/find';
    return axiosClient.get(url);
  },

  // tao cong ty hoac chi nhanh moi
  createNew: (params) => {
    const url = '/organization/create';
    return axiosClient.post(url, params);
  },

  // tao cong ty hoac chi nhanh moi voi nhieu dia chi
  createNewMultiAddress: (params) => {
    const url = '/organization/create-with-many-address';
    return axiosClient.post(url, params);
  },

  // cap nhat thong tin cong ty hoac chi nhanh
  updateInfo: (id, params) => {
    const url = `/organization/update/${id}`;
    return axiosClient.put(url, params);
  },

  // cap nhat thong tin cong ty hoac chi nhanh voi nhieu dia chi
  updateInfoMultiAddress: (id, params) => {
    const url = `/organization/update-with-many-address/${id}`;
    return axiosClient.put(url, params);
  },

  // xoa cong ty
  delete: (ids) => {
    const url = '/organization/delete';
    return axiosClient.delete(url, {
      data: {
        organizationIds: ids,
      },
    });
  },

  // thiet lap cai dat
  updateSetting: (params) => {
    const url = '/setting';
    return axiosClient.put(url, params);
  },

  // tao danh sach ncc khong tai ve hoa don
  createListSupplierNotDownloadInvoice: (params) => {
    const url = '/list-supplier-not-download-invoice/create';
    return axiosClient.post(url, params);
  },

  // lay danh sach ncc khong tai ve hoa don
  getListSupplierNotDownloadInvoice: () => {
    const url = '/list-supplier-not-download-invoice/find';
    return axiosClient.get(url);
  },

  // tao ds tu dong dieu chuyen hoa don cho chi nhanh/phong ban
  createListAutoTransferInvoiceDepartment: (params) => {
    const url = '/auto-transfer-invoice-department/create';
    return axiosClient.post(url, params);
  },

  // lay ds tu dong dieu chuyen hoa don cho chi nhanh/phong ban
  getListAutoTransferInvoiceDepartment: () => {
    const url = '/auto-transfer-invoice-department/find';
    return axiosClient.get(url);
  },

  // tao ds tu dong dieu chuyen theo mst nguoi mua
  createListAutoTransferByBuyerTaxCode: (params) => {
    const url = '/auto-transfer-invoice-from-email/create';
    return axiosClient.post(url, params);
  },

  // lay ds tu dong dieu chuyen theo mst nguoi mua
  getListAutoTransferByBuyerTaxCode: () => {
    const url = '/auto-transfer-invoice-from-email/find';
    return axiosClient.get(url);
  },
};

export default organizationApi;
