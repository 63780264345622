import PropTypes from 'prop-types';
const parse = require('html-react-parser');

ViewTaxReducedDocumentDetail.propTypes = {
  item: PropTypes.object,
};

ViewTaxReducedDocumentDetail.defaultProps = {
  item: null,
};

/**
 *
 * @param {{
 * item: object,
 * }} props
 * @returns
 */
function ViewTaxReducedDocumentDetail(props) {
  // ---- params ----
  const { item } = props;

  return (
    <div
      className="overflow-auto"
      style={{
        maxHeight: '70vh',
      }}
    >
      {item && (
        <div>
          <p className="font-size-lg font-weight-bold">{item?.title}</p>
          <p>{item?.description}</p>
          <div
            className="p-3 rounded"
            style={{
              backgroundColor: '#f3f6f9',
            }}
          >
            {parse(item?.note)}
          </div>

          {/* iframe */}
          {/* <div className="gutter-t">
            <object data={item?.pdfUrl} type="application/pdf">
              <iframe className="w-100 h-800px" src={item?.pdfUrl}></iframe>
            </object>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default ViewTaxReducedDocumentDetail;
