import AppResource from 'general/constants/AppResource';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './style.scss';

function AuthHeader(props) {
  // ---- params ----
  const { t } = useTranslation();
  const navigate = useNavigate();
  const headerTabs = [
    { title: 'Trang chủ', id: 'home', href: '' },
    { title: 'Giới thiệu', id: 'introduction', href: '' },
    { title: 'Ưu thế', id: 'advantages', href: '' },
    { title: 'Bảng giá', id: 'price', href: '' },
    { title: 'Liên hệ', id: 'contact', href: '' },
  ];

  // ---- methods ----
  const handleNavigate = (id) => {
    navigate(`/landing-page#${id}`);
  };

  return (
    <div id="auth-header" className="AuthHeader w-100 py-5 py-md-8 bg-white">
      <div className="container-xl d-flex justify-content-between align-items-center">
        <img
          className="h-30px cursor-pointer"
          src={AppResource.icons.icLogoQuanLyHoaDon}
          alt="logo"
          onClick={() => {
            navigate('/landing-page');
          }}
        />

        <div className="d-flex">
          <div className="AuthHeader_laptop header-tabs me-3">
            <div className="d-flex align-items-center h-100">
              {headerTabs?.map((item, index) => (
                <div
                  key={index}
                  className="header-tab font-size-h5 font-weight-bolder text-dark-65 cursor-pointer mx-1 mx-md-3 mx-lg-6 mx-xl-8"
                  onClick={() => {
                    handleNavigate(item.id);
                  }}
                >
                  {item?.title}
                </div>
              ))}
            </div>
          </div>

          <Dropdown className="AuthHeader_mobile my-auto mx-2">
            <Dropdown.Toggle className="p-0 d-flex align-items-center">
              <div className="btn btn-icon btn-clean font-weight-bolder btn-lg">
                <i className="fas fa-bars font-size-h2 cursor-pointer"></i>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu align="left" className="w-200px py-4 bg-white shadow-lg rounded-lg px-3">
              {headerTabs?.map((item, index) => (
                <Dropdown.Item
                  className="header-tab p-4 font-size-h6 font-weight-bolder text-dark-65 bg-hover-secondary-o-1"
                  key={index}
                  onClick={() => {
                    handleNavigate(item.id);
                  }}
                >
                  {item?.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default AuthHeader;
