import React from 'react';
import PropTypes from 'prop-types';

export const KTBSButtonColor = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  info: 'info',
  dark: 'dark',
  light: 'light',
  white: 'white',
};

export const KTBSButtonType = {
  default: 'default',
  light: 'light',
  outline: 'outline',
};

export const KTBSButtonSize = {
  default: '',
  small: 'sm',
  large: 'lg',
};

export const KTBSButtonFontWeight = {
  lighter: 'lighter',
  light: 'light',
  normal: 'normal',
  bold: 'bold',
  bolder: 'bolder',
  boldest: 'boldest',
};

KTBSButton.propTypes = {
  type: PropTypes.oneOf(Object.values(KTBSButtonType)),
  color: PropTypes.oneOf(Object.values(KTBSButtonColor)),
  hoverColor: PropTypes.oneOf(Object.values(KTBSButtonColor)),
  titleColor: PropTypes.oneOf(Object.values(KTBSButtonColor)),
  size: PropTypes.oneOf(Object.values(KTBSButtonSize)),
  fontWeight: PropTypes.oneOf(Object.values(KTBSButtonFontWeight)),
  title: PropTypes.string,
  isBlock: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  iconElement: PropTypes.element,
  iconOnly: PropTypes.bool,
  circle: PropTypes.bool,
  additionalClassName: PropTypes.string,
};

KTBSButton.defaultProps = {
  type: KTBSButtonType.default,
  color: KTBSButtonColor.primary,
  hoverColor: null,
  titleColor: null,
  size: KTBSButtonSize.default,
  fontWeight: KTBSButtonFontWeight.normal,
  title: '',
  isBlock: false,
  active: false,
  disabled: false,
  onPress: null,
  iconElement: null,
  iconOnly: false,
  circle: false,
  additionalClassName: '',
};

/**
 *
 * @param {{
 * type: string,
 * color: string,
 * hoverColor: string,
 * titleColor: string,
 * size: string,
 * fontWeight: string,
 * title: string,
 * isBlock: boolean,
 * active: boolean,
 * disabled: boolean,
 * onPress: function,
 * iconElement: element,
 * iconOnly: boolean,
 * circle: boolean,
 * additionalClassName: string,
 * }} props
 * @returns
 */
function KTBSButton(props) {
  // MARK: --- Params ---
  let {
    type,
    color,
    hoverColor,
    titleColor,
    title,
    size,
    fontWeight,
    isBlock,
    active,
    disabled,
    onPress,
    iconElement,
    iconOnly,
    circle,
    additionalClassName,
  } = props;
  if (hoverColor || titleColor) {
    color = KTBSButtonColor.light;
  }

  // MARK: --- Functions ---
  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  return (
    <div>
      <div
        className={`
          d-flex align-items-center
          btn 
          ${iconOnly ? 'btn-icon' : ''}
          ${circle ? 'btn-circle' : ''}
          ${
            type === KTBSButtonType.default
              ? `btn-${color}`
              : type === KTBSButtonType.light
              ? `btn-light-${color}`
              : type === KTBSButtonType.outline
              ? `btn-outline-${color}`
              : ''
          }
          btn-${size}
          ${hoverColor ? `btn-hover-${hoverColor}` : ''}
          ${titleColor ? `btn-text-${titleColor} btn-hover-text-${titleColor}` : ''}
          font-weight-${fontWeight}
          ${disabled ? 'disabled' : ''}
          ${additionalClassName}
      `}
        onClick={handlePress}
      >
        {iconElement && (
          <div className={`${!_.isEmpty(title) && !iconOnly ? 'mr-2' : ''}`}>{iconElement}</div>
        )}
        {!iconOnly && title}
      </div>
    </div>
  );
}

export default KTBSButton;
