import PropTypes from 'prop-types';
import { useState } from 'react';
import './style.scss';
import KTTooltip from '../KTTooltip';

export const KTAdvanceNavFontWeight = {
  light: '',
  lighter: 'lighter',
  bold: 'bold',
  bolder: 'bolder',
  boldest: 'boldest',
};

export const KTAdvanceNavColor = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  info: 'info',
  dark: 'dark',
  light: 'light',
};

KTAdvanceNav.propTypes = {
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      iconElement: PropTypes.element,
      text: PropTypes.string,
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      labelElement: PropTypes.element,
      showArrow: PropTypes.bool,
      showBullet: PropTypes.bool,
      onPress: PropTypes.func,
    })
  ),
  fontWeight: PropTypes.oneOf(Object.values(KTAdvanceNavFontWeight)),
  enableHoverStyle: PropTypes.bool,
  enableActiveStyle: PropTypes.bool,
  enableLinkRounded: PropTypes.bool,
  enableAccentStyle: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(KTAdvanceNavColor)),
  additionalComponent: PropTypes.element,
  collapseEnable: PropTypes.bool,
};

KTAdvanceNav.defaultProps = {
  itemList: [],
  fontWeight: KTAdvanceNavFontWeight.light,
  enableHoverStyle: false,
  enableActiveStyle: false,
  enableLinkRounded: false,
  enableAccentStyle: false,
  color: KTAdvanceNavColor.primary,
  additionalComponent: null,
  collapseEnable: true,
};

/**
 *
 * @param {{
 * itemList: {
 *  iconElement: element,
 *  text: string,
 *  active: boolean,
 *  disabled: boolean,
 *  labelElement: element,
 *  showArrow: boolean,
 *  showBullet: boolean,
 *  onPress: function,
 * }[],
 * fontWeight: string,
 * enableHoverStyle: boolean,
 * enableActiveStyle: boolean,
 * enableLinkRounded: boolean,
 * enableAccentStyle: boolean,
 * color: string,
 * additionalComponent: element,
 * collapseEnable: boolean,
 * }} props
 * @returns
 */
function KTAdvanceNav(props) {
  // MARK: --- Params ---
  const {
    itemList,
    fontWeight,
    enableHoverStyle,
    enableActiveStyle,
    enableLinkRounded,
    enableAccentStyle,
    color,
    additionalComponent,
    collapseEnable,
  } = props;

  return (
    <div className="KTAdvanceNav d-flex flex-column h-100">
      <ul
        className={`${collapseEnable ? 'KTAdvanceNav_Expand' : ''} navi navi-${fontWeight} ${
          enableHoverStyle ? 'navi-hover' : ''
        } ${enableActiveStyle ? 'navi-active' : ''} ${enableAccentStyle ? 'navi-accent' : ''} ${
          enableLinkRounded ? 'navi-link-rounded' : ''
        } navi-${color}`}
      >
        {itemList.map((item, index) => {
          return (
            <li className="navi-item py-1" key={index}>
              <a
                className={`navi-link ${item.active ? 'active' : ''} ${
                  item.disabled ? 'disabled' : ''
                }`}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (item?.onPress) {
                    item?.onPress();
                  }
                }}
              >
                {item.showBullet && (
                  <span className="navi-bullet">
                    <i className="bullet" />
                  </span>
                )}
                {item.iconElement && <span className="navi-icon">{item.iconElement}</span>}
                <span className="navi-text">{item.text}</span>
                {item.labelElement && <span className="navi-label">{item.labelElement}</span>}
                {item.showArrow && <span className="navi-arrow"></span>}
              </a>
            </li>
          );
        })}
      </ul>
      {collapseEnable && (
        <ul
          className={`${collapseEnable ? 'KTAdvanceNav_Colapse' : ''} navi navi-${fontWeight} ${
            enableHoverStyle ? 'navi-hover' : ''
          } ${enableActiveStyle ? 'navi-active' : ''} ${enableAccentStyle ? 'navi-accent' : ''} ${
            enableLinkRounded ? 'navi-link-rounded' : ''
          } navi-${color}`}
        >
          {itemList.map((item, index) => {
            return (
              <KTTooltip text={item.text} key={index}>
                <li className="navi-item py-1">
                  <a
                    className={`navi-link ${item.active ? 'active' : ''} ${
                      item.disabled ? 'disabled' : ''
                    }`}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (item?.onPress) {
                        item?.onPress();
                      }
                    }}
                  >
                    {item.showBullet && (
                      <span className="navi-bullet">
                        <i className="bullet" />
                      </span>
                    )}
                    {item.iconElement && <span className="navi-icon">{item.iconElement}</span>}
                    {/* <span className="navi-text">{item.text}</span> */}
                    {item.labelElement && <span className="navi-label">{item.labelElement}</span>}
                    {item.showArrow && <span className="navi-arrow"></span>}
                  </a>
                </li>
              </KTTooltip>
            );
          })}
        </ul>
      )}
      {additionalComponent && <div>{additionalComponent}</div>}
    </div>
  );
}

export default KTAdvanceNav;
