import EmptyView from 'features/Others/components/EmptyView';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import MailHelper from 'general/helpers/MailHelper';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MailAttachmentView from '../MailAttachmentView';
import MailTagView from '../MailTagView';
import { Dropdown } from 'react-bootstrap';
import useRouter from 'hooks/useRouter';
const parse = require('html-react-parser');
import './style.scss';
import useAccount from 'hooks/useAccount';

InvoiceDetailView.propTypes = {
  height: PropTypes.string,
  item: PropTypes.object,
  onDelete: PropTypes.func,
  mobile: PropTypes.any,
};

InvoiceDetailView.defaultProps = {
  height: '',
  item: null,
  onDelete: null,
  mobile: null,
};

/**
 *
 * @param {{
 * height: string,
 * item: object,
 * onDelete: function,
 * }} props
 * @returns
 */
function InvoiceDetailView(props) {
  // ---- params ----
  const { t } = useTranslation();
  const { height, item, onDelete, mobile } = props;
  const refToolbar = useRef();
  const router = useRouter();
  const refAttachmentBar = useRef();
  const account = useAccount();

  const [contentHeight, setContentHeight] = useState(height);
  const isEmpty = _.isUndefined(item) || _.isNull(item);
  const invoiceAttachmentOptions = useMemo(() => {
    const attachmentsHasInvoice = item?.attachments?.filter((item) => !!item.invoiceId);
    return attachmentsHasInvoice?.map((item) => {
      return {
        name: item.filename,
        value: item.invoiceId,
        icon: 'fa-regular fa-file',
      };
    });
  }, [item]);

  useEffect(() => {
    setContentHeight(
      `calc(${height} - ${
        refToolbar.current?.offsetHeight + refAttachmentBar.current?.offsetHeight
      }px - 1.5rem)`
    );
  }, [height]);

  // ---- methods ----
  function handleDelete() {
    if (onDelete) {
      onDelete(item);
    }
  }

  function handleViewMailInvoice(invoiceId) {
    router.navigate(`/inbot/invoice/detail/${invoiceId}`);
  }

  function handleDownloadAll() {
    const files = item?.attachments?.map((item) => {
      return {
        filename: item.filename,
        url: Utils.getFullUrl(item.url),
      };
    });
    Utils.downloadMultiFiles(files, 'thu_muc_dinh_kem.zip');
  }

  return !isEmpty ? (
    <div className="InvoiceDetailView h-100 d-flex flex-column">
      {/* mail toolbar */}
      <div
        className="px-4 py-3 border-bottom d-flex align-items-center justify-content-between bg-white"
        ref={refToolbar}
      >
        <div className="d-flex align-items-center gap-2">
          {/* hien thi ds email mobile */}

          <div className="mobile d-none">{mobile}</div>
          {/* tai xuong tat ca */}
          {item?.attachments?.length > 0 && (
            <KTTooltip text={t('DownloadAll')}>
              <div
                className="w-30px h-30px d-flex align-items-center justify-content-center bg-hover-light rounded cursor-pointer text-grey"
                onClick={handleDownloadAll}
              >
                <i
                  className="fa-regular fa-download"
                  style={{
                    color: 'inherit',
                  }}
                />
              </div>
            </KTTooltip>
          )}
          {/* in email */}
          {/* <KTTooltip text={t('Print')}>
            <div
              className="w-30px h-30px d-flex align-items-center justify-content-center bg-hover-light rounded cursor-pointer text-grey"
              style={{
                color: '#7177A8',
              }}
              onClick={() => {}}
            >
              <i
                className="fa-regular fa-print"
                style={{
                  color: 'inherit',
                }}
              />
            </div>
          </KTTooltip> */}
          {/* xoa mail */}
          {account.checkPermission('DELETE_AND_RESTORE_MAILBOX') && (
            <KTTooltip text={t('Delete')}>
              <div
                className="w-30px h-30px d-flex align-items-center justify-content-center bg-hover-light rounded cursor-pointer text-grey"
                style={{
                  color: '#7177A8',
                }}
                onClick={handleDelete}
              >
                <i
                  className="fa-regular fa-trash-can"
                  style={{
                    color: 'inherit',
                  }}
                />
              </div>
            </KTTooltip>
          )}
        </div>

        {invoiceAttachmentOptions?.length > 0 && (
          <Dropdown>
            <Dropdown.Toggle as={'div'}>
              <div>
                <VIButton
                  beforeIcon={<i className="fa-regular fa-eye" />}
                  text={t('DetailInvoice')}
                  className="btn-white p-2"
                  // onClick={() => {}}
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="p-2"
              popperConfig={{
                strategy: 'fixed',
                onFirstUpdate: () => window.dispatchEvent(new CustomEvent('scroll')),
              }}
            >
              {invoiceAttachmentOptions?.map((item, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      const invoiceId = item.value;
                      handleViewMailInvoice(invoiceId);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {item.icon && (
                        <i
                          className={`${item.icon} mr-2 min-w-20px`}
                          style={{
                            color: 'inherit',
                          }}
                        />
                      )}{' '}
                      {item.name}
                    </div>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      {/* mail content */}
      <div
        className="overflow-auto bg-white m-3 p-4 d-flex flex-column gap-2 flex-grow-1"
        style={{
          height: contentHeight,
          borderRadius: '16px',
        }}
      >
        <h5 className="text-grey font-weight-bolder m-0">{item?.subject}</h5>
        <div>
          <p className="m-0 text-primary">&lt;{item?.senderEmail}&gt;</p>
          <p className="m-0">
            {item?.receiveDate ? Utils.formatDateTime(item?.receiveDate, 'DD/MM/YYYY') : ''}
          </p>
        </div>
        <div className="d-flex flex-wrap gap-2 align-items-center">
          {item?.StatusMails?.map((item, index) => {
            return (
              <div key={index}>
                <MailTagView
                  text={
                    MailHelper.getMailStatusText(item?.statusValidMail) + ` (${item?.count ?? 0})`
                  }
                  color={MailHelper.getMailStatusColor(item?.statusValidMail)}
                  icon={MailHelper.getMailStatusIcon(item?.statusValidMail)}
                />
              </div>
            );
          })}
        </div>

        {/* noi dung html email */}
        {/* <div className="mt-2">{item?.content ? parse(item?.content) : ''}</div> */}
        <div className="mt-2" dangerouslySetInnerHTML={{ __html: item?.content ?? '' }}></div>
      </div>

      {/* attachment bar */}
      {item?.attachments?.length > 0 && (
        <div
          className="px-4 py-3 border-top d-flex flex-column align-items-start bg-white"
          ref={refAttachmentBar}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <p className="m-0 font-weight-bolder">{item?.attachments?.length} tệp đính kèm</p>
            <KTTooltip text={t('DownloadAll')}>
              <div
                className="w-30px h-30px d-flex align-items-center justify-content-center bg-hover-light rounded cursor-pointer"
                style={{
                  color: '#7177A8',
                }}
                onClick={handleDownloadAll}
              >
                <i
                  className="fa-regular fa-download text-grey"
                  style={{
                    color: 'inherit',
                  }}
                />
              </div>
            </KTTooltip>
          </div>
          <div className="d-flex flex-wrap align-items-center gap-2 mt-2 w-100 max-h-120px overflow-auto">
            {item?.attachments?.map((obj, index) => {
              return (
                <div key={index} className="max-w-100">
                  <MailAttachmentView
                    item={obj}
                    onPress={() => {
                      Utils.download(Utils.getFullUrl(obj.url), obj.filename);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="bg-white h-100 d-flex align-items-center">
      <EmptyView
        title="Không có thư nào được chọn"
        message={
          <>
            <span
              className="font-weight-bolder"
              style={{
                color: '#FFA318',
              }}
            >
              <i
                className="fa-regular fa-triangle-exclamation mr-1"
                style={{
                  color: 'inherit',
                }}
              />
              Lưu ý:{' '}
            </span>
            Tất cả thư sẽ được lưu trữ trong vòng 6 tháng kể từ ngày nhận thư
          </>
        }
        image={AppResource.icons.icMail}
      />
    </div>
  );
}

export default InvoiceDetailView;
