import customDataTableStyle from 'assets/styles/customDataTableStyle';
import AuditLogDetailView from 'features/System/components/AuditLogDetailView';
import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIDateRangePicker2 from 'general/components/VietInvoice/VIDateRangePicker2';
import VIModal from 'general/components/VietInvoice/VIModal';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import SystemHelper from 'general/helpers/SystemHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

SystemAuditingLogScreen.propTypes = {
  navItemList: PropTypes.array,
};

SystemAuditingLogScreen.defaultProps = {
  navItemList: [],
};

/**
 *
 * @param {{
 *  navItemList: object[],
 * }} props
 * @returns
 */

function SystemAuditingLogScreen(props) {
  // ---- params ----
  const { navItemList } = props;
  const { t } = useTranslation();
  const { auditingLogList, isGettingAuditingLogList, totalAuditingLog } = useSelector(
    (state) => state.system
  );
  const [filters, setFilters] = useState(Global.gFiltersAuditingLogList);
  const columns = useMemo(() => {
    return [
      {
        name: t('User'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 font-weight-bold m-0">
              {row?.Account?.fullname ?? ''}
            </p>
          );
        },
      },
      {
        name: t('Action'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.action}
            </p>
          );
        },
      },
      {
        name: t('Reference'),
        sortable: false,
        width: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.reference}
            </p>
          );
        },
      },
      {
        name: t('Description'),
        sortable: false,
        minWidth: '260px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.description}
            </p>
          );
        },
      },
      {
        name: t('Organization'),
        sortable: false,
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.organization}
            </p>
          );
        },
      },
      {
        name: t('IPAddress'),
        sortable: false,
        width: '140px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.ipAddress}
            </p>
          );
        },
      },
      {
        name: t('DateTime'),
        sortable: false,
        width: '160px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="text-dark-75 m-0">
              {row?.createdAt ? Utils.formatDateTime(row?.createdAt, 'DD/MM/YYYY HH:mm') : ''}
            </p>
          );
        },
      },
      // {
      //   name: '',
      //   center: true,
      //   width: '40px',
      //   style: {
      //     position: 'sticky',
      //     right: 0,
      //     backgroundColor: '#fff',
      //   },
      //   cell: (row) => <div className="d-flex align-items-center justify-content-between"></div>,
      // },
    ];
  }, [t]);
  const [modalDetailShowing, setModalDetailShowing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dropdownMenuItems = useMemo(() => {
    return navItemList.map((item) => {
      return {
        name: item.text,
        value: item.text,
        icon: item.icon,
        onPress: item.onPress,
      };
    });
  }, [navItemList]);

  useEffect(() => {
    SystemHelper.callApiGetAuditLogs(filters);
  }, [filters]);

  // ---- methods -----
  function handleViewDetail(row) {
    setSelectedItem(row);
    setModalDetailShowing(true);
  }

  return (
    <div className="h-100">
      <VIDataTable
        draggable={false}
        tableStyles={customDataTableStyle}
        title={
          <div className="d-flex align-items-center justify-content-center gap-1">
            <div className="d-block d-lg-none">
              <KTBSDropdown
                toggleElement={
                  <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                    <i className="fa-solid fa-bars text-grey" />
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                dropdownMenuItems={dropdownMenuItems}
                selectedValue={t('AuditingLog')}
                onChange={(newValue) => {}}
              />
            </div>{' '}
            {t('AuditingLog')}
          </div>
        }
        selectable={false}
        pagination={{
          perPage: filters.limit,
          total: totalAuditingLog,
          currentPage: filters.page + 1,
          count: auditingLogList?.length + filters.limit * filters.page,
        }}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          Global.gFiltersAuditingLogList = {
            ...Global.gFiltersAuditingLogList,
            page: iNewPage,
          };
          setFilters(Global.gFiltersAuditingLogList);
        }}
        onChangeRowsPerPage={(perPage) => {
          Global.gFiltersAuditingLogList = {
            ...Global.gFiltersAuditingLogList,
            limit: perPage,
            page: 0,
          };
          setFilters(Global.gFiltersAuditingLogList);
        }}
        fullColumns={columns}
        data={auditingLogList}
        loading={isGettingAuditingLogList}
        filterbar={
          <>
            <VISearchField
              placeholder={`${t('EnterToSearch')}...`}
              value={filters.q}
              className=""
              typingTimeout={500}
              onSubmit={(newValue) => {
                Global.gFiltersAuditingLogList = {
                  ...Global.gFiltersAuditingLogList,
                  q: newValue,
                  page: 0,
                };
                setFilters(Global.gFiltersAuditingLogList);
              }}
            />

            <div className="d-flex align-items-center">
              <label className="mb-0 mr-2 text-grey font-weight-bolder">{t('FilterByDate')}</label>
              <VIDateRangePicker2
                alignRight={true}
                initialValue={Global.gFiltersAuditingLogList.dateRangeValue}
                onPressSave={(selectedValue, startDate, endDate) => {
                  Global.gFiltersAuditingLogList = {
                    ...Global.gFiltersAuditingLogList,
                    from: startDate,
                    to: endDate,
                    dateRangeValue: selectedValue,
                  };
                  setFilters(Global.gFiltersAuditingLogList);
                }}
              />
            </div>
          </>
        }
        onRowClick={(row) => {
          handleViewDetail(row);
        }}
        onSetSelectedMultiItems={(rows) => console.log(rows)}
      />

      {/* modal audit log detail */}
      <VIModal
        titleModal={t('ActionDetail')}
        show={modalDetailShowing}
        contentModal={<AuditLogDetailView item={selectedItem} />}
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalDetailShowing(false);
          setSelectedItem(null);
        }}
      />
    </div>
  );
}

export default SystemAuditingLogScreen;
