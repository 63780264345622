import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import miscApi from 'api/miscApi';

// MARK: --- Thunks ---
// lay thong tin thiet bi
export const thunkGetDeviceInfo = createAsyncThunk(
  'system/getDeviceInfo',
  async (params, thunkAPI) => {
    const res = await miscApi.getDeviceInfo();
    return res?.data;
  }
);

// de lai thong tin lien he
export const thunkCreateContactCustomer = createAsyncThunk(
  'app/createContactCustomer',
  async (params, thunkAPI) => {
    const res = await miscApi.createContactCustomer(params);
    return res;
  }
);

// lay top binh luan cua khach hang
export const thunkGetTopCustomerComments = createAsyncThunk(
  'app/getTopCustomerComments',
  async (params, thunkAPI) => {
    const res = await miscApi.getTopCustomerComments(params);
    return res;
  }
);

// lay tat ca cau hinh
export const thunkGetAppConfigs = createAsyncThunk(
  'app/getAppConfigs',
  async (params, thunkAPI) => {
    const res = await miscApi.getAppConfigs();
    return res;
  }
);

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    deviceInfo: {},

    isCreatingContactCustomer: false,

    // top binh luan cua khach hang
    isGettingTopCustomerComment: false,
    topCustomerComments: [],

    appConfigList: [],

    showAppLoading: false,
  },
  reducers: {
    showAppLoading(state) {
      state.showAppLoading = true;
    },
    hideAppLoading(state) {
      state.showAppLoading = false;
    },
  },
  extraReducers: {
    // lay thong tin thiet bi
    [thunkGetDeviceInfo.fulfilled]: (state, action) => {
      const payload = action.payload;
      if (payload) {
        state.deviceInfo = payload;
      }
    },

    // tao thong tin lien he
    [thunkCreateContactCustomer.rejected]: (state, action) => {
      state.isCreatingContactCustomer = false;
    },
    [thunkCreateContactCustomer.pending]: (state, action) => {
      state.isCreatingContactCustomer = true;
    },
    [thunkCreateContactCustomer.fulfilled]: (state, action) => {
      state.isCreatingContactCustomer = false;
    },

    // lay top binh luan khach hang
    [thunkGetTopCustomerComments.rejected]: (state, action) => {
      state.isGettingTopCustomerComment = false;
    },
    [thunkGetTopCustomerComments.pending]: (state, action) => {
      state.isGettingTopCustomerComment = true;
    },
    [thunkGetTopCustomerComments.fulfilled]: (state, action) => {
      state.isGettingTopCustomerComment = false;
      const { result, commentCustomers } = action?.payload;
      if (result === 'success' && commentCustomers) {
        state.topCustomerComments = commentCustomers;
      }
    },

    // lay danh sach cau hinh
    [thunkGetAppConfigs.fulfilled]: (state, action) => {
      const { result, configs } = action?.payload;
      if (result === 'success' && configs) {
        state.appConfigList = configs;
      }
    },
  },
});

const { reducer, actions } = appSlice;
export const { hideAppLoading, showAppLoading } = actions;
export default reducer;
