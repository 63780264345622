import { thunkGetProfile } from 'features/Auth/authSlice';
import { thunkGetAllOrganizations } from 'features/Organization/organizationSlice';
import { FastField, Formik } from 'formik';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIFormCheck from 'general/components/VietInvoice/VIFormCheck';
import AppConfigs from 'general/constants/AppConfigs';
import AppResource from 'general/constants/AppResource';
import AxionHelper from 'general/helpers/AxionHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';

ModalContentAxionConnect.propTypes = {
  onPressChangeInvoiceType: PropTypes.func,
  onSwitchChangeOutInvoiceType: PropTypes.func,
  onPressCancel: PropTypes.func,
  inInvoice: PropTypes.bool,
  outInvoice: PropTypes.bool,
  onChangeInInvoice: PropTypes.func,
  onChangeOutInvoice: PropTypes.func,
  addMoreAccountMode: PropTypes.bool,
  initialUsername: PropTypes.string,
  initialPassword: PropTypes.string,
  onChangeInputUserName: PropTypes.func,
  onChangeInputPassword: PropTypes.func,
};

ModalContentAxionConnect.defaultProps = {
  onPressChangeInvoiceType: null,
  onSwitchChangeOutInvoiceType: null,
  onPressCancel: null,
  inInvoice: false,
  outInvoice: false,
  onChangeInInvoice: null,
  onChangeOutInvoice: null,
  addMoreAccountMode: false,
  initialUsername: '',
  initialPassword: '',
  onChangeInputUserName: null,
  onChangeInputPassword: null,
};

/**
 *
 * @param {{
 * onPressChangeInvoiceType: function,
 * onSwitchChangeOutInvoiceType: function,
 * onPressCancel: function,
 * inInvoice: boolean,
 * outInvoice: boolean,
 * onChangeInInvoice: function,
 * onChangeOutInvoice: function,
 * addMoreAccountMode: boolean,
 * initialUsername: string,
 * initialPassword: string,
 * onChangeInputUserName: function,
 * onChangeInputPassword: function,
 * }} props
 * @returns
 */
function ModalContentAxionConnect(props) {
  // ----- params -----
  const { t } = useTranslation();
  const {
    onPressChangeInvoiceType,
    onSwitchChangeOutInvoiceType,
    onPressCancel,
    inInvoice,
    outInvoice,
    onChangeInInvoice,
    onChangeOutInvoice,
    addMoreAccountMode,
    initialUsername,
    initialPassword,
    onChangeInputUserName,
    onChangeInputPassword,
  } = props;
  const [inInvoiceOn, setInInvoiceOn] = useState(false);
  const [outInvoiceOn, setOutInvoiceOn] = useState(false);
  const dispatch = useDispatch();
  const { isConnecting } = useSelector((state) => state.axion);

  useEffect(() => {
    setInInvoiceOn(inInvoice);
    setOutInvoiceOn(outInvoice);
  }, [inInvoice, outInvoice]);

  // ---- methods ------
  function handlePressChangeInvoiceType() {
    if (onPressChangeInvoiceType) {
      onPressChangeInvoiceType();
    }
  }

  function handlePressCancel() {
    if (onPressCancel) {
      onPressCancel();
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          url: AppConfigs.axionConnectUrl,
          username: initialUsername,
          password: initialPassword,
        }}
        validationSchema={Yup.object({
          url: Yup.string().required(t('Required')),
          username: Yup.string().required(t('Required')),
          password: Yup.string().required(t('Required')),
        })}
        enableReinitialize
        onSubmit={async (values) => {
          const params = {
            username: values.username,
            password: values.password,
            isInput: inInvoiceOn,
            isOutput: outInvoiceOn,
          };
          if (Global.gTempSelectedOrganization) {
            if (Global.gTempSelectedOrganization.organizationDepartmentId) {
              params.organizationDepartmentId =
                Global.gTempSelectedOrganization.organizationDepartmentId;
            } else if (Global.gTempSelectedOrganization.organizationId) {
              params.organizationId = Global.gTempSelectedOrganization.organizationId;
            }
          }
          // console.log(Global.gTempSelectedOrganization);
          // return;
          if (!addMoreAccountMode) {
            const res = await AxionHelper.callApiConnect(params);
            if (res) {
              ToastHelper.showSuccess(t('ConnectAxionSuccess'));
              dispatch(thunkGetProfile());
              handlePressCancel();

              dispatch(thunkGetAllOrganizations());
              // lay ds hoa don dong bo cqt
              AxionHelper.callApiGetConnectionAxionHistory('IN', Global.gFiltersAxionInputInvoice);
              AxionHelper.callApiGetConnectionAxionHistory(
                'OUT',
                Global.gFiltersAxionOutputInvoice
              );
            }
          } else {
            const res = await AxionHelper.callApiConnectAndCreateOrganization(params);
            if (res) {
              // ket noi va tao tai khoan thanh cong
              handlePressCancel();
            }
          }
        }}
      >
        {(formikProps) => {
          return (
            <div>
              <div
                className="p-4 gutter-b"
                style={{
                  backgroundColor: '#F8F8F8',
                  borderRadius: '12px',
                }}
              >
                <p className="m-0">
                  Vui lòng đăng nhập bằng tài khoản CQT đã cung cấp cho doanh nghiệp để tự động tra
                  cứu xử lý và tải về hoá đơn theo Nghị định 123/2020/NĐ-CP
                </p>
              </div>

              <div className="">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                      <img src={AppResource.images.imgAxionBg} className="w-100" style={{}} />
                    </div>
                  </div>
                  <div className="col-lg-6 mt-6 mt-lg-0">
                    {/* link website tra cuu */}
                    <KTFormGroup
                      label={
                        <>
                          {t('LinkToLookupWebsite')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="url"
                      inputElement={
                        <FastField name="url">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder=""
                              disabled
                            />
                          )}
                        </FastField>
                      }
                    />

                    {/* ten dang nhap */}
                    <KTFormGroup
                      label={
                        <>
                          {t('Username')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="username"
                      inputElement={
                        <FastField name="username">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                if (!addMoreAccountMode) {
                                  AxionHelper.saveUsername(value);
                                }
                                if (onChangeInputUserName) {
                                  onChangeInputUserName(value);
                                }
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${t('Username')}...`}
                            />
                          )}
                        </FastField>
                      }
                    />

                    {/* mat khau */}
                    <KTFormGroup
                      label={
                        <>
                          {t('Password')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="password"
                      additionalClassName="mb-0"
                      inputElement={
                        <FastField name="password">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                if (!addMoreAccountMode) {
                                  AxionHelper.savePassword(value);
                                }
                                if (onChangeInputPassword) {
                                  onChangeInputPassword(value);
                                }
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.password}
                              placeholder={`${t('Password')}...`}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                </div>

                <div className="row mt-6">
                  <div className="col-lg-6"></div>

                  <div className="col-lg-6">
                    <p className="font-weight-bolder text-grey mb-0">{t('InvoiceType')}</p>
                    <div className="d-flex flex-wrap align-items-center">
                      <VIFormCheck
                        type="checkbox"
                        id="checkboxInInvoice"
                        name={t('InInvoice')}
                        handleChange={() => {
                          const newValue = !inInvoiceOn;
                          if (!addMoreAccountMode) {
                            AxionHelper.saveSwitchInvoiceIn(newValue);
                          }
                          setInInvoiceOn(newValue);
                          if (onChangeInInvoice) {
                            onChangeInInvoice(newValue);
                          }
                        }}
                        checkedItem={inInvoiceOn}
                        className="mr-6"
                      />
                      <VIFormCheck
                        type="checkbox"
                        id="checkboxOutInvoice"
                        name={t('OutInvoice')}
                        handleChange={() => {
                          const newValue = !outInvoiceOn;
                          setOutInvoiceOn(newValue);
                          if (onSwitchChangeOutInvoiceType && newValue) {
                            onSwitchChangeOutInvoiceType(newValue);
                          }
                          if (!newValue) {
                            if (!addMoreAccountMode) {
                              AxionHelper.saveSwitchInvoiceOut(newValue);
                            }
                            if (onChangeOutInvoice) {
                              onChangeOutInvoice(newValue);
                            }
                          }
                        }}
                        checkedItem={outInvoiceOn}
                      />
                    </div>

                    {outInvoiceOn && (
                      <div>
                        <p>
                          Tải toàn bộ hóa đơn đầu ra (bao gồm hóa đơn phát hành bởi ICORP eInvoice{' '}
                          <a
                            href="#"
                            className="font-weight-bold"
                            style={{
                              textDecoration: 'underline',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              handlePressChangeInvoiceType();
                            }}
                          >
                            {t('Change')}
                          </a>
                        </p>
                      </div>
                    )}

                    <div
                      className="px-4 py-2 mt-4"
                      style={{
                        backgroundColor: '#F8F8F8',
                        borderRadius: '12px',
                      }}
                    >
                      <p className="m-0">
                        <span className="text-danger">Lưu ý:</span> Mọi hóa đơn lấy về sẽ được tính
                        tài nguyên
                      </p>
                    </div>
                  </div>
                </div>

                {/* actions */}
                <div className="d-flex align-items-center justify-content-end gap-3 mt-6">
                  <VIButton
                    text={t('Cancel')}
                    className="btn-grey"
                    style={{ padding: '8px' }}
                    onClick={handlePressCancel}
                  />
                  <VIButton
                    text={isConnecting ? `${t('Connecting')}...` : t('Connect')}
                    className=""
                    style={{ padding: '8px' }}
                    onClick={() => {
                      formikProps.handleSubmit();
                    }}
                    disabled={isConnecting}
                    beforeIcon={isConnecting ? <ClockLoader color="white" size={20} /> : <></>}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default ModalContentAxionConnect;
