import { FastField, Formik } from 'formik';
import { motion } from 'framer-motion';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppConfigs from 'general/constants/AppConfigs';
import AccountHelper from 'general/helpers/AccountHelper';
import useRouter from 'hooks/useRouter';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

ForgotPasswordForm.defaultProps = {
  onSubmit: null,
  onCancel: null,
};

function ForgotPasswordForm(props) {
  // MARK: --- Params ---
  const { onSubmit, onCancel } = props;
  const router = useRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isRequestingResetPassword } = useSelector((state) => state.auth);

  // MARK: --- Functions ---
  function handleCancel() {
    if (onCancel) {
      onCancel();
    }
  }

  return (
    <motion.div
      className="login-form"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      {/* form */}
      <Formik
        initialValues={{
          email: '',
          taxCode: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string().required(t('EmailRequired')).email(t('EmailInvalid')),
          taxCode: Yup.string()
            .required(t('TaxCodeRequired'))
            .trim()
            .test('len', t('TaxCodeInvalid', { field: t('taxCode') }), (val) => {
              if (val) {
                return (
                  !val.toString().includes(' ') &&
                  (val.toString().match(AppConfigs.regexTaxCode10) ||
                    val.toString().match(AppConfigs.regexTaxCode13))
                );
              }
            }),
        })}
        onSubmit={async (values) => {
          // console.log({ values });
          const params = {
            email: values.email,
            taxCode: values.taxCode,
          };
          AccountHelper.saveAccountEmail(values.email);
          AccountHelper.saveAccountTaxCode(values.taxCode);

          const res = await AccountHelper.callApiRequestResetPassword(params);
          if (res) {
            // gui yc reset password thanh cong
            router.navigate('/auth/reset-password', { replace: true });
            AppDialogHelper.show(
              'Gửi yêu cầu thành công',
              'Vui lòng kiểm tra hòm thư của bạn (bao gồm cả thư mục Spam) và làm theo hướng dẫn để thực hiện đặt lại mật khẩu.',
              [
                {
                  title: t('Close'),
                  onClick: () => {
                    AppDialogHelper.hide();
                  },
                  type: 'neutral',
                },
              ],
              false
            );
          }
        }}
      >
        {(formikProps) => (
          <form
            className="form fv-plugins-bootstrap fv-plugins-framework"
            onSubmit={formikProps.handleSubmit}
          >
            {/* title */}
            <div className="pb-6 pt-lg-0 pt-5">
              <h3 className="font-weight-bolder text-dark font-size-h5 font-size-h2-lg">
                {t('ForgottenPassword')} ?
              </h3>
              <p className="text-dark font-weight-bold font-size-h6 mb-0">
                {t('EnterEmailToResetPassword')}
              </p>
            </div>

            {/* form group */}
            <KTFormGroup
              inputName="email"
              label={
                <>
                  {t('Email')} <span className="text-danger">(*)</span>
                </>
              }
              additionalClassName="fv-plugins-icon-container"
              // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
              inputElement={
                <FastField name="email">
                  {({ field, form, meta }) => (
                    <KTFormInput
                      name={field.name}
                      value={field.value}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      onBlur={() => {
                        form.setFieldTouched(field.name, true);
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(meta.error)}
                      isTouched={meta.touched}
                      feedbackText={meta.error}
                      type={KTFormInputType.email}
                      // additionalInputClassName="h-auto p-6 rounded-lg"
                    />
                  )}
                </FastField>
              }
            />

            {/* form group */}
            <KTFormGroup
              inputName="taxCode"
              label={
                <>
                  {t('TaxCode')} <span className="text-danger">(*)</span>
                </>
              }
              additionalClassName="fv-plugins-icon-container"
              // additionalLabelClassName="font-size-h6 font-weight-bolder text-dark"
              inputElement={
                <FastField name="taxCode">
                  {({ field, form, meta }) => (
                    <KTFormInput
                      name={field.name}
                      value={field.value}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      onBlur={() => {
                        form.setFieldTouched(field.name, true);
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(meta.error)}
                      isTouched={meta.touched}
                      feedbackText={meta.error}
                      type={KTFormInputType.text}
                      // additionalInputClassName="h-auto p-6 rounded-lg"
                    />
                  )}
                </FastField>
              }
            />

            {/* action */}
            <div className="d-flex flex-wrap pb-lg-0">
              <VIButton
                text={isRequestingResetPassword ? `${t('Requesting')}...` : t('Submit')}
                className="btn-blue w-100"
                style={{ padding: '10px' }}
                disabled={isRequestingResetPassword}
                beforeIcon={
                  isRequestingResetPassword ? <ClockLoader color="white" size={20} /> : <></>
                }
              />
            </div>
          </form>
        )}
      </Formik>
    </motion.div>
  );
}

export default ForgotPasswordForm;
