// Import reducers
import appReducer from './appSlice';
import dashboardReducer from 'features/Dashboard/dashboardSlice';
import invoiceReducer from 'features/Invoice/invoiceSlice';
import mailReducer from 'features/Mail/mailSlice';
import categoryReducer from 'features/Category/categorySlice';
import organizationReducer from 'features/Organization/organizationSlice';
import supplierReducer from 'features/Supplier/supplierSlice';
import axionReducer from 'features/Axions/axionSlice';
import systemReducer from 'features/System/systemSlice';
import accountReducer from 'features/Account/accountSlice';
import faqReducer from 'features/FAQ/faqSlice';
import instructionReducer from 'features/Instruction/instructionSlice';
import authReducer from 'features/Auth/authSlice';
import notificationReducer from 'features/Notification/notificationSlice';
import tagsReducer from 'features/Tag/tagSlice';
import toolReducer from 'features/Tools/toolSlice';
import paymentReducer from 'features/Payment/paymentSlice';

const { configureStore } = require('@reduxjs/toolkit');

// root reducer
const rootReducer = {
  app: appReducer,
  dashboard: dashboardReducer,
  invoice: invoiceReducer,
  mail: mailReducer,
  category: categoryReducer,
  organization: organizationReducer,
  supplier: supplierReducer,
  axion: axionReducer,
  system: systemReducer,
  account: accountReducer,
  faq: faqReducer,
  instruction: instructionReducer,
  auth: authReducer,
  notification: notificationReducer,
  tags: tagsReducer,
  tool: toolReducer,
  payment: paymentReducer,
};

// app store
const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_DEV_TOOLS_ENABLE == 1 ? true : false,
});

export default store;
