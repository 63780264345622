import customDataTableExpandableStyle from 'assets/styles/customDataTableExpandableStyle';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIModal from 'general/components/VietInvoice/VIModal';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import AppData from 'general/constants/AppData';
import OrganizationHelper from 'general/helpers/OrganizationHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import ModalAddDepartmentContent from '../ModalAddDepartmentContent';
import ModalAddMultiBranchContent from '../ModalAddMultiBranchContent';
import ModalEditBranchContent from '../ModalEditBranchContent';

ExpandedOrganizationComponent.propTypes = {
  data: PropTypes.array,
  level: PropTypes.number,
  onEditDepartment: PropTypes.func,
  onEditBranch: PropTypes.func,
  onAddBranch: PropTypes.func,
  addMoreAccountMode: PropTypes.bool,
  onPressedConnect: PropTypes.func,
};

ExpandedOrganizationComponent.defaultProps = {
  data: [],
  level: 2,
  onEditDepartment: null,
  onEditBranch: null,
  onAddBranch: null,
  addMoreAccountMode: false,
  onPressedConnect: null,
};

function ExpandedOrganizationComponent(props) {
  // MARK --- Params ---
  const {
    data,
    level,
    onAddBranch,
    onEditBranch,
    onEditDepartment,
    addMoreAccountMode,
    onPressedConnect,
  } = props;
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModalEditBranch, setShowModalEditBranch] = useState(false);
  const [showModalEditDepartment, setShowModalEditDepartment] = useState(false);
  const [showModalAddDepartment, setShowModalAddDepartment] = useState(false);
  const [modalEditMultiBranchShowing, setModalEditMultiBranchShowing] = useState(false);
  const account = useAccount();
  const columns = useMemo(() => {
    const arrColumns = [
      {
        name: t('BranchName'),
        minWidth: '300px',
        style: {
          paddingLeft: `${level}rem`,
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-bolder m-0 text-maxline-3 mr-4">
              {row?.organizationName ?? row?.departmentName}
            </p>
          );
        },
      },
      {
        name: t('TaxCode'),
        width: '150px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.taxCode}
            </p>
          );
        },
      },
      {
        name: t('EmailReceiveInvoice'),
        width: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.invoiceMailbox}
            </p>
          );
        },
      },
    ];
    if (!addMoreAccountMode) {
      arrColumns.push(
        {
          name: t('Người quản lý'),
          width: '250px',
          cell: (row) => {
            return (
              <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
                {OrganizationHelper.getEmployeeList(row)}
              </p>
            );
          },
        },
        {
          name: t('InputInvoices'),
          width: '130px',
          right: true,
          cell: (row) => {
            return (
              <p
                data-tag="allowRowEvents"
                className="q-max-line-3 font-weight-normal m-0 text-maxline-3 m-0"
              >
                {Utils.formatNumber(row?.buyInvoices)}
              </p>
            );
          },
        },
        {
          name: t('OutputInvoices'),
          width: '130px',
          right: true,
          cell: (row) => {
            return (
              <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3 m-0">
                {Utils.formatNumber(row?.sellInvoices)}
              </p>
            );
          },
        },

        {
          name: t('ContactPerson'),
          width: '250px',
          cell: (row) => {
            return (
              <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
                {row?.representativeName}
              </p>
            );
          },
        },
        {
          name: t('ContactPosition'),
          width: '220px',
          cell: (row) => {
            return (
              <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
                {row?.representativeJobTitle}
              </p>
            );
          },
        },
        {
          name: t('ContactEmail'),
          width: '220px',
          cell: (row) => {
            return (
              <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
                {row?.representativeEmail}
              </p>
            );
          },
        },
        {
          name: t('ContactPhone'),
          width: '160px',
          cell: (row) => {
            return (
              <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
                {row?.representativePhone}
              </p>
            );
          },
        }
      );
    }
    arrColumns.push(
      {
        name: t('State'),
        sortable: false,
        width: '130px',
        center: true,
        cell: (row) => {
          return (
            <KTTooltip text={row?.active ?? true ? t('Active') : t('InActive')}>
              {row?.active ?? true ? (
                <i className="fa-regular fa-circle-check text-primary" />
              ) : (
                <i className="fa-regular fa-times-circle text-danger" />
              )}
            </KTTooltip>
          );
        },
      },
      {
        name: '',
        center: true,
        width: !addMoreAccountMode ? '40px' : '120px',

        cell: (row) => {
          const menuItems = [];
          if (account.checkPermission(AppData.permissionCode.coCauToChuc.them) && level < 3) {
            menuItems.push({
              iconElement: <i className="fa-regular fa-sitemap"></i>,
              text: t('AddDepartment'),
              click: () => {
                handleAdd(row);
              },
            });
          }
          if (account.checkPermission(AppData.permissionCode.coCauToChuc.sua)) {
            menuItems.push({
              iconElement: <i className="far fa-edit"></i>,
              text: t('Edit'),
              click: () => {
                handleEdit(row);
              },
            });
          }
          if (account.checkPermission(AppData.permissionCode.coCauToChuc.xoa)) {
            menuItems.push({
              iconElement: <i className="far fa-trash"></i>,
              text: t('Delete'),
              click: () => {
                handleDeleteItem(row);
              },
            });
          }
          return (
            <div className="d-flex align-items-center justify-content-between">
              {!addMoreAccountMode ? (
                !_.isEmpty(menuItems) && (
                  <VITableRowDropDown
                    toggleElement={
                      <div>
                        <i className="text-grey fas fa-ellipsis-h p-0"></i>
                      </div>
                    }
                    // menuItem={menuItems.filter((item, index) => {
                    //   if (level === 3) {
                    //     if (index > 0) return item;
                    //   } else return item;
                    // })}
                    menuItem={menuItems}
                  />
                )
              ) : (
                <VIButton
                  text={
                    !_.isNull(account.axionToken) && row?.cqtToken === account.axionToken
                      ? t('Connected')
                      : t('Connect')
                  }
                  className={
                    !_.isNull(account.axionToken) && row?.cqtToken === account.axionToken
                      ? 'btn-grey'
                      : 'btn-blue'
                  }
                  style={{ padding: '8px' }}
                  onClick={() => {
                    if (onPressedConnect) {
                      onPressedConnect(row);
                    }
                  }}
                  disabled={!_.isNull(account.axionToken) && row?.cqtToken === account.axionToken}
                />
              )}
            </div>
          );
        },
      }
    );
    return arrColumns;
  }, [data, account, addMoreAccountMode]);
  const isSelectedOrganizationHasMultiAddresses = useMemo(() => {
    const organizationAddresses = selectedRow?.OrganizationAddresses;
    return (
      organizationAddresses && _.isArray(organizationAddresses) && organizationAddresses.length > 0
    );
  }, [selectedRow]);

  // MARK --- Functions ---
  function handleEdit(row) {
    if (addMoreAccountMode) {
      return;
    }
    if (!account.checkPermission(AppData.permissionCode.coCauToChuc.sua)) {
      return;
    }

    const organizationAddresses = row?.OrganizationAddresses;
    const isHasMultiAddresses =
      organizationAddresses && _.isArray(organizationAddresses) && organizationAddresses.length > 0;

    setSelectedRow(row);
    if (level === 2) {
      if (isHasMultiAddresses) {
        setModalEditMultiBranchShowing(true);
      } else {
        setShowModalEditBranch(true);
      }
    } else {
      setShowModalEditDepartment(true);
    }
  }

  function handleAdd(row) {
    setSelectedRow(row);
    setShowModalAddDepartment(true);
  }

  function handleDeleteItem(row) {
    // console.log({ row });
    // return;
    if (
      (level === 2 && row?.organizationId === account.organizationId) ||
      (level === 3 && row?.organizationDepartmentId === account.organizationDepartmentId)
    ) {
      AppDialogHelper.show(
        t('Warning'),
        t('CannotDeleteCurrentSelectedOrganization'),
        [
          {
            title: t('Close'),
            onClick: () => {
              AppDialogHelper.hide();
            },
            type: 'neutral',
          },
        ],
        false
      );
      return;
    }

    AppDialogHelper.show(
      t('ConfirmDelete'),
      t(`DoYouWantToDelete${level === 2 ? 'Branch' : 'Department'}`, {
        name: row?.organizationName ?? row?.departmentName,
      }),
      [
        {
          title: t('Cancel'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
        {
          title: t('Delete'),
          type: 'negative',
          onClick: () => {
            AppDialogHelper.hide();
            if (level === 2) {
              // xoa chi nhanh
              OrganizationHelper.callApiDeleteBranch([row?.organizationId]);
            } else {
              // xoa phong ban
              OrganizationHelper.callApiDeleteDepartment([row?.organizationDepartmentId]);
            }
          },
        },
      ]
    );
  }

  return (
    <>
      <DataTable
        noTableHead={true}
        columns={columns}
        data={data}
        customStyles={{
          ...customDataTableExpandableStyle,
          expanderButton: {
            ...customDataTableExpandableStyle.expanderButton,

            style: {
              ...customDataTableExpandableStyle.expanderButton.style,
              paddingLeft: `${level - 1}rem`,
              display: level < 3 ? 'block' : 'none',
            },
          },
        }}
        responsive={true}
        noHeader
        striped
        noDataComponent={<div></div>}
        onRowClicked={(row) => {}}
        onRowDoubleClicked={(row) => {
          handleEdit(row);
        }}
        pointerOnHover
        highlightOnHover
        selectableRowsHighlight
        expandableRows
        expandableRowExpanded={(row) => true}
        expandableRowsComponent={(row) => (
          <ExpandedOrganizationComponent
            addMoreAccountMode={addMoreAccountMode}
            level={level + 1}
            data={row.data.ChildrenOrganizations}
            onPressedConnect={(row) => {
              if (onPressedConnect) {
                onPressedConnect(row);
              }
            }}
          />
        )}
        expandableIcon={{
          expanded: <i className="fa-regular fa-square-minus"></i>,
          collapsed: <i className="fa-regular fa-square-plus"></i>,
        }}
      />

      {/* modal edit branch */}
      <VIModal
        titleModal={t('EditBranch')}
        closeBtn
        modalSize="lg"
        contentModal={
          <ModalEditBranchContent
            onPressCancel={() => {
              setShowModalEditBranch(false);
            }}
            branch={selectedRow}
            onCheckedAcceptMultiNameChange={(checked) => {
              if (checked) {
                setShowModalEditBranch(false);
                setModalEditMultiBranchShowing(true);
              }
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setShowModalEditBranch(false);
        }}
        show={showModalEditBranch}
      />

      {/* modal edit department */}
      <VIModal
        titleModal={t('EditDepartment')}
        closeBtn
        modalSize="lg"
        contentModal={
          <ModalAddDepartmentContent
            department={selectedRow}
            onPressCancel={() => {
              setSelectedRow(null);
              setShowModalEditDepartment(false);
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setSelectedRow(null);
          setShowModalEditDepartment(false);
        }}
        show={showModalEditDepartment}
      />

      {/* modal add department */}
      <VIModal
        titleModal={t('AddDepartment')}
        closeBtn
        modalSize="lg"
        contentModal={
          <ModalAddDepartmentContent
            parentBranch={selectedRow}
            onPressCancel={() => {
              setSelectedRow(null);
              setShowModalAddDepartment(false);
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setSelectedRow(null);
          setShowModalAddDepartment(false);
        }}
        show={showModalAddDepartment}
      />

      {/* modal add multi branch */}
      <VIModal
        titleModal={t('EditBranch')}
        closeBtn
        modalSize="xl"
        contentModal={
          <ModalAddMultiBranchContent
            // parentCompany={selectedOrganizationItem}
            item={selectedRow}
            onPressCancel={() => {
              setModalEditMultiBranchShowing(false);
              if (!Global.gTempStateCreateBranchMultiAddresses) {
                if (!isSelectedOrganizationHasMultiAddresses) {
                  setShowModalEditBranch(true);
                }
              }
              Global.gTempStateCreateBranchMultiAddresses = false;
            }}
            onCheckedAcceptMultiNameChange={(checked) => {
              if (!checked) {
                setShowModalEditBranch(true);
                setModalEditMultiBranchShowing(false);
              }
            }}
          />
        }
        handleSubmit={() => {}}
        onHide={() => {
          setModalEditMultiBranchShowing(false);
          if (!Global.gTempStateCreateBranchMultiAddresses) {
            if (!isSelectedOrganizationHasMultiAddresses) {
              setShowModalEditBranch(true);
            }
          }
          Global.gTempStateCreateBranchMultiAddresses = false;
        }}
        show={modalEditMultiBranchShowing}
      />
    </>
  );
}

export default ExpandedOrganizationComponent;
