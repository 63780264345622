import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';
import PropTypes from 'prop-types';

EmptyView.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttonText: PropTypes.string,
  onPressButton: PropTypes.func,
  imgClassName: PropTypes.string,
  tableMaxHeight: PropTypes.any,
};

EmptyView.defaultProps = {
  image: AppResource.images.imgEmptyNotFound,
  title: '',
  message: '',
  buttonText: '',
  onPressButton: null,
  imgClassName: '',
  tableMaxHeight: null,
};

/**
 *
 * @param {{
 *  image: string,
 *  title: string,
 *  message: string|element,
 *  buttonText: string,
 *  onPressButton: function,
 * imgClassName: string,
 * }} props
 * @returns
 */
function EmptyView(props) {
  // ----- Params -----
  const { image, title, message, buttonText, onPressButton, imgClassName, tableMaxHeight } = props;

  // ----- Methods -----
  function handlePressButton() {
    if (onPressButton) {
      onPressButton();
    }
  }

  return (
    <div
      className="w-100 d-flex flex-column align-items-center justify-content-center"
      style={{ height: `${tableMaxHeight}` }}
    >
      <img src={image} className={imgClassName} />
      <div className="mt-6 d-flex flex-column align-items-center">
        <h6 className="card-title text-grey font-weight-bolder mb-4">{title}</h6>
        <p className="font-size-lg text-center">{message}</p>
      </div>
      {!_.isEmpty(buttonText) && (
        <VIButton
          className="btn-blue border-0 mt-2 p-2"
          text={buttonText}
          onClick={handlePressButton}
        />
      )}
    </div>
  );
}

export default EmptyView;
