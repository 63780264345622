import { unwrapResult } from '@reduxjs/toolkit';
import store from 'app/store';
import { thunkGetProfile } from 'features/Auth/authSlice';
import {
  thunkCreateCompany,
  thunkCreateCompanyMultiAddress,
  thunkCreateDepartment,
  thunkCreateListAutoTransferByBuyerTaxCode,
  thunkCreateListAutoTransferInvoiceDepartment,
  thunkCreateListSupplierNotDownloadInvoice,
  thunkDeleteCompany,
  thunkDeleteDepartment,
  thunkGetAllOrganizations,
  thunkGetListAutoTransferByBuyerTaxCode,
  thunkGetListAutoTransferInvoiceDepartment,
  thunkGetListSupplierNotDownloadInvoice,
  thunkUpdateCompanyInfo,
  thunkUpdateCompanyInfoMultiAddress,
  thunkUpdateDepartmentInfo,
  thunkUpdateSetting,
} from 'features/Organization/organizationSlice';
import Global from 'general/utils/Global';
import StringUtils from 'general/utils/StringUtils';
import { t } from 'i18next';
import ToastHelper from './ToastHelper';

const OrganizationHelper = {
  // tim kiem cay co cau to chuc
  filter: (array, text, employeeId) => {
    // console.log({ employeeId, array });
    const getNodes = (result, object) => {
      if (
        StringUtils.removeVietnameseTones(object.organizationName ?? object.departmentName)
          .toLocaleLowerCase()
          .includes(StringUtils.removeVietnameseTones(text).toLocaleLowerCase())
      ) {
        if (employeeId === '') {
          result.push(object);
        } else if (object?.employees?.map((item) => item?.accountId)?.includes(employeeId)) {
          result.push(object);
        }
        return result;
      }
      if (Array.isArray(object.ChildrenOrganizations)) {
        const nodes = object.ChildrenOrganizations.reduce(getNodes, []);
        if (nodes.length) result.push({ ...object, ChildrenOrganizations: nodes });
      }
      return result;
    };

    return array.reduce(getNodes, []);
  },

  // lay danh sach nguoi quan ly
  getEmployeeList: (row) => {
    const employees = row?.employees;
    if (employees && _.isArray(employees)) {
      return employees.map((item) => item?.fullname ?? item?.email ?? '')?.join(', ');
    }
    return '';
  },

  // goi api xoa cong ty
  callApiDeleteCompany: async (ids) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkDeleteCompany(ids)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('DeleteCompanySuccess'));
        store.dispatch(thunkGetAllOrganizations());
      }
    } catch (error) {
      console.log({ error });
    }
  },

  // goi api xoa chi nhanh
  callApiDeleteBranch: async (ids) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkDeleteCompany(ids)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('DeleteBranchSuccess'));
        store.dispatch(thunkGetAllOrganizations());
      }
    } catch (error) {
      console.log({ error });
    }
  },

  // goi api tao cong ty, chi nhanh
  callApiCreateOrganization: async (params, isCompany = true, multiAddress = false) => {
    try {
      let res;
      if (!multiAddress) {
        res = unwrapResult(await store.dispatch(thunkCreateCompany(params)));
      } else {
        res = unwrapResult(await store.dispatch(thunkCreateCompanyMultiAddress(params)));
      }
      const { result, organization } = res;
      if (result === 'success' && organization) {
        ToastHelper.showSuccess(isCompany ? t('CreateCompanySuccess') : t('CreateBranchSuccess'));
        store.dispatch(thunkGetAllOrganizations());
        Global.gTempCompanyInvoiceMailbox = '';
        Global.gTempCompanyTaxCode = '';
        Global.resetTempOrganizationContactInfo();
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api cap nhat cong ty, chi nhanh
  callApiUpdateOrganization: async (id, params, isCompany = true, multiAddress = false) => {
    try {
      let res;
      if (!multiAddress) {
        res = unwrapResult(
          await store.dispatch(
            thunkUpdateCompanyInfo({
              id,
              params,
            })
          )
        );
      } else {
        res = unwrapResult(
          await store.dispatch(
            thunkUpdateCompanyInfoMultiAddress({
              id,
              params,
            })
          )
        );
      }

      const { result, organization } = res;
      if (result === 'success' && organization) {
        ToastHelper.showSuccess(isCompany ? t('UpdateCompanySuccess') : t('UpdateBranchSuccess'));
        store.dispatch(thunkGetAllOrganizations());
        Global.resetTempOrganizationContactInfo();
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api tao phong ban
  callApiCreateDepartment: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkCreateDepartment(params)));
      const { result, organizationDepartment } = res;
      if (result === 'success' && organizationDepartment) {
        ToastHelper.showSuccess(t('CreateDepartmentSuccess'));
        store.dispatch(thunkGetAllOrganizations());
        Global.resetTempOrganizationContactInfo();
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api xoa phong ban
  callApiDeleteDepartment: async (ids) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkDeleteDepartment(ids)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('DeleteDepartmentSuccess'));
        store.dispatch(thunkGetAllOrganizations());
      }
    } catch (error) {
      console.log({ error });
    }
  },

  // goi api cap nhat phong ban
  callApiUpdateDepartment: async (id, params) => {
    try {
      const res = unwrapResult(
        await store.dispatch(
          thunkUpdateDepartmentInfo({
            id,
            params,
          })
        )
      );
      const { result, organizationDepartment } = res;
      if (result === 'success' && organizationDepartment) {
        ToastHelper.showSuccess(t('UpdateDepartmentSuccess'));
        store.dispatch(thunkGetAllOrganizations());
        Global.resetTempOrganizationContactInfo();
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api cap nhat cai dat
  callApiUpdateSetting: async (params) => {
    try {
      const res = unwrapResult(await store.dispatch(thunkUpdateSetting(params)));
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('UpdateSettingSuccess'));
        store.dispatch(thunkGetProfile());
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api tao ds ncc khong tai ve hoa don
  callApiCreateListSupplierNotDownloadInvoice: async (params) => {
    try {
      const res = unwrapResult(
        await store.dispatch(thunkCreateListSupplierNotDownloadInvoice(params))
      );
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('UpdateListSupplierSuccess'));
        store.dispatch(thunkGetProfile());
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay ds ncc khong tai ve hoa don
  callApiGetListSupplierNotDownloadInvoice: async () => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetListSupplierNotDownloadInvoice()));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api tao ds tu dong dieu chuyen hoa don cho chi nhanh/phong ban
  callApiCreateListAutoTransferInvoiceDepartment: async (params) => {
    try {
      const res = unwrapResult(
        await store.dispatch(thunkCreateListAutoTransferInvoiceDepartment(params))
      );
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('UpdateListAutoTransferInvoiceDepartmentSuccess'));
        store.dispatch(thunkGetProfile());
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay ds tu dong dieu chuyen hoa don cho chi nhanh/phong ban
  callApiGetListAutoTransferInvoiceDepartment: async () => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetListAutoTransferInvoiceDepartment()));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api tao ds tu dong dieu chuyen hoa don theo mst nguoi mua
  callApiCreateListAutoTransferByBuyerTaxCode: async (params) => {
    try {
      const res = unwrapResult(
        await store.dispatch(thunkCreateListAutoTransferByBuyerTaxCode(params))
      );
      const { result } = res;
      if (result === 'success') {
        ToastHelper.showSuccess(t('UpdateListAutoTransferByBuyerTaxCodeSuccess'));
        store.dispatch(thunkGetProfile());
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // goi api lay ds tu dong dieu chuyen hoa don theo mst nguoi mua
  callApiGetListAutoTransferByBuyerTaxCode: async () => {
    try {
      const res = unwrapResult(await store.dispatch(thunkGetListAutoTransferByBuyerTaxCode()));
      const { result } = res;
      if (result === 'success') {
        return true;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  },
};

export default OrganizationHelper;
