import { unwrapResult } from '@reduxjs/toolkit';
import store from 'app/store';
import { thunkGetNotificationList } from 'features/Notification/notificationSlice';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import _ from 'lodash';
import AccountHelper from './AccountHelper';

const NotificationHelper = {
  // goi api lay danh sach thong bao
  callApiGetNotificationList: async (params) => {
    try {
      unwrapResult(await store.dispatch(thunkGetNotificationList(params)));
      return true;
    } catch (error) {
      console.log({ error });
      return false;
    }
  },

  // lay icon thong bao
  getNotificationIcon: (notification) => {
    switch (notification?.type) {
      case AppData.notificationType.promotion:
        return AppResource.icons.icNotificationPromotion;
      case AppData.notificationType.system:
        return AppResource.icons.icNotificationSystem;
      default:
        return AppResource.icons.icNotificationActivity;
    }
  },

  // kiem tra thong bao da duoc doc
  checkNotificationRead: (notificationId) => {
    const accountId = AccountHelper.getAccountId();
    const currentReadNotificationIds =
      localStorage.getItem(accountId + '_' + PreferenceKeys.savedNotificationReadIds) ?? '';
    const arrCurrentReadNotificationIds = _.filter(
      currentReadNotificationIds.split(',') ?? [],
      (item) => item !== ''
    );
    return arrCurrentReadNotificationIds.includes(notificationId + '');
  },

  // lay so luong thong bao da doc
  countUnreadNotifications: () => {
    const accountId = AccountHelper.getAccountId();
    const currentReadNotificationIds =
      localStorage.getItem(accountId + '_' + PreferenceKeys.savedNotificationReadIds) ?? '';
    const arrCurrentReadNotificationIds = _.filter(
      currentReadNotificationIds.split(',') ?? [],
      (item) => item !== ''
    );
    return _.uniq(arrCurrentReadNotificationIds)?.length;
  },

  // danh dau thong bao da duoc doc
  markReadNotification: (notificationId) => {
    const accountId = AccountHelper.getAccountId();
    const currentReadNotificationIds =
      localStorage.getItem(accountId + '_' + PreferenceKeys.savedNotificationReadIds) ?? '';
    let arrCurrentReadNotificationIds = currentReadNotificationIds.split(',') ?? [];
    if (!arrCurrentReadNotificationIds.includes(notificationId + '')) {
      arrCurrentReadNotificationIds.push(notificationId);
    }
    arrCurrentReadNotificationIds = _.filter(arrCurrentReadNotificationIds, (item) => item !== '');
    localStorage.setItem(
      accountId + '_' + PreferenceKeys.savedNotificationReadIds,
      _.uniq(arrCurrentReadNotificationIds).join(',')
    );
  },
};

export default NotificationHelper;
