import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Overlay } from 'react-bootstrap';
import './style.scss';

VIDropdownPosition.propTypes = {
  options: PropTypes.array,
  toggleElement: PropTypes.element,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  dropdwonContent: PropTypes.element,
  initialValue: PropTypes.any,
  onValueChanged: PropTypes.func,
  defaultValue: PropTypes.string,
};

VIDropdownPosition.defaultProps = {
  options: [],
  toggleElement: null,
  name: '',
  className: '',
  dropdwonContent: null,
  initialValue: '',
  onValueChanged: () => {},
  defaultValue: '',
};

function VIDropdownPosition(props) {
  const { options, toggleElement, name, className, dropdwonContent, onValueChanged, defaultValue } =
    props;
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef(null);
  const refDropdownMenu = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        if (!dropdwonContent) {
          setShowMenu(false);
        } else if (!refDropdownMenu.current?.contains(event.target) && refDropdownMenu.current) {
          setShowMenu(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setShowMenu(!showMenu);
  };

  const handleClose = () => {
    setShowMenu(false);
  };
  const [dataShow, setDataShow] = useState(options?.[0]?.name);
  const handleChange = (item) => {
    if (onValueChanged) {
      onValueChanged(item?.value);
    }
    setDataShow(item?.name);
  };
  useEffect(() => {
    setDataShow(defaultValue);
  }, [defaultValue]);

  return (
    <Dropdown className="VIDropdownPosition" ref={dropdownRef}>
      <Dropdown.Toggle className={` border-0 ${className} p-0`} variant="" onClick={handleToggle}>
        <div
          className={`w-100 h-34.5px px-3 py-2 toggle-tax`}
          style={
            showMenu
              ? { border: '1px solid #304ffd', boxShadow: '0px 0px 6px 0px #40afffcc' }
              : { border: '1px solid #e4e6ef' }
          }
        >
          {dataShow}
        </div>
      </Dropdown.Toggle>

      <Overlay show={showMenu} placement="bottom" target={(ref) => ref && ref?.parentElement}>
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              position: 'absolute',
              zIndex: 9999,
              boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              // backgroundColor: 'white',
              padding: '10px',
            }}
          >
            <Dropdown.Menu ref={refDropdownMenu} show={showMenu}>
              {dropdwonContent ? (
                dropdwonContent
              ) : (
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  {options.map((item, index) => {
                    return (
                      <div
                        className={`navi-item px-2`}
                        key={index}
                        onClick={() => {
                          handleClose();
                          handleChange(item);
                        }}
                      >
                        <a
                          href="#"
                          className="navi-link rounded-3 px-3"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <span
                            className="navi-text font-size-lg me-3"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {item?.name}
                          </span>
                          {dataShow === item?.name && (
                            <i className="fa-solid fa-check text-primary"></i>
                          )}
                        </a>
                      </div>
                    );
                  })}
                </div>
              )}
            </Dropdown.Menu>
          </div>
        )}
      </Overlay>
    </Dropdown>
  );
}

export default VIDropdownPosition;
