import KTCheckbox, {
  KTCheckboxStyle,
  KTCheckboxType,
} from 'general/components/OtherKeenComponents/KTFormControls/KTCheckbox';
import MailHelper from 'general/helpers/MailHelper';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MailTagView from '../MailTagView';
import './style.scss';
import useAccount from 'hooks/useAccount';

CellInvoiceMail.propTypes = {
  item: PropTypes.object,
  onChangeImportant: PropTypes.func,
  onPress: PropTypes.func,
  onMarkUnread: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
  onChangeSelectedState: PropTypes.func,
  selected: PropTypes.bool,
  highlight: PropTypes.bool,
  onViewInvoice: PropTypes.func,
  onRecheck: PropTypes.func,
  onForward: PropTypes.func,
};

CellInvoiceMail.defaultProps = {
  item: null,
  onChangeImportant: null,
  onPress: null,
  onMarkUnread: null,
  onDelete: null,
  onRestore: null,
  onChangeSelectedState: null,
  selected: false,
  highlight: false,
  onViewInvoice: null,
  onRecheck: null,
  onForward: null,
};

/**
 *
 * @param {{
 * item: object,
 * onChangeImportant: function,
 * onPress: function,
 * onMarkUnread: function,
 * onDelete: function,
 * onRestore: function,
 * onChangeSelectedState: function,
 * selected: boolean,
 * highlight: boolean,
 * onViewInvoice: function,
 * onRecheck: function,
 * onForward: function,
 * }} props
 * @returns
 */
function CellInvoiceMail(props) {
  // ---- params ----
  const { t } = useTranslation();
  const {
    item,
    onChangeImportant,
    onPress,
    onMarkUnread,
    onDelete,
    onRestore,
    onChangeSelectedState,
    selected,
    highlight,
    onViewInvoice,
    onRecheck,
    onForward,
  } = props;
  const [favorite, setFavorite] = useState(false);
  const account = useAccount();
  const menuOptions = useMemo(() => {
    const options = account.checkPermission('DELETE_AND_RESTORE_MAILBOX')
      ? [
          { name: t('ReCheck'), value: 'recheck', icon: 'fa-regular fa-list-check' },
          { name: t('MarkUnread'), value: 'mark_unread', icon: 'fa-regular fa-envelope' },
          { name: t('Forward'), value: 'forward', icon: 'fa-regular fa-share-from-square' },
          { name: t('Restore'), value: 'restore', icon: 'fa-regular fa-trash-undo' },
          { name: t('Delete'), value: 'delete', icon: 'fa-regular fa-trash-can' },
        ]
      : [
          { name: t('ReCheck'), value: 'recheck', icon: 'fa-regular fa-list-check' },
          { name: t('MarkUnread'), value: 'mark_unread', icon: 'fa-regular fa-envelope' },
          { name: t('Forward'), value: 'forward', icon: 'fa-regular fa-share-from-square' },
        ];
    if (item?.isTrash) {
      delete options[0];
      delete options[1];
      delete options[2];
    } else {
      delete options[3];
      if (item?.readStatus === 'UNREAD') {
        delete options[1];
      }
    }
    if (!account.checkPermission('DELETE_AND_RESTORE_MAILBOX') && item?.isTrash) {
      return null;
    } else {
      return options;
    }
  }, [item]);

  const isRead = item?.readStatus === 'READ';
  const isImportant = item?.isImportant ?? false;
  const isTrash = item?.isTrash ?? false;
  const invoiceAttachmentOptions = useMemo(() => {
    const attachmentsHasInvoice = item?.attachments?.filter((item) => !!item.invoiceId);
    return attachmentsHasInvoice.map((item) => {
      return {
        name: item.filename,
        value: item.invoiceId,
        icon: 'fa-regular fa-file',
      };
    });
  }, [item]);

  useEffect(() => {
    setFavorite(isImportant);
  }, [isImportant]);

  // ---- methods ----
  function handleViewMailInvoice(invoiceId) {
    if (onViewInvoice) {
      onViewInvoice(invoiceId);
    }
  }

  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  function handleMarkUnread() {
    if (onMarkUnread) {
      onMarkUnread();
    }
  }

  function handleDelete() {
    if (onDelete) {
      onDelete();
    }
  }

  function handleRestore() {
    if (onRestore) {
      onRestore();
    }
  }

  function handleRecheck() {
    if (onRecheck) {
      onRecheck();
    }
  }

  function handleChangeSelectedState(newState) {
    if (onChangeSelectedState) {
      onChangeSelectedState(newState);
    }
  }

  function handleForward() {
    if (onForward) {
      onForward();
    }
  }

  return (
    <div
      className={`CellInvoiceMail p-3 bg-hover-light d-flex gap-1 border-bottom cursor-pointer ${
        highlight ? 'bg-light' : ''
      }`}
    >
      <div className="d-flex flex-column gap-2 justify-content-between">
        <div
          className="w-30px h-30px d-flex align-items-center justify-content-center bg-hover-secondary rounded"
          style={{
            color: '#7177A8',
          }}
        >
          <KTCheckbox
            name="checkbox_mail"
            style={KTCheckboxStyle.rounded}
            type={KTCheckboxType.outline}
            checked={selected}
            onChange={(checked) => {
              handleChangeSelectedState(checked);
            }}
          />
        </div>
        {!isTrash && (
          <div
            className="w-30px h-30px d-flex align-items-center justify-content-center bg-hover-secondary rounded cursor-pointer"
            style={{
              color: !favorite ? '#7177A8' : '#FFA318',
            }}
            onClick={() => {
              const newValue = !favorite;
              setFavorite(newValue);
              if (onChangeImportant) {
                onChangeImportant(newValue);
              }
            }}
          >
            <i
              className={`${favorite ? 'fa-solid' : 'fa-regular'} fa-star`}
              style={{
                color: 'inherit',
                fontSize: '1.4rem',
              }}
            />
          </div>
        )}
      </div>

      <div className="flex-grow-1 d-flex flex-column align-items-start gap-2" onClick={handlePress}>
        <div>
          <p
            className={`m-0 ${!isRead ? 'font-weight-bolder' : ''}`}
            style={{
              fontSize: '1.1rem',
              color: '#1C2256',
            }}
          >
            {item?.subject}
          </p>
          <p className="m-0 text-primary">&lt;{item?.senderEmail}&gt;</p>
          <div className="m-0 d-flex align-items-center gap-2 text-grey">
            {/* <i
              className="fa-solid fa-circle"
              style={{
                fontSize: '0.4rem',
                color: 'inherit',
              }}
            />{' '} */}
            {item?.receiveDate ? Utils.formatDateTime(item?.receiveDate, 'DD/MM/YYYY') : ''}
          </div>
        </div>
        {item?.StatusMails?.length > 0 && (
          <div className="d-flex align-items-center gap-2 flex-wrap">
            {item?.StatusMails?.map((item, index) => {
              return (
                <div key={index}>
                  <MailTagView
                    text={
                      MailHelper.getMailStatusText(item?.statusValidMail) + ` (${item?.count ?? 0})`
                    }
                    color={MailHelper.getMailStatusColor(item?.statusValidMail)}
                    icon={MailHelper.getMailStatusIcon(item?.statusValidMail)}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="d-flex flex-column gap-2 align-items-center justify-content-between">
        {/* dropdown view invoice (attachments) */}
        {invoiceAttachmentOptions?.length > 0 ? (
          <Dropdown>
            <Dropdown.Toggle as={'div'}>
              <div
                className="w-30px h-30px d-flex align-items-center justify-content-center bg-hover-secondary rounded"
                style={{
                  color: '#7177A8',
                }}
              >
                <i
                  className="fa-regular fa-eye"
                  style={{
                    color: 'inherit',
                    fontSize: '1.4rem',
                  }}
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="p-2"
              popperConfig={{
                strategy: 'fixed',
                onFirstUpdate: () => window.dispatchEvent(new CustomEvent('scroll')),
              }}
            >
              {invoiceAttachmentOptions.map((item, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      const invoiceId = item.value;
                      handleViewMailInvoice(invoiceId);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {item.icon && (
                        <i
                          className={`${item.icon} mr-2 min-w-20px`}
                          style={{
                            color: 'inherit',
                          }}
                        />
                      )}{' '}
                      <span>{item.name}</span>
                    </div>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <div></div>
        )}

        {/* dropdown menu */}
        {menuOptions?.length > 0 && (
          <Dropdown>
            <Dropdown.Toggle as={'div'}>
              <div
                className="w-30px h-30px d-flex align-items-center justify-content-center bg-hover-secondary rounded"
                style={{
                  color: '#7177A8',
                }}
              >
                <i
                  className="fa-solid fa-ellipsis-vertical"
                  style={{
                    color: 'inherit',
                    fontSize: '1.4rem',
                  }}
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-2">
              {menuOptions.map((item, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      const action = item?.value;
                      switch (action) {
                        case 'mark_unread':
                          handleMarkUnread();
                          break;
                        case 'delete':
                          handleDelete();
                          break;
                        case 'restore':
                          handleRestore();
                          break;
                        case 'recheck':
                          handleRecheck();
                          break;
                        case 'forward':
                          handleForward();
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {item.icon && (
                        <i
                          className={`${item.icon} mr-2 min-w-20px`}
                          style={{
                            color: 'inherit',
                          }}
                        />
                      )}{' '}
                      {item.name}
                    </div>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </div>
  );
}

export default CellInvoiceMail;
