import axiosClient from './axiosClient';

const tagApi = {
  // lay danh sach tag
  findTag: (params) => {
    const url = '/tag/find';
    return axiosClient.get(url, { params });
  },
  //cập nhật tag
  updateTag: (id, params) => {
    const url = `/tag/update/${id}`;
    return axiosClient.put(url, params);
  },
  //thêm tag
  createTag: (params) => {
    const url = `/tag/create`;
    return axiosClient.post(url, params);
  },
  // xóa tag
  deleteTag: (ids) => {
    const url = '/tag/delete';
    return axiosClient.delete(url, {
      data: {
        tagIds: ids,
      },
    });
  },
  //lấy chi tiết tag
  findDetailTag: (id, params) => {
    const url = `/tag/detail/${id}`;
    return axiosClient.get(url, params);
  },
};

export default tagApi;
