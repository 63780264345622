import KTBSDropdown, {
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VISearchField from 'general/components/VietInvoice/VISearchField';
import AccountHelper from 'general/helpers/AccountHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

LoggedInDevice.propTypes = {
  dropdownMenuItems: PropTypes.array,
};

LoggedInDevice.defaultProps = {
  dropdownMenuItems: [],
};

/**
 *
 * @param {{
 *  dropdownMenuItems: object[],
 * }} props
 * @returns
 */
function LoggedInDevice(props) {
  // MARK --- Params ---
  const { dropdownMenuItems } = props;
  const { t } = useTranslation();
  const { loggedInDevices, isGettingLoggedInDevice, totalLoggedInDevices } = useSelector(
    (state) => state?.account
  );
  const [filters, setFilters] = useState(Global.gFiltersLoggedInDevice);
  const columns = useMemo(
    () => [
      {
        name: t('DeviceName'),
        minWidth: '310px',
        cell: (row) => {
          let brands = 'windows text-primary';
          if (
            row?.device?.toLowerCase().includes('mac') ||
            row?.device?.toLowerCase().includes('iphone') ||
            row?.device?.toLowerCase().includes('ipad')
          ) {
            brands = 'apple text-grey';
          } else if (row?.device?.toLowerCase().includes('android')) {
            brands = 'android text-success';
          }

          return (
            <p data-tag="allowRowEvents" className="font-weight-bolder m-0 text-maxline-3 ">
              <i className={`fa-brands fa-${brands} mr-4 fa-xl`}></i>
              {row?.device} - {row?.browser}
            </p>
          );
        },
      },
      {
        name: t('LoggedInTime'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3 ">
              {row?.createdAt ? Utils.formatDateTime(row?.createdAt, 'DD/MM/YYYY HH:mm:ss') : ''}
            </p>
          );
        },
      },
      {
        name: t('IPAddress'),
        minWidth: '120px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0">
              {row?.ipAddress}
            </p>
          );
        },
      },
    ],
    [loggedInDevices]
  );

  useEffect(() => {
    AccountHelper.callApiGetLoggedInDevices(filters);
  }, [filters]);

  return (
    <div className="h-100">
      <VIDataTable
        additionalCardBodyClassName="px-5"
        minusHeight={55}
        // haveCardHeader={false}
        title={
          <div className="d-flex align-items-center justify-content-center gap-1">
            <div className="d-block d-lg-none">
              <KTBSDropdown
                toggleElement={
                  <div className="w-30px h-30px button-close cursor-pointer d-flex align-items-center justify-content-center bg-transparent">
                    <i className="fa-solid fa-bars text-grey" />
                  </div>
                }
                alignment={KTBSDropdownAlignments.start}
                dropdownMenuItems={dropdownMenuItems}
                selectedValue={t('ProtectAccount')}
                onChange={(newValue) => {}}
              />
            </div>{' '}
            {t('LoggedInDevices')}
          </div>
        }
        draggable={false}
        pagination={{
          perPage: filters.limit,
          total: totalLoggedInDevices,
          currentPage: filters.page + 1,
          count: loggedInDevices?.length + filters.limit * filters.page,
        }}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          Global.gFiltersLoggedInDevice = {
            ...Global.gFiltersLoggedInDevice,
            page: iNewPage,
          };
          setFilters(Global.gFiltersLoggedInDevice);
        }}
        onChangeRowsPerPage={(perPage) => {
          Global.gFiltersLoggedInDevice = {
            ...Global.gFiltersLoggedInDevice,
            limit: perPage,
            page: 0,
          };
          setFilters(Global.gFiltersLoggedInDevice);
        }}
        fullColumns={columns}
        data={loggedInDevices}
        loading={isGettingLoggedInDevice}
        filterbar={
          <>
            <VISearchField
              placeholder={`${t('EnterToSearch')}...`}
              value={filters.q}
              className=""
              typingTimeout={500}
              onSubmit={(newValue) => {
                Global.gFiltersLoggedInDevice = {
                  ...Global.gFiltersLoggedInDevice,
                  q: newValue,
                  page: 0,
                };
                setFilters(Global.gFiltersLoggedInDevice);
              }}
            />
          </>
        }
        onRowClick={(row) => {
          console.log(row);
        }}
      />
    </div>
  );
}

export default LoggedInDevice;
