import customDataTableActionStyle from 'assets/styles/customDataTableActionStyle';
import { AppDialogHelper } from 'general/components/AppDialog';
import VIDataTable from 'general/components/VietInvoice/VIDataTable';
import VIDateRangePicker2 from 'general/components/VietInvoice/VIDateRangePicker2';
import VIModal from 'general/components/VietInvoice/VIModal';
import VITableRowDropDown from 'general/components/VietInvoice/VITableRowDropdown';
import AxionHelper from 'general/helpers/AxionHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ViewConnectionAxionHistoryDetail from '../ViewConnectionAxionHistoryDetail';

AxionInputInvoice.propTypes = {
  minusHeight: PropTypes.number,
};

AxionInputInvoice.defaultProps = {
  minusHeight: 0,
};

function AxionInputInvoice(props) {
  // MARK --- Params ---
  const { minusHeight } = props;
  const [headerHeight, setHeaderHeight] = useState(minusHeight);
  const { t } = useTranslation();
  const {
    connectionAxionInHistoryInList,
    isGettingConnectionAxionInHistory,
    totalConnectionAxionInHistory,
  } = useSelector((state) => state?.axion);
  const [filters, setFilters] = useState(Global.gFiltersAxionInputInvoice);
  const columns = useMemo(
    () => [
      {
        name: t('TakeInvoiceDate'),
        minWidth: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {row?.syncDate ? Utils.formatDateTime(row?.syncDate, 'DD/MM/YYYY') : ''}
            </p>
          );
        },
      },
      {
        name: t('DownloadSucceed'),
        minWidth: '160px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.succeededDownload ?? 0)}
            </p>
          );
        },
      },
      {
        name: t('DownloadFailed'),
        minWidth: '120px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.failedDownload ?? 0)}
            </p>
          );
        },
      },
      {
        name: t('InvoiceProcessed'),
        minWidth: '120px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.processed ?? 0)}
            </p>
          );
        },
      },
      {
        name: t('DuplicatedInvoice'),
        minWidth: '160px',
        right: true,
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.duplicated ?? 0)}
            </p>
          );
        },
      },
      {
        name: t('InvoiceProcessing'),
        minWidth: '200px',
        right: true,
        style: {},
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.processing ?? 0)}
            </p>
          );
        },
      },
      {
        name: t('InvoiceSkipped'),
        minWidth: '160px',
        right: true,
        style: {
          borderRight: 'none',
        },
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="font-weight-normal m-0 text-maxline-3">
              {Utils.formatNumber(row?.malformed ?? 0)}
            </p>
          );
        },
      },
      {
        name: '',
        center: true,
        width: '40px',
        style: {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          borderLeft: '1px solid #ebedfe',
        },
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            <VITableRowDropDown
              toggleElement={
                <div>
                  <i className="text-grey fas fa-ellipsis-h p-0"></i>
                </div>
              }
              menuItem={[
                {
                  iconElement: <i className="fa-regular fa-circle-info"></i>,
                  text: t('Detail'),
                  click: () => {
                    setSelectedHistory(row);
                    setModalConnectionAxionHistoryDetailShowing(true);
                  },
                },
                {
                  iconElement: <i className="fa-regular fa-rotate-right"></i>,
                  text: t('ReDownload'),
                  click: () => {
                    handleReloadConnectionHistory(row);
                  },
                },
              ]}
            />
          </div>
        ),
      },
    ],
    [t]
  );
  const [modalConnectionAxionHistoryDetailShowing, setModalConnectionAxionHistoryDetailShowing] =
    useState(false);
  const [selectedHistory, setSelectedHistory] = useState(null);

  useEffect(() => {
    AxionHelper.callApiGetConnectionAxionHistory('IN', filters);
  }, [filters]);

  // ---- methods ----
  async function handleReloadConnectionHistory(row) {
    const id = row?.cqtInvoiceHistoryId;
    if (id) {
      AppDialogHelper.show(t('Reload'), 'Hệ thống đang xử lý và sẽ trả về kết quả sau ít phút.', [
        {
          title: t('Close'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
      ]);
      const res = await AxionHelper.callApiReload(id);
      if (res) {
      }
    }
  }

  useEffect(() => {
    setHeaderHeight(minusHeight);
  }, [minusHeight]);

  return (
    <div className="h-100">
      <VIDataTable
        title={t('InvoiceReceiptHistory')}
        additionalCardBodyClassName="px-5"
        tableStyles={customDataTableActionStyle}
        minusHeight={Number(headerHeight)}
        toolbar={
          <div className="d-flex align-items-center gap-2">
            <VIDateRangePicker2
              alignRight={true}
              initialValue={filters.dateRangeValue}
              onPressSave={(selectedValue, startDate, endDate) => {
                Global.gFiltersAxionInputInvoice = {
                  ...Global.gFiltersAxionInputInvoice,
                  from: startDate,
                  to: endDate,
                  dateRangeValue: selectedValue,
                };
                setFilters(Global.gFiltersAxionInputInvoice);
              }}
            />
          </div>
        }
        draggable={false}
        pagination={{
          perPage: filters.limit,
          total: totalConnectionAxionInHistory,
          currentPage: filters.page + 1,
          count: connectionAxionInHistoryInList?.length + filters.limit * filters.page,
        }}
        onChangePage={(page) => {
          let iNewPage = parseInt(page);
          iNewPage -= 1;
          if (iNewPage < 0) {
            iNewPage = 0;
          }
          Global.gFiltersAxionInputInvoice = {
            ...Global.gFiltersAxionInputInvoice,
            page: iNewPage,
          };
          setFilters(Global.gFiltersAxionInputInvoice);
        }}
        onChangeRowsPerPage={(perPage) => {
          Global.gFiltersAxionInputInvoice = {
            ...Global.gFiltersAxionInputInvoice,
            limit: perPage,
            page: 0,
          };
          setFilters(Global.gFiltersAxionInputInvoice);
        }}
        fullColumns={columns}
        data={connectionAxionInHistoryInList}
        loading={false}
        onRowClick={(row) => {
          setSelectedHistory(row);
          setModalConnectionAxionHistoryDetailShowing(true);
        }}
      />

      {/* modal connection supplier history detail */}
      <VIModal
        titleModal={t('InvoiceList')}
        show={modalConnectionAxionHistoryDetailShowing}
        contentModal={
          <ViewConnectionAxionHistoryDetail
            item={selectedHistory}
            onPressCancel={() => {
              setModalConnectionAxionHistoryDetailShowing(false);
              setSelectedHistory(null);
            }}
          />
        }
        closeBtn
        modalSize="xl"
        handleSubmit={() => {}}
        onHide={() => {
          setModalConnectionAxionHistoryDetailShowing(false);
          setSelectedHistory(null);
        }}
      />
    </div>
  );
}

export default AxionInputInvoice;
