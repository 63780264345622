import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpDateStatusTax from '../LookUpUpDateStatusTax';
import VIModal from 'general/components/VietInvoice/VIModal';
import DataTable from 'react-data-table-component';
import customDataTableStyle from 'assets/styles/customDataTableStyle';
import VIPagination from 'general/components/VietInvoice/VIPagination';

CheckTaxRate.propTypes = {
  invoice: PropTypes.array,
};

CheckTaxRate.defaultProps = {
  invoice: null,
};

/**
 *
 * @param {{
 * invoice: array,
 *
 * }} props
 * @returns
 */
function CheckTaxRate(props) {
  // ---- params ----
  const { invoice, setLoading } = props;
  const [showModalUpdateStatusTax, setShowModalUpdateStatusTax] = useState(false);
  const [currentSelected, setCurrentSelected] = useState();
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        name: t('Tên hàng hóa, dịch vụ/ mã số hs'),
        minWidth: '290px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.productName ? row?.productName : ''}
            </p>
          );
        },
      },
      {
        name: t('Tax'),
        minWidth: '110px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className=" font-weight-normal m-0 text-maxline-3 mr-4">
              {row?.vat ? row?.vat : ''}
            </p>
          );
        },
      },
      {
        name: t('TaxReduction'),
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="m-0 text-maxline-3"
              onClick={() => {
                setCurrentSelected(row);
                setShowModalUpdateStatusTax(true);
              }}
            >
              {row?.isReduceTax ? (
                <span style={{ color: '#28A043' }}>Có giảm thuế</span>
              ) : (
                <span style={{ color: '#EE2334' }}>Không giảm thuế</span>
              )}
            </p>
          );
        },
      },
      {
        name: t('CheckTaxRates'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
              onClick={() => {
                setCurrentSelected(row);
                setShowModalUpdateStatusTax(true);
              }}
            >
              {Utils.getCheckTaxRate(row?.checkResult)}
            </p>
          );
        },
      },
    ],
    []
  );
  return (
    <div>
      <div className="">
        <DataTable
          className="h-100"
          fixedHeader
          fixedHeaderScrollHeight={'70vh'}
          columns={columns}
          data={invoice}
          responsive={true}
          noHeader
          striped
          pointerOnHover
          highlightOnHover
          selectableRowsHighlight
          customStyles={customDataTableStyle}
          onRowClicked={(row) => {
            setCurrentSelected(row);
            setShowModalUpdateStatusTax(true);
          }}
        />
        <div className="d-flex align-items-center justify-content-center mt-3">
          <VIPagination
            rowsPerPage={30}
            rowCount={invoice?.length ?? 0}
            currentPage={1}
            displayRows={invoice?.length ?? 0}
            onChangePage={(newPage) => {
              // let iNewPage = parseInt(newPage);
              // handleChangePage(iNewPage);
            }}
            onChangeRowsPerPage={(newPerPage) => {
              // const iNewPerPage = parseInt(newPerPage);
              // handleChangeRowPerPage(iNewPerPage);
            }}
          />
        </div>
      </div>
      <VIModal
        titleModal={t('Tra cứu mặt hàng giảm thuế theo Nghị quyết 101/2023/QH15')}
        show={showModalUpdateStatusTax}
        onHide={() => {
          setShowModalUpdateStatusTax(false);
        }}
        contentModal={
          <UpDateStatusTax
            currentSelected={currentSelected}
            setShowModalUpdateStatusTax={setShowModalUpdateStatusTax}
            setLoading={setLoading}
          />
        }
        closeBtn={true}
        handleSubmit={async () => {}}
        modalSize="xl"
      />
    </div>
  );
}

export default CheckTaxRate;
