import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

NotificationDetailView.propTypes = {
  item: PropTypes.object,
};

NotificationDetailView.defaultProps = {
  item: null,
};

/**
 *
 * @param {{
 * item: object,
 * }} props
 * @returns
 */
function NotificationDetailView(props) {
  // ---- params -----
  const { item } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <img
          src={Utils.getFullUrl(item?.thumbnail)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = AppResource.images.imgErrorNotificationImage;
          }}
          className="w-100 mb-6 rounded-lg"
          style={{
            aspectRatio: '607/149',
            objectFit: 'cover',
          }}
        />

        <div className="d-flex flex-row-reverse mb-6">
          <span> {Utils.formatDateTime(item?.createdAt, `[${t('Date')}] DD/MM/YYYY`)}</span>
        </div>

        <div
          className="mb-6"
          style={{
            whiteSpace: 'break-spaces',
            lineHeight: '1.8rem',
          }}
        >
          {item?.description}
        </div>

        <div
          className="cursor-pointer d-flex align-items-center text-hover-primary"
          onClick={() => {
            window.open(item?.url);
          }}
        >
          <i
            // style={{ color: '#00aab7' }}
            className="fa-regular fa-arrow-up-right-from-square fa-lg mr-2"
          ></i>
          <span className="">{t('LearnMore')}</span>
        </div>
      </div>
    </div>
  );
}

export default NotificationDetailView;
