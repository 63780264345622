import { thunkGetProfile } from 'features/Auth/authSlice';
import AxionInputInvoice from 'features/Axions/components/AxionInputInvoice';
import AxionOutputInvoice from 'features/Axions/components/AxionOutputInvoice';
import CellAxionStatSmall from 'features/Axions/components/CellAxionStatSmall';
import ModalContentAxionChangeInvoiceType from 'features/Axions/components/ModalContentAxionChangeInvoiceType';
import ModalContentAxionConnect from 'features/Axions/components/ModalContentAxionConnect';
import ModalContentAxionDisconnect from 'features/Axions/components/ModalContentAxionDisconnect';
import ViewAxionListAccount from 'features/Axions/components/ViewAxionListAccount';
import ViewChooseDateRangeSync from 'features/Axions/components/ViewChooseDateRangeSync';
import { AppDialogHelper } from 'general/components/AppDialog';
import VIAppTabs from 'general/components/VietInvoice/VIAppTabs';
import VIButton from 'general/components/VietInvoice/VIButton';
import VIModal from 'general/components/VietInvoice/VIModal';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import AxionHelper from 'general/helpers/AxionHelper';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import useAccount from 'hooks/useAccount';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ClockLoader } from 'react-spinners';

function AxionConnectScreen(props) {
  // ----- params -----
  const { t } = useTranslation();
  const [modalConnectShowing, setModalConnectShowing] = useState(false);
  const [modalChangeInvoiceTypeShowing, setModalChangeInvoiceTypeShowing] = useState(false);
  const [modalDisconnectShowing, setModalDisconnectShowing] = useState(false);
  const [modalChooseDateRangeSyncShowing, setModalChooseDateRangeSyncShowing] = useState(false);
  const [modalListAccountShowing, setModalListAccountShowing] = useState(false);
  const {
    isAxionConnected,
    axionIsInput,
    axionIsOutput,
    axionUserName,
    axionPassword,
    checkPermission,
  } = useAccount();
  const tabList = useMemo(() => {
    return [t('OutInvoice'), t('InInvoice')];
  }, []);
  const [activeTab, setActiveTab] = useState(tabList[1]);
  const dispatch = useDispatch();
  const [inInvoiceOn, setInInvoiceOn] = useState(false);
  const [outInvoiceOn, setOutInvoiceOn] = useState(false);
  const { totalHDDV, totalHDDR, isSynchronizing } = useSelector((state) => state.axion);
  const refButtons = useRef(null);
  const refCards = useRef(null);
  const refAppTab = useRef(null);
  const [addMoreAccountMode, setAddMoreAccountMode] = useState(false);
  const [initialAxionUserName, setInitialAxionUsername] = useState('');
  const [initialAxionPassword, setInitialAxionPassword] = useState('');

  useEffect(() => {
    setInInvoiceOn(axionIsInput);
    setOutInvoiceOn(axionIsOutput);
  }, [axionIsInput, axionIsOutput]);

  // ----- methods -----
  function handleConnectAxion() {
    setModalConnectShowing(true);
  }

  function handleDisconnectAxion() {
    setModalDisconnectShowing(true);
  }

  function handleSyncInvoiceAxion() {
    setModalChooseDateRangeSyncShowing(true);
  }

  async function handleSyncAxion() {
    const params = {
      from: Global.gFiltersAxionSync.from,
      to: Global.gFiltersAxionSync.to,
    };
    console.log({ params });
    AppDialogHelper.show(
      t('SyncInvoice'),
      t('SyncInvoiceText'),
      [
        {
          title: t('Close'),
          type: 'neutral',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
      ],
      false,
      AppResource.images.imgSyncInvoice,
      true
    );
    setTimeout(() => {
      AppDialogHelper.hide();
    }, 3000);
    // goi api lay lich su dong bo hoa don
    const res = await AxionHelper.callApiSync(params);
    if (res) {
    }
  }

  return (
    <div className="d-flex flex-column min-h-100">
      {isAxionConnected ? (
        <div className="flex-grow-1 d-flex flex-column">
          {/* da ket noi cqt */}
          <div ref={refButtons} className="d-flex align-items-center justify-content-between">
            <h3 className="card-label text-grey font-weight-bolder mb-0">{t('ConnectAxion')}</h3>
            <div className="d-flex align-items-center gap-2 flex-wrap justify-content-end">
              {checkPermission(AppData.permissionCode.tuDongKetNoi.cqt) && (
                <div className="d-flex gap-2">
                  <VIButton
                    // beforeIcon={<i className="fa-regular fa-add" />}
                    text={t('AddAccount')}
                    className="btn-white p-3"
                    onClick={() => {
                      setInitialAxionUsername('');
                      setInitialAxionPassword('');
                      setInInvoiceOn(true);
                      setOutInvoiceOn(false);
                      setAddMoreAccountMode(true);
                      handleConnectAxion();
                    }}
                  />
                  <VIButton
                    // beforeIcon={<i className="fa-regular fa-add" />}
                    text={t('ListAccount')}
                    className="btn-white p-3"
                    onClick={() => {
                      setModalListAccountShowing(true);
                    }}
                  />
                </div>
              )}

              <VIButton
                beforeIcon={
                  isSynchronizing ? (
                    <ClockLoader color="white" size={20} />
                  ) : (
                    <i className="fa-regular fa-rotate" />
                  )
                }
                text={isSynchronizing ? `${t('Syncing')}...` : t('SyncNow')}
                className="btn-blue border-0 p-3"
                onClick={handleSyncInvoiceAxion}
                disabled={isSynchronizing}
              />
              {checkPermission(AppData.permissionCode.tuDongKetNoi.cqt) && (
                <div className="d-flex gap-2">
                  <VIButton
                    beforeIcon={<i className="fa-regular fa-rotate-left" />}
                    text={t('ReConnect')}
                    className="btn-green border-0 p-3"
                    onClick={() => {
                      setInitialAxionUsername(axionUserName);
                      setInitialAxionPassword(axionPassword);
                      handleConnectAxion();
                    }}
                  />
                  <VIButton
                    beforeIcon={<i className="fa-regular fa-link-simple-slash" />}
                    text={t('Disconnect')}
                    className="btn-red border-0 p-3"
                    onClick={handleDisconnectAxion}
                  />
                </div>
              )}
            </div>
          </div>

          <div ref={refCards} className="row mt-4">
            <div className="col-md-6 mb-4">
              <CellAxionStatSmall
                text={t('InInvoice')}
                value={totalHDDV}
                active={axionIsInput}
                icon={<img src={AppResource.images.imgInputInvoice} />}
              />
            </div>
            <div className="col-md-6 mb-4">
              <CellAxionStatSmall
                text={t('OutInvoice')}
                value={totalHDDR}
                icon={<img src={AppResource.images.imgOutputInvoice} />}
                active={axionIsOutput}
              />
            </div>
          </div>

          <div className="flex-grow-1 d-flex flex-column">
            <div ref={refAppTab}>
              <VIAppTabs
                tabs={tabList}
                activeTab={activeTab}
                handleClick={(tab) => {
                  setActiveTab(tab);
                }}
              />
            </div>
            <div
              className="bg-white border-left border-right border-bottom "
              style={{
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '20px',
              }}
            >
              {activeTab === t('InInvoice') ? (
                <AxionInputInvoice
                  minusHeight={
                    refButtons.current?.offsetHeight +
                    refCards.current?.offsetHeight +
                    refAppTab.current?.offsetHeight +
                    20
                  }
                />
              ) : (
                <AxionOutputInvoice
                  minusHeight={
                    refButtons.current?.offsetHeight +
                    refCards.current?.offsetHeight +
                    refAppTab.current?.offsetHeight +
                    20
                  }
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          {/* chua ket noi cqt */}
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="card-title text-grey font-weight-bolder mb-0">{t('ConnectAxion')}</h3>
            {checkPermission(AppData.permissionCode.tuDongKetNoi.cqt) && (
              <div className="d-flex align-items-center gap-2 flex-wrap justify-content-end">
                <VIButton
                  // beforeIcon={<i className="fa-regular fa-add" />}
                  text={t('AddAccount')}
                  className="btn-white p-3"
                  onClick={() => {
                    setAddMoreAccountMode(true);
                    handleConnectAxion();
                  }}
                />
                <VIButton
                  // beforeIcon={<i className="fa-regular fa-add" />}
                  text={t('ListAccount')}
                  className="btn-white p-3"
                  onClick={() => {
                    setModalListAccountShowing(true);
                  }}
                />
              </div>
            )}
          </div>

          <div className="mt-4">
            <div className="row">
              <div className="col">
                <div
                  className="overflow-hidden bg-white p-6"
                  style={{
                    borderRadius: '14px',
                    boxShadow: '0px 8px 24px 0px rgba(37, 42, 91, 0.05)',
                  }}
                >
                  <img className="mb-4 h-250px" src={AppResource.images.imgAxionBg} />
                  <p className="font-size-lg font-weight-bold">
                    Hệ thống tra cứu hoá đơn điện tử theo Nghị định 123/2020/NĐ-CP
                  </p>
                  <VIButton
                    className="btn-blue border-0 mt-2 p-2"
                    text={t('Connect')}
                    onClick={handleConnectAxion}
                    disabled={!checkPermission(AppData.permissionCode.tuDongKetNoi.cqt)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* modal connect axion */}
      <VIModal
        titleModal={t('SetupConnection')}
        show={modalConnectShowing && !modalChangeInvoiceTypeShowing}
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalConnectShowing(false);
          setInInvoiceOn(axionIsInput);
          setOutInvoiceOn(axionIsOutput);
          setAddMoreAccountMode(false);
          Global.gTempSelectedOrganization = null;
        }}
        modalSize="lg"
        contentModal={
          <ModalContentAxionConnect
            onPressChangeInvoiceType={() => {
              setOutInvoiceOn(false);
              setModalChangeInvoiceTypeShowing(true);
            }}
            onSwitchChangeOutInvoiceType={(isOn) => {
              if (isOn) {
                // hien thi dialog tai tat ca hoa don dau ra
                setOutInvoiceOn(false);
                setModalChangeInvoiceTypeShowing(true);
              }
            }}
            onPressCancel={() => {
              setModalConnectShowing(false);
              setInInvoiceOn(axionIsInput);
              setOutInvoiceOn(axionIsOutput);
              setAddMoreAccountMode(false);
              setInitialAxionUsername('');
              setInitialAxionPassword('');
              Global.gTempSelectedOrganization = null;
            }}
            inInvoice={inInvoiceOn}
            outInvoice={outInvoiceOn}
            onChangeInInvoice={(newValue) => {
              setInInvoiceOn(newValue);
            }}
            onChangeOutInvoice={(newValue) => {
              setOutInvoiceOn(newValue);
            }}
            addMoreAccountMode={addMoreAccountMode}
            initialUsername={initialAxionUserName}
            initialPassword={initialAxionPassword}
            onChangeInputUserName={(value) => {
              setInitialAxionUsername(value);
            }}
            onChangeInputPassword={(value) => {
              setInitialAxionPassword(value);
            }}
          />
        }
      />

      {/* modal change invoice type */}
      <VIModal
        titleModal={
          <div className="d-flex align-items-center">
            <i
              className="fa-regular fa-hexagon-exclamation text-warning mr-2"
              style={{
                fontSize: '1.8rem',
              }}
            />
            {t('Notice')}
          </div>
        }
        show={modalChangeInvoiceTypeShowing}
        closeBtn
        handleSubmit={() => {
          setModalChangeInvoiceTypeShowing(false);
        }}
        onHide={() => {
          setModalChangeInvoiceTypeShowing(false);
        }}
        buttonPositive=""
        buttonNegative={t('Close')}
        contentModal={
          <ModalContentAxionChangeInvoiceType
            onChangeOutInvoice={(isOn) => {
              setOutInvoiceOn(isOn);
            }}
            outInvoice={outInvoiceOn}
          />
        }
      />

      {/* modal disconnect */}
      <VIModal
        titleModal={t('Disconnect')}
        show={modalDisconnectShowing}
        contentModal={
          <ModalContentAxionDisconnect
            onPressCancel={() => {
              setModalDisconnectShowing(false);
            }}
            onPressDisconnect={async ({ isInput, isOutput }) => {
              const res = await AxionHelper.callApiDisconnect(isInput, isOutput);
              if (res) {
                setModalDisconnectShowing(false);
                dispatch(thunkGetProfile());
                ToastHelper.showSuccess(t('DisconnectAxionSuccess'));
              }
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalDisconnectShowing(false);
        }}
      />

      {/* modal choose date range sync */}
      <VIModal
        titleModal={t('SyncInvoice')}
        show={modalChooseDateRangeSyncShowing}
        contentModal={<ViewChooseDateRangeSync />}
        closeBtn
        handleSubmit={() => {
          handleSyncAxion();
          setModalChooseDateRangeSyncShowing(false);
        }}
        onHide={() => {
          setModalChooseDateRangeSyncShowing(false);
        }}
        buttonPositive={t('Sync')}
        buttonNegative={t('Cancel')}
      />

      {/* modal list account */}
      <VIModal
        titleModal={t('ListAccount')}
        modalSize="xl"
        show={modalListAccountShowing}
        contentModal={
          <ViewAxionListAccount
            onPressedCancel={() => {
              setModalListAccountShowing(false);
            }}
            onShowModalConnect={(row, addAccountMode = true) => {
              // console.log({ row, addAccountMode });
              // return;
              Global.gTempSelectedOrganization = row;
              setAddMoreAccountMode(addAccountMode);
              handleConnectAxion();
            }}
          />
        }
        closeBtn
        handleSubmit={() => {
          setInitialAxionUsername('');
          setInitialAxionPassword('');
          setInInvoiceOn(true);
          setOutInvoiceOn(false);
          setAddMoreAccountMode(true);
          setModalListAccountShowing(false);
          handleConnectAxion();
        }}
        onHide={() => {
          setModalListAccountShowing(false);
        }}
        buttonPositive={t('AddAccount')}
        buttonNegative={t('Close')}
      />
    </div>
  );
}

export default AxionConnectScreen;
