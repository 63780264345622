import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useScrollToTop from 'hooks/useScrollToTop';
import { useDispatch } from 'react-redux';
import useRouter from 'hooks/useRouter';
import HeaderStore from 'features/Payment/components/HeaderStore';
import LandingPageFooter from 'features/LandingPage/components/LandingPageFooter';
import VIButton from 'general/components/VietInvoice/VIButton';
import AppResource from 'general/constants/AppResource';

VNPayReturnScreen.propTypes = {};

function VNPayReturnScreen(props) {
  // ---- params ----
  const { t } = useTranslation();
  useScrollToTop();
  const dispatch = useDispatch();
  const router = useRouter();
  const query = router.query;
  console.log({ query });
  const paymentSuccess = ['00', '07'].includes(query?.vnp_ResponseCode);
  const clientOrderCode = query?.vnp_TxnRef?.split(' ')[0] ?? '';
  console.log({ paymentSuccess, clientOrderCode });

  return (
    <div>
      <div className="VNPayReturnScreen min-vh-100 d-flex flex-column">
        {/* header */}
        <HeaderStore additionalClassName="bg-white" />

        {/* body */}
        <div className="OrderSuccessScreen_Body flex-grow-1 pb-10">
          <div className="container-xl py-10 d-flex align-items-center justify-content-center my-10">
            <div
              className="bg-white overflow-hidden"
              style={{
                borderRadius: '16px',
                boxShadow: '0px 8px 24px 0px rgba(37, 42, 91, 0.05)',
              }}
            >
              <div
                className="d-flex gap-2 align-items-center px-10 py-6 justify-content-center"
                style={{
                  color: paymentSuccess ? '#28A043' : '#E54135',
                }}
              >
                <i
                  className={`fa-regular ${paymentSuccess ? 'fa-circle-check' : 'fa-circle-xmark'}`}
                  style={{
                    color: 'inherit',
                    fontSize: '32px',
                  }}
                />
                <span className="font-size-h4 font-weight-bolder">
                  {`Thanh toán ${
                    paymentSuccess ? 'thành công' : 'thất bại'
                  } cho đơn hàng ${clientOrderCode}`}
                </span>
              </div>

              <div
                className="p-10 d-flex flex-column gap-4"
                style={{
                  backgroundColor: '#F8F8F8',
                }}
              >
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <img
                    className=""
                    src={
                      paymentSuccess
                        ? AppResource.images.imgPaymentSuccess
                        : AppResource.images.imgPaymentFailed
                    }
                  />
                  {/* <div className="font-size-normal"></div> */}
                </div>

                <div className="d-flex flex-column align-items-center">
                  <p>Xem danh sách sản phẩm đã mua và bắt đầu sử dụng</p>
                  <VIButton
                    text={t('ViewProducts')}
                    className="btn-blue"
                    style={{ padding: '8px 16px' }}
                    onClick={() => {
                      router.navigate('/inbot/system/resource');
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <LandingPageFooter />
      </div>
    </div>
  );
}

export default VNPayReturnScreen;
