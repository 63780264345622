import VIButton from 'general/components/VietInvoice/VIButton';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function LookUpInvoice(props) {
  const { currentLookUp } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <div className="font-size-h5 font-weight-bolder mb-2">{t('LookUp')}</div>
        <Table bordered hover>
          <tbody>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <div style={{ width: 'max-content' }}>{t('LookupLink')}</div>
              </td>
              <td>
                <a
                  target="blank"
                  href={currentLookUp?.supplierLookupWebsite}
                  className={`${
                    currentLookUp?.supplierLookupWebsite ? 'text-primary' : 'text-grey'
                  } col-8 cursor-pointer ps-0`}
                >
                  {currentLookUp?.supplierLookupWebsite
                    ? currentLookUp?.supplierLookupWebsite
                    : 'Không có link tra cứu'}
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <div style={{ width: 'max-content' }}>{t('LookupCode')}</div>
              </td>
              <td>
                {currentLookUp?.supplierReferenceCode
                  ? currentLookUp?.supplierReferenceCode
                  : 'Không có mã tra cứu'}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div>
        <div className="font-size-h5 font-weight-bolder mb-2">{t('SellerInformation')}</div>
        <Table bordered hover>
          <tbody>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <div style={{ width: 'max-content' }}>{t('SellerName')}</div>
              </td>
              <td>
                <span className="" style={{ wordBreak: 'break-word' }}>
                  {currentLookUp?.sellerName ? currentLookUp?.sellerName : 'Không có tên người bán'}
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <div style={{ width: 'max-content' }}>{t('TaxCode')}</div>
              </td>
              <td>
                <span className="" style={{ wordBreak: 'break-word' }}>
                  {currentLookUp?.sellerTaxCode
                    ? currentLookUp?.sellerTaxCode
                    : 'Không có mã số thuế'}
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <div style={{ width: 'max-content' }}>{t('PhoneNumber')}</div>
              </td>
              <td>
                <span className="" style={{ wordBreak: 'break-word' }}>
                  {currentLookUp?.sellerPhone
                    ? currentLookUp?.sellerPhone
                    : 'Không có Số điện thoai'}
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <div style={{ width: 'max-content' }}>{t('Email')}</div>
              </td>
              <td>
                <span className="" style={{ wordBreak: 'break-word' }}>
                  {currentLookUp?.sellerMail ? currentLookUp?.sellerMail : 'Không có Email'}
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <div style={{ width: 'max-content' }}>{t('CodeAxion')}</div>
              </td>
              <td>
                <span className="" style={{ wordBreak: 'break-word' }}>
                  {currentLookUp?.maCQT ? currentLookUp?.maCQT : 'Không có mã cơ quan thuế'}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default LookUpInvoice;
