import LandingPageFooter from 'features/LandingPage/components/LandingPageFooter';
import CartInputVoucher from 'features/Payment/components/CartInputVoucher';
import CartPackageList from 'features/Payment/components/CartPackageList';
import HeaderStore from 'features/Payment/components/HeaderStore';
import { useTranslation } from 'react-i18next';

ShoppingCartHomeScreen.propTypes = {};

function ShoppingCartHomeScreen(props) {
  // ---- params ----
  const { t } = useTranslation();

  return (
    <div className="min-vh-100 d-flex flex-column">
      {/* header */}
      <HeaderStore />

      {/* body */}
      <div className="container-xl flex-grow-1 pb-10">
        <h3 className="card-label font-weight-bolder text-grey m-0">{t('ShoppingCart')}</h3>
        <div className="row mt-6">
          {/* danh sach goi */}
          <div className="col-8">
            <CartPackageList />
          </div>

          {/* ma giam gia */}
          <div className="col-4">
            <CartInputVoucher />
          </div>
        </div>
      </div>

      {/* footer */}
      <LandingPageFooter />
    </div>
  );
}

export default ShoppingCartHomeScreen;
